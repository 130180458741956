import Long from 'long';
import {IMCAttachment, MCAttachment, MCAttachmentSource} from '../../api/proto';
import {FileData, validateImageFileLimitsToUpload} from './fileReaders';

export function formatAttachment(dataFile: FileData, asDocumentType?: boolean): IMCAttachment {
  const messageRequestData: IMCAttachment = {
    name: dataFile.fileName,
    size: Long.fromNumber(dataFile.file.size),
    width: dataFile.width ? Long.fromNumber(dataFile.width) : undefined,
    height: dataFile.height ? Long.fromNumber(dataFile.height) : undefined,
    duration: dataFile.duration ? Long.fromNumber(dataFile.duration) : undefined,
    mimeType: dataFile.mimeType,
    type: MCAttachment.Type.DOCUMENT,
    source: {
      source: MCAttachmentSource.Source.BYTESERVER,
      reference: dataFile.fileId,
    },
  };

  if (asDocumentType && !dataFile.audio) {
    return messageRequestData;
  }

  if (dataFile.animation) {
    messageRequestData.type = MCAttachment.Type.GIF;
  } else if (dataFile.image && validateImageFileLimitsToUpload(dataFile)) {
    messageRequestData.type = MCAttachment.Type.IMAGE;
  } else if (dataFile.video) {
    messageRequestData.type = MCAttachment.Type.VIDEO;
  } else if (dataFile.audio) {
    messageRequestData.type = MCAttachment.Type.AUDIO;
  }

  return messageRequestData;
}

export default formatAttachment;
