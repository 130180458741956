import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {animated} from 'react-spring';
import useResizeObserver from 'use-resize-observer';

import Button from 'o-ui/Button';
import {TelegramAuthStageResponse, entities} from '../../../../api/proto';
import Channel from '../../../../stores/Channel';
import ChannelCreationStepType from '../ChannelCreationStepType';
import Telegram2FactorAuthForm from './Telegram2FactorAuthForm';
import TelegramConfirmationForm from './TelegramConfirmationForm';
import TelegramCreationModalHeader from './TelegramCreationModalHeader';
import TelegramCreationResult from './TelegramCreationResult';
import TelegramDefinitionForm from './TelegramDefinitionForm';
import useOpacityTransition from '../../../../hooks/useOpacityTransition';

interface TelegramCreationFormProps {
  title?: string;
  subTitle?: string;
  modalHeader?: boolean;
  reactivationMode?: boolean;
  codeHash?: string | null;
  onChangeStep?: (step: ChannelCreationStepType) => void;
  onClose?: () => void;
  type: entities.OzekonChannelType;
  onChangeType?: (type: entities.OzekonChannelType) => void;
  newChannel?: Channel;
}

export const TelegramCreationForm: React.FC<TelegramCreationFormProps> = observer((props) => {
  const {t} = useTranslation();

  const {ref: innerRef, height} = useResizeObserver<HTMLDivElement>();

  const [newChannel, setNewChannel] = React.useState<Channel | null>(props.newChannel || null);
  const [codeHash, setCodeHash] = React.useState<string | null>(props.codeHash || null);

  const initialStep = props.codeHash ? ChannelCreationStepType.CHANNEL_CONFIRMATION : ChannelCreationStepType.CHANNEL_INFO;

  const [prevStep, setPrevStep] = React.useState<number>(initialStep);
  const [currentStep, setCurrentStep] = React.useState<number>(initialStep);

  const transitions = useOpacityTransition(currentStep, prevStep);

  const openPage = (newPage: number) => {
    setPrevStep(currentStep);
    setCurrentStep(newPage);
    props.onChangeStep?.(newPage);
  };


  const handleChangeDefinitionForm = () => {
    setNewChannel(null);
  };

  const handleChangeType = (type: entities.OzekonChannelType) => {
    setNewChannel(null);
    props.onChangeType?.(type);
  };

  const handleSubmitDefinitionForm = (channel: Channel, codeHash?: string | null) => {
    setNewChannel(channel);
    setCodeHash(codeHash || null);
    openPage(ChannelCreationStepType.CHANNEL_CONFIRMATION);
  };

  const handleSubmitConfirmationForm = (status?: TelegramAuthStageResponse.EnterCode.Result | null) => {
    if (status === TelegramAuthStageResponse.EnterCode.Result.AUTH_SUCCESS) {
      openPage(ChannelCreationStepType.SUCCESS);
    }
    if (status === TelegramAuthStageResponse.EnterCode.Result.PASSWORD_REQUIRED) {
      openPage(ChannelCreationStepType.TWO_FACTOR_AUTH);
    }
  };

  const handleCloseConfirmationForm = () => {
    openPage(ChannelCreationStepType.SUCCESS);
  };

  const renderCreationFormStep = (step: ChannelCreationStepType) => {
    switch (step) {
      case ChannelCreationStepType.CHANNEL_INFO:
        return (
          <>
            {props.modalHeader ? <TelegramCreationModalHeader onClose={props.onClose} /> : null}

            <TelegramDefinitionForm
              title={props.title}
              subTitle={props.subTitle}
              type={props.type}
              newChannel={newChannel}
              onChangeForm={handleChangeDefinitionForm}
              onChangeType={props.onChangeType ? handleChangeType : undefined}
              onSubmit={handleSubmitDefinitionForm}
              reactivationMode={props.reactivationMode}
            />
          </>
        );
      case ChannelCreationStepType.CHANNEL_CONFIRMATION:
        return (
          <>
            {props.modalHeader ? <TelegramCreationModalHeader onClose={props.onClose} /> : null}

            {newChannel ? (
              <TelegramConfirmationForm
                title={props.title}
                subTitle={props.subTitle}
                type={props.type}
                newChannel={newChannel}
                codeHash={codeHash}
                onSubmit={handleSubmitConfirmationForm}
                onClose={handleCloseConfirmationForm}
                reactivationMode={props.reactivationMode}
              />
            ) : null}
          </>
        );
      case ChannelCreationStepType.TWO_FACTOR_AUTH:
        return (
          <>
            {props.modalHeader ? <TelegramCreationModalHeader onClose={props.onClose} /> : null}

            {newChannel ? (
              <Telegram2FactorAuthForm
                title={props.title}
                subTitle={props.subTitle}
                newChannel={newChannel}
                onClose={handleCloseConfirmationForm}
              />
            ) : null}
          </>
        );
      case ChannelCreationStepType.SUCCESS:
        return (
          <>
            {props.modalHeader ? <TelegramCreationModalHeader onClose={props.onClose} /> : null}

            <TelegramCreationResult title={props.title} subTitle={props.subTitle} newChannel={newChannel} />

            {props.onClose ? (
              <div className="channel-dialog-form__footer">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className="channel-dialog-form__cancel-button"
                  onClick={props.onClose}
                >
                  {t('settings_channel_creation_form_close')}
                </Button>
              </div>
            ) : null}
          </>
        );
    }
  };

  return (
    <div
      className={cn('channel-dialog-form', `page-${currentStep}`, {
        reactivation: props.reactivationMode,
        'with-header': props.modalHeader,
        'with-title': props.title,
      })}
    >
      <div
        style={{height: height && height > 40 ? height : undefined}}
        className="channel-dialog-form__body"
      >
        {currentStep !== prevStep ? transitions((styles, step) => (
          <animated.div ref={innerRef} style={styles} className="animated-form">
            {renderCreationFormStep(step)}
          </animated.div>
        )) : (
          <div ref={innerRef} className="animated-form">
            {renderCreationFormStep(currentStep)}
          </div>
        )}
      </div>
    </div>
  );
});

export default TelegramCreationForm;
