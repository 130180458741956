import cn from 'classnames';
import React from 'react';

import maestroImg from '../../../assets/images/maestro.svg';
import mastercardImg from '../../../assets/images/mastercard.svg';
import visaImg from '../../../assets/images/visa.svg';

function getPaymentMethodIcon(type?: string | null) {
  switch (type) {
    case 'visa':
      return visaImg;
    case 'mastercard':
      return mastercardImg;
    case 'maestro':
      return maestroImg;
    default:
      return null;
  }
}

interface PaymentMethodIconProps {
  className?: string;
  type?: string | null;
  bordered?: boolean | null;
}

export const PaymentMethodIcon: React.FC<PaymentMethodIconProps> = ({
  className,
  type,
  bordered,
}) => {
  const src = getPaymentMethodIcon(type);
  if (!src) {
    return null;
  }

  return bordered ? (
    <span
      className={cn('border-primary radius-6 d-inline-flex justify-content-center align-items-center', className)}
      style={{width: 56, height: 32}}
    >
      <img
        src={src}
        alt={type || ''}
        style={{height: type === 'visa' ? 14 : 18}}
      />
    </span>
  ) : (
    <img
      className={className}
      src={src}
      alt={type || ''}
      style={{height: 10}}
    />
  );
};

export default PaymentMethodIcon;