import isNil from '../../../utils/isNil';
import {IRawChat} from '../RawChat';

export default function initDefaultChatFields(currentChat: IRawChat, updates: IRawChat): IRawChat {
  let rawChat = {
    ...updates,
    name: isNil(updates.name) ? currentChat.name || '' : updates.name,
    alternativeName: isNil(updates.alternativeName) ? currentChat.alternativeName || '' : updates.alternativeName,
    showAlternativeName: updates.showAlternativeName || false,
    joinedOperators: isNil(updates.joinedOperators) ? currentChat.joinedOperators || [] : updates.joinedOperators,
    status: isNil(updates.status) ? currentChat.status || null : updates.status,
    statusSetAt: isNil(updates.statusSetAt) ? currentChat.statusSetAt || null : updates.statusSetAt,
    statusSetBy: isNil(updates.statusSetBy) ? currentChat.statusSetBy || null : updates.statusSetBy,
  };

  const messagesExcludedFromUnreadRange = isNil(updates.messagesExcludedFromUnreadRange)
    ? currentChat.messagesExcludedFromUnreadRange || null
    : updates.messagesExcludedFromUnreadRange;

  if (messagesExcludedFromUnreadRange?.greaterThan(0)) {
    rawChat = {
      ...rawChat,
      messagesExcludedFromUnreadRange,
    };
  }

  return rawChat;
}
