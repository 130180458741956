import {MCMethodGetPeer} from '../../../api/proto';
import Chat from '../../Chat';

export default function getChatsDirectPeerKeys(chats: Chat[]): [MCMethodGetPeer.IPeerKey[], Map<string, string>] {
  const peerIdByChatIdMap = new Map<string, string>();
  const keys: MCMethodGetPeer.IPeerKey[] = [];

  chats.forEach((chat) => {
    if (!chat.directChatWithPeer) {
      return;
    }

    peerIdByChatIdMap.set(chat.directChatWithPeer.toString(), chat.id.toString());

    keys.push({
      id: chat.directChatWithPeer,
      channelID: chat.channelID,
    });
  });

  return [keys, peerIdByChatIdMap];
}
