import Long from 'long';
import {generatePath} from 'react-router';

import Paths from '../routes/Paths';
import {base64ToUint8Array, uint8ArrayToBase64} from './arrayUtils';


const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

const localhostDomainRE = /^localhost[\\:?\d]*(?:[^\\:?\d]\S*)?$/
const nonLocalhostDomainRE = /^[^\s\\.]+\.\S{2,}$/;

function isUrl(url?: string | null) {
  if (typeof url !== 'string') {
    return false;
  }

  const match = url.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  if (localhostDomainRE.test(everythingAfterProtocol) ||
    nonLocalhostDomainRE.test(everythingAfterProtocol)) {
    return true;
  }

  return false;
}

export const validURL = (url?: string | null) => {
  if (!url) {
    return false;
  }

  return isUrl(url);
};

export const formatUrl = (url?: string | null): string => {
  if (url) {
    return url.startsWith('http') ? url : `http://${url}`;
  }
  return '';
};

export default formatUrl;

export const formatPhoneNumberUrl = (phoneNumber?: string | null): string => {
  if (phoneNumber) {
    return phoneNumber.startsWith('tel:') ? phoneNumber : `tel:${phoneNumber}`;
  }
  return '';
};

export const formatUrlToMessage = (workspaceId?: Uint8Array | null, channelId?: Uint8Array | null, chatId?: Long | null, messageId?: Long | null): string => {
  const path = generatePath(Paths.SpecificMessage, {
    workspaceId: encodeURIComponent(uint8ArrayToBase64(workspaceId) || ''),
    channelId: encodeURIComponent(uint8ArrayToBase64(channelId) || ''),
    chatId: chatId?.toString() || '',
    messageId: messageId?.toString() || '',
  });
  return `${window.location.origin}${path}`;
};

export const isLocalUrl = (href?: string | null): boolean => {
  if (!validURL(href) || !href) {
    return false;
  }

  return window.location.hostname === new URL(href).hostname;
};

export const isPhoneNumberUrl = (href?: string | null): boolean => {
  return !!href && href.startsWith('tel:');
};


export const encodeInviteCode = (inviteId?: Uint8Array | null): string => {
  return encodeURIComponent(uint8ArrayToBase64(inviteId));
};

export const decodeInviteCode = (inviteCode?: string | null): Uint8Array | null => {
  return inviteCode ? base64ToUint8Array(decodeURIComponent(inviteCode)) : null;
};

export const formatInviteUrl = (inviteId?: Uint8Array | null): string => {
  const path = generatePath(Paths.RegistrationByInvite, {
    code: encodeInviteCode(inviteId),
  });

  return new URL(path, window.location.origin).toString();
};
