import cn from 'classnames';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useTranslation} from 'react-i18next';

import ListItemIcon from 'o-ui/ListItemIcon';
import MenuItem from 'o-ui/Select/MenuItem';

import Paths from '../../../routes/Paths';
import SettingsTab from '../../../stores/LayOutStore/SettingsTab';
import useStore from '../../../stores/useStore';
import {getSettingsMenuIcon} from './SettingsMenuItemIcon';

interface SettingsMenuItemProps {
  tab: SettingsTab;
  onClick?(path: Paths): void;
  className?: string;
  hidden?: boolean;
}

type ItemDataType = {path: Paths; title: string};

const getItemDataObj = (path: Paths, title: string): ItemDataType => ({
  path,
  title,
});

const SettingsMenuItem: React.FC<SettingsMenuItemProps> = observer(({
  tab,
  className,
  hidden,
  onClick,
}) => {
  const {t} = useTranslation();
  const {layOutStore, lightThemeChosen} = useStore();

  const active = layOutStore.activeSettingsTab === tab;

  const dataDictionary: Record<SettingsTab, ItemDataType> = {
    [SettingsTab.BILLING]: getItemDataObj(Paths.Billing, t('settings_menu_billing')),
    [SettingsTab.CHANNELS]: getItemDataObj(Paths.Channels, t('settings_menu_channels')),
    [SettingsTab.MESSAGE_TAGS]: getItemDataObj(Paths.Tags, t('settings_menu_tags')),
    [SettingsTab.PERSONAL_INFO]: getItemDataObj(Paths.PersonalInfo, t('settings_menu_profile')),
    [SettingsTab.SNIPPETS]: getItemDataObj(Paths.Snippets, t('settings_menu_snippets')),
    [SettingsTab.WORKSPACE_MEMBERS]: getItemDataObj(Paths.WorkspaceMembers, t('settings_menu_members')),
    [SettingsTab.WORKSPACE_SETTINGS]: getItemDataObj(Paths.WorkspaceSettings, t('settings_menu_workspace')),
    [SettingsTab.DOWNLOAD_APP]: getItemDataObj(Paths.DownloadApp, t('settings_menu_download_app')),
  };

  const {path, title} = dataDictionary[tab];

  const handleClick = () => {
    onClick?.(path);
  };

  const Icon = getSettingsMenuIcon(tab, lightThemeChosen ? 'light' : 'dark');
  const IconHover = getSettingsMenuIcon(tab, lightThemeChosen ? 'hover' : 'hover_dark');
  const IconActive = getSettingsMenuIcon(tab, 'active');

  return (
    <MenuItem
      className={cn('settings-menu__item', className, {active})}
      selected={active}
      onClick={handleClick}
      hidden={hidden}
    >
      <ListItemIcon className="settings-menu__icon-wrapper">
        <Icon className="settings-menu__icon" />
        <IconHover className="settings-menu__icon settings-menu__icon--hover" />
        <IconActive className="settings-menu__icon settings-menu__icon--active" />
      </ListItemIcon>
      <span className="settings-menu__title nowrap">{title}</span>
    </MenuItem>
  );
});

export default SettingsMenuItem;
