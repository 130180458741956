import cn from 'classnames';
import copy from 'copy-to-clipboard';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

// import Switch from 'o-ui/Switch';
import Peer from '../../../../stores/Peer';
import useStore from '../../../../stores/useStore';
import {formatPhoneNumber} from '../../../../utils/getPhoneMask';
import PeerExtraInfoAdditional from './PeerExtraInfoAdditional';

interface PeerExtraInfoProps {
  className?: string;
  peer: Peer;
}

export const PeerExtraInfo: React.FC<PeerExtraInfoProps> = observer(({
  peer,
  className,
}) => {
  const {t} = useTranslation();
  const {notifications} = useStore();
  const {chat} = peer;
  const chatLink = chat?.link || '';

  const handleCopyLink = () => {
    copy(chatLink);
    notifications.action(t('chat_user_chat_info_invite_link_copied'));
  };

  // const handleToggle = () => {
  //   if (props.user.chat?.store?.notificationsIsDisabled) {
  //     chat?.store?.updateMutedSetting(false);
  //   } else {
  //     chat?.store?.updateMutedSetting(true);
  //   }
  // };

  return (
    <div className={cn('chat-extra-info', className)}>
      <table className="chat-extra-info__table">
        <tbody>
          <tr>
            <td className="align-top">
              <i className="chat-extra-info__icon tg-icon tg-icon-user-fill" />
            </td>
            <td className="align-top">
              <span className="chat-extra-info__name">{t('chat_user_chat_info_name_title')}</span>
            </td>
            <td className="align-top">
              <div className="chat-extra-info__value two-rows">
                <div>{peer.fullName}</div>
              </div>
            </td>
          </tr>

          {peer.username ? (
            <tr>
              <td className="align-top">
                <i className="chat-extra-info__icon tg-icon tg-icon-at-line" />
              </td>
              <td className="align-top">
                <span className="chat-extra-info__name">{t('chat_user_chat_info_username_title')}</span>
              </td>
              <td className="align-top">
                <span className="chat-extra-info__value">@{peer.username}</span>
              </td>
            </tr>
          ) : null}
          {chatLink ? (
            <tr>
              <td className="align-top">
                <i className="chat-extra-info__icon tg-icon tg-icon-telegram-fill" />
              </td>
              <td className="align-top">
                <span className="chat-extra-info__name">{t('chat_user_chat_info_invite_link')}</span>
              </td>
              <td className="align-top">
                <span className="chat-extra-info__link cursor-pointer" onClick={handleCopyLink}>
                  {chatLink}
                </span>
              </td>
            </tr>
          ) : null}
          {peer.phoneNumber ? (
            <tr>
              <td className="align-top">
                <i className="chat-extra-info__icon tg-icon-phone-fill" />
              </td>
              <td className="align-top">
                <span className="chat-extra-info__name">{t('chat_user_chat_info_phone_title')}</span>
              </td>
              <td className="align-top">
                <span className="chat-extra-info__value">{formatPhoneNumber(peer.phoneNumber)}</span>
              </td>
            </tr>
          ) : null}
          {peer.email ? (
            <tr>
              <td className="align-top">
                <i className="chat-extra-info__icon tg-icon-email-fill" />
              </td>
              <td className="align-top">
                <span className="chat-extra-info__name">{t('chat_user_chat_info_email_title')}</span>
              </td>
              <td className="align-top">
                <span className="chat-extra-info__value">{peer.email}</span>
              </td>
            </tr>
          ) : null}

          {/*chat ? (
            <tr>
              <td>
                <i className="chat-extra-info__icon tg-icon tg-icon-bell-fill" />
              </td>
              <td>
                <span className="chat-extra-info__name">{t('Notifications')}</span>
              </td>
              <td className="chat-extra-info__col-switcher">
                <Switch
                  className="small-switcher ml-auto"
                  checked={!chat.store?.notificationsIsDisabled}
                  onChange={handleToggle}
                />
              </td>
            </tr>
          ) : null*/}
        </tbody>
      </table>

      <PeerExtraInfoAdditional peer={peer} />
    </div>
  );
});

export default PeerExtraInfo;
