import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react-lite';

import IconButton from 'o-ui/Button/IconButton';
import {useStore} from '../../../../../stores/AppStore';
import {IRawChat} from '../../../../../stores/RawChatsStore/RawChat';
import WorkspaceMemberAccessRecord from '../../../../../stores/Workspaces/WorkspaceMemberAccessRecord';
import ChatChannelTypeIcon from '../../../../Chat/Tabs/ChatsTab/ChatItem/ChatChannelTypeIcon';
import AvatarWrapper from '../../../../Chat/AvatarWrapper';


interface ChatsAccessItemProps {
  chat: IRawChat;
  accessRecord: WorkspaceMemberAccessRecord;
  style?: React.CSSProperties;
}

const ChatsAccessItem: React.FC<ChatsAccessItemProps> = observer(({chat, accessRecord, style}) => {
  const {activeWorkspace: {channels}} = useStore();

  const handleDeleteClick = () => {
    accessRecord.removeChat(chat.id);
  };

  return (
    <div
      className={cn(
        'chats-access-item',
        {
          disabled: accessRecord.disableChannel,
        }
      )}
      style={style}
    >
      <div className="chats-access-item__body">
        <AvatarWrapper
          className="chats-access-item__avatar mr-5"
          size="xs"
          attachment={chat?.avatar}
          channelID={chat?.channelID}
          name={chat.name}
        />
        <div className="chats-access-item__data overflow-hidden">
          <div className="chats-access-item__username text-truncate font-size-14 mb-2">
            {chat.name}
            <ChatChannelTypeIcon
              className="chats-access-item__icon ml-2"
              type={channels.getType(chat.channelID)}
            />
          </div>
        </div>
        <IconButton
          className="chats-access-item__delete tg-icon-delete-bin-line font-size-18"
          size="small"
          onClick={handleDeleteClick}
          disabled={!!accessRecord.disableChannel}
        />
      </div>
    </div>
  );
});

export default ChatsAccessItem;
