import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import SearchInput from 'o-ui/Input/SearchInput';
import {SEARCH_TEXT_LENGTH_LIMIT} from '../../../../constants';
import useStore from '../../../../stores/useStore';
import ChatsFilter from './ChatsFilter';
import FoundTagItem from './FoundTagItem';

export const ChatsTabHead = observer(() => {
  const {chatsView} = useStore();
  const {t} = useTranslation();

  React.useEffect(() => {
    return () => chatsView.search.onBlurSearchField();
  }, [chatsView.search]);

  return (
    <ChatsFilter>
      <SearchInput
        placeholder={t('chat_chats_tab_head_search_placeholder')}
        value={chatsView.search.searchText}
        onChange={chatsView.searchInActiveChannel}
        maxLength={SEARCH_TEXT_LENGTH_LIMIT}
        autoFocus={!!chatsView.search.chatForSearch}
        onFocus={chatsView.search.onFocusSearchField}
        onBlur={chatsView.search.onBlurSearchField}
        className={cn('chats-tab__head-search', {
          tagForSearch: !!chatsView.search.tagForSearch,
        })}
        delay={600}
      />
      {chatsView.search.tagForSearch ? (
        <FoundTagItem
          className="chats-tab__head-tag-search"
          tag={chatsView.search.tagForSearch}
          onDeleteClick={chatsView.search.resetTagForSearch}
        />
      ) : null}
    </ChatsFilter>
  );
});

export default ChatsTabHead;
