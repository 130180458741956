import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import errorIcon from '../../../../assets/images/error-square-with-triangle.svg';
import useStore from '../../../../stores/useStore';

interface PaymentFailingInfoProps {
  className?: string;
}

export const PaymentFailingInfo: React.FC<PaymentFailingInfoProps> = observer(({className}) => {
  const {t} = useTranslation();
  const {activeWorkspace: {billing}} = useStore();

  return (
    <div
      className={cn(
        'd-flex flex-row px-6 py-5',
        className,
      )}
    >
      <img className="mr-5" src={errorIcon} alt="" />

      <div className="d-flex flex-column justify-content-between">
        <div className="h5-bold">
          {t('settings_billing_subscription_cancelled_due_non_payment')}
        </div>

        <div className="body2-regular">
          {t('settings_billing_subscription_cancelled_update_payment')}
        </div>
      </div>

      <div className="ml-auto d-flex align-items-center">
        <Button
          className="text-capitalize"
          variant="contained"
          color="primary"
          size="large"
          onClick={billing.openCustomerPortal}
          loading={billing.openCustomerPortalInProgress}
        >
          {t('settings_billing_button_update_payment_method')}
        </Button>
      </div>
    </div>
  );
});

export default PaymentFailingInfo;