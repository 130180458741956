import {observer} from 'mobx-react';
import React from 'react';

import {IMCTextEntities} from '../../../../api/proto';
import {useStore} from '../../../../stores/AppStore';
import {trimMentionSymbol} from '../../../../stores/EditorStore';
import {uint8ArrayToString} from '../../../../utils/arrayUtils';

interface IProps {
  children?: React.ReactNode;
  entity: IMCTextEntities;
  text?: string;
}

export const MentionNameEntity: React.FC<IProps> = observer(({children, entity, text}) => {
  const {
    chatsView,
    layOutStore: {rightSideBar},
  } = useStore();

  const onClick = async () => {
    console.debug(`-> MentionNameEntity.onClick payload=${uint8ArrayToString(entity.payload)} text=${text}`);

    const username = uint8ArrayToString(entity.payload);
    if (username) {
      const peer = await chatsView.activeUsersChat?.store?.peers?.findByUsername(username);
      rightSideBar.setPeer(peer);
    } else if (text) {
      const username = trimMentionSymbol(text);
      const peer = await chatsView.activeUsersChat?.store?.peers?.findByUsername(username);
      rightSideBar.setPeer(peer);
    }
  };

  return (
    <span className="mention-name-link" onClick={onClick}>
      {children}
    </span>
  );
});

export default MentionNameEntity;
