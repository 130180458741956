import {observer} from 'mobx-react';
import React from 'react';
import setCurrentLogo from './setCurrentLogo';
import useStore from '../../stores/useStore';

const DEFAULT_LOGO = '/logo.png';
const UNREAD_LOGO = '/logo-unread.png';


export const LogoSwitcher: React.FC = observer(() => {
  const {hasUnread} = useStore();

  if (hasUnread) {
    setCurrentLogo(UNREAD_LOGO);
  } else if (!hasUnread) {
    setCurrentLogo(DEFAULT_LOGO);
  }

  return null;
});

export default LogoSwitcher;