import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';

import {
  IMCAttachment,
  IMCChatRestriction,
  IMCMessagePreview,
  IMCMessageSuggestion,
  IMeta,
  MCChat,
  MCChatPreview,
} from '../../api/proto';
import {TELEGRAM_PUBLIC_CHAT_LINK} from '../../config';
import {Channel} from '../Channel';
import {IRawChat} from '../RawChatsStore/RawChat';

export class ChatBase implements IRawChat {
  constructor(public raw: IRawChat, public channel: Channel) {
    makeObservable(this);
    this.assign_(this, raw);
  }

  @observable id: Long = Long.ZERO;
  @observable channelID: Uint8Array = new Uint8Array();
  @observable externalID?: Uint8Array | null;
  @observable name?: string | null;
  @observable username?: string | null;
  @observable alternativeName?: string | null;
  @observable showAlternativeName?: boolean | null;
  @observable avatar?: IMCAttachment | null;
  @observable inReadPointer?: Long | null;
  @observable outReadPointer?: Long | null;
  @observable messagesExcludedFromUnreadRange?: Long | null;
  @observable lastMessageSentAt?: Long | null;
  @observable lastMessage?: IMCMessagePreview | null;
  @observable inviteLink?: string | null;

  @observable draft?: string | null;
  @observable isMuted?: boolean | null;
  @observable type?: MCChatPreview.Type | null;

  @observable privileges?: MCChat.IPrivileges | null;
  @observable attachmentGroupSizes?: MCChat.IAttachmentGroupSize[] | null;

  @observable restrictions?: IMCChatRestriction[] | null;

  @observable status?: MCChat.MCChatStatus | null | undefined;
  @observable statusSetBy?: Long | null;
  @observable statusSetAt?: Long | null;

  @observable joinedOperators?: Long[] | null | undefined;

  @observable directChatWithPeer?: Long | null;

  @observable meta?: (IMeta | null);
  @observable nextOperatorMessageSuggestion?: IMCMessageSuggestion | null;

  @action protected assign_ = (chat: ChatBase, props: IRawChat) => {
    Object.assign(chat, props);
  };

  @computed get userLink() {
    return this.username ? `${TELEGRAM_PUBLIC_CHAT_LINK}${this.username}` : null;
  }

  @computed get link(): string {
    return this.userLink || this.inviteLink || '';
  }

  @computed get open() {
    return this.status === MCChat.MCChatStatus.S_OPEN;
  }

  @computed get inProgress() {
    return this.status === MCChat.MCChatStatus.S_IN_PROGRESS;
  }

  @computed get resolved() {
    return this.status === MCChat.MCChatStatus.S_RESOLVED;
  }
}

export default ChatBase;
