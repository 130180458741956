
export enum PageType {
  Main = 'Main',
  Onboarding = 'Onboarding',
  Entrance = 'Entrance',
  Product = 'Product',
  ProductSettings = 'Product.Settings',
  ProductBilling = 'Product.Billing',
  Cart = 'Cart',
  Checkout = 'Checkout',
  ThankYouPage = 'ThankYouPage',
  HelpCenter = 'HelpCenter',
  Blog = 'Blog',
  Info = 'Info',
  Error = 'Error',
}

export default PageType;