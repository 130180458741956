import React from 'react';

import Chat from '../../../../stores/Chat';
import ChatMessagesList from '../ChatMessagesList';
import ChatMessagesDayTitle from './ChatMessagesDayTitle';
import ChatMessagesLoader from './ChatMessagesLoader';
import ChatMessagesScrollDown from './ChatMessagesScrollDown';


interface ChatMessagesWindowProps {
  chat: Chat;
}

export const ChatMessagesWindow = React.memo((props: ChatMessagesWindowProps) => {
  // console.debug(`---->render ChatMessagesWindow`);

  return (
    <div className="chat-conversation w-100 bg-background-secondary">
      <ChatMessagesLoader chat={props.chat} />

      <ChatMessagesList chat={props.chat} />

      <ChatMessagesScrollDown chat={props.chat} />
      <ChatMessagesDayTitle chat={props.chat} />
    </div>
  );
});

export default ChatMessagesWindow;
