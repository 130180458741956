import React from 'react';
import {useLocation} from 'react-router-dom';

import Div100vh from '../components/Div100vh';
import ChatLeftSidebar from '../pages/Chat/ChatLeftSidebar';
import SideMenu from '../pages/Chat/SideMenu';
import useStore from '../stores/useStore';
import AuthSideMenuLayoutWrapper from './AuthSideMenuLayoutWrapper';


const AuthSideMenuLayout: React.FC<React.PropsWithChildren> = React.memo(({children}) => {
  const location = useLocation();
  const {anal, workspaces} = useStore();

  React.useEffect(() => {
    if (workspaces.isInit) {
      console.debug('anal.AuthSideMenuLayout', location.pathname);
      anal.openRoute(location.pathname);
    }
  }, [
    anal,
    location.pathname,
    workspaces.isInit,
  ]);

  return (
    <AuthSideMenuLayoutWrapper>
      <Div100vh className="chat-layout">
        <SideMenu />
        <ChatLeftSidebar />
        {children}
      </Div100vh>
    </AuthSideMenuLayoutWrapper>
  );
});

export default AuthSideMenuLayout;
