import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import {BRAND_NAME} from '../../../../config';
import useWorkspaceNavigate from '../../../../hooks/useWorkspaceNavigate';
import Paths from '../../../../routes/Paths';
import useStore from '../../../../stores/useStore';

export const BlockingInfo: React.FC = observer(() => {
  const {t} = useTranslation();

  const {activeWorkspace, userStore} = useStore();
  const {billing} = activeWorkspace;

  const workspaceNavigate = useWorkspaceNavigate();

  const handlePayNowClick = () => {
    workspaceNavigate(Paths.Billing);
  };

  if (billing.trialEnabled && billing.currentTariffExpired) {
    return userStore.isOperator ? (
      <>
        <div className="h4-medium">
          {t('chat_blocking_trial_period_is_over')}
        </div>
        <div className="body2-regular mt-3">
          {t('chat_blocking_wait_for_access')}
        </div>
      </>
    ) : (
      <>
        <div className="h4-medium">
          {t('chat_blocking_trial_period_is_over')}
        </div>
        <div className="body2-regular mt-3">
          {t('chat_blocking_you_need_to_pay', {brandName: BRAND_NAME})}
        </div>

        <div className="mt-6">
          <Button
            variant="contained"
            size="large"
            onClick={handlePayNowClick}
          >
            {t('chat_blocking_pay_now_button')}
          </Button>
        </div>
      </>
    );
  }

  if (billing.currentTariffExpired) {
    return userStore.isOperator ? (
      <>
        <div className="h4-medium">
          {t('chat_blocking_paid_period_is_over')}
        </div>
        <div className="body2-regular mt-3">
          {t('chat_blocking_wait_for_access')}
        </div>
      </>
    ) : (
      <>
        <div className="h4-medium">
          {t('chat_blocking_paid_period_is_over')}
        </div>
        <div className="body2-regular mt-3">
          {t('chat_blocking_you_need_to_pay', {brandName: BRAND_NAME})}
        </div>

        <div className="mt-6">
          <Button
            variant="contained"
            size="large"
            onClick={handlePayNowClick}
          >
            {t('chat_blocking_pay_now_button')}
          </Button>
        </div>
      </>
    );
  }

  return (
    <div className="h4-medium">
      {t('chat_blocking_info')}
    </div>
  );
});

export default BlockingInfo;