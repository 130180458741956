import React, {useRef} from 'react';
import {ReactComponent as AudioProgress} from '../../assets/image-icons/audioProgress.svg';


export interface IProps {
  played: number;
  playedSeconds: number;
  onProgressClick?: (fraction: number) => void;
}

export const AudioPlayerProgressBar: React.FC<IProps> = (props) => {
  const progressLineRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: React.MouseEvent) => {
    if (progressLineRef.current) {
      const percentWidth = progressLineRef.current.clientWidth / 100;
      const rect = progressLineRef.current.getBoundingClientRect();
      const clickDistance = e.clientX - rect.left;
      const fraction = Math.round(clickDistance / percentWidth) / 100;
      props.onProgressClick?.(fraction);
    }
  };

  return (
    <div className="audio-player__progress-bar">
      <div className="audio-player__progress-line" ref={progressLineRef} onClick={handleClick}>
        <AudioProgress className="audio-player__progress-img" />
        <span
          className="audio-player__progress-bg"
          style={{width: (props.played * 100).toFixed(2) + '%'}}
        >
          <AudioProgress className="audio-player__progress-img-over" />
        </span>
      </div>
    </div>
  );
};

export default AudioPlayerProgressBar;
