import React from 'react';
import cn from 'classnames';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import ProfileShortInfo from './ProfileShortInfo';
import SettingsHeadTitle from './SettingsHeadTitle';
import {Paths} from '../../routes/Paths';
import {ReactComponent as LogoutIcon} from '../../assets/image-icons/logout.svg';
import Button from 'o-ui/Button';

export interface SettingsHeadState {
  title?: string;
  links?: string[];
  onBack?: () => void;
  onToggleMenu?: () => void;
}

export interface SettingsHeadProps extends SettingsHeadState {
  className?: string;
}

export const SettingsHead = (props: SettingsHeadProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const handleLogout = () => {
    navigate(Paths.Logout);
  };

  return (
    <div className={cn('settings-tab__head border-bottom', props.className)}>
      <div className="settings-tab__head-left">
        <SettingsHeadTitle
          onToggleMenu={props.onToggleMenu}
          title={props.title}
          links={props.links}
          onBack={props.onBack}
        />
      </div>
      <div className="settings-tab__head-right border-left pl-6 d-none d-sm-flex">
        <ProfileShortInfo />

        <Button
          className="settings-page__logout my-auto ml-5 d-sm-block d-none"
          variant="contained"
          color="errorSecondary"
          onClick={handleLogout}
        >
          <LogoutIcon className="settings-page__logout-icon" />
          {t('settings_head_logout')}
        </Button>
      </div>
    </div>
  );
};

export default SettingsHead;
