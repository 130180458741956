import {useTransition} from 'react-spring';

export default function useOpacityTransition(pageIndex: number, prevPageIndex?: number) {
  const transitions = useTransition(pageIndex, {
    from:
      prevPageIndex === pageIndex
        ? {}
        : {
          opacity: 0,
        },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
    //config: {duration: 3000},
  });

  return transitions;
}