//import copy from 'copy-to-clipboard';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import FormLabel from 'o-ui/FormLabel';
//import Icon from 'o-ui/Icon';
//import CopyTextInput from 'o-ui/Input/CopyTextInput';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import Select from 'o-ui/Select';

import {getUserRoles} from '../../../../stores/Workspaces';
import WorkspaceMember from '../../../../stores/Workspaces/WorkspaceMember';
import ChannelsAndChatsAccess from '../ChannelsAndChatsAccess';
import MemberStatus from '../MemberStatus';


interface IProps {
  member: WorkspaceMember;
  setDisabledSubmit?: React.Dispatch<React.SetStateAction<boolean>>;
  onBeforeSubmit?: () => void;
  onSubmit?: (success: boolean) => void;
}

export interface IInviteManageFormRef {
  submitForm?: () => void;
}

export const InviteManageForm = observer((props: IProps) => {
  const {t} = useTranslation();
  const rolesList = getUserRoles();

  return (
    <div className="operator-manage w-100">
      <div className="operator-manage__left">
        <div className="operator-manage__form">
          {/*
          <p className="m-0">[Development mode]: Invite URL</p>
          <CopyTextInput
            copyBtnIcon={<Icon className="tg-icon-copy-line icon-size-20" />}
            type="text"
            className="w-100 mb-5"
            value={props.member.emailInviteUrl}
            onCopyValue={(value) => {
              copy(value || '', {
                format: 'text/plain',
              });
            }}
          />
          */}
          <div>
            <FormLabel className="w-100">{t('settings_workspace_members_manage_status')}</FormLabel>
          </div>
          <div className="d-flex align-items-center">
            <MemberStatus member={props.member} />
          </div>

          <FormLabel>{t('settings_workspace_members_manage_email')}</FormLabel>
          <OutlinedTextInput className="w-100" name="email" value={props.member.email} disabled errorHelper={' '} />

          <Select
            name="role"
            className="w-100"
            label={t('settings_workspace_members_manage_role')}
            value={props.member.role?.toString()}
            items={rolesList}
            required
            disabled
          />
        </div>
      </div>

      <div className="operator-manage__right">
        <div className="operator-manage__chats-access-container">
          <ChannelsAndChatsAccess memberConfig={props.member.config} disabled={true} />
        </div>
      </div>
    </div>
  );
});

export default InviteManageForm;
