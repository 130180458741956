import React from 'react';
import {useLocation} from 'react-router';

import SettingsTab from '../../stores/LayOutStore/SettingsTab';
import {getSettingsTabByPath} from '../../stores/LayOutStore/utils/getSettingsTabByPath';

export const useAllowChangeHeadState = (settingsTab: SettingsTab) => {
  const location = useLocation();

  return React.useMemo(() => {
    return getSettingsTabByPath(location.pathname) === settingsTab;
  }, [settingsTab, location.pathname]);
};

export default useAllowChangeHeadState;
