import cn from 'classnames';
import React from 'react';

interface UnreadCounterIconProps {
  count?: number;
}

const UnreadCounterIcon: React.FC<UnreadCounterIconProps> = ({count}) => {
  if (!count || count < 0) {
    return null;
  }

  const stringCount = count.toString();
  const slicedCount = stringCount.length > 4 ? `${stringCount.slice(0, 4)}+` : stringCount;

  return (
    <div
      className={cn(
        'channels-menu__unread-counter channels-menu__unread-counter--item',
        `digits-${slicedCount.length}`,
      )}
    >
      {slicedCount}
    </div>
  );
};

export default UnreadCounterIcon;
