import React from 'react';
import cn from 'classnames';

import {getUserRoleTitle} from '../stores/Workspaces';
import {entities} from '../api/proto';

interface Props {
  role?: entities.WorkspaceRole | null;
  className?: string;
}

const SystemUserRoleLabel: React.FC<Props> = (props) => {
  const label = getUserRoleTitle(props.role);
  const roleType = {
    [entities.WorkspaceRole.WR_ADMIN]: 'admin',
    [entities.WorkspaceRole.WR_OWNER]: 'owner',
    [entities.WorkspaceRole.WR_OPERATOR]: 'operator',
    [entities.WorkspaceRole.WR_NONE]: 'system',
  };

  if (props.role === undefined) {
    return null;
  }

  return (
    <span className={cn('system-user-role-label', `system-user-role-label--${props.role ? roleType[props.role] : undefined}`, props.className)}>
      {label}
    </span>
  );
};

export default SystemUserRoleLabel;
