import cn from 'classnames';
import React from 'react';

import {DEFAULT_MEDIA_PREVIEW_HEIGHT, DEFAULT_MEDIA_PREVIEW_WIDTH, EMPTY_IMG_BLUE} from '../../../../constants';
import PreviewImage from '../../../../components/PreviewImage';
import {MAX_VIDEO_AUTOPLAY_BYTES} from '../../../../config';
import useMaxMediaSize from '../../../../hooks/useMaxMediaSize';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import ModalType from '../../../../stores/ModalType';
import useStore from '../../../../stores/useStore';
import {uint8ArrayToUuid} from '../../../../utils/arrayUtils';
import VideoInlinePlayer from './VideoInlinePlayer';
import useMessageAttachment from './useMessageAttachment';

export interface VideoContentProps {
  className?: string;
  attachment: MessageAttachment;
  autoSize?: boolean;
  autoPlay?: boolean;
  inlinePlayer?: boolean;
  isScrolling?: boolean;
  playButtonSmall?: boolean;
  playButton?: boolean;
  style?: React.CSSProperties;
}

export const VideoContent = (props: VideoContentProps) => {
  const {activeWorkspace, modals} = useStore();

  const {message, messagePreview} = props.attachment;
  const cachedAttachment = activeWorkspace.attachmentStore.getFromCache(props.attachment?.source?.reference, message.chat.channelID);
  const autoplayAvailable = !!props.inlinePlayer && (!!cachedAttachment.src || !!props.attachment.size?.lessThan(MAX_VIDEO_AUTOPLAY_BYTES));

  const [inView, setInView] = React.useState<boolean>(false);

  const attachment = messagePreview && !(autoplayAvailable && inView) ? messagePreview : props.attachment;
  const {src, width, height, duration} = useMessageAttachment(attachment, !!(messagePreview || (autoplayAvailable && inView)));
  const {maxWidth, maxHeight} = useMaxMediaSize();

  const openLightbox = () => {
    modals.open(
      ModalType.MEDIA_LIGHT_BOX,
      {
        attachment: props.attachment,
      },
      false,
      true,
    );
  };

  return autoplayAvailable ? (
    <VideoInlinePlayer
      className={cn(
        'message-video__video',
        'cursor-pointer',
        props.className,
        uint8ArrayToUuid(props.attachment.id),
        {
          'message-video__video--empty': true,
        },
      )}
      src={src}
      duration={duration}
      width={width || DEFAULT_MEDIA_PREVIEW_WIDTH}
      height={height || DEFAULT_MEDIA_PREVIEW_HEIGHT}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      fixedSize={true}
      isScrolling={props.isScrolling}
      onClick={message.isDelivered ? openLightbox : undefined}
      onInViewChange={setInView}
      playButton={props.playButton}
      autoPlay={props.autoPlay}
      style={props.style}
    />
  ) : (
    <PreviewImage
      className={cn(
        'message-img__img',
        'message-video__video',
        'cursor-pointer',
        props.className,
        uint8ArrayToUuid(props.attachment.id),
        {
          'message-video__video--empty': true,
        },
      )}
      src={src || EMPTY_IMG_BLUE}
      width={width || DEFAULT_MEDIA_PREVIEW_WIDTH}
      height={height || DEFAULT_MEDIA_PREVIEW_HEIGHT}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      fixedSize={true}
      isScrolling={props.isScrolling}
      onClick={message.isDelivered ? openLightbox : undefined}
      onInViewChange={setInView}
      playButton={props.playButton}
      style={props.style}
    />
  );
};

export default VideoContent;
