import Long from 'long';
import moment from 'moment';

//const TIME_FORMAT = 'HH:mm:ss';
const TIME_FORMAT = 'HH:mm';
const DATE_FORMAT = 'D.MM.YY';
//const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const FILES_DATE_TIME_FORMAT = 'DD MMM, YYYY [at] HH:mm';
export const PLAYER_DATE_TIME_FORMAT = 'MMMM DD [at] HH:mm';
export const BILLING_DATE_FORMAT = 'MMMM DD, yyyy';
export const BILLING_DATE_TIME_FORMAT = 'MMMM DD, yyyy HH:mm';

export const parseUnixStamp = (stamp?: Long | null): moment.Moment => {
  return moment.utc(stamp?.toNumber() || 0, 'X').local();
};

export const formatTime = (stamp?: Long | null): string => {
  return parseUnixStamp(stamp).format(TIME_FORMAT);
};

export const formatDateTime = (stamp?: Long | null, isSecondsStamp: boolean = false, format: string = DATE_TIME_FORMAT): string => {
  const stamp_ = isSecondsStamp ? (stamp?.toNumber() || 0) * 1000 : stamp?.toNumber();
  return moment(stamp_).format(format);
};

export const getDateOrTime = (stamp?: Long | null): string => {
  const targetTime = parseUnixStamp(stamp);
  // const diff = new Date().getTime() - targetTime.valueOf();
  // return diff > 24 * 60 * 60 * 1000 ? targetTime.format(DATE_FORMAT) : targetTime.format(TIME_FORMAT);

  return targetTime.isSame(moment(), 'day') ? targetTime.format(TIME_FORMAT) : targetTime.format(DATE_FORMAT);
};

export const getTimeDiff = (stamp?: Long | null): number => {
  return moment().diff(moment.utc(stamp?.toNumber() || 0));
};

export const getSecondsDiff = (stamp?: Long | null): number => {
  return getTimeDiff(stamp) / 1000;
};

const addZero = (value: number): string => {
  return value < 10 ? `0${value}` : `${value}`;
};

export const formatDuration = (ms: number): string => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const hours = Math.floor((ms % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  const minutes = Math.floor((ms % (60 * 60 * 1000)) / (60 * 1000));
  const seconds = Math.floor((ms % (60 * 1000)) / 1000);

  if (days) {
    return `${days}d, ${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`;
  }

  if (hours) {
    return `${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`;
  }

  return `${addZero(minutes)}:${addZero(seconds)}`;
};

export const getFormattedDuration = (stamp?: Long | null): string => {
  const d = getTimeDiff(stamp);
  return formatDuration(d < 0 ? -1 * d : d);
};

export const niceBytes = (size?: number | null): string => {
  let n = size || 0;
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};

export const formatUserName = (firstName: string | null = '', lastName: string | null = ''): string => {
  return `${firstName || ''} ${lastName || ''}`.trim();
};
