import {makeObservable} from 'mobx';

import {
  IMCChannelShownFields,
  IMCMethodResponse,
  MCChannelAuthFlow,
  MCCreateChannel,
  MCDropChannelResponse,
  MCMethod,
  TelegramAuthStage,
  TelegramAuthorization,
  TelegramChannelState,
  api,
  entities,
} from '../../../api/proto';
import i18n from '../../../i18n';
import {APILayer} from '../../APILayer';
import TimerStore from '../../TimerStore';
import Channel from '../Channel';
import ChannelsStore from '../ChannelsStore';

export const getTgChannelStateTitle = (state?: TelegramChannelState.State | null): string => {
  switch (state) {
    case TelegramChannelState.State.CREATE_CHANNEL:
    case TelegramChannelState.State.ENTER_CODE:
    case TelegramChannelState.State.ENTER_NUMBER:
    case TelegramChannelState.State.ENTER_PASSWORD:
      return i18n.t('settings_channel_manage_status_authorization');
    case TelegramChannelState.State.EVENT_LISTENING:
      return i18n.t('settings_channel_manage_status_launch');

    default:
      return '-';
  }
};

export class Telegram extends APILayer {
  constructor(public channels: ChannelsStore) {
    super(channels.workspace.app);
    makeObservable(this);
  }

  createChannel = async (data: {phone: string; shownFields?: IMCChannelShownFields | null}) => {
    if (this.loading) {
      return {error: {message: 'In process'}, res: null};
    }

    const {error, res} = await this.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.channels.workspace.id,
          action: new MCMethod({
            createChannel: new MCCreateChannel({
              type: entities.OzekonChannelType.OIT_TELEGRAM,
              shownFields: data.shownFields,
              telegramAuthFlow: new TelegramAuthorization({
                auth: new TelegramAuthStage({
                  enterPhone: new TelegramAuthStage.EnterPhone({
                    phone: data.phone,
                  }),
                }),
              }),
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    const createChannelRes = res?.methodResponse?.createChannel;

    let newChannel: Channel | null = null;

    if (createChannelRes) {
      newChannel = new Channel(
        {
          channelID: createChannelRes?.channelID,
          shownFields: data.shownFields,
          type: entities.OzekonChannelType.OIT_TELEGRAM,
          workspaceID: this.channels.workspace.id,
          state: {
            identifiers: {
              phone: data.phone,
            },
          },
        },
        this.channels.workspace,
      );

      this.channels.workspace.refreshMembers();
      this.channels.workspace.app.anal.channelSetupEvent(newChannel);
    }

    this.processReloadChannels_(res?.methodResponse);

    return {error, res: createChannelRes, channel: newChannel};
  };

  public resendAuthCodeTimer = new TimerStore();

  resendAuthCode = async (data: {channel: Channel; codeHash: string}) => {
    if (this.loading) {
      return {error: {message: 'In process'}, res: null};
    }

    this.resendAuthCodeTimer.run();

    const {error, res} = await this.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.channels.workspace.id,
          action: new MCMethod({
            authFlow: new MCChannelAuthFlow({
              channelID: data.channel.id,
              telegramAuthFlow: new TelegramAuthorization({
                auth: new TelegramAuthStage({
                  resendCode: new TelegramAuthStage.ResendCode({
                    codeHash: data.codeHash,
                    phone: data.channel.phone,
                  }),
                }),
              }),
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    return {error, res: res?.methodResponse?.authFlow?.telegramAuthFlowResponse?.auth?.resendCode};
  };

  sendAuthCode = async (data: {channel: Channel; code: string; codeHash: string}) => {
    const {error, res} = await this.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.channels.workspace.id,
          action: new MCMethod({
            authFlow: new MCChannelAuthFlow({
              channelID: data.channel.id,
              telegramAuthFlow: new TelegramAuthorization({
                auth: new TelegramAuthStage({
                  enterCode: new TelegramAuthStage.EnterCode({
                    phone: data.channel.phone,
                    codeHash: data.codeHash,
                    code: data.code,
                  }),
                }),
              }),
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    this.processReloadChannels_(res?.methodResponse);

    return {error, res: res?.methodResponse?.authFlow?.telegramAuthFlowResponse?.auth?.enterCode};
  };

  sendPassword = async (data: {channel: Channel; password: string}) => {
    const {error, res} = await this.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.channels.workspace.id,
          action: new MCMethod({
            authFlow: new MCChannelAuthFlow({
              channelID: data.channel.id,
              telegramAuthFlow: new TelegramAuthorization({
                auth: new TelegramAuthStage({
                  enterPassword: new TelegramAuthStage.EnterPassword({
                    password: data.password,
                  }),
                }),
              }),
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    this.processReloadChannels_(res?.methodResponse);

    return {error, res: res?.methodResponse?.authFlow?.telegramAuthFlowResponse?.auth?.enterPassword};
  };

  reactivateChannel = async (data: {channel: Channel; phone: string}) => {
    if (this.loading) {
      return {error: {message: 'In process'}, res: null};
    }

    const {error, res} = await this.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.channels.workspace.id,
          action: new MCMethod({
            authFlow: new MCChannelAuthFlow({
              channelID: data.channel.id,
              resetAuthFlow: true,
              telegramAuthFlow: new TelegramAuthorization({
                auth: new TelegramAuthStage({
                  enterPhone: new TelegramAuthStage.EnterPhone({
                    phone: data.channel.phone,
                  }),
                }),
              }),
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    this.processReloadChannels_(res?.methodResponse);

    return {
      error,
      res: res?.methodResponse?.authFlow?.telegramAuthFlowResponse?.auth?.enterPhone,
    };
  };

  private processReloadChannels_ = (methodResponse?: IMCMethodResponse | null) => {
    if (methodResponse?.createChannel?.result !== MCDropChannelResponse.Result.R_INTERNAL_SERVER_ERROR) {
      this.channels.load();
    }
  };
}

export default Telegram;
