class Tooltip {
  root;

  constructor(quill, boundsContainer) {
    this.quill = quill;
    this.boundsContainer = boundsContainer || document.body;
    this.root = quill.addContainer('ql-tooltip');
    this.root.innerHTML = this.constructor.TEMPLATE;
    if (this.quill.root === this.quill.scrollingContainer) {
      this.quill.root.addEventListener('scroll', () => {
        this.root.style.marginTop = -1 * this.quill.root.scrollTop + 'px';

        this.quill.container.style.overflow = 'inherit';
        
        if (!this.root.classList.contains('ql-hidden')) {
          const quillBounds = this.quill.container.getBoundingClientRect();
          const tooltipBounds = this.root.getBoundingClientRect();
          const quillEditorRowHeight = 18;

          if (
            quillBounds.top > tooltipBounds.top + tooltipBounds.height ||
            quillBounds.bottom < tooltipBounds.bottom - tooltipBounds.height - quillEditorRowHeight
          ) {
            this.quill.container.style.overflow = 'hidden';
          } else {
            this.quill.container.style.overflow = 'inherit';
          }
        }
      });
    }
    this.hide();
  }

  hide() {
    this.root?.classList.add('ql-hidden');
  }

  position(reference) {
    let left = reference.left + reference.width / 2 - this.root.offsetWidth / 2;
    if (left < 0) {
      left = 0;
    }
    // root.scrollTop should be 0 if scrollContainer !== root
    let top = reference.bottom + this.quill.root.scrollTop;
    if (top < 0) {
      top = 0;
    }
    this.root.style.left = left + 'px';
    this.root.style.top = top + 'px';
    this.root.classList.remove('ql-flip');
    let containerBounds = this.boundsContainer.getBoundingClientRect();
    let rootBounds = this.root.getBoundingClientRect();
    let shift = 0;
    if (rootBounds.right > containerBounds.right) {
      shift = containerBounds.right - rootBounds.right;
      this.root.style.left = ((left + shift) < 0 ? 0 : (left + shift)) + 'px';
    }
    if (rootBounds.left < containerBounds.left) {
      shift = containerBounds.left - rootBounds.left;
      this.root.style.left = ((left + shift) < 0 ? 0 : (left + shift)) + 'px';
    }
    if (rootBounds.bottom > containerBounds.bottom) {
      let height = rootBounds.bottom - rootBounds.top;
      let verticalShift = reference.bottom - reference.top + height;
      this.root.style.top = ((top - verticalShift) < 0 ? 0 : (top - verticalShift)) + 'px';
      this.root.classList.add('ql-flip');
    }
    return shift;
  }

  show() {
    this.root.classList.remove('ql-editing');
    this.root.classList.remove('ql-hidden');
  }
}

export default Tooltip;
