import EventEmitter from '../../api/EventEmitter';
import {IMCTextEntities} from '../../api/proto';
import {GROUP_MESSAGE_ITEMS_LIMIT} from '../../constants';
import {sliceIntoChunks} from '../../utils/arrayUtils';
import {FileData} from '../../utils/file/fileReaders';
import {formatAttachment} from '../../utils/file/formatAttachment';
import {RawMessage, RawMessagesStore} from '../RawMessagesStore';
import ChatStore from './ChatStore';


export enum SendMessagesQueueEvent {
  SEND_MESSAGE = 'SEND_MESSAGE',
}

export type SendMessageData = {
  text: string;
  dataFiles?: FileData[];
  groupInAlbum?: boolean;
  compressImages?: boolean;
  asDocumentType?: boolean;
  replyMessage?: RawMessage | null;
  textEntities?: IMCTextEntities[] | null;
};

interface ISendMessagesQueue {
  sendMessage(data: SendMessageData): void;
  clear(): void;
}

type MessagesQueueData = {
  sendMessageData: SendMessageData;
  rawNewMessages: RawMessage | Map<number, RawMessage[]>;
};

export class SendMessagesQueue extends EventEmitter implements ISendMessagesQueue {
  constructor(protected rawMessagesStore: RawMessagesStore, protected chatStore: ChatStore) {
    super();
  }

  private messagesQueueList_: MessagesQueueData[] = [];

  private queueIsRunning_: boolean = false;

  public sendMessage = (data: SendMessageData) => {
    this.preSendMessage_(data);

    if (!this.queueIsRunning_) {
      this.runQueue_();
    }

    this.emit(SendMessagesQueueEvent.SEND_MESSAGE);
  };

  public clear = () => {
    this.messagesQueueList_ = [];
    this.queueIsRunning_ = false;
  };

  private addDataToQueueList_ = (
    sendMessageData: SendMessageData,
    rawNewMessages: RawMessage | Map<number, RawMessage[]>,
  ) => {
    this.messagesQueueList_.push({
      sendMessageData,
      rawNewMessages,
    });
  };

  private preSendMessage_ = (sendMessageData: SendMessageData) => {
    const {
      text,
      dataFiles,
      groupInAlbum,
      asDocumentType,
      replyMessage,
      textEntities,
    } = sendMessageData;

    if (dataFiles) {
      this.chatStore.channel.app.uploader.addToProgress(dataFiles);

      if (groupInAlbum) {
        for (const [index, chunkedDataFiles] of sliceIntoChunks(dataFiles, GROUP_MESSAGE_ITEMS_LIMIT).entries()) {
          const attachments = chunkedDataFiles.map((dataFile) => {
            const attachment = formatAttachment(dataFile, asDocumentType);
            dataFile.attachment = attachment;
            return attachment;
          });

          const newRawMessages = this.rawMessagesStore.preSendGroup({
            text: index === 0 ? text : '',
            attachments,
            dataFiles: chunkedDataFiles,
            textEntities: index === 0 ? textEntities : null,
          });

          if (newRawMessages.size) {
            this.addDataToQueueList_(sendMessageData, newRawMessages);
          }
        }
      } else {
        const newRawMessages: Map<number, RawMessage[]> = new Map();

        for (const dataFile of dataFiles) {
          const attachment = formatAttachment(dataFile, asDocumentType);
          dataFile.attachment = attachment;

          const newRawMessage = this.rawMessagesStore.preSend({
            text: newRawMessages.size === 0 ? text : '',
            attachments: dataFile.attachment ? [dataFile.attachment] : null,
            dataFiles: [dataFile],
            textEntities: newRawMessages.size === 0 ? textEntities : null,
          });

          const type = dataFile.attachment?.type || 0;
          const pack = newRawMessages.get(type);
          newRawMessages.set(type, pack ? [...pack, newRawMessage] : [newRawMessage]);
        }

        if (newRawMessages.size) {
          this.addDataToQueueList_(sendMessageData, newRawMessages);
        }
      }
    } else {
      const newRawMessage = this.rawMessagesStore.preSend({
        text,
        replyToMessage: replyMessage,
        textEntities,
      });
      this.addDataToQueueList_(sendMessageData, newRawMessage);
    }
  };

  private runQueue_ = async () => {
    if (this.messagesQueueList_.length) {
      this.queueIsRunning_ = true;

      const queueItem = this.messagesQueueList_.shift();

      if (queueItem) {
        await this.sendRequest_(queueItem);
      }

      this.runQueue_();
    } else {
      this.queueIsRunning_ = false;
    }
  };

  private sendRequest_ = async (queueItem: MessagesQueueData) => {
    if (!this.rawMessagesStore) {
      return;
    }
    const {sendMessageData, rawNewMessages} = queueItem;

    const {compressImages, groupInAlbum} = sendMessageData;

    if (rawNewMessages instanceof Map) {
      for (const [, pack] of rawNewMessages) {
        if (groupInAlbum && pack.length > 1) {
          let dataFiles: FileData[] = [];
          for (const newRawMessage of pack) {
            if (newRawMessage.dataFiles) {
              dataFiles = [...dataFiles, ...newRawMessage.dataFiles.map((dataFile) => ({...dataFile, message: newRawMessage}))];
            }
          }

          if (dataFiles.length) {
            const res = await this.chatStore.channel.app.uploader.uploadFiles(
              this.chatStore.channel.id,
              dataFiles,
              compressImages,
            );

            const uploadedRawMessages: RawMessage[] = [];
            res.dataFiles.forEach((dataFile) => dataFile.message && uploadedRawMessages.push(dataFile.message));

            res.failedFiles.forEach(([error, dataFile]) => {
              this.chatStore.channel.app.notifications.error(error);
              dataFile.message && this.rawMessagesStore.setMessageError({localID: dataFile.message.localID}, error);
            });

            const sendRes = uploadedRawMessages.length ? await this.rawMessagesStore.sendGroupedRequest(uploadedRawMessages) : null;

            if (sendRes?.error) {
              this.chatStore.channel.app.notifications.error(sendRes.error.message);
            }
          }
        } else {
          for (const newRawMessage of pack) {
            if (newRawMessage.dataFiles) {
              const res = await this.chatStore.channel.app.uploader.uploadFiles(
                this.chatStore.channel.id,
                newRawMessage.dataFiles,
                compressImages,
              );

              res.failedFiles.forEach(([error, dataFile]) => {
                this.chatStore.channel.app.notifications.error(error);
                dataFile.message && this.rawMessagesStore.setMessageError(dataFile.message, error);
              });

              /*
              if (error && error?.message !== CANCELED_ERROR_MESSAGE) {
                this.rawMessagesStore.setMessageError(newRawMessage, error.message);
                this.chatStore.channel.app.notifications.error(error.message);
              }
              */

              const messageRes = res.attachments?.length
                ? await this.rawMessagesStore.sendRequest(newRawMessage, res.attachments)
                : null;

              if (messageRes?.error) {
                this.chatStore.channel.app.notifications.error(messageRes.error.message);
              }
            }
          }
        }
      }
    } else {
      await this.rawMessagesStore.sendRequest(rawNewMessages);
    }
  };
}
