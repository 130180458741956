import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import DialogActions from 'o-ui/Dialog/DialogActions';
import DialogContent from 'o-ui/Dialog/DialogContent';
import useMountedState from 'o-ui/hooks/useMountedState';

import {useStore} from '../stores/AppStore';
import {ModalItem} from '../stores/ModalsStore';
import {IRawChat} from '../stores/RawChatsStore/RawChat';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';
import SelectChat from '../components/Select/SelectChat';


interface IProps {
  modal: ModalItem;
}

export const ModalMemberAccessAddChat = observer((props: IProps) => {
  const isMounted = useMountedState();
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);
  const {accessRecord} = props.modal.data;
  const {activeWorkspace} = useStore();

  const [addBtnDisabled, setAddBtnDisabled] = React.useState(true);
  const selectedChatsRef = React.useRef<IRawChat[]>([]);

  const loadingRef = React.useRef<boolean>(false);
  const searchRef = React.useRef<string>('');
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [foundChats, setFoundChats] = React.useState<IRawChat[]>([]);

  const [loadedChats_, setLoadedChats_] = React.useState<IRawChat[]>([]);

  const chats = React.useMemo(() => {
    return loadedChats_.filter(({id}) => !accessRecord?.chatsIds.some((chatId) => id?.equals(chatId)));
  }, [
    accessRecord?.chatsIds,
    loadedChats_,
  ]);


  React.useEffect(() => {
    const loadChats = async function () {
      if (loadingRef.current) {
        return;
      }

      if (accessRecord?.channelID) {
        loadingRef.current = true;
        const chats_ = await activeWorkspace.chatsSource.load(accessRecord?.channelID);
        if (!isMounted()) {
          return;
        }
        setLoadedChats_(chats_);
        loadingRef.current = false;
      }
    };

    loadChats();
  }, [
    isMounted,
    accessRecord?.channelID,
    activeWorkspace.chatsSource,
  ]);

  const resetChatsSearch = () => {
    searchRef.current = '';
    setFoundChats([]);
    setSearchQuery('');
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
    resetChatsSearch();
  };

  const handleSelectClick = () => {
    accessRecord?.addChats(selectedChatsRef.current);

    handleCloseClick();
  };

  const handleSelect = React.useCallback((chats_: IRawChat[]) => {
    setAddBtnDisabled(!chats_.length);
    selectedChatsRef.current = chats_;
  }, []);

  const handleSearchChat = React.useCallback((query: string) => {
    const searchChats = async function (q: string) {
      if (accessRecord?.channelID) {
        const chats_ = await activeWorkspace.chatsSource.search(accessRecord?.channelID, q);

        if (!isMounted()) {
          return;
        }
        if (searchRef.current === q) {
          setFoundChats(
            chats_.filter(({id}) => !accessRecord?.chatsIds.some((chatId) => id?.equals(chatId)))
          );
        }
      }
    };

    if (query) {
      searchRef.current = query;
      setSearchQuery(query);
      searchChats(query);
    } else {
      resetChatsSearch();
    }
  }, [
    isMounted,
    accessRecord?.channelID,
    accessRecord?.chatsIds,
    activeWorkspace.chatsSource,
  ]);

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      title={t('modal_member_access_add_chats_title')}
      maxWidth="xs"
      fullWidth={true}
      onClose={resetChatsSearch}
    >
      <DialogContent style={{minHeight: 350}}>
        <div className="py-6">
          <SelectChat
            chatItemClassName="pl-5"
            chats={searchQuery ? foundChats : chats}
            channelType={accessRecord?.channel?.type}
            onSelect={handleSelect}
            onSearch={handleSearchChat}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleCloseClick}
        >
          {t('modal_cancel_btn')}
        </Button>
        <Button
          className="ml-auto"
          variant="contained"
          color="primary"
          size="large"
          disabled={addBtnDisabled}
          onClick={handleSelectClick}
        >
          {t('modal_add_btn')}
        </Button>
      </DialogActions>
    </ModalDialog>
  );
});

export default ModalMemberAccessAddChat;
