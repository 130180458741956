import emailRegex from 'email-regex';
import Long from 'long';
import urlRegex from 'url-regex';

import {IMCTextEntities, MCTextEntities} from '../../../api/proto';
import {stringToUint8Array} from '../../../utils/arrayUtils';

export default function findCustomTextEntities(text?: string): IMCTextEntities[] {
  const newEntities: IMCTextEntities[] = [];

  if (text) {
    const regexUrl = urlRegex({strict: false});
    const regexEmail = emailRegex();

    let match: RegExpExecArray | never[] | null = [];

    const regexUrlEmail = new RegExp(`(${regexUrl.source})|(${regexEmail.source})`, 'g');

    while ((match = regexUrlEmail.exec(text))) {
      const type = regexEmail.test(match[0].trim()) ? MCTextEntities.Type.EMAIL_ADDRESS : MCTextEntities.Type.LINK;

      newEntities.push({
        type,
        offset: Long.fromNumber(match.index || 0),
        length: Long.fromNumber(match[0]?.length),
        payload: stringToUint8Array(match[0]),
      });
    }
  }

  return newEntities;
}