export enum RawChatsStoreEvent {
  ADD_NEW_CHAT = 'ADD_NEW_CHAT',
  UPDATE_CHAT = 'UPDATE_CHAT',
  REMOVE_CHAT = 'REMOVE_CHAT',
  LOAD_CHATS = 'LOAD_CHATS',
  CLEAR = 'CLEAR',
  UPDATE_TOTAL = 'UPDATE_TOTAL',
  UPDATE_TOTAL_UNREAD_COUNT = 'UPDATE_TOTAL_UNREAD_COUNT',
}

export default RawChatsStoreEvent;
