import React from 'react';
import Long from 'long';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  MouseSensor,
  Modifier,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
  SortingStrategy,
} from '@dnd-kit/sortable';
import {restrictToVerticalAxis, restrictToHorizontalAxis, restrictToFirstScrollableAncestor} from '@dnd-kit/modifiers';

type SortDirection = 'vertical' | 'horizontal';

interface SortableContainerProps<ItemType> {
  items: ItemType[];
  onSortEnd?(oldIndex: number, newIndex: number): void;
  overlay?: boolean;
  sortDirection?: SortDirection;
}

const sortingStrategyByDirection: Record<SortDirection, SortingStrategy> = {
  vertical: verticalListSortingStrategy,
  horizontal: horizontalListSortingStrategy,
};

const restrictAxisModifierByType: Record<SortDirection, Modifier> = {
  vertical: restrictToVerticalAxis,
  horizontal: restrictToHorizontalAxis,
};

const SortableContainer = <ObjectType extends {id?: Long | null | number | string}>(
  props: React.PropsWithChildren<SortableContainerProps<ObjectType>>,
): JSX.Element => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const items = React.useMemo(() => {
    return props.items.map((item, idx) => ({...item, id: item.id?.toString() || `${idx}`}));
  }, [props.items]);

  const handleDragEnd = (event) => {
    const {active, over} = event;

    if (active.id !== over.id && props.onSortEnd) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);

      props.onSortEnd(oldIndex, newIndex);
    }
  };

  const sortDirection = props.sortDirection || 'vertical';

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToFirstScrollableAncestor, restrictAxisModifierByType[sortDirection]]}
    >
      <SortableContext items={items} strategy={sortingStrategyByDirection[sortDirection]}>
        {props.children}
      </SortableContext>
    </DndContext>
  );
};

export default SortableContainer;
