import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react-lite';
import {useStore} from './stores/AppStore';

const AppContainer: React.FC<React.PropsWithChildren> = observer(({children}) => {
  const app = useStore();

  return (
    <div className={cn('app', {
      'app--custom-title-bar': app.externalApp.customTitleBar,
      'app--light': app.lightThemeChosen,
      'app--dark': !app.lightThemeChosen,
    })}>
      {children}
    </div>
  );
});

export default AppContainer;
