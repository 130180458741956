
export enum ChatInfoType {
  NONE,
  MEMBERS,
  IMAGES,
  VIDEOS,
  FILES,
  VOICES,
  AUDIOS,
  LINKS,
  GIFS,
  STICKERS,
  OPERATORS,
}

export default ChatInfoType;
