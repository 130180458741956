import React from 'react';
import {IMCTextEntities} from '../../../../api/proto';
import {uint8ArrayToString} from '../../../../utils/arrayUtils';

interface EmailEntityProps {
  children?: React.ReactNode;
  entity: IMCTextEntities;
  textUrl?: string;
}

export const EmailEntity: React.FC<EmailEntityProps> = ({
  children,
  entity,
  textUrl,
}) => {
  const email = uint8ArrayToString(entity.payload);
  return (
    <a href={`mailto: ${email || textUrl}`} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default EmailEntity;
