import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import useAttachmentProgress from './useAttachmentProgress';


interface FileUploadingProgressProps {
  attachment: MessageAttachment;
}

export const FileUploadingProgress: React.FC<React.PropsWithChildren<FileUploadingProgressProps>> = observer(({
  attachment,
  children,
}) => {
  const {t} = useTranslation();
  const {isInProgress, isUpload, progress} = useAttachmentProgress(attachment);

  if (!isInProgress || !isUpload) {
    return <>{children}</>;
  }

  return (
    <span className="file-uploading-progress">
      {t('chat_user_chat_uploaded_progress_title')} {progress}%
    </span>
  );
});

export default FileUploadingProgress;
