import React from 'react';

import {CONTENT_TYPES_WITH_PREVIEW} from '../../config';
import {useStore} from '../../stores/AppStore';
import Chat from '../../stores/Chat';
import ModalType from '../../stores/ModalType';
import {FileData} from '../../utils/file/fileReaders';
import FileInput from './FileInput';
import useFileChangeHendler from './useFileChangeHendler';

interface PhotoVideoUploaderProps {
  chat: Chat;
  className?: string;
}

export const PhotoVideoUploader = React.forwardRef<HTMLInputElement, PhotoVideoUploaderProps>((props, ref) => {
  const {modals} = useStore();

  const handleSend = (dataFiles: FileData[]) => {
    modals.open(ModalType.UPLOAD_PHOTO_VIDEO_ASYNC, {dataFiles, chat: props.chat});
  };

  const handleFileChange = useFileChangeHendler(handleSend);

  return (
    <FileInput
      className={props.className}
      ref={ref}
      onChange={handleFileChange}
      multiple={true}
      accept={Array.from(CONTENT_TYPES_WITH_PREVIEW).join(',')}
    />
  );
});

export default PhotoVideoUploader;
