import Long from 'long';
import React from 'react';

import {ListChildComponentProps} from 'o-ui/VirtualizedList';
import ChatInfoDateDelimiter from '../ChatInfo/ChatInfoDateDelimiter';
import MediaGalleryItem from './MediaGalleryItem';
import {MediaGalleryRowType} from './useMediaGallery';

interface MediaGalleryRowProps {
  rowProps: ListChildComponentProps;
  items: MediaGalleryRowType[];
  setSize: (index: number, size: number) => void;
  width: number;
  isScrolling?: boolean;
}

export const MediaGalleryRow = React.memo(({
  rowProps,
  items,
  setSize,
  width,
  isScrolling,
}: MediaGalleryRowProps) => {
  const mediaGalleryRow = items[rowProps.index];

  React.useEffect(() => {
    if (typeof mediaGalleryRow === 'number') {
      setSize(rowProps.index, 40);
    } else {
      setSize(rowProps.index, width / 3);
    }
  }, [setSize, rowProps.index, mediaGalleryRow, width]);

  return (
    <div style={rowProps.style} key={rowProps.index}>
      {typeof mediaGalleryRow === 'number' ? (
        <ChatInfoDateDelimiter stamp={Long.fromNumber(mediaGalleryRow)} />
      ) : (
        <div className="media-gallery">
          {mediaGalleryRow.map((attachment) => (
            <MediaGalleryItem
              key={attachment.unicID}
              attachment={attachment}
              isScrolling={isScrolling}
            />
          ))}
        </div>
      )}
    </div>
  );
});

export default MediaGalleryRow;
