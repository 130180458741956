import cn from 'classnames';
import React from 'react';

import PreviewImage from '../../../../components/PreviewImage';
import useMaxMediaSize from '../../../../hooks/useMaxMediaSize';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import ModalType from '../../../../stores/ModalType';
import useStore from '../../../../stores/useStore';
import useMessageAttachment from './useMessageAttachment';

export interface ImageContentProps {
  className?: string;
  attachment: MessageAttachment;
  onContextMenu?: React.MouseEventHandler;
  fixedSize?: boolean;
  isScrolling?: boolean;
  blurredBackground?: boolean;
}

export const ImageContent: React.FC<ImageContentProps> = React.memo((props) => {
  const {modals} = useStore();

  const [inView, setInView] = React.useState<boolean>(false);
  const {src, width, height} = useMessageAttachment(props.attachment, inView);
  const {maxWidth, maxHeight} = useMaxMediaSize();

  const openLightbox = () => {
    modals.open(
      ModalType.MEDIA_LIGHT_BOX,
      {
        attachment: props.attachment,
      },
      false,
      true,
    );
  };

  return props.blurredBackground ? (
    <div
      className={cn('message-img__img-wrapper')}
      data-attachment-uuid={props.attachment.uuid}
    >
      <div className={cn('message-img__img-bg')} style={{backgroundImage: `url(${src})`}} />
      <PreviewImage
        className={cn(
          'message-img__img cursor-pointer',
          props.className,
          `attachment-uuid-${props.attachment.uuid}`,
        )}
        src={src}
        fixedSize={props.fixedSize}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        width={width}
        height={height}
        onClick={openLightbox}
        onContextMenu={props.onContextMenu}
        isScrolling={props.isScrolling}
        onInViewChange={setInView}
      />
    </div>
  ) : (
    <PreviewImage
      className={cn(
        'message-img__img cursor-pointer',
        props.className,
        `attachment-uuid-${props.attachment.uuid}`,
      )}
      src={src}
      fixedSize={props.fixedSize}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      width={width}
      height={height}
      onClick={openLightbox}
      onContextMenu={props.onContextMenu}
      isScrolling={props.isScrolling}
      onInViewChange={setInView}
    />
  );
});

export default ImageContent;
