import {action, computed, makeObservable, observable} from 'mobx';

import {ButtonProps} from 'o-ui/Button/Button';

import {entities} from '../api/proto';
import {Breakpoint} from '../modals/components/ModalDialog';
import {APILayer} from '../stores/APILayer';
import Message from '../stores/Message';
import ModalType from '../stores/ModalType';
import {FileData} from '../utils/file/fileReaders';
import {AppStore} from './AppStore';
import MessageAttachment from './Attachment/MessageAttachment';
import Channel from './Channel';
import ChannelScreenType from './Channel/ChannelScreenType';
import Chat from './Chat';
import {ITag} from './MessageTagsStore';
import {Snippet} from './Snippets';
import {ITextSelection} from './TextSelection';
import Invite from './Workspaces/Invite';
import InviteBase from './Workspaces/InviteBase';
import WorkspaceMember from './Workspaces/WorkspaceMember';
import {WorkspaceMemberAccessRecord} from './Workspaces/WorkspaceMemberAccessRecord';
import WorkspaceMemberConfig from './Workspaces/WorkspaceMemberConfig';

export interface IModalItem {
  id: number;
  type: ModalType;
  data: ModalData;
}

export interface ICallback {
  [name: string]: ((data?: ModalData) => void) | (<T>(data?: ModalData) => Promise<T>);
}

export interface IModalData {
  title?: string | null;
  chat?: Chat | null;
  channel?: Channel | null;
  codeHash?: string | null;
  message?: Message | null;
  messages?: Message[] | null;
  files?: File[] | null;
  dataFiles?: FileData[] | null;
  attachment?: MessageAttachment | null;
  snippet?: Snippet;
  callbacks?: ICallback | null;
  syncCallbacks?: boolean | null;
  textSelection?: ITextSelection;
  tag?: ITag;
  member?: WorkspaceMember;
  memberConfig?: WorkspaceMemberConfig | null;
  invite?: Invite | InviteBase;
  accessRecord?: WorkspaceMemberAccessRecord | null;
  channelType?: entities.OzekonChannelType | null;
  channelScreenType?: ChannelScreenType;
  code?: string | null;
  email?: string | null;
  headerText?: string | null;
  submitButtonText?: string | null;
  submitButtonColor?: ButtonProps['color'] | null;
  cancelButtonText?: string | null;
  cancelButtonColor?: ButtonProps['color'] | null;
  messageIndex?: number | null;
  content?: JSX.Element | null;
  maxWidth?: Breakpoint | null;
  compressImages?: boolean | null;
  downloadFileUrl?: string | null;
  invitationLink?: string | null;
  tariff?: entities.ITariff | null;
}

export class ModalData {
  @observable title?: string | null;
  @observable chat?: Chat | null;
  @observable channel?: Channel | null;
  @observable codeHash?: string | null;
  @observable message?: Message | null;
  @observable files?: File[] | null;
  @observable dataFiles?: FileData[] | null;
  @observable attachment?: MessageAttachment;
  @observable snippet?: Snippet;
  @observable callbacks?: ICallback | null;
  @observable syncCallbacks?: boolean | null;
  @observable textSelection?: ITextSelection | null;
  @observable tag?: ITag;
  @observable member?: WorkspaceMember;
  @observable memberConfig?: WorkspaceMemberConfig | null;
  @observable invite?: Invite | InviteBase;
  @observable accessRecord?: WorkspaceMemberAccessRecord | null;
  @observable channelType?: entities.OzekonChannelType;
  @observable channelScreenType?: ChannelScreenType;
  @observable code?: string | null;
  @observable email?: string | null;
  @observable headerText?: string | null;
  @observable submitButtonText?: string | null;
  @observable submitButtonColor?: ButtonProps['color'] | null;
  @observable cancelButtonText?: string | null;
  @observable cancelButtonColor?: ButtonProps['color'] | null;
  @observable messageIndex?: number | null;
  @observable content?: JSX.Element | null;
  @observable maxWidth?: Breakpoint | null;
  @observable compressImages?: boolean | null;
  @observable downloadFileUrl?: string | null;
  @observable invitationLink?: string;
  @observable tariff?: entities.ITariff | null;

  constructor(props: IModalData) {
    Object.assign(this, props);
    makeObservable(this);
  }
}

export class ModalItem implements IModalItem {
  id: number;
  type: ModalType;
  @observable data: ModalData;

  constructor(props: IModalItem, private modals: ModalsStore) {
    makeObservable(this);

    this.id = props.id;
    this.type = props.type;
    this.data = props.data;

    Object.assign(this, props);
  }

  close = () => {
    this.modals.close(this.id);
  };

  trigger = async (name: string, data?: ModalData) => {
    if (this.data.callbacks && this.data.callbacks[name] instanceof Function) {
      if (this.data.syncCallbacks) {
        await this.data.callbacks[name](data || this.data);
      } else {
        this.data.callbacks[name](data || this.data);
      }
      this.close();
    }
  };

  open = (type: ModalType, data: IModalData) => {
    this.modals.open(type, data);
  };

  @action updateData = (newData: ModalData) => {
    this.data = newData;
  };
}

export class ModalsStore extends APILayer {
  @observable list: ModalItem[] = [];

  @computed get isOpen(): boolean {
    return this.list.length > 0;
  }

  constructor(app: AppStore) {
    super(app);
    makeObservable(this);
  }

  @action close = (id: number) => {
    this.list = this.list.filter((modal) => modal.id !== id);
  };

  @action
  open = (type: ModalType, data: IModalData = {}, onFirstPlace?: boolean, closeAllModals?: boolean) => {
    const modal = new ModalItem(
      {
        id: new Date().getTime(),
        type,
        data: new ModalData(data),
      },
      this,
    );
    if (closeAllModals) {
      this.list = [];
    }
    if (onFirstPlace) {
      this.list.unshift(modal);
    } else {
      this.list.push(modal);
    }
  };

  @action
  findModal = (type: ModalType): ModalItem | undefined => {
    return this.list.find((modal) => modal.type === type);
  };
}

export default ModalsStore;
