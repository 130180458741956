export default {
  '1': '+#(###)###-####',
  '7': '+#(###)###-##-##',
  '20': '+##(###)###-####',
  '27': '+##-##-###-####',
  '30': '+##(###)###-####',
  '31': '+##-##-###-####',
  '32': '+##(###)###-###',
  '33': '+##(###)###-###',
  '34': '+##(###)###-###',
  '36': '+##(###)###-###',
  '39': '+##(###)####-###',
  '40': '+##-##-###-####',
  '41': '+##-##-###-####',
  '43': '+##(###)###-####',
  '44': '+##-##-####-####',
  '45': '+##-##-##-##-##',
  '46': '+##-##-###-####',
  '47': '+##(###)##-###',
  '48': '+##(###)###-###',
  '49': '+##-###-######',
  '51': '+##(###)###-###',
  '52': '+##-##-##-####',
  '53': '+##-#-###-####',
  '54': '+##(###)###-####',
  '55': '+##(##)####-####',
  '56': '+##-#-####-####',
  '57': '+##(###)###-####',
  '58': '+##(###)###-####',
  '60': '+##-#-###-###',
  '61': '+##-#-####-####',
  '62': '+##-##-###-####',
  '63': '+##(###)###-####',
  '64': '+##(###)###-####',
  '65': '+##-####-####',
  '66': '+##-##-###-###',
  '76': '+#(###)###-##-##',
  '77': '+#(###)###-##-##',
  '81': '+##(###)###-###',
  '82': '+##-##-###-####',
  '84': '+##(###)####-###',
  '86': '+##-##-#####-#####',
  '90': '+##(###)###-####',
  '91': '+##(####)###-###',
  '92': '+##(###)###-####',
  '93': '+##-##-###-####',
  '94': '+##-##-###-####',
  '95': '+##-###-####-###',
  '98': '+##(###)###-####',
  '211': '+###-##-###-####',
  '212': '+###-##-####-###',
  '213': '+###-##-###-####',
  '216': '+###-##-###-###',
  '218': '+###-##-###-###',
  '220': '+###(###)##-##',
  '221': '+###-##-###-####',
  '222': '+###-##-##-####',
  '223': '+###-##-##-####',
  '224': '+###-##-###-###',
  '225': '+###-##-###-###',
  '226': '+###-##-##-####',
  '227': '+###-##-##-####',
  '228': '+###-##-###-###',
  '229': '+###-##-##-####',
  '230': '+###-###-####',
  '231': '+###-##-###-###',
  '232': '+###-##-######',
  '233': '+###(###)###-###',
  '234': '+###(###)###-####',
  '235': '+###-##-##-##-##',
  '236': '+###-##-##-####',
  '237': '+###-####-####',
  '238': '+###(###)##-##',
  '239': '+###-##-#####',
  '240': '+###-##-###-####',
  '241': '+###-#-##-##-##',
  '242': '+###-##-###-####',
  '243': '+###(###)###-###',
  '244': '+###(###)###-###',
  '245': '+###-#-######',
  '246': '+###-###-####',
  '247': '+###-####',
  '248': '+###-#-###-###',
  '249': '+###-##-###-####',
  '250': '+###(###)###-###',
  '251': '+###-##-###-####',
  '252': '+###-#-###-###',
  '253': '+###-##-##-##-##',
  '254': '+###-###-######',
  '255': '+###-##-###-####',
  '256': '+###(###)###-###',
  '257': '+###-##-##-####',
  '258': '+###-##-###-###',
  '260': '+###-##-###-####',
  '261': '+###-##-##-#####',
  '262': '+###-#####-####',
  '263': '+###-#-######',
  '264': '+###-##-###-####',
  '265': '+###-#-####-####',
  '266': '+###-#-###-####',
  '267': '+###-##-###-###',
  '268': '+###-##-##-####',
  '269': '+###-##-#####',
  '290': '+###-####',
  '291': '+###-#-###-###',
  '297': '+###-###-####',
  '298': '+###-###-###',
  '299': '+###-##-##-##',
  '350': '+###-###-#####',
  '351': '+###-##-###-####',
  '352': '+###(###)###-###',
  '353': '+###(###)###-###',
  '354': '+###-###-####',
  '355': '+###(###)###-###',
  '356': '+###-####-####',
  '357': '+###-##-###-###',
  '358': '+###(###)###-##-##',
  '359': '+###(###)###-###',
  '370': '+###(###)##-###',
  '371': '+###-##-###-###',
  '372': '+###-###-####',
  '373': '+###-####-####',
  '374': '+###-##-###-###',
  '375': '+###(##)###-##-##',
  '376': '+###-###-###',
  '377': '+###-##-###-###',
  '378': '+###-####-######',
  '380': '+###(##)###-##-##',
  '381': '+###-##-###-####',
  '382': '+###-##-###-###',
  '385': '+###-##-###-###',
  '386': '+###-##-###-###',
  '387': '+###-##-####',
  '389': '+###-##-###-###',
  '420': '+###(###)###-###',
  '421': '+###(###)###-###',
  '423': '+###(###)###-####',
  '500': '+###-#####',
  '501': '+###-###-####',
  '502': '+###-#-###-####',
  '503': '+###-##-##-####',
  '504': '+###-####-####',
  '505': '+###-####-####',
  '506': '+###-####-####',
  '507': '+###-###-####',
  '508': '+###-##-####',
  '509': '+###-##-##-####',
  '557': '+##(##)####-####',
  '559': '+##(##)#####-####',
  '590': '+###(###)###-###',
  '591': '+###-#-###-####',
  '592': '+###-###-####',
  '593': '+###-#-###-####',
  '594': '+###-#####-####',
  '595': '+###(###)###-###',
  '596': '+###(###)##-##-##',
  '597': '+###-###-###',
  '598': '+###-#-###-##-##',
  '599': '+###-###-####',
  '628': '+##(###)###-##-###',
  '670': '+###-###-####',
  '673': '+###-###-####',
  '674': '+###-###-####',
  '675': '+###(###)##-###',
  '676': '+###-#####',
  '677': '+###-#####',
  '678': '+###-#####',
  '679': '+###-##-#####',
  '680': '+###-###-####',
  '681': '+###-##-####',
  '682': '+###-##-###',
  '683': '+###-####',
  '685': '+###-##-####',
  '686': '+###-##-###',
  '687': '+###-##-####',
  '689': '+###-##-##-##',
  '690': '+###-####',
  '691': '+###-###-####',
  '692': '+###-###-####',
  '850': '+###-####-#############',
  '852': '+###-####-####',
  '853': '+###-####-####',
  '855': '+###-##-###-###',
  '856': '+###-##-###-###',
  '880': '+###-##-###-###',
  '886': '+###-####-####',
  '960': '+###-###-####',
  '961': '+###-#-###-###',
  '962': '+###-#-####-####',
  '963': '+###-##-####-###',
  '964': '+###(###)###-####',
  '965': '+###-####-####',
  '966': '+###-#-###-####',
  '967': '+###-##-###-###',
  '968': '+###-##-###-###',
  '970': '+###-##-###-####',
  '971': '+###-#-###-####',
  '972': '+###-#-###-####',
  '973': '+###-####-####',
  '974': '+###-####-####',
  '975': '+###-#-###-###',
  '976': '+###-##-##-####',
  '977': '+###-##-###-###',
  '992': '+###-##-###-####',
  '993': '+###-#-###-####',
  '994': '+###-##-###-##-##',
  '995': '+###(###)###-###',
  '996': '+###(###)###-###',
  '998': '+###-##-###-####',
  '1242': '+#(###)###-####',
  '1246': '+#(###)###-####',
  '1264': '+#(###)###-####',
  '1268': '+#(###)###-####',
  '1284': '+#(###)###-####',
  '1340': '+#(###)###-####',
  '1345': '+#(###)###-####',
  '1441': '+#(###)###-####',
  '1473': '+#(###)###-####',
  '1649': '+#(###)###-####',
  '1664': '+#(###)###-####',
  '1670': '+#(###)###-####',
  '1671': '+#(###)###-####',
  '1684': '+#(###)###-####',
  '1721': '+#(###)###-####',
  '1758': '+#(###)###-####',
  '1767': '+#(###)###-####',
  '1784': '+#(###)###-####',
  '1809': '+#(###)###-####',
  '1829': '+#(###)###-####',
  '1849': '+#(###)###-####',
  '1868': '+#(###)###-####',
  '1869': '+#(###)###-####',
  '1876': '+#(###)###-####',
  '2651': '+###-#-###-###',
  '5999': '+###-####-####',
  '6721': '+###-###-###',
  '6723': '+###-###-###',
  '6882': '+###-#####',
  '9665': '+###-#-####-####',
  '9715': '+###-##-###-####',
  '9725': '+###-##-###-####',
  '21821': '+###-##-###-####',
  '67077': '+###-###-#####',
  '67078': '+###-###-#####',
  '68890': '+###-######',
  '85620': '+###(####)###-###',
  '97517': '+###-##-###-###',
  '396698': '+##-#-###-#####',
  '850191': '+###-###-###-####',
};
