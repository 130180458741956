import {useTransition} from 'react-spring';

export default function useSlideTransition(pageIndex: number, prevPageIndex: number) {
  const transitions = useTransition(pageIndex, {
    from:
      prevPageIndex === pageIndex
        ? {}
        : {
          opacity: 0,
          transform: pageIndex <= prevPageIndex ? 'translate3d(-100%, 0, 0)' : 'translate3d(100%, 0, 0)',
        },
    enter: {
      opacity: 1,
      transform: 'translate3d(0%, 0, 0)',
    },
    leave: {
      opacity: 0,
      transform: pageIndex <= prevPageIndex ? 'translate3d(100%, 0, 0)' : 'translate3d(-100%, 0, 0)',
    },
    //config: {duration: 3000},
  });

  return transitions;
}