import {matchPath} from 'react-router-dom';
import getHistoryState from '../../RoutingHistory/getHistoryState';
import Paths from '../../../routes/Paths';
import browserStorage from '../../../utils/browserStorage';
import {PREFERABLE_TARIFF_ID} from '../../../constants';

export default function getPreferableTariffId(): string | null {
  const state = getHistoryState();

  const match = state?.from?.pathname ?
    matchPath(Paths.RawBillingTariff, state?.from?.pathname) : null;

  return match?.params?.tariffId || browserStorage.local(PREFERABLE_TARIFF_ID) || null;
}