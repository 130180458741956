import {observer} from 'mobx-react';
import Button from 'o-ui/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Navigate, generatePath} from 'react-router';
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import {uint8ArrayToBase64} from '../../utils/arrayUtils';
import InviteMessage from './InviteMessage';

const AcceptInvitePage: React.FC = observer(() => {
  const {t} = useTranslation();
  const {activeWorkspace} = useStore();

  const workspaceNavigate = useWorkspaceNavigate();

  const [loading, setLoading] = React.useState(false);

  const {invites} = activeWorkspace;

  const skipInviteHandle = () => {
    invites.clearRegistrationInvite();
    workspaceNavigate(Paths.PersonalInfo);
  };

  const acceptInvite = async () => {
    setLoading(true);

    await invites.acceptInvite(invites.registrationInvite);

    setLoading(false);
    workspaceNavigate(Paths.PersonalInfo);
  };

  if (!invites.registrationInvite) {
    return (
      <Navigate
        to={generatePath(Paths.PersonalInfo, {
          workspaceId: encodeURIComponent(uint8ArrayToBase64(activeWorkspace.id)),
        })}
        replace
      />
    );
  }

  return (
    <div className="auth-page">
      <div className="auth-page__accept-invite">
        <div className="auth-page__accept-invite-inner">
          <div className="auth-page__title mb-6 text-center">{t('accept_invite_title')}</div>

          {invites.registrationInvite ? (
            <InviteMessage invite={invites.registrationInvite} pageType="acceptInvite" className="mb-6" />
          ) : null}

          <Button className="w-100 mb-4" variant="contained" color="secondary" size="large" onClick={skipInviteHandle}>
            {t('accept_invite_skip_btn', {workspace: activeWorkspace.name})}
          </Button>

          {invites.registrationInvite ? (
            <Button
              className="w-100"
              variant="contained"
              color="primary"
              size="large"
              loading={loading}
              onClick={acceptInvite}
            >
              {t('accept_invite_accept_btn', {workspace: invites.registrationInvite.workspaceName})}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
});

export default AcceptInvitePage;
