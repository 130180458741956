import React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from 'react-i18next';

import Button, {ButtonProps} from 'o-ui/Button';
import WorkspaceMember from '../../../../stores/Workspaces/WorkspaceMember';
import Invite from '../../../../stores/Workspaces/Invite';
import {useStore} from '../../../../stores/AppStore';

interface ResendInviteButtonProps extends ButtonProps {
  member: WorkspaceMember | Invite;
}

export default observer(function ResendInviteButton({
  className,
  member,
  size,
}: ResendInviteButtonProps) {
  const {t} = useTranslation();
  const {activeWorkspace, notifications} = useStore();

  const resendInvite = async () => {
    const {error, res} = await member.resendRegistrationEmail();
    if (error) {
      notifications.error(error.message);
    } else if (res) {
      notifications.success(
        <Trans>
          The invite was sent again to <strong>{member.email}</strong>
        </Trans>,
      );
      activeWorkspace.load();
    }
  }

  return (
    <Button
      disabled={!member.resendRegistrationEmailTimer.stopped}
      onClick={resendInvite}
      className={className}
      variant="contained"
      color="primary"
      size={size}
    >
      {t('settings_workspace_members_manage_resend_invite')}
    </Button>
  );
});
