import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import IconButton from 'o-ui/Button/IconButton';
import useCompactMode from '../../../../hooks/useCompactMode';
import Chat from '../../../../stores/Chat';
import useStore from '../../../../stores/useStore';
import ChatBlockButton from './ChatBlockButton';
import ChatHeadForwards from './ChatHeadForwards';
import ChatHeadMenu from './ChatHeadMenu';
import ChatHeadTitle from './ChatHeadTitle';

import {ReactComponent as SidebarIcon} from '../../../../assets/image-icons/sidebar.svg';
import {ReactComponent as SidebarActiveIcon} from '../../../../assets/image-icons/sidebarActive.svg';
import {ReactComponent as SidebarActiveDarkThemeIcon} from '../../../../assets/image-icons/sidebarActiveDarkTheme.svg';
import {ReactComponent as SidebarDarkThemeIcon} from '../../../../assets/image-icons/sidebarDarkTheme.svg';
import {ReactComponent as SidebarHoverIcon} from '../../../../assets/image-icons/sidebarHover.svg';
import {ReactComponent as SidebarHoverDarkThemeIcon} from '../../../../assets/image-icons/sidebarHoverDarkTheme.svg';

interface ChatHeadProps {
  chat: Chat;
}

export const ChatHead: React.FC<ChatHeadProps> = observer(({chat}) => {
  const {
    layOutStore: {rightSideBar},
    lightThemeChosen,
    chatsView,
  } = useStore();
  const compactMode = useCompactMode();

  const handleSearchClick = () => {
    if (window.innerWidth < 768) {
      chatsView.hideActiveChat();
    }

    chatsView.enableSearchInActiveChat(chat);
  };

  const toggleNotifications = () => {
    console.debug(`TODO: not implemented handler "toggleNotifications"`);
  };

  const openChatSidebar = () => {
    rightSideBar.toggle(chat);
  };

  const [sidebarBtnHover, setSidebarBtnHover] = React.useState<boolean>(false);

  const renderSideBarIcon = () => {
    if (sidebarBtnHover && !rightSideBar.isOpen) {
      return lightThemeChosen ? <SidebarHoverIcon /> : <SidebarHoverDarkThemeIcon />;
    } else if (rightSideBar.isOpen) {
      return lightThemeChosen ? <SidebarActiveIcon /> : <SidebarActiveDarkThemeIcon />;
    } else {
      return lightThemeChosen ? <SidebarIcon /> : <SidebarDarkThemeIcon />;
    }
  };

  const selectedMessagesCount = 0; //chat.store?.selectedMessages.length

  return (
    <div
      className={cn(
        'chat-head',
        {'chat-head--compact-mode': compactMode},
      )}
    >
      {selectedMessagesCount > 0 ? (
        <ChatHeadForwards chat={chat} />
      ) : (
        <>
          <div className="chat-head__left">
            <ChatHeadTitle chat={chat} />
          </div>
          <div className="chat-head__right">
            <IconButton
              className={cn(
                'chat-head__button chat-head__button--mobile-hidden d-none',
                chat.isMuted ? 'tg-icon-bell-disabled' : 'tg-icon-bell-active',
              )}
              onClick={toggleNotifications}
              size="small"
            />

            {chat.channel.isWebWidget ? <ChatBlockButton className="chat-head__button" chat={chat} /> : null}
            {/* {chat.store?.assignment?.youAreJoined && chat.source !== com.chatman.Source.WEB_WIDGET ? (
              <IconButton
                className="chat-head__button chat-head__button--mobile-hidden tg-icon-check-5-line"
                onClick={chat.store?.assignment?.resolveChat}
                size="small"
              />
            ) : null} */}
            <IconButton
              className="chat-head__button chat-head__button--mobile-hidden"
              onClick={openChatSidebar}
              size="small"
              onMouseEnter={() => setSidebarBtnHover(true)}
              onMouseLeave={() => setSidebarBtnHover(false)}
            >
              {renderSideBarIcon()}
            </IconButton>

            <IconButton
              className={cn('chat-head__button tg-icon-search-s-line', {
                // active: !!chatsView.internalSearch.chatForSearch || !!chatsView.search.chatForSearch,
                active: !!chatsView.search.chatForSearch,
              })}
              onClick={handleSearchClick}
              size="small"
            />

            <ChatHeadMenu chat={chat} />
          </div>
        </>
      )}
    </div>
  );
});

export default ChatHead;
