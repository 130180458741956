import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {Chat} from '../../../../stores/Chat';
import {ChatInfoType} from '../../../../stores/LayOutStore';

interface ChatFilesInfoProps {
  className?: string;
  chat: Chat;
  onClick?: (type: ChatInfoType) => void;
}

export const ChatFilesInfo = observer((props: ChatFilesInfoProps) => {
  const {t} = useTranslation();

  const handleClick = (type: ChatInfoType) => {
    props.onClick && props.onClick(type);
  };

  return (
    <div className={cn('chat-files-info', props.className)}>
      <div className="chat-files-info__item" onClick={() => handleClick(ChatInfoType.IMAGES)}>
        <i className="chat-files-info__icon tg-icon tg-icon-image-line" />
        <span className="chat-files-info__name">
          {t('chat_user_chat_info_count_images', {count: props.chat.store.imagesHistory?.attachmentsCount || 0})}
        </span>
      </div>

      <div className="chat-files-info__item" onClick={() => handleClick(ChatInfoType.VIDEOS)}>
        <i className="chat-files-info__icon tg-icon tg-icon-video-line" />
        <span className="chat-files-info__name">
          {t('chat_user_chat_info_count_videos', {count: props.chat.store.videosHistory?.attachmentsCount || 0})}
        </span>
      </div>

      <div className="chat-files-info__item" onClick={() => handleClick(ChatInfoType.FILES)}>
        <i className="chat-files-info__icon tg-icon tg-icon-files-line" />
        <span className="chat-files-info__name">
          {t('chat_user_chat_info_count_files', {count: props.chat.store.filesHistory?.attachmentsCount || 0})}
        </span>
      </div>

      {/* <div className="chat-files-info__item" onClick={() => handleClick(ChatInfoType.LINKS)}>
        <i className="chat-files-info__icon tg-icon tg-icon-links-line" />
        <span className="chat-files-info__name">{t('chat_user_chat_info_count_shared_links', {count: 0})}</span>
      </div> */}

      <div className="chat-files-info__item" onClick={() => handleClick(ChatInfoType.VOICES)}>
        <i className="chat-files-info__icon tg-icon tg-icon-voice-line" />
        <span className="chat-files-info__name">
          {t('chat_user_chat_info_count_voice_messages', {
            count: props.chat.store.voicesHistory?.attachmentsCount || 0,
          })}
        </span>
      </div>

      <div className="chat-files-info__item" onClick={() => handleClick(ChatInfoType.AUDIOS)}>
        <i className="chat-files-info__icon tg-icon tg-icon-audio-line" />
        <span className="chat-files-info__name">
          {t('chat_user_chat_info_count_audios', {count: props.chat.store.audiosHistory?.attachmentsCount || 0})}
        </span>
      </div>

      <div className="chat-files-info__item" onClick={() => handleClick(ChatInfoType.GIFS)}>
        <i className="chat-files-info__icon tg-icon tg-icon-gif-line" />
        <span className="chat-files-info__name">
          {t('chat_user_chat_info_count_gifs', {count: props.chat.store.gifsHistory?.attachmentsCount || 0})}
        </span>
      </div>

      {/* <div className="chat-files-info__item" onClick={() => handleClick(ChatInfoType.STICKERS)}>
        <i className="chat-files-info__icon tg-icon tg-icon-stickers-line" />
        <span className="chat-files-info__name">
          {t('chat_user_chat_info_count_stickers', {count: props.chat.store.stickersHistory?.attachmentsCount || 0})}
        </span>
      </div> */}
    </div>
  );
});

export default ChatFilesInfo;
