import React from 'react';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {useStore} from '../../../../stores/AppStore';
import {ReactComponent as AllChatsAsRead} from '../../../../assets/image-icons/allChatsAsRead.svg';
import Button from 'o-ui/Button';
import {ModalType} from '../../../../stores/ModalType';

interface Props {
  className?: string;
}

export const AllChatsReadButton: React.FC<Props> = observer((props) => {
  const {modals, activeWorkspace} = useStore();
  const {t} = useTranslation();

  const [showTitle, setShowTitle] = React.useState<boolean>(false);

  const selectedChannel = activeWorkspace.channels.selectedChannel;

  const handleClick = () => {
    modals.open(ModalType.CONFIRM, {
      headerText: selectedChannel
        ? t('chat_chats_tab_selected_channel_mark_as_read_button_header', {channelName: selectedChannel.name})
        : t('chat_chats_tab_all_channels_mark_as_read_button_header'),
      title: selectedChannel
        ? t('chat_chats_tab_selected_channel_mark_as_read_button_title', {channelName: selectedChannel.name})
        : t('chat_chats_tab_all_channels_mark_as_read_button_title'),
      submitButtonText: t('Submit'),
      submitButtonColor: 'primary',
      syncCallbacks: true,
      callbacks: {
        submit: () => {
          if (selectedChannel) {
            activeWorkspace.channels.readAllChannelsChats([selectedChannel]);
          } else {
            activeWorkspace.channels.readAllChannelsChats(activeWorkspace.channels.availableChannels);
          }
        },
      },
    });
  };

  const handleShowTitle = () => {
    setShowTitle(true);
  };

  const handleHiddenTitle = () => {
    setShowTitle(false);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      className={cn(
        'chat-button',
        {
          'chat-button--with-title': showTitle,
        },
        props.className,
      )}
      onClick={handleClick}
      onMouseEnter={handleShowTitle}
      onMouseLeave={handleHiddenTitle}
    >
      <AllChatsAsRead />
      {showTitle ? <span className="px-3">{t('chat_chats_tab_mark_all_chats_as_read_button')}</span> : null}
    </Button>
  );
});

export default AllChatsReadButton;
