import React from 'react';

interface ChatItemDelimiterProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const ChatItemDelimiter: React.FC<ChatItemDelimiterProps> = (props) => {
  return (
    <div className="chat-list__delimiter" style={props.style}>
      <span className="chat-list__delimiter-title">{props.children}</span>
    </div>
  );
};

export default ChatItemDelimiter;
