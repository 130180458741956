import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {ContextMenuItem} from '../stores/ContextMenuStore';
import {useStore} from '../stores/AppStore';
import {ModalType} from '../stores/ModalType';

interface IProps {
  menu: ContextMenuItem;
}

export const MenuNewChat: React.FC<IProps> = observer(() => {
  const {t} = useTranslation();
  const {contextMenu, modals} = useStore();

  const handleNewGroupClick = () => {
    contextMenu.close();
    modals.open(ModalType.GROUP_CREATING);
  };

  const handleOpenContacts = () => {
    contextMenu.close();
    modals.open(ModalType.CHAT_CREATING);
  };

  return (
    <div className="dropdown-menu">
      {/* {settings.systemUser?.allowCreateGroups ? ( */}
      <button type="button" className="dropdown-item" onClick={handleNewGroupClick}>
        <i className="dropdown-icon tg-icon-group-line" />
        {t('ctx_menu_new_chat_new_group')}
      </button>
      {/* ) : null} */}

      {/* {settings.systemUser?.allowCreateChatWithContact ? ( */}
      <button type="button" className="dropdown-item" onClick={handleOpenContacts}>
        <i className="dropdown-icon tg-icon-user-line" />
        {t('ctx_menu_new_chat_new_private_chat')}
      </button>
      {/* ) : null} */}
    </div>
  );
});

export default MenuNewChat;
