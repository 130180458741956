import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import Button from 'o-ui/Button';
import incomeEmailImg from '../../assets/images/income-email-bg.svg';
import logotypeWhiteImg from '../../assets/images/logotype-white.svg';
import logotypeImg from '../../assets/images/logotype.svg';
import Div100vh from '../../components/Div100vh';
import {Paths} from '../../routes/Paths';
import useStore from '../../stores/useStore';

const RegistrationCompletePage: React.FC = observer(() => {
  const {t} = useTranslation();
  const {lightThemeChosen, userStore} = useStore();

  const nextPath = !userStore.isLoggedIn ?
    Paths.Login :
    userStore.user?.isOwner ?
      Paths.Onboarding :
      Paths.QuickOnboarding;

  return (
    <Div100vh className="auth-page">
      <div className="auth-page__header">
        <img className="auth-page__header-logo" src={lightThemeChosen ? logotypeImg : logotypeWhiteImg} alt="" />
      </div>

      <div className="auth-page__content">
        <img className="auth-page__central-info-bg-img" src={incomeEmailImg} alt="" />
        <div className="auth-page__central-info">
          <div className="auth-page__central-info-title">{t('registration_complete_title')}</div>
          <div className="auth-page__central-info-sub-title">{t('registration_complete_subtitle')}</div>

          <Button
            className="mt-6"
            component={Link}
            to={nextPath}
            variant="contained"
            color="primary"
            size="large"
          >
            {t('registration_complete_to_onboarding')}
          </Button>
        </div>
      </div>
    </Div100vh>
  );
});

export default RegistrationCompletePage;
