import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {animated, useTransition} from 'react-spring';

import DialogContent from 'o-ui/Dialog/DialogContent';
import {api} from '../api/proto';
import {useStore} from '../stores/AppStore';
import {ModalItem} from '../stores/ModalsStore';
import WorkspaceMemberConfig from '../stores/Workspaces/WorkspaceMemberConfig';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';
import InvitationByLinkForm from '../pages/Settings/WorkspaceMembers/Invitation/InvitationByLinkForm';
import InvitationByLinkResult from '../pages/Settings/WorkspaceMembers/Invitation/InvitationByLinkResult';
import InvitationAccessForm from '../pages/Settings/WorkspaceMembers/Invitation/InvitationAccessForm';


enum StepType {
  DEFAULT = 0,
  CONFIG = 1,
  RESULT = 2,
}

interface IProps {
  modal: ModalItem;
}

export const ModalInviteMemberByLink: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const app = useStore();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const [currentStep, setCurrentStep] = React.useState<StepType>(StepType.DEFAULT);
  const [createdLink, setCreatedLink] = React.useState<api.IWorkspaceCreateInviteResponse | null>(null);
  const [memberConfig] = React.useState<WorkspaceMemberConfig>(new WorkspaceMemberConfig(app.activeWorkspace));

  const handleCloseClick = React.useCallback(() => {
    modalRef.current?.close();
  }, []);

  const handleSubmit = React.useCallback((inviteLink: api.IWorkspaceCreateInviteResponse) => {
    setCreatedLink(inviteLink);
    setCurrentStep(StepType.RESULT);
  }, []);

  const transitions = useTransition(currentStep, {
    from:
      !createdLink
        ? {}
        : {
          opacity: 0,
          transform: currentStep > 0 ? 'translate3d(100%, 0, 0)' : 'translate3d(-100%, 0, 0)',
        },
    enter: {opacity: 1, transform: 'translate3d(0%, 0, 0)'},
    leave: {
      opacity: 0,
      transform: currentStep > 0 ? 'translate3d(-100%, 0, 0)' : 'translate3d(100%, 0, 0)',
    },
  });

  const onOpenInvitationAccess = React.useCallback(() => {
    setCurrentStep(StepType.CONFIG);
  }, []);

  const goBackFromInvitationAccess = React.useCallback(() => {
    setCurrentStep(StepType.DEFAULT);
  }, []);

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      maxWidth="smr"
      fullWidth={true}
      title={createdLink ? t('settings_workspace_members_invite_link_created') : t('settings_workspace_members_invite_by_link')}
      onClose={handleCloseClick}
    >
      <DialogContent
        className="position-relative overflow-hidden p-6"
        style={{height: currentStep === StepType.RESULT ? '250px' : currentStep === StepType.CONFIG ? '642px' : '220px'}}
      >
        {transitions((styles, step) => step === StepType.RESULT ? (
          <animated.div style={styles} className="animated-tab p-6 h-100">
            <InvitationByLinkResult
              inviteLink={createdLink}
              onClose={handleCloseClick}
            />
          </animated.div>
        ) : step === StepType.CONFIG ? (
          <animated.div style={styles} className="animated-tab p-6 h-100">
            <InvitationAccessForm
              className="h-100"
              memberConfig={memberConfig}
              onClose={goBackFromInvitationAccess}
            />
          </animated.div>
        ) : (
          <animated.div style={styles} className="animated-tab p-6">
            <InvitationByLinkForm
              onSubmit={handleSubmit}
              onOpenInvitationAccess={onOpenInvitationAccess}
              memberConfig={memberConfig}
            />
          </animated.div>
        ))}
      </DialogContent>
    </ModalDialog>
  );
});

export default ModalInviteMemberByLink;
