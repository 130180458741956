import {MAX_MESSAGE_TEXT_LENGTH} from '../../../constants';
import {IMCTextEntities} from '../../../api/proto';
import {DeltaStatic} from '../../../components/QuillTextInput/QuillCore';
import {chunkString} from '../../../utils/arrayUtils';
import trimGuardText from '../../../utils/trimGuardText';
import convertDeltaToTextEntities from './convertDeltaToTextEntities';

const MATCH_SENTENCES_REG_EXP = /[^.?!]+[.!?]+[\])'"`��]*|.+/g;

export interface IChunkText {
  text: string;
  textEntities: IMCTextEntities[];
}

export default function chunkText(text: string, contents?: DeltaStatic, chunkSize: number = MAX_MESSAGE_TEXT_LENGTH): IChunkText[] {
  let sentences: string[] = trimGuardText(text || '').match(MATCH_SENTENCES_REG_EXP) || [];

  sentences = sentences.reduce((arr: string[], sentence: string) => {
    if (sentence.length > chunkSize) {
      chunkString(sentence, chunkSize).forEach((sen) => arr.push(sen));
    } else {
      arr.push(sentence);
    }
    return arr;
  }, []);


  let chunkText = '';
  let chunkTextEntities: IMCTextEntities[] = [];

  let subTextStartIdx = 0;
  let deltaStartIdx = 0;
  let totalLength = 0;

  const chunks: Array<{text: string; textEntities: IMCTextEntities[]}> = [];
  const sentencesLength = sentences.length;
  sentences.forEach((sentence, i) => {
    console.debug(`sentence ${i}: sentence.length=${sentence.length} text.length=${chunkText.length}`)
    totalLength += sentence.length;

    const last = i === sentencesLength - 1;
    const thisSentenceTooLarge = totalLength >= chunkSize;

    if (thisSentenceTooLarge || last) {
      chunks.push({text: chunkText, textEntities: chunkTextEntities});
      console.debug(`sentence ${i}: clear`)

      chunkText = '';
      chunkTextEntities = [];
      deltaStartIdx = 0;
      totalLength = 0;
    }

    if (i > 0) {
      subTextStartIdx += sentences[i - 1].length;
    }

    if (chunkText.length) {
      deltaStartIdx += sentences[i - 1].length;
    }

    const sentenceContents = contents?.slice(subTextStartIdx, subTextStartIdx + sentence.length);

    console.debug(`sentence ${i}: add sentence.length=${sentence.length}  to prev text.length=${chunkText.length}`)
    chunkText += sentence;
    chunkTextEntities = [...chunkTextEntities, ...convertDeltaToTextEntities(sentenceContents, sentence, deltaStartIdx)];

    if (last) {
      chunks.push({text: chunkText, textEntities: chunkTextEntities});
    }
  });

  return chunks.filter(({text, textEntities}) => text || textEntities.length);
}