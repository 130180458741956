import React, {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {useStore} from '../../stores/AppStore';
import Chat from '../../stores/Chat';
import ModalType from '../../stores/ModalType';
import {FileData, dataFileReader} from '../../utils/file/fileReaders';
import someFileIsEmpty from '../../utils/file/someFileIsEmpty';

interface IProps {
  chat: Chat;
  disabled?: boolean;
}

export const FilePasteUploader: React.FC<IProps> = (props) => {
  const {t} = useTranslation();
  const {modals, notifications} = useStore();

  const handleSend = useCallback(
    (dataFiles: FileData[]) => {
      const photoVideoModal = modals.findModal(ModalType.UPLOAD_PHOTO_VIDEO_ASYNC);
      const fileModal = modals.findModal(ModalType.UPLOAD_FILE_ASYNC);

      const isPhotoVideoFiles = dataFiles.every((f) => f.image || f.video);

      if (photoVideoModal) {
        if (isPhotoVideoFiles) {
          photoVideoModal.updateData({
            dataFiles: [...(photoVideoModal.data.dataFiles || []), ...dataFiles],
            chat: props.chat,
          });
        } else {
          modals.open(ModalType.UPLOAD_FILE_ASYNC, {
            dataFiles: [...(photoVideoModal.data.dataFiles || []), ...dataFiles],
            chat: props.chat,
          });

          photoVideoModal.close();
        }
      } else if (fileModal) {
        fileModal.updateData({
          dataFiles: [...(fileModal.data.dataFiles || []), ...dataFiles],
          chat: props.chat,
        });
      } else {
        modals.open(isPhotoVideoFiles ? ModalType.UPLOAD_PHOTO_VIDEO_ASYNC : ModalType.UPLOAD_FILE_ASYNC, {
          dataFiles,
          chat: props.chat,
        });
      }
    },
    [modals, props.chat],
  );

  const pasteListener = useCallback(
    (event) => {
      if (props.disabled) {
        return;
      }

      const items = event.clipboardData?.items;

      if (items) {
        const files: File[] = [];

        for (const index in items) {
          const item = items[index];

          if (item.kind === 'file') {
            const blob = item.getAsFile();
            if (blob) {
              files.push(blob);
            }
          }
        }

        if (files.length) {
          if (someFileIsEmpty(files)) {
            notifications.error(t('file_uploader_file_is_empty'));
            return;
          }
          dataFileReader(files, handleSend);
          event.preventDefault();
        }
      }
    },
    [
      handleSend,
      props.disabled,
      notifications,
      t,
    ],
  );

  useEffect(() => {
    window.document.addEventListener('paste', pasteListener);

    return () => {
      window.document.removeEventListener('paste', pasteListener);
    };
  }, [pasteListener]);

  return null;
};
