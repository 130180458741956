import React from 'react';

const longPressDuration = 450;

export function useContextMenuHandler(callback) {
  const [longPressCountdown, setLongPressCountdown] = React.useState<NodeJS.Timeout | undefined>();

  const clear = () => {
    longPressCountdown && clearTimeout(longPressCountdown);
  };

  const onTouchStart = (...args) => {
    setLongPressCountdown(
      setTimeout(() => {
        callback(...args);
      }, longPressDuration),
    );
  };

  const onTouchMove = () => {
    clear();
  };

  const onTouchCancel = () => {
    clear();
  };

  const onTouchEnd = () => {
    clear();
  };

  const onContextMenu = (...args) => {
    clear();

    callback(...args);
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchCancel,
    onTouchEnd,
    onContextMenu,
  };
}

export default useContextMenuHandler;
