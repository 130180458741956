import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import MenuList from 'o-ui/Select/MenuList';
import MenuItem from 'o-ui/Select/MenuItem';
import ListItemIcon from 'o-ui/ListItemIcon';
import {ReactComponent as Chats} from '../../../assets/image-icons/chats.svg';
import {ReactComponent as ChatsDarkTheme} from '../../../assets/image-icons/chatsDarkTheme.svg';
import {ReactComponent as ChatsHover} from '../../../assets/image-icons/chatsHover.svg';
import {ReactComponent as ChatsActive} from '../../../assets/image-icons/chatsActive.svg';
import {ReactComponent as ChatsActiveDarkTheme} from '../../../assets/image-icons/chatsActiveDarkTheme.svg';
import {ReactComponent as Contacts} from '../../../assets/image-icons/contacts.svg';
import {ReactComponent as ContactsDarkTheme} from '../../../assets/image-icons/contactsDarkTheme.svg';
import {ReactComponent as ContactsHover} from '../../../assets/image-icons/contactsHover.svg';
import {ReactComponent as ContactsActive} from '../../../assets/image-icons/contactsActive.svg';
import {ReactComponent as ContactsActiveDarkTheme} from '../../../assets/image-icons/contactsActiveDarkTheme.svg';
import {useStore} from '../../../stores/AppStore';
import {ChatTab} from '../../../stores/LayOutStore';
import {MAX_WIDTH_FOR_ENABLE_EMBEDDED_LEFT_BAR} from '../../../config';


const BottomMenu: React.FC<React.PropsWithChildren> = observer(() => {
  const {channels, lightThemeChosen, layOutStore} = useStore();
  const {t} = useTranslation();

  const closeSideMenuIfNeeded = () => {
    if (window.innerWidth <= MAX_WIDTH_FOR_ENABLE_EMBEDDED_LEFT_BAR) {
      layOutStore.setLeftBarCollapsed(true);
    }
  };

  const handleOpenContacts = () => {
    layOutStore.setActiveTab(ChatTab.CONTACTS);
    closeSideMenuIfNeeded();
  };

  const handleOpenChats = () => {
    layOutStore.setActiveTab(ChatTab.CHATS);
    closeSideMenuIfNeeded();
  };

  if (!layOutStore.chatLeftSidebarBottomMenuVisible) {
    return null;
  }

  return (
    <MenuList className="bottom-menu">
      <MenuItem
        className={cn('bottom-menu__item', {disabled: !channels.isInit})}
        onClick={handleOpenChats}
        selected={layOutStore.activeTab === ChatTab.CHATS}
        disableRipple
      >
        <ListItemIcon className="bottom-menu__icon-wrapper">
          {lightThemeChosen ? (
            <>
              <Chats className="bottom-menu__icon" />
              <ChatsActive className="bottom-menu__icon bottom-menu__icon--active" />
            </>
          ) : (
            <>
              <ChatsDarkTheme className="bottom-menu__icon" />
              <ChatsActiveDarkTheme className="bottom-menu__icon bottom-menu__icon--active" />
            </>
          )}
          <ChatsHover className="bottom-menu__icon bottom-menu__icon--hover" />
        </ListItemIcon>
        <span className="bottom-menu__title">{t('Chats')}</span>
      </MenuItem>
      <MenuItem
        className={cn('bottom-menu__item', {disabled: !channels.isInit})}
        onClick={handleOpenContacts}
        selected={layOutStore.activeTab === ChatTab.CONTACTS}
        disableRipple
      >
        <ListItemIcon className="bottom-menu__icon-wrapper">
          {lightThemeChosen ? (
            <>
              <Contacts className="bottom-menu__icon" />
              <ContactsActive className="bottom-menu__icon bottom-menu__icon--active" />
            </>
          ) : (
            <>
              <ContactsDarkTheme className="bottom-menu__icon" />
              <ContactsActiveDarkTheme className="bottom-menu__icon bottom-menu__icon--active" />
            </>
          )}
          <ContactsHover className="bottom-menu__icon bottom-menu__icon--hover" />
        </ListItemIcon>
        <span className="bottom-menu__title">{t('Contacts')}</span>
      </MenuItem>
    </MenuList>
  );
});

export default BottomMenu;
