import cn from 'classnames';
import Long from 'long';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import useWorkspaceNavigate from '../../../../hooks/useWorkspaceNavigate';
import Paths from '../../../../routes/Paths';
import useStore from '../../../../stores/useStore';
import {BILLING_DATE_FORMAT, formatDateTime} from '../../../../utils/format';

import {ReactComponent as SuccessIcon} from '../../../../assets/images/success-circle-gray.svg';

interface CancelSubscriptionSuccessProps {
  className?: string;
  style?: React.CSSProperties;
  onClose?: () => void;
}

export const CancelSubscriptionSuccess = observer(({
  className,
  style,
  onClose,
}: CancelSubscriptionSuccessProps) => {
  const {t} = useTranslation();

  const {activeWorkspace: {billing}} = useStore();

  const workspaceNavigate = useWorkspaceNavigate();

  const handleClickGoHome = () => {
    workspaceNavigate(Paths.Chat);
  };

  return (
    <div
      className={cn(
        'bg-background-primary d-inline-flex flex-column radius-8 border-primary py-5',
        className,
      )}
      style={style}
    >
      <SuccessIcon className="ml-6 mb-5" />

      <div className="px-6 d-inline-flex align-items-center">
        <span className="h3-medium">
          {t('settings_billing_cancelled_subscription_title')}
        </span>
      </div>

      <div className="px-6 mt-1-5 d-inline-flex align-items-center">
        {t('settings_billing_cancelled_subscription_subtitle')}
      </div>

      <div className="px-6 mt-5">
        <div className="d-flex border-primary radius-8 p-5">
          <div className="d-flex flex-column pr-5">
            <div className="body3-regular color-body-secondary">
              {t('settings_billing_cancelled_subscription_current_plan')}
            </div>
            <div>
              {billing.currentTariff?.name}
            </div>
          </div>

          <div className="d-flex flex-column">
            <div className="body3-regular color-body-secondary">
              {t('settings_billing_cancelled_subscription_next_billing_date')}
            </div>
            <div>
              {billing?.expiresAt?.greaterThan(Long.ZERO) ? formatDateTime(billing.expiresAt, true, BILLING_DATE_FORMAT) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="px-6 mt-5 d-inline-flex align-items-center">
        {t('settings_billing_cancelled_subscription_info')}
      </div>

      <div className="px-6 mt-5">
        <Button
          className="mr-4"
          variant="contained"
          size="large"
          onClick={onClose}
        >
          {t('settings_billing_button_browse_plans')}
        </Button>

        <Button
          variant="outlined"
          color="tertiary"
          size="large"
          onClick={handleClickGoHome}
        >
          {t('settings_billing_button_go_home')}
        </Button>
      </div>

    </div>
  );
});

export default CancelSubscriptionSuccess;