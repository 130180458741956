import React from 'react';

import {UserChat} from './UserChat';
import {ContextMenu} from '../../contextMenu/ContextMenu';
import {RightSideBar} from './UserChat/ChatInfo/RightSideBar';
import useChatPageRoute from './useChatPageRoute';

export const ChatPage: React.FC<React.PropsWithChildren> = React.memo(() => {
  useChatPageRoute();

  return (
    <>
      <UserChat />
      <RightSideBar />
      <ContextMenu />
    </>
  );
});

export default ChatPage;
