import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import SettingsTab from '../../stores/LayOutStore/SettingsTab';
import ModalType from '../../stores/ModalType';
import useStore from '../../stores/useStore';
import PersonalInfoForm, {IPersonalInfoFormRef} from './PersonalInfo/PersonalInfoForm';
import {SettingsHeadState} from './SettingsHead';
import useAllowChangeHeadState from './useAllowChangeHeadState';

interface IProps {
  onChangeState?: (state: SettingsHeadState) => void;
}

export const PersonalInfoTab = React.memo((props: IProps) => {
  const {t} = useTranslation();
  const {modals} = useStore();
  const formRef = React.useRef<IPersonalInfoFormRef>(null);
  const [disabledSubmit, setDisabledSubmit] = React.useState<boolean>(true);

  const allowChangeHeadState = useAllowChangeHeadState(SettingsTab.PERSONAL_INFO);

  React.useEffect(() => {
    if (!allowChangeHeadState) {
      return;
    }

    props.onChangeState?.({
      title: t('settings_menu_profile'),
    });
  }, [props, allowChangeHeadState, t]);

  const handleSubmit = () => {
    formRef.current?.submitForm?.();
    setDisabledSubmit(true);
  };

  const handleOpenChangeEmail = () => {
    modals.open(ModalType.PROFILE_CHANGE_EMAIL);
  };

  const handleOpenChangePassword = () => {
    modals.open(ModalType.PROFILE_CHANGE_PASSWORD);
  };

  const handleClose = () => {
    setDisabledSubmit(true);
    formRef.current?.resetForm?.();
  };

  const handleChange = () => {
    setDisabledSubmit(false);
  };

  return (
    <div className="settings-tab with-footer">
      <div className="settings-tab__body custom-scroll-y">
        <div className="settings-tab__paper settings-tab__paper--472 mb-5">
          <div className="label-2 text-uppercase mb-5">{t('settings_personal_info_title')}</div>

          <PersonalInfoForm ref={formRef} onChange={handleChange} />

          <div className="settings-tab__row">
            <Button
              className="mr-3"
              variant="contained"
              color="secondary"
              onClick={handleOpenChangeEmail}
            >
              {t('settings_personal_info_change_email')}
            </Button>
            <Button
              className="text-capitalize"
              variant="contained"
              color="secondary"
              onClick={handleOpenChangePassword}
            >
              {t('settings_personal_info_change_password')}
            </Button>
          </div>
        </div>
      </div>

      <div className="settings-tab__footer">
        <div className="settings-tab__row">
          <Button
            className="mr-5"
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
            disabled={disabledSubmit}
          >
            {t('settings_personal_info_update')}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleClose}
            disabled={disabledSubmit}
          >
            {t('settings_personal_info_cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default PersonalInfoTab;
