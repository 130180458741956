import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import {DialogActions, DialogContent} from 'o-ui/Dialog';

import {BRAND_NAME} from '../config';
import useStore from '../stores/useStore';
import {ModalItem} from '../stores/ModalsStore';
import ModalDialog from './components/ModalDialog';

import {ReactComponent as NewVersionIcon} from '../assets/icons/new-version-fill.svg';

interface IProps {
  modal: ModalItem;
}

export const ModalForceVersionUpdateApp: React.FC<IProps> = observer((props) => {
  const {appVersionUpdater} = useStore();
  const {t} = useTranslation();

  const handleUpdate = () => {
    props.modal.data.callbacks?.onUpdate(props.modal.data);
  };

  return (
    <ModalDialog
      className="force-update-modal"
      modal={props.modal}
      withoutCloseButton
      title={
        <>
          <NewVersionIcon className="force-update-modal__icon mr-3" />
          {t('app_version_updater_important_update_available')}
        </>
      }
      fullScreen={false}
      closeOnEsc={false}
      maxWidth="md"
    >
      <DialogContent className="force-update-modal__content">
        {t('app_version_updater_mandatory_version_of_app', {brandName: BRAND_NAME})}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleUpdate}
          size="large"
          color="primary"
          variant="contained"
          disabled={appVersionUpdater.isUpdating}
          loading={appVersionUpdater.isUpdating}
        >
          {t('app_version_updater_update_now_btn')}
        </Button>
      </DialogActions>
    </ModalDialog>
  );
});

export default ModalForceVersionUpdateApp;
