import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../../stores/AppStore';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import ResendVerificationEmailForm from './ResendVerificationEmailForm';
import FormHelperText from 'o-ui/FormHelperText';
import FormLabel from 'o-ui/FormLabel';
import {getUserResponseStatusError} from '../../../api/getErrorByType';

export interface IPersonalInfoFormRef {
  submitForm?: () => void;
  resetForm?: () => void;
}

interface IProps {
  onSubmit?: () => void;
  onChange?: React.ChangeEventHandler;
}

export const PersonalInfoForm = observer(
  React.forwardRef<IPersonalInfoFormRef, IProps>((props: IProps, ref) => {
    const {notifications, userStore} = useStore();
    const {t} = useTranslation();

    const [apiError, setApiError] = React.useState<string | null | undefined>('');

    const formik = useFormik({
      initialValues: {
        firstName: userStore.user?.firstName || '',
        lastName: userStore.user?.lastName || '',
      },
      validationSchema: Yup.object({
        firstName: Yup.string()
          .required(t('settings_personal_info_first_name_required'))
          .max(32, t('settings_personal_info_name_length_validation', {maxLength: 32})),
        lastName: Yup.string()
          .required(t('settings_personal_info_last_name_required'))
          .max(32, t('settings_personal_info_name_length_validation', {maxLength: 32})),
      }),
      validateOnBlur: true,
      onSubmit: async (values) => {
        setApiError('');
        const {error, res} = await userStore.updateUserDetails(values);

        if (error) {
          setApiError(error.message);
          notifications.error(error.message);
        }

        if (res) {
          const statusErrorMessage = getUserResponseStatusError('changeDetails', res.status);

          if (statusErrorMessage) {
            notifications.error(statusErrorMessage);
            setApiError(statusErrorMessage);
            return;
          }

          notifications.success(t('settings_personal_info_submit_success'));
          props.onSubmit && props.onSubmit();
        }
      },
      onReset: () => {
        setApiError('');
      },
    });

    React.useImperativeHandle(ref, () => ({
      submitForm: () => formik.submitForm(),
      resetForm: () => {
        formik.setFieldValue('firstName', userStore.user?.firstName || '', false);
        formik.setFieldValue('lastName', userStore.user?.lastName || '', false);
      },
    }));

    React.useEffect(() => {
      formik.setFieldValue('firstName', userStore.user?.firstName || '', false);
      formik.setFieldValue('lastName', userStore.user?.lastName || '', false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore.user]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.handleChange(e);
      props.onChange?.(e);
    };

    return (
      <form className="personal-info-form" onSubmit={formik.handleSubmit}>
        <FormLabel className="text-capitalize">{t('settings_personal_info_first_name_placeholder')}</FormLabel>
        <OutlinedTextInput
          className="w-100"
          name="firstName"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
          required
          errorHelper={formik.errors.firstName || ' '}
        />

        <FormLabel className="text-capitalize">{t('settings_personal_info_last_name_placeholder')}</FormLabel>
        <OutlinedTextInput
          className="w-100"
          name="lastName"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastName}
          required
          errorHelper={formik.errors.lastName || ' '}
        />

        <FormLabel>{t('settings_personal_info_email_placeholder')}</FormLabel>
        <div
          className={cn('personal-info-form__email', {
            'not-verified': userStore.user?.emailNotConfirmed,
          })}
        >
          <OutlinedTextInput
            className="personal-info-form__email-input w-100"
            name="email"
            value={userStore.user?.email || ''}
          />

          {userStore.user?.emailNotConfirmed ? (
            <ResendVerificationEmailForm />
          ) : (
            <span className="personal-info-form__verified-status body3-bold">
              <span className="tg-icon tg-icon-check-line font-size-18 mr-2" />
              {t('settings_personal_info_email_verified')}
            </span>
          )}
        </div>

        <FormHelperText className="mt-2" error>
          {apiError || ' '}
        </FormHelperText>
      </form>
    );
  }),
);

export default PersonalInfoForm;
