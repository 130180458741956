import imageCompression from 'browser-image-compression';
import {FileData} from './fileReaders';

async function compress(file: File, onSuccess: (file: File) => void, onError?: (error: any) => void) {
    try {
      const compressedFile: File = await imageCompression(file, {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 2000,
        initialQuality: 0.5,
      });
      onSuccess(compressedFile);
    } catch (error) {
      console.log(error);
      onError && onError(error);
    }
}

export function compressImage(dataFile: FileData): Promise<FileData> {
  return new Promise((resolve, reject) => {
    compress(dataFile.file, (compressedFile: File) => {
      const reader = new FileReader();
      const image = new Image();
      const objectUrl = URL.createObjectURL(compressedFile);
      image.src = objectUrl;

      image.onload = () => {
        reader.readAsArrayBuffer(compressedFile);
      };
      reader.addEventListener('load', () => {
        resolve({
          ...dataFile,
          file: compressedFile,
          image: image,
          width: image.width,
          height: image.height,
        });
      });
      reader.addEventListener('error', () => {
        reject(dataFile);
      });
    },
    () => {
      reject(dataFile);
    });
  });
}

export default compressImage;