import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import FormHelperText from 'o-ui/FormHelperText';
import ModalType from '../../../stores/ModalType';
import useStore from '../../../stores/useStore';

export const ResendVerificationEmailForm = observer(() => {
  const {t} = useTranslation();
  const {modals, notifications, userStore} = useStore();
  const [apiError, setApiError] = React.useState<string | null | undefined>('');

  const handleResend = async () => {
    setApiError('');
    const {error, res} = await userStore.resendConfirmationEmailCode();
    if (error) {
      setApiError(error.message);
      notifications.error(error.message);
    }
    if (res) {
      notifications.success(t('Verification email has been successfully sent'));
    }

    modals.open(ModalType.PROFILE_VERIFICATION_EMAIL, {}, true, true);
  };

  return (
    <div className="personal-info-form__verification-status">
      <p className="personal-info-form__verification-status-title body2-bold mb-3">
        {t('settings_personal_info_verify_email_title')}
      </p>
      <p className="personal-info-form__verification-status-info body3-regular mb-5">
        {t('settings_personal_info_verify_email_subtitle')}
      </p>

      <div className="d-flex w-100">
        <Button
          className="mr-3"
          variant="contained"
          color="primary"
          size="small"
          onClick={handleResend}
        >
          {t('settings_personal_info_verify_email')}
        </Button>

        <FormHelperText error>{apiError || ' '}</FormHelperText>
      </div>
    </div>
  );
});

export default ResendVerificationEmailForm;
