import {action, makeObservable, observable} from 'mobx';

import {IAPIRequest, APIRequest, APIResponse} from './proto';
import wait from '../utils/wait';
import getErrorByType from './getErrorByType';
import EventEmitter from './EventEmitter';
import {AppStore} from '../stores/AppStore';
import {equalUint8Arrays, randomUint8Array, uint8ArrayToUuid} from '../utils/arrayUtils';
import {isObject} from '../utils/isObject';
import getQueryStringParam from '../utils/getQueryStringParam';


const debug: string = getQueryStringParam('debug');

interface IEventHandler {
  eventName: keyof APIResponse;
  handler?: (res: APIResponse) => void;
}

export enum RequestPriority {
  LOW = -1,
  NORMAL = 0,
  MEDIUM = 1,
  HIGHT = 2,
  FIRST = 3,
}

export enum ResponseCode {
  DEFAULT = 0,
  CUSTOM = 1,
  IN_PROCESS = 2,
  TOO_FREQUENT = 3,
}

export interface IErrorEvent<T = APIResponse.Status> {
  type?: T | null;
  message?: string | null;
  systemMessage?: string | null;
  trx?: string | null;
}

export class ErrorEvent<T = APIResponse.Status> {
  constructor(properties?: IErrorEvent<T>) {
    this.type = properties?.type;
    this.message = properties?.message;
    this.systemMessage = properties?.systemMessage;
    this.trx = properties?.trx;
  }

  public type?: T | null;

  public message?: string | null;
  public systemMessage?: string | null;

  public trx?: string | null;
}

export type ResponseType = {
  [key: string]: any;
};

export type NetworkResponse<R extends ResponseType = any, T = APIResponse.Status> = {
  error: IErrorEvent<T> | null;
  res?: R | null;
  code?: ResponseCode | null;
  trx?: string | null;
};

export class NetworkErrorResponse<R extends ResponseType = any, T = APIResponse.Status> implements NetworkResponse<R, T> {
  error: IErrorEvent<T> | null;
  res: R | null = null;
  code: ResponseCode | null = ResponseCode.CUSTOM;

  constructor(message?: string | null, code: ResponseCode = ResponseCode.CUSTOM) {
    this.error = new ErrorEvent({message});
    this.code = code;
  }
}

export type LogLevel = 'disabled' | 'info' | 'debug' | null;

export type ProgressEvent = {
  total: number;
  loaded: number;
  progress: number;
};

export type OnProgressCallBack = (progressEvent: ProgressEvent) => void;

export interface RequestOptions {
  timeout?: number | null;
  priority?: RequestPriority | null;
  logLevel?: LogLevel;
  onProgress?: OnProgressCallBack;
  trx?: Uint8Array | null;
}

interface IResponseHandler {
  id: Uint8Array;
  eventName: keyof APIResponse | null;
  reqData: IAPIRequest;
  resultHandler: (res: APIResponse[keyof APIResponse] | null, reqData: IAPIRequest) => void;
  errorHandler?: (res: IErrorEvent, reqData: IAPIRequest) => void;
  timer?: NodeJS.Timeout | null;
  priority: RequestPriority;
  stamp: number;
  logLevel?: LogLevel;
  onProgress?: OnProgressCallBack;
}

export interface IConnectionOpenEvent {
  initial?: boolean;
}

const WS_END_POINT = '/api';
const WS_RECONNECT_DELAY = 2000;
const WS_RECONNECT_TIMEOUT = 20000;
const UPLOAD_SIZE_MISTAKE = 200;

export enum NetworkEvent {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',

  BEFORE_CONNECTION_OPEN = 'BEFORE_CONNECTION_OPEN',
  CONNECTION_OPEN = 'CONNECTION_OPEN',
  CONNECTION_CLOSE = 'CONNECTION_CLOSE',

  BEFORE_RESEND_WAITING_REQUESTS = 'BEFORE_RESEND_WAITING_REQUESTS',
  RESEND_WAITING_REQUESTS_DONE = 'RESEND_WAITING_REQUESTS_DONE',

  API_ERROR = 'API_ERROR',
}

export class Network extends EventEmitter {
  public debug: string = debug;

  private connectionStamp: number = 0;
  private webSocket?: WebSocket;
  private eventHandlers: IEventHandler[] = [];
  private responseHandlers: IResponseHandler[] = [];

  constructor(protected app: AppStore) {
    super();
    makeObservable(this);
    this.connect();

    window.addEventListener('offline', () => {
      this.emit(NetworkEvent.OFFLINE);
      this.setOnLine_(false);
    });

    window.addEventListener('online', () => {
      this.emit(NetworkEvent.ONLINE);
      this.setOnLine_(true);
    });
  }

  @observable public initialized = false;

  @observable public navigatorOnLine = true;
  @observable public connectionIsOpen = false;
  protected connectionOpening = false;

  @action protected setOnLine_ = (onLine: boolean) => {
    this.navigatorOnLine = onLine;
  };

  @action protected onopen_ = async (ev) => {
    console.debug(`%c${new Date().toISOString()} - web socket: connection open in ${new Date().getTime() - this.connectionStamp}ms: `, 'color: green', ev);

    this.clearReconnectionTimeout();
    this.reconecting = false;
    this.connectionIsOpen = true;
    this.connectionOpening = true;

    this.emit(NetworkEvent.BEFORE_CONNECTION_OPEN, {initial: !this.initialized});

    this.connectionOpening = false;
    await this.reSendWaitingRequests();

    this.emit(NetworkEvent.CONNECTION_OPEN, {initial: !this.initialized});

    this.initialized = true;
  };

  protected onerror_ = (e) => {
    console.debug(`%c${new Date().toISOString()} - web socket: experienced an error in ${new Date().getTime() - this.connectionStamp}ms: `, 'color: red', e);
  };

  private throwWaitingHandlerByTimout = (requestId: Uint8Array) => {
    const responseHandler = this.responseHandlers.find((h) => equalUint8Arrays(h.id, requestId));

    if (responseHandler?.errorHandler) {
      responseHandler.errorHandler({message: 'timeout error'}, responseHandler.reqData);
      console.error('response timeout', responseHandler.reqData);
    }

    this.removeResponseHandler(requestId);
  };

  private sortResponseHandlers = (a: IResponseHandler, b: IResponseHandler): number => b.priority - a.priority;
  private resending: boolean = false;

  private reSendWaitingRequests = async () => {
    this.emit(NetworkEvent.BEFORE_RESEND_WAITING_REQUESTS);

    this.responseHandlers = this.responseHandlers.sort(this.sortResponseHandlers);
    this.resending = true;

    try {
      let i = 0;
      const responseHandlers = this.responseHandlers.slice();
      const length = responseHandlers.length;
      for (; i < length; i++) {
        if (responseHandlers[i]) {
          this.reSendRequest(responseHandlers[i]);
          await wait(40);
        }
      }
    } catch (e) {
      console.error(e);
    }

    this.resending = false;
    this.emit(NetworkEvent.RESEND_WAITING_REQUESTS_DONE);
  };

  @action private onclose = (e: CloseEvent) => {
    console.debug(`%c${new Date().toISOString()} - web socket: connection is closed:`, 'color: red', e);
    this.emit(NetworkEvent.CONNECTION_CLOSE);
    this.connectionIsOpen = false;
    this.reconect();
  };

  private logResponse = (serverMessage: APIResponse, duration: number, logLevel?: LogLevel) => {
    (logLevel === 'info' ? console.info : console.debug)(
      `%c-->ws:message (${new Date().toISOString()}) %c${duration ? '(duration: ' + duration + 'ms)' : ''}`,
      'color: black',
      `font-weight: bold; color: ${this.getDurationColor(duration)}`,
      serverMessage,
      serverMessage.refetchTip ? null : uint8ArrayToUuid(serverMessage.trx),
    );
  };

  private getDurationColor = (duration: number) => {
    if (duration > 1000) {
      return 'red';
    } else if (duration > 400) {
      return 'orange';
    }
    return 'green';
  };

  private logError = (serverMessage: APIResponse, duration: number) => {
    console.debug(
      `%c-->ws:message (${new Date().toISOString()}) %c${duration ? '(duration: ' + duration + 'ms)' : ''} %c${getErrorByType(serverMessage.status)}`,
      'color: red',
      `font-weight: bold; color: ${this.getDurationColor(duration)}`,
      'font-weight: bold; color: red',
      serverMessage,
      uint8ArrayToUuid(serverMessage.trx),
    );
  };

  protected onmessage = (message: MessageEvent) => {
    if (!message || !message.data) {
      return;
    }
    (message.data as Blob).arrayBuffer().then(this.processMessageEvent);
  };

  protected processMessageEvent = (bytes: ArrayBuffer) => {
    const serverMessage = APIResponse.decode(new Uint8Array(bytes));
    const responseHandler = this.responseHandlers.find((t) => equalUint8Arrays(t.id, serverMessage?.trx));
    const stamp = responseHandler?.stamp || 0;
    const duration = stamp ? new Date().getTime() - stamp : 0;

    if (!serverMessage.status && (responseHandler?.logLevel !== 'disabled' || this.debug === 'low')) {
      this.logResponse(serverMessage, duration, responseHandler?.logLevel);
    } else if (serverMessage.status) {
      this.logError(serverMessage, duration);
    }

    if (serverMessage.status) {
      this.emit(NetworkEvent.API_ERROR, serverMessage);
    }

    if (responseHandler && (
      serverMessage.status === APIResponse.Status.AS_INTERNAL_ERROR ||
      serverMessage.status === APIResponse.Status.AS_PENDING_GREETINGS
    )) {
      this.reSendRequest(responseHandler);
      return;
    }

    if (responseHandler) {
      this.callResponseHandler(responseHandler, serverMessage);
    }

    for (const messageKey in serverMessage) {
      if (!Object.prototype.hasOwnProperty.call(serverMessage, messageKey)) {
        continue;
      }
      this.callEventHandler(messageKey, serverMessage);
    }
  };

  protected callResponseHandler = (responseHandler: IResponseHandler, serverMessage: APIResponse) => {
    if (responseHandler.timer) {
      clearTimeout(responseHandler.timer);
    }

    if (serverMessage.status && responseHandler.errorHandler) {
      const message = getErrorByType(serverMessage.status);
      const error = new ErrorEvent({
        message,
        type: serverMessage.status,
      });
      responseHandler.errorHandler(error, responseHandler.reqData);
    } else {
      responseHandler.resultHandler(
        responseHandler.eventName ? serverMessage[responseHandler.eventName] : null,
        responseHandler.reqData,
      );
    }
    this.removeResponseHandler(serverMessage.trx);
  };

  protected callEventHandler = (messageKey: string, serverMessage: APIResponse) => {
    this.eventHandlers.forEach((t) => {
      try {
        if (t.eventName.toLowerCase() === messageKey.toLowerCase()) {
          t.handler?.(serverMessage[messageKey]);
        }
      } catch (e) {
        console.debug(e);
      }
    });

    this.emit(messageKey, serverMessage[messageKey]);
    this.callSubEventHandler(messageKey, serverMessage);
  };

  protected callSubEventHandler = (messageKey: string, serverMessage: APIResponse) => {
    try {
      const messageData = serverMessage[messageKey];

      for (const subMessageKey in messageData) {
        if (!isObject(messageData) || !Object.prototype.hasOwnProperty.call(messageData, subMessageKey)) {
          continue;
        }

        this.emit(`${messageKey}.${subMessageKey}`, messageData[subMessageKey]);
      }
    } catch (e) {
      console.debug(e);
    }
  };

  protected reconnectionTimeout: NodeJS.Timeout | null = null;

  protected clearReconnectionTimeout = () => {
    if (this.reconnectionTimeout) {
      console.debug(`%c${new Date().toISOString()} - web socket: reconnection timeout clear`, 'color: gray');
      clearTimeout(this.reconnectionTimeout);
      this.reconnectionTimeout = null;
    }
  };

  protected connect = () => {
    const url = `${window.location.protocol.indexOf('https') >= 0 ? 'wss' : 'ws'}://${window.location.host
      }${WS_END_POINT}`;
    console.debug('connecting to URL: ', url);

    this.clearReconnectionTimeout();
    this.connectionStamp = new Date().getTime();
    this.webSocket = new WebSocket(url);

    this.webSocket.onopen = this.onopen_;
    this.webSocket.onmessage = this.onmessage;
    this.webSocket.onerror = this.onerror_;
    this.webSocket.onclose = this.onclose;

    this.reconnectionTimeout = setTimeout(() => {
      console.debug(`%c${new Date().toISOString()} - web socket: connection closed by timeout`, 'color: red');
      this.webSocket?.close();
    }, WS_RECONNECT_TIMEOUT);
  };

  protected close = () => {
    console.debug('web socket: manual close');
    this.webSocket?.close();
  };

  protected reconecting = false;

  protected reconect = async () => {
    this.reconecting = true;
    console.debug('web socket reconnecting...');
    await wait(WS_RECONNECT_DELAY);
    this.connect();
  };

  public getConnectionStateName = () => {
    if (this.webSocket?.readyState === WebSocket.OPEN) {
      return 'OPEN';
    } else if (this.webSocket?.readyState === WebSocket.CLOSED) {
      return 'CLOSED';
    } else if (this.webSocket?.readyState === WebSocket.CLOSING) {
      return 'CLOSING';
    } else if (this.webSocket?.readyState === WebSocket.CONNECTING) {
      return 'CONNECTING';
    }
    return '';
  };

  public checkConnectionState = () => {
    if (this.webSocket?.readyState !== WebSocket.OPEN) {
      console.error(`-->ws: is ${this.getConnectionStateName()}`, this.reconecting ? ' - reconecting...' : '');
    }
  };

  protected reSendRequest = (responseHandler: IResponseHandler) => {
    if (this.webSocket?.readyState !== WebSocket.OPEN) {
      this.checkConnectionState();
      return;
    }

    (responseHandler.logLevel === 'info' ? console.info : console.debug)(
      `<--ws:resend (${new Date().toISOString()})`,
      responseHandler.reqData,
      uint8ArrayToUuid(responseHandler.reqData.trx)
    );
    responseHandler.stamp = new Date().getTime();
    this.webSocket?.send(APIRequest.encode(responseHandler.reqData).finish());
  };

  private asyncRequest = <T = any>(
    data: IAPIRequest,
    responseType: keyof APIResponse | null = null,
    {
      timeout,
      priority,
      logLevel,
      onProgress,
      trx,
    }: RequestOptions = {},
  ): Promise<{res: T; trx?: string | null}> => {
    return new Promise((resolve, reject) => {
      trx = trx || randomUint8Array();
      data = {
        ...data,
        trx,
      };

      this.addResponseHandler(
        trx,
        data,
        responseType,
        (res: T, reqData: IAPIRequest) => {
          resolve({res, trx: uint8ArrayToUuid(reqData.trx)});
        },
        (res: IErrorEvent, reqData: IAPIRequest) => {
          res.message = getErrorByType(res.type) || res.message;
          res.trx = uint8ArrayToUuid(reqData.trx);
          reject(res);
        },
        timeout,
        priority,
        logLevel,
        onProgress,
      );

      if (logLevel === 'info') {
        console.info(`<--ws:${this.connectionOpening ? 'added-to-resend' : 'send'} (${new Date().toISOString()})`, data, uint8ArrayToUuid(trx));
      } else if (logLevel !== 'disabled' || this.debug === 'low') {
        console.debug(`<--ws:${this.connectionOpening ? 'added-to-resend' : 'send'} (${new Date().toISOString()})`, data, uint8ArrayToUuid(trx));
      }

      if (this.webSocket?.readyState !== WebSocket.OPEN) {
        this.checkConnectionState();
        //reject('ws is closed');
      } else {
        const bufferedAmountBeforeRequest = this.webSocket?.bufferedAmount;

        const wsData = APIRequest.encode(data).finish();

        if (!this.connectionOpening) {
          this.webSocket?.send(wsData);
        }

        if (onProgress) {
          this.triggerProgress_(trx, onProgress, wsData.length, bufferedAmountBeforeRequest, 0);
        }
      }
    });
  };

  private triggerProgress_ = async (trx: Uint8Array, onProgress: OnProgressCallBack, totalSize: number, initialSize: number, prevSendSize: number) => {
    const handler = this.findResponseHandler(trx);
    if (!handler) {
      onProgress?.({
        total: totalSize,
        loaded: totalSize,
        progress: 1,
      });
      console.debug(`%conProgress - finished by handler`, 'color: gray');
      return;
    }

    const leftSize = this.webSocket?.bufferedAmount || 0;
    const sendSize = totalSize - leftSize;

    if (sendSize !== prevSendSize) {
      console.debug(`%conProgress - totalSize=${totalSize} sendSize=${sendSize} %cinitialSize=${initialSize} %cprevSendSize=${prevSendSize}`, 'color: gray', initialSize > 0 ? 'color: red' : 'color: gray', prevSendSize > sendSize ? 'color: red' : 'color: gray');
    }

    if (leftSize > UPLOAD_SIZE_MISTAKE) {
      onProgress?.({
        total: totalSize,
        loaded: sendSize,
        progress: sendSize / totalSize,
      });
    } else {
      onProgress?.({
        total: totalSize,
        loaded: totalSize,
        progress: sendSize / totalSize,
      });
      console.debug(`%conProgress - finished`, 'color: gray');
      return;
    }

    await wait(100);

    await this.triggerProgress_(trx, onProgress, totalSize, initialSize, sendSize);
  };

  public request = async <T extends ResponseType = any>(
    data: IAPIRequest,
    responseType: keyof APIResponse | null = null,
    options?: RequestOptions,
  ): Promise<NetworkResponse<T, APIResponse.Status>> => {
    try {
      const {res, trx} = await this.asyncRequest<T>(
        data,
        responseType,
        options,
      );

      return {error: null, res, trx};
    } catch (error: any) {
      return {error, res: null, trx: error.trx};
    }
  };

  public addEventListener = (eventName: keyof APIResponse, handler: (res: any) => void): void => {
    this.eventHandlers.push({eventName, handler});
  };

  public removeEventListener = (handler: (res: any) => void) => {
    console.debug('removeEventListener before', this.eventHandlers.length);
    this.eventHandlers = this.eventHandlers.filter((h) => h.handler !== handler);
    console.debug('removeEventListener after', this.eventHandlers.length);
  };

  public removeEventAllListeners = (eventName: keyof APIResponse) => {
    this.eventHandlers = this.eventHandlers.filter((handler) => handler.eventName !== eventName);
  };

  protected addResponseHandler = (
    requestId: Uint8Array,
    reqData: IAPIRequest,
    eventName: keyof APIResponse | null,
    resultHandler: (res: any, reqData: IAPIRequest) => void,
    errorHandler?: (res: any, reqData: IAPIRequest) => void,
    timeout?: number | null,
    priority?: RequestPriority | null,
    logLevel?: LogLevel,
    onProgress?: OnProgressCallBack,
  ): void => {
    let timer: NodeJS.Timeout | null = null;
    if (timeout) {
      timer = setTimeout(() => {
        this.throwWaitingHandlerByTimout(requestId);
      }, timeout);
    }

    this.responseHandlers.push({
      id: requestId,
      eventName,
      reqData,
      resultHandler,
      errorHandler,
      timer,
      priority: priority || RequestPriority.NORMAL,
      stamp: new Date().getTime(),
      logLevel,
      onProgress,
    });
  };

  protected removeResponseHandler = (trx: Uint8Array) => {
    this.responseHandlers = this.responseHandlers.filter((handler) => !equalUint8Arrays(handler.id, trx));
  };

  protected findResponseHandler = (trx: Uint8Array) => {
    return this.responseHandlers.find((t) => equalUint8Arrays(t.id, trx));
  };

  public cancel = (trx: Uint8Array) => {
    const responseHandler = this.findResponseHandler(trx);
    if (responseHandler) {
      const error = new ErrorEvent({
        message: CANCELED_ERROR_MESSAGE,
      });
      responseHandler.errorHandler?.(error, responseHandler.reqData);
    }
    this.removeResponseHandler(trx);
  };
}

export default Network;

export const CANCELED_ERROR_MESSAGE = 'canceled';
