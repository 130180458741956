import Long from 'long';
import {action, computed, observable} from 'mobx';

import {APILayer} from '../stores/APILayer';
import getRandomInt from '../utils/getRandomInt';
import {AppStore} from './AppStore';

export enum TagType {
  Workspace = 0,
  Personal = 1,
}

export const MESSAGE_TAG_COLORS = {
  red: '#FFBFD2',
  purple: '#EEBFFF',
  blue: '#BFC6FF',
  lightBlue: '#A7E5FF',
  turquoise: '#BFFFEC',
  green: '#DBFFBF',
  yellow: '#FEFFBF',
  orange: '#FFE0A4',
};

export const DEFAULT_MESSAGE_TAG_COLOR = MESSAGE_TAG_COLORS.red;

export const MESSAGE_TAG_NO_WRAP_WIDTH = 150;

export const getRandomTagColor = () => {
  const colors = Object.values(MESSAGE_TAG_COLORS);
  const idx = getRandomInt(0, colors.length);

  return colors[idx];
};

export interface ITag {
  id?: Long;
  name?: string;
  color?: string;
  text?: string;
  isWorkspace?: boolean;
}

export const filterTagsList = (list: ITag[], tagType: TagType, filter = ''): ITag[] => {
  const isWorkspace = tagType === TagType.Workspace;

  return list.filter(
    (item) => item.text?.toLowerCase().includes(filter.toLowerCase()) && Boolean(item.isWorkspace) === isWorkspace,
  );
};

export const getUniqueList = (list: ITag[], tags: ITag[]): ITag[] => {
  return tags.length
    ? list.filter((item) => {
        return !tags.some((tag) => item.id && tag.id?.equals(item.id));
      })
    : list;
};

export class MessageTagsStore extends APILayer {
  constructor(public app: AppStore) {
    super(app);
  }

  @observable isInit = false;
  @observable list: ITag[] = [];

  @computed get barIsOpen() {
    return this.list.length > 0;
  }

  init = () => {
    if (this.loading || this.isInit) {
      return;
    }

    this.loadList();

    this.isInit = true;
  };

  @action reset = () => {
    this.isInit = false;
    this.loading = false;
    this.list = [];
  };

  loadList = () => {
    console.debug(`TODO: not implementre method "loadList"`);
  };

  @action processList = (tags: ITag[]) => {
    this.isInit = true;
    this.list = tags;
  };

  add = (text: string, color: string, isWorkspace = false) => {
    console.debug(`TODO: not implementre method "add", args:`, text, color, isWorkspace);
  };

  @action processNewTag = (tag: ITag) => {
    this.list.push(tag);
    this.list = this.list.slice();
  };

  edit = (tag: ITag) => {
    console.debug(`TODO: not implementre method "edit", args: ${tag}`);
  };

  @action processEditedTags = (tags: ITag[]) => {
    console.debug(`TODO: not implementre method "processEditedTags", args: ${tags}`);
  };

  delete = (tag: ITag) => {
    console.debug(`TODO: not implementre method "delete", args: ${tag}`);
  };

  @action processDeleteTag = (tag: ITag) => {
    console.debug(`TODO: not implementre method "processDeleteTag", args: ${tag}`);
  };

  updateOrdering = (tagIds: Long[]) => {
    console.debug(`TODO: not implementre method "updateOrdering", args: ${tagIds}`);
  };

  onTagsOrderingChangeEvent = (event: any) => {
    console.debug(`TODO: not implementre method "onTagsOrderingChangeEvent", args: ${event}`);
  };

  addTagToMessage = (tag: ITag) => {
    console.debug(`TODO: not implementre method "addTagToMessage", args: ${tag}`);
  };

  removeTagFromMessage = (tag: ITag) => {
    console.debug(`TODO: not implementre method "removeTagFromMessage", args: ${tag}`);
  };
}

export default MessageTagsStore;
