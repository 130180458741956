import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'o-ui/Button';
import SettingsTab from '../../stores/LayOutStore/SettingsTab';
import { SettingsHeadState } from './SettingsHead';
import WorkspaceSettingsForm, { IWorkspaceSettingsFormRef } from './WorkspaceSettings/WorkspaceSettingsForm';
import useAllowChangeHeadState from './useAllowChangeHeadState';

interface IProps {
  onChangeState?: (state: SettingsHeadState) => void;
}

export const WorkspaceSettingsTab = React.memo((props: IProps) => {
  const {t} = useTranslation();
  const formRef = React.useRef<IWorkspaceSettingsFormRef>(null);
  const [disabledSubmit, setDisabledSubmit] = React.useState<boolean>(true);

  const allowChangeHeadState = useAllowChangeHeadState(SettingsTab.WORKSPACE_SETTINGS);

  React.useEffect(() => {
    if (!allowChangeHeadState) {
      return;
    }
    props.onChangeState?.({
      title: t('settings_workspace_title'),
    });
  }, [props, allowChangeHeadState, t]);

  const handleSubmit = () => {
    formRef.current?.submitForm?.();
    setDisabledSubmit(true);
  };

  const handleClose = () => {
    setDisabledSubmit(true);
    formRef.current?.resetForm?.();
  };

  const handleChange = (valid: boolean) => {
    setDisabledSubmit(!valid);
  };

  return (
    <div className="settings-tab with-footer">
      <div className="settings-tab__body custom-scroll">
        <div className="settings-tab__paper w-100 h-100">
          <WorkspaceSettingsForm ref={formRef} onChange={handleChange} />
        </div>
      </div>

      <div className="settings-tab__footer">
        <div className="settings-tab__row">
          <Button
            className="mr-5"
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
            disabled={disabledSubmit}
          >
            {t('settings_personal_info_update')}
          </Button>

          <Button variant="contained" color="secondary" size="large" onClick={handleClose} disabled={disabledSubmit}>
            {t('settings_personal_info_cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default WorkspaceSettingsTab;
