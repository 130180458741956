import React, {UIEventHandler} from 'react';

import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import useLayoutEffectWithPrevDeps from '../../../hooks/useLayoutEffectWithPrevDeps';
import Chat from '../../../stores/Chat';
import Message from '../../../stores/Message';

export const getMessageElementId = (message: Message) => `chat-msg-${message.id.toString()}`;

interface UseChatMessagesListProps {
  chat: Chat;
  chatMessages: (Message | Message[])[];
}

interface UseChatMessagesList<T> {
  onScroll?: UIEventHandler<T> | undefined;
  ref?: React.MutableRefObject<HTMLDivElement>;
  isScrolling?: boolean;
}

export const useChatMessagesList = ({
  chat,
  chatMessages,
}: UseChatMessagesListProps): UseChatMessagesList<HTMLDivElement> => {
  const scrollableNodeRef = React.useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;

  const [containerHeight, setContainerHeight] = React.useState<number | undefined>();
  const [contentHeight, setContentHeight] = React.useState<number | undefined>();

  const scrollUpdateWasRequested = React.useRef<boolean>(false);
  const [isScrolling, setIsScrolling] = React.useState<boolean>(false);

  const getScrollEl = (): HTMLElement | null | undefined => {
    return scrollableNodeRef?.current;
  };

  const scrollToUnreadDelimiter = () => {
    const scrollEl = getScrollEl();
    const el = document.getElementById('unread-messages-delimiter');

    if (el && scrollEl) {
      console.debug('userChat->scrollToUnreadDelimiter');
      scrollEl.scrollTop = el.offsetTop;
      scrollUpdateWasRequested.current = true;

      chat.store?.setScrolledToUnreadDelimiter(true);
    }
  };

  const scrollToMessage = (message?: Message | null, block: ScrollLogicalPosition = 'center') => {
    console.debug('userChat->scrollToMessage', message?.id.toNumber());

    let el: HTMLElement | null | undefined = message?.id ? document.getElementById(getMessageElementId(message)) : null;
    let groupMessagesEl: HTMLElement | null = null;

    if (el === null) {
      groupMessagesEl = message?.id ? document.querySelector(`[data-msg-id="${message?.id.toString()}"]`) : null;

      el = groupMessagesEl?.closest('.message');
    }

    const scrollEl = getScrollEl();

    if (el && scrollEl) {
      // const {scrollTop} = scrollEl;
      scrollUpdateWasRequested.current = true;

      const elOffsetHeight = groupMessagesEl
        ? groupMessagesEl.offsetTop + groupMessagesEl.offsetHeight
        : el.offsetHeight;

      if (block === 'start') {
        // console.debug('userChat->scrollToMessage - el', message?.id?.toNumber(), scrollTop, el.offsetTop, scrollEl);
        scrollEl.scrollTop = el.offsetTop;
      } else if (block === 'center') {
        // console.debug('userChat->scrollToMessage - el', message?.id?.toNumber(), scrollTop, elOffsetHeight, scrollEl);
        scrollEl.scrollTop =
          el.offsetTop + elOffsetHeight + Math.ceil(scrollEl.offsetHeight / 2) - scrollEl.clientHeight;
      } else {
        // console.debug('userChat->scrollToMessage - el', message?.id?.toNumber(), scrollTop, el.offsetTop, scrollEl);
        scrollEl.scrollTop = el.offsetTop + elOffsetHeight - scrollEl.clientHeight;
      }
    } else {
      console.debug(
        `%c userChat->scrollToMessage - target message not found ${message?.id?.toNumber()}`,
        'color: orange',
      );
    }
  };

  React.useEffect(() => {
    const scrollEl = getScrollEl();

    if (!('ResizeObserver' in window) || !scrollEl) {
      return undefined;
    }

    const scrollElObserver = new ResizeObserver(([entry]) => {
      // During animation
      if (!(entry.target as HTMLDivElement).offsetParent) {
        return;
      }

      // console.debug('ResizeObserver height=', entry.contentRect.height);
      setContainerHeight(entry.contentRect.height);
    });
    scrollElObserver.observe(scrollEl);

    const contentEl = scrollEl.querySelector('.simplebar-content');
    const contentElObserver = new ResizeObserver(([entry]) => {
      // During animation
      if (!(entry.target as HTMLDivElement).offsetParent) {
        return;
      }

      // console.debug('ResizeObserver contentEl height=', entry.contentRect.height);
      setContentHeight(entry.contentRect.height);
    });

    if (contentEl) {
      contentElObserver.observe(contentEl);
    }

    return () => {
      scrollElObserver.disconnect();
      contentElObserver.disconnect();
    };
  }, []);

  const timerRef = React.useRef<null | NodeJS.Timeout>(null);

  const resetTargetWithDelay = () => {
    // reset target in 2sec after found-message animation end.
    timerRef.current = setTimeout(() => {
      chat.store?.resetTarget();
    }, 2000);
  };

  React.useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useLayoutEffectWithPrevDeps(() => {
    if (chat.store?.anchorMessage) {
      console.debug('->useLayoutEffectWithPrevDeps -> scroll to anchorMessage');
      scrollToMessage(chat.store?.anchorMessage, chat.store?.scrollDirection === 'backwards' ? 'start' : 'end');
    } else if (chat.store.firstUnreadMessageId && !chat.store?.scrolledToUnreadDelimiter) {
      console.debug('->useLayoutEffectWithPrevDeps -> scroll to unreadMessagesDelimiter');
      scrollToUnreadDelimiter();
      return;
    } else if (chat.store?.targetMessage) {
      console.debug('->useLayoutEffectWithPrevDeps -> scroll to targetMessage');
      scrollToMessage(chat.store?.targetMessage);
      resetTargetWithDelay();
    } else if (chat.store?.searchMessage) {
      console.debug('->useLayoutEffectWithPrevDeps -> scroll to searchMessage');
      scrollToMessage(chat.store?.searchMessage);
    }
    /* else if ((chat.store?.scrolled || chat.store?.loadedNextPage) && !scrollUpdateWasRequested.current) {
      console.debug('->useLayoutEffectWithPrevDeps -> recoveryScrollPosition');
      recoveryScrollPosition();
    } else {
      console.debug('->useLayoutEffectWithPrevDeps -> scrollToBottom');
      scrollToBottom();
    }*/
  }, [
    chatMessages,
    chat.store?.anchorMessage,
    chat.store?.targetMessage,
    chat.store?.searchMessage,
    containerHeight,
    contentHeight,
    chat.store?.firstUnreadMessageId,
    chat.store?.scrolledToUnreadDelimiter,
  ]);

  const handleScrollCallback = useDebouncedCallback(
    React.useCallback(() => {
      const scrollEl = scrollableNodeRef?.current;

      if (!scrollUpdateWasRequested.current) {
        console.debug('userChat-> reset Targets');
        chat.store?.resetAnchor();
        chat.store?.resetTarget();
        chat.store?.resetSearchTarget();
      }

      if (scrollEl) {
        // console.debug('scrollHeight', scrollEl.scrollHeight, 'clientHeight', scrollEl.clientHeight);
        // console.debug('setScrollTop', scrollEl.scrollTop);
        // console.debug('setScrollBottom', scrollEl.scrollHeight - scrollEl.scrollTop);
        const {clientHeight, scrollHeight, scrollTop} = scrollEl;
        // console.debug('scrollHeight', scrollHeight);

        chat.store?.setScrollTop(scrollTop);

        const scrollBottom = Math.round(scrollHeight - scrollTop);
        // console.debug('scrollBottom', scrollBottom);
        if (clientHeight < scrollHeight) {
          chat.store?.setScrollBottom(scrollBottom);
        }
        //chat.store?.setScrollBottom(scrollEl.scrollHeight - scrollEl.scrollTop);
        const scrollBottomVariants = [
          scrollBottom - 1,
          scrollBottom - 2,
          scrollBottom - 3,
          scrollBottom - 4,
          scrollBottom,
          scrollBottom + 1,
          scrollBottom + 2,
          scrollBottom + 3,
          scrollBottom + 4,
        ];

        if (scrollBottomVariants.includes(scrollEl.clientHeight)) {
          chat.store?.setScrolled(false);
        } else {
          chat.store?.setScrolled(true);
        }

        if (scrollTop === 0 && clientHeight < scrollHeight) {
          chat.store?.chatScrolledToTop();
        } else if (scrollBottomVariants.includes(scrollEl.clientHeight)) {
          chat.store?.chatScrolledToBottom();
        }
      }
      setIsScrolling(false);
      scrollUpdateWasRequested.current = false;
      chat.store?.setScrolling(null);
    }, [chat.store]),
    400,
  );

  const handleScroll = React.useCallback(
    (e: React.UIEvent) => {
      const scrollHeight = scrollableNodeRef?.current?.scrollHeight || 0;
      const scrollTop = scrollableNodeRef?.current?.scrollTop || 0;
      const scrollBottom = Math.round(scrollHeight - scrollTop);
      chat.store?.setScrolling(scrollBottom);

      setIsScrolling(true);
      handleScrollCallback(e);
    },
    [handleScrollCallback, chat.store],
  );

  // console.debug(`---->render useChatMessagesList`, isScrolling);

  return React.useMemo(
    () => ({
      onScroll: handleScroll,
      ref: scrollableNodeRef,
      isScrolling,
    }),
    [handleScroll, scrollableNodeRef, isScrolling],
  );
};

export default useChatMessagesList;
