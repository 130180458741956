import cn from 'classnames';
import React from 'react';
import NavigationBarItemProps from './NavigationBarItemProps';

export const NavigationBarButton: React.FC<NavigationBarItemProps & React.PropsWithChildren> = ({
  className,
  location,
  index,
  onClick,
  children,
}) => {

  const handleClick = () => {
    onClick?.(location, index);
  };

  return (
    <button
      type="button"
      className={cn('d-flex align-items-center', className)}
      onClick={handleClick}
      title={location.pathname}
    >
      {children}
    </button>
  );
};

export default NavigationBarButton;
