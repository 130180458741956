import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import Tab from 'o-ui/Tab';
import Tabs from 'o-ui/Tabs';

import {ReactComponent as PlusIcon} from '../../../../assets/icons/plus-line.svg';
import ModalType from '../../../../stores/ModalType';
import useStore from '../../../../stores/useStore';
import InviteLinksList from './InviteLinksList';
import NoInviteLinks from './NoInviteLinks';


export enum InviteLinksType {
  Active,
  Activated,
  Expired,
}

export const InviteLinksViewer = observer(() => {
  const {t} = useTranslation();
  const {activeWorkspace: {invites}, anal, modals} = useStore();

  const [selectedTab, setSelectedTab] = React.useState(InviteLinksType.Active);

  const handleChangeTab = (e: React.ChangeEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const inviteLinks = React.useMemo(() => {
    switch (selectedTab) {
      case InviteLinksType.Active:
        return invites.inviteLinksActive;
      case InviteLinksType.Activated:
        return invites.inviteLinksActivated;
      case InviteLinksType.Expired:
        return invites.inviteLinksExpired;
      default:
        return [];
    }
  }, [selectedTab, invites.inviteLinksActive, invites.inviteLinksActivated, invites.inviteLinksExpired]);

  const openInvitationForm = React.useCallback((e) => {
    anal.inviteClick(e);
    modals.open(ModalType.INVITE_MEMBER_BY_LINK);
  }, [
    anal,
    modals,
  ]);

  return (
    <>
      <div className="d-flex mt-6 px-6">
        <Tabs variant="filled" value={selectedTab} onChange={handleChangeTab}>
          <Tab label={<span className="d-flex align-items-center">{t('settings_workspace_members_filter_active')}</span>} />
          <Tab label={<span className="d-flex align-items-center">{t('settings_workspace_members_filter_activated')}</span>} />
          <Tab label={<span className="d-flex align-items-center">{t('settings_workspace_members_filter_expired')}</span>} />
        </Tabs>

        <div className="ml-auto">
          <Button
            className="d-flex align-items-center"
            variant="contained"
            color="primary"
            onClick={openInvitationForm}
          >
            <PlusIcon className="mr-3" width="12" />
            {t('settings_workspace_members_invite_links_create_button')}
          </Button>
        </div>
      </div>

      <div className="custom-scroll-y mt-6 mb-3 h-100 px-6">
        {inviteLinks.length ? (
          <InviteLinksList inviteLinks={inviteLinks} />
        ) : (
          <NoInviteLinks
            type={selectedTab}
            onCreateLink={openInvitationForm}
          />
        )}
      </div>
    </>
  );
});

export default InviteLinksViewer;
