import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import LinearProgress from 'o-ui/LinearProgress';
import useStore from '../../../../stores/useStore';
import {BILLING_DATE_FORMAT, formatDateTime} from '../../../../utils/format';
import warningIcon from '../../../../assets/images/warning-square-with-triangle.svg';
import formatMoney from '../../../../utils/format/formatMoney';

interface TrialPeriodExpiredProps {
  className?: string;
}

export const TrialPeriodExpired: React.FC<TrialPeriodExpiredProps> = observer(({className}) => {
  const {t} = useTranslation();

  const {activeWorkspace} = useStore();
  const {billing} = activeWorkspace;
  const {currentTariff} = billing;

  return (
    <div
      className={cn(
        'd-flex flex-column px-6 py-5',
        className,
      )}
    >
      <div className="d-flex flex-row">
        <img className="mr-5" src={warningIcon} alt="" />

        <div className="d-flex flex-column">
          <div className="h3-bold color-warning-secondary">
            {t('Trial ended on {{date}}', {
              date: formatDateTime(billing.expiresAt, true, BILLING_DATE_FORMAT)
            })}
          </div>
          <div className="color-warning-secondary">
            {t('Upgrade now for {{price}} per month for {{number}} seats', {
              price: formatMoney({value: currentTariff?.price, currency: currentTariff?.currency}),
              number: currentTariff?.includedUsers?.toNumber(),
            })}
          </div>
        </div>
      </div>

      <LinearProgress
        className="mt-3"
        style={{height: '4px', backgroundColor: 'var(--warning-tint3)'}}
        color="primary"
        variant="determinate"
      />
    </div>
  );
});

export default TrialPeriodExpired;