import React from 'react';
import {Message} from '../../../../stores/Message';

interface IProps {
  children?: React.ReactNode;
  message: Message;
  pinnedMode?: boolean;
}

export const MessageMeta: React.FC<IProps> = (props) => {
  return <span className="message-meta">{props.children}</span>;
};

export default MessageMeta;
