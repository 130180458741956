import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import AutoSizer, {Size} from 'react-virtualized-auto-sizer';

import Button from 'o-ui/Button';
import CustomScrollbar from 'o-ui/CustomScrollbar';
import FixedSizeList from 'o-ui/VirtualizedList/FixedSizeList';

import useMediaQuery from '../../../../../hooks/useMediaQuery';
import {useStore} from '../../../../../stores/AppStore';
import ModalType from '../../../../../stores/ModalType';
import {IRawChat} from '../../../../../stores/RawChatsStore/RawChat';
import WorkspaceMemberAccessRecord from '../../../../../stores/Workspaces/WorkspaceMemberAccessRecord';
import ChatsAccessHeader from './ChatsAccessHeader';
import ChatsAccessItem from './ChatsAccessItem';


const ITEM_SIZE = 60; //px;
const MAX_IN_VIEW_PORT = 5; // on mobiles and tablets

interface ChatsListWrapperProps {
  children: (size: Size) => React.ReactNode;
  className?: string;
  chatsCount: number;
}

export const ChatsListWrapper = React.forwardRef<HTMLDivElement, ChatsListWrapperProps>((props, ref) => {
  const tablet = useMediaQuery('(max-width: 1000px)');

  let height: React.CSSProperties['height'] =
    props.chatsCount >= MAX_IN_VIEW_PORT ? ITEM_SIZE * MAX_IN_VIEW_PORT : ITEM_SIZE * props.chatsCount;

  if (props.chatsCount === 0) {
    height = ITEM_SIZE * MAX_IN_VIEW_PORT;
  }

  if (!tablet) {
    height = '100%';
  }

  return (
    <div ref={ref} style={{maxHeight: height, height, width: '100%'}} className={props.className}>
      <AutoSizer>{props.children}</AutoSizer>
    </div>
  );
});

interface ChatsAccessProps {
  accessRecord: WorkspaceMemberAccessRecord;
  onBackToChannels(): void;
}

const ChatsAccess: React.FC<ChatsAccessProps> = observer(({accessRecord, onBackToChannels}) => {
  const {t} = useTranslation();
  const {modals} = useStore();

  const [chats, setChats] = React.useState<IRawChat[]>(accessRecord.chats);
  const [filter, setFilter] = React.useState<string>('');

  const handleChangeFilter = (value: string) => {
    setFilter(value);
  };

  React.useEffect(() => {
    accessRecord.loadChats();
  }, [accessRecord]);

  React.useEffect(() => {
    if (filter) {
      setChats(accessRecord.chats.filter((chat) => chat.name?.toLowerCase().includes(filter.toLowerCase())));
    } else {
      setChats(accessRecord.chats);
    }
  }, [filter, accessRecord.chats]);

  const handleAddChatClick = () => {
    modals.open(ModalType.MEMBER_ACCESS_ADD_CHAT, {accessRecord});
  };

  const handleResetExceptions = () => {
    modals.open(ModalType.CONFIRM, {
      headerText: t('modal_chats_access_confirm_reset_exceptions_header_text'),
      title: t('modal_chats_access_confirm_reset_exceptions_title', {
        channel: accessRecord.channel?.name || accessRecord.channel?.phone,
      }),
      submitButtonText: t('modal_submit_btn'),
      submitButtonColor: 'primary',
      syncCallbacks: true,
      callbacks: {
        submit: () => {
          accessRecord.reset();
        },
      },
    });
  };

  return (
    <div className="chats-access">
      <ChatsAccessHeader
        onAddChat={handleAddChatClick}
        onBack={onBackToChannels}
        onSearch={handleChangeFilter}
        onResetExceptions={handleResetExceptions}
        accessRecord={accessRecord}
      />
      <ChatsListWrapper
        className="chats-access__list"
        chatsCount={chats.length}
      >
        {({width, height}) => (
          <>
            {chats.length ? (
              <FixedSizeList
                width={width || 0}
                height={height || 0}
                itemCount={chats.length}
                itemSize={ITEM_SIZE}
                outerElementType={CustomScrollbar}
              >
                {({index, style}) => (
                  <ChatsAccessItem
                    key={chats[index].id?.toString()}
                    style={style}
                    chat={chats[index]}
                    accessRecord={accessRecord}
                  />
                )}
              </FixedSizeList>
            ) : null}

            {accessRecord.chats.length && !chats.length ? (
              <div style={{width: width}} className="chats-access__empty-list-item">
                <div className="mb-3 body2-regular">{t('setting_workspace_members_chats_access_no_items_found')}</div>
              </div>
            ) : null}

            {!accessRecord.chats.length ? (
              <div
                style={{width: width}}
                className={cn('chats-access__empty-list-item', {disabled: accessRecord.disableChannel})}
              >
                <div className="mb-3 label-1">{t('setting_workspace_members_chats_access_empty_list_item_title')}</div>
                <div className="body2-regular mb-5">
                  {accessRecord.isAllowList
                    ? t('setting_workspace_members_chats_access_empty_list_item_description')
                    : t('setting_workspace_members_chats_access_empty_list_item_description_denny_type')}
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleAddChatClick}
                  disabled={!!accessRecord.disableChannel}
                >
                  <span className="tg-icon-plus-line mr-3" />
                  {t('setting_workspace_members_chats_access_add_chat_btn')}
                </Button>
              </div>
            ) : null}
          </>
        )}
      </ChatsListWrapper>
    </div>
  );
});

export default ChatsAccess;
