import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import DayPicker from 'o-ui/DayPicker/DayPicker';
import {DayModifiers} from 'o-ui/DayPicker/types/Modifiers';
import DialogActions from 'o-ui/Dialog/DialogActions';
import DialogContent from 'o-ui/Dialog/DialogContent';

import {ModalItem} from '../stores/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
}

export const ModalJumpToDate: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const handleSubmit = (day: Date, modifiers: DayModifiers) => {
    if (!modifiers.disabled) {
      props.modal.data.chat?.store?.navigateToDate(day);
      handleCloseClick();
    }
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      maxWidth="xs"
      fullWidth={true}
      PaperProps={{
        style: {maxWidth: 340},
      }}
    >
      <DialogContent className="px-6 pt-7" style={{minHeight: 386}}>
        <DayPicker
          onDayClick={handleSubmit}
          disabled={[
            {
              after: new Date(),
            },
          ]}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleCloseClick}
        >
          {t('modal_cancel_btn')}
        </Button>
      </DialogActions>
    </ModalDialog>
  );
});

export default ModalJumpToDate;
