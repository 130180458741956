import React from 'react';
import cn from 'classnames';

import {entities} from '../api/proto';
import {ReactComponent as AllChannelsIconSquared} from '../assets/image-icons/allChannelsIconSquared.svg';
import {ReactComponent as TelegramIconSquared} from '../assets/image-icons/telegramIconSquared.svg';

import {ReactComponent as FacebookIconSquared} from '../assets/image-icons/facebookIconSquared.svg';
import {ReactComponent as InstagramIconSquared} from '../assets/image-icons/instagramIconSquared.svg';
import {ReactComponent as InternalChatIconSquared} from '../assets/image-icons/internalChatIconSquared.svg';
import {ReactComponent as LiveChatIconSquared} from '../assets/image-icons/liveChatIconSquared.svg';
import {ReactComponent as SignalIconSquared} from '../assets/image-icons/signalIconSquared.svg';
import {ReactComponent as SlackIconSquared} from '../assets/image-icons/slackIconSquared.svg';
import {ReactComponent as TelegramBotIconSquared} from '../assets/image-icons/telegramBotIconSquared.svg';
import {ReactComponent as WhatsappIconSquared} from '../assets/image-icons/whatsappIconSquared.svg';

import {ReactComponent as AllChannelsIconSquaredActive} from '../assets/image-icons/allChannelsIconSquaredActive.svg';
import {ReactComponent as TelegramIconSquaredActive} from '../assets/image-icons/telegramIconSquaredActive.svg';

import {ReactComponent as LiveChatIconSquaredActive} from '../assets/image-icons/liveChatIconSquaredActive.svg';
import {ReactComponent as SignalIconSquaredActive} from '../assets/image-icons/signalIconSquaredActive.svg';
import {ReactComponent as WhatsappIconSquaredActive} from '../assets/image-icons/whatsappIconSquaredActive.svg';

import {ReactComponent as FacebookIconSquaredActive} from '../assets/image-icons/facebookIconSquaredActive.svg';
import {ReactComponent as InstagramIconSquaredActive} from '../assets/image-icons/instagramIconSquaredActive.svg';
import {ReactComponent as InternalChatIconSquaredActive} from '../assets/image-icons/internalChatIconSquaredActive.svg';
import {ReactComponent as TelegramBotIconSquaredActive} from '../assets/image-icons/telegramBotIconSquaredActive.svg';

// import {ReactComponent as SlackIconSquaredActive} from '../assets/image-icons/slackIconSquaredActive.svg';

interface ChannelIconProps {
  className?: string;
  type?: entities.OzekonChannelType | string | null;
  active?: boolean;
}

export const ChannelIcon = (props: ChannelIconProps) => {
  const getDefaultIcon = () => {
    switch (props.type) {
      case entities.OzekonChannelType.OIT_TELEGRAM:
        return <TelegramIconSquared className={cn('channel-icon', props.className)} />;
      case entities.OzekonChannelType.OIT_WEB_WIDGET:
        return <LiveChatIconSquared className={cn('channel-icon', props.className)} />;
      case entities.OzekonChannelType.OIT_INSTAGRAM:
        return <InstagramIconSquared className={cn('channel-icon', props.className)} />;
      case entities.OzekonChannelType.OIT_TELEGRAM_BOT:
        return <TelegramBotIconSquared className={cn('channel-icon', props.className)} />;
      case 'SIGNAL':
        return <SignalIconSquared className={cn('channel-icon', props.className)} />;
      case 'INTERNAL_CHAT':
        return <InternalChatIconSquared className={cn('channel-icon', props.className)} />;
      case 'whatsapp':
        return <WhatsappIconSquared className={cn('channel-icon', props.className)} />;
      case 'slack':
        return <SlackIconSquared className={cn('channel-icon', props.className)} />;
      case 'facebook':
        return <FacebookIconSquared className={cn('channel-icon', props.className)} />;
    }

    return null;
  };

  const getActiveIcon = () => {
    switch (props.type) {
      case entities.OzekonChannelType.OIT_TELEGRAM:
        return <TelegramIconSquaredActive className={cn('channel-icon', props.className)} />;
      case entities.OzekonChannelType.OIT_WEB_WIDGET:
        return <LiveChatIconSquaredActive className={cn('channel-icon', props.className)} />;
      case entities.OzekonChannelType.OIT_INSTAGRAM:
        return <InstagramIconSquaredActive className={cn('channel-icon', props.className)} />;
      case entities.OzekonChannelType.OIT_TELEGRAM_BOT:
        return <TelegramBotIconSquaredActive className={cn('channel-icon', props.className)} />;
      case 'SIGNAL':
        return <SignalIconSquaredActive className={cn('channel-icon', props.className)} />;
      case 'INTERNAL_CHAT':
        return <InternalChatIconSquaredActive className={cn('channel-icon', props.className)} />;
      case 'whatsapp':
        return <WhatsappIconSquaredActive className={cn('channel-icon', props.className)} />;
      case 'slack':
        return <SlackIconSquared className={cn('channel-icon', props.className)} />;
      case 'facebook':
        return <FacebookIconSquaredActive className={cn('channel-icon', props.className)} />;
    }

    return <TelegramIconSquaredActive className={cn('channel-icon', props.className)} />;
  };

  return props.active ? getActiveIcon() : getDefaultIcon();
};

export default ChannelIcon;

interface AllChannelsIconProps {
  className?: string;
  active?: boolean;
}

export const AllChannelsIcon = (props: AllChannelsIconProps) => {
  return props.active ? (
    <AllChannelsIconSquaredActive className={cn('channel-icon', props.className)} />
  ) : (
    <AllChannelsIconSquared className={cn('channel-icon', props.className)} />
  );
};
