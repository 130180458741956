import Embed from './QuillCore/blots/embed';
import {MentionedUserData} from '../../stores/MentionUser';

type ClickHandler = ((e: any) => void) | null;

export class MentionBlot extends Embed {
  clickHandler: ClickHandler = null;

  constructor(scroll, node: HTMLElement) {
    super(scroll, node);
    this.clickHandler = null;
  }

  static create(data: MentionedUserData) {
    const node = super.create();
    //console.log('--->MentionBlot create=', data);
    if (data && (data.username || data.name || data.userid)) {
      node.setAttribute('name', data.name || '');
      node.setAttribute('username', data.username || '');
      node.setAttribute('userid', data.userid || '');
      /*
      const denotationChar = document.createElement("span");
      denotationChar.className = "ql-mention-denotation-char";
      denotationChar.innerHTML = data.username;
      node.appendChild(denotationChar);
      */
      node.innerHTML += data.username || data.name || data.userid;
    }
    return node;
  }

  static value(node) {
    return {
      name: node.getAttribute('name'),
      username: node.getAttribute('username'),
      userid: node.getAttribute('userid'),
    };
  }
  /*
  static formats(node) {
    return node;
  }
  */

  attach() {
    super.attach();
    this.clickHandler = (e) => {
      //console.log('mention-clicked');
      const event = new Event('mention-clicked', {
        bubbles: true,
        cancelable: true,
      });
      //event.value = Object.assign({}, this.domNode.dataset);
      //event.event = e;
      window.dispatchEvent(event);
      e.preventDefault();
    };
    this.domNode.addEventListener('click', this.clickHandler, false);
  }

  detach() {
    super.detach();
    if (this.clickHandler) {
      this.domNode.removeEventListener('click', this.clickHandler);
      this.clickHandler = null;
    }
  }
}

MentionBlot.blotName = 'mention';
MentionBlot.tagName = 'span';
MentionBlot.className = 'mention-blot';

export default MentionBlot;
