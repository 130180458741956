import React from 'react';

import ListItemIcon from 'o-ui/ListItemIcon';
import SettingsTab from '../../../stores/LayOutStore/SettingsTab';

import {ReactComponent as Download} from '../../../assets/image-icons/download.svg';
import {ReactComponent as DownloadActive} from '../../../assets/image-icons/downloadActive.svg';
import {ReactComponent as DownloadDarkTheme} from '../../../assets/image-icons/downloadDarkTheme.svg';
import {ReactComponent as DownloadHover} from '../../../assets/image-icons/downloadHover.svg';
import {ReactComponent as DownloadHoverDarkTheme} from '../../../assets/image-icons/downloadHoverDarkTheme.svg';
import {ReactComponent as LinkHorizontal} from '../../../assets/image-icons/linkHorizontal.svg';
import {ReactComponent as LinkHorizontalActive} from '../../../assets/image-icons/linkHorizontalActive.svg';
import {ReactComponent as LinkHorizontalDarkTheme} from '../../../assets/image-icons/linkHorizontalDarkTheme.svg';
import {ReactComponent as LinkHorizontalHover} from '../../../assets/image-icons/linkHorizontalHover.svg';
import {ReactComponent as LinkHorizontalHoverDarkTheme} from '../../../assets/image-icons/linkHorizontalHoverDarkTheme.svg';
import {ReactComponent as Snippet} from '../../../assets/image-icons/snippet.svg';
import {ReactComponent as SnippetActive} from '../../../assets/image-icons/snippetActive.svg';
import {ReactComponent as SnippetDarkTheme} from '../../../assets/image-icons/snippetDarkTheme.svg';
import {ReactComponent as SnippetHover} from '../../../assets/image-icons/snippetHover.svg';
import {ReactComponent as SnippetHoverDarkTheme} from '../../../assets/image-icons/snippetHoverDarkTheme.svg';
import {ReactComponent as Tag} from '../../../assets/image-icons/tag.svg';
import {ReactComponent as TagActive} from '../../../assets/image-icons/tagActive.svg';
import {ReactComponent as TagDarkTheme} from '../../../assets/image-icons/tagDarkTheme.svg';
import {ReactComponent as TagHover} from '../../../assets/image-icons/tagHover.svg';
import {ReactComponent as TagHoverDarkTheme} from '../../../assets/image-icons/tagHoverDarkTheme.svg';
import {ReactComponent as User} from '../../../assets/image-icons/user.svg';
import {ReactComponent as UserActive} from '../../../assets/image-icons/userActive.svg';
import {ReactComponent as UserDarkTheme} from '../../../assets/image-icons/userDarkTheme.svg';
import {ReactComponent as UserHover} from '../../../assets/image-icons/userHover.svg';
import {ReactComponent as UserHoverDarkTheme} from '../../../assets/image-icons/userHoverDarkTheme.svg';
import {ReactComponent as Users} from '../../../assets/image-icons/users.svg';
import {ReactComponent as UsersActive} from '../../../assets/image-icons/usersActive.svg';
import {ReactComponent as UsersDarkTheme} from '../../../assets/image-icons/usersDarkTheme.svg';
import {ReactComponent as UsersHover} from '../../../assets/image-icons/usersHover.svg';
import {ReactComponent as UsersHoverDarkTheme} from '../../../assets/image-icons/usersHoverDarkTheme.svg';
import {ReactComponent as Wallet} from '../../../assets/image-icons/wallet.svg';
import {ReactComponent as WalletActive} from '../../../assets/image-icons/walletActive.svg';
import {ReactComponent as WalletHover} from '../../../assets/image-icons/walletHover.svg';
import {ReactComponent as WalletHoverDarkTheme} from '../../../assets/image-icons/walletHoverDarkTheme.svg';
import {ReactComponent as Workspace} from '../../../assets/image-icons/workspace.svg';
import {ReactComponent as WorkspaceActive} from '../../../assets/image-icons/workspaceActive.svg';
import {ReactComponent as WorkspaceDarkTheme} from '../../../assets/image-icons/workspaceDarkTheme.svg';
import {ReactComponent as WorkspaceHover} from '../../../assets/image-icons/workspaceHover.svg';
import {ReactComponent as WorkspaceHoverDarkTheme} from '../../../assets/image-icons/workspaceHoverDarkTheme.svg';

type IconType = React.FunctionComponent<React.SVGProps<SVGSVGElement> & {title?: string}>;

type IconVariant = 'light' | 'hover' | 'dark' | 'hover_dark' | 'active';

type IconVariantType = {
  light: IconType;
  hover: IconType;
  dark: IconType;
  hover_dark: IconType;
  active: IconType;
};

export function getSettingsMenuIcon(settingsTab: SettingsTab, variant: IconVariant = 'light'): IconType {
  const iconsDictionary: Record<SettingsTab, IconVariantType> = {
    [SettingsTab.PERSONAL_INFO]: {
      light: User,
      hover: UserHover,
      dark: UserDarkTheme,
      hover_dark: UserHoverDarkTheme,
      active: UserActive,
    },
    [SettingsTab.CHANNELS]: {
      light: LinkHorizontal,
      hover: LinkHorizontalHover,
      dark: LinkHorizontalDarkTheme,
      hover_dark: LinkHorizontalHoverDarkTheme,
      active: LinkHorizontalActive,
    },
    [SettingsTab.WORKSPACE_MEMBERS]: {
      light: Users,
      hover: UsersHover,
      dark: UsersDarkTheme,
      hover_dark: UsersHoverDarkTheme,
      active: UsersActive,
    },
    [SettingsTab.BILLING]: {
      light: Wallet,
      hover: WalletHover,
      dark: Wallet,
      hover_dark: WalletHoverDarkTheme,
      active: WalletActive,
    },
    [SettingsTab.SNIPPETS]: {
      light: Snippet,
      hover: SnippetHover,
      dark: SnippetDarkTheme,
      hover_dark: SnippetHoverDarkTheme,
      active: SnippetActive,
    },
    [SettingsTab.MESSAGE_TAGS]: {
      light: Tag,
      hover: TagHover,
      dark: TagDarkTheme,
      hover_dark: TagHoverDarkTheme,
      active: TagActive,
    },
    [SettingsTab.WORKSPACE_SETTINGS]: {
      light: Workspace,
      hover: WorkspaceHover,
      dark: WorkspaceDarkTheme,
      hover_dark: WorkspaceHoverDarkTheme,
      active: WorkspaceActive,
    },
    [SettingsTab.DOWNLOAD_APP]: {
      light: Download,
      hover: DownloadHover,
      dark: DownloadDarkTheme,
      hover_dark: DownloadHoverDarkTheme,
      active: DownloadActive,
    },
  };

  return iconsDictionary[settingsTab][variant];
}

interface SettingsMenuItemIconProps {
  tab: SettingsTab;
  lightThemeChosen: boolean;
}

const SettingsMenuItemIcon: React.FC<SettingsMenuItemIconProps> = ({tab, lightThemeChosen}) => {
  const Icon = getSettingsMenuIcon(tab, lightThemeChosen ? 'light' : 'dark');
  const IconHover = getSettingsMenuIcon(tab, lightThemeChosen ? 'hover' : 'hover_dark');
  const IconActive = getSettingsMenuIcon(tab, 'active');

  return (
    <ListItemIcon className="settings-menu__icon-wrapper">
      <Icon className="settings-menu__icon" />
      <IconHover className="settings-menu__icon settings-menu__icon--hover" />
      <IconActive className="settings-menu__icon settings-menu__icon--active" />
    </ListItemIcon>
  );
};

export default SettingsMenuItemIcon;
