import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import {InviteLinksType} from './InviteLinksViewer';
import {ReactComponent as PlusIcon} from '../../../../assets/icons/plus-line.svg';
import {ReactComponent as InviteLink} from '../../../../assets/image-icons/inviteLink.svg';

interface Props {
  type: InviteLinksType;
  onCreateLink?(e: React.MouseEvent<HTMLButtonElement>): void;
}

type Content = {
  title: string;
  description: string | React.ReactElement;
};

const NoInviteLinks: React.FC<Props> = (props) => {
  const {t} = useTranslation();

  const content: Record<InviteLinksType, Content> = {
    [InviteLinksType.Active]: {
      title: t('settings_workspace_members_no_active_links'),
      description: (
        <Trans i18nKey="settings_workspace_members_no_active_links_description" />
      ),
    },
    [InviteLinksType.Activated]: {
      title: t('settings_workspace_members_no_activated_links'),
      description: t('settings_workspace_members_no_activated_links_description'),
    },
    [InviteLinksType.Expired]: {
      title: t('settings_workspace_members_no_expired_links'),
      description: t('settings_workspace_members_no_expired_links_description'),
    },
  };

  return (
    <div className="invite-links-list__no-links">
      <InviteLink className="mb-6" />
      <div className="body1-bold mb-3">{content[props.type].title}</div>
      <div className="body1-regular color-body-quaternary mb-6">{content[props.type].description}</div>
      <Button
        className="invite-links-list__no-links-add-button ml-3"
        variant="contained"
        color="secondary"
        onClick={props.onCreateLink}
      >
        <PlusIcon className="invite-links-list__no-links-add-button-icon" />
        {t('settings_workspace_members_invite_links_create_button')}
      </Button>
    </div>
  );
};

export default NoInviteLinks;
