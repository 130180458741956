import React from 'react';
import {useTranslation} from 'react-i18next';

import {SearchInput} from 'o-ui/Input';
import {FixedSizeList, IFixedSizeList, ListOnScrollProps} from 'o-ui/VirtualizedList';
import useDebouncedCallback from 'o-ui/hooks/useDebouncedCallback';

import {SEARCH_TEXT_LENGTH_LIMIT} from '../../constants';
import {entities} from '../../api/proto';
import {IRawChat} from '../../stores/RawChatsStore/RawChat';
import SelectChatItem from './SelectChatItem';
import SelectChatProvider from './SelectChatProvider';

interface ISortedContact {
  children: IRawChat[];
  group?: string;
}

interface SelectChatProps {
  channelType?: entities.OzekonChannelType | null;
  chats: IRawChat[];
  onSelect?(chats: IRawChat[]): void;
  onSearch?(value: string): void;
  onScrolledToTop?(): void;
  onScrolledToBottom?(): void;
  withSearchField?: boolean;
  chatItemClassName?: string;
  listHeight?: number;
}

const DEFAULT_LIST_HEIGHT = 485;

export const SelectChat: React.FC<SelectChatProps> = React.memo(
  ({
    channelType,
    chats: propsChats,
    onSelect,
    onSearch,
    onScrolledToTop,
    onScrolledToBottom,
    chatItemClassName,
    listHeight = DEFAULT_LIST_HEIGHT,
  }) => {
    const {t} = useTranslation();
    const listRef = React.useRef<IFixedSizeList>(null);
    const listInnerRef = React.useRef<HTMLDivElement>(null);
    const [chats, setChats] = React.useState<(IRawChat[] & ISortedContact[]) | Array<IRawChat>>([]);

    React.useEffect(() => {
      setChats(propsChats);
    }, [propsChats]);

    const handleScrolledToTop = React.useCallback(() => {
      if (listHeight !== 0) {
        onScrolledToTop?.();
      }
    }, [listHeight, onScrolledToTop]);

    const handleScrolledToBottom = React.useCallback(() => {
      if (listHeight !== 0) {
        onScrolledToBottom?.();
      }
    }, [listHeight, onScrolledToBottom]);

    const scrollCallback = React.useCallback(
      (params: ListOnScrollProps) => {
        if (params.scrollUpdateWasRequested) {
          return;
        }

        const scrollHeight = listInnerRef.current?.clientHeight || 0;

        if (params.scrollDirection === 'backward' && params.scrollOffset === 0) {
          handleScrolledToTop();
        }

        if (scrollHeight >= listHeight && Math.floor(scrollHeight - params.scrollOffset - listHeight) <= 0) {
          handleScrolledToBottom();
        }
      },
      [listInnerRef, listHeight, handleScrolledToTop, handleScrolledToBottom],
    );

    const scrollCallbackDebounced = useDebouncedCallback(scrollCallback, 400);

    const handleScrollList = React.useCallback(
      (params: ListOnScrollProps) => {
        scrollCallbackDebounced(params);
      },
      [scrollCallbackDebounced],
    );

    const handleSearch = React.useCallback(
      (val: string) => {
        onSearch?.(val);
        listRef.current?.scrollTo(0);
      },
      [onSearch, listRef],
    );

    return (
      <SelectChatProvider onSelect={onSelect}>
        <div className="select-chat">
          <SearchInput
            className="w-100 mb-5"
            placeholder={t('components_select_chat_search_input_placeholder')}
            onChange={handleSearch}
            maxLength={SEARCH_TEXT_LENGTH_LIMIT}
            autoFocus
          />
          <FixedSizeList
            ref={listRef}
            innerRef={listInnerRef}
            className="chat-list chat-user-list custom-scroll list-unstyled"
            width={400}
            height={listHeight}
            itemCount={chats.length}
            itemSize={60}
            onScroll={handleScrollList}
          >
            {({index, style}) => (
              <SelectChatItem
                key={'chat-' + chats[index]?.id?.toString()}
                style={style}
                className={chatItemClassName}
                channelType={channelType}
                chat={chats[index]}
              />
            )}
          </FixedSizeList>
        </div>
      </SelectChatProvider>
    );
  },
);

export default SelectChat;
