import React from 'react';
import WorkspaceMember from '../../../stores/Workspaces/WorkspaceMember';

export interface SelectMemberContextValue {
  selectedMembers: WorkspaceMember[];
  toggleMember: (member: WorkspaceMember) => void;
  selectMembers: (members: WorkspaceMember[]) => void;
}

const noOp = () => {
  return;
};

const SelectMemberContext = React.createContext<SelectMemberContextValue>({
  selectedMembers: [],
  toggleMember: noOp,
  selectMembers: noOp,
});

export default SelectMemberContext;