import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';

import useAsyncLoading from '../../../../hooks/useAsyncLoading';
import {useStore} from '../../../../stores/AppStore';
import ModalType from '../../../../stores/ModalType';
import {ModalData} from '../../../../stores/ModalsStore';
import WorkspaceMember from '../../../../stores/Workspaces/WorkspaceMember';
import MemberManageForm, {IMemberManageFormRef} from './MemberManageForm';

interface IProps {
  member: WorkspaceMember;
  className?: string;
  bodyClassName?: string;
  onSubmit?: () => void;
  onClose?: () => void;
}

export const MemberManageTab = observer((props: IProps) => {
  const {t} = useTranslation();
  const {modals, notifications, userStore} = useStore();
  const formRef = React.useRef<IMemberManageFormRef>(null);

  const [saving, setSaving] = React.useState<boolean>(false);
  const [disabledSubmit, setDisabledSubmit] = React.useState<boolean>(false);

  const [handleSubmitBlock, blocking] = useAsyncLoading(async () => {
    const blocking = props.member.active;
    const {error, res} = blocking ? await props.member.block() : await props.member.unblock();

    if (error) {
      notifications.error(error.message);
    } else if (res) {
      notifications.success(
        blocking
          ? t('settings_workspace_members_manage_user_blocked_notification', {email: props.member.email})
          : t('settings_workspace_members_manage_user_unblocked_notification', {email: props.member.email}),
      );
    }
  });

  const handleBlockMember = () => {
    modals.open(ModalType.MEMBER_BLOCKING, {
      member: props.member,
      callbacks: {
        submit: handleSubmitBlock,
      },
    });
  };

  const handleBeforeSubmit = () => {
    setSaving(true);
  };

  const handlSubmit = (success: boolean) => {
    setSaving(false);
    if (success) {
      props.onSubmit?.();
    }
  };

  const handleSubmitClick = () => {
    formRef.current?.submitForm?.();
  };

  const [handleSubmitDelete, deleting] = useAsyncLoading(async (data?: ModalData) => {
    if (!data?.member) {
      return;
    }

    const {error, res} = await data.member.delete();

    if (error) {
      notifications.error(error.message);
    } else if (res) {
      notifications.success(
        t('settings_workspace_members_manage_user_deleted_notification', {
          email: data.member.email,
        }),
      );
      props.onSubmit?.();
    }
  });

  const handleDeleteClick = () => {
    modals.open(ModalType.MEMBER_DELETING, {
      member: props.member,
      callbacks: {
        submit: handleSubmitDelete,
      },
    });
  };

  return (
    <div className={cn('channel-settings', props.className)}>
      <div className={cn('channel-settings__wrap', props.bodyClassName)}>
        <div className="channel-settings__paper px-6 h-100 border-primary radius-8">
          <MemberManageForm
            ref={formRef}
            member={props.member}
            onBeforeSubmit={handleBeforeSubmit}
            onSubmit={handlSubmit}
            setDisabledSubmit={setDisabledSubmit}
          />
        </div>
      </div>

      <div className="settings-tab__footer manage-operators">
        <div className="left">
          <Button
            variant="contained"
            color="primary"
            size="large"
            className="mr-5"
            onClick={handleSubmitClick}
            disabled={disabledSubmit || saving || deleting || blocking}
            loading={saving}
          >
            {t('settings_workspace_members_manage_save')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            className="mr-5"
            onClick={props.onClose}
            disabled={saving || deleting || blocking}
          >
            {t('settings_workspace_members_manage_cancel')}
          </Button>
        </div>

        <div className="right">
          <Button
            variant="contained"
            color="error"
            size="large"
            className="nowrap ml-auto mr-5 d-none"
            onClick={handleDeleteClick}
            disabled={saving || deleting || blocking}
            loading={deleting}
          >
            {t('settings_workspace_members_manage_delete')}
          </Button>

          {(props.member?.active || props.member?.blocked) &&
          !props.member.isActiveUser &&
          (!props.member.isAdmin || userStore.user?.isOwner) ? (
            <Button
              className="nowrap ml-auto "
              variant="contained"
              color="errorSecondary"
              size="large"
              onClick={handleBlockMember}
              disabled={saving || deleting || blocking}
              loading={blocking}
            >
              {props.member.active
                ? t('settings_workspace_members_manage_block')
                : t('settings_workspace_members_manage_unblock')}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
});

export default MemberManageTab;
