import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Message from '../../../stores/Message';

function useMessageTypeTitle(message: Message): string {
  const {t} = useTranslation();

  const getMessageType = useCallback(
    (msg: Message): string => {
      if (msg.hasSticker) {
        return t('reply_to_sticker_message');
      } else if (msg.hasVideo) {
        return t('reply_to_video_message');
      } else if (msg.hasImage) {
        return t('reply_to_photo_message');
      } else if (msg.hasVoice) {
        return t('reply_to_voice_message');
      } else if (msg.hasAudio || msg.hasDocument) {
        return msg.firstAttachment?.name || '';
      } else if (msg.hasGif) {
        return t('reply_to_gif_message');
      }
      return '';
    },
    [t],
  );

  const [title, setTitle] = useState(getMessageType(message));

  useEffect(() => {
    setTitle(getMessageType(message));
  }, [message, getMessageType]);

  return title;
}

export default useMessageTypeTitle;
