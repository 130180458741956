import React from 'react';
import {useTranslation} from 'react-i18next';

import SettingsTab from '../../stores/LayOutStore/SettingsTab';
import DownloadAppOSButton from './DownloadApp/DownloadAppOSButton';
import {AppPlatform, DesktopAppData, getAppInfo} from './DownloadApp/utils';
import {SettingsHeadState} from './SettingsHead';
import useAllowChangeHeadState from './useAllowChangeHeadState';

interface DownloadAppTabProps {
  onChangeState?: (state: SettingsHeadState) => void;
}

export const DownloadAppTab = React.memo((props: DownloadAppTabProps) => {
  const {t} = useTranslation();

  const allowChangeHeadState = useAllowChangeHeadState(SettingsTab.DOWNLOAD_APP);

  const [winAppInfo, setWinAppInfo] = React.useState<DesktopAppData | null>(null);
  const [linuxAppInfo, setLinuxAppInfo] = React.useState<DesktopAppData | null>(null);

  React.useEffect(() => {
    const loadUrls = async () => {
      const winAppInfo = await getAppInfo(AppPlatform.WINDOWS);
      setWinAppInfo(winAppInfo);

      const linuxAppInfo = await getAppInfo(AppPlatform.LINUX);
      setLinuxAppInfo(linuxAppInfo);
    };

    loadUrls();
  }, []);

  React.useEffect(() => {
    if (!allowChangeHeadState) {
      return;
    }
    props.onChangeState?.({
      title: t('settings_download_app_management'),
    });
  }, [props, allowChangeHeadState, t]);

  return (
    <div className="settings-tab">
      <div className="settings-tab__body custom-scroll">
        <div className="settings-tab__paper w-100 h-100">
          <div className="body1-regular">{t('settings_download_app_title')}</div>

          <div className="mt-6">
            <DownloadAppOSButton
              platform={AppPlatform.WINDOWS}
              className="mb-5"
              downloadUrl={winAppInfo?.url}
              version={winAppInfo?.version}
              disabled={!winAppInfo?.url}
            />
            <DownloadAppOSButton
              platform={AppPlatform.MAC}
              className="mb-5"
              disabled
            />
            <DownloadAppOSButton
              platform={AppPlatform.LINUX}
              downloadUrl={linuxAppInfo?.url}
              version={linuxAppInfo?.version}
              disabled={!linuxAppInfo?.url}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default DownloadAppTab;