import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {observer} from 'mobx-react';

import useOnClickOutside from '../hooks/useOnClickOutside';
import {useStore} from '../stores/AppStore';
import {IMenuPosition} from '../stores/ContextMenuStore';
import windowInnerHeight from '../utils/windowInnerHeight';
import {ContextMenuWrapper} from './ContextMenuWrapper';

interface IContextMenuStyles {
  position: IMenuPosition;
  height: number;
  width: number;
}

const getStyles = ({position, height, width}: IContextMenuStyles) => {
  const style: React.CSSProperties = {};

  const innerHeight = windowInnerHeight();
  const verticalOffset = position?.verticalOffset || 0;

  let top = verticalOffset;
  let left = 0;

  if (innerHeight - position.clientY < height) {
    top = position.clientY - height - verticalOffset;
  } else {
    top = position.clientY - verticalOffset;
  }

  if (window.innerWidth - position.clientX < width || position.rightAlign) {
    left = position.clientX - width;
  } else {
    left = position.clientX;
  }

  style['top'] = top >= 0 ? top : verticalOffset;
  style['left'] = left >= 0 ? left : 0;

  return style;
};

export const ContextMenu: React.FC<React.PropsWithChildren> = observer(() => {
  const app = useStore();
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState<React.CSSProperties>({});
  const menu = app.contextMenu.current;

  const height = contextMenuRef.current?.clientHeight || 0;
  const width = contextMenuRef.current?.clientWidth || 0;

  useEffect(() => {
    if (menu) {
      setStyle(
        getStyles({
          position: menu.position,
          height,
          width,
        }),
      );
    }
  }, [menu, menu?.position, height, width]);

  const handleClickOutside = () => {
    if (menu) {
      menu.close();
    }
  };

  useOnClickOutside(contextMenuRef, handleClickOutside);

  if (!menu) {
    return null;
  }

  const onContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  return ReactDOM.createPortal(
    <div
      className="context-menu"
      style={style}
      ref={contextMenuRef}
      onContextMenu={onContextMenu}
    >
      <ContextMenuWrapper key={menu.id.toString()} menu={menu} />
    </div>,
    window.document.body,
  );
});

export default ContextMenu;