import {toc} from 'mdast-util-toc';

export default function remarkMenu(options: {heading?: string} = {}) {
  return (node: any) => {
    const result = toc(
      node,
      Object.assign({}, options, {
        heading: options.heading || 'Javascript API',
      }),
    );

    if (result.endIndex === null || result.index === null || result.index === -1 || !result.map) {
      return;
    }

    node.children = [result.map];
  };
}
