import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {ReactComponent as CheckIcon} from '../../../../../assets/icons/check-round-fill.svg';
import {ReactComponent as CrossIcon} from '../../../../../assets/icons/cross-round-fill.svg';
import {WorkspaceMemberAccessRecord} from '../../../../../stores/Workspaces/WorkspaceMemberAccessRecord';

interface MemberAccessInfoProps {
  classNames?: string;
  accessRecord: WorkspaceMemberAccessRecord;
}

type AccessInfoData = {
  label: string;
  Icon: typeof CheckIcon;
  classNames?: string;
};

const MemberAccessInfo: React.FC<MemberAccessInfoProps> = observer((props) => {
  const {t} = useTranslation();
  const {chatsAllowList, chatsDenyList, disableChannel} = props.accessRecord;

  const getInfoData = (): AccessInfoData => {
    if (disableChannel) {
      return {
        label: t('settings_channel_access_item_disabled_label'),
        Icon: CrossIcon,
        classNames: 'disabled',
      };
    } else if (chatsAllowList?.length) {
      return {
        label: t('settings_channel_access_item_allowed_chats_label', {
          count: chatsAllowList.length,
        }),
        Icon: CheckIcon,
        classNames: 'partly',
      };
    } else if (chatsDenyList?.length) {
      return {
        label: t('settings_channel_access_item_disallowed_chats_label', {
          count: chatsDenyList.length,
        }),
        Icon: CrossIcon,
        classNames: 'partly',
      };
    }

    return {
      label: t('settings_channel_access_item_allow_all_label'),
      Icon: CheckIcon,
    };
  };

  const {label, Icon, classNames} = getInfoData();

  return (
    <div className={cn('channels-access-item__info', props.classNames)}>
      <Icon className={cn('channels-access-item__info-icon', classNames)} />
      {label}
    </div>
  );
});

export default MemberAccessInfo;
