import React from 'react';

import {useStore} from '../../stores/AppStore';
import Chat from '../../stores/Chat';
import ModalType from '../../stores/ModalType';
import {FileData} from '../../utils/file/fileReaders';
import FileInput from './FileInput';
import useFileChangeHendler from './useFileChangeHendler';


interface MultiFileUploaderProps {
  chat: Chat;
  className?: string;
}

export const MultiFileUploader = React.forwardRef<HTMLInputElement, MultiFileUploaderProps>((props, ref) => {
  const {modals} = useStore();

  const handleSend = (dataFiles: FileData[]) => {
    const photoCount = dataFiles?.filter((f) => f.image).length || 0;
    const videoCount = dataFiles?.filter((f) => f.video).length || 0;

    if (dataFiles.length === photoCount + videoCount) {
      modals.open(ModalType.UPLOAD_PHOTO_VIDEO_ASYNC, {dataFiles, chat: props.chat});
    } else {
      modals.open(ModalType.UPLOAD_FILE_ASYNC, {dataFiles, chat: props.chat});
    }
  };

  const handleFileChange = useFileChangeHendler(handleSend);

  return (
    <FileInput
      className={props.className}
      ref={ref}
      onChange={handleFileChange}
      multiple={true}
    />
  );
});

export default MultiFileUploader;
