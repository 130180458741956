import React from 'react';
import cn from 'classnames';
import ReactPlayer from 'react-player';

import {IconButton} from 'o-ui/Button';
import {niceBytes} from '../../utils/format';
import {useStore} from '../../stores/AppStore';


interface UploadAudioInfoProps {
  name?: string | null;
  type?: string | null;
  size?: number | null;
  url?: string | null;
  objectUrl?: string;
  onDelete?(objectUrl?: string): void;
}

export const UploadAudioInfo: React.FC<UploadAudioInfoProps> = ({
  name,
  size,
  objectUrl,
  onDelete,
}) => {
  const src = objectUrl;
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);

  const {chatsView} = useStore();
  const {audioPlayer} = chatsView;

  const togglePlayingClick = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="file-info audio-player">
      <button
        color="none"
        type="button"
        className={cn('audio-player__play-btn tg-icon', {
          'tg-icon-play-circle-fill': !isPlaying,
          'tg-icon-pause-circle-fill': isPlaying,
        })}
        onClick={togglePlayingClick}
      />

      <ReactPlayer
        className="d-none"
        url={src || ''}
        volume={audioPlayer.volume ? audioPlayer.volume / 100 : 0}
        controls={false}
        playsinline={true}
        playing={isPlaying}
        progressInterval={100}
      />

      <div className="file-info__body">
        <div className="text-left text-truncate">
          <div className="file-info__name">{name}</div>
          <p className="file-info__size body2-regular color-body-tertiary font-size-12 mb-0">
            {niceBytes(size)}
          </p>
        </div>
        {onDelete ? (
          <IconButton
            className="ml-auto"
            onClick={(e) => {
              e.preventDefault();
              onDelete?.(objectUrl);
            }}
          >
            <span className="file-info__delete-btn tg-icon-delete-bin-line icon-size-18" />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
};

export default UploadAudioInfo;
