import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import InputBase from 'o-ui/Input/InputBase';
import Tabs from 'o-ui/Tabs';
import Tab from 'o-ui/Tab';
import {useStore} from '../../../stores/AppStore';
import {ModalType} from '../../../stores/ModalType';
import {ITag, TagType} from '../../../stores/MessageTagsStore';
import MessageTagsTable from './MessageTagsTable';
import {ReactComponent as PlusIcon} from '../../../assets/icons/plus-line.svg';

export const MessageTagsViewer = observer(() => {
  const {t} = useTranslation();

  const {modals} = useStore();

  const [tagsList] = React.useState<ITag[]>([]); // chatStore.tags.list;

  const [selectedType, setSelectedType] = React.useState(TagType.Workspace);
  const [filter, setFilter] = React.useState<string>('');

  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const handleChangeTab = (e: React.ChangeEvent, newValue: number) => {
    setSelectedType(newValue);
    setFilter('');
  };

  const handleCreateTag = () => {
    modals.open(ModalType.MESSAGE_TAG_CREATING, {
      tag: {
        isWorkspace: selectedType === TagType.Workspace,
      },
    });
  };
  /*
  React.useEffect(() => {
    chatStore.tags.init();
  }, [chatStore]);
  */
  const userIsAdmin = true; // user?.isAdmin || systemUser?.isOwner

  const workspaceTagsLength = tagsList.filter((tag) => tag.isWorkspace).length;
  const personalTagsLength = tagsList.filter((tag) => !tag.isWorkspace).length;

  return (
    <>
      <Tabs className="message-tags-tab__tabs" value={selectedType} onChange={handleChangeTab}>
        <Tab
          label={
            <span className="d-flex align-items-center">
              {t('settings_message_tags_workspace_tags')}
              <span className="tab-counter">{workspaceTagsLength}</span>
            </span>
          }
        />
        <Tab
          label={
            <span className="d-flex align-items-center">
              {t('settings_message_tags_personal_tags')}
              <span className="tab-counter">{personalTagsLength}</span>
            </span>
          }
        />
      </Tabs>

      {(workspaceTagsLength && selectedType === TagType.Workspace) ||
      (personalTagsLength && selectedType === TagType.Personal) ? (
        <div className="d-flex mt-5">
          <div className="message-tags-tab__search bg-background-secondary radius-8 d-flex flex-auto align-items-center px-5 py-1">
            <span className="tg-icon-search-line mr-5" />
            <InputBase
              className="w-100"
              placeholder={t('settings_message_tags_search_placeholder')}
              value={filter}
              onChange={handleChangeFilter}
            />
          </div>

          {(userIsAdmin && selectedType === TagType.Workspace) || selectedType === TagType.Personal ? (
            <Button className="ml-3" variant="contained" color="secondary" onClick={handleCreateTag}>
              <PlusIcon className="message-tags-tab__add-icon" />
              {t('settings_message_tags_create_tag')}
            </Button>
          ) : null}
        </div>
      ) : null}

      <MessageTagsTable selectedType={selectedType} filter={filter} />
    </>
  );
});

export default MessageTagsViewer;
