import React from 'react';
import {useTranslation} from 'react-i18next';
import {ModalItem} from '../stores/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';
import Button from 'o-ui/Button';
import DialogContent from 'o-ui/Dialog/DialogContent';
import DialogActions from 'o-ui/Dialog/DialogActions';

interface IProps {
  modal: ModalItem;
}

export const ModalUploadAvatar = (props: IProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const handleSubmit = () => {
    props.modal.trigger('submit', props.modal.data);
    handleCloseClick();
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      title={t('New photo')}
      maxWidth="sm"
      fullWidth={true}
      scroll="body"
      PaperProps={{
        className: 'modal--upload overflow-initial',
      }}
    >
      <DialogContent className="p-4 d-flex justify-content-center">
        {props.modal.data.dataFiles?.map((dataFile, i) =>
          dataFile.image ? (
            <img
              className="preview-img"
              key={i}
              style={{maxHeight: '50vh', maxWidth: '100%'}}
              src={dataFile.objectUrl}
              alt=""
            />
          ) : null,
        )}
      </DialogContent>
      <DialogActions className="justify-content-end">
        <Button variant="contained" color="tertiary" size="large" onClick={handleCloseClick}>
          {t('Cancel')}
        </Button>
        <Button variant="contained" color="primary" size="large" onClick={handleSubmit}>
          {t('Ok')}
        </Button>
      </DialogActions>
    </ModalDialog>
  );
};

export default ModalUploadAvatar;
