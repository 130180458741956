import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';
import {getSecondsDiff} from '../utils/format';

const DEFAULT_TIMER_TIME = 30;

export class TimerStore {
  @observable currentTime = 0;
  private timer: NodeJS.Timeout | null = null;

  constructor() {
    makeObservable(this);
  }

  @action private setTime = (time: number) => {
    this.currentTime = time;
  };

  @computed get stopped(): boolean {
    return this.currentTime <= 0;
  }

  @computed get inProgress(): boolean {
    return this.currentTime > 0;
  }

  private decreaseTime = () => {
    if (this.currentTime > 0) {
      this.setTime(this.currentTime - 1);

      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(this.decreaseTime, 1000);
    }
  };

  run = (timerTime = DEFAULT_TIMER_TIME) => {
    this.setTime(Math.round(Math.abs(timerTime)));
    this.decreaseTime();
  };

  stop = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.setTime(0);
  };

  runByNextTryAtTimestamp = (nextTryAtTimestamp?: Long | null) => {
    if (nextTryAtTimestamp && nextTryAtTimestamp.toNumber() > 0) {
      this.run(getSecondsDiff(nextTryAtTimestamp));
    } else {
      this.stop();
    }
  };
}

export default TimerStore;
