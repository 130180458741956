import Chat from '../Chat';

export enum ChatsAssignedType {
  TO_ME = 'TO_ME',
  TO_OTHER = 'TO_OTHER',
}

export function getChatAssignedType(chat: Chat) {
  return chat.assignment.youAreJoined ? ChatsAssignedType.TO_ME : ChatsAssignedType.TO_OTHER;
}

export default getChatAssignedType;