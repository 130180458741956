import React from 'react';
import {useTranslation} from 'react-i18next';

import Avatar from 'o-ui/Avatar';
import Button from 'o-ui/Button';
import DialogContent from 'o-ui/Dialog/DialogContent';
import DialogActions from 'o-ui/Dialog/DialogActions';
import FormLabel from 'o-ui/FormLabel';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import trimInput from 'o-ui/utils/trimInput';

import {entities} from '../api/proto';
import {useStore} from '../stores/AppStore';
import {ModalItem} from '../stores/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';
import AvatarPicker, {AvatarPickerRef} from '../components/FileUploader/AvatarPicker';
import formatAttachment from '../utils/file/formatAttachment';
import {FileData} from '../utils/file/fileReaders';
import {WORKSPACE_NAME_LENGTH_LIMIT} from '../constants';

interface IProps {
  modal: ModalItem;
}

export const ModalWorkspaceCreating = ({modal}: IProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const refAvatarPicker = React.useRef<AvatarPickerRef | null>(null);

  const {notifications, workspaces, uploader} = useStore();

  const [newPhoto, setNewPhoto] = React.useState<FileData | null>(null);
  const [workspaceName, setWorkspaceName] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);
    let attachmentSource: entities.IAttachmentSource | null = null;
    if (newPhoto) {
      newPhoto.attachment = formatAttachment(newPhoto);
      uploader.addToProgress([newPhoto]);

      const {attachments} = await uploader.uploadFiles(
        newPhoto.fileId,
        [newPhoto],
      );

      if (attachments.length) {
        attachmentSource = attachments[0].source || null;
      }
    }

    const {error, res} = await workspaces.createWorkspace(workspaceName, attachmentSource);

    if (error) {
      notifications.error(error.message);
    }

    if (res) {
      notifications.success(t('settings_workspace_created_successfully'));
      handleCloseClick();
    }
    setLoading(false);
  };

  const handleChangeAvatar = () => {
    refAvatarPicker.current?.open?.();
  };

  const handleFileChange = (dataFiles: FileData[]) => {
    if (dataFiles.length) {
      setNewPhoto(dataFiles[0]);
    }
  };

  const handleChangeWorkspaceName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = trimInput(e.target.value);
    if (newName.length <= WORKSPACE_NAME_LENGTH_LIMIT) {
      setWorkspaceName(newName);
    }
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  return (
    <ModalDialog
      ref={modalRef}
      modal={modal}
      title={t('modal_workspace_creating_title')}
      maxWidth="xs"
      fullWidth={true}
      className="modal-group-creating"
    >
      <DialogContent className="p-6">
        <div className="d-flex">
          <div className="mr-5">
            {newPhoto ? (
              <Avatar
                size="lg"
                className="upload-btn position-relative filled d-flex justify-content-center"
                src={newPhoto?.objectUrl}
                onClick={handleChangeAvatar}
              >
                <i className="tg-icon position-absolute-center tg-icon-camera-add-line text-white" />
              </Avatar>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className="upload-btn position-relative rounded-50 btn-icon"
                type="button"
                onClick={handleChangeAvatar}
              >
                <i className="tg-icon position-absolute-center tg-icon-camera-add-line text-white" />
              </Button>
            )}
            <AvatarPicker
              className="d-none"
              ref={refAvatarPicker}
              onChange={handleFileChange}
            />
          </div>
          <div className="flex-grow-1">
            <FormLabel>{t('modal_workspace_creating_workspace_name_label')}</FormLabel>
            <OutlinedTextInput
              className="w-100 mb-5"
              value={workspaceName}
              onChange={handleChangeWorkspaceName}
              placeholder={t('modal_workspace_creating_workspace_name_placeholder')}
              required
              autoFocus
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
          disabled={workspaceName.length === 0}
          loading={loading}
        >
          {t('modal_create_btn')}
        </Button>
        <Button variant="contained" color="secondary" size="large" onClick={handleCloseClick}>
          {t('modal_cancel_btn')}
        </Button>
      </DialogActions>
    </ModalDialog>
  );
};

export default ModalWorkspaceCreating;
