import React from 'react';
import ImageTransformer, {ImageTransformerProps} from './ImageTransformer';

export const MessageImageViewer = (props: ImageTransformerProps) => {
  return (
    <ImageTransformer
      transformerClassName="d-none w-100 h-100"
      loaderSize={80}
      {...props}
    />
  );
};

export default MessageImageViewer;
