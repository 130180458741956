
export default function jsonParse<T>(jsonStr?: string | null): T | undefined {
  let obj: T | undefined = undefined;
  if (!jsonStr) {
    return obj;
  }

  try {
    obj = JSON.parse(jsonStr);
  } catch (e) {
    console.debug(e);
  }

  return obj;
}
