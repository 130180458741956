import cn from 'classnames';
import React from 'react';
import {useLocation} from 'react-router-dom';
import {observer} from 'mobx-react';

import useStore from '../stores/useStore';

const AuthLayout: React.FC<React.PropsWithChildren> = observer(({children}) => {
  const location = useLocation();
  const {anal, layOutStore, workspaces} = useStore();

  React.useEffect(() => {
    if (workspaces.isInit) {
      console.debug('anal.AuthLayout', location.pathname);
      anal.openRoute(location.pathname);
    }
  }, [
    anal,
    location.pathname,
    workspaces.isInit,
  ]);

  return (
    <div
      className={cn('layout-wrapper', {
        'right-sidebar-open': layOutStore.rightSideBar.isOpen,
        collapsed: layOutStore.leftBarCollapsed,
      })}
    >
      {children}
    </div>
  );
});

export default AuthLayout;