import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react-lite';

import Channel from '../../../../stores/Channel';
import WorkspaceMember from '../../../../stores/Workspaces/WorkspaceMember';
import WorkspaceMemberAccessRecord from '../../../../stores/Workspaces/WorkspaceMemberAccessRecord';
import MembersAccess from './MembersAccess';
import ChatsAccess from './ChatsAccess';


interface TeamAccessProps {
  channel: Channel;
  disabled?: boolean;
  className?: string;
  onToggleAccessRecord?(record: WorkspaceMemberAccessRecord | null): void;
  onChange?(changed: boolean, member: WorkspaceMember): void;
}

export interface TeamAccessRef {
  selectedAccessRecord: WorkspaceMemberAccessRecord | null;
  backToChannels: () => void;
}

export const TeamAccess = observer(
  React.forwardRef<TeamAccessRef, TeamAccessProps>(
    ({channel, className, disabled, onToggleAccessRecord, onChange}, ref) => {

      const members = React.useMemo<WorkspaceMember[]>(
        () => channel.workspace.members
          .filter((m) => !m.isOwner && m.activated)
          .map((m) => m.clone())
          .sort((memberA, memberB) => {
            return (
              Number(memberA.expired) - Number(memberB.expired) ||
              Number(memberB.active) - Number(memberA.active) ||
              Number(memberB.isOwner) - Number(memberA.isOwner) ||
              Number(memberB.isAdmin) - Number(memberA.isAdmin) ||
              Number(memberB.isOperator) - Number(memberA.isOperator)
            );
          }),
        [
          channel.workspace.members,
        ]);

      const [selectedMember, setSelectedMember] = React.useState<WorkspaceMember | null>(null);
      const [selectedAccessRecord, setSelectedAccessRecord] = React.useState<WorkspaceMemberAccessRecord | null>(null);

      const handleOpenAccessRecord = (member: WorkspaceMember, record: WorkspaceMemberAccessRecord) => {
        setSelectedMember(member);
        setSelectedAccessRecord(record);
        onToggleAccessRecord?.(record);
      };

      const handleBackToChannels = () => {
        setSelectedAccessRecord(null);
        setSelectedMember(null);
        onToggleAccessRecord?.(null);
      };

      React.useImperativeHandle(ref, () => ({
        selectedAccessRecord: selectedAccessRecord,
        backToChannels: handleBackToChannels,
      }));

      return (
        <div className={cn('channels-chats-access', className, {disabled})}>
          <div className="channels-chats-access__container">
            {selectedMember && selectedAccessRecord ? (
              <ChatsAccess
                onBackToChannels={handleBackToChannels}
                member={selectedMember}
                accessRecord={selectedAccessRecord}
                onChange={onChange}
              />
            ) : (
              <MembersAccess
                channel={channel}
                members={members}
                onOpenAccessRecord={handleOpenAccessRecord}
                onChange={onChange}
              />
            )}
          </div>
        </div>
      );
    },
  ),
);

export default TeamAccess;
