import cn from 'classnames';
import Delta from 'quill-delta';
import React from 'react';
import * as uuid from 'uuid';

import {isMobileOrTablet} from 'o-ui/utils/browser-detect';

import {MAX_MESSAGE_TEXT_LENGTH} from '../../constants';
import MentionBlot from './MentionBlot';
import {DeltaStatic} from './QuillCore';
import Sources from './QuillCore/core/emmiterSources';
import Quill from './QuillCore/quill';
import QuillEditor, {QuillCore} from './QuillEditor';
import CustomQuillTheme from './themes/CustomQuillTheme';

Quill.register(
  {
    'themes/CustomQuillTheme': CustomQuillTheme,
  },
  true,
);

Quill.register(MentionBlot);

interface QuillTextInputProps {
  className?: string | null;
  editorClassName?: string | null;
  onChange?: (htmlContent: string, countLines: number) => void;
  onEnter?: () => void;
  children?: React.ReactNode;
  defaultValue?: string | DeltaStatic;
  placeholder?: string;
  bounds?: string | HTMLElement;
}

export const QuillTextInput = React.forwardRef((props: QuillTextInputProps, ref: React.Ref<QuillEditor | null>) => {
  const [id] = React.useState<string>(uuid.v4());
  const [editorRef, setEditorRef] = React.useState<QuillEditor | null>(null);
  const [quill, setQuill] = React.useState<QuillCore | null>(null);

  const measuredRef = (node: QuillEditor | null) => {
    setEditorRef(node);
    setQuill(node?.getEditor() || null);
  };

  React.useImperativeHandle(ref, () => editorRef);

  const getSelectionStartIndex = (): number => {
    const range = quill?.getSelection(true);
    return range?.index || 0;
  };

  const handleKeyDownTextarea = (e: React.KeyboardEvent) => {
    if (!e.ctrlKey && !e.shiftKey && e.keyCode === 13 && !isMobileOrTablet()) {
      e.preventDefault();
      e.stopPropagation();
      quill?.deleteText(getSelectionStartIndex() - 1, 1);
      props.onEnter?.();
      return false;
    }
    return true;
  };

  const handleKeyUpTextarea = (e: React.KeyboardEvent) => {
    if (e.ctrlKey && e.keyCode === 13) {
      quill?.insertText(getSelectionStartIndex(), '\n');
    } else if (!e.ctrlKey && !e.shiftKey && e.keyCode === 13 && !isMobileOrTablet()) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    return true;
  };

  const handleChangeQuill = (content: string, delta: Delta, source: Sources) => {
    quill?.deleteText(MAX_MESSAGE_TEXT_LENGTH, quill.getLength());

    if (source !== Sources.SILENT) {
      props.onChange?.(content, quill?.getLines().length || 0);
    }
  };

  return (
    <div className={cn('text-input', props.className)}>
      <QuillEditor
        id={id}
        ref={measuredRef}
        className={cn('text-input__quill', props.editorClassName)}
        theme="CustomQuillTheme"
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        onChange={handleChangeQuill}
        onKeyDown={handleKeyDownTextarea}
        onKeyUp={handleKeyUpTextarea}
        modules={{
          toolbar: ['bold', 'italic', 'underline', 'strike', 'link'],
        }}
        formats={['mention', 'bold', 'italic', 'underline', 'strike', 'link']}
        bounds={props.bounds}
      />
      {props.children}
    </div>
  );
});

export default QuillTextInput;
