import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';
// import {useStore} from '../../../../stores/AppStore';
import {useTranslation} from 'react-i18next';
import {Chat} from '../../../../stores/Chat';
// import {ModalType} from '../../../../stores/ModalType';
import Button from 'o-ui/Button';

interface ChatHeadForwardsProps {
  chat: Chat;
  className?: string;
}

export const ChatHeadForwards = observer((props: ChatHeadForwardsProps) => {
  // const {modals} = useStore();
  const {t} = useTranslation();

  const closeForwardMessages = () => {
    console.debug(`TODO: not implemented handler "closeForwardMessages"`);
  };

  React.useEffect(() => {
    const listener = (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        // props.chat.store?.selector?.reset();
      }
    };
    window.document.addEventListener('keydown', listener);

    return () => {
      window.document.removeEventListener('keydown', listener);
    };
  }, []);

  const openForwardMessages = () => {
    console.debug(`TODO: not implemented handler "openForwardMessages"`);
    // if (props.chat.store?.selectedMessages.length) {
    //   modals.open(ModalType.FORWARD, {chat: props.chat, messages: props.chat.store?.selectedMessages});
    // }
  };

  return (
    <div className={cn('row no-gutters align-items-center w-100', props.className)}>
      <div className="col d-flex align-items-center justify-content-between">
        <Button variant="contained" color="primary" onClick={openForwardMessages}>
          <span>{t('chat_user_chat_head_forward_title')}</span>
          {/* <span className="pl-3 opacity-07">{props.chat.store?.selectedMessages.length}</span> */}
        </Button>
        <Button className="ml-auto" variant="contained" color="tertiary" onClick={closeForwardMessages}>
          {t('chat_user_chat_head_forward_cancel')}
        </Button>
      </div>
    </div>
  );
});

export default ChatHeadForwards;
