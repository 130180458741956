
export enum ChatTab {
  CHATS,
  CONTACTS,
  TEAM,
  OPERATORS,
  SETTINGS,
}

export default ChatTab;
