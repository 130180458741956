import React from 'react';
import {useTranslation} from 'react-i18next';
import Div100vh from '../../components/Div100vh';

import AuthInfo from './AuthInfo';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPasswordPage = () => {
  const {t} = useTranslation();

  return (
    <Div100vh className="auth-page">
      <div className="auth-page__left-side">
        <AuthInfo />
      </div>

      <div className="auth-page__right-side">
        <div className="auth-page__title">{t('forgot_password_title')}</div>

        <ForgotPasswordForm className="mb-auto auth-page__form" />
      </div>
    </Div100vh>
  );
};

export default ForgotPasswordPage;
