import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import Skeleton from 'o-ui/Skeleton';
import {MCAttachment} from '../../../../api/proto';
import {useStore} from '../../../../stores/AppStore';

interface MediaGallerySkeletonProps {
  className?: string;
  children: React.ReactNode;
  attachmentType?: MCAttachment.Type | null;
}

const DEFAULT_ITEMS_COUNT = 24;

export const MediaGallerySkeleton: React.FC<MediaGallerySkeletonProps> = observer((props) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const {
    layOutStore: {rightSideBar},
    chatsView,
  } = useStore();

  const [itemsCount, setItemsCount] = React.useState<number>(DEFAULT_ITEMS_COUNT);
  const [size, setSize] = React.useState(0);

  const attachmentsCount =
    chatsView.activeUsersChat?.store.getAttachmentsHistoryByType(props.attachmentType)?.attachmentsCount || 0;

  React.useEffect(() => {
    const containerEl = containerRef.current;

    if (containerEl) {
      const size = containerEl.offsetWidth / 3;
      setSize(size);

      const maxCount = Math.round(containerEl.offsetHeight / size) * 3;
      setItemsCount(attachmentsCount < maxCount ? attachmentsCount : maxCount);
    }
  }, [containerRef, attachmentsCount]);

  if (rightSideBar.chatInfoBarGalleryItemsDisplayAllow || !rightSideBar.chatInfoBarAnimationAllow) {
    return <>{props.children}</>;
  }

  if (!attachmentsCount) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className={cn('chat-gallery-skeleton chat-gallery-skeleton--media-gallery', props.className)}
    >
      <div className="profile-sidebar__date-delimiter">
        <Skeleton withoutContainer inline width="100%" height={16} animationType="slide" />
      </div>
      <div className="chat-gallery-skeleton__container">
        {Array(itemsCount)
          .fill('')
          .map((_, index) => (
            <Skeleton key={index} height={size} inline animationType="slide" className="chat-gallery-skeleton__item" />
          ))}
      </div>
    </div>
  );
});

export default MediaGallerySkeleton;
