import React from 'react';

export default function WindowTitleBar({
  children,
}: React.PropsWithChildren) {
  return (
    <div className="window-title-bar">
      {children}
    </div>
  );
}
