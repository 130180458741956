import windowInnerHeight from '../windowInnerHeight';

export default function isInViewport(el: HTMLElement): boolean {
  const innerHeight = windowInnerHeight();
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
