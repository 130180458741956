import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import Checkbox from 'o-ui/Checkbox';
import {useStore} from '../../../../../stores/AppStore';
import {ChatsFilterType} from '../../../../../stores/ChatsView/ChatsFilterStore';
import CommonFilter from './CommonFilter';
import WebWidgetFilter from './WebWidgetFilter';


export const ChatsFilter: React.FC<React.PropsWithChildren> = observer((props) => {
  const {t} = useTranslation();
  const {
    activeWorkspace: {chatsLoader},
    chatsView,
  } = useStore();

  const getChatsFilter = () => {
    switch (chatsView.filter.type) {
      case ChatsFilterType.WEB_WIDGET_FILTER:
        return <WebWidgetFilter />;
      case ChatsFilterType.COMMON_FILTER:
        return <CommonFilter />;
      default:
        return null;
    }
  };

  return (
    <div className="chats-list-filter">
      {getChatsFilter()}

      <div className="chats-list-filter__row">
        {props.children ? (
          <div className={cn('chats-list-filter__content', {'mr-0': !chatsView.search.unreadFilterVisible})}>
            {props.children}
          </div>
        ) : null}

        {chatsView.search.unreadFilterVisible ? (
          <Button
            variant="outlined"
            color={chatsLoader.unreadOnly ? 'primary' : 'tertiary'}
            size="small"
            className="chats-list-filter__btn ml-auto"
            onClick={chatsLoader.toggleUnreadOnly}
          >
            <Checkbox className="mr-3" checked={chatsLoader.unreadOnly} />
            {t('chat_chats_tab_filter_unread')}
          </Button>
        ) : null}
      </div>
    </div>
  );
});

export default ChatsFilter;
