import React from 'react';
import {observer} from 'mobx-react';
import {Message} from '../../../stores/Message';
import {useStore} from '../../../stores/AppStore';
import {useTranslation} from 'react-i18next';

interface UnreadMessagesDelimiterProps {
  message: Message;
}

export const UnreadMessagesDelimiter: React.FC<UnreadMessagesDelimiterProps> = observer((props) => {
  const {chatsView} = useStore();
  const {t} = useTranslation();

  const messageIdWithUnreadDelimiter = chatsView?.activeUsersChat?.store?.messageIdWithUnreadDelimiter;
  if (
    messageIdWithUnreadDelimiter &&
    // props.message.isExternal &&
    !props.message.isOwn &&
    props.message.id.equals(messageIdWithUnreadDelimiter)
  ) {
    return (
      <div id="unread-messages-delimiter" className="unread-messages-title">
        {t('chat_user_chat_unread_messages_delimiter_title')}
      </div>
    );
  }

  return null;
});

export default UnreadMessagesDelimiter;
