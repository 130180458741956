import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';

import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate';
import Paths from '../../routes/Paths';
import useStore from '../../stores/useStore';
import {decodeInviteCode} from '../../utils/formatUrl';

export const useRegistrationInviteLink = (code: string) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {
    userStore,
    notifications,
    modals,
    activeWorkspace: {invites},
  } = useStore();
  const inviteID = decodeInviteCode(code);

  const workspaceNavigate = useWorkspaceNavigate();

  const notFitLinkNotificationWasOpenRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!userStore.isInit) {
      return;
    }

    const init = async () => {
      await invites.loadRegistrationInvite(inviteID);

      const shouldRegister = !!invites.registrationInvite?.registrationRequired;
      const inviteByEmail = !!invites.registrationInvite?.email;

      const isCurrentUser =
        !invites.registrationInvite?.email || invites.registrationInvite.email === userStore.user?.email;

      if ((userStore.isLoggedIn && isCurrentUser) || (!userStore.isLoggedIn && !!invites.registrationInvite)) {
        if (invites.registrationInvite?.expired || invites.registrationInvite?.used) {
          navigate(Paths.ExpiredInvite);
          return;
        }
      }

      if (userStore.isLoggedIn) {
        if (isCurrentUser) {
          navigate(Paths.AcceptInvite);
          return;
        } else if (inviteByEmail && !notFitLinkNotificationWasOpenRef.current) {
          notFitLinkNotificationWasOpenRef.current = true;
          const id = notifications.warning(
            t('registration_invite_link_not_fit_current_user_notification_title'),
            t('registration_invite_link_not_fit_current_user_notification', {code}),
            {autoHideDuration: null},
            [
              {
                title: t('registration_invite_link_not_fit_current_user_notification_action_logout'),
                variant: 'contained',
                onClick: async () => {
                  const link = invites.registrationInvite?.inviteUrl;
                  await userStore.logout();
                  window.open(link, '_self');
                  notifications.closeSnackbar(id);
                },
              },
            ],
          );
        }
        workspaceNavigate(Paths.PersonalInfo, {replace: true});
      } else {
        if (!shouldRegister && inviteByEmail) {
          navigate(Paths.Login, {
            state: {
              code,
            },
          });
        }
      }
    };

    init();
  }, [
    code,
    userStore.isInit,
    userStore.isLoggedIn,
    notifications,
    inviteID,
    modals,
    invites,
    userStore,
    navigate,
    workspaceNavigate,
    t,
  ]);

  return invites.registrationInvite;
};

export default useRegistrationInviteLink;
