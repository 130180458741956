import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import DialogActions from 'o-ui/Dialog/DialogActions';

import {IMCWebWidgetConfig} from '../../../../api/proto';
import {WEB_WIDGET_FIRST_MESSAGES} from '../../../../constants';
import useAsyncLoading from '../../../../hooks/useAsyncLoading';
import useAttachment from '../../../../hooks/useAttachment';
import Channel from '../../../../stores/Channel';
import useStore from '../../../../stores/useStore';
import WebWidgetFirstMessage from '../WebWidgetCreation/WebWidgetFirstMessage';
import WebWidgetPreview from '../WebWidgetCreation/WebWidgetPreview';

interface WebWidgetWelcomeMessageSettingsProps {
  channel: Channel;
  onClose?: () => void;
}

export const WebWidgetWelcomeMessageSettings = observer(({channel, onClose}: WebWidgetWelcomeMessageSettingsProps) => {
  const webWidgetConfig = channel.webWidgetConfig;

  const {src: avatarSrc} = useAttachment(webWidgetConfig?.displayAvatar, channel.id);

  const {t} = useTranslation();
  const {notifications, channels} = useStore();
  const [displayWelcomeMessage, setDisplayWelcomeMessage] = React.useState<string>(
    webWidgetConfig?.displayWelcomeMessage || '',
  );

  const [handleSubmit, loading] = useAsyncLoading(async () => {
    const newWebWidgetConfig: IMCWebWidgetConfig = {
      ...webWidgetConfig?.raw,
      displayWelcomeMessage,
    };

    const {error, res} = await channels.updateChannel({
      channel,
      channelID: channel.id,
      shownFields: channel.shownFields,
      webWidgetConfig: newWebWidgetConfig,
    });

    if (error) {
      notifications.error(error.message);
    }

    if (res) {
      onClose?.();
    }
  });

  const handleChangeDisplayWelcomeMessage = (value: string) => {
    setDisplayWelcomeMessage(value);
  };

  const formChanged = React.useMemo<boolean>(() => {
    return webWidgetConfig?.displayWelcomeMessage != displayWelcomeMessage;
  }, [
    webWidgetConfig?.displayWelcomeMessage,
    displayWelcomeMessage,
  ]);

  return (
    <>
      <div className="d-flex flex-row w-860 webwidget-settings">
        <div className="webwidget-settings__left border-primary-right p-4">
          <WebWidgetFirstMessage
            value={displayWelcomeMessage}
            options={WEB_WIDGET_FIRST_MESSAGES}
            onChange={handleChangeDisplayWelcomeMessage}
          />
        </div>
        <div className="webwidget-settings__right p-4">
          <WebWidgetPreview
            color={webWidgetConfig?.displayColor || ''}
            companyName={webWidgetConfig?.displayCompanyName || ''}
            welcomeMessage={displayWelcomeMessage}
            avatarUrl={avatarSrc}
            chatButtonSize={webWidgetConfig?.chatButtonSize?.toString()}
            chatButtonPosition={{bottom: 8}}
          />
        </div>
      </div>

      <DialogActions>
        <Button variant="contained" color="secondary" size="large" onClick={onClose} disabled={loading}>
          {t('settings_web_widget_manage_form_cancel_btn')}
        </Button>
        <Button
          className="ml-auto"
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
          disabled={!formChanged || loading || channel.loading}
          loading={loading || channel.loading}
        >
          {t('settings_web_widget_manage_form_save_btn')}
        </Button>
      </DialogActions>
    </>
  );
});

export default WebWidgetWelcomeMessageSettings;
