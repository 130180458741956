import React from 'react';

import {useStore} from '../../../../stores/AppStore';
import {DOWNLOAD_CANCELED_ERROR_MESSAGE} from '../../../../stores/Attachment/AttachmentStore';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';

interface MessageAttachmentInfo {
  src?: string;
  mimeType?: string;
  width?: number;
  height?: number;
  duration?: number;
  error?: string;
}

const RELOAD_ON_ERROR_DELAY = 3000; //ms

export default function useMessageAttachment(attachment?: MessageAttachment | null, inView: boolean = true): MessageAttachmentInfo {
  const {uploader} = useStore();

  const width = attachment?.width?.toNumber();
  const height = attachment?.height?.toNumber();
  const duration = attachment?.duration?.toNumber();

  const [src, setSrc] = React.useState<string | undefined>();
  const [mimeType, setMimeType] = React.useState<string | undefined>();
  const [error, setError] = React.useState<string | undefined>();

  const loadingRef = React.useRef<boolean>(false);
  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

  const message = attachment?.message;
  const chatStore = message?.chat.store;

  React.useEffect(() => {
    async function getAttachment() {
      if (
        !attachment?.id?.length ||
        // src ||
        (error && error !== DOWNLOAD_CANCELED_ERROR_MESSAGE) ||
        loadingRef.current ||
        !inView
      ) {
        return;
      }

      if (uploader.hasInProgress(attachment?.id)) {
        const dataFile = uploader.getFile(attachment?.id);
        setSrc(dataFile?.objectUrl);
        setMimeType(dataFile?.mimeType);
        console.debug(`%c----->useMessageAttachment FileInProgress: ${dataFile?.objectUrl}`, 'color: red');
        return;
      }

      loadingRef.current = true;
      const res = await chatStore?.getAttachment(attachment.id, attachment.name);
      loadingRef.current = false;

      if (res?.error) {
        intervalRef.current && clearInterval(intervalRef.current);

        setError(res?.error?.message);
        setSrc(undefined);

        intervalRef.current = setInterval(getAttachment, RELOAD_ON_ERROR_DELAY);
      } else if (res?.res?.src) {
        intervalRef.current = null;
        setSrc(res?.res?.src);
        setMimeType(res?.res?.mimeType);
        setError(undefined);
      }
    }

    getAttachment();

    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, [
    attachment,
    uploader,
    chatStore,
    error,
    src,
    loadingRef,
    inView,
  ]);

  return {
    width,
    height,
    duration,
    src,
    mimeType,
    error,
  };
}
