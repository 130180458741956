import React from 'react';

import {useOrigin100vh} from '../../hooks/useOrigin100vh';
import {useStore} from '../../stores/AppStore';
import {getStyleProperty} from '../../utils/styleProperty';

export function use100vh() {
  const originHeight = useOrigin100vh();
  const {appVersionUpdater} = useStore();

  const [height, setHeight] = React.useState<number | null>(null);

  const updateAppBarHeight = parseInt(getStyleProperty('--update-app-top-bar-height'), 10);

  React.useEffect(() => {
    if (originHeight) {
      setHeight(appVersionUpdater.hasUpdate ? originHeight - updateAppBarHeight : originHeight);
    }
  }, [
    originHeight,
    appVersionUpdater.hasUpdate,
    updateAppBarHeight,
  ]);

  return height || originHeight;
}

export default use100vh;