import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {animated} from 'react-spring';
import useResizeObserver from 'use-resize-observer';

import Button from 'o-ui/Button';
import {entities} from '../../../../api/proto';
import Channel from '../../../../stores/Channel';
import ChannelCreationStepType from '../ChannelCreationStepType';
import TelegramCreationModalHeader from '../TelegramCreationForm/TelegramCreationModalHeader';
import TelegramBotCreationResult from './TelegramBotCreationResult';
import TelegramBotDefinitionForm from './TelegramBotDefinitionForm';
import useOpacityTransition from '../../../../hooks/useOpacityTransition';

interface TelegramBotCreationFormProps {
  title?: string;
  subTitle?: string;
  modalHeader?: boolean;
  onChangeStep?: (step: ChannelCreationStepType) => void;
  onClose?: () => void;
  type: entities.OzekonChannelType;
  onChangeType?: (type: entities.OzekonChannelType) => void;
}

export const TelegramBotCreationForm = observer((props: TelegramBotCreationFormProps) => {
  const {t} = useTranslation();

  const {ref: innerRef, height} = useResizeObserver<HTMLDivElement>();

  const [newChannel, setNewChannel] = React.useState<Channel | null | undefined>(null);

  const [stepNumber, setStepPhoneNumber] = React.useState<number>(ChannelCreationStepType.CHANNEL_INFO);

  const openPage = (newPage: number) => {
    setStepPhoneNumber(newPage);
    props.onChangeStep?.(newPage);
  };

  const transitions = useOpacityTransition(stepNumber);

  const handleChangeDefinitionForm = () => {
    setNewChannel(null);
  };

  const handleChangeType = (type: entities.OzekonChannelType) => {
    setNewChannel(null);
    props.onChangeType?.(type);
  };

  const handleSubmitDefinitionForm = (channel?: Channel | null) => {
    if (channel) {
      setNewChannel(channel);
    }
    openPage(ChannelCreationStepType.SUCCESS);
  };

  const renderCreationFormStep = (step: ChannelCreationStepType) => {
    switch (step) {
      case ChannelCreationStepType.CHANNEL_INFO:
        return (
          <>
            {props.modalHeader ? <TelegramCreationModalHeader onClose={props.onClose} /> : null}

            <TelegramBotDefinitionForm
              title={props.title}
              subTitle={props.subTitle}
              type={props.type}
              newChannel={newChannel}
              onChangeForm={handleChangeDefinitionForm}
              onChangeType={props.onChangeType ? handleChangeType : undefined}
              onSubmit={handleSubmitDefinitionForm}
            />
          </>
        );
      case ChannelCreationStepType.SUCCESS:
        return (
          <>
            {props.modalHeader ? <TelegramCreationModalHeader onClose={props.onClose} /> : null}

            <TelegramBotCreationResult
              title={props.title}
              subTitle={props.subTitle}
              newChannel={newChannel}
            />

            {props.onClose ? (
              <div className="channel-dialog-form__footer">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className="channel-dialog-form__cancel-button"
                  onClick={props.onClose}
                >
                  {t('settings_channel_creation_form_close')}
                </Button>
              </div>
            ) : null}
          </>
        );
    }

    return null;
  };

  return (
    <div
      style={{width: 442}}
      className={cn('channel-dialog-form', `page-${stepNumber}`, {
        'with-header': props.modalHeader,
        'with-title': props.title,
      })}
    >
      <div
        style={{height: height && height > 40 ? height : undefined}}
        className="channel-dialog-form__body"
      >
        {transitions((styles, step) => (
          <animated.div ref={innerRef} style={styles} className="animated-form">
            {renderCreationFormStep(step)}
          </animated.div>
        ))}
      </div>
    </div>
  );
});

export default TelegramBotCreationForm;
