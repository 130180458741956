import {computed, makeObservable} from 'mobx';

import {MCMethodJoinWidgetChat, MCMethodLeaveWidgetChat, api} from '../../api/proto';
import {APILayer} from '../APILayer';
import TimerStore from '../TimerStore';
import WorkspaceMember from '../Workspaces/WorkspaceMember';
import Chat from './Chat';

const NEXT_REQUEST_DELAY = 2;

export class ChatAssignment extends APILayer {
  constructor(public chat: Chat) {
    super(chat.channel.app);

    makeObservable(this);
  }

  private nextRequestTimer_ = new TimerStore();

  @computed get requestLoading(): boolean {
    return this.loading || this.nextRequestTimer_.inProgress;
  }

  @computed get enabled(): boolean {
    return this.chat.channel.assignmentEnabled;
  }

  @computed get youAreJoined(): boolean {
    return !!this.chat.joinedOperators?.some((userID) => this.app.userStore.currentMember?.userId?.equals(userID));
  }

  @computed get joinedOperators(): WorkspaceMember[] {
    const operators: WorkspaceMember[] = [];

    this.chat.joinedOperators?.forEach((userId) => {
      const operator = this.app.activeWorkspace.findMemberByUserId(userId);
      if (operator) {
        operators.push(operator);
      }
    });

    return operators;
  }

  @computed get otherJoinedOperators(): WorkspaceMember[] {
    const operators: WorkspaceMember[] = [];

    this.chat.joinedOperators?.forEach((userID) => {
      if (this.app.userStore.currentMember?.userId?.notEquals(userID)) {
        const operator = this.app.activeWorkspace.findMemberByUserId(userID);
        if (operator) {
          operators.push(operator);
        }
      }
    });

    return operators;
  }

  @computed get resolvedBy(): WorkspaceMember | null {
    return this.chat.resolved ? this.app.activeWorkspace.findMemberByUserId(this.chat.statusSetBy) : null;
  }

  @computed get openBy(): WorkspaceMember | null {
    return this.chat.open ? this.app.activeWorkspace.findMemberByUserId(this.chat.statusSetBy) : null;
  }

  @computed get inProgressBy(): WorkspaceMember | null {
    return this.chat.inProgress ? this.app.activeWorkspace.findMemberByUserId(this.chat.statusSetBy) : null;
  }

  joinChat = async () => {
    if (this.loading) {
      return;
    }

    this.nextRequestTimer_.run(NEXT_REQUEST_DELAY);

    const {error, res} = await this.request<api.ChannelsResponse>(
      {
        channelsRequest: {
          workspaceId: this.chat.channel.workspace.id,
          action: {
            joinWidgetChat: new MCMethodJoinWidgetChat({
              channelID: this.chat.channel.id,
              chatID: this.chat.id,
            }),
          },
        },
      },
      'channelsResponse',
    );

    return {error, res: res};
  };

  leaveChat = async (resolve: boolean = false) => {
    if (this.loading) {
      return;
    }

    this.nextRequestTimer_.run(NEXT_REQUEST_DELAY);

    const {error, res} = await this.request<api.ChannelsResponse>(
      {
        channelsRequest: {
          workspaceId: this.chat.channel.workspace.id,
          action: {
            leaveWidgetChat: new MCMethodLeaveWidgetChat({
              channelID: this.chat.channel.id,
              chatID: this.chat.id,
              resolve,
            }),
          },
        },
      },
      'channelsResponse',
    );

    return {error, res: res};
  };

  resolveChat = () => {
    return this.leaveChat(true);
  };

  // @action processUpdateAssignedOperators = (e: com.chatman.IAssignOperatorToChatStatusChangedEvent) => {
  //   if (!e.operatorId) {
  //     return;
  //   }

  //   if (e.isAssigned) {
  //     this.chat.addAssignedOperator(e.operatorId);
  //   } else {
  //     this.chat.removeAssignedOperator(e.operatorId);
  //   }
  // };
}

export default ChatAssignment;
