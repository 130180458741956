import {canCopyImagesToClipboard, copyImageToClipboard} from 'copy-image-clipboard';
import copy from 'copy-to-clipboard';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import {useStore} from '../stores/AppStore';
import {ContextMenuItem} from '../stores/ContextMenuStore';
import {ModalType} from '../stores/ModalType';
// import {formatUrlToMessage} from '../utils/formatUrl';
// import {ContextMenuType} from '../stores/ContextMenuType';
// import {getRandomTagColor} from '../stores/MessageTagsStore';
// import {isChannelAllowToForwardMessage} from '../stores/Channel';
import useMessageAttachment from '../pages/Chat/UserChat/MessageContent/useMessageAttachment';
import {canDownloadFileByFilePicker, downloadFile, downloadFileBySaveFilePicker} from '../utils/downloadFile';
import {formatUrlToMessage} from '../utils/formatUrl';

import React from 'react';
import {ReactComponent as SnippetLine} from '../assets/image-icons/snippetLine.svg';
// import {ReactComponent as TagLine} from '../assets/image-icons/tagLine.svg';

interface MenuMessageProps {
  menu: ContextMenuItem;
}

export const MenuMessage: React.FC<MenuMessageProps> = observer((props) => {
  const {t} = useTranslation();
  const app = useStore();

  const {message, attachment, textSelection} = props.menu.data;

  const {src: attachmentSrc, mimeType} = useMessageAttachment(attachment);

  const handleReplyClick = () => {
    if (message) {
      message.chat.store?.setReplyMessage(message);
      props.menu.close();
    }
  };

  // const handleEditClick = () => {
  //   if (message) {
  //     message.chat.store?.setEditingMessage(message);
  //     props.menu.close();
  //   }
  // };

  const handleCopyTextClick = () => {
    copy(message?.text || '', {
      format: 'text/plain',
    });
    app.notifications.action(t('service_message_text_copied'));
    props.menu.close();
  };

  const handleCopySelectedTextClick = () => {
    copy(textSelection?.text || '', {
      format: 'text/plain',
    });
    app.notifications.action(t('service_message_selected_text_copied'));
    props.menu.close();
  };

  const handleCopyLinkClick = () => {
    copy(
      formatUrlToMessage(message?.chat.channel.workspace.id, message?.chat.channelID, message?.chat.id, message?.id),
    );

    app.notifications.action(t('service_message_link_copied'));
    props.menu.close();
  };

  // const handleTagClick = (e: React.MouseEvent) => {
  //   props.menu.close();

  //   if (message) {
  //     app.contextMenu.open({clientX: e.clientX, clientY: e.clientY}, ContextMenuType.MESSAGE_TAGS, {
  //       message: message,
  //       tag: {
  //         color: getRandomTagColor(),
  //       },
  //     });
  //   }
  // };

  const handleSaveToSnippetsClick = () => {
    props.menu.close();

    if (message) {
      app.modals.open(ModalType.CREATE_SNIPPET, {
        message,
      });
    }
  };

  // const handlePinClick = () => {
  //   console.debug('handlePinClick not implemented yet', message);
  //   // if (message) {
  //   //   app.modals.open(ModalType.PIN_MESSAGE, {message, chat: message.chat});
  //   //   props.menu.close();
  //   // }
  // };

  // const handleUnPinClick = () => {
  //   console.debug('handleUnPinClick not implemented yet', message);
  //   // app.modals.open(ModalType.UNPIN_MESSAGE, {message});
  //   // props.menu.close();
  // };

  const handleDeleteClick = () => {
    if (message) {
      app.modals.open(ModalType.DELETE_CHAT_MESSAGE, {message});
      props.menu.close();
    }
  };

  // const handleForwardClick = () => {
  //   console.debug('handleForwardClick not implemented yet', message);
  //   // if (message) {
  //   //   app.modals.open(ModalType.FORWARD, {chat: message.chat, messages: [albumMessage || message]});
  //   //   props.menu.close();
  //   // }
  // };

  // const handleSelectClick = () => {
  //   if (message) {
  //     message.chat.store?.selector?.addSelectedMessage(message);
  //     props.menu.close();
  //   }
  // };

  // const handleClearSelectionClick = () => {
  //   if (message) {
  //     message.chat.store?.selector?.deleteSelectedMessage(message);
  //     props.menu.close();
  //   }
  // };

  const handleCopyImageClick = () => {
    copyImageToClipboard(attachmentSrc || '')
      .then(() => {
        app.notifications.action(t('service_message_image_copied'));
        props.menu.close();
      })
      .catch((e) => {
        app.notifications.error(t('service_message_image_copied_error'));
        console.debug('Error: ', e.message);
        props.menu.close();
      });
  };

  const canDownloadByFilePicker = canDownloadFileByFilePicker();

  const handleDownloadFile = async () => {
    if (attachment && attachmentSrc) {
      if (canDownloadByFilePicker) {
        try {
          await downloadFileBySaveFilePicker({url: attachmentSrc, fileName: attachment.name, mimeType: mimeType});
        } catch {
          app.notifications.error(t('service_message_save_file_error'));
        }
      } else {
        downloadFile({url: attachmentSrc, fileName: attachment.name});
      }

      props.menu.close();
    }
  };

  if (!message) {
    return null;
  }

  return (
    <div className="dropdown-menu">
      {message.chat.permissions.canSendMessage ? (
        <>
          <button type="button" className="dropdown-item d-flex text-capitalize" onClick={handleReplyClick}>
            {t('ctx_menu_message_reply')}
          </button>
          <hr />
        </>
      ) : null}

      {textSelection?.text ? (
        <button type="button" className="dropdown-item text-capitalize" onClick={handleCopySelectedTextClick}>
          {t('ctx_menu_message_copy_selected_text')}
        </button>
      ) : null}

      {!textSelection?.text && message.text ? (
        <button type="button" className="dropdown-item d-flex text-capitalize" onClick={handleCopyTextClick}>
          {t('ctx_menu_message_copy_text')}
        </button>
      ) : null}

      <button type="button" className="dropdown-item d-flex text-capitalize" onClick={handleCopyLinkClick}>
        {t('ctx_menu_message_copy_message_link')}
      </button>

      {attachmentSrc && canCopyImagesToClipboard() ? (
        <button type="button" className="dropdown-item d-flex text-capitalize" onClick={handleCopyImageClick}>
          {t('ctx_menu_message_copy_media')}
        </button>
      ) : null}

      {attachmentSrc ? (
        <button type="button" className="dropdown-item d-flex text-capitalize" onClick={handleDownloadFile}>
          {canDownloadByFilePicker ? t('ctx_menu_message_save_as') : t('ctx_menu_message_download')}
        </button>
      ) : null}

      {/* {message.editingIsAvailable ? (
        <button type="button" className="dropdown-item d-flex" onClick={handleEditClick}>
          {t('ctx_menu_message_edit')}
        </button>
      ) : null} */}

      {/* {message.chat?.source === com.chatman.Source.TELEGRAM && (
        <>
          {message.pinned ? (
            <button type="button" className="dropdown-item d-flex" onClick={handleUnPinClick}>
              {t('ctx_menu_message_unpin')}
            </button>
          ) : (
            <button type="button" className="dropdown-item d-flex" onClick={handlePinClick}>
              {t('ctx_menu_message_pin')}
            </button>
          )}
        </>
      )} */}

      {/* {!message.chat.store?.replyMessage && isChannelAllowToForwardMessage(message.chat.source) ? (
        <>
          <button type="button" className="dropdown-item d-flex" onClick={handleForwardClick}>
            {t('ctx_menu_message_forward')}
          </button>

          {message.chat.store?.selector?.findSelectedMessage(message) ? (
            <button type="button" className="dropdown-item d-flex" onClick={handleClearSelectionClick}>
              {t('ctx_menu_message_clear_selection')}
            </button>
          ) : (
            <button type="button" className="dropdown-item d-flex" onClick={handleSelectClick}>
              {t('ctx_menu_message_select')}
            </button>
          )}
        </>
      ) : null} */}

      {message.chat.permissions.canDeleteMessage ? (
        <>
          <hr />
          <button type="button" className="dropdown-item d-flex" onClick={handleDeleteClick}>
            {t('ctx_menu_message_delete')}
          </button>
        </>
      ) : null}

      <hr />

      {/* <button type="button" className="dropdown-item d-flex d-none" onClick={handleTagClick}>
        <TagLine className="mr-3" />
        {t('ctx_menu_message_add_tag')}
      </button> */}

      <button type="button" className="dropdown-item d-flex d-none" onClick={handleSaveToSnippetsClick}>
        <SnippetLine className="mr-3" />
        {t('ctx_menu_message_save_to_snippets')}
      </button>
    </div>
  );
});

export default MenuMessage;
