import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import {ComponentSize} from 'o-ui';

import useAsyncLoading from '../../../../hooks/useAsyncLoading';
import useLoadingWrapper from '../../../../hooks/useLoadingWrapper';
import useMobileMode from '../../../../hooks/useMobileMode';
import useStore from '../../../../stores/useStore';
import Channel from '../../../../stores/Channel';
import ModalType from '../../../../stores/ModalType';
import WorkspaceMember from '../../../../stores/Workspaces/WorkspaceMember';
import {formatPhoneNumber} from '../../../../utils/getPhoneMask';
import TeamAccess from '../TeamAccess';
import ChannelDefaultForm, {ChannelDefaultFormRef} from './ChannelDefaultForm';
import WebWidgetManageForm from './WebWidgetManageForm';


interface IProps {
  channel: Channel;
  className?: string;
  bodyClassName?: string;
  onClose?: () => void;
}

export const ManagingChannelForm = observer((props: IProps) => {
  const isMobile = useMobileMode();
  const {channel} = props;

  const {activeWorkspace, channels, modals, notifications} = useStore();

  const [loadingWrapper, loading, setLoading] = useLoadingWrapper();

  const {t} = useTranslation();
  const formRef = React.useRef<ChannelDefaultFormRef>(null);
  const [formIsChanged, setFormIsChanged] = React.useState<boolean>(false);

  const handleBlockAccount = () => {
    console.debug(`TODO: not implemented handler "handleBlockAccount"`);
  };

  const [deleteChannel, deleteChannelLoading] = useAsyncLoading(async () => {
    const {error, res} = await channels.dropChannel(channel.id);

    if (error) {
      notifications.error(error.message);
    }

    if (res) {
      notifications.success(t('notification_channel_deleted', {phone: formatPhoneNumber(channel.phone)}));
      props.onClose?.();
    }
  });

  const handleDeleteChannel = () => {
    modals.open(ModalType.CHANNEL_DELETING, {
      channel,
      callbacks: {
        submit: deleteChannel,
      },
    });
  };

  const [teamAccessChanged, setTeamAccessChanged] = React.useState<boolean>(false);
  const editedMembersRef = React.useRef<WorkspaceMember[]>([]);

  const handleChangeConfigs = React.useCallback((changed: boolean, member: WorkspaceMember) => {
    changed && setTeamAccessChanged(changed);
    if (changed && !editedMembersRef.current.some((m) => m.id && member.id?.equals(m.id))) {
      editedMembersRef.current.push(member);
    }
  }, []);

  const submitInfoUpdate = loadingWrapper(async () => {
    if (editedMembersRef.current.length) {
      for (const member of editedMembersRef.current) {
        const {error} = await member.saveConfig();
        if (error) {
          notifications.error(error.message);
        }
      }
      await activeWorkspace.loadMembers();
    }

    formRef.current?.submitForm?.();
  });

  const handleBeforeSubmitForm = () => {
    setLoading(true);
  };

  const handleSubmitForm = () => {
    setLoading(false);
    props.onClose?.();
  };

  const handleChangeForm = () => {
    setFormIsChanged(true);
  };

  const buttonSize: ComponentSize = isMobile ? 'medium' : 'large';

  return (
    <div className={cn('channel-settings', props.className)}>
      <div className={cn('channel-settings__wrap', props.bodyClassName)}>
        <div className="channel-settings__paper paper p-6 h-100">
          <div className="channel-settings__left-side custom-scroll-y">
            {channel.isWebWidget ? (
              <WebWidgetManageForm
                ref={formRef}
                channel={channel}
                onBeforeSubmit={handleBeforeSubmitForm}
                onSubmit={handleSubmitForm}
                onChange={handleChangeForm}
              />
            ) : (
              <ChannelDefaultForm
                ref={formRef}
                channel={channel}
                onBeforeSubmit={handleBeforeSubmitForm}
                onSubmit={handleSubmitForm}
                onChange={handleChangeForm}
              />
            )}
          </div>

          <div className="channel-settings__right-side">
            <TeamAccess
              channel={channel}
              onChange={handleChangeConfigs}
            />
          </div>
        </div>
      </div>

      <div className="channel-settings__footer managing-channels">
        <div className="left">
          <Button
            variant="contained"
            color="primary"
            size={buttonSize}
            className="mr-5"
            onClick={submitInfoUpdate}
            disabled={!formIsChanged && !teamAccessChanged || deleteChannelLoading}
            loading={loading}
          >
            {t('settings_channel_manage_form_save_btn')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size={buttonSize}
            className="mr-5"
            onClick={props.onClose}
            disabled={loading || deleteChannelLoading}
          >
            {t('settings_channel_manage_form_cancel_btn')}
          </Button>
        </div>

        <div className="right">
          <Button
            variant="contained"
            color="error"
            size={buttonSize}
            className="text-capitalize mr-5 ml-auto"
            onClick={handleDeleteChannel}
            disabled={loading}
            loading={deleteChannelLoading}
          >
            {t('settings_channel_manage_form_delete_channel_btn')}
          </Button>

          <Button
            variant="contained"
            color="errorSecondary"
            size={buttonSize}
            className="text-capitalize"
            onClick={handleBlockAccount}
            disabled
          >
            {/* {channel.state === com.chatman.ChannelState.STOPPED && !channel.restarting
              ? t('Unblock channel')
              : t('Block channel')} */}
            {t('settings_channel_manage_form_block_channel_btn')}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default ManagingChannelForm;
