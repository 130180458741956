import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useInView} from 'react-intersection-observer';
import ReactPlayer from 'react-player';

import useStore from '../../../../stores/useStore';
import calcImageSize from '../../../../utils/image/calcImageSize';
import VideoInlineProgress from './VideoInlineProgress';


interface IProps {
  className?: string;
  src?: string | null;
  duration?: number;
  fixedSize?: boolean;
  width?: number | null;
  height?: number | null;
  maxWidth?: number;
  maxHeight?: number;
  onClick?: () => void;
  onInViewChange?: (inView: boolean, entry: IntersectionObserverEntry) => void;
  isScrolling?: boolean;
  autoPlay?: boolean;
  playButton?: boolean;
  onContextMenu?: React.MouseEventHandler;
  style?: React.CSSProperties;
}

export const VideoInlinePlayer: React.FC<IProps> = observer((props) => {
  const {modals, page} = useStore();
  const [src, setSrc] = React.useState<string | undefined | null>(undefined);
  const [srcLoaded, setSrcLoaded] = React.useState<boolean>(false);
  const [prevSrc, setPrevSrc] = React.useState<string | undefined | null>(undefined);
  const [playedSeconds, setPlayedSeconds] = React.useState<number>(0);

  const {width, height} = props;

  let calcWidth = 0;
  let calcHeight = 0;

  if (props.fixedSize) {
    [calcWidth, calcHeight] = calcImageSize(
      width || 0,
      height || 0,
      props.maxWidth,
      props.maxHeight,
    );
  }

  const {ref: inViewRef, inView} = useInView({
    threshold: 0,
    onChange: props.onInViewChange,
  });

  React.useEffect(() => {
    if (!props.isScrolling && inView) {
      setSrcLoaded(false);
      setSrc(props.src);
      if (!prevSrc) {
        setPrevSrc(props.src);
      }
    }
  }, [props.src, props.isScrolling, inView, prevSrc]);


  const handleProgress = React.useCallback(
    (state) => setPlayedSeconds(state.playedSeconds),
    [],
  );

  const size = props.fixedSize ? {width: calcWidth, height: calcHeight} : {};

  return (
    <div
      ref={inViewRef}
      className={cn(
        'message-video-inline-player',
        props.className,
      )}
      style={{...size, ...props.style}}
      onClick={props.onClick}
      onContextMenu={props.onContextMenu}
    >
      {prevSrc && !srcLoaded ? (
        <div
          className={cn('message-img__img-prev')}
          style={{backgroundImage: `url(${prevSrc})`}}
        />
      ) : null}
      <ReactPlayer
        className="message-video-inline-player w-100 h-100"
        url={src || ''}
        width="100%"
        height="100%"
        controls={false}
        pip={false}
        playsinline={true}
        playing={props.autoPlay && !!src && inView && page.inFocus && !props.isScrolling && !modals.isOpen}
        loop={true}
        muted={true}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
              disablePictureInPicture: true,
            },
          },
        }}
        onProgress={handleProgress}
        progressInterval={100}
      />
      <VideoInlineProgress
        playedSeconds={playedSeconds}
        duration={props.duration}
      />
    </div>
  );
});

export default VideoInlinePlayer;
