import {RawMessage} from '../IRawMessagesStore';
import textEntitiesBuilder from './textEntitiesBuilder';

export function messagesProcessor(rawMessages: RawMessage[]): RawMessage[] {
  rawMessages.forEach((rawMessage) => {
    textEntitiesBuilder(rawMessage);
  });

  return rawMessages;
}

export default messagesProcessor;