import React from 'react';

import {useStore} from '../../stores/AppStore';
import ModalType from '../../stores/ModalType';
import {ModalData} from '../../stores/ModalsStore';
import {FileData} from '../../utils/file/fileReaders';
import FileInput from './FileInput';
import useFileChangeHendler from './useFileChangeHendler';

interface AvatarPickerProps {
  className?: string;
  onChange?: (files: FileData[]) => void;
}

export interface AvatarPickerRef {
  open?: () => void;
}

const avatarPickerOpts = {
  types: [
    {
      description: 'Images',
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg', '.jpg'],
      },
    },
  ],
  excludeAcceptAllOption: true,
  multiple: false,
};

export const AvatarPicker = React.forwardRef<AvatarPickerRef, AvatarPickerProps>((props, ref) => {
  const {modals} = useStore();
  const refFileInput = React.useRef<HTMLInputElement>(null);

  React.useImperativeHandle(ref, () => ({
    open: () => {
      if (typeof window.showOpenFilePicker === 'function') {
        getTheFile();
      } else {
        refFileInput.current?.click();
      }
    },
  }));

  async function getTheFile() {
    try {
      const [fileHandle] = await window.showOpenFilePicker(avatarPickerOpts);
      const fileData = await fileHandle.getFile();
      handleFileChange([fileData]);
    } catch (e) {
      // console.debug('File picking cancelled', e);
    }
  }

  const handleSend = (dataFiles: FileData[]) => {
    modals.open(
      ModalType.UPLOAD_AVATAR,
      {
        dataFiles,
        callbacks: {
          submit: handleSubmitFile,
        },
      },
      true,
    );
  };

  const handleSubmitFile = (data?: ModalData) => {
    if (data?.dataFiles) {
      props.onChange?.(data?.dataFiles);
    }
  };

  const handleFileChange = useFileChangeHendler(handleSend);

  return (
    <FileInput
      className={props.className}
      ref={refFileInput}
      onChange={handleFileChange}
      accept="image/png, image/jpeg, image/jpg"
    />
  );
});

export default AvatarPicker;
