import Long from 'long';
import {createBrowserHistory} from 'history';

import {base64ToUint8Array} from '../utils/arrayUtils';
import {IRawChat} from '../stores/RawChatsStore/RawChat';


export interface IBrowserHistoryChat {
  id?: string | null;
  channelID?: string | null;
  name?: string | null;
  type?: string | null;
}

export interface IBrowserHistory {
  from?: {
    pathname?: string;
  };
  selectedChannelId?: string | null;
  chatId?: string | null;
  chat?: IBrowserHistoryChat | null;
  avatarId?: string;
}

export const browserHistory = createBrowserHistory();

export default browserHistory;

export function convertChatFromHistory(chat: IBrowserHistoryChat): IRawChat {
  return {
    id: chat.id ? Long.fromString(chat.id) : Long.ZERO,
    channelID: chat.channelID ? base64ToUint8Array(decodeURIComponent(chat.channelID)) : null,
    name: chat.name,
    type: chat.type ? Number(chat.type) : 0,
  };
}
