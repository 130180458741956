import { api } from '../../api/proto';
import {t} from '../../i18n';

export default function getCancelCurrentTariffError(status?: api.WorkspaceCancelCurrentTariffResponse.Status | null): string | null {
  switch (status) {
    case api.WorkspaceCancelCurrentTariffResponse.Status.WCT_INTERNAL_ERROR:
      return t('Internal error');
    case api.WorkspaceCancelCurrentTariffResponse.Status.WCT_NO_ACTIVE_TARIFF:
      return t('No active tariff');
  }

  return null;
}