import {bytesToMegaBytes} from './fileSize';
import {
  SUPPORTED_AUDIO_CONTENT_TYPES,
  SUPPORTED_IMAGE_CONTENT_TYPES,
  SUPPORTED_VIDEO_CONTENT_TYPES,
  MAX_IMAGE_UPLOAD_MB,
  MAX_IMAGE_UPLOAD_WIDTH_HEIGHT_SUM,
  MAX_IMAGE_UPLOAD_ASPECT_RATIO,
  MIN_IMAGE_UPLOAD_ASPECT_RATIO,
} from '../../config';
// eslint-disable-next-line  @typescript-eslint/no-var-requires
const gifDurations = require('gif-me-duration');
import {fileTypeFromBlob} from './fileTypeBrowser';
import getFileExtension from './getFileExtension';
import {IMCAttachment, IMCMessage} from '../../api/proto';
import {randomUint8Array} from '../arrayUtils';
// import { randomUint8Array } from '../arrayUtils';
// import {randomUint8Array} from '../arrayUtils';

export const validateImageFileLimitsToUpload = (dataFile: FileData): boolean => {
  if (!dataFile?.image || !dataFile?.file) {
    return false;
  }

  const fileSizeMb = bytesToMegaBytes(dataFile.file.size || 0);
  const imageRatio = dataFile.image.height / dataFile.image.width;

  if (fileSizeMb > MAX_IMAGE_UPLOAD_MB) {
    return false;
  }

  if (imageRatio > MAX_IMAGE_UPLOAD_ASPECT_RATIO || imageRatio < MIN_IMAGE_UPLOAD_ASPECT_RATIO) {
    return false;
  }

  if (dataFile.image.width + dataFile.image.height > MAX_IMAGE_UPLOAD_WIDTH_HEIGHT_SUM) {
    return false;
  }

  return true;
};

export function isImageFile(mimeType?: string | null, fileName?: string | null): boolean {
  if (mimeType) {
    return mimeType?.startsWith('image');
  }
  return !!fileName?.match(/.(jpg|jpeg|png|gif)$/i);
}

export function isGifFile(mimeType?: string | null, fileName?: string | null): boolean {
  if (mimeType) {
    return mimeType?.startsWith('image/gif');
  }
  return !!fileName?.match(/.(gif)$/i);
}

export function isVideoFile(mimeType?: string | null): boolean {
  return !!mimeType?.startsWith('video');
}

export function getAudioDuration(src: string): Promise<number> {
  return new Promise(function (resolve) {
    const audio = new Audio();
    audio.addEventListener(
      'loadedmetadata',
      function () {
        resolve(audio.duration);
      },
      false,
    );
    audio.src = src;
  });
}

export interface FileData {
  fileId: Uint8Array;
  file: File;
  fileName: string;
  objectUrl: string;
  mimeType?: string;
  animation?: HTMLImageElement;
  image?: HTMLImageElement;
  video?: HTMLVideoElement;
  audio?: HTMLAudioElement;
  width?: number;
  height?: number;
  duration?: number;
  message?: IMCMessage;
  attachment?: IMCAttachment;
  canceled?: boolean;
}

const readFileInfo = async (file: File) => {
  let fileType = file.type;
  let fileName = file.name;
  let fileExt = getFileExtension(fileName);

  if (!fileType) {
    const fileInfo = await readFileType(file);
    console.debug('fileInfo', fileInfo);
    fileType = fileInfo?.mime || '';
    fileName = fileExt ? fileName : fileName + '.' + fileInfo?.ext;
    fileExt = fileExt || fileInfo?.ext || '';
  }

  return {
    fileType,
    fileName,
    fileExt,
  };
};

const fileReader = async (files: File[], callback: (dataFiles: FileData[]) => void) => {
  const dataFiles: FileData[] = [];

  for (const file of files) {
    const fileId = randomUint8Array();
    const {fileType, fileName} = await readFileInfo(file);
    const objectUrl = URL.createObjectURL(file);

    const baseFileInfo: FileData = {
      fileId,
      file,
      fileName,
      objectUrl,
      mimeType: fileType,
    };

    if (SUPPORTED_IMAGE_CONTENT_TYPES.has(fileType)) {
      const image = new Image();
      const reader = new FileReader();
      const objectUrl = URL.createObjectURL(file);
      image.onload = () => {
        reader.readAsArrayBuffer(file);
      };

      let duration;
      if (fileType.endsWith('gif')) {
        try {
          const result: {duration?: number; url?: string}[] | null = await gifDurations(objectUrl);
          if (result?.length && result[0].duration) {
            duration = result[0].duration / 1000;
          }
        } catch (e) {
          console.warn(e);
        }
      }

      reader.addEventListener('load', () => {
        dataFiles.push({
          ...baseFileInfo,
          animation: fileType.endsWith('gif') ? image : undefined,
          image: fileType.endsWith('gif') ? undefined : image,
          width: image.width,
          height: image.height,
          duration,
        });

        if (files.length === dataFiles.length) {
          callback(dataFiles);
        }
      });

      image.src = objectUrl;
    } else if (SUPPORTED_VIDEO_CONTENT_TYPES.has(fileType)) {
      const video = document.createElement('video');
      const objectUrl = URL.createObjectURL(file);
      video.addEventListener(
        'loadedmetadata',
        function () {
          dataFiles.push({
            ...baseFileInfo,
            video,
            width: this.videoWidth,
            height: this.videoHeight,
            duration: this.duration || 0,
          });

          if (files.length === dataFiles.length) {
            callback(dataFiles);
          }
        },
        false,
      );
      video.src = objectUrl;
    } else if (SUPPORTED_AUDIO_CONTENT_TYPES.has(fileType)) {
      const audio = new Audio();
      const objectUrl = URL.createObjectURL(file);
      audio.addEventListener(
        'loadedmetadata',
        function () {
          dataFiles.push({
            ...baseFileInfo,
            audio,
            duration: this.duration || 0,
          });

          if (files.length === dataFiles.length) {
            callback(dataFiles);
          }
        },
        false,
      );
      audio.src = objectUrl;
    } else {
      dataFiles.push(baseFileInfo);

      if (files.length === dataFiles.length) {
        callback(dataFiles);
      }
    }
  }
};

export const dataFileReader = (files: File[], callback: (dataFiles: FileData[]) => void) => {
  fileReader(files, callback);
};

export default dataFileReader;

export const readFileType = async (file: File) => {
  const fileBlob = await fileToBlob(file);
  const fileType = await fileTypeFromBlob(fileBlob);
  return fileType;
};

export function fileToBlob(file: File): Promise<Blob> {
  return new Promise((resolve, reject) => {
    file
      .arrayBuffer()
      .then((arrayBuffer) => {
        const blob = new Blob([new Uint8Array(arrayBuffer)], {type: file.type});
        resolve(blob);
      })
      .catch(() => {
        reject(file);
      });
  });
}

// export function fileToUint8Array(file: File): Promise<Uint8Array> {
//   return new Promise((resolve, reject) => {
//     file
//       .arrayBuffer()
//       .then((arrayBuffer) => {
//         const uint8Array = new Uint8Array(arrayBuffer);
//         resolve(uint8Array);
//       })
//       .catch(() => {
//         reject(file);
//       });
//   });
// }
