import React from 'react';

import SelectChatContext, {SelectChatContextValue} from './SelectChatContext';
import {IRawChat} from '../../stores/RawChatsStore/RawChat';


interface SelectChatProviderProps {
  onSelect?: (chats: IRawChat[]) => void;
}

export default function SelectChatProvider({
  children,
  onSelect,
}: React.PropsWithChildren<SelectChatProviderProps>) {
  const selectedChatsRef = React.useRef<IRawChat[]>([]);
  const [selectedChats, setSelectedChats] = React.useState<IRawChat[]>([]);

  const toggleChat = React.useCallback((chat: IRawChat) => {
    const idx = selectedChatsRef.current.findIndex(({id}) => chat.id && id?.equals(chat.id));
    if (idx >= 0) {
      selectedChatsRef.current.splice(idx, 1);
    } else {
      selectedChatsRef.current.push(chat);
    }

    selectedChatsRef.current = selectedChatsRef.current.slice();
    setSelectedChats([...selectedChatsRef.current]);
    onSelect?.(selectedChatsRef.current);
  }, [
    selectedChatsRef,
    onSelect,
  ]);

  const selectChats = React.useCallback((chats: IRawChat[]) => {
    selectedChatsRef.current = chats;
    setSelectedChats(chats);
    onSelect?.(selectedChatsRef.current);
  }, [
    selectedChatsRef,
    onSelect,
  ]);

  const contextValue = React.useMemo<SelectChatContextValue>(() => ({
    selectedChats,
    toggleChat,
    selectChats,
  }), [
    selectedChats,
    toggleChat,
    selectChats,
  ]);

  return (
    <SelectChatContext.Provider value={contextValue}>
      {children}
    </SelectChatContext.Provider>
  );
}
