import React from 'react';

import {IMCTextEntities} from '../../../../api/proto';
import {uint8ArrayToString} from '../../../../utils/arrayUtils';
import {formatPhoneNumberUrl} from '../../../../utils/formatUrl';


interface TextPhoneNumberEntityProps {
  children?: React.ReactNode;
  entity: IMCTextEntities;
  textUrl?: string;
}

export const TextPhoneNumberEntity: React.FC<TextPhoneNumberEntityProps> = ({
  children,
  entity,
  textUrl,
}) => {
  const phoneNumber = uint8ArrayToString(entity.payload);
  return (
    <a href={formatPhoneNumberUrl(phoneNumber || textUrl)} rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default TextPhoneNumberEntity;
