import React from 'react';

import Dialog, {DialogProps} from 'o-ui/Dialog';
import CustomizedDialogTitle from 'o-ui/Dialog/CustomizedDialogTitle';
import useMediaQuery from '../../hooks/useMediaQuery';
import {ModalItem} from '../../stores/ModalsStore';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg';

export interface ModalDialogProps extends Omit<DialogProps, 'title' | 'open'> {
  modal: ModalItem;
  title?: string | JSX.Element | null;
  withoutCloseButton?: boolean;
  open?: boolean;
  onBack?(): void;
  onClose?(): void;
}

export interface ModalDialogRef {
  close: () => void;
}

export const ModalDialog = React.forwardRef<ModalDialogRef, ModalDialogProps>(
  ({modal, title, withoutCloseButton, open, onBack, ...props}, ref) => {
    const [opened, setOpened] = React.useState<boolean>(true);
    const fullscreen = useMediaQuery('(max-width: 900px)');

    const handleAnimationEnd = () => {
      if (!opened) {
        props.onClose?.();
        modal.close();
      }
    };

    const handleClose = () => {
      setOpened(false);
    };

    React.useImperativeHandle(ref, () => ({
      close: handleClose,
    }));

    return (
      <Dialog
        open={open || opened}
        onClose={handleClose}
        onDialogTransitionEnd={handleAnimationEnd}
        fullScreen={fullscreen}
        {...props}
      >
        {title ? (
          <CustomizedDialogTitle onBack={onBack} onClose={withoutCloseButton ? undefined : handleClose}>
            {title}
          </CustomizedDialogTitle>
        ) : null}
        {props.children}
      </Dialog>
    );
  },
);

export default ModalDialog;
