import {observer} from 'mobx-react';
import React from 'react';

import CircularProgress from 'o-ui/CircularProgress';
import useStore from '../../../../stores/useStore';
import BlockingInfo from './BlockingInfo';

import {ReactComponent as StopSignImg} from '../../../../assets/images/stopSign.svg';
import {ReactComponent as StopSignDarkImg} from '../../../../assets/images/stopSignDark.svg';

export const BlockedChatArea: React.FC<React.PropsWithChildren> = observer(() => {
  const {chatsView, lightThemeChosen} = useStore();

  return (
    <div className="blocked-chat-area border-primary-left d-flex flex-column justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-center align-items-center">
        {chatsView.openingChatID ? (
          <CircularProgress
            size={72}
            style={{
              color: 'var(--brand-primary)',
              zIndex: 3,
            }}
          />
        ) : (
          <>
            {lightThemeChosen ? (
              <StopSignImg className="mb-6" />
            ) : (
              <StopSignDarkImg className="mb-6" />
            )}
            <BlockingInfo />
          </>
        )}
      </div>
    </div>
  );
});

export default BlockedChatArea;
