import {action, makeObservable, observable} from 'mobx';

import {FileData} from '../utils/file/fileReaders';
import {AppStore} from '../stores/AppStore';
import {compressImage} from '../utils/file/compressImages';
import {IMCAttachment} from '../api/proto';
import {NetworkResponse} from '../api/network';
import {uint8ArrayToUuid} from '../utils/arrayUtils';
import {fileUploader} from '../api/fileUploader';


interface FileUploadProgress {
  abortController: AbortController;
  progress: number; // Float between 0 and 1.
  progressPercent: number;
  dataFile?: FileData | null;
  error?: string;
  isCanceled?: boolean;
  cancelable?: boolean;
}

interface FilesUploadResponse {
  dataFiles: FileData[];
  attachments: IMCAttachment[];
  failedFiles: [string, FileData][];
}

export class UploadClient {

  constructor(private app: AppStore) {
    makeObservable(this);
  }

  @observable public progressMap: {[fileId: string]: FileUploadProgress} = {};

  private attachmentIDToString_ = (attachmentID?: Uint8Array | null): string => {
    if (!attachmentID) {
      return '';
    }
    return uint8ArrayToUuid(attachmentID);
  };

  public getFileState = (attachmentID?: Uint8Array | null): FileUploadProgress | null => {
    const attachmentUuid = this.attachmentIDToString_(attachmentID);
    if (this.progressMap[attachmentUuid]) {
      return this.progressMap[attachmentUuid] || null;
    }
    return null;
  };

  public hasInProgress = (attachmentID?: Uint8Array | null): boolean => {
    const attachmentUuid = this.attachmentIDToString_(attachmentID);
    if (!attachmentID) {
      return false;
    }

    return !!this.progressMap[attachmentUuid];
  };

  public getFile = (attachmentID?: Uint8Array | null): FileData | null => {
    return this.getFileState(attachmentID)?.dataFile || null;
  };

  @action private addToProgress_ = (dataFile: FileData, progress: number) => {
    const attachmentUuid = this.attachmentIDToString_(dataFile.fileId);
    const progressInstance = this.progressMap[attachmentUuid];

    this.progressMap = {
      ...this.progressMap,
      [attachmentUuid]: {
        ...progressInstance,
        progress: progress,
        progressPercent: Math.floor((progress || 0) * 100),
        dataFile,
        abortController: new AbortController(),
        cancelable: true,
      },
    };
  };

  @action private updateFileProgress_ = (attachmentID: Uint8Array, progress?: number | null) => {
    const progressInstance = this.getFileState(attachmentID);

    if (progressInstance && progressInstance.progress !== progress) {
      console.debug(`%cUpload progress: ${this.attachmentIDToString_(attachmentID)} ${progress}`, 'color: gray');
      progressInstance.progress = progress || 0;
      progressInstance.progressPercent = Math.floor((progress || 0) * 100);
    }
  };

  @action private removeFromProgress_ = (attachmentID?: Uint8Array | null) => {
    const attachmentUuid = this.attachmentIDToString_(attachmentID);
    console.debug(`%cremove From Upload Progress: ${attachmentUuid}`, 'color: red');
    if (this.progressMap[attachmentUuid]) {
      delete this.progressMap[attachmentUuid];
    }
  };

  @action public cancelUpload = (attachmentID?: Uint8Array | null) => {
    const attachmentUuid = this.attachmentIDToString_(attachmentID);
    console.debug(`%c cancel upload: ${attachmentUuid}`, 'color: orange');

    const progressInstance = this.progressMap[attachmentUuid];

    if (progressInstance) {
      if (progressInstance.dataFile) {
        progressInstance.dataFile.canceled = true;
      }
      progressInstance.isCanceled = true;
      progressInstance.abortController.abort();

      this.removeFromProgress_(attachmentID);
    }
  };

  @action protected processUploadResult_ = (channelID: Uint8Array, dataFile: FileData, attachmentID: Uint8Array) => {
    dataFile.fileId = attachmentID;
    if (dataFile.attachment?.source?.reference) {
      dataFile.attachment.source.reference = attachmentID;
    }

    this.addToProgress_(dataFile, 1);

    this.app.activeWorkspace.attachmentStore.putToCache(channelID, dataFile, attachmentID);
  };

  protected isCanceled_ = (attachmentID?: Uint8Array | null): boolean => {
    const progressInstance = this.getFileState(attachmentID);

    return !progressInstance || !!progressInstance.isCanceled;
   };

  private uploadFile_ = async (dataFile: FileData): Promise<NetworkResponse<{attachmentID?: Uint8Array}>> => {
    const progressInstance = this.getFileState(dataFile.fileId);

    this.addToProgress_(dataFile, 0);

    const {error, res} = await fileUploader.upload({
      dataFile,
      signal: progressInstance?.abortController.signal,
      onUploadProgress: ({progress}) => {
        this.updateFileProgress_(dataFile.fileId, progress);
      },
    });

    if (res?.attachmentID) {
      this.updateFileProgress_(dataFile.fileId, 1);
    }

    return {error, res};
  };

  public uploadFiles = async (channelID: Uint8Array, dataFiles: FileData[], compressImages?: boolean): Promise<FilesUploadResponse> => {
    const filesCount: number = dataFiles.length;
    const attachmentIDs: Uint8Array[] = [];
    const uploadedDataFiles: FileData[] = [];
    const failedFiles: [string, FileData][] = [];

    if (filesCount) {
      for (let i = 0; i < filesCount; i++) {
        let dataFile = dataFiles[i];
        if (this.isCanceled_(dataFile.fileId)) {
          continue;
        }

        if (compressImages && dataFile.image) {
          dataFile = await compressImage(dataFile);
        }

        if (this.isCanceled_(dataFile.fileId)) {
          continue;
        }

        const {res, error} = await this.uploadFile_(dataFile);

        if (res?.attachmentID && this.getFileState(dataFile.fileId)) {
          this.processUploadResult_(channelID, dataFile, res?.attachmentID);

          attachmentIDs.push(res?.attachmentID);

          uploadedDataFiles.push(dataFile);

        } else if (res?.attachmentID && this.isCanceled_(dataFile.fileId)) {
          console.debug(`%c Upload canceled: ${this.attachmentIDToString_(dataFile.fileId)}`, 'color: red');
        } else {
          console.debug(`%c Upload error: ${error?.message} (${this.attachmentIDToString_(dataFile.fileId)})`, 'color: red', error);
          failedFiles.push([error?.message || '', dataFile]);
        }
      }

    }

    const attachments: IMCAttachment[] = [];
    uploadedDataFiles.forEach((dataFile) => {
      if (!dataFile.canceled && dataFile.attachment) {
        attachments.push(dataFile.attachment);
      }
    });

    const res = {
      failedFiles,
      dataFiles: uploadedDataFiles.filter((dataFile) => !this.isCanceled_(dataFile.fileId)),
      attachments,
    };

    attachmentIDs.forEach((attachmentID) => this.removeFromProgress_(attachmentID));

    return res;
  };

  public addToProgress = (dataFiles: FileData[]): void => {
    const filesCount: number = dataFiles.length;

    for (let i = 0; i < filesCount; i++) {
      const dataFile = dataFiles[i];

      this.addToProgress_(dataFile, 0);
    }
  };
}

export default UploadClient;
