import React from 'react';

import {IconButton} from 'o-ui/Button';
import {niceBytes} from '../../utils/format';
import FileIcon from '../../components/FileIcon';


interface UploadFileInfoProps {
  name?: string | null;
  type?: string | null;
  size?: number | null;
  url?: string | null;
  objectUrl?: string;
  onDelete?(objectUrl?: string): void;
}

export const UploadFileInfo: React.FC<UploadFileInfoProps> = ({
  name,
  type,
  size,
  url,
  objectUrl,
  onDelete,
}) => {

  return (
    <div className="file-info">
      <FileIcon
        className="file-info__icon"
        type={type}
        small={true}
        url={url}
        fileName={name}
      />

      <div className="file-info__body">
        <div className="text-left text-truncate">
          <div className="file-info__name">{name}</div>
          <p className="file-info__size body2-regular color-body-tertiary font-size-12 mb-0">
            {niceBytes(size)}
          </p>
        </div>
        {onDelete ? (
          <IconButton
            className="ml-auto"
            onClick={(e) => {
              e.preventDefault();
              onDelete?.(objectUrl);
            }}
          >
            <span className="file-info__delete-btn tg-icon-delete-bin-line icon-size-18" />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
};

export default UploadFileInfo;
