import React from 'react';
import cn from 'classnames';

export interface TopAudioPlayerProgressBarProps {
  played: number;
  playedSeconds: number;
  onProgressClick?: (fraction: number) => void;
}

export default function TopAudioPlayerProgressBar(props: TopAudioPlayerProgressBarProps) {
  const progressLineRef = React.useRef<HTMLDivElement>(null);

  const [withTransition, setWithTransition] = React.useState<boolean>(true);

  const timeout = React.useRef<null | NodeJS.Timeout>(null);

  const handleClick = (e: React.MouseEvent) => {
    if (progressLineRef.current) {
      setWithTransition(false);
      const percentWidth = progressLineRef.current.clientWidth / 100;
      const rect = progressLineRef.current.getBoundingClientRect();
      const clickDistance = e.clientX - rect.left;
      const fraction = Math.round(clickDistance / percentWidth) / 100;
      props.onProgressClick?.(fraction);

      timeout.current = setTimeout(() => {
        setWithTransition(true);
      }, 150);
    }
  };

  React.useEffect(() => {
    return () => {
      timeout.current && clearTimeout(timeout.current);
    }
  }, [timeout]);

  return (
    <div className="audio-player-top__progress-bar">
      <div className="audio-player-top__progress-line" ref={progressLineRef} onClick={handleClick}>
        <div
          className={cn('audio-player-top__progress', {withTransition})}
          style={{width: (props.played * 100).toFixed(2) + '%'}}
        ></div>
      </div>
    </div>
  );
}