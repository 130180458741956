import {InstagramAuthorizationResponse} from '../../../api/proto';
import i18n from '../../../i18n';

const {t} = i18n;

export const getInstagramAuthError = (type?: InstagramAuthorizationResponse.Status | null): string => {
  switch (type) {
    case InstagramAuthorizationResponse.Status.INVALID_CODE:
      return t('api_instagram_authorization_invalid_code');
    case InstagramAuthorizationResponse.Status.INSTAGRAM_ACCESS_NOT_GRANTED:
      return t('api_instagram_authorization_access_not_granted');
    case InstagramAuthorizationResponse.Status.INSTAGRAM_NO_CONNECTED_ACCOUNTS:
      return t('api_instagram_authorization_no_connected_accounts');
    case InstagramAuthorizationResponse.Status.INTERNAL_ERROR:
      return t('api_instagram_authorization_internal_error');
  }

  if (type && InstagramAuthorizationResponse.Status[type]) {
    return InstagramAuthorizationResponse.Status[type]?.toLowerCase().replace(/_/g, ' ');
  }

  return '';
};

export default getInstagramAuthError;