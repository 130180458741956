import React from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import AuthInfo from './AuthInfo';
import ApprovePrivateRegistrationForm from './ApprovePrivateRegistrationForm';
import Div100vh from '../../components/Div100vh';

type RouteParams = {
  code?: string;
};

const ApprovePrivateRegistrationPage = () => {
  const {code = ''} = useParams<RouteParams>();
  const {t} = useTranslation();

  return (
    <Div100vh className="auth-page">
      <div className="auth-page__left-side">
        <AuthInfo />
      </div>

      <div className="auth-page__right-side">
        <div className="h1-bold color-body-primary mt-auto mb-6">{t('approve_private_registration_title')}</div>

        <ApprovePrivateRegistrationForm className="mb-auto" code={code} />
      </div>
    </Div100vh>
  );
};

export default ApprovePrivateRegistrationPage;
