import {observer} from 'mobx-react';
import React from 'react';
import {animated} from 'react-spring';

import useIsFirstRender from '../../hooks/useIsFirstRender';
import SettingsTab from '../../stores/LayOutStore/SettingsTab';
import useStore from '../../stores/useStore';
import SettingsHead, {SettingsHeadState} from './SettingsHead';
import SettingsTabItem from './SettingsTabItem';
import useSlideTransition from '../../hooks/useSlideTransition';

interface IProps {
  selectedTab: SettingsTab;
  onToggleMenu?: () => void;
}

export interface ISettingsTabWrapperRef {
  reset?: () => void;
}

export const SettingsTabWrapper = observer(
  React.forwardRef<ISettingsTabWrapperRef, IProps>(({selectedTab, onToggleMenu}: IProps, ref) => {
    const {animationsEnabled} = useStore();

    const isFirst = useIsFirstRender();

    const [prevPageIndex, setPrevPageIndex] = React.useState<SettingsTab>(selectedTab);
    const [pageIndex, setPageIndex] = React.useState<SettingsTab>(selectedTab);

    const transitions = useSlideTransition(pageIndex, prevPageIndex);

    React.useEffect(() => {
      if (selectedTab !== pageIndex) {
        setPrevPageIndex(pageIndex);
        setPageIndex(selectedTab);
      }
    }, [
      selectedTab,
      pageIndex,
    ]);

    const [headState, setHeadState] = React.useState<SettingsHeadState>({});

    const handleChangeState = React.useCallback((state: SettingsHeadState) => {
      setHeadState(state);
    }, []);

    const tabRef = React.useRef<ISettingsTabWrapperRef>(null);

    React.useImperativeHandle(ref, () => ({
      reset: () => {
        if (tabRef.current?.reset) {
          tabRef.current.reset();
        }
      },
    }));

    return (
      <>
        <SettingsHead
          className="settings-page__content-head"
          onToggleMenu={onToggleMenu}
          {...headState}
        />
        <div className="settings-page__content">
          {!isFirst && animationsEnabled && prevPageIndex !== pageIndex
            ? transitions((styles, item) => (
              <animated.div
                key={item}
                style={styles}
                className="animated-tab"
              >
                <SettingsTabItem
                  tabRef={tabRef}
                  tab={item}
                  onChangeState={handleChangeState}
                />
              </animated.div>
            )) : (
              <SettingsTabItem
                tabRef={tabRef}
                tab={pageIndex}
                onChangeState={handleChangeState}
              />
            )}
        </div>
      </>
    );
  }),
);

export default SettingsTabWrapper;
