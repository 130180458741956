import {ErrorEvent} from '../../../api/network';
import {IMCCreateChannelResponse, MCCreateChannelResponse} from '../../../api/proto';
import i18n from '../../../i18n';
import getInstagramAuthError from '../platforms/getInstagramAuthError';

export const getCreateChannelError = (res?: IMCCreateChannelResponse | null): ErrorEvent | null => {
  if (res?.telegramBotAuthFlow && res?.result === MCCreateChannelResponse.Result.R_INTERNAL_SERVER_ERROR) {
    return new ErrorEvent({message: i18n.t('api_create_channel_telegram_bot_authorization_server_error')});
  } else if (res?.telegramBotAuthFlow && !res?.telegramBotAuthFlow?.success) {
    return new ErrorEvent({message: i18n.t('api_create_channel_telegram_bot_authorization_failed')});
  }

  if (res?.instagramAuthFlow?.status) {
    return new ErrorEvent({
      message: getInstagramAuthError(res?.instagramAuthFlow?.status),
    });
  }


  if (res?.result === MCCreateChannelResponse.Result.R_INTERNAL_SERVER_ERROR) {
    return new ErrorEvent({message: i18n.t('api_create_channel_internal_server_error')});
  }

  return null;
};

export default getCreateChannelError;