import Long from 'long';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import CustomizedDialogTitle from 'o-ui/Dialog/CustomizedDialogTitle';
import DialogActions from 'o-ui/Dialog/DialogActions';
import DialogContent from 'o-ui/Dialog/DialogContent';

import SelectMember from '../components/Select/SelectMember';
import {ModalItem} from '../stores/ModalsStore';
import WorkspaceMember from '../stores/Workspaces/WorkspaceMember';
import useStore from '../stores/useStore';
import {diffLongArrays} from '../utils/arrayUtils';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

import warningIcon from '../assets/images/warning-square-with-triangle.svg';

interface IProps {
  modal: ModalItem;
}

export const ModalTariffPayWithMembersBlock = observer((props: IProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);
  const {tariff} = props.modal.data;
  const {activeWorkspace, anal} = useStore();
  const {billing} = activeWorkspace;

  const [selectedMembersNumber, setSelectedMembersNumber] = React.useState<number>(0);
  const selectedMembersRef = React.useRef<WorkspaceMember[]>([]);

  const searchRef = React.useRef<string>('');
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [foundMembers, setFoundMembers] = React.useState<WorkspaceMember[]>([]);

  const members = activeWorkspace.activeMembers;

  const resetMembersSearch = () => {
    searchRef.current = '';
    setFoundMembers([]);
    setSearchQuery('');
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
    resetMembersSearch();
  };

  const handleSelectClick = async () => {
    const selectedMemberIDs: Long[] = selectedMembersRef.current.map(m => m.id || Long.ZERO);
    const membersIDs: Long[] = members.map(m => m.id || Long.ZERO);

    const blockMemberIDs: Long[] = diffLongArrays(membersIDs, selectedMemberIDs);

    tariff && anal.tariffUpgrade(tariff);

    await billing.payForTariff(tariff?.id, blockMemberIDs);

    handleCloseClick();
  };

  const handleSelect = React.useCallback((members_: WorkspaceMember[]) => {
    setSelectedMembersNumber(members_.length);
    selectedMembersRef.current = members_;
  }, []);

  const handleSearchMember = React.useCallback((query: string) => {
    const searchMembers = function (q: string) {
      if (searchRef.current === q) {
        setFoundMembers(
          members.filter((m) => m.email?.includes(q) || m.fullName?.includes(q))
        );
      }
    };

    if (query) {
      searchRef.current = query;
      setSearchQuery(query);
      searchMembers(query);
    } else {
      resetMembersSearch();
    }
  }, [
    members,
  ]);

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      maxWidth="sm"
      fullWidth={true}
      onClose={resetMembersSearch}
    >
      <CustomizedDialogTitle
        className="align-items-start border-0"
        onClose={handleCloseClick}
      >
        <div className="d-flex flex-column">
          <div className="h3-medium">{t('modal_tariff_pay_with_members_title')}</div>
          <div className="body1-regular mt-1">{t('You are about to downgrade your plan.')}</div>
          <div className="body1-regular mt-3">{t('Please select the users who will remain active in your workspace.')}</div>
        </div>
      </CustomizedDialogTitle>

      <div className="px-6">
        <div className="d-flex flex-row justify-center radius-12 bg-warning-tint p-5">
          <img className="mr-3" src={warningIcon} alt="" style={{width: 24}} />
          <div className="body1-regular">
            {t('Unselected users will be blocked.')}
          </div>
        </div>

        <div className="d-flex flex-row justify-center radius-12 border-body-separator p-5 mt-5">
          <div className="body1-regular">
            {t('modal_tariff_pay_with_members_selected_count', {
              selected: selectedMembersNumber,
              total: tariff?.includedUsers?.toNumber(),
            })}
          </div>
        </div>
      </div>

      <DialogContent
        className="px-0"
        style={{minHeight: 350, height: 350}}
      >
        <div className="py-6 w-100 h-100">
          <SelectMember
            members={searchQuery ? foundMembers : members}
            onSelect={handleSelect}
            onSearch={handleSearchMember}
            maxSelected={tariff?.includedUsers?.toNumber()}
            listClassName="px-5"
            inputClassName="mx-6"
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          className="flex-grow-1 mr-1"
          variant="contained"
          color="primary"
          size="large"
          onClick={handleCloseClick}
        >
          {t('modal_tariff_pay_with_members_cancel_btn', {name: billing.currentTariff?.name})}
        </Button>
        <Button
          className="flex-grow-1 ml-1"
          variant="contained"
          color="tertiary"
          size="large"
          onClick={handleSelectClick}
          loading={tariff?.id === billing.payForTariffInProgress}
        >
          {t('modal_tariff_pay_with_members_confirm_btn', {name: tariff?.name})}
        </Button>
      </DialogActions>
    </ModalDialog>
  );
});

export default ModalTariffPayWithMembersBlock;