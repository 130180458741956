import {action, makeAutoObservable, observable} from 'mobx';
import {entities} from '../api/proto';
import {AppStore} from './AppStore';

export default class FeaturesStore {
  constructor(public app: AppStore) {
    makeAutoObservable(this);
  }

  @observable public billing: boolean = false;
  @observable public telegramBot: boolean = false;
  @observable public instagram: boolean = false;

  public proccessFeatures = (features?: entities.IFeatures | null) => {
    this.billing = features?.billing || false;
    this.telegramBot = features?.telegramBot || false;
    this.instagram = features?.instagram || false;
  };

  @action public reset = () => {
    this.billing = false;
    this.telegramBot = false;
    this.instagram = false;
  };
}