import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';
import {useInView} from 'react-intersection-observer';

import LottiePlayer from '../../../../components/Lottie/LottiePlayer';
import {useStore} from '../../../../stores/AppStore';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import useMessageAttachment from './useMessageAttachment';

export interface StickerContentAnimatedProps {
  className?: string;
  attachment: MessageAttachment;
  isScrolling?: boolean;
}

export const StickerContentAnimated = observer((props: StickerContentAnimatedProps) => {
  const {modals, page} = useStore();
  const [url, setUrl] = React.useState<string | null>(null);
  const {ref, inView} = useInView({
    threshold: 0,
  });

  const {src} = useMessageAttachment(props.attachment, inView);

  React.useEffect(() => {
    if (src && !url && !props.isScrolling && inView) {
      setUrl(src);
    }
  }, [props.isScrolling, inView, url, src]);

  return (
    <div
      ref={ref}
      className={cn(
        'sticker-content-animated',
        props.className,
      )}
    >
      {url ? (
        <LottiePlayer
          url={url}
          play={!!url && inView && page.inFocus && !props.isScrolling && !modals.isOpen}
        />
      ) : null}
    </div>
  );
});

export default StickerContentAnimated;
