import React from 'react';
import {useTranslation} from 'react-i18next';
import WebWidgetSnippetSettings from '../pages/Settings/Channels/WebWidgetSettings/WebWidgetSnippetSettings';
import {ModalItem} from '../stores/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
}

export const ModalWebWidgetSnippetSettings: React.FC<IProps> = (props) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      title={t('modal_web_widget_snippets_title')}
      maxWidth="md"
      PaperProps={{
        className: 'webwidget-settings',
      }}
    >
      {props.modal.data.channel ? (
        <WebWidgetSnippetSettings channel={props.modal.data.channel} onClose={handleCloseClick} />
      ) : null}
    </ModalDialog>
  );
};

export default ModalWebWidgetSnippetSettings;
