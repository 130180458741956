import React from 'react';

import SelectMemberContext, {SelectMemberContextValue} from './SelectMemberContext';
import WorkspaceMember from '../../../stores/Workspaces/WorkspaceMember';


interface SelectMemberProviderProps {
  onSelect?: (members: WorkspaceMember[]) => void;
  maxSelected?: number | null;
}

export default function SelectMemberProvider({
  children,
  onSelect,
  maxSelected,
}: React.PropsWithChildren<SelectMemberProviderProps>) {
  const selectedMembersRef = React.useRef<WorkspaceMember[]>([]);
  const [selectedMembers, setSelectedMembers] = React.useState<WorkspaceMember[]>([]);

  const toggleMember = React.useCallback((member: WorkspaceMember) => {
    const idx = selectedMembersRef.current.findIndex(({id}) => member.id && id?.equals(member.id));
    if (idx >= 0) {
      selectedMembersRef.current.splice(idx, 1);
    } else if (maxSelected && selectedMembersRef.current.length >= maxSelected) {
      return;
    } else {
      selectedMembersRef.current.push(member);
    }

    selectedMembersRef.current = selectedMembersRef.current.slice();
    setSelectedMembers([...selectedMembersRef.current]);
    onSelect?.(selectedMembersRef.current);
  }, [
    selectedMembersRef,
    onSelect,
    maxSelected,
  ]);

  const selectMembers = React.useCallback((members: WorkspaceMember[]) => {
    if (maxSelected && members.length > maxSelected) {
      return;
    }

    selectedMembersRef.current = members;
    setSelectedMembers(members);
    onSelect?.(selectedMembersRef.current);
  }, [
    selectedMembersRef,
    onSelect,
    maxSelected,
  ]);

  const contextValue = React.useMemo<SelectMemberContextValue>(() => ({
    selectedMembers,
    toggleMember,
    selectMembers,
  }), [
    selectedMembers,
    toggleMember,
    selectMembers,
  ]);

  return (
    <SelectMemberContext.Provider value={contextValue}>
      {children}
    </SelectMemberContext.Provider>
  );
}