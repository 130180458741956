import React from 'react';

import {EntityWrapper, PlainTextEntityWrapper} from './EntityWrapper';
import getTextEntitiesNodes, {TextEntitiesTreeNode} from './getTextEntitiesNodes';
// import {TextSelection} from './TextSelection';


export const TextContentTree: React.FC<TextEntitiesTreeNode> = (props) => {
  const nodes = getTextEntitiesNodes(props.text, props.textEntities, props.startIndex, props.level);
  // console.debug(`%cTextContentTree: text=|${props.text}| startIndex=${props.startIndex} | level=${props.level}| `, 'color: blue', nodes, props.textEntities);
  // console.debug(nodes);
  const renderSubNodes = () => {
    return nodes.length ? (
      nodes.map((node, i) => (
        <TextContentTree
          key={i + node.startIndex}
          plainText={props.plainText}
          text={node.text}
          textEntity={node.textEntity}
          textEntities={node.textEntities}
          startIndex={node.startIndex}
          level={node.level + 1}
        />
      ))
    ) : (
      <React.Fragment key={props.startIndex}>{props.text}</React.Fragment>
    );
  };

  if (props.textEntity) {
    // console.debug('--> TextContentTree render textEntity', com.chatman.TextEntityType[props.textEntity.teType || 0]);
    return props.plainText ? (
      <PlainTextEntityWrapper key={props.startIndex} entity={props.textEntity} text={props.text}>
        {renderSubNodes()}
      </PlainTextEntityWrapper>
    ) : (
      <EntityWrapper key={props.startIndex} entity={props.textEntity} text={props.text}>
        {renderSubNodes()}
      </EntityWrapper>
    );
  }
  /*
  if (
    props.textSelection?.length &&
    props.textSelection?.offset !== undefined &&
    props.textSelection?.offset !== null &&
    props.text
  ) {
    // console.debug(nodes);
    return (
      <>
        <React.Fragment>{props.text.substr(0, props.textSelection.offset)}</React.Fragment>
        <TextSelection containerRef={props.containerRef}>
          {props.text.substr(props.textSelection.offset, props.textSelection.length)}
        </TextSelection>
        <React.Fragment>
          {props.text.substr(props.textSelection.offset + props.textSelection.length, props.text.length)}
        </React.Fragment>
      </>
    );
  }
  */
  if (nodes.length) {
    // console.debug('--> TextContentTree render renderSubNodes');
    return <React.Fragment key={props.startIndex}>{renderSubNodes()}</React.Fragment>;
  }

  // console.debug('--> TextContentTree render text', props.text);
  return <React.Fragment key={props.startIndex}>{props.text}</React.Fragment>;
};

export default TextContentTree;
