import {AppStore} from '../AppStore';
import User from '../User';
import ExternalAppInfo from './ExternalAppInfo';
import ExternalAppWindowState from './ExternalAppWindowState';
import ExternalUser from './ExternalUser';
import {ExternalApiAction, ExternalApiEvent, send, subscribe} from './externalApi';



export class ExternalApiCallHandler {
  constructor(private app: AppStore) {
    this.init();
  }

  protected isInit_: boolean = false;

  init = () => {
    if (this.isInit_) {
      return;
    }

    subscribe(ExternalApiAction.APP_INFO, this.handleAppInfo_);
    subscribe(ExternalApiAction.APP_WINDOW_STATE, this.handleAppWindowState_);
    subscribe(ExternalApiAction.SIGN_OUT, this.handleCallSignOut_);
    subscribe(ExternalApiAction.LOGGER, this.handleLogger_);
    subscribe(ExternalApiAction.APP_NEW_VERSION_DOWNLOADED, this.handleAppNewVersionDownloaded_);

    this.isInit_ = true;
  };

  protected handleAppInfo_ = (info: ExternalAppInfo) => {
    console.debug(`-->AppInfo:`, info);
    this.app.externalApp.processAppInfo(info);

    if (!window.externalAppConfig) {
      return;
    }
    try {
      window.externalAppConfig = info;
    } catch (e) {
      console.error(e);
    }
  };

  protected handleAppWindowState_ = (state: ExternalAppWindowState) => {
    console.debug(`-->AppWindowState:`, state);
    this.app.externalApp.processAppWindowState(state);
  };

  protected handleCallSignOut_ = () => {
    this.app.userStore.logout();
  };

  protected handleLogger_ = (...args) => {
    console.log(...args);
  };

  protected handleAppNewVersionDownloaded_ = (info: ExternalAppInfo) => {
    console.debug(`-->NewVersionDownloaded:`, info);
    this.app.appVersionUpdater.setNewDesktopVersion(info?.version);
  };

  public updateUnreadCounter = (unread: number) => {
    send(ExternalApiEvent.UPDATE_UNREAD_COUNTER, {unread});
  };

  public updateUser = (user?: User | null) => {
    const userData: ExternalUser | null = user ? {
      login: user?.email,
      name: user?.fullName,
    } : null;

    send(ExternalApiEvent.UPDATE_USER, {
      user: userData,
    });
  };

  public updateWebApp = (toVersion: string | null) => {
    send(ExternalApiEvent.UPDATE_WEB_APP, {
      version: this.app.version,
      toVersion,
    });
  };

  public checkNewAppVersion = () => {
    send(ExternalApiEvent.CHECK_DESKTOP_APP_NEW_VERSION);
  };

  public updateDesktopApp = () => {
    send(ExternalApiEvent.UPDATE_DESKTOP_APP);
  };

  public minimizeWindow = () => {
    send(ExternalApiEvent.WINDOW_MINIMIZE);
  };

  public restoreWindow = () => {
    send(ExternalApiEvent.WINDOW_RESTORE);
  };

  public maximizeWindow = () => {
    send(ExternalApiEvent.WINDOW_MAXIMIZE);
  };

  public unmaximizeWindow = () => {
    send(ExternalApiEvent.WINDOW_UNMAXIMIZE);
  };

  public toggleMaximizeWindow = () => {
    send(ExternalApiEvent.WINDOW_TOGGLE_MAXIMIZE);
  };

  public closeWindow = () => {
    send(ExternalApiEvent.WINDOW_CLOSE);
  };
}

export default ExternalApiCallHandler;
