import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';

import {CircularProgress} from 'o-ui';
import useMountedState from 'o-ui/hooks/useMountedState';
import useTimeout from 'o-ui/hooks/useTimeout';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import useAttachmentProgress from './useAttachmentProgress';


interface FileUploadingCircularProgressProps {
  className?: string;
  attachment: MessageAttachment;
  dark?: boolean;
  delay?: number;
  small?: boolean;
}

export const FileUploadingCircularProgress: React.FC<React.PropsWithChildren<FileUploadingCircularProgressProps>> = observer(({
  className,
  attachment,
  dark,
  delay = 400,
  small,
  children
}) => {
  const isMounted = useMountedState();
  const [visible, setVisible] = React.useState<boolean>(false);
  const {
    cancel,
    isInProgress,
    isDone,
    progress,
  } = useAttachmentProgress(attachment);

  useTimeout(() => {
    if (!isMounted()) {
      return;
    }
    setVisible(true);
  }, delay);

  const message = attachment.message;

  if (!visible || isDone || message.isDelivered && !isInProgress) {
    return <>{children}</>;
  }

  return (
    <div
      className={cn('uploading-circular-progress position-relative', className, {
        'uploading-circular-progress--small': small,
        'uploading-circular-progress--dark': dark,
        'cursor-pointer': !isDone,
      })}
      onClick={isDone ? undefined : cancel}
    >
      <CircularProgress
        size={small ? 24 : 36}
        value={100}
        variant="determinate"
        className="position-absolute-center"
        style={{
          color: 'var(--primary-400)',
          zIndex: 1,
        }}
      />
      <CircularProgress
        size={small ? 24 : 36}
        value={progress}
        variant="determinate"
        className="position-absolute-center"
        style={{
          color: '#ffffff',
          zIndex: 3,
        }}
      />
      <svg className="position-absolute-center" width="9.6px" height="9.6px" viewBox="0 0 9.6 9.6">
        <path
          fill="#FFFFFF"
          d="M8.5,9.5C8.2,9.5,8,9.4,7.8,9.2L0.3,1.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l7.5,7.5c0.4,0.4,0.4,1,0,1.4
	C9,9.4,8.8,9.5,8.5,9.5z"
        />
        <path
          fill="#FFFFFF"
          d="M1,9.6c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l7.6-7.6c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L1.7,9.3
	C1.5,9.5,1.3,9.6,1,9.6z"
        />
      </svg>
    </div>
  );
});

export default FileUploadingCircularProgress;
