import React from 'react';
import {observable, action, makeObservable} from 'mobx';
import {Howl} from 'howler';

import {AppStore} from './AppStore';
import {Paths} from '../routes/Paths';
import {closeSnackbar} from '../plugins/notistack';
import {OptionsObject, SnackbarKey} from '../plugins/notistack/types';
import {ButtonProps} from 'o-ui/Button';

export const soundNames: string[] = [
  'telegram-message',
  'bell-sound',
  'facebook-message',
  'instagram-message',
  'iphone-message-tone',
  'kuku-bird',
  'messenger-web',
  'slack-message',
  'sparrow-bird',
  'tin-long',
  'tone-ton-ton',
  'uff-tone',
  'viber-message',
  'viber-message-2',
  'whatsapp-message',
  'whatsapp-web',
  'wow',
  'bebc',
  'facebook-message-5',
  'tuk-tu',
];

export const DEFAULT_SOUND_NAME = soundNames[0];

const sounds: {[index: string]: any} = {};

soundNames.forEach((soundName: string) => (sounds[soundName] = require(`../assets/sounds/${soundName}.mp3`)));

export enum NotificationType {
  ALARM = 0,
}

export enum SnackbarType {
  Message,
  Action,
}

export interface INotificationItem {
  id: number;
  expiration: number;
  type: NotificationType;
  sound: string;
}

export class NotificationItem implements INotificationItem {
  id = 0;
  expiration = 0;
  type: NotificationType = NotificationType.ALARM;
  sound = '';

  constructor(props: INotificationItem) {
    Object.assign(this, props);
  }
}

export interface ISnackbar {
  id: string;
  message: string | React.ReactNode;
  subMessage?: string | React.ReactNode;
  options?: OptionsObject;
  actions?: ISnackbarAction[];
  hideCloseIcon?: boolean;
  type: SnackbarType;
  snackKey?: SnackbarKey;
}

export interface ISnackbarAction extends ButtonProps {
  title: string;
}

export class NotificationsStore {
  @observable list: NotificationItem[] = [];

  constructor(private app: AppStore) {
    makeObservable(this);
  }

  get isMuted() {
    return window.location.pathname === Paths.WidgetApiDocs;
  }

  @action delete = (id: number) => {
    this.list = this.list.filter((notice) => notice.id !== id);
  };

  @action add = (sound = '', expiration = 3000) => {
    const notice = new NotificationItem({
      id: new Date().getTime(),
      expiration,
      type: NotificationType.ALARM,
      sound,
    });

    this.list.push(notice);

    this.runExpirationTimer(notice);

    this.play(sound);
  };

  loadedSounds: {[name: string]: Howl} = {};

  @action play = (sound: string) => {
    if (this.isMuted) {
      return;
    }

    const soundName = soundNames.indexOf(sound) >= 0 ? sound : DEFAULT_SOUND_NAME;
    if (!this.loadedSounds[soundName]) {
      this.loadedSounds[soundName] = new Howl({
        src: sounds[soundName],
      });
    }
    this.loadedSounds[soundName].play();

    return this.loadedSounds[soundName];
  };

  runExpirationTimer = (notice: INotificationItem) => {
    if (notice.expiration) {
      setTimeout(() => {
        this.delete(notice.id);
      }, notice.expiration);
    }
  };

  @observable snackbars: ISnackbar[] = [];

  @action enqueueSnackbar = (
    message: string | React.ReactNode,
    subMessage?: string | React.ReactNode,
    options?: OptionsObject,
    actions?: ISnackbarAction[],
    hideCloseIcon?: boolean,

    type: SnackbarType = SnackbarType.Message,
  ): string => {
    const newSnack = {
      id: crypto.randomUUID(),
      message,
      options: {
        autoHideDuration: 2000,
        ...options,
      },
      actions,
      subMessage,
      type,
      hideCloseIcon,
    };

    this.snackbars = [...this.snackbars, newSnack];

    return newSnack.id;
  };

  @action clear = () => {
    this.snackbars = [];
  };

  @action closeSnackbar = (id?: string | null) => {
    const snackbar = this.snackbars.find((snack) => snack.id === id);
    if (snackbar?.snackKey) {
      closeSnackbar(snackbar?.snackKey);
    }
  };

  @action removeSnackbar = (id: string) => {
    this.snackbars = this.snackbars.filter((notification) => notification.id !== id);
  };

  success = (
    message: string | React.ReactNode,
    subMessage?: string | React.ReactNode,
    options?: OptionsObject,
    actions?: ISnackbarAction[],
    hideCloseIcon?: boolean,
  ) => {
    return this.enqueueSnackbar(message, subMessage, {variant: 'success', ...options}, actions, hideCloseIcon);
  };

  error = (
    message: string | React.ReactNode,
    subMessage?: string | React.ReactNode,
    options?: OptionsObject,
    actions?: ISnackbarAction[],
    hideCloseIcon?: boolean,
  ) => {
    return this.enqueueSnackbar(message, subMessage, {variant: 'error', ...options}, actions, hideCloseIcon);
  };

  warning = (
    message: string | React.ReactNode,
    subMessage?: string | React.ReactNode,
    options?: OptionsObject,
    actions?: ISnackbarAction[],
    hideCloseIcon?: boolean,
  ) => {
    return this.enqueueSnackbar(message, subMessage, {variant: 'warning', ...options}, actions, hideCloseIcon);
  };

  info = (
    message: string | React.ReactNode,
    subMessage?: string | React.ReactNode,
    options?: OptionsObject,
    actions?: ISnackbarAction[],
    hideCloseIcon?: boolean,
  ) => {
    return this.enqueueSnackbar(message, subMessage, {variant: 'info', ...options}, actions, hideCloseIcon);
  };

  default = (
    message: string | React.ReactNode,
    subMessage?: string | React.ReactNode,
    options?: OptionsObject,
    actions?: ISnackbarAction[],
    hideCloseIcon?: boolean,
  ) => {
    return this.enqueueSnackbar(message, subMessage, {variant: 'default', ...options}, actions, hideCloseIcon);
  };

  action = (message: string | React.ReactNode) => {
    return this.enqueueSnackbar(message, undefined, undefined, undefined, undefined, SnackbarType.Action);
  };
}

export default NotificationsStore;
