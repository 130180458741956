import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ContextMenuItem} from '../stores/ContextMenuStore';

interface IProps {
  menu: ContextMenuItem;
}

export const MenuWebWidgetSnippet: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  return (
    <div className="dropdown-menu">
      <button type="button" className="dropdown-item d-flex" onClick={() => props.menu.trigger('snippetEdit')}>
        <i className="dropdown-icon tg-icon-gallery-line" />
        {t('ctx_menu_web_widget_snippet_edit_btn')}
      </button>
      <button type="button" className="dropdown-item d-flex" onClick={() => props.menu.trigger('snippetDelete')}>
        <i className="dropdown-icon tg-icon-file-line" />
        {t('ctx_menu_web_widget_snippet_delete_btn')}
      </button>
    </div>
  );
});

export default MenuWebWidgetSnippet;
