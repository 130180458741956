import cn from 'classnames';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {animated, useTransition} from 'react-spring';
import * as Yup from 'yup';

import DialogActions from 'o-ui/Dialog/DialogActions';
import DialogContent from 'o-ui/Dialog/DialogContent';
import FormHelperText from 'o-ui/FormHelperText';
import InputAdornment from 'o-ui/Input/InputAdornment';
import InputLabel from 'o-ui/Input/InputLabel';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import PinInput from 'o-ui/Input/PinInput';
import VisibilitySwitcher from 'o-ui/Input/VisibilitySwitcher';
import {Link} from 'o-ui/Link';

import {getUserResponseStatusError} from '../api/getErrorByType';
import {entities} from '../api/proto';
import ButtonWithTimer from '../components/ButtonWithTimer';
import SnackMessage from '../components/SnackMessage';
import useMobileMode from '../hooks/useMobileMode';
import {useStore} from '../stores/AppStore';
import {ModalType} from '../stores/ModalType';
import {ModalItem} from '../stores/ModalsStore';
import {formatDuration} from '../utils/format';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
  emailVerificationMode?: boolean;
}

enum ChangeEmailSteps {
  CHANGE_EMAIL,
  VERIFICATION_EMAIL,
}

const CODE_LENGTH = 8;

export const ModalProfileChangeEmail: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);
  const [showCurrentPassword, setShowCurrentPassword] = React.useState<boolean>(false);
  const {notifications, userStore, modals} = useStore();

  const initialStep = props.emailVerificationMode ? ChangeEmailSteps.VERIFICATION_EMAIL : ChangeEmailSteps.CHANGE_EMAIL;

  const [currentStep, setCurrentStep] = React.useState(initialStep);
  const [prevStep, setPrevStep] = React.useState(initialStep);

  const [apiError, setApiError] = React.useState<string | null | undefined>('');

  const isMobile = useMobileMode();

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const switchToStep = (step: ChangeEmailSteps) => {
    setPrevStep(currentStep);
    setCurrentStep(step);
  };

  const formikNewEmail = useFormik({
    initialValues: {
      password: '',
      newEmail: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t('settings_personal_info_change_email_password_required')),
      newEmail: Yup.string()
        .email(t('settings_personal_info_change_email_validation'))
        .required(t('settings_personal_info_change_email_required')),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      setApiError('');
      const {error, res} = await userStore.changeEmail(values);
      if (error) {
        notifications.error(error.message);
        setApiError(error.message);
      }

      if (res) {
        const statusErrorMessage = getUserResponseStatusError('changeEmail', res.status);

        if (res.status === entities.ChangeEmailResult.CER_INVALID_PASSWORD && statusErrorMessage) {
          formikNewEmail.setErrors({password: statusErrorMessage});
          return;
        }

        if (res.status === entities.ChangeEmailResult.CER_EMAIL_USED && statusErrorMessage) {
          formikNewEmail.setErrors({newEmail: statusErrorMessage});
          return;
        }

        if (statusErrorMessage) {
          notifications.error(statusErrorMessage);
          setApiError(statusErrorMessage);
          return;
        }

        await userStore.resendConfirmationEmailCode();

        notifications.success(t('settings_personal_info_change_email_submit_success'));

        switchToStep(ChangeEmailSteps.VERIFICATION_EMAIL);

        userStore.initVerifyEmailNotification();
      }
    },
    onReset: () => {
      setApiError('');
    },
  });

  const handleSubmitNewEmailForm = (e) => {
    e.preventDefault();
    formikNewEmail.handleSubmit();
  };

  const handleResendConfirmationCode = async () => {
    const {res, error} = await userStore.resendConfirmationEmailCode();

    if (error) {
      notifications.error(error.message);
      setApiError(error.message);
    }

    if (res) {
      const statusErrorMessage = getUserResponseStatusError('resendConfirmEmail', res.status);
      if (statusErrorMessage) {
        notifications.error(statusErrorMessage);
        return;
      }
    }

    notifications.success(t('settings_personal_info_resend_verify_email_submit_success'));
  };

  const formikEmailVerification = useFormik({
    initialValues: {
      code: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      code: Yup.string()
        .required(t('settings_personal_info_change_email_code_required'))
        .min(CODE_LENGTH, t('settings_personal_info_change_email_code_validation', {length: CODE_LENGTH})),
    }),
    onSubmit: async ({code}) => {
      setApiError('');
      const {error, res} = await userStore.confirmEmailByVerificationCode(code);

      if (error) {
        notifications.error(error.message);
        setApiError(error.message);
      }

      if (res) {
        const statusErrorMessage = getUserResponseStatusError('confirmEmail', res.status);

        if (res.status === entities.ConfirmCredsResult.CS_INVALID_CODE && statusErrorMessage) {
          formikEmailVerification.setErrors({code: statusErrorMessage});
          return;
        }

        if (statusErrorMessage) {
          notifications.error(statusErrorMessage);
          setApiError(statusErrorMessage);
          return;
        }

        notifications.success(t('settings_personal_info_change_email_verify_submit_success'));
        handleCloseClick();

        if (userStore.emailVerificationSnackbarId) {
          notifications.closeSnackbar(userStore.emailVerificationSnackbarId);
        }
      }
    },
    onReset: () => {
      setApiError('');
    },
  });

  const handleChangeConfirmationCode = async (value: string) => {
    setApiError('');
    await formikEmailVerification.setFieldValue('code', value, false);
    if (value.length === CODE_LENGTH && userStore.confirmEmailByVerificationCodeTimer.stopped) {
      formikEmailVerification.submitForm();
    }
  };

  const handleOpenForgotPasswordModal = () => {
    modals.open(ModalType.PROFILE_FORGOT_PASSWORD_CONFIRM_TO_SEND_EMAIL, {email: userStore.user?.email}, true, true);
  };

  const transitions = useTransition(currentStep, {
    from:
      prevStep === currentStep
        ? {}
        : {
          opacity: 0,
          transform: prevStep <= currentStep ? 'translate3d(100%, 0, 0)' : 'translate3d(-100%, 0, 0)',
        },
    enter: {opacity: 1, transform: 'translate3d(0%, 0, 0)'},
    leave: {
      opacity: 0,
      transform: prevStep <= currentStep ? 'translate3d(-100%, 0, 0)' : 'translate3d(100%, 0, 0)',
    },
  });

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  const getContentHeight = (step: ChangeEmailSteps) => {
    switch (step) {
      case ChangeEmailSteps.CHANGE_EMAIL:
        return userStore.changeEmailTimer.inProgress ? '310px' : '210px';
      case ChangeEmailSteps.VERIFICATION_EMAIL:
        return userStore.confirmEmailByVerificationCodeTimer.inProgress
          ? '340px'
          : userStore.resendConfirmationEmailCodeTimer.inProgress
            ? '270px'
            : '240px';
    }
  };

  const getDialogContent = (step: ChangeEmailSteps, styles: Record<string, any>) => {
    switch (step) {
      case ChangeEmailSteps.CHANGE_EMAIL:
        return (
          <animated.div style={styles} className="operator-manage__animated-tab px-6">
            <form onSubmit={formikNewEmail.handleSubmit}>
              {userStore.changeEmailTimer.inProgress ? (
                <SnackMessage
                  className="mb-5"
                  message={t('notification_too_many_request_message')}
                  subMessage={t('notification_too_many_request_sub_message', {
                    time: formatDuration(userStore.changeEmailTimer.currentTime * 1000),
                  })}
                  variant="error"
                  hideCloseIcon
                />
              ) : null}

              <InputLabel className="mb-2">{t('settings_personal_info_change_email_password_label')}</InputLabel>
              <OutlinedTextInput
                className="w-100"
                name="password"
                placeholder={t('settings_personal_info_change_email_password_placeholder')}
                type={showCurrentPassword ? 'text' : 'password'}
                error={!!(formikNewEmail.touched.password && formikNewEmail.errors.password)}
                errorHelper={formikNewEmail.errors.password || ' '}
                onChange={formikNewEmail.handleChange}
                onBlur={formikNewEmail.handleBlur}
                value={formikNewEmail.values.password}
                endAdornment={
                  <InputAdornment position="end">
                    <VisibilitySwitcher onClick={handleClickShowCurrentPassword} visible={showCurrentPassword} />
                  </InputAdornment>
                }
                required
              />

              <InputLabel className="mb-2">{t('settings_personal_info_change_email_email_label')}</InputLabel>
              <OutlinedTextInput
                className="w-100"
                name="newEmail"
                placeholder={t('settings_personal_info_change_email_email_placeholder')}
                onChange={formikNewEmail.handleChange}
                onBlur={formikNewEmail.handleBlur}
                value={formikNewEmail.values.newEmail}
                error={!!(formikNewEmail.touched.newEmail && formikNewEmail.errors.newEmail)}
                errorHelper={formikNewEmail.errors.newEmail || ' '}
                required
              />
            </form>
          </animated.div>
        );
      case ChangeEmailSteps.VERIFICATION_EMAIL:
        return (
          <animated.div style={styles} className="operator-manage__animated-tab px-6">
            <form onSubmit={formikEmailVerification.handleSubmit}>
              <span className="body1-regular color-body-primary">
                <Trans
                  i18nKey="settings_personal_info_change_email_code_title"
                  values={{email: formikNewEmail.values.newEmail}}
                  components={{strong: <strong />}}
                />
              </span>
              {userStore.confirmEmailByVerificationCodeTimer.inProgress ? (
                <SnackMessage
                  className="mb-5"
                  message={t('notification_too_many_request_message')}
                  subMessage={t('notification_too_many_request_sub_message', {
                    time: formatDuration(userStore.confirmEmailByVerificationCodeTimer.currentTime * 1000),
                  })}
                  variant="error"
                  hideCloseIcon
                />
              ) : null}
              <InputLabel className="mt-5">
                {t('settings_personal_info_change_email_code_label', {length: CODE_LENGTH})}
              </InputLabel>
              <div className="d-inline-flex">
                <PinInput
                  className={cn({
                    error: apiError || formikEmailVerification.errors.code,
                  })}
                  name="code"
                  onChange={handleChangeConfirmationCode}
                  value={formikEmailVerification.values.code}
                  fields={8}
                />
              </div>
              <FormHelperText error>{apiError || formikEmailVerification.errors.code || ' '}</FormHelperText>
            </form>
            {userStore.resendConfirmationEmailCodeTimer.inProgress ? (
              <span className="body1-regular color-body-primary">
                {t('settings_personal_info_change_email_resend_code_timestamp_text', {
                  time: formatDuration(Math.floor(userStore.resendConfirmationEmailCodeTimer.currentTime) * 1000),
                })}
                <div className="mt-2">
                  <span className="body2-regular color-body-secondary">
                    {t('settings_personal_info_change_email_code_availability')}
                  </span>
                </div>
              </span>
            ) : (
              <Link className="cursor-pointer" onClick={handleResendConfirmationCode}>
                {t('settings_personal_info_change_email_resend_code')}
              </Link>
            )}
          </animated.div>
        );
    }
  };

  const getDialogActions = (step: ChangeEmailSteps) => {
    switch (step) {
      case ChangeEmailSteps.CHANGE_EMAIL:
        return (
          <div className="d-flex justify-content-between w-100">
            <ButtonWithTimer
              variant="contained"
              color="primary"
              size="large"
              timer={userStore.changeEmailTimer}
              onClick={handleSubmitNewEmailForm}
            >
              {t('settings_personal_info_change_email_go_to_verify_step')}
            </ButtonWithTimer>

            <ButtonWithTimer
              timer={userStore.forgotPasswordTimer}
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleOpenForgotPasswordModal}
            >
              {t('settings_personal_info_change_email_forgot_password')}
            </ButtonWithTimer>
          </div>
        );

      case ChangeEmailSteps.VERIFICATION_EMAIL:
        return (
          <ButtonWithTimer
            variant="contained"
            color="primary"
            size="large"
            onClick={() => formikEmailVerification.handleSubmit()}
            disabled={
              !!(formikEmailVerification.touched.code && formikEmailVerification.errors.code) ||
              !formikEmailVerification.dirty
            }
            timer={userStore.confirmEmailByVerificationCodeTimer}
          >
            {t('settings_personal_info_change_email_send_code')}
          </ButtonWithTimer>
        );
    }
  };

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      className="modal-profile-change-email"
      title={props.emailVerificationMode ? t('settings_personal_info_change_email_confirm_email') : t('settings_personal_info_change_email_title')}
      maxWidth="md"
    >
      <DialogContent
        className="p-0 position-relative overflow-hidden"
        style={isMobile ? {} : {width: '600px', height: getContentHeight(currentStep)}}
      >
        {transitions((styles, step) => getDialogContent(step, styles))}
      </DialogContent>
      <DialogActions>{getDialogActions(currentStep)}</DialogActions>
    </ModalDialog>
  );
});

export default ModalProfileChangeEmail;
