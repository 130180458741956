import {
  MAX_MEDIA_PREVIEW_HEIGHT,
  MAX_MEDIA_PREVIEW_HEIGHT_FOR_COMPACT,
  MAX_MEDIA_PREVIEW_WIDTH,
  MAX_MEDIA_PREVIEW_WIDTH_FOR_COMPACT,
} from '../constants';
import useCompactMode from './useCompactMode';

export function useMaxMediaSize(): {
  maxWidth?: number;
  maxHeight?: number;
} {
  const compactMode = useCompactMode();

  if (compactMode) {
    return {
      maxWidth: MAX_MEDIA_PREVIEW_WIDTH_FOR_COMPACT,
      maxHeight: MAX_MEDIA_PREVIEW_HEIGHT_FOR_COMPACT,
    };
  }

  return {
    maxWidth: MAX_MEDIA_PREVIEW_WIDTH,
    maxHeight: MAX_MEDIA_PREVIEW_HEIGHT,
  };
}

export default useMaxMediaSize;