import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import FormControl from 'o-ui/FormControl';
import FormControlLabel from 'o-ui/FormControlLabel';
import SearchInput from 'o-ui/Input/SearchInput';
import Switch from 'o-ui/Switch';

import {SEARCH_TEXT_LENGTH_LIMIT} from '../../../../../constants';
import WorkspaceMember from '../../../../../stores/Workspaces/WorkspaceMember';
import WorkspaceMemberAccessRecord from '../../../../../stores/Workspaces/WorkspaceMemberAccessRecord';
import {getUserRoleTitle} from '../../../../../stores/Workspaces';


interface ChatsAccessHeaderProps {
  onAddChat(): void;
  onBack(): void;
  onSearch(value: string): void;
  onResetExceptions(): void;
  member: WorkspaceMember;
  accessRecord: WorkspaceMemberAccessRecord;
  disableAddChatButton?: boolean;
}

const ChatsAccessHeader: React.FC<ChatsAccessHeaderProps> = observer(
  ({
    onBack,
    onSearch,
    onResetExceptions,
    onAddChat,
    member,
    accessRecord,
    disableAddChatButton,
  }) => {
    const {t} = useTranslation();

    const {disableChannel, chatsList} = accessRecord;

    const title = member.displayName;
    const subTitle = title && title !== member.email && member.email ? (
      <>{getUserRoleTitle(member.role)} &bull; {member.email}</>
    ) : getUserRoleTitle(member.role);

    return (
      <div className="chats-access__header">
        <div className="chats-access__header-row">
          <Button className="chats-access__header-back-btn" variant="text" color="primary" onClick={onBack}>
            <span className="tg-icon-chevron-left-line mr-3 font-size-12" />
            {t('setting_workspace_members_chats_access_header_back_btn')}
          </Button>
        </div>

        <div className="chats-access__header-row">
          <div className="chats-access__header-title">{title}</div>
          <div className="chats-access__header-phone">{subTitle}</div>
        </div>

        <div className={cn({'channel-disabled': disableChannel})}>
          <div className="chats-access__header-exceptions chats-access__header-row">
            <div className="chats-access__header-exceptions-title">
              {t('setting_workspace_members_chats_access_header_exceptions_title')}
            </div>
            {chatsList?.length ? (
              <Button
                onClick={onResetExceptions}
                variant="contained"
                color="secondary"
                size="small"
                disabled={!!disableChannel}
              >
                {t('settings_channel_access_header_reset_exceptions_btn')}
              </Button>
            ) : null}
          </div>

          <FormControl className="chats-access__header-row w-100 outline-default" variant="outlined">
            <FormControlLabel
              className="justify-content-end m-0"
              label={t('setting_workspace_members_chats_access_type_exception_label')}
              labelPlacement="start"
              control={
                <>
                  <Switch
                    className={cn('chats-access__header-switch', {disabled: !!disableChannel})}
                    icon={
                      <div className="switch__thumb">
                        <span className="tg-icon-lock-2-line" />
                      </div>
                    }
                    checkedIcon={
                      <div className="switch__thumb">
                        <span className="tg-icon-check-fill" />
                      </div>
                    }
                    checked={accessRecord.isAllowList}
                    onChange={accessRecord.toggleListType}
                    disabled={!!disableChannel}
                  />
                  <div className="ml-auto">
                    {accessRecord.isAllowList
                      ? t('setting_workspace_members_chats_access_type_exception_allowed_label')
                      : t('setting_workspace_members_chats_access_type_exception_blocked_label')}
                  </div>
                </>
              }
            />
          </FormControl>

          {chatsList?.length ? (
            <div className="chats-access__header-search chats-access__header-row">
              <SearchInput
                className="w-100"
                placeholder={t('components_select_chat_search_input_placeholder')}
                onChange={onSearch}
                maxLength={SEARCH_TEXT_LENGTH_LIMIT}
              />
              <Button
                onClick={onAddChat}
                variant="contained"
                color="secondary"
                disabled={!!disableChannel || disableAddChatButton}
              >
                <span className="tg-icon-plus-line mr-3" />
                {t('setting_workspace_members_chats_access_add_chat_btn')}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);

export default ChatsAccessHeader;
