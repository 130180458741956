import copy from 'copy-to-clipboard';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import FormLabel from 'o-ui/FormLabel';
import Icon from 'o-ui/Icon';
import CopyTextInput from 'o-ui/Input/CopyTextInput';
import Link from 'o-ui/Link';

import {API_DOCUMENTATION_URL} from '../../../../config';
import useStore from '../../../../stores/useStore';

import {ReactComponent as CopyLineIcon} from '../../../../assets/icons/copy-line.svg';

interface WebWidgetCreationResultProps {
  className?: string;
  style?: React.CSSProperties;
  scriptUrl?: string | null;
  apiCode?: string | null;
  isOnboarding?: boolean;
}

export const WebWidgetCreationResult: React.FC<WebWidgetCreationResultProps> = observer(({
  className,
  style,
  scriptUrl,
  apiCode,
  isOnboarding,
}) => {
  const {t} = useTranslation();
  const {notifications} = useStore();

  const handleCopyWidgetScript = () => {
    copy(scriptUrl || '');
    notifications.action(t('settings_web_widget_creation_result_copy_script'));
  };

  const handleCopyApiCode = () => {
    copy(apiCode || '');
    notifications.action(t('settings_web_widget_creation_result_copy_api_code'));
  };

  return (
    <div
      className={className}
      style={style}
    >
      {isOnboarding ? (
        <>
          <div className="h1-medium mb-8">{t('onboarding_channel_creation_success')}</div>
        </>
      ) : null}

      <div className="h4-medium">{t('settings_web_widget_creation_result_title')}</div>
      <div className="body1-regular mt-3">{t('settings_web_widget_creation_result_description')}</div>

      <div className="mt-3">
        <Link href={`${API_DOCUMENTATION_URL}#examples`} target="_blank">
          {t('settings_web_widget_creation_result_example_link')}
        </Link>
      </div>

      <FormLabel className="body3-regular color-body-tertiary d-flex text-capitalize mt-3">
        {t('settings_web_widget_creation_result_script_label')}
      </FormLabel>
      <CopyTextInput
        className="w-100"
        value={scriptUrl}
        spellCheck={false}
        onCopyValue={handleCopyWidgetScript}
        copiedStateDelay={2000}
        copyBtnIcon={<CopyLineIcon />}
      />

      <FormLabel className="body3-regular color-body-tertiary d-flex text-capitalize mt-3">
        {t('settings_web_widget_creation_result_api_code_label')}
      </FormLabel>
      <CopyTextInput
        className="w-100"
        value={apiCode}
        spellCheck={false}
        onCopyValue={handleCopyApiCode}
        copiedStateDelay={2000}
        copyBtnIcon={<CopyLineIcon />}
      />

      <div className="mt-3 body1-regular">
        <Link className="d-flex align-items-center" href={API_DOCUMENTATION_URL} target="_blank">
          <Icon className="tg-icon-file-line icon-size-16 mr-2" />
          {t('settings_web_widget_creation_result_documentation_link')}
        </Link>
      </div>
    </div>
  );
});

export default WebWidgetCreationResult;
