import React from 'react';
import {useTranslation} from 'react-i18next';
import AutoSizer, {Size} from 'react-virtualized-auto-sizer';


import CustomScrollbar from 'o-ui/CustomScrollbar';
import {FixedSizeList} from 'o-ui/VirtualizedList';
import WorkspaceMember from '../../../../stores/Workspaces/WorkspaceMember';
import MembersTableRow from './MembersTableRow';

const ITEM_SIZE = 64; //px;

interface MembersListWrapperProps {
  children: (size: Size) => React.ReactNode;
  itemsCount: number;
}

export const MembersListWrapper: React.FC<MembersListWrapperProps> = (props) => {
  return (
    <div style={{height: '100%', width: '100%'}}>
      <AutoSizer>{props.children}</AutoSizer>
    </div>
  );
};

interface MembersTableProps {
  list: WorkspaceMember[];
  onClick?: (member: WorkspaceMember) => void;
}

export const MembersTable: React.FC<MembersTableProps> = (props) => {
  const {t} = useTranslation();

  return (
    <div className="members-table">
      <div className="members-table__row members-table__row--head">
        <div className="members-table__cell members-table__cell--name">
          {t('settings_workspace_members_column_name_and_email')}
        </div>
        <div className="members-table__cell members-table__cell--role">
          {t('settings_workspace_members_column_role')}
        </div>
        <div className="members-table__cell members-table__cell--status">
          {t('settings_workspace_members_column_status')}
        </div>
        <div className="members-table__cell members-table__cell--created-at">
          {t('settings_workspace_members_column_created_at')}
        </div>
        <div className="members-table__cell members-table__cell--invited-by">
          {t('settings_workspace_members_column_invited_by')}
        </div>
      </div>
      <div className="members-table__wrapper">
        <MembersListWrapper itemsCount={props.list.length}>
          {({width, height}) => (
            <FixedSizeList
              width={width || 0}
              height={height || 0}
              itemCount={props.list.length}
              itemSize={ITEM_SIZE}
              outerElementType={CustomScrollbar}
            >
              {({index, style}) => (
                <MembersTableRow
                  style={style}
                  key={props.list[index].id?.toString()}
                  item={props.list[index]}
                  onClick={props.onClick}
                />
              )}
            </FixedSizeList>
          )}
        </MembersListWrapper>
      </div>
    </div>
  );
};

export default MembersTable;
