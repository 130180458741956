import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';

import DialogActions from 'o-ui/Dialog/DialogActions';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import {getUserResponseStatusError} from '../../api/getErrorByType';
import ButtonWithTimer from '../../components/ButtonWithTimer';
import SnackMessage from '../../components/SnackMessage';
import {Paths} from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import {formatDuration} from '../../utils/format';
import {LoginFormNotification} from './LoginForm';

interface IProps {
  className?: string;
  isProfile?: boolean;
  onSuccess?: () => void;
}

export const ForgotPasswordForm = observer((props: IProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {userStore, notifications} = useStore();

  const [apiError, setApiError] = React.useState<string | null | undefined>('');

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string().required(t('forgot_password_email_required')),
    }),
    onSubmit: async ({email}) => {
      setApiError('');
      const {error, res} = await userStore.forgotPassword(email);

      if (error) {
        setApiError(error.message);
        notifications.error(error.message);
      }

      if (res) {
        const statusErrorMessage = getUserResponseStatusError('forgotPassword', res.status);

        if (statusErrorMessage) {
          notifications.error(statusErrorMessage);
          setApiError(statusErrorMessage);
          return;
        }

        if (props.isProfile) {
          props.onSuccess?.();
        } else {
          navigate(Paths.Login, {
            replace: true,
            state: {
              notificationType: LoginFormNotification.EMAILED_TO_RESET_PASSWORD,
              email,
            },
          });
        }
      }
    },
  });

  const handleChange = (e: React.ChangeEvent) => {
    formik.handleChange(e);
    setApiError('');
  };

  const getSubmitButton = () => (
    <ButtonWithTimer
      className="w-100"
      type="submit"
      variant="contained"
      color="primary"
      size="large"
      loading={userStore.loading}
      timer={userStore.forgotPasswordTimer}
    >
      {t('forgot_password_submit')}
    </ButtonWithTimer>
  );

  return (
    <form className={props.className} onSubmit={formik.handleSubmit}>
      <div className="auth-page__sub-title">{t('forgot_password_subtitle')}</div>

      {userStore.forgotPasswordTimer.inProgress ? (
        <SnackMessage
          className="mb-6"
          message={t('notification_too_many_request_message')}
          subMessage={t('notification_too_many_request_sub_message', {
            time: formatDuration(userStore.forgotPasswordTimer.currentTime * 1000),
          })}
          variant="error"
          hideCloseIcon
        />
      ) : null}

      <OutlinedTextInput
        className="w-100"
        name="email"
        placeholder={t('forgot_password_email_placeholder')}
        onChange={handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        errorHelper={apiError || formik.errors.email || ' '}
        required
      />

      {props.isProfile ? (
        <DialogActions className="p-0 pt-6 mt-3">{getSubmitButton()}</DialogActions>
      ) : (
        <>{getSubmitButton()}</>
      )}

      {!props.isProfile ? (
        <div className="auth-page__join-link">
          {t('forgot_password_did_remember')}
          <Link to={Paths.Login} className="auth-page__link">
            {t('forgot_password_go_to_login')}
          </Link>
        </div>
      ) : null}
    </form>
  );
});

export default ForgotPasswordForm;
