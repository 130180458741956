import React from 'react';

import {IMCTextEntities} from '../../../../api/proto';

export interface TextEntitiesTreeNode {
  text: string;
  textEntity?: IMCTextEntities | null;
  textEntities: IMCTextEntities[];
  startIndex: number;
  level: number;
  plainText?: boolean;
  //textSelection?: com.chatman.ITextSelection | null;
  containerRef?: React.RefObject<HTMLDivElement | null>;
}

export function getTextEntitiesNodes(
  text: string,
  textEntities: IMCTextEntities[],
  startIndex: number,
  level: number,
) {
  const textNodes: TextEntitiesTreeNode[] = [];
  const teLength = textEntities.length;
  // console.debug('-> getTextEntitiesNodes ****** level=', level, 'startIndex=', startIndex, text, textEntities);

  const textEntitiesSorted = textEntities.sort((a, b) => (a.offset?.toNumber() || 0) - (b.offset?.toNumber() || 0));

  textEntitiesSorted.forEach((e, i) => {
    const offset = (e.offset?.toNumber() || 0) - startIndex;
    const length = e.length?.toNumber() || 0;
    // console.debug('--> getTextEntitiesNodes-0: ', text, 'type=', e.type, 'offset=', offset, 'length=', length);

    if (i === 0 && offset > startIndex) {
      // console.debug('--> getTextEntitiesNodes-1', text.substr(startIndex, offset), 'offset=', offset, 'length=', length);
      textNodes.push({
        //text: text.substr(offset, length),
        text: text.substr(startIndex, offset),
        textEntities: [],
        startIndex: startIndex,
        level,
      });
    }

    const prevTextNode =
      textNodes.length > 0 && textNodes[textNodes.length - 1] ? textNodes[textNodes.length - 1] : null;
    const prevTextEntity = prevTextNode && prevTextNode.textEntity ? prevTextNode.textEntity : null;

    const prevNodeLimit = prevTextEntity ? (prevTextEntity.offset?.toNumber() || 0) + (prevTextEntity.length?.toNumber() || 0) : 0;
    const prevNodeLimitInner = prevTextEntity
      ? (prevTextEntity.offset?.toNumber() || 0) - startIndex + (prevTextEntity.length?.toNumber() || 0)
      : 0;

    if (prevNodeLimitInner && prevNodeLimitInner < offset) {
      const nextText = text.substr(prevNodeLimitInner, offset - prevNodeLimitInner);
      // console.debug(`--> getTextEntitiesNodes-4 text=|${nextText}| offset=${offset} length=${length} prevNodeLimitInner=${prevNodeLimitInner}`);
      textNodes.push({
        text: nextText,
        textEntities: [],
        startIndex: prevNodeLimit,
        level,
      });
    }

    if (prevTextNode && prevTextEntity && prevNodeLimitInner >= offset + length) {
      // console.debug(`--> getTextEntitiesNodes-3 added to prev prevNodeLimitInner=${prevNodeLimitInner} offset=${offset} length=${length}`, e, prevTextNode);
      prevTextNode.textEntities.push(e);

      if (i === teLength - 1 && text.length > prevNodeLimitInner) {
        // console.debug('--> getTextEntitiesNodes-5', text.substr(prevNodeLimitInner), 'offset=', offset, 'length=', length);
        textNodes.push({
          text: text.substr(prevNodeLimitInner),
          textEntities: [],
          startIndex: prevNodeLimitInner,
          level,
        });
      }
    } else {
      // console.debug('--> getTextEntitiesNodes-2', text.substr(offset, length), 'offset=', offset, 'length=', length);
      textNodes.push({
        text: text.substr(offset, length),
        textEntity: e,
        textEntities: [],
        startIndex: e.offset?.toNumber() || 0,
        level,
      });

      if (i === teLength - 1 && text.length > offset + length) {
        // console.debug('--> getTextEntitiesNodes-6', text.substr(offset + length), 'offset=', offset, 'length=', length);
        textNodes.push({
          text: text.substr(offset + length),
          textEntities: [],
          startIndex: (e.offset?.toNumber() || 0) + (e.length?.toNumber() || 0),
          level,
        });
      }
    }
  });

  return textNodes;
}

export default getTextEntitiesNodes;