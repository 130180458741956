import BubbleTheme, {BubbleTooltip} from '../QuillCore/themes/bubble';
import icons from './icons';
import Keyboard from '../QuillCore/modules/keyboard';

class CustomQuillThemeTooltip extends BubbleTooltip {
  preview?: HTMLElement;

  listen() {
    this.textbox.addEventListener('keydown', (event) => {
      if (Keyboard.match(event, 'enter')) {
        this.save();
        event.preventDefault();
      } else if (Keyboard.match(event, 'escape')) {
        this.cancel();
        event.preventDefault();
      }
    });

    this.root?.querySelector('.ql-close-tooltip')?.addEventListener('click', (event) => {
      event.preventDefault();
      this.hide();
    });

    this.root?.querySelector('.ql-clear-input')?.addEventListener('click', (event) => {
      event.preventDefault();
      const input = this.root?.querySelector('.ql-link-input');
      if (input) {
        input.value = '';
      }
      this.edit('link', '');
    });

    this.root?.querySelector('.ql-action')?.addEventListener('click', (event) => {
      if (this.root.classList.contains('ql-editing')) {
        this.save();
      } else if (this.preview?.textContent) {
        this.edit('link', this.preview.textContent);
      }
      event.preventDefault();
    });

    const observer = new MutationObserver(() => {
      const btn = this.root.querySelector('.ql-link');
      if (btn.classList.contains('ql-active')) {
        btn.querySelector('.ql-text').textContent = 'Edit link';
      } else {
        btn.querySelector('.ql-text').textContent = 'Add link';
      }
    });

    observer.observe(this.root, {
      attributes: true,
      attributeFilter: ['class'],
    });
  }
}

CustomQuillThemeTooltip.TEMPLATE = [
  '<span class="ql-tooltip-arrow"></span>',
  '<div class="ql-tooltip-editor">',
  '<button class="ql-close-tooltip tg-icon-chevron-left-line"></button>',
  '<input class="ql-link-input" placeholder="Paste link here" type="text" data-formula="e=mc^2" data-link="Paste link here" data-video="Embed URL">',
  '<button class="ql-clear-input tg-icon-cross-line"></button>',
  '<button class="ql-action tg-icon-check-2-line"></button>',
  '</div>',
].join('');

class CustomQuillTheme extends BubbleTheme {
  extendToolbar(toolbar) {
    this.tooltip = new CustomQuillThemeTooltip(this.quill, this.options.bounds);
    this.tooltip.root?.appendChild(toolbar.container);
    this.buildButtons([].slice.call(toolbar.container.querySelectorAll('button')), icons);
    this.buildPickers([].slice.call(toolbar.container.querySelectorAll('select')), icons);
  }
}

export {CustomQuillThemeTooltip, CustomQuillTheme as default};
