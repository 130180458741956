import React from 'react';
import formatSeconds from '../../../../utils/formatSeconds';

interface IProps {
  playedSeconds: number;
  duration?: number;
}

export const VideoInlineProgress: React.FC<IProps> = ({duration, playedSeconds}) => {
  const leftSeconds = duration ? duration - playedSeconds : playedSeconds;
  return playedSeconds ? (
    <span className="message-video-inline-player__progress">
      {formatSeconds(leftSeconds < 0 ? 0 : leftSeconds)}
      <i className="message-video-inline-player__progress-muted tg-icon tg-icon-volume-0-fill ml-1" />
    </span>
  ) : null;
};

export default VideoInlineProgress;
