import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import Avatar from 'o-ui/Avatar';
import IconButton from 'o-ui/Button/IconButton';
// import ChatTypeIcon from './ChatItem/ChatTypeIcon';
// import ChatChannelTypeIcon from './ChatItem/ChatChannelTypeIcon';
import Chat, {ChatClickEventHandler} from '../../../../stores/Chat';
import useAvatarAttachment from '../../useAvatarAttachment';

interface ChatForSearchItemProps {
  chat: Chat;
  style?: React.CSSProperties;
  onClose?: ChatClickEventHandler;
}

export const ChatForSearchItem: React.FC<ChatForSearchItemProps> = observer((props) => {
  const {chat} = props;
  const {src} = useAvatarAttachment(chat.avatar, chat.channelID);

  const onCloseClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    props.onClose?.(props.chat);
  };

  return (
    <div
      key={chat.id.toString()}
      style={props.style}
      id={chat.id.toString()}
      data-channel-id={chat.channelID}
      className={cn('chat-item disabled-hover', {
        unread: chat.unreadCount,
        typing: chat.isTyping,
        resolved: chat.resolved,
        joined: chat.assignment.joinedOperators.length,
        'joined-other': chat.assignment.otherJoinedOperators.length,
        'joined-you': chat.assignment.youAreJoined,
      })}
    >
      <div className="chat-item__body">
        <Avatar
          className="chat-item__avatar"
          src={src}
          name={chat.displayTitle}
          // status={chat.store?.chatUser?.statusString}
        />
        <div className="chat-item__data">
          <div className="chat-item__head">
            {/* <ChatTypeIcon className="chat-item__chat-type-icon" chatType={chat.chatType} /> */}

            <span className="chat-item__title">{chat.displayTitle}</span>

            {/* <ChatChannelTypeIcon className="chat-item__source-icon" source={chat.channel?.source} /> */}

            <IconButton className="tg-icon tg-icon-cross-2-line ml-auto" onClick={onCloseClick} size="medium" />
          </div>
        </div>
      </div>
    </div>
  );
});

export default ChatForSearchItem;
