export enum AnalyticsEvent {
  AddToCart = 'AddToCart',
  InitiateCheckout = 'InitiateCheckout',
  CompleteRegistration = 'CompleteRegistration',
  Subscribe = 'Subscribe',
  SignUp = 'Sign Up',
  SubscribeClick = 'Subscribe Click',
  page_view = 'page_view',
  scroll = 'scroll',
  start_btn_click = 'start_btn_click',
  header_menu_click = 'header_menu_click',
  header_login_btn_click = 'header_login_btn_click',
  contactus_btn_click = 'contactus_btn_click',
  use_cases_tab_click = 'use_cases_tab_click',
  live_chat_widget_click = 'live_chat_widget_click',
  signup_btn_click = 'signup_btn_click',
  login_lnk_click = 'login_lnk_click',
  login_btn_click = 'login_btn_click',
  signup_lnk_click = 'signup_lnk_click',
  signup_success = 'signup_success',
  login_success = 'login_success',
  add_to_cart = 'add_to_cart',
  subscribe = 'subscribe',
  upgrade = 'upgrade',
  outbound_click = 'outbound_click',
  file_download = 'file_download',
  invite_btn_click = 'invite_btn_click',
  user_invite_sent = 'user_invite_sent',
  add_channel_btn_click = 'add_channel_btn_click',
  channel_setup = 'channel_setup',
  workspace_created = 'workspace_created',
}

export default AnalyticsEvent;