import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {MessageTagItem} from '../../components/MessageTagItem';
import {useStore} from '../../stores/AppStore';
import {ContextMenuItem} from '../../stores/ContextMenuStore';
import {DEFAULT_MESSAGE_TAG_COLOR, ITag, MESSAGE_TAG_NO_WRAP_WIDTH, TagType} from '../../stores/MessageTagsStore';
import {MenuMessageTagsTable} from './MenuMessageTagsTable';

import {IconButton} from 'o-ui/Button';
import InputBase from 'o-ui/Input/InputBase';
import {ReactComponent as Hexagon} from '../../assets/image-icons/hexagon.svg';
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate';
import {Paths} from '../../routes/Paths';

interface IProps {
  menu: ContextMenuItem;
}

export const MenuMessageTagsViewer: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const {
    chatsView,
    userStore,
    layOutStore: {rightSideBar},
  } = useStore();

  const workspaceNavigate = useWorkspaceNavigate();

  const {message, tag} = props.menu.data;
  const {user} = userStore;

  const tagColor = tag?.color || DEFAULT_MESSAGE_TAG_COLOR;

  const [filter, setFilter] = React.useState<string>('');

  const handleRemoveFromMessage = (tag: ITag) => {
    if (message) {
      // chatsView.tags.removeTagFromMessage(tag, message);
      chatsView.tags.removeTagFromMessage(tag);
    }
  };

  const handleClearFilter = () => {
    setFilter('');
  };

  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const handleCreateTag = (tagType: TagType) => {
    chatsView.tags.add(filter, tagColor, tagType === TagType.Workspace);
    handleClearFilter();
  };

  const handleOpenTagsSettings = () => {
    workspaceNavigate(Paths.Tags);

    rightSideBar.close();
    props.menu.close();
  };

  return (
    <div className="dropdown-menu message-tags-menu">
      <div className="px-5 py-3">
        {message?.tags.length ? (
          <div className="message-tags-menu__selected-tags-container custom-scroll mb-3">
            {message?.tags.map((tag) => (
              <MessageTagItem
                labelClassName="mr-2"
                key={tag.id?.toString()}
                tag={tag}
                onCancel={handleRemoveFromMessage}
                noWrapMaxWidth={MESSAGE_TAG_NO_WRAP_WIDTH}
              />
            ))}
          </div>
        ) : null}

        <div className="radius-8 d-flex flex-auto align-items-center">
          <InputBase
            className="w-100"
            placeholder={t('ctx_menu_message_tag_search_tags')}
            value={filter}
            onChange={handleChangeFilter}
          />
          {filter.length ? (
            <IconButton className="tg-icon-cross-line icon-size-12" onClick={handleClearFilter} size="extraSmall" />
          ) : null}
        </div>
      </div>
      <hr />

      <div className="px-5">
        <MenuMessageTagsTable filter={filter} message={message} />
      </div>

      {filter.length ? (
        <>
          {user?.isOwner && (
            <button
              type="button"
              className="dropdown-item d-flex py-5"
              onClick={() => handleCreateTag(TagType.Workspace)}
            >
              {t('ctx_menu_message_tag_create_workspace_tag')}
              <MessageTagItem
                labelClassName="ml-3"
                tag={{
                  text: filter,
                  color: tagColor,
                }}
                noWrap
              />
            </button>
          )}
          {user?.isOwner && (
            <button
              type="button"
              className="dropdown-item d-flex py-5"
              onClick={() => handleCreateTag(TagType.Personal)}
            >
              {t('ctx_menu_message_tag_create_personal_tag')}
              <MessageTagItem
                labelClassName="ml-3"
                tag={{
                  text: filter,
                  color: tagColor,
                }}
                noWrap
              />
            </button>
          )}
        </>
      ) : null}
      <hr />
      <button type="button" className="dropdown-item d-flex py-5" onClick={handleOpenTagsSettings}>
        <Hexagon className="system-menu__icon mr-3 icon-size-18" />
        {t('ctx_menu_message_tag_manage_tags')}
      </button>
    </div>
  );
});

export default MenuMessageTagsViewer;
