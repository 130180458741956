import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import {useStore} from '../stores/AppStore';

import {ReactComponent as NewVersionIcon} from '../assets/icons/new-version-fill.svg';


const VersionUpdateAppBar = observer(() => {
  const {appVersionUpdater, externalApiCallHandler} = useStore();
  const {t} = useTranslation();

  if (!appVersionUpdater.hasUpdate) {
    return null;
  }

  return (
    <div className="update-app-bar">
      <NewVersionIcon className="mr-3" />
      {t('app_version_updater_available_new_version')}
      <Button
        size="small"
        className="update-app-bar__button ml-5"
        onClick={appVersionUpdater.hasNewDesktopVersion ? externalApiCallHandler.updateDesktopApp : appVersionUpdater.update}
      >
        {t('app_version_updater_update_now_btn')}
      </Button>
    </div>
  );
});

export default VersionUpdateAppBar;
