import React from 'react';

import {useStore} from '../../../../stores/AppStore';
import {getStyleProperty} from '../../../../utils/styleProperty';
import {use100vh} from '../../../../components/Div100vh';


export const useChatsTabAdaptations = (containerRef: React.RefObject<HTMLDivElement>) => {
  // const containerRef = React.useRef<HTMLDivElement>(null);
  const height = use100vh();
  const {layOutStore} = useStore();

  React.useEffect(() => {
    let containerHeight = height ? `${height}px` : '100vh';
    if (layOutStore.chatLeftSidebarBottomMenuVisible) {
      const sidebarHeight = parseInt(getStyleProperty('--chat-left-sidebar-bottom-tabs-height'), 10);

      containerHeight = height ? `${height - sidebarHeight}px` : `calc(100vh - ${sidebarHeight}px)`;
    }

    containerRef?.current?.style.setProperty('height', containerHeight);
  }, [layOutStore.chatLeftSidebarBottomMenuVisible, height, containerRef]);
};

export default useChatsTabAdaptations;
