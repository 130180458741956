import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';

import AuthInfo from '../AuthInfo';
import RegistrationForm from './RegistrationForm';
import Div100vh from '../../../components/Div100vh';

export const RegistrationPage = observer(() => {
  const {t} = useTranslation();

  return (
    <Div100vh className="auth-page">
      <div className="auth-page__left-side">
        <AuthInfo />
      </div>

      <div className="auth-page__right-side">
        <div className="auth-page__title">{t('registration_title')}</div>

        <RegistrationForm className="mb-auto" />
      </div>
    </Div100vh>
  );
});

export default RegistrationPage;
