import React from 'react';
import {useTranslation} from 'react-i18next';
import {Slider} from 'o-ui/Slider/Slider';
import {SliderItem} from 'o-ui/Slider/SliderItem';
import {BRAND_NAME} from '../../config';

export const AuthInfoSlider = () => {
  const {t} = useTranslation();

  return (
    <Slider pagination={true} delay={5000} className="auth-info-slider">
      <SliderItem className="auth-info-slider__slide">
        <div className="auth-info-slider__info">
          <div className="auth-info-slider__info-icon tg-icon-chat-line" />
          <div className="auth-info-slider__info-title">{t('login_slide_1_title')}</div>
          <div className="auth-info-slider__info-sub-title">{t('login_slide_1_subtitle', {brandName: BRAND_NAME})}</div>
        </div>
      </SliderItem>
      <SliderItem className="auth-info-slider__slide">
        <div className="auth-info-slider__info">
          <div className="auth-info-slider__info-icon tg-icon-link-line" />
          <div className="auth-info-slider__info-title">{t('login_slide_2_title')}</div>
          <div className="auth-info-slider__info-sub-title">{t('login_slide_2_subtitle')}</div>
        </div>
      </SliderItem>
      <SliderItem className="auth-info-slider__slide">
        <div className="auth-info-slider__info">
          <div className="auth-info-slider__info-icon tg-icon-charts-line" />
          <div className="auth-info-slider__info-title">{t('login_slide_3_title')}</div>
          <div className="auth-info-slider__info-sub-title">{t('login_slide_3_subtitle', {brandName: BRAND_NAME})}</div>
        </div>
      </SliderItem>
    </Slider>
  );
};

export default AuthInfoSlider;