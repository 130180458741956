import React from 'react';
import cn from 'classnames';
import Long from 'long';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import i18n from '../../../../i18n';
import Invite from '../../../../stores/Workspaces/Invite';


function leftTimeInSeconds(time: Long): number {
  return Math.round((new Date().getTime() - time.toNumber()) / 1000);
}

function leftTimeInMinutes(time: Long): number {
  return Math.floor(leftTimeInSeconds(time) / 60);
}

function leftTimeInHours(time: Long): number {
  return Math.floor(leftTimeInMinutes(time) / 60);
}

function leftTimeInDays(time: Long): number {
  return Math.floor(leftTimeInHours(time) / 24);
}

function inviteTimeTitle(time?: Long | null): string {
  if (!time) {
    return '';
  }

  if (leftTimeInDays(time) === 1) {
    return i18n.t('settings_workspace_members_invited_day_ago', {day: leftTimeInDays(time)});
  } else if (leftTimeInDays(time) > 1) {
    return i18n.t('settings_workspace_members_invited_days_ago', {days: leftTimeInDays(time)});
  } else if (leftTimeInHours(time) === 1) {
    return i18n.t('settings_workspace_members_invited_hour_ago', {hour: leftTimeInHours(time)});
  } else if (leftTimeInHours(time) > 1) {
    return i18n.t('settings_workspace_members_invited_hours_ago', {hours: leftTimeInHours(time)});
  } else if (leftTimeInMinutes(time) === 1) {
    return i18n.t('settings_workspace_members_invited_minute_ago', {minute: leftTimeInMinutes(time)});
  } else if (leftTimeInMinutes(time) > 1) {
    return i18n.t('settings_workspace_members_invited_minutes_ago', {minutes: leftTimeInMinutes(time)});
  }

  return i18n.t('settings_workspace_members_invited_seconds_ago', {seconds: leftTimeInSeconds(time)});
}

export const MemberInvitationTime = observer((props: {className?: string; member?: Invite | null}) => {
  const {t} = useTranslation();
  if (!props.member?.inviteUrl) {
    return null;
  }

  if (props.member.expired) {
    return (
      <span className={cn('body2-regular color-gray-500', props.className)}>
        {t('settings_workspace_members_invite_period_expired')}
      </span>
    );
  }

  return (
    <span className={cn('body2-regular color-gray-500', props.className)}>
      {inviteTimeTitle(props.member.createdAt)}
    </span>
  );
});

export default MemberInvitationTime;
