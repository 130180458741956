import {observer} from 'mobx-react';
import React from 'react';

import CustomScrollbar from 'o-ui/CustomScrollbar';
import VariableSizeList, {IVariableSizeList, ListOnScrollProps} from 'o-ui/VirtualizedList';
import AutoSizer from '../../../../components/AutoSizer';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';
import {useStore} from '../../../../stores/AppStore';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import {Chat} from '../../../../stores/Chat';
import AttachedFilesRow from './AttachedFilesRow';
import AttachedFilesSkeleton from './AttachedFilesSkeleton';

interface AttachedFilesProps {
  attachments?: MessageAttachment[];
  chat: Chat;
}

interface AttachedFilesListProps extends AttachedFilesProps {
  width: number;
  height: number;
}

export const AttachedFilesList: React.FC<AttachedFilesListProps> = observer(({width, height, attachments, chat}) => {
  const {layOutStore} = useStore();

  const listRef = React.useRef<IVariableSizeList>(null);
  const outerRef = React.useRef<HTMLDivElement>(null);
  const listInnerRef = React.useRef<HTMLDivElement>(null);

  const sizeMap = React.useRef({});
  const setSize = React.useCallback((index, size) => {
    sizeMap.current = {...sizeMap.current, [index]: size};
    listRef.current?.resetAfterIndex(index);
  }, []);

  const getItemSize = (index: number): number => {
    return sizeMap.current[index] || width / 3;
  };

  React.useEffect(() => {
    chat.store?.filesHistory?.load();

    return () => {
      layOutStore.rightSideBar.setChatInfoBarGalleryItemsDisplayAllow(false);
    };
  }, [chat.store?.filesHistory, layOutStore]);

  const handleScrolledToBottom = () => {
    if (height !== 0) {
      chat.store?.filesHistory?.loadNextPage();
    }
  };

  const handleScrollList = (params: ListOnScrollProps) => {
    scrollCallback(params);
  };

  const scrollCallback = useDebouncedCallback((params: ListOnScrollProps) => {
    if (params.scrollUpdateWasRequested) {
      return;
    }

    const scrollHeight = listInnerRef.current?.clientHeight || 0;
    if (scrollHeight - params.scrollOffset - height === 0) {
      handleScrolledToBottom();
    }
  }, 400);

  return (
    <AttachedFilesSkeleton attachmentType={chat.store?.filesHistory?.type}>
      <VariableSizeList
        ref={listRef}
        innerRef={listInnerRef}
        outerRef={outerRef}
        className="chat-custom-scroll"
        width={width}
        height={height}
        itemCount={attachments?.length || 0}
        itemSize={getItemSize}
        outerElementType={CustomScrollbar}
        onScroll={handleScrollList}
      >
        {(rowProps) => <AttachedFilesRow rowProps={rowProps} setSize={setSize} items={attachments || []} />}
      </VariableSizeList>
    </AttachedFilesSkeleton>
  );
});

export const AttachedFiles = (props: AttachedFilesProps) => {
  return <AutoSizer>{({width, height}) => <AttachedFilesList {...props} width={width} height={height} />}</AutoSizer>;
};

export default AttachedFiles;
