import Long from 'long';

import RawMessagesStore from './RawMessagesStore';
import WorkspaceStore from '../Workspaces/WorkspaceStore';

interface IMessagesPool {
  getMessagesStore: (channelID: Uint8Array, chatID: Long) => RawMessagesStore;
}

export default class MessagesPool implements IMessagesPool {

  constructor(protected workspace: WorkspaceStore) {
  }
  
  private messagesStores_: {[chatId: string]: RawMessagesStore} = {};
  
  public getMessagesStore = (channelID: Uint8Array, chatID: Long, lastMessageId?: Long | null): RawMessagesStore => {
    if (!this.messagesStores_[chatID.toString()]) {
      this.messagesStores_[chatID.toString()] = new RawMessagesStore(
        this.workspace,
        channelID,
        chatID,
        lastMessageId,
      );
    }

    return this.messagesStores_[chatID.toString()];
  };

  public reset = () => {
    this.messagesStores_ = {};
  };
}
