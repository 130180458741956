import Long from 'long';

import WorkspaceStore from '../Workspaces/WorkspaceStore';
import RawChatHistory from './RawChatHistory';
import {MCAttachment} from '../../api/proto';

interface IChatsHistoryPool {
  getChatHistoryStore: (channelID: Uint8Array, chatID: Long, type: MCAttachment.Type) => RawChatHistory;
}

export default class ChatsHistoryPool implements IChatsHistoryPool {
  constructor(protected workspace: WorkspaceStore) {}

  private chatHistoryStores_: {[chatID: string]: Map<MCAttachment.Type, RawChatHistory>} = {};

  public getChatHistoryStore = (channelID: Uint8Array, chatID: Long, type: MCAttachment.Type): RawChatHistory => {
    let _rawChatHistory: RawChatHistory | null = null;

    if (!this.chatHistoryStores_[chatID.toString()]) {
      this.chatHistoryStores_[chatID.toString()] = new Map();
    }

    if (this.chatHistoryStores_[chatID.toString()].has(type)) {
      _rawChatHistory = this.chatHistoryStores_[chatID.toString()].get(type) as RawChatHistory;
    } else {
      _rawChatHistory = new RawChatHistory(this.workspace, type, channelID, chatID);
      this.chatHistoryStores_[chatID.toString()].set(type, _rawChatHistory);
    }

    return _rawChatHistory;
  };

  protected resetChatHistoryStores_ = (chatIDStr: string) => {
    this.chatHistoryStores_[chatIDStr].forEach((store) => {
      store.reset();
    });

    delete this.chatHistoryStores_[chatIDStr];
  };

  public reset = (chatID?: Long | null) => {
    if (chatID) {
      this.resetChatHistoryStores_(chatID.toString());
      return;
    }

    for (const chatIDStr in this.chatHistoryStores_) {
      this.resetChatHistoryStores_(chatIDStr);
    }
    this.chatHistoryStores_ = {};
  };
}
