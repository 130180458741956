import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';

import {CHAT_MESSAGES_PAGE_SIZE} from '../../constants';
import {IMCMessage, MCAttachment} from '../../api/proto';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';
import MessageAttachment from '../Attachment/MessageAttachment';
import Message from '../Message';
import {RawChatHistory, RawChatHistoryData} from '../RawChatHistory';
import RawChatHistoryEvent from '../RawChatHistory/RawChatHistoryEvent';
import Chat from './Chat';
import {getChatAttachmentsCount} from './utils';

export class ChatHistory extends APILayer {
  @observable rawChatHistory_: RawChatHistory;

  constructor(public type: MCAttachment.Type, public app: AppStore, public chat: Chat) {
    super(app);
    makeObservable(this);

    this.rawChatHistory_ = this.chat.channel.workspace.chatsHistoryPool.getChatHistoryStore(
      this.chat.channelID,
      this.chat.id,
      this.type,
    );
  }

  @observable public attachmentsCount: number = 0;

  @action protected setAttachmentsCount_ = (count: number) => {
    this.attachmentsCount = count;
  };

  @action protected incrementAttachmentsCount_ = (count: number) => {
    this.attachmentsCount += count;
  };

  private isInit_ = false;

  private setIsInit_ = (isInit: boolean) => {
    this.isInit_ = isInit;
  };

  public init = () => {
    if (!this.isInit_) {
      console.debug(`%c----->ChatHistory->init ${this.chat.id.toString()} ${this.type.toString()}`, 'color: #afb0b6');
      this.rawChatHistory_.on(RawChatHistoryEvent.LOAD_HISTORY, this.onLoadChatHistory_);
      this.rawChatHistory_.on(RawChatHistoryEvent.ADD_NEW_MESSAGE, this.onAddNewMessage_);
      this.rawChatHistory_.on(RawChatHistoryEvent.UPDATE_MESSAGE, this.onUpdateMessage_);

      this.incrementAttachmentsCount_(getChatAttachmentsCount(this.chat, this.type));
      this.setIsInit_(true);
    }
  };

  public reinit = () => {
    this.setAttachmentsCount_(getChatAttachmentsCount(this.chat, this.type));
  };

  private isLoaded_ = false;

  private setIsLoaded_ = (isLoaded: boolean) => {
    this.isLoaded_ = isLoaded;
  };

  public load = () => {
    this.init();
    if (!this.isLoaded_) {
      this.loadNextPage();
      this.setIsLoaded_(true);
    }
  };

  @observable protected messages_: Message[] = [];

  @computed public get attachments(): MessageAttachment[] {
    const attachments_: MessageAttachment[] = [];

    this.messages_.forEach((message) => {
      message.attachments?.forEach((attach) => {
        if (attach.type === this.type) {
          attachments_.push(attach);
        }
      });
    }, []);

    return attachments_;
  }

  @action private addMessage_ = (rawMessage: IMCMessage, unshift?: boolean) => {
    const message_ = new Message(rawMessage, this.chat);

    if (unshift) {
      this.messages_.unshift(message_);
    } else {
      this.messages_.push(message_);
    }
  };

  @action private setMessages_ = (rawMessages: IMCMessage[]) => {
    this.messages_ = [];

    rawMessages.forEach((rawMessage) => {
      this.addMessage_(rawMessage);
    });
  };

  @action private addMessages_ = (rawMessages: IMCMessage[]) => {
    rawMessages.forEach((rawMessage) => {
      this.addMessage_(rawMessage);
    });

    this.messages_ = this.messages_.slice();
  };

  @action private unshiftMessages_ = (rawMessages: IMCMessage[]) => {
    rawMessages.forEach((rawMessage) => {
      this.addMessage_(rawMessage, true);
    });

    this.messages_ = this.messages_.slice();
  };

  public loadNextPage = async () => {
    if (this.loading) {
      return;
    }

    this.setLoading(true);

    const fromMessageID = this.messages_.length ? this.messages_[this.messages_.length - 1].id : null;
    console.debug(`%c----->ChatHistory->loadNextPage fromMessageID=${fromMessageID?.toString()} chat=${this.chat.id.toString()} ${this.type.toString()}`, 'color: #afb0b6');

    await this.rawChatHistory_?.load({
      fromMessageID,
      limit: Long.fromNumber(CHAT_MESSAGES_PAGE_SIZE),
      attachmentType: this.type,
    });

    this.setLoading(false);
  };

  protected onLoadChatHistory_ = ({rawMessages}: RawChatHistoryData) => {
    if (this.isLoaded_) {
      if (rawMessages.length) {
        this.addMessages_(rawMessages);
      }
    } else {
      this.setMessages_(rawMessages);
    }
  };

  protected onAddNewMessage_ = (rawNewMessage: IMCMessage) => {
    this.unshiftMessages_([rawNewMessage]);

    const newAttachments = rawNewMessage?.attachments?.filter((attach) => attach.type === this.type);

    if (newAttachments) {
      this.incrementAttachmentsCount_(newAttachments.length);
    }
  };

  protected onUpdateMessage_ = (rawMessage: IMCMessage) => {
    let message: Message | null | undefined;

    if (!message) {
      message = this.findMessage_(rawMessage.localID);
    }

    if (!message) {
      this.unshiftMessages_([rawMessage]);
    } else {
      message.update(rawMessage);
    }
  };

  private findMessage_ = (localID?: Long | null): Message | null => {
    return localID ? this.messages_.find((msg) => msg.localID?.equals(localID)) || null : null;
  };

  @action public reset = () => {
    console.debug(`%c----->ChatHistory->reset chat=${this.chat.id.toString()} ${this.type.toString()}`, 'color: #afb0b6');
    this.messages_ = [];
    this.isInit_ = false;
    this.isLoaded_ = false;
    this.attachmentsCount = 0;

    this.rawChatHistory_.off(RawChatHistoryEvent.LOAD_HISTORY, this.onLoadChatHistory_);
    this.rawChatHistory_.off(RawChatHistoryEvent.ADD_NEW_MESSAGE, this.onAddNewMessage_);
    this.rawChatHistory_.off(RawChatHistoryEvent.UPDATE_MESSAGE, this.onUpdateMessage_);
  };
}

export default ChatHistory;
