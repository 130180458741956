import React from 'react';
import useStore from '../stores/useStore';
import getChannelTypesList, {ChannelTypesOptions} from '../stores/Channel/utils/getChannelTypesList';

export default function useChannelTypesList() {
  const {features} = useStore();
  const [channelTypesList, setChannelTypesList] = React.useState<ChannelTypesOptions[]>(getChannelTypesList(features));

  React.useEffect(() => {
    setChannelTypesList(getChannelTypesList(features));
  }, [
    features,
  ]);

  return channelTypesList;
}