import React from 'react';
import {observer} from 'mobx-react';

import use100vh from './use100vh';

const Div100vh = observer(
  React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({style, ...other}, ref) => {
    const height = use100vh();

    const styleWithRealHeight = {
      ...style,
      height: height ? `${height}px` : '100vh',
    };
    return <div ref={ref} style={styleWithRealHeight} {...other} />;
  }),
);

export default Div100vh;
