import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import Button from 'o-ui/Button';
import successTickImg from '../../assets/images/email-confirmed-bg.png';
import logotypeWhiteImg from '../../assets/images/logotype-white.svg';
import logotypeImg from '../../assets/images/logotype.svg';
import Div100vh from '../../components/Div100vh';
import {BRAND_NAME} from '../../config';
import {Paths} from '../../routes/Paths';
import useStore from '../../stores/useStore';

const EmailVerificationCompletePage = () => {
  const {t} = useTranslation();
  const {lightThemeChosen} = useStore();

  return (
    <Div100vh className="auth-page">
      <div className="auth-page__header">
        <img className="auth-page__header-logo" src={lightThemeChosen ? logotypeImg : logotypeWhiteImg} alt="" />
      </div>

      <div className="auth-page__content">
        <img className="auth-page__central-info-bg-img" src={successTickImg} alt="" />
        <div className="auth-page__central-info">
          <div className="auth-page__central-info-title">{t('email_verify_complete_title')}</div>
          <div className="auth-page__central-info-sub-title">{t('email_verify_complete_subtitle', {brandName: BRAND_NAME})}</div>

          <Button className="mt-6" component={Link} to={Paths.Login} variant="contained" color="primary" size="large">
            {t('email_verify_complete_to_continue')}
          </Button>
        </div>
      </div>
    </Div100vh>
  );
};

export default EmailVerificationCompletePage;