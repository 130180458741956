import {action, makeObservable, observable} from 'mobx';

import {
  Network,
  NetworkResponse,
  RequestOptions,
  ResponseType,
} from '../api/network';
import {APIResponse, IAPIRequest} from '../api/proto';
import {AppStore} from '../stores/AppStore';
import {getCacheData, setCacheData} from '../utils/cache';

export interface APIRequestOptions extends RequestOptions {
  expiration?: number | null;
}

export class APILayer {
  get api(): Network {
    return this.app.api;
  }

  constructor(public app: AppStore) {
    makeObservable(this);
  }

  @observable loading = false;

  @action setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  request = async <T extends ResponseType = any>(
    data: IAPIRequest,
    responseType: keyof APIResponse | null = null,
    {
      expiration,
      ...options
    }: APIRequestOptions = {}
  ) => {
    let cacheKey: string = responseType || '';
    try {
      cacheKey = JSON.stringify(data);
    } catch (e) {
      console.log(e);
    }

    if (expiration && cacheKey) {
      const cacheData = getCacheData(cacheKey, expiration);
      if (cacheData) {
        return cacheData as NetworkResponse<T, APIResponse.Status>;
      }
    }

    this.setLoading(true);
    const response = await this.api.request<T>(
      data,
      responseType,
      options,
    );
    this.setLoading(false);

    if (expiration && cacheKey) {
      setCacheData(cacheKey, response);
    }

    return response;
  };
}

export default APILayer;
