import cn from 'classnames';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink} from 'react-router-dom';
import * as Yup from 'yup';

import {Checkbox} from 'o-ui/Checkbox';
import FormHelperText from 'o-ui/FormHelperText';
import InputAdornment from 'o-ui/Input/InputAdornment';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import VisibilitySwitcher from 'o-ui/Input/VisibilitySwitcher';

import {entities} from '../../api/proto';
import ButtonWithTimer from '../../components/ButtonWithTimer';
import SnackMessage from '../../components/SnackMessage';
import {BRAND_NAME} from '../../config';
import {Paths} from '../../routes/Paths';
import useStore from '../../stores/useStore';
import {formatDuration} from '../../utils/format';

type FormValues = {
  email: string;
  password: string;
};

export enum LoginFormNotification {
  USE_NEW_PASSWORD = 1,
  EMAILED_TO_RESET_PASSWORD = 2,
}

interface LoginFormProps {
  className?: string;
  notificationType?: LoginFormNotification;
  email?: string;
  hideSignUpLink?: boolean;
  hideForgoPasswordtLink?: boolean;
  location?: Location;
}

export const LoginForm = observer((props: LoginFormProps) => {
  const {t} = useTranslation();
  const {
    userStore,
    notifications,
    activeWorkspace: {invites},
  } = useStore();

  const [apiError, setApiError] = React.useState<string | null | undefined>('');
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [isRememberMe, setIsRememberMe] = React.useState<boolean>(false);

  const [responseStatus, setResponseStatus] = React.useState<entities.UserLoginResult | undefined | null>(null);

  const [notificationType, setNotificationType] = React.useState<LoginFormNotification | null>(
    props?.notificationType || null,
  );

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickRememberMe = () => {
    setIsRememberMe(!isRememberMe);
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      email: props?.email || invites.registrationInvite?.email || '',
      password: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string().required(t('login_email_required')),
      password: Yup.string().required(t('login_password_required')),
    }),
    onSubmit: async (values) => {
      setApiError('');
      setResponseStatus(null);
      setNotificationType(null);

      const {error, res} = await userStore.login(values, props.location);

      if (error) {
        notifications.error(error.message);
        setApiError(error.message);
      }

      if (res) {
        setResponseStatus(res.status);

        if (res.status !== entities.UserLoginResult.LS_OK) {
          return;
        }
      }

      setNotificationType(null);
    },
  });

  const handleLoginAndSkipInvite = () => {
    invites.clearRegistrationInvite();
  };

  return (
    <form className={cn('auth-page__form', props.className)} onSubmit={formik.handleSubmit}>
      {notificationType === LoginFormNotification.EMAILED_TO_RESET_PASSWORD ? (
        <SnackMessage
          className="mb-6"
          message={t('reset_password_success_title')}
          subMessage={t('login_emailed_new_password_instruction_notification')}
          variant="success"
          hideCloseIcon
        />
      ) : null}

      {notificationType === LoginFormNotification.USE_NEW_PASSWORD ? (
        <SnackMessage
          className="mb-6"
          message={t('reset_password_success_title')}
          subMessage={t('login_use_new_password_notification')}
          variant="success"
          hideCloseIcon
        />
      ) : null}

      {userStore.loginUserTimer.inProgress ? (
        <SnackMessage
          className="mb-6"
          message={t('notification_too_many_request_message')}
          subMessage={t('notification_too_many_request_sub_message', {
            time: formatDuration(userStore.loginUserTimer.currentTime * 1000),
          })}
          variant="error"
          hideCloseIcon
        />
      ) : null}

      {!userStore.loginUserTimer.inProgress && responseStatus === entities.UserLoginResult.LS_INVALID_CREDENTIALS ? (
        <SnackMessage
          className="mb-6"
          message={t('login_invalid_credentials_message')}
          subMessage={t('login_invalid_credentials_sub_message')}
          variant="error"
          hideCloseIcon
        />
      ) : null}

      <OutlinedTextInput
        className="w-100"
        name="email"
        placeholder={t('login_email_placeholder')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={!!(formik.touched.email && formik.errors.email)}
        errorHelper={formik.errors.email || ' '}
        disabled={!!invites.registrationInvite?.email}
        required
      />

      <OutlinedTextInput
        className="w-100"
        name="password"
        placeholder={t('login_password_placeholder')}
        type={showPassword ? 'text' : 'password'}
        error={!!(formik.touched.password && formik.errors.password)}
        errorHelper={formik.errors.password || ''}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        required
        endAdornment={
          <InputAdornment position="end">
            <VisibilitySwitcher onClick={handleClickShowPassword} visible={showPassword} />
          </InputAdornment>
        }
      />

      {!props.hideForgoPasswordtLink && (
        <div className="auth-page__forget-password">
          <RouterLink to={Paths.ForgotPassword} className="auth-page__forget-password-link">
            {t('login_form_forgot_password')}
          </RouterLink>
        </div>
      )}

      <div>
        <div className="my-5 d-flex">
          <Checkbox checked={isRememberMe} onChange={handleClickRememberMe} label={t('login_form_remember_me')} />
        </div>
      </div>

      {invites.registrationInvite ? (
        <>
          <ButtonWithTimer
            className="w-100 mb-4"
            variant="contained"
            color="secondary"
            size="large"
            loading={userStore.loading}
            timer={userStore.loginUserTimer}
            onClick={handleLoginAndSkipInvite}
          >
            {t('login_form_login_and_skip_invite')}
          </ButtonWithTimer>
          <ButtonWithTimer
            className="w-100"
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            loading={userStore.loading}
            timer={userStore.loginUserTimer}
          >
            {t('login_form_login_and_accept_invite')}
          </ButtonWithTimer>
        </>
      ) : (
        <ButtonWithTimer
          className="w-100"
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          loading={userStore.loading}
          timer={userStore.loginUserTimer}
        >
          {t('login_form_login')}
        </ButtonWithTimer>
      )}

      {!invites.registrationInvite?.email && !props.hideSignUpLink ? (
        <div className="auth-page__join-link">
          {t('login_form_no_account')}
          <RouterLink to={Paths.Registration} className="auth-page__link">
            {t('login_form_join', {brandName: BRAND_NAME})}
          </RouterLink>
        </div>
      ) : null}

      <FormHelperText className="mt-3" error>
        {formik.errors.password || apiError || ' '}
      </FormHelperText>
    </form>
  );
});

export default LoginForm;
