import cn from 'classnames';
import React from 'react';

import {DEFAULT_MEDIA_PREVIEW_HEIGHT, DEFAULT_MEDIA_PREVIEW_WIDTH} from '../../../../constants';
import PreviewImage from '../../../../components/PreviewImage';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import ModalType from '../../../../stores/ModalType';
import useStore from '../../../../stores/useStore';
import {uint8ArrayToUuid} from '../../../../utils/arrayUtils';
import useMessageAttachment from '../MessageContent/useMessageAttachment';

export interface MediaGalleryVideoProps {
  className?: string;
  attachment: MessageAttachment;
  autoSize?: boolean;
  playButtonSmall?: boolean;
  playButton?: boolean;
  style?: React.CSSProperties;
  isScrolling?: boolean;
}

export const MediaGalleryVideo = (props: MediaGalleryVideoProps) => {
  const {modals} = useStore();

  const {message, messagePreview} = props.attachment;

  const [inView, setInView] = React.useState<boolean>(false);

  const {src, width, height} = useMessageAttachment(messagePreview, inView && !props.isScrolling);

  const openLightbox = () => {
    modals.open(
      ModalType.MEDIA_LIGHT_BOX,
      {
        attachment: props.attachment,
      },
      false,
      true,
    );
  };

  return (
    <PreviewImage
      className={cn(
        'cursor-pointer',
        props.className,
        uint8ArrayToUuid(props.attachment.id),
      )}
      src={src}
      width={width || DEFAULT_MEDIA_PREVIEW_WIDTH}
      height={height || DEFAULT_MEDIA_PREVIEW_HEIGHT}
      fixedSize={true}
      isScrolling={props.isScrolling}
      onClick={message.isDelivered ? openLightbox : undefined}
      onInViewChange={setInView}
      playButton={props.playButton}
      style={props.style}
      blueThumbnail={true}
    />
  );
};

export default MediaGalleryVideo;