import React from 'react';

import TableCell from 'o-ui/TableCell';
import TableRow from 'o-ui/TableRow';

import {observer} from 'mobx-react';
import {entities} from '../../../../api/proto';
import Channel from '../../../../stores/Channel';
import {uint8ArrayToUuid} from '../../../../utils/arrayUtils';
import {formatPhoneNumber} from '../../../../utils/getPhoneMask';
import ChannelStatus from '../ChannelStatus';

interface ChannelsTableRowProps {
  channel: Channel;
  onClick?: (channel: Channel) => void;
}

export const ChannelsTableRow: React.FC<ChannelsTableRowProps> = observer((props) => {
  const handleManageClick = () => {
    props.onClick?.(props.channel);
  };

  if (props.channel.type === entities.OzekonChannelType.OIT_WEB_WIDGET) {
    return (
      <TableRow
        className="cursor-pointer"
        data-id={uint8ArrayToUuid(props.channel.id)}
        onClick={handleManageClick}
        hover={true}
      >
        <TableCell className="channel-name">{props.channel.name || '-'}</TableCell>
        <TableCell>{props.channel.webWidgetConfig?.domain?.toString()}</TableCell>
        <TableCell>
          <ChannelStatus channel={props.channel} />
        </TableCell>
        <TableCell>{'-'}</TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow
      className="cursor-pointer"
      data-id={uint8ArrayToUuid(props.channel.id)}
      onClick={handleManageClick}
      hover={true}
    >
      <TableCell component="th" scope="row">
        {formatPhoneNumber(props.channel.phone) || '-'}
      </TableCell>
      <TableCell className="channel-name">{props.channel.name || '-'}</TableCell>
      <TableCell>{props.channel.userName || '-'}</TableCell>
      <TableCell>
        <ChannelStatus channel={props.channel} />
      </TableCell>
      <TableCell>{'-'}</TableCell>
      <TableCell>{'-'}</TableCell>
    </TableRow>
  );
});

export default ChannelsTableRow;
