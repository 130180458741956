import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import {entities} from '../../../../api/proto';
import ChannelIcon from '../../../../components/ChannelIcon';
import getChannelTypeName from '../../../../stores/Channel/utils/getChannelTypeName';
import useStore from '../../../../stores/useStore';

import {ReactComponent as PlusIcon} from '../../../../assets/icons/plus-line.svg';

interface ChannelTypesListItemProps {
  type: entities.OzekonChannelType;
  onClick?: (type: entities.OzekonChannelType) => void;
  onAddClick?: (type: entities.OzekonChannelType) => void;
  disabled?: boolean;
}

export const ChannelTypesListItem: React.FC<ChannelTypesListItemProps> = observer(({
  type,
  onClick,
  onAddClick,
  disabled,
}) => {
  const {t} = useTranslation();
  const {channels} = useStore();

  const getChannelsStatus = (count: number) => {
    if (count === 1) {
      return t('settings_channels_connected_channel', {count});
    } else if (count > 1) {
      return t('settings_channels_connected_channels', {count});
    }
    return t('settings_channels_no_channels');
  };

  const handleAddTgAccountClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onAddClick?.(type);
  };

  const handleClick = () => !disabled && onClick?.(type);

  return (
    <div
      className={cn('channel-types-list__item', {disabled})}
      onClick={handleClick}
    >
      <div className="channel-types-list__item__left">
        <ChannelIcon className="channel-types-list__icon" type={type} />
        <div className="channel-types-list__name body1-regular">{getChannelTypeName(type)}</div>
        <div className="channel-types-list__status body2-regular">
          {disabled ? t('settings_channels_not_available_yet') : getChannelsStatus(channels.getChannels(type).length)}
        </div>
      </div>
      <div className="channel-types-list__action">
        {disabled ? null : (
          <Button onClick={handleAddTgAccountClick} variant="contained" color="primary">
            <PlusIcon className="mr-3" width="12" />
            {t('settings_channels_add_channel_btn')}
          </Button>
        )}
      </div>
    </div>
  );
});

export default ChannelTypesListItem;