import Long from 'long';

import {IUpdate} from '../../api/proto';
import {ChannelsUpdaterEvent} from '../Channel/ChannelsUpdater';
import WorkspaceStore from '../Workspaces/WorkspaceStore';
import {RawMessage} from './IRawMessagesStore';
import RawMessagesBase from './RawMessagesBase';

export class RawMessagesStore extends RawMessagesBase {
  constructor(
    protected workspace: WorkspaceStore,
    protected channelID: Uint8Array,
    protected chatID: Long,
    protected lastMessageId?: Long | null,
  ) {
    super(workspace, channelID, chatID, lastMessageId);

    workspace.updater.on(ChannelsUpdaterEvent.UPDATE, this.processNewUpdate_);
  }

  private processNewUpdate_ = (update: IUpdate) => {
    if (update.updateNewMessage?.message) {
      this.processMessage_(update.updateNewMessage.message, update.updateNewMessage.randomID);
    }

    if (update.updateEditedMessage?.message) {
      this.processMessage_(update.updateEditedMessage.message);
    }
  };

  private processMessage_ = (rawMessage: RawMessage, randomID?: Long | null) => {
    if (!this.chatID || !rawMessage.chatID?.equals(this.chatID) || !rawMessage.localID) {
      return;
    }

    console.debug(`%c-->RawMessagesStore.processMessage_ - ${this.chatID}`, 'color: #afb0b6', rawMessage, randomID);

    if (this.hasByRandomID(randomID) || (this.has(rawMessage.localID) && !this.isSending(rawMessage.localID))) {
      this.updateMessage_(rawMessage, randomID);
    } else {
      this.insertMessage_(rawMessage, randomID);
    }
  };

  private insertMessage_ = (rawMessage: RawMessage, randomID?: Long | null) => {
    let sending = this.sendingMessages.slice();

    if (sending.length) {
      sending.forEach((msg) => {
        this.removeMessage(msg.localID);
      });

      sending = sending.map((msg, idx) => ({...msg, localID: rawMessage.localID?.add(idx + 1)}));

      sending.unshift({...rawMessage, randomID});

      sending.forEach((_rawMessage) => {
        if (!this.hasByRandomID(_rawMessage?.randomID)) {
          this.addMessage_(_rawMessage, _rawMessage.randomID);
        }
      });
    } else {
      this.addMessage_(rawMessage);
    }
  };
}

export default RawMessagesStore;
