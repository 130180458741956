import React from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../stores/AppStore';


export default observer(function VersionBadge(props: React.HTMLAttributes<HTMLSpanElement>) {
  const {externalApp: {desktopAppVersion}} = useStore();

  let versionStr = '';
  if (desktopAppVersion && process.env.REACT_APP_VERSION) {
    versionStr = `${desktopAppVersion}/${process.env.REACT_APP_VERSION}`;
  } else if (desktopAppVersion) {
    versionStr = desktopAppVersion;
  } else if (process.env.REACT_APP_VERSION) {
    versionStr = process.env.REACT_APP_VERSION;
  }

  return versionStr ? (
    <span {...props}>{versionStr}</span>
  ) : null;
});
