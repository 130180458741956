import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';

import Avatar from 'o-ui/Avatar';
import WorkspaceMember from '../../../../stores/Workspaces/WorkspaceMember';
import useCompactMode from '../../../../hooks/useCompactMode';

interface MemberProfileInfoProps {
  className?: string;
  member: WorkspaceMember;
}

export const MemberProfileInfo = observer((props: MemberProfileInfoProps) => {
  const compactMode = useCompactMode();
  return (
    <div className={cn('chat-profile-info', props.className)}>
      <Avatar
        size={compactMode ? 'md' : 'lg'}
        className="chat-profile-info__avatar"
        name={props.member.displayName}
      />

      <div className="chat-profile-info__body">
        <div className="chat-profile-info__title">{props.member.displayName}</div>
      </div>
    </div>
  );
});

export default MemberProfileInfo;
