import React from 'react';

import useContextMenuHandler from '../../../../hooks/useContextMenuHandler';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import ContextMenuType from '../../../../stores/ContextMenuType';
import {useStore} from '../../../../stores/useStore';
import AnimationContent from '../MessageContent/AnimationContent';
import ImageContent from '../MessageContent/ImageContent';
import MediaGalleryVideo from './MediaGalleryVideo';

interface MediaGalleryItemProps {
  attachment: MessageAttachment;
  isScrolling?: boolean;
}

export const MediaGalleryItem = React.memo(({attachment, isScrolling}: MediaGalleryItemProps) => {
  const {contextMenu} = useStore();
  const message = attachment.message;

  const contextMenuHandlers = useContextMenuHandler((e: React.MouseEvent, attachment: MessageAttachment) => {
    e.stopPropagation();
    e.preventDefault();

    contextMenu.open({clientX: e.clientX, clientY: e.clientY}, ContextMenuType.FILE, {
      attachment,
    });
  });

  return (
    <div
      key={message.id.toNumber()}
      className="media-gallery__item"
      onContextMenu={(e) => contextMenuHandlers.onContextMenu(e, attachment)}
      onTouchCancel={contextMenuHandlers.onTouchCancel}
      onTouchEnd={contextMenuHandlers.onTouchEnd}
      onTouchMove={contextMenuHandlers.onTouchMove}
      onTouchStart={(e) => contextMenuHandlers.onTouchStart(e, attachment)}
    >
      {attachment.isImage ? <ImageContent className="media-gallery__img" attachment={attachment} /> : null}
      {attachment.isVideo && (
        <MediaGalleryVideo
          className="media-gallery__img"
          attachment={attachment}
          autoSize={true}
          playButtonSmall={true}
          playButton={true}
          isScrolling={isScrolling}
        />
      )}
      {/* {message.videoNoteInfo ? <VideoNoteContent videoNoteInfo={message.videoNoteInfo} /> : null} */}
      {attachment.isGif && (
        <AnimationContent className="media-gallery__img" attachment={attachment} />
      )}
    </div>
  );
});

export default MediaGalleryItem;