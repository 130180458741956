import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {MenuList} from 'o-ui';
import Button from 'o-ui/Button';
import {ReactComponent as LogoutIcon} from '../../../../assets/image-icons/logout.svg';
import useWorkspaceNavigate from '../../../../hooks/useWorkspaceNavigate';
import Paths, {WorkspacePathType} from '../../../../routes/Paths';
import {useStore} from '../../../../stores/AppStore';
import DownloadAppButton from '../../../Settings/DownloadAppButton';
import NightModeSwitcher from '../../../Settings/NightModeSwitcher';
import SettingsMenu from '../../../Settings/SettingsMenu/SettingsMenu';
import useTabHeight from '../useTabHeight';

export const SettingsMenuTab: React.FC<React.PropsWithChildren> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const workspaceNavigate = useWorkspaceNavigate();

  const tabHeight = useTabHeight();

  const {layOutStore, lightThemeChosen} = useStore();

  const handleTabClick = (path: WorkspacePathType) => {
    layOutStore.setShowSettingsTabContent(true);
    workspaceNavigate(path);
  };

  const handleLogout = () => {
    navigate(Paths.Logout);
  };

  return (
    <div className="settings-menu-tab" style={{height: tabHeight}}>
      <div className="settings-menu-tab__menu">
        <SettingsMenu onClick={handleTabClick} selectedTab={layOutStore.activeSettingsTab} />
      </div>

      <div className="settings-menu-tab__footer">
        <MenuList className="mb-2">
          <DownloadAppButton />
        </MenuList>

        <NightModeSwitcher className="w-100" checked={!lightThemeChosen} onChange={layOutStore.theme.toggleCurrentTheme} />

        <Button
          className="settings-page__logout w-100 mt-3 d-block d-sm-none"
          variant="contained"
          color="errorSecondary"
          onClick={handleLogout}
        >
          <LogoutIcon className="settings-page__logout-icon" />
          {t('Log out')}
        </Button>
      </div>
    </div>
  );
});

export default SettingsMenuTab;
