import React from 'react';
import cn from 'classnames';

import {IconButton} from 'o-ui/Button';
import {ITag} from '../../../../stores/MessageTagsStore';


interface FoundTagItemProps {
  className?: string;
  style?: React.CSSProperties;
  tag?: ITag | null;
  onClick?: (tag?: ITag | null) => void;
  onDeleteClick?: (tag?: ITag | null) => void;
}

export const FoundTagItem: React.FC<FoundTagItemProps> = (props) => {
  return (
    <div
      className={cn('chat-list__found-tag-item', props.className)}
      style={props.style}
      onClick={() => props?.onClick && props?.onClick(props.tag)}
    >
      <div className="chat-list__found-tag-item-inner" style={{backgroundColor: props.tag?.color || ''}}>
        <span className="chat-list__found-tag-item-title">{props.tag?.text}</span>
        {props.onDeleteClick ? (
          <IconButton
            className="chat-list__found-tag-item-delete tg-icon-cross-2-line"
            onClick={() => props?.onDeleteClick && props?.onDeleteClick(props.tag)}
            size="extraSmall"
          />
        ) : null}
      </div>
    </div>
  );
};

export default FoundTagItem;
