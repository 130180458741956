import {observer} from 'mobx-react-lite';
import React from 'react';
import useStore from '../stores/useStore';

import {ReactComponent as LoaderIcon} from '../assets/image-icons/appLoader.svg';

const AppLoader: React.FC<React.PropsWithChildren> = observer(({children}) => {
  const {workspaces, userStore} = useStore();

  if (workspaces.isInit || !userStore.isLoggedIn) {
    return <>{children}</>;
  }

  return (
    <div className="app-loader">
      <div className="app-loader__icon">
        <LoaderIcon />
      </div>
    </div>
  );
});

export default AppLoader;
