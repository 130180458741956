import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {animated} from 'react-spring';

import {entities} from '../../api/proto';
import {BRAND_NAME} from '../../config';
import Channel from '../../stores/Channel';
import getChannelTypeName from '../../stores/Channel/utils/getChannelTypeName';
import SettingsTab from '../../stores/LayOutStore/SettingsTab';
import ModalType from '../../stores/ModalType';
import useStore from '../../stores/useStore';
import ChannelTypesList from './Channels/ChannelTypesList';
import ChannelsViewer from './Channels/ChannelsViewer';
import ManagingChannelForm from './Channels/ManagingChannel';
import {SettingsHeadState} from './SettingsHead';
import useAllowChangeHeadState from './useAllowChangeHeadState';
import useSlideTransition from '../../hooks/useSlideTransition';


enum Pages {
  CHANNEL_TYPES = 0,
  CHANNELS = 1,
  CHANNELS_SETTINGS = 2,
}

const DEFAULT_PAGE = Pages.CHANNEL_TYPES;

function getChannelType(path: string): entities.OzekonChannelType | null {
  switch (path) {
    case 'telegram':
      return entities.OzekonChannelType.OIT_TELEGRAM;
    case 'instagram':
      return entities.OzekonChannelType.OIT_INSTAGRAM;
    case 'web-widget':
      return entities.OzekonChannelType.OIT_WEB_WIDGET;
    case 'telegram-bot':
      return entities.OzekonChannelType.OIT_TELEGRAM_BOT;
    default:
      return null;
  }
}

interface IProps {
  onChangeState?: (state: SettingsHeadState) => void;
}

export interface IChannelsTabRef {
  reset?: () => void;
}

export const ChannelsTab = observer(
  React.forwardRef<IChannelsTabRef, IProps>((props: IProps, ref) => {
    React.useImperativeHandle(ref, () => ({
      reset: () => {
        openPage(Pages.CHANNEL_TYPES, pageIndex);
      },
    }));

    const {t} = useTranslation();
    const {animationsEnabled, anal, modals, channels} = useStore();

    const {channelType = ''} = useParams();

    const initialChannelType = getChannelType(channelType);
    const [selectedChannelType, setSelectedChannelType] = React.useState<entities.OzekonChannelType | null>(initialChannelType);

    const openManaging = (channel: Channel) => {
      channels.setManagingChannel(channel);
      openPage(Pages.CHANNELS_SETTINGS, pageIndex);
    };

    const closeManaging = () => {
      channels.setManagingChannel(null);
      openPage(Pages.CHANNELS, pageIndex);
    };

    const [prevPageIndex, setPrevPageIndex] = React.useState<Pages>(DEFAULT_PAGE);
    const [pageIndex, setPageIndex] = React.useState<Pages>(channelType ? Pages.CHANNELS : DEFAULT_PAGE);
    const transitions = useSlideTransition(pageIndex, prevPageIndex);

    const openPage = React.useCallback((newPage: Pages, prevPage: Pages) => {
      setPrevPageIndex(prevPage);
      setPageIndex(newPage);
    }, []);

    const goBack = React.useCallback(
      (_pageIndex: Pages) => {
        switch (_pageIndex) {
          case Pages.CHANNELS:
            openPage(Pages.CHANNEL_TYPES, _pageIndex);
            break;
          case Pages.CHANNELS_SETTINGS:
            openPage(Pages.CHANNELS, _pageIndex);
            break;
        }
      },
      [openPage],
    );

    const getPageTitle = React.useCallback(
      (page: Pages, source: entities.OzekonChannelType | null) => {
        switch (page) {
          case Pages.CHANNEL_TYPES:
            return t('settings_menu_channels');
          case Pages.CHANNELS:
            return getChannelTypeName(source);
          case Pages.CHANNELS_SETTINGS:
            return t('settings_menu_channel_settings');
        }
      },
      [t],
    );

    const getPageLinks = React.useCallback(
      (page: Pages, source: entities.OzekonChannelType | null) => {
        switch (page) {
          case Pages.CHANNEL_TYPES:
            return [];
          case Pages.CHANNELS:
            return [t('settings_menu_channels')];
          case Pages.CHANNELS_SETTINGS:
            return [t('settings_menu_channels'), getChannelTypeName(source)];
        }
      },
      [t],
    );

    const allowChangeHeadState = useAllowChangeHeadState(SettingsTab.CHANNELS);

    React.useEffect(() => {
      if (!allowChangeHeadState) {
        return;
      }

      props.onChangeState?.({
        title: getPageTitle(pageIndex, selectedChannelType),
        links: getPageLinks(pageIndex, selectedChannelType),
        onBack: () => goBack(pageIndex),
      });
    }, [props, allowChangeHeadState, pageIndex, goBack, getPageTitle, getPageLinks, selectedChannelType]);

    const openChannelsTable = React.useCallback(
      (channelType: entities.OzekonChannelType) => {
        setSelectedChannelType(channelType);
        openPage(Pages.CHANNELS, Pages.CHANNEL_TYPES);
      },
      [openPage],
    );


    const openChannelCreationModal = React.useCallback(
      (channelType: entities.OzekonChannelType) => {
        anal.addChannelClick();
        modals.open(ModalType.CHANNEL_CREATION, {channelType});
      },
      [
        anal,
        modals,
      ],
    );

    const getPageContent = (page: Pages) => {
      switch (page) {
        case Pages.CHANNEL_TYPES:
          return (
            <div className="paper rounded-sm-0 p-6 custom-scroll-y">
              <div className="settings-tab__body-descr body2-regular color-body-primary mb-6">
                {t('settings_channels_types_list_title', {brandName: BRAND_NAME})}
              </div>

              <ChannelTypesList
                onClick={openChannelsTable}
                onAddClick={openChannelCreationModal}
              />
            </div>
          );
        case Pages.CHANNELS:
          return (
            <ChannelsViewer
              channelType={selectedChannelType}
              onChannelOpen={openManaging}
            />
          );

        case Pages.CHANNELS_SETTINGS:
          return channels.managingChannel ? (
            <ManagingChannelForm bodyClassName="px-6 py-6" channel={channels.managingChannel} onClose={closeManaging} />
          ) : null;
      }
    };

    return (
      <div
        className={cn('settings-tab', `page-${pageIndex}`, {'without-padding': pageIndex === Pages.CHANNELS_SETTINGS})}
      >
        <div className="settings-tab__body custom-scroll">
          {animationsEnabled
            ? transitions((styles, item) => (
              <animated.div key={item} style={styles} className="settings-tab__animated-tab">
                {getPageContent(item)}
              </animated.div>
            ))
            : getPageContent(pageIndex)}
        </div>
      </div>
    );
  }),
);

export default ChannelsTab;
