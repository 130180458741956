import cn from 'classnames';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import Button from 'o-ui/Button';
import FormHelperText from 'o-ui/FormHelperText';
import FormLabel from 'o-ui/FormLabel';
import Image from 'o-ui/Image';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import trimInput from 'o-ui/utils/trimInput';

import {WORKSPACE_NAME_LENGTH_LIMIT} from '../../../constants';
import {ReactComponent as WorkspaceLogoPlaceholder} from '../../../assets/image-icons/workspaceLogoPlaceholder.svg';
import AvatarPicker, {AvatarPickerRef} from '../../../components/FileUploader/AvatarPicker';
import {useStore} from '../../../stores/AppStore';
import {FileData} from '../../../utils/file/fileReaders';
import useAvatarAttachment from '../../Chat/useAvatarAttachment';

export interface IWorkspaceSettingsFormRef {
  submitForm?: () => void;
  resetForm?: () => void;
}

interface IProps {
  className?: string;
  onSubmit?: () => void;
  onChange?: (valid: boolean) => void;
  title?: string;
  subTitle?: string;
}

export const WorkspaceSettingsForm = observer(
  React.forwardRef<IWorkspaceSettingsFormRef, IProps>((props: IProps, ref) => {
    const {activeWorkspace, notifications} = useStore();
    const {t} = useTranslation();
    const refAvatarPicker = React.useRef<AvatarPickerRef | null>(null);

    const [dataFile, setDataFile] = React.useState<FileData | null | undefined>(null);
    const [avatarDeleted, setAvatarDeleted] = React.useState<boolean>(false);
    const [apiError, setApiError] = React.useState<string | null | undefined>('');

    const formik = useFormik<{
      name: string;
    }>({
      initialValues: {
        name: activeWorkspace.name || '',
      },
      validationSchema: Yup.object({
        name: Yup.string()
          .required(t('settings_workspace_name_required'))
          .max(
            WORKSPACE_NAME_LENGTH_LIMIT,
            t('settings_workspace_name_length_validation', {
              maxLength: WORKSPACE_NAME_LENGTH_LIMIT,
            }),
          ),
      }),
      validateOnBlur: true,
      onSubmit: async (values) => {
        setApiError('');

        const name = trimInput(values.name);
        if (!name) {
          return;
        }

        const {error, res} = await activeWorkspace.updateInfo(name, dataFile, avatarDeleted);

        if (error) {
          setApiError(error.message);
          notifications.error(error.message);
        }

        if (res) {
          notifications.success(t('settings_workspace_submit_success'));
          props.onSubmit?.();
        }
      },
    });

    React.useImperativeHandle(ref, () => ({
      submitForm: () => formik.submitForm(),
      resetForm: () => {
        formik.setFieldValue('name', activeWorkspace.name || '', false);
        setAvatarDeleted(false);
        setDataFile(null);
      },
    }));

    React.useEffect(() => {
      formik.setFieldValue('name', activeWorkspace.name || '', false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeWorkspace.name]);

    function validForm(newName?: string): boolean {
      const name = trimInput(typeof newName !== 'undefined' ? newName : formik.values.name);
      if (!name) {
        return false;
      }
      return true;
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.handleChange(e);
      props.onChange?.(validForm(e.target.value));
    };

    const handleUpload = () => {
      refAvatarPicker.current?.open?.();
    };

    const handleFileChange = (dataFiles: FileData[]) => {
      if (dataFiles.length) {
        setDataFile(dataFiles[0]);
        props.onChange?.(validForm());
        setAvatarDeleted(false);
      }
    };

    const {src} = useAvatarAttachment(activeWorkspace.avatar, activeWorkspace.id);

    const handleDeleteAvatar = () => {
      setAvatarDeleted(true);
      setDataFile(null);
      props.onChange?.(validForm());
    };

    return (
      <form
        className={cn(
          'personal-info-form',
          props.className,
        )}
        onSubmit={formik.handleSubmit}
      >
        {props.title ? <div className="h1-bold color-body-primary mt-auto mb-2">{props.title}</div> : null}
        {props.subTitle ? <div className="body1-regular color-body-primary mb-5">{props.subTitle}</div> : null}

        <div className="d-flex align-items-center">
          {dataFile?.objectUrl || (src && !avatarDeleted) ? (
            <Image
              style={{width: '80px', height: '80px'}}
              className="rounded-5 object-fit-cover"
              src={dataFile?.objectUrl || src}
              retryOnError={true}
              retryCount={3}
              loaderSize={80}
            />
          ) : (
            <WorkspaceLogoPlaceholder className="cursor-pointer" onClick={handleUpload} />
          )}
          <AvatarPicker
            className="d-none"
            ref={refAvatarPicker}
            onChange={handleFileChange}
          />
          <Button
            className="ml-4"
            variant="contained"
            color="primary"
            size="small"
            onClick={handleUpload}
          >
            {t('settings_workspace_avatar_upload')}
          </Button>
          {!dataFile && !src || avatarDeleted ? null : (
            <Button
              className="ml-3"
              variant="contained"
              color="error"
              size="small"
              onClick={handleDeleteAvatar}
            >
              {t('settings_workspace_avatar_delete')}
            </Button>
          )}
        </div>

        <FormLabel className="text-capitalize mt-6">{t('settings_workspace_name')}</FormLabel>
        <OutlinedTextInput
          className="w-100"
          name="name"
          placeholder={t('settings_workspace_name_placeholder')}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          required
          errorHelper={formik.errors.name || ' '}
        />

        <FormHelperText className="mt-2" error>
          {apiError || ' '}
        </FormHelperText>
      </form>
    );
  }),
);

export default WorkspaceSettingsForm;
