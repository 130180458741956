import React from 'react';
import {observer} from 'mobx-react';

import TextContentTree from '../../../UserChat/TextContent/TextContentTree';
import Message from '../../../../../stores/Message';


interface ChatLastMessageTextProps {
  className?: string;
  lastChatMessage?: Message | null;
}

export const ChatLastMessageText = observer(({className, lastChatMessage}: ChatLastMessageTextProps) => {
  return lastChatMessage?.text || lastChatMessage?.serviceMessageText ? (
    <span className={className}>
      <TextContentTree
        plainText={true}
        text={lastChatMessage?.text || lastChatMessage?.serviceMessageText}
        textEntities={lastChatMessage?.textEntities || []}
        startIndex={0}
        level={0}
      />
    </span>
  ) : null;
});

export default ChatLastMessageText;
