import React from 'react';
import useMountedState from 'o-ui/hooks/useMountedState';


type ASYNC_FN = (...args: any[]) => Promise<unknown>;

type UseAsyncLoading = [
  ASYNC_FN,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
];

export default function useAsyncLoading(directFn: ASYNC_FN): UseAsyncLoading {
  const [loading, setLoading] = React.useState<boolean>(false);
  const isMounted = useMountedState();

  const useLoading: ASYNC_FN = React.useCallback(async (...args) => {
    setLoading(true);

    try {
      await directFn(...args);
    } catch (error) {
      console.error(error);
    }

    if (isMounted()) {
      setLoading(false);
    }
  }, [
    directFn,
    isMounted,
  ]);

  return [
    useLoading,
    loading,
    setLoading,
  ];
}
