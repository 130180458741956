import {
  IMCMessageSuggestion,
  IMCMethodSuggestNextOperatorMessagesResponse,
  MCMethodSuggestNextOperatorMessages,
} from '../../api/proto';
import Chat from './Chat';

export default class ChatMessageSuggestionStore {
  constructor(public chat: Chat) {
  }

  public load = async (suggestion: IMCMessageSuggestion): Promise<IMCMessageSuggestion | null> => {
    const {res} = await this.chat.channel.channelsRequest<IMCMethodSuggestNextOperatorMessagesResponse>(
      {
        suggestNextOperatorMessages: new MCMethodSuggestNextOperatorMessages({
          channelID: this.chat.channelID,
          chatID: this.chat.id,
          suggestion,
        }),
      },
      'suggestNextOperatorMessages',
    );

    return res?.suggestion || null;
  };
}
