import Long from 'long';

export const PAGE_SIZE = 50;

export enum PagingOffsetType {
  FIRST_PAGE = 'FIRST_PAGE',
  PREV_PAGE = 'PREV_PAGE',
  NEXT_PAGE = 'NEXT_PAGE',
  MIDDLE_PAGE = 'MIDDLE_PAGE',
}

export interface PagingOffset {
  offsetBefore?: Long;
  offsetAfter?: Long;
  offsetType: PagingOffsetType;
  pageSize: number;
}

export interface PagingPage<T> {
  result: Array<T>;
  offsetType: PagingOffsetType;
}

export function mergeArraysByOffsetType<T>(targetArray: T[], newArray: T[], offsetType?: PagingOffsetType): T[] {
  if (!newArray.length) {
    return targetArray;
  }

  if (!targetArray.length) {
    return newArray;
  }

  if (offsetType === PagingOffsetType.PREV_PAGE) {
    return [...targetArray, ...newArray];
  } else if (offsetType === PagingOffsetType.NEXT_PAGE) {
    return [...newArray, ...targetArray];
  }

  return newArray;
}

export function getOffsetRange(offsetType: PagingOffsetType = PagingOffsetType.FIRST_PAGE, pageSize: number = PAGE_SIZE): PagingOffset {
  const halfPageSize = pageSize / 2;

  let offsetBefore: Long | undefined;
  let offsetAfter: Long | undefined;

  if (offsetType === PagingOffsetType.PREV_PAGE) {
    // Prev page
    offsetBefore = Long.fromNumber(pageSize);
  } else if (offsetType === PagingOffsetType.NEXT_PAGE) {
    // Next page
    offsetAfter = Long.fromNumber(pageSize);
  } else if (offsetType === PagingOffsetType.MIDDLE_PAGE) {
    // Middle page seround specific chat
    offsetBefore = Long.fromNumber(halfPageSize);
    offsetAfter = Long.fromNumber(halfPageSize);
  } else {
    // First page
    offsetBefore = Long.fromNumber(pageSize);
  }

  return {
    offsetBefore,
    offsetAfter,
    offsetType,
    pageSize,
  };
}

export function getOffset(id?: Long | null, prevPage?: boolean, pageSize: number = PAGE_SIZE): PagingOffset {
  const halfPageSize = pageSize / 2;

  let offsetBefore: Long | undefined;
  let offsetAfter: Long | undefined;
  let offsetType: PagingOffsetType = PagingOffsetType.FIRST_PAGE;

  if (id && prevPage === true) {
    // Prev page
    offsetBefore = Long.fromNumber(pageSize);
    offsetType = PagingOffsetType.PREV_PAGE;
  } else if (id && prevPage === false) {
    // Next page
    offsetAfter = Long.fromNumber(pageSize);
    offsetType = PagingOffsetType.NEXT_PAGE;
  } else if (id) {
    // Middle page seround specific chat
    offsetBefore = Long.fromNumber(halfPageSize);
    offsetAfter = Long.fromNumber(halfPageSize);
    offsetType = PagingOffsetType.MIDDLE_PAGE;
  } else {
    // First page
    offsetBefore = Long.fromNumber(pageSize);
  }

  return {
    offsetBefore,
    offsetAfter,
    offsetType,
    pageSize,
  };
}

export interface SliceRange {
  start: number;
  end: number; // not included
}

export function getSliceRange(index: number, offsetBefore?: Long, offsetAfter?: Long, pageSize: number = PAGE_SIZE): SliceRange {
  if (offsetBefore && offsetAfter) {
    let start = index - offsetAfter.toNumber();
    start = start < 0 ? 0 : start;
    const end = index + offsetBefore.toNumber();
    return {start, end};
  } else if (offsetBefore) {
    const start = index + 1;
    const end = index + 1 + offsetBefore.toNumber();
    return {start, end};
  } else if (offsetAfter) {
    let start = index - offsetAfter.toNumber();
    start = start < 0 ? 0 : start;
    const end = index;
    return {start, end};
  }
  return {start: index + 1, end: index + pageSize};
}
