import {observer} from 'mobx-react';
import React from 'react';

import CustomScrollbar from 'o-ui/CustomScrollbar';
import VariableSizeList, {IVariableSizeList, ListOnScrollProps} from 'o-ui/VirtualizedList';
import AutoSizer from '../../../../components/AutoSizer';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';
import {useStore} from '../../../../stores/AppStore';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import {Chat} from '../../../../stores/Chat';
import MediaGalleryRow from './MediaGalleryRow';
import MediaGallerySkeleton from './MediaGallerySkeleton';
import useMediaGallery from './useMediaGallery';

export enum MediaGalleryType {
  IMAGES,
  VIDEOS,
  STICKERS,
  GIFS,
}

interface MediaGalleryProps {
  chat: Chat;
  attachments?: MessageAttachment[];
  type: MediaGalleryType;
}

interface MediaGalleryListProps extends MediaGalleryProps {
  width: number;
  height: number;
}

export const MediaGalleryList: React.FC<MediaGalleryListProps> = observer(
  ({width, height, chat, attachments, type}) => {
    const {
      layOutStore: {rightSideBar},
    } = useStore();

    const listRef = React.useRef<IVariableSizeList>(null);
    const outerRef = React.useRef<HTMLDivElement>(null);
    const listInnerRef = React.useRef<HTMLDivElement>(null);

    const sizeMap = React.useRef({});
    const setSize = React.useCallback((index, size) => {
      sizeMap.current = {...sizeMap.current, [index]: size};
      listRef.current?.resetAfterIndex(index);
    }, []);

    const getItemSize = (index: number): number => {
      return sizeMap.current[index] || width / 3;
    };

    const chatHistory = React.useMemo(() => {
      switch (type) {
        case MediaGalleryType.IMAGES:
          return chat.store?.imagesHistory;
        case MediaGalleryType.VIDEOS:
          return chat.store?.videosHistory;
        case MediaGalleryType.STICKERS:
          return chat.store?.stickersHistory;
        case MediaGalleryType.GIFS:
          return chat.store?.gifsHistory;
      }
    }, [chat.store, type]);

    React.useEffect(() => {
      if (rightSideBar.chatInfoType || rightSideBar.peerInfoType) {
        chatHistory?.load();
      }

      return () => {
        rightSideBar.setChatInfoBarGalleryItemsDisplayAllow(false);
      };
    }, [chatHistory, rightSideBar]);

    const [attachmentsRows] = useMediaGallery(attachments);

    const handleScrolledToBottom = () => {
      if (height !== 0) {
        chatHistory?.loadNextPage();
      }
    };

    const handleScrollList = (params: ListOnScrollProps) => {
      scrollCallback(params);
    };

    const scrollCallback = useDebouncedCallback((params: ListOnScrollProps) => {
      if (params.scrollUpdateWasRequested) {
        return;
      }

      const scrollHeight = listInnerRef.current?.clientHeight || 0;
      if (Math.floor(scrollHeight - params.scrollOffset - height) === 0) {
        handleScrolledToBottom();
      }
    }, 400);

    return (
      <MediaGallerySkeleton attachmentType={chatHistory?.type}>
        <VariableSizeList
          ref={listRef}
          innerRef={listInnerRef}
          outerRef={outerRef}
          className="chat-custom-scroll"
          width={width}
          height={height}
          itemCount={attachmentsRows.length}
          itemSize={getItemSize}
          outerElementType={CustomScrollbar}
          onScroll={handleScrollList}
        >
          {(rowProps) => (
            <MediaGalleryRow
              rowProps={rowProps}
              setSize={setSize}
              items={attachmentsRows}
              width={width}
            />
          )}
        </VariableSizeList>
      </MediaGallerySkeleton>
    );
  },
);

export const MediaGallery = (props: MediaGalleryProps) => {
  return <AutoSizer>{({width, height}) => <MediaGalleryList {...props} width={width} height={height} />}</AutoSizer>;
};

export default MediaGallery;
