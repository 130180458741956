import Cookies from 'js-cookie';

import browserStorage from 'o-ui/utils/browserStorage';
import {
  NetworkEvent,
  RequestPriority,
} from '../../api/network';
import {api, APIResponse} from '../../api/proto';
import {APILayer} from '../../stores/APILayer';
import {
  randomUint8Array,
  uint8ArrayToUuid,
  uuidToUint8Array,
} from '../../utils/arrayUtils';
import {AppStore} from '../AppStore';


const GREETINGS_TOKEN_KEY = 'greetingsToken';


export class GreetingsStore extends APILayer {
  protected isInit: boolean = false;

  protected greetingsSending_: boolean = false;
  protected greetingsSent_: boolean = false;
  protected greetingsToken_: Uint8Array = randomUint8Array();

  protected get greetings() {
    return new api.ClientGreetings({
      token: this.greetingsToken_,
      appVersion: this.app.version,
    });
  }

  protected setInit_ = (isInit: boolean) => {
    this.isInit = isInit;
  };

  protected initGreetingsToken_ = () => {
    try {
      const greetingsToken = this.restoreGreetingsToken_() || randomUint8Array();
      this.storeGreetingsToken_(greetingsToken);
      this.setInit_(true);
    } catch (e) {
      console.error(e);
    }
  };

  protected restoreGreetingsToken_ = (): Uint8Array | null => {
    try {
      const greetingsTokenStr = browserStorage.getItem(GREETINGS_TOKEN_KEY);
      if (greetingsTokenStr) {
        console.debug(`%c GreetingsStore.restoreGreetingsToken`, 'color: orange', greetingsTokenStr, uuidToUint8Array(greetingsTokenStr));
        return uuidToUint8Array(greetingsTokenStr);
      }
    } catch (e) {
      console.debug(e);
    }
    return null;
  };

  protected storeGreetingsToken_ = (greetingsToken: Uint8Array) => {
    this.greetingsToken_ = greetingsToken;
    const greetingsTokenStr = uint8ArrayToUuid(greetingsToken);

    browserStorage.setItem(GREETINGS_TOKEN_KEY, greetingsTokenStr);
    Cookies.set(GREETINGS_TOKEN_KEY, greetingsTokenStr, {sameSite: 'Lax'});
  };

  constructor(public app: AppStore) {
    super(app);
    this.initGreetingsToken_();

    this.api.on(NetworkEvent.BEFORE_CONNECTION_OPEN, this.beforeWsOpen_);
    this.api.on(NetworkEvent.CONNECTION_CLOSE, this.onWsClose_);
    this.api.on('userResponse.newToken', this.handleNewToken_);
    this.api.on(NetworkEvent.API_ERROR, this.onApiError_);
  }

  protected beforeWsOpen_ = () => {
    this.sendGreetings_();
  };

  protected onWsClose_ = () => {
    this.greetingsSent_ = false;
  };

  protected onApiError_ = (data: APIResponse) => {
    if (data.status === APIResponse.Status.AS_PENDING_GREETINGS) {
      this.greetingsSent_ = false;
      this.sendGreetings_();
    }
  };

  protected handleNewToken_ = (newToken?: Uint8Array | null) => {
    if (newToken?.length) {
      console.debug(`%c GreetingsStore.handleNewToken`, 'color: orange', newToken, uint8ArrayToUuid(newToken));
      this.storeGreetingsToken_(newToken);
    }
  };

  protected sendGreetings_ = async () => {
    if (this.greetingsSending_) {
      console.debug(`%c ---->GreetingsStore.sendGreetings rejected`, 'color: red');
      return;
    }
    console.debug(`%c ---->GreetingsStore.sendGreetings`, 'color: orange');

    this.greetingsSending_ = true;
    const {error, res} = await this.request<api.IUserResponse>(
      {
        userRequest: new api.UserRequest({
          greetings: this.greetings,
        }),
      },
      'userResponse',
      {
        priority: RequestPriority.FIRST,
      },
    );
    this.greetingsSending_ = false;
    this.greetingsSent_ = true;
    console.debug(`%c ---->GreetingsStore.sendGreetings Done`, 'color: green', res);

    if (error) {
      console.error(error);
    }

    if (res?.greetings?.features) {
      this.app.features.proccessFeatures(res.greetings.features);
    }
  };
}

export default GreetingsStore;
