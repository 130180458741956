import cn from 'classnames';
import {observer} from 'mobx-react';
import React, {useCallback} from 'react';
import ReactPlayer from 'react-player';
import {animated, useTransition} from 'react-spring';

import {useStore} from '../../stores/AppStore';
import Chat from '../../stores/Chat';
import formatSeconds from '../../utils/formatSeconds';
import TopAudioPlayerProgressBar from './TopAudioPlayerProgressBar';
import VolumeControl from './VolumeControl';

export interface AudioPlayerProps {
  className?: string;
  chat?: Chat;
}

export const TopAudioPlayer = observer((props: AudioPlayerProps) => {
  const {chatsView, animationsEnabled} = useStore();
  const {audioPlayer} = chatsView;

  const [showPlayer, setShowPlayer] = React.useState<boolean>(!!audioPlayer.activeAudioInfo);

  React.useEffect(() => {
    setShowPlayer(!!audioPlayer.activeAudioInfo);
  }, [audioPlayer.activeAudioInfo]);

  React.useEffect(() => {
    if (!animationsEnabled) {
      audioPlayer.closeTopPanel();
    }
  }, [animationsEnabled, audioPlayer]);

  const handleClosePlayer = () => {
    setShowPlayer(false);
    audioPlayer.setWillClosing(true);
  };

  const togglePlayingClick = () => {
    audioPlayer.setPlaying(!audioPlayer.isPlaying);
  };

  const handleProgress = useCallback(
    (state) => {
      audioPlayer.setPlayed(state.played);
      audioPlayer.setPlayedSeconds(state.playedSeconds);
    },
    [audioPlayer],
  );

  const handlePlayEnd = useCallback(() => {
    audioPlayer.setPlaying(false);
  }, [audioPlayer]);

  const seekTo = useCallback(
    (fraction: number) => {
      audioPlayer.activeAudioRef?.current?.seekTo(fraction, 'fraction');
    },
    [audioPlayer.activeAudioRef],
  );

  const title = audioPlayer.isVoiceNote ? audioPlayer.activeAudioSenderName : audioPlayer.activeAudioInfo?.name;

  const renderPlayer = () => (
    <div
      className={cn('audio-player-top', props.className, {
        pinnedMessagesMode: props?.chat?.store?.pinnedMessagesMode,
        pinnedTopBarOffset: props?.chat?.store?.pinnedMessages.length,
        noAnimation: !animationsEnabled,
      })}
    >
      <div className="audio-player-top__info">
        <div className="d-flex align-items-center">
          <button
            color="none"
            type="button"
            className={cn('audio-player-top__play-btn tg-icon', {
              'tg-icon-play-fill': !audioPlayer.isPlaying,
              'tg-icon-pause-line': audioPlayer.isPlaying,
            })}
            onClick={togglePlayingClick}
          />
          <div className="audio-player-top__title">{title}</div>
          <div className="audio-player-top__date">{audioPlayer.activeAudioDate}</div>
        </div>

        <div className="d-flex align-items-center">
          <VolumeControl
            className="mr-3"
            volume={audioPlayer.volume}
            onChange={audioPlayer.setVolume}
            mode="collapsible"
            orient="vertical"
          />
          <div className="audio-player-top__duration">
            {audioPlayer.activeAudioInfo?.duration ? (
              <>
                <span className="audio-player-top__duration-current">{formatSeconds(audioPlayer.playedSeconds)}</span>
                <span className="audio-player-top__duration-delimiter">/</span>
                <span className="audio-player-top__duration-total">
                  {formatSeconds(audioPlayer.activeAudioInfo.duration?.toNumber())}
                </span>
              </>
            ) : (
              '-'
            )}
          </div>
          <div className={'audio-player-top__close-btn tg-icon tg-icon-cross-2-line'} onClick={handleClosePlayer} />
        </div>

        <ReactPlayer
          ref={audioPlayer.activeAudioRef}
          className="d-none"
          url={audioPlayer.activeAudioUrl || ''}
          volume={audioPlayer.volume ? audioPlayer.volume / 100 : 0}
          controls={false}
          playsinline={true}
          playing={audioPlayer.isPlaying}
          onEnded={handlePlayEnd}
          onProgress={handleProgress}
          progressInterval={100}
        />
      </div>
      <TopAudioPlayerProgressBar
        played={audioPlayer.played || 0}
        playedSeconds={audioPlayer.playedSeconds || 0}
        onProgressClick={seekTo}
      />
    </div>
  );

  const transitions = useTransition([showPlayer], {
    from: {minHeight: '0px'},
    enter: {minHeight: '52px'},
    leave: {minHeight: '0px'},
    config: {duration: 200},
    onDestroyed: () => {
      if (!showPlayer) {
        audioPlayer.closeTopPanel();
      }
    },
  });

  if (animationsEnabled) {
    return transitions((styles, allow) =>
      allow ? (
        <animated.div
          className={cn('audio-player-top__animated', {
            pinnedMessagesMode: props?.chat?.store?.pinnedMessagesMode,
            pinnedTopBarOffset: props?.chat?.store?.pinnedMessages.length,
          })}
          style={styles}
        >
          {renderPlayer()}
        </animated.div>
      ) : null,
    );
  }

  return showPlayer ? renderPlayer() : null;
});

export default TopAudioPlayer;
