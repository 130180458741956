import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {animated, useTransition} from 'react-spring';

import {useStore} from '../../../../stores/AppStore';
import {ChatInfoType} from '../../../../stores/LayOutStore';
import Peer from '../../../../stores/Peer';
import ChatFilesInfo from './ChatFilesInfo';
import PeerExtraInfo from './PeerExtraInfo';
import PeerProfileInfo from './PeerProfileInfo';
import ProfileSidebar from './ProfileSidebar';
// import ChatInfoGallery, {getChatInfoGalleryTitle} from './ChatInfoGallery';
// import ChatActionsList from './ChatActionsList';
// import ChatOperatorsInfo from './ChatOperatorsInfo';
import useIsFirstRender from '../../../../hooks/useIsFirstRender';
import ChatInfoGallery, {getChatInfoGalleryTitle} from './ChatInfoGallery';

interface PeerInfoBarProps {
  peer: Peer;
}

export const PeerInfoBar: React.FC<PeerInfoBarProps> = observer((props) => {
  const {
    animationsEnabled,
    layOutStore: {rightSideBar},
  } = useStore();
  const {t} = useTranslation();

  const isFirst = useIsFirstRender();

  const handleFilesViewClick = (type: ChatInfoType) => {
    rightSideBar.chatInfoBarAnimationEnable();
    rightSideBar.openPeerGallery(type);
  };

  const handleCloseFilesViewClick = () => {
    rightSideBar.setChatInfoBarGalleryItemsDisplayAllow(false);
    rightSideBar.closePeerGallery();
  };

  const transitions = useTransition(rightSideBar.peerInfoType, {
    from: {
      transform: `translateX(${rightSideBar.peerInfoType === ChatInfoType.NONE ? '-100%' : '100%'})`,
    },
    enter: {
      transform: 'translateX(0%)',
    },
    leave: {
      transform: `translateX(${rightSideBar.peerInfoType === ChatInfoType.NONE ? '100%' : '-100%'})`,
    },
    onDestroyed: () => {
      rightSideBar.chatInfoBarAnimationEnable();

      rightSideBar.setChatInfoBarGalleryItemsDisplayAllow(true);
    },
  });

  const getContent = (peerInfoType: ChatInfoType) => {
    if (peerInfoType === ChatInfoType.NONE) {
      return (
        <ProfileSidebar
          className="info-list"
          title={t('chat_user_chat_head_user_info_title')}
          onClose={rightSideBar.close}
          // onBack={chatsView.activeUsersChat?.isPrivate ? undefined : handleOpenGroupInfo}
        >
          {/*props.user.chat && !props.user.chat?.store?.isInternal && !settings.systemUser?.isOperator ? (
            <ChatOperatorsInfo
              className="profile-sidebar__operators-info"
              chat={props.user.chat}
              onClick={handleFilesViewClick}
            />
          ) : null*/}

          <PeerProfileInfo className="profile-sidebar__profile-info" peer={props.peer} />

          <PeerExtraInfo className="profile-sidebar__extra-info" peer={props.peer} />

          {props.peer.chat ? (
            <ChatFilesInfo
              className="profile-sidebar__files-info"
              chat={props.peer.chat}
              onClick={handleFilesViewClick}
            />
          ) : null}

          {/*props.user.chat ? <ChatActionsList className="profile-sidebar__files-info" chat={props.user.chat} /> : null*/}
        </ProfileSidebar>
      );
    } else if (props.peer.chat) {
      return (
        <ProfileSidebar
          title={getChatInfoGalleryTitle(peerInfoType)}
          onBack={handleCloseFilesViewClick}
          onClose={rightSideBar.close}
        >
          <ChatInfoGallery chat={props.peer.chat} infoType={peerInfoType} />
        </ProfileSidebar>
      );
    }
    return null;
  };

  const isAnimated = !isFirst && animationsEnabled && rightSideBar.chatInfoBarAnimationAllow;
  return (
    <>
      {isAnimated
        ? transitions((style, userChatInfoType) => (
            <animated.div style={style} className="animation-info-bar">
              {getContent(userChatInfoType)}
            </animated.div>
          ))
        : getContent(rightSideBar.peerInfoType)}
    </>
  );
});

export default PeerInfoBar;
