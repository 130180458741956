import React from 'react';
import {useTranslation} from 'react-i18next';

export const ChatItemTypingIcon = () => {
  const {t} = useTranslation();
  return (
    <>
      {t('typing')}
      <span className="chat-item__typing-icon">
        <span className="dot ml-2"></span>
        <span className="dot ml-2"></span>
        <span className="dot ml-2"></span>
      </span>
    </>
  );
};

export default ChatItemTypingIcon;
