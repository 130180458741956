import React from 'react';

import {useStore} from '../../stores/AppStore';
import Chat from '../../stores/Chat';
import ModalType from '../../stores/ModalType';
import {FileData} from '../../utils/file/fileReaders';
import FileInput from './FileInput';
import useFileChangeHendler from './useFileChangeHendler';

interface AnyFileUploaderProps {
  chat: Chat;
  className?: string;
}

export const AnyFileUploader = React.forwardRef<HTMLInputElement, AnyFileUploaderProps>((props, ref) => {
  const {modals} = useStore();

  const handleSend = (dataFiles: FileData[]) => {
    modals.open(ModalType.UPLOAD_FILE_ASYNC, {dataFiles, chat: props.chat});
  };

  const handleFileChange = useFileChangeHendler(handleSend);

  return <FileInput className={props.className} ref={ref} onChange={handleFileChange} multiple={true} />;
});

export default AnyFileUploader;
