import {observer} from 'mobx-react';
import React from 'react';

import {Chat} from '../../../stores/Chat';
import {ChatMessagesItem} from './ChatMessagesItem';
import ChatMessagesListContainer from './ChatMessagesListContainer';
import {getMessageElementId} from './useChatMessagesList';

interface ChatMessagesListProps {
  chat: Chat;
  searchId?: string;
}

export const ChatMessagesList = observer(({chat, searchId}: ChatMessagesListProps) => {
  const chatMessages = chat.store?.groupedMessages || [];

  const messagesNumber = chatMessages.length;

  return (
    <ChatMessagesListContainer chat={chat}>
      {chatMessages.map((chatMessage, i) => {
        return (
          <ChatMessagesItem
            key={`${chat.id.toString()}_${i}`}
            index={i}
            isScrolling={false}
            messagesNumber={messagesNumber}
            chat={chat}
            chatMessages={chatMessages}
            message={chatMessage}
            prevMessage={i > 0 ? chatMessages[i - 1] : null}
            nextMessage={messagesNumber > i + 1 ? chatMessages[i + 1] : null}
            searchId={searchId}
            isLastMessage={messagesNumber === i + 1}
            formatMessageId={getMessageElementId}
          />
        );
      })}
    </ChatMessagesListContainer>
  );
});

export default ChatMessagesList;
