import React, {useRef} from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';

import {IconButton} from 'o-ui/Button';
import {useStore} from '../../../../stores/AppStore';
import Chat from '../../../../stores/Chat';
import ContextMenuType from '../../../../stores/ContextMenuType';
import Message from '../../../../stores/Message';
import PhotoVideoUploader from '../../../../components/FileUploader/PhotoVideoUploader';
import AnyFileUploader from '../../../../components/FileUploader/AnyFileUploader';
import MultiFileUploader from '../../../../components/FileUploader/MultiFileUploader';


interface IProps {
  chat: Chat;
  replyMessage?: Message | null;
  className?: string;
  buttonClassName?: string;
  onFilesUpload?: (files: File[]) => void;
  onVideosUpload?: (files: File[]) => void;
  onImagesUpload?: (files: File[]) => void;
  onAudioUpload?: (files: File[]) => void;
}

export const ChatUploadMenu: React.FC<IProps> = observer((props) => {
  const {contextMenu} = useStore();
  const btnRef = useRef<HTMLButtonElement>(null);

  const refPhotoVideoInput = useRef<HTMLInputElement>(null);
  const refFilesInput = useRef<HTMLInputElement>(null);
  const refMultiFilesInput = useRef<HTMLInputElement>(null);

  const handleOpenMenu = () => {
    refMultiFilesInput.current?.click();
    /*
    const width = btnRef.current?.clientWidth;
    const rect = btnRef.current?.getBoundingClientRect();
    const clientX = rect?.left ? rect?.left + (width || 0) : e.clientX;
    const clientY = rect?.top ? rect?.top - 10 : e.clientY;
    contextMenu.open({clientX, clientY: clientY}, ContextMenuType.UPLOAD_MENU, {
      callbacks: {
        photoVideoUpload: () => {
          refPhotoVideoInput.current?.click();
        },
        fileUpload: () => {
          refFilesInput.current?.click();
        },
      },
    });
    */
  };

  return (
    <>
      <PhotoVideoUploader chat={props.chat} className="d-none" ref={refPhotoVideoInput} />
      <AnyFileUploader chat={props.chat} className="d-none" ref={refFilesInput} />
      <MultiFileUploader chat={props.chat} className="d-none" ref={refMultiFilesInput} />

      <IconButton
        className={cn(
          'tg-icon-attachment-line line-height-22 font-size-22',
          {active: contextMenu.isOpen(ContextMenuType.UPLOAD_MENU)},
          props.buttonClassName,
        )}
        ref={btnRef}
        onClick={handleOpenMenu}
      />
    </>
  );
});

export default ChatUploadMenu;
