import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';

import FileUploadingCircularProgress from '../../pages/Chat/UserChat/MessageContent/FileUploadingCircularProgress';
import {useStore} from '../../stores/AppStore';
import MessageAttachment from '../../stores/Attachment/MessageAttachment';
import AudioPlayerProgressBar from './AudioPlayerProgressBar';

export interface AudioPlayerProps {
  attachment: MessageAttachment;
  className?: string;
  hideProgressBar?: boolean;
}

export const AudioPlayer = observer((props: AudioPlayerProps) => {
  const {chatsView} = useStore();
  const {audioPlayer} = chatsView;

  const message = props.attachment.message;

  const togglePlayingClick = () => {
    if (!audioPlayer.isActive(props.attachment)) {
      audioPlayer.setActiveAudio(message, props.attachment, message.senderName, message.stamp);
    }
    audioPlayer.setPlaying(!audioPlayer.isPlaying);
  };

  const seekTo = React.useCallback(
    (fraction: number) => {
      audioPlayer.activeAudioRef?.current?.seekTo(fraction, 'fraction');
    },
    [audioPlayer.activeAudioRef],
  );

  const isPlaying = () => {
    return audioPlayer.isActive(props.attachment) ? audioPlayer.isPlaying : false;
  };
  const played = () => {
    return audioPlayer.isActive(props.attachment) ? audioPlayer.played : 0;
  };
  const playedSeconds = () => {
    return audioPlayer.isActive(props.attachment) ? audioPlayer.playedSeconds : 0;
  };

  const btn = (
    <button
      color="none"
      type="button"
      className={cn('audio-player__play-btn tg-icon', {
        'tg-icon-play-circle-fill': !isPlaying(),
        'tg-icon-pause-circle-fill': isPlaying(),
      })}
      onClick={togglePlayingClick}
      disabled={message && !message.isDelivered}
    />
  );

  return (
    <div className={cn('audio-player', props.className)}>
      {message && !message.isDelivered ? (
        <FileUploadingCircularProgress
          className="mr-3"
          attachment={props.attachment}
          dark={message.isOwn}
          small={true}
        >
          {btn}
        </FileUploadingCircularProgress>
      ) : (
        <>{btn}</>
      )}
      {props.hideProgressBar ? null : (
        <AudioPlayerProgressBar played={played()} playedSeconds={playedSeconds()} onProgressClick={seekTo} />
      )}
    </div>
  );
});

export default AudioPlayer;
