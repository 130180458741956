import React from 'react';
import moment from 'moment';

import {formatSeconds} from '../../utils/formatSeconds';
import {AudioPlayer} from '../AudioPlayer/AudioPlayer';
import {FILES_DATE_TIME_FORMAT} from '../../utils/format';
import MessageAttachment from '../../stores/Attachment/MessageAttachment';

interface AudioFileProps {
  attachment: MessageAttachment;
}

export const AudioFile: React.FC<AudioFileProps> = (props) => {
  if (!props.attachment) {
    return null;
  }

  const message = props.attachment.message;

  const date = message.stamp ? moment.unix(message.stamp.toNumber()).format(FILES_DATE_TIME_FORMAT) : '';
  const duration = props.attachment.fileDuration ? formatSeconds(props.attachment.fileDuration) : '';
  const description = `${date}, ${duration}`;

  return (
    <div className="file-info">
      <AudioPlayer attachment={props.attachment} hideProgressBar={true} />
      <div className="file-info__body">
        <div className="text-left text-truncate">
          <div className="file-info__name">{props.attachment.name}</div>
          <p className="file-info__description font-size-13 mb-0">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default AudioFile;
