import React from 'react';
import cn from 'classnames';

import {FileInfo} from '../../../../components/FileInfo';
// import ImageContent from './ImageContent';
import FileUploadingCircularProgress from './FileUploadingCircularProgress';
import {MCAttachment} from '../../../../api/proto';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import AnimationContent from './AnimationContent';


interface FileContentProps {
  className?: string;
  attachment: MessageAttachment;
  isOwn?: boolean;
  isScrolling?: boolean;
  fixedSize?: boolean;
  blurredBackground?: boolean;
  onContextMenu?: React.MouseEventHandler;
}

export const FileContent = (props: FileContentProps) => {
  const {className, attachment, isOwn, isScrolling} = props;

  const message = attachment.message;

  if (message.type === MCAttachment.Type.VIDEO) {
    return (
      <>
        <AnimationContent
          className={className}
          attachment={attachment}
          isScrolling={isScrolling}
          onContextMenu={props.onContextMenu}
        />
        <FileUploadingCircularProgress className="position-absolute-center" attachment={attachment} />
      </>
    );
  }
  /*
  if (message.isDocumentImage) {
    return (
      <>
        <ImageContent
          className={props.className}
          attachment={attachment}
          fixedSize={props.fixedSize}
          isScrolling={props.isScrolling}
          blurredBackground={props.blurredBackground}
        />
        <FileUploadingCircularProgress className="position-absolute-center" attachment={attachment} />
      </>
    );
  }
  */
  return (
    <div onContextMenu={props.onContextMenu} className={cn('message-file', className)}>
      <FileInfo
        attachment={attachment}
        isOwn={isOwn}
        small={true}
      />
    </div>
  );
};

export default FileContent;
