import React from 'react';
import {useStore} from '../../stores/AppStore';
import {observer} from 'mobx-react';
import Avatar from 'o-ui/Avatar';

export const ProfileShortInfo = observer(() => {
  const {userStore} = useStore();
  return (
    <div className="profile-short-info">
      <Avatar size="sm" className="profile-short-info__avatar" name={userStore.user?.fullName} />
      <div className="profile-short-info__body">
        <div className="body2-regular color-body-primary">{userStore.user?.fullName}</div>
        <div className="body3-regular color-gray-500">{userStore.user?.email}</div>
      </div>
    </div>
  );
});

export default ProfileShortInfo;
