import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import {ReactComponent as SnippetLine} from './../../../../assets/image-icons/snippetLine.svg';

interface ChatSnippetButtonProps {
  className?: string;
  isOpen?: boolean;
  onClick?(): void;
}

export const ChatSnippetButton: React.FC<ChatSnippetButtonProps> = observer((props) => {
  const {t} = useTranslation();

  return (
    <Button
      className={cn(
        'chat-input__snippets-btn radius-8',
        {active: props.isOpen},
        props.className,
      )}
      variant={props.isOpen ? 'contained' : 'outlined'}
      color={props.isOpen ? 'primary' : 'tertiary'}
      size="small"
      onClick={props.onClick}
    >
      <SnippetLine className="chat-input__snippets-btn-icon" />
      <span className="chat-input__snippets-btn-text">
        {t('chat_user_chat_snippets_button')}
      </span>
    </Button>
  );
});

export default ChatSnippetButton;
