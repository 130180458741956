import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Avatar from 'o-ui/Avatar';
import Button from 'o-ui/Button';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import {useStore} from '../../../../stores/AppStore';
import {ModalType} from '../../../../stores/ModalType';
import {uint8ArrayToBase64, uint8ArrayToUuid} from '../../../../utils/arrayUtils';
import useAvatarAttachment from '../../useAvatarAttachment';
import WorkspaceBarItem from './WorkspaceBarItem';


export const WorkspaceBar = observer(() => {
  const {t} = useTranslation();
  const {activeWorkspace, modals, workspaces} = useStore();
  const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false);
  const menuRef = React.useRef<HTMLDivElement | null>(null);

  const {src} = useAvatarAttachment(activeWorkspace.avatar, activeWorkspace.id);

  useOnClickOutside(menuRef, () => {
    if (dropdownOpen) {
      setDropdownOpen(false);
    }
  });

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleOpenWorkspaceCreating = () => {
    modals.open(ModalType.WORKSPACE_CREATING);
  };

  return (
    <div
      ref={menuRef}
      className={cn(
        'workspace-bar',
        {'workspace-bar--open': dropdownOpen}
      )}
      onClick={toggleDropdown}
    >
      <div className="workspace-bar__body w-100 my-3">
        <Avatar
          className="workspace-bar__avatar"
          size="xs"
          name={activeWorkspace.name || uint8ArrayToBase64(activeWorkspace.id)}
          src={src}
        />

        <span
          className="workspace-bar__name body2-bold color-body-primary text-truncate pr-3"
          title={activeWorkspace.name || uint8ArrayToBase64(activeWorkspace.id)}
          data-id={uint8ArrayToBase64(activeWorkspace.id)}
        >
          {activeWorkspace.name || uint8ArrayToBase64(activeWorkspace.id)}
        </span>

        <i className="workspace-bar__chevron tg-icon tg-icon-chevron-down-line mr-4" />
      </div>

      <div
        style={{display: dropdownOpen ? 'block' : 'none'}}
        className="dropdown-menu workspace-bar__menu custom-scroll-y"
      >
        {workspaces.list.map((w) => (
          <WorkspaceBarItem
            key={uint8ArrayToUuid(w.id)}
            workspace={w}
          />
        ))}

        <div className="w-100 px-5 py-3">
          <Button
            className="w-100"
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<i className="tg-icon tg-icon-add-line font-size-12" />}
            onClick={handleOpenWorkspaceCreating}
          >
            {t('Create a workspace')}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default WorkspaceBar;
