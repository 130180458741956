import React from 'react';
import Message from '../../../../stores/Message';

interface IProps {
  children?: React.ReactNode;
  message: Message;
}

export const MessageStatus: React.FC<IProps> = (props) => {
  return <span className="message-status mb-0 align-middle">{props.children}</span>;
};

export default MessageStatus;
