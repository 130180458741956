import {observer} from 'mobx-react';
import React from 'react';

import {ContextMenuItem} from '../stores/ContextMenuStore';
import ContextMenuType from '../stores/ContextMenuType';
import MenuChat from './MenuChat';
import MenuMessage from './MenuMessage';
// import MenuMessageDeleted from './MenuMessageDeleted';
// import MenuSpecialMessage from './MenuSpecialMessage';
import MenuNewChat from './MenuNewChat';
import MenuUpload from './MenuUpload';
// import MenuNewInternalChat from './MenuNewInternalChat';
import MenuFile from './MenuFile';
// import MenuSnippetManage from './MenuSnippetManage';
// import MenuSharedLink from './MenuSharedLink';
import MenuAllChannelsItem from './MenuAllChannelsItem';
import MenuBlockWebWidgetChat from './MenuBlockWebWidgetChat';
import MenuChannel from './MenuChannel';
import MenuMessageTagsViewer from './MenuMessageTags/MenuMessageTagsViewer';
// import MenuBot from './MenuBot';
// import MenuChatMember from './MenuChatMember';
import MenuManageInvite from './MenuManageInvite';
import MenuWebWidgetSnippet from './MenuWebWidgetSnippet';

interface ContextMenuWrapperProps {
  menu: ContextMenuItem;
}

export const ContextMenuWrapper: React.FC<ContextMenuWrapperProps> = observer((props) => {
  switch (props.menu.type) {
    case ContextMenuType.CHAT_MENU:
      return <MenuChat menu={props.menu} />;
    case ContextMenuType.MESSAGE_MENU:
      return <MenuMessage menu={props.menu} />;
    // case ContextMenuType.MESSAGE_DELETED_MENU:
    //   return <MenuMessageDeleted menu={props.menu} />;
    // case ContextMenuType.SPECIAL_MESSAGE_MENU:
    //   return <MenuSpecialMessage menu={props.menu} />;
    case ContextMenuType.UPLOAD_MENU:
      return <MenuUpload menu={props.menu} />;
    case ContextMenuType.NEW_CHAT:
      return <MenuNewChat menu={props.menu} />;
    // case ContextMenuType.NEW_INTERNAL_CHAT:
    //   return <MenuNewInternalChat menu={props.menu} />;
    // case ContextMenuType.SNIPPET_MANAGE_MENU:
    //   return <MenuSnippetManage menu={props.menu} />;
    case ContextMenuType.BLOCK_WEB_WIDGET_CHAT:
      return <MenuBlockWebWidgetChat menu={props.menu} />;
    case ContextMenuType.CHANNEL_MENU:
      return <MenuChannel menu={props.menu} />;
    case ContextMenuType.ALL_CHANNELS_ITEM_MENU:
      return <MenuAllChannelsItem menu={props.menu} />;
    case ContextMenuType.MESSAGE_TAGS:
      return <MenuMessageTagsViewer menu={props.menu} />;
    case ContextMenuType.FILE:
      return <MenuFile menu={props.menu} />;
    // case ContextMenuType.SHARED_LINK:
    //   return <MenuSharedLink menu={props.menu} />;
    // case ContextMenuType.BOT_MENU:
    //   return <MenuBot menu={props.menu} />;
    // case ContextMenuType.CHAT_MEMBER_MENU:
    //   return <MenuChatMember menu={props.menu} />;
    case ContextMenuType.INVITE_MANAGE_MENU:
      return <MenuManageInvite menu={props.menu} />;
    case ContextMenuType.WEB_WIDGET_SNIPPET_MENU:
      return <MenuWebWidgetSnippet menu={props.menu} />;

    default:
      return null;
  }
});

export default ContextMenuWrapper;
