import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import translationDE from './locales/de/translation.json';
import translationIT from './locales/it/translation.json';
import translationES from './locales/es/translation.json';
import translationRU from './locales/ru/translation.json';
import translationENG from './locales/en/translation.json';
import translationUA from './locales/uk/translation.json';

const DEFAULT_LANGUAGE = 'en';
const initialLanguage: string = DEFAULT_LANGUAGE;

const resources = {
  gr: {
    translation: translationDE,
  },
  it: {
    translation: translationIT,
  },
  sp: {
    translation: translationES,
  },
  ru: {
    translation: translationRU,
  },
  en: {
    translation: translationENG,
  },
  uk: {
    translation: translationUA,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    resources,
    lng: initialLanguage,
    fallbackLng: DEFAULT_LANGUAGE,
    returnEmptyString: false,

    nsSeparator: false,
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export const t = i18n.t;

export default i18n;
