export default function minScale(
  maxWidth: number,
  maxHeight: number,
  width?: number | string,
  height?: number | string,
): number {
  if (!width || !height) {
    return 1;
  }

  const widthNum = typeof width === 'string' ? parseInt(width, 10) : width;
  const heightNum = typeof height === 'string' ? parseInt(height, 10) : height;

  const scaleForWidth = maxWidth / widthNum;
  const scaleForHeight = maxHeight / heightNum;

  if (scaleForWidth < 1 && scaleForHeight < 1) {
    return scaleForWidth < scaleForHeight ? scaleForWidth : scaleForHeight;
  } else if (scaleForWidth < 1) {
    return scaleForWidth;
  } else if (scaleForHeight < 1) {
    return scaleForHeight;
  }

  return 1;
}