import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import {entities} from '../../../api/proto';
import ModalType from '../../../stores/ModalType';
import useStore from '../../../stores/useStore';
import formatMoney from '../../../utils/format/formatMoney';
import getBillingPeriodTitle from '../../Settings/Billing/getBillingPeriodTitle';

import CheckInCircle from '../../../assets/images/check-in-green-circle.svg';

interface TariffSelectItemProps {
  className?: string;
  style?: React.CSSProperties;
  tariff: entities.ITariff;
  highlight?: boolean;
  recomended?: boolean;
  maxTariff?: boolean;
}

export const TariffSelectItem = observer(({
  className,
  style,
  tariff,
  highlight,
  recomended,
  maxTariff,
}: TariffSelectItemProps) => {
  const {t} = useTranslation();
  const {activeWorkspace: {billing, membersCount}, anal, modals} = useStore();

  const seats: number = tariff?.includedUsers?.toNumber() || 0;
  const getStartedMode: boolean = !billing.currentTariff && !tariff.price;

  const handleClickPay = async () => {
    if (billing.currentTariff && (billing.currentTariff.price || 0) > (tariff?.price || 0)) {
      modals.open(ModalType.BILLING_TARIFF_PAY_WITH_MEMBERS, {tariff});
      return;
    }

    if (membersCount > (tariff.includedUsers?.toNumber() || 0)) {
      modals.open(ModalType.BILLING_APPLY_TARIFF_WARNING, {tariff});
      return;
    }

    anal.onboardingSubscribe(tariff);

    const {error, res} = await billing.payForTariff(tariff?.id);
    if (!error && !res?.paymentLink) {
      modals.open(ModalType.BILLING_FREE_PLAN_ACTIVATED, {tariff});
    }
  };

  const payButtonTitle = () => {
    if (!billing.currentTariff) {
      return !tariff.price ? t('settings_billing_tariff_get_started') : t('settings_billing_tariff_subscribe');
    }
    if ((billing.currentTariff.price || 0) > (tariff?.price || 0)) {
      return t('settings_billing_tariff_downgrade');
    }
    return t('settings_billing_tariff_upgrade');
  };

  return (
    <div
      className={cn(
        'd-inline-flex flex-column radius-8 border-primary py-5',
        highlight ? 'bg-brand-primary' : 'bg-background-primary',
        className,
      )}
      style={style}
      data-tariff-id={tariff?.id}
    >

      <div className="px-5 pb-3 d-flex">
        <span className={cn('h4-medium')}>
          {tariff?.name}
        </span>
        {recomended ? (
          <span className="label-3 d-inline-flex align-items-center color-info-primary bg-info-tint2 radius-8 py-1 px-3 ml-auto">
            {t('settings_billing_tariff_recomended')}
          </span>
        ) : null}
        {maxTariff ? (
          <span className="label-3 d-inline-flex align-items-center color-warning-primary bg-warning-tint2 radius-8 py-1 px-3 ml-auto">
            &#128293; {t('settings_billing_tariff_top')}
          </span>
        ) : null}
      </div>

      <div className="px-5 d-flex flex-row justify-content-between">
        <div>
          <div className="h3-bold">
            {formatMoney({value: tariff?.price, currency: tariff?.currency})}
          </div>
          <div>
            {getBillingPeriodTitle(tariff?.billingPeriod)}
          </div>
        </div>
        <div className="d-flex flex-row align-items-center">
          <div className="body1-regular border-primary radius-8 py-1 px-3">
            {t('settings_billing_tariff_seats_count', {count: seats})}
          </div>
        </div>
      </div>

      <div className="px-5 pt-5 mt-auto">
        <Button
          className="w-100"
          variant="contained"
          color={highlight ? 'success' : getStartedMode ? 'secondary' : 'primary'}
          onClick={handleClickPay}
          loading={billing.payForTariffInProgress === tariff?.id}
        >
          {payButtonTitle()}
        </Button>
      </div>

      <div className="px-5 pt-5 mt-auto">
        <div className="d-flex mt-3">
          <img src={CheckInCircle} alt="" />
          <div className="ml-3">{t('settings_billing_tariff_option_unlimited_channels')}</div>
        </div>

        <div className="d-flex mt-3">
          <img src={CheckInCircle} alt="" />
          <div className="ml-3">{t('settings_billing_tariff_option_unlimited_chats')}</div>
        </div>

        <div className="d-flex mt-3">
          <img src={CheckInCircle} alt="" />
          <div className="ml-3">{t('settings_billing_tariff_option_internal_chats')}</div>
        </div>

        <div className="d-flex mt-3">
          <img src={CheckInCircle} alt="" />
          <div className="ml-3">{t('settings_billing_tariff_option_snippets')}</div>
        </div>

        <div className="d-flex mt-3">
          <img src={CheckInCircle} alt="" />
          <div className="ml-3">{t('settings_billing_tariff_option_tags')}</div>
        </div>
      </div>

    </div>
  );
});

export default TariffSelectItem;