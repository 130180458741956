import store from '../stores/AppStore';

export type DownloadFileParams = {
  url?: string | null;
  fileName?: string | null;
  mimeType?: string;
};

export function downloadFile({url, fileName}: DownloadFileParams) {
  if (!url) {
    return;
  }

  console.debug('downloadFile', url, fileName);
  const fileNameFromUrl = url?.split('/').pop() || 'unnamed';

  const link = document.createElement('a');
  link.download = fileName || fileNameFromUrl;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  link.remove();
}

export default downloadFile;

export function canDownloadFileByFilePicker() {
  return typeof window.showSaveFilePicker === 'function' && !store.externalApp.desktopAppVersion;
}

export async function downloadFileBySaveFilePicker({url, fileName, mimeType}: DownloadFileParams) {
  if (!url || !canDownloadFileByFilePicker()) return;
  console.debug('downloadFileBySaveFilePicker', url, fileName, mimeType);
  const fileNameFromUrl = url?.split('/').pop() || 'unnamed';

  try {
    const fileHandle = await window.showSaveFilePicker({
      suggestedName: fileName || fileNameFromUrl,
      types: [
        {
          accept: {[`${mimeType}`]: []},
        },
      ],
    });

    const writable = await fileHandle.createWritable();

    const response = await fetch(url);

    response.body?.pipeTo(writable);
  } catch (e) {
    console.error('downloadFileBySaveFilePicker error', e);
  }
}
