import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {animated, useTransition} from 'react-spring';

import ModalMediaViewer from 'o-ui/Dialog/ModalMediaViewer/ModalMediaViewer';
import {CHAT_MESSAGES_PAGE_SIZE} from '../constants';
import Swiper from '../components/Swiper';
import {TextContentItem} from '../pages/Chat/UserChat/TextContent/TextContentItem';
import {useStore} from '../stores/AppStore';
import {ModalItem} from '../stores/ModalsStore';
import MediaViewerItem from './components/MediaViewerItem';
import ModalMediaViewerMenu from './components/ModalMediaViewerMenu';
import useMessageAttachmentsNavigation from './components/useMessageAttachmentsNavigation';
import useRotate from './components/useRotate';

interface ModalMediaLightBoxProps {
  modal: ModalItem;
}

export const ModalMediaLightBox = observer((props: ModalMediaLightBoxProps) => {
  const {chatsView} = useStore();
  const {attachment} = props.modal.data;

  if (!attachment) {
    return null;
  }

  const attachmentsHistory = chatsView.activeUsersChat?.store.getAttachmentsHistoryByType(attachment.type);

  if (!attachmentsHistory) {
    return null;
  }

  const attachments = attachmentsHistory.attachments || [];

  const attachmentsNav = useMessageAttachmentsNavigation(attachments, attachment);

  const {deg, rotate, reset} = useRotate();

  const [hideViewerControls, setHideViewerControls] = React.useState<boolean>(false);
  const [smallPlayer, setSmallPlayer] = React.useState<boolean>(false);
  const [hideCloseButton, setHideCloseButton] = React.useState<boolean>(false);

  const transitions = useTransition(attachmentsNav.index, {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
  });

  React.useEffect(() => {
    reset();
  }, [reset, attachmentsNav.attachment]);

  React.useEffect(() => {
    attachmentsHistory.load();
  }, [attachmentsHistory]);

  React.useEffect(() => {
    if (attachmentsNav.index === attachments.length - 1 && attachments.length % CHAT_MESSAGES_PAGE_SIZE === 0) {
      attachmentsHistory.loadNextPage();
    }
  }, [attachmentsHistory, attachmentsNav.index, attachments.length]);

  const toggleHideViewerControls = () => {
    setHideViewerControls(!hideViewerControls);
  };

  const toggleHideCloseButton = (hide: boolean) => {
    setHideCloseButton(hide);
  };

  const toggleSmallPlayer = () => {
    setSmallPlayer(!smallPlayer);
  };

  function renderMediaItem(idx: number) {
    const attachment = attachmentsNav.getAttachment(idx);

    return attachment ? (
      <MediaViewerItem
        attachment={attachment}
        attachmentsNav={attachmentsNav}
        rotateDeg={deg}
        toggleHideViewerControls={toggleHideViewerControls}
        toggleSmallPlayer={toggleSmallPlayer}
        toggleHideCloseButton={toggleHideCloseButton}
        smallPlayer={smallPlayer}
      />
    ) : null;
  }

  return (
    <ModalMediaViewer
      className={cn('modal-media-viewer', {
        'video-player-modal': attachmentsNav.attachment?.isVideo,
        'video-player-small': attachmentsNav.attachment?.isVideo && smallPlayer,
      })}
      currentIndex={attachmentsNav.index}
      length={attachments.length}
      onPrev={attachmentsNav.switchToPrev}
      onNext={attachmentsNav.switchToNext}
      onRotateClick={rotate}
      onClose={props.modal.close}
      downloadName={attachment.name}
      downloadUrl={attachmentsNav.downloadUrl}
      title={attachmentsNav.title}
      subTitle={attachmentsNav.subTitle}
      customControls={
        <ModalMediaViewerMenu
          downloadUrl={attachmentsNav.downloadUrl}
          closeModal={props.modal.close}
          attachment={attachmentsNav.attachment}
        />
      }
      description={
        attachmentsNav.attachment?.message?.text ? (
          <TextContentItem className="mb-0 text-truncate" message={attachmentsNav.attachment.message}></TextContentItem>
        ) : null
      }
      hideCloseButton={hideCloseButton}
      hideViewerControls={hideViewerControls || smallPlayer}
    >
      <Swiper onSwipeLeft={attachmentsNav.switchToPrev} onSwipeRight={attachmentsNav.switchToNext}>
        {transitions((styles, item) => (
          <animated.div key={item} style={styles} className="position-absolute-center">
            {typeof item !== 'undefined' ? renderMediaItem(item) : null}
          </animated.div>
        ))}
      </Swiper>
    </ModalMediaViewer>
  );
});

export default ModalMediaLightBox;
