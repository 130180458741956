import React from 'react';
import {observer} from 'mobx-react';

import Message from '../../../../stores/Message';
import ImageThumbnail from './ImageThumbnail';
import StickerThumbnail from './StickerThumbnail';

interface MessageThumbnailProps {
  message: Message;
  className?: string;
}

export const MessageThumbnail = observer(({className, message}: MessageThumbnailProps) => {
  const attachment = message.attachments?.[0];
  if (!attachment) return null;

  if (message.hasImage) {
    return (
      <ImageThumbnail
        className={className}
        attachment={attachment}
      />
    );
  } else if (message.hasSticker) {
    return (
      <StickerThumbnail
        className={className}
        attachment={attachment}
      />
    );
  }

  return null;
});

export default MessageThumbnail;
