import cn from 'classnames';
import {canCopyImagesToClipboard, copyImageToClipboard} from 'copy-image-clipboard';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import MediaViewerButton from 'o-ui/Dialog/ModalMediaViewer/MediaViewerButton';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import {useStore} from '../../stores/AppStore';
import {ChatInfoType} from '../../stores/LayOutStore';
// import {isChannelAllowToForwardMessage} from '../../stores/Channel';
import useMessageAttachment from '../../pages/Chat/UserChat/MessageContent/useMessageAttachment';
import MessageAttachment from '../../stores/Attachment/MessageAttachment';
import {canDownloadFileByFilePicker, downloadFile, downloadFileBySaveFilePicker} from '../../utils/downloadFile';

interface ModalMediaViewerMenuProps {
  attachment?: MessageAttachment | null;
  closeModal?: () => void;
  downloadUrl?: string;
}

export const ModalMediaViewerMenu: React.FC<ModalMediaViewerMenuProps> = observer((props) => {
  const app = useStore();
  const {t} = useTranslation();
  const menuRef = React.useRef<HTMLDivElement | null>(null);
  const {attachment} = props;
  const [menuOpened, setMenuOpened] = useState<boolean>(false);

  if (!attachment) {
    return null;
  }

  const {src, mimeType} = useMessageAttachment(attachment);

  const toggleMenu = () => {
    setMenuOpened(!menuOpened);
  };

  useOnClickOutside(menuRef, () => {
    setMenuOpened(false);
  });

  const handleCopyImageClick = () => {
    copyImageToClipboard(src || '')
      .then(() => {
        app.notifications.success(t('modal_media_viewer_image_copied_notification'));
        toggleMenu();
      })
      .catch((e) => {
        console.debug('Error: ', e.message);
        toggleMenu();
      });
  };

  // const handleForwardClick = () => {
  //   if (message) {
  //     toggleMenu();
  //     props.closeModal && props.closeModal();
  //     app.modals.open(ModalType.FORWARD, {chat: message.chat, messages: [message]});
  //   }
  // };

  // const handleDeleteClick = () => {
  //   if (message) {
  //     // app.chatStore.deleteMessage(message.chat, message);
  //     toggleMenu();
  //     props.closeModal && props.closeModal();
  //     app.notifications.success(t('Message deleted'));
  //   }
  // };

  // const goToMessageClick = () => {
  //   if (message) {
  //     // app.chatStore.activeUsersChat?.store?.navigateToMessage(message);
  //     toggleMenu();
  //     props.closeModal && props.closeModal();
  //   }
  // };

  const openChatInfo = (type: ChatInfoType) => {
    app.layOutStore.rightSideBar.chatInfoBarAnimationDisable();
    app.layOutStore.rightSideBar.openChatGallery(type, app.chatsView.activeUsersChat);
    toggleMenu();
    props.closeModal?.();
  };

  const canDownloadByFilePicker = canDownloadFileByFilePicker();

  const handleDownloadFile = async () => {
    if (src) {
      if (canDownloadByFilePicker) {
        try {
          await downloadFileBySaveFilePicker({url: src, fileName: attachment.name, mimeType: mimeType});
        } catch {
          app.notifications.error(t('service_message_save_file_error'));
        }
      } else {
        downloadFile({url: src, fileName: attachment.name});
      }
      toggleMenu();
    }
  };

  return (
    <div ref={menuRef}>
      <MediaViewerButton onClick={toggleMenu}>
        <i className="tg-icon-dots-menu-line" />
      </MediaViewerButton>
      <div className={cn('dropdown-menu dropdown-menu--top dropdown-menu--right', {'dropdown-menu--open': menuOpened})}>
        {/* <button type="button" className="dropdown-item d-flex" onClick={goToMessageClick}>
          {t('modal_media_viewer_go_to_message_btn')}
        </button> */}
        {src && canCopyImagesToClipboard() ? (
          <button type="button" className="dropdown-item d-flex" onClick={handleCopyImageClick}>
            {t('modal_copy_btn')}
          </button>
        ) : null}
        {/* {message && isChannelAllowToForwardMessage(message.chat.source) ? (
          <button type="button" className="dropdown-item d-flex" onClick={handleForwardClick}>
            {t('modal_media_viewer_forward_btn')}
          </button>
        ) : null} */}
        {/* <button type="button" className="dropdown-item d-flex" onClick={handleDeleteClick}>
          {t('modal_delete_btn')}
        </button> */}
        {src ? (
          <button type="button" className="dropdown-item d-flex" onClick={handleDownloadFile}>
            {canDownloadByFilePicker ? t('modal_media_viewer_save_as_btn') : t('modal_media_viewer_download_btn')}
          </button>
        ) : null}
        {attachment.isImage ? (
          <button type="button" className="dropdown-item d-flex" onClick={() => openChatInfo(ChatInfoType.IMAGES)}>
            {t('modal_media_viewer_view_all_images_btn')}
          </button>
        ) : null}
        {attachment.isVideo ? (
          <button type="button" className="dropdown-item d-flex" onClick={() => openChatInfo(ChatInfoType.VIDEOS)}>
            {t('modal_media_viewer_view_all_videos_btn')}
          </button>
        ) : null}
        {attachment?.isGif ? (
          <button type="button" className="dropdown-item d-flex" onClick={() => openChatInfo(ChatInfoType.GIFS)}>
            {t('modal_media_viewer_view_all_gifs_btn')}
          </button>
        ) : null}
        {attachment?.isSticker ? (
          <button type="button" className="dropdown-item d-flex" onClick={() => openChatInfo(ChatInfoType.STICKERS)}>
            {t('modal_media_viewer_view_all_stickers_btn')}
          </button>
        ) : null}
      </div>
    </div>
  );
});

export default ModalMediaViewerMenu;
