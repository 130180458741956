import React from 'react';
import {useTranslation} from 'react-i18next';

import FormLabel from 'o-ui/FormLabel';
import Channel from '../../../../stores/Channel';

import brandIcon from '../../../../assets/image-icons/brandIcon.svg';
import telegramIconSquared from '../../../../assets/image-icons/telegramBotIconSquared.svg';

interface IProps {
  title?: string;
  subTitle?: string;
  newChannel?: Channel | null;
}

export const TelegramBotCreationResult = (props: IProps) => {
  const {t} = useTranslation();

  return (
    <div className="channel-dialog-form__form custom-scroll d-flex flex-column">
      {props.title ? <div className="h1-bold color-body-primary">{props.title}</div> : null}
      {props.subTitle ? <div className="body1-regular color-body-primary pb-6">{props.subTitle}</div> : null}

      <div className="account-dialog-form__title-icons">
        <img className="account-dialog-form__tg-icon" src={telegramIconSquared} alt="" />
        <img className="account-dialog-form__ozekon-icon" src={brandIcon} alt="" />
      </div>
      <div className="h4-bold font-bold color-body-primary mt-3">
        {t('settings_channel_creation_form_success_connect')}
      </div>

      <FormLabel className="body3-regular text-capitalize d-flex mt-3 mb-1">
        {t('settings_channel_creation_form_channel_user_name_label')}
      </FormLabel>
      <p className="body1-regular m-0">{props.newChannel?.userName || '-'}</p>

      <FormLabel className="body3-regular text-capitalize d-flex mt-3 mb-1">
        {t('settings_channel_creation_form_channel_name_label')}
      </FormLabel>
      <p className="body1-regular m-0 mb-2">{props.newChannel?.name || '-'}</p>
    </div>
  );
};

export default TelegramBotCreationResult;
