import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useNavigate, useParams} from 'react-router-dom';
import Div100vh from '../../components/Div100vh';

import logotypeBlue from '../../assets/images/logotype.svg';
import logotypeWhite from '../../assets/images/logotype-white.svg';
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';

interface RecoveryPasswordOptionProps {
  title: string;
  subTitle: string;
  onClick?(): void;
}

const RecoveryPasswordOption: React.FC<RecoveryPasswordOptionProps> = ({title, subTitle, onClick}) => {
  return (
    <div className="recovery-password__option" onClick={onClick}>
      <div className="recovery-password__option-title">{title}</div>
      <div className="recovery-password__option-sub-title">{subTitle}</div>
      <div className="recovery-password__option-icon tg-icon-chevron-right-line" />
    </div>
  );
};

type RouteParams = {
  code?: string;
  email?: string;
};

export const RecoveryPasswordPage: React.FC = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {lightThemeChosen, userStore} = useStore();

  const workspaceNavigate = useWorkspaceNavigate();

  const {email = null, code = null} = useParams<RouteParams>();

  const logotype = lightThemeChosen ? logotypeBlue : logotypeWhite;

  const handleLogoutAndRecoveryPassword = async () => {
    await userStore.logout();

    navigate(generatePath(Paths.ResetPasswordNonAuth, {code, email}));
  };

  const handleGoToPersonalInfo = () => {
    workspaceNavigate(Paths.PersonalInfo);
  };

  return (
    <Div100vh className="recovery-password">
      <div className="recovery-password__inner">
        <div className="recovery-password__logo">
          <img className="recovery-password__logo-img" src={logotype} alt="" />
        </div>

        <div className="recovery-password__content">
          <div className="recovery-password__title h1-bold color-body-primary mt-auto mb-6">
            {t('recovery_password_title')}
          </div>
          <div className="mb-6">{t('recovery_password_subtitle')}</div>

          <RecoveryPasswordOption
            title={t('recovery_password_option_logout_title')}
            subTitle={t('recovery_password_option_logout_subtitle')}
            onClick={handleLogoutAndRecoveryPassword}
          />

          <RecoveryPasswordOption
            title={t('recovery_password_option_stay_logged_in_title')}
            subTitle={t('recovery_password_option_stay_logged_in_subtitle')}
            onClick={handleGoToPersonalInfo}
          />
        </div>
      </div>
    </Div100vh>
  );
});

export default RecoveryPasswordPage;
