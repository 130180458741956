import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {INLINE_RIGHT_SIDE_BAR_START_FROM} from '../constants';
import useMessageAttachment from '../pages/Chat/UserChat/MessageContent/useMessageAttachment';
import {useStore} from '../stores/AppStore';
import {ContextMenuItem} from '../stores/ContextMenuStore';
import {canDownloadFileByFilePicker, downloadFile, downloadFileBySaveFilePicker} from '../utils/downloadFile';

interface IProps {
  menu: ContextMenuItem;
}

export const MenuFile: React.FC<IProps> = observer((props) => {
  const {chatsView, layOutStore, notifications} = useStore();
  const {t} = useTranslation();

  const {attachment} = props.menu.data;
  const {src: attachmentSrc, mimeType} = useMessageAttachment(attachment);

  const closeChatSidebar = () => {
    if (window.innerWidth < INLINE_RIGHT_SIDE_BAR_START_FROM) {
      layOutStore.rightSideBar.close();
    }
  };

  const goToMessageClick = () => {
    if (attachment) {
      chatsView.activeUsersChat?.store?.navigateToMessage(attachment.message);
      props.menu.close();
      closeChatSidebar();
    }
  };

  const canDownloadByFilePicker = canDownloadFileByFilePicker();

  const handleDownloadFile = async () => {
    if (attachment && attachmentSrc) {
      if (canDownloadByFilePicker) {
        try {
          await downloadFileBySaveFilePicker({url: attachmentSrc, fileName: attachment.name, mimeType: mimeType});
        } catch {
          notifications.error(t('service_message_save_file_error'));
        }
      } else {
        downloadFile({url: attachmentSrc, fileName: attachment.name});
      }

      props.menu.close();
    }
  };

  const handleForwardClick = () => {
    console.debug('handleForwardClick not implemented yet');
    // if (message) {
    //   modals.open(ModalType.FORWARD, {chat: message.chat, messages: [message]});
    //   props.menu.close();
    //   closeChatSidebar();
    // }
  };

  return (
    <div className="dropdown-menu">
      <button type="button" className="dropdown-item d-flex" onClick={goToMessageClick}>
        {t('ctx_menu_file_go_to_message')}
      </button>
      {attachmentSrc ? (
        <button type="button" className="dropdown-item d-flex" onClick={handleDownloadFile}>
          {canDownloadByFilePicker ? t('ctx_menu_file_save_as') : t('ctx_menu_file_download')}
        </button>
      ) : null}
      {/* {message && isChannelAllowToForwardMessage(message.chat.source) ? ( */}
      <button type="button" className="dropdown-item d-flex" onClick={handleForwardClick}>
        {t('ctx_menu_file_forward')}
      </button>
      {/* ) : null} */}
    </div>
  );
});

export default MenuFile;
