import equal from 'fast-deep-equal';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import * as Yup from 'yup';
//import copy from 'copy-to-clipboard';

import FormHelperText from 'o-ui/FormHelperText';
import FormLabel from 'o-ui/FormLabel';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import Select, {SelectChangeEvent} from 'o-ui/Select';
//import Icon from 'o-ui/Icon';
//import {CopyTextInput} from 'o-ui/Input';

import {entities} from '../../../../api/proto';
import useMountedState from '../../../../hooks/useMountedState';
import useStore from '../../../../stores/useStore';
import {getUserRoles} from '../../../../stores/Workspaces';
import WorkspaceMember from '../../../../stores/Workspaces/WorkspaceMember';
import WorkspaceMemberConfig from '../../../../stores/Workspaces/WorkspaceMemberConfig';
import {formatUserName} from '../../../../utils/format';
import {ChannelsAndChatsAccess} from '../ChannelsAndChatsAccess';
import MemberStatus from '../MemberStatus';
import MemberConfigOptions from './MemberConfigOptions';


interface IProps {
  member: WorkspaceMember;
  setDisabledSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  onBeforeSubmit?: () => void;
  onSubmit?: (success: boolean) => void;
}

export interface IMemberManageFormRef {
  submitForm?: () => void;
}

export const MemberManageForm = observer(
  React.forwardRef<IMemberManageFormRef, IProps>((props: IProps, ref) => {
    const isMounted = useMountedState();
    const {t} = useTranslation();
    const {notifications, userStore} = useStore();
    const [apiError, setApiError] = React.useState<string | null | undefined>('');
    const [memberConfig, setMemberConfig] = React.useState<WorkspaceMemberConfig>(props.member.config.copy());
    const memberConfigRef = React.useRef<WorkspaceMemberConfig>(props.member.config.copy());
    const rolesList = getUserRoles();

    React.useEffect(() => {
      const copy = props.member.config.copy();

      if (!equal(memberConfigRef.current.permissions, props.member.permissions)) {
        memberConfigRef.current = copy;
      }

      setMemberConfig(copy);
    }, [props.member]);

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        role: props.member.role,
      },
      validationSchema: Yup.object({
        role: Yup.number(),
      }),
      onSubmit: async (values) => {
        props.onBeforeSubmit?.();
        setApiError('');
        let message: string | null = null;

        const {error} = await props.member.update({
          newRole: values.role,
          // newCustomPermissions: memberConfig.permissions,
          newAccess: {
            records: memberConfig.rawChannelAccessRecords,
          },
        });

        if (error) {
          message = error.message;
          notifications.error(error.message);
          setApiError(error.message);
        }

        if (!message) {
          notifications.success(
            <Trans>
              User access settings <strong>{props.member.email}</strong> changed successfully
            </Trans>,
          );
        }

        if (!isMounted()) {
          return;
        }
        props.onSubmit?.(!message);
      },
    });

    React.useEffect(() => {
      const isChatsAccessRecordsChanged =
        JSON.stringify(memberConfigRef.current.rawChannelAccessRecords) !==
        JSON.stringify(memberConfig.rawChannelAccessRecords);

      const formIsChanged =
        formik.values.role !== props.member.role ||
        !equal(memberConfigRef.current.permissions, memberConfig.permissions) ||
        isChatsAccessRecordsChanged;

      props.setDisabledSubmit(!formIsChanged);
    }, [memberConfig.permissions, memberConfig.rawChannelAccessRecords, props, formik.values.role]);

    React.useImperativeHandle(ref, () => ({
      submitForm: () => formik.submitForm(),
    }));

    const configDisabled = formik.values.role !== entities.WorkspaceRole.WR_OPERATOR;

    const handleChangeRole = (e: SelectChangeEvent) => {
      formik.handleChange(e);

      if (parseInt(e.target.value, 10) !== entities.WorkspaceRole.WR_OPERATOR) {
        memberConfig.enableAllPermissions();
        memberConfig.allowAllChannels();
      }
    };

    return (
      <div className="operator-manage">
        <div className="operator-manage__left">
          <form className="operator-manage__form" onSubmit={formik.handleSubmit}>
            {/*
            <p className="m-0">[Development mode]: Invite URL</p>
            <CopyTextInput
              copyBtnIcon={<Icon className="tg-icon-copy-line icon-size-20" />}
              type="text"
              className="w-100 mb-5"
              value={props.member.inviteUrl || props.member.emailInviteUrl}
              onCopyValue={(value) => {
                copy(value || '', {
                  format: 'text/plain',
                });
              }}
            />
          */}
            <div>
              <FormLabel className="w-100">{t('settings_workspace_members_manage_status')}</FormLabel>
            </div>
            <div>
              <MemberStatus member={props.member} />
            </div>

            <FormLabel className="mt-5">{t('settings_workspace_members_manage_full_name')}</FormLabel>
            <OutlinedTextInput
              className="w-100"
              name="firstName"
              value={formatUserName(props.member.firstName, props.member.lastName) || '-'}
              disabled
              errorHelper={' '}
            />

            <FormLabel>{t('settings_workspace_members_manage_email')}</FormLabel>
            <OutlinedTextInput className="w-100" name="email" value={props.member.email} disabled errorHelper={' '} />

            <Select
              name="role"
              className="w-100"
              label={t('settings_workspace_members_manage_role')}
              onChange={handleChangeRole}
              onBlur={formik.handleBlur}
              value={formik.values.role?.toString()}
              items={rolesList}
              errorHelper={formik.errors.role || ' '}
              required
              disabled={!userStore.isOwner}
            />

            <MemberConfigOptions
              memberConfig={memberConfig}
              // disabled={configDisabled}
              disabled
            />

            <FormHelperText className="mt-3" error>
              {apiError || ' '}
            </FormHelperText>
          </form>
        </div>

        <div className="operator-manage__right">
          <div className="operator-manage__chats-access-container">
            <ChannelsAndChatsAccess memberConfig={memberConfig} disabled={configDisabled} />
          </div>
        </div>
      </div>
    );
  }),
);

export default MemberManageForm;
