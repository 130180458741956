import React from 'react';
import useMountedState from 'o-ui/hooks/useMountedState';


type FN<R = unknown, F = ASYNC_FN> = (fn: F) => R;

type ASYNC_RESULT = Promise<unknown>;

type ASYNC_FN = (...args: unknown[]) => ASYNC_RESULT;

type UseLoadingWrapper = [
  FN<ASYNC_FN>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
];

export default function useLoadingWrapper(): UseLoadingWrapper {
  const [loading, setLoading] = React.useState<boolean>(false);
  const isMounted = useMountedState();

  const useLoadingRef = React.useRef<FN<ASYNC_FN>>((fn) => {
    return async (...args) => {
      setLoading(true);

      try {
        await fn(...args);
      } catch (error) {
        console.error(error);
      }

      if (isMounted()) {
        setLoading(false);
      }
    };
  });

  return [
    useLoadingRef.current,
    loading,
    setLoading,
  ];
}
