import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';

import Message from '../../../../stores/Message';
import {formatTime} from '../../../../utils/format';
import MessageDeliveryStatus from './MessageDeliveryStatus';

interface MessageMetaItemsProps {
  message: Message;
  messageGroup: Message[];
  pinnedMode?: boolean;
}

export default observer(function MessageMetaItems(props: MessageMetaItemsProps) {
  const {t} = useTranslation();
  return (
    <>
      {props.message.edited ? (
        <span className="message-status__edited">{t('chat_user_chat_message_edited_status')}</span>
      ) : null}
      {!props.pinnedMode && props.messageGroup.some((m) => m.pinned) ? (
        <i className="message-status__pinned tg-icon tg-icon-pin-fill" />
      ) : null}
      <span className="message-status__time ml-2 align-middle">{formatTime(props.message.stamp)}</span>
      
      <MessageDeliveryStatus message={props.message} />
    </>
  );
});
