import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {animated, useTransition} from 'react-spring';

import WorkspaceMember from '../../stores/Workspaces/WorkspaceMember';
import MemberManageTab from '../Settings/WorkspaceMembers/MemberManage/MemberManageTab';
import MembersAdministration from '../Settings/WorkspaceMembers/MembersAdministration';
import {MembersAdministrationTabs, OperatorStatus, OperatorType} from '../Settings/WorkspaceMembersTab';

enum Pages {
  MEMBERS = 0,
  MEMBER_SETTINGS = 2,
}

export const OperatorsInvitationStep = observer(() => {
  const {t} = useTranslation();
  const [selectedMember, setSelectedMember] = React.useState<WorkspaceMember | null>(null);

  const [prevPageIndex, setPrevPageIndex] = React.useState<Pages>(Pages.MEMBERS);
  const [pageIndex, setPageIndex] = React.useState<Pages>(Pages.MEMBERS);

  const [operatorsAdministrationActiveTab, setOperatorsAdministrationActiveTab] = React.useState(
    MembersAdministrationTabs.Members,
  );

  const [selectedOperatorTypeTab, setSelectedOperatorTypeTab] = React.useState(OperatorType.All);
  const [selectedOperatorStatusTab, setSelectedOperatorStatusTab] = React.useState(OperatorStatus.All);

  const openPage = React.useCallback((newPage: Pages, currentPage: Pages) => {
    setPrevPageIndex(currentPage);
    setPageIndex(newPage);
  }, []);

  const goBack = React.useCallback(() => {
    openPage(Pages.MEMBERS, Pages.MEMBER_SETTINGS);
  }, [openPage]);

  const handleSubmitMember = React.useCallback(() => {
    goBack();
    // operators.loadOperators();
  }, [goBack]);

  const openOperatorManage = React.useCallback(
    (member: WorkspaceMember) => {
      setSelectedMember(member);
      openPage(Pages.MEMBER_SETTINGS, Pages.MEMBERS);
    },
    [openPage],
  );

  const handleChangeOperatorsAdministrationTab = (newValue: MembersAdministrationTabs) => {
    setOperatorsAdministrationActiveTab(newValue);
  };

  const handleChangeOperatorTypeTab = (newTab: OperatorType) => {
    setSelectedOperatorTypeTab(newTab);
  };

  const handleChangeOperatorStatusTab = (newTab: OperatorStatus) => {
    setSelectedOperatorStatusTab(newTab);
  };

  const transitions = useTransition(pageIndex, {
    from: {
      opacity: 0,
      transform: prevPageIndex <= pageIndex ? 'translate3d(100%, 0, 0)' : 'translate3d(-100%, 0, 0)',
    },
    enter: {opacity: 1, transform: 'translate3d(0%, 0, 0)'},
    leave: {
      opacity: 0,
      transform: prevPageIndex <= pageIndex ? 'translate3d(-100%, 0, 0)' : 'translate3d(100%, 0, 0)',
    },
  });

  return (
    <>
      {transitions((styles, page) =>
        page === Pages.MEMBERS ? (
          <animated.div
            style={styles}
            className="animated-tab w-100 h-100 position-absolute operators-invitation custom-scroll overflow-auto"
          >
            <div className="h1-bold color-body-primary ml-7 mr-7 mb-6">{t('onboarding_operator_invitation_title')}</div>
            <MembersAdministration
              onChangeTab={handleChangeOperatorsAdministrationTab}
              selectedTab={operatorsAdministrationActiveTab}
              onOpenOperatorManage={openOperatorManage}
              selectedOperatorTypeTab={selectedOperatorTypeTab}
              selectedOperatorStatusTab={selectedOperatorStatusTab}
              onChangeOperatorTypeTab={handleChangeOperatorTypeTab}
              onChangeOperatorStatusTab={handleChangeOperatorStatusTab}
            />
          </animated.div>
        ) : page === Pages.MEMBER_SETTINGS ? (
          <animated.div style={styles} className="animated-tab w-100 h-100 position-absolute operators-invitation">
            <div className="h1-bold color-body-primary ml-7 mr-7 mb-6">{t('onboarding_operator_invitation_title')}</div>
            {selectedMember ? (
              <MemberManageTab
                className="h-100-72px"
                member={selectedMember}
                onSubmit={handleSubmitMember}
                onClose={goBack}
              />
            ) : null}
          </animated.div>
        ) : null,
      )}
    </>
  );
});

export default OperatorsInvitationStep;
