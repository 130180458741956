import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import TableCell from 'o-ui/TableCell';
import TableHead from 'o-ui/TableHead';
import TableRow from 'o-ui/TableRow';

import {entities} from '../../../../api/proto';
import {useStore} from '../../../../stores/AppStore';

interface ChannelsTableHeadProps {
  type?: entities.OzekonChannelType | null;
}

export const ChannelsTableHead: React.FC<ChannelsTableHeadProps> = observer((props) => {
  const {channels} = useStore();
  const {t} = useTranslation();

  if (props.type === entities.OzekonChannelType.OIT_WEB_WIDGET) {
    return (
      <TableHead>
        <TableRow>
          <TableCell>{t('settings_channels_table_cell_channel_name')}</TableCell>
          <TableCell>{t('settings_channels_table_cell_domain')}</TableCell>
          <TableCell>{t('settings_channels_table_cell_status')}</TableCell>
          <TableCell>{t('settings_channels_table_cell_last_changes_by')}</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <TableHead onClick={() => channels.load()}>
      <TableRow>
        <TableCell>{t('settings_channels_table_cell_phone')}</TableCell>
        <TableCell>{t('settings_channels_table_cell_channel_name')}</TableCell>
        <TableCell>{t('settings_channels_table_cell_user_name')}</TableCell>
        <TableCell>{t('settings_channels_table_cell_status')}</TableCell>
        <TableCell>{t('settings_channels_table_cell_last_connection')}</TableCell>
        <TableCell>{t('settings_channels_table_cell_last_changes_by')}</TableCell>
      </TableRow>
    </TableHead>
  );
});

export default ChannelsTableHead;
