import React from 'react';
import cn from 'classnames';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import Button from 'o-ui/Button';
import FormHelperText from 'o-ui/FormHelperText';
import Link from 'o-ui/Link';
import Select from 'o-ui/Select';

import {api, entities} from '../../../../api/proto';
import {useStore} from '../../../../stores/AppStore';
import {ModalType} from '../../../../stores/ModalType';
import {getUserRoles} from '../../../../stores/Workspaces';
import WorkspaceMemberConfig from '../../../../stores/Workspaces/WorkspaceMemberConfig';
import getCreateInviteError from '../../../../stores/Workspaces/utils/getCreateInviteError';
import {formatInviteUrl} from '../../../../utils/formatUrl';


interface IProps {
  onSubmit?: (inviteLink: api.IWorkspaceCreateInviteResponse) => void;
  onOpenInvitationAccess?(): void;
  memberConfig?: WorkspaceMemberConfig;
}

export const InvitationByLinkForm = observer((props: IProps) => {
  const {t} = useTranslation();
  const {notifications, modals, activeWorkspace, userStore} = useStore();
  const [apiError, setApiError] = React.useState<string | null | undefined>('');
  const [submiting, setSubmiting] = React.useState<boolean>(false);

  const rolesList = getUserRoles();

  const formik = useFormik({
    initialValues: {
      role: entities.WorkspaceRole.WR_OPERATOR,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      role: Yup.number().required(t('settings_workspace_members_invitation_role_required')),
    }),
    onSubmit: async (values, {resetForm}) => {
      setSubmiting(true);
      const {error, res} = await activeWorkspace.invites.invite({
        role: values.role,
        access: {
          records: props.memberConfig?.rawChannelAccessRecords,
        }
      });
      setSubmiting(false);

      if (error) {
        notifications.error(error.message);
      }
      if (res) {
        const statusErrorMessage = getCreateInviteError(res.status);

        if (statusErrorMessage) {
          notifications.error(statusErrorMessage);
          setApiError(statusErrorMessage);
          return;
        }

        modals.open(ModalType.CREATED_INVITATION_LINK, {invitationLink: formatInviteUrl(res?.invite?.id)});
        setApiError(null);

        notifications.success(t('settings_workspace_members_invitation_successfully_created_notification'));
        props.onSubmit?.(res);
        resetForm();
      }
    },
  });

  const handleOpenConfigDlg = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.onOpenInvitationAccess?.();
  };

  return (
    <form className="operator-invitation__form" onSubmit={formik.handleSubmit}>
      <div className="d-flex align-items-center color-body-primary">
        <Trans
          i18nKey="settings_workspace_members_invite_link_create_with_role"
          components={{
            select: (
              <Select
                name="role"
                size="small"
                className="mx-3"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.role}
                items={rolesList}
                required
                disabled={!userStore.isOwner}
              />
            ),
          }}
        />
      </div>

      <FormHelperText className="mb-2" error>
        {apiError || formik.errors.role || ' '}
      </FormHelperText>

      <div className="operator-invitation__row mt-2">
        <Link
          className={cn('operator-invitation__button', {
            invisible: formik.values.role !== entities.WorkspaceRole.WR_OPERATOR,
          })}
          component="button"
          color="primary"
          onClick={handleOpenConfigDlg}
        >
          {t('settings_workspace_members_invitation_settings_button')}
        </Link>
      </div>

      <div className="body2-regular color-body-secondary mt-2 mb-6">
        {t('settings_workspace_members_invite_link_expire_info')}
      </div>

      <div className="operator-invitation__footer">
        <Button
          className="operator-invitation__button"
          variant="contained"
          color="primary"
          type="submit"
          loading={submiting}
        >
          {t('settings_workspace_members_invite_links_create_button')}
        </Button>
      </div>
    </form>
  );
});

export default InvitationByLinkForm;
