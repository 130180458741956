import React, {Component} from 'react';


interface IPosition {
  x: number;
  y: number;
}

interface IPositionDelta {
  deltaX: number;
  deltaY: number;
}

function getPosition(event: TouchEvent): IPosition {
  const {pageX, pageY} = event.touches[0];
  return {x: pageX, y: pageY};
}

interface SwiperProps {
  tagName?: React.ElementType | string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  allowMouseEvents?: boolean;
  onSwipeUp?: (n: number, event: TouchEvent) => void;
  onSwipeDown?: (n: number, event: TouchEvent) => void;
  onSwipeLeft?: (n: number, event: TouchEvent) => void;
  onSwipeRight?: (n: number, event: TouchEvent) => void;
  onSwipeStart?: (event: TouchEvent) => void;
  onSwipeMove?: (delta: IPosition, event: TouchEvent) => void;
  onSwipeEnd?: (event: TouchEvent) => void;
  innerRef?: (node: HTMLElement) => void;
  tolerance?: number;
}

class Swiper extends Component<SwiperProps> {

  static displayName = 'Swiper';

  constructor(props) {
    super(props);
  }

  swiper?: HTMLElement;

  mouseDown: boolean = false;
  moving: boolean = false;
  moveStart?: IPosition | null;
  movePosition?: IPositionDelta | null;

  componentDidMount() {
    this.swiper?.addEventListener?.('touchmove', this._handleSwipeMove);
  }

  componentWillUnmount() {
    this.swiper?.removeEventListener?.('touchmove', this._handleSwipeMove, false);
  }

  _onMouseDown = (event) => {
    if (!this.props.allowMouseEvents) {
      return;
    }

    this.mouseDown = true;

    document.addEventListener('mouseup', this._onMouseUp);
    document.addEventListener('mousemove', this._onMouseMove);

    this._handleSwipeStart(event);
  };

  _onMouseMove = (event) => {
    if (!this.mouseDown) {
      return;
    }

    this._handleSwipeMove(event);
  };

  _onMouseUp = (event) => {
    this.mouseDown = false;

    document.removeEventListener('mouseup', this._onMouseUp);
    document.removeEventListener('mousemove', this._onMouseMove);

    this._handleSwipeEnd(event);
  };

  _handleSwipeStart = (event: TouchEvent) => {
    const {x, y} = getPosition(event);
    this.moveStart = {x, y};
    this.props.onSwipeStart?.(event);
  };

  _handleSwipeMove = (event: TouchEvent) => {
    if (!this.moveStart) {
      return;
    }
    const {x, y} = getPosition(event);
    const deltaX = x - this.moveStart.x;
    const deltaY = y - this.moveStart.y;
    this.moving = true;

    // handling the responsability of cancelling the scroll to
    // the component handling the event
    const shouldPreventDefault = this.props.onSwipeMove?.({
      x: deltaX,
      y: deltaY
    }, event);

    if (shouldPreventDefault && event.cancelable) {
      event.preventDefault();
    }

    this.movePosition = {deltaX, deltaY};
  };

  _handleSwipeEnd = (event: TouchEvent) => {
    this.props.onSwipeEnd?.(event);

    const {tolerance = 0} = this.props;

    if (this.moving && this.movePosition) {
      if (this.movePosition.deltaX < -tolerance) {
        this.props.onSwipeLeft?.(1, event);
      } else if (this.movePosition.deltaX > tolerance) {
        this.props.onSwipeRight?.(1, event);
      }
      if (this.movePosition.deltaY < -tolerance) {
        this.props.onSwipeUp?.(1, event);
      } else if (this.movePosition.deltaY > tolerance) {
        this.props.onSwipeDown?.(1, event);
      }
    }

    this.moveStart = null;
    this.moving = false;
    this.movePosition = null;
  };

  _setSwiperRef = (node: HTMLElement) => {
    this.swiper = node;
    this.props.innerRef?.(node);
  };

  render() {
    const {
      tagName: TagName = 'div',
      className,
      style,
      children,
      allowMouseEvents,
      onSwipeUp,
      onSwipeDown,
      onSwipeLeft,
      onSwipeRight,
      onSwipeStart,
      onSwipeMove,
      onSwipeEnd,
      innerRef,
      tolerance,
      ...props
    } = this.props;

    return (
      <TagName
        ref={this._setSwiperRef}
        onMouseDown={this._onMouseDown}
        onTouchStart={this._handleSwipeStart}
        onTouchEnd={this._handleSwipeEnd}
        className={className}
        style={style}
        {...props}
      >

        {children}

      </TagName>
    );
  }
}

export default Swiper;
