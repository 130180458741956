import {IMCTextEntities, MCTextEntities} from '../api/proto';
import {uint8ArrayToString} from './arrayUtils';

const getOpeningTag = (textEntity: IMCTextEntities) => {
  switch (textEntity.type) {
    case MCTextEntities.Type.BOLD:
      return '<b>';
    case MCTextEntities.Type.ITALIC:
      return '<i>';
    case MCTextEntities.Type.STRIKETHROUGH:
      return '<strike>';
    case MCTextEntities.Type.UNDERLINE:
      return '<u>';
    case MCTextEntities.Type.CODE:
      return '<pre>';
    case MCTextEntities.Type.LINK:
      if (textEntity.payload) {
        return `<a href="${uint8ArrayToString(textEntity.payload) || '#'}" target="_blank">`;
      }
      break;
    case MCTextEntities.Type.MENTION:
      return '<user>';
  }
  return '';
};

const getClosingTag = (textEntity: IMCTextEntities) => {
  switch (textEntity.type) {
    case MCTextEntities.Type.BOLD:
      return '</b>';
    case MCTextEntities.Type.ITALIC:
      return '</i>';
    case MCTextEntities.Type.STRIKETHROUGH:
      return '</strike>';
    case MCTextEntities.Type.UNDERLINE:
      return '</u>';
    case MCTextEntities.Type.CODE:
      return '</pre>';
    case MCTextEntities.Type.LINK:
      if (textEntity.payload) {
        return '</a>';
      }
      break;
    case MCTextEntities.Type.MENTION:
      return '</user>';
  }
  return '';
};

export const formatText = (text: string, textEntities?: IMCTextEntities[] | null): string => {
  if (!textEntities?.length) {
    return text;
  }

  let res = '';
  const hasEntries = textEntities.length > 0;
  const textLength = text.length;

  const openingTagsMap = {};
  const closingTagsMap = {};

  textEntities.forEach((e) => {
    if (e.offset !== undefined && e.offset !== null) {
      openingTagsMap[e.offset.toNumber()] = openingTagsMap[e.offset.toNumber()]
        ? openingTagsMap[e.offset.toNumber()] + getOpeningTag(e)
        : getOpeningTag(e);
    }
    if (e.offset !== undefined && e.offset !== null && e.length !== undefined && e.length !== null) {
      closingTagsMap[e.offset.toNumber() - 1 + e.length.toNumber()] = closingTagsMap[
        e.offset.toNumber() - 1 + e.length.toNumber()
      ]
        ? closingTagsMap[e.offset.toNumber() - 1 + e.length.toNumber()] + getClosingTag(e)
        : getClosingTag(e);
    }
  });

  for (let i = 0; i < textLength; i++) {
    try {
      if (hasEntries && openingTagsMap[i]) {
        res += openingTagsMap[i];
      }

      res += text[i];

      if (hasEntries && closingTagsMap[i]) {
        res += closingTagsMap[i];
      }
    } catch (error) {
      console.error(error);
    }
  }

  return res;
};

export const camelCaseToNormal = (camel: string) => {
  const camelCase = camel.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');

  let flat = '';

  camelCase.forEach((word, idx) => {
    flat = flat + (idx === 0 ? word.charAt(0).toUpperCase() : word.charAt(0).toLocaleLowerCase()) + word.slice(1) + ' ';
  });
  flat?.[0].toUpperCase();
  return flat;
};
