import {observer} from 'mobx-react';
import React from 'react';

import Div100vh, {use100vh} from '../../../../components/Div100vh';
import {useStore} from '../../../../stores/AppStore';
import RightSideBarType from '../../../../stores/LayOutStore/RightSideBarType';
import {setStyleProperty} from '../../../../utils/styleProperty';
import ChatInfoBar from './ChatInfoBar';
import MemberInfoBar from './MemberInfoBar';
import PeerInfoBar from './PeerInfoBar';

export const RightSideBar: React.FC<React.PropsWithChildren> = observer(() => {
  const height = use100vh();
  const {
    layOutStore: {rightSideBar},
  } = useStore();

  React.useEffect(() => {
    setStyleProperty('--right-sidebar-height', height ? `${height}px` : '100vh');
  }, [height]);

  const getPageContent = (page: RightSideBarType) => {
    switch (page) {
      case RightSideBarType.CHAT_INFO:
        return rightSideBar.chat ? <ChatInfoBar chat={rightSideBar.chat} /> : null;
      case RightSideBarType.PEER_INFO:
        return rightSideBar.peer ? <PeerInfoBar peer={rightSideBar.peer} /> : null;
      case RightSideBarType.MEMBER_INFO:
        return rightSideBar.member ? <MemberInfoBar member={rightSideBar.member} /> : null;
    }

    return null;
  };

  return (
    <Div100vh className="right-sidebar">
      <div className="right-sidebar__content">{getPageContent(rightSideBar.type)}</div>
    </Div100vh>
  );
});

export default RightSideBar;
