import React from 'react';
import {useTranslation} from 'react-i18next';
import AuthInfoSlider from './AuthInfoSlider';

import logotype from '../../assets/images/logotype-white.svg';

export const AuthInfo = () => {
  const {t} = useTranslation();

  return (
    <div className="auth-info">
      <div className="auth-info__logo">
        <img className="auth-info__logo-img" src={logotype} alt="" />
      </div>
      <div className="auth-info__logo-sub-title">{t('login_logo_subtitle')}</div>

      <AuthInfoSlider />
    </div>
  );
};

export default AuthInfo;
