import axios from 'axios';
import yaml from 'js-yaml';

const DOWNLOAD_APP_URL = '/downloads';
const WINDOWS_APP_INFO_URI = `${DOWNLOAD_APP_URL}/latest.yml`;
const LINUX_APP_INFO_URI = `${DOWNLOAD_APP_URL}/latest-linux.yml`;
const MAC_APP_INFO_URI = `${DOWNLOAD_APP_URL}/latest-mac.yml`;

const getAppInfoUrl = (platform: AppPlatform): string => {
  switch (platform) {
    case AppPlatform.WINDOWS:
      return WINDOWS_APP_INFO_URI;
    case AppPlatform.LINUX:
      return LINUX_APP_INFO_URI;
    default:
      return MAC_APP_INFO_URI;
  }
};

interface DesktopAppInfo {
  version?: string;
  path?: string;
  sha512?: string;
  releaseDate?: string;
  files?: Array<{
    url: string;
    sha512: string;
    size: number;
  }>;
}

export interface DesktopAppData extends DesktopAppInfo {
  url: string;
}

export enum AppPlatform {
  WINDOWS = 'windows',
  LINUX = 'linux',
  MAC = 'mac',
}

export const getAppInfo = async (platform: AppPlatform): Promise<DesktopAppData | null> => {
  const appInfoUrl = getAppInfoUrl(platform);

  try {
    const res = await axios.get(appInfoUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (typeof res.data === 'string') {
      const appInfo = yaml.load(res.data) as DesktopAppInfo | null;

      const appInfoExt: DesktopAppData = {
        ...appInfo,
        url: appInfo?.path ? `${DOWNLOAD_APP_URL}/${appInfo.path}` : '',
      }

      return appInfoExt;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};