import React from 'react';
import {SnackbarContent, useSnackbar} from '../plugins/notistack';

interface SnackActionProps {
  id: string | number;
  message: string | React.ReactNode;
}

const SnackAction = React.forwardRef<HTMLDivElement, SnackActionProps>((props, ref) => {
  const {closeSnackbar} = useSnackbar();

  const handleDismiss = React.useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} className="snack-action">
      <div className="snack-action__card">
        <div className="snack-action__message">{props.message}</div>
        <div className="snack-action__close-wrapper">
          <span
            className="snack-action__close-icon tg-icon-cross-line cursor-pointer icon-size-10"
            onClick={handleDismiss}
          />
        </div>
      </div>
    </SnackbarContent>
  );
});

SnackAction.displayName = 'SnackAction';

export default SnackAction;
