
export enum RawMessagesStoreEvent {
  ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE',
  UPDATE_MESSAGE = 'UPDATE_MESSAGE',
  REMOVE_MESSAGE = 'REMOVE_MESSAGE',
  CLEAR = 'CLEAR',
  DESTROY = 'DESTROY',
}

export default RawMessagesStoreEvent;
