import React from 'react';
import {ModalItem} from '../stores/ModalsStore';
import {useTranslation} from 'react-i18next';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';
import Button from 'o-ui/Button';
import CustomizedDialogTitle from 'o-ui/Dialog/CustomizedDialogTitle';
import DialogContent from 'o-ui/Dialog/DialogContent';
import DialogActions from 'o-ui/Dialog/DialogActions';

interface IProps {
  modal: ModalItem;
}

export const ModalConfirm = (props: IProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);
    await props.modal.trigger('submit');
    setLoading(false);
    handleCloseClick();
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  const handleCancelClick = () => {
    if (props.modal.data.callbacks?.cancel) {
      props.modal.trigger('cancel');
    } else {
      handleCloseClick();
    }
  };

  return (
    <ModalDialog ref={modalRef} modal={props.modal} maxWidth={props.modal.data.maxWidth || 'xs'} fullWidth={true}>
      {props.modal.data?.headerText ? (
        <CustomizedDialogTitle className="h3-medium" onClose={handleCloseClick}>
          {props.modal.data.headerText}
        </CustomizedDialogTitle>
      ) : null}
      <DialogContent className="p-6 pt-7 body1-regular custom-scroll-y">
        {props.modal.data?.title}

        {props.modal.data?.content}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={props.modal.data?.submitButtonColor || 'error'}
          size="large"
          onClick={handleSubmit}
          loading={loading}
        >
          {props.modal.data?.submitButtonText ? props.modal.data.submitButtonText : t('modal_submit_btn')}
        </Button>
        <Button
          variant="contained"
          color={props.modal.data?.cancelButtonColor || 'secondary'}
          size="large"
          autoFocus
          onClick={handleCancelClick}
        >
          {props.modal.data?.cancelButtonText ? props.modal.data.cancelButtonText : t('modal_cancel_btn')}
        </Button>
      </DialogActions>
    </ModalDialog>
  );
};

export default ModalConfirm;
