import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import CustomizedDialogTitle from 'o-ui/Dialog/CustomizedDialogTitle';
import DialogContent from 'o-ui/Dialog/DialogContent';

import useWorkspaceNavigate from '../hooks/useWorkspaceNavigate';
import Paths from '../routes/Paths';
import isFreeTariff from '../stores/Billing/utils/isFreeTariff';
import ModalType from '../stores/ModalType';
import {ModalItem} from '../stores/ModalsStore';
import useStore from '../stores/useStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

import warningIcon from '../assets/images/warning-square-with-triangle.svg';

interface IProps {
  modal: ModalItem;
}

export const ModalApplyTariffWarning = observer(({modal}: IProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const {activeWorkspace: {billing, membersCount}, anal, modals} = useStore();
  const {recomendedTariff} = billing;

  const workspaceNavigate = useWorkspaceNavigate();

  const tariff = modal.data.tariff;
  const membersDiff = membersCount - (tariff?.includedUsers?.toNumber() || 0);
  const recomendedMembersDiff = membersCount - (recomendedTariff?.includedUsers?.toNumber() || 0);

  const handlePayForTariff = async () => {
    if (!tariff) {
      return;
    }

    anal.onboardingSubscribe(tariff);

    const {error, res} = await billing.payForTariff(tariff?.id);

    if (!error) {
      handleCloseClick();
    }

    if (!error && !res?.paymentLink && isFreeTariff(tariff)) {
      modals.open(ModalType.BILLING_FREE_PLAN_ACTIVATED, {tariff});
    }
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  const handlePayForRecomendedTariff = async () => {
    tariff && anal.onboardingSubscribe(tariff);
    const {error, res} = await billing.payForTariff(recomendedTariff?.id);

    if (!error) {
      handleCloseClick();
    }

    if (!error && !res?.paymentLink) {
      workspaceNavigate(Paths.Chat);
    }
  };

  return (
    <ModalDialog
      ref={modalRef}
      modal={modal}
      maxWidth={modal.data.maxWidth || 'sm'}
      fullWidth={true}
    >
      <CustomizedDialogTitle
        className="align-items-start"
        onClose={handleCloseClick}
      >
        <div className="d-flex flex-column">
          <img className="mr-auto" src={warningIcon} alt="" style={{width: 48}} />
          <div className="h3-medium mt-5">{t('Warning')}</div>
          <div className="body1-regular mt-1">
            {t('You have invited more users than the selected plan allows')}
          </div>
        </div>
      </CustomizedDialogTitle>

      <DialogContent className="px-6 custom-scroll-y">
        <div className="label-2 color-body-tertiary mt-5 text-uppercase">{t('Options:')}</div>

        <div className="bg-background-secondary radius-12 p-5 d-flex flex-column mt-3">
          <div className="h5-bold">{t('Continue with {{tariff}}', {tariff: tariff?.name})}</div>
          <div>{t('{{number}} invited users will be blocked', {number: membersDiff})}</div>
        </div>

        <div className="bg-brand-tint color-brand-primary radius-12 p-5 d-flex flex-column mt-3">
          <div className="h5-bold">{t('Upgrade to {{tariff}}', {tariff: recomendedTariff?.name})}</div>
          <div>
            {recomendedMembersDiff > 0 ?
              t('Only {{number}} invited users will be blocked', {number: recomendedMembersDiff}) :
              t('All invited users remain active')}
          </div>
        </div>
      </DialogContent>

      <div className="d-flex pt-5 px-6 pb-6">
        <Button
          className="flex-grow-1 mr-3"
          variant="outlined"
          color="tertiary"
          size="large"
          onClick={handlePayForTariff}
          loading={billing.payForTariffInProgress === tariff?.id}
          autoFocus
        >
          {t('Continue with {{tariff}}', {tariff: tariff?.name})}
        </Button>
        <Button
          className="flex-grow-1"
          variant="contained"
          color="primary"
          size="large"
          onClick={handlePayForRecomendedTariff}
          loading={billing.payForTariffInProgress === recomendedTariff?.id}
        >
          {t('Upgrade to {{tariff}}', {tariff: recomendedTariff?.name})}
        </Button>
      </div>
    </ModalDialog>
  );
});

export default ModalApplyTariffWarning;