import qs from 'query-string';
import {generatePath} from 'react-router';

import Paths from '../../../routes/Paths';
import {uint8ArrayToBase64} from '../../../utils/arrayUtils';
import ChannelScreenType from '../ChannelScreenType';

const INSTAGRAM_CLIENT_ID = '3759728007577861';
const INSTAGRAM_BASE_URL = 'https://www.instagram.com/oauth/authorize'
const INSTAGRAM_AUTH_RESPONSE_TYPE = 'code';
const INSTAGRAM_PERMISSION_SCOPE=['business_basic','business_manage_messages'];
/*
const FACEBOOK_BASE_URL = 'https://www.facebook.com/dialog/oauth'
const FACEBOOK_CLIENT_ID = '522032993499340';
const FACEBOOK_DISPLAY = 'page'
const FACEBOOK_AUTH_RESPONSE_TYPE = 'token';
const FACEBOOK_PERMISSION_SCOPE=['instagram_basic,','instagram_business_manage_messages','instagram_manage_messages'];
*/
export const formatInstagramAuthLink = (workspaceId?: Uint8Array | null): string => {
  const redirectPath = generatePath(Paths.ChannelsInstagramCallback);
  const state = {
    workspaceId: encodeURIComponent(uint8ArrayToBase64(workspaceId)),
    channelType: 'instagram',
    channelScreenType: ChannelScreenType.CALLBACK,
  };
  const stateJson = JSON.stringify(state);
  const redirectUri = `${location.origin}${redirectPath}`;
  /*
  const oauthUrl = qs.stringifyUrl({
    url: FACEBOOK_BASE_URL,
    query: {
      client_id: FACEBOOK_CLIENT_ID,
      display:FACEBOOK_DISPLAY,
      response_type:FACEBOOK_AUTH_RESPONSE_TYPE,
      redirect_uri: redirectUri,
      state: stateJson,
      scope: FACEBOOK_PERMISSION_SCOPE.join(','),
    },
  });
 */
  const oauthUrl = qs.stringifyUrl({
    url: INSTAGRAM_BASE_URL,
    query: {
      enable_fb_login:0,
      force_authentication:1,
      client_id: INSTAGRAM_CLIENT_ID,
      response_type:INSTAGRAM_AUTH_RESPONSE_TYPE,
      redirect_uri: redirectUri,
      state: stateJson,
      scope: INSTAGRAM_PERMISSION_SCOPE.join(','),
    },
  });

  console.debug('InstagramAuthLink=', oauthUrl);
  return oauthUrl;
};

export default formatInstagramAuthLink;