import {observer} from 'mobx-react';
import React from 'react';

import useDebouncedCallback from 'o-ui/hooks/useDebouncedCallback';
import Chat from '../../../../stores/Chat';
import MessageDayTitle from '../MessageDayTitle';

interface ChatMessagesDayTitleProps {
  chat: Chat;
}

export const ChatMessagesDayTitle: React.FC<ChatMessagesDayTitleProps> = observer(({chat}) => {
  const [isScrolling, setIsScrolling] = React.useState<boolean>(false);

  const resetScrolling = useDebouncedCallback(() => {
    setIsScrolling(false);
  }, 2000);

  React.useEffect(() => {
    if (chat.store?.scrolling && ['backwards', 'forwards'].includes(chat.store.scrolling)) {
      setIsScrolling(true);
    } else if (chat.store?.scrolling === null) {
      resetScrolling();
    }
  }, [chat.store?.scrolling, resetScrolling]);

  /*
    React.useEffect(() => {
      const emulateScrolling = async () => {
        console.debug(`setIsScrolling true`);
        setIsScrolling(true);
        await new Promise((resolve) => setTimeout(resolve, 10000));
        console.debug(`setIsScrolling false`);
        setIsScrolling(false);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        emulateScrolling();
      };
  
      emulateScrolling();
    }, []);
  */
  const targetMessage = chat.store?.firstVisibleMessage;

  return targetMessage?.localID?.greaterThan(1) && isScrolling ? (
    <div
      style={{
        height: 0,
        position: 'absolute',
        top: 16,
        left: 0,
        right: 0,
        margin: 'auto',
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <MessageDayTitle message={targetMessage} />
    </div>
  ) : null;
});

export default ChatMessagesDayTitle;
