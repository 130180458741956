import React from 'react';
import {observer} from 'mobx-react';
import {animated, useTransition} from 'react-spring';

import useSize from 'o-ui/hooks/useSize';
import {useStore} from '../../../../../stores/AppStore';
import {Chat, ChatClickEventHandler} from '../../../../../stores/Chat';
import {ChatsFilterType} from '../../../../../stores/ChatsView/ChatsFilterStore';
import {MessageClickEventHandler} from '../../../../../stores/Message';
import ChatsFoundList from './ChatsFoundList';
import ChatsVirtualizedList from './ChatsVirtualizedList';
import useSelectedChatInFocus from './useSelectedChatInFocus';

interface TransitionItem {
  storeKey: string;
  hidden?: boolean;
  filterType: ChatsFilterType;
}

interface ChatsListTransitionalProps {
  chatForSearch?: Chat;
  onCloseChatForSearch?: (chat: Chat) => void;
  onChatClick?: ChatClickEventHandler;
  searchText?: string;
  onFoundMessageClick?: MessageClickEventHandler;
  onScrolledToTop?: () => void;
  onScrolledToBottom?: () => void;
}

export const ChatsListTransitional: React.FC<ChatsListTransitionalProps> = observer((props) => {
  const squareRef = React.useRef<HTMLDivElement | null>(null);
  const size = useSize(squareRef);
  const {chatsView} = useStore();

  const currentFilterType = React.useRef<ChatsFilterType>(ChatsFilterType.SEARCH_ONLY);
  const prevStoreKey = React.useRef<TransitionItem | null>(null);
  const [currentTransitionItem, setCurrentTransitionItem] = React.useState<TransitionItem | null>(null);

  React.useEffect(() => {
    const newStoreKey = (props.searchText || props.chatForSearch) ? `search_${props.searchText || ''}${props.chatForSearch?.id || ''}` : chatsView.chatsStoreKey;

    if (newStoreKey !== currentTransitionItem?.storeKey) {
      currentFilterType.current = chatsView.filter.type;
      prevStoreKey.current = currentTransitionItem;
      setCurrentTransitionItem({
        storeKey: newStoreKey,
        filterType: chatsView.filter.type,
      });
    }
  }, [
    currentTransitionItem,
    prevStoreKey,
    props.searchText,
    props.chatForSearch,
    chatsView.chatsStoreKey,
    chatsView.filter.type,
  ]);

  const [listRef, setListRendered] = useSelectedChatInFocus({chats: chatsView.chats});

  const transitions = useTransition(currentTransitionItem, {
    key: currentTransitionItem?.storeKey,
    from: currentTransitionItem?.storeKey === prevStoreKey.current?.storeKey ? {opacity: 1} : {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
    config: {duration: 200},
  });

  return (
    <div
      ref={squareRef}
      style={{maxHeight: '100%', width: '100%'}}
      className="chat-message-list chats-tab__content-list"
    >
      {transitions((styles, item) => {
        // for hidding outgoing list width diferrent filter type
        // because we can't use leave animation for this
        // because VariableSizeList rerender when height is different
        const visible = !prevStoreKey.current ||
          !(item?.storeKey === prevStoreKey.current.storeKey && currentTransitionItem?.filterType !== prevStoreKey.current.filterType);
        return item?.storeKey && visible ? (
          <animated.div key={item.storeKey} style={styles} className="animated-tab">
            {item.storeKey.startsWith('search_') ? (
              <ChatsFoundList
                key={item.storeKey}
                width={size?.width || 0}
                height={size?.height || 0}
                itemCount={chatsView.foundItems.length}
                chatForSearch={props.chatForSearch}
                onCloseChatForSearch={props.onCloseChatForSearch}
                onChatClick={props.onChatClick}
                onFoundMessageClick={props.onFoundMessageClick}
              />
            ) : (
              <ChatsVirtualizedList
                key={item.storeKey}
                ref={listRef}
                width={size?.width}
                height={size?.height}
                storeKey={item.storeKey}
                chatsCount={chatsView.getChats(item.storeKey).length}
                onChatClick={props.onChatClick}
                onListRendered={setListRendered}
                onScrolledToTop={props.onScrolledToTop}
                onScrolledToBottom={props.onScrolledToBottom}
              />
            )}
          </animated.div>
        ) : null
      })
      }
    </div>
  );
});

export default ChatsListTransitional;
