import Long from 'long';
import {action, computed, observable} from 'mobx';

import {NetworkErrorResponse} from '../../api/network';
import {
  IMCMessage,
  IMCMethodMarkReadResponse,
  IMCMethodRestrictChatAccessResponse,
  MCChatPreview,
  MCMethodMarkRead,
  MCMethodRestrictChatAccess,
} from '../../api/proto';
import Message from '../Message';
import MessagePreview from '../Message/MessagePreview';
import {convertToMessagePreview} from '../Message/utils';
import {IRawChat} from '../RawChatsStore/RawChat';
import ChatAssignment from './ChatAssignment';
import ChatBase from './ChatBase';
import ChatPermissions from './ChatPermissions';
import ChatStore from './ChatStore';
import getUnreadCount from './utils/getUnreadCount';

const TTL_AUTO_PURGE = 2 * 60 * 60 * 1000; // 2 hours

export type ChatClickEventHandler<T = Chat> = (chat: T) => void;

export class Chat extends ChatBase {
  instanceId: number = new Date().getTime() + Math.random();

  permissions: ChatPermissions = new ChatPermissions(this);
  assignment: ChatAssignment = new ChatAssignment(this);

  @computed get pinned() {
    return false;
  }

  @computed get displayTitle() {
    const _title = (this.alternativeName && this.showAlternativeName ? this.alternativeName : this.name) || '';

    return _title;
  }

  @computed get unreadCount() {
    return getUnreadCount(this);
  }

  @computed get firstUnreadInboxMessageId(): Long | null {
    const inReadPointer = this.inReadPointer || Long.fromNumber(0);

    if (this.lastMessage?.localID?.equals(inReadPointer)) {
      return null;
    }

    return inReadPointer.add(1);
  }

  @observable private lastChatMessagePreview_?: MessagePreview | null;
  @observable lastChatMessage?: Message | null;

  get lastChatMessagePreview() {
    if (!this.lastChatMessagePreview_) {
      this.initLastChatMessagePreview_();
    }

    return this.lastChatMessagePreview_;
  }

  get lastChatMessageId() {
    return this.lastChatMessagePreview?.localID || null;
  }

  @action private initLastChatMessagePreview_ = () => {
    if (this.lastMessage) {
      this.lastChatMessagePreview_ = new MessagePreview(this.lastMessage, this, this.lastMessageSentAt);
      this.initLastChatMessage_();
    }
  };

  @action private initLastChatMessage_ = () => {
    if (this.lastMessage) {
      const rawMessage: IMCMessage = {
        chatID: this.id,
        localID: this.lastMessage.localID,
        peer: this.lastMessage.peer,
        serviceMessage: this.lastMessage.serviceMessage,
        attachments: this.lastMessage.attachments,
        operatorID: this.lastMessage.operatorID,
        text: this.lastMessage.text,
      };

      this.lastChatMessage = new Message(rawMessage, this);
    }
  };

  @action updateLastMessage = (message: IMCMessage) => {
    this.lastMessage = convertToMessagePreview(message);
    if (message.sentAt) {
      this.lastMessageSentAt = message.sentAt;
    }
    this?.initLastChatMessagePreview_();
  };

  public update = (props: IRawChat) => {
    console.debug('%c----->chat.update', 'color: #afb0b6', props);
    this.assign_(this, props);
    Object.assign(this.raw, props);
    this?.initLastChatMessagePreview_();

    if (this._store && props.attachmentGroupSizes) {
      this._store?.reinitChatHistory();
    }
  };

  @action setDraft = (draft: string) => {
    console.debug(`TODO: not implemented method "setDraft" to chat`, draft);
    this.update({...this, draft});
  };

  public markAsRead = async () => {
    const {error, res} = await this.channel.channelsRequest<IMCMethodMarkReadResponse>(
      {
        markRead: new MCMethodMarkRead({
          channel: {channelID: this.channel.id},
          chatID: this.id,
          messageID: this.lastChatMessageId,
        }),
      },
      'markRead',
    );

    return {error, res: res};
  };

  public restrictChatAccess = async (peerID?: Long | null, until?: Long | null) => {
    if (!peerID) {
      return new NetworkErrorResponse(`PeerID: [${peerID}] not found on restrict chat access request`);
    }

    const {error, res} = await this.channel.channelsRequest<IMCMethodRestrictChatAccessResponse>(
      {
        restrictChatAccess: new MCMethodRestrictChatAccess({
          channelID: this.channel.id,
          chatID: this.id,
          peerID,
          until,
        }),
      },
      'restrictChatAccess',
    );

    return {error, res};
  };

  get isSuperGroup(): boolean {
    return this.type === MCChatPreview.Type.SUPERGROUP;
  }

  get isGroup(): boolean {
    return this.type === MCChatPreview.Type.GROUP;
  }

  get isPrivate(): boolean {
    return this.type === MCChatPreview.Type.PRIVATE;
  }

  get isChannel(): boolean {
    return this.type === MCChatPreview.Type.CHANNEL;
  }

  get isTyping(): boolean {
    return false;
  }

  @observable _store?: ChatStore | null;

  get store(): ChatStore {
    if (this._store) {
      this.runAutoPurgeTimer();
    } else {
      return this.initStore();
    }

    return this._store;
  }

  @action initStore = () => {
    if (!this._store) {
      this._store = new ChatStore(this, this.channel);
      this.runAutoPurgeTimer();
    }
    return this._store;
  };

  @action close = () => {
    this.store?.close();

    this.runAutoPurgeTimer();
  };

  autoPurgeTimer?: NodeJS.Timeout | null;

  runAutoPurgeTimer = () => {
    this.stopAutoPurgeTimer();
    this.autoPurgeTimer = setTimeout(() => {
      this.purge();
    }, TTL_AUTO_PURGE);
  };

  stopAutoPurgeTimer = () => {
    if (this.autoPurgeTimer) {
      clearTimeout(this.autoPurgeTimer);
    }
    this.autoPurgeTimer = null;
  };

  @action purge = () => {
    if (this._store?.active) {
      return;
    }

    this._store?.purge();
    this._store = undefined;
    this.lastChatMessagePreview_ = undefined;
    this.lastChatMessage = undefined;
  };

  restore = () => {
    this.store?.restore();
  };

  init = async () => {
    await this.store?.init();
    this.restore();
  };
}

export default Chat;
