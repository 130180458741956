import {action, makeAutoObservable, observable} from 'mobx';
import browserStorage from '../../utils/browserStorage';

export enum AppTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

const THEME_STORAGE_KEY = 'theme';

export class AppThemeStore {
  constructor() {
    makeAutoObservable(this);
  }

  @observable lightThemeChosen = true;

  @action private setCurrentTheme_ = (theme: AppTheme) => {
    const light = theme !== AppTheme.DARK;
    if (this.lightThemeChosen === light) {
      return;
    }

    this.lightThemeChosen = light;
    try {
      browserStorage.setItem(THEME_STORAGE_KEY, theme !== AppTheme.DARK ? AppTheme.LIGHT : AppTheme.DARK);
    } catch (e) {
      console.warn(e);
    }
  };

  init = () => {
    try {
      const theme = browserStorage.getItem(THEME_STORAGE_KEY) !== 'dark' ? AppTheme.LIGHT : AppTheme.DARK;
      this.setCurrentTheme_(theme);
    } catch (e) {
      console.warn(e);
    }
  };

  public toggleCurrentTheme = () => {
    this.setCurrentTheme_(!this.lightThemeChosen ? AppTheme.LIGHT : AppTheme.DARK);
  };

  public switchOnLightTheme = () => {
    if (!this.lightThemeChosen) {
      this.setCurrentTheme_(AppTheme.LIGHT);
    }
  };
}

export default AppThemeStore;
