import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {MAX_REPLY_MESSAGE_INNER_WIDTH} from '../../../constants';
import {IMCMessagePreview} from '../../../api/proto';
import Chat from '../../../stores/Chat';
import Message from '../../../stores/Message';
import MessagePreview from '../../../stores/Message/MessagePreview';
import {MessageContent} from './MessageContent/MessageContent';
import {TextContentItem} from './TextContent/TextContentItem';
import {useReplyMessageSize} from './useMessageSize';

interface IProps {
  chat: Chat;
  message: Message;
  replyToMessage: IMCMessagePreview;
}

export const MessageReplyInfo: React.FC<IProps> = observer(({chat, message, replyToMessage}) => {
  const {t} = useTranslation();
  const [messagePreview] = React.useState<MessagePreview>(new MessagePreview(replyToMessage, chat));
  const messageTextRef = React.useRef<HTMLDivElement>(null);

  const navigateToReply = () => {
    chat.store?.navigateToMessageByID(messagePreview.localID);
  };

  let typeTitles = '';
  if (messagePreview.hasImage) {
    typeTitles = t('Photo');
  } else if (messagePreview.hasVideo) {
    typeTitles = t('Video');
  }

  const messageSize = useReplyMessageSize(messagePreview, MAX_REPLY_MESSAGE_INNER_WIDTH);

  return (
    <div
      className={cn('reply-content', {
        deleted: messagePreview.deleted,
      })}
      onClick={messagePreview.deleted ? undefined : navigateToReply}
    >
      {messagePreview.deleted ? (
        <p className="mb-0 text-left">{t('Deleted message')}</p>
      ) : (
        <div className="reply-content__inner" style={messageSize.style}>
          {typeTitles ? (
            <div className="d-flex">
              <MessageContent
                className="message-content mr-3"
                message={messagePreview}
                attachment={messagePreview.firstAttachment}
                autoSize={true}
                playButton={false}
                reply={message}
              />
              <div>
                <div className="message__title mb-0 text-truncate">{messagePreview.senderName}</div>
                <div className="message__type">{typeTitles}</div>
              </div>
            </div>
          ) : (
            <>
              <div className="message__title mb-0">{messagePreview.senderName}</div>
              <MessageContent
                message={messagePreview}
                attachment={messagePreview.firstAttachment}
                fixedSize={true}
                reply={message}
              />
            </>
          )}

          {messagePreview.text || messagePreview.serviceMessageText ? (
            <div
              className={cn('message-text', {
                'with-text-selection': false,
              })}
              ref={messageTextRef}
            >
              <TextContentItem
                className="mb-0"
                message={messagePreview}
                containerRef={messageTextRef}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
});

export default MessageReplyInfo;
