import React from 'react';
import {useNavigate} from 'react-router';

import Paths from '../../routes/Paths';
import SettingsTab from '../../stores/LayOutStore/SettingsTab';
import SettingsMenuItem from './SettingsMenu/SettingsMenuItem';

interface DownloadAppButtonProps {
  className?: string;
}

const DownloadAppButton: React.FC<DownloadAppButtonProps> = ({className}) => {
  const navigate = useNavigate();

  const handleClick = (path: Paths) => {
    navigate(path);
  };

  return <SettingsMenuItem className={className} tab={SettingsTab.DOWNLOAD_APP} onClick={handleClick} />;
};

export default DownloadAppButton;
