import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import Message from '../../../../stores/Message';
import MessagePreview from '../../../../stores/Message/MessagePreview';
import {getMessageServiceText} from '../../../../stores/Message/utils';
import TextContentTree from './TextContentTree';

interface IProps {
  children?: React.ReactNode;
  className?: string | null;
  message: Message | MessagePreview;
  // textSelection?: com.chatman.ITextSelection | null;
  containerRef?: React.RefObject<HTMLDivElement | null>;
}

export const TextContentItem: React.FC<IProps> = observer(({children, className, message, containerRef}) => {
  // const textEntities = props.textSelection
  //   ? [
  //     new com.chatman.TextEntity({
  //       teType: com.chatman.TextEntityType.TET_BOLD,
  //       offset: props.textSelection?.offset,
  //       length: props.textSelection?.length,
  //     }),
  //   ]
  //   : props.message.textEntities;

  const text = message.serviceMessage ? getMessageServiceText(message.serviceMessage, message) : message.text;

  return (
    <pre
      className={cn('text-content', className, {
        // 'with-text-selection': props.textSelection && Object.keys(props.textSelection).length,
      })}
    >
      <TextContentTree
        text={text || ''}
        textEntities={message.textEntities || []}
        startIndex={0}
        level={0}
        // textSelection={props.textSelection}
        containerRef={containerRef}
      />
      {children}
    </pre>
  );
});

export default TextContentItem;
