import cn from 'classnames';
import React from 'react';

import LottiePlayer from '../../../../components/Lottie/LottiePlayer';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import calcImageCoverSize from '../../../../utils/image/calcImageCoverSize';
import useMessageAttachment from '../MessageContent/useMessageAttachment';

export interface StickerThumbnailProps {
  className?: string;
  attachment: MessageAttachment;
}

export const StickerThumbnail: React.FC<StickerThumbnailProps> = React.memo((props) => {
  const {src, width, height} = useMessageAttachment(props.attachment, true);

  const [calcWidth, calcHeight] = calcImageCoverSize(
    width || 0,
    height || 0,
    32,
    32,
    false,
  );

  return (
    <div
      className={cn('image-thumbnail', props.className)}
      data-attachment-uuid={props.attachment.uuid}
    >
      <div className={cn('image-thumbnail__sticker-bg')} style={{backgroundImage: `url(${src})`}} />
      <LottiePlayer
        className={cn(
          'image-thumbnail__img',
          `attachment-uuid-${props.attachment.uuid}`,
        )}
        style={{width: calcWidth, height: calcHeight}}
        url={src || ''}
        play={true}
      />
    </div>
  );
});

export default StickerThumbnail;
