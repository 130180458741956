import {useFormik} from 'formik';
import Long from 'long';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import FormHelperText from 'o-ui/FormHelperText';
import FormLabel from 'o-ui/FormLabel';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';

import {IMCWebWidgetConfig} from '../../../../api/proto';
import {
  MAX_FORM_FIELD_LENGTH,
  WEB_WIDGET_DEFAULT_CHAT_BUTTON_SIZE,
  WEB_WIDGET_POSIBLE_CHAT_BUTTON_SIZES,
} from '../../../../constants';
import ColorSelector from '../../../../components/ColorSelector';
import FileInputUploader from '../../../../components/FileUploader/FileInputUploader';
import useAttachment from '../../../../hooks/useAttachment';
import Attachment from '../../../../stores/Attachment/Attachment';
import Channel from '../../../../stores/Channel';
import {FileData} from '../../../../utils/file/fileReaders';
import jsonParse from '../../../../utils/json/jsonParse';
import jsonStringify from '../../../../utils/json/jsonStringify';
import ButtonSizeSelector from '../WebWidgetCreation/ButtonSizeSelector';
import PositionSelector from '../WebWidgetCreation/PositionSelector';


interface WebWidgetThemeSettingsFormProps {
  channel: Channel;
  onSubmit?: (config: IMCWebWidgetConfig, fileLogo?: FileData | null) => void;
  colorOptions: string[];
  onChangeCompanyName?: (companyName: string) => void;
  onChangeColor?: (color: string) => void;
  onChangeAvatar?: (file: FileData | null) => void;
  onChangeChatButtonSize?: (value: string) => void;
  onChangeChatButtonPosition?: (props: React.CSSProperties) => void;
}

export interface IWebWidgetThemeSettingsFormRef {
  submitForm?: () => void;
}

export const WebWidgetThemeSettingsForm = observer(
  React.forwardRef<IWebWidgetThemeSettingsFormRef, WebWidgetThemeSettingsFormProps>((props, ref) => {
    const {t} = useTranslation();
    const webWidgetConfig = props.channel.webWidgetConfig;

    const [chosenFile, setChosenFile] = React.useState<FileData | null>(null);
    const [displayAvatar, setDisplayAvatar] = React.useState<Attachment | null>(webWidgetConfig?.displayAvatar || null);
    const [apiError, setApiError] = React.useState<string | null | undefined>('');

    const {src: avatarSrc, reset} = useAttachment(displayAvatar, props.channel.id);

    const position = jsonParse<React.CSSProperties>(webWidgetConfig?.position) || {};

    const formik = useFormik({
      initialValues: {
        displayCompanyName: webWidgetConfig?.displayCompanyName,
        displayAvatar: webWidgetConfig?.displayAvatar,
        displayColor: webWidgetConfig?.displayColor || props.colorOptions[0],
        chatButtonSize: webWidgetConfig?.chatButtonSize
          ? `${webWidgetConfig?.chatButtonSize?.toString()}px`
          : WEB_WIDGET_DEFAULT_CHAT_BUTTON_SIZE,
        position: position,
      },
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object({
        displayCompanyName: Yup.string().required(t('Required')),
        displayAvatar: Yup.object().nullable(),
        displayColor: Yup.string(),
        chatButtonSize: Yup.string(),
        position: Yup.object().nullable(),
      }),
      onSubmit: (values) => {
        setApiError('');

        const newWebWidgetConfig: IMCWebWidgetConfig = {
          ...webWidgetConfig?.raw,
          displayColor: values.displayColor,
          displayCompanyName: values.displayCompanyName,
          displayAvatar: values.displayAvatar,
          chatButtonSize: Long.fromNumber(parseInt(values.chatButtonSize || '', 10)),

          position: jsonStringify(values.position),
        };

        props.onSubmit?.(newWebWidgetConfig, chosenFile);
      },
    });

    React.useImperativeHandle(ref, () => ({
      submitForm: () => formik.submitForm(),
    }));

    const handleFileChange = (dataFiles: FileData[]) => {
      if (dataFiles.length) {
        setChosenFile(dataFiles[0]);
        props.onChangeAvatar?.(dataFiles[0]);
      } else {
        setDisplayAvatar(null);
        setChosenFile(null);
        reset();
        props.onChangeAvatar?.(null);
        formik.handleChange({
          target: {
            name: 'displayAvatar',
            value: null,
          },
        });
      }
    };

    const handleColorChange = (value: string) => {
      formik.handleChange({
        target: {
          name: 'displayColor',
          value,
        },
      });
      props.onChangeColor?.(value);
    };

    const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.handleChange(e);
      props.onChangeCompanyName?.(e.target.value);
    };

    const handleChatButtonSizeChange = (value: string) => {
      formik.handleChange({
        target: {
          name: 'chatButtonSize',
          value: value,
        },
      });
      props.onChangeChatButtonSize?.(value);
    };

    const handlePositionChange = (position: React.CSSProperties) => {
      formik.handleChange({
        target: {
          name: 'position',
          value: position,
        },
      });
      props.onChangeChatButtonPosition?.(position);
    };

    return (
      <form
        className="webwidget-theme-settings-form position-relative"
        onSubmit={formik.handleSubmit}
        style={{height: 546}}
      >
        <FormLabel>{t('settings_web_widget_manage_form_company_name_label')}</FormLabel>
        <OutlinedTextInput
          className="w-100"
          name="displayCompanyName"
          value={formik.values.displayCompanyName}
          onChange={handleCompanyNameChange}
          errorHelper={formik.errors.displayCompanyName || ' '}
          textHelper={t('settings_web_widget_manage_form_company_name_helper')}
          maxLength={MAX_FORM_FIELD_LENGTH}
          required
        />

        <FormLabel>{t('settings_web_widget_manage_form_company_logo_label')}</FormLabel>
        <FileInputUploader
          className="w-100"
          name="displayAvatar"
          onChange={handleFileChange}
          fileUrl={avatarSrc}
          accept="image/*"
          errorHelper={formik.errors.displayAvatar || ' '}
        />

        <FormLabel>{t('settings_web_widget_manage_form_color_label')}</FormLabel>
        <ColorSelector
          value={formik.values.displayColor}
          options={props.colorOptions}
          onChange={handleColorChange}
        />

        <FormLabel className="mt-3">{t('settings_web_widget_manage_form_button_size_label')}</FormLabel>
        <ButtonSizeSelector
          className="w-100"
          value={formik.values.chatButtonSize}
          onChange={handleChatButtonSizeChange}
          items={WEB_WIDGET_POSIBLE_CHAT_BUTTON_SIZES.map((size) => ({caption: size, value: size}))}
        />

        <PositionSelector
          className="mt-3"
          position={formik.values.position}
          onChange={handlePositionChange}
        />

        <FormHelperText className="mt-2" error>
          {apiError || ' '}
        </FormHelperText>
      </form>
    );
  }),
);

export default WebWidgetThemeSettingsForm;
