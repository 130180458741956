import Delta from 'quill-delta';
import QuillEditor, {QuillCore} from '../components/QuillTextInput/QuillEditor';

export class TextEditorProxy {
  quill: QuillCore | null = null;
  quillRef: QuillEditor | null = null;

  setEditor = (editor: QuillCore | null) => {
    this.quill = editor;
    /*
    this.quill?.clipboard.addMatcher(Node.TEXT_NODE, function (node, delta) {
      console.log('clipboard Node.TEXT_NODE delta=', delta);
      console.log('clipboard Node.TEXT_NODE node=', node);
      return delta;
    });

    this.quill?.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
      console.log('clipboard Node.ELEMENT_NODE node=', node);
      console.log('clipboard Node.ELEMENT_NODE delta=', delta);
      return delta;
    });
    */

    this.quill?.clipboard.addMatcher('TABLE', function (node) {
      return new Delta({
        ops: [{insert: node.innerText}],
      });
    });
  };

  setEditorRef = (ref: QuillEditor | null) => {
    if ((!this.quillRef || ref?.props.id) && this.quillRef?.props.id !== ref?.props.id) {
      this.quillRef = ref;
      this.setEditor(ref?.getEditor() || null);
    } else if (ref === null) {
      this.quillRef = null;
      this.setEditor(null);
    }
  };

  reset = () => {
    this.quill?.setContents(
      new Delta({
        ops: [{insert: '\n'}],
      }),
    );
  };
}

export default TextEditorProxy;
