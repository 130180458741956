export enum SettingsTab {
  PERSONAL_INFO,
  CHANNELS,
  WORKSPACE_MEMBERS,
  MESSAGE_TAGS,
  SNIPPETS,
  WORKSPACE_SETTINGS,
  BILLING,
  DOWNLOAD_APP,
}

export default SettingsTab;