import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';

import {IconButton} from 'o-ui/Button';
import SystemUserTitle from './SystemUserTitle';
import SystemMenu from './SystemMenu';
import ChannelsMenu from './ChannelsMenu';
import {useStore} from '../../../stores/AppStore';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import VersionBadge from '../VersionBadge';
import NavigationBar from './NavigationBar';
import Div100vh from '../../../components/Div100vh';
import {MAX_WIDTH_FOR_ENABLE_EMBEDDED_LEFT_BAR} from '../../../config';
import WorkspaceBar from './WorkspaceBar';


export const SideMenu = observer(() => {
  const {t} = useTranslation();
  const {layOutStore} = useStore();
  const menuRef = React.useRef<HTMLDivElement | null>(null);

  const handleCollapseClick = () => {
    layOutStore.setLeftBarCollapsed(true);
  };

  const handleExpandClick = () => {
    layOutStore.setLeftBarCollapsed(false);
  };

  useOnClickOutside(menuRef, () => {
    if (window.innerWidth <= MAX_WIDTH_FOR_ENABLE_EMBEDDED_LEFT_BAR && !layOutStore.leftBarCollapsed) {
      setTimeout(handleCollapseClick, 100);
    }
  });

  return (
    <Div100vh ref={menuRef} className="side-menu">
      {layOutStore.leftBarCollapsed ? null : <NavigationBar />}
      <div className="side-menu__workspace">
        <WorkspaceBar />
      </div>
      <div className="side-menu__top">
        <SystemUserTitle />

        <SystemMenu />
      </div>
      <div className="side-menu__channels">
        <div className="side-menu__channels-title">{t('chat_sidebar_channels_title')}</div>
        <ChannelsMenu />
      </div>

      <div className="side-menu__bottom">
        <div className="w-100 px-5">
          <div className="side-menu__collapse-button" onClick={handleCollapseClick}>
            <i className="tg-icon tg-icon-chevron-double-left-line" />
            <span>{t('Hide')}</span>
          </div>

          <IconButton
            className="side-menu__expand-button tg-icon-chevron-double-right-line"
            onClick={handleExpandClick}
            size="small"
          />
        </div>

        <VersionBadge className="side-menu__version" />
      </div>
    </Div100vh>
  );
});

export default SideMenu;
