import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import {ModalItem} from '../stores/ModalsStore';
import {formatUserName} from '../utils/format';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';
import {getUserRoleTitle} from '../stores/Workspaces';

interface IProps {
  modal: ModalItem;
}

export const ModalMemberBlocking: React.FC<IProps> = (props) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);
  const {member} = props.modal.data;

  const handleSubmit = () => {
    props.modal.trigger('submit', props.modal.data);
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  return (
    <ModalDialog ref={modalRef} modal={props.modal} className="new-dlg">
      <div className="account-dialog-form account-blocking">
        <div className="account-dialog-form__body">
          <div className="account-dialog-form__header">
            <span className="account-dialog-form__title">
              {member?.active
                ? t('modal_member_blocking_block_member_title')
                : t('modal_member_blocking_unblock_member_title')}
            </span>
            <span
              className="tg-icon-cross-line cursor-pointer icon-size-18 color-body-primary ml-auto"
              onClick={handleCloseClick}
            />
          </div>

          <div className="account-dialog-form__form custom-scroll d-flex flex-column">
            <div className="h4-bold font-bold color-body-primary">
              {member?.active
                ? t('modal_member_blocking_block_member_description')
                : t('modal_member_blocking_unblock_member_description')}
            </div>

            <div className="account-dialog-form__field-label">{t('modal_member_blocking_full_name')}</div>
            <div className="account-dialog-form__field-value">
              {formatUserName(member?.firstName, member?.lastName) || '-'}
            </div>

            <div className="account-dialog-form__field-label">{t('modal_member_blocking_email')}</div>
            <div className="account-dialog-form__field-value">{member?.email || '-'}</div>

            <div className="account-dialog-form__field-label">{t('modal_member_blocking_role')}</div>
            <div className="account-dialog-form__field-value">{getUserRoleTitle(member?.role)}</div>
          </div>

          <div className="account-dialog-form__footer mt-auto">
            <Button
              variant="contained"
              color="error"
              size="large"
              className="account-dialog-form__connect-button"
              onClick={handleSubmit}
            >
              {member?.blocked ? t('modal_member_blocking_unblock_btn') : t('modal_member_blocking_block_btn')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className="account-dialog-form__cancel-button"
              onClick={handleCloseClick}
            >
              {t('modal_cancel_btn')}
            </Button>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};

export default ModalMemberBlocking;
