import React from 'react';
import {observer} from 'mobx-react';

import {Chat} from '../../../../stores/Chat';
import CircularProgress from 'o-ui/CircularProgress';

interface ChatMessagesLoaderProps {
  chat: Chat;
}

export const ChatMessagesLoader: React.FC<ChatMessagesLoaderProps> = observer(({chat}) => {
  return chat.store?.historyLoading ? (
    <CircularProgress
      size={36}
      style={{
        color: 'var(--brand-primary)',
        position: 'absolute',
        top: 36,
        left: '50%',
        marginTop: '-18px',
        marginLeft: '-18px',
        zIndex: 3,
      }}
    />
  ) : null;
});

export default ChatMessagesLoader;
