import {entities} from '../../../api/proto';
import i18n from '../../../i18n';

export const getChannelTypeName = (type?: entities.OzekonChannelType | null) => {
  switch (type) {
    case entities.OzekonChannelType.OIT_TELEGRAM:
      return i18n.t('settings_channel_telegram_name');
    case entities.OzekonChannelType.OIT_WEB_WIDGET:
      return i18n.t('settings_channel_web_widget_name');
    case entities.OzekonChannelType.OIT_INSTAGRAM:
      return i18n.t('settings_channel_instagram_name');
    case entities.OzekonChannelType.OIT_TELEGRAM_BOT:
      return i18n.t('settings_channel_telegram_bot_name');
    case entities.OzekonChannelType.OIT_NONE:
      return 'None';
  }
  return i18n.t('settings_channel_telegram_name');
};

export default getChannelTypeName;