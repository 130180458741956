import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import Loader from 'o-ui/Loader';
import ChannelIcon from '../../../components/ChannelIcon';
import Channel from '../../../stores/Channel';
import ContextMenuType from '../../../stores/ContextMenuType';
import ChatTab from '../../../stores/LayOutStore/ChatTab';
import useStore from '../../../stores/useStore';
import {equalUint8Arrays, uint8ArrayToBase64, uint8ArrayToUuid} from '../../../utils/arrayUtils';
import ChannelsMenuItemTooltip from './ChannelsMenuItemTooltip';
import UnreadCounterIcon from './UnreadCounterIcon';

interface ChannelsMenuItemProps {
  channel: Channel;
  onClick?: (channel: Channel) => void;
}

export const ChannelsMenuItem = observer((props: ChannelsMenuItemProps) => {
  const {channels, contextMenu, layOutStore} = useStore();

  const chatsImporting = props.channel.state?.chatsImportInProgress;
  const messagesImporting = props.channel.state?.messagesImportInProgress;

  const handleClick = () => {
    props.onClick?.(props.channel);
  };

  const onContextMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    contextMenu.open({clientX: e.clientX, clientY: e.clientY}, ContextMenuType.CHANNEL_MENU, {
      channel: props.channel,
    });
  };

  const active =
    equalUint8Arrays(channels.selectedChannel?.id, props.channel.id) && layOutStore.activeTab === ChatTab.CHATS;

  return (
    <ChannelsMenuItemTooltip
      channel={props.channel}
      collapsed={layOutStore.leftBarCollapsed}
    >
      <div
        className={cn(
          'channels-menu__item py-4 px-4',
          {
            active,
            chatsImporting,
            messagesImporting,
          }
        )}
        data-id={uint8ArrayToBase64(props.channel.id)}
        data-uuid={uint8ArrayToUuid(props.channel.id)}
        onClick={handleClick}
        onContextMenu={onContextMenu}
      >
        {chatsImporting || messagesImporting ? <Loader size={20} className="channels-menu__item-loader" /> : null}

        <ChannelIcon
          className="channels-menu__item-icon"
          type={props.channel.type}
          active={active}
        />
        <div className="channels-menu__item-info">
          <div className="channels-menu__item-title">{props.channel.name}</div>
        </div>
        {/* {props.channel.pinned ? (
          <div className="channels-menu__status-icon channels-menu__status-icon--item">
            <i className="tg-icon tg-icon-pin-fill" />
          </div>
        ) : null} */}
        <UnreadCounterIcon count={props.channel.unreadNumber} />
      </div>
    </ChannelsMenuItemTooltip>
  );
});

export default ChannelsMenuItem;