import React from 'react';
import {observer} from 'mobx-react';

import AudioPlayer from '../../../../components/AudioPlayer';
import {useStore} from '../../../../stores/AppStore';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import {niceBytes} from '../../../../utils/format';
import formatSeconds from '../../../../utils/formatSeconds';

export interface AudioContentProps {
  attachment: MessageAttachment;
}

export const AudioContent = observer(({attachment}: AudioContentProps) => {
  const {chatsView} = useStore();
  const {audioPlayer} = chatsView;

  const isPlaying = () => {
    return audioPlayer.isActive(attachment) ? audioPlayer.isPlaying : false;
  };

  const playedSeconds = () => {
    return audioPlayer.isActive(attachment) ? audioPlayer.playedSeconds : 0;
  };

  const duration = attachment.duration ? formatSeconds(attachment.duration?.toNumber()) : '';

  const description = isPlaying()
    ? `${formatSeconds(playedSeconds())} / ${duration}`
    : `${duration}, ${niceBytes(attachment?.size?.toNumber() || 0)}`;

  return (
    <div
      className="message-audio"
      data-attachment-uuid={attachment.uuid}
    >
      <div className="d-flex align-items-center mb-3">
        <AudioPlayer attachment={attachment} hideProgressBar={!attachment.isVoiceNote} />
        {attachment.isVoiceNote ? null : <div className="message-audio__name">{attachment.name}</div>}
      </div>
      <div className="text-left">
        <p className="message-audio__info font-size-12 mb-n2">{description}</p>
      </div>
    </div>
  );
});

export default AudioContent;
