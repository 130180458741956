import React from 'react';
import {useStore} from '../stores/AppStore';
import Attachment from '../stores/Attachment/Attachment';
import {DOWNLOAD_CANCELED_ERROR_MESSAGE} from '../stores/Attachment/AttachmentStore';

interface AttachmentInfo {
  src?: string;
  mimeType?: string;
  width?: number;
  height?: number;
  duration?: number;
  error?: string;
  reset: () => void;
}

export default function useAttachment(attachment?: Attachment | null, channelID?: Uint8Array | null): AttachmentInfo {
  const {uploader, activeWorkspace} = useStore();

  const width = attachment?.width?.toNumber();
  const height = attachment?.height?.toNumber();
  const duration = attachment?.duration?.toNumber();

  const [src, setSrc] = React.useState<string | undefined>();
  const [mimeType, setMimeType] = React.useState<string | undefined>();
  const [error, setError] = React.useState<string | undefined>();

  const loadingRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    async function getAttachment() {
      if (!attachment?.id?.length || (error && error !== DOWNLOAD_CANCELED_ERROR_MESSAGE) || loadingRef.current) {
        return;
      }

      loadingRef.current = true;

      const res = await activeWorkspace.attachmentStore.get(attachment.id, channelID, attachment.name);

      loadingRef.current = false;

      if (res?.error) {
        setError(res?.error?.message);
        setSrc(undefined);
      } else if (res?.res?.src) {
        setSrc(res?.res?.src);
        setMimeType(res?.res?.mimeType);
        setError(undefined);
      }
    }

    getAttachment();
  }, [attachment, uploader, activeWorkspace, error, src, loadingRef, channelID]);

  const reset = React.useCallback(() => {
    setSrc(undefined);
    setMimeType(undefined);
    setError(undefined);
  }, []);

  return {
    width,
    height,
    duration,
    src,
    mimeType,
    error,
    reset,
  };
}
