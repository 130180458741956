import React from 'react';

import CustomScrollbar from 'o-ui/CustomScrollbar';
import VariableSizeList, {IVariableSizeList, ListOnScrollProps} from 'o-ui/VirtualizedList';

import {CHAT_ITEM_HEIGHT, CHAT_ITEM_HEIGHT_COMPACT} from '../../../../../constants';
import useDebouncedCallback from '../../../../../hooks/useDebouncedCallback';
import {ChatClickEventHandler} from '../../../../../stores/Chat';
import useStore from '../../../../../stores/useStore';
import ChatsVirtualizedListItem from './ChatsVirtualizedListItem';


interface ChatsVirtualizedListProps {
  storeKey?: string;
  chatsCount: number;
  onChatClick?: ChatClickEventHandler;
  width?: number;
  height?: number;
  onListRendered?: (rendered: boolean) => void;
  onScrolledToTop?: () => void;
  onScrolledToBottom?: () => void;
}

export const ChatsVirtualizedList = React.memo(React.forwardRef((props: ChatsVirtualizedListProps, ref: React.Ref<IVariableSizeList | null>) => {
  const {chatsView, layOutStore} = useStore();

  const listRef = React.useRef<IVariableSizeList>(null);
  const outerRef = React.useRef<HTMLDivElement>(null);
  const listInnerRef = React.useRef<HTMLDivElement>(null);

  React.useImperativeHandle(ref, () => listRef.current);

  const sizeMap = React.useRef({});
  const setSize = React.useCallback((index, size) => {
    sizeMap.current = {...sizeMap.current, [index]: size};
    listRef.current?.resetAfterIndex(index);
  }, []);

  React.useEffect(() => {
    outerRef.current?.parentElement?.classList.add('will-change-unset');
  }, []);

  const getItemSize = React.useCallback((index: number): number => {
    const height = layOutStore.compactMode ? CHAT_ITEM_HEIGHT_COMPACT : CHAT_ITEM_HEIGHT;
    return sizeMap.current[index] || height;
  }, [layOutStore.compactMode]);

  const handelItemsRendered = React.useCallback(() => {
    props.onListRendered?.(true);
  }, [props]);


  const handleScrolledToTop = React.useCallback(() => {
    if ((props.height || 0) !== 0) {
      props.onScrolledToTop?.();
    }
  }, [props]);

  const handleScrolledToBottom = React.useCallback(() => {
    if ((props.height || 0) !== 0) {
      props.onScrolledToBottom?.();
    }
  }, [props]);

  const scrollCallback = useDebouncedCallback((params: ListOnScrollProps) => {
    if (params.scrollUpdateWasRequested) {
      return;
    }

    const scrollHeight = listInnerRef.current?.clientHeight || 0;

    if (params.scrollDirection === 'backward' && params.scrollOffset === 0) {
      handleScrolledToTop();
    }

    if (Math.floor(scrollHeight - params.scrollOffset - (props.height || 0)) === 0) {
      handleScrolledToBottom();
    }
  }, 400);

  const handleScrollList = React.useCallback((params: ListOnScrollProps) => {
    scrollCallback(params);
  }, [scrollCallback]);

  const handleItemKey = React.useCallback((index) => {
    const chats = !props.storeKey || chatsView.chatsStoreKey === props.storeKey ? chatsView.chats : chatsView.getChats(props.storeKey);
    return chats[index].id?.toNumber() || index;
  }, [
    props.storeKey,
    chatsView,
  ]);

  return (
    <VariableSizeList
      ref={listRef}
      innerRef={listInnerRef}
      outerRef={outerRef}
      className="chat-list chat-user-list"
      width={props.width || 0}
      height={props.height || 0}
      itemCount={props.chatsCount}
      itemSize={getItemSize}
      itemKey={handleItemKey}
      outerElementType={CustomScrollbar}
      onItemsReRendered={handelItemsRendered}
      onScroll={handleScrollList}
      maxNewItemsForAutoScrollBackward={3}
      useItemsCache={true}
    >
      {(listRow) => (
        <ChatsVirtualizedListItem
          key={handleItemKey(listRow.index)}
          storeKey={props.storeKey}
          row={listRow}
          setSize={setSize}
          onChatClick={props.onChatClick}
        />
      )}
    </VariableSizeList>
  );
}));

export default ChatsVirtualizedList;
