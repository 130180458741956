import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {entities} from '../../../../api/proto';
import ChannelIcon from '../../../../components/ChannelIcon';
import ChannelTypesListItem from './ChannelTypesListItem';
import useStore from '../../../../stores/useStore';

const {OzekonChannelType} = entities;

interface ChannelTypesListProps {
  onClick?: (type: entities.OzekonChannelType) => void;
  onAddClick?: (type: entities.OzekonChannelType) => void;
}

export const ChannelTypesList: React.FC<ChannelTypesListProps> = observer((props) => {
  const {t} = useTranslation();
  const {features} = useStore();

  return (
    <div className="channel-types-list">
      <ChannelTypesListItem
        type={OzekonChannelType.OIT_TELEGRAM}
        onClick={props.onClick}
        onAddClick={props.onAddClick}
      />

      <ChannelTypesListItem
        type={OzekonChannelType.OIT_WEB_WIDGET}
        onClick={props.onClick}
        onAddClick={props.onAddClick}
      />

      <ChannelTypesListItem
        type={OzekonChannelType.OIT_INSTAGRAM}
        onClick={props.onClick}
        onAddClick={props.onAddClick}
        disabled={!features.instagram}
      />

      <ChannelTypesListItem
        type={OzekonChannelType.OIT_TELEGRAM_BOT}
        onClick={props.onClick}
        onAddClick={props.onAddClick}
        disabled={!features.telegramBot}
      />

      <div className="channel-types-list__item disabled">
        <ChannelIcon className="channel-types-list__icon" type="slack" />
        <div className="channel-types-list__name body1-regular">{t('settings_channel_slack_name')}</div>
        <div className="channel-types-list__status body2-regular">{t('settings_channels_not_available_yet')}</div>
      </div>

      <div className="channel-types-list__item disabled">
        <ChannelIcon className="channel-types-list__icon" type="whatsapp" />
        <div className="channel-types-list__name body1-regular">{t('settings_channel_whatsapp_name')}</div>
        <div className="channel-types-list__status body2-regular">{t('settings_channels_not_available_yet')}</div>
      </div>
    </div>
  );
});

export default ChannelTypesList;
