import {api} from '../../api/proto';
import {t} from '../../i18n';

export default function getResumeCurrentTariffError(status?: api.WorkspaceResumeCurrentTariffResponse.Status | null): string | null {
  switch (status) {
    case api.WorkspaceResumeCurrentTariffResponse.Status.WRT_INTERNAL_ERROR:
      return t('Internal error');
    case api.WorkspaceResumeCurrentTariffResponse.Status.WRT_NO_ACTIVE_TARIFF:
      return t('No active tariff');
  }

  return null;
}