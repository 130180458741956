import React from 'react';

import {observer} from 'mobx-react';
import {QuillCore} from '../../components/QuillTextInput/QuillEditor';

interface Props {
  quill?: QuillCore | null;
  maxTextLength?: number | null;
}

export const QuillTextCounter: React.FC<Props> = observer(({quill, maxTextLength}) => {
  const [textLength, setTextLength] = React.useState<number>(0);

  const trimTextByLimit = React.useCallback(() => {
    if (typeof maxTextLength === 'number' && quill) {
      quill.deleteText(maxTextLength, quill.getLength());

      setTextLength(quill.getLength() - 1);
    }
  }, [quill, maxTextLength]);

  React.useEffect(() => {
    quill?.on('text-change', trimTextByLimit);
  }, [quill, trimTextByLimit]);

  React.useEffect(() => {
    trimTextByLimit();
  }, [trimTextByLimit]);

  return maxTextLength !== null ? (
    <div className="body2-regular color-gray-500 mt-2">{`${textLength}/${maxTextLength}`}</div>
  ) : null;
});

export default QuillTextCounter;
