import {observer} from 'mobx-react';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import Long from 'long';
import Button from 'o-ui/Button';
import {DURATION} from '../constants';
import {useStore} from '../stores/AppStore';
import {ContextMenuItem} from '../stores/ContextMenuStore';

interface MenuBlockWebWidgetChatProps {
  menu: ContextMenuItem;
}

export const MenuBlockWebWidgetChat: React.FC<MenuBlockWebWidgetChatProps> = observer((props) => {
  const {t} = useTranslation();
  const {notifications} = useStore();

  const blockTo = async (duration: number) => {
    if (!props.menu.data.chat) {
      return;
    }
    const {chat} = props.menu.data;
    const stamp = new Date().getTime() + duration;

    props.menu.close();

    const {res, error} = await chat.restrictChatAccess(chat.store.closestPeerId, Long.fromNumber(stamp));

    if (error) {
      notifications.error(error.message);
    }

    if (res) {
      notifications.success(
        <Trans i18nKey="notification_chat_blocked" values={{chatName: chat.name}} components={{strong: <strong />}} />,
      );
    }
  };

  return (
    <div className="context-menu-instance" style={{width: 320}}>
      <div className="context-menu-instance__head">
        <i className="context-menu-instance__head-icon tg-icon tg-icon-block-line" />
        <span className="context-menu-instance__head-title">{t('ctx_menu_web_widget_chat_block_title')}</span>
      </div>
      <div className="context-menu-instance__info">{t('ctx_menu_web_widget_chat_block_sub_title')}</div>

      <Button
        className="w-100 mt-2"
        variant="contained"
        color="secondary"
        size="medium"
        onClick={() => blockTo(DURATION.MINUTES_5)}
      >
        {t('ctx_menu_web_widget_chat_block_period_5_min')}
      </Button>
      <Button
        className="w-100 mt-2"
        variant="contained"
        color="secondary"
        size="medium"
        onClick={() => blockTo(DURATION.MINUTES_30)}
      >
        {t('ctx_menu_web_widget_chat_block_period_30_min')}
      </Button>
      <Button
        className="w-100 mt-2"
        variant="contained"
        color="secondary"
        size="medium"
        onClick={() => blockTo(DURATION.HOURS_1)}
      >
        {t('ctx_menu_web_widget_chat_block_period_1_hour')}
      </Button>
      <Button
        className="w-100 mt-2"
        variant="contained"
        color="secondary"
        size="medium"
        onClick={() => blockTo(DURATION.HOURS_3)}
      >
        {t('ctx_menu_web_widget_chat_block_period_3_hours')}
      </Button>
      <Button
        className="w-100 mt-2"
        variant="contained"
        color="secondary"
        size="medium"
        onClick={() => blockTo(DURATION.HOURS_24)}
      >
        {t('ctx_menu_web_widget_chat_block_period_24_hours')}
      </Button>
      <Button
        className="w-100 mt-2"
        variant="contained"
        color="secondary"
        size="medium"
        onClick={() => blockTo(DURATION.DAYS_3)}
      >
        {t('ctx_menu_web_widget_chat_block_period_3_days')}
      </Button>
      <Button
        className="w-100 mt-2"
        variant="contained"
        color="secondary"
        size="medium"
        onClick={() => blockTo(DURATION.WEEK_1)}
      >
        {t('ctx_menu_web_widget_chat_block_period_1_week')}
      </Button>
      <Button
        className="w-100 mt-2"
        variant="contained"
        color="secondary"
        size="medium"
        onClick={() => blockTo(DURATION.FOREVER)}
      >
        {t('ctx_menu_web_widget_chat_block_permanently')}
      </Button>
    </div>
  );
});

export default MenuBlockWebWidgetChat;
