import cn from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import AutoSizer, {Size} from 'react-virtualized-auto-sizer';

import {SearchInput} from 'o-ui/Input';
import {FixedSizeList, IFixedSizeList, ListOnScrollProps} from 'o-ui/VirtualizedList';
import useDebouncedCallback from 'o-ui/hooks/useDebouncedCallback';

import {SEARCH_TEXT_LENGTH_LIMIT} from '../../../constants';
import SelectMemberItem from './SelectMemberItem';
import SelectMemberProvider from './SelectMemberProvider';
import WorkspaceMember from '../../../stores/Workspaces/WorkspaceMember';

interface MembersListWrapperProps {
  className?: string;
  children: (size: Size) => React.ReactNode;
}

export const MembersListWrapper: React.FC<MembersListWrapperProps> = ({
  className,
  children,
}) => {
  return (
    <div
      className={cn('flex-grow-1', className)}
      style={{width: '100%'}}
    >
      <AutoSizer>{children}</AutoSizer>
    </div>
  );
};

interface ISortedContact {
  children: WorkspaceMember[];
  group?: string;
}

interface SelectMemberProps {
  members: WorkspaceMember[];
  onSelect?(members: WorkspaceMember[]): void;
  onSearch?(value: string): void;
  onScrolledToTop?(): void;
  onScrolledToBottom?(): void;
  withSearchField?: boolean;
  className?: string;
  memberItemClassName?: string;
  inputClassName?: string;
  listClassName?: string;
  listHeight?: number;
  maxSelected?: number | null;
}

const DEFAULT_LIST_HEIGHT = 485;
const ITEM_SIZE = 60; //px;

export const SelectMember: React.FC<SelectMemberProps> = React.memo(
  ({
    members: propsMembers,
    onSelect,
    onSearch,
    onScrolledToTop,
    onScrolledToBottom,
    className,
    memberItemClassName,
    inputClassName,
    listClassName,
    listHeight = DEFAULT_LIST_HEIGHT,
    maxSelected,
  }) => {
    const {t} = useTranslation();
    const listRef = React.useRef<IFixedSizeList>(null);
    const listInnerRef = React.useRef<HTMLDivElement>(null);
    const [members, setMembers] = React.useState<(WorkspaceMember[] & ISortedContact[]) | Array<WorkspaceMember>>([]);

    React.useEffect(() => {
      setMembers(propsMembers);
    }, [propsMembers]);

    const handleScrolledToTop = React.useCallback(() => {
      if (listHeight !== 0) {
        onScrolledToTop?.();
      }
    }, [listHeight, onScrolledToTop]);

    const handleScrolledToBottom = React.useCallback(() => {
      if (listHeight !== 0) {
        onScrolledToBottom?.();
      }
    }, [listHeight, onScrolledToBottom]);

    const scrollCallback = React.useCallback(
      (params: ListOnScrollProps) => {
        if (params.scrollUpdateWasRequested) {
          return;
        }

        const scrollHeight = listInnerRef.current?.clientHeight || 0;

        if (params.scrollDirection === 'backward' && params.scrollOffset === 0) {
          handleScrolledToTop();
        }

        if (scrollHeight >= listHeight && Math.floor(scrollHeight - params.scrollOffset - listHeight) <= 0) {
          handleScrolledToBottom();
        }
      },
      [listInnerRef, listHeight, handleScrolledToTop, handleScrolledToBottom],
    );

    const scrollCallbackDebounced = useDebouncedCallback(scrollCallback, 400);

    const handleScrollList = React.useCallback(
      (params: ListOnScrollProps) => {
        scrollCallbackDebounced(params);
      },
      [scrollCallbackDebounced],
    );

    const handleSearch = React.useCallback(
      (val: string) => {
        onSearch?.(val);
        listRef.current?.scrollTo(0);
      },
      [onSearch, listRef],
    );

    return (
      <SelectMemberProvider
        onSelect={onSelect}
        maxSelected={maxSelected}
      >
        <div className={cn('d-flex flex-column h-100', className)}>
          <SearchInput
            className={cn('mb-5 flex-grow-0', inputClassName)}
            placeholder={t('components_select_chat_search_input_placeholder')}
            onChange={handleSearch}
            maxLength={SEARCH_TEXT_LENGTH_LIMIT}
            autoFocus
          />
          <MembersListWrapper className={listClassName}>
            {({width, height}) => (
              <FixedSizeList
                ref={listRef}
                innerRef={listInnerRef}
                className="chat-list chat-user-list custom-scroll list-unstyled"
                width={width}
                height={height}
                itemCount={members.length}
                itemSize={ITEM_SIZE}
                onScroll={handleScrollList}
              >
                {({index, style}) => (
                  <SelectMemberItem
                    key={'chat-' + members[index]?.id?.toString()}
                    style={style}
                    className={memberItemClassName}
                    member={members[index]}
                  />
                )}
              </FixedSizeList>
            )}
          </MembersListWrapper>
        </div>
      </SelectMemberProvider>
    );
  },
);

export default SelectMember;
