import React from 'react';
import {Navigate, generatePath} from 'react-router-dom';

import {Paths} from '../../routes/Paths';
import useStore from '../../stores/useStore';
import {uint8ArrayToBase64} from '../../utils/arrayUtils';


export const BillingRedirect: React.FC = () => {
  const {activeWorkspace} = useStore();

  if (!activeWorkspace.id) {
    return null;
  }

  return (
    <Navigate
      to={generatePath(Paths.Billing, {
        workspaceId: encodeURIComponent(uint8ArrayToBase64(activeWorkspace.id)),
      })}
    />
  );
};

export default BillingRedirect;