import React from 'react';
import {observer} from 'mobx-react';

import Badge from '../../../../../components/Badge';
import Chat from '../../../../../stores/Chat';

interface ChatItemUnreadCounterProps {
  className?: string;
  chat: Chat;
}

export const ChatItemUnreadCounter: React.FC<ChatItemUnreadCounterProps> = observer(({chat, className}) => {
  if (chat.unreadCount > 0) {
    return (
      <Badge
        className={className}
        text={chat.unreadCount.toString()}
        title={chat.unreadCount.toString()}
        color="warning"
        variant="circle"
      />
    );
  }

  return null;
});

export default ChatItemUnreadCounter;
