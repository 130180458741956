import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import Button from 'o-ui/Button';
import SearchInput from 'o-ui/Input/SearchInput';
import {formatPhoneNumber} from 'o-ui/utils/getPhoneMask';

import {SEARCH_TEXT_LENGTH_LIMIT} from '../../../constants';
import {entities} from '../../../api/proto';
import Channel from '../../../stores/Channel';
import ChannelScreenType from '../../../stores/Channel/ChannelScreenType';
import getChannelTypeName from '../../../stores/Channel/utils/getChannelTypeName';
import ModalType from '../../../stores/ModalType';
import useStore from '../../../stores/useStore';
import {uint8ArrayToBase64, uint8ArrayToUuid} from '../../../utils/arrayUtils';
import ChannelsTable from './ChannelsTable';

import {ReactComponent as PlusIcon} from '../../../assets/icons/plus-line.svg';

function filterChannel(channel: Channel, filter: string): boolean {
  return !filter ||
    channel.name.toLowerCase().includes(filter) ||
    channel.email.toLowerCase().includes(filter) ||
    channel.phone.toLowerCase().includes(filter) ||
    formatPhoneNumber(channel.phone).includes(filter) ||
    channel.userName.toLowerCase().includes(filter) ||
    uint8ArrayToUuid(channel.id).toLowerCase().includes(filter) ||
    uint8ArrayToBase64(channel.id).toLowerCase().includes(filter) ||
    !!channel.webWidgetConfig?.domain?.toLowerCase().includes(filter) ||
    false
    ;
}

interface IProps {
  channelType?: entities.OzekonChannelType | null;
  onChannelOpen?: (channel: Channel) => void;
}

export const ChannelsViewer = observer(({channelType, onChannelOpen}: IProps) => {
  const {t} = useTranslation();
  const {channels, modals} = useStore();

  const [filter, setFilter] = React.useState<string>('');
  const channelsByType = React.useMemo(() => {
    return channels.list.filter((c) => c.type === channelType && filterChannel(c, filter.toLocaleLowerCase()));
  }, [channelType, channels.list, filter]);

  const {channelScreenType = ''} = useParams();

  React.useEffect(() => {
    if (channelScreenType === ChannelScreenType.CALLBACK && channelType === entities.OzekonChannelType.OIT_INSTAGRAM) {
      channels.instagram.processAuthQueryCallback();
      modals.open(ModalType.CHANNEL_CREATION, {
        channelType,
        channelScreenType,
      });
    }
  }, [
    channelType,
    channelScreenType,
    modals,
    channels.instagram,
  ]);

  const openSelectedChannelCreationModal = () => {
    modals.open(ModalType.CHANNEL_CREATION, {channelType});
  };

  const handleChangeFilter = (value: string) => {
    setFilter(value);
  };

  return (
    <div className="settings-tab__table-container">
      <div className="settings-tab__table-container-head d-flex direction-row align-items-center py-5">
        <div className="settings-tab__body-descr body2-regular color-body-primary my-3">
          {t('settings_channels_types_list_item_title', {
            messengerName: getChannelTypeName(channelType),
          })}
        </div>
        <Button
          className="settings-tab__button ml-auto nowrap"
          variant="contained"
          color="primary"
          size="small"
          onClick={openSelectedChannelCreationModal}
        >
          <PlusIcon className="settings-tab__button-icon" />
          {t('settings_channels_types_list_item_add_channel_btn')}
        </Button>
      </div>

      <SearchInput
        className="settings-tab__table-search px-5 py-1 mx-6 mt-3 mb-6"
        placeholder={t('settings_channels_types_list_search_placeholder')}
        onChange={handleChangeFilter}
        maxLength={SEARCH_TEXT_LENGTH_LIMIT}
      />

      <ChannelsTable
        channelType={channelType}
        channels={channelsByType}
        onRowClick={onChannelOpen}
      />
    </div>
  );
});

export default ChannelsViewer;
