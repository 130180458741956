import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Tabs from 'o-ui/Tabs';
import {MCMethodGetChatsPreview} from '../../../../../api/proto';
import {useStore} from '../../../../../stores/AppStore';
import {ChatsFilterType} from '../../../../../stores/ChatsView/ChatsFilterStore';
import ChatsFilterTab from './ChatsFilterTab';


export const ChatsFilter: React.FC<React.PropsWithChildren> = observer(() => {
  const {t} = useTranslation();
  const {
    activeWorkspace: {
      chatsLoader,
      chatsView,
    },
  } = useStore();

  const handleChangeTab = (e: React.ChangeEvent, newValue: MCMethodGetChatsPreview.MCChatStatus) => {
    chatsLoader.filterByStatus(newValue);
  };

  const handleChangeInprogressTab = (e: React.ChangeEvent, newValue: number) => {
    switch (newValue) {
      case 0:
        chatsLoader.setAssignedToMeOnly();
        break;
      case 1:
        chatsLoader.setAssignedToOtherOnly();
        break;
    }
  };

  return (
    <>
      <div className="chats-list-filter__row p-0">
        <Tabs
          className="chats-list-filter__tabs w-100 h-100"
          value={chatsLoader.status}
          onChange={handleChangeTab}
        >
          <ChatsFilterTab value={MCMethodGetChatsPreview.MCChatStatus.S_ALL}>
            {t('chat_chats_tab_filter_all')}
          </ChatsFilterTab>

          <ChatsFilterTab
            value={MCMethodGetChatsPreview.MCChatStatus.S_OPEN}
            unreadCount={chatsLoader.totalByStatus[MCMethodGetChatsPreview.MCChatStatus.S_OPEN]}
          >
            {t('chat_chats_tab_filter_open')}
          </ChatsFilterTab>
          <ChatsFilterTab value={MCMethodGetChatsPreview.MCChatStatus.S_IN_PROGRESS}>
            {t('chat_chats_tab_filter_in_progress')}
          </ChatsFilterTab>
          <ChatsFilterTab value={MCMethodGetChatsPreview.MCChatStatus.S_RESOLVED}>
            {t('chat_chats_tab_filter_resolve')}
          </ChatsFilterTab>
        </Tabs>
      </div>

      {(chatsView.filter.type === ChatsFilterType.WEB_WIDGET_FILTER || chatsView.filter.type === ChatsFilterType.COMMON_FILTER) &&
        chatsLoader.status === MCMethodGetChatsPreview.MCChatStatus.S_IN_PROGRESS ? (
        <div className="chats-list-filter__row chats-list-filter__row--slim p-0">
          <Tabs
            className="chats-list-filter__tabs w-100 h-100"
            value={chatsLoader.assignedToMeOnly ? 0 : chatsLoader.assignedToOtherOnly ? 1 : -1}
            onChange={handleChangeInprogressTab}
          >
            <ChatsFilterTab>{t('chat_chats_tab_filter_my_chats')}</ChatsFilterTab>
            <ChatsFilterTab>{t('chat_chats_tab_filter_other_chats')}</ChatsFilterTab>
          </Tabs>
        </div>
      ) : null}
    </>
  );
});

export default ChatsFilter;
