import React from 'react';
import {useTranslation} from 'react-i18next';

import FormLabel from 'o-ui/FormLabel';
import Channel from '../../../../stores/Channel';
import {formatPhoneNumber} from '../../../../utils/getPhoneMask';

import brandIcon from '../../../../assets/image-icons/brandIcon.svg';
import telegramIconSquared from '../../../../assets/image-icons/telegramIconSquared.svg';

interface TelegramCreationResultProps {
  title?: string;
  subTitle?: string;
  newChannel?: Channel | null;
}

export const TelegramCreationResult: React.FC<TelegramCreationResultProps> = (props) => {
  const {t} = useTranslation();

  return (
    <div className="channel-dialog-form__form custom-scroll d-flex flex-column">
      {props.title ? <div className="h1-bold color-body-primary">{props.title}</div> : null}
      {props.subTitle ? <div className="body1-regular color-body-primary pb-6">{props.subTitle}</div> : null}

      <div className="channel-dialog-form__title-icons">
        <img className="channel-dialog-form__tg-icon" src={telegramIconSquared} alt="" />
        <img className="channel-dialog-form__ozekon-icon" src={brandIcon} alt="" />
      </div>
      <div className="h4-bold font-bold color-body-primary mt-5">
        {t('settings_channel_creation_form_success_connect')}
      </div>

      <FormLabel className="body3-regular text-capitalize d-flex mb-2 mt-5">
        {t('settings_channel_creation_form_channel_phone_label')}
      </FormLabel>
      <p className="body1-regular">{formatPhoneNumber(props.newChannel?.phone) || '-'}</p>

      <FormLabel className="body3-regular text-capitalize d-flex mb-2">
        {t('settings_channel_creation_form_channel_user_name_label')}
      </FormLabel>
      <p className="body1-regular">{props.newChannel?.userName || '-'}</p>

      <FormLabel className="body3-regular text-capitalize d-flex mb-2">
        {t('settings_channel_creation_form_channel_name_label')}
      </FormLabel>
      <p className="body1-regular">{props.newChannel?.name || '-'}</p>
    </div>
  );
};

export default TelegramCreationResult;
