import moment from 'moment';
import React from 'react';

import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';

export type MediaGalleryRowType = number | Array<MessageAttachment>;

export const useMediaGallery = (attachments?: MessageAttachment[]): [Array<MediaGalleryRowType>] => {
  const [items, setItems] = React.useState<MediaGalleryRowType[]>([]);

  const buildGalleryItems = React.useCallback((newAttachments?: MessageAttachment[]) => {
    const _items: MediaGalleryRowType[] = [];

    newAttachments?.forEach((attach: MessageAttachment, i: number) => {
      const lastRow = _items[_items.length - 1];
      const lastRowItem: MessageAttachment | null =
        typeof lastRow === 'number' || typeof lastRow === 'undefined' ? null : lastRow[lastRow.length - 1];
      const nextMonth =
        i === 0 ||
        (lastRowItem?.message.stamp &&
          attach.message.stamp &&
          !moment
            .unix(lastRowItem.message.stamp.toNumber())
            .isSame(moment.unix(attach.message.stamp.toNumber()), 'month'));

      if (nextMonth && attach.message.stamp) {
        _items.push(attach.message.stamp.toNumber());
      }

      if (nextMonth || typeof lastRow === 'number' || typeof lastRow === 'undefined' || lastRow?.length === 3) {
        _items.push([attach]);
      } else {
        lastRow.push(attach);
      }
    });

    setItems(_items);
  }, []);

  React.useEffect(() => {
    buildGalleryItems(attachments);
  }, [buildGalleryItems, attachments]);

  return [items];
};

export default useMediaGallery;
