import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

interface ChatDraftMessageProps {
  className?: string;
  draftMessage?: string | null;
}

export const ChatDraftMessage = observer((props: ChatDraftMessageProps) => {
  const {t} = useTranslation();

  return props.draftMessage ? (
    <span className={props.className}>
      <span className="chat-item__draft-title">{t('Draft')}: </span>
      {props.draftMessage}
    </span>
  ) : null;
});

export default ChatDraftMessage;
