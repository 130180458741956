import {action, computed, makeObservable, observable} from 'mobx';

import Long from 'long';
import {api, entities} from '../../api/proto';
import {APILayer} from '../APILayer';
import {AppStore} from '../AppStore';
import EditorStore from '../EditorStore';
import TextEditorProxy from '../TextEditorProxy';
import Snippet, {ISnippet} from './Snippet';

export enum SnippetType {
  Workspace = 1,
  Personal = 2,
}

export type CreateSnippetData = {
  command?: string | null;
  text?: string | null;
  entities?: entities.ITextEntities[] | null;
};

export const DEFAULT_SNIPPET_TYPE: SnippetType = SnippetType.Personal;

export type SnippetMessage = {
  text: string;
  textEntities: entities.ITextEntities[];
};

export const filterSnippetsList = (list: Snippet[], filter = '', shortcutMode?: boolean): Snippet[] => {
  return list.filter((item) => {
    if (shortcutMode) {
      return item.command?.toLowerCase().includes(filter.toLowerCase());
    }

    return (
      item.text?.toLowerCase().includes(filter.toLowerCase()) ||
      item.command?.toLowerCase().includes(filter.toLowerCase())
    );
  });
};

export const getUniqueList = (list: Snippet[], snippets: Snippet[]): Snippet[] => {
  return snippets.length
    ? list.filter((item) => {
        return !snippets.some((tag) => item.id && tag.id?.equals(item.id));
      })
    : list;
};

export class SnippetsStore extends APILayer {
  editor: EditorStore | null = null;
  private editorProxy_: TextEditorProxy = new TextEditorProxy();

  constructor(public app: AppStore, public type: SnippetType) {
    super(app);

    makeObservable(this);

    this.editor = new EditorStore(this.editorProxy_);
  }

  @computed get isPersonal() {
    return this.type === SnippetType.Personal;
  }

  @computed get isWorkspace() {
    return this.type === SnippetType.Workspace;
  }

  @observable private list_: Snippet[] = [];

  @computed get list(): Snippet[] {
    return this.list_;
  }

  @action private setList_ = (rawList: entities.IWorkspaceSnippet[]) => {
    this.list_ = rawList.map((raw) => new Snippet(raw, this.type));
  };

  find = (id?: Long | null): Snippet | null => {
    return this.list_.find((s) => id && s.id.equals(id)) || null;
  };

  @computed get count(): number {
    return this.list_.length;
  }

  @action reset = () => {
    this.list_ = [];
  };

  @observable skipNextRequestUpdate = false;

  @action setSkipNextRequestUpdate = (skip: boolean) => {
    this.skipNextRequestUpdate = skip;
  };

  load = async () => {
    const {error, res} = await this.app.activeWorkspace.workspaceRequest<api.IWorkspaceSnippetsSetResponse>(
      {
        snippets: new api.WorkspaceSnippetsSetRequest({
          workspaceId: this.app.activeWorkspace.id,
          personal: this.type === SnippetType.Personal,
        }),
      },
      'snippets',
    );

    if (res?.snippets) {
      this.processSnippets_(res?.snippets);
    }

    return {error, res: res};
  };

  create = async (data: CreateSnippetData) => {
    const newSnippet = this.createNewSnippet_(data);

    return await this.setSnippetsRequest(this.list_.concat([newSnippet]));
  };

  update = async (id?: Long | null, data?: CreateSnippetData) => {
    const found = this.find(id);

    if (found) {
      found.update(data);
      return await this.setSnippetsRequest(this.list_);
    }

    const newSnippet = this.createNewSnippet_(data);
    return await this.setSnippetsRequest(this.list_.concat([newSnippet]));
  };

  updateOrder = async (snippets: Snippet[]) => {
    const prevList = this.list_.slice();

    this.processSnippets_(snippets);
    this.setSkipNextRequestUpdate(true);

    const {res, error} = await this.setSnippetsRequest(snippets, false);

    if (error) {
      this.processSnippets_(prevList);
    }

    return {res, error};
  };

  delete = async ({id}: ISnippet) => {
    const snippets = this.list_.slice();
    const idx = snippets.findIndex((s) => id && s.id.equals(id));

    if (idx !== -1) {
      snippets.splice(idx, 1);
    }

    return await this.setSnippetsRequest(snippets);
  };

  private createNewSnippet_ = (raw?: entities.IWorkspaceSnippet): Snippet => {
    return new Snippet(
      {
        ...raw,
        memberID: raw?.memberID || this.isPersonal ? this.app.userStore.currentMember?.id : null,
      },
      this.type,
    );
  };

  private setSnippetsRequest = async (
    snippets?: entities.IWorkspaceSnippet[] | null,
    enableProcessSnippets: boolean = true,
  ) => {
    const {error, res} = await this.app.activeWorkspace.workspaceRequest<api.IWorkspaceSnippetsSetResponse>(
      {
        snippetsSet: new api.WorkspaceSnippetsSetRequest({
          workspaceId: this.app.activeWorkspace.id,
          personal: this.isPersonal,
          snippets,
        }),
      },
      'snippetsSet',
    );

    if (res?.snippets && enableProcessSnippets) {
      this.processSnippets_(res?.snippets);
    }

    return {error, res};
  };

  @action private processSnippets_ = (snippets: entities.IWorkspaceSnippet[]) => {
    if (this.skipNextRequestUpdate) {
      this.setSkipNextRequestUpdate(false);
    } else {
      this.setList_(snippets);
    }
  };
}

export default SnippetsStore;
