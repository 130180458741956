import React from 'react';

import useStore from '../../../../stores/useStore';
import {entities} from '../../../../api/proto';

export default function useTariff(tariffId?: string | null) {
  const [tariff, setTariff] = React.useState<entities.ITariff | null>(null);
  const {activeWorkspace: {billing}} = useStore();

  React.useEffect(() => {
    if (!tariffId || tariff?.id === tariffId) {
      return;
    }

    if (!billing.availableTariffs.length) {
      billing.loadAvailableTariffs();
    }

    const foundTariff = billing.findTariff(tariffId);
    if (!foundTariff) {
      return;
    }

    setTariff(foundTariff);
  }, [
    billing,
    billing.availableTariffs,
    tariffId,
    tariff?.id,
  ]);

  return tariff;
}