import React from 'react';
import {observer} from 'mobx-react';

import useStore from '../stores/useStore';
import ModalWrapper from './ModalWrapper';

export const Modals: React.FC<React.PropsWithChildren> = observer(() => {
  const {modals} = useStore();

  return (
    <>
      {modals.list.map((modal) => (
        <ModalWrapper key={modal.id.toString()} modal={modal} />
      ))}
    </>
  );
});

export default Modals;