import Long from 'long';
import {action, computed, observable} from 'mobx';

import {IMCAttachment, IMCWebWidgetConfig, MCWebWidgetConfig} from '../../api/proto';
import Attachment from '../Attachment/Attachment';


export function generateWebWidgetScriptUrl(APICode?: string | null) {
  if (!APICode) {
    return '';
  }
  return `<script src="//${window.location.host}/widget/loader?id=${APICode}" async ></script>`;
}

export class WebWidgetConfig implements IMCWebWidgetConfig {
  constructor(public raw?: IMCWebWidgetConfig | null) {
    this.assign_(raw);
  }

  @observable APICode?: string | null;
  @observable domain?: string | null;
  @observable displayCompanyName?: string | null;
  @observable displayWelcomeMessage?: string | null;
  @observable displayAvatar?: Attachment | null;
  @observable displayColor?: string | null;
  @observable chatButtonSize?: Long | null;
  @observable position?: string | null;
  @observable helloSnippets?: MCWebWidgetConfig.IHelloSnippet[] | null;
  @observable externalMessagingAPIEnabled?: boolean | null;

  @action protected assign_ = (rawConfig?: IMCWebWidgetConfig | null) => {
    Object.assign(this, {
      ...rawConfig,
      displayAvatar: rawConfig?.displayAvatar ? new Attachment(rawConfig?.displayAvatar) : null,
    });
  };

  @computed get scriptUrl(): string {
    return generateWebWidgetScriptUrl(this.APICode);
  }

  @action public setAvatar = (attachment?: IMCAttachment | null) => {
    this.displayAvatar = attachment ? new Attachment(attachment) : null
  };
}

export default WebWidgetConfig;
