import React from 'react';
import {autorun} from 'mobx';

import {useStore} from '../../../../stores/AppStore';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import {uint8ArrayToUuid} from '../../../../utils/arrayUtils';

interface AttachmentProgressInfo {
  cancel?: () => void;
  progress: number;
  isInProgress: boolean;
  isDone: boolean;
  isUpload: boolean;
  error?: string;
}

export default function useAttachmentProgress(attachment?: MessageAttachment | null): AttachmentProgressInfo {
  const {
    activeWorkspace: {attachmentStore},
    uploader,
  } = useStore();

  const [isInProgress, setIsInProgress] = React.useState<boolean>(false);
  const [isDone, setIsDone] = React.useState<boolean>(false);
  const [isUpload, setIsUpload] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<number>(0);
  const [error, setError] = React.useState<string | undefined>();

  const cancel = React.useCallback(() => {
    if (attachment && uploader.hasInProgress(attachment?.id)) {
      attachment.message.chat.store.rawMessagesStore?.removeMessage(attachment.message.id, attachment.message.randomID);
      uploader.cancelUpload(attachment.id);
    }

    if (attachment && attachmentStore.hasInProgress(attachment.id)) {
      attachmentStore.cancelDownload(attachment.id);
    }
  }, [attachment, uploader, attachmentStore]);

  React.useEffect(() => {
    autorun(() => {
      const attachmentUuid = uint8ArrayToUuid(attachment?.id);

      const uploadState = uploader.progressMap[attachmentUuid];
      const downloadState = attachmentStore.progressMap[attachmentUuid];
      const progressState = uploadState || downloadState;

      if (!isUpload && uploadState) {
        setIsUpload(true);
      } else if (isUpload && !uploadState && downloadState) {
        setIsUpload(false);
      }

      if (progressState?.error) {
        setError(progressState.error);
      }

      if (!isInProgress && progressState) {
        setIsInProgress(true);
        setIsDone(false);
        // console.debug(`%c--->useAttachmentProgress start: ${attachmentUuid}`, 'color: red');
      } else if (isInProgress && !progressState) {
        setIsInProgress(false);
        setIsDone(true);
        // console.debug(`%c--->useAttachmentProgress done: ${attachmentUuid}`, 'color: red');
      }

      const currentProgress = progressState?.progressPercent || 0;
      if (!progressState && isDone && progress !== 100) {
        // console.debug(`%c--->useAttachmentProgress 100%: ${attachmentUuid}`, 'color: red');
        setProgress(100);
      } else if (progressState && progress !== currentProgress) {
        // console.debug(`%c--->useAttachmentProgress ${currentProgress}%: ${attachmentUuid}`, 'color: red');
        setProgress(currentProgress);
      }
    });
  }, [
    attachment?.id,
    attachmentStore.progressMap,
    uploader.progressMap,
    isUpload,
    isInProgress,
    isDone,
    progress,
  ]);

  return {
    cancel,
    isInProgress,
    isDone,
    isUpload,
    progress,
    error,
  };
}
