import {entities} from '../../../api/proto';
import {t} from '../../../i18n';

export function getErrorByUpdateMemberResult(type?: entities.WorkspaceUpdateMemberResult | null): string | null {
  switch (type) {
    case entities.WorkspaceUpdateMemberResult.WUMR_MEMBER_NOT_FOUND:
      return t('Member not found');
    case entities.WorkspaceUpdateMemberResult.WUMR_INVALID_ROLE:
      return t('Invalid role');
    case entities.WorkspaceUpdateMemberResult.WUMR_INVALID_STATUS:
      return t('Invalid status');
    case entities.WorkspaceUpdateMemberResult.WUMR_INVALID_ACCESS:
      return t('Invalid access');
    case entities.WorkspaceUpdateMemberResult.WUMR_WORKSPACE_LIMIT:
      return t('Workspace limit');
  }

  if (type && entities.WorkspaceUpdateMemberResult[type]) {
    return entities.WorkspaceUpdateMemberResult[type]?.toLowerCase().replace(/_/g, ' ');
  }

  return null;
}

export default getErrorByUpdateMemberResult;