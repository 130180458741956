import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react-lite';

import {WorkspaceMemberAccessRecord} from '../../../../stores/Workspaces/WorkspaceMemberAccessRecord';
import WorkspaceMemberConfig from '../../../../stores/Workspaces/WorkspaceMemberConfig';
import {ChannelsAccess} from './ChannelsAccess';
import {ChatsAccess} from './ChatsAccess';
import {equalUint8Arrays} from '../../../../utils/arrayUtils';


interface ChannelsAndChatsAccessProps {
  memberConfig: WorkspaceMemberConfig;
  disabled?: boolean;
  className?: string;
  onToggleAccessRecord?(record: WorkspaceMemberAccessRecord | null): void;
}

export interface ChannelsAndChatsAccessRef {
  selectedAccessRecord: WorkspaceMemberAccessRecord | null;
  backToChannels: () => void;
}

export const ChannelsAndChatsAccess = observer(
  React.forwardRef<ChannelsAndChatsAccessRef, ChannelsAndChatsAccessProps>(
    ({memberConfig, className, disabled, onToggleAccessRecord}, ref) => {
      const [selectedAccessRecord, setSelectedAccessRecord] = React.useState<WorkspaceMemberAccessRecord | null>(null);

      React.useEffect(() => {
        const record = memberConfig.channelAccessRecords.find(({channelID}) => equalUint8Arrays(channelID, selectedAccessRecord?.channelID));
        record && setSelectedAccessRecord(record);
      }, [memberConfig.channelAccessRecords, selectedAccessRecord]);

      const handleOpenAccessRecord = (record: WorkspaceMemberAccessRecord) => {
        setSelectedAccessRecord(record);
        onToggleAccessRecord?.(record);
      };

      const handleBackToChannels = () => {
        setSelectedAccessRecord(null);
        onToggleAccessRecord?.(null);
      };

      React.useImperativeHandle(ref, () => ({
        selectedAccessRecord: selectedAccessRecord,
        backToChannels: handleBackToChannels,
      }));

      return (
        <div className={cn('channels-chats-access', className, {disabled})}>
          <div className="channels-chats-access__container">
            {selectedAccessRecord ? (
              <ChatsAccess onBackToChannels={handleBackToChannels} accessRecord={selectedAccessRecord} />
            ) : (
              <ChannelsAccess memberConfig={memberConfig} onOpenAccessRecord={handleOpenAccessRecord} />
            )}
          </div>
        </div>
      );
    },
  ),
);

export default ChannelsAndChatsAccess;
