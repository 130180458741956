import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import getChannelTypeName from '../stores/Channel/utils/getChannelTypeName';
import {ModalItem} from '../stores/ModalsStore';
import {formatPhoneNumber} from '../utils/getPhoneMask';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
}

export const ModalChannelDeleting = (props: IProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const {channel} = props.modal.data;

  const handleSubmit = () => {
    props.modal.trigger('submit', props.modal.data);
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  return (
    <ModalDialog ref={modalRef} modal={props.modal} className="new-dlg">
      <div className="channel-dialog-form channel-blocking">
        <div className="channel-dialog-form__body">
          <div className="channel-dialog-form__header">
            <span className="channel-dialog-form__title">{t('modal_channel_deleting_title')}</span>
            <span
              className="tg-icon-cross-line cursor-pointer icon-size-18 color-body-primary ml-auto"
              onClick={handleCloseClick}
            />
          </div>

          <div className="channel-dialog-form__form custom-scroll d-flex flex-column">
            <div className="h4-bold font-bold color-body-primary">{t('Confirm the channel deleting')}</div>

            <div className="channel-dialog-form__field-label">{t('modal_channel_deleting_messenger')}</div>
            <div className="channel-dialog-form__field-value">{getChannelTypeName(channel?.type)}</div>

            <div className="channel-dialog-form__field-label">{t('modal_channel_deleting_phone_number')}</div>
            <div className="channel-dialog-form__field-value">
              {channel?.phone ? formatPhoneNumber(channel?.phone) : '-'}
            </div>

            <div className="channel-dialog-form__field-label">{t('modal_channel_deleting_channel_name')}</div>
            <div className="channel-dialog-form__field-value">{channel?.name || '-'}</div>

            <div className="channel-dialog-form__field-label">{t('modal_channel_deleting_username')}</div>
            <div className="channel-dialog-form__field-value">{channel?.userName || '-'}</div>
          </div>

          <div className="channel-dialog-form__footer mt-5">
            <Button
              variant="contained"
              color="error"
              size="large"
              className="channel-dialog-form__connect-button"
              onClick={handleSubmit}
            >
              {t('modal_channel_deleting_delete_btn')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className="channel-dialog-form__cancel-button"
              onClick={handleCloseClick}
            >
              {t('modal_cancel_btn')}
            </Button>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};

export default ModalChannelDeleting;
