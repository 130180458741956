import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';

import {SEARCH_TEXT_LENGTH_LIMIT} from '../../constants';
import {IMCChatPreview, IMCMessage} from '../../api/proto';
import Message from '../../stores/Message';
import {equalUint8Arrays} from '../../utils/arrayUtils';
import Chat from '../Chat/Chat';
import {ITag} from '../MessageTagsStore';
import RawChatsSearch from '../RawChatsSearch';
import RawChatsSearchEvent from '../RawChatsSearch/RawChatsSearchEvent';
import RawChatsStore from '../RawChatsStore';
import {IRawChat} from '../RawChatsStore/RawChat';
import RawChatsStoreEvent from '../RawChatsStore/RawChatsStoreEvent';
import WorkspaceStore from '../Workspaces/WorkspaceStore';

export class ChatsSearch {
  @observable protected _loading = false;

  @action protected setLoading_ = (loading: boolean) => {
    this._loading = loading;
  };

  @computed public get enabled(): boolean {
    return !!(this.searchText || this.chatForSearch);
  }

  @computed public get loading(): boolean {
    if (!this.enabled) {
      return false;
    }
    return this._loading;
  }

  private rawChatsSearch_: RawChatsSearch | null = null;

  constructor(public workspace: WorkspaceStore) {
    makeObservable(this);

    this.rawChatsSearch_ = new RawChatsSearch(this.workspace);
    this.rawChatsSearch_?.on(RawChatsSearchEvent.CLEAR, this.onClear_);
    this.rawChatsSearch_?.on(RawChatsSearchEvent.FIND_CHATS, this.onFoundChats_);
    this.rawChatsSearch_?.on(RawChatsSearchEvent.FIND_MESSAGES, this.onFoundMessages_);
    this.rawChatsSearch_?.on(RawChatsStoreEvent.UPDATE_CHAT, this.onUpdateChat_);
  }

  @observable public searchText = '';
  @observable public searchFieldInFocus = false;
  @observable public channelId?: Uint8Array | null = null;
  @observable public chatForSearch?: Chat;
  @observable public tagForSearch?: ITag | null;

  @observable public foundChats: Chat[] = [];
  @observable public foundMessages: Message[] = [];

  @action public onFocusSearchField = () => {
    this.searchFieldInFocus = true;
  };

  @action public onBlurSearchField = () => {
    this.searchFieldInFocus = false;
  };

  @computed public get unreadFilterVisible(): boolean {
    return !(this.searchText || this.searchFieldInFocus || this.chatForSearch || this.tagForSearch);
  }

  public searchInChat = async (searchText: string, channelId?: Uint8Array | null) => {
    this.setSearchText_(searchText);

    this.setLoading_(true);
    await this.rawChatsSearch_?.searchMessages(
      searchText,
      this.chatForSearch?.id,
      this.chatForSearch?.channelID || channelId,
    );
    this.setLoading_(false);
  };

  public searchInChannel = async (searchText: string, channelId?: Uint8Array | null) => {
    this.setSearchText_(searchText);

    this.setLoading_(true);
    await this.rawChatsSearch_?.searchChatsAndMessages(
      searchText,
      this.chatForSearch?.id,
      this.chatForSearch?.channelID || channelId,
    );
    this.setLoading_(false);
  };

  public searchChats = async (searchText: string, channelId?: Uint8Array | null) => {
    this.setSearchText_(searchText);

    this.setLoading_(true);
    await this.rawChatsSearch_?.searchChats(searchText, this.chatForSearch?.channelID || channelId);
    this.setLoading_(false);
  };

  public findChat = (chatID?: Long | null, channelID?: Uint8Array | null): Chat | null => {
    if (!chatID) {
      return null;
    }
    if (channelID) {
      return (
        this.foundChats.find((chat) => chatID?.equals(chat.id) && equalUint8Arrays(channelID, chat.channelID)) || null
      );
    }
    return this.foundChats.find((chat) => chatID?.equals(chat.id)) || null;
  };

  @action private setSearchText_ = (searchText: string, channelId?: Uint8Array | null) => {
    if (searchText.length > SEARCH_TEXT_LENGTH_LIMIT) {
      return;
    }
    console.debug('ChatsSearch->setSearchText=', searchText);

    this.searchText = searchText;
    this.channelId = channelId;

    if (searchText) {
      this.workspace.chatsView.tags.init();
    }

    if (!searchText) {
      this.setTagForSearch(null);
    }
  };

  @action private setChatForSearch_ = (chatForSearch: Chat | null) => {
    console.debug(`%c----->ChatsSearch.setChatForSearch ${chatForSearch?.id?.toString()}`, 'color: #afb0b6');
    this.chatForSearch = chatForSearch || undefined;
  };

  public enableSearchInActiveChat = (chat: Chat) => {
    console.debug(`%c----->ChatsSearch.enableSearchInActiveChat ${chat?.id?.toString()}`, 'color: #afb0b6');

    if (this.chatForSearch?.id.equals(chat.id)) {
      return;
    }

    this.setChatForSearch_(chat || null);
    this.searchInChat(this.searchText, chat?.channelID);
  };

  public enableSearchInActiveChannel = () => {
    console.debug(`%c----->ChatsSearch.enableSearchInActiveChannel`, 'color: #afb0b6');
    this.setChatForSearch_(null);
    this.searchInChannel(this.searchText, this.workspace.channels.selectedChannel?.id);
  };

  public disabledSearchInActiveChat = (activeUsersChat?: Chat | null) => {
    console.debug(`%c----->ChatsSearch.disabledSearchInActiveChat`, 'color: #afb0b6');
    this.setChatForSearch_(null);
    this.searchInChat(this.searchText, activeUsersChat?.channelID);
  };

  @action private onFoundChats_ = (rawChats: IMCChatPreview[], channelId?: Uint8Array | null) => {
    const chats: Chat[] = [];

    if (channelId) {
      const channel = this.workspace.findChannel(channelId);

      if (!channel) {
        return;
      }

      rawChats.forEach((rawChat) => {
        chats.push(new Chat(rawChat, channel));
      });
    } else {
      rawChats.forEach((rawChat) => {
        const channel = this.workspace.findChannel(rawChat?.channelID);

        if (channel) {
          chats.push(new Chat(rawChat, channel));
        }
      });
    }

    this.foundChats = chats;
  };

  private getChatsMap_ = (rawChats?: IMCChatPreview[]): Map<string, Chat> => {
    return (rawChats || []).reduce((map, rawChat) => {
      const chatIdStr = rawChat.id?.toString();
      const channel = this.workspace.findChannel(rawChat?.channelID);
      const chat = channel ? new Chat(rawChat, channel) : null;
      if (chatIdStr && chat) {
        map.set(chatIdStr, chat);
      }
      return map;
    }, new Map<string, Chat>());
  };

  @action private onFoundMessages_ = (rawMessages: IMCMessage[], channelID?: Uint8Array | null, rawChats?: IMCChatPreview[]) => {
    const msgs: Message[] = [];

    const chatsMap = this.getChatsMap_(rawChats);

    rawMessages.forEach((m) => {
      const chatIDStr = m.chatID?.toString();
      const chat = chatIDStr ? chatsMap.get(chatIDStr) : null;

      if (chat) {
        msgs.push(new Message(m, chat));
      } else {
        console.debug(`%c----->ChatsSearch.onFoundMessages_ not found chat ${chatIDStr}`, 'color: #afb0b6');
      }
    });

    msgs.sort((a, b) => (b.stamp?.toNumber() || 0) - (a.stamp?.toNumber() || 0));
    this.foundMessages = msgs;
  };

  protected onUpdateChat_ = (rawChatsStore: RawChatsStore, rawChat: IRawChat) => {
    console.debug('%c----->ChatsSearch.onUpdateChat', 'color: blue', rawChat);
    const chat = this.foundChats.find(({id}) => rawChat.id?.equals(id));
    chat?.update(rawChat);
  };

  protected onClear_ = () => {
    this.resetResult_();
  };

  @action public reset = () => {
    console.debug('%c----->ChatsSearch.reset', 'color: blue');
    this.searchText = '';
    this.channelId = null;
    this.chatForSearch = undefined;

    this.resetResult_();
  };

  @action private resetResult_ = () => {
    this.foundMessages = this.foundMessages.length ? [] : this.foundMessages;
    this.foundChats = this.foundChats.length ? [] : this.foundChats;
    this.foundMessagesByTags = this.foundMessagesByTags.length ? [] : this.foundMessagesByTags;
  };

  // Message tags:
  @observable foundMessagesByTags: Message[] = [];

  @action private searchMessagesByTags_ = (tagForSearch: ITag, forChannelId?: Uint8Array | null) => {
    console.debug('searchMessagesByTags', tagForSearch, forChannelId);
  };

  @action public setTagForSearch = (tagForSearch?: ITag | null, forChannelId?: Uint8Array | null) => {
    this.tagForSearch = tagForSearch;

    if (tagForSearch) {
      this.searchMessagesByTags_(tagForSearch, forChannelId);
    } else {
      this.foundMessagesByTags = [];
    }
  };

  public resetTagForSearch = () => {
    this.setTagForSearch(null);
  };

  @computed get foundTags(): ITag[] {
    const tags: ITag[] = [];
    if (this.searchText && this.searchText?.length > 1) {
      this.workspace.chatsView.tags.list.forEach((tag) => {
        if (tag.text?.toLowerCase().includes(this.searchText.toLowerCase()) && tag.id) {
          tags.push(tag);
        }
      });
    }
    return tags;
  }
}

export default ChatsSearch;
