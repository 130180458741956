import cn from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {EMPTY_IMG} from '../../constants';
import {fileUploader} from '../../api/fileUploader';
import InviteBase from '../../stores/Workspaces/InviteBase';

type PageType = 'login' | 'registration' | 'acceptInvite';

interface Props {
  invite?: InviteBase | null;
  className?: string;
  pageType?: PageType;
}

const InviteMessage: React.FC<Props> = ({invite, className, pageType = 'login'}) => {
  const {t} = useTranslation();

  const [src, setSrc] = React.useState<string>(EMPTY_IMG);

  const isEmailInvite = !!invite?.email;

  React.useEffect(() => {
    const loadLogo = async () => {
      if (!invite?.rawWorkspace?.avatar?.reference) {
        return;
      }

      const {res} = await fileUploader.download({
        attachmentID: invite?.rawWorkspace?.avatar?.reference,
      });

      if (res?.content) {
        try {
          const src = URL.createObjectURL(new Blob([res.content], {}));
          setSrc(src);
        } catch (err) {
          console.error(err);
        }
      }
    };

    loadLogo();
  }, [invite]);

  if (!invite) {
    return null;
  }

  return (
    <div className={cn('invite-message', className)}>
      <div className="invite-message__inner">
        <div className="invite-message__logo" style={{backgroundImage: `url(${src})`}}></div>
        <div className="invite-message__content overflow-hidden">
          <div className="invite-message__title label-1">{t('invite_message_title', {name: invite.workspaceName})}</div>
          <div className="invite-message__text">
            {pageType === 'registration'
              ? t('invite_message_registration_text')
              : pageType === 'acceptInvite'
                ? t('invite_message_accept_invite_text')
                : isEmailInvite
                  ? t('invite_message_by_email_text')
                  : t('invite_message_text')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteMessage;
