import React from 'react';
import {Navigate, generatePath, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import AuthInfo from './AuthInfo';
import ResetPasswordForm from './ResetPasswordForm';
import Div100vh from '../../components/Div100vh';
import {useStore} from '../../stores/AppStore';
import {observer} from 'mobx-react';
import Paths from '../../routes/Paths';

type RouteParams = {
  code?: string;
  email?: string;
};

const ResetPasswordPage = observer(() => {
  const {code = '', email = ''} = useParams<RouteParams>();
  const {t} = useTranslation();

  const {userStore} = useStore();

  if (userStore?.user && !userStore.user.isInit) {
    return null;
  }

  if (userStore?.user?.isLoggedIn) {
    return (
      <Navigate
        to={
          userStore?.user?.email?.toLowerCase() === email?.toLowerCase()
            ? generatePath(Paths.ResetPasswordProfile, {code, email})
            : generatePath(Paths.RecoveryPassword, {code, email})
        }
        replace
      />
    );
  } else {
    return (
      <Div100vh className="auth-page">
        <div className="auth-page__left-side">
          <AuthInfo />
        </div>

        <div className="auth-page__right-side">
          <div className="auth-page__title">{t('reset_password_title')}</div>

          <ResetPasswordForm className="mb-auto auth-page__form" code={code} email={email} />
        </div>
      </Div100vh>
    );
  }
});

export default ResetPasswordPage;
