import cn from 'classnames';
import React from 'react';
import {useInView} from 'react-intersection-observer';

import Image from 'o-ui/Image';
import {DEFAULT_MEDIA_PREVIEW_HEIGHT, DEFAULT_MEDIA_PREVIEW_WIDTH, EMPTY_IMG, EMPTY_IMG_BLUE} from '../constants';
import calcImageSize from '../utils/image/calcImageSize';

import {ReactComponent as PlayButton} from '../assets/image-icons/playButton.svg';

interface PreviewImageProps {
  className?: string;
  src?: string | null;
  fixedSize?: boolean;
  width?: number | null;
  height?: number | null;
  maxWidth?: number;
  maxHeight?: number;
  onClick?: () => void;
  onInViewChange?: (inView: boolean, entry: IntersectionObserverEntry) => void;
  onContextMenu?: React.MouseEventHandler;
  isScrolling?: boolean;
  mimeType?: (string | null);
  fileName?: (string | null);
  playButton?: boolean;
  style?: React.CSSProperties;
  blueThumbnail?: boolean;
}

export const PreviewImage: React.FC<PreviewImageProps> = React.memo((props) => {
  const [src, setSrc] = React.useState<string | undefined | null>(undefined);
  const [srcLoaded, setSrcLoaded] = React.useState<boolean>(false);
  const [prevSrc, setPrevSrc] = React.useState<string | undefined | null>(undefined);

  const [naturalWidth, setNaturalWidth] = React.useState<number>(0);
  const [naturalHeight, setNaturalHeight] = React.useState<number>(0);

  const {width, height} = props;

  let calcWidth = 0;
  let calcHeight = 0;

  if (props.fixedSize) {
    [calcWidth, calcHeight] = calcImageSize(
      width || naturalWidth || 0,
      height || naturalHeight || 0,
      props.maxWidth,
      props.maxHeight,
    );
  }

  const {ref: inViewRef, inView} = useInView({
    threshold: 0,
    onChange: props.onInViewChange,
  });

  React.useEffect(() => {
    if (!props.isScrolling && inView) {
      setSrcLoaded(false);
      setSrc(props.src);
      if (!prevSrc) {
        setPrevSrc(props.src);
      }
    }
  }, [
    props.src,
    props.isScrolling,
    inView,
    prevSrc,
  ]);

  const handleLoad = React.useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
    if ((
      e.currentTarget.src !== EMPTY_IMG && e.currentTarget.src !== EMPTY_IMG_BLUE) ||
      e.currentTarget.src !== props.src) {
      setNaturalWidth(e.currentTarget.naturalWidth);
      setNaturalHeight(e.currentTarget.naturalHeight);
      setSrcLoaded(true);
    }
  }, [props.src]);

  const size = props.fixedSize ? {
    width: calcWidth || DEFAULT_MEDIA_PREVIEW_WIDTH,
    height: calcHeight || DEFAULT_MEDIA_PREVIEW_HEIGHT
  } : {};

  return (
    <>
      {prevSrc && !srcLoaded ? (
        <div
          className={cn('message-img__img-prev')}
          style={{backgroundImage: `url(${prevSrc})`}}
        />
      ) : null}
      <Image
        ref={inViewRef}
        className={cn(props.className, `fixedSize-${props.fixedSize}`, {
          'message-img__img--loading': src && !srcLoaded,
          [`calcWidth-${calcWidth}`]: true,
          [`calcHeight-${calcHeight}`]: true,
          [`width-${width}`]: true,
          [`height-${height}`]: true,
          [`naturalWidth-${naturalWidth}`]: true,
          [`naturalHeight-${naturalHeight}`]: true,
        })}
        style={{...size, ...props.style}}
        src={src || (props.blueThumbnail ? EMPTY_IMG_BLUE : EMPTY_IMG)}
        onClick={props.onClick}
        onContextMenu={props.onContextMenu}
        loaderEnabled={!prevSrc}
        retryOnError={true}
        reloadButtonEnabled={true}
        reloadButtonSize={32}
        useCache={false}
        onLoad={handleLoad}
      />
      {props.playButton && props.onClick ? (
        <PlayButton
          className="message-video__preview-play-button position-absolute-center font-size-18"
          onClick={props.onClick}
        />
      ) : null}
    </>
  );
});

export default PreviewImage;