import React from 'react';
import {observer} from 'mobx-react';

import Chat from '../../../stores/Chat';
import SimpleBar from 'o-ui/CustomScrollbar/SimpleBar';
import useChatMessagesList from './useChatMessagesList';

interface ChatMessagesListContainerProps extends React.PropsWithChildren {
  chat: Chat;
}

export const ChatMessagesListContainer = observer((props: ChatMessagesListContainerProps) => {
  const {chat} = props;
  const chatMessages = chat.store?.groupedMessages || [];

  const list = useChatMessagesList({chat, chatMessages});

  return (
    <SimpleBar
      style={{maxHeight: '100%'}}
      className="chat-conversation__list chat-custom-scroll"
      scrollableNodeProps={{
        onScroll: list.onScroll,
        ref: list.ref,
      }}
    >
      {props.children}
    </SimpleBar>
  );
});

export default ChatMessagesListContainer;
