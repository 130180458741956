import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';

import {
  IMCMessage,
  IMCMessageAction,
  IMCMessagePreview,
  IMCPeerPreview,
  IMCServiceMessage,
  IMCTextEntities,
  MCAttachment,
} from '../../api/proto';
import {isGifFile, isImageFile} from '../../utils/file/fileReaders';
import MessageAttachment from '../Attachment/MessageAttachment';
import Chat from '../Chat';
import {messageIsDeleted, messageIsEdited} from './utils';

export class MessageBase implements IMCMessage {
  @observable randomID?: Long | null;
  @observable localID: Long = Long.ZERO;
  @observable sentAt?: Long | null;
  @observable peer?: IMCPeerPreview | null;
  @observable chatID?: Long | null;
  @observable actions?: IMCMessageAction[] | null;
  @observable attachments?: MessageAttachment[] | null;
  @observable serviceMessage?: IMCServiceMessage | null;
  @observable groupID?: Uint8Array | null;
  @observable externalID?: Uint8Array | null;
  @observable replyToMessageID?: Long | null;
  @observable replyToExternalMessageID?: Uint8Array | null;
  @observable replyToMessage?: IMCMessagePreview | null;
  @observable text?: string | null;
  @observable textEntities?: IMCTextEntities[] | null;
  @observable operatorID?: Long | null;
  @observable operatorName?: string | null;

  @observable error?: string | null;
  swapped?: boolean = false;
  @observable sending?: boolean = false;

  constructor(public raw: IMCMessage, public chat: Chat) {
    makeObservable(this);
  }

  @computed get id(): Long {
    return this.localID || Long.ZERO;
  }

  @computed get peerId() {
    return this.peer?.id;
  }

  @computed get operator() {
    return this.chat.store.channel.workspace.findMemberByUserId(this.operatorID);
  }

  @computed get isSystem(): boolean {
    return !!this.serviceMessage;
  }

  @computed get isExternal(): boolean {
    return !!this.peer;
  }

  @computed get isOwn(): boolean {
    return this.chat.channel.app.userStore.isCurrentOperator(this.operatorID);
  }

  @computed get senderName(): string {
    if (this.peer?.id && this.chat.store?.directPeer?.id?.equals(this.peer?.id)) {
      return this.chat.store?.directPeer.fullName;
    }

    if (this.peer) {
      return this.peer.name || '';
    }

    if (this.operatorID && this.operatorID.notEquals(0)) {
      return this.operator?.fullName || this.operatorID.toString() || '';
    }

    if (this.isOwn) {
      return this.chat.channel.app.userStore.user?.fullName || '';
    }

    if (this.operatorName) {
      return this.operatorName;
    }

    return '';
  }

  @computed get isUnknownSender() {
    return !this.peer && (!this.operatorID || this.operatorID.equals(0)) && !this.isOwn;
  }

  @computed get avatar() {
    if (this.peer) {
      return this.peer.avatar;
    }

    return null;
  }

  @computed get stamp() {
    return this.sentAt;
  }

  @computed get edited() {
    return messageIsEdited(this);
  }

  @computed get deleted() {
    return messageIsDeleted(this);
  }

  @computed get pinned() {
    return false;
  }

  @computed get firstAttachment(): MessageAttachment | null {
    return this.attachments ? this.attachments[0] : null;
  }

  @computed get type(): MCAttachment.Type | null {
    if (!this.attachments?.length) {
      return null;
    }

    const type: MCAttachment.Type | null | undefined = this.firstAttachment?.type;
    const every = this.attachments.every((attachment) => attachment.type === type);

    return every ? type || MCAttachment.Type.UNKNOWN : MCAttachment.Type.DOCUMENT;
  }

  @computed get isAlbum(): boolean {
    return !!this.groupID;
  }

  @computed get hasDocument() {
    return this.type === MCAttachment.Type.DOCUMENT && !isGifFile(this.firstAttachment?.mimeType);
  }

  @computed get isDocumentImage(): boolean {
    return !!this.attachments?.length && this.attachments?.every((attachment) => attachment.isDocumentImage);
  }

  @computed get hasAudio() {
    return this.type === MCAttachment.Type.AUDIO;
  }

  @computed get hasImage() {
    return this.type === MCAttachment.Type.IMAGE;
  }

  @computed get hasVideo() {
    return this.type === MCAttachment.Type.VIDEO;
  }

  @computed get hasSticker() {
    return this.type === MCAttachment.Type.STICKER && !this.hasStickerAnimated;
  }

  @computed get hasStickerAnimated() {
    return this.type === MCAttachment.Type.STICKER && !isImageFile(this.firstAttachment?.mimeType);
  }

  @computed get hasGif() {
    return this.type === MCAttachment.Type.GIF || isGifFile(this.firstAttachment?.mimeType);
  }

  @computed get hasVoice() {
    return this.type === MCAttachment.Type.VOICE;
  }

  @action protected assign_ = (chat: MessageBase, props: IMCMessage) => {
    // console.debug('%c----->chat.assign', 'color: #afb0b6', props);
    Object.assign(chat, props);
  };
}

export default MessageBase;
