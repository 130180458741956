import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import useStore from '../../../../stores/useStore';
import subscriptionInfoImg from '../../../../assets/images/subscription-info.png';

interface NoActiveSubscriptionInfoProps {
  className?: string;
}

export const NoActiveSubscriptionInfo: React.FC<NoActiveSubscriptionInfoProps> = observer(({className}) => {
  const {t} = useTranslation();
  const {activeWorkspace: {billing}} = useStore();

  return billing.noActiveSubscriptions ? (
    <div
      className={cn(
        'bg-brand-primary d-flex flex-row radius-8 border-primary p-6',
        className,
      )}
      style={{
        backgroundImage: `url(${subscriptionInfoImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'right',
      }}
    >
      <div className="d-flex flex-column justify-content-between">
        <div className="h3-bold">
          {t('settings_billing_subscription_no_active_title')}
        </div>

        <div className="body2-regular mt-1">
          {t('settings_billing_subscription_choose_plan')}
        </div>
      </div>
    </div>
  ) : null;
});

export default NoActiveSubscriptionInfo;