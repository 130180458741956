import React from 'react';
import cn from 'classnames';
import Long from 'long';
import moment from 'moment';

import {IconButton} from 'o-ui/Button';
import FileUploadingCircularProgress from '../pages/Chat/UserChat/MessageContent/FileUploadingCircularProgress';
import FileUploadingProgress from '../pages/Chat/UserChat/MessageContent/FileUploadingProgress';
import useDownloadAttachment from '../pages/Chat/UserChat/MessageContent/useDownloadAttachment';
import MessageAttachment from '../stores/Attachment/MessageAttachment';
import {FILES_DATE_TIME_FORMAT, niceBytes} from '../utils/format';
import FileIcon from './FileIcon';


interface IProps {
  attachment: MessageAttachment;
  isOwn?: boolean;
  objectUrl?: string;
  onDelete?(objectUrl?: string): void;
  small?: boolean;
  stamp?: Long | null;
}

export const FileInfo: React.FC<IProps> = (props) => {
  const date = props.stamp ? moment.unix(props.stamp.toNumber()).format(FILES_DATE_TIME_FORMAT) : '';
  const {download} = useDownloadAttachment(props.attachment);

  return (
    <div
      className={cn('file-info', {'is-own': props.isOwn})}
      data-attachment-uuid={props.attachment.uuid}
    >
      <FileUploadingCircularProgress
        className="mr-5"
        attachment={props.attachment}
        dark={props.isOwn}
        small={props.small}
      >
        <FileIcon
          blue={props.isOwn}
          className="file-info__icon"
          type={props.attachment.fileType}
          small={props.small}
          fileName={props.attachment.name}
          onClick={download}
        />
      </FileUploadingCircularProgress>

      <div className="file-info__body">
        <div className="text-left text-truncate">
          <div className="file-info__name">{props.attachment.name}</div>
          <p className="file-info__size body2-regular color-body-tertiary font-size-12 mb-0">
            <FileUploadingProgress attachment={props.attachment}>
              {niceBytes(props.attachment.fileSize)}
            </FileUploadingProgress>
          </p>
          {date ? <div className="file-info__date">{date}</div> : null}
        </div>
        {props.onDelete ? (
          <IconButton
            className="ml-auto"
            onClick={(e) => {
              e.preventDefault();
              props.onDelete?.(props?.objectUrl);
            }}
          >
            <span className="file-info__delete-btn tg-icon-delete-bin-line icon-size-18" />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
};

export default FileInfo;
