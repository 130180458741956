import Long from 'long';

import {
  IMCMethodSearchChatsResponse,
  MCMethod,
  MCMethodSearchChats,
  MCMethodSearchMessages,
  api,
} from '../../api/proto';
import {equalUint8Arrays, randomUint8Array, uint8ArrayToBase64, uuidToUint8Array} from '../../utils/arrayUtils';
import RawChatsStore from '../RawChatsStore';
import {IRawChat} from '../RawChatsStore/RawChat';
import RawChatsStoreMode from '../RawChatsStore/RawChatsStoreMode';
import {WorkspaceStore} from '../Workspaces';
import RawChatsSearchEvent from './RawChatsSearchEvent';

export class RawChatsSearch extends RawChatsStore {
  protected isInit_: boolean = false;

  public channelIDs: Uint8Array[] = [];
  protected channelID?: Uint8Array | null = null;

  constructor(protected workspace: WorkspaceStore) {
    super(workspace, [], '', RawChatsStoreMode.SEARCH);
  }

  public clear = () => {
    this.isInit_ = false;
    this.channelIDs = [];
    this.storeKey = '';
    this.rawChats_ = [];
    this.chatsIdNumbersDict_ = {};
    this.emit(RawChatsSearchEvent.CLEAR, this);
  };

  protected setChats_ = (rawChats: IRawChat[], channelIDs: Uint8Array[]) => {
    this.isInit_ = true;
    this.rawChats_ = rawChats;
    this.channelIDs = channelIDs;
    this.storeKey = channelIDs.map((id) => uint8ArrayToBase64(id)).join('_');

    const chatsIdNumbersDict: {[id: string]: boolean} = {};
    rawChats.forEach((raw) => {
      chatsIdNumbersDict[raw.id?.toString() || ''] = true;
    });
    this.chatsIdNumbersDict_ = chatsIdNumbersDict;
  };

  public find = (chatID?: Long | null): IRawChat | null => {
    return this.rawChats_.find((raw) => chatID && raw.id?.equals(chatID)) || null;
  };

  public searchChatsAndMessages = async (query: string, chatID?: Long | null, channelID?: Uint8Array | null) => {
    await Promise.all([
      this.searchChats(query, channelID),
      this.searchMessages(query, chatID, channelID),
    ]);
  };

  private searchChatsTrx_: Uint8Array | null = null;

  public searchChats = async (query: string, channelID?: Uint8Array | null) => {
    if (!query) {
      this.clear();
      return;
    }

    const req = new MCMethodSearchChats({
      channelID,
      query,
    });

    this.searchChatsTrx_ = randomUint8Array();

    const {res, trx} = await this.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            searchChats: req,
          }),
        }),
      },
      'channelsResponse',
      {trx: this.searchChatsTrx_},
    );

    if (equalUint8Arrays(this.searchChatsTrx_, uuidToUint8Array(trx))) {
      this.processSearchChats_(res?.methodResponse?.searchChats, channelID);
    }
  };

  private processSearchChats_ = (res?: IMCMethodSearchChatsResponse | null, channelID?: Uint8Array | null) => {
    const rawChats = res?.chats || [];
    this.setChats_(rawChats, channelID ? [channelID] : []);
    console.debug(
      `%c----->RawChatsSearch->processSearchChats_ ${this.storeKey} mode: ${this.mode}`,
      'color: gray',
      this.chatsIdNumbersDict_,
      rawChats,
    );

    this.emit(RawChatsSearchEvent.FIND_CHATS, this.rawChats_, channelID);
  };

  private searchMessagesTrx_: Uint8Array | null = null;

  public searchMessages = async (query: string, chatID?: Long | null, channelID?: Uint8Array | null) => {
    if (!query) {
      this.clear();
      return;
    }

    const req = new MCMethodSearchMessages({
      channelID,
      chatID,
      query,
    });

    this.searchMessagesTrx_ = randomUint8Array();

    const {res, trx} = await this.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            searchMessages: req,
          }),
        }),
      },
      'channelsResponse',
      {trx: this.searchMessagesTrx_},
    );

    if (equalUint8Arrays(this.searchMessagesTrx_, uuidToUint8Array(trx))) {
      this.processSearchChatsMessages_(res, channelID);
    }
  };

  private processSearchChatsMessages_ = (res?: api.ChannelsResponse | null, channelID?: Uint8Array | null) => {
    const rawMessages = res?.methodResponse?.searchMessages?.messages || [];
    const rawChats = res?.methodResponse?.searchMessages?.mentionedChats || [];

    this.emit(RawChatsSearchEvent.FIND_MESSAGES, rawMessages, channelID, rawChats);
  };
}

export default RawChatsSearch;