import cn from 'classnames';
import {IconButton} from 'o-ui/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {usePopperTooltip} from 'react-popper-tooltip';

interface SnippetManageActionsProps {
  onEdit?(): void;
  onDelete?(): void;
  isSorting?: boolean;
  allowChange?: boolean;
}

const SnippetManageActions: React.FC<SnippetManageActionsProps> = ({onDelete, onEdit, isSorting, allowChange}) => {
  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible: show,
  } = usePopperTooltip({
    closeOnOutsideClick: true,
    trigger: 'hover',
    placement: 'auto-start',
    delayHide: 200,
    interactive: true,
    offset: [0, 6],
  });
  const {t} = useTranslation();

  if (!allowChange) {
    return null;
  }

  return (
    <div className={cn('snippet-manage__actions', {hidden: isSorting})}>
      <IconButton
        ref={setTriggerRef}
        color="secondary"
        className="snippet-manage__btn tg-icon-more-line"
        type="button"
        size="small"
      />

      <div ref={setTooltipRef} {...getTooltipProps()} className={cn('dropdown-menu withoutPositionStyles', {show})}>
        {onEdit ? (
          <button type="button" className="dropdown-item p-3 border-bottom" onClick={onEdit}>
            {t('ctx_menu_snippet_manage_edit')}
          </button>
        ) : null}
        {onDelete ? (
          <button type="button" className="dropdown-item p-3 error-primary-color" onClick={onDelete}>
            {t('ctx_menu_snippet_manage_delete')}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default SnippetManageActions;
