import cn from 'classnames';
import Long from 'long';
import {observer} from 'mobx-react';
import {IconButton} from 'o-ui/Button';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {DURATION} from '../../../../constants';
import {useCountDown} from '../../../../hooks/useCountDown';
import {useStore} from '../../../../stores/AppStore';
import {Chat} from '../../../../stores/Chat';
import {getChatBlockedToTime} from '../../../../stores/Chat/utils';
import {ContextMenuType} from '../../../../stores/ContextMenuType';
import {ModalType} from '../../../../stores/ModalType';
import {formatDuration} from '../../../../utils/format';

interface ChatHeadProps {
  className?: string;
  chat: Chat;
}

export const ChatBlockButton: React.FC<ChatHeadProps> = observer((props) => {
  const {t} = useTranslation();
  const {modals, contextMenu, chatsView, notifications} = useStore();

  const {chat} = props;

  const blockedTimeLeft =
    (getChatBlockedToTime(chat, chat.store.closestPeerId)?.toNumber() || 0) - new Date().getTime();
  const [timeLeft, actions] = useCountDown(blockedTimeLeft);

  React.useEffect(() => {
    actions.start();
  }, [actions]);

  React.useEffect(() => {
    actions.start((getChatBlockedToTime(chat, chat.store.closestPeerId)?.toNumber() || 0) - new Date().getTime());
  }, [chat, chat.restrictions, chat.store.closestPeerId, actions]);

  const handleBlockClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    contextMenu.open({clientX: e.clientX, clientY: e.clientY}, ContextMenuType.BLOCK_WEB_WIDGET_CHAT, {
      chat: chatsView.activeUsersChat,
    });
  };

  const handleUnblockClick = () => {
    modals.open(ModalType.CHAT_UNBLOCKING, {
      chat: chat,
      callbacks: {
        submit: async () => {
          const {res, error} = await chat.restrictChatAccess(chat.store.closestPeerId, Long.ZERO);

          if (error) {
            notifications.error(error.message);
          }

          if (res) {
            notifications.success(
              <Trans
                i18nKey="notification_chat_unblocked"
                values={{chatName: chat.name}}
                components={{strong: <strong />}}
              />,
            );
          }
        },
      },
    });
  };

  if (timeLeft > 0) {
    return (
      <div className={cn('chat-block-button chat-block-button--blocked')} onClick={handleUnblockClick}>
        <i className="chat-block-button__icon tg-icon tg-icon-block-line" />
        <span className="chat-block-button__time">
          {blockedTimeLeft > DURATION.YEAR_1
            ? t('chat_user_chat_block_button_permanently_label')
            : formatDuration(timeLeft)}
        </span>
      </div>
    );
  }

  return (
    <IconButton
      className={cn(props.className, 'chat-block-button tg-icon tg-icon-block-line', {
        active: contextMenu.isOpen(ContextMenuType.BLOCK_WEB_WIDGET_CHAT),
      })}
      onClick={handleBlockClick}
      size="small"
    />
  );
});

export default ChatBlockButton;
