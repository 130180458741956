import {api} from '../../../api/proto';
import i18n from '../../../i18n';

const {t} = i18n;


export default function getCreateInviteError(status?: api.WorkspaceCreateInviteResponse.Status | null): string {
  switch (status) {
    case api.WorkspaceCreateInviteResponse.Status.CINV_INVALID_WORKSPACE:
      return t('api_workspace_invite_status_invalid_workspace');
    case api.WorkspaceCreateInviteResponse.Status.CINV_INVALID_EMAIL:
      return t('api_workspace_invite_status_invalid_email');
    case api.WorkspaceCreateInviteResponse.Status.CINV_INVALID_ROLE:
      return t('api_workspace_invite_status_invalid_role');
    case api.WorkspaceCreateInviteResponse.Status.CINV_INVALID_ACCESS:
      return t('api_workspace_invite_status_invalid_access');
    case api.WorkspaceCreateInviteResponse.Status.CINV_ALREADY_INVITED:
      return t('api_workspace_invite_status_already_invited');
    case api.WorkspaceCreateInviteResponse.Status.CINV_ALREADY_MEMBER:
      return t('api_workspace_invite_status_already_member');
    case api.WorkspaceCreateInviteResponse.Status.CINV_UPGRADE_REQUIRED:
      return t('api_workspace_invite_status_upgrade_required');
    case api.WorkspaceCreateInviteResponse.Status.CINV_INTERNAL_ERROR:
      return t('api_workspace_invite_status_internal_error');
  }

  if (status && api.WorkspaceCreateInviteResponse.Status[status]) {
    return api.WorkspaceCreateInviteResponse.Status[status]?.toLowerCase().replace(/_/g, ' ');
  }

  return '';
}
