import {computed, observable} from 'mobx';

import {IMCMessage} from '../../api/proto';
import {parseUnixStamp} from '../../utils/format';
import MessageAttachment from '../Attachment/MessageAttachment';
import {Chat} from '../Chat';
import {ITag} from '../MessageTagsStore';
import {MessageBase} from './MessageBase';
import {getMessageServiceText} from './utils';
import Peer from '../Peer';

export type MessageClickEventHandler<T = Message> = (message: T) => void;

export class Message extends MessageBase {
  constructor(raw: IMCMessage, chat: Chat) {
    super(raw, chat);
    this.update(raw);
  }

  @observable messageNode: HTMLElement | null = null;

  setMessageRef = (ref: HTMLElement | null) => {
    this.messageNode = ref;
  };

  @computed get tags(): ITag[] {
    return [];
  }

  @computed get isDelivered(): boolean {
    return !this.sending;
  }

  @computed get menuIsAvailable(): boolean {
    return this.isDelivered;
  }

  @computed get isSelected(): boolean {
    return false; //NOT implemented yet
  }

  @computed get isReadInbox(): boolean {
    return !(!this.chat?.inReadPointer || this.localID?.greaterThan(this.chat.inReadPointer));
  }

  @computed get isReadOutbox(): boolean {
    return !(!this.chat?.outReadPointer || this.localID?.greaterThan(this.chat.outReadPointer));
  }

  @computed get isFailed(): boolean {
    return !!this.error;
  }

  @computed get isDeleted(): boolean {
    return this.deleted;
  }

  @computed get daysPassed() {
    const diff = new Date().getTime() - parseUnixStamp(this.stamp).valueOf();
    return Math.floor(diff / (24 * 60 * 60 * 1000));
  }

  @computed get editingIsAvailable() {
    return this.isOwn && this.daysPassed < 2 && !this.deleted;
  }

  update = (props: IMCMessage) => {
    this.assign_(this, {
      ...props,
      attachments: props.attachments?.map((attach) => new MessageAttachment(attach, this)),
    });
  };

  navigateToMessage = (delay?: number) => {
    if (delay) {
      setTimeout(() => {
        this.chat.store?.navigateToMessage(this);
      }, delay);
    } else {
      this.chat.store?.navigateToMessage(this);
    }
  };

  restartFoundMessageAnimation = () => {
    if (this.isAlbum && this.messageNode) {
      const albumMessageAnimationNode = this.messageNode
        .querySelector(`[data-msg-id="${this?.id.toString()}"]`)
        ?.querySelector('.found-message-animation') as HTMLDivElement;

      if (albumMessageAnimationNode) {
        albumMessageAnimationNode.style.animation = 'none';
        albumMessageAnimationNode.offsetWidth;
        albumMessageAnimationNode.style.animation = 'found-message-animation 3s';
      }
      return;
    } else if (this.messageNode) {
      this.messageNode.style.animation = 'none';
      this.messageNode.offsetWidth;
      this.messageNode.style.animation = 'found-message-animation 3s';
    }
  };

  @computed get serviceMessageText(): string {
    return this.serviceMessage ? getMessageServiceText(this.serviceMessage, this) : '';
  }

  @computed get hidden(): boolean {
    //Hide by backend dev's request, because have  the same message [migratedFromSuperGroup]
    return !!this.serviceMessage?.migratedToSuperGroup;
  }

  @computed get peerInstance(): Peer | null {
    return this.peer ? new Peer(this.peer, this.chat) : null;
  }
}

export default Message;
