import {filterUnique, findEmails, findPhones, findUrls} from './utils';
import {RawMessage} from '../IRawMessagesStore';

export function textEntitiesBuilder(rawMessage: RawMessage): RawMessage {
  const text = rawMessage.text;
  const textEntities = rawMessage.textEntities || [];

  const urlEntities = filterUnique(findUrls(text), textEntities);
  const emailEntities = filterUnique(findEmails(text), textEntities, true);
  const phoneEntities = filterUnique(findPhones(text), textEntities);

  if (urlEntities.length || emailEntities.length || phoneEntities.length) {
    rawMessage.textEntities = [
      ...textEntities,
      ...urlEntities,
      ...emailEntities,
      ...phoneEntities
    ];
  }

  return rawMessage;
}

export default textEntitiesBuilder;
