
export default function jsonStringify(obj?: unknown | null): string {
  if (!obj) {
    return '';
  }

  try {
    return JSON.stringify(obj);
  } catch (e) {
    console.debug(e);
  }

  return '';
}
