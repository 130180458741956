import React from 'react';
import {observer} from 'mobx-react';

import Skeleton from 'o-ui/Skeleton';
import {useStore} from '../../../../../stores/AppStore';


interface ChatsListSkeletonProps {
  className?: string;
  children: React.ReactNode;
}

const DEFAULT_ITEMS_COUNT = 20;

const ChatsListSkeleton = observer((props: ChatsListSkeletonProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const {activeWorkspace: {chatsLoader, chatsView}} = useStore();
  const itemRef = React.useRef<HTMLDivElement>(null);

  const [loading, setLoading] = React.useState<boolean>(false);
  const loadingTimeout = React.useRef<NodeJS.Timeout | null>(null);

  const [itemsCount, setItemsCount] = React.useState<number>(DEFAULT_ITEMS_COUNT);

  React.useEffect(() => {
    if (
      (!chatsView.search.enabled && chatsLoader.chatsLoading !== loading) ||
      (chatsView.search.enabled && !chatsView.search.chatForSearch && chatsView.search.loading !== loading)
    ) {
      loadingTimeout.current && clearTimeout(loadingTimeout.current);
      if (chatsView.search.enabled && chatsView.search.loading !== loading && chatsView.search.loading === true) {
        setLoading(chatsView.search.loading);
      } else {
        loadingTimeout.current = setTimeout(() => {
          if (chatsView.search.enabled) {
            setLoading(chatsView.search.loading);
          } else {
            setLoading(chatsLoader.chatsLoading);
          }
        }, 200);
      }
    }
  }, [
    loading,
    chatsLoader.chatsLoading,
    chatsView.search.enabled,
    chatsView.search.chatForSearch,
    chatsView.search.loading,
    loadingTimeout,
  ]);

  React.useEffect(() => {
    const containerEl = containerRef.current;
    const itemEl = itemRef.current;

    if (containerEl && itemEl) {
      const count = Math.round(containerEl.offsetHeight / itemEl.offsetHeight);

      setItemsCount(count);
    }
  }, [containerRef, itemRef]);

  if (!loading) {
    return <>{props.children}</>;
  }

  return (
    <div ref={containerRef} className={props.className}>
      {Array(itemsCount)
        .fill('')
        .map((_, index) => (
          <div ref={itemRef} key={index} className="chat-item chat-item__skeleton">
            <div className="chat-item__body">
              <div className="chat-item__avatar">
                <Skeleton width={40} height={40} circle animationType="slide" />
              </div>
              <div className="chat-item__data">
                <div className="chat-item__head">
                  <Skeleton width={115} height={16} animationType="slide" />
                  <Skeleton width={39} height={16} animationType="slide" />
                </div>
                <div className="chat-item__last-message d-flex mt-3">
                  <Skeleton withoutContainer inline width="100%" height={16} animationType="slide" />
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
});

export default ChatsListSkeleton;
