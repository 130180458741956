import axios from 'axios';
import {compare} from 'compare-versions';
import {observable, action, computed, makeObservable} from 'mobx';
import qs from 'query-string';

import {AppStore} from './AppStore';
import {UPDATE_CHECK_INTERVAL} from '../constants';
import ModalType from './ModalType';
import wait from '../utils/wait';

export default class AppVersionUpdater {
  constructor(public app: AppStore) {
    makeObservable(this);

    this.start();
  }

  start = async () => {
    await wait(5000);

    await this.readHostedWebVersion();
  };

  @observable hasNewWebVersion: boolean = false;
  @observable hostedWebVersion: string | null = null;
  @observable hostedForceUpdateWebVersion: string | null = null;

  @observable isUpdating: boolean = false;

  @observable hasNewDesktopVersion: boolean = false;
  @observable newDesktopVersion?: string | null = null;

  @observable forceUpdateModalIsOpen: boolean = false;

  @computed public get hasUpdate(): boolean {
    return this.hasNewWebVersion || this.hasNewDesktopVersion;
  }

  @computed private get _hasForceUpdate(): boolean {
    if (this.hostedForceUpdateWebVersion && this.app.forceUpdateVersion) {
      return compare(this.hostedForceUpdateWebVersion, this.app.forceUpdateVersion, '>');
    }
    return false;
  }

  @computed private get _hasWebVersionUpdate(): boolean {
    if (this.hostedWebVersion && this.app.version) {
      return compare(this.hostedWebVersion, this.app.version, '>');
    }
    return false;
  }

  @action setForceUpdateModalIsOpen = (open: boolean) => {
    this.forceUpdateModalIsOpen = open;
  };

  @action setHasNewWebVersion = (value: boolean) => {
    this.hasNewWebVersion = value;
  };

  @action setUpdating = (updating: boolean) => {
    this.isUpdating = updating;
  };

  compareVersions = () => {
    if (this._hasForceUpdate) {
      this.openForceUpdateModal();
      return;
    }

    this.setHasNewWebVersion(this._hasWebVersionUpdate);
  };

  readHostedWebVersion = async () => {
    try {
      const res = await axios.get(`/version.json?t=${Date.now()}`, {
        params: {
          nocashe: Math.floor(Date.now() / 1000),
        },
        headers: {
          Accept: 'application/json',
        },
        timeout: 30000,
        transformResponse: (r) => r,
      });

      this.processHostedVersion(res.data);
    } catch (e) {
      console.debug(e);
    }

    this.compareVersions();

    await wait(UPDATE_CHECK_INTERVAL);

    await this.readHostedWebVersion();
  };

  reload = () => {
    const qsPrms = qs.parse(window.location.search);

    qsPrms['wv'] = this.hostedWebVersion;

    if (this.app.externalApp.desktopAppVersion) {
      qsPrms['v'] = this.app.externalApp.desktopAppVersion;
    }

    window.location.replace(window.location.origin + window.location.pathname + `?${qs.stringify(qsPrms)}`);
  };

  openForceUpdateModal = () => {
    if (this.forceUpdateModalIsOpen) return;

    this.app.modals.open(ModalType.FORCE_VERSION_UPDATE_APP, {
      callbacks: {
        onUpdate: this.update,
      },
    });

    this.setForceUpdateModalIsOpen(true);
  };

  update = async () => {
    this.setUpdating(true);

    this.app.externalApiCallHandler.updateWebApp(this.hasUpdate ? this.hostedWebVersion : this.hostedForceUpdateWebVersion);

    await wait(1000);

    this.reload();

    this.setUpdating(false);
    this.setHasNewWebVersion(false);
    this.setForceUpdateModalIsOpen(false);
  };

  @action processHostedVersion = (data?: string) => {
    if (typeof data !== 'string') {
      return;
    }

    try {
      const res = JSON.parse(data);
      if (res?.version) {
        this.hostedWebVersion = res.version;
      }
      if (res?.forceUpdateVersion) {
        this.hostedForceUpdateWebVersion = res.forceUpdateVersion;
      }
    } catch (e) {
      console.debug(e);
    }
  };

  @action public setNewDesktopVersion = (version?: string | null) => {
    this.setHasNewWebVersion(true);
    this.hasNewDesktopVersion = true;
    this.newDesktopVersion = version;
  };
}
