import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {AllChannelsIcon} from '../../../components/ChannelIcon';
import {useStore} from '../../../stores/AppStore';
import {Channel} from '../../../stores/Channel';
import ContextMenuType from '../../../stores/ContextMenuType';
import {ChatTab} from '../../../stores/LayOutStore';
import UnreadCounterIcon from './UnreadCounterIcon';

interface Props {
  onClick(channel: Channel | null): void;
}

export const ChannelsMenuItemAll: React.FC<Props> = observer((props) => {
  const {t} = useTranslation();
  const {channels, layOutStore, contextMenu} = useStore();

  const active = !channels.selectedChannel && [ChatTab.CHATS, ChatTab.CONTACTS].includes(layOutStore.activeTab);

  const onContextMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (channels.allChannelsUnreadCount) {
      contextMenu.open({clientX: e.clientX, clientY: e.clientY}, ContextMenuType.ALL_CHANNELS_ITEM_MENU, {});
    }
  };

  return (
    <div
      className={cn(
        'channels-menu__item py-4 px-4',
        {
          active,
        },
      )}
      onClick={() => props.onClick(null)}
      onContextMenu={onContextMenu}
    >
      <AllChannelsIcon
        className="channels-menu__item-icon"
        active={active}
      />
      <div className="channels-menu__item-info">
        <div className="channels-menu__item-title">{t('chat_user_chat_side_menu_all_channels_title')}</div>
      </div>

      <UnreadCounterIcon count={channels.allChannelsUnreadCount} />
    </div>
  );
});

export default ChannelsMenuItemAll;
