import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';
import {animated, useTransition} from 'react-spring';

import useStore from '../../stores/useStore';
import {ChatTab} from '../../stores/LayOutStore';
import {getStyleProperty, setStyleProperty} from '../../utils/styleProperty';
import Div100vh, {use100vh} from '../../components/Div100vh';
import ChatLeftSidebarTab from './ChatLeftSidebarTab';
import BottomMenu from './BottomMenu';


export const ChatLeftSidebar = observer(() => {
  const height = use100vh();
  const {activeWorkspace, layOutStore, animationsEnabled} = useStore();

  React.useEffect(() => {
    setStyleProperty('--chat-left-sidebar-height', height ? `${height}px` : '100vh');
  }, [height]);

  const sidebarWidth = getStyleProperty('--chat-left-sidebar-width').trim() || '330px';

  const reverseAnimation = layOutStore.activeTab === ChatTab.CONTACTS;

  const transitions = useTransition(layOutStore.activeTab, {
    from: {transform: `translate3d(${reverseAnimation ? sidebarWidth : `-${sidebarWidth}`}, 0, 0)`},
    enter: {transform: 'translate3d(0px, 0, 0)'},
    leave: {transform: `translate3d(${reverseAnimation ? `-${sidebarWidth}` : sidebarWidth}, 0, 0)`},
    // config: {duration: 4000},
  });

  const allowAnimation =
    animationsEnabled &&
    layOutStore.activeTab !== layOutStore.prevActiveTab &&
    [ChatTab.CHATS, ChatTab.CONTACTS].includes(layOutStore.activeTab) &&
    [ChatTab.CHATS, ChatTab.CONTACTS].includes(layOutStore.prevActiveTab);

  return (activeWorkspace.blocked || !activeWorkspace.channels.list.length) &&
    layOutStore.activeTab === ChatTab.CHATS ? null : (
    <Div100vh
      className={cn('chat-left-sidebar', {
        [`chat-left-sidebar--${ChatTab[layOutStore.activeTab].toLowerCase()}`]: true,
      })}
    >
      {allowAnimation
        ? transitions((styles, item) => (
          <animated.div key={item} style={styles} className={`animated-tab tab-index-${item}`}>
            <ChatLeftSidebarTab tab={item} />
          </animated.div>
        )) : (
          <ChatLeftSidebarTab tab={layOutStore.activeTab} />
        )}

      <BottomMenu />
    </Div100vh>
  );
});

export default ChatLeftSidebar;
