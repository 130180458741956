import React from 'react';
import {autorun} from 'mobx';

import Chat from '../Chat';

export default function useRemovedFromChat(chat: Chat) {
  const [removed, setRemoved] = React.useState(false);

  React.useEffect(() => {
    autorun(() => {
      setRemoved(
        chat.channel.isWebWidget &&
        !chat.store.members?.list.length
      );
    });
  }, [
    chat.channel.isWebWidget,
    chat.store.members?.list,
  ]);

  return removed;
}
