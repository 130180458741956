import React from 'react';
import cn from 'classnames';

import Tooltip from 'o-ui/Tooltip';
import Channel from '../../../stores/Channel';
import {formatPhoneNumber} from '../../../utils/getPhoneMask';


interface IProps {
  children?: React.ReactNode;
  channel: Channel;
  collapsed: boolean;
}

const getSubtitle = (channel: Channel): string => {
  if (channel.phone) {
    return formatPhoneNumber(channel.phone);
  }

  return channel.userName || channel.email || channel.webWidgetConfig?.domain || '';
};

export const ChannelsMenuItemTooltip: React.FC<IProps> = (props) => {
  const {children, channel, collapsed} = props;

  const subtitle = getSubtitle(channel);

  const collapsedHtml = (
    <div className="channels-menu__tooltip-inner">
      <div className="channels-menu__tooltip-info">
        <div className="channels-menu__tooltip-title">{channel.name}</div>
        <div className="channels-menu__tooltip-subtitle">{subtitle}</div>
      </div>
      <div className="channels-menu__tooltip-icons">
        {/* {channel.pinned ? (
          <div className="channels-menu__status-icon channels-menu__tooltip-icon">
            <i className="tg-icon tg-icon-pin-fill" />
          </div>
        ) : null} */}
        {channel.unreadNumber > 0 ? (
          <div className={cn('channels-menu__unread-counter', `digits-${channel.unreadNumber.toString().length}`)}>
            {channel.unreadNumber}
          </div>
        ) : null}
      </div>
    </div>
  );

  const html = (
    <div className="channels-menu__tooltip-inner">
      <div className="channels-menu__tooltip-info">
        <div className="channels-menu__tooltip-subtitle">{subtitle}</div>
      </div>
    </div>
  );

  return (
    <>
      {!collapsed && subtitle === '' ? (
        children
      ) : (
        <Tooltip
          className={cn('channels-menu__tooltip', {collapsed})}
          placement="right"
          arrow
          title={<React.Fragment>{collapsed ? collapsedHtml : html}</React.Fragment>}
        >
          {children}
        </Tooltip>
      )}
    </>
  );
};

export default ChannelsMenuItemTooltip;
