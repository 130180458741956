import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import CircularProgress from 'o-ui/CircularProgress';
import formatInstagramAuthLink from '../../../../stores/Channel/platforms/formatInstagramAuthLink';
import useStore from '../../../../stores/useStore';

import brandIcon from '../../../../assets/image-icons/brandIcon.svg';
import instagramIconSquared from '../../../../assets/image-icons/instagramIconSquared.svg';

interface InstagramAuthRedirectProps {
  title?: string;
  subTitle?: string;
}

export const InstagramAuthRedirect: React.FC<InstagramAuthRedirectProps> = observer(({
  title,
  subTitle,
}) => {
  const {t} = useTranslation();
  const {activeWorkspace} = useStore();

  const oauthUrl = formatInstagramAuthLink(activeWorkspace.id);

  window.location.href = oauthUrl;

  return (
    <div className="channel-dialog-form__form custom-scroll">
      {title ? <div className="h1-bold color-body-primary">{title}</div> : null}
      {subTitle ? <div className="body1-regular color-body-primary pb-6">{subTitle}</div> : null}

      <div className="channel-dialog-form__title-icons">
        <img className="channel-dialog-form__tg-icon" src={instagramIconSquared} alt="" />
        <img className="channel-dialog-form__ozekon-icon" src={brandIcon} alt="" />
      </div>
      <div className="h4-bold font-bold color-body-primary mt-5">
        {t('settings_channel_creation_form_auth_redirect')}
      </div>

      <div
        className="d-flex justify-content-center mt-5"
        style={{
          height: '50px',
        }}
      >
        <CircularProgress
          size={36}
          style={{
            color: 'var(--brand-primary)',
          }}
        />
      </div>
    </div>
  );
});

export default InstagramAuthRedirect;