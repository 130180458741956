import React from 'react';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import IconButton from 'o-ui/Button/IconButton';
import useMobileMode from '../../hooks/useMobileMode';

interface IProps {
  title?: string;
  links?: string[];
  onBack?: () => void;
  onToggleMenu?: () => void;
}

export const SettingsHeadTitle = (props: IProps) => {
  const fullscreenSettingsPage = useMobileMode();

  const {t} = useTranslation();
  return (
    <div className={cn('settings-tab__head-title', {'with-back': props?.links?.length})}>
      <div className="settings-tab__head-title-back">
        {props?.links?.length ? (
          <IconButton
            className="settings-tab__head-title-back-button tg-icon-chevron-left-line"
            onClick={props.onBack}
            size="large"
          />
        ) : fullscreenSettingsPage ? (
          <IconButton
            className="settings-tab__head-title-back-button mobile-open-menu-button tg-icon-chevron-left-line"
            onClick={props.onToggleMenu}
            size="large"
          />
        ) : null}
      </div>
      <div className="settings-tab__head-title-wrap">
        <div className="settings-tab__head-title-top">
          <span className="body2-regular color-gray-500">{t('Settings')}</span>
          {props?.links?.map((link, i) => (
            <React.Fragment key={i}>
              <span className="body2-regular color-gray-500 px-1">-</span>
              <span className="body2-regular color-gray-500">{link}</span>
            </React.Fragment>
          ))}
        </div>
        <div className="settings-tab__head-title-bottom">
          <div className="h4-medium body-secondary text-capitalize">{props.title}</div>
        </div>
      </div>
    </div>
  );
};

export default SettingsHeadTitle;
