import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {ContextMenuItem} from '../stores/ContextMenuStore';

interface IProps {
  menu: ContextMenuItem;
}

export const MenuUpload: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  return (
    <div className="dropdown-menu">
      <button type="button" className="dropdown-item d-flex" onClick={() => props.menu.trigger('photoVideoUpload')}>
        <i className="dropdown-icon tg-icon-gallery-line" />
        {t('ctx_menu_upload_photo_video')}
      </button>
      <button type="button" className="dropdown-item d-flex" onClick={() => props.menu.trigger('fileUpload')}>
        <i className="dropdown-icon tg-icon-file-line" />
        {t('ctx_menu_upload_photo_file')}
      </button>
    </div>
  );
});

export default MenuUpload;
