export enum ContextMenuType {
  CHAT_MENU,
  MESSAGE_MENU,
  MESSAGE_DELETED_MENU,
  SPECIAL_MESSAGE_MENU,
  UPLOAD_MENU,
  NEW_CHAT,
  NEW_INTERNAL_CHAT,
  SNIPPET,
  BLOCK_WEB_WIDGET_CHAT,
  CHANNEL_MENU,
  ALL_CHANNELS_ITEM_MENU,
  MESSAGE_TAGS,
  FILE,
  SHARED_LINK,
  BOT_MENU,
  CHAT_MEMBER_MENU,
  INVITE_MANAGE_MENU,
  WEB_WIDGET_SNIPPET_MENU,
  SNIPPET_MANAGE_MENU,
}

export default ContextMenuType;
