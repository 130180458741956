import {observer} from 'mobx-react';
import React from 'react';
import useResizeObserver from 'use-resize-observer';

import Chat from '../../../stores/Chat';
import Message from '../../../stores/Message';
import MessageDayTitle from './MessageDayTitle';
import MessageItem from './MessageItem';
import SpecialMessageItem from './SpecialMessage/SpecialMessageItem';
import UnreadMessagesDelimiter from './UnreadMessagesDelimiter';

interface ChatMessagesItemProps {
  measure?: () => void;
  onResize?: (size: {width: number; height: number}) => void;
  style?: React.CSSProperties;
  isScrolling?: boolean;
  index: number;
  messagesNumber: number;
  chatMessages: Array<Message | Message[]>;
  chat: Chat;
  pinnedMode?: boolean;
  message: Message | Message[];
  prevMessage?: Message | Message[] | null;
  nextMessage?: Message | Message[] | null;
  searchId?: string;
  isLastMessage?: boolean;
  messageRef?: (el: HTMLElement | null, message: Message) => void;
  onChangeVisibility?: (isVisible: boolean, index: number, message: Message) => void;
  formatMessageId?: (message: Message) => string;
}

export const ChatMessagesItem = observer((props: ChatMessagesItemProps) => {
  const {ref: innerRef} = useResizeObserver<HTMLDivElement>({
    onResize: (size) => {
      props.measure?.();
      props.onResize?.({height: size.height || 0, width: size.width || 0});
    },
  });

  const messageGroup: Message[] = Array.isArray(props.message) ? props.message : [props.message];
  const lastMessage: Message | null = messageGroup.length ? messageGroup[messageGroup.length - 1] : null;
  const firstMessage: Message | null = messageGroup.length ? messageGroup[0] : null;

  if (!firstMessage) {
    return null;
  }

  let message: Message | null = firstMessage;

  if (messageGroup.length > 1 && !message.text && lastMessage?.text) {
    message = lastMessage;
  }

  const prevMessage: Message | null | undefined = Array.isArray(props.prevMessage)
    ? props.prevMessage[0]
    : props.prevMessage;
  const nextMessage: Message | null | undefined = Array.isArray(props.nextMessage)
    ? props.nextMessage[0]
    : props.nextMessage;

  if (props.message instanceof Message && props.message.hidden) {
    return null;
  }

  if (props.message instanceof Message && props.message.isSystem) {
    return (
      <div
        id={props.formatMessageId?.(firstMessage)}
        data-index={props.index}
        data-msg-local-id={firstMessage.localID?.toString()}
        style={props.style}
      >
        <UnreadMessagesDelimiter message={message} />
        <div ref={innerRef}>
          {prevMessage || message ? (
            <MessageDayTitle className="py-5" message={message} prevMessage={prevMessage} />
          ) : null}
          <SpecialMessageItem
            messageRef={props.messageRef}
            message={props.message}
            searchId={props.searchId}
            isLastMessage={props.messagesNumber === props.index + 1}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      id={props.formatMessageId?.(firstMessage)}
      data-index={props.index}
      data-msg-local-id={firstMessage.localID?.toString()}
      data-msg-random-id={firstMessage.randomID?.toString()}
      style={props.style}
    >
      <UnreadMessagesDelimiter message={message} />
      <div ref={innerRef}>
        {prevMessage || message ? (
          <MessageDayTitle className="py-5" message={message} prevMessage={prevMessage} />
        ) : null}
        <MessageItem
          index={props.index}
          pinnedMode={props.pinnedMode}
          message={message}
          messageGroup={messageGroup}
          prevMessage={prevMessage}
          nextMessage={nextMessage}
          chat={props.chat}
          searchId={props.searchId}
          isLastMessage={props.messagesNumber === props.index + 1}
          isScrolling={props.isScrolling}
          onChangeVisibility={props.onChangeVisibility}
        />
      </div>
    </div>
  );
});

export default ChatMessagesItem;
