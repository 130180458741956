import {generatePath} from 'react-router';

import Channel from '../../stores/Channel';
import Chat from '../../stores/Chat';
import Message from '../../stores/Message';
import {uint8ArrayToBase64} from '../../utils/arrayUtils';
import browserHistory from '../browserHistory';
import Paths from './Paths';

export const navigateToWorkspace = (workspaceId?: Uint8Array | null) => {
  console.debug('%c navigateToWorkspace', 'color: pink', uint8ArrayToBase64(workspaceId));
  if (!workspaceId) {
    return;
  }
  browserHistory.push(
    generatePath(Paths.Chat, {
      workspaceId: encodeURIComponent(uint8ArrayToBase64(workspaceId)),
    }),
  );
};

export const navigateToChannel = (channel: Channel) => {
  console.debug('%c navigateToChannel', 'color: pink', uint8ArrayToBase64(channel.id));
  browserHistory.push(
    generatePath(Paths.Channel, {
      channelId: encodeURIComponent(uint8ArrayToBase64(channel.id)),
      workspaceId: encodeURIComponent(uint8ArrayToBase64(channel.workspace.id)),
    }),
    {state: {selectedChannelId: uint8ArrayToBase64(channel.id)}},
  );
};

export const generatePathToChat = (chat: Chat): string => {
  return generatePath(Paths.SpecificChat, {
    workspaceId: encodeURIComponent(uint8ArrayToBase64(chat.channel.workspace.id)),
    channelId: encodeURIComponent(uint8ArrayToBase64(chat.channelID)),
    chatId: chat.id.toString(),
  });
};

export const navigateToChat = (chat: Chat, selectedChannelID?: Uint8Array | null) => {
  const selectedChannelIDBase64 = uint8ArrayToBase64(selectedChannelID);
  console.debug('%c navigateToChat', 'color: pink', selectedChannelIDBase64);
  browserHistory.push(
    generatePathToChat(chat),
    {
      selectedChannelId: selectedChannelIDBase64,
      chatId: chat.id.toString(),
      chat: {
        id: chat.id.toString(),
        channelID: encodeURIComponent(uint8ArrayToBase64(chat.channelID)),
        name: chat.name?.toString(),
        type: chat.type?.toString(),
      },
      avatarId: chat.avatar?.source?.reference
        ? encodeURIComponent(uint8ArrayToBase64(chat.avatar?.source?.reference))
        : null,
    },
  );
};

export const generatePathToMessage = (message: Message): string => {
  return generatePath(Paths.SpecificMessage, {
    workspaceId: encodeURIComponent(uint8ArrayToBase64(message.chat.channel.workspace.id)),
    channelId: encodeURIComponent(uint8ArrayToBase64(message.chat.channelID)),
    chatId: message.chat.id.toString(),
    messageId: message.id.toString(),
  });
};

export const navigateToMessage = (message: Message, selectedChannelID?: Uint8Array | null) => {
  const selectedChannelIDBase64 = uint8ArrayToBase64(selectedChannelID);
  browserHistory.push(
    generatePathToMessage(message),
    {
      selectedChannelId: selectedChannelIDBase64,
      chatId: message.chat.id.toString(),
      chat: {
        id: message.chat.id.toString(),
        channelID: encodeURIComponent(uint8ArrayToBase64(message.chat.channelID)),
        name: message.chat.name?.toString(),
        type: message.chat.type?.toString(),
      },
      avatarId: message.chat.avatar?.source?.reference
        ? encodeURIComponent(uint8ArrayToBase64(message.chat.avatar?.source?.reference))
        : null,
    },
  );
};