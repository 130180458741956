import {computed, makeObservable} from 'mobx';

import {IMCAttachment} from '../../api/proto';
import {randomUint8Array, uint8ArrayToUuid} from '../../utils/arrayUtils';
import Message from '../Message';
import Attachment from './Attachment';

export class MessageAttachment extends Attachment {
  constructor(public raw: IMCAttachment, public message: Message) {
    super(raw);
    makeObservable(this);
  }

  @computed get unicID(): string {
    if (!this.id) {
      return uint8ArrayToUuid(randomUint8Array());
    }

    return `${this.id}_${this.message.id.toString()}`;
  }

  @computed get messagePreview(): MessageAttachment | null | undefined {
    if (!this.preview) {
      return null;
    }

    return new MessageAttachment(this.preview, this.message);
  }
}

export default MessageAttachment;
