import {entities} from '../../api/proto';
import i18n from '../../i18n';
import WorkspaceMember from './WorkspaceMember';

export const getInviteLinkRoleTitle = (type?: entities.WorkspaceRole | null): string => {
  switch (type) {
    case entities.WorkspaceRole.WR_ADMIN:
      return i18n.t('Admin');
    case entities.WorkspaceRole.WR_OWNER:
      return i18n.t('User');
  }
  return i18n.t('Operator');
};

export const getInviteLinkRoles = (): {caption: string; value: number}[] => {
  return Object.keys(entities.WorkspaceRole)
    .filter((r) => entities.WorkspaceRole[r] !== entities.WorkspaceRole.WR_NONE)
    .map((r) => ({
      caption: getInviteLinkRoleTitle(entities.WorkspaceRole[r]),
      value: entities.WorkspaceRole[r],
    }));
};

export const getInviteLinkUserEmail = (inviteLink: WorkspaceMember): string => {
  return (
    inviteLink.profile?.fields?.find((f) => f.type === entities.UserProfileFieldType.UPFT_EMAIL)?.value?.strValue ?? ''
  );
};

// export const formatInviteUrl = (inviteId?: Uint8Array | null): string => {
//   const code = uint8ArrayToBase64(inviteId);
//   const path = generatePath(Paths.RegistrationByInvitation, {code});
//   return `${location.origin}${path}`;
// };
