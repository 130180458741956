import cn from 'classnames';
import {observer} from 'mobx-react';
import {Switch} from 'o-ui';
import {formatPhoneNumber} from 'o-ui/utils/getPhoneMask';
import React from 'react';
import {ReactComponent as ChevronRightIcon} from '../../../../../assets/icons/chevron-right-line.svg';
import ChannelIcon from '../../../../../components/ChannelIcon';
import {WorkspaceMemberAccessRecord} from '../../../../../stores/Workspaces/WorkspaceMemberAccessRecord';
import ChannelAccessInfo from './ChannelAccessInfo';

interface ChannelsAccessItemProps {
  accessRecord: WorkspaceMemberAccessRecord;
  onOpenAccessRecord?(accessRecord: WorkspaceMemberAccessRecord): void;
}

const ChannelsAccessItem: React.FC<ChannelsAccessItemProps> = observer(({onOpenAccessRecord, accessRecord}) => {
  const {disableChannel, channel} = accessRecord;

  if (!channel) {
    return null;
  }

  const handleClick = () => {
    onOpenAccessRecord && onOpenAccessRecord(accessRecord);
  };

  const handleToggleDisableChannel = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    accessRecord.toggleChannelAccess();
  };

  const handleClickToggle = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  return (
    <div className={cn('channels-access-item', {disabled: disableChannel})} onClick={handleClick}>
      <ChannelIcon className="channels-access-item__icon" type={channel.type} />
      <div className="channels-access-item__title">
        <div className="channels-access-item__name">{channel.name}</div>
        <div className="channels-access-item__phone">{formatPhoneNumber(channel.phone)}</div>
      </div>

      <ChannelAccessInfo classNames="ml-auto" accessRecord={accessRecord} />
      <Switch
        checked={!accessRecord.disableChannel}
        onChange={handleToggleDisableChannel}
        onClick={handleClickToggle}
      />

      <ChevronRightIcon />
    </div>
  );
});

export default ChannelsAccessItem;
