import {action, computed, makeObservable} from 'mobx';

import {IMCPeerPreview, MCPeer} from '../../api/proto';
import {formatUserName} from '../../utils/format';
import Channel from '../Channel';
import Chat from '../Chat';
import IPeerMetaInfo from './utils/IPeerMetaInfo';
import parseMetaInfo from './utils/parseMetaInfo';
import parsePeerMeta from './utils/parsePeerMeta';

export class Peer extends MCPeer {
  constructor(public raw: IMCPeerPreview, public chat?: Chat) {
    super(raw);
    makeObservable(this);
    // this.fill(raw);
  }

  @computed get fullName(): string {
    if (this.metaInfo?.firstName || this.metaInfo?.lastName) {
      return formatUserName(this.metaInfo?.firstName, this.metaInfo?.lastName);
    }

    return this.name;
  }

  @computed get displayName(): string {
    return (
      this.fullName ||
      // this.chat?.title ||
      this.id?.toString()
    );
  }

  @action fill = (props?: IMCPeerPreview) => {
    Object.assign(this, props);
  };

  @computed get channel(): Channel | null {
    return null;
    // return this.app.activeWorkspace.channels.find((c) => c.id?.equals(this.channelId)) || null;
  }

  @computed get isOnline(): boolean {
    return false;
  }

  @computed get phoneNumber(): string {
    return this.metaInfo?.phoneNumber || '';
  }

  @computed get email(): string {
    return this.metaInfo?.email || '';
  }

  @computed get statusTitle(): string {
    return '';
  }

  @computed get metaInfo(): IPeerMetaInfo | null | undefined {
    return parsePeerMeta(this.meta);
  }

  @computed get metaData(): string[] {
    return parseMetaInfo(this.metaInfo?.metadata) || [];
  }
}

export default Peer;
