import Long from 'long';
import {computed} from 'mobx';
import {entities} from '../../api/proto';
import {getRandNumericID} from '../../utils/idUtils';
import {SnippetType} from './SnippetsStore';

export const SNIPPET_SHORTCUT_REGEXP = /^(?:_?[a-zA-Z0-9]+)*_?$/;

export interface ISnippet extends entities.IWorkspaceSnippet {
  raw: entities.IWorkspaceSnippet;
  id?: Long;
  type: SnippetType;
}

export class Snippet implements ISnippet {
  constructor(public raw: entities.IWorkspaceSnippet = {}, public type: SnippetType) {
    this.update(raw);
  }

  memberID?: Long | null;
  title?: string | null;
  command?: string | null;
  text?: string | null;
  entities?: entities.ITextEntities[] | null;

  id: Long = Long.fromNumber(getRandNumericID());

  @computed get isPersonal() {
    return this.type === SnippetType.Personal;
  }

  @computed get isWorkspace() {
    return this.type === SnippetType.Workspace;
  }

  update = (props?: entities.IWorkspaceSnippet) => {
    Object.assign(this, props);
  };
}

export default Snippet;
