import React from 'react';

import {entities} from '../../../api/proto';
import {PREFERABLE_TARIFF_ID} from '../../../constants';
import useStore from '../../../stores/useStore';
import browserStorage from '../../../utils/browserStorage';
import compareTariffId from '../../../stores/Billing/utils/compareTariffId';

export default function useHighlightedTariff() {
  const [highlightedTariff, setHighlightedTariff] = React.useState<entities.ITariff | null>(null);
  const {activeWorkspace: {billing}} = useStore();

  React.useEffect(() => {
    const highlightTariffId = browserStorage.local(PREFERABLE_TARIFF_ID);

    if (!highlightTariffId || compareTariffId(highlightedTariff, highlightTariffId)) {
      return;
    }

    const foundTariff = billing.findTariff(highlightTariffId);
    if (!foundTariff) {
      return;
    }

    setHighlightedTariff(foundTariff);
  }, [
    billing,
    billing.availableTariffs,
    highlightedTariff,
  ]);

  return highlightedTariff;
}