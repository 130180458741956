import cn from 'classnames';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {IconButton} from 'o-ui/Button';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import {useStore} from '../../../../stores/AppStore';
import Chat from '../../../../stores/Chat';
import RightSideBarType from '../../../../stores/LayOutStore/RightSideBarType';
// import {ChatAction, ChatActionType, CHAT_ACTION_RULES, useChatActionsList} from '../../../../stores/ChatActions';

interface ChatHeadMenuProps {
  chat: Chat;
}

export const ChatHeadMenu: React.FC<ChatHeadMenuProps> = observer(({chat}) => {
  const {
    layOutStore: {rightSideBar},
    // settings: {systemUser},
  } = useStore();
  const {t} = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const menuRef = React.useRef<HTMLDivElement | null>(null);

  // const chatActionsList = useChatActionsList({chat});

  // const handleClick = (chatAction: ChatAction) => {
  //   chatAction.act(chat);
  // };

  useOnClickOutside(menuRef, () => {
    if (dropdownOpen) {
      setDropdownOpen(false);
    }
  });

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleGroupInfoClick = (e: React.MouseEvent) => {
    e.preventDefault();

    rightSideBar.openChatInfo(chat);
    setDropdownOpen(false);
  };

  const handleUserInfoClick = (e: React.MouseEvent) => {
    e.preventDefault();

    rightSideBar.openChatPeerInfo(chat);
    setDropdownOpen(false);
  };

  // function addMemberButton() {
  //   // console.debug(`TODO: not implemented handler "addMemberButton"`);
  //   return null;
  // }

  // const memberButton = addMemberButton();

  const noMenuItems = rightSideBar.type !== RightSideBarType.NONE;

  React.useEffect(() => {
    if (noMenuItems) {
      setDropdownOpen(false);
    }
  }, [noMenuItems]);

  if (noMenuItems) {
    return null;
  }

  return (
    <div className="chat-head__menu-container" ref={menuRef}>
      <IconButton
        className={cn('chat-head__button tg-icon-more-line', {active: dropdownOpen})}
        onClick={toggleDropdown}
        type="button"
        size="small"
      />
      <div
        style={{display: dropdownOpen ? 'block' : 'none'}}
        className="dropdown-menu chat-head__menu withoutPositionStyles"
      >
        {chat.isGroup || chat.isSuperGroup ? (
          <button type="button" className="dropdown-item d-flex" onClick={handleGroupInfoClick}>
            {t('ctx_menu_chat_view_group_info')}
          </button>
        ) : (
          <button type="button" className="dropdown-item d-flex" onClick={handleUserInfoClick}>
            {t('ctx_menu_chat_view_profile')}
          </button>
        )}

        {/* {rightSideBar.type === RightSideBarType.CHAT_INFO ? null : (
          <button type="button" className="dropdown-item d-flex" onClick={openChatSidebar}>
            {chat.isGroup || chat.isSuperGroup
              ? t('chat_user_chat_head_group_info_title')
              : t('chat_user_chat_head_user_info_title')}
          </button>
        )} */}

        {/* {chat.store?.assignment?.youAreJoined ? (
          <button type="button" className="dropdown-item d-flex" onClick={chat.store?.assignment?.resolveChat}>
            {t('chat_user_chat_head_resolve_chat_button')}
          </button>
        ) : null} */}

        {/* <>{memberButton}</> */}

        {/* {chatActionsList.map((action) => (
          <button key={action.type} type="button" className="dropdown-item d-flex" onClick={() => handleClick(action)}>
            {action.title}
          </button>
        ))} */}
      </div>
    </div>
  );
});

export default ChatHeadMenu;
