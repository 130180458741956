import React from 'react';
import {observer} from 'mobx-react';

import Table from 'o-ui/Table';
import TableBody from 'o-ui/TableBody';
import SimpleBar from 'o-ui/CustomScrollbar/SimpleBar';

import {entities} from '../../../../api/proto';
import Channel from '../../../../stores/Channel';
import ChannelsTableHead from './ChannelsTableHead';
import ChannelsTableRow from './ChannelsTableRow';
import {uint8ArrayToUuid} from '../../../../utils/arrayUtils';

interface ChannelsTableProps {
  channelType?: entities.OzekonChannelType | null;
  channels: Channel[];
  onRowClick?: (channel: Channel) => void;
}

export const ChannelsTable = observer(({
  channelType,
  channels,
  onRowClick,
}: ChannelsTableProps) => {
  return (
    <SimpleBar className="channels-table__container">
      <Table className="channels-table">
        <ChannelsTableHead type={channelType} />
        <TableBody>
          {channels.map((row) => (
            <ChannelsTableRow
              key={uint8ArrayToUuid(row.id)}
              channel={row}
              onClick={onRowClick}
            />
          ))}
        </TableBody>
      </Table>
    </SimpleBar>
  );
});

export default ChannelsTable;
