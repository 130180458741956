import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import DialogActions from 'o-ui/Dialog/DialogActions';
import DialogContent from 'o-ui/Dialog/DialogContent';

import {getUserResponseStatusError} from '../api/getErrorByType';
import {entities} from '../api/proto';
import successTickImg from '../assets/images/check_icon.png';
import SnackMessage from '../components/SnackMessage';
import useMobileMode from '../hooks/useMobileMode';
import ForgotPasswordForm from '../pages/Auth/ForgotPasswordForm';
import {useStore} from '../stores/AppStore';
import {ModalType} from '../stores/ModalType';
import {ModalItem} from '../stores/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

export enum ForgotPasswordStep {
  ENTER_EMAIL,
  PASSWORD_EMAILED,
  CONFIRM_TO_SEND_EMAIL,
  EXPIRED_LINK,
}

interface ModalProfileForgotPasswordProps {
  modal: ModalItem;
  step: ForgotPasswordStep;
}

export const ModalProfileForgotPassword = observer((props: ModalProfileForgotPasswordProps) => {
  const {modals, userStore, notifications} = useStore();
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);
  const isMobile = useMobileMode();

  const userEmail = props.modal.data.email;

  const [step, setStep] = React.useState<ForgotPasswordStep>(() => {
    if ([ForgotPasswordStep.CONFIRM_TO_SEND_EMAIL, ForgotPasswordStep.EXPIRED_LINK].includes(props.step)) {
      return userEmail ? props.step : ForgotPasswordStep.ENTER_EMAIL;
    } else {
      return props.step;
    }
  });

  const handleSuccessForgotPasswordForm = () => {
    setStep(ForgotPasswordStep.PASSWORD_EMAILED);
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  const handleOpenChangePasswordModal = () => {
    modals.open(ModalType.PROFILE_CHANGE_PASSWORD, {}, true, true);
  };

  const handleSendEmail = async () => {
    if (userEmail) {
      const {error, res} = await userStore.forgotPassword(userEmail);

      if (error) {
        notifications.error(error.message);
      }

      if (res) {
        if (
          res.status &&
          [entities.RestorePasswordResult.RPR_INVALID_EMAIL, entities.RestorePasswordResult.RPR_UNKNOWN_EMAIL].includes(
            res.status,
          )
        ) {
          setStep(ForgotPasswordStep.ENTER_EMAIL);
        }

        const statusErrorMessage = getUserResponseStatusError('forgotPassword', res.status);

        if (statusErrorMessage) {
          notifications.error(statusErrorMessage);
          return;
        }
        setStep(ForgotPasswordStep.PASSWORD_EMAILED);
      }
    }
  };

  const getContent = (step: ForgotPasswordStep) => {
    switch (step) {
      case ForgotPasswordStep.ENTER_EMAIL:
        return (
          <DialogContent className="px-6 pt-6" style={isMobile ? {} : {width: '600px'}}>
            <ForgotPasswordForm className="mb-7" isProfile onSuccess={handleSuccessForgotPasswordForm} />
          </DialogContent>
        );
      case ForgotPasswordStep.PASSWORD_EMAILED:
        return (
          <>
            <DialogContent className="px-6 pt-6" style={isMobile ? {} : {width: '600px'}}>
              <div className="modal-profile-forgot-password-success">
                <div className="title-with-icon">
                  <img className="icon" src={successTickImg} alt="" />
                  <div className="text h3-medium pb-3">
                    {t('settings_personal_info_forgot_password_send_email_title')}
                  </div>
                </div>
                <div className="mb-auto">
                  <div className="auth-page__sub-title">
                    {t('settings_personal_info_forgot_password_send_email_description')}
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions className="pt-6 pb-6">
              <Button variant="contained" color="primary" size="large" onClick={handleCloseClick}>
                {t('modal_close_btn')}
              </Button>
            </DialogActions>
          </>
        );
      case ForgotPasswordStep.CONFIRM_TO_SEND_EMAIL:
        return (
          <>
            <DialogContent className="px-6 pt-6" style={isMobile ? {} : {width: '600px'}}>
              <div className="pb-6 body1-regular">{t('forgot_password_confirm_send_link_sub_title')}</div>
            </DialogContent>
            <DialogActions className="pt-6 pb-6">
              <Button variant="contained" color="primary" size="large" onClick={handleSendEmail}>
                {t('modal_submit_btn')}
              </Button>
              <Button variant="contained" color="secondary" size="large" onClick={handleOpenChangePasswordModal}>
                {t('modal_back_btn')}
              </Button>
            </DialogActions>
          </>
        );
      case ForgotPasswordStep.EXPIRED_LINK:
        return (
          <>
            <DialogContent className="px-6 pt-6" style={isMobile ? {} : {width: '600px'}}>
              <SnackMessage
                message={t('reset_password_link_expired_message')}
                subMessage={t('reset_password_link_expired_sub_message')}
                variant="error"
                hideCloseIcon
              />

              <div className="p-6 body1-regular">{t('forgot_password_confirm_resend_link_sub_title')}</div>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" size="large" onClick={handleSendEmail}>
                {t('modal_submit_btn')}
              </Button>
              <Button variant="contained" color="secondary" size="large" onClick={handleOpenChangePasswordModal}>
                {t('modal_back_btn')}
              </Button>
            </DialogActions>
          </>
        );
    }
  };

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      title={t('forgot_password_title')}
      className="modal-profile-forgot-password"
      maxWidth="md"
    >
      {getContent(step)}
    </ModalDialog>
  );
});

export default ModalProfileForgotPassword;
