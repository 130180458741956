import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useStore} from '../stores/AppStore';
import {ContextMenuItem} from '../stores/ContextMenuStore';
// import {ChatAction, useChatActionsList} from '../stores/ChatActions';

interface IProps {
  menu: ContextMenuItem;
}

export const MenuChat: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const {
    layOutStore: {rightSideBar},
  } = useStore();
  const {chat} = props.menu.data;

  if (!chat) {
    return null;
  }

  // const chatActionsList = useChatActionsList({chat});

  // const handleClick = (chatAction: ChatAction) => {
  //   if (chat) {
  //     chatAction.act(chat);
  //   }
  //   props.menu.close();
  // };

  // const onPinClick = () => {
  //   console.debug('onPinClick not implemented yet', chat);
  //   // chat?.store?.pin();
  //   props.menu.close();
  // };

  // const onUnpinClick = () => {
  //   console.debug('onUnpinClick not implemented yet', chat);
  //   // chat?.store?.unpin();
  //   props.menu.close();
  // };

  const handleGroupInfoClick = () => {
    rightSideBar.openChatInfo(chat);
    props.menu.close();
  };

  const handleUserInfoClick = () => {
    rightSideBar.openChatPeerInfo(chat);
    props.menu.close();
  };
  /*
  const handleToggleDisableNotifications = () => {
    chat?.store?.toggleMute();
    props.menu.close();
  };
  */
  const handleMarkAsRead = () => {
    chat.markAsRead();

    props.menu.close();
  };

  return (
    <div className="dropdown-menu">
      {/* {chat.pinned ? (
        <button type="button" className="dropdown-item d-flex" onClick={onUnpinClick}>
          {t('ctx_menu_chat_unpin_from_top')}
        </button>
      ) : (
        <button type="button" className="dropdown-item d-flex d-none" onClick={onPinClick}>
          {t('ctx_menu_chat_pin_to_top')}
        </button>
      )} */}

      {chat.isGroup || chat.isSuperGroup ? (
        <button type="button" className="dropdown-item d-flex" onClick={handleGroupInfoClick}>
          {t('ctx_menu_chat_view_group_info')}
        </button>
      ) : (
        <button type="button" className="dropdown-item d-flex" onClick={handleUserInfoClick}>
          {t('ctx_menu_chat_view_profile')}
        </button>
      )}

      {/*chat.isMuted ? (
        <button type="button" className="dropdown-item d-flex" onClick={handleToggleDisableNotifications}>
          {t('ctx_menu_chat_enable_notifications')}
        </button>
      ) : (
        <button type="button" className="dropdown-item d-flex" onClick={handleToggleDisableNotifications}>
          {t('ctx_menu_chat_disable_notifications')}
        </button>
      )*/}

      {chat.unreadCount ? (
        <button type="button" className="dropdown-item d-flex" onClick={handleMarkAsRead}>
          {t('ctx_menu_chat_mark_as_read')}
        </button>
      ) : null}

      {/* {chatActionsList.map((action) => (
        <button key={action.type} type="button" className="dropdown-item d-flex" onClick={() => handleClick(action)}>
          {action.title}
        </button>
      ))} */}
    </div>
  );
});

export default MenuChat;
