import {action, makeObservable, observable} from 'mobx';

import getQueryStringParam from '../../utils/getQueryStringParam';
import {getStyleProperty, setStyleProperty} from '../../utils/styleProperty';
import ExternalAppInfo from './ExternalAppInfo';
import ExternalAppWindowState from './ExternalAppWindowState';

export default class ExternalAppStore {
  constructor() {
    makeObservable(this);
  }

  @observable public desktopAppVersion = getQueryStringParam('v');
  @observable public customTitleBar: boolean = false;
  @observable public customTitleBarHeight: number = 0;

  @observable public isMinimized: boolean = false;
  @observable public isMaximized: boolean = false;
  @observable public isFullScreen: boolean = false;

  @action public processAppInfo = (appInfo: ExternalAppInfo) => {
    if (appInfo.version) {
      this.desktopAppVersion = appInfo.version;
    }
    this.customTitleBar = !!appInfo.customTitleBar;
    this.customTitleBarHeight = appInfo.customTitleBarHeight || 0;

    setStyleProperty('--window-custom-title-bar-height', `${this.customTitleBarHeight}px`);
  };

  @action public processAppWindowState = (state: ExternalAppWindowState) => {
    this.isMinimized = !!state.isMinimized;
    this.isMaximized = !!state.isMaximized;
    this.isFullScreen = !!state.isFullScreen;
  };

  static getCustomTitleBarHeight = (): number => {
    const titleBarHeightStr = window.externalAppConfig?.customTitleBarHeight?.toString() ||
      getStyleProperty('--window-custom-title-bar-height');
    return parseInt(titleBarHeightStr, 10) || 0;
  };
}