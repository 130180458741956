import React from 'react';
import {observer} from 'mobx-react';
import {ContextMenuItem} from '../stores/ContextMenuStore';
import {useTranslation} from 'react-i18next';
import {useStore} from '../stores/AppStore';
import {ModalType} from '../stores/ModalType';

interface MenuChannelProps {
  menu: ContextMenuItem;
}

export const MenuChannel: React.FC<MenuChannelProps> = observer((props) => {
  const {t} = useTranslation();
  const {modals, activeWorkspace} = useStore();
  const {channel} = props.menu.data;

  if (!channel) {
    return null;
  }

  // const handlePinClick = () => {
  //   console.debug('onPinClick not implemented yet', channel);
  //   // channel?.pin();
  //   props.menu.close();
  // };

  // const handleUnpinClick = () => {
  //   console.debug('onPinClick not implemented yet', channel);
  //   // channel?.unpin();
  //   props.menu.close();
  // };

  const handleReadAllChats = () => {
    props.menu.close();
    
    modals.open(ModalType.CONFIRM, {
      headerText: t('chat_chats_tab_selected_channel_mark_as_read_button_header', {channelName: channel.name}),
      title: t('chat_chats_tab_selected_channel_mark_as_read_button_title', {channelName: channel.name}),
      submitButtonText: t('Submit'),
      submitButtonColor: 'primary',
      syncCallbacks: true,
      callbacks: {
        submit: () => {
          activeWorkspace.channels.readAllChannelsChats([channel]);
        },
      },
    });
  };

  const noMenuItems = !channel.unreadNumber;

  if (noMenuItems) {
    return null;
  }

  return (
    <div className="dropdown-menu">
      {/* {channel.pinned ? (
        <button type="button" className="dropdown-item d-flex" onClick={handleUnpinClick}>
          {t('ctx_menu_channel_unpin_from_top')}
        </button>
      ) : (
        <button type="button" className="dropdown-item d-flex" onClick={handlePinClick}>
          {t('ctx_menu_channel_pin_to_top')}
        </button>
      )} */}

      {channel.unreadNumber ? (
        <button type="button" className="dropdown-item d-flex" onClick={handleReadAllChats}>
          {t('ctx_menu_channel_mark_all_chats_as_read')}
        </button>
      ) : null}
    </div>
  );
});

export default MenuChannel;
