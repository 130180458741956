import Long from 'long';
import React from 'react';

import {IVariableSizeList} from 'o-ui';
import Chat from '../../../../../stores/Chat';
import useSelectedChatIndex from './useSelectedChatIndex';


interface UseSelectedChatInFocusProps {
  chats: Chat[];
}

type UseSelectedChatInFocus = [
  (node?: IVariableSizeList | null) => void,
  (ready?: boolean | null) => void,
] & {
  ref: (node?: IVariableSizeList | null) => void;
  setReady: (ready?: boolean | null) => void;
}

export default function useSelectedChatInFocus({chats}: UseSelectedChatInFocusProps): UseSelectedChatInFocus {
  const [focusedChatId, setFocusedChatId] = React.useState<Long | null>(null);
  const [listRef, setListRef] = React.useState<IVariableSizeList | null>(null);
  const listRenderedRef = React.useRef<boolean>(false);
  const [listRendered, setListRendered] = React.useState(false);

  const setReady = React.useCallback((ready?: boolean | null) => {
    const val = ready || false;
    if (listRenderedRef.current !== val) {
      listRenderedRef.current = ready || false;
      setListRendered(ready || false);
    }
  }, [listRenderedRef]);

  const [selectedChatIndex, chatID] = useSelectedChatIndex({
    chats,
    listRendered,
  });

  React.useEffect(() => {
    setReady(false);
  }, [chats, setReady]);

  React.useEffect(() => {
    if (listRenderedRef.current && selectedChatIndex !== null && chatID && !focusedChatId?.equals(chatID)) {
      listRef?.scrollToItem(selectedChatIndex, 'smart');
      setFocusedChatId(chatID);
    }
  }, [listRenderedRef, selectedChatIndex, listRef, chatID, focusedChatId]);

  const result = [setListRef, setReady] as UseSelectedChatInFocus;
  // Support object destructuring, by adding the specific values.
  result.ref = result[0];
  result.setReady = result[1];

  return result;
}
