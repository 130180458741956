import React from 'react';
import {observer} from 'mobx-react';

import Avatar from 'o-ui/Avatar';
import Message from '../../../../stores/Message';
import useAvatarAttachment from '../../useAvatarAttachment';


interface SpecialMessageNewAvatarProps {
  message: Message;
}

export const SpecialMessageNewAvatar: React.FC<SpecialMessageNewAvatarProps> = observer(({message}) => {
  const {src} = useAvatarAttachment(message?.serviceMessage?.editChat?.avatar, message?.chat?.channelID);
  if (!src) {
    return null;
  }

  return (
    <div className="message__body-special-content-avatar">
      <Avatar
        size="xl"
        name={message.senderName}
        src={src}
      />
    </div>
  );
});

export default SpecialMessageNewAvatar;
