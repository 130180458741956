import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {SketchPicker} from 'react-color';
import {useTranslation} from 'react-i18next';
import {animated, useTransition} from 'react-spring';
import useOnClickOutside from '../hooks/useOnClickOutside';
import {useStore} from '../stores/AppStore';

interface ColorSelectorProps {
  value?: string | null;
  options?: string[] | null;
  onChange?: (color: string) => void;
}

export const ColorSelector = observer((props: ColorSelectorProps) => {
  const {t} = useTranslation();
  const {animationsEnabled} = useStore();
  const ref = React.useRef<HTMLDivElement | null>(null);
  const pickerButtonRef = React.useRef<HTMLDivElement | null>(null);
  const [colorPickerOpen, setColorPickerOpen] = React.useState<boolean>(false);

  const closeColorPickerOpen = () => {
    setColorPickerOpen(false);
  };

  const openColorPickerOpen = () => {
    setColorPickerOpen(true);
  };

  useOnClickOutside(ref, closeColorPickerOpen);

  const optionIsSelected = props.options?.some((option) => props.value === option);

  const handleChange = (color: string) => {
    props.onChange && props.onChange(color);
  };

  const handleColorPick = (color) => {
    props.onChange && props.onChange(color.hex);
  };

  const transitions = useTransition([colorPickerOpen], {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
  });

  return (
    <div className="color-selector" ref={ref}>
      {props.options?.map((option) => (
        <div
          key={option}
          style={{color: option}}
          className={cn('color-selector__item', {'color-selector__item--selected': props.value === option})}
          onClick={() => handleChange(option)}
        />
      ))}
      <div
        className={cn('color-selector__picker-btn', {
          'color-selector__picker-btn--selected': !!(optionIsSelected ? '' : props.value),
        })}
        ref={pickerButtonRef}
        onClick={openColorPickerOpen}
        style={{
          backgroundColor: optionIsSelected ? '' : props.value || '',
          color: optionIsSelected ? '' : props.value || '',
        }}
      >
        <span className="color-selector__picker-btn-hex">{optionIsSelected ? t('Custom color') : props.value}</span>
        {animationsEnabled ? (
          transitions((styles, allow) =>
            allow ? (
              <animated.div className="color-selector__picker" style={styles}>
                <SketchPicker color={optionIsSelected ? '' : props.value} onChangeComplete={handleColorPick} />
              </animated.div>
            ) : null,
          )
        ) : (
          <>
            {colorPickerOpen ? (
              <div className="color-selector__picker">
                <SketchPicker color={optionIsSelected ? '' : props.value} onChangeComplete={handleColorPick} />
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
});

export default ColorSelector;
