import React from 'react';
import {useTranslation} from 'react-i18next';

import {EMPTY_IMG} from '../../../../constants';
import {MCWebWidgetConfig} from '../../../../api/proto';
import {
  getMaxOffset,
  parsePositionStyles,
} from '../../../../utils/widgetPositionUtils';


interface WebWidgetPreviewProps {
  color?: string | null;
  companyName?: string | null;
  welcomeMessage?: string | null;
  avatarUrl?: string | null;
  chatButtonSize?: string | null;
  chatButtonPosition?: React.CSSProperties | null;
  helloSnippets?: MCWebWidgetConfig.IHelloSnippet[] | null;
}

export const WebWidgetPreview = (props: WebWidgetPreviewProps) => {
  const {t} = useTranslation();
  const color = props.color || '';

  const chatAppRef = React.useRef<HTMLDivElement>(null);
  const chatAppBoxRef = React.useRef<HTMLDivElement>(null);
  const chatAppButtonRef = React.useRef<HTMLDivElement>(null);

  const styles = parsePositionStyles(
    props.chatButtonSize,
    props.chatButtonPosition,
    getMaxOffset(
      chatAppBoxRef.current,
      chatAppRef.current
    ),
  );

  return (
    <div
      ref={chatAppRef}
      className="owc-widget__chat-app owc-widget__chat-app--enter owc-widget__chat-app--expand position-relative h-100"
    >
      <div
        ref={chatAppBoxRef}
        className="owc-widget__chat-box"
        style={styles.chatBoxStyle}
      >
        <div className="owc-widget__header">
          <div
            className="owc-widget__header-avatar"
            style={{background: `url("${props.avatarUrl || EMPTY_IMG}") center/cover`, color}}
          />
          <div className="owc-widget__header-operator">
            <div className="owc-widget__header-operator-name">{props.companyName}</div>
            <div className="owc-widget__header-operator-info">
              <span className="owc-widget__header-operator-role">{t('Customer support')}</span>
              <span className="owc-widget__header-operator-status">{t('Online')}</span>
            </div>
          </div>
          <button className="owc-widget__close-button"></button>
        </div>

        <ul className="owc-widget__messages-list">
          {props.welcomeMessage ? (
            <li className="owc-widget__message-item owc-widget__message-item--other">
              <div
                className="owc-widget__message-item-avatar"
                style={{background: `url("${props.avatarUrl || EMPTY_IMG}") center/cover`, color}}
              />
              <span className="owc-widget__message-item-text">{props.welcomeMessage}</span>
            </li>
          ) : null}
          {props.helloSnippets?.length ? (
            props.helloSnippets?.map((s, i) => (
              <li
                key={`${i}-${s}`}
                className="owc-widget__message-item owc-widget__message-item--self owc-widget__message-item--snippet"
              >
                <span className="owc-widget__message-item-text">{s.text}</span>
              </li>
            ))
          ) : (
            <li className="owc-widget__message-item owc-widget__message-item--self" style={{color}}>
              <span className="owc-widget__message-item-text">Yes, please.</span>
            </li>
          )}
        </ul>

        <div className="owc-widget__message-input-bar">
          <div
            className="owc-widget__text-editor owc-widget__text-editor--blank"
            data-placeholder="Enter message"
          ></div>
          <button className="owc-widget__send-button">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="owc-widget__send-button-icon"
            >
              <path
                d="M20.2359 10.1964L5.78139 3.27025C4.01248 2.42265 2.20749 4.3631 3.18066 6.06616L5.2605 9.70587C5.54867 10.2102 6.04185 10.5643 6.61181 10.6761L10.8572 11.5094C11.3955 11.615 11.3955 12.385 10.8572 12.4906L6.61181 13.3239C6.04185 13.4357 5.54867 13.7898 5.2605 14.2941L3.18066 17.9338C2.20749 19.6369 4.01248 21.5774 5.78139 20.7298L20.2359 13.8036C21.7502 13.078 21.7502 10.922 20.2359 10.1964Z"
                fill={color}
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <div
        ref={chatAppButtonRef}
        className="owc-widget__chat-button"
        style={{color, ...styles.chatButtonStyle}}
      >
        <div className="owc-widget__chat-button-body" style={styles.chatButtonBodyStyle}>
          <div className="owc-widget__chat-button-footprint-1"></div>
          <div className="owc-widget__chat-button-footprint-2"></div>
          <div className="owc-widget__chat-button-icon"></div>
          <div className="owc-widget__chat-button-icon-arrow"></div>
        </div>
      </div>
    </div>
  );
};

export default WebWidgetPreview;
