import {computed, makeObservable} from 'mobx';
import Chat from './Chat';

export class ChatPermissions {
  constructor(public chat: Chat) {
    makeObservable(this);
  }

  @computed get canSendMessage() {
    return !this.chat.channel.isWebWidget || this.chat.assignment.youAreJoined;
  }

  @computed get canDeleteMessage() {
    return !this.chat.channel.isWebWidget || this.chat.assignment.youAreJoined;
  }
}

export default ChatPermissions;
