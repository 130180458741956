import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button} from 'o-ui';
import InputBase from 'o-ui/Input/InputBase';
import Tab from 'o-ui/Tab';
import Tabs from 'o-ui/Tabs';
import {ReactComponent as PlusIcon} from '../../../assets/icons/plus-line.svg';
import {useStore} from '../../../stores/AppStore';
import {ModalType} from '../../../stores/ModalType';
import {Snippet} from '../../../stores/Snippets';
import {SnippetType} from '../../../stores/Snippets/SnippetsStore';
import SnippetsList from './SnippetsList';

export const SnippetsViewer = observer(() => {
  const {t} = useTranslation();

  const {activeWorkspace, userStore, modals} = useStore();

  const [snippetType, setSnippetType] = React.useState<SnippetType>(SnippetType.Workspace);

  const [filter, setFilter] = React.useState<string>('');

  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const handleClearFilter = () => {
    setFilter('');
  };

  const handleChangeTab = (e: React.ChangeEvent, type: SnippetType) => {
    setSnippetType(type);
    handleClearFilter();
  };

  const handleCreateSnippet = () => {
    handleClearFilter();
    modals.open(ModalType.CREATE_SNIPPET, {
      snippet: new Snippet({}, snippetType),
    });
  };

  const workspaceSnippetsCount = activeWorkspace.workspaceSnippets.count;
  const personalSnippetsCount = activeWorkspace.personalSnippets.count;

  const allowCreateWorkspace = userStore.isAdmin || userStore.isOwner;

  return (
    <>
      <Tabs className="message-tags-tab__tabs px-6 border-bottom" value={snippetType} onChange={handleChangeTab}>
        <Tab
          label={
            <span className="d-flex align-items-center">
              {t('settings_snippets_workspace_title')}
              <span className="tab-counter">{workspaceSnippetsCount}</span>
            </span>
          }
          value={SnippetType.Workspace}
        />
        <Tab
          label={
            <span className="d-flex align-items-center">
              {t('settings_snippets_personal_title')}
              <span className="tab-counter">{personalSnippetsCount}</span>
            </span>
          }
          value={SnippetType.Personal}
        />
      </Tabs>

      {(workspaceSnippetsCount && snippetType === SnippetType.Workspace) ||
      (personalSnippetsCount && snippetType === SnippetType.Personal) ? (
        <div className="d-flex mt-5  px-6">
          <div className="message-tags-tab__search bg-background-secondary radius-8 d-flex flex-auto align-items-center px-5 py-1">
            <span className="tg-icon-search-line mr-5" />
            <InputBase
              className="w-100"
              placeholder={t('settings_snippets_search_placeholder')}
              value={filter}
              onChange={handleChangeFilter}
            />
          </div>

          {(allowCreateWorkspace && snippetType === SnippetType.Workspace) || snippetType === SnippetType.Personal ? (
            <Button className="ml-3" variant="contained" color="secondary" onClick={handleCreateSnippet}>
              <PlusIcon className="message-tags-tab__add-icon" />
              {t('settings_snippets_create_snippet_btn')}
            </Button>
          ) : null}
        </div>
      ) : null}

      <SnippetsList filter={filter} type={snippetType} onCreateSnippet={handleCreateSnippet} />
    </>
  );
});

export default SnippetsViewer;
