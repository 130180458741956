import {observer} from 'mobx-react';
import React from 'react';
import {Navigate, matchPath, useLocation} from 'react-router-dom';

import Paths from '../routes/Paths';
import pathsOnlyForUnauthorized from '../routes/Paths/pathsOnlyForUnauthorized';
import useStore from '../stores/useStore';

const NonAuthLayout: React.FC<React.PropsWithChildren> = observer(({children}) => {
  const location = useLocation();

  const {anal, userStore} = useStore();

  const onlyForUnauthorized = pathsOnlyForUnauthorized.some((path) => matchPath(path, location.pathname));

  React.useEffect(() => {
    if (userStore.isInit) {
      console.debug('anal.NonAuthLayout', location.pathname);
      anal.openRoute(location.pathname);
    }
  }, [
    anal,
    location.pathname,
    userStore.isInit,
  ]);

  if (userStore.user?.isLoggedIn && onlyForUnauthorized) {
    return <Navigate to={Paths.Root} replace />;
  }

  return <React.Fragment>{children}</React.Fragment>;
});

export default NonAuthLayout;