/*eslint-disable*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const APIRequest = $root.APIRequest = (() => {

$protobuf.util.Long = require('long');
$protobuf.configure();
    /**
     * Properties of a APIRequest.
     * @exports IAPIRequest
     * @interface IAPIRequest
     * @property {Uint8Array|null} [trx] APIRequest trx
     * @property {api.IUserRequest|null} [userRequest] APIRequest userRequest
     * @property {api.IWorkspaceRequest|null} [workspaceRequest] APIRequest workspaceRequest
     * @property {api.IChannelsRequest|null} [channelsRequest] APIRequest channelsRequest
     */

    /**
     * Constructs a new APIRequest.
     * @exports APIRequest
     * @classdesc Represents a APIRequest.
     * @implements IAPIRequest
     * @constructor
     * @param {IAPIRequest=} [properties] Properties to set
     */
    function APIRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * APIRequest trx.
     * @member {Uint8Array} trx
     * @memberof APIRequest
     * @instance
     */
    APIRequest.prototype.trx = $util.newBuffer([]);

    /**
     * APIRequest userRequest.
     * @member {api.IUserRequest|null|undefined} userRequest
     * @memberof APIRequest
     * @instance
     */
    APIRequest.prototype.userRequest = null;

    /**
     * APIRequest workspaceRequest.
     * @member {api.IWorkspaceRequest|null|undefined} workspaceRequest
     * @memberof APIRequest
     * @instance
     */
    APIRequest.prototype.workspaceRequest = null;

    /**
     * APIRequest channelsRequest.
     * @member {api.IChannelsRequest|null|undefined} channelsRequest
     * @memberof APIRequest
     * @instance
     */
    APIRequest.prototype.channelsRequest = null;

    /**
     * Creates a new APIRequest instance using the specified properties.
     * @function create
     * @memberof APIRequest
     * @static
     * @param {IAPIRequest=} [properties] Properties to set
     * @returns {APIRequest} APIRequest instance
     */
    APIRequest.create = function create(properties) {
        return new APIRequest(properties);
    };

    /**
     * Encodes the specified APIRequest message. Does not implicitly {@link APIRequest.verify|verify} messages.
     * @function encode
     * @memberof APIRequest
     * @static
     * @param {IAPIRequest} message APIRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    APIRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.trx != null && Object.hasOwnProperty.call(message, "trx"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.trx);
        if (message.userRequest != null && Object.hasOwnProperty.call(message, "userRequest"))
            $root.api.UserRequest.encode(message.userRequest, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.workspaceRequest != null && Object.hasOwnProperty.call(message, "workspaceRequest"))
            $root.api.WorkspaceRequest.encode(message.workspaceRequest, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.channelsRequest != null && Object.hasOwnProperty.call(message, "channelsRequest"))
            $root.api.ChannelsRequest.encode(message.channelsRequest, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified APIRequest message, length delimited. Does not implicitly {@link APIRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof APIRequest
     * @static
     * @param {IAPIRequest} message APIRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    APIRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a APIRequest message from the specified reader or buffer.
     * @function decode
     * @memberof APIRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {APIRequest} APIRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    APIRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.APIRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.trx = reader.bytes();
                    break;
                }
            case 10: {
                    message.userRequest = $root.api.UserRequest.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.workspaceRequest = $root.api.WorkspaceRequest.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.channelsRequest = $root.api.ChannelsRequest.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a APIRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof APIRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {APIRequest} APIRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    APIRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a APIRequest message.
     * @function verify
     * @memberof APIRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    APIRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.trx != null && message.hasOwnProperty("trx"))
            if (!(message.trx && typeof message.trx.length === "number" || $util.isString(message.trx)))
                return "trx: buffer expected";
        if (message.userRequest != null && message.hasOwnProperty("userRequest")) {
            let error = $root.api.UserRequest.verify(message.userRequest);
            if (error)
                return "userRequest." + error;
        }
        if (message.workspaceRequest != null && message.hasOwnProperty("workspaceRequest")) {
            let error = $root.api.WorkspaceRequest.verify(message.workspaceRequest);
            if (error)
                return "workspaceRequest." + error;
        }
        if (message.channelsRequest != null && message.hasOwnProperty("channelsRequest")) {
            let error = $root.api.ChannelsRequest.verify(message.channelsRequest);
            if (error)
                return "channelsRequest." + error;
        }
        return null;
    };

    /**
     * Creates a APIRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof APIRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {APIRequest} APIRequest
     */
    APIRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.APIRequest)
            return object;
        let message = new $root.APIRequest();
        if (object.trx != null)
            if (typeof object.trx === "string")
                $util.base64.decode(object.trx, message.trx = $util.newBuffer($util.base64.length(object.trx)), 0);
            else if (object.trx.length >= 0)
                message.trx = object.trx;
        if (object.userRequest != null) {
            if (typeof object.userRequest !== "object")
                throw TypeError(".APIRequest.userRequest: object expected");
            message.userRequest = $root.api.UserRequest.fromObject(object.userRequest);
        }
        if (object.workspaceRequest != null) {
            if (typeof object.workspaceRequest !== "object")
                throw TypeError(".APIRequest.workspaceRequest: object expected");
            message.workspaceRequest = $root.api.WorkspaceRequest.fromObject(object.workspaceRequest);
        }
        if (object.channelsRequest != null) {
            if (typeof object.channelsRequest !== "object")
                throw TypeError(".APIRequest.channelsRequest: object expected");
            message.channelsRequest = $root.api.ChannelsRequest.fromObject(object.channelsRequest);
        }
        return message;
    };

    /**
     * Creates a plain object from a APIRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof APIRequest
     * @static
     * @param {APIRequest} message APIRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    APIRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.trx = "";
            else {
                object.trx = [];
                if (options.bytes !== Array)
                    object.trx = $util.newBuffer(object.trx);
            }
            object.userRequest = null;
            object.workspaceRequest = null;
            object.channelsRequest = null;
        }
        if (message.trx != null && message.hasOwnProperty("trx"))
            object.trx = options.bytes === String ? $util.base64.encode(message.trx, 0, message.trx.length) : options.bytes === Array ? Array.prototype.slice.call(message.trx) : message.trx;
        if (message.userRequest != null && message.hasOwnProperty("userRequest"))
            object.userRequest = $root.api.UserRequest.toObject(message.userRequest, options);
        if (message.workspaceRequest != null && message.hasOwnProperty("workspaceRequest"))
            object.workspaceRequest = $root.api.WorkspaceRequest.toObject(message.workspaceRequest, options);
        if (message.channelsRequest != null && message.hasOwnProperty("channelsRequest"))
            object.channelsRequest = $root.api.ChannelsRequest.toObject(message.channelsRequest, options);
        return object;
    };

    /**
     * Converts this APIRequest to JSON.
     * @function toJSON
     * @memberof APIRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    APIRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for APIRequest
     * @function getTypeUrl
     * @memberof APIRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    APIRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/APIRequest";
    };

    return APIRequest;
})();

export const APIResponse = $root.APIResponse = (() => {

    /**
     * Properties of a APIResponse.
     * @exports IAPIResponse
     * @interface IAPIResponse
     * @property {Uint8Array|null} [trx] APIResponse trx
     * @property {APIResponse.Status|null} [status] APIResponse status
     * @property {Long|null} [retryAfterNMs] APIResponse retryAfterNMs
     * @property {api.IUserResponse|null} [userResponse] APIResponse userResponse
     * @property {api.IWorkspaceResponse|null} [workspaceResponse] APIResponse workspaceResponse
     * @property {api.IChannelsResponse|null} [channelsResponse] APIResponse channelsResponse
     * @property {api.IRefetchTip|null} [refetchTip] APIResponse refetchTip
     */

    /**
     * Constructs a new APIResponse.
     * @exports APIResponse
     * @classdesc Represents a APIResponse.
     * @implements IAPIResponse
     * @constructor
     * @param {IAPIResponse=} [properties] Properties to set
     */
    function APIResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * APIResponse trx.
     * @member {Uint8Array} trx
     * @memberof APIResponse
     * @instance
     */
    APIResponse.prototype.trx = $util.newBuffer([]);

    /**
     * APIResponse status.
     * @member {APIResponse.Status} status
     * @memberof APIResponse
     * @instance
     */
    APIResponse.prototype.status = 0;

    /**
     * APIResponse retryAfterNMs.
     * @member {Long} retryAfterNMs
     * @memberof APIResponse
     * @instance
     */
    APIResponse.prototype.retryAfterNMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * APIResponse userResponse.
     * @member {api.IUserResponse|null|undefined} userResponse
     * @memberof APIResponse
     * @instance
     */
    APIResponse.prototype.userResponse = null;

    /**
     * APIResponse workspaceResponse.
     * @member {api.IWorkspaceResponse|null|undefined} workspaceResponse
     * @memberof APIResponse
     * @instance
     */
    APIResponse.prototype.workspaceResponse = null;

    /**
     * APIResponse channelsResponse.
     * @member {api.IChannelsResponse|null|undefined} channelsResponse
     * @memberof APIResponse
     * @instance
     */
    APIResponse.prototype.channelsResponse = null;

    /**
     * APIResponse refetchTip.
     * @member {api.IRefetchTip|null|undefined} refetchTip
     * @memberof APIResponse
     * @instance
     */
    APIResponse.prototype.refetchTip = null;

    /**
     * Creates a new APIResponse instance using the specified properties.
     * @function create
     * @memberof APIResponse
     * @static
     * @param {IAPIResponse=} [properties] Properties to set
     * @returns {APIResponse} APIResponse instance
     */
    APIResponse.create = function create(properties) {
        return new APIResponse(properties);
    };

    /**
     * Encodes the specified APIResponse message. Does not implicitly {@link APIResponse.verify|verify} messages.
     * @function encode
     * @memberof APIResponse
     * @static
     * @param {IAPIResponse} message APIResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    APIResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.trx != null && Object.hasOwnProperty.call(message, "trx"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.trx);
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
        if (message.retryAfterNMs != null && Object.hasOwnProperty.call(message, "retryAfterNMs"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.retryAfterNMs);
        if (message.userResponse != null && Object.hasOwnProperty.call(message, "userResponse"))
            $root.api.UserResponse.encode(message.userResponse, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.workspaceResponse != null && Object.hasOwnProperty.call(message, "workspaceResponse"))
            $root.api.WorkspaceResponse.encode(message.workspaceResponse, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.channelsResponse != null && Object.hasOwnProperty.call(message, "channelsResponse"))
            $root.api.ChannelsResponse.encode(message.channelsResponse, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.refetchTip != null && Object.hasOwnProperty.call(message, "refetchTip"))
            $root.api.RefetchTip.encode(message.refetchTip, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified APIResponse message, length delimited. Does not implicitly {@link APIResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof APIResponse
     * @static
     * @param {IAPIResponse} message APIResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    APIResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a APIResponse message from the specified reader or buffer.
     * @function decode
     * @memberof APIResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {APIResponse} APIResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    APIResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.APIResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.trx = reader.bytes();
                    break;
                }
            case 2: {
                    message.status = reader.int32();
                    break;
                }
            case 3: {
                    message.retryAfterNMs = reader.uint64();
                    break;
                }
            case 10: {
                    message.userResponse = $root.api.UserResponse.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.workspaceResponse = $root.api.WorkspaceResponse.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.channelsResponse = $root.api.ChannelsResponse.decode(reader, reader.uint32());
                    break;
                }
            case 30: {
                    message.refetchTip = $root.api.RefetchTip.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a APIResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof APIResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {APIResponse} APIResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    APIResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a APIResponse message.
     * @function verify
     * @memberof APIResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    APIResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.trx != null && message.hasOwnProperty("trx"))
            if (!(message.trx && typeof message.trx.length === "number" || $util.isString(message.trx)))
                return "trx: buffer expected";
        if (message.status != null && message.hasOwnProperty("status"))
            switch (message.status) {
            default:
                return "status: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                break;
            }
        if (message.retryAfterNMs != null && message.hasOwnProperty("retryAfterNMs"))
            if (!$util.isInteger(message.retryAfterNMs) && !(message.retryAfterNMs && $util.isInteger(message.retryAfterNMs.low) && $util.isInteger(message.retryAfterNMs.high)))
                return "retryAfterNMs: integer|Long expected";
        if (message.userResponse != null && message.hasOwnProperty("userResponse")) {
            let error = $root.api.UserResponse.verify(message.userResponse);
            if (error)
                return "userResponse." + error;
        }
        if (message.workspaceResponse != null && message.hasOwnProperty("workspaceResponse")) {
            let error = $root.api.WorkspaceResponse.verify(message.workspaceResponse);
            if (error)
                return "workspaceResponse." + error;
        }
        if (message.channelsResponse != null && message.hasOwnProperty("channelsResponse")) {
            let error = $root.api.ChannelsResponse.verify(message.channelsResponse);
            if (error)
                return "channelsResponse." + error;
        }
        if (message.refetchTip != null && message.hasOwnProperty("refetchTip")) {
            let error = $root.api.RefetchTip.verify(message.refetchTip);
            if (error)
                return "refetchTip." + error;
        }
        return null;
    };

    /**
     * Creates a APIResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof APIResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {APIResponse} APIResponse
     */
    APIResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.APIResponse)
            return object;
        let message = new $root.APIResponse();
        if (object.trx != null)
            if (typeof object.trx === "string")
                $util.base64.decode(object.trx, message.trx = $util.newBuffer($util.base64.length(object.trx)), 0);
            else if (object.trx.length >= 0)
                message.trx = object.trx;
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "AS_OK":
        case 0:
            message.status = 0;
            break;
        case "AS_INVALID_HTTP_METHOD":
        case 1:
            message.status = 1;
            break;
        case "AS_NON_PARSABLE_REQUEST":
        case 2:
            message.status = 2;
            break;
        case "AS_TRX_SHOULD_BE_UUID":
        case 3:
            message.status = 3;
            break;
        case "AS_INTERNAL_ERROR":
        case 4:
            message.status = 4;
            break;
        case "AS_PENDING_GREETINGS":
        case 5:
            message.status = 5;
            break;
        case "AS_DIFFERENT_TOKEN_PROVIDED":
        case 6:
            message.status = 6;
            break;
        case "AS_NOT_ALLOWED":
        case 7:
            message.status = 7;
            break;
        }
        if (object.retryAfterNMs != null)
            if ($util.Long)
                (message.retryAfterNMs = $util.Long.fromValue(object.retryAfterNMs)).unsigned = true;
            else if (typeof object.retryAfterNMs === "string")
                message.retryAfterNMs = parseInt(object.retryAfterNMs, 10);
            else if (typeof object.retryAfterNMs === "number")
                message.retryAfterNMs = object.retryAfterNMs;
            else if (typeof object.retryAfterNMs === "object")
                message.retryAfterNMs = new $util.LongBits(object.retryAfterNMs.low >>> 0, object.retryAfterNMs.high >>> 0).toNumber(true);
        if (object.userResponse != null) {
            if (typeof object.userResponse !== "object")
                throw TypeError(".APIResponse.userResponse: object expected");
            message.userResponse = $root.api.UserResponse.fromObject(object.userResponse);
        }
        if (object.workspaceResponse != null) {
            if (typeof object.workspaceResponse !== "object")
                throw TypeError(".APIResponse.workspaceResponse: object expected");
            message.workspaceResponse = $root.api.WorkspaceResponse.fromObject(object.workspaceResponse);
        }
        if (object.channelsResponse != null) {
            if (typeof object.channelsResponse !== "object")
                throw TypeError(".APIResponse.channelsResponse: object expected");
            message.channelsResponse = $root.api.ChannelsResponse.fromObject(object.channelsResponse);
        }
        if (object.refetchTip != null) {
            if (typeof object.refetchTip !== "object")
                throw TypeError(".APIResponse.refetchTip: object expected");
            message.refetchTip = $root.api.RefetchTip.fromObject(object.refetchTip);
        }
        return message;
    };

    /**
     * Creates a plain object from a APIResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof APIResponse
     * @static
     * @param {APIResponse} message APIResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    APIResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.trx = "";
            else {
                object.trx = [];
                if (options.bytes !== Array)
                    object.trx = $util.newBuffer(object.trx);
            }
            object.status = options.enums === String ? "AS_OK" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.retryAfterNMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.retryAfterNMs = options.longs === String ? "0" : 0;
            object.userResponse = null;
            object.workspaceResponse = null;
            object.channelsResponse = null;
            object.refetchTip = null;
        }
        if (message.trx != null && message.hasOwnProperty("trx"))
            object.trx = options.bytes === String ? $util.base64.encode(message.trx, 0, message.trx.length) : options.bytes === Array ? Array.prototype.slice.call(message.trx) : message.trx;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.APIResponse.Status[message.status] === undefined ? message.status : $root.APIResponse.Status[message.status] : message.status;
        if (message.retryAfterNMs != null && message.hasOwnProperty("retryAfterNMs"))
            if (typeof message.retryAfterNMs === "number")
                object.retryAfterNMs = options.longs === String ? String(message.retryAfterNMs) : message.retryAfterNMs;
            else
                object.retryAfterNMs = options.longs === String ? $util.Long.prototype.toString.call(message.retryAfterNMs) : options.longs === Number ? new $util.LongBits(message.retryAfterNMs.low >>> 0, message.retryAfterNMs.high >>> 0).toNumber(true) : message.retryAfterNMs;
        if (message.userResponse != null && message.hasOwnProperty("userResponse"))
            object.userResponse = $root.api.UserResponse.toObject(message.userResponse, options);
        if (message.workspaceResponse != null && message.hasOwnProperty("workspaceResponse"))
            object.workspaceResponse = $root.api.WorkspaceResponse.toObject(message.workspaceResponse, options);
        if (message.channelsResponse != null && message.hasOwnProperty("channelsResponse"))
            object.channelsResponse = $root.api.ChannelsResponse.toObject(message.channelsResponse, options);
        if (message.refetchTip != null && message.hasOwnProperty("refetchTip"))
            object.refetchTip = $root.api.RefetchTip.toObject(message.refetchTip, options);
        return object;
    };

    /**
     * Converts this APIResponse to JSON.
     * @function toJSON
     * @memberof APIResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    APIResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for APIResponse
     * @function getTypeUrl
     * @memberof APIResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    APIResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/APIResponse";
    };

    /**
     * Status enum.
     * @name APIResponse.Status
     * @enum {number}
     * @property {number} AS_OK=0 AS_OK value
     * @property {number} AS_INVALID_HTTP_METHOD=1 AS_INVALID_HTTP_METHOD value
     * @property {number} AS_NON_PARSABLE_REQUEST=2 AS_NON_PARSABLE_REQUEST value
     * @property {number} AS_TRX_SHOULD_BE_UUID=3 AS_TRX_SHOULD_BE_UUID value
     * @property {number} AS_INTERNAL_ERROR=4 AS_INTERNAL_ERROR value
     * @property {number} AS_PENDING_GREETINGS=5 AS_PENDING_GREETINGS value
     * @property {number} AS_DIFFERENT_TOKEN_PROVIDED=6 AS_DIFFERENT_TOKEN_PROVIDED value
     * @property {number} AS_NOT_ALLOWED=7 AS_NOT_ALLOWED value
     */
    APIResponse.Status = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AS_OK"] = 0;
        values[valuesById[1] = "AS_INVALID_HTTP_METHOD"] = 1;
        values[valuesById[2] = "AS_NON_PARSABLE_REQUEST"] = 2;
        values[valuesById[3] = "AS_TRX_SHOULD_BE_UUID"] = 3;
        values[valuesById[4] = "AS_INTERNAL_ERROR"] = 4;
        values[valuesById[5] = "AS_PENDING_GREETINGS"] = 5;
        values[valuesById[6] = "AS_DIFFERENT_TOKEN_PROVIDED"] = 6;
        values[valuesById[7] = "AS_NOT_ALLOWED"] = 7;
        return values;
    })();

    return APIResponse;
})();

export const api = $root.api = (() => {

    /**
     * Namespace api.
     * @exports api
     * @namespace
     */
    const api = {};

    api.UserRequest = (function() {

        /**
         * Properties of a UserRequest.
         * @memberof api
         * @interface IUserRequest
         * @property {api.IClientGreetings|null} [greetings] UserRequest greetings
         * @property {api.ILoginRequest|null} [login] UserRequest login
         * @property {api.IRegisterRequest|null} [register] UserRequest register
         * @property {api.IForgotPasswordRequest|null} [forgotPassword] UserRequest forgotPassword
         * @property {api.IResetPasswordRequest|null} [resetPassword] UserRequest resetPassword
         * @property {api.IConfirmEmailRequest|null} [confirmEmail] UserRequest confirmEmail
         * @property {api.IResendConfirmEmailRequest|null} [resendConfirmEmail] UserRequest resendConfirmEmail
         * @property {api.ILogoutRequest|null} [logout] UserRequest logout
         * @property {api.IChangeDetailsRequest|null} [changeDetails] UserRequest changeDetails
         * @property {api.IGetDetailsRequest|null} [getDetails] UserRequest getDetails
         * @property {api.IChangeEmailRequest|null} [changeEmail] UserRequest changeEmail
         * @property {api.IChangePasswordRequest|null} [changePassword] UserRequest changePassword
         */

        /**
         * Constructs a new UserRequest.
         * @memberof api
         * @classdesc Represents a UserRequest.
         * @implements IUserRequest
         * @constructor
         * @param {api.IUserRequest=} [properties] Properties to set
         */
        function UserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserRequest greetings.
         * @member {api.IClientGreetings|null|undefined} greetings
         * @memberof api.UserRequest
         * @instance
         */
        UserRequest.prototype.greetings = null;

        /**
         * UserRequest login.
         * @member {api.ILoginRequest|null|undefined} login
         * @memberof api.UserRequest
         * @instance
         */
        UserRequest.prototype.login = null;

        /**
         * UserRequest register.
         * @member {api.IRegisterRequest|null|undefined} register
         * @memberof api.UserRequest
         * @instance
         */
        UserRequest.prototype.register = null;

        /**
         * UserRequest forgotPassword.
         * @member {api.IForgotPasswordRequest|null|undefined} forgotPassword
         * @memberof api.UserRequest
         * @instance
         */
        UserRequest.prototype.forgotPassword = null;

        /**
         * UserRequest resetPassword.
         * @member {api.IResetPasswordRequest|null|undefined} resetPassword
         * @memberof api.UserRequest
         * @instance
         */
        UserRequest.prototype.resetPassword = null;

        /**
         * UserRequest confirmEmail.
         * @member {api.IConfirmEmailRequest|null|undefined} confirmEmail
         * @memberof api.UserRequest
         * @instance
         */
        UserRequest.prototype.confirmEmail = null;

        /**
         * UserRequest resendConfirmEmail.
         * @member {api.IResendConfirmEmailRequest|null|undefined} resendConfirmEmail
         * @memberof api.UserRequest
         * @instance
         */
        UserRequest.prototype.resendConfirmEmail = null;

        /**
         * UserRequest logout.
         * @member {api.ILogoutRequest|null|undefined} logout
         * @memberof api.UserRequest
         * @instance
         */
        UserRequest.prototype.logout = null;

        /**
         * UserRequest changeDetails.
         * @member {api.IChangeDetailsRequest|null|undefined} changeDetails
         * @memberof api.UserRequest
         * @instance
         */
        UserRequest.prototype.changeDetails = null;

        /**
         * UserRequest getDetails.
         * @member {api.IGetDetailsRequest|null|undefined} getDetails
         * @memberof api.UserRequest
         * @instance
         */
        UserRequest.prototype.getDetails = null;

        /**
         * UserRequest changeEmail.
         * @member {api.IChangeEmailRequest|null|undefined} changeEmail
         * @memberof api.UserRequest
         * @instance
         */
        UserRequest.prototype.changeEmail = null;

        /**
         * UserRequest changePassword.
         * @member {api.IChangePasswordRequest|null|undefined} changePassword
         * @memberof api.UserRequest
         * @instance
         */
        UserRequest.prototype.changePassword = null;

        /**
         * Creates a new UserRequest instance using the specified properties.
         * @function create
         * @memberof api.UserRequest
         * @static
         * @param {api.IUserRequest=} [properties] Properties to set
         * @returns {api.UserRequest} UserRequest instance
         */
        UserRequest.create = function create(properties) {
            return new UserRequest(properties);
        };

        /**
         * Encodes the specified UserRequest message. Does not implicitly {@link api.UserRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UserRequest
         * @static
         * @param {api.IUserRequest} message UserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.greetings != null && Object.hasOwnProperty.call(message, "greetings"))
                $root.api.ClientGreetings.encode(message.greetings, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                $root.api.LoginRequest.encode(message.login, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.register != null && Object.hasOwnProperty.call(message, "register"))
                $root.api.RegisterRequest.encode(message.register, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.forgotPassword != null && Object.hasOwnProperty.call(message, "forgotPassword"))
                $root.api.ForgotPasswordRequest.encode(message.forgotPassword, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.resetPassword != null && Object.hasOwnProperty.call(message, "resetPassword"))
                $root.api.ResetPasswordRequest.encode(message.resetPassword, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.confirmEmail != null && Object.hasOwnProperty.call(message, "confirmEmail"))
                $root.api.ConfirmEmailRequest.encode(message.confirmEmail, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.resendConfirmEmail != null && Object.hasOwnProperty.call(message, "resendConfirmEmail"))
                $root.api.ResendConfirmEmailRequest.encode(message.resendConfirmEmail, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.logout != null && Object.hasOwnProperty.call(message, "logout"))
                $root.api.LogoutRequest.encode(message.logout, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.changeDetails != null && Object.hasOwnProperty.call(message, "changeDetails"))
                $root.api.ChangeDetailsRequest.encode(message.changeDetails, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.getDetails != null && Object.hasOwnProperty.call(message, "getDetails"))
                $root.api.GetDetailsRequest.encode(message.getDetails, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.changeEmail != null && Object.hasOwnProperty.call(message, "changeEmail"))
                $root.api.ChangeEmailRequest.encode(message.changeEmail, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.changePassword != null && Object.hasOwnProperty.call(message, "changePassword"))
                $root.api.ChangePasswordRequest.encode(message.changePassword, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UserRequest message, length delimited. Does not implicitly {@link api.UserRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UserRequest
         * @static
         * @param {api.IUserRequest} message UserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UserRequest} UserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UserRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 10: {
                        message.greetings = $root.api.ClientGreetings.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.login = $root.api.LoginRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.register = $root.api.RegisterRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.forgotPassword = $root.api.ForgotPasswordRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.resetPassword = $root.api.ResetPasswordRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.confirmEmail = $root.api.ConfirmEmailRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 16: {
                        message.resendConfirmEmail = $root.api.ResendConfirmEmailRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 17: {
                        message.logout = $root.api.LogoutRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 18: {
                        message.changeDetails = $root.api.ChangeDetailsRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 19: {
                        message.getDetails = $root.api.GetDetailsRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.changeEmail = $root.api.ChangeEmailRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.changePassword = $root.api.ChangePasswordRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UserRequest} UserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserRequest message.
         * @function verify
         * @memberof api.UserRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.greetings != null && message.hasOwnProperty("greetings")) {
                let error = $root.api.ClientGreetings.verify(message.greetings);
                if (error)
                    return "greetings." + error;
            }
            if (message.login != null && message.hasOwnProperty("login")) {
                let error = $root.api.LoginRequest.verify(message.login);
                if (error)
                    return "login." + error;
            }
            if (message.register != null && message.hasOwnProperty("register")) {
                let error = $root.api.RegisterRequest.verify(message.register);
                if (error)
                    return "register." + error;
            }
            if (message.forgotPassword != null && message.hasOwnProperty("forgotPassword")) {
                let error = $root.api.ForgotPasswordRequest.verify(message.forgotPassword);
                if (error)
                    return "forgotPassword." + error;
            }
            if (message.resetPassword != null && message.hasOwnProperty("resetPassword")) {
                let error = $root.api.ResetPasswordRequest.verify(message.resetPassword);
                if (error)
                    return "resetPassword." + error;
            }
            if (message.confirmEmail != null && message.hasOwnProperty("confirmEmail")) {
                let error = $root.api.ConfirmEmailRequest.verify(message.confirmEmail);
                if (error)
                    return "confirmEmail." + error;
            }
            if (message.resendConfirmEmail != null && message.hasOwnProperty("resendConfirmEmail")) {
                let error = $root.api.ResendConfirmEmailRequest.verify(message.resendConfirmEmail);
                if (error)
                    return "resendConfirmEmail." + error;
            }
            if (message.logout != null && message.hasOwnProperty("logout")) {
                let error = $root.api.LogoutRequest.verify(message.logout);
                if (error)
                    return "logout." + error;
            }
            if (message.changeDetails != null && message.hasOwnProperty("changeDetails")) {
                let error = $root.api.ChangeDetailsRequest.verify(message.changeDetails);
                if (error)
                    return "changeDetails." + error;
            }
            if (message.getDetails != null && message.hasOwnProperty("getDetails")) {
                let error = $root.api.GetDetailsRequest.verify(message.getDetails);
                if (error)
                    return "getDetails." + error;
            }
            if (message.changeEmail != null && message.hasOwnProperty("changeEmail")) {
                let error = $root.api.ChangeEmailRequest.verify(message.changeEmail);
                if (error)
                    return "changeEmail." + error;
            }
            if (message.changePassword != null && message.hasOwnProperty("changePassword")) {
                let error = $root.api.ChangePasswordRequest.verify(message.changePassword);
                if (error)
                    return "changePassword." + error;
            }
            return null;
        };

        /**
         * Creates a UserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UserRequest} UserRequest
         */
        UserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UserRequest)
                return object;
            let message = new $root.api.UserRequest();
            if (object.greetings != null) {
                if (typeof object.greetings !== "object")
                    throw TypeError(".api.UserRequest.greetings: object expected");
                message.greetings = $root.api.ClientGreetings.fromObject(object.greetings);
            }
            if (object.login != null) {
                if (typeof object.login !== "object")
                    throw TypeError(".api.UserRequest.login: object expected");
                message.login = $root.api.LoginRequest.fromObject(object.login);
            }
            if (object.register != null) {
                if (typeof object.register !== "object")
                    throw TypeError(".api.UserRequest.register: object expected");
                message.register = $root.api.RegisterRequest.fromObject(object.register);
            }
            if (object.forgotPassword != null) {
                if (typeof object.forgotPassword !== "object")
                    throw TypeError(".api.UserRequest.forgotPassword: object expected");
                message.forgotPassword = $root.api.ForgotPasswordRequest.fromObject(object.forgotPassword);
            }
            if (object.resetPassword != null) {
                if (typeof object.resetPassword !== "object")
                    throw TypeError(".api.UserRequest.resetPassword: object expected");
                message.resetPassword = $root.api.ResetPasswordRequest.fromObject(object.resetPassword);
            }
            if (object.confirmEmail != null) {
                if (typeof object.confirmEmail !== "object")
                    throw TypeError(".api.UserRequest.confirmEmail: object expected");
                message.confirmEmail = $root.api.ConfirmEmailRequest.fromObject(object.confirmEmail);
            }
            if (object.resendConfirmEmail != null) {
                if (typeof object.resendConfirmEmail !== "object")
                    throw TypeError(".api.UserRequest.resendConfirmEmail: object expected");
                message.resendConfirmEmail = $root.api.ResendConfirmEmailRequest.fromObject(object.resendConfirmEmail);
            }
            if (object.logout != null) {
                if (typeof object.logout !== "object")
                    throw TypeError(".api.UserRequest.logout: object expected");
                message.logout = $root.api.LogoutRequest.fromObject(object.logout);
            }
            if (object.changeDetails != null) {
                if (typeof object.changeDetails !== "object")
                    throw TypeError(".api.UserRequest.changeDetails: object expected");
                message.changeDetails = $root.api.ChangeDetailsRequest.fromObject(object.changeDetails);
            }
            if (object.getDetails != null) {
                if (typeof object.getDetails !== "object")
                    throw TypeError(".api.UserRequest.getDetails: object expected");
                message.getDetails = $root.api.GetDetailsRequest.fromObject(object.getDetails);
            }
            if (object.changeEmail != null) {
                if (typeof object.changeEmail !== "object")
                    throw TypeError(".api.UserRequest.changeEmail: object expected");
                message.changeEmail = $root.api.ChangeEmailRequest.fromObject(object.changeEmail);
            }
            if (object.changePassword != null) {
                if (typeof object.changePassword !== "object")
                    throw TypeError(".api.UserRequest.changePassword: object expected");
                message.changePassword = $root.api.ChangePasswordRequest.fromObject(object.changePassword);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UserRequest
         * @static
         * @param {api.UserRequest} message UserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.greetings = null;
                object.login = null;
                object.register = null;
                object.forgotPassword = null;
                object.resetPassword = null;
                object.confirmEmail = null;
                object.resendConfirmEmail = null;
                object.logout = null;
                object.changeDetails = null;
                object.getDetails = null;
                object.changeEmail = null;
                object.changePassword = null;
            }
            if (message.greetings != null && message.hasOwnProperty("greetings"))
                object.greetings = $root.api.ClientGreetings.toObject(message.greetings, options);
            if (message.login != null && message.hasOwnProperty("login"))
                object.login = $root.api.LoginRequest.toObject(message.login, options);
            if (message.register != null && message.hasOwnProperty("register"))
                object.register = $root.api.RegisterRequest.toObject(message.register, options);
            if (message.forgotPassword != null && message.hasOwnProperty("forgotPassword"))
                object.forgotPassword = $root.api.ForgotPasswordRequest.toObject(message.forgotPassword, options);
            if (message.resetPassword != null && message.hasOwnProperty("resetPassword"))
                object.resetPassword = $root.api.ResetPasswordRequest.toObject(message.resetPassword, options);
            if (message.confirmEmail != null && message.hasOwnProperty("confirmEmail"))
                object.confirmEmail = $root.api.ConfirmEmailRequest.toObject(message.confirmEmail, options);
            if (message.resendConfirmEmail != null && message.hasOwnProperty("resendConfirmEmail"))
                object.resendConfirmEmail = $root.api.ResendConfirmEmailRequest.toObject(message.resendConfirmEmail, options);
            if (message.logout != null && message.hasOwnProperty("logout"))
                object.logout = $root.api.LogoutRequest.toObject(message.logout, options);
            if (message.changeDetails != null && message.hasOwnProperty("changeDetails"))
                object.changeDetails = $root.api.ChangeDetailsRequest.toObject(message.changeDetails, options);
            if (message.getDetails != null && message.hasOwnProperty("getDetails"))
                object.getDetails = $root.api.GetDetailsRequest.toObject(message.getDetails, options);
            if (message.changeEmail != null && message.hasOwnProperty("changeEmail"))
                object.changeEmail = $root.api.ChangeEmailRequest.toObject(message.changeEmail, options);
            if (message.changePassword != null && message.hasOwnProperty("changePassword"))
                object.changePassword = $root.api.ChangePasswordRequest.toObject(message.changePassword, options);
            return object;
        };

        /**
         * Converts this UserRequest to JSON.
         * @function toJSON
         * @memberof api.UserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserRequest
         * @function getTypeUrl
         * @memberof api.UserRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.UserRequest";
        };

        return UserRequest;
    })();

    api.UserResponse = (function() {

        /**
         * Properties of a UserResponse.
         * @memberof api
         * @interface IUserResponse
         * @property {api.IUserData|null} [userData] UserResponse userData
         * @property {Uint8Array|null} [newToken] UserResponse newToken
         * @property {api.IServerGreetings|null} [greetings] UserResponse greetings
         * @property {api.ILoginResponse|null} [login] UserResponse login
         * @property {api.IRegisterResponse|null} [register] UserResponse register
         * @property {api.IForgotPasswordResponse|null} [forgotPassword] UserResponse forgotPassword
         * @property {api.IResetPasswordResponse|null} [resetPassword] UserResponse resetPassword
         * @property {api.IConfirmEmailResponse|null} [confirmEmail] UserResponse confirmEmail
         * @property {api.IResendConfirmEmailResponse|null} [resendConfirmEmail] UserResponse resendConfirmEmail
         * @property {api.ILogoutResponse|null} [logout] UserResponse logout
         * @property {api.IChangeDetailsResponse|null} [changeDetails] UserResponse changeDetails
         * @property {api.IGetDetailsResponse|null} [getDetails] UserResponse getDetails
         * @property {api.IChangeEmailResponse|null} [changeEmail] UserResponse changeEmail
         * @property {api.IChangePasswordResponse|null} [changePassword] UserResponse changePassword
         */

        /**
         * Constructs a new UserResponse.
         * @memberof api
         * @classdesc Represents a UserResponse.
         * @implements IUserResponse
         * @constructor
         * @param {api.IUserResponse=} [properties] Properties to set
         */
        function UserResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserResponse userData.
         * @member {api.IUserData|null|undefined} userData
         * @memberof api.UserResponse
         * @instance
         */
        UserResponse.prototype.userData = null;

        /**
         * UserResponse newToken.
         * @member {Uint8Array} newToken
         * @memberof api.UserResponse
         * @instance
         */
        UserResponse.prototype.newToken = $util.newBuffer([]);

        /**
         * UserResponse greetings.
         * @member {api.IServerGreetings|null|undefined} greetings
         * @memberof api.UserResponse
         * @instance
         */
        UserResponse.prototype.greetings = null;

        /**
         * UserResponse login.
         * @member {api.ILoginResponse|null|undefined} login
         * @memberof api.UserResponse
         * @instance
         */
        UserResponse.prototype.login = null;

        /**
         * UserResponse register.
         * @member {api.IRegisterResponse|null|undefined} register
         * @memberof api.UserResponse
         * @instance
         */
        UserResponse.prototype.register = null;

        /**
         * UserResponse forgotPassword.
         * @member {api.IForgotPasswordResponse|null|undefined} forgotPassword
         * @memberof api.UserResponse
         * @instance
         */
        UserResponse.prototype.forgotPassword = null;

        /**
         * UserResponse resetPassword.
         * @member {api.IResetPasswordResponse|null|undefined} resetPassword
         * @memberof api.UserResponse
         * @instance
         */
        UserResponse.prototype.resetPassword = null;

        /**
         * UserResponse confirmEmail.
         * @member {api.IConfirmEmailResponse|null|undefined} confirmEmail
         * @memberof api.UserResponse
         * @instance
         */
        UserResponse.prototype.confirmEmail = null;

        /**
         * UserResponse resendConfirmEmail.
         * @member {api.IResendConfirmEmailResponse|null|undefined} resendConfirmEmail
         * @memberof api.UserResponse
         * @instance
         */
        UserResponse.prototype.resendConfirmEmail = null;

        /**
         * UserResponse logout.
         * @member {api.ILogoutResponse|null|undefined} logout
         * @memberof api.UserResponse
         * @instance
         */
        UserResponse.prototype.logout = null;

        /**
         * UserResponse changeDetails.
         * @member {api.IChangeDetailsResponse|null|undefined} changeDetails
         * @memberof api.UserResponse
         * @instance
         */
        UserResponse.prototype.changeDetails = null;

        /**
         * UserResponse getDetails.
         * @member {api.IGetDetailsResponse|null|undefined} getDetails
         * @memberof api.UserResponse
         * @instance
         */
        UserResponse.prototype.getDetails = null;

        /**
         * UserResponse changeEmail.
         * @member {api.IChangeEmailResponse|null|undefined} changeEmail
         * @memberof api.UserResponse
         * @instance
         */
        UserResponse.prototype.changeEmail = null;

        /**
         * UserResponse changePassword.
         * @member {api.IChangePasswordResponse|null|undefined} changePassword
         * @memberof api.UserResponse
         * @instance
         */
        UserResponse.prototype.changePassword = null;

        /**
         * Creates a new UserResponse instance using the specified properties.
         * @function create
         * @memberof api.UserResponse
         * @static
         * @param {api.IUserResponse=} [properties] Properties to set
         * @returns {api.UserResponse} UserResponse instance
         */
        UserResponse.create = function create(properties) {
            return new UserResponse(properties);
        };

        /**
         * Encodes the specified UserResponse message. Does not implicitly {@link api.UserResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UserResponse
         * @static
         * @param {api.IUserResponse} message UserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userData != null && Object.hasOwnProperty.call(message, "userData"))
                $root.api.UserData.encode(message.userData, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.newToken != null && Object.hasOwnProperty.call(message, "newToken"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.newToken);
            if (message.greetings != null && Object.hasOwnProperty.call(message, "greetings"))
                $root.api.ServerGreetings.encode(message.greetings, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                $root.api.LoginResponse.encode(message.login, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.register != null && Object.hasOwnProperty.call(message, "register"))
                $root.api.RegisterResponse.encode(message.register, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.forgotPassword != null && Object.hasOwnProperty.call(message, "forgotPassword"))
                $root.api.ForgotPasswordResponse.encode(message.forgotPassword, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.resetPassword != null && Object.hasOwnProperty.call(message, "resetPassword"))
                $root.api.ResetPasswordResponse.encode(message.resetPassword, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.confirmEmail != null && Object.hasOwnProperty.call(message, "confirmEmail"))
                $root.api.ConfirmEmailResponse.encode(message.confirmEmail, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.resendConfirmEmail != null && Object.hasOwnProperty.call(message, "resendConfirmEmail"))
                $root.api.ResendConfirmEmailResponse.encode(message.resendConfirmEmail, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.logout != null && Object.hasOwnProperty.call(message, "logout"))
                $root.api.LogoutResponse.encode(message.logout, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.changeDetails != null && Object.hasOwnProperty.call(message, "changeDetails"))
                $root.api.ChangeDetailsResponse.encode(message.changeDetails, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.getDetails != null && Object.hasOwnProperty.call(message, "getDetails"))
                $root.api.GetDetailsResponse.encode(message.getDetails, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.changeEmail != null && Object.hasOwnProperty.call(message, "changeEmail"))
                $root.api.ChangeEmailResponse.encode(message.changeEmail, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.changePassword != null && Object.hasOwnProperty.call(message, "changePassword"))
                $root.api.ChangePasswordResponse.encode(message.changePassword, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UserResponse message, length delimited. Does not implicitly {@link api.UserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UserResponse
         * @static
         * @param {api.IUserResponse} message UserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UserResponse} UserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UserResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userData = $root.api.UserData.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.newToken = reader.bytes();
                        break;
                    }
                case 10: {
                        message.greetings = $root.api.ServerGreetings.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.login = $root.api.LoginResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.register = $root.api.RegisterResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.forgotPassword = $root.api.ForgotPasswordResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.resetPassword = $root.api.ResetPasswordResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.confirmEmail = $root.api.ConfirmEmailResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 16: {
                        message.resendConfirmEmail = $root.api.ResendConfirmEmailResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 17: {
                        message.logout = $root.api.LogoutResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 18: {
                        message.changeDetails = $root.api.ChangeDetailsResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 19: {
                        message.getDetails = $root.api.GetDetailsResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.changeEmail = $root.api.ChangeEmailResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.changePassword = $root.api.ChangePasswordResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UserResponse} UserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserResponse message.
         * @function verify
         * @memberof api.UserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userData != null && message.hasOwnProperty("userData")) {
                let error = $root.api.UserData.verify(message.userData);
                if (error)
                    return "userData." + error;
            }
            if (message.newToken != null && message.hasOwnProperty("newToken"))
                if (!(message.newToken && typeof message.newToken.length === "number" || $util.isString(message.newToken)))
                    return "newToken: buffer expected";
            if (message.greetings != null && message.hasOwnProperty("greetings")) {
                let error = $root.api.ServerGreetings.verify(message.greetings);
                if (error)
                    return "greetings." + error;
            }
            if (message.login != null && message.hasOwnProperty("login")) {
                let error = $root.api.LoginResponse.verify(message.login);
                if (error)
                    return "login." + error;
            }
            if (message.register != null && message.hasOwnProperty("register")) {
                let error = $root.api.RegisterResponse.verify(message.register);
                if (error)
                    return "register." + error;
            }
            if (message.forgotPassword != null && message.hasOwnProperty("forgotPassword")) {
                let error = $root.api.ForgotPasswordResponse.verify(message.forgotPassword);
                if (error)
                    return "forgotPassword." + error;
            }
            if (message.resetPassword != null && message.hasOwnProperty("resetPassword")) {
                let error = $root.api.ResetPasswordResponse.verify(message.resetPassword);
                if (error)
                    return "resetPassword." + error;
            }
            if (message.confirmEmail != null && message.hasOwnProperty("confirmEmail")) {
                let error = $root.api.ConfirmEmailResponse.verify(message.confirmEmail);
                if (error)
                    return "confirmEmail." + error;
            }
            if (message.resendConfirmEmail != null && message.hasOwnProperty("resendConfirmEmail")) {
                let error = $root.api.ResendConfirmEmailResponse.verify(message.resendConfirmEmail);
                if (error)
                    return "resendConfirmEmail." + error;
            }
            if (message.logout != null && message.hasOwnProperty("logout")) {
                let error = $root.api.LogoutResponse.verify(message.logout);
                if (error)
                    return "logout." + error;
            }
            if (message.changeDetails != null && message.hasOwnProperty("changeDetails")) {
                let error = $root.api.ChangeDetailsResponse.verify(message.changeDetails);
                if (error)
                    return "changeDetails." + error;
            }
            if (message.getDetails != null && message.hasOwnProperty("getDetails")) {
                let error = $root.api.GetDetailsResponse.verify(message.getDetails);
                if (error)
                    return "getDetails." + error;
            }
            if (message.changeEmail != null && message.hasOwnProperty("changeEmail")) {
                let error = $root.api.ChangeEmailResponse.verify(message.changeEmail);
                if (error)
                    return "changeEmail." + error;
            }
            if (message.changePassword != null && message.hasOwnProperty("changePassword")) {
                let error = $root.api.ChangePasswordResponse.verify(message.changePassword);
                if (error)
                    return "changePassword." + error;
            }
            return null;
        };

        /**
         * Creates a UserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UserResponse} UserResponse
         */
        UserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UserResponse)
                return object;
            let message = new $root.api.UserResponse();
            if (object.userData != null) {
                if (typeof object.userData !== "object")
                    throw TypeError(".api.UserResponse.userData: object expected");
                message.userData = $root.api.UserData.fromObject(object.userData);
            }
            if (object.newToken != null)
                if (typeof object.newToken === "string")
                    $util.base64.decode(object.newToken, message.newToken = $util.newBuffer($util.base64.length(object.newToken)), 0);
                else if (object.newToken.length >= 0)
                    message.newToken = object.newToken;
            if (object.greetings != null) {
                if (typeof object.greetings !== "object")
                    throw TypeError(".api.UserResponse.greetings: object expected");
                message.greetings = $root.api.ServerGreetings.fromObject(object.greetings);
            }
            if (object.login != null) {
                if (typeof object.login !== "object")
                    throw TypeError(".api.UserResponse.login: object expected");
                message.login = $root.api.LoginResponse.fromObject(object.login);
            }
            if (object.register != null) {
                if (typeof object.register !== "object")
                    throw TypeError(".api.UserResponse.register: object expected");
                message.register = $root.api.RegisterResponse.fromObject(object.register);
            }
            if (object.forgotPassword != null) {
                if (typeof object.forgotPassword !== "object")
                    throw TypeError(".api.UserResponse.forgotPassword: object expected");
                message.forgotPassword = $root.api.ForgotPasswordResponse.fromObject(object.forgotPassword);
            }
            if (object.resetPassword != null) {
                if (typeof object.resetPassword !== "object")
                    throw TypeError(".api.UserResponse.resetPassword: object expected");
                message.resetPassword = $root.api.ResetPasswordResponse.fromObject(object.resetPassword);
            }
            if (object.confirmEmail != null) {
                if (typeof object.confirmEmail !== "object")
                    throw TypeError(".api.UserResponse.confirmEmail: object expected");
                message.confirmEmail = $root.api.ConfirmEmailResponse.fromObject(object.confirmEmail);
            }
            if (object.resendConfirmEmail != null) {
                if (typeof object.resendConfirmEmail !== "object")
                    throw TypeError(".api.UserResponse.resendConfirmEmail: object expected");
                message.resendConfirmEmail = $root.api.ResendConfirmEmailResponse.fromObject(object.resendConfirmEmail);
            }
            if (object.logout != null) {
                if (typeof object.logout !== "object")
                    throw TypeError(".api.UserResponse.logout: object expected");
                message.logout = $root.api.LogoutResponse.fromObject(object.logout);
            }
            if (object.changeDetails != null) {
                if (typeof object.changeDetails !== "object")
                    throw TypeError(".api.UserResponse.changeDetails: object expected");
                message.changeDetails = $root.api.ChangeDetailsResponse.fromObject(object.changeDetails);
            }
            if (object.getDetails != null) {
                if (typeof object.getDetails !== "object")
                    throw TypeError(".api.UserResponse.getDetails: object expected");
                message.getDetails = $root.api.GetDetailsResponse.fromObject(object.getDetails);
            }
            if (object.changeEmail != null) {
                if (typeof object.changeEmail !== "object")
                    throw TypeError(".api.UserResponse.changeEmail: object expected");
                message.changeEmail = $root.api.ChangeEmailResponse.fromObject(object.changeEmail);
            }
            if (object.changePassword != null) {
                if (typeof object.changePassword !== "object")
                    throw TypeError(".api.UserResponse.changePassword: object expected");
                message.changePassword = $root.api.ChangePasswordResponse.fromObject(object.changePassword);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UserResponse
         * @static
         * @param {api.UserResponse} message UserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userData = null;
                if (options.bytes === String)
                    object.newToken = "";
                else {
                    object.newToken = [];
                    if (options.bytes !== Array)
                        object.newToken = $util.newBuffer(object.newToken);
                }
                object.greetings = null;
                object.login = null;
                object.register = null;
                object.forgotPassword = null;
                object.resetPassword = null;
                object.confirmEmail = null;
                object.resendConfirmEmail = null;
                object.logout = null;
                object.changeDetails = null;
                object.getDetails = null;
                object.changeEmail = null;
                object.changePassword = null;
            }
            if (message.userData != null && message.hasOwnProperty("userData"))
                object.userData = $root.api.UserData.toObject(message.userData, options);
            if (message.newToken != null && message.hasOwnProperty("newToken"))
                object.newToken = options.bytes === String ? $util.base64.encode(message.newToken, 0, message.newToken.length) : options.bytes === Array ? Array.prototype.slice.call(message.newToken) : message.newToken;
            if (message.greetings != null && message.hasOwnProperty("greetings"))
                object.greetings = $root.api.ServerGreetings.toObject(message.greetings, options);
            if (message.login != null && message.hasOwnProperty("login"))
                object.login = $root.api.LoginResponse.toObject(message.login, options);
            if (message.register != null && message.hasOwnProperty("register"))
                object.register = $root.api.RegisterResponse.toObject(message.register, options);
            if (message.forgotPassword != null && message.hasOwnProperty("forgotPassword"))
                object.forgotPassword = $root.api.ForgotPasswordResponse.toObject(message.forgotPassword, options);
            if (message.resetPassword != null && message.hasOwnProperty("resetPassword"))
                object.resetPassword = $root.api.ResetPasswordResponse.toObject(message.resetPassword, options);
            if (message.confirmEmail != null && message.hasOwnProperty("confirmEmail"))
                object.confirmEmail = $root.api.ConfirmEmailResponse.toObject(message.confirmEmail, options);
            if (message.resendConfirmEmail != null && message.hasOwnProperty("resendConfirmEmail"))
                object.resendConfirmEmail = $root.api.ResendConfirmEmailResponse.toObject(message.resendConfirmEmail, options);
            if (message.logout != null && message.hasOwnProperty("logout"))
                object.logout = $root.api.LogoutResponse.toObject(message.logout, options);
            if (message.changeDetails != null && message.hasOwnProperty("changeDetails"))
                object.changeDetails = $root.api.ChangeDetailsResponse.toObject(message.changeDetails, options);
            if (message.getDetails != null && message.hasOwnProperty("getDetails"))
                object.getDetails = $root.api.GetDetailsResponse.toObject(message.getDetails, options);
            if (message.changeEmail != null && message.hasOwnProperty("changeEmail"))
                object.changeEmail = $root.api.ChangeEmailResponse.toObject(message.changeEmail, options);
            if (message.changePassword != null && message.hasOwnProperty("changePassword"))
                object.changePassword = $root.api.ChangePasswordResponse.toObject(message.changePassword, options);
            return object;
        };

        /**
         * Converts this UserResponse to JSON.
         * @function toJSON
         * @memberof api.UserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserResponse
         * @function getTypeUrl
         * @memberof api.UserResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.UserResponse";
        };

        return UserResponse;
    })();

    api.ClientGreetings = (function() {

        /**
         * Properties of a ClientGreetings.
         * @memberof api
         * @interface IClientGreetings
         * @property {Uint8Array|null} [token] ClientGreetings token
         * @property {string|null} [appVersion] ClientGreetings appVersion
         */

        /**
         * Constructs a new ClientGreetings.
         * @memberof api
         * @classdesc Represents a ClientGreetings.
         * @implements IClientGreetings
         * @constructor
         * @param {api.IClientGreetings=} [properties] Properties to set
         */
        function ClientGreetings(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClientGreetings token.
         * @member {Uint8Array} token
         * @memberof api.ClientGreetings
         * @instance
         */
        ClientGreetings.prototype.token = $util.newBuffer([]);

        /**
         * ClientGreetings appVersion.
         * @member {string} appVersion
         * @memberof api.ClientGreetings
         * @instance
         */
        ClientGreetings.prototype.appVersion = "";

        /**
         * Creates a new ClientGreetings instance using the specified properties.
         * @function create
         * @memberof api.ClientGreetings
         * @static
         * @param {api.IClientGreetings=} [properties] Properties to set
         * @returns {api.ClientGreetings} ClientGreetings instance
         */
        ClientGreetings.create = function create(properties) {
            return new ClientGreetings(properties);
        };

        /**
         * Encodes the specified ClientGreetings message. Does not implicitly {@link api.ClientGreetings.verify|verify} messages.
         * @function encode
         * @memberof api.ClientGreetings
         * @static
         * @param {api.IClientGreetings} message ClientGreetings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientGreetings.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.token);
            if (message.appVersion != null && Object.hasOwnProperty.call(message, "appVersion"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.appVersion);
            return writer;
        };

        /**
         * Encodes the specified ClientGreetings message, length delimited. Does not implicitly {@link api.ClientGreetings.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ClientGreetings
         * @static
         * @param {api.IClientGreetings} message ClientGreetings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientGreetings.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ClientGreetings message from the specified reader or buffer.
         * @function decode
         * @memberof api.ClientGreetings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ClientGreetings} ClientGreetings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientGreetings.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ClientGreetings();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.token = reader.bytes();
                        break;
                    }
                case 2: {
                        message.appVersion = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ClientGreetings message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ClientGreetings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ClientGreetings} ClientGreetings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientGreetings.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ClientGreetings message.
         * @function verify
         * @memberof api.ClientGreetings
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ClientGreetings.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!(message.token && typeof message.token.length === "number" || $util.isString(message.token)))
                    return "token: buffer expected";
            if (message.appVersion != null && message.hasOwnProperty("appVersion"))
                if (!$util.isString(message.appVersion))
                    return "appVersion: string expected";
            return null;
        };

        /**
         * Creates a ClientGreetings message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ClientGreetings
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ClientGreetings} ClientGreetings
         */
        ClientGreetings.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ClientGreetings)
                return object;
            let message = new $root.api.ClientGreetings();
            if (object.token != null)
                if (typeof object.token === "string")
                    $util.base64.decode(object.token, message.token = $util.newBuffer($util.base64.length(object.token)), 0);
                else if (object.token.length >= 0)
                    message.token = object.token;
            if (object.appVersion != null)
                message.appVersion = String(object.appVersion);
            return message;
        };

        /**
         * Creates a plain object from a ClientGreetings message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ClientGreetings
         * @static
         * @param {api.ClientGreetings} message ClientGreetings
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ClientGreetings.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.token = "";
                else {
                    object.token = [];
                    if (options.bytes !== Array)
                        object.token = $util.newBuffer(object.token);
                }
                object.appVersion = "";
            }
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = options.bytes === String ? $util.base64.encode(message.token, 0, message.token.length) : options.bytes === Array ? Array.prototype.slice.call(message.token) : message.token;
            if (message.appVersion != null && message.hasOwnProperty("appVersion"))
                object.appVersion = message.appVersion;
            return object;
        };

        /**
         * Converts this ClientGreetings to JSON.
         * @function toJSON
         * @memberof api.ClientGreetings
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ClientGreetings.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ClientGreetings
         * @function getTypeUrl
         * @memberof api.ClientGreetings
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ClientGreetings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ClientGreetings";
        };

        return ClientGreetings;
    })();

    api.ServerGreetings = (function() {

        /**
         * Properties of a ServerGreetings.
         * @memberof api
         * @interface IServerGreetings
         * @property {boolean|null} [ok] ServerGreetings ok
         * @property {entities.UserRegistrationStatus|null} [status] ServerGreetings status
         * @property {entities.IFeatures|null} [features] ServerGreetings features
         */

        /**
         * Constructs a new ServerGreetings.
         * @memberof api
         * @classdesc Represents a ServerGreetings.
         * @implements IServerGreetings
         * @constructor
         * @param {api.IServerGreetings=} [properties] Properties to set
         */
        function ServerGreetings(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServerGreetings ok.
         * @member {boolean} ok
         * @memberof api.ServerGreetings
         * @instance
         */
        ServerGreetings.prototype.ok = false;

        /**
         * ServerGreetings status.
         * @member {entities.UserRegistrationStatus} status
         * @memberof api.ServerGreetings
         * @instance
         */
        ServerGreetings.prototype.status = 0;

        /**
         * ServerGreetings features.
         * @member {entities.IFeatures|null|undefined} features
         * @memberof api.ServerGreetings
         * @instance
         */
        ServerGreetings.prototype.features = null;

        /**
         * Creates a new ServerGreetings instance using the specified properties.
         * @function create
         * @memberof api.ServerGreetings
         * @static
         * @param {api.IServerGreetings=} [properties] Properties to set
         * @returns {api.ServerGreetings} ServerGreetings instance
         */
        ServerGreetings.create = function create(properties) {
            return new ServerGreetings(properties);
        };

        /**
         * Encodes the specified ServerGreetings message. Does not implicitly {@link api.ServerGreetings.verify|verify} messages.
         * @function encode
         * @memberof api.ServerGreetings
         * @static
         * @param {api.IServerGreetings} message ServerGreetings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServerGreetings.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ok != null && Object.hasOwnProperty.call(message, "ok"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.ok);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
            if (message.features != null && Object.hasOwnProperty.call(message, "features"))
                $root.entities.Features.encode(message.features, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ServerGreetings message, length delimited. Does not implicitly {@link api.ServerGreetings.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ServerGreetings
         * @static
         * @param {api.IServerGreetings} message ServerGreetings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServerGreetings.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ServerGreetings message from the specified reader or buffer.
         * @function decode
         * @memberof api.ServerGreetings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ServerGreetings} ServerGreetings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServerGreetings.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ServerGreetings();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ok = reader.bool();
                        break;
                    }
                case 2: {
                        message.status = reader.int32();
                        break;
                    }
                case 10: {
                        message.features = $root.entities.Features.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ServerGreetings message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ServerGreetings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ServerGreetings} ServerGreetings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServerGreetings.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ServerGreetings message.
         * @function verify
         * @memberof api.ServerGreetings
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ServerGreetings.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ok != null && message.hasOwnProperty("ok"))
                if (typeof message.ok !== "boolean")
                    return "ok: boolean expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.features != null && message.hasOwnProperty("features")) {
                let error = $root.entities.Features.verify(message.features);
                if (error)
                    return "features." + error;
            }
            return null;
        };

        /**
         * Creates a ServerGreetings message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ServerGreetings
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ServerGreetings} ServerGreetings
         */
        ServerGreetings.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ServerGreetings)
                return object;
            let message = new $root.api.ServerGreetings();
            if (object.ok != null)
                message.ok = Boolean(object.ok);
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "UL_GUEST":
            case 0:
                message.status = 0;
                break;
            case "UL_REGISTERED":
            case 1:
                message.status = 1;
                break;
            }
            if (object.features != null) {
                if (typeof object.features !== "object")
                    throw TypeError(".api.ServerGreetings.features: object expected");
                message.features = $root.entities.Features.fromObject(object.features);
            }
            return message;
        };

        /**
         * Creates a plain object from a ServerGreetings message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ServerGreetings
         * @static
         * @param {api.ServerGreetings} message ServerGreetings
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServerGreetings.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.ok = false;
                object.status = options.enums === String ? "UL_GUEST" : 0;
                object.features = null;
            }
            if (message.ok != null && message.hasOwnProperty("ok"))
                object.ok = message.ok;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.entities.UserRegistrationStatus[message.status] === undefined ? message.status : $root.entities.UserRegistrationStatus[message.status] : message.status;
            if (message.features != null && message.hasOwnProperty("features"))
                object.features = $root.entities.Features.toObject(message.features, options);
            return object;
        };

        /**
         * Converts this ServerGreetings to JSON.
         * @function toJSON
         * @memberof api.ServerGreetings
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServerGreetings.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ServerGreetings
         * @function getTypeUrl
         * @memberof api.ServerGreetings
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ServerGreetings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ServerGreetings";
        };

        return ServerGreetings;
    })();

    api.LoginRequest = (function() {

        /**
         * Properties of a LoginRequest.
         * @memberof api
         * @interface ILoginRequest
         * @property {entities.AuthType|null} [type] LoginRequest type
         * @property {string|null} [email] LoginRequest email
         * @property {string|null} [password] LoginRequest password
         */

        /**
         * Constructs a new LoginRequest.
         * @memberof api
         * @classdesc Represents a LoginRequest.
         * @implements ILoginRequest
         * @constructor
         * @param {api.ILoginRequest=} [properties] Properties to set
         */
        function LoginRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginRequest type.
         * @member {entities.AuthType} type
         * @memberof api.LoginRequest
         * @instance
         */
        LoginRequest.prototype.type = 0;

        /**
         * LoginRequest email.
         * @member {string} email
         * @memberof api.LoginRequest
         * @instance
         */
        LoginRequest.prototype.email = "";

        /**
         * LoginRequest password.
         * @member {string} password
         * @memberof api.LoginRequest
         * @instance
         */
        LoginRequest.prototype.password = "";

        /**
         * Creates a new LoginRequest instance using the specified properties.
         * @function create
         * @memberof api.LoginRequest
         * @static
         * @param {api.ILoginRequest=} [properties] Properties to set
         * @returns {api.LoginRequest} LoginRequest instance
         */
        LoginRequest.create = function create(properties) {
            return new LoginRequest(properties);
        };

        /**
         * Encodes the specified LoginRequest message. Does not implicitly {@link api.LoginRequest.verify|verify} messages.
         * @function encode
         * @memberof api.LoginRequest
         * @static
         * @param {api.ILoginRequest} message LoginRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.password);
            return writer;
        };

        /**
         * Encodes the specified LoginRequest message, length delimited. Does not implicitly {@link api.LoginRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.LoginRequest
         * @static
         * @param {api.ILoginRequest} message LoginRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.LoginRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.LoginRequest} LoginRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.LoginRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.email = reader.string();
                        break;
                    }
                case 3: {
                        message.password = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.LoginRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.LoginRequest} LoginRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginRequest message.
         * @function verify
         * @memberof api.LoginRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                    break;
                }
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            return null;
        };

        /**
         * Creates a LoginRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.LoginRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.LoginRequest} LoginRequest
         */
        LoginRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.LoginRequest)
                return object;
            let message = new $root.api.LoginRequest();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "AT_EMAIL":
            case 0:
                message.type = 0;
                break;
            }
            if (object.email != null)
                message.email = String(object.email);
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };

        /**
         * Creates a plain object from a LoginRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.LoginRequest
         * @static
         * @param {api.LoginRequest} message LoginRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "AT_EMAIL" : 0;
                object.email = "";
                object.password = "";
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.entities.AuthType[message.type] === undefined ? message.type : $root.entities.AuthType[message.type] : message.type;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            return object;
        };

        /**
         * Converts this LoginRequest to JSON.
         * @function toJSON
         * @memberof api.LoginRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginRequest
         * @function getTypeUrl
         * @memberof api.LoginRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.LoginRequest";
        };

        return LoginRequest;
    })();

    api.LoginResponse = (function() {

        /**
         * Properties of a LoginResponse.
         * @memberof api
         * @interface ILoginResponse
         * @property {entities.UserLoginResult|null} [status] LoginResponse status
         * @property {Long|null} [userId] LoginResponse userId
         * @property {Long|null} [nextTryAtTimestamp] LoginResponse nextTryAtTimestamp
         */

        /**
         * Constructs a new LoginResponse.
         * @memberof api
         * @classdesc Represents a LoginResponse.
         * @implements ILoginResponse
         * @constructor
         * @param {api.ILoginResponse=} [properties] Properties to set
         */
        function LoginResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginResponse status.
         * @member {entities.UserLoginResult} status
         * @memberof api.LoginResponse
         * @instance
         */
        LoginResponse.prototype.status = 0;

        /**
         * LoginResponse userId.
         * @member {Long} userId
         * @memberof api.LoginResponse
         * @instance
         */
        LoginResponse.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * LoginResponse nextTryAtTimestamp.
         * @member {Long} nextTryAtTimestamp
         * @memberof api.LoginResponse
         * @instance
         */
        LoginResponse.prototype.nextTryAtTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new LoginResponse instance using the specified properties.
         * @function create
         * @memberof api.LoginResponse
         * @static
         * @param {api.ILoginResponse=} [properties] Properties to set
         * @returns {api.LoginResponse} LoginResponse instance
         */
        LoginResponse.create = function create(properties) {
            return new LoginResponse(properties);
        };

        /**
         * Encodes the specified LoginResponse message. Does not implicitly {@link api.LoginResponse.verify|verify} messages.
         * @function encode
         * @memberof api.LoginResponse
         * @static
         * @param {api.ILoginResponse} message LoginResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.userId);
            if (message.nextTryAtTimestamp != null && Object.hasOwnProperty.call(message, "nextTryAtTimestamp"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.nextTryAtTimestamp);
            return writer;
        };

        /**
         * Encodes the specified LoginResponse message, length delimited. Does not implicitly {@link api.LoginResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.LoginResponse
         * @static
         * @param {api.ILoginResponse} message LoginResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.LoginResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.LoginResponse} LoginResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.LoginResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.userId = reader.uint64();
                        break;
                    }
                case 3: {
                        message.nextTryAtTimestamp = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.LoginResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.LoginResponse} LoginResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginResponse message.
         * @function verify
         * @memberof api.LoginResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isInteger(message.userId) && !(message.userId && $util.isInteger(message.userId.low) && $util.isInteger(message.userId.high)))
                    return "userId: integer|Long expected";
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (!$util.isInteger(message.nextTryAtTimestamp) && !(message.nextTryAtTimestamp && $util.isInteger(message.nextTryAtTimestamp.low) && $util.isInteger(message.nextTryAtTimestamp.high)))
                    return "nextTryAtTimestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates a LoginResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.LoginResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.LoginResponse} LoginResponse
         */
        LoginResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.LoginResponse)
                return object;
            let message = new $root.api.LoginResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "LS_OK":
            case 0:
                message.status = 0;
                break;
            case "LS_INVALID_CREDENTIALS":
            case 1:
                message.status = 1;
                break;
            case "LS_PENDING_STAMP":
            case 2:
                message.status = 2;
                break;
            }
            if (object.userId != null)
                if ($util.Long)
                    (message.userId = $util.Long.fromValue(object.userId)).unsigned = true;
                else if (typeof object.userId === "string")
                    message.userId = parseInt(object.userId, 10);
                else if (typeof object.userId === "number")
                    message.userId = object.userId;
                else if (typeof object.userId === "object")
                    message.userId = new $util.LongBits(object.userId.low >>> 0, object.userId.high >>> 0).toNumber(true);
            if (object.nextTryAtTimestamp != null)
                if ($util.Long)
                    (message.nextTryAtTimestamp = $util.Long.fromValue(object.nextTryAtTimestamp)).unsigned = true;
                else if (typeof object.nextTryAtTimestamp === "string")
                    message.nextTryAtTimestamp = parseInt(object.nextTryAtTimestamp, 10);
                else if (typeof object.nextTryAtTimestamp === "number")
                    message.nextTryAtTimestamp = object.nextTryAtTimestamp;
                else if (typeof object.nextTryAtTimestamp === "object")
                    message.nextTryAtTimestamp = new $util.LongBits(object.nextTryAtTimestamp.low >>> 0, object.nextTryAtTimestamp.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a LoginResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.LoginResponse
         * @static
         * @param {api.LoginResponse} message LoginResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "LS_OK" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.userId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.userId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.nextTryAtTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nextTryAtTimestamp = options.longs === String ? "0" : 0;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.entities.UserLoginResult[message.status] === undefined ? message.status : $root.entities.UserLoginResult[message.status] : message.status;
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (typeof message.userId === "number")
                    object.userId = options.longs === String ? String(message.userId) : message.userId;
                else
                    object.userId = options.longs === String ? $util.Long.prototype.toString.call(message.userId) : options.longs === Number ? new $util.LongBits(message.userId.low >>> 0, message.userId.high >>> 0).toNumber(true) : message.userId;
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (typeof message.nextTryAtTimestamp === "number")
                    object.nextTryAtTimestamp = options.longs === String ? String(message.nextTryAtTimestamp) : message.nextTryAtTimestamp;
                else
                    object.nextTryAtTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.nextTryAtTimestamp) : options.longs === Number ? new $util.LongBits(message.nextTryAtTimestamp.low >>> 0, message.nextTryAtTimestamp.high >>> 0).toNumber(true) : message.nextTryAtTimestamp;
            return object;
        };

        /**
         * Converts this LoginResponse to JSON.
         * @function toJSON
         * @memberof api.LoginResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginResponse
         * @function getTypeUrl
         * @memberof api.LoginResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.LoginResponse";
        };

        return LoginResponse;
    })();

    api.RegisterRequest = (function() {

        /**
         * Properties of a RegisterRequest.
         * @memberof api
         * @interface IRegisterRequest
         * @property {entities.AuthType|null} [type] RegisterRequest type
         * @property {string|null} [email] RegisterRequest email
         * @property {string|null} [password] RegisterRequest password
         * @property {string|null} [passwordConfirm] RegisterRequest passwordConfirm
         * @property {boolean|null} [termsAccepted] RegisterRequest termsAccepted
         * @property {Uint8Array|null} [inviteID] RegisterRequest inviteID
         */

        /**
         * Constructs a new RegisterRequest.
         * @memberof api
         * @classdesc Represents a RegisterRequest.
         * @implements IRegisterRequest
         * @constructor
         * @param {api.IRegisterRequest=} [properties] Properties to set
         */
        function RegisterRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegisterRequest type.
         * @member {entities.AuthType} type
         * @memberof api.RegisterRequest
         * @instance
         */
        RegisterRequest.prototype.type = 0;

        /**
         * RegisterRequest email.
         * @member {string} email
         * @memberof api.RegisterRequest
         * @instance
         */
        RegisterRequest.prototype.email = "";

        /**
         * RegisterRequest password.
         * @member {string} password
         * @memberof api.RegisterRequest
         * @instance
         */
        RegisterRequest.prototype.password = "";

        /**
         * RegisterRequest passwordConfirm.
         * @member {string} passwordConfirm
         * @memberof api.RegisterRequest
         * @instance
         */
        RegisterRequest.prototype.passwordConfirm = "";

        /**
         * RegisterRequest termsAccepted.
         * @member {boolean} termsAccepted
         * @memberof api.RegisterRequest
         * @instance
         */
        RegisterRequest.prototype.termsAccepted = false;

        /**
         * RegisterRequest inviteID.
         * @member {Uint8Array} inviteID
         * @memberof api.RegisterRequest
         * @instance
         */
        RegisterRequest.prototype.inviteID = $util.newBuffer([]);

        /**
         * Creates a new RegisterRequest instance using the specified properties.
         * @function create
         * @memberof api.RegisterRequest
         * @static
         * @param {api.IRegisterRequest=} [properties] Properties to set
         * @returns {api.RegisterRequest} RegisterRequest instance
         */
        RegisterRequest.create = function create(properties) {
            return new RegisterRequest(properties);
        };

        /**
         * Encodes the specified RegisterRequest message. Does not implicitly {@link api.RegisterRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RegisterRequest
         * @static
         * @param {api.IRegisterRequest} message RegisterRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.email);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.password);
            if (message.passwordConfirm != null && Object.hasOwnProperty.call(message, "passwordConfirm"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.passwordConfirm);
            if (message.termsAccepted != null && Object.hasOwnProperty.call(message, "termsAccepted"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.termsAccepted);
            if (message.inviteID != null && Object.hasOwnProperty.call(message, "inviteID"))
                writer.uint32(/* id 14, wireType 2 =*/114).bytes(message.inviteID);
            return writer;
        };

        /**
         * Encodes the specified RegisterRequest message, length delimited. Does not implicitly {@link api.RegisterRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RegisterRequest
         * @static
         * @param {api.IRegisterRequest} message RegisterRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RegisterRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RegisterRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RegisterRequest} RegisterRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RegisterRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 10: {
                        message.email = reader.string();
                        break;
                    }
                case 11: {
                        message.password = reader.string();
                        break;
                    }
                case 12: {
                        message.passwordConfirm = reader.string();
                        break;
                    }
                case 13: {
                        message.termsAccepted = reader.bool();
                        break;
                    }
                case 14: {
                        message.inviteID = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RegisterRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RegisterRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RegisterRequest} RegisterRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RegisterRequest message.
         * @function verify
         * @memberof api.RegisterRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RegisterRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                    break;
                }
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            if (message.passwordConfirm != null && message.hasOwnProperty("passwordConfirm"))
                if (!$util.isString(message.passwordConfirm))
                    return "passwordConfirm: string expected";
            if (message.termsAccepted != null && message.hasOwnProperty("termsAccepted"))
                if (typeof message.termsAccepted !== "boolean")
                    return "termsAccepted: boolean expected";
            if (message.inviteID != null && message.hasOwnProperty("inviteID"))
                if (!(message.inviteID && typeof message.inviteID.length === "number" || $util.isString(message.inviteID)))
                    return "inviteID: buffer expected";
            return null;
        };

        /**
         * Creates a RegisterRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RegisterRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RegisterRequest} RegisterRequest
         */
        RegisterRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RegisterRequest)
                return object;
            let message = new $root.api.RegisterRequest();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "AT_EMAIL":
            case 0:
                message.type = 0;
                break;
            }
            if (object.email != null)
                message.email = String(object.email);
            if (object.password != null)
                message.password = String(object.password);
            if (object.passwordConfirm != null)
                message.passwordConfirm = String(object.passwordConfirm);
            if (object.termsAccepted != null)
                message.termsAccepted = Boolean(object.termsAccepted);
            if (object.inviteID != null)
                if (typeof object.inviteID === "string")
                    $util.base64.decode(object.inviteID, message.inviteID = $util.newBuffer($util.base64.length(object.inviteID)), 0);
                else if (object.inviteID.length >= 0)
                    message.inviteID = object.inviteID;
            return message;
        };

        /**
         * Creates a plain object from a RegisterRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RegisterRequest
         * @static
         * @param {api.RegisterRequest} message RegisterRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RegisterRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "AT_EMAIL" : 0;
                object.email = "";
                object.password = "";
                object.passwordConfirm = "";
                object.termsAccepted = false;
                if (options.bytes === String)
                    object.inviteID = "";
                else {
                    object.inviteID = [];
                    if (options.bytes !== Array)
                        object.inviteID = $util.newBuffer(object.inviteID);
                }
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.entities.AuthType[message.type] === undefined ? message.type : $root.entities.AuthType[message.type] : message.type;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            if (message.passwordConfirm != null && message.hasOwnProperty("passwordConfirm"))
                object.passwordConfirm = message.passwordConfirm;
            if (message.termsAccepted != null && message.hasOwnProperty("termsAccepted"))
                object.termsAccepted = message.termsAccepted;
            if (message.inviteID != null && message.hasOwnProperty("inviteID"))
                object.inviteID = options.bytes === String ? $util.base64.encode(message.inviteID, 0, message.inviteID.length) : options.bytes === Array ? Array.prototype.slice.call(message.inviteID) : message.inviteID;
            return object;
        };

        /**
         * Converts this RegisterRequest to JSON.
         * @function toJSON
         * @memberof api.RegisterRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RegisterRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RegisterRequest
         * @function getTypeUrl
         * @memberof api.RegisterRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RegisterRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RegisterRequest";
        };

        return RegisterRequest;
    })();

    api.RegisterResponse = (function() {

        /**
         * Properties of a RegisterResponse.
         * @memberof api
         * @interface IRegisterResponse
         * @property {entities.UserRegisterResult|null} [status] RegisterResponse status
         * @property {Array.<string>|null} [allowedDomains] RegisterResponse allowedDomains
         * @property {Long|null} [nextTryAtTimestamp] RegisterResponse nextTryAtTimestamp
         */

        /**
         * Constructs a new RegisterResponse.
         * @memberof api
         * @classdesc Represents a RegisterResponse.
         * @implements IRegisterResponse
         * @constructor
         * @param {api.IRegisterResponse=} [properties] Properties to set
         */
        function RegisterResponse(properties) {
            this.allowedDomains = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegisterResponse status.
         * @member {entities.UserRegisterResult} status
         * @memberof api.RegisterResponse
         * @instance
         */
        RegisterResponse.prototype.status = 0;

        /**
         * RegisterResponse allowedDomains.
         * @member {Array.<string>} allowedDomains
         * @memberof api.RegisterResponse
         * @instance
         */
        RegisterResponse.prototype.allowedDomains = $util.emptyArray;

        /**
         * RegisterResponse nextTryAtTimestamp.
         * @member {Long} nextTryAtTimestamp
         * @memberof api.RegisterResponse
         * @instance
         */
        RegisterResponse.prototype.nextTryAtTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new RegisterResponse instance using the specified properties.
         * @function create
         * @memberof api.RegisterResponse
         * @static
         * @param {api.IRegisterResponse=} [properties] Properties to set
         * @returns {api.RegisterResponse} RegisterResponse instance
         */
        RegisterResponse.create = function create(properties) {
            return new RegisterResponse(properties);
        };

        /**
         * Encodes the specified RegisterResponse message. Does not implicitly {@link api.RegisterResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RegisterResponse
         * @static
         * @param {api.IRegisterResponse} message RegisterResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.allowedDomains != null && message.allowedDomains.length)
                for (let i = 0; i < message.allowedDomains.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.allowedDomains[i]);
            if (message.nextTryAtTimestamp != null && Object.hasOwnProperty.call(message, "nextTryAtTimestamp"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.nextTryAtTimestamp);
            return writer;
        };

        /**
         * Encodes the specified RegisterResponse message, length delimited. Does not implicitly {@link api.RegisterResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RegisterResponse
         * @static
         * @param {api.IRegisterResponse} message RegisterResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RegisterResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RegisterResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RegisterResponse} RegisterResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RegisterResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        if (!(message.allowedDomains && message.allowedDomains.length))
                            message.allowedDomains = [];
                        message.allowedDomains.push(reader.string());
                        break;
                    }
                case 3: {
                        message.nextTryAtTimestamp = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RegisterResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RegisterResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RegisterResponse} RegisterResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RegisterResponse message.
         * @function verify
         * @memberof api.RegisterResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RegisterResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                    break;
                }
            if (message.allowedDomains != null && message.hasOwnProperty("allowedDomains")) {
                if (!Array.isArray(message.allowedDomains))
                    return "allowedDomains: array expected";
                for (let i = 0; i < message.allowedDomains.length; ++i)
                    if (!$util.isString(message.allowedDomains[i]))
                        return "allowedDomains: string[] expected";
            }
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (!$util.isInteger(message.nextTryAtTimestamp) && !(message.nextTryAtTimestamp && $util.isInteger(message.nextTryAtTimestamp.low) && $util.isInteger(message.nextTryAtTimestamp.high)))
                    return "nextTryAtTimestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates a RegisterResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RegisterResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RegisterResponse} RegisterResponse
         */
        RegisterResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RegisterResponse)
                return object;
            let message = new $root.api.RegisterResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "RS_OK":
            case 0:
                message.status = 0;
                break;
            case "RS_INVALID_EMAIL":
            case 1:
                message.status = 1;
                break;
            case "RS_EMAIL_USED":
            case 2:
                message.status = 2;
                break;
            case "RS_PASSWORD_TOO_SHORT":
            case 3:
                message.status = 3;
                break;
            case "RS_ONLY_SPECIFIC_DOMAINS":
            case 4:
                message.status = 4;
                break;
            case "RS_PASSWORDS_MISMATCH":
            case 5:
                message.status = 5;
                break;
            case "RS_TERMS_NOT_ACCEPTED":
            case 6:
                message.status = 6;
                break;
            case "RS_PENDING_STAMP":
            case 7:
                message.status = 7;
                break;
            case "RS_ALREADY_REGISTERED":
            case 8:
                message.status = 8;
                break;
            case "RS_INVALID_INVITE":
            case 9:
                message.status = 9;
                break;
            case "RS_UPGRADE_REQUIRED":
            case 10:
                message.status = 10;
                break;
            }
            if (object.allowedDomains) {
                if (!Array.isArray(object.allowedDomains))
                    throw TypeError(".api.RegisterResponse.allowedDomains: array expected");
                message.allowedDomains = [];
                for (let i = 0; i < object.allowedDomains.length; ++i)
                    message.allowedDomains[i] = String(object.allowedDomains[i]);
            }
            if (object.nextTryAtTimestamp != null)
                if ($util.Long)
                    (message.nextTryAtTimestamp = $util.Long.fromValue(object.nextTryAtTimestamp)).unsigned = true;
                else if (typeof object.nextTryAtTimestamp === "string")
                    message.nextTryAtTimestamp = parseInt(object.nextTryAtTimestamp, 10);
                else if (typeof object.nextTryAtTimestamp === "number")
                    message.nextTryAtTimestamp = object.nextTryAtTimestamp;
                else if (typeof object.nextTryAtTimestamp === "object")
                    message.nextTryAtTimestamp = new $util.LongBits(object.nextTryAtTimestamp.low >>> 0, object.nextTryAtTimestamp.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a RegisterResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RegisterResponse
         * @static
         * @param {api.RegisterResponse} message RegisterResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RegisterResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.allowedDomains = [];
            if (options.defaults) {
                object.status = options.enums === String ? "RS_OK" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.nextTryAtTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nextTryAtTimestamp = options.longs === String ? "0" : 0;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.entities.UserRegisterResult[message.status] === undefined ? message.status : $root.entities.UserRegisterResult[message.status] : message.status;
            if (message.allowedDomains && message.allowedDomains.length) {
                object.allowedDomains = [];
                for (let j = 0; j < message.allowedDomains.length; ++j)
                    object.allowedDomains[j] = message.allowedDomains[j];
            }
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (typeof message.nextTryAtTimestamp === "number")
                    object.nextTryAtTimestamp = options.longs === String ? String(message.nextTryAtTimestamp) : message.nextTryAtTimestamp;
                else
                    object.nextTryAtTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.nextTryAtTimestamp) : options.longs === Number ? new $util.LongBits(message.nextTryAtTimestamp.low >>> 0, message.nextTryAtTimestamp.high >>> 0).toNumber(true) : message.nextTryAtTimestamp;
            return object;
        };

        /**
         * Converts this RegisterResponse to JSON.
         * @function toJSON
         * @memberof api.RegisterResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RegisterResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RegisterResponse
         * @function getTypeUrl
         * @memberof api.RegisterResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RegisterResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RegisterResponse";
        };

        return RegisterResponse;
    })();

    api.ForgotPasswordRequest = (function() {

        /**
         * Properties of a ForgotPasswordRequest.
         * @memberof api
         * @interface IForgotPasswordRequest
         * @property {string|null} [email] ForgotPasswordRequest email
         */

        /**
         * Constructs a new ForgotPasswordRequest.
         * @memberof api
         * @classdesc Represents a ForgotPasswordRequest.
         * @implements IForgotPasswordRequest
         * @constructor
         * @param {api.IForgotPasswordRequest=} [properties] Properties to set
         */
        function ForgotPasswordRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ForgotPasswordRequest email.
         * @member {string} email
         * @memberof api.ForgotPasswordRequest
         * @instance
         */
        ForgotPasswordRequest.prototype.email = "";

        /**
         * Creates a new ForgotPasswordRequest instance using the specified properties.
         * @function create
         * @memberof api.ForgotPasswordRequest
         * @static
         * @param {api.IForgotPasswordRequest=} [properties] Properties to set
         * @returns {api.ForgotPasswordRequest} ForgotPasswordRequest instance
         */
        ForgotPasswordRequest.create = function create(properties) {
            return new ForgotPasswordRequest(properties);
        };

        /**
         * Encodes the specified ForgotPasswordRequest message. Does not implicitly {@link api.ForgotPasswordRequest.verify|verify} messages.
         * @function encode
         * @memberof api.ForgotPasswordRequest
         * @static
         * @param {api.IForgotPasswordRequest} message ForgotPasswordRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForgotPasswordRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
            return writer;
        };

        /**
         * Encodes the specified ForgotPasswordRequest message, length delimited. Does not implicitly {@link api.ForgotPasswordRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ForgotPasswordRequest
         * @static
         * @param {api.IForgotPasswordRequest} message ForgotPasswordRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForgotPasswordRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ForgotPasswordRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.ForgotPasswordRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ForgotPasswordRequest} ForgotPasswordRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForgotPasswordRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ForgotPasswordRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.email = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ForgotPasswordRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ForgotPasswordRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ForgotPasswordRequest} ForgotPasswordRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForgotPasswordRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ForgotPasswordRequest message.
         * @function verify
         * @memberof api.ForgotPasswordRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ForgotPasswordRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            return null;
        };

        /**
         * Creates a ForgotPasswordRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ForgotPasswordRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ForgotPasswordRequest} ForgotPasswordRequest
         */
        ForgotPasswordRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ForgotPasswordRequest)
                return object;
            let message = new $root.api.ForgotPasswordRequest();
            if (object.email != null)
                message.email = String(object.email);
            return message;
        };

        /**
         * Creates a plain object from a ForgotPasswordRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ForgotPasswordRequest
         * @static
         * @param {api.ForgotPasswordRequest} message ForgotPasswordRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ForgotPasswordRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.email = "";
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            return object;
        };

        /**
         * Converts this ForgotPasswordRequest to JSON.
         * @function toJSON
         * @memberof api.ForgotPasswordRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ForgotPasswordRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ForgotPasswordRequest
         * @function getTypeUrl
         * @memberof api.ForgotPasswordRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ForgotPasswordRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ForgotPasswordRequest";
        };

        return ForgotPasswordRequest;
    })();

    api.ForgotPasswordResponse = (function() {

        /**
         * Properties of a ForgotPasswordResponse.
         * @memberof api
         * @interface IForgotPasswordResponse
         * @property {entities.RestorePasswordResult|null} [status] ForgotPasswordResponse status
         * @property {Long|null} [nextTryAtTimestamp] ForgotPasswordResponse nextTryAtTimestamp
         */

        /**
         * Constructs a new ForgotPasswordResponse.
         * @memberof api
         * @classdesc Represents a ForgotPasswordResponse.
         * @implements IForgotPasswordResponse
         * @constructor
         * @param {api.IForgotPasswordResponse=} [properties] Properties to set
         */
        function ForgotPasswordResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ForgotPasswordResponse status.
         * @member {entities.RestorePasswordResult} status
         * @memberof api.ForgotPasswordResponse
         * @instance
         */
        ForgotPasswordResponse.prototype.status = 0;

        /**
         * ForgotPasswordResponse nextTryAtTimestamp.
         * @member {Long} nextTryAtTimestamp
         * @memberof api.ForgotPasswordResponse
         * @instance
         */
        ForgotPasswordResponse.prototype.nextTryAtTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ForgotPasswordResponse instance using the specified properties.
         * @function create
         * @memberof api.ForgotPasswordResponse
         * @static
         * @param {api.IForgotPasswordResponse=} [properties] Properties to set
         * @returns {api.ForgotPasswordResponse} ForgotPasswordResponse instance
         */
        ForgotPasswordResponse.create = function create(properties) {
            return new ForgotPasswordResponse(properties);
        };

        /**
         * Encodes the specified ForgotPasswordResponse message. Does not implicitly {@link api.ForgotPasswordResponse.verify|verify} messages.
         * @function encode
         * @memberof api.ForgotPasswordResponse
         * @static
         * @param {api.IForgotPasswordResponse} message ForgotPasswordResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForgotPasswordResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.nextTryAtTimestamp != null && Object.hasOwnProperty.call(message, "nextTryAtTimestamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.nextTryAtTimestamp);
            return writer;
        };

        /**
         * Encodes the specified ForgotPasswordResponse message, length delimited. Does not implicitly {@link api.ForgotPasswordResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ForgotPasswordResponse
         * @static
         * @param {api.IForgotPasswordResponse} message ForgotPasswordResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForgotPasswordResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ForgotPasswordResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.ForgotPasswordResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ForgotPasswordResponse} ForgotPasswordResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForgotPasswordResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ForgotPasswordResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.nextTryAtTimestamp = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ForgotPasswordResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ForgotPasswordResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ForgotPasswordResponse} ForgotPasswordResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForgotPasswordResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ForgotPasswordResponse message.
         * @function verify
         * @memberof api.ForgotPasswordResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ForgotPasswordResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (!$util.isInteger(message.nextTryAtTimestamp) && !(message.nextTryAtTimestamp && $util.isInteger(message.nextTryAtTimestamp.low) && $util.isInteger(message.nextTryAtTimestamp.high)))
                    return "nextTryAtTimestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates a ForgotPasswordResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ForgotPasswordResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ForgotPasswordResponse} ForgotPasswordResponse
         */
        ForgotPasswordResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ForgotPasswordResponse)
                return object;
            let message = new $root.api.ForgotPasswordResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "RPR_CODE_SENT":
            case 0:
                message.status = 0;
                break;
            case "RPR_PENDING_STAMP":
            case 1:
                message.status = 1;
                break;
            case "RPR_INVALID_EMAIL":
            case 2:
                message.status = 2;
                break;
            case "RPR_UNKNOWN_EMAIL":
            case 3:
                message.status = 3;
                break;
            }
            if (object.nextTryAtTimestamp != null)
                if ($util.Long)
                    (message.nextTryAtTimestamp = $util.Long.fromValue(object.nextTryAtTimestamp)).unsigned = true;
                else if (typeof object.nextTryAtTimestamp === "string")
                    message.nextTryAtTimestamp = parseInt(object.nextTryAtTimestamp, 10);
                else if (typeof object.nextTryAtTimestamp === "number")
                    message.nextTryAtTimestamp = object.nextTryAtTimestamp;
                else if (typeof object.nextTryAtTimestamp === "object")
                    message.nextTryAtTimestamp = new $util.LongBits(object.nextTryAtTimestamp.low >>> 0, object.nextTryAtTimestamp.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a ForgotPasswordResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ForgotPasswordResponse
         * @static
         * @param {api.ForgotPasswordResponse} message ForgotPasswordResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ForgotPasswordResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "RPR_CODE_SENT" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.nextTryAtTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nextTryAtTimestamp = options.longs === String ? "0" : 0;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.entities.RestorePasswordResult[message.status] === undefined ? message.status : $root.entities.RestorePasswordResult[message.status] : message.status;
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (typeof message.nextTryAtTimestamp === "number")
                    object.nextTryAtTimestamp = options.longs === String ? String(message.nextTryAtTimestamp) : message.nextTryAtTimestamp;
                else
                    object.nextTryAtTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.nextTryAtTimestamp) : options.longs === Number ? new $util.LongBits(message.nextTryAtTimestamp.low >>> 0, message.nextTryAtTimestamp.high >>> 0).toNumber(true) : message.nextTryAtTimestamp;
            return object;
        };

        /**
         * Converts this ForgotPasswordResponse to JSON.
         * @function toJSON
         * @memberof api.ForgotPasswordResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ForgotPasswordResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ForgotPasswordResponse
         * @function getTypeUrl
         * @memberof api.ForgotPasswordResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ForgotPasswordResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ForgotPasswordResponse";
        };

        return ForgotPasswordResponse;
    })();

    api.ConfirmEmailRequest = (function() {

        /**
         * Properties of a ConfirmEmailRequest.
         * @memberof api
         * @interface IConfirmEmailRequest
         * @property {string|null} [code] ConfirmEmailRequest code
         */

        /**
         * Constructs a new ConfirmEmailRequest.
         * @memberof api
         * @classdesc Represents a ConfirmEmailRequest.
         * @implements IConfirmEmailRequest
         * @constructor
         * @param {api.IConfirmEmailRequest=} [properties] Properties to set
         */
        function ConfirmEmailRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConfirmEmailRequest code.
         * @member {string} code
         * @memberof api.ConfirmEmailRequest
         * @instance
         */
        ConfirmEmailRequest.prototype.code = "";

        /**
         * Creates a new ConfirmEmailRequest instance using the specified properties.
         * @function create
         * @memberof api.ConfirmEmailRequest
         * @static
         * @param {api.IConfirmEmailRequest=} [properties] Properties to set
         * @returns {api.ConfirmEmailRequest} ConfirmEmailRequest instance
         */
        ConfirmEmailRequest.create = function create(properties) {
            return new ConfirmEmailRequest(properties);
        };

        /**
         * Encodes the specified ConfirmEmailRequest message. Does not implicitly {@link api.ConfirmEmailRequest.verify|verify} messages.
         * @function encode
         * @memberof api.ConfirmEmailRequest
         * @static
         * @param {api.IConfirmEmailRequest} message ConfirmEmailRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfirmEmailRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
            return writer;
        };

        /**
         * Encodes the specified ConfirmEmailRequest message, length delimited. Does not implicitly {@link api.ConfirmEmailRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ConfirmEmailRequest
         * @static
         * @param {api.IConfirmEmailRequest} message ConfirmEmailRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfirmEmailRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConfirmEmailRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.ConfirmEmailRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ConfirmEmailRequest} ConfirmEmailRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfirmEmailRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ConfirmEmailRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConfirmEmailRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ConfirmEmailRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ConfirmEmailRequest} ConfirmEmailRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfirmEmailRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConfirmEmailRequest message.
         * @function verify
         * @memberof api.ConfirmEmailRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConfirmEmailRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            return null;
        };

        /**
         * Creates a ConfirmEmailRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ConfirmEmailRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ConfirmEmailRequest} ConfirmEmailRequest
         */
        ConfirmEmailRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ConfirmEmailRequest)
                return object;
            let message = new $root.api.ConfirmEmailRequest();
            if (object.code != null)
                message.code = String(object.code);
            return message;
        };

        /**
         * Creates a plain object from a ConfirmEmailRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ConfirmEmailRequest
         * @static
         * @param {api.ConfirmEmailRequest} message ConfirmEmailRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfirmEmailRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.code = "";
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            return object;
        };

        /**
         * Converts this ConfirmEmailRequest to JSON.
         * @function toJSON
         * @memberof api.ConfirmEmailRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfirmEmailRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConfirmEmailRequest
         * @function getTypeUrl
         * @memberof api.ConfirmEmailRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConfirmEmailRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ConfirmEmailRequest";
        };

        return ConfirmEmailRequest;
    })();

    api.ConfirmEmailResponse = (function() {

        /**
         * Properties of a ConfirmEmailResponse.
         * @memberof api
         * @interface IConfirmEmailResponse
         * @property {entities.ConfirmCredsResult|null} [status] ConfirmEmailResponse status
         * @property {Long|null} [nextTryAtTimestamp] ConfirmEmailResponse nextTryAtTimestamp
         */

        /**
         * Constructs a new ConfirmEmailResponse.
         * @memberof api
         * @classdesc Represents a ConfirmEmailResponse.
         * @implements IConfirmEmailResponse
         * @constructor
         * @param {api.IConfirmEmailResponse=} [properties] Properties to set
         */
        function ConfirmEmailResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConfirmEmailResponse status.
         * @member {entities.ConfirmCredsResult} status
         * @memberof api.ConfirmEmailResponse
         * @instance
         */
        ConfirmEmailResponse.prototype.status = 0;

        /**
         * ConfirmEmailResponse nextTryAtTimestamp.
         * @member {Long} nextTryAtTimestamp
         * @memberof api.ConfirmEmailResponse
         * @instance
         */
        ConfirmEmailResponse.prototype.nextTryAtTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ConfirmEmailResponse instance using the specified properties.
         * @function create
         * @memberof api.ConfirmEmailResponse
         * @static
         * @param {api.IConfirmEmailResponse=} [properties] Properties to set
         * @returns {api.ConfirmEmailResponse} ConfirmEmailResponse instance
         */
        ConfirmEmailResponse.create = function create(properties) {
            return new ConfirmEmailResponse(properties);
        };

        /**
         * Encodes the specified ConfirmEmailResponse message. Does not implicitly {@link api.ConfirmEmailResponse.verify|verify} messages.
         * @function encode
         * @memberof api.ConfirmEmailResponse
         * @static
         * @param {api.IConfirmEmailResponse} message ConfirmEmailResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfirmEmailResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.nextTryAtTimestamp != null && Object.hasOwnProperty.call(message, "nextTryAtTimestamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.nextTryAtTimestamp);
            return writer;
        };

        /**
         * Encodes the specified ConfirmEmailResponse message, length delimited. Does not implicitly {@link api.ConfirmEmailResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ConfirmEmailResponse
         * @static
         * @param {api.IConfirmEmailResponse} message ConfirmEmailResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfirmEmailResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConfirmEmailResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.ConfirmEmailResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ConfirmEmailResponse} ConfirmEmailResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfirmEmailResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ConfirmEmailResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.nextTryAtTimestamp = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConfirmEmailResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ConfirmEmailResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ConfirmEmailResponse} ConfirmEmailResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfirmEmailResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConfirmEmailResponse message.
         * @function verify
         * @memberof api.ConfirmEmailResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConfirmEmailResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (!$util.isInteger(message.nextTryAtTimestamp) && !(message.nextTryAtTimestamp && $util.isInteger(message.nextTryAtTimestamp.low) && $util.isInteger(message.nextTryAtTimestamp.high)))
                    return "nextTryAtTimestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates a ConfirmEmailResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ConfirmEmailResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ConfirmEmailResponse} ConfirmEmailResponse
         */
        ConfirmEmailResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ConfirmEmailResponse)
                return object;
            let message = new $root.api.ConfirmEmailResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "CS_CONFIRMED":
            case 0:
                message.status = 0;
                break;
            case "CS_INVALID_CODE":
            case 1:
                message.status = 1;
                break;
            case "CS_PENDING_STAMP":
            case 2:
                message.status = 2;
                break;
            case "CS_INVALID_TOKEN":
            case 3:
                message.status = 3;
                break;
            case "CS_ALREADY_CONFIRMED":
            case 4:
                message.status = 4;
                break;
            case "CS_CODE_EXPIRED":
            case 5:
                message.status = 5;
                break;
            }
            if (object.nextTryAtTimestamp != null)
                if ($util.Long)
                    (message.nextTryAtTimestamp = $util.Long.fromValue(object.nextTryAtTimestamp)).unsigned = true;
                else if (typeof object.nextTryAtTimestamp === "string")
                    message.nextTryAtTimestamp = parseInt(object.nextTryAtTimestamp, 10);
                else if (typeof object.nextTryAtTimestamp === "number")
                    message.nextTryAtTimestamp = object.nextTryAtTimestamp;
                else if (typeof object.nextTryAtTimestamp === "object")
                    message.nextTryAtTimestamp = new $util.LongBits(object.nextTryAtTimestamp.low >>> 0, object.nextTryAtTimestamp.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a ConfirmEmailResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ConfirmEmailResponse
         * @static
         * @param {api.ConfirmEmailResponse} message ConfirmEmailResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfirmEmailResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "CS_CONFIRMED" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.nextTryAtTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nextTryAtTimestamp = options.longs === String ? "0" : 0;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.entities.ConfirmCredsResult[message.status] === undefined ? message.status : $root.entities.ConfirmCredsResult[message.status] : message.status;
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (typeof message.nextTryAtTimestamp === "number")
                    object.nextTryAtTimestamp = options.longs === String ? String(message.nextTryAtTimestamp) : message.nextTryAtTimestamp;
                else
                    object.nextTryAtTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.nextTryAtTimestamp) : options.longs === Number ? new $util.LongBits(message.nextTryAtTimestamp.low >>> 0, message.nextTryAtTimestamp.high >>> 0).toNumber(true) : message.nextTryAtTimestamp;
            return object;
        };

        /**
         * Converts this ConfirmEmailResponse to JSON.
         * @function toJSON
         * @memberof api.ConfirmEmailResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfirmEmailResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConfirmEmailResponse
         * @function getTypeUrl
         * @memberof api.ConfirmEmailResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConfirmEmailResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ConfirmEmailResponse";
        };

        return ConfirmEmailResponse;
    })();

    api.ResendConfirmEmailRequest = (function() {

        /**
         * Properties of a ResendConfirmEmailRequest.
         * @memberof api
         * @interface IResendConfirmEmailRequest
         * @property {boolean|null} [resend] ResendConfirmEmailRequest resend
         */

        /**
         * Constructs a new ResendConfirmEmailRequest.
         * @memberof api
         * @classdesc Represents a ResendConfirmEmailRequest.
         * @implements IResendConfirmEmailRequest
         * @constructor
         * @param {api.IResendConfirmEmailRequest=} [properties] Properties to set
         */
        function ResendConfirmEmailRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResendConfirmEmailRequest resend.
         * @member {boolean} resend
         * @memberof api.ResendConfirmEmailRequest
         * @instance
         */
        ResendConfirmEmailRequest.prototype.resend = false;

        /**
         * Creates a new ResendConfirmEmailRequest instance using the specified properties.
         * @function create
         * @memberof api.ResendConfirmEmailRequest
         * @static
         * @param {api.IResendConfirmEmailRequest=} [properties] Properties to set
         * @returns {api.ResendConfirmEmailRequest} ResendConfirmEmailRequest instance
         */
        ResendConfirmEmailRequest.create = function create(properties) {
            return new ResendConfirmEmailRequest(properties);
        };

        /**
         * Encodes the specified ResendConfirmEmailRequest message. Does not implicitly {@link api.ResendConfirmEmailRequest.verify|verify} messages.
         * @function encode
         * @memberof api.ResendConfirmEmailRequest
         * @static
         * @param {api.IResendConfirmEmailRequest} message ResendConfirmEmailRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResendConfirmEmailRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.resend != null && Object.hasOwnProperty.call(message, "resend"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.resend);
            return writer;
        };

        /**
         * Encodes the specified ResendConfirmEmailRequest message, length delimited. Does not implicitly {@link api.ResendConfirmEmailRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ResendConfirmEmailRequest
         * @static
         * @param {api.IResendConfirmEmailRequest} message ResendConfirmEmailRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResendConfirmEmailRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResendConfirmEmailRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.ResendConfirmEmailRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ResendConfirmEmailRequest} ResendConfirmEmailRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResendConfirmEmailRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ResendConfirmEmailRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.resend = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResendConfirmEmailRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ResendConfirmEmailRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ResendConfirmEmailRequest} ResendConfirmEmailRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResendConfirmEmailRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResendConfirmEmailRequest message.
         * @function verify
         * @memberof api.ResendConfirmEmailRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResendConfirmEmailRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.resend != null && message.hasOwnProperty("resend"))
                if (typeof message.resend !== "boolean")
                    return "resend: boolean expected";
            return null;
        };

        /**
         * Creates a ResendConfirmEmailRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ResendConfirmEmailRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ResendConfirmEmailRequest} ResendConfirmEmailRequest
         */
        ResendConfirmEmailRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ResendConfirmEmailRequest)
                return object;
            let message = new $root.api.ResendConfirmEmailRequest();
            if (object.resend != null)
                message.resend = Boolean(object.resend);
            return message;
        };

        /**
         * Creates a plain object from a ResendConfirmEmailRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ResendConfirmEmailRequest
         * @static
         * @param {api.ResendConfirmEmailRequest} message ResendConfirmEmailRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResendConfirmEmailRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.resend = false;
            if (message.resend != null && message.hasOwnProperty("resend"))
                object.resend = message.resend;
            return object;
        };

        /**
         * Converts this ResendConfirmEmailRequest to JSON.
         * @function toJSON
         * @memberof api.ResendConfirmEmailRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResendConfirmEmailRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResendConfirmEmailRequest
         * @function getTypeUrl
         * @memberof api.ResendConfirmEmailRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResendConfirmEmailRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ResendConfirmEmailRequest";
        };

        return ResendConfirmEmailRequest;
    })();

    api.ResendConfirmEmailResponse = (function() {

        /**
         * Properties of a ResendConfirmEmailResponse.
         * @memberof api
         * @interface IResendConfirmEmailResponse
         * @property {entities.ResendConfirmResult|null} [status] ResendConfirmEmailResponse status
         * @property {Long|null} [nextTryAtTimestamp] ResendConfirmEmailResponse nextTryAtTimestamp
         */

        /**
         * Constructs a new ResendConfirmEmailResponse.
         * @memberof api
         * @classdesc Represents a ResendConfirmEmailResponse.
         * @implements IResendConfirmEmailResponse
         * @constructor
         * @param {api.IResendConfirmEmailResponse=} [properties] Properties to set
         */
        function ResendConfirmEmailResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResendConfirmEmailResponse status.
         * @member {entities.ResendConfirmResult} status
         * @memberof api.ResendConfirmEmailResponse
         * @instance
         */
        ResendConfirmEmailResponse.prototype.status = 0;

        /**
         * ResendConfirmEmailResponse nextTryAtTimestamp.
         * @member {Long} nextTryAtTimestamp
         * @memberof api.ResendConfirmEmailResponse
         * @instance
         */
        ResendConfirmEmailResponse.prototype.nextTryAtTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ResendConfirmEmailResponse instance using the specified properties.
         * @function create
         * @memberof api.ResendConfirmEmailResponse
         * @static
         * @param {api.IResendConfirmEmailResponse=} [properties] Properties to set
         * @returns {api.ResendConfirmEmailResponse} ResendConfirmEmailResponse instance
         */
        ResendConfirmEmailResponse.create = function create(properties) {
            return new ResendConfirmEmailResponse(properties);
        };

        /**
         * Encodes the specified ResendConfirmEmailResponse message. Does not implicitly {@link api.ResendConfirmEmailResponse.verify|verify} messages.
         * @function encode
         * @memberof api.ResendConfirmEmailResponse
         * @static
         * @param {api.IResendConfirmEmailResponse} message ResendConfirmEmailResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResendConfirmEmailResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.nextTryAtTimestamp != null && Object.hasOwnProperty.call(message, "nextTryAtTimestamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.nextTryAtTimestamp);
            return writer;
        };

        /**
         * Encodes the specified ResendConfirmEmailResponse message, length delimited. Does not implicitly {@link api.ResendConfirmEmailResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ResendConfirmEmailResponse
         * @static
         * @param {api.IResendConfirmEmailResponse} message ResendConfirmEmailResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResendConfirmEmailResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResendConfirmEmailResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.ResendConfirmEmailResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ResendConfirmEmailResponse} ResendConfirmEmailResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResendConfirmEmailResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ResendConfirmEmailResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.nextTryAtTimestamp = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResendConfirmEmailResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ResendConfirmEmailResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ResendConfirmEmailResponse} ResendConfirmEmailResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResendConfirmEmailResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResendConfirmEmailResponse message.
         * @function verify
         * @memberof api.ResendConfirmEmailResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResendConfirmEmailResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (!$util.isInteger(message.nextTryAtTimestamp) && !(message.nextTryAtTimestamp && $util.isInteger(message.nextTryAtTimestamp.low) && $util.isInteger(message.nextTryAtTimestamp.high)))
                    return "nextTryAtTimestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates a ResendConfirmEmailResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ResendConfirmEmailResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ResendConfirmEmailResponse} ResendConfirmEmailResponse
         */
        ResendConfirmEmailResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ResendConfirmEmailResponse)
                return object;
            let message = new $root.api.ResendConfirmEmailResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "RCS_CODE_SENT":
            case 0:
                message.status = 0;
                break;
            case "RCS_PENDING_STAMP":
            case 1:
                message.status = 1;
                break;
            case "RCS_INVALID_TOKEN":
            case 2:
                message.status = 2;
                break;
            case "RCS_ALREADY_CONFIRMED":
            case 3:
                message.status = 3;
                break;
            }
            if (object.nextTryAtTimestamp != null)
                if ($util.Long)
                    (message.nextTryAtTimestamp = $util.Long.fromValue(object.nextTryAtTimestamp)).unsigned = true;
                else if (typeof object.nextTryAtTimestamp === "string")
                    message.nextTryAtTimestamp = parseInt(object.nextTryAtTimestamp, 10);
                else if (typeof object.nextTryAtTimestamp === "number")
                    message.nextTryAtTimestamp = object.nextTryAtTimestamp;
                else if (typeof object.nextTryAtTimestamp === "object")
                    message.nextTryAtTimestamp = new $util.LongBits(object.nextTryAtTimestamp.low >>> 0, object.nextTryAtTimestamp.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a ResendConfirmEmailResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ResendConfirmEmailResponse
         * @static
         * @param {api.ResendConfirmEmailResponse} message ResendConfirmEmailResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResendConfirmEmailResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "RCS_CODE_SENT" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.nextTryAtTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nextTryAtTimestamp = options.longs === String ? "0" : 0;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.entities.ResendConfirmResult[message.status] === undefined ? message.status : $root.entities.ResendConfirmResult[message.status] : message.status;
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (typeof message.nextTryAtTimestamp === "number")
                    object.nextTryAtTimestamp = options.longs === String ? String(message.nextTryAtTimestamp) : message.nextTryAtTimestamp;
                else
                    object.nextTryAtTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.nextTryAtTimestamp) : options.longs === Number ? new $util.LongBits(message.nextTryAtTimestamp.low >>> 0, message.nextTryAtTimestamp.high >>> 0).toNumber(true) : message.nextTryAtTimestamp;
            return object;
        };

        /**
         * Converts this ResendConfirmEmailResponse to JSON.
         * @function toJSON
         * @memberof api.ResendConfirmEmailResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResendConfirmEmailResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResendConfirmEmailResponse
         * @function getTypeUrl
         * @memberof api.ResendConfirmEmailResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResendConfirmEmailResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ResendConfirmEmailResponse";
        };

        return ResendConfirmEmailResponse;
    })();

    api.ChangeEmailRequest = (function() {

        /**
         * Properties of a ChangeEmailRequest.
         * @memberof api
         * @interface IChangeEmailRequest
         * @property {string|null} [newEmail] ChangeEmailRequest newEmail
         * @property {string|null} [password] ChangeEmailRequest password
         */

        /**
         * Constructs a new ChangeEmailRequest.
         * @memberof api
         * @classdesc Represents a ChangeEmailRequest.
         * @implements IChangeEmailRequest
         * @constructor
         * @param {api.IChangeEmailRequest=} [properties] Properties to set
         */
        function ChangeEmailRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangeEmailRequest newEmail.
         * @member {string} newEmail
         * @memberof api.ChangeEmailRequest
         * @instance
         */
        ChangeEmailRequest.prototype.newEmail = "";

        /**
         * ChangeEmailRequest password.
         * @member {string} password
         * @memberof api.ChangeEmailRequest
         * @instance
         */
        ChangeEmailRequest.prototype.password = "";

        /**
         * Creates a new ChangeEmailRequest instance using the specified properties.
         * @function create
         * @memberof api.ChangeEmailRequest
         * @static
         * @param {api.IChangeEmailRequest=} [properties] Properties to set
         * @returns {api.ChangeEmailRequest} ChangeEmailRequest instance
         */
        ChangeEmailRequest.create = function create(properties) {
            return new ChangeEmailRequest(properties);
        };

        /**
         * Encodes the specified ChangeEmailRequest message. Does not implicitly {@link api.ChangeEmailRequest.verify|verify} messages.
         * @function encode
         * @memberof api.ChangeEmailRequest
         * @static
         * @param {api.IChangeEmailRequest} message ChangeEmailRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeEmailRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.newEmail != null && Object.hasOwnProperty.call(message, "newEmail"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.newEmail);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            return writer;
        };

        /**
         * Encodes the specified ChangeEmailRequest message, length delimited. Does not implicitly {@link api.ChangeEmailRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ChangeEmailRequest
         * @static
         * @param {api.IChangeEmailRequest} message ChangeEmailRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeEmailRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChangeEmailRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.ChangeEmailRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ChangeEmailRequest} ChangeEmailRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeEmailRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ChangeEmailRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.newEmail = reader.string();
                        break;
                    }
                case 2: {
                        message.password = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChangeEmailRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ChangeEmailRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ChangeEmailRequest} ChangeEmailRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeEmailRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChangeEmailRequest message.
         * @function verify
         * @memberof api.ChangeEmailRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChangeEmailRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.newEmail != null && message.hasOwnProperty("newEmail"))
                if (!$util.isString(message.newEmail))
                    return "newEmail: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            return null;
        };

        /**
         * Creates a ChangeEmailRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ChangeEmailRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ChangeEmailRequest} ChangeEmailRequest
         */
        ChangeEmailRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ChangeEmailRequest)
                return object;
            let message = new $root.api.ChangeEmailRequest();
            if (object.newEmail != null)
                message.newEmail = String(object.newEmail);
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };

        /**
         * Creates a plain object from a ChangeEmailRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ChangeEmailRequest
         * @static
         * @param {api.ChangeEmailRequest} message ChangeEmailRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChangeEmailRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.newEmail = "";
                object.password = "";
            }
            if (message.newEmail != null && message.hasOwnProperty("newEmail"))
                object.newEmail = message.newEmail;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            return object;
        };

        /**
         * Converts this ChangeEmailRequest to JSON.
         * @function toJSON
         * @memberof api.ChangeEmailRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChangeEmailRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChangeEmailRequest
         * @function getTypeUrl
         * @memberof api.ChangeEmailRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChangeEmailRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ChangeEmailRequest";
        };

        return ChangeEmailRequest;
    })();

    api.ChangeEmailResponse = (function() {

        /**
         * Properties of a ChangeEmailResponse.
         * @memberof api
         * @interface IChangeEmailResponse
         * @property {entities.ChangeEmailResult|null} [status] ChangeEmailResponse status
         * @property {Long|null} [nextTryAtTimestamp] ChangeEmailResponse nextTryAtTimestamp
         */

        /**
         * Constructs a new ChangeEmailResponse.
         * @memberof api
         * @classdesc Represents a ChangeEmailResponse.
         * @implements IChangeEmailResponse
         * @constructor
         * @param {api.IChangeEmailResponse=} [properties] Properties to set
         */
        function ChangeEmailResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangeEmailResponse status.
         * @member {entities.ChangeEmailResult} status
         * @memberof api.ChangeEmailResponse
         * @instance
         */
        ChangeEmailResponse.prototype.status = 0;

        /**
         * ChangeEmailResponse nextTryAtTimestamp.
         * @member {Long} nextTryAtTimestamp
         * @memberof api.ChangeEmailResponse
         * @instance
         */
        ChangeEmailResponse.prototype.nextTryAtTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ChangeEmailResponse instance using the specified properties.
         * @function create
         * @memberof api.ChangeEmailResponse
         * @static
         * @param {api.IChangeEmailResponse=} [properties] Properties to set
         * @returns {api.ChangeEmailResponse} ChangeEmailResponse instance
         */
        ChangeEmailResponse.create = function create(properties) {
            return new ChangeEmailResponse(properties);
        };

        /**
         * Encodes the specified ChangeEmailResponse message. Does not implicitly {@link api.ChangeEmailResponse.verify|verify} messages.
         * @function encode
         * @memberof api.ChangeEmailResponse
         * @static
         * @param {api.IChangeEmailResponse} message ChangeEmailResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeEmailResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.nextTryAtTimestamp != null && Object.hasOwnProperty.call(message, "nextTryAtTimestamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.nextTryAtTimestamp);
            return writer;
        };

        /**
         * Encodes the specified ChangeEmailResponse message, length delimited. Does not implicitly {@link api.ChangeEmailResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ChangeEmailResponse
         * @static
         * @param {api.IChangeEmailResponse} message ChangeEmailResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeEmailResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChangeEmailResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.ChangeEmailResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ChangeEmailResponse} ChangeEmailResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeEmailResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ChangeEmailResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.nextTryAtTimestamp = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChangeEmailResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ChangeEmailResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ChangeEmailResponse} ChangeEmailResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeEmailResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChangeEmailResponse message.
         * @function verify
         * @memberof api.ChangeEmailResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChangeEmailResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (!$util.isInteger(message.nextTryAtTimestamp) && !(message.nextTryAtTimestamp && $util.isInteger(message.nextTryAtTimestamp.low) && $util.isInteger(message.nextTryAtTimestamp.high)))
                    return "nextTryAtTimestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates a ChangeEmailResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ChangeEmailResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ChangeEmailResponse} ChangeEmailResponse
         */
        ChangeEmailResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ChangeEmailResponse)
                return object;
            let message = new $root.api.ChangeEmailResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "CER_OK":
            case 0:
                message.status = 0;
                break;
            case "CER_INVALID_EMAIL":
            case 1:
                message.status = 1;
                break;
            case "CER_PENDING_STAMP":
            case 2:
                message.status = 2;
                break;
            case "CER_EMAIL_USED":
            case 3:
                message.status = 3;
                break;
            case "CER_NOT_REGISTERED":
            case 4:
                message.status = 4;
                break;
            case "CER_INVALID_PASSWORD":
            case 5:
                message.status = 5;
                break;
            }
            if (object.nextTryAtTimestamp != null)
                if ($util.Long)
                    (message.nextTryAtTimestamp = $util.Long.fromValue(object.nextTryAtTimestamp)).unsigned = true;
                else if (typeof object.nextTryAtTimestamp === "string")
                    message.nextTryAtTimestamp = parseInt(object.nextTryAtTimestamp, 10);
                else if (typeof object.nextTryAtTimestamp === "number")
                    message.nextTryAtTimestamp = object.nextTryAtTimestamp;
                else if (typeof object.nextTryAtTimestamp === "object")
                    message.nextTryAtTimestamp = new $util.LongBits(object.nextTryAtTimestamp.low >>> 0, object.nextTryAtTimestamp.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a ChangeEmailResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ChangeEmailResponse
         * @static
         * @param {api.ChangeEmailResponse} message ChangeEmailResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChangeEmailResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "CER_OK" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.nextTryAtTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nextTryAtTimestamp = options.longs === String ? "0" : 0;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.entities.ChangeEmailResult[message.status] === undefined ? message.status : $root.entities.ChangeEmailResult[message.status] : message.status;
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (typeof message.nextTryAtTimestamp === "number")
                    object.nextTryAtTimestamp = options.longs === String ? String(message.nextTryAtTimestamp) : message.nextTryAtTimestamp;
                else
                    object.nextTryAtTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.nextTryAtTimestamp) : options.longs === Number ? new $util.LongBits(message.nextTryAtTimestamp.low >>> 0, message.nextTryAtTimestamp.high >>> 0).toNumber(true) : message.nextTryAtTimestamp;
            return object;
        };

        /**
         * Converts this ChangeEmailResponse to JSON.
         * @function toJSON
         * @memberof api.ChangeEmailResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChangeEmailResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChangeEmailResponse
         * @function getTypeUrl
         * @memberof api.ChangeEmailResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChangeEmailResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ChangeEmailResponse";
        };

        return ChangeEmailResponse;
    })();

    api.ChangePasswordRequest = (function() {

        /**
         * Properties of a ChangePasswordRequest.
         * @memberof api
         * @interface IChangePasswordRequest
         * @property {string|null} [currentPassword] ChangePasswordRequest currentPassword
         * @property {string|null} [newPassword] ChangePasswordRequest newPassword
         */

        /**
         * Constructs a new ChangePasswordRequest.
         * @memberof api
         * @classdesc Represents a ChangePasswordRequest.
         * @implements IChangePasswordRequest
         * @constructor
         * @param {api.IChangePasswordRequest=} [properties] Properties to set
         */
        function ChangePasswordRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangePasswordRequest currentPassword.
         * @member {string} currentPassword
         * @memberof api.ChangePasswordRequest
         * @instance
         */
        ChangePasswordRequest.prototype.currentPassword = "";

        /**
         * ChangePasswordRequest newPassword.
         * @member {string} newPassword
         * @memberof api.ChangePasswordRequest
         * @instance
         */
        ChangePasswordRequest.prototype.newPassword = "";

        /**
         * Creates a new ChangePasswordRequest instance using the specified properties.
         * @function create
         * @memberof api.ChangePasswordRequest
         * @static
         * @param {api.IChangePasswordRequest=} [properties] Properties to set
         * @returns {api.ChangePasswordRequest} ChangePasswordRequest instance
         */
        ChangePasswordRequest.create = function create(properties) {
            return new ChangePasswordRequest(properties);
        };

        /**
         * Encodes the specified ChangePasswordRequest message. Does not implicitly {@link api.ChangePasswordRequest.verify|verify} messages.
         * @function encode
         * @memberof api.ChangePasswordRequest
         * @static
         * @param {api.IChangePasswordRequest} message ChangePasswordRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangePasswordRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currentPassword != null && Object.hasOwnProperty.call(message, "currentPassword"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.currentPassword);
            if (message.newPassword != null && Object.hasOwnProperty.call(message, "newPassword"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.newPassword);
            return writer;
        };

        /**
         * Encodes the specified ChangePasswordRequest message, length delimited. Does not implicitly {@link api.ChangePasswordRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ChangePasswordRequest
         * @static
         * @param {api.IChangePasswordRequest} message ChangePasswordRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangePasswordRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChangePasswordRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.ChangePasswordRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ChangePasswordRequest} ChangePasswordRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangePasswordRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ChangePasswordRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.currentPassword = reader.string();
                        break;
                    }
                case 2: {
                        message.newPassword = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChangePasswordRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ChangePasswordRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ChangePasswordRequest} ChangePasswordRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangePasswordRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChangePasswordRequest message.
         * @function verify
         * @memberof api.ChangePasswordRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChangePasswordRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currentPassword != null && message.hasOwnProperty("currentPassword"))
                if (!$util.isString(message.currentPassword))
                    return "currentPassword: string expected";
            if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                if (!$util.isString(message.newPassword))
                    return "newPassword: string expected";
            return null;
        };

        /**
         * Creates a ChangePasswordRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ChangePasswordRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ChangePasswordRequest} ChangePasswordRequest
         */
        ChangePasswordRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ChangePasswordRequest)
                return object;
            let message = new $root.api.ChangePasswordRequest();
            if (object.currentPassword != null)
                message.currentPassword = String(object.currentPassword);
            if (object.newPassword != null)
                message.newPassword = String(object.newPassword);
            return message;
        };

        /**
         * Creates a plain object from a ChangePasswordRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ChangePasswordRequest
         * @static
         * @param {api.ChangePasswordRequest} message ChangePasswordRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChangePasswordRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.currentPassword = "";
                object.newPassword = "";
            }
            if (message.currentPassword != null && message.hasOwnProperty("currentPassword"))
                object.currentPassword = message.currentPassword;
            if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                object.newPassword = message.newPassword;
            return object;
        };

        /**
         * Converts this ChangePasswordRequest to JSON.
         * @function toJSON
         * @memberof api.ChangePasswordRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChangePasswordRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChangePasswordRequest
         * @function getTypeUrl
         * @memberof api.ChangePasswordRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChangePasswordRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ChangePasswordRequest";
        };

        return ChangePasswordRequest;
    })();

    api.ChangePasswordResponse = (function() {

        /**
         * Properties of a ChangePasswordResponse.
         * @memberof api
         * @interface IChangePasswordResponse
         * @property {entities.ChangePasswordResult|null} [status] ChangePasswordResponse status
         * @property {Long|null} [nextTryAtTimestamp] ChangePasswordResponse nextTryAtTimestamp
         */

        /**
         * Constructs a new ChangePasswordResponse.
         * @memberof api
         * @classdesc Represents a ChangePasswordResponse.
         * @implements IChangePasswordResponse
         * @constructor
         * @param {api.IChangePasswordResponse=} [properties] Properties to set
         */
        function ChangePasswordResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangePasswordResponse status.
         * @member {entities.ChangePasswordResult} status
         * @memberof api.ChangePasswordResponse
         * @instance
         */
        ChangePasswordResponse.prototype.status = 0;

        /**
         * ChangePasswordResponse nextTryAtTimestamp.
         * @member {Long} nextTryAtTimestamp
         * @memberof api.ChangePasswordResponse
         * @instance
         */
        ChangePasswordResponse.prototype.nextTryAtTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ChangePasswordResponse instance using the specified properties.
         * @function create
         * @memberof api.ChangePasswordResponse
         * @static
         * @param {api.IChangePasswordResponse=} [properties] Properties to set
         * @returns {api.ChangePasswordResponse} ChangePasswordResponse instance
         */
        ChangePasswordResponse.create = function create(properties) {
            return new ChangePasswordResponse(properties);
        };

        /**
         * Encodes the specified ChangePasswordResponse message. Does not implicitly {@link api.ChangePasswordResponse.verify|verify} messages.
         * @function encode
         * @memberof api.ChangePasswordResponse
         * @static
         * @param {api.IChangePasswordResponse} message ChangePasswordResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangePasswordResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.nextTryAtTimestamp != null && Object.hasOwnProperty.call(message, "nextTryAtTimestamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.nextTryAtTimestamp);
            return writer;
        };

        /**
         * Encodes the specified ChangePasswordResponse message, length delimited. Does not implicitly {@link api.ChangePasswordResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ChangePasswordResponse
         * @static
         * @param {api.IChangePasswordResponse} message ChangePasswordResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangePasswordResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChangePasswordResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.ChangePasswordResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ChangePasswordResponse} ChangePasswordResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangePasswordResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ChangePasswordResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.nextTryAtTimestamp = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChangePasswordResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ChangePasswordResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ChangePasswordResponse} ChangePasswordResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangePasswordResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChangePasswordResponse message.
         * @function verify
         * @memberof api.ChangePasswordResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChangePasswordResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (!$util.isInteger(message.nextTryAtTimestamp) && !(message.nextTryAtTimestamp && $util.isInteger(message.nextTryAtTimestamp.low) && $util.isInteger(message.nextTryAtTimestamp.high)))
                    return "nextTryAtTimestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates a ChangePasswordResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ChangePasswordResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ChangePasswordResponse} ChangePasswordResponse
         */
        ChangePasswordResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ChangePasswordResponse)
                return object;
            let message = new $root.api.ChangePasswordResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "CPR_OK":
            case 0:
                message.status = 0;
                break;
            case "CPR_INVALID_OLD_PASSWORD":
            case 1:
                message.status = 1;
                break;
            case "CPR_PENDING_STAMP":
            case 2:
                message.status = 2;
                break;
            case "CPR_NOT_REGISTERED":
            case 3:
                message.status = 3;
                break;
            case "CPR_INVALID_NEW_PASSWORD":
            case 4:
                message.status = 4;
                break;
            }
            if (object.nextTryAtTimestamp != null)
                if ($util.Long)
                    (message.nextTryAtTimestamp = $util.Long.fromValue(object.nextTryAtTimestamp)).unsigned = true;
                else if (typeof object.nextTryAtTimestamp === "string")
                    message.nextTryAtTimestamp = parseInt(object.nextTryAtTimestamp, 10);
                else if (typeof object.nextTryAtTimestamp === "number")
                    message.nextTryAtTimestamp = object.nextTryAtTimestamp;
                else if (typeof object.nextTryAtTimestamp === "object")
                    message.nextTryAtTimestamp = new $util.LongBits(object.nextTryAtTimestamp.low >>> 0, object.nextTryAtTimestamp.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a ChangePasswordResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ChangePasswordResponse
         * @static
         * @param {api.ChangePasswordResponse} message ChangePasswordResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChangePasswordResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "CPR_OK" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.nextTryAtTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nextTryAtTimestamp = options.longs === String ? "0" : 0;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.entities.ChangePasswordResult[message.status] === undefined ? message.status : $root.entities.ChangePasswordResult[message.status] : message.status;
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (typeof message.nextTryAtTimestamp === "number")
                    object.nextTryAtTimestamp = options.longs === String ? String(message.nextTryAtTimestamp) : message.nextTryAtTimestamp;
                else
                    object.nextTryAtTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.nextTryAtTimestamp) : options.longs === Number ? new $util.LongBits(message.nextTryAtTimestamp.low >>> 0, message.nextTryAtTimestamp.high >>> 0).toNumber(true) : message.nextTryAtTimestamp;
            return object;
        };

        /**
         * Converts this ChangePasswordResponse to JSON.
         * @function toJSON
         * @memberof api.ChangePasswordResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChangePasswordResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChangePasswordResponse
         * @function getTypeUrl
         * @memberof api.ChangePasswordResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChangePasswordResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ChangePasswordResponse";
        };

        return ChangePasswordResponse;
    })();

    api.ResetPasswordRequest = (function() {

        /**
         * Properties of a ResetPasswordRequest.
         * @memberof api
         * @interface IResetPasswordRequest
         * @property {string|null} [email] ResetPasswordRequest email
         * @property {string|null} [code] ResetPasswordRequest code
         * @property {string|null} [newPassword] ResetPasswordRequest newPassword
         * @property {string|null} [newPasswordConfirm] ResetPasswordRequest newPasswordConfirm
         */

        /**
         * Constructs a new ResetPasswordRequest.
         * @memberof api
         * @classdesc Represents a ResetPasswordRequest.
         * @implements IResetPasswordRequest
         * @constructor
         * @param {api.IResetPasswordRequest=} [properties] Properties to set
         */
        function ResetPasswordRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResetPasswordRequest email.
         * @member {string} email
         * @memberof api.ResetPasswordRequest
         * @instance
         */
        ResetPasswordRequest.prototype.email = "";

        /**
         * ResetPasswordRequest code.
         * @member {string} code
         * @memberof api.ResetPasswordRequest
         * @instance
         */
        ResetPasswordRequest.prototype.code = "";

        /**
         * ResetPasswordRequest newPassword.
         * @member {string} newPassword
         * @memberof api.ResetPasswordRequest
         * @instance
         */
        ResetPasswordRequest.prototype.newPassword = "";

        /**
         * ResetPasswordRequest newPasswordConfirm.
         * @member {string} newPasswordConfirm
         * @memberof api.ResetPasswordRequest
         * @instance
         */
        ResetPasswordRequest.prototype.newPasswordConfirm = "";

        /**
         * Creates a new ResetPasswordRequest instance using the specified properties.
         * @function create
         * @memberof api.ResetPasswordRequest
         * @static
         * @param {api.IResetPasswordRequest=} [properties] Properties to set
         * @returns {api.ResetPasswordRequest} ResetPasswordRequest instance
         */
        ResetPasswordRequest.create = function create(properties) {
            return new ResetPasswordRequest(properties);
        };

        /**
         * Encodes the specified ResetPasswordRequest message. Does not implicitly {@link api.ResetPasswordRequest.verify|verify} messages.
         * @function encode
         * @memberof api.ResetPasswordRequest
         * @static
         * @param {api.IResetPasswordRequest} message ResetPasswordRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetPasswordRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
            if (message.newPassword != null && Object.hasOwnProperty.call(message, "newPassword"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.newPassword);
            if (message.newPasswordConfirm != null && Object.hasOwnProperty.call(message, "newPasswordConfirm"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.newPasswordConfirm);
            return writer;
        };

        /**
         * Encodes the specified ResetPasswordRequest message, length delimited. Does not implicitly {@link api.ResetPasswordRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ResetPasswordRequest
         * @static
         * @param {api.IResetPasswordRequest} message ResetPasswordRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetPasswordRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResetPasswordRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.ResetPasswordRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ResetPasswordRequest} ResetPasswordRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetPasswordRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ResetPasswordRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.email = reader.string();
                        break;
                    }
                case 2: {
                        message.code = reader.string();
                        break;
                    }
                case 3: {
                        message.newPassword = reader.string();
                        break;
                    }
                case 4: {
                        message.newPasswordConfirm = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResetPasswordRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ResetPasswordRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ResetPasswordRequest} ResetPasswordRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetPasswordRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResetPasswordRequest message.
         * @function verify
         * @memberof api.ResetPasswordRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResetPasswordRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                if (!$util.isString(message.newPassword))
                    return "newPassword: string expected";
            if (message.newPasswordConfirm != null && message.hasOwnProperty("newPasswordConfirm"))
                if (!$util.isString(message.newPasswordConfirm))
                    return "newPasswordConfirm: string expected";
            return null;
        };

        /**
         * Creates a ResetPasswordRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ResetPasswordRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ResetPasswordRequest} ResetPasswordRequest
         */
        ResetPasswordRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ResetPasswordRequest)
                return object;
            let message = new $root.api.ResetPasswordRequest();
            if (object.email != null)
                message.email = String(object.email);
            if (object.code != null)
                message.code = String(object.code);
            if (object.newPassword != null)
                message.newPassword = String(object.newPassword);
            if (object.newPasswordConfirm != null)
                message.newPasswordConfirm = String(object.newPasswordConfirm);
            return message;
        };

        /**
         * Creates a plain object from a ResetPasswordRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ResetPasswordRequest
         * @static
         * @param {api.ResetPasswordRequest} message ResetPasswordRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResetPasswordRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.email = "";
                object.code = "";
                object.newPassword = "";
                object.newPasswordConfirm = "";
            }
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                object.newPassword = message.newPassword;
            if (message.newPasswordConfirm != null && message.hasOwnProperty("newPasswordConfirm"))
                object.newPasswordConfirm = message.newPasswordConfirm;
            return object;
        };

        /**
         * Converts this ResetPasswordRequest to JSON.
         * @function toJSON
         * @memberof api.ResetPasswordRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResetPasswordRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResetPasswordRequest
         * @function getTypeUrl
         * @memberof api.ResetPasswordRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResetPasswordRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ResetPasswordRequest";
        };

        return ResetPasswordRequest;
    })();

    api.ResetPasswordResponse = (function() {

        /**
         * Properties of a ResetPasswordResponse.
         * @memberof api
         * @interface IResetPasswordResponse
         * @property {entities.ResetPasswordResult|null} [status] ResetPasswordResponse status
         * @property {Long|null} [nextTryAtTimestamp] ResetPasswordResponse nextTryAtTimestamp
         */

        /**
         * Constructs a new ResetPasswordResponse.
         * @memberof api
         * @classdesc Represents a ResetPasswordResponse.
         * @implements IResetPasswordResponse
         * @constructor
         * @param {api.IResetPasswordResponse=} [properties] Properties to set
         */
        function ResetPasswordResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResetPasswordResponse status.
         * @member {entities.ResetPasswordResult} status
         * @memberof api.ResetPasswordResponse
         * @instance
         */
        ResetPasswordResponse.prototype.status = 0;

        /**
         * ResetPasswordResponse nextTryAtTimestamp.
         * @member {Long} nextTryAtTimestamp
         * @memberof api.ResetPasswordResponse
         * @instance
         */
        ResetPasswordResponse.prototype.nextTryAtTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ResetPasswordResponse instance using the specified properties.
         * @function create
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {api.IResetPasswordResponse=} [properties] Properties to set
         * @returns {api.ResetPasswordResponse} ResetPasswordResponse instance
         */
        ResetPasswordResponse.create = function create(properties) {
            return new ResetPasswordResponse(properties);
        };

        /**
         * Encodes the specified ResetPasswordResponse message. Does not implicitly {@link api.ResetPasswordResponse.verify|verify} messages.
         * @function encode
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {api.IResetPasswordResponse} message ResetPasswordResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetPasswordResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.nextTryAtTimestamp != null && Object.hasOwnProperty.call(message, "nextTryAtTimestamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.nextTryAtTimestamp);
            return writer;
        };

        /**
         * Encodes the specified ResetPasswordResponse message, length delimited. Does not implicitly {@link api.ResetPasswordResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {api.IResetPasswordResponse} message ResetPasswordResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetPasswordResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResetPasswordResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ResetPasswordResponse} ResetPasswordResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetPasswordResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ResetPasswordResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.nextTryAtTimestamp = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResetPasswordResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ResetPasswordResponse} ResetPasswordResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetPasswordResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResetPasswordResponse message.
         * @function verify
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResetPasswordResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (!$util.isInteger(message.nextTryAtTimestamp) && !(message.nextTryAtTimestamp && $util.isInteger(message.nextTryAtTimestamp.low) && $util.isInteger(message.nextTryAtTimestamp.high)))
                    return "nextTryAtTimestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates a ResetPasswordResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ResetPasswordResponse} ResetPasswordResponse
         */
        ResetPasswordResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ResetPasswordResponse)
                return object;
            let message = new $root.api.ResetPasswordResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "RP_OK":
            case 0:
                message.status = 0;
                break;
            case "RP_INVALID_CODE":
            case 1:
                message.status = 1;
                break;
            case "RP_PENDING_STAMP":
            case 2:
                message.status = 2;
                break;
            case "RP_INVALID_EMAIL":
            case 3:
                message.status = 3;
                break;
            case "RP_PASSWORD_MISMATCH":
            case 4:
                message.status = 4;
                break;
            case "RP_PASSWORD_TOO_SHORT":
            case 5:
                message.status = 5;
                break;
            case "RP_CODE_EXPIRED":
            case 6:
                message.status = 6;
                break;
            }
            if (object.nextTryAtTimestamp != null)
                if ($util.Long)
                    (message.nextTryAtTimestamp = $util.Long.fromValue(object.nextTryAtTimestamp)).unsigned = true;
                else if (typeof object.nextTryAtTimestamp === "string")
                    message.nextTryAtTimestamp = parseInt(object.nextTryAtTimestamp, 10);
                else if (typeof object.nextTryAtTimestamp === "number")
                    message.nextTryAtTimestamp = object.nextTryAtTimestamp;
                else if (typeof object.nextTryAtTimestamp === "object")
                    message.nextTryAtTimestamp = new $util.LongBits(object.nextTryAtTimestamp.low >>> 0, object.nextTryAtTimestamp.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a ResetPasswordResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {api.ResetPasswordResponse} message ResetPasswordResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResetPasswordResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "RP_OK" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.nextTryAtTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nextTryAtTimestamp = options.longs === String ? "0" : 0;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.entities.ResetPasswordResult[message.status] === undefined ? message.status : $root.entities.ResetPasswordResult[message.status] : message.status;
            if (message.nextTryAtTimestamp != null && message.hasOwnProperty("nextTryAtTimestamp"))
                if (typeof message.nextTryAtTimestamp === "number")
                    object.nextTryAtTimestamp = options.longs === String ? String(message.nextTryAtTimestamp) : message.nextTryAtTimestamp;
                else
                    object.nextTryAtTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.nextTryAtTimestamp) : options.longs === Number ? new $util.LongBits(message.nextTryAtTimestamp.low >>> 0, message.nextTryAtTimestamp.high >>> 0).toNumber(true) : message.nextTryAtTimestamp;
            return object;
        };

        /**
         * Converts this ResetPasswordResponse to JSON.
         * @function toJSON
         * @memberof api.ResetPasswordResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResetPasswordResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResetPasswordResponse
         * @function getTypeUrl
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResetPasswordResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ResetPasswordResponse";
        };

        return ResetPasswordResponse;
    })();

    api.LogoutRequest = (function() {

        /**
         * Properties of a LogoutRequest.
         * @memberof api
         * @interface ILogoutRequest
         * @property {api.LogoutRequest.LogoutType|null} [type] LogoutRequest type
         */

        /**
         * Constructs a new LogoutRequest.
         * @memberof api
         * @classdesc Represents a LogoutRequest.
         * @implements ILogoutRequest
         * @constructor
         * @param {api.ILogoutRequest=} [properties] Properties to set
         */
        function LogoutRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LogoutRequest type.
         * @member {api.LogoutRequest.LogoutType} type
         * @memberof api.LogoutRequest
         * @instance
         */
        LogoutRequest.prototype.type = 0;

        /**
         * Creates a new LogoutRequest instance using the specified properties.
         * @function create
         * @memberof api.LogoutRequest
         * @static
         * @param {api.ILogoutRequest=} [properties] Properties to set
         * @returns {api.LogoutRequest} LogoutRequest instance
         */
        LogoutRequest.create = function create(properties) {
            return new LogoutRequest(properties);
        };

        /**
         * Encodes the specified LogoutRequest message. Does not implicitly {@link api.LogoutRequest.verify|verify} messages.
         * @function encode
         * @memberof api.LogoutRequest
         * @static
         * @param {api.ILogoutRequest} message LogoutRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LogoutRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            return writer;
        };

        /**
         * Encodes the specified LogoutRequest message, length delimited. Does not implicitly {@link api.LogoutRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.LogoutRequest
         * @static
         * @param {api.ILogoutRequest} message LogoutRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LogoutRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LogoutRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.LogoutRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.LogoutRequest} LogoutRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LogoutRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.LogoutRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LogoutRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.LogoutRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.LogoutRequest} LogoutRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LogoutRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LogoutRequest message.
         * @function verify
         * @memberof api.LogoutRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LogoutRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        /**
         * Creates a LogoutRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.LogoutRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.LogoutRequest} LogoutRequest
         */
        LogoutRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.LogoutRequest)
                return object;
            let message = new $root.api.LogoutRequest();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "LT_NONE":
            case 0:
                message.type = 0;
                break;
            case "LT_CURRENT_TOKEN":
            case 1:
                message.type = 1;
                break;
            case "LT_ALL_TOKENS":
            case 2:
                message.type = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a LogoutRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.LogoutRequest
         * @static
         * @param {api.LogoutRequest} message LogoutRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LogoutRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.type = options.enums === String ? "LT_NONE" : 0;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.api.LogoutRequest.LogoutType[message.type] === undefined ? message.type : $root.api.LogoutRequest.LogoutType[message.type] : message.type;
            return object;
        };

        /**
         * Converts this LogoutRequest to JSON.
         * @function toJSON
         * @memberof api.LogoutRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LogoutRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LogoutRequest
         * @function getTypeUrl
         * @memberof api.LogoutRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LogoutRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.LogoutRequest";
        };

        /**
         * LogoutType enum.
         * @name api.LogoutRequest.LogoutType
         * @enum {number}
         * @property {number} LT_NONE=0 LT_NONE value
         * @property {number} LT_CURRENT_TOKEN=1 LT_CURRENT_TOKEN value
         * @property {number} LT_ALL_TOKENS=2 LT_ALL_TOKENS value
         */
        LogoutRequest.LogoutType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "LT_NONE"] = 0;
            values[valuesById[1] = "LT_CURRENT_TOKEN"] = 1;
            values[valuesById[2] = "LT_ALL_TOKENS"] = 2;
            return values;
        })();

        return LogoutRequest;
    })();

    api.LogoutResponse = (function() {

        /**
         * Properties of a LogoutResponse.
         * @memberof api
         * @interface ILogoutResponse
         * @property {api.LogoutResponse.Status|null} [status] LogoutResponse status
         */

        /**
         * Constructs a new LogoutResponse.
         * @memberof api
         * @classdesc Represents a LogoutResponse.
         * @implements ILogoutResponse
         * @constructor
         * @param {api.ILogoutResponse=} [properties] Properties to set
         */
        function LogoutResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LogoutResponse status.
         * @member {api.LogoutResponse.Status} status
         * @memberof api.LogoutResponse
         * @instance
         */
        LogoutResponse.prototype.status = 0;

        /**
         * Creates a new LogoutResponse instance using the specified properties.
         * @function create
         * @memberof api.LogoutResponse
         * @static
         * @param {api.ILogoutResponse=} [properties] Properties to set
         * @returns {api.LogoutResponse} LogoutResponse instance
         */
        LogoutResponse.create = function create(properties) {
            return new LogoutResponse(properties);
        };

        /**
         * Encodes the specified LogoutResponse message. Does not implicitly {@link api.LogoutResponse.verify|verify} messages.
         * @function encode
         * @memberof api.LogoutResponse
         * @static
         * @param {api.ILogoutResponse} message LogoutResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LogoutResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified LogoutResponse message, length delimited. Does not implicitly {@link api.LogoutResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.LogoutResponse
         * @static
         * @param {api.ILogoutResponse} message LogoutResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LogoutResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LogoutResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.LogoutResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.LogoutResponse} LogoutResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LogoutResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.LogoutResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LogoutResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.LogoutResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.LogoutResponse} LogoutResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LogoutResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LogoutResponse message.
         * @function verify
         * @memberof api.LogoutResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LogoutResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                    break;
                }
            return null;
        };

        /**
         * Creates a LogoutResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.LogoutResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.LogoutResponse} LogoutResponse
         */
        LogoutResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.LogoutResponse)
                return object;
            let message = new $root.api.LogoutResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "LS_OK":
            case 0:
                message.status = 0;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a LogoutResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.LogoutResponse
         * @static
         * @param {api.LogoutResponse} message LogoutResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LogoutResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.status = options.enums === String ? "LS_OK" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.api.LogoutResponse.Status[message.status] === undefined ? message.status : $root.api.LogoutResponse.Status[message.status] : message.status;
            return object;
        };

        /**
         * Converts this LogoutResponse to JSON.
         * @function toJSON
         * @memberof api.LogoutResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LogoutResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LogoutResponse
         * @function getTypeUrl
         * @memberof api.LogoutResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LogoutResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.LogoutResponse";
        };

        /**
         * Status enum.
         * @name api.LogoutResponse.Status
         * @enum {number}
         * @property {number} LS_OK=0 LS_OK value
         */
        LogoutResponse.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "LS_OK"] = 0;
            return values;
        })();

        return LogoutResponse;
    })();

    api.GetDetailsRequest = (function() {

        /**
         * Properties of a GetDetailsRequest.
         * @memberof api
         * @interface IGetDetailsRequest
         * @property {Array.<entities.UserProfileFieldType>|null} [fields] GetDetailsRequest fields
         */

        /**
         * Constructs a new GetDetailsRequest.
         * @memberof api
         * @classdesc Represents a GetDetailsRequest.
         * @implements IGetDetailsRequest
         * @constructor
         * @param {api.IGetDetailsRequest=} [properties] Properties to set
         */
        function GetDetailsRequest(properties) {
            this.fields = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetDetailsRequest fields.
         * @member {Array.<entities.UserProfileFieldType>} fields
         * @memberof api.GetDetailsRequest
         * @instance
         */
        GetDetailsRequest.prototype.fields = $util.emptyArray;

        /**
         * Creates a new GetDetailsRequest instance using the specified properties.
         * @function create
         * @memberof api.GetDetailsRequest
         * @static
         * @param {api.IGetDetailsRequest=} [properties] Properties to set
         * @returns {api.GetDetailsRequest} GetDetailsRequest instance
         */
        GetDetailsRequest.create = function create(properties) {
            return new GetDetailsRequest(properties);
        };

        /**
         * Encodes the specified GetDetailsRequest message. Does not implicitly {@link api.GetDetailsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetDetailsRequest
         * @static
         * @param {api.IGetDetailsRequest} message GetDetailsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDetailsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fields != null && message.fields.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.fields.length; ++i)
                    writer.int32(message.fields[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified GetDetailsRequest message, length delimited. Does not implicitly {@link api.GetDetailsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetDetailsRequest
         * @static
         * @param {api.IGetDetailsRequest} message GetDetailsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDetailsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetDetailsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetDetailsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetDetailsRequest} GetDetailsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDetailsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetDetailsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.fields && message.fields.length))
                            message.fields = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.fields.push(reader.int32());
                        } else
                            message.fields.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetDetailsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetDetailsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetDetailsRequest} GetDetailsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDetailsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetDetailsRequest message.
         * @function verify
         * @memberof api.GetDetailsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetDetailsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fields != null && message.hasOwnProperty("fields")) {
                if (!Array.isArray(message.fields))
                    return "fields: array expected";
                for (let i = 0; i < message.fields.length; ++i)
                    switch (message.fields[i]) {
                    default:
                        return "fields: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
            }
            return null;
        };

        /**
         * Creates a GetDetailsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetDetailsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetDetailsRequest} GetDetailsRequest
         */
        GetDetailsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetDetailsRequest)
                return object;
            let message = new $root.api.GetDetailsRequest();
            if (object.fields) {
                if (!Array.isArray(object.fields))
                    throw TypeError(".api.GetDetailsRequest.fields: array expected");
                message.fields = [];
                for (let i = 0; i < object.fields.length; ++i)
                    switch (object.fields[i]) {
                    default:
                        if (typeof object.fields[i] === "number") {
                            message.fields[i] = object.fields[i];
                            break;
                        }
                    case "UPFT_FIRST_NAME":
                    case 0:
                        message.fields[i] = 0;
                        break;
                    case "UPFT_LAST_NAME":
                    case 1:
                        message.fields[i] = 1;
                        break;
                    case "UPFT_EMAIL":
                    case 2:
                        message.fields[i] = 2;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetDetailsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetDetailsRequest
         * @static
         * @param {api.GetDetailsRequest} message GetDetailsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetDetailsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.fields = [];
            if (message.fields && message.fields.length) {
                object.fields = [];
                for (let j = 0; j < message.fields.length; ++j)
                    object.fields[j] = options.enums === String ? $root.entities.UserProfileFieldType[message.fields[j]] === undefined ? message.fields[j] : $root.entities.UserProfileFieldType[message.fields[j]] : message.fields[j];
            }
            return object;
        };

        /**
         * Converts this GetDetailsRequest to JSON.
         * @function toJSON
         * @memberof api.GetDetailsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetDetailsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetDetailsRequest
         * @function getTypeUrl
         * @memberof api.GetDetailsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetDetailsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.GetDetailsRequest";
        };

        return GetDetailsRequest;
    })();

    api.GetDetailsResponse = (function() {

        /**
         * Properties of a GetDetailsResponse.
         * @memberof api
         * @interface IGetDetailsResponse
         * @property {Array.<entities.IUserProfileField>|null} [fields] GetDetailsResponse fields
         */

        /**
         * Constructs a new GetDetailsResponse.
         * @memberof api
         * @classdesc Represents a GetDetailsResponse.
         * @implements IGetDetailsResponse
         * @constructor
         * @param {api.IGetDetailsResponse=} [properties] Properties to set
         */
        function GetDetailsResponse(properties) {
            this.fields = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetDetailsResponse fields.
         * @member {Array.<entities.IUserProfileField>} fields
         * @memberof api.GetDetailsResponse
         * @instance
         */
        GetDetailsResponse.prototype.fields = $util.emptyArray;

        /**
         * Creates a new GetDetailsResponse instance using the specified properties.
         * @function create
         * @memberof api.GetDetailsResponse
         * @static
         * @param {api.IGetDetailsResponse=} [properties] Properties to set
         * @returns {api.GetDetailsResponse} GetDetailsResponse instance
         */
        GetDetailsResponse.create = function create(properties) {
            return new GetDetailsResponse(properties);
        };

        /**
         * Encodes the specified GetDetailsResponse message. Does not implicitly {@link api.GetDetailsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetDetailsResponse
         * @static
         * @param {api.IGetDetailsResponse} message GetDetailsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDetailsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fields != null && message.fields.length)
                for (let i = 0; i < message.fields.length; ++i)
                    $root.entities.UserProfileField.encode(message.fields[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetDetailsResponse message, length delimited. Does not implicitly {@link api.GetDetailsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetDetailsResponse
         * @static
         * @param {api.IGetDetailsResponse} message GetDetailsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDetailsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetDetailsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetDetailsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetDetailsResponse} GetDetailsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDetailsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetDetailsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.fields && message.fields.length))
                            message.fields = [];
                        message.fields.push($root.entities.UserProfileField.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetDetailsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetDetailsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetDetailsResponse} GetDetailsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDetailsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetDetailsResponse message.
         * @function verify
         * @memberof api.GetDetailsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetDetailsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fields != null && message.hasOwnProperty("fields")) {
                if (!Array.isArray(message.fields))
                    return "fields: array expected";
                for (let i = 0; i < message.fields.length; ++i) {
                    let error = $root.entities.UserProfileField.verify(message.fields[i]);
                    if (error)
                        return "fields." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetDetailsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetDetailsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetDetailsResponse} GetDetailsResponse
         */
        GetDetailsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetDetailsResponse)
                return object;
            let message = new $root.api.GetDetailsResponse();
            if (object.fields) {
                if (!Array.isArray(object.fields))
                    throw TypeError(".api.GetDetailsResponse.fields: array expected");
                message.fields = [];
                for (let i = 0; i < object.fields.length; ++i) {
                    if (typeof object.fields[i] !== "object")
                        throw TypeError(".api.GetDetailsResponse.fields: object expected");
                    message.fields[i] = $root.entities.UserProfileField.fromObject(object.fields[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetDetailsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetDetailsResponse
         * @static
         * @param {api.GetDetailsResponse} message GetDetailsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetDetailsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.fields = [];
            if (message.fields && message.fields.length) {
                object.fields = [];
                for (let j = 0; j < message.fields.length; ++j)
                    object.fields[j] = $root.entities.UserProfileField.toObject(message.fields[j], options);
            }
            return object;
        };

        /**
         * Converts this GetDetailsResponse to JSON.
         * @function toJSON
         * @memberof api.GetDetailsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetDetailsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetDetailsResponse
         * @function getTypeUrl
         * @memberof api.GetDetailsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetDetailsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.GetDetailsResponse";
        };

        return GetDetailsResponse;
    })();

    api.ChangeDetailsRequest = (function() {

        /**
         * Properties of a ChangeDetailsRequest.
         * @memberof api
         * @interface IChangeDetailsRequest
         * @property {Array.<entities.IUserProfileField>|null} [updates] ChangeDetailsRequest updates
         */

        /**
         * Constructs a new ChangeDetailsRequest.
         * @memberof api
         * @classdesc Represents a ChangeDetailsRequest.
         * @implements IChangeDetailsRequest
         * @constructor
         * @param {api.IChangeDetailsRequest=} [properties] Properties to set
         */
        function ChangeDetailsRequest(properties) {
            this.updates = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangeDetailsRequest updates.
         * @member {Array.<entities.IUserProfileField>} updates
         * @memberof api.ChangeDetailsRequest
         * @instance
         */
        ChangeDetailsRequest.prototype.updates = $util.emptyArray;

        /**
         * Creates a new ChangeDetailsRequest instance using the specified properties.
         * @function create
         * @memberof api.ChangeDetailsRequest
         * @static
         * @param {api.IChangeDetailsRequest=} [properties] Properties to set
         * @returns {api.ChangeDetailsRequest} ChangeDetailsRequest instance
         */
        ChangeDetailsRequest.create = function create(properties) {
            return new ChangeDetailsRequest(properties);
        };

        /**
         * Encodes the specified ChangeDetailsRequest message. Does not implicitly {@link api.ChangeDetailsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.ChangeDetailsRequest
         * @static
         * @param {api.IChangeDetailsRequest} message ChangeDetailsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeDetailsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.updates != null && message.updates.length)
                for (let i = 0; i < message.updates.length; ++i)
                    $root.entities.UserProfileField.encode(message.updates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChangeDetailsRequest message, length delimited. Does not implicitly {@link api.ChangeDetailsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ChangeDetailsRequest
         * @static
         * @param {api.IChangeDetailsRequest} message ChangeDetailsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeDetailsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChangeDetailsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.ChangeDetailsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ChangeDetailsRequest} ChangeDetailsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeDetailsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ChangeDetailsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.updates && message.updates.length))
                            message.updates = [];
                        message.updates.push($root.entities.UserProfileField.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChangeDetailsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ChangeDetailsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ChangeDetailsRequest} ChangeDetailsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeDetailsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChangeDetailsRequest message.
         * @function verify
         * @memberof api.ChangeDetailsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChangeDetailsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.updates != null && message.hasOwnProperty("updates")) {
                if (!Array.isArray(message.updates))
                    return "updates: array expected";
                for (let i = 0; i < message.updates.length; ++i) {
                    let error = $root.entities.UserProfileField.verify(message.updates[i]);
                    if (error)
                        return "updates." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ChangeDetailsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ChangeDetailsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ChangeDetailsRequest} ChangeDetailsRequest
         */
        ChangeDetailsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ChangeDetailsRequest)
                return object;
            let message = new $root.api.ChangeDetailsRequest();
            if (object.updates) {
                if (!Array.isArray(object.updates))
                    throw TypeError(".api.ChangeDetailsRequest.updates: array expected");
                message.updates = [];
                for (let i = 0; i < object.updates.length; ++i) {
                    if (typeof object.updates[i] !== "object")
                        throw TypeError(".api.ChangeDetailsRequest.updates: object expected");
                    message.updates[i] = $root.entities.UserProfileField.fromObject(object.updates[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ChangeDetailsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ChangeDetailsRequest
         * @static
         * @param {api.ChangeDetailsRequest} message ChangeDetailsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChangeDetailsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.updates = [];
            if (message.updates && message.updates.length) {
                object.updates = [];
                for (let j = 0; j < message.updates.length; ++j)
                    object.updates[j] = $root.entities.UserProfileField.toObject(message.updates[j], options);
            }
            return object;
        };

        /**
         * Converts this ChangeDetailsRequest to JSON.
         * @function toJSON
         * @memberof api.ChangeDetailsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChangeDetailsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChangeDetailsRequest
         * @function getTypeUrl
         * @memberof api.ChangeDetailsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChangeDetailsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ChangeDetailsRequest";
        };

        return ChangeDetailsRequest;
    })();

    api.ChangeDetailsResponse = (function() {

        /**
         * Properties of a ChangeDetailsResponse.
         * @memberof api
         * @interface IChangeDetailsResponse
         * @property {api.ChangeDetailsResponse.Status|null} [status] ChangeDetailsResponse status
         * @property {Array.<api.ChangeDetailsResponse.UpdateStatus>|null} [updates] ChangeDetailsResponse updates
         */

        /**
         * Constructs a new ChangeDetailsResponse.
         * @memberof api
         * @classdesc Represents a ChangeDetailsResponse.
         * @implements IChangeDetailsResponse
         * @constructor
         * @param {api.IChangeDetailsResponse=} [properties] Properties to set
         */
        function ChangeDetailsResponse(properties) {
            this.updates = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangeDetailsResponse status.
         * @member {api.ChangeDetailsResponse.Status} status
         * @memberof api.ChangeDetailsResponse
         * @instance
         */
        ChangeDetailsResponse.prototype.status = 0;

        /**
         * ChangeDetailsResponse updates.
         * @member {Array.<api.ChangeDetailsResponse.UpdateStatus>} updates
         * @memberof api.ChangeDetailsResponse
         * @instance
         */
        ChangeDetailsResponse.prototype.updates = $util.emptyArray;

        /**
         * Creates a new ChangeDetailsResponse instance using the specified properties.
         * @function create
         * @memberof api.ChangeDetailsResponse
         * @static
         * @param {api.IChangeDetailsResponse=} [properties] Properties to set
         * @returns {api.ChangeDetailsResponse} ChangeDetailsResponse instance
         */
        ChangeDetailsResponse.create = function create(properties) {
            return new ChangeDetailsResponse(properties);
        };

        /**
         * Encodes the specified ChangeDetailsResponse message. Does not implicitly {@link api.ChangeDetailsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.ChangeDetailsResponse
         * @static
         * @param {api.IChangeDetailsResponse} message ChangeDetailsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeDetailsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.updates != null && message.updates.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.updates.length; ++i)
                    writer.int32(message.updates[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified ChangeDetailsResponse message, length delimited. Does not implicitly {@link api.ChangeDetailsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ChangeDetailsResponse
         * @static
         * @param {api.IChangeDetailsResponse} message ChangeDetailsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeDetailsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChangeDetailsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.ChangeDetailsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ChangeDetailsResponse} ChangeDetailsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeDetailsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ChangeDetailsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        if (!(message.updates && message.updates.length))
                            message.updates = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.updates.push(reader.int32());
                        } else
                            message.updates.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChangeDetailsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ChangeDetailsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ChangeDetailsResponse} ChangeDetailsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeDetailsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChangeDetailsResponse message.
         * @function verify
         * @memberof api.ChangeDetailsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChangeDetailsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.updates != null && message.hasOwnProperty("updates")) {
                if (!Array.isArray(message.updates))
                    return "updates: array expected";
                for (let i = 0; i < message.updates.length; ++i)
                    switch (message.updates[i]) {
                    default:
                        return "updates: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
            }
            return null;
        };

        /**
         * Creates a ChangeDetailsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ChangeDetailsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ChangeDetailsResponse} ChangeDetailsResponse
         */
        ChangeDetailsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ChangeDetailsResponse)
                return object;
            let message = new $root.api.ChangeDetailsResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "CDS_OK":
            case 0:
                message.status = 0;
                break;
            case "CDS_NOT_REGISTERED":
            case 1:
                message.status = 1;
                break;
            case "CDS_HAS_ERRORS":
            case 2:
                message.status = 2;
                break;
            }
            if (object.updates) {
                if (!Array.isArray(object.updates))
                    throw TypeError(".api.ChangeDetailsResponse.updates: array expected");
                message.updates = [];
                for (let i = 0; i < object.updates.length; ++i)
                    switch (object.updates[i]) {
                    default:
                        if (typeof object.updates[i] === "number") {
                            message.updates[i] = object.updates[i];
                            break;
                        }
                    case "CDR_OK":
                    case 0:
                        message.updates[i] = 0;
                        break;
                    case "CDR_INVALID_VALUE":
                    case 1:
                        message.updates[i] = 1;
                        break;
                    case "CDR_UNKNOWN_TYPE":
                    case 2:
                        message.updates[i] = 2;
                        break;
                    case "CDR_NOT_ALLOWED":
                    case 3:
                        message.updates[i] = 3;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from a ChangeDetailsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ChangeDetailsResponse
         * @static
         * @param {api.ChangeDetailsResponse} message ChangeDetailsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChangeDetailsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.updates = [];
            if (options.defaults)
                object.status = options.enums === String ? "CDS_OK" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.api.ChangeDetailsResponse.Status[message.status] === undefined ? message.status : $root.api.ChangeDetailsResponse.Status[message.status] : message.status;
            if (message.updates && message.updates.length) {
                object.updates = [];
                for (let j = 0; j < message.updates.length; ++j)
                    object.updates[j] = options.enums === String ? $root.api.ChangeDetailsResponse.UpdateStatus[message.updates[j]] === undefined ? message.updates[j] : $root.api.ChangeDetailsResponse.UpdateStatus[message.updates[j]] : message.updates[j];
            }
            return object;
        };

        /**
         * Converts this ChangeDetailsResponse to JSON.
         * @function toJSON
         * @memberof api.ChangeDetailsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChangeDetailsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChangeDetailsResponse
         * @function getTypeUrl
         * @memberof api.ChangeDetailsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChangeDetailsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ChangeDetailsResponse";
        };

        /**
         * Status enum.
         * @name api.ChangeDetailsResponse.Status
         * @enum {number}
         * @property {number} CDS_OK=0 CDS_OK value
         * @property {number} CDS_NOT_REGISTERED=1 CDS_NOT_REGISTERED value
         * @property {number} CDS_HAS_ERRORS=2 CDS_HAS_ERRORS value
         */
        ChangeDetailsResponse.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CDS_OK"] = 0;
            values[valuesById[1] = "CDS_NOT_REGISTERED"] = 1;
            values[valuesById[2] = "CDS_HAS_ERRORS"] = 2;
            return values;
        })();

        /**
         * UpdateStatus enum.
         * @name api.ChangeDetailsResponse.UpdateStatus
         * @enum {number}
         * @property {number} CDR_OK=0 CDR_OK value
         * @property {number} CDR_INVALID_VALUE=1 CDR_INVALID_VALUE value
         * @property {number} CDR_UNKNOWN_TYPE=2 CDR_UNKNOWN_TYPE value
         * @property {number} CDR_NOT_ALLOWED=3 CDR_NOT_ALLOWED value
         */
        ChangeDetailsResponse.UpdateStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CDR_OK"] = 0;
            values[valuesById[1] = "CDR_INVALID_VALUE"] = 1;
            values[valuesById[2] = "CDR_UNKNOWN_TYPE"] = 2;
            values[valuesById[3] = "CDR_NOT_ALLOWED"] = 3;
            return values;
        })();

        return ChangeDetailsResponse;
    })();

    api.UserData = (function() {

        /**
         * Properties of a UserData.
         * @memberof api
         * @interface IUserData
         * @property {Long|null} [userId] UserData userId
         * @property {entities.UserRegistrationStatus|null} [status] UserData status
         * @property {string|null} [nickname] UserData nickname
         * @property {boolean|null} [emailNotConfirmed] UserData emailNotConfirmed
         */

        /**
         * Constructs a new UserData.
         * @memberof api
         * @classdesc Represents a UserData.
         * @implements IUserData
         * @constructor
         * @param {api.IUserData=} [properties] Properties to set
         */
        function UserData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserData userId.
         * @member {Long} userId
         * @memberof api.UserData
         * @instance
         */
        UserData.prototype.userId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UserData status.
         * @member {entities.UserRegistrationStatus} status
         * @memberof api.UserData
         * @instance
         */
        UserData.prototype.status = 0;

        /**
         * UserData nickname.
         * @member {string} nickname
         * @memberof api.UserData
         * @instance
         */
        UserData.prototype.nickname = "";

        /**
         * UserData emailNotConfirmed.
         * @member {boolean} emailNotConfirmed
         * @memberof api.UserData
         * @instance
         */
        UserData.prototype.emailNotConfirmed = false;

        /**
         * Creates a new UserData instance using the specified properties.
         * @function create
         * @memberof api.UserData
         * @static
         * @param {api.IUserData=} [properties] Properties to set
         * @returns {api.UserData} UserData instance
         */
        UserData.create = function create(properties) {
            return new UserData(properties);
        };

        /**
         * Encodes the specified UserData message. Does not implicitly {@link api.UserData.verify|verify} messages.
         * @function encode
         * @memberof api.UserData
         * @static
         * @param {api.IUserData} message UserData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.userId);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
            if (message.nickname != null && Object.hasOwnProperty.call(message, "nickname"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.nickname);
            if (message.emailNotConfirmed != null && Object.hasOwnProperty.call(message, "emailNotConfirmed"))
                writer.uint32(/* id 20, wireType 0 =*/160).bool(message.emailNotConfirmed);
            return writer;
        };

        /**
         * Encodes the specified UserData message, length delimited. Does not implicitly {@link api.UserData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UserData
         * @static
         * @param {api.IUserData} message UserData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserData message from the specified reader or buffer.
         * @function decode
         * @memberof api.UserData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UserData} UserData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UserData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userId = reader.uint64();
                        break;
                    }
                case 2: {
                        message.status = reader.int32();
                        break;
                    }
                case 10: {
                        message.nickname = reader.string();
                        break;
                    }
                case 20: {
                        message.emailNotConfirmed = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UserData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UserData} UserData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserData message.
         * @function verify
         * @memberof api.UserData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isInteger(message.userId) && !(message.userId && $util.isInteger(message.userId.low) && $util.isInteger(message.userId.high)))
                    return "userId: integer|Long expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.nickname != null && message.hasOwnProperty("nickname"))
                if (!$util.isString(message.nickname))
                    return "nickname: string expected";
            if (message.emailNotConfirmed != null && message.hasOwnProperty("emailNotConfirmed"))
                if (typeof message.emailNotConfirmed !== "boolean")
                    return "emailNotConfirmed: boolean expected";
            return null;
        };

        /**
         * Creates a UserData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UserData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UserData} UserData
         */
        UserData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UserData)
                return object;
            let message = new $root.api.UserData();
            if (object.userId != null)
                if ($util.Long)
                    (message.userId = $util.Long.fromValue(object.userId)).unsigned = true;
                else if (typeof object.userId === "string")
                    message.userId = parseInt(object.userId, 10);
                else if (typeof object.userId === "number")
                    message.userId = object.userId;
                else if (typeof object.userId === "object")
                    message.userId = new $util.LongBits(object.userId.low >>> 0, object.userId.high >>> 0).toNumber(true);
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "UL_GUEST":
            case 0:
                message.status = 0;
                break;
            case "UL_REGISTERED":
            case 1:
                message.status = 1;
                break;
            }
            if (object.nickname != null)
                message.nickname = String(object.nickname);
            if (object.emailNotConfirmed != null)
                message.emailNotConfirmed = Boolean(object.emailNotConfirmed);
            return message;
        };

        /**
         * Creates a plain object from a UserData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UserData
         * @static
         * @param {api.UserData} message UserData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.userId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.userId = options.longs === String ? "0" : 0;
                object.status = options.enums === String ? "UL_GUEST" : 0;
                object.nickname = "";
                object.emailNotConfirmed = false;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (typeof message.userId === "number")
                    object.userId = options.longs === String ? String(message.userId) : message.userId;
                else
                    object.userId = options.longs === String ? $util.Long.prototype.toString.call(message.userId) : options.longs === Number ? new $util.LongBits(message.userId.low >>> 0, message.userId.high >>> 0).toNumber(true) : message.userId;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.entities.UserRegistrationStatus[message.status] === undefined ? message.status : $root.entities.UserRegistrationStatus[message.status] : message.status;
            if (message.nickname != null && message.hasOwnProperty("nickname"))
                object.nickname = message.nickname;
            if (message.emailNotConfirmed != null && message.hasOwnProperty("emailNotConfirmed"))
                object.emailNotConfirmed = message.emailNotConfirmed;
            return object;
        };

        /**
         * Converts this UserData to JSON.
         * @function toJSON
         * @memberof api.UserData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserData
         * @function getTypeUrl
         * @memberof api.UserData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.UserData";
        };

        return UserData;
    })();

    api.WorkspaceRequest = (function() {

        /**
         * Properties of a WorkspaceRequest.
         * @memberof api
         * @interface IWorkspaceRequest
         * @property {Uint8Array|null} [workspaceId] WorkspaceRequest workspaceId
         * @property {api.IWorkspacesListRequest|null} [workspaces] WorkspaceRequest workspaces
         * @property {api.IWorkspaceCreateRequest|null} [create] WorkspaceRequest create
         * @property {api.IWorkspaceUpdateRequest|null} [update] WorkspaceRequest update
         * @property {api.IWorkspaceMembersRequest|null} [members] WorkspaceRequest members
         * @property {api.IWorkspaceMembersSearchRequest|null} [search] WorkspaceRequest search
         * @property {api.IWorkspaceMembersCountRequest|null} [counts] WorkspaceRequest counts
         * @property {api.IWorkspaceInviteRequest|null} [invite] WorkspaceRequest invite
         * @property {api.IWorkspaceResendInviteRequest|null} [resendInvite] WorkspaceRequest resendInvite
         * @property {api.IWorkspaceCreateInviteRequest|null} [createInvite] WorkspaceRequest createInvite
         * @property {api.IWorkspaceInvitesRequest|null} [invitesRequest] WorkspaceRequest invitesRequest
         * @property {api.IWorkspaceInviteRequestNew|null} [inviteRequest] WorkspaceRequest inviteRequest
         * @property {api.IWorkspaceInviteDeleteRequest|null} [inviteDelete] WorkspaceRequest inviteDelete
         * @property {api.IWorkspaceAcceptInviteRequest|null} [inviteAccept] WorkspaceRequest inviteAccept
         * @property {api.IWorkspaceUpdateMemberRequest|null} [updateMember] WorkspaceRequest updateMember
         * @property {api.IWorkspaceSnippetsRequest|null} [snippets] WorkspaceRequest snippets
         * @property {api.IWorkspaceSnippetsSetRequest|null} [snippetsSet] WorkspaceRequest snippetsSet
         * @property {api.IWorkspaceGetAvailableTariffsRequest|null} [availableTariffs] WorkspaceRequest availableTariffs
         * @property {api.IWorkspaceGetBillingHistoryRequest|null} [billingHistory] WorkspaceRequest billingHistory
         * @property {api.IWorkspacePayForTariffRequest|null} [payForTariff] WorkspaceRequest payForTariff
         * @property {api.IWorkspaceGetCurrentTariffRequest|null} [currentTariff] WorkspaceRequest currentTariff
         * @property {api.IWorkspaceCancelCurrentTariff|null} [cancelCurrentTariff] WorkspaceRequest cancelCurrentTariff
         * @property {api.IWorkspaceResumeCurrentTariff|null} [resumeCurrentTariff] WorkspaceRequest resumeCurrentTariff
         * @property {api.IWorkspaceGetPaymentInvoiceRequest|null} [getPaymentInvoice] WorkspaceRequest getPaymentInvoice
         * @property {api.IWorkspaceGetCustomerPortalRequest|null} [getCustomerPortal] WorkspaceRequest getCustomerPortal
         */

        /**
         * Constructs a new WorkspaceRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceRequest.
         * @implements IWorkspaceRequest
         * @constructor
         * @param {api.IWorkspaceRequest=} [properties] Properties to set
         */
        function WorkspaceRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceRequest workspaceId.
         * @member {Uint8Array} workspaceId
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.workspaceId = $util.newBuffer([]);

        /**
         * WorkspaceRequest workspaces.
         * @member {api.IWorkspacesListRequest|null|undefined} workspaces
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.workspaces = null;

        /**
         * WorkspaceRequest create.
         * @member {api.IWorkspaceCreateRequest|null|undefined} create
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.create = null;

        /**
         * WorkspaceRequest update.
         * @member {api.IWorkspaceUpdateRequest|null|undefined} update
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.update = null;

        /**
         * WorkspaceRequest members.
         * @member {api.IWorkspaceMembersRequest|null|undefined} members
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.members = null;

        /**
         * WorkspaceRequest search.
         * @member {api.IWorkspaceMembersSearchRequest|null|undefined} search
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.search = null;

        /**
         * WorkspaceRequest counts.
         * @member {api.IWorkspaceMembersCountRequest|null|undefined} counts
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.counts = null;

        /**
         * WorkspaceRequest invite.
         * @member {api.IWorkspaceInviteRequest|null|undefined} invite
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.invite = null;

        /**
         * WorkspaceRequest resendInvite.
         * @member {api.IWorkspaceResendInviteRequest|null|undefined} resendInvite
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.resendInvite = null;

        /**
         * WorkspaceRequest createInvite.
         * @member {api.IWorkspaceCreateInviteRequest|null|undefined} createInvite
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.createInvite = null;

        /**
         * WorkspaceRequest invitesRequest.
         * @member {api.IWorkspaceInvitesRequest|null|undefined} invitesRequest
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.invitesRequest = null;

        /**
         * WorkspaceRequest inviteRequest.
         * @member {api.IWorkspaceInviteRequestNew|null|undefined} inviteRequest
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.inviteRequest = null;

        /**
         * WorkspaceRequest inviteDelete.
         * @member {api.IWorkspaceInviteDeleteRequest|null|undefined} inviteDelete
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.inviteDelete = null;

        /**
         * WorkspaceRequest inviteAccept.
         * @member {api.IWorkspaceAcceptInviteRequest|null|undefined} inviteAccept
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.inviteAccept = null;

        /**
         * WorkspaceRequest updateMember.
         * @member {api.IWorkspaceUpdateMemberRequest|null|undefined} updateMember
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.updateMember = null;

        /**
         * WorkspaceRequest snippets.
         * @member {api.IWorkspaceSnippetsRequest|null|undefined} snippets
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.snippets = null;

        /**
         * WorkspaceRequest snippetsSet.
         * @member {api.IWorkspaceSnippetsSetRequest|null|undefined} snippetsSet
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.snippetsSet = null;

        /**
         * WorkspaceRequest availableTariffs.
         * @member {api.IWorkspaceGetAvailableTariffsRequest|null|undefined} availableTariffs
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.availableTariffs = null;

        /**
         * WorkspaceRequest billingHistory.
         * @member {api.IWorkspaceGetBillingHistoryRequest|null|undefined} billingHistory
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.billingHistory = null;

        /**
         * WorkspaceRequest payForTariff.
         * @member {api.IWorkspacePayForTariffRequest|null|undefined} payForTariff
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.payForTariff = null;

        /**
         * WorkspaceRequest currentTariff.
         * @member {api.IWorkspaceGetCurrentTariffRequest|null|undefined} currentTariff
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.currentTariff = null;

        /**
         * WorkspaceRequest cancelCurrentTariff.
         * @member {api.IWorkspaceCancelCurrentTariff|null|undefined} cancelCurrentTariff
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.cancelCurrentTariff = null;

        /**
         * WorkspaceRequest resumeCurrentTariff.
         * @member {api.IWorkspaceResumeCurrentTariff|null|undefined} resumeCurrentTariff
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.resumeCurrentTariff = null;

        /**
         * WorkspaceRequest getPaymentInvoice.
         * @member {api.IWorkspaceGetPaymentInvoiceRequest|null|undefined} getPaymentInvoice
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.getPaymentInvoice = null;

        /**
         * WorkspaceRequest getCustomerPortal.
         * @member {api.IWorkspaceGetCustomerPortalRequest|null|undefined} getCustomerPortal
         * @memberof api.WorkspaceRequest
         * @instance
         */
        WorkspaceRequest.prototype.getCustomerPortal = null;

        /**
         * Creates a new WorkspaceRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceRequest
         * @static
         * @param {api.IWorkspaceRequest=} [properties] Properties to set
         * @returns {api.WorkspaceRequest} WorkspaceRequest instance
         */
        WorkspaceRequest.create = function create(properties) {
            return new WorkspaceRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceRequest message. Does not implicitly {@link api.WorkspaceRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceRequest
         * @static
         * @param {api.IWorkspaceRequest} message WorkspaceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.workspaceId != null && Object.hasOwnProperty.call(message, "workspaceId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.workspaceId);
            if (message.workspaces != null && Object.hasOwnProperty.call(message, "workspaces"))
                $root.api.WorkspacesListRequest.encode(message.workspaces, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.create != null && Object.hasOwnProperty.call(message, "create"))
                $root.api.WorkspaceCreateRequest.encode(message.create, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.WorkspaceUpdateRequest.encode(message.update, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.members != null && Object.hasOwnProperty.call(message, "members"))
                $root.api.WorkspaceMembersRequest.encode(message.members, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.search != null && Object.hasOwnProperty.call(message, "search"))
                $root.api.WorkspaceMembersSearchRequest.encode(message.search, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.counts != null && Object.hasOwnProperty.call(message, "counts"))
                $root.api.WorkspaceMembersCountRequest.encode(message.counts, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.invite != null && Object.hasOwnProperty.call(message, "invite"))
                $root.api.WorkspaceInviteRequest.encode(message.invite, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.resendInvite != null && Object.hasOwnProperty.call(message, "resendInvite"))
                $root.api.WorkspaceResendInviteRequest.encode(message.resendInvite, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.createInvite != null && Object.hasOwnProperty.call(message, "createInvite"))
                $root.api.WorkspaceCreateInviteRequest.encode(message.createInvite, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.invitesRequest != null && Object.hasOwnProperty.call(message, "invitesRequest"))
                $root.api.WorkspaceInvitesRequest.encode(message.invitesRequest, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.inviteRequest != null && Object.hasOwnProperty.call(message, "inviteRequest"))
                $root.api.WorkspaceInviteRequestNew.encode(message.inviteRequest, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.inviteDelete != null && Object.hasOwnProperty.call(message, "inviteDelete"))
                $root.api.WorkspaceInviteDeleteRequest.encode(message.inviteDelete, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.inviteAccept != null && Object.hasOwnProperty.call(message, "inviteAccept"))
                $root.api.WorkspaceAcceptInviteRequest.encode(message.inviteAccept, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.updateMember != null && Object.hasOwnProperty.call(message, "updateMember"))
                $root.api.WorkspaceUpdateMemberRequest.encode(message.updateMember, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.snippets != null && Object.hasOwnProperty.call(message, "snippets"))
                $root.api.WorkspaceSnippetsRequest.encode(message.snippets, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
            if (message.snippetsSet != null && Object.hasOwnProperty.call(message, "snippetsSet"))
                $root.api.WorkspaceSnippetsSetRequest.encode(message.snippetsSet, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
            if (message.availableTariffs != null && Object.hasOwnProperty.call(message, "availableTariffs"))
                $root.api.WorkspaceGetAvailableTariffsRequest.encode(message.availableTariffs, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
            if (message.billingHistory != null && Object.hasOwnProperty.call(message, "billingHistory"))
                $root.api.WorkspaceGetBillingHistoryRequest.encode(message.billingHistory, writer.uint32(/* id 101, wireType 2 =*/810).fork()).ldelim();
            if (message.payForTariff != null && Object.hasOwnProperty.call(message, "payForTariff"))
                $root.api.WorkspacePayForTariffRequest.encode(message.payForTariff, writer.uint32(/* id 102, wireType 2 =*/818).fork()).ldelim();
            if (message.currentTariff != null && Object.hasOwnProperty.call(message, "currentTariff"))
                $root.api.WorkspaceGetCurrentTariffRequest.encode(message.currentTariff, writer.uint32(/* id 103, wireType 2 =*/826).fork()).ldelim();
            if (message.cancelCurrentTariff != null && Object.hasOwnProperty.call(message, "cancelCurrentTariff"))
                $root.api.WorkspaceCancelCurrentTariff.encode(message.cancelCurrentTariff, writer.uint32(/* id 104, wireType 2 =*/834).fork()).ldelim();
            if (message.resumeCurrentTariff != null && Object.hasOwnProperty.call(message, "resumeCurrentTariff"))
                $root.api.WorkspaceResumeCurrentTariff.encode(message.resumeCurrentTariff, writer.uint32(/* id 105, wireType 2 =*/842).fork()).ldelim();
            if (message.getPaymentInvoice != null && Object.hasOwnProperty.call(message, "getPaymentInvoice"))
                $root.api.WorkspaceGetPaymentInvoiceRequest.encode(message.getPaymentInvoice, writer.uint32(/* id 106, wireType 2 =*/850).fork()).ldelim();
            if (message.getCustomerPortal != null && Object.hasOwnProperty.call(message, "getCustomerPortal"))
                $root.api.WorkspaceGetCustomerPortalRequest.encode(message.getCustomerPortal, writer.uint32(/* id 107, wireType 2 =*/858).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceRequest message, length delimited. Does not implicitly {@link api.WorkspaceRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceRequest
         * @static
         * @param {api.IWorkspaceRequest} message WorkspaceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceRequest} WorkspaceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.workspaceId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.workspaces = $root.api.WorkspacesListRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.create = $root.api.WorkspaceCreateRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.update = $root.api.WorkspaceUpdateRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.members = $root.api.WorkspaceMembersRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.search = $root.api.WorkspaceMembersSearchRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.counts = $root.api.WorkspaceMembersCountRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.invite = $root.api.WorkspaceInviteRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.resendInvite = $root.api.WorkspaceResendInviteRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.createInvite = $root.api.WorkspaceCreateInviteRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 23: {
                        message.invitesRequest = $root.api.WorkspaceInvitesRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 24: {
                        message.inviteRequest = $root.api.WorkspaceInviteRequestNew.decode(reader, reader.uint32());
                        break;
                    }
                case 25: {
                        message.inviteDelete = $root.api.WorkspaceInviteDeleteRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 26: {
                        message.inviteAccept = $root.api.WorkspaceAcceptInviteRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 30: {
                        message.updateMember = $root.api.WorkspaceUpdateMemberRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 40: {
                        message.snippets = $root.api.WorkspaceSnippetsRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 41: {
                        message.snippetsSet = $root.api.WorkspaceSnippetsSetRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 100: {
                        message.availableTariffs = $root.api.WorkspaceGetAvailableTariffsRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 101: {
                        message.billingHistory = $root.api.WorkspaceGetBillingHistoryRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 102: {
                        message.payForTariff = $root.api.WorkspacePayForTariffRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 103: {
                        message.currentTariff = $root.api.WorkspaceGetCurrentTariffRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 104: {
                        message.cancelCurrentTariff = $root.api.WorkspaceCancelCurrentTariff.decode(reader, reader.uint32());
                        break;
                    }
                case 105: {
                        message.resumeCurrentTariff = $root.api.WorkspaceResumeCurrentTariff.decode(reader, reader.uint32());
                        break;
                    }
                case 106: {
                        message.getPaymentInvoice = $root.api.WorkspaceGetPaymentInvoiceRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 107: {
                        message.getCustomerPortal = $root.api.WorkspaceGetCustomerPortalRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceRequest} WorkspaceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceRequest message.
         * @function verify
         * @memberof api.WorkspaceRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                if (!(message.workspaceId && typeof message.workspaceId.length === "number" || $util.isString(message.workspaceId)))
                    return "workspaceId: buffer expected";
            if (message.workspaces != null && message.hasOwnProperty("workspaces")) {
                let error = $root.api.WorkspacesListRequest.verify(message.workspaces);
                if (error)
                    return "workspaces." + error;
            }
            if (message.create != null && message.hasOwnProperty("create")) {
                let error = $root.api.WorkspaceCreateRequest.verify(message.create);
                if (error)
                    return "create." + error;
            }
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.WorkspaceUpdateRequest.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.members != null && message.hasOwnProperty("members")) {
                let error = $root.api.WorkspaceMembersRequest.verify(message.members);
                if (error)
                    return "members." + error;
            }
            if (message.search != null && message.hasOwnProperty("search")) {
                let error = $root.api.WorkspaceMembersSearchRequest.verify(message.search);
                if (error)
                    return "search." + error;
            }
            if (message.counts != null && message.hasOwnProperty("counts")) {
                let error = $root.api.WorkspaceMembersCountRequest.verify(message.counts);
                if (error)
                    return "counts." + error;
            }
            if (message.invite != null && message.hasOwnProperty("invite")) {
                let error = $root.api.WorkspaceInviteRequest.verify(message.invite);
                if (error)
                    return "invite." + error;
            }
            if (message.resendInvite != null && message.hasOwnProperty("resendInvite")) {
                let error = $root.api.WorkspaceResendInviteRequest.verify(message.resendInvite);
                if (error)
                    return "resendInvite." + error;
            }
            if (message.createInvite != null && message.hasOwnProperty("createInvite")) {
                let error = $root.api.WorkspaceCreateInviteRequest.verify(message.createInvite);
                if (error)
                    return "createInvite." + error;
            }
            if (message.invitesRequest != null && message.hasOwnProperty("invitesRequest")) {
                let error = $root.api.WorkspaceInvitesRequest.verify(message.invitesRequest);
                if (error)
                    return "invitesRequest." + error;
            }
            if (message.inviteRequest != null && message.hasOwnProperty("inviteRequest")) {
                let error = $root.api.WorkspaceInviteRequestNew.verify(message.inviteRequest);
                if (error)
                    return "inviteRequest." + error;
            }
            if (message.inviteDelete != null && message.hasOwnProperty("inviteDelete")) {
                let error = $root.api.WorkspaceInviteDeleteRequest.verify(message.inviteDelete);
                if (error)
                    return "inviteDelete." + error;
            }
            if (message.inviteAccept != null && message.hasOwnProperty("inviteAccept")) {
                let error = $root.api.WorkspaceAcceptInviteRequest.verify(message.inviteAccept);
                if (error)
                    return "inviteAccept." + error;
            }
            if (message.updateMember != null && message.hasOwnProperty("updateMember")) {
                let error = $root.api.WorkspaceUpdateMemberRequest.verify(message.updateMember);
                if (error)
                    return "updateMember." + error;
            }
            if (message.snippets != null && message.hasOwnProperty("snippets")) {
                let error = $root.api.WorkspaceSnippetsRequest.verify(message.snippets);
                if (error)
                    return "snippets." + error;
            }
            if (message.snippetsSet != null && message.hasOwnProperty("snippetsSet")) {
                let error = $root.api.WorkspaceSnippetsSetRequest.verify(message.snippetsSet);
                if (error)
                    return "snippetsSet." + error;
            }
            if (message.availableTariffs != null && message.hasOwnProperty("availableTariffs")) {
                let error = $root.api.WorkspaceGetAvailableTariffsRequest.verify(message.availableTariffs);
                if (error)
                    return "availableTariffs." + error;
            }
            if (message.billingHistory != null && message.hasOwnProperty("billingHistory")) {
                let error = $root.api.WorkspaceGetBillingHistoryRequest.verify(message.billingHistory);
                if (error)
                    return "billingHistory." + error;
            }
            if (message.payForTariff != null && message.hasOwnProperty("payForTariff")) {
                let error = $root.api.WorkspacePayForTariffRequest.verify(message.payForTariff);
                if (error)
                    return "payForTariff." + error;
            }
            if (message.currentTariff != null && message.hasOwnProperty("currentTariff")) {
                let error = $root.api.WorkspaceGetCurrentTariffRequest.verify(message.currentTariff);
                if (error)
                    return "currentTariff." + error;
            }
            if (message.cancelCurrentTariff != null && message.hasOwnProperty("cancelCurrentTariff")) {
                let error = $root.api.WorkspaceCancelCurrentTariff.verify(message.cancelCurrentTariff);
                if (error)
                    return "cancelCurrentTariff." + error;
            }
            if (message.resumeCurrentTariff != null && message.hasOwnProperty("resumeCurrentTariff")) {
                let error = $root.api.WorkspaceResumeCurrentTariff.verify(message.resumeCurrentTariff);
                if (error)
                    return "resumeCurrentTariff." + error;
            }
            if (message.getPaymentInvoice != null && message.hasOwnProperty("getPaymentInvoice")) {
                let error = $root.api.WorkspaceGetPaymentInvoiceRequest.verify(message.getPaymentInvoice);
                if (error)
                    return "getPaymentInvoice." + error;
            }
            if (message.getCustomerPortal != null && message.hasOwnProperty("getCustomerPortal")) {
                let error = $root.api.WorkspaceGetCustomerPortalRequest.verify(message.getCustomerPortal);
                if (error)
                    return "getCustomerPortal." + error;
            }
            return null;
        };

        /**
         * Creates a WorkspaceRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceRequest} WorkspaceRequest
         */
        WorkspaceRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceRequest)
                return object;
            let message = new $root.api.WorkspaceRequest();
            if (object.workspaceId != null)
                if (typeof object.workspaceId === "string")
                    $util.base64.decode(object.workspaceId, message.workspaceId = $util.newBuffer($util.base64.length(object.workspaceId)), 0);
                else if (object.workspaceId.length >= 0)
                    message.workspaceId = object.workspaceId;
            if (object.workspaces != null) {
                if (typeof object.workspaces !== "object")
                    throw TypeError(".api.WorkspaceRequest.workspaces: object expected");
                message.workspaces = $root.api.WorkspacesListRequest.fromObject(object.workspaces);
            }
            if (object.create != null) {
                if (typeof object.create !== "object")
                    throw TypeError(".api.WorkspaceRequest.create: object expected");
                message.create = $root.api.WorkspaceCreateRequest.fromObject(object.create);
            }
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.WorkspaceRequest.update: object expected");
                message.update = $root.api.WorkspaceUpdateRequest.fromObject(object.update);
            }
            if (object.members != null) {
                if (typeof object.members !== "object")
                    throw TypeError(".api.WorkspaceRequest.members: object expected");
                message.members = $root.api.WorkspaceMembersRequest.fromObject(object.members);
            }
            if (object.search != null) {
                if (typeof object.search !== "object")
                    throw TypeError(".api.WorkspaceRequest.search: object expected");
                message.search = $root.api.WorkspaceMembersSearchRequest.fromObject(object.search);
            }
            if (object.counts != null) {
                if (typeof object.counts !== "object")
                    throw TypeError(".api.WorkspaceRequest.counts: object expected");
                message.counts = $root.api.WorkspaceMembersCountRequest.fromObject(object.counts);
            }
            if (object.invite != null) {
                if (typeof object.invite !== "object")
                    throw TypeError(".api.WorkspaceRequest.invite: object expected");
                message.invite = $root.api.WorkspaceInviteRequest.fromObject(object.invite);
            }
            if (object.resendInvite != null) {
                if (typeof object.resendInvite !== "object")
                    throw TypeError(".api.WorkspaceRequest.resendInvite: object expected");
                message.resendInvite = $root.api.WorkspaceResendInviteRequest.fromObject(object.resendInvite);
            }
            if (object.createInvite != null) {
                if (typeof object.createInvite !== "object")
                    throw TypeError(".api.WorkspaceRequest.createInvite: object expected");
                message.createInvite = $root.api.WorkspaceCreateInviteRequest.fromObject(object.createInvite);
            }
            if (object.invitesRequest != null) {
                if (typeof object.invitesRequest !== "object")
                    throw TypeError(".api.WorkspaceRequest.invitesRequest: object expected");
                message.invitesRequest = $root.api.WorkspaceInvitesRequest.fromObject(object.invitesRequest);
            }
            if (object.inviteRequest != null) {
                if (typeof object.inviteRequest !== "object")
                    throw TypeError(".api.WorkspaceRequest.inviteRequest: object expected");
                message.inviteRequest = $root.api.WorkspaceInviteRequestNew.fromObject(object.inviteRequest);
            }
            if (object.inviteDelete != null) {
                if (typeof object.inviteDelete !== "object")
                    throw TypeError(".api.WorkspaceRequest.inviteDelete: object expected");
                message.inviteDelete = $root.api.WorkspaceInviteDeleteRequest.fromObject(object.inviteDelete);
            }
            if (object.inviteAccept != null) {
                if (typeof object.inviteAccept !== "object")
                    throw TypeError(".api.WorkspaceRequest.inviteAccept: object expected");
                message.inviteAccept = $root.api.WorkspaceAcceptInviteRequest.fromObject(object.inviteAccept);
            }
            if (object.updateMember != null) {
                if (typeof object.updateMember !== "object")
                    throw TypeError(".api.WorkspaceRequest.updateMember: object expected");
                message.updateMember = $root.api.WorkspaceUpdateMemberRequest.fromObject(object.updateMember);
            }
            if (object.snippets != null) {
                if (typeof object.snippets !== "object")
                    throw TypeError(".api.WorkspaceRequest.snippets: object expected");
                message.snippets = $root.api.WorkspaceSnippetsRequest.fromObject(object.snippets);
            }
            if (object.snippetsSet != null) {
                if (typeof object.snippetsSet !== "object")
                    throw TypeError(".api.WorkspaceRequest.snippetsSet: object expected");
                message.snippetsSet = $root.api.WorkspaceSnippetsSetRequest.fromObject(object.snippetsSet);
            }
            if (object.availableTariffs != null) {
                if (typeof object.availableTariffs !== "object")
                    throw TypeError(".api.WorkspaceRequest.availableTariffs: object expected");
                message.availableTariffs = $root.api.WorkspaceGetAvailableTariffsRequest.fromObject(object.availableTariffs);
            }
            if (object.billingHistory != null) {
                if (typeof object.billingHistory !== "object")
                    throw TypeError(".api.WorkspaceRequest.billingHistory: object expected");
                message.billingHistory = $root.api.WorkspaceGetBillingHistoryRequest.fromObject(object.billingHistory);
            }
            if (object.payForTariff != null) {
                if (typeof object.payForTariff !== "object")
                    throw TypeError(".api.WorkspaceRequest.payForTariff: object expected");
                message.payForTariff = $root.api.WorkspacePayForTariffRequest.fromObject(object.payForTariff);
            }
            if (object.currentTariff != null) {
                if (typeof object.currentTariff !== "object")
                    throw TypeError(".api.WorkspaceRequest.currentTariff: object expected");
                message.currentTariff = $root.api.WorkspaceGetCurrentTariffRequest.fromObject(object.currentTariff);
            }
            if (object.cancelCurrentTariff != null) {
                if (typeof object.cancelCurrentTariff !== "object")
                    throw TypeError(".api.WorkspaceRequest.cancelCurrentTariff: object expected");
                message.cancelCurrentTariff = $root.api.WorkspaceCancelCurrentTariff.fromObject(object.cancelCurrentTariff);
            }
            if (object.resumeCurrentTariff != null) {
                if (typeof object.resumeCurrentTariff !== "object")
                    throw TypeError(".api.WorkspaceRequest.resumeCurrentTariff: object expected");
                message.resumeCurrentTariff = $root.api.WorkspaceResumeCurrentTariff.fromObject(object.resumeCurrentTariff);
            }
            if (object.getPaymentInvoice != null) {
                if (typeof object.getPaymentInvoice !== "object")
                    throw TypeError(".api.WorkspaceRequest.getPaymentInvoice: object expected");
                message.getPaymentInvoice = $root.api.WorkspaceGetPaymentInvoiceRequest.fromObject(object.getPaymentInvoice);
            }
            if (object.getCustomerPortal != null) {
                if (typeof object.getCustomerPortal !== "object")
                    throw TypeError(".api.WorkspaceRequest.getCustomerPortal: object expected");
                message.getCustomerPortal = $root.api.WorkspaceGetCustomerPortalRequest.fromObject(object.getCustomerPortal);
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceRequest
         * @static
         * @param {api.WorkspaceRequest} message WorkspaceRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.workspaceId = "";
                else {
                    object.workspaceId = [];
                    if (options.bytes !== Array)
                        object.workspaceId = $util.newBuffer(object.workspaceId);
                }
                object.workspaces = null;
                object.create = null;
                object.update = null;
                object.members = null;
                object.search = null;
                object.counts = null;
                object.invite = null;
                object.resendInvite = null;
                object.createInvite = null;
                object.invitesRequest = null;
                object.inviteRequest = null;
                object.inviteDelete = null;
                object.inviteAccept = null;
                object.updateMember = null;
                object.snippets = null;
                object.snippetsSet = null;
                object.availableTariffs = null;
                object.billingHistory = null;
                object.payForTariff = null;
                object.currentTariff = null;
                object.cancelCurrentTariff = null;
                object.resumeCurrentTariff = null;
                object.getPaymentInvoice = null;
                object.getCustomerPortal = null;
            }
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                object.workspaceId = options.bytes === String ? $util.base64.encode(message.workspaceId, 0, message.workspaceId.length) : options.bytes === Array ? Array.prototype.slice.call(message.workspaceId) : message.workspaceId;
            if (message.workspaces != null && message.hasOwnProperty("workspaces"))
                object.workspaces = $root.api.WorkspacesListRequest.toObject(message.workspaces, options);
            if (message.create != null && message.hasOwnProperty("create"))
                object.create = $root.api.WorkspaceCreateRequest.toObject(message.create, options);
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.WorkspaceUpdateRequest.toObject(message.update, options);
            if (message.members != null && message.hasOwnProperty("members"))
                object.members = $root.api.WorkspaceMembersRequest.toObject(message.members, options);
            if (message.search != null && message.hasOwnProperty("search"))
                object.search = $root.api.WorkspaceMembersSearchRequest.toObject(message.search, options);
            if (message.counts != null && message.hasOwnProperty("counts"))
                object.counts = $root.api.WorkspaceMembersCountRequest.toObject(message.counts, options);
            if (message.invite != null && message.hasOwnProperty("invite"))
                object.invite = $root.api.WorkspaceInviteRequest.toObject(message.invite, options);
            if (message.resendInvite != null && message.hasOwnProperty("resendInvite"))
                object.resendInvite = $root.api.WorkspaceResendInviteRequest.toObject(message.resendInvite, options);
            if (message.createInvite != null && message.hasOwnProperty("createInvite"))
                object.createInvite = $root.api.WorkspaceCreateInviteRequest.toObject(message.createInvite, options);
            if (message.invitesRequest != null && message.hasOwnProperty("invitesRequest"))
                object.invitesRequest = $root.api.WorkspaceInvitesRequest.toObject(message.invitesRequest, options);
            if (message.inviteRequest != null && message.hasOwnProperty("inviteRequest"))
                object.inviteRequest = $root.api.WorkspaceInviteRequestNew.toObject(message.inviteRequest, options);
            if (message.inviteDelete != null && message.hasOwnProperty("inviteDelete"))
                object.inviteDelete = $root.api.WorkspaceInviteDeleteRequest.toObject(message.inviteDelete, options);
            if (message.inviteAccept != null && message.hasOwnProperty("inviteAccept"))
                object.inviteAccept = $root.api.WorkspaceAcceptInviteRequest.toObject(message.inviteAccept, options);
            if (message.updateMember != null && message.hasOwnProperty("updateMember"))
                object.updateMember = $root.api.WorkspaceUpdateMemberRequest.toObject(message.updateMember, options);
            if (message.snippets != null && message.hasOwnProperty("snippets"))
                object.snippets = $root.api.WorkspaceSnippetsRequest.toObject(message.snippets, options);
            if (message.snippetsSet != null && message.hasOwnProperty("snippetsSet"))
                object.snippetsSet = $root.api.WorkspaceSnippetsSetRequest.toObject(message.snippetsSet, options);
            if (message.availableTariffs != null && message.hasOwnProperty("availableTariffs"))
                object.availableTariffs = $root.api.WorkspaceGetAvailableTariffsRequest.toObject(message.availableTariffs, options);
            if (message.billingHistory != null && message.hasOwnProperty("billingHistory"))
                object.billingHistory = $root.api.WorkspaceGetBillingHistoryRequest.toObject(message.billingHistory, options);
            if (message.payForTariff != null && message.hasOwnProperty("payForTariff"))
                object.payForTariff = $root.api.WorkspacePayForTariffRequest.toObject(message.payForTariff, options);
            if (message.currentTariff != null && message.hasOwnProperty("currentTariff"))
                object.currentTariff = $root.api.WorkspaceGetCurrentTariffRequest.toObject(message.currentTariff, options);
            if (message.cancelCurrentTariff != null && message.hasOwnProperty("cancelCurrentTariff"))
                object.cancelCurrentTariff = $root.api.WorkspaceCancelCurrentTariff.toObject(message.cancelCurrentTariff, options);
            if (message.resumeCurrentTariff != null && message.hasOwnProperty("resumeCurrentTariff"))
                object.resumeCurrentTariff = $root.api.WorkspaceResumeCurrentTariff.toObject(message.resumeCurrentTariff, options);
            if (message.getPaymentInvoice != null && message.hasOwnProperty("getPaymentInvoice"))
                object.getPaymentInvoice = $root.api.WorkspaceGetPaymentInvoiceRequest.toObject(message.getPaymentInvoice, options);
            if (message.getCustomerPortal != null && message.hasOwnProperty("getCustomerPortal"))
                object.getCustomerPortal = $root.api.WorkspaceGetCustomerPortalRequest.toObject(message.getCustomerPortal, options);
            return object;
        };

        /**
         * Converts this WorkspaceRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceRequest";
        };

        return WorkspaceRequest;
    })();

    api.WorkspaceResponse = (function() {

        /**
         * Properties of a WorkspaceResponse.
         * @memberof api
         * @interface IWorkspaceResponse
         * @property {Uint8Array|null} [workspaceId] WorkspaceResponse workspaceId
         * @property {entities.WorkspaceRole|null} [role] WorkspaceResponse role
         * @property {api.IWorkspacesListResponse|null} [workspaces] WorkspaceResponse workspaces
         * @property {api.IWorkspaceCreateResponse|null} [create] WorkspaceResponse create
         * @property {api.IWorkspaceUpdateResponse|null} [update] WorkspaceResponse update
         * @property {api.IWorkspaceMembersResponse|null} [members] WorkspaceResponse members
         * @property {api.IWorkspaceMembersSearchResponse|null} [search] WorkspaceResponse search
         * @property {api.IWorkspaceMembersCountResponse|null} [counts] WorkspaceResponse counts
         * @property {api.IWorkspaceInviteResponse|null} [invite] WorkspaceResponse invite
         * @property {api.IWorkspaceResendInviteResponse|null} [resendInvite] WorkspaceResponse resendInvite
         * @property {api.IWorkspaceCreateInviteResponse|null} [createInvite] WorkspaceResponse createInvite
         * @property {api.IWorkspaceInvitesResponse|null} [invitesRequest] WorkspaceResponse invitesRequest
         * @property {api.IWorkspaceInviteResponseNew|null} [inviteRequest] WorkspaceResponse inviteRequest
         * @property {api.IWorkspaceInviteDeleteResponse|null} [inviteDelete] WorkspaceResponse inviteDelete
         * @property {api.IWorkspaceAcceptInviteResponse|null} [inviteAccept] WorkspaceResponse inviteAccept
         * @property {api.IWorkspaceUpdateMemberResponse|null} [updateMember] WorkspaceResponse updateMember
         * @property {api.IWorkspaceSnippetsResponse|null} [snippets] WorkspaceResponse snippets
         * @property {api.IWorkspaceSnippetsSetResponse|null} [snippetsSet] WorkspaceResponse snippetsSet
         * @property {api.IWorkspaceGetAvailableTariffsResponse|null} [availableTariffs] WorkspaceResponse availableTariffs
         * @property {api.IWorkspaceGetBillingHistoryResponse|null} [billingHistory] WorkspaceResponse billingHistory
         * @property {api.IWorkspacePayForTariffResponse|null} [payForTariff] WorkspaceResponse payForTariff
         * @property {api.IWorkspaceGetCurrentTariffResponse|null} [currentTariff] WorkspaceResponse currentTariff
         * @property {api.IWorkspaceCancelCurrentTariffResponse|null} [cancelCurrentTariff] WorkspaceResponse cancelCurrentTariff
         * @property {api.IWorkspaceResumeCurrentTariffResponse|null} [resumeCurrentTariff] WorkspaceResponse resumeCurrentTariff
         * @property {api.IWorkspaceGetPaymentInvoiceResponse|null} [getPaymentInvoice] WorkspaceResponse getPaymentInvoice
         * @property {api.IWorkspaceGetCustomerPortalResponse|null} [getCustomerPortal] WorkspaceResponse getCustomerPortal
         */

        /**
         * Constructs a new WorkspaceResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceResponse.
         * @implements IWorkspaceResponse
         * @constructor
         * @param {api.IWorkspaceResponse=} [properties] Properties to set
         */
        function WorkspaceResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceResponse workspaceId.
         * @member {Uint8Array} workspaceId
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.workspaceId = $util.newBuffer([]);

        /**
         * WorkspaceResponse role.
         * @member {entities.WorkspaceRole} role
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.role = 0;

        /**
         * WorkspaceResponse workspaces.
         * @member {api.IWorkspacesListResponse|null|undefined} workspaces
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.workspaces = null;

        /**
         * WorkspaceResponse create.
         * @member {api.IWorkspaceCreateResponse|null|undefined} create
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.create = null;

        /**
         * WorkspaceResponse update.
         * @member {api.IWorkspaceUpdateResponse|null|undefined} update
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.update = null;

        /**
         * WorkspaceResponse members.
         * @member {api.IWorkspaceMembersResponse|null|undefined} members
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.members = null;

        /**
         * WorkspaceResponse search.
         * @member {api.IWorkspaceMembersSearchResponse|null|undefined} search
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.search = null;

        /**
         * WorkspaceResponse counts.
         * @member {api.IWorkspaceMembersCountResponse|null|undefined} counts
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.counts = null;

        /**
         * WorkspaceResponse invite.
         * @member {api.IWorkspaceInviteResponse|null|undefined} invite
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.invite = null;

        /**
         * WorkspaceResponse resendInvite.
         * @member {api.IWorkspaceResendInviteResponse|null|undefined} resendInvite
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.resendInvite = null;

        /**
         * WorkspaceResponse createInvite.
         * @member {api.IWorkspaceCreateInviteResponse|null|undefined} createInvite
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.createInvite = null;

        /**
         * WorkspaceResponse invitesRequest.
         * @member {api.IWorkspaceInvitesResponse|null|undefined} invitesRequest
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.invitesRequest = null;

        /**
         * WorkspaceResponse inviteRequest.
         * @member {api.IWorkspaceInviteResponseNew|null|undefined} inviteRequest
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.inviteRequest = null;

        /**
         * WorkspaceResponse inviteDelete.
         * @member {api.IWorkspaceInviteDeleteResponse|null|undefined} inviteDelete
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.inviteDelete = null;

        /**
         * WorkspaceResponse inviteAccept.
         * @member {api.IWorkspaceAcceptInviteResponse|null|undefined} inviteAccept
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.inviteAccept = null;

        /**
         * WorkspaceResponse updateMember.
         * @member {api.IWorkspaceUpdateMemberResponse|null|undefined} updateMember
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.updateMember = null;

        /**
         * WorkspaceResponse snippets.
         * @member {api.IWorkspaceSnippetsResponse|null|undefined} snippets
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.snippets = null;

        /**
         * WorkspaceResponse snippetsSet.
         * @member {api.IWorkspaceSnippetsSetResponse|null|undefined} snippetsSet
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.snippetsSet = null;

        /**
         * WorkspaceResponse availableTariffs.
         * @member {api.IWorkspaceGetAvailableTariffsResponse|null|undefined} availableTariffs
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.availableTariffs = null;

        /**
         * WorkspaceResponse billingHistory.
         * @member {api.IWorkspaceGetBillingHistoryResponse|null|undefined} billingHistory
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.billingHistory = null;

        /**
         * WorkspaceResponse payForTariff.
         * @member {api.IWorkspacePayForTariffResponse|null|undefined} payForTariff
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.payForTariff = null;

        /**
         * WorkspaceResponse currentTariff.
         * @member {api.IWorkspaceGetCurrentTariffResponse|null|undefined} currentTariff
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.currentTariff = null;

        /**
         * WorkspaceResponse cancelCurrentTariff.
         * @member {api.IWorkspaceCancelCurrentTariffResponse|null|undefined} cancelCurrentTariff
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.cancelCurrentTariff = null;

        /**
         * WorkspaceResponse resumeCurrentTariff.
         * @member {api.IWorkspaceResumeCurrentTariffResponse|null|undefined} resumeCurrentTariff
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.resumeCurrentTariff = null;

        /**
         * WorkspaceResponse getPaymentInvoice.
         * @member {api.IWorkspaceGetPaymentInvoiceResponse|null|undefined} getPaymentInvoice
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.getPaymentInvoice = null;

        /**
         * WorkspaceResponse getCustomerPortal.
         * @member {api.IWorkspaceGetCustomerPortalResponse|null|undefined} getCustomerPortal
         * @memberof api.WorkspaceResponse
         * @instance
         */
        WorkspaceResponse.prototype.getCustomerPortal = null;

        /**
         * Creates a new WorkspaceResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceResponse
         * @static
         * @param {api.IWorkspaceResponse=} [properties] Properties to set
         * @returns {api.WorkspaceResponse} WorkspaceResponse instance
         */
        WorkspaceResponse.create = function create(properties) {
            return new WorkspaceResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceResponse message. Does not implicitly {@link api.WorkspaceResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceResponse
         * @static
         * @param {api.IWorkspaceResponse} message WorkspaceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.workspaceId != null && Object.hasOwnProperty.call(message, "workspaceId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.workspaceId);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.role);
            if (message.workspaces != null && Object.hasOwnProperty.call(message, "workspaces"))
                $root.api.WorkspacesListResponse.encode(message.workspaces, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.create != null && Object.hasOwnProperty.call(message, "create"))
                $root.api.WorkspaceCreateResponse.encode(message.create, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.WorkspaceUpdateResponse.encode(message.update, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.members != null && Object.hasOwnProperty.call(message, "members"))
                $root.api.WorkspaceMembersResponse.encode(message.members, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.search != null && Object.hasOwnProperty.call(message, "search"))
                $root.api.WorkspaceMembersSearchResponse.encode(message.search, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.counts != null && Object.hasOwnProperty.call(message, "counts"))
                $root.api.WorkspaceMembersCountResponse.encode(message.counts, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.invite != null && Object.hasOwnProperty.call(message, "invite"))
                $root.api.WorkspaceInviteResponse.encode(message.invite, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.resendInvite != null && Object.hasOwnProperty.call(message, "resendInvite"))
                $root.api.WorkspaceResendInviteResponse.encode(message.resendInvite, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.createInvite != null && Object.hasOwnProperty.call(message, "createInvite"))
                $root.api.WorkspaceCreateInviteResponse.encode(message.createInvite, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.invitesRequest != null && Object.hasOwnProperty.call(message, "invitesRequest"))
                $root.api.WorkspaceInvitesResponse.encode(message.invitesRequest, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.inviteRequest != null && Object.hasOwnProperty.call(message, "inviteRequest"))
                $root.api.WorkspaceInviteResponseNew.encode(message.inviteRequest, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.inviteDelete != null && Object.hasOwnProperty.call(message, "inviteDelete"))
                $root.api.WorkspaceInviteDeleteResponse.encode(message.inviteDelete, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.inviteAccept != null && Object.hasOwnProperty.call(message, "inviteAccept"))
                $root.api.WorkspaceAcceptInviteResponse.encode(message.inviteAccept, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.updateMember != null && Object.hasOwnProperty.call(message, "updateMember"))
                $root.api.WorkspaceUpdateMemberResponse.encode(message.updateMember, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.snippets != null && Object.hasOwnProperty.call(message, "snippets"))
                $root.api.WorkspaceSnippetsResponse.encode(message.snippets, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
            if (message.snippetsSet != null && Object.hasOwnProperty.call(message, "snippetsSet"))
                $root.api.WorkspaceSnippetsSetResponse.encode(message.snippetsSet, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
            if (message.availableTariffs != null && Object.hasOwnProperty.call(message, "availableTariffs"))
                $root.api.WorkspaceGetAvailableTariffsResponse.encode(message.availableTariffs, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
            if (message.billingHistory != null && Object.hasOwnProperty.call(message, "billingHistory"))
                $root.api.WorkspaceGetBillingHistoryResponse.encode(message.billingHistory, writer.uint32(/* id 101, wireType 2 =*/810).fork()).ldelim();
            if (message.payForTariff != null && Object.hasOwnProperty.call(message, "payForTariff"))
                $root.api.WorkspacePayForTariffResponse.encode(message.payForTariff, writer.uint32(/* id 102, wireType 2 =*/818).fork()).ldelim();
            if (message.currentTariff != null && Object.hasOwnProperty.call(message, "currentTariff"))
                $root.api.WorkspaceGetCurrentTariffResponse.encode(message.currentTariff, writer.uint32(/* id 103, wireType 2 =*/826).fork()).ldelim();
            if (message.cancelCurrentTariff != null && Object.hasOwnProperty.call(message, "cancelCurrentTariff"))
                $root.api.WorkspaceCancelCurrentTariffResponse.encode(message.cancelCurrentTariff, writer.uint32(/* id 104, wireType 2 =*/834).fork()).ldelim();
            if (message.resumeCurrentTariff != null && Object.hasOwnProperty.call(message, "resumeCurrentTariff"))
                $root.api.WorkspaceResumeCurrentTariffResponse.encode(message.resumeCurrentTariff, writer.uint32(/* id 105, wireType 2 =*/842).fork()).ldelim();
            if (message.getPaymentInvoice != null && Object.hasOwnProperty.call(message, "getPaymentInvoice"))
                $root.api.WorkspaceGetPaymentInvoiceResponse.encode(message.getPaymentInvoice, writer.uint32(/* id 106, wireType 2 =*/850).fork()).ldelim();
            if (message.getCustomerPortal != null && Object.hasOwnProperty.call(message, "getCustomerPortal"))
                $root.api.WorkspaceGetCustomerPortalResponse.encode(message.getCustomerPortal, writer.uint32(/* id 107, wireType 2 =*/858).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceResponse message, length delimited. Does not implicitly {@link api.WorkspaceResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceResponse
         * @static
         * @param {api.IWorkspaceResponse} message WorkspaceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceResponse} WorkspaceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.workspaceId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.role = reader.int32();
                        break;
                    }
                case 3: {
                        message.workspaces = $root.api.WorkspacesListResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.create = $root.api.WorkspaceCreateResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.update = $root.api.WorkspaceUpdateResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.members = $root.api.WorkspaceMembersResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.search = $root.api.WorkspaceMembersSearchResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.counts = $root.api.WorkspaceMembersCountResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.invite = $root.api.WorkspaceInviteResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.resendInvite = $root.api.WorkspaceResendInviteResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.createInvite = $root.api.WorkspaceCreateInviteResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 23: {
                        message.invitesRequest = $root.api.WorkspaceInvitesResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 24: {
                        message.inviteRequest = $root.api.WorkspaceInviteResponseNew.decode(reader, reader.uint32());
                        break;
                    }
                case 25: {
                        message.inviteDelete = $root.api.WorkspaceInviteDeleteResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 26: {
                        message.inviteAccept = $root.api.WorkspaceAcceptInviteResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 30: {
                        message.updateMember = $root.api.WorkspaceUpdateMemberResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 40: {
                        message.snippets = $root.api.WorkspaceSnippetsResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 41: {
                        message.snippetsSet = $root.api.WorkspaceSnippetsSetResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 100: {
                        message.availableTariffs = $root.api.WorkspaceGetAvailableTariffsResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 101: {
                        message.billingHistory = $root.api.WorkspaceGetBillingHistoryResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 102: {
                        message.payForTariff = $root.api.WorkspacePayForTariffResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 103: {
                        message.currentTariff = $root.api.WorkspaceGetCurrentTariffResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 104: {
                        message.cancelCurrentTariff = $root.api.WorkspaceCancelCurrentTariffResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 105: {
                        message.resumeCurrentTariff = $root.api.WorkspaceResumeCurrentTariffResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 106: {
                        message.getPaymentInvoice = $root.api.WorkspaceGetPaymentInvoiceResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 107: {
                        message.getCustomerPortal = $root.api.WorkspaceGetCustomerPortalResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceResponse} WorkspaceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceResponse message.
         * @function verify
         * @memberof api.WorkspaceResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                if (!(message.workspaceId && typeof message.workspaceId.length === "number" || $util.isString(message.workspaceId)))
                    return "workspaceId: buffer expected";
            if (message.role != null && message.hasOwnProperty("role"))
                switch (message.role) {
                default:
                    return "role: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.workspaces != null && message.hasOwnProperty("workspaces")) {
                let error = $root.api.WorkspacesListResponse.verify(message.workspaces);
                if (error)
                    return "workspaces." + error;
            }
            if (message.create != null && message.hasOwnProperty("create")) {
                let error = $root.api.WorkspaceCreateResponse.verify(message.create);
                if (error)
                    return "create." + error;
            }
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.WorkspaceUpdateResponse.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.members != null && message.hasOwnProperty("members")) {
                let error = $root.api.WorkspaceMembersResponse.verify(message.members);
                if (error)
                    return "members." + error;
            }
            if (message.search != null && message.hasOwnProperty("search")) {
                let error = $root.api.WorkspaceMembersSearchResponse.verify(message.search);
                if (error)
                    return "search." + error;
            }
            if (message.counts != null && message.hasOwnProperty("counts")) {
                let error = $root.api.WorkspaceMembersCountResponse.verify(message.counts);
                if (error)
                    return "counts." + error;
            }
            if (message.invite != null && message.hasOwnProperty("invite")) {
                let error = $root.api.WorkspaceInviteResponse.verify(message.invite);
                if (error)
                    return "invite." + error;
            }
            if (message.resendInvite != null && message.hasOwnProperty("resendInvite")) {
                let error = $root.api.WorkspaceResendInviteResponse.verify(message.resendInvite);
                if (error)
                    return "resendInvite." + error;
            }
            if (message.createInvite != null && message.hasOwnProperty("createInvite")) {
                let error = $root.api.WorkspaceCreateInviteResponse.verify(message.createInvite);
                if (error)
                    return "createInvite." + error;
            }
            if (message.invitesRequest != null && message.hasOwnProperty("invitesRequest")) {
                let error = $root.api.WorkspaceInvitesResponse.verify(message.invitesRequest);
                if (error)
                    return "invitesRequest." + error;
            }
            if (message.inviteRequest != null && message.hasOwnProperty("inviteRequest")) {
                let error = $root.api.WorkspaceInviteResponseNew.verify(message.inviteRequest);
                if (error)
                    return "inviteRequest." + error;
            }
            if (message.inviteDelete != null && message.hasOwnProperty("inviteDelete")) {
                let error = $root.api.WorkspaceInviteDeleteResponse.verify(message.inviteDelete);
                if (error)
                    return "inviteDelete." + error;
            }
            if (message.inviteAccept != null && message.hasOwnProperty("inviteAccept")) {
                let error = $root.api.WorkspaceAcceptInviteResponse.verify(message.inviteAccept);
                if (error)
                    return "inviteAccept." + error;
            }
            if (message.updateMember != null && message.hasOwnProperty("updateMember")) {
                let error = $root.api.WorkspaceUpdateMemberResponse.verify(message.updateMember);
                if (error)
                    return "updateMember." + error;
            }
            if (message.snippets != null && message.hasOwnProperty("snippets")) {
                let error = $root.api.WorkspaceSnippetsResponse.verify(message.snippets);
                if (error)
                    return "snippets." + error;
            }
            if (message.snippetsSet != null && message.hasOwnProperty("snippetsSet")) {
                let error = $root.api.WorkspaceSnippetsSetResponse.verify(message.snippetsSet);
                if (error)
                    return "snippetsSet." + error;
            }
            if (message.availableTariffs != null && message.hasOwnProperty("availableTariffs")) {
                let error = $root.api.WorkspaceGetAvailableTariffsResponse.verify(message.availableTariffs);
                if (error)
                    return "availableTariffs." + error;
            }
            if (message.billingHistory != null && message.hasOwnProperty("billingHistory")) {
                let error = $root.api.WorkspaceGetBillingHistoryResponse.verify(message.billingHistory);
                if (error)
                    return "billingHistory." + error;
            }
            if (message.payForTariff != null && message.hasOwnProperty("payForTariff")) {
                let error = $root.api.WorkspacePayForTariffResponse.verify(message.payForTariff);
                if (error)
                    return "payForTariff." + error;
            }
            if (message.currentTariff != null && message.hasOwnProperty("currentTariff")) {
                let error = $root.api.WorkspaceGetCurrentTariffResponse.verify(message.currentTariff);
                if (error)
                    return "currentTariff." + error;
            }
            if (message.cancelCurrentTariff != null && message.hasOwnProperty("cancelCurrentTariff")) {
                let error = $root.api.WorkspaceCancelCurrentTariffResponse.verify(message.cancelCurrentTariff);
                if (error)
                    return "cancelCurrentTariff." + error;
            }
            if (message.resumeCurrentTariff != null && message.hasOwnProperty("resumeCurrentTariff")) {
                let error = $root.api.WorkspaceResumeCurrentTariffResponse.verify(message.resumeCurrentTariff);
                if (error)
                    return "resumeCurrentTariff." + error;
            }
            if (message.getPaymentInvoice != null && message.hasOwnProperty("getPaymentInvoice")) {
                let error = $root.api.WorkspaceGetPaymentInvoiceResponse.verify(message.getPaymentInvoice);
                if (error)
                    return "getPaymentInvoice." + error;
            }
            if (message.getCustomerPortal != null && message.hasOwnProperty("getCustomerPortal")) {
                let error = $root.api.WorkspaceGetCustomerPortalResponse.verify(message.getCustomerPortal);
                if (error)
                    return "getCustomerPortal." + error;
            }
            return null;
        };

        /**
         * Creates a WorkspaceResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceResponse} WorkspaceResponse
         */
        WorkspaceResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceResponse)
                return object;
            let message = new $root.api.WorkspaceResponse();
            if (object.workspaceId != null)
                if (typeof object.workspaceId === "string")
                    $util.base64.decode(object.workspaceId, message.workspaceId = $util.newBuffer($util.base64.length(object.workspaceId)), 0);
                else if (object.workspaceId.length >= 0)
                    message.workspaceId = object.workspaceId;
            switch (object.role) {
            default:
                if (typeof object.role === "number") {
                    message.role = object.role;
                    break;
                }
                break;
            case "WR_NONE":
            case 0:
                message.role = 0;
                break;
            case "WR_OWNER":
            case 1:
                message.role = 1;
                break;
            case "WR_ADMIN":
            case 2:
                message.role = 2;
                break;
            case "WR_OPERATOR":
            case 3:
                message.role = 3;
                break;
            }
            if (object.workspaces != null) {
                if (typeof object.workspaces !== "object")
                    throw TypeError(".api.WorkspaceResponse.workspaces: object expected");
                message.workspaces = $root.api.WorkspacesListResponse.fromObject(object.workspaces);
            }
            if (object.create != null) {
                if (typeof object.create !== "object")
                    throw TypeError(".api.WorkspaceResponse.create: object expected");
                message.create = $root.api.WorkspaceCreateResponse.fromObject(object.create);
            }
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.WorkspaceResponse.update: object expected");
                message.update = $root.api.WorkspaceUpdateResponse.fromObject(object.update);
            }
            if (object.members != null) {
                if (typeof object.members !== "object")
                    throw TypeError(".api.WorkspaceResponse.members: object expected");
                message.members = $root.api.WorkspaceMembersResponse.fromObject(object.members);
            }
            if (object.search != null) {
                if (typeof object.search !== "object")
                    throw TypeError(".api.WorkspaceResponse.search: object expected");
                message.search = $root.api.WorkspaceMembersSearchResponse.fromObject(object.search);
            }
            if (object.counts != null) {
                if (typeof object.counts !== "object")
                    throw TypeError(".api.WorkspaceResponse.counts: object expected");
                message.counts = $root.api.WorkspaceMembersCountResponse.fromObject(object.counts);
            }
            if (object.invite != null) {
                if (typeof object.invite !== "object")
                    throw TypeError(".api.WorkspaceResponse.invite: object expected");
                message.invite = $root.api.WorkspaceInviteResponse.fromObject(object.invite);
            }
            if (object.resendInvite != null) {
                if (typeof object.resendInvite !== "object")
                    throw TypeError(".api.WorkspaceResponse.resendInvite: object expected");
                message.resendInvite = $root.api.WorkspaceResendInviteResponse.fromObject(object.resendInvite);
            }
            if (object.createInvite != null) {
                if (typeof object.createInvite !== "object")
                    throw TypeError(".api.WorkspaceResponse.createInvite: object expected");
                message.createInvite = $root.api.WorkspaceCreateInviteResponse.fromObject(object.createInvite);
            }
            if (object.invitesRequest != null) {
                if (typeof object.invitesRequest !== "object")
                    throw TypeError(".api.WorkspaceResponse.invitesRequest: object expected");
                message.invitesRequest = $root.api.WorkspaceInvitesResponse.fromObject(object.invitesRequest);
            }
            if (object.inviteRequest != null) {
                if (typeof object.inviteRequest !== "object")
                    throw TypeError(".api.WorkspaceResponse.inviteRequest: object expected");
                message.inviteRequest = $root.api.WorkspaceInviteResponseNew.fromObject(object.inviteRequest);
            }
            if (object.inviteDelete != null) {
                if (typeof object.inviteDelete !== "object")
                    throw TypeError(".api.WorkspaceResponse.inviteDelete: object expected");
                message.inviteDelete = $root.api.WorkspaceInviteDeleteResponse.fromObject(object.inviteDelete);
            }
            if (object.inviteAccept != null) {
                if (typeof object.inviteAccept !== "object")
                    throw TypeError(".api.WorkspaceResponse.inviteAccept: object expected");
                message.inviteAccept = $root.api.WorkspaceAcceptInviteResponse.fromObject(object.inviteAccept);
            }
            if (object.updateMember != null) {
                if (typeof object.updateMember !== "object")
                    throw TypeError(".api.WorkspaceResponse.updateMember: object expected");
                message.updateMember = $root.api.WorkspaceUpdateMemberResponse.fromObject(object.updateMember);
            }
            if (object.snippets != null) {
                if (typeof object.snippets !== "object")
                    throw TypeError(".api.WorkspaceResponse.snippets: object expected");
                message.snippets = $root.api.WorkspaceSnippetsResponse.fromObject(object.snippets);
            }
            if (object.snippetsSet != null) {
                if (typeof object.snippetsSet !== "object")
                    throw TypeError(".api.WorkspaceResponse.snippetsSet: object expected");
                message.snippetsSet = $root.api.WorkspaceSnippetsSetResponse.fromObject(object.snippetsSet);
            }
            if (object.availableTariffs != null) {
                if (typeof object.availableTariffs !== "object")
                    throw TypeError(".api.WorkspaceResponse.availableTariffs: object expected");
                message.availableTariffs = $root.api.WorkspaceGetAvailableTariffsResponse.fromObject(object.availableTariffs);
            }
            if (object.billingHistory != null) {
                if (typeof object.billingHistory !== "object")
                    throw TypeError(".api.WorkspaceResponse.billingHistory: object expected");
                message.billingHistory = $root.api.WorkspaceGetBillingHistoryResponse.fromObject(object.billingHistory);
            }
            if (object.payForTariff != null) {
                if (typeof object.payForTariff !== "object")
                    throw TypeError(".api.WorkspaceResponse.payForTariff: object expected");
                message.payForTariff = $root.api.WorkspacePayForTariffResponse.fromObject(object.payForTariff);
            }
            if (object.currentTariff != null) {
                if (typeof object.currentTariff !== "object")
                    throw TypeError(".api.WorkspaceResponse.currentTariff: object expected");
                message.currentTariff = $root.api.WorkspaceGetCurrentTariffResponse.fromObject(object.currentTariff);
            }
            if (object.cancelCurrentTariff != null) {
                if (typeof object.cancelCurrentTariff !== "object")
                    throw TypeError(".api.WorkspaceResponse.cancelCurrentTariff: object expected");
                message.cancelCurrentTariff = $root.api.WorkspaceCancelCurrentTariffResponse.fromObject(object.cancelCurrentTariff);
            }
            if (object.resumeCurrentTariff != null) {
                if (typeof object.resumeCurrentTariff !== "object")
                    throw TypeError(".api.WorkspaceResponse.resumeCurrentTariff: object expected");
                message.resumeCurrentTariff = $root.api.WorkspaceResumeCurrentTariffResponse.fromObject(object.resumeCurrentTariff);
            }
            if (object.getPaymentInvoice != null) {
                if (typeof object.getPaymentInvoice !== "object")
                    throw TypeError(".api.WorkspaceResponse.getPaymentInvoice: object expected");
                message.getPaymentInvoice = $root.api.WorkspaceGetPaymentInvoiceResponse.fromObject(object.getPaymentInvoice);
            }
            if (object.getCustomerPortal != null) {
                if (typeof object.getCustomerPortal !== "object")
                    throw TypeError(".api.WorkspaceResponse.getCustomerPortal: object expected");
                message.getCustomerPortal = $root.api.WorkspaceGetCustomerPortalResponse.fromObject(object.getCustomerPortal);
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceResponse
         * @static
         * @param {api.WorkspaceResponse} message WorkspaceResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.workspaceId = "";
                else {
                    object.workspaceId = [];
                    if (options.bytes !== Array)
                        object.workspaceId = $util.newBuffer(object.workspaceId);
                }
                object.role = options.enums === String ? "WR_NONE" : 0;
                object.workspaces = null;
                object.create = null;
                object.update = null;
                object.members = null;
                object.search = null;
                object.counts = null;
                object.invite = null;
                object.resendInvite = null;
                object.createInvite = null;
                object.invitesRequest = null;
                object.inviteRequest = null;
                object.inviteDelete = null;
                object.inviteAccept = null;
                object.updateMember = null;
                object.snippets = null;
                object.snippetsSet = null;
                object.availableTariffs = null;
                object.billingHistory = null;
                object.payForTariff = null;
                object.currentTariff = null;
                object.cancelCurrentTariff = null;
                object.resumeCurrentTariff = null;
                object.getPaymentInvoice = null;
                object.getCustomerPortal = null;
            }
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                object.workspaceId = options.bytes === String ? $util.base64.encode(message.workspaceId, 0, message.workspaceId.length) : options.bytes === Array ? Array.prototype.slice.call(message.workspaceId) : message.workspaceId;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = options.enums === String ? $root.entities.WorkspaceRole[message.role] === undefined ? message.role : $root.entities.WorkspaceRole[message.role] : message.role;
            if (message.workspaces != null && message.hasOwnProperty("workspaces"))
                object.workspaces = $root.api.WorkspacesListResponse.toObject(message.workspaces, options);
            if (message.create != null && message.hasOwnProperty("create"))
                object.create = $root.api.WorkspaceCreateResponse.toObject(message.create, options);
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.WorkspaceUpdateResponse.toObject(message.update, options);
            if (message.members != null && message.hasOwnProperty("members"))
                object.members = $root.api.WorkspaceMembersResponse.toObject(message.members, options);
            if (message.search != null && message.hasOwnProperty("search"))
                object.search = $root.api.WorkspaceMembersSearchResponse.toObject(message.search, options);
            if (message.counts != null && message.hasOwnProperty("counts"))
                object.counts = $root.api.WorkspaceMembersCountResponse.toObject(message.counts, options);
            if (message.invite != null && message.hasOwnProperty("invite"))
                object.invite = $root.api.WorkspaceInviteResponse.toObject(message.invite, options);
            if (message.resendInvite != null && message.hasOwnProperty("resendInvite"))
                object.resendInvite = $root.api.WorkspaceResendInviteResponse.toObject(message.resendInvite, options);
            if (message.createInvite != null && message.hasOwnProperty("createInvite"))
                object.createInvite = $root.api.WorkspaceCreateInviteResponse.toObject(message.createInvite, options);
            if (message.invitesRequest != null && message.hasOwnProperty("invitesRequest"))
                object.invitesRequest = $root.api.WorkspaceInvitesResponse.toObject(message.invitesRequest, options);
            if (message.inviteRequest != null && message.hasOwnProperty("inviteRequest"))
                object.inviteRequest = $root.api.WorkspaceInviteResponseNew.toObject(message.inviteRequest, options);
            if (message.inviteDelete != null && message.hasOwnProperty("inviteDelete"))
                object.inviteDelete = $root.api.WorkspaceInviteDeleteResponse.toObject(message.inviteDelete, options);
            if (message.inviteAccept != null && message.hasOwnProperty("inviteAccept"))
                object.inviteAccept = $root.api.WorkspaceAcceptInviteResponse.toObject(message.inviteAccept, options);
            if (message.updateMember != null && message.hasOwnProperty("updateMember"))
                object.updateMember = $root.api.WorkspaceUpdateMemberResponse.toObject(message.updateMember, options);
            if (message.snippets != null && message.hasOwnProperty("snippets"))
                object.snippets = $root.api.WorkspaceSnippetsResponse.toObject(message.snippets, options);
            if (message.snippetsSet != null && message.hasOwnProperty("snippetsSet"))
                object.snippetsSet = $root.api.WorkspaceSnippetsSetResponse.toObject(message.snippetsSet, options);
            if (message.availableTariffs != null && message.hasOwnProperty("availableTariffs"))
                object.availableTariffs = $root.api.WorkspaceGetAvailableTariffsResponse.toObject(message.availableTariffs, options);
            if (message.billingHistory != null && message.hasOwnProperty("billingHistory"))
                object.billingHistory = $root.api.WorkspaceGetBillingHistoryResponse.toObject(message.billingHistory, options);
            if (message.payForTariff != null && message.hasOwnProperty("payForTariff"))
                object.payForTariff = $root.api.WorkspacePayForTariffResponse.toObject(message.payForTariff, options);
            if (message.currentTariff != null && message.hasOwnProperty("currentTariff"))
                object.currentTariff = $root.api.WorkspaceGetCurrentTariffResponse.toObject(message.currentTariff, options);
            if (message.cancelCurrentTariff != null && message.hasOwnProperty("cancelCurrentTariff"))
                object.cancelCurrentTariff = $root.api.WorkspaceCancelCurrentTariffResponse.toObject(message.cancelCurrentTariff, options);
            if (message.resumeCurrentTariff != null && message.hasOwnProperty("resumeCurrentTariff"))
                object.resumeCurrentTariff = $root.api.WorkspaceResumeCurrentTariffResponse.toObject(message.resumeCurrentTariff, options);
            if (message.getPaymentInvoice != null && message.hasOwnProperty("getPaymentInvoice"))
                object.getPaymentInvoice = $root.api.WorkspaceGetPaymentInvoiceResponse.toObject(message.getPaymentInvoice, options);
            if (message.getCustomerPortal != null && message.hasOwnProperty("getCustomerPortal"))
                object.getCustomerPortal = $root.api.WorkspaceGetCustomerPortalResponse.toObject(message.getCustomerPortal, options);
            return object;
        };

        /**
         * Converts this WorkspaceResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceResponse";
        };

        return WorkspaceResponse;
    })();

    api.WorkspacesListRequest = (function() {

        /**
         * Properties of a WorkspacesListRequest.
         * @memberof api
         * @interface IWorkspacesListRequest
         * @property {boolean|null} [fetch] WorkspacesListRequest fetch
         */

        /**
         * Constructs a new WorkspacesListRequest.
         * @memberof api
         * @classdesc Represents a WorkspacesListRequest.
         * @implements IWorkspacesListRequest
         * @constructor
         * @param {api.IWorkspacesListRequest=} [properties] Properties to set
         */
        function WorkspacesListRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspacesListRequest fetch.
         * @member {boolean} fetch
         * @memberof api.WorkspacesListRequest
         * @instance
         */
        WorkspacesListRequest.prototype.fetch = false;

        /**
         * Creates a new WorkspacesListRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspacesListRequest
         * @static
         * @param {api.IWorkspacesListRequest=} [properties] Properties to set
         * @returns {api.WorkspacesListRequest} WorkspacesListRequest instance
         */
        WorkspacesListRequest.create = function create(properties) {
            return new WorkspacesListRequest(properties);
        };

        /**
         * Encodes the specified WorkspacesListRequest message. Does not implicitly {@link api.WorkspacesListRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspacesListRequest
         * @static
         * @param {api.IWorkspacesListRequest} message WorkspacesListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspacesListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fetch != null && Object.hasOwnProperty.call(message, "fetch"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.fetch);
            return writer;
        };

        /**
         * Encodes the specified WorkspacesListRequest message, length delimited. Does not implicitly {@link api.WorkspacesListRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspacesListRequest
         * @static
         * @param {api.IWorkspacesListRequest} message WorkspacesListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspacesListRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspacesListRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspacesListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspacesListRequest} WorkspacesListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspacesListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspacesListRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fetch = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspacesListRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspacesListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspacesListRequest} WorkspacesListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspacesListRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspacesListRequest message.
         * @function verify
         * @memberof api.WorkspacesListRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspacesListRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                if (typeof message.fetch !== "boolean")
                    return "fetch: boolean expected";
            return null;
        };

        /**
         * Creates a WorkspacesListRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspacesListRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspacesListRequest} WorkspacesListRequest
         */
        WorkspacesListRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspacesListRequest)
                return object;
            let message = new $root.api.WorkspacesListRequest();
            if (object.fetch != null)
                message.fetch = Boolean(object.fetch);
            return message;
        };

        /**
         * Creates a plain object from a WorkspacesListRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspacesListRequest
         * @static
         * @param {api.WorkspacesListRequest} message WorkspacesListRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspacesListRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fetch = false;
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                object.fetch = message.fetch;
            return object;
        };

        /**
         * Converts this WorkspacesListRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspacesListRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspacesListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspacesListRequest
         * @function getTypeUrl
         * @memberof api.WorkspacesListRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspacesListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspacesListRequest";
        };

        return WorkspacesListRequest;
    })();

    api.WorkspacesListResponse = (function() {

        /**
         * Properties of a WorkspacesListResponse.
         * @memberof api
         * @interface IWorkspacesListResponse
         * @property {Array.<entities.IWorkspace>|null} [entries] WorkspacesListResponse entries
         */

        /**
         * Constructs a new WorkspacesListResponse.
         * @memberof api
         * @classdesc Represents a WorkspacesListResponse.
         * @implements IWorkspacesListResponse
         * @constructor
         * @param {api.IWorkspacesListResponse=} [properties] Properties to set
         */
        function WorkspacesListResponse(properties) {
            this.entries = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspacesListResponse entries.
         * @member {Array.<entities.IWorkspace>} entries
         * @memberof api.WorkspacesListResponse
         * @instance
         */
        WorkspacesListResponse.prototype.entries = $util.emptyArray;

        /**
         * Creates a new WorkspacesListResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspacesListResponse
         * @static
         * @param {api.IWorkspacesListResponse=} [properties] Properties to set
         * @returns {api.WorkspacesListResponse} WorkspacesListResponse instance
         */
        WorkspacesListResponse.create = function create(properties) {
            return new WorkspacesListResponse(properties);
        };

        /**
         * Encodes the specified WorkspacesListResponse message. Does not implicitly {@link api.WorkspacesListResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspacesListResponse
         * @static
         * @param {api.IWorkspacesListResponse} message WorkspacesListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspacesListResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.entries != null && message.entries.length)
                for (let i = 0; i < message.entries.length; ++i)
                    $root.entities.Workspace.encode(message.entries[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspacesListResponse message, length delimited. Does not implicitly {@link api.WorkspacesListResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspacesListResponse
         * @static
         * @param {api.IWorkspacesListResponse} message WorkspacesListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspacesListResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspacesListResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspacesListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspacesListResponse} WorkspacesListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspacesListResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspacesListResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.entries && message.entries.length))
                            message.entries = [];
                        message.entries.push($root.entities.Workspace.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspacesListResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspacesListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspacesListResponse} WorkspacesListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspacesListResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspacesListResponse message.
         * @function verify
         * @memberof api.WorkspacesListResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspacesListResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.entries != null && message.hasOwnProperty("entries")) {
                if (!Array.isArray(message.entries))
                    return "entries: array expected";
                for (let i = 0; i < message.entries.length; ++i) {
                    let error = $root.entities.Workspace.verify(message.entries[i]);
                    if (error)
                        return "entries." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WorkspacesListResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspacesListResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspacesListResponse} WorkspacesListResponse
         */
        WorkspacesListResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspacesListResponse)
                return object;
            let message = new $root.api.WorkspacesListResponse();
            if (object.entries) {
                if (!Array.isArray(object.entries))
                    throw TypeError(".api.WorkspacesListResponse.entries: array expected");
                message.entries = [];
                for (let i = 0; i < object.entries.length; ++i) {
                    if (typeof object.entries[i] !== "object")
                        throw TypeError(".api.WorkspacesListResponse.entries: object expected");
                    message.entries[i] = $root.entities.Workspace.fromObject(object.entries[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspacesListResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspacesListResponse
         * @static
         * @param {api.WorkspacesListResponse} message WorkspacesListResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspacesListResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.entries = [];
            if (message.entries && message.entries.length) {
                object.entries = [];
                for (let j = 0; j < message.entries.length; ++j)
                    object.entries[j] = $root.entities.Workspace.toObject(message.entries[j], options);
            }
            return object;
        };

        /**
         * Converts this WorkspacesListResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspacesListResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspacesListResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspacesListResponse
         * @function getTypeUrl
         * @memberof api.WorkspacesListResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspacesListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspacesListResponse";
        };

        return WorkspacesListResponse;
    })();

    api.WorkspaceMembersFilters = (function() {

        /**
         * Properties of a WorkspaceMembersFilters.
         * @memberof api
         * @interface IWorkspaceMembersFilters
         * @property {Array.<entities.WorkspaceRole>|null} [roles] WorkspaceMembersFilters roles
         * @property {Array.<entities.WorkspaceMemberStatus>|null} [statuses] WorkspaceMembersFilters statuses
         * @property {Array.<entities.WorkspaceMemberSource>|null} [sources] WorkspaceMembersFilters sources
         */

        /**
         * Constructs a new WorkspaceMembersFilters.
         * @memberof api
         * @classdesc Represents a WorkspaceMembersFilters.
         * @implements IWorkspaceMembersFilters
         * @constructor
         * @param {api.IWorkspaceMembersFilters=} [properties] Properties to set
         */
        function WorkspaceMembersFilters(properties) {
            this.roles = [];
            this.statuses = [];
            this.sources = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceMembersFilters roles.
         * @member {Array.<entities.WorkspaceRole>} roles
         * @memberof api.WorkspaceMembersFilters
         * @instance
         */
        WorkspaceMembersFilters.prototype.roles = $util.emptyArray;

        /**
         * WorkspaceMembersFilters statuses.
         * @member {Array.<entities.WorkspaceMemberStatus>} statuses
         * @memberof api.WorkspaceMembersFilters
         * @instance
         */
        WorkspaceMembersFilters.prototype.statuses = $util.emptyArray;

        /**
         * WorkspaceMembersFilters sources.
         * @member {Array.<entities.WorkspaceMemberSource>} sources
         * @memberof api.WorkspaceMembersFilters
         * @instance
         */
        WorkspaceMembersFilters.prototype.sources = $util.emptyArray;

        /**
         * Creates a new WorkspaceMembersFilters instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceMembersFilters
         * @static
         * @param {api.IWorkspaceMembersFilters=} [properties] Properties to set
         * @returns {api.WorkspaceMembersFilters} WorkspaceMembersFilters instance
         */
        WorkspaceMembersFilters.create = function create(properties) {
            return new WorkspaceMembersFilters(properties);
        };

        /**
         * Encodes the specified WorkspaceMembersFilters message. Does not implicitly {@link api.WorkspaceMembersFilters.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceMembersFilters
         * @static
         * @param {api.IWorkspaceMembersFilters} message WorkspaceMembersFilters message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMembersFilters.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roles != null && message.roles.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.roles.length; ++i)
                    writer.int32(message.roles[i]);
                writer.ldelim();
            }
            if (message.statuses != null && message.statuses.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.statuses.length; ++i)
                    writer.int32(message.statuses[i]);
                writer.ldelim();
            }
            if (message.sources != null && message.sources.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (let i = 0; i < message.sources.length; ++i)
                    writer.int32(message.sources[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified WorkspaceMembersFilters message, length delimited. Does not implicitly {@link api.WorkspaceMembersFilters.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceMembersFilters
         * @static
         * @param {api.IWorkspaceMembersFilters} message WorkspaceMembersFilters message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMembersFilters.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceMembersFilters message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceMembersFilters
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceMembersFilters} WorkspaceMembersFilters
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMembersFilters.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceMembersFilters();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.roles && message.roles.length))
                            message.roles = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.roles.push(reader.int32());
                        } else
                            message.roles.push(reader.int32());
                        break;
                    }
                case 2: {
                        if (!(message.statuses && message.statuses.length))
                            message.statuses = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.statuses.push(reader.int32());
                        } else
                            message.statuses.push(reader.int32());
                        break;
                    }
                case 3: {
                        if (!(message.sources && message.sources.length))
                            message.sources = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.sources.push(reader.int32());
                        } else
                            message.sources.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceMembersFilters message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceMembersFilters
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceMembersFilters} WorkspaceMembersFilters
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMembersFilters.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceMembersFilters message.
         * @function verify
         * @memberof api.WorkspaceMembersFilters
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceMembersFilters.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.roles != null && message.hasOwnProperty("roles")) {
                if (!Array.isArray(message.roles))
                    return "roles: array expected";
                for (let i = 0; i < message.roles.length; ++i)
                    switch (message.roles[i]) {
                    default:
                        return "roles: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
            }
            if (message.statuses != null && message.hasOwnProperty("statuses")) {
                if (!Array.isArray(message.statuses))
                    return "statuses: array expected";
                for (let i = 0; i < message.statuses.length; ++i)
                    switch (message.statuses[i]) {
                    default:
                        return "statuses: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
            }
            if (message.sources != null && message.hasOwnProperty("sources")) {
                if (!Array.isArray(message.sources))
                    return "sources: array expected";
                for (let i = 0; i < message.sources.length; ++i)
                    switch (message.sources[i]) {
                    default:
                        return "sources: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
            }
            return null;
        };

        /**
         * Creates a WorkspaceMembersFilters message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceMembersFilters
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceMembersFilters} WorkspaceMembersFilters
         */
        WorkspaceMembersFilters.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceMembersFilters)
                return object;
            let message = new $root.api.WorkspaceMembersFilters();
            if (object.roles) {
                if (!Array.isArray(object.roles))
                    throw TypeError(".api.WorkspaceMembersFilters.roles: array expected");
                message.roles = [];
                for (let i = 0; i < object.roles.length; ++i)
                    switch (object.roles[i]) {
                    default:
                        if (typeof object.roles[i] === "number") {
                            message.roles[i] = object.roles[i];
                            break;
                        }
                    case "WR_NONE":
                    case 0:
                        message.roles[i] = 0;
                        break;
                    case "WR_OWNER":
                    case 1:
                        message.roles[i] = 1;
                        break;
                    case "WR_ADMIN":
                    case 2:
                        message.roles[i] = 2;
                        break;
                    case "WR_OPERATOR":
                    case 3:
                        message.roles[i] = 3;
                        break;
                    }
            }
            if (object.statuses) {
                if (!Array.isArray(object.statuses))
                    throw TypeError(".api.WorkspaceMembersFilters.statuses: array expected");
                message.statuses = [];
                for (let i = 0; i < object.statuses.length; ++i)
                    switch (object.statuses[i]) {
                    default:
                        if (typeof object.statuses[i] === "number") {
                            message.statuses[i] = object.statuses[i];
                            break;
                        }
                    case "WM_ACTIVE":
                    case 0:
                        message.statuses[i] = 0;
                        break;
                    case "WM_BLOCKED":
                    case 1:
                        message.statuses[i] = 1;
                        break;
                    case "WM_INVITED":
                    case 2:
                        message.statuses[i] = 2;
                        break;
                    case "WM_INVITE_EXPIRED":
                    case 3:
                        message.statuses[i] = 3;
                        break;
                    case "WM_REMOVED":
                    case 4:
                        message.statuses[i] = 4;
                        break;
                    }
            }
            if (object.sources) {
                if (!Array.isArray(object.sources))
                    throw TypeError(".api.WorkspaceMembersFilters.sources: array expected");
                message.sources = [];
                for (let i = 0; i < object.sources.length; ++i)
                    switch (object.sources[i]) {
                    default:
                        if (typeof object.sources[i] === "number") {
                            message.sources[i] = object.sources[i];
                            break;
                        }
                    case "WIT_CREATOR":
                    case 0:
                        message.sources[i] = 0;
                        break;
                    case "WIT_EMAIL_INVITE":
                    case 1:
                        message.sources[i] = 1;
                        break;
                    case "WIT_LINK_INVITE":
                    case 2:
                        message.sources[i] = 2;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceMembersFilters message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceMembersFilters
         * @static
         * @param {api.WorkspaceMembersFilters} message WorkspaceMembersFilters
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceMembersFilters.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.roles = [];
                object.statuses = [];
                object.sources = [];
            }
            if (message.roles && message.roles.length) {
                object.roles = [];
                for (let j = 0; j < message.roles.length; ++j)
                    object.roles[j] = options.enums === String ? $root.entities.WorkspaceRole[message.roles[j]] === undefined ? message.roles[j] : $root.entities.WorkspaceRole[message.roles[j]] : message.roles[j];
            }
            if (message.statuses && message.statuses.length) {
                object.statuses = [];
                for (let j = 0; j < message.statuses.length; ++j)
                    object.statuses[j] = options.enums === String ? $root.entities.WorkspaceMemberStatus[message.statuses[j]] === undefined ? message.statuses[j] : $root.entities.WorkspaceMemberStatus[message.statuses[j]] : message.statuses[j];
            }
            if (message.sources && message.sources.length) {
                object.sources = [];
                for (let j = 0; j < message.sources.length; ++j)
                    object.sources[j] = options.enums === String ? $root.entities.WorkspaceMemberSource[message.sources[j]] === undefined ? message.sources[j] : $root.entities.WorkspaceMemberSource[message.sources[j]] : message.sources[j];
            }
            return object;
        };

        /**
         * Converts this WorkspaceMembersFilters to JSON.
         * @function toJSON
         * @memberof api.WorkspaceMembersFilters
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceMembersFilters.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceMembersFilters
         * @function getTypeUrl
         * @memberof api.WorkspaceMembersFilters
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceMembersFilters.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceMembersFilters";
        };

        return WorkspaceMembersFilters;
    })();

    api.WorkspaceMembersRequest = (function() {

        /**
         * Properties of a WorkspaceMembersRequest.
         * @memberof api
         * @interface IWorkspaceMembersRequest
         * @property {Long|null} [offset] WorkspaceMembersRequest offset
         * @property {api.IWorkspaceMembersFilters|null} [filters] WorkspaceMembersRequest filters
         */

        /**
         * Constructs a new WorkspaceMembersRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceMembersRequest.
         * @implements IWorkspaceMembersRequest
         * @constructor
         * @param {api.IWorkspaceMembersRequest=} [properties] Properties to set
         */
        function WorkspaceMembersRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceMembersRequest offset.
         * @member {Long} offset
         * @memberof api.WorkspaceMembersRequest
         * @instance
         */
        WorkspaceMembersRequest.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * WorkspaceMembersRequest filters.
         * @member {api.IWorkspaceMembersFilters|null|undefined} filters
         * @memberof api.WorkspaceMembersRequest
         * @instance
         */
        WorkspaceMembersRequest.prototype.filters = null;

        /**
         * Creates a new WorkspaceMembersRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceMembersRequest
         * @static
         * @param {api.IWorkspaceMembersRequest=} [properties] Properties to set
         * @returns {api.WorkspaceMembersRequest} WorkspaceMembersRequest instance
         */
        WorkspaceMembersRequest.create = function create(properties) {
            return new WorkspaceMembersRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceMembersRequest message. Does not implicitly {@link api.WorkspaceMembersRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceMembersRequest
         * @static
         * @param {api.IWorkspaceMembersRequest} message WorkspaceMembersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMembersRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.offset);
            if (message.filters != null && Object.hasOwnProperty.call(message, "filters"))
                $root.api.WorkspaceMembersFilters.encode(message.filters, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceMembersRequest message, length delimited. Does not implicitly {@link api.WorkspaceMembersRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceMembersRequest
         * @static
         * @param {api.IWorkspaceMembersRequest} message WorkspaceMembersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMembersRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceMembersRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceMembersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceMembersRequest} WorkspaceMembersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMembersRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceMembersRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.offset = reader.uint64();
                        break;
                    }
                case 2: {
                        message.filters = $root.api.WorkspaceMembersFilters.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceMembersRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceMembersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceMembersRequest} WorkspaceMembersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMembersRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceMembersRequest message.
         * @function verify
         * @memberof api.WorkspaceMembersRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceMembersRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset) && !(message.offset && $util.isInteger(message.offset.low) && $util.isInteger(message.offset.high)))
                    return "offset: integer|Long expected";
            if (message.filters != null && message.hasOwnProperty("filters")) {
                let error = $root.api.WorkspaceMembersFilters.verify(message.filters);
                if (error)
                    return "filters." + error;
            }
            return null;
        };

        /**
         * Creates a WorkspaceMembersRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceMembersRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceMembersRequest} WorkspaceMembersRequest
         */
        WorkspaceMembersRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceMembersRequest)
                return object;
            let message = new $root.api.WorkspaceMembersRequest();
            if (object.offset != null)
                if ($util.Long)
                    (message.offset = $util.Long.fromValue(object.offset)).unsigned = true;
                else if (typeof object.offset === "string")
                    message.offset = parseInt(object.offset, 10);
                else if (typeof object.offset === "number")
                    message.offset = object.offset;
                else if (typeof object.offset === "object")
                    message.offset = new $util.LongBits(object.offset.low >>> 0, object.offset.high >>> 0).toNumber(true);
            if (object.filters != null) {
                if (typeof object.filters !== "object")
                    throw TypeError(".api.WorkspaceMembersRequest.filters: object expected");
                message.filters = $root.api.WorkspaceMembersFilters.fromObject(object.filters);
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceMembersRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceMembersRequest
         * @static
         * @param {api.WorkspaceMembersRequest} message WorkspaceMembersRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceMembersRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.offset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.offset = options.longs === String ? "0" : 0;
                object.filters = null;
            }
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (typeof message.offset === "number")
                    object.offset = options.longs === String ? String(message.offset) : message.offset;
                else
                    object.offset = options.longs === String ? $util.Long.prototype.toString.call(message.offset) : options.longs === Number ? new $util.LongBits(message.offset.low >>> 0, message.offset.high >>> 0).toNumber(true) : message.offset;
            if (message.filters != null && message.hasOwnProperty("filters"))
                object.filters = $root.api.WorkspaceMembersFilters.toObject(message.filters, options);
            return object;
        };

        /**
         * Converts this WorkspaceMembersRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceMembersRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceMembersRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceMembersRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceMembersRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceMembersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceMembersRequest";
        };

        return WorkspaceMembersRequest;
    })();

    api.WorkspaceMembersResponse = (function() {

        /**
         * Properties of a WorkspaceMembersResponse.
         * @memberof api
         * @interface IWorkspaceMembersResponse
         * @property {Array.<entities.IWorkspaceMember>|null} [members] WorkspaceMembersResponse members
         */

        /**
         * Constructs a new WorkspaceMembersResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceMembersResponse.
         * @implements IWorkspaceMembersResponse
         * @constructor
         * @param {api.IWorkspaceMembersResponse=} [properties] Properties to set
         */
        function WorkspaceMembersResponse(properties) {
            this.members = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceMembersResponse members.
         * @member {Array.<entities.IWorkspaceMember>} members
         * @memberof api.WorkspaceMembersResponse
         * @instance
         */
        WorkspaceMembersResponse.prototype.members = $util.emptyArray;

        /**
         * Creates a new WorkspaceMembersResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceMembersResponse
         * @static
         * @param {api.IWorkspaceMembersResponse=} [properties] Properties to set
         * @returns {api.WorkspaceMembersResponse} WorkspaceMembersResponse instance
         */
        WorkspaceMembersResponse.create = function create(properties) {
            return new WorkspaceMembersResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceMembersResponse message. Does not implicitly {@link api.WorkspaceMembersResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceMembersResponse
         * @static
         * @param {api.IWorkspaceMembersResponse} message WorkspaceMembersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMembersResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.members != null && message.members.length)
                for (let i = 0; i < message.members.length; ++i)
                    $root.entities.WorkspaceMember.encode(message.members[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceMembersResponse message, length delimited. Does not implicitly {@link api.WorkspaceMembersResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceMembersResponse
         * @static
         * @param {api.IWorkspaceMembersResponse} message WorkspaceMembersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMembersResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceMembersResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceMembersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceMembersResponse} WorkspaceMembersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMembersResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceMembersResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.members && message.members.length))
                            message.members = [];
                        message.members.push($root.entities.WorkspaceMember.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceMembersResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceMembersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceMembersResponse} WorkspaceMembersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMembersResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceMembersResponse message.
         * @function verify
         * @memberof api.WorkspaceMembersResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceMembersResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.members != null && message.hasOwnProperty("members")) {
                if (!Array.isArray(message.members))
                    return "members: array expected";
                for (let i = 0; i < message.members.length; ++i) {
                    let error = $root.entities.WorkspaceMember.verify(message.members[i]);
                    if (error)
                        return "members." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WorkspaceMembersResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceMembersResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceMembersResponse} WorkspaceMembersResponse
         */
        WorkspaceMembersResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceMembersResponse)
                return object;
            let message = new $root.api.WorkspaceMembersResponse();
            if (object.members) {
                if (!Array.isArray(object.members))
                    throw TypeError(".api.WorkspaceMembersResponse.members: array expected");
                message.members = [];
                for (let i = 0; i < object.members.length; ++i) {
                    if (typeof object.members[i] !== "object")
                        throw TypeError(".api.WorkspaceMembersResponse.members: object expected");
                    message.members[i] = $root.entities.WorkspaceMember.fromObject(object.members[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceMembersResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceMembersResponse
         * @static
         * @param {api.WorkspaceMembersResponse} message WorkspaceMembersResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceMembersResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.members = [];
            if (message.members && message.members.length) {
                object.members = [];
                for (let j = 0; j < message.members.length; ++j)
                    object.members[j] = $root.entities.WorkspaceMember.toObject(message.members[j], options);
            }
            return object;
        };

        /**
         * Converts this WorkspaceMembersResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceMembersResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceMembersResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceMembersResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceMembersResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceMembersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceMembersResponse";
        };

        return WorkspaceMembersResponse;
    })();

    api.WorkspaceMembersCountRequest = (function() {

        /**
         * Properties of a WorkspaceMembersCountRequest.
         * @memberof api
         * @interface IWorkspaceMembersCountRequest
         * @property {Array.<api.IWorkspaceMembersFilters>|null} [groups] WorkspaceMembersCountRequest groups
         */

        /**
         * Constructs a new WorkspaceMembersCountRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceMembersCountRequest.
         * @implements IWorkspaceMembersCountRequest
         * @constructor
         * @param {api.IWorkspaceMembersCountRequest=} [properties] Properties to set
         */
        function WorkspaceMembersCountRequest(properties) {
            this.groups = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceMembersCountRequest groups.
         * @member {Array.<api.IWorkspaceMembersFilters>} groups
         * @memberof api.WorkspaceMembersCountRequest
         * @instance
         */
        WorkspaceMembersCountRequest.prototype.groups = $util.emptyArray;

        /**
         * Creates a new WorkspaceMembersCountRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceMembersCountRequest
         * @static
         * @param {api.IWorkspaceMembersCountRequest=} [properties] Properties to set
         * @returns {api.WorkspaceMembersCountRequest} WorkspaceMembersCountRequest instance
         */
        WorkspaceMembersCountRequest.create = function create(properties) {
            return new WorkspaceMembersCountRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceMembersCountRequest message. Does not implicitly {@link api.WorkspaceMembersCountRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceMembersCountRequest
         * @static
         * @param {api.IWorkspaceMembersCountRequest} message WorkspaceMembersCountRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMembersCountRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.groups != null && message.groups.length)
                for (let i = 0; i < message.groups.length; ++i)
                    $root.api.WorkspaceMembersFilters.encode(message.groups[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceMembersCountRequest message, length delimited. Does not implicitly {@link api.WorkspaceMembersCountRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceMembersCountRequest
         * @static
         * @param {api.IWorkspaceMembersCountRequest} message WorkspaceMembersCountRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMembersCountRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceMembersCountRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceMembersCountRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceMembersCountRequest} WorkspaceMembersCountRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMembersCountRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceMembersCountRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.groups && message.groups.length))
                            message.groups = [];
                        message.groups.push($root.api.WorkspaceMembersFilters.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceMembersCountRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceMembersCountRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceMembersCountRequest} WorkspaceMembersCountRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMembersCountRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceMembersCountRequest message.
         * @function verify
         * @memberof api.WorkspaceMembersCountRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceMembersCountRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.groups != null && message.hasOwnProperty("groups")) {
                if (!Array.isArray(message.groups))
                    return "groups: array expected";
                for (let i = 0; i < message.groups.length; ++i) {
                    let error = $root.api.WorkspaceMembersFilters.verify(message.groups[i]);
                    if (error)
                        return "groups." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WorkspaceMembersCountRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceMembersCountRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceMembersCountRequest} WorkspaceMembersCountRequest
         */
        WorkspaceMembersCountRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceMembersCountRequest)
                return object;
            let message = new $root.api.WorkspaceMembersCountRequest();
            if (object.groups) {
                if (!Array.isArray(object.groups))
                    throw TypeError(".api.WorkspaceMembersCountRequest.groups: array expected");
                message.groups = [];
                for (let i = 0; i < object.groups.length; ++i) {
                    if (typeof object.groups[i] !== "object")
                        throw TypeError(".api.WorkspaceMembersCountRequest.groups: object expected");
                    message.groups[i] = $root.api.WorkspaceMembersFilters.fromObject(object.groups[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceMembersCountRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceMembersCountRequest
         * @static
         * @param {api.WorkspaceMembersCountRequest} message WorkspaceMembersCountRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceMembersCountRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.groups = [];
            if (message.groups && message.groups.length) {
                object.groups = [];
                for (let j = 0; j < message.groups.length; ++j)
                    object.groups[j] = $root.api.WorkspaceMembersFilters.toObject(message.groups[j], options);
            }
            return object;
        };

        /**
         * Converts this WorkspaceMembersCountRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceMembersCountRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceMembersCountRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceMembersCountRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceMembersCountRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceMembersCountRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceMembersCountRequest";
        };

        return WorkspaceMembersCountRequest;
    })();

    api.WorkspaceMembersCountResponse = (function() {

        /**
         * Properties of a WorkspaceMembersCountResponse.
         * @memberof api
         * @interface IWorkspaceMembersCountResponse
         * @property {Array.<Long>|null} [counts] WorkspaceMembersCountResponse counts
         */

        /**
         * Constructs a new WorkspaceMembersCountResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceMembersCountResponse.
         * @implements IWorkspaceMembersCountResponse
         * @constructor
         * @param {api.IWorkspaceMembersCountResponse=} [properties] Properties to set
         */
        function WorkspaceMembersCountResponse(properties) {
            this.counts = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceMembersCountResponse counts.
         * @member {Array.<Long>} counts
         * @memberof api.WorkspaceMembersCountResponse
         * @instance
         */
        WorkspaceMembersCountResponse.prototype.counts = $util.emptyArray;

        /**
         * Creates a new WorkspaceMembersCountResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceMembersCountResponse
         * @static
         * @param {api.IWorkspaceMembersCountResponse=} [properties] Properties to set
         * @returns {api.WorkspaceMembersCountResponse} WorkspaceMembersCountResponse instance
         */
        WorkspaceMembersCountResponse.create = function create(properties) {
            return new WorkspaceMembersCountResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceMembersCountResponse message. Does not implicitly {@link api.WorkspaceMembersCountResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceMembersCountResponse
         * @static
         * @param {api.IWorkspaceMembersCountResponse} message WorkspaceMembersCountResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMembersCountResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.counts != null && message.counts.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.counts.length; ++i)
                    writer.uint64(message.counts[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified WorkspaceMembersCountResponse message, length delimited. Does not implicitly {@link api.WorkspaceMembersCountResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceMembersCountResponse
         * @static
         * @param {api.IWorkspaceMembersCountResponse} message WorkspaceMembersCountResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMembersCountResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceMembersCountResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceMembersCountResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceMembersCountResponse} WorkspaceMembersCountResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMembersCountResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceMembersCountResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.counts && message.counts.length))
                            message.counts = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.counts.push(reader.uint64());
                        } else
                            message.counts.push(reader.uint64());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceMembersCountResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceMembersCountResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceMembersCountResponse} WorkspaceMembersCountResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMembersCountResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceMembersCountResponse message.
         * @function verify
         * @memberof api.WorkspaceMembersCountResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceMembersCountResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.counts != null && message.hasOwnProperty("counts")) {
                if (!Array.isArray(message.counts))
                    return "counts: array expected";
                for (let i = 0; i < message.counts.length; ++i)
                    if (!$util.isInteger(message.counts[i]) && !(message.counts[i] && $util.isInteger(message.counts[i].low) && $util.isInteger(message.counts[i].high)))
                        return "counts: integer|Long[] expected";
            }
            return null;
        };

        /**
         * Creates a WorkspaceMembersCountResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceMembersCountResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceMembersCountResponse} WorkspaceMembersCountResponse
         */
        WorkspaceMembersCountResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceMembersCountResponse)
                return object;
            let message = new $root.api.WorkspaceMembersCountResponse();
            if (object.counts) {
                if (!Array.isArray(object.counts))
                    throw TypeError(".api.WorkspaceMembersCountResponse.counts: array expected");
                message.counts = [];
                for (let i = 0; i < object.counts.length; ++i)
                    if ($util.Long)
                        (message.counts[i] = $util.Long.fromValue(object.counts[i])).unsigned = true;
                    else if (typeof object.counts[i] === "string")
                        message.counts[i] = parseInt(object.counts[i], 10);
                    else if (typeof object.counts[i] === "number")
                        message.counts[i] = object.counts[i];
                    else if (typeof object.counts[i] === "object")
                        message.counts[i] = new $util.LongBits(object.counts[i].low >>> 0, object.counts[i].high >>> 0).toNumber(true);
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceMembersCountResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceMembersCountResponse
         * @static
         * @param {api.WorkspaceMembersCountResponse} message WorkspaceMembersCountResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceMembersCountResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.counts = [];
            if (message.counts && message.counts.length) {
                object.counts = [];
                for (let j = 0; j < message.counts.length; ++j)
                    if (typeof message.counts[j] === "number")
                        object.counts[j] = options.longs === String ? String(message.counts[j]) : message.counts[j];
                    else
                        object.counts[j] = options.longs === String ? $util.Long.prototype.toString.call(message.counts[j]) : options.longs === Number ? new $util.LongBits(message.counts[j].low >>> 0, message.counts[j].high >>> 0).toNumber(true) : message.counts[j];
            }
            return object;
        };

        /**
         * Converts this WorkspaceMembersCountResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceMembersCountResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceMembersCountResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceMembersCountResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceMembersCountResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceMembersCountResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceMembersCountResponse";
        };

        return WorkspaceMembersCountResponse;
    })();

    api.WorkspaceMembersSearchRequest = (function() {

        /**
         * Properties of a WorkspaceMembersSearchRequest.
         * @memberof api
         * @interface IWorkspaceMembersSearchRequest
         * @property {string|null} [query] WorkspaceMembersSearchRequest query
         * @property {number|null} [offset] WorkspaceMembersSearchRequest offset
         */

        /**
         * Constructs a new WorkspaceMembersSearchRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceMembersSearchRequest.
         * @implements IWorkspaceMembersSearchRequest
         * @constructor
         * @param {api.IWorkspaceMembersSearchRequest=} [properties] Properties to set
         */
        function WorkspaceMembersSearchRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceMembersSearchRequest query.
         * @member {string} query
         * @memberof api.WorkspaceMembersSearchRequest
         * @instance
         */
        WorkspaceMembersSearchRequest.prototype.query = "";

        /**
         * WorkspaceMembersSearchRequest offset.
         * @member {number} offset
         * @memberof api.WorkspaceMembersSearchRequest
         * @instance
         */
        WorkspaceMembersSearchRequest.prototype.offset = 0;

        /**
         * Creates a new WorkspaceMembersSearchRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceMembersSearchRequest
         * @static
         * @param {api.IWorkspaceMembersSearchRequest=} [properties] Properties to set
         * @returns {api.WorkspaceMembersSearchRequest} WorkspaceMembersSearchRequest instance
         */
        WorkspaceMembersSearchRequest.create = function create(properties) {
            return new WorkspaceMembersSearchRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceMembersSearchRequest message. Does not implicitly {@link api.WorkspaceMembersSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceMembersSearchRequest
         * @static
         * @param {api.IWorkspaceMembersSearchRequest} message WorkspaceMembersSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMembersSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.query);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.offset);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceMembersSearchRequest message, length delimited. Does not implicitly {@link api.WorkspaceMembersSearchRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceMembersSearchRequest
         * @static
         * @param {api.IWorkspaceMembersSearchRequest} message WorkspaceMembersSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMembersSearchRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceMembersSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceMembersSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceMembersSearchRequest} WorkspaceMembersSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMembersSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceMembersSearchRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.query = reader.string();
                        break;
                    }
                case 2: {
                        message.offset = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceMembersSearchRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceMembersSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceMembersSearchRequest} WorkspaceMembersSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMembersSearchRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceMembersSearchRequest message.
         * @function verify
         * @memberof api.WorkspaceMembersSearchRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceMembersSearchRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.query != null && message.hasOwnProperty("query"))
                if (!$util.isString(message.query))
                    return "query: string expected";
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            return null;
        };

        /**
         * Creates a WorkspaceMembersSearchRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceMembersSearchRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceMembersSearchRequest} WorkspaceMembersSearchRequest
         */
        WorkspaceMembersSearchRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceMembersSearchRequest)
                return object;
            let message = new $root.api.WorkspaceMembersSearchRequest();
            if (object.query != null)
                message.query = String(object.query);
            if (object.offset != null)
                message.offset = object.offset >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceMembersSearchRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceMembersSearchRequest
         * @static
         * @param {api.WorkspaceMembersSearchRequest} message WorkspaceMembersSearchRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceMembersSearchRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.query = "";
                object.offset = 0;
            }
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = message.query;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            return object;
        };

        /**
         * Converts this WorkspaceMembersSearchRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceMembersSearchRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceMembersSearchRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceMembersSearchRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceMembersSearchRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceMembersSearchRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceMembersSearchRequest";
        };

        return WorkspaceMembersSearchRequest;
    })();

    api.WorkspaceMembersSearchResponse = (function() {

        /**
         * Properties of a WorkspaceMembersSearchResponse.
         * @memberof api
         * @interface IWorkspaceMembersSearchResponse
         * @property {api.WorkspaceMembersSearchResponse.SearchStatus|null} [status] WorkspaceMembersSearchResponse status
         * @property {Array.<entities.IWorkspaceMember>|null} [members] WorkspaceMembersSearchResponse members
         */

        /**
         * Constructs a new WorkspaceMembersSearchResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceMembersSearchResponse.
         * @implements IWorkspaceMembersSearchResponse
         * @constructor
         * @param {api.IWorkspaceMembersSearchResponse=} [properties] Properties to set
         */
        function WorkspaceMembersSearchResponse(properties) {
            this.members = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceMembersSearchResponse status.
         * @member {api.WorkspaceMembersSearchResponse.SearchStatus} status
         * @memberof api.WorkspaceMembersSearchResponse
         * @instance
         */
        WorkspaceMembersSearchResponse.prototype.status = 0;

        /**
         * WorkspaceMembersSearchResponse members.
         * @member {Array.<entities.IWorkspaceMember>} members
         * @memberof api.WorkspaceMembersSearchResponse
         * @instance
         */
        WorkspaceMembersSearchResponse.prototype.members = $util.emptyArray;

        /**
         * Creates a new WorkspaceMembersSearchResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceMembersSearchResponse
         * @static
         * @param {api.IWorkspaceMembersSearchResponse=} [properties] Properties to set
         * @returns {api.WorkspaceMembersSearchResponse} WorkspaceMembersSearchResponse instance
         */
        WorkspaceMembersSearchResponse.create = function create(properties) {
            return new WorkspaceMembersSearchResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceMembersSearchResponse message. Does not implicitly {@link api.WorkspaceMembersSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceMembersSearchResponse
         * @static
         * @param {api.IWorkspaceMembersSearchResponse} message WorkspaceMembersSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMembersSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.members != null && message.members.length)
                for (let i = 0; i < message.members.length; ++i)
                    $root.entities.WorkspaceMember.encode(message.members[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceMembersSearchResponse message, length delimited. Does not implicitly {@link api.WorkspaceMembersSearchResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceMembersSearchResponse
         * @static
         * @param {api.IWorkspaceMembersSearchResponse} message WorkspaceMembersSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMembersSearchResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceMembersSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceMembersSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceMembersSearchResponse} WorkspaceMembersSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMembersSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceMembersSearchResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 10: {
                        if (!(message.members && message.members.length))
                            message.members = [];
                        message.members.push($root.entities.WorkspaceMember.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceMembersSearchResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceMembersSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceMembersSearchResponse} WorkspaceMembersSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMembersSearchResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceMembersSearchResponse message.
         * @function verify
         * @memberof api.WorkspaceMembersSearchResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceMembersSearchResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.members != null && message.hasOwnProperty("members")) {
                if (!Array.isArray(message.members))
                    return "members: array expected";
                for (let i = 0; i < message.members.length; ++i) {
                    let error = $root.entities.WorkspaceMember.verify(message.members[i]);
                    if (error)
                        return "members." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WorkspaceMembersSearchResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceMembersSearchResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceMembersSearchResponse} WorkspaceMembersSearchResponse
         */
        WorkspaceMembersSearchResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceMembersSearchResponse)
                return object;
            let message = new $root.api.WorkspaceMembersSearchResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "SS_OK":
            case 0:
                message.status = 0;
                break;
            case "SS_QUERY_TOO_SHORT":
            case 1:
                message.status = 1;
                break;
            case "SS_EMPTY_PARAMS":
            case 2:
                message.status = 2;
                break;
            }
            if (object.members) {
                if (!Array.isArray(object.members))
                    throw TypeError(".api.WorkspaceMembersSearchResponse.members: array expected");
                message.members = [];
                for (let i = 0; i < object.members.length; ++i) {
                    if (typeof object.members[i] !== "object")
                        throw TypeError(".api.WorkspaceMembersSearchResponse.members: object expected");
                    message.members[i] = $root.entities.WorkspaceMember.fromObject(object.members[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceMembersSearchResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceMembersSearchResponse
         * @static
         * @param {api.WorkspaceMembersSearchResponse} message WorkspaceMembersSearchResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceMembersSearchResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.members = [];
            if (options.defaults)
                object.status = options.enums === String ? "SS_OK" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.api.WorkspaceMembersSearchResponse.SearchStatus[message.status] === undefined ? message.status : $root.api.WorkspaceMembersSearchResponse.SearchStatus[message.status] : message.status;
            if (message.members && message.members.length) {
                object.members = [];
                for (let j = 0; j < message.members.length; ++j)
                    object.members[j] = $root.entities.WorkspaceMember.toObject(message.members[j], options);
            }
            return object;
        };

        /**
         * Converts this WorkspaceMembersSearchResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceMembersSearchResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceMembersSearchResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceMembersSearchResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceMembersSearchResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceMembersSearchResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceMembersSearchResponse";
        };

        /**
         * SearchStatus enum.
         * @name api.WorkspaceMembersSearchResponse.SearchStatus
         * @enum {number}
         * @property {number} SS_OK=0 SS_OK value
         * @property {number} SS_QUERY_TOO_SHORT=1 SS_QUERY_TOO_SHORT value
         * @property {number} SS_EMPTY_PARAMS=2 SS_EMPTY_PARAMS value
         */
        WorkspaceMembersSearchResponse.SearchStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SS_OK"] = 0;
            values[valuesById[1] = "SS_QUERY_TOO_SHORT"] = 1;
            values[valuesById[2] = "SS_EMPTY_PARAMS"] = 2;
            return values;
        })();

        return WorkspaceMembersSearchResponse;
    })();

    api.WorkspaceInviteRequest = (function() {

        /**
         * Properties of a WorkspaceInviteRequest.
         * @memberof api
         * @interface IWorkspaceInviteRequest
         * @property {entities.WorkspaceMemberSource|null} [source] WorkspaceInviteRequest source
         * @property {entities.WorkspaceRole|null} [role] WorkspaceInviteRequest role
         * @property {string|null} [email] WorkspaceInviteRequest email
         * @property {Array.<entities.WorkspaceMemberPermission>|null} [customPermissions] WorkspaceInviteRequest customPermissions
         */

        /**
         * Constructs a new WorkspaceInviteRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceInviteRequest.
         * @implements IWorkspaceInviteRequest
         * @constructor
         * @param {api.IWorkspaceInviteRequest=} [properties] Properties to set
         */
        function WorkspaceInviteRequest(properties) {
            this.customPermissions = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceInviteRequest source.
         * @member {entities.WorkspaceMemberSource} source
         * @memberof api.WorkspaceInviteRequest
         * @instance
         */
        WorkspaceInviteRequest.prototype.source = 0;

        /**
         * WorkspaceInviteRequest role.
         * @member {entities.WorkspaceRole} role
         * @memberof api.WorkspaceInviteRequest
         * @instance
         */
        WorkspaceInviteRequest.prototype.role = 0;

        /**
         * WorkspaceInviteRequest email.
         * @member {string} email
         * @memberof api.WorkspaceInviteRequest
         * @instance
         */
        WorkspaceInviteRequest.prototype.email = "";

        /**
         * WorkspaceInviteRequest customPermissions.
         * @member {Array.<entities.WorkspaceMemberPermission>} customPermissions
         * @memberof api.WorkspaceInviteRequest
         * @instance
         */
        WorkspaceInviteRequest.prototype.customPermissions = $util.emptyArray;

        /**
         * Creates a new WorkspaceInviteRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceInviteRequest
         * @static
         * @param {api.IWorkspaceInviteRequest=} [properties] Properties to set
         * @returns {api.WorkspaceInviteRequest} WorkspaceInviteRequest instance
         */
        WorkspaceInviteRequest.create = function create(properties) {
            return new WorkspaceInviteRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceInviteRequest message. Does not implicitly {@link api.WorkspaceInviteRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceInviteRequest
         * @static
         * @param {api.IWorkspaceInviteRequest} message WorkspaceInviteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInviteRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.source);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.role);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
            if (message.customPermissions != null && message.customPermissions.length) {
                writer.uint32(/* id 10, wireType 2 =*/82).fork();
                for (let i = 0; i < message.customPermissions.length; ++i)
                    writer.int32(message.customPermissions[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified WorkspaceInviteRequest message, length delimited. Does not implicitly {@link api.WorkspaceInviteRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceInviteRequest
         * @static
         * @param {api.IWorkspaceInviteRequest} message WorkspaceInviteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceInviteRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceInviteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceInviteRequest} WorkspaceInviteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInviteRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceInviteRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.source = reader.int32();
                        break;
                    }
                case 2: {
                        message.role = reader.int32();
                        break;
                    }
                case 3: {
                        message.email = reader.string();
                        break;
                    }
                case 10: {
                        if (!(message.customPermissions && message.customPermissions.length))
                            message.customPermissions = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.customPermissions.push(reader.int32());
                        } else
                            message.customPermissions.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceInviteRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceInviteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceInviteRequest} WorkspaceInviteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInviteRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceInviteRequest message.
         * @function verify
         * @memberof api.WorkspaceInviteRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceInviteRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.source != null && message.hasOwnProperty("source"))
                switch (message.source) {
                default:
                    return "source: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.role != null && message.hasOwnProperty("role"))
                switch (message.role) {
                default:
                    return "role: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.customPermissions != null && message.hasOwnProperty("customPermissions")) {
                if (!Array.isArray(message.customPermissions))
                    return "customPermissions: array expected";
                for (let i = 0; i < message.customPermissions.length; ++i)
                    switch (message.customPermissions[i]) {
                    default:
                        return "customPermissions: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
            }
            return null;
        };

        /**
         * Creates a WorkspaceInviteRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceInviteRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceInviteRequest} WorkspaceInviteRequest
         */
        WorkspaceInviteRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceInviteRequest)
                return object;
            let message = new $root.api.WorkspaceInviteRequest();
            switch (object.source) {
            default:
                if (typeof object.source === "number") {
                    message.source = object.source;
                    break;
                }
                break;
            case "WIT_CREATOR":
            case 0:
                message.source = 0;
                break;
            case "WIT_EMAIL_INVITE":
            case 1:
                message.source = 1;
                break;
            case "WIT_LINK_INVITE":
            case 2:
                message.source = 2;
                break;
            }
            switch (object.role) {
            default:
                if (typeof object.role === "number") {
                    message.role = object.role;
                    break;
                }
                break;
            case "WR_NONE":
            case 0:
                message.role = 0;
                break;
            case "WR_OWNER":
            case 1:
                message.role = 1;
                break;
            case "WR_ADMIN":
            case 2:
                message.role = 2;
                break;
            case "WR_OPERATOR":
            case 3:
                message.role = 3;
                break;
            }
            if (object.email != null)
                message.email = String(object.email);
            if (object.customPermissions) {
                if (!Array.isArray(object.customPermissions))
                    throw TypeError(".api.WorkspaceInviteRequest.customPermissions: array expected");
                message.customPermissions = [];
                for (let i = 0; i < object.customPermissions.length; ++i)
                    switch (object.customPermissions[i]) {
                    default:
                        if (typeof object.customPermissions[i] === "number") {
                            message.customPermissions[i] = object.customPermissions[i];
                            break;
                        }
                    case "PT_ACCESS_TO_NEW_CHATS":
                    case 0:
                        message.customPermissions[i] = 0;
                        break;
                    case "PT_ACCESS_TO_CONTACT_INFO":
                    case 1:
                        message.customPermissions[i] = 1;
                        break;
                    case "PT_ACCESS_TO_CONTACTS_LIST":
                    case 2:
                        message.customPermissions[i] = 2;
                        break;
                    case "PT_ALLOW_MANAGE_CHATS":
                    case 3:
                        message.customPermissions[i] = 3;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceInviteRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceInviteRequest
         * @static
         * @param {api.WorkspaceInviteRequest} message WorkspaceInviteRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceInviteRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.customPermissions = [];
            if (options.defaults) {
                object.source = options.enums === String ? "WIT_CREATOR" : 0;
                object.role = options.enums === String ? "WR_NONE" : 0;
                object.email = "";
            }
            if (message.source != null && message.hasOwnProperty("source"))
                object.source = options.enums === String ? $root.entities.WorkspaceMemberSource[message.source] === undefined ? message.source : $root.entities.WorkspaceMemberSource[message.source] : message.source;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = options.enums === String ? $root.entities.WorkspaceRole[message.role] === undefined ? message.role : $root.entities.WorkspaceRole[message.role] : message.role;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.customPermissions && message.customPermissions.length) {
                object.customPermissions = [];
                for (let j = 0; j < message.customPermissions.length; ++j)
                    object.customPermissions[j] = options.enums === String ? $root.entities.WorkspaceMemberPermission[message.customPermissions[j]] === undefined ? message.customPermissions[j] : $root.entities.WorkspaceMemberPermission[message.customPermissions[j]] : message.customPermissions[j];
            }
            return object;
        };

        /**
         * Converts this WorkspaceInviteRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceInviteRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceInviteRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceInviteRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceInviteRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceInviteRequest";
        };

        return WorkspaceInviteRequest;
    })();

    api.WorkspaceInviteResponse = (function() {

        /**
         * Properties of a WorkspaceInviteResponse.
         * @memberof api
         * @interface IWorkspaceInviteResponse
         * @property {entities.WorkspaceInviteResult|null} [status] WorkspaceInviteResponse status
         * @property {string|null} [link] WorkspaceInviteResponse link
         * @property {Long|null} [expiredAt] WorkspaceInviteResponse expiredAt
         * @property {Long|null} [memberId] WorkspaceInviteResponse memberId
         */

        /**
         * Constructs a new WorkspaceInviteResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceInviteResponse.
         * @implements IWorkspaceInviteResponse
         * @constructor
         * @param {api.IWorkspaceInviteResponse=} [properties] Properties to set
         */
        function WorkspaceInviteResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceInviteResponse status.
         * @member {entities.WorkspaceInviteResult} status
         * @memberof api.WorkspaceInviteResponse
         * @instance
         */
        WorkspaceInviteResponse.prototype.status = 0;

        /**
         * WorkspaceInviteResponse link.
         * @member {string} link
         * @memberof api.WorkspaceInviteResponse
         * @instance
         */
        WorkspaceInviteResponse.prototype.link = "";

        /**
         * WorkspaceInviteResponse expiredAt.
         * @member {Long} expiredAt
         * @memberof api.WorkspaceInviteResponse
         * @instance
         */
        WorkspaceInviteResponse.prototype.expiredAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * WorkspaceInviteResponse memberId.
         * @member {Long} memberId
         * @memberof api.WorkspaceInviteResponse
         * @instance
         */
        WorkspaceInviteResponse.prototype.memberId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new WorkspaceInviteResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceInviteResponse
         * @static
         * @param {api.IWorkspaceInviteResponse=} [properties] Properties to set
         * @returns {api.WorkspaceInviteResponse} WorkspaceInviteResponse instance
         */
        WorkspaceInviteResponse.create = function create(properties) {
            return new WorkspaceInviteResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceInviteResponse message. Does not implicitly {@link api.WorkspaceInviteResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceInviteResponse
         * @static
         * @param {api.IWorkspaceInviteResponse} message WorkspaceInviteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInviteResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.link != null && Object.hasOwnProperty.call(message, "link"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.link);
            if (message.expiredAt != null && Object.hasOwnProperty.call(message, "expiredAt"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.expiredAt);
            if (message.memberId != null && Object.hasOwnProperty.call(message, "memberId"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.memberId);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceInviteResponse message, length delimited. Does not implicitly {@link api.WorkspaceInviteResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceInviteResponse
         * @static
         * @param {api.IWorkspaceInviteResponse} message WorkspaceInviteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceInviteResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceInviteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceInviteResponse} WorkspaceInviteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInviteResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceInviteResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.link = reader.string();
                        break;
                    }
                case 3: {
                        message.expiredAt = reader.uint64();
                        break;
                    }
                case 4: {
                        message.memberId = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceInviteResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceInviteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceInviteResponse} WorkspaceInviteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInviteResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceInviteResponse message.
         * @function verify
         * @memberof api.WorkspaceInviteResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceInviteResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.link != null && message.hasOwnProperty("link"))
                if (!$util.isString(message.link))
                    return "link: string expected";
            if (message.expiredAt != null && message.hasOwnProperty("expiredAt"))
                if (!$util.isInteger(message.expiredAt) && !(message.expiredAt && $util.isInteger(message.expiredAt.low) && $util.isInteger(message.expiredAt.high)))
                    return "expiredAt: integer|Long expected";
            if (message.memberId != null && message.hasOwnProperty("memberId"))
                if (!$util.isInteger(message.memberId) && !(message.memberId && $util.isInteger(message.memberId.low) && $util.isInteger(message.memberId.high)))
                    return "memberId: integer|Long expected";
            return null;
        };

        /**
         * Creates a WorkspaceInviteResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceInviteResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceInviteResponse} WorkspaceInviteResponse
         */
        WorkspaceInviteResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceInviteResponse)
                return object;
            let message = new $root.api.WorkspaceInviteResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "WIR_SUCCESS":
            case 0:
                message.status = 0;
                break;
            case "WIR_INVALID_EMAIL":
            case 1:
                message.status = 1;
                break;
            case "WIR_ALREADY_INVITED":
            case 2:
                message.status = 2;
                break;
            case "WIR_INVALID_ROLE":
            case 3:
                message.status = 3;
                break;
            }
            if (object.link != null)
                message.link = String(object.link);
            if (object.expiredAt != null)
                if ($util.Long)
                    (message.expiredAt = $util.Long.fromValue(object.expiredAt)).unsigned = true;
                else if (typeof object.expiredAt === "string")
                    message.expiredAt = parseInt(object.expiredAt, 10);
                else if (typeof object.expiredAt === "number")
                    message.expiredAt = object.expiredAt;
                else if (typeof object.expiredAt === "object")
                    message.expiredAt = new $util.LongBits(object.expiredAt.low >>> 0, object.expiredAt.high >>> 0).toNumber(true);
            if (object.memberId != null)
                if ($util.Long)
                    (message.memberId = $util.Long.fromValue(object.memberId)).unsigned = true;
                else if (typeof object.memberId === "string")
                    message.memberId = parseInt(object.memberId, 10);
                else if (typeof object.memberId === "number")
                    message.memberId = object.memberId;
                else if (typeof object.memberId === "object")
                    message.memberId = new $util.LongBits(object.memberId.low >>> 0, object.memberId.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceInviteResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceInviteResponse
         * @static
         * @param {api.WorkspaceInviteResponse} message WorkspaceInviteResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceInviteResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "WIR_SUCCESS" : 0;
                object.link = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.expiredAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expiredAt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.memberId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.memberId = options.longs === String ? "0" : 0;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.entities.WorkspaceInviteResult[message.status] === undefined ? message.status : $root.entities.WorkspaceInviteResult[message.status] : message.status;
            if (message.link != null && message.hasOwnProperty("link"))
                object.link = message.link;
            if (message.expiredAt != null && message.hasOwnProperty("expiredAt"))
                if (typeof message.expiredAt === "number")
                    object.expiredAt = options.longs === String ? String(message.expiredAt) : message.expiredAt;
                else
                    object.expiredAt = options.longs === String ? $util.Long.prototype.toString.call(message.expiredAt) : options.longs === Number ? new $util.LongBits(message.expiredAt.low >>> 0, message.expiredAt.high >>> 0).toNumber(true) : message.expiredAt;
            if (message.memberId != null && message.hasOwnProperty("memberId"))
                if (typeof message.memberId === "number")
                    object.memberId = options.longs === String ? String(message.memberId) : message.memberId;
                else
                    object.memberId = options.longs === String ? $util.Long.prototype.toString.call(message.memberId) : options.longs === Number ? new $util.LongBits(message.memberId.low >>> 0, message.memberId.high >>> 0).toNumber(true) : message.memberId;
            return object;
        };

        /**
         * Converts this WorkspaceInviteResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceInviteResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceInviteResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceInviteResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceInviteResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceInviteResponse";
        };

        return WorkspaceInviteResponse;
    })();

    api.WorkspaceResendInviteRequest = (function() {

        /**
         * Properties of a WorkspaceResendInviteRequest.
         * @memberof api
         * @interface IWorkspaceResendInviteRequest
         * @property {Uint8Array|null} [inviteID] WorkspaceResendInviteRequest inviteID
         */

        /**
         * Constructs a new WorkspaceResendInviteRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceResendInviteRequest.
         * @implements IWorkspaceResendInviteRequest
         * @constructor
         * @param {api.IWorkspaceResendInviteRequest=} [properties] Properties to set
         */
        function WorkspaceResendInviteRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceResendInviteRequest inviteID.
         * @member {Uint8Array} inviteID
         * @memberof api.WorkspaceResendInviteRequest
         * @instance
         */
        WorkspaceResendInviteRequest.prototype.inviteID = $util.newBuffer([]);

        /**
         * Creates a new WorkspaceResendInviteRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceResendInviteRequest
         * @static
         * @param {api.IWorkspaceResendInviteRequest=} [properties] Properties to set
         * @returns {api.WorkspaceResendInviteRequest} WorkspaceResendInviteRequest instance
         */
        WorkspaceResendInviteRequest.create = function create(properties) {
            return new WorkspaceResendInviteRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceResendInviteRequest message. Does not implicitly {@link api.WorkspaceResendInviteRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceResendInviteRequest
         * @static
         * @param {api.IWorkspaceResendInviteRequest} message WorkspaceResendInviteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceResendInviteRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.inviteID != null && Object.hasOwnProperty.call(message, "inviteID"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.inviteID);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceResendInviteRequest message, length delimited. Does not implicitly {@link api.WorkspaceResendInviteRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceResendInviteRequest
         * @static
         * @param {api.IWorkspaceResendInviteRequest} message WorkspaceResendInviteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceResendInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceResendInviteRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceResendInviteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceResendInviteRequest} WorkspaceResendInviteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceResendInviteRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceResendInviteRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.inviteID = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceResendInviteRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceResendInviteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceResendInviteRequest} WorkspaceResendInviteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceResendInviteRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceResendInviteRequest message.
         * @function verify
         * @memberof api.WorkspaceResendInviteRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceResendInviteRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.inviteID != null && message.hasOwnProperty("inviteID"))
                if (!(message.inviteID && typeof message.inviteID.length === "number" || $util.isString(message.inviteID)))
                    return "inviteID: buffer expected";
            return null;
        };

        /**
         * Creates a WorkspaceResendInviteRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceResendInviteRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceResendInviteRequest} WorkspaceResendInviteRequest
         */
        WorkspaceResendInviteRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceResendInviteRequest)
                return object;
            let message = new $root.api.WorkspaceResendInviteRequest();
            if (object.inviteID != null)
                if (typeof object.inviteID === "string")
                    $util.base64.decode(object.inviteID, message.inviteID = $util.newBuffer($util.base64.length(object.inviteID)), 0);
                else if (object.inviteID.length >= 0)
                    message.inviteID = object.inviteID;
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceResendInviteRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceResendInviteRequest
         * @static
         * @param {api.WorkspaceResendInviteRequest} message WorkspaceResendInviteRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceResendInviteRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.inviteID = "";
                else {
                    object.inviteID = [];
                    if (options.bytes !== Array)
                        object.inviteID = $util.newBuffer(object.inviteID);
                }
            if (message.inviteID != null && message.hasOwnProperty("inviteID"))
                object.inviteID = options.bytes === String ? $util.base64.encode(message.inviteID, 0, message.inviteID.length) : options.bytes === Array ? Array.prototype.slice.call(message.inviteID) : message.inviteID;
            return object;
        };

        /**
         * Converts this WorkspaceResendInviteRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceResendInviteRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceResendInviteRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceResendInviteRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceResendInviteRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceResendInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceResendInviteRequest";
        };

        return WorkspaceResendInviteRequest;
    })();

    api.WorkspaceResendInviteResponse = (function() {

        /**
         * Properties of a WorkspaceResendInviteResponse.
         * @memberof api
         * @interface IWorkspaceResendInviteResponse
         * @property {entities.WorkspaceInviteResendResult|null} [status] WorkspaceResendInviteResponse status
         * @property {Long|null} [nextTryTimestamp] WorkspaceResendInviteResponse nextTryTimestamp
         * @property {Long|null} [expiredAt] WorkspaceResendInviteResponse expiredAt
         */

        /**
         * Constructs a new WorkspaceResendInviteResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceResendInviteResponse.
         * @implements IWorkspaceResendInviteResponse
         * @constructor
         * @param {api.IWorkspaceResendInviteResponse=} [properties] Properties to set
         */
        function WorkspaceResendInviteResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceResendInviteResponse status.
         * @member {entities.WorkspaceInviteResendResult} status
         * @memberof api.WorkspaceResendInviteResponse
         * @instance
         */
        WorkspaceResendInviteResponse.prototype.status = 0;

        /**
         * WorkspaceResendInviteResponse nextTryTimestamp.
         * @member {Long} nextTryTimestamp
         * @memberof api.WorkspaceResendInviteResponse
         * @instance
         */
        WorkspaceResendInviteResponse.prototype.nextTryTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * WorkspaceResendInviteResponse expiredAt.
         * @member {Long} expiredAt
         * @memberof api.WorkspaceResendInviteResponse
         * @instance
         */
        WorkspaceResendInviteResponse.prototype.expiredAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new WorkspaceResendInviteResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceResendInviteResponse
         * @static
         * @param {api.IWorkspaceResendInviteResponse=} [properties] Properties to set
         * @returns {api.WorkspaceResendInviteResponse} WorkspaceResendInviteResponse instance
         */
        WorkspaceResendInviteResponse.create = function create(properties) {
            return new WorkspaceResendInviteResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceResendInviteResponse message. Does not implicitly {@link api.WorkspaceResendInviteResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceResendInviteResponse
         * @static
         * @param {api.IWorkspaceResendInviteResponse} message WorkspaceResendInviteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceResendInviteResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.nextTryTimestamp != null && Object.hasOwnProperty.call(message, "nextTryTimestamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.nextTryTimestamp);
            if (message.expiredAt != null && Object.hasOwnProperty.call(message, "expiredAt"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.expiredAt);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceResendInviteResponse message, length delimited. Does not implicitly {@link api.WorkspaceResendInviteResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceResendInviteResponse
         * @static
         * @param {api.IWorkspaceResendInviteResponse} message WorkspaceResendInviteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceResendInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceResendInviteResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceResendInviteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceResendInviteResponse} WorkspaceResendInviteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceResendInviteResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceResendInviteResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.nextTryTimestamp = reader.uint64();
                        break;
                    }
                case 3: {
                        message.expiredAt = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceResendInviteResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceResendInviteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceResendInviteResponse} WorkspaceResendInviteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceResendInviteResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceResendInviteResponse message.
         * @function verify
         * @memberof api.WorkspaceResendInviteResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceResendInviteResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.nextTryTimestamp != null && message.hasOwnProperty("nextTryTimestamp"))
                if (!$util.isInteger(message.nextTryTimestamp) && !(message.nextTryTimestamp && $util.isInteger(message.nextTryTimestamp.low) && $util.isInteger(message.nextTryTimestamp.high)))
                    return "nextTryTimestamp: integer|Long expected";
            if (message.expiredAt != null && message.hasOwnProperty("expiredAt"))
                if (!$util.isInteger(message.expiredAt) && !(message.expiredAt && $util.isInteger(message.expiredAt.low) && $util.isInteger(message.expiredAt.high)))
                    return "expiredAt: integer|Long expected";
            return null;
        };

        /**
         * Creates a WorkspaceResendInviteResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceResendInviteResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceResendInviteResponse} WorkspaceResendInviteResponse
         */
        WorkspaceResendInviteResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceResendInviteResponse)
                return object;
            let message = new $root.api.WorkspaceResendInviteResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "WIRR_SUCCESS":
            case 0:
                message.status = 0;
                break;
            case "WIRR_MEMBER_NOT_FOUND":
            case 1:
                message.status = 1;
                break;
            case "WIRR_PENDING_STAMP":
            case 2:
                message.status = 2;
                break;
            case "WIRR_NO_EMAIL":
            case 3:
                message.status = 3;
                break;
            }
            if (object.nextTryTimestamp != null)
                if ($util.Long)
                    (message.nextTryTimestamp = $util.Long.fromValue(object.nextTryTimestamp)).unsigned = true;
                else if (typeof object.nextTryTimestamp === "string")
                    message.nextTryTimestamp = parseInt(object.nextTryTimestamp, 10);
                else if (typeof object.nextTryTimestamp === "number")
                    message.nextTryTimestamp = object.nextTryTimestamp;
                else if (typeof object.nextTryTimestamp === "object")
                    message.nextTryTimestamp = new $util.LongBits(object.nextTryTimestamp.low >>> 0, object.nextTryTimestamp.high >>> 0).toNumber(true);
            if (object.expiredAt != null)
                if ($util.Long)
                    (message.expiredAt = $util.Long.fromValue(object.expiredAt)).unsigned = true;
                else if (typeof object.expiredAt === "string")
                    message.expiredAt = parseInt(object.expiredAt, 10);
                else if (typeof object.expiredAt === "number")
                    message.expiredAt = object.expiredAt;
                else if (typeof object.expiredAt === "object")
                    message.expiredAt = new $util.LongBits(object.expiredAt.low >>> 0, object.expiredAt.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceResendInviteResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceResendInviteResponse
         * @static
         * @param {api.WorkspaceResendInviteResponse} message WorkspaceResendInviteResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceResendInviteResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "WIRR_SUCCESS" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.nextTryTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nextTryTimestamp = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.expiredAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expiredAt = options.longs === String ? "0" : 0;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.entities.WorkspaceInviteResendResult[message.status] === undefined ? message.status : $root.entities.WorkspaceInviteResendResult[message.status] : message.status;
            if (message.nextTryTimestamp != null && message.hasOwnProperty("nextTryTimestamp"))
                if (typeof message.nextTryTimestamp === "number")
                    object.nextTryTimestamp = options.longs === String ? String(message.nextTryTimestamp) : message.nextTryTimestamp;
                else
                    object.nextTryTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.nextTryTimestamp) : options.longs === Number ? new $util.LongBits(message.nextTryTimestamp.low >>> 0, message.nextTryTimestamp.high >>> 0).toNumber(true) : message.nextTryTimestamp;
            if (message.expiredAt != null && message.hasOwnProperty("expiredAt"))
                if (typeof message.expiredAt === "number")
                    object.expiredAt = options.longs === String ? String(message.expiredAt) : message.expiredAt;
                else
                    object.expiredAt = options.longs === String ? $util.Long.prototype.toString.call(message.expiredAt) : options.longs === Number ? new $util.LongBits(message.expiredAt.low >>> 0, message.expiredAt.high >>> 0).toNumber(true) : message.expiredAt;
            return object;
        };

        /**
         * Converts this WorkspaceResendInviteResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceResendInviteResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceResendInviteResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceResendInviteResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceResendInviteResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceResendInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceResendInviteResponse";
        };

        return WorkspaceResendInviteResponse;
    })();

    api.WorkspaceUpdateMemberRequest = (function() {

        /**
         * Properties of a WorkspaceUpdateMemberRequest.
         * @memberof api
         * @interface IWorkspaceUpdateMemberRequest
         * @property {Long|null} [memberId] WorkspaceUpdateMemberRequest memberId
         * @property {entities.WorkspaceRole|null} [newRole] WorkspaceUpdateMemberRequest newRole
         * @property {entities.WorkspaceMemberStatus|null} [newStatus] WorkspaceUpdateMemberRequest newStatus
         * @property {entities.IWorkspaceMemberAccess|null} [newAccess] WorkspaceUpdateMemberRequest newAccess
         */

        /**
         * Constructs a new WorkspaceUpdateMemberRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceUpdateMemberRequest.
         * @implements IWorkspaceUpdateMemberRequest
         * @constructor
         * @param {api.IWorkspaceUpdateMemberRequest=} [properties] Properties to set
         */
        function WorkspaceUpdateMemberRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceUpdateMemberRequest memberId.
         * @member {Long} memberId
         * @memberof api.WorkspaceUpdateMemberRequest
         * @instance
         */
        WorkspaceUpdateMemberRequest.prototype.memberId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * WorkspaceUpdateMemberRequest newRole.
         * @member {entities.WorkspaceRole} newRole
         * @memberof api.WorkspaceUpdateMemberRequest
         * @instance
         */
        WorkspaceUpdateMemberRequest.prototype.newRole = 0;

        /**
         * WorkspaceUpdateMemberRequest newStatus.
         * @member {entities.WorkspaceMemberStatus} newStatus
         * @memberof api.WorkspaceUpdateMemberRequest
         * @instance
         */
        WorkspaceUpdateMemberRequest.prototype.newStatus = 0;

        /**
         * WorkspaceUpdateMemberRequest newAccess.
         * @member {entities.IWorkspaceMemberAccess|null|undefined} newAccess
         * @memberof api.WorkspaceUpdateMemberRequest
         * @instance
         */
        WorkspaceUpdateMemberRequest.prototype.newAccess = null;

        /**
         * Creates a new WorkspaceUpdateMemberRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceUpdateMemberRequest
         * @static
         * @param {api.IWorkspaceUpdateMemberRequest=} [properties] Properties to set
         * @returns {api.WorkspaceUpdateMemberRequest} WorkspaceUpdateMemberRequest instance
         */
        WorkspaceUpdateMemberRequest.create = function create(properties) {
            return new WorkspaceUpdateMemberRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceUpdateMemberRequest message. Does not implicitly {@link api.WorkspaceUpdateMemberRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceUpdateMemberRequest
         * @static
         * @param {api.IWorkspaceUpdateMemberRequest} message WorkspaceUpdateMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceUpdateMemberRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.memberId != null && Object.hasOwnProperty.call(message, "memberId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.memberId);
            if (message.newRole != null && Object.hasOwnProperty.call(message, "newRole"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.newRole);
            if (message.newStatus != null && Object.hasOwnProperty.call(message, "newStatus"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.newStatus);
            if (message.newAccess != null && Object.hasOwnProperty.call(message, "newAccess"))
                $root.entities.WorkspaceMemberAccess.encode(message.newAccess, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceUpdateMemberRequest message, length delimited. Does not implicitly {@link api.WorkspaceUpdateMemberRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceUpdateMemberRequest
         * @static
         * @param {api.IWorkspaceUpdateMemberRequest} message WorkspaceUpdateMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceUpdateMemberRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceUpdateMemberRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceUpdateMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceUpdateMemberRequest} WorkspaceUpdateMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceUpdateMemberRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceUpdateMemberRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.memberId = reader.uint64();
                        break;
                    }
                case 2: {
                        message.newRole = reader.int32();
                        break;
                    }
                case 3: {
                        message.newStatus = reader.int32();
                        break;
                    }
                case 4: {
                        message.newAccess = $root.entities.WorkspaceMemberAccess.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceUpdateMemberRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceUpdateMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceUpdateMemberRequest} WorkspaceUpdateMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceUpdateMemberRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceUpdateMemberRequest message.
         * @function verify
         * @memberof api.WorkspaceUpdateMemberRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceUpdateMemberRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.memberId != null && message.hasOwnProperty("memberId"))
                if (!$util.isInteger(message.memberId) && !(message.memberId && $util.isInteger(message.memberId.low) && $util.isInteger(message.memberId.high)))
                    return "memberId: integer|Long expected";
            if (message.newRole != null && message.hasOwnProperty("newRole"))
                switch (message.newRole) {
                default:
                    return "newRole: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.newStatus != null && message.hasOwnProperty("newStatus"))
                switch (message.newStatus) {
                default:
                    return "newStatus: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.newAccess != null && message.hasOwnProperty("newAccess")) {
                let error = $root.entities.WorkspaceMemberAccess.verify(message.newAccess);
                if (error)
                    return "newAccess." + error;
            }
            return null;
        };

        /**
         * Creates a WorkspaceUpdateMemberRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceUpdateMemberRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceUpdateMemberRequest} WorkspaceUpdateMemberRequest
         */
        WorkspaceUpdateMemberRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceUpdateMemberRequest)
                return object;
            let message = new $root.api.WorkspaceUpdateMemberRequest();
            if (object.memberId != null)
                if ($util.Long)
                    (message.memberId = $util.Long.fromValue(object.memberId)).unsigned = true;
                else if (typeof object.memberId === "string")
                    message.memberId = parseInt(object.memberId, 10);
                else if (typeof object.memberId === "number")
                    message.memberId = object.memberId;
                else if (typeof object.memberId === "object")
                    message.memberId = new $util.LongBits(object.memberId.low >>> 0, object.memberId.high >>> 0).toNumber(true);
            switch (object.newRole) {
            default:
                if (typeof object.newRole === "number") {
                    message.newRole = object.newRole;
                    break;
                }
                break;
            case "WR_NONE":
            case 0:
                message.newRole = 0;
                break;
            case "WR_OWNER":
            case 1:
                message.newRole = 1;
                break;
            case "WR_ADMIN":
            case 2:
                message.newRole = 2;
                break;
            case "WR_OPERATOR":
            case 3:
                message.newRole = 3;
                break;
            }
            switch (object.newStatus) {
            default:
                if (typeof object.newStatus === "number") {
                    message.newStatus = object.newStatus;
                    break;
                }
                break;
            case "WM_ACTIVE":
            case 0:
                message.newStatus = 0;
                break;
            case "WM_BLOCKED":
            case 1:
                message.newStatus = 1;
                break;
            case "WM_INVITED":
            case 2:
                message.newStatus = 2;
                break;
            case "WM_INVITE_EXPIRED":
            case 3:
                message.newStatus = 3;
                break;
            case "WM_REMOVED":
            case 4:
                message.newStatus = 4;
                break;
            }
            if (object.newAccess != null) {
                if (typeof object.newAccess !== "object")
                    throw TypeError(".api.WorkspaceUpdateMemberRequest.newAccess: object expected");
                message.newAccess = $root.entities.WorkspaceMemberAccess.fromObject(object.newAccess);
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceUpdateMemberRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceUpdateMemberRequest
         * @static
         * @param {api.WorkspaceUpdateMemberRequest} message WorkspaceUpdateMemberRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceUpdateMemberRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.memberId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.memberId = options.longs === String ? "0" : 0;
                object.newRole = options.enums === String ? "WR_NONE" : 0;
                object.newStatus = options.enums === String ? "WM_ACTIVE" : 0;
                object.newAccess = null;
            }
            if (message.memberId != null && message.hasOwnProperty("memberId"))
                if (typeof message.memberId === "number")
                    object.memberId = options.longs === String ? String(message.memberId) : message.memberId;
                else
                    object.memberId = options.longs === String ? $util.Long.prototype.toString.call(message.memberId) : options.longs === Number ? new $util.LongBits(message.memberId.low >>> 0, message.memberId.high >>> 0).toNumber(true) : message.memberId;
            if (message.newRole != null && message.hasOwnProperty("newRole"))
                object.newRole = options.enums === String ? $root.entities.WorkspaceRole[message.newRole] === undefined ? message.newRole : $root.entities.WorkspaceRole[message.newRole] : message.newRole;
            if (message.newStatus != null && message.hasOwnProperty("newStatus"))
                object.newStatus = options.enums === String ? $root.entities.WorkspaceMemberStatus[message.newStatus] === undefined ? message.newStatus : $root.entities.WorkspaceMemberStatus[message.newStatus] : message.newStatus;
            if (message.newAccess != null && message.hasOwnProperty("newAccess"))
                object.newAccess = $root.entities.WorkspaceMemberAccess.toObject(message.newAccess, options);
            return object;
        };

        /**
         * Converts this WorkspaceUpdateMemberRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceUpdateMemberRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceUpdateMemberRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceUpdateMemberRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceUpdateMemberRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceUpdateMemberRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceUpdateMemberRequest";
        };

        return WorkspaceUpdateMemberRequest;
    })();

    api.WorkspaceUpdateMemberResponse = (function() {

        /**
         * Properties of a WorkspaceUpdateMemberResponse.
         * @memberof api
         * @interface IWorkspaceUpdateMemberResponse
         * @property {entities.WorkspaceUpdateMemberResult|null} [status] WorkspaceUpdateMemberResponse status
         */

        /**
         * Constructs a new WorkspaceUpdateMemberResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceUpdateMemberResponse.
         * @implements IWorkspaceUpdateMemberResponse
         * @constructor
         * @param {api.IWorkspaceUpdateMemberResponse=} [properties] Properties to set
         */
        function WorkspaceUpdateMemberResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceUpdateMemberResponse status.
         * @member {entities.WorkspaceUpdateMemberResult} status
         * @memberof api.WorkspaceUpdateMemberResponse
         * @instance
         */
        WorkspaceUpdateMemberResponse.prototype.status = 0;

        /**
         * Creates a new WorkspaceUpdateMemberResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceUpdateMemberResponse
         * @static
         * @param {api.IWorkspaceUpdateMemberResponse=} [properties] Properties to set
         * @returns {api.WorkspaceUpdateMemberResponse} WorkspaceUpdateMemberResponse instance
         */
        WorkspaceUpdateMemberResponse.create = function create(properties) {
            return new WorkspaceUpdateMemberResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceUpdateMemberResponse message. Does not implicitly {@link api.WorkspaceUpdateMemberResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceUpdateMemberResponse
         * @static
         * @param {api.IWorkspaceUpdateMemberResponse} message WorkspaceUpdateMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceUpdateMemberResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceUpdateMemberResponse message, length delimited. Does not implicitly {@link api.WorkspaceUpdateMemberResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceUpdateMemberResponse
         * @static
         * @param {api.IWorkspaceUpdateMemberResponse} message WorkspaceUpdateMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceUpdateMemberResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceUpdateMemberResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceUpdateMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceUpdateMemberResponse} WorkspaceUpdateMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceUpdateMemberResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceUpdateMemberResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceUpdateMemberResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceUpdateMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceUpdateMemberResponse} WorkspaceUpdateMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceUpdateMemberResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceUpdateMemberResponse message.
         * @function verify
         * @memberof api.WorkspaceUpdateMemberResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceUpdateMemberResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            return null;
        };

        /**
         * Creates a WorkspaceUpdateMemberResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceUpdateMemberResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceUpdateMemberResponse} WorkspaceUpdateMemberResponse
         */
        WorkspaceUpdateMemberResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceUpdateMemberResponse)
                return object;
            let message = new $root.api.WorkspaceUpdateMemberResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "WUMR_SUCCESS":
            case 0:
                message.status = 0;
                break;
            case "WUMR_MEMBER_NOT_FOUND":
            case 1:
                message.status = 1;
                break;
            case "WUMR_INVALID_ROLE":
            case 2:
                message.status = 2;
                break;
            case "WUMR_INVALID_STATUS":
            case 3:
                message.status = 3;
                break;
            case "WUMR_INVALID_ACCESS":
            case 4:
                message.status = 4;
                break;
            case "WUMR_WORKSPACE_LIMIT":
            case 5:
                message.status = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceUpdateMemberResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceUpdateMemberResponse
         * @static
         * @param {api.WorkspaceUpdateMemberResponse} message WorkspaceUpdateMemberResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceUpdateMemberResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.status = options.enums === String ? "WUMR_SUCCESS" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.entities.WorkspaceUpdateMemberResult[message.status] === undefined ? message.status : $root.entities.WorkspaceUpdateMemberResult[message.status] : message.status;
            return object;
        };

        /**
         * Converts this WorkspaceUpdateMemberResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceUpdateMemberResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceUpdateMemberResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceUpdateMemberResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceUpdateMemberResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceUpdateMemberResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceUpdateMemberResponse";
        };

        return WorkspaceUpdateMemberResponse;
    })();

    api.WorkspaceCreateRequest = (function() {

        /**
         * Properties of a WorkspaceCreateRequest.
         * @memberof api
         * @interface IWorkspaceCreateRequest
         * @property {string|null} [name] WorkspaceCreateRequest name
         * @property {entities.IAttachmentSource|null} [avatar] WorkspaceCreateRequest avatar
         */

        /**
         * Constructs a new WorkspaceCreateRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceCreateRequest.
         * @implements IWorkspaceCreateRequest
         * @constructor
         * @param {api.IWorkspaceCreateRequest=} [properties] Properties to set
         */
        function WorkspaceCreateRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceCreateRequest name.
         * @member {string} name
         * @memberof api.WorkspaceCreateRequest
         * @instance
         */
        WorkspaceCreateRequest.prototype.name = "";

        /**
         * WorkspaceCreateRequest avatar.
         * @member {entities.IAttachmentSource|null|undefined} avatar
         * @memberof api.WorkspaceCreateRequest
         * @instance
         */
        WorkspaceCreateRequest.prototype.avatar = null;

        /**
         * Creates a new WorkspaceCreateRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceCreateRequest
         * @static
         * @param {api.IWorkspaceCreateRequest=} [properties] Properties to set
         * @returns {api.WorkspaceCreateRequest} WorkspaceCreateRequest instance
         */
        WorkspaceCreateRequest.create = function create(properties) {
            return new WorkspaceCreateRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceCreateRequest message. Does not implicitly {@link api.WorkspaceCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceCreateRequest
         * @static
         * @param {api.IWorkspaceCreateRequest} message WorkspaceCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                $root.entities.AttachmentSource.encode(message.avatar, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceCreateRequest message, length delimited. Does not implicitly {@link api.WorkspaceCreateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceCreateRequest
         * @static
         * @param {api.IWorkspaceCreateRequest} message WorkspaceCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceCreateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceCreateRequest} WorkspaceCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceCreateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.avatar = $root.entities.AttachmentSource.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceCreateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceCreateRequest} WorkspaceCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceCreateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceCreateRequest message.
         * @function verify
         * @memberof api.WorkspaceCreateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceCreateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.avatar != null && message.hasOwnProperty("avatar")) {
                let error = $root.entities.AttachmentSource.verify(message.avatar);
                if (error)
                    return "avatar." + error;
            }
            return null;
        };

        /**
         * Creates a WorkspaceCreateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceCreateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceCreateRequest} WorkspaceCreateRequest
         */
        WorkspaceCreateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceCreateRequest)
                return object;
            let message = new $root.api.WorkspaceCreateRequest();
            if (object.name != null)
                message.name = String(object.name);
            if (object.avatar != null) {
                if (typeof object.avatar !== "object")
                    throw TypeError(".api.WorkspaceCreateRequest.avatar: object expected");
                message.avatar = $root.entities.AttachmentSource.fromObject(object.avatar);
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceCreateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceCreateRequest
         * @static
         * @param {api.WorkspaceCreateRequest} message WorkspaceCreateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceCreateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.avatar = null;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.avatar != null && message.hasOwnProperty("avatar"))
                object.avatar = $root.entities.AttachmentSource.toObject(message.avatar, options);
            return object;
        };

        /**
         * Converts this WorkspaceCreateRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceCreateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceCreateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceCreateRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceCreateRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceCreateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceCreateRequest";
        };

        return WorkspaceCreateRequest;
    })();

    api.WorkspaceCreateResponse = (function() {

        /**
         * Properties of a WorkspaceCreateResponse.
         * @memberof api
         * @interface IWorkspaceCreateResponse
         * @property {Uint8Array|null} [workspaceId] WorkspaceCreateResponse workspaceId
         */

        /**
         * Constructs a new WorkspaceCreateResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceCreateResponse.
         * @implements IWorkspaceCreateResponse
         * @constructor
         * @param {api.IWorkspaceCreateResponse=} [properties] Properties to set
         */
        function WorkspaceCreateResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceCreateResponse workspaceId.
         * @member {Uint8Array} workspaceId
         * @memberof api.WorkspaceCreateResponse
         * @instance
         */
        WorkspaceCreateResponse.prototype.workspaceId = $util.newBuffer([]);

        /**
         * Creates a new WorkspaceCreateResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceCreateResponse
         * @static
         * @param {api.IWorkspaceCreateResponse=} [properties] Properties to set
         * @returns {api.WorkspaceCreateResponse} WorkspaceCreateResponse instance
         */
        WorkspaceCreateResponse.create = function create(properties) {
            return new WorkspaceCreateResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceCreateResponse message. Does not implicitly {@link api.WorkspaceCreateResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceCreateResponse
         * @static
         * @param {api.IWorkspaceCreateResponse} message WorkspaceCreateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceCreateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.workspaceId != null && Object.hasOwnProperty.call(message, "workspaceId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.workspaceId);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceCreateResponse message, length delimited. Does not implicitly {@link api.WorkspaceCreateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceCreateResponse
         * @static
         * @param {api.IWorkspaceCreateResponse} message WorkspaceCreateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceCreateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceCreateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceCreateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceCreateResponse} WorkspaceCreateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceCreateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceCreateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.workspaceId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceCreateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceCreateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceCreateResponse} WorkspaceCreateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceCreateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceCreateResponse message.
         * @function verify
         * @memberof api.WorkspaceCreateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceCreateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                if (!(message.workspaceId && typeof message.workspaceId.length === "number" || $util.isString(message.workspaceId)))
                    return "workspaceId: buffer expected";
            return null;
        };

        /**
         * Creates a WorkspaceCreateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceCreateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceCreateResponse} WorkspaceCreateResponse
         */
        WorkspaceCreateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceCreateResponse)
                return object;
            let message = new $root.api.WorkspaceCreateResponse();
            if (object.workspaceId != null)
                if (typeof object.workspaceId === "string")
                    $util.base64.decode(object.workspaceId, message.workspaceId = $util.newBuffer($util.base64.length(object.workspaceId)), 0);
                else if (object.workspaceId.length >= 0)
                    message.workspaceId = object.workspaceId;
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceCreateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceCreateResponse
         * @static
         * @param {api.WorkspaceCreateResponse} message WorkspaceCreateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceCreateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.workspaceId = "";
                else {
                    object.workspaceId = [];
                    if (options.bytes !== Array)
                        object.workspaceId = $util.newBuffer(object.workspaceId);
                }
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                object.workspaceId = options.bytes === String ? $util.base64.encode(message.workspaceId, 0, message.workspaceId.length) : options.bytes === Array ? Array.prototype.slice.call(message.workspaceId) : message.workspaceId;
            return object;
        };

        /**
         * Converts this WorkspaceCreateResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceCreateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceCreateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceCreateResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceCreateResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceCreateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceCreateResponse";
        };

        return WorkspaceCreateResponse;
    })();

    api.WorkspaceUpdateRequest = (function() {

        /**
         * Properties of a WorkspaceUpdateRequest.
         * @memberof api
         * @interface IWorkspaceUpdateRequest
         * @property {Uint8Array|null} [workspaceId] WorkspaceUpdateRequest workspaceId
         * @property {string|null} [name] WorkspaceUpdateRequest name
         * @property {entities.IAttachmentSource|null} [avatar] WorkspaceUpdateRequest avatar
         */

        /**
         * Constructs a new WorkspaceUpdateRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceUpdateRequest.
         * @implements IWorkspaceUpdateRequest
         * @constructor
         * @param {api.IWorkspaceUpdateRequest=} [properties] Properties to set
         */
        function WorkspaceUpdateRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceUpdateRequest workspaceId.
         * @member {Uint8Array} workspaceId
         * @memberof api.WorkspaceUpdateRequest
         * @instance
         */
        WorkspaceUpdateRequest.prototype.workspaceId = $util.newBuffer([]);

        /**
         * WorkspaceUpdateRequest name.
         * @member {string} name
         * @memberof api.WorkspaceUpdateRequest
         * @instance
         */
        WorkspaceUpdateRequest.prototype.name = "";

        /**
         * WorkspaceUpdateRequest avatar.
         * @member {entities.IAttachmentSource|null|undefined} avatar
         * @memberof api.WorkspaceUpdateRequest
         * @instance
         */
        WorkspaceUpdateRequest.prototype.avatar = null;

        /**
         * Creates a new WorkspaceUpdateRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceUpdateRequest
         * @static
         * @param {api.IWorkspaceUpdateRequest=} [properties] Properties to set
         * @returns {api.WorkspaceUpdateRequest} WorkspaceUpdateRequest instance
         */
        WorkspaceUpdateRequest.create = function create(properties) {
            return new WorkspaceUpdateRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceUpdateRequest message. Does not implicitly {@link api.WorkspaceUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceUpdateRequest
         * @static
         * @param {api.IWorkspaceUpdateRequest} message WorkspaceUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.workspaceId != null && Object.hasOwnProperty.call(message, "workspaceId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.workspaceId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                $root.entities.AttachmentSource.encode(message.avatar, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceUpdateRequest message, length delimited. Does not implicitly {@link api.WorkspaceUpdateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceUpdateRequest
         * @static
         * @param {api.IWorkspaceUpdateRequest} message WorkspaceUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceUpdateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceUpdateRequest} WorkspaceUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceUpdateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.workspaceId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.avatar = $root.entities.AttachmentSource.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceUpdateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceUpdateRequest} WorkspaceUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceUpdateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceUpdateRequest message.
         * @function verify
         * @memberof api.WorkspaceUpdateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceUpdateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                if (!(message.workspaceId && typeof message.workspaceId.length === "number" || $util.isString(message.workspaceId)))
                    return "workspaceId: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.avatar != null && message.hasOwnProperty("avatar")) {
                let error = $root.entities.AttachmentSource.verify(message.avatar);
                if (error)
                    return "avatar." + error;
            }
            return null;
        };

        /**
         * Creates a WorkspaceUpdateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceUpdateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceUpdateRequest} WorkspaceUpdateRequest
         */
        WorkspaceUpdateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceUpdateRequest)
                return object;
            let message = new $root.api.WorkspaceUpdateRequest();
            if (object.workspaceId != null)
                if (typeof object.workspaceId === "string")
                    $util.base64.decode(object.workspaceId, message.workspaceId = $util.newBuffer($util.base64.length(object.workspaceId)), 0);
                else if (object.workspaceId.length >= 0)
                    message.workspaceId = object.workspaceId;
            if (object.name != null)
                message.name = String(object.name);
            if (object.avatar != null) {
                if (typeof object.avatar !== "object")
                    throw TypeError(".api.WorkspaceUpdateRequest.avatar: object expected");
                message.avatar = $root.entities.AttachmentSource.fromObject(object.avatar);
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceUpdateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceUpdateRequest
         * @static
         * @param {api.WorkspaceUpdateRequest} message WorkspaceUpdateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceUpdateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.workspaceId = "";
                else {
                    object.workspaceId = [];
                    if (options.bytes !== Array)
                        object.workspaceId = $util.newBuffer(object.workspaceId);
                }
                object.name = "";
                object.avatar = null;
            }
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                object.workspaceId = options.bytes === String ? $util.base64.encode(message.workspaceId, 0, message.workspaceId.length) : options.bytes === Array ? Array.prototype.slice.call(message.workspaceId) : message.workspaceId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.avatar != null && message.hasOwnProperty("avatar"))
                object.avatar = $root.entities.AttachmentSource.toObject(message.avatar, options);
            return object;
        };

        /**
         * Converts this WorkspaceUpdateRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceUpdateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceUpdateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceUpdateRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceUpdateRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceUpdateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceUpdateRequest";
        };

        return WorkspaceUpdateRequest;
    })();

    api.WorkspaceUpdateResponse = (function() {

        /**
         * Properties of a WorkspaceUpdateResponse.
         * @memberof api
         * @interface IWorkspaceUpdateResponse
         * @property {entities.IWorkspace|null} [workspace] WorkspaceUpdateResponse workspace
         */

        /**
         * Constructs a new WorkspaceUpdateResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceUpdateResponse.
         * @implements IWorkspaceUpdateResponse
         * @constructor
         * @param {api.IWorkspaceUpdateResponse=} [properties] Properties to set
         */
        function WorkspaceUpdateResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceUpdateResponse workspace.
         * @member {entities.IWorkspace|null|undefined} workspace
         * @memberof api.WorkspaceUpdateResponse
         * @instance
         */
        WorkspaceUpdateResponse.prototype.workspace = null;

        /**
         * Creates a new WorkspaceUpdateResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceUpdateResponse
         * @static
         * @param {api.IWorkspaceUpdateResponse=} [properties] Properties to set
         * @returns {api.WorkspaceUpdateResponse} WorkspaceUpdateResponse instance
         */
        WorkspaceUpdateResponse.create = function create(properties) {
            return new WorkspaceUpdateResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceUpdateResponse message. Does not implicitly {@link api.WorkspaceUpdateResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceUpdateResponse
         * @static
         * @param {api.IWorkspaceUpdateResponse} message WorkspaceUpdateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceUpdateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.workspace != null && Object.hasOwnProperty.call(message, "workspace"))
                $root.entities.Workspace.encode(message.workspace, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceUpdateResponse message, length delimited. Does not implicitly {@link api.WorkspaceUpdateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceUpdateResponse
         * @static
         * @param {api.IWorkspaceUpdateResponse} message WorkspaceUpdateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceUpdateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceUpdateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceUpdateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceUpdateResponse} WorkspaceUpdateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceUpdateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceUpdateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.workspace = $root.entities.Workspace.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceUpdateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceUpdateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceUpdateResponse} WorkspaceUpdateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceUpdateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceUpdateResponse message.
         * @function verify
         * @memberof api.WorkspaceUpdateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceUpdateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.workspace != null && message.hasOwnProperty("workspace")) {
                let error = $root.entities.Workspace.verify(message.workspace);
                if (error)
                    return "workspace." + error;
            }
            return null;
        };

        /**
         * Creates a WorkspaceUpdateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceUpdateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceUpdateResponse} WorkspaceUpdateResponse
         */
        WorkspaceUpdateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceUpdateResponse)
                return object;
            let message = new $root.api.WorkspaceUpdateResponse();
            if (object.workspace != null) {
                if (typeof object.workspace !== "object")
                    throw TypeError(".api.WorkspaceUpdateResponse.workspace: object expected");
                message.workspace = $root.entities.Workspace.fromObject(object.workspace);
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceUpdateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceUpdateResponse
         * @static
         * @param {api.WorkspaceUpdateResponse} message WorkspaceUpdateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceUpdateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.workspace = null;
            if (message.workspace != null && message.hasOwnProperty("workspace"))
                object.workspace = $root.entities.Workspace.toObject(message.workspace, options);
            return object;
        };

        /**
         * Converts this WorkspaceUpdateResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceUpdateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceUpdateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceUpdateResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceUpdateResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceUpdateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceUpdateResponse";
        };

        return WorkspaceUpdateResponse;
    })();

    api.WorkspaceSnippetsRequest = (function() {

        /**
         * Properties of a WorkspaceSnippetsRequest.
         * @memberof api
         * @interface IWorkspaceSnippetsRequest
         * @property {Uint8Array|null} [workspaceId] WorkspaceSnippetsRequest workspaceId
         * @property {boolean|null} [personal] WorkspaceSnippetsRequest personal
         */

        /**
         * Constructs a new WorkspaceSnippetsRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceSnippetsRequest.
         * @implements IWorkspaceSnippetsRequest
         * @constructor
         * @param {api.IWorkspaceSnippetsRequest=} [properties] Properties to set
         */
        function WorkspaceSnippetsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceSnippetsRequest workspaceId.
         * @member {Uint8Array} workspaceId
         * @memberof api.WorkspaceSnippetsRequest
         * @instance
         */
        WorkspaceSnippetsRequest.prototype.workspaceId = $util.newBuffer([]);

        /**
         * WorkspaceSnippetsRequest personal.
         * @member {boolean} personal
         * @memberof api.WorkspaceSnippetsRequest
         * @instance
         */
        WorkspaceSnippetsRequest.prototype.personal = false;

        /**
         * Creates a new WorkspaceSnippetsRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceSnippetsRequest
         * @static
         * @param {api.IWorkspaceSnippetsRequest=} [properties] Properties to set
         * @returns {api.WorkspaceSnippetsRequest} WorkspaceSnippetsRequest instance
         */
        WorkspaceSnippetsRequest.create = function create(properties) {
            return new WorkspaceSnippetsRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceSnippetsRequest message. Does not implicitly {@link api.WorkspaceSnippetsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceSnippetsRequest
         * @static
         * @param {api.IWorkspaceSnippetsRequest} message WorkspaceSnippetsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceSnippetsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.workspaceId != null && Object.hasOwnProperty.call(message, "workspaceId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.workspaceId);
            if (message.personal != null && Object.hasOwnProperty.call(message, "personal"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.personal);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceSnippetsRequest message, length delimited. Does not implicitly {@link api.WorkspaceSnippetsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceSnippetsRequest
         * @static
         * @param {api.IWorkspaceSnippetsRequest} message WorkspaceSnippetsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceSnippetsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceSnippetsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceSnippetsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceSnippetsRequest} WorkspaceSnippetsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceSnippetsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceSnippetsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.workspaceId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.personal = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceSnippetsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceSnippetsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceSnippetsRequest} WorkspaceSnippetsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceSnippetsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceSnippetsRequest message.
         * @function verify
         * @memberof api.WorkspaceSnippetsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceSnippetsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                if (!(message.workspaceId && typeof message.workspaceId.length === "number" || $util.isString(message.workspaceId)))
                    return "workspaceId: buffer expected";
            if (message.personal != null && message.hasOwnProperty("personal"))
                if (typeof message.personal !== "boolean")
                    return "personal: boolean expected";
            return null;
        };

        /**
         * Creates a WorkspaceSnippetsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceSnippetsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceSnippetsRequest} WorkspaceSnippetsRequest
         */
        WorkspaceSnippetsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceSnippetsRequest)
                return object;
            let message = new $root.api.WorkspaceSnippetsRequest();
            if (object.workspaceId != null)
                if (typeof object.workspaceId === "string")
                    $util.base64.decode(object.workspaceId, message.workspaceId = $util.newBuffer($util.base64.length(object.workspaceId)), 0);
                else if (object.workspaceId.length >= 0)
                    message.workspaceId = object.workspaceId;
            if (object.personal != null)
                message.personal = Boolean(object.personal);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceSnippetsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceSnippetsRequest
         * @static
         * @param {api.WorkspaceSnippetsRequest} message WorkspaceSnippetsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceSnippetsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.workspaceId = "";
                else {
                    object.workspaceId = [];
                    if (options.bytes !== Array)
                        object.workspaceId = $util.newBuffer(object.workspaceId);
                }
                object.personal = false;
            }
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                object.workspaceId = options.bytes === String ? $util.base64.encode(message.workspaceId, 0, message.workspaceId.length) : options.bytes === Array ? Array.prototype.slice.call(message.workspaceId) : message.workspaceId;
            if (message.personal != null && message.hasOwnProperty("personal"))
                object.personal = message.personal;
            return object;
        };

        /**
         * Converts this WorkspaceSnippetsRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceSnippetsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceSnippetsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceSnippetsRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceSnippetsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceSnippetsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceSnippetsRequest";
        };

        return WorkspaceSnippetsRequest;
    })();

    api.WorkspaceSnippetsResponse = (function() {

        /**
         * Properties of a WorkspaceSnippetsResponse.
         * @memberof api
         * @interface IWorkspaceSnippetsResponse
         * @property {Array.<entities.IWorkspaceSnippet>|null} [snippets] WorkspaceSnippetsResponse snippets
         */

        /**
         * Constructs a new WorkspaceSnippetsResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceSnippetsResponse.
         * @implements IWorkspaceSnippetsResponse
         * @constructor
         * @param {api.IWorkspaceSnippetsResponse=} [properties] Properties to set
         */
        function WorkspaceSnippetsResponse(properties) {
            this.snippets = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceSnippetsResponse snippets.
         * @member {Array.<entities.IWorkspaceSnippet>} snippets
         * @memberof api.WorkspaceSnippetsResponse
         * @instance
         */
        WorkspaceSnippetsResponse.prototype.snippets = $util.emptyArray;

        /**
         * Creates a new WorkspaceSnippetsResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceSnippetsResponse
         * @static
         * @param {api.IWorkspaceSnippetsResponse=} [properties] Properties to set
         * @returns {api.WorkspaceSnippetsResponse} WorkspaceSnippetsResponse instance
         */
        WorkspaceSnippetsResponse.create = function create(properties) {
            return new WorkspaceSnippetsResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceSnippetsResponse message. Does not implicitly {@link api.WorkspaceSnippetsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceSnippetsResponse
         * @static
         * @param {api.IWorkspaceSnippetsResponse} message WorkspaceSnippetsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceSnippetsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.snippets != null && message.snippets.length)
                for (let i = 0; i < message.snippets.length; ++i)
                    $root.entities.WorkspaceSnippet.encode(message.snippets[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceSnippetsResponse message, length delimited. Does not implicitly {@link api.WorkspaceSnippetsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceSnippetsResponse
         * @static
         * @param {api.IWorkspaceSnippetsResponse} message WorkspaceSnippetsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceSnippetsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceSnippetsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceSnippetsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceSnippetsResponse} WorkspaceSnippetsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceSnippetsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceSnippetsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.snippets && message.snippets.length))
                            message.snippets = [];
                        message.snippets.push($root.entities.WorkspaceSnippet.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceSnippetsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceSnippetsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceSnippetsResponse} WorkspaceSnippetsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceSnippetsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceSnippetsResponse message.
         * @function verify
         * @memberof api.WorkspaceSnippetsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceSnippetsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.snippets != null && message.hasOwnProperty("snippets")) {
                if (!Array.isArray(message.snippets))
                    return "snippets: array expected";
                for (let i = 0; i < message.snippets.length; ++i) {
                    let error = $root.entities.WorkspaceSnippet.verify(message.snippets[i]);
                    if (error)
                        return "snippets." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WorkspaceSnippetsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceSnippetsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceSnippetsResponse} WorkspaceSnippetsResponse
         */
        WorkspaceSnippetsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceSnippetsResponse)
                return object;
            let message = new $root.api.WorkspaceSnippetsResponse();
            if (object.snippets) {
                if (!Array.isArray(object.snippets))
                    throw TypeError(".api.WorkspaceSnippetsResponse.snippets: array expected");
                message.snippets = [];
                for (let i = 0; i < object.snippets.length; ++i) {
                    if (typeof object.snippets[i] !== "object")
                        throw TypeError(".api.WorkspaceSnippetsResponse.snippets: object expected");
                    message.snippets[i] = $root.entities.WorkspaceSnippet.fromObject(object.snippets[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceSnippetsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceSnippetsResponse
         * @static
         * @param {api.WorkspaceSnippetsResponse} message WorkspaceSnippetsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceSnippetsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.snippets = [];
            if (message.snippets && message.snippets.length) {
                object.snippets = [];
                for (let j = 0; j < message.snippets.length; ++j)
                    object.snippets[j] = $root.entities.WorkspaceSnippet.toObject(message.snippets[j], options);
            }
            return object;
        };

        /**
         * Converts this WorkspaceSnippetsResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceSnippetsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceSnippetsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceSnippetsResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceSnippetsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceSnippetsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceSnippetsResponse";
        };

        return WorkspaceSnippetsResponse;
    })();

    api.WorkspaceSnippetsSetRequest = (function() {

        /**
         * Properties of a WorkspaceSnippetsSetRequest.
         * @memberof api
         * @interface IWorkspaceSnippetsSetRequest
         * @property {Uint8Array|null} [workspaceId] WorkspaceSnippetsSetRequest workspaceId
         * @property {boolean|null} [personal] WorkspaceSnippetsSetRequest personal
         * @property {Array.<entities.IWorkspaceSnippet>|null} [snippets] WorkspaceSnippetsSetRequest snippets
         */

        /**
         * Constructs a new WorkspaceSnippetsSetRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceSnippetsSetRequest.
         * @implements IWorkspaceSnippetsSetRequest
         * @constructor
         * @param {api.IWorkspaceSnippetsSetRequest=} [properties] Properties to set
         */
        function WorkspaceSnippetsSetRequest(properties) {
            this.snippets = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceSnippetsSetRequest workspaceId.
         * @member {Uint8Array} workspaceId
         * @memberof api.WorkspaceSnippetsSetRequest
         * @instance
         */
        WorkspaceSnippetsSetRequest.prototype.workspaceId = $util.newBuffer([]);

        /**
         * WorkspaceSnippetsSetRequest personal.
         * @member {boolean} personal
         * @memberof api.WorkspaceSnippetsSetRequest
         * @instance
         */
        WorkspaceSnippetsSetRequest.prototype.personal = false;

        /**
         * WorkspaceSnippetsSetRequest snippets.
         * @member {Array.<entities.IWorkspaceSnippet>} snippets
         * @memberof api.WorkspaceSnippetsSetRequest
         * @instance
         */
        WorkspaceSnippetsSetRequest.prototype.snippets = $util.emptyArray;

        /**
         * Creates a new WorkspaceSnippetsSetRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceSnippetsSetRequest
         * @static
         * @param {api.IWorkspaceSnippetsSetRequest=} [properties] Properties to set
         * @returns {api.WorkspaceSnippetsSetRequest} WorkspaceSnippetsSetRequest instance
         */
        WorkspaceSnippetsSetRequest.create = function create(properties) {
            return new WorkspaceSnippetsSetRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceSnippetsSetRequest message. Does not implicitly {@link api.WorkspaceSnippetsSetRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceSnippetsSetRequest
         * @static
         * @param {api.IWorkspaceSnippetsSetRequest} message WorkspaceSnippetsSetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceSnippetsSetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.workspaceId != null && Object.hasOwnProperty.call(message, "workspaceId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.workspaceId);
            if (message.personal != null && Object.hasOwnProperty.call(message, "personal"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.personal);
            if (message.snippets != null && message.snippets.length)
                for (let i = 0; i < message.snippets.length; ++i)
                    $root.entities.WorkspaceSnippet.encode(message.snippets[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceSnippetsSetRequest message, length delimited. Does not implicitly {@link api.WorkspaceSnippetsSetRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceSnippetsSetRequest
         * @static
         * @param {api.IWorkspaceSnippetsSetRequest} message WorkspaceSnippetsSetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceSnippetsSetRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceSnippetsSetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceSnippetsSetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceSnippetsSetRequest} WorkspaceSnippetsSetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceSnippetsSetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceSnippetsSetRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.workspaceId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.personal = reader.bool();
                        break;
                    }
                case 3: {
                        if (!(message.snippets && message.snippets.length))
                            message.snippets = [];
                        message.snippets.push($root.entities.WorkspaceSnippet.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceSnippetsSetRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceSnippetsSetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceSnippetsSetRequest} WorkspaceSnippetsSetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceSnippetsSetRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceSnippetsSetRequest message.
         * @function verify
         * @memberof api.WorkspaceSnippetsSetRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceSnippetsSetRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                if (!(message.workspaceId && typeof message.workspaceId.length === "number" || $util.isString(message.workspaceId)))
                    return "workspaceId: buffer expected";
            if (message.personal != null && message.hasOwnProperty("personal"))
                if (typeof message.personal !== "boolean")
                    return "personal: boolean expected";
            if (message.snippets != null && message.hasOwnProperty("snippets")) {
                if (!Array.isArray(message.snippets))
                    return "snippets: array expected";
                for (let i = 0; i < message.snippets.length; ++i) {
                    let error = $root.entities.WorkspaceSnippet.verify(message.snippets[i]);
                    if (error)
                        return "snippets." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WorkspaceSnippetsSetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceSnippetsSetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceSnippetsSetRequest} WorkspaceSnippetsSetRequest
         */
        WorkspaceSnippetsSetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceSnippetsSetRequest)
                return object;
            let message = new $root.api.WorkspaceSnippetsSetRequest();
            if (object.workspaceId != null)
                if (typeof object.workspaceId === "string")
                    $util.base64.decode(object.workspaceId, message.workspaceId = $util.newBuffer($util.base64.length(object.workspaceId)), 0);
                else if (object.workspaceId.length >= 0)
                    message.workspaceId = object.workspaceId;
            if (object.personal != null)
                message.personal = Boolean(object.personal);
            if (object.snippets) {
                if (!Array.isArray(object.snippets))
                    throw TypeError(".api.WorkspaceSnippetsSetRequest.snippets: array expected");
                message.snippets = [];
                for (let i = 0; i < object.snippets.length; ++i) {
                    if (typeof object.snippets[i] !== "object")
                        throw TypeError(".api.WorkspaceSnippetsSetRequest.snippets: object expected");
                    message.snippets[i] = $root.entities.WorkspaceSnippet.fromObject(object.snippets[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceSnippetsSetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceSnippetsSetRequest
         * @static
         * @param {api.WorkspaceSnippetsSetRequest} message WorkspaceSnippetsSetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceSnippetsSetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.snippets = [];
            if (options.defaults) {
                if (options.bytes === String)
                    object.workspaceId = "";
                else {
                    object.workspaceId = [];
                    if (options.bytes !== Array)
                        object.workspaceId = $util.newBuffer(object.workspaceId);
                }
                object.personal = false;
            }
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                object.workspaceId = options.bytes === String ? $util.base64.encode(message.workspaceId, 0, message.workspaceId.length) : options.bytes === Array ? Array.prototype.slice.call(message.workspaceId) : message.workspaceId;
            if (message.personal != null && message.hasOwnProperty("personal"))
                object.personal = message.personal;
            if (message.snippets && message.snippets.length) {
                object.snippets = [];
                for (let j = 0; j < message.snippets.length; ++j)
                    object.snippets[j] = $root.entities.WorkspaceSnippet.toObject(message.snippets[j], options);
            }
            return object;
        };

        /**
         * Converts this WorkspaceSnippetsSetRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceSnippetsSetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceSnippetsSetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceSnippetsSetRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceSnippetsSetRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceSnippetsSetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceSnippetsSetRequest";
        };

        return WorkspaceSnippetsSetRequest;
    })();

    api.WorkspaceSnippetsSetResponse = (function() {

        /**
         * Properties of a WorkspaceSnippetsSetResponse.
         * @memberof api
         * @interface IWorkspaceSnippetsSetResponse
         * @property {Array.<entities.IWorkspaceSnippet>|null} [snippets] WorkspaceSnippetsSetResponse snippets
         */

        /**
         * Constructs a new WorkspaceSnippetsSetResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceSnippetsSetResponse.
         * @implements IWorkspaceSnippetsSetResponse
         * @constructor
         * @param {api.IWorkspaceSnippetsSetResponse=} [properties] Properties to set
         */
        function WorkspaceSnippetsSetResponse(properties) {
            this.snippets = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceSnippetsSetResponse snippets.
         * @member {Array.<entities.IWorkspaceSnippet>} snippets
         * @memberof api.WorkspaceSnippetsSetResponse
         * @instance
         */
        WorkspaceSnippetsSetResponse.prototype.snippets = $util.emptyArray;

        /**
         * Creates a new WorkspaceSnippetsSetResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceSnippetsSetResponse
         * @static
         * @param {api.IWorkspaceSnippetsSetResponse=} [properties] Properties to set
         * @returns {api.WorkspaceSnippetsSetResponse} WorkspaceSnippetsSetResponse instance
         */
        WorkspaceSnippetsSetResponse.create = function create(properties) {
            return new WorkspaceSnippetsSetResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceSnippetsSetResponse message. Does not implicitly {@link api.WorkspaceSnippetsSetResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceSnippetsSetResponse
         * @static
         * @param {api.IWorkspaceSnippetsSetResponse} message WorkspaceSnippetsSetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceSnippetsSetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.snippets != null && message.snippets.length)
                for (let i = 0; i < message.snippets.length; ++i)
                    $root.entities.WorkspaceSnippet.encode(message.snippets[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceSnippetsSetResponse message, length delimited. Does not implicitly {@link api.WorkspaceSnippetsSetResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceSnippetsSetResponse
         * @static
         * @param {api.IWorkspaceSnippetsSetResponse} message WorkspaceSnippetsSetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceSnippetsSetResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceSnippetsSetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceSnippetsSetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceSnippetsSetResponse} WorkspaceSnippetsSetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceSnippetsSetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceSnippetsSetResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.snippets && message.snippets.length))
                            message.snippets = [];
                        message.snippets.push($root.entities.WorkspaceSnippet.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceSnippetsSetResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceSnippetsSetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceSnippetsSetResponse} WorkspaceSnippetsSetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceSnippetsSetResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceSnippetsSetResponse message.
         * @function verify
         * @memberof api.WorkspaceSnippetsSetResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceSnippetsSetResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.snippets != null && message.hasOwnProperty("snippets")) {
                if (!Array.isArray(message.snippets))
                    return "snippets: array expected";
                for (let i = 0; i < message.snippets.length; ++i) {
                    let error = $root.entities.WorkspaceSnippet.verify(message.snippets[i]);
                    if (error)
                        return "snippets." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WorkspaceSnippetsSetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceSnippetsSetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceSnippetsSetResponse} WorkspaceSnippetsSetResponse
         */
        WorkspaceSnippetsSetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceSnippetsSetResponse)
                return object;
            let message = new $root.api.WorkspaceSnippetsSetResponse();
            if (object.snippets) {
                if (!Array.isArray(object.snippets))
                    throw TypeError(".api.WorkspaceSnippetsSetResponse.snippets: array expected");
                message.snippets = [];
                for (let i = 0; i < object.snippets.length; ++i) {
                    if (typeof object.snippets[i] !== "object")
                        throw TypeError(".api.WorkspaceSnippetsSetResponse.snippets: object expected");
                    message.snippets[i] = $root.entities.WorkspaceSnippet.fromObject(object.snippets[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceSnippetsSetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceSnippetsSetResponse
         * @static
         * @param {api.WorkspaceSnippetsSetResponse} message WorkspaceSnippetsSetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceSnippetsSetResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.snippets = [];
            if (message.snippets && message.snippets.length) {
                object.snippets = [];
                for (let j = 0; j < message.snippets.length; ++j)
                    object.snippets[j] = $root.entities.WorkspaceSnippet.toObject(message.snippets[j], options);
            }
            return object;
        };

        /**
         * Converts this WorkspaceSnippetsSetResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceSnippetsSetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceSnippetsSetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceSnippetsSetResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceSnippetsSetResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceSnippetsSetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceSnippetsSetResponse";
        };

        return WorkspaceSnippetsSetResponse;
    })();

    api.WorkspaceCreateInviteRequest = (function() {

        /**
         * Properties of a WorkspaceCreateInviteRequest.
         * @memberof api
         * @interface IWorkspaceCreateInviteRequest
         * @property {Uint8Array|null} [workspaceId] WorkspaceCreateInviteRequest workspaceId
         * @property {string|null} [email] WorkspaceCreateInviteRequest email
         * @property {entities.WorkspaceRole|null} [role] WorkspaceCreateInviteRequest role
         * @property {entities.IWorkspaceMemberAccess|null} [access] WorkspaceCreateInviteRequest access
         */

        /**
         * Constructs a new WorkspaceCreateInviteRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceCreateInviteRequest.
         * @implements IWorkspaceCreateInviteRequest
         * @constructor
         * @param {api.IWorkspaceCreateInviteRequest=} [properties] Properties to set
         */
        function WorkspaceCreateInviteRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceCreateInviteRequest workspaceId.
         * @member {Uint8Array} workspaceId
         * @memberof api.WorkspaceCreateInviteRequest
         * @instance
         */
        WorkspaceCreateInviteRequest.prototype.workspaceId = $util.newBuffer([]);

        /**
         * WorkspaceCreateInviteRequest email.
         * @member {string} email
         * @memberof api.WorkspaceCreateInviteRequest
         * @instance
         */
        WorkspaceCreateInviteRequest.prototype.email = "";

        /**
         * WorkspaceCreateInviteRequest role.
         * @member {entities.WorkspaceRole} role
         * @memberof api.WorkspaceCreateInviteRequest
         * @instance
         */
        WorkspaceCreateInviteRequest.prototype.role = 0;

        /**
         * WorkspaceCreateInviteRequest access.
         * @member {entities.IWorkspaceMemberAccess|null|undefined} access
         * @memberof api.WorkspaceCreateInviteRequest
         * @instance
         */
        WorkspaceCreateInviteRequest.prototype.access = null;

        /**
         * Creates a new WorkspaceCreateInviteRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceCreateInviteRequest
         * @static
         * @param {api.IWorkspaceCreateInviteRequest=} [properties] Properties to set
         * @returns {api.WorkspaceCreateInviteRequest} WorkspaceCreateInviteRequest instance
         */
        WorkspaceCreateInviteRequest.create = function create(properties) {
            return new WorkspaceCreateInviteRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceCreateInviteRequest message. Does not implicitly {@link api.WorkspaceCreateInviteRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceCreateInviteRequest
         * @static
         * @param {api.IWorkspaceCreateInviteRequest} message WorkspaceCreateInviteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceCreateInviteRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.workspaceId != null && Object.hasOwnProperty.call(message, "workspaceId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.workspaceId);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.role);
            if (message.access != null && Object.hasOwnProperty.call(message, "access"))
                $root.entities.WorkspaceMemberAccess.encode(message.access, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceCreateInviteRequest message, length delimited. Does not implicitly {@link api.WorkspaceCreateInviteRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceCreateInviteRequest
         * @static
         * @param {api.IWorkspaceCreateInviteRequest} message WorkspaceCreateInviteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceCreateInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceCreateInviteRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceCreateInviteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceCreateInviteRequest} WorkspaceCreateInviteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceCreateInviteRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceCreateInviteRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.workspaceId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.email = reader.string();
                        break;
                    }
                case 3: {
                        message.role = reader.int32();
                        break;
                    }
                case 4: {
                        message.access = $root.entities.WorkspaceMemberAccess.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceCreateInviteRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceCreateInviteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceCreateInviteRequest} WorkspaceCreateInviteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceCreateInviteRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceCreateInviteRequest message.
         * @function verify
         * @memberof api.WorkspaceCreateInviteRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceCreateInviteRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                if (!(message.workspaceId && typeof message.workspaceId.length === "number" || $util.isString(message.workspaceId)))
                    return "workspaceId: buffer expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.role != null && message.hasOwnProperty("role"))
                switch (message.role) {
                default:
                    return "role: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.access != null && message.hasOwnProperty("access")) {
                let error = $root.entities.WorkspaceMemberAccess.verify(message.access);
                if (error)
                    return "access." + error;
            }
            return null;
        };

        /**
         * Creates a WorkspaceCreateInviteRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceCreateInviteRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceCreateInviteRequest} WorkspaceCreateInviteRequest
         */
        WorkspaceCreateInviteRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceCreateInviteRequest)
                return object;
            let message = new $root.api.WorkspaceCreateInviteRequest();
            if (object.workspaceId != null)
                if (typeof object.workspaceId === "string")
                    $util.base64.decode(object.workspaceId, message.workspaceId = $util.newBuffer($util.base64.length(object.workspaceId)), 0);
                else if (object.workspaceId.length >= 0)
                    message.workspaceId = object.workspaceId;
            if (object.email != null)
                message.email = String(object.email);
            switch (object.role) {
            default:
                if (typeof object.role === "number") {
                    message.role = object.role;
                    break;
                }
                break;
            case "WR_NONE":
            case 0:
                message.role = 0;
                break;
            case "WR_OWNER":
            case 1:
                message.role = 1;
                break;
            case "WR_ADMIN":
            case 2:
                message.role = 2;
                break;
            case "WR_OPERATOR":
            case 3:
                message.role = 3;
                break;
            }
            if (object.access != null) {
                if (typeof object.access !== "object")
                    throw TypeError(".api.WorkspaceCreateInviteRequest.access: object expected");
                message.access = $root.entities.WorkspaceMemberAccess.fromObject(object.access);
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceCreateInviteRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceCreateInviteRequest
         * @static
         * @param {api.WorkspaceCreateInviteRequest} message WorkspaceCreateInviteRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceCreateInviteRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.workspaceId = "";
                else {
                    object.workspaceId = [];
                    if (options.bytes !== Array)
                        object.workspaceId = $util.newBuffer(object.workspaceId);
                }
                object.email = "";
                object.role = options.enums === String ? "WR_NONE" : 0;
                object.access = null;
            }
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                object.workspaceId = options.bytes === String ? $util.base64.encode(message.workspaceId, 0, message.workspaceId.length) : options.bytes === Array ? Array.prototype.slice.call(message.workspaceId) : message.workspaceId;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = options.enums === String ? $root.entities.WorkspaceRole[message.role] === undefined ? message.role : $root.entities.WorkspaceRole[message.role] : message.role;
            if (message.access != null && message.hasOwnProperty("access"))
                object.access = $root.entities.WorkspaceMemberAccess.toObject(message.access, options);
            return object;
        };

        /**
         * Converts this WorkspaceCreateInviteRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceCreateInviteRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceCreateInviteRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceCreateInviteRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceCreateInviteRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceCreateInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceCreateInviteRequest";
        };

        return WorkspaceCreateInviteRequest;
    })();

    api.WorkspaceCreateInviteResponse = (function() {

        /**
         * Properties of a WorkspaceCreateInviteResponse.
         * @memberof api
         * @interface IWorkspaceCreateInviteResponse
         * @property {api.WorkspaceCreateInviteResponse.Status|null} [status] WorkspaceCreateInviteResponse status
         * @property {entities.IInvite|null} [invite] WorkspaceCreateInviteResponse invite
         */

        /**
         * Constructs a new WorkspaceCreateInviteResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceCreateInviteResponse.
         * @implements IWorkspaceCreateInviteResponse
         * @constructor
         * @param {api.IWorkspaceCreateInviteResponse=} [properties] Properties to set
         */
        function WorkspaceCreateInviteResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceCreateInviteResponse status.
         * @member {api.WorkspaceCreateInviteResponse.Status} status
         * @memberof api.WorkspaceCreateInviteResponse
         * @instance
         */
        WorkspaceCreateInviteResponse.prototype.status = 0;

        /**
         * WorkspaceCreateInviteResponse invite.
         * @member {entities.IInvite|null|undefined} invite
         * @memberof api.WorkspaceCreateInviteResponse
         * @instance
         */
        WorkspaceCreateInviteResponse.prototype.invite = null;

        /**
         * Creates a new WorkspaceCreateInviteResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceCreateInviteResponse
         * @static
         * @param {api.IWorkspaceCreateInviteResponse=} [properties] Properties to set
         * @returns {api.WorkspaceCreateInviteResponse} WorkspaceCreateInviteResponse instance
         */
        WorkspaceCreateInviteResponse.create = function create(properties) {
            return new WorkspaceCreateInviteResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceCreateInviteResponse message. Does not implicitly {@link api.WorkspaceCreateInviteResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceCreateInviteResponse
         * @static
         * @param {api.IWorkspaceCreateInviteResponse} message WorkspaceCreateInviteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceCreateInviteResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.invite != null && Object.hasOwnProperty.call(message, "invite"))
                $root.entities.Invite.encode(message.invite, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceCreateInviteResponse message, length delimited. Does not implicitly {@link api.WorkspaceCreateInviteResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceCreateInviteResponse
         * @static
         * @param {api.IWorkspaceCreateInviteResponse} message WorkspaceCreateInviteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceCreateInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceCreateInviteResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceCreateInviteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceCreateInviteResponse} WorkspaceCreateInviteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceCreateInviteResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceCreateInviteResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.status = reader.int32();
                        break;
                    }
                case 1: {
                        message.invite = $root.entities.Invite.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceCreateInviteResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceCreateInviteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceCreateInviteResponse} WorkspaceCreateInviteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceCreateInviteResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceCreateInviteResponse message.
         * @function verify
         * @memberof api.WorkspaceCreateInviteResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceCreateInviteResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 10:
                    break;
                }
            if (message.invite != null && message.hasOwnProperty("invite")) {
                let error = $root.entities.Invite.verify(message.invite);
                if (error)
                    return "invite." + error;
            }
            return null;
        };

        /**
         * Creates a WorkspaceCreateInviteResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceCreateInviteResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceCreateInviteResponse} WorkspaceCreateInviteResponse
         */
        WorkspaceCreateInviteResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceCreateInviteResponse)
                return object;
            let message = new $root.api.WorkspaceCreateInviteResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "CINV_OK":
            case 0:
                message.status = 0;
                break;
            case "CINV_INVALID_WORKSPACE":
            case 1:
                message.status = 1;
                break;
            case "CINV_INVALID_EMAIL":
            case 2:
                message.status = 2;
                break;
            case "CINV_INVALID_ROLE":
            case 3:
                message.status = 3;
                break;
            case "CINV_INVALID_ACCESS":
            case 4:
                message.status = 4;
                break;
            case "CINV_ALREADY_INVITED":
            case 5:
                message.status = 5;
                break;
            case "CINV_ALREADY_MEMBER":
            case 6:
                message.status = 6;
                break;
            case "CINV_UPGRADE_REQUIRED":
            case 7:
                message.status = 7;
                break;
            case "CINV_INTERNAL_ERROR":
            case 10:
                message.status = 10;
                break;
            }
            if (object.invite != null) {
                if (typeof object.invite !== "object")
                    throw TypeError(".api.WorkspaceCreateInviteResponse.invite: object expected");
                message.invite = $root.entities.Invite.fromObject(object.invite);
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceCreateInviteResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceCreateInviteResponse
         * @static
         * @param {api.WorkspaceCreateInviteResponse} message WorkspaceCreateInviteResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceCreateInviteResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.invite = null;
                object.status = options.enums === String ? "CINV_OK" : 0;
            }
            if (message.invite != null && message.hasOwnProperty("invite"))
                object.invite = $root.entities.Invite.toObject(message.invite, options);
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.api.WorkspaceCreateInviteResponse.Status[message.status] === undefined ? message.status : $root.api.WorkspaceCreateInviteResponse.Status[message.status] : message.status;
            return object;
        };

        /**
         * Converts this WorkspaceCreateInviteResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceCreateInviteResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceCreateInviteResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceCreateInviteResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceCreateInviteResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceCreateInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceCreateInviteResponse";
        };

        /**
         * Status enum.
         * @name api.WorkspaceCreateInviteResponse.Status
         * @enum {number}
         * @property {number} CINV_OK=0 CINV_OK value
         * @property {number} CINV_INVALID_WORKSPACE=1 CINV_INVALID_WORKSPACE value
         * @property {number} CINV_INVALID_EMAIL=2 CINV_INVALID_EMAIL value
         * @property {number} CINV_INVALID_ROLE=3 CINV_INVALID_ROLE value
         * @property {number} CINV_INVALID_ACCESS=4 CINV_INVALID_ACCESS value
         * @property {number} CINV_ALREADY_INVITED=5 CINV_ALREADY_INVITED value
         * @property {number} CINV_ALREADY_MEMBER=6 CINV_ALREADY_MEMBER value
         * @property {number} CINV_UPGRADE_REQUIRED=7 CINV_UPGRADE_REQUIRED value
         * @property {number} CINV_INTERNAL_ERROR=10 CINV_INTERNAL_ERROR value
         */
        WorkspaceCreateInviteResponse.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CINV_OK"] = 0;
            values[valuesById[1] = "CINV_INVALID_WORKSPACE"] = 1;
            values[valuesById[2] = "CINV_INVALID_EMAIL"] = 2;
            values[valuesById[3] = "CINV_INVALID_ROLE"] = 3;
            values[valuesById[4] = "CINV_INVALID_ACCESS"] = 4;
            values[valuesById[5] = "CINV_ALREADY_INVITED"] = 5;
            values[valuesById[6] = "CINV_ALREADY_MEMBER"] = 6;
            values[valuesById[7] = "CINV_UPGRADE_REQUIRED"] = 7;
            values[valuesById[10] = "CINV_INTERNAL_ERROR"] = 10;
            return values;
        })();

        return WorkspaceCreateInviteResponse;
    })();

    api.WorkspaceInvitesRequest = (function() {

        /**
         * Properties of a WorkspaceInvitesRequest.
         * @memberof api
         * @interface IWorkspaceInvitesRequest
         * @property {boolean|null} [fetch] WorkspaceInvitesRequest fetch
         */

        /**
         * Constructs a new WorkspaceInvitesRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceInvitesRequest.
         * @implements IWorkspaceInvitesRequest
         * @constructor
         * @param {api.IWorkspaceInvitesRequest=} [properties] Properties to set
         */
        function WorkspaceInvitesRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceInvitesRequest fetch.
         * @member {boolean} fetch
         * @memberof api.WorkspaceInvitesRequest
         * @instance
         */
        WorkspaceInvitesRequest.prototype.fetch = false;

        /**
         * Creates a new WorkspaceInvitesRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceInvitesRequest
         * @static
         * @param {api.IWorkspaceInvitesRequest=} [properties] Properties to set
         * @returns {api.WorkspaceInvitesRequest} WorkspaceInvitesRequest instance
         */
        WorkspaceInvitesRequest.create = function create(properties) {
            return new WorkspaceInvitesRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceInvitesRequest message. Does not implicitly {@link api.WorkspaceInvitesRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceInvitesRequest
         * @static
         * @param {api.IWorkspaceInvitesRequest} message WorkspaceInvitesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInvitesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fetch != null && Object.hasOwnProperty.call(message, "fetch"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.fetch);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceInvitesRequest message, length delimited. Does not implicitly {@link api.WorkspaceInvitesRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceInvitesRequest
         * @static
         * @param {api.IWorkspaceInvitesRequest} message WorkspaceInvitesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInvitesRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceInvitesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceInvitesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceInvitesRequest} WorkspaceInvitesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInvitesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceInvitesRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fetch = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceInvitesRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceInvitesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceInvitesRequest} WorkspaceInvitesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInvitesRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceInvitesRequest message.
         * @function verify
         * @memberof api.WorkspaceInvitesRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceInvitesRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                if (typeof message.fetch !== "boolean")
                    return "fetch: boolean expected";
            return null;
        };

        /**
         * Creates a WorkspaceInvitesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceInvitesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceInvitesRequest} WorkspaceInvitesRequest
         */
        WorkspaceInvitesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceInvitesRequest)
                return object;
            let message = new $root.api.WorkspaceInvitesRequest();
            if (object.fetch != null)
                message.fetch = Boolean(object.fetch);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceInvitesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceInvitesRequest
         * @static
         * @param {api.WorkspaceInvitesRequest} message WorkspaceInvitesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceInvitesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fetch = false;
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                object.fetch = message.fetch;
            return object;
        };

        /**
         * Converts this WorkspaceInvitesRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceInvitesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceInvitesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceInvitesRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceInvitesRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceInvitesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceInvitesRequest";
        };

        return WorkspaceInvitesRequest;
    })();

    api.WorkspaceInvitesResponse = (function() {

        /**
         * Properties of a WorkspaceInvitesResponse.
         * @memberof api
         * @interface IWorkspaceInvitesResponse
         * @property {Array.<entities.IInvite>|null} [invites] WorkspaceInvitesResponse invites
         */

        /**
         * Constructs a new WorkspaceInvitesResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceInvitesResponse.
         * @implements IWorkspaceInvitesResponse
         * @constructor
         * @param {api.IWorkspaceInvitesResponse=} [properties] Properties to set
         */
        function WorkspaceInvitesResponse(properties) {
            this.invites = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceInvitesResponse invites.
         * @member {Array.<entities.IInvite>} invites
         * @memberof api.WorkspaceInvitesResponse
         * @instance
         */
        WorkspaceInvitesResponse.prototype.invites = $util.emptyArray;

        /**
         * Creates a new WorkspaceInvitesResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceInvitesResponse
         * @static
         * @param {api.IWorkspaceInvitesResponse=} [properties] Properties to set
         * @returns {api.WorkspaceInvitesResponse} WorkspaceInvitesResponse instance
         */
        WorkspaceInvitesResponse.create = function create(properties) {
            return new WorkspaceInvitesResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceInvitesResponse message. Does not implicitly {@link api.WorkspaceInvitesResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceInvitesResponse
         * @static
         * @param {api.IWorkspaceInvitesResponse} message WorkspaceInvitesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInvitesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.invites != null && message.invites.length)
                for (let i = 0; i < message.invites.length; ++i)
                    $root.entities.Invite.encode(message.invites[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceInvitesResponse message, length delimited. Does not implicitly {@link api.WorkspaceInvitesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceInvitesResponse
         * @static
         * @param {api.IWorkspaceInvitesResponse} message WorkspaceInvitesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInvitesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceInvitesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceInvitesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceInvitesResponse} WorkspaceInvitesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInvitesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceInvitesResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.invites && message.invites.length))
                            message.invites = [];
                        message.invites.push($root.entities.Invite.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceInvitesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceInvitesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceInvitesResponse} WorkspaceInvitesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInvitesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceInvitesResponse message.
         * @function verify
         * @memberof api.WorkspaceInvitesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceInvitesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.invites != null && message.hasOwnProperty("invites")) {
                if (!Array.isArray(message.invites))
                    return "invites: array expected";
                for (let i = 0; i < message.invites.length; ++i) {
                    let error = $root.entities.Invite.verify(message.invites[i]);
                    if (error)
                        return "invites." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WorkspaceInvitesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceInvitesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceInvitesResponse} WorkspaceInvitesResponse
         */
        WorkspaceInvitesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceInvitesResponse)
                return object;
            let message = new $root.api.WorkspaceInvitesResponse();
            if (object.invites) {
                if (!Array.isArray(object.invites))
                    throw TypeError(".api.WorkspaceInvitesResponse.invites: array expected");
                message.invites = [];
                for (let i = 0; i < object.invites.length; ++i) {
                    if (typeof object.invites[i] !== "object")
                        throw TypeError(".api.WorkspaceInvitesResponse.invites: object expected");
                    message.invites[i] = $root.entities.Invite.fromObject(object.invites[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceInvitesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceInvitesResponse
         * @static
         * @param {api.WorkspaceInvitesResponse} message WorkspaceInvitesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceInvitesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.invites = [];
            if (message.invites && message.invites.length) {
                object.invites = [];
                for (let j = 0; j < message.invites.length; ++j)
                    object.invites[j] = $root.entities.Invite.toObject(message.invites[j], options);
            }
            return object;
        };

        /**
         * Converts this WorkspaceInvitesResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceInvitesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceInvitesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceInvitesResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceInvitesResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceInvitesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceInvitesResponse";
        };

        return WorkspaceInvitesResponse;
    })();

    api.WorkspaceInviteRequestNew = (function() {

        /**
         * Properties of a WorkspaceInviteRequestNew.
         * @memberof api
         * @interface IWorkspaceInviteRequestNew
         * @property {Uint8Array|null} [id] WorkspaceInviteRequestNew id
         */

        /**
         * Constructs a new WorkspaceInviteRequestNew.
         * @memberof api
         * @classdesc Represents a WorkspaceInviteRequestNew.
         * @implements IWorkspaceInviteRequestNew
         * @constructor
         * @param {api.IWorkspaceInviteRequestNew=} [properties] Properties to set
         */
        function WorkspaceInviteRequestNew(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceInviteRequestNew id.
         * @member {Uint8Array} id
         * @memberof api.WorkspaceInviteRequestNew
         * @instance
         */
        WorkspaceInviteRequestNew.prototype.id = $util.newBuffer([]);

        /**
         * Creates a new WorkspaceInviteRequestNew instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceInviteRequestNew
         * @static
         * @param {api.IWorkspaceInviteRequestNew=} [properties] Properties to set
         * @returns {api.WorkspaceInviteRequestNew} WorkspaceInviteRequestNew instance
         */
        WorkspaceInviteRequestNew.create = function create(properties) {
            return new WorkspaceInviteRequestNew(properties);
        };

        /**
         * Encodes the specified WorkspaceInviteRequestNew message. Does not implicitly {@link api.WorkspaceInviteRequestNew.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceInviteRequestNew
         * @static
         * @param {api.IWorkspaceInviteRequestNew} message WorkspaceInviteRequestNew message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInviteRequestNew.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceInviteRequestNew message, length delimited. Does not implicitly {@link api.WorkspaceInviteRequestNew.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceInviteRequestNew
         * @static
         * @param {api.IWorkspaceInviteRequestNew} message WorkspaceInviteRequestNew message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInviteRequestNew.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceInviteRequestNew message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceInviteRequestNew
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceInviteRequestNew} WorkspaceInviteRequestNew
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInviteRequestNew.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceInviteRequestNew();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceInviteRequestNew message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceInviteRequestNew
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceInviteRequestNew} WorkspaceInviteRequestNew
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInviteRequestNew.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceInviteRequestNew message.
         * @function verify
         * @memberof api.WorkspaceInviteRequestNew
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceInviteRequestNew.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            return null;
        };

        /**
         * Creates a WorkspaceInviteRequestNew message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceInviteRequestNew
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceInviteRequestNew} WorkspaceInviteRequestNew
         */
        WorkspaceInviteRequestNew.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceInviteRequestNew)
                return object;
            let message = new $root.api.WorkspaceInviteRequestNew();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length >= 0)
                    message.id = object.id;
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceInviteRequestNew message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceInviteRequestNew
         * @static
         * @param {api.WorkspaceInviteRequestNew} message WorkspaceInviteRequestNew
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceInviteRequestNew.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            return object;
        };

        /**
         * Converts this WorkspaceInviteRequestNew to JSON.
         * @function toJSON
         * @memberof api.WorkspaceInviteRequestNew
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceInviteRequestNew.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceInviteRequestNew
         * @function getTypeUrl
         * @memberof api.WorkspaceInviteRequestNew
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceInviteRequestNew.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceInviteRequestNew";
        };

        return WorkspaceInviteRequestNew;
    })();

    api.WorkspaceInviteResponseNew = (function() {

        /**
         * Properties of a WorkspaceInviteResponseNew.
         * @memberof api
         * @interface IWorkspaceInviteResponseNew
         * @property {entities.IInvite|null} [invite] WorkspaceInviteResponseNew invite
         * @property {boolean|null} [registrationRequired] WorkspaceInviteResponseNew registrationRequired
         * @property {entities.IWorkspace|null} [workspace] WorkspaceInviteResponseNew workspace
         */

        /**
         * Constructs a new WorkspaceInviteResponseNew.
         * @memberof api
         * @classdesc Represents a WorkspaceInviteResponseNew.
         * @implements IWorkspaceInviteResponseNew
         * @constructor
         * @param {api.IWorkspaceInviteResponseNew=} [properties] Properties to set
         */
        function WorkspaceInviteResponseNew(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceInviteResponseNew invite.
         * @member {entities.IInvite|null|undefined} invite
         * @memberof api.WorkspaceInviteResponseNew
         * @instance
         */
        WorkspaceInviteResponseNew.prototype.invite = null;

        /**
         * WorkspaceInviteResponseNew registrationRequired.
         * @member {boolean} registrationRequired
         * @memberof api.WorkspaceInviteResponseNew
         * @instance
         */
        WorkspaceInviteResponseNew.prototype.registrationRequired = false;

        /**
         * WorkspaceInviteResponseNew workspace.
         * @member {entities.IWorkspace|null|undefined} workspace
         * @memberof api.WorkspaceInviteResponseNew
         * @instance
         */
        WorkspaceInviteResponseNew.prototype.workspace = null;

        /**
         * Creates a new WorkspaceInviteResponseNew instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceInviteResponseNew
         * @static
         * @param {api.IWorkspaceInviteResponseNew=} [properties] Properties to set
         * @returns {api.WorkspaceInviteResponseNew} WorkspaceInviteResponseNew instance
         */
        WorkspaceInviteResponseNew.create = function create(properties) {
            return new WorkspaceInviteResponseNew(properties);
        };

        /**
         * Encodes the specified WorkspaceInviteResponseNew message. Does not implicitly {@link api.WorkspaceInviteResponseNew.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceInviteResponseNew
         * @static
         * @param {api.IWorkspaceInviteResponseNew} message WorkspaceInviteResponseNew message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInviteResponseNew.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.invite != null && Object.hasOwnProperty.call(message, "invite"))
                $root.entities.Invite.encode(message.invite, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.registrationRequired != null && Object.hasOwnProperty.call(message, "registrationRequired"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.registrationRequired);
            if (message.workspace != null && Object.hasOwnProperty.call(message, "workspace"))
                $root.entities.Workspace.encode(message.workspace, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceInviteResponseNew message, length delimited. Does not implicitly {@link api.WorkspaceInviteResponseNew.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceInviteResponseNew
         * @static
         * @param {api.IWorkspaceInviteResponseNew} message WorkspaceInviteResponseNew message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInviteResponseNew.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceInviteResponseNew message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceInviteResponseNew
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceInviteResponseNew} WorkspaceInviteResponseNew
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInviteResponseNew.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceInviteResponseNew();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.invite = $root.entities.Invite.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.registrationRequired = reader.bool();
                        break;
                    }
                case 3: {
                        message.workspace = $root.entities.Workspace.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceInviteResponseNew message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceInviteResponseNew
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceInviteResponseNew} WorkspaceInviteResponseNew
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInviteResponseNew.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceInviteResponseNew message.
         * @function verify
         * @memberof api.WorkspaceInviteResponseNew
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceInviteResponseNew.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.invite != null && message.hasOwnProperty("invite")) {
                let error = $root.entities.Invite.verify(message.invite);
                if (error)
                    return "invite." + error;
            }
            if (message.registrationRequired != null && message.hasOwnProperty("registrationRequired"))
                if (typeof message.registrationRequired !== "boolean")
                    return "registrationRequired: boolean expected";
            if (message.workspace != null && message.hasOwnProperty("workspace")) {
                let error = $root.entities.Workspace.verify(message.workspace);
                if (error)
                    return "workspace." + error;
            }
            return null;
        };

        /**
         * Creates a WorkspaceInviteResponseNew message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceInviteResponseNew
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceInviteResponseNew} WorkspaceInviteResponseNew
         */
        WorkspaceInviteResponseNew.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceInviteResponseNew)
                return object;
            let message = new $root.api.WorkspaceInviteResponseNew();
            if (object.invite != null) {
                if (typeof object.invite !== "object")
                    throw TypeError(".api.WorkspaceInviteResponseNew.invite: object expected");
                message.invite = $root.entities.Invite.fromObject(object.invite);
            }
            if (object.registrationRequired != null)
                message.registrationRequired = Boolean(object.registrationRequired);
            if (object.workspace != null) {
                if (typeof object.workspace !== "object")
                    throw TypeError(".api.WorkspaceInviteResponseNew.workspace: object expected");
                message.workspace = $root.entities.Workspace.fromObject(object.workspace);
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceInviteResponseNew message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceInviteResponseNew
         * @static
         * @param {api.WorkspaceInviteResponseNew} message WorkspaceInviteResponseNew
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceInviteResponseNew.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.invite = null;
                object.registrationRequired = false;
                object.workspace = null;
            }
            if (message.invite != null && message.hasOwnProperty("invite"))
                object.invite = $root.entities.Invite.toObject(message.invite, options);
            if (message.registrationRequired != null && message.hasOwnProperty("registrationRequired"))
                object.registrationRequired = message.registrationRequired;
            if (message.workspace != null && message.hasOwnProperty("workspace"))
                object.workspace = $root.entities.Workspace.toObject(message.workspace, options);
            return object;
        };

        /**
         * Converts this WorkspaceInviteResponseNew to JSON.
         * @function toJSON
         * @memberof api.WorkspaceInviteResponseNew
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceInviteResponseNew.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceInviteResponseNew
         * @function getTypeUrl
         * @memberof api.WorkspaceInviteResponseNew
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceInviteResponseNew.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceInviteResponseNew";
        };

        return WorkspaceInviteResponseNew;
    })();

    api.WorkspaceInviteDeleteRequest = (function() {

        /**
         * Properties of a WorkspaceInviteDeleteRequest.
         * @memberof api
         * @interface IWorkspaceInviteDeleteRequest
         * @property {Uint8Array|null} [id] WorkspaceInviteDeleteRequest id
         */

        /**
         * Constructs a new WorkspaceInviteDeleteRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceInviteDeleteRequest.
         * @implements IWorkspaceInviteDeleteRequest
         * @constructor
         * @param {api.IWorkspaceInviteDeleteRequest=} [properties] Properties to set
         */
        function WorkspaceInviteDeleteRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceInviteDeleteRequest id.
         * @member {Uint8Array} id
         * @memberof api.WorkspaceInviteDeleteRequest
         * @instance
         */
        WorkspaceInviteDeleteRequest.prototype.id = $util.newBuffer([]);

        /**
         * Creates a new WorkspaceInviteDeleteRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceInviteDeleteRequest
         * @static
         * @param {api.IWorkspaceInviteDeleteRequest=} [properties] Properties to set
         * @returns {api.WorkspaceInviteDeleteRequest} WorkspaceInviteDeleteRequest instance
         */
        WorkspaceInviteDeleteRequest.create = function create(properties) {
            return new WorkspaceInviteDeleteRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceInviteDeleteRequest message. Does not implicitly {@link api.WorkspaceInviteDeleteRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceInviteDeleteRequest
         * @static
         * @param {api.IWorkspaceInviteDeleteRequest} message WorkspaceInviteDeleteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInviteDeleteRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceInviteDeleteRequest message, length delimited. Does not implicitly {@link api.WorkspaceInviteDeleteRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceInviteDeleteRequest
         * @static
         * @param {api.IWorkspaceInviteDeleteRequest} message WorkspaceInviteDeleteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInviteDeleteRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceInviteDeleteRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceInviteDeleteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceInviteDeleteRequest} WorkspaceInviteDeleteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInviteDeleteRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceInviteDeleteRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceInviteDeleteRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceInviteDeleteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceInviteDeleteRequest} WorkspaceInviteDeleteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInviteDeleteRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceInviteDeleteRequest message.
         * @function verify
         * @memberof api.WorkspaceInviteDeleteRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceInviteDeleteRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            return null;
        };

        /**
         * Creates a WorkspaceInviteDeleteRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceInviteDeleteRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceInviteDeleteRequest} WorkspaceInviteDeleteRequest
         */
        WorkspaceInviteDeleteRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceInviteDeleteRequest)
                return object;
            let message = new $root.api.WorkspaceInviteDeleteRequest();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length >= 0)
                    message.id = object.id;
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceInviteDeleteRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceInviteDeleteRequest
         * @static
         * @param {api.WorkspaceInviteDeleteRequest} message WorkspaceInviteDeleteRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceInviteDeleteRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            return object;
        };

        /**
         * Converts this WorkspaceInviteDeleteRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceInviteDeleteRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceInviteDeleteRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceInviteDeleteRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceInviteDeleteRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceInviteDeleteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceInviteDeleteRequest";
        };

        return WorkspaceInviteDeleteRequest;
    })();

    api.WorkspaceInviteDeleteResponse = (function() {

        /**
         * Properties of a WorkspaceInviteDeleteResponse.
         * @memberof api
         * @interface IWorkspaceInviteDeleteResponse
         * @property {api.WorkspaceInviteDeleteResponse.Status|null} [status] WorkspaceInviteDeleteResponse status
         */

        /**
         * Constructs a new WorkspaceInviteDeleteResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceInviteDeleteResponse.
         * @implements IWorkspaceInviteDeleteResponse
         * @constructor
         * @param {api.IWorkspaceInviteDeleteResponse=} [properties] Properties to set
         */
        function WorkspaceInviteDeleteResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceInviteDeleteResponse status.
         * @member {api.WorkspaceInviteDeleteResponse.Status} status
         * @memberof api.WorkspaceInviteDeleteResponse
         * @instance
         */
        WorkspaceInviteDeleteResponse.prototype.status = 0;

        /**
         * Creates a new WorkspaceInviteDeleteResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceInviteDeleteResponse
         * @static
         * @param {api.IWorkspaceInviteDeleteResponse=} [properties] Properties to set
         * @returns {api.WorkspaceInviteDeleteResponse} WorkspaceInviteDeleteResponse instance
         */
        WorkspaceInviteDeleteResponse.create = function create(properties) {
            return new WorkspaceInviteDeleteResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceInviteDeleteResponse message. Does not implicitly {@link api.WorkspaceInviteDeleteResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceInviteDeleteResponse
         * @static
         * @param {api.IWorkspaceInviteDeleteResponse} message WorkspaceInviteDeleteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInviteDeleteResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceInviteDeleteResponse message, length delimited. Does not implicitly {@link api.WorkspaceInviteDeleteResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceInviteDeleteResponse
         * @static
         * @param {api.IWorkspaceInviteDeleteResponse} message WorkspaceInviteDeleteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceInviteDeleteResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceInviteDeleteResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceInviteDeleteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceInviteDeleteResponse} WorkspaceInviteDeleteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInviteDeleteResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceInviteDeleteResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceInviteDeleteResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceInviteDeleteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceInviteDeleteResponse} WorkspaceInviteDeleteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceInviteDeleteResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceInviteDeleteResponse message.
         * @function verify
         * @memberof api.WorkspaceInviteDeleteResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceInviteDeleteResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            return null;
        };

        /**
         * Creates a WorkspaceInviteDeleteResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceInviteDeleteResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceInviteDeleteResponse} WorkspaceInviteDeleteResponse
         */
        WorkspaceInviteDeleteResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceInviteDeleteResponse)
                return object;
            let message = new $root.api.WorkspaceInviteDeleteResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "DINV_UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "DINV_OK":
            case 1:
                message.status = 1;
                break;
            case "DINV_INVALID_WORKSPACE":
            case 2:
                message.status = 2;
                break;
            case "DINV_INVITE_NOT_FOUND":
            case 3:
                message.status = 3;
                break;
            case "DINV_INTERNAL_ERROR":
            case 4:
                message.status = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceInviteDeleteResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceInviteDeleteResponse
         * @static
         * @param {api.WorkspaceInviteDeleteResponse} message WorkspaceInviteDeleteResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceInviteDeleteResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.status = options.enums === String ? "DINV_UNKNOWN" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.api.WorkspaceInviteDeleteResponse.Status[message.status] === undefined ? message.status : $root.api.WorkspaceInviteDeleteResponse.Status[message.status] : message.status;
            return object;
        };

        /**
         * Converts this WorkspaceInviteDeleteResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceInviteDeleteResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceInviteDeleteResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceInviteDeleteResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceInviteDeleteResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceInviteDeleteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceInviteDeleteResponse";
        };

        /**
         * Status enum.
         * @name api.WorkspaceInviteDeleteResponse.Status
         * @enum {number}
         * @property {number} DINV_UNKNOWN=0 DINV_UNKNOWN value
         * @property {number} DINV_OK=1 DINV_OK value
         * @property {number} DINV_INVALID_WORKSPACE=2 DINV_INVALID_WORKSPACE value
         * @property {number} DINV_INVITE_NOT_FOUND=3 DINV_INVITE_NOT_FOUND value
         * @property {number} DINV_INTERNAL_ERROR=4 DINV_INTERNAL_ERROR value
         */
        WorkspaceInviteDeleteResponse.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DINV_UNKNOWN"] = 0;
            values[valuesById[1] = "DINV_OK"] = 1;
            values[valuesById[2] = "DINV_INVALID_WORKSPACE"] = 2;
            values[valuesById[3] = "DINV_INVITE_NOT_FOUND"] = 3;
            values[valuesById[4] = "DINV_INTERNAL_ERROR"] = 4;
            return values;
        })();

        return WorkspaceInviteDeleteResponse;
    })();

    api.WorkspaceAcceptInviteRequest = (function() {

        /**
         * Properties of a WorkspaceAcceptInviteRequest.
         * @memberof api
         * @interface IWorkspaceAcceptInviteRequest
         * @property {Uint8Array|null} [id] WorkspaceAcceptInviteRequest id
         */

        /**
         * Constructs a new WorkspaceAcceptInviteRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceAcceptInviteRequest.
         * @implements IWorkspaceAcceptInviteRequest
         * @constructor
         * @param {api.IWorkspaceAcceptInviteRequest=} [properties] Properties to set
         */
        function WorkspaceAcceptInviteRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceAcceptInviteRequest id.
         * @member {Uint8Array} id
         * @memberof api.WorkspaceAcceptInviteRequest
         * @instance
         */
        WorkspaceAcceptInviteRequest.prototype.id = $util.newBuffer([]);

        /**
         * Creates a new WorkspaceAcceptInviteRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceAcceptInviteRequest
         * @static
         * @param {api.IWorkspaceAcceptInviteRequest=} [properties] Properties to set
         * @returns {api.WorkspaceAcceptInviteRequest} WorkspaceAcceptInviteRequest instance
         */
        WorkspaceAcceptInviteRequest.create = function create(properties) {
            return new WorkspaceAcceptInviteRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceAcceptInviteRequest message. Does not implicitly {@link api.WorkspaceAcceptInviteRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceAcceptInviteRequest
         * @static
         * @param {api.IWorkspaceAcceptInviteRequest} message WorkspaceAcceptInviteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceAcceptInviteRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceAcceptInviteRequest message, length delimited. Does not implicitly {@link api.WorkspaceAcceptInviteRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceAcceptInviteRequest
         * @static
         * @param {api.IWorkspaceAcceptInviteRequest} message WorkspaceAcceptInviteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceAcceptInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceAcceptInviteRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceAcceptInviteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceAcceptInviteRequest} WorkspaceAcceptInviteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceAcceptInviteRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceAcceptInviteRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceAcceptInviteRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceAcceptInviteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceAcceptInviteRequest} WorkspaceAcceptInviteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceAcceptInviteRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceAcceptInviteRequest message.
         * @function verify
         * @memberof api.WorkspaceAcceptInviteRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceAcceptInviteRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            return null;
        };

        /**
         * Creates a WorkspaceAcceptInviteRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceAcceptInviteRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceAcceptInviteRequest} WorkspaceAcceptInviteRequest
         */
        WorkspaceAcceptInviteRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceAcceptInviteRequest)
                return object;
            let message = new $root.api.WorkspaceAcceptInviteRequest();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length >= 0)
                    message.id = object.id;
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceAcceptInviteRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceAcceptInviteRequest
         * @static
         * @param {api.WorkspaceAcceptInviteRequest} message WorkspaceAcceptInviteRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceAcceptInviteRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            return object;
        };

        /**
         * Converts this WorkspaceAcceptInviteRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceAcceptInviteRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceAcceptInviteRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceAcceptInviteRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceAcceptInviteRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceAcceptInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceAcceptInviteRequest";
        };

        return WorkspaceAcceptInviteRequest;
    })();

    api.WorkspaceAcceptInviteResponse = (function() {

        /**
         * Properties of a WorkspaceAcceptInviteResponse.
         * @memberof api
         * @interface IWorkspaceAcceptInviteResponse
         * @property {api.WorkspaceAcceptInviteResponse.Status|null} [status] WorkspaceAcceptInviteResponse status
         */

        /**
         * Constructs a new WorkspaceAcceptInviteResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceAcceptInviteResponse.
         * @implements IWorkspaceAcceptInviteResponse
         * @constructor
         * @param {api.IWorkspaceAcceptInviteResponse=} [properties] Properties to set
         */
        function WorkspaceAcceptInviteResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceAcceptInviteResponse status.
         * @member {api.WorkspaceAcceptInviteResponse.Status} status
         * @memberof api.WorkspaceAcceptInviteResponse
         * @instance
         */
        WorkspaceAcceptInviteResponse.prototype.status = 0;

        /**
         * Creates a new WorkspaceAcceptInviteResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceAcceptInviteResponse
         * @static
         * @param {api.IWorkspaceAcceptInviteResponse=} [properties] Properties to set
         * @returns {api.WorkspaceAcceptInviteResponse} WorkspaceAcceptInviteResponse instance
         */
        WorkspaceAcceptInviteResponse.create = function create(properties) {
            return new WorkspaceAcceptInviteResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceAcceptInviteResponse message. Does not implicitly {@link api.WorkspaceAcceptInviteResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceAcceptInviteResponse
         * @static
         * @param {api.IWorkspaceAcceptInviteResponse} message WorkspaceAcceptInviteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceAcceptInviteResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceAcceptInviteResponse message, length delimited. Does not implicitly {@link api.WorkspaceAcceptInviteResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceAcceptInviteResponse
         * @static
         * @param {api.IWorkspaceAcceptInviteResponse} message WorkspaceAcceptInviteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceAcceptInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceAcceptInviteResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceAcceptInviteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceAcceptInviteResponse} WorkspaceAcceptInviteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceAcceptInviteResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceAcceptInviteResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceAcceptInviteResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceAcceptInviteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceAcceptInviteResponse} WorkspaceAcceptInviteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceAcceptInviteResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceAcceptInviteResponse message.
         * @function verify
         * @memberof api.WorkspaceAcceptInviteResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceAcceptInviteResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                    break;
                }
            return null;
        };

        /**
         * Creates a WorkspaceAcceptInviteResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceAcceptInviteResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceAcceptInviteResponse} WorkspaceAcceptInviteResponse
         */
        WorkspaceAcceptInviteResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceAcceptInviteResponse)
                return object;
            let message = new $root.api.WorkspaceAcceptInviteResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "AINV_UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "AINV_OK":
            case 1:
                message.status = 1;
                break;
            case "AINV_INVALID_WORKSPACE":
            case 2:
                message.status = 2;
                break;
            case "AINV_INVITE_NOT_FOUND":
            case 3:
                message.status = 3;
                break;
            case "AINV_INTERNAL_ERROR":
            case 4:
                message.status = 4;
                break;
            case "AINV_NOT_BOUND_TO_USER":
            case 5:
                message.status = 5;
                break;
            case "AINV_ALREADY_MEMBER":
            case 6:
                message.status = 6;
                break;
            case "AINV_INVITE_USED":
            case 7:
                message.status = 7;
                break;
            case "AINV_INVITE_EXPIRED":
            case 8:
                message.status = 8;
                break;
            case "AINV_UPGRADE_REQUIRED":
            case 9:
                message.status = 9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceAcceptInviteResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceAcceptInviteResponse
         * @static
         * @param {api.WorkspaceAcceptInviteResponse} message WorkspaceAcceptInviteResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceAcceptInviteResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.status = options.enums === String ? "AINV_UNKNOWN" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.api.WorkspaceAcceptInviteResponse.Status[message.status] === undefined ? message.status : $root.api.WorkspaceAcceptInviteResponse.Status[message.status] : message.status;
            return object;
        };

        /**
         * Converts this WorkspaceAcceptInviteResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceAcceptInviteResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceAcceptInviteResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceAcceptInviteResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceAcceptInviteResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceAcceptInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceAcceptInviteResponse";
        };

        /**
         * Status enum.
         * @name api.WorkspaceAcceptInviteResponse.Status
         * @enum {number}
         * @property {number} AINV_UNKNOWN=0 AINV_UNKNOWN value
         * @property {number} AINV_OK=1 AINV_OK value
         * @property {number} AINV_INVALID_WORKSPACE=2 AINV_INVALID_WORKSPACE value
         * @property {number} AINV_INVITE_NOT_FOUND=3 AINV_INVITE_NOT_FOUND value
         * @property {number} AINV_INTERNAL_ERROR=4 AINV_INTERNAL_ERROR value
         * @property {number} AINV_NOT_BOUND_TO_USER=5 AINV_NOT_BOUND_TO_USER value
         * @property {number} AINV_ALREADY_MEMBER=6 AINV_ALREADY_MEMBER value
         * @property {number} AINV_INVITE_USED=7 AINV_INVITE_USED value
         * @property {number} AINV_INVITE_EXPIRED=8 AINV_INVITE_EXPIRED value
         * @property {number} AINV_UPGRADE_REQUIRED=9 AINV_UPGRADE_REQUIRED value
         */
        WorkspaceAcceptInviteResponse.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "AINV_UNKNOWN"] = 0;
            values[valuesById[1] = "AINV_OK"] = 1;
            values[valuesById[2] = "AINV_INVALID_WORKSPACE"] = 2;
            values[valuesById[3] = "AINV_INVITE_NOT_FOUND"] = 3;
            values[valuesById[4] = "AINV_INTERNAL_ERROR"] = 4;
            values[valuesById[5] = "AINV_NOT_BOUND_TO_USER"] = 5;
            values[valuesById[6] = "AINV_ALREADY_MEMBER"] = 6;
            values[valuesById[7] = "AINV_INVITE_USED"] = 7;
            values[valuesById[8] = "AINV_INVITE_EXPIRED"] = 8;
            values[valuesById[9] = "AINV_UPGRADE_REQUIRED"] = 9;
            return values;
        })();

        return WorkspaceAcceptInviteResponse;
    })();

    api.WorkspaceGetAvailableTariffsRequest = (function() {

        /**
         * Properties of a WorkspaceGetAvailableTariffsRequest.
         * @memberof api
         * @interface IWorkspaceGetAvailableTariffsRequest
         * @property {boolean|null} [fetch] WorkspaceGetAvailableTariffsRequest fetch
         */

        /**
         * Constructs a new WorkspaceGetAvailableTariffsRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceGetAvailableTariffsRequest.
         * @implements IWorkspaceGetAvailableTariffsRequest
         * @constructor
         * @param {api.IWorkspaceGetAvailableTariffsRequest=} [properties] Properties to set
         */
        function WorkspaceGetAvailableTariffsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceGetAvailableTariffsRequest fetch.
         * @member {boolean} fetch
         * @memberof api.WorkspaceGetAvailableTariffsRequest
         * @instance
         */
        WorkspaceGetAvailableTariffsRequest.prototype.fetch = false;

        /**
         * Creates a new WorkspaceGetAvailableTariffsRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceGetAvailableTariffsRequest
         * @static
         * @param {api.IWorkspaceGetAvailableTariffsRequest=} [properties] Properties to set
         * @returns {api.WorkspaceGetAvailableTariffsRequest} WorkspaceGetAvailableTariffsRequest instance
         */
        WorkspaceGetAvailableTariffsRequest.create = function create(properties) {
            return new WorkspaceGetAvailableTariffsRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceGetAvailableTariffsRequest message. Does not implicitly {@link api.WorkspaceGetAvailableTariffsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceGetAvailableTariffsRequest
         * @static
         * @param {api.IWorkspaceGetAvailableTariffsRequest} message WorkspaceGetAvailableTariffsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetAvailableTariffsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fetch != null && Object.hasOwnProperty.call(message, "fetch"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.fetch);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceGetAvailableTariffsRequest message, length delimited. Does not implicitly {@link api.WorkspaceGetAvailableTariffsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceGetAvailableTariffsRequest
         * @static
         * @param {api.IWorkspaceGetAvailableTariffsRequest} message WorkspaceGetAvailableTariffsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetAvailableTariffsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceGetAvailableTariffsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceGetAvailableTariffsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceGetAvailableTariffsRequest} WorkspaceGetAvailableTariffsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetAvailableTariffsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceGetAvailableTariffsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fetch = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceGetAvailableTariffsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceGetAvailableTariffsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceGetAvailableTariffsRequest} WorkspaceGetAvailableTariffsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetAvailableTariffsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceGetAvailableTariffsRequest message.
         * @function verify
         * @memberof api.WorkspaceGetAvailableTariffsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceGetAvailableTariffsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                if (typeof message.fetch !== "boolean")
                    return "fetch: boolean expected";
            return null;
        };

        /**
         * Creates a WorkspaceGetAvailableTariffsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceGetAvailableTariffsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceGetAvailableTariffsRequest} WorkspaceGetAvailableTariffsRequest
         */
        WorkspaceGetAvailableTariffsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceGetAvailableTariffsRequest)
                return object;
            let message = new $root.api.WorkspaceGetAvailableTariffsRequest();
            if (object.fetch != null)
                message.fetch = Boolean(object.fetch);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceGetAvailableTariffsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceGetAvailableTariffsRequest
         * @static
         * @param {api.WorkspaceGetAvailableTariffsRequest} message WorkspaceGetAvailableTariffsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceGetAvailableTariffsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fetch = false;
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                object.fetch = message.fetch;
            return object;
        };

        /**
         * Converts this WorkspaceGetAvailableTariffsRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceGetAvailableTariffsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceGetAvailableTariffsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceGetAvailableTariffsRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceGetAvailableTariffsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceGetAvailableTariffsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceGetAvailableTariffsRequest";
        };

        return WorkspaceGetAvailableTariffsRequest;
    })();

    api.WorkspaceGetAvailableTariffsResponse = (function() {

        /**
         * Properties of a WorkspaceGetAvailableTariffsResponse.
         * @memberof api
         * @interface IWorkspaceGetAvailableTariffsResponse
         * @property {Array.<entities.ITariff>|null} [tariffs] WorkspaceGetAvailableTariffsResponse tariffs
         */

        /**
         * Constructs a new WorkspaceGetAvailableTariffsResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceGetAvailableTariffsResponse.
         * @implements IWorkspaceGetAvailableTariffsResponse
         * @constructor
         * @param {api.IWorkspaceGetAvailableTariffsResponse=} [properties] Properties to set
         */
        function WorkspaceGetAvailableTariffsResponse(properties) {
            this.tariffs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceGetAvailableTariffsResponse tariffs.
         * @member {Array.<entities.ITariff>} tariffs
         * @memberof api.WorkspaceGetAvailableTariffsResponse
         * @instance
         */
        WorkspaceGetAvailableTariffsResponse.prototype.tariffs = $util.emptyArray;

        /**
         * Creates a new WorkspaceGetAvailableTariffsResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceGetAvailableTariffsResponse
         * @static
         * @param {api.IWorkspaceGetAvailableTariffsResponse=} [properties] Properties to set
         * @returns {api.WorkspaceGetAvailableTariffsResponse} WorkspaceGetAvailableTariffsResponse instance
         */
        WorkspaceGetAvailableTariffsResponse.create = function create(properties) {
            return new WorkspaceGetAvailableTariffsResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceGetAvailableTariffsResponse message. Does not implicitly {@link api.WorkspaceGetAvailableTariffsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceGetAvailableTariffsResponse
         * @static
         * @param {api.IWorkspaceGetAvailableTariffsResponse} message WorkspaceGetAvailableTariffsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetAvailableTariffsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tariffs != null && message.tariffs.length)
                for (let i = 0; i < message.tariffs.length; ++i)
                    $root.entities.Tariff.encode(message.tariffs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceGetAvailableTariffsResponse message, length delimited. Does not implicitly {@link api.WorkspaceGetAvailableTariffsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceGetAvailableTariffsResponse
         * @static
         * @param {api.IWorkspaceGetAvailableTariffsResponse} message WorkspaceGetAvailableTariffsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetAvailableTariffsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceGetAvailableTariffsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceGetAvailableTariffsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceGetAvailableTariffsResponse} WorkspaceGetAvailableTariffsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetAvailableTariffsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceGetAvailableTariffsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.tariffs && message.tariffs.length))
                            message.tariffs = [];
                        message.tariffs.push($root.entities.Tariff.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceGetAvailableTariffsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceGetAvailableTariffsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceGetAvailableTariffsResponse} WorkspaceGetAvailableTariffsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetAvailableTariffsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceGetAvailableTariffsResponse message.
         * @function verify
         * @memberof api.WorkspaceGetAvailableTariffsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceGetAvailableTariffsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tariffs != null && message.hasOwnProperty("tariffs")) {
                if (!Array.isArray(message.tariffs))
                    return "tariffs: array expected";
                for (let i = 0; i < message.tariffs.length; ++i) {
                    let error = $root.entities.Tariff.verify(message.tariffs[i]);
                    if (error)
                        return "tariffs." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WorkspaceGetAvailableTariffsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceGetAvailableTariffsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceGetAvailableTariffsResponse} WorkspaceGetAvailableTariffsResponse
         */
        WorkspaceGetAvailableTariffsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceGetAvailableTariffsResponse)
                return object;
            let message = new $root.api.WorkspaceGetAvailableTariffsResponse();
            if (object.tariffs) {
                if (!Array.isArray(object.tariffs))
                    throw TypeError(".api.WorkspaceGetAvailableTariffsResponse.tariffs: array expected");
                message.tariffs = [];
                for (let i = 0; i < object.tariffs.length; ++i) {
                    if (typeof object.tariffs[i] !== "object")
                        throw TypeError(".api.WorkspaceGetAvailableTariffsResponse.tariffs: object expected");
                    message.tariffs[i] = $root.entities.Tariff.fromObject(object.tariffs[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceGetAvailableTariffsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceGetAvailableTariffsResponse
         * @static
         * @param {api.WorkspaceGetAvailableTariffsResponse} message WorkspaceGetAvailableTariffsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceGetAvailableTariffsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.tariffs = [];
            if (message.tariffs && message.tariffs.length) {
                object.tariffs = [];
                for (let j = 0; j < message.tariffs.length; ++j)
                    object.tariffs[j] = $root.entities.Tariff.toObject(message.tariffs[j], options);
            }
            return object;
        };

        /**
         * Converts this WorkspaceGetAvailableTariffsResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceGetAvailableTariffsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceGetAvailableTariffsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceGetAvailableTariffsResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceGetAvailableTariffsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceGetAvailableTariffsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceGetAvailableTariffsResponse";
        };

        WorkspaceGetAvailableTariffsResponse.TariffOption = (function() {

            /**
             * Properties of a TariffOption.
             * @memberof api.WorkspaceGetAvailableTariffsResponse
             * @interface ITariffOption
             * @property {entities.ITariff|null} [tariff] TariffOption tariff
             * @property {Array.<api.WorkspaceGetAvailableTariffsResponse.TariffOption.IPricingOption>|null} [options] TariffOption options
             */

            /**
             * Constructs a new TariffOption.
             * @memberof api.WorkspaceGetAvailableTariffsResponse
             * @classdesc Represents a TariffOption.
             * @implements ITariffOption
             * @constructor
             * @param {api.WorkspaceGetAvailableTariffsResponse.ITariffOption=} [properties] Properties to set
             */
            function TariffOption(properties) {
                this.options = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TariffOption tariff.
             * @member {entities.ITariff|null|undefined} tariff
             * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption
             * @instance
             */
            TariffOption.prototype.tariff = null;

            /**
             * TariffOption options.
             * @member {Array.<api.WorkspaceGetAvailableTariffsResponse.TariffOption.IPricingOption>} options
             * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption
             * @instance
             */
            TariffOption.prototype.options = $util.emptyArray;

            /**
             * Creates a new TariffOption instance using the specified properties.
             * @function create
             * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption
             * @static
             * @param {api.WorkspaceGetAvailableTariffsResponse.ITariffOption=} [properties] Properties to set
             * @returns {api.WorkspaceGetAvailableTariffsResponse.TariffOption} TariffOption instance
             */
            TariffOption.create = function create(properties) {
                return new TariffOption(properties);
            };

            /**
             * Encodes the specified TariffOption message. Does not implicitly {@link api.WorkspaceGetAvailableTariffsResponse.TariffOption.verify|verify} messages.
             * @function encode
             * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption
             * @static
             * @param {api.WorkspaceGetAvailableTariffsResponse.ITariffOption} message TariffOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TariffOption.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tariff != null && Object.hasOwnProperty.call(message, "tariff"))
                    $root.entities.Tariff.encode(message.tariff, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.options != null && message.options.length)
                    for (let i = 0; i < message.options.length; ++i)
                        $root.api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption.encode(message.options[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified TariffOption message, length delimited. Does not implicitly {@link api.WorkspaceGetAvailableTariffsResponse.TariffOption.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption
             * @static
             * @param {api.WorkspaceGetAvailableTariffsResponse.ITariffOption} message TariffOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TariffOption.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TariffOption message from the specified reader or buffer.
             * @function decode
             * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.WorkspaceGetAvailableTariffsResponse.TariffOption} TariffOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TariffOption.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceGetAvailableTariffsResponse.TariffOption();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.tariff = $root.entities.Tariff.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            if (!(message.options && message.options.length))
                                message.options = [];
                            message.options.push($root.api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TariffOption message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.WorkspaceGetAvailableTariffsResponse.TariffOption} TariffOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TariffOption.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TariffOption message.
             * @function verify
             * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TariffOption.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tariff != null && message.hasOwnProperty("tariff")) {
                    let error = $root.entities.Tariff.verify(message.tariff);
                    if (error)
                        return "tariff." + error;
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    if (!Array.isArray(message.options))
                        return "options: array expected";
                    for (let i = 0; i < message.options.length; ++i) {
                        let error = $root.api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption.verify(message.options[i]);
                        if (error)
                            return "options." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a TariffOption message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.WorkspaceGetAvailableTariffsResponse.TariffOption} TariffOption
             */
            TariffOption.fromObject = function fromObject(object) {
                if (object instanceof $root.api.WorkspaceGetAvailableTariffsResponse.TariffOption)
                    return object;
                let message = new $root.api.WorkspaceGetAvailableTariffsResponse.TariffOption();
                if (object.tariff != null) {
                    if (typeof object.tariff !== "object")
                        throw TypeError(".api.WorkspaceGetAvailableTariffsResponse.TariffOption.tariff: object expected");
                    message.tariff = $root.entities.Tariff.fromObject(object.tariff);
                }
                if (object.options) {
                    if (!Array.isArray(object.options))
                        throw TypeError(".api.WorkspaceGetAvailableTariffsResponse.TariffOption.options: array expected");
                    message.options = [];
                    for (let i = 0; i < object.options.length; ++i) {
                        if (typeof object.options[i] !== "object")
                            throw TypeError(".api.WorkspaceGetAvailableTariffsResponse.TariffOption.options: object expected");
                        message.options[i] = $root.api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption.fromObject(object.options[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a TariffOption message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption
             * @static
             * @param {api.WorkspaceGetAvailableTariffsResponse.TariffOption} message TariffOption
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TariffOption.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.options = [];
                if (options.defaults)
                    object.tariff = null;
                if (message.tariff != null && message.hasOwnProperty("tariff"))
                    object.tariff = $root.entities.Tariff.toObject(message.tariff, options);
                if (message.options && message.options.length) {
                    object.options = [];
                    for (let j = 0; j < message.options.length; ++j)
                        object.options[j] = $root.api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption.toObject(message.options[j], options);
                }
                return object;
            };

            /**
             * Converts this TariffOption to JSON.
             * @function toJSON
             * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TariffOption.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TariffOption
             * @function getTypeUrl
             * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TariffOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.WorkspaceGetAvailableTariffsResponse.TariffOption";
            };

            TariffOption.PricingOption = (function() {

                /**
                 * Properties of a PricingOption.
                 * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption
                 * @interface IPricingOption
                 * @property {string|null} [currency] PricingOption currency
                 * @property {number|null} [amount] PricingOption amount
                 */

                /**
                 * Constructs a new PricingOption.
                 * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption
                 * @classdesc Represents a PricingOption.
                 * @implements IPricingOption
                 * @constructor
                 * @param {api.WorkspaceGetAvailableTariffsResponse.TariffOption.IPricingOption=} [properties] Properties to set
                 */
                function PricingOption(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PricingOption currency.
                 * @member {string} currency
                 * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption
                 * @instance
                 */
                PricingOption.prototype.currency = "";

                /**
                 * PricingOption amount.
                 * @member {number} amount
                 * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption
                 * @instance
                 */
                PricingOption.prototype.amount = 0;

                /**
                 * Creates a new PricingOption instance using the specified properties.
                 * @function create
                 * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption
                 * @static
                 * @param {api.WorkspaceGetAvailableTariffsResponse.TariffOption.IPricingOption=} [properties] Properties to set
                 * @returns {api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption} PricingOption instance
                 */
                PricingOption.create = function create(properties) {
                    return new PricingOption(properties);
                };

                /**
                 * Encodes the specified PricingOption message. Does not implicitly {@link api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption.verify|verify} messages.
                 * @function encode
                 * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption
                 * @static
                 * @param {api.WorkspaceGetAvailableTariffsResponse.TariffOption.IPricingOption} message PricingOption message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PricingOption.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.currency);
                    if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                        writer.uint32(/* id 2, wireType 1 =*/17).double(message.amount);
                    return writer;
                };

                /**
                 * Encodes the specified PricingOption message, length delimited. Does not implicitly {@link api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption
                 * @static
                 * @param {api.WorkspaceGetAvailableTariffsResponse.TariffOption.IPricingOption} message PricingOption message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PricingOption.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PricingOption message from the specified reader or buffer.
                 * @function decode
                 * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption} PricingOption
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PricingOption.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.currency = reader.string();
                                break;
                            }
                        case 2: {
                                message.amount = reader.double();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PricingOption message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption} PricingOption
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PricingOption.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PricingOption message.
                 * @function verify
                 * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PricingOption.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.currency != null && message.hasOwnProperty("currency"))
                        if (!$util.isString(message.currency))
                            return "currency: string expected";
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        if (typeof message.amount !== "number")
                            return "amount: number expected";
                    return null;
                };

                /**
                 * Creates a PricingOption message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption} PricingOption
                 */
                PricingOption.fromObject = function fromObject(object) {
                    if (object instanceof $root.api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption)
                        return object;
                    let message = new $root.api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption();
                    if (object.currency != null)
                        message.currency = String(object.currency);
                    if (object.amount != null)
                        message.amount = Number(object.amount);
                    return message;
                };

                /**
                 * Creates a plain object from a PricingOption message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption
                 * @static
                 * @param {api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption} message PricingOption
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PricingOption.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.currency = "";
                        object.amount = 0;
                    }
                    if (message.currency != null && message.hasOwnProperty("currency"))
                        object.currency = message.currency;
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
                    return object;
                };

                /**
                 * Converts this PricingOption to JSON.
                 * @function toJSON
                 * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PricingOption.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PricingOption
                 * @function getTypeUrl
                 * @memberof api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PricingOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/api.WorkspaceGetAvailableTariffsResponse.TariffOption.PricingOption";
                };

                return PricingOption;
            })();

            return TariffOption;
        })();

        return WorkspaceGetAvailableTariffsResponse;
    })();

    api.WorkspaceGetCurrentTariffRequest = (function() {

        /**
         * Properties of a WorkspaceGetCurrentTariffRequest.
         * @memberof api
         * @interface IWorkspaceGetCurrentTariffRequest
         * @property {boolean|null} [fetch] WorkspaceGetCurrentTariffRequest fetch
         */

        /**
         * Constructs a new WorkspaceGetCurrentTariffRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceGetCurrentTariffRequest.
         * @implements IWorkspaceGetCurrentTariffRequest
         * @constructor
         * @param {api.IWorkspaceGetCurrentTariffRequest=} [properties] Properties to set
         */
        function WorkspaceGetCurrentTariffRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceGetCurrentTariffRequest fetch.
         * @member {boolean} fetch
         * @memberof api.WorkspaceGetCurrentTariffRequest
         * @instance
         */
        WorkspaceGetCurrentTariffRequest.prototype.fetch = false;

        /**
         * Creates a new WorkspaceGetCurrentTariffRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceGetCurrentTariffRequest
         * @static
         * @param {api.IWorkspaceGetCurrentTariffRequest=} [properties] Properties to set
         * @returns {api.WorkspaceGetCurrentTariffRequest} WorkspaceGetCurrentTariffRequest instance
         */
        WorkspaceGetCurrentTariffRequest.create = function create(properties) {
            return new WorkspaceGetCurrentTariffRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceGetCurrentTariffRequest message. Does not implicitly {@link api.WorkspaceGetCurrentTariffRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceGetCurrentTariffRequest
         * @static
         * @param {api.IWorkspaceGetCurrentTariffRequest} message WorkspaceGetCurrentTariffRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetCurrentTariffRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fetch != null && Object.hasOwnProperty.call(message, "fetch"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.fetch);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceGetCurrentTariffRequest message, length delimited. Does not implicitly {@link api.WorkspaceGetCurrentTariffRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceGetCurrentTariffRequest
         * @static
         * @param {api.IWorkspaceGetCurrentTariffRequest} message WorkspaceGetCurrentTariffRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetCurrentTariffRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceGetCurrentTariffRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceGetCurrentTariffRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceGetCurrentTariffRequest} WorkspaceGetCurrentTariffRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetCurrentTariffRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceGetCurrentTariffRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fetch = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceGetCurrentTariffRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceGetCurrentTariffRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceGetCurrentTariffRequest} WorkspaceGetCurrentTariffRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetCurrentTariffRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceGetCurrentTariffRequest message.
         * @function verify
         * @memberof api.WorkspaceGetCurrentTariffRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceGetCurrentTariffRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                if (typeof message.fetch !== "boolean")
                    return "fetch: boolean expected";
            return null;
        };

        /**
         * Creates a WorkspaceGetCurrentTariffRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceGetCurrentTariffRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceGetCurrentTariffRequest} WorkspaceGetCurrentTariffRequest
         */
        WorkspaceGetCurrentTariffRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceGetCurrentTariffRequest)
                return object;
            let message = new $root.api.WorkspaceGetCurrentTariffRequest();
            if (object.fetch != null)
                message.fetch = Boolean(object.fetch);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceGetCurrentTariffRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceGetCurrentTariffRequest
         * @static
         * @param {api.WorkspaceGetCurrentTariffRequest} message WorkspaceGetCurrentTariffRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceGetCurrentTariffRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fetch = false;
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                object.fetch = message.fetch;
            return object;
        };

        /**
         * Converts this WorkspaceGetCurrentTariffRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceGetCurrentTariffRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceGetCurrentTariffRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceGetCurrentTariffRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceGetCurrentTariffRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceGetCurrentTariffRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceGetCurrentTariffRequest";
        };

        return WorkspaceGetCurrentTariffRequest;
    })();

    api.WorkspaceGetCurrentTariffResponse = (function() {

        /**
         * Properties of a WorkspaceGetCurrentTariffResponse.
         * @memberof api
         * @interface IWorkspaceGetCurrentTariffResponse
         * @property {entities.ITariff|null} [tariff] WorkspaceGetCurrentTariffResponse tariff
         * @property {Long|null} [expiresAt] WorkspaceGetCurrentTariffResponse expiresAt
         * @property {boolean|null} [autoRenew] WorkspaceGetCurrentTariffResponse autoRenew
         * @property {string|null} [cardLastDigits] WorkspaceGetCurrentTariffResponse cardLastDigits
         * @property {string|null} [cardBrand] WorkspaceGetCurrentTariffResponse cardBrand
         * @property {api.WorkspaceGetCurrentTariffResponse.PaymentStatus|null} [paymentStatus] WorkspaceGetCurrentTariffResponse paymentStatus
         * @property {string|null} [transactionId] WorkspaceGetCurrentTariffResponse transactionId
         */

        /**
         * Constructs a new WorkspaceGetCurrentTariffResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceGetCurrentTariffResponse.
         * @implements IWorkspaceGetCurrentTariffResponse
         * @constructor
         * @param {api.IWorkspaceGetCurrentTariffResponse=} [properties] Properties to set
         */
        function WorkspaceGetCurrentTariffResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceGetCurrentTariffResponse tariff.
         * @member {entities.ITariff|null|undefined} tariff
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @instance
         */
        WorkspaceGetCurrentTariffResponse.prototype.tariff = null;

        /**
         * WorkspaceGetCurrentTariffResponse expiresAt.
         * @member {Long} expiresAt
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @instance
         */
        WorkspaceGetCurrentTariffResponse.prototype.expiresAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * WorkspaceGetCurrentTariffResponse autoRenew.
         * @member {boolean} autoRenew
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @instance
         */
        WorkspaceGetCurrentTariffResponse.prototype.autoRenew = false;

        /**
         * WorkspaceGetCurrentTariffResponse cardLastDigits.
         * @member {string} cardLastDigits
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @instance
         */
        WorkspaceGetCurrentTariffResponse.prototype.cardLastDigits = "";

        /**
         * WorkspaceGetCurrentTariffResponse cardBrand.
         * @member {string} cardBrand
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @instance
         */
        WorkspaceGetCurrentTariffResponse.prototype.cardBrand = "";

        /**
         * WorkspaceGetCurrentTariffResponse paymentStatus.
         * @member {api.WorkspaceGetCurrentTariffResponse.PaymentStatus} paymentStatus
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @instance
         */
        WorkspaceGetCurrentTariffResponse.prototype.paymentStatus = 0;

        /**
         * WorkspaceGetCurrentTariffResponse transactionId.
         * @member {string} transactionId
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @instance
         */
        WorkspaceGetCurrentTariffResponse.prototype.transactionId = "";

        /**
         * Creates a new WorkspaceGetCurrentTariffResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @static
         * @param {api.IWorkspaceGetCurrentTariffResponse=} [properties] Properties to set
         * @returns {api.WorkspaceGetCurrentTariffResponse} WorkspaceGetCurrentTariffResponse instance
         */
        WorkspaceGetCurrentTariffResponse.create = function create(properties) {
            return new WorkspaceGetCurrentTariffResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceGetCurrentTariffResponse message. Does not implicitly {@link api.WorkspaceGetCurrentTariffResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @static
         * @param {api.IWorkspaceGetCurrentTariffResponse} message WorkspaceGetCurrentTariffResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetCurrentTariffResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tariff != null && Object.hasOwnProperty.call(message, "tariff"))
                $root.entities.Tariff.encode(message.tariff, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.expiresAt != null && Object.hasOwnProperty.call(message, "expiresAt"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.expiresAt);
            if (message.autoRenew != null && Object.hasOwnProperty.call(message, "autoRenew"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.autoRenew);
            if (message.paymentStatus != null && Object.hasOwnProperty.call(message, "paymentStatus"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.paymentStatus);
            if (message.cardLastDigits != null && Object.hasOwnProperty.call(message, "cardLastDigits"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.cardLastDigits);
            if (message.cardBrand != null && Object.hasOwnProperty.call(message, "cardBrand"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.cardBrand);
            if (message.transactionId != null && Object.hasOwnProperty.call(message, "transactionId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.transactionId);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceGetCurrentTariffResponse message, length delimited. Does not implicitly {@link api.WorkspaceGetCurrentTariffResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @static
         * @param {api.IWorkspaceGetCurrentTariffResponse} message WorkspaceGetCurrentTariffResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetCurrentTariffResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceGetCurrentTariffResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceGetCurrentTariffResponse} WorkspaceGetCurrentTariffResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetCurrentTariffResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceGetCurrentTariffResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tariff = $root.entities.Tariff.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.expiresAt = reader.uint64();
                        break;
                    }
                case 3: {
                        message.autoRenew = reader.bool();
                        break;
                    }
                case 5: {
                        message.cardLastDigits = reader.string();
                        break;
                    }
                case 6: {
                        message.cardBrand = reader.string();
                        break;
                    }
                case 4: {
                        message.paymentStatus = reader.int32();
                        break;
                    }
                case 7: {
                        message.transactionId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceGetCurrentTariffResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceGetCurrentTariffResponse} WorkspaceGetCurrentTariffResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetCurrentTariffResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceGetCurrentTariffResponse message.
         * @function verify
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceGetCurrentTariffResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tariff != null && message.hasOwnProperty("tariff")) {
                let error = $root.entities.Tariff.verify(message.tariff);
                if (error)
                    return "tariff." + error;
            }
            if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                if (!$util.isInteger(message.expiresAt) && !(message.expiresAt && $util.isInteger(message.expiresAt.low) && $util.isInteger(message.expiresAt.high)))
                    return "expiresAt: integer|Long expected";
            if (message.autoRenew != null && message.hasOwnProperty("autoRenew"))
                if (typeof message.autoRenew !== "boolean")
                    return "autoRenew: boolean expected";
            if (message.cardLastDigits != null && message.hasOwnProperty("cardLastDigits"))
                if (!$util.isString(message.cardLastDigits))
                    return "cardLastDigits: string expected";
            if (message.cardBrand != null && message.hasOwnProperty("cardBrand"))
                if (!$util.isString(message.cardBrand))
                    return "cardBrand: string expected";
            if (message.paymentStatus != null && message.hasOwnProperty("paymentStatus"))
                switch (message.paymentStatus) {
                default:
                    return "paymentStatus: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                if (!$util.isString(message.transactionId))
                    return "transactionId: string expected";
            return null;
        };

        /**
         * Creates a WorkspaceGetCurrentTariffResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceGetCurrentTariffResponse} WorkspaceGetCurrentTariffResponse
         */
        WorkspaceGetCurrentTariffResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceGetCurrentTariffResponse)
                return object;
            let message = new $root.api.WorkspaceGetCurrentTariffResponse();
            if (object.tariff != null) {
                if (typeof object.tariff !== "object")
                    throw TypeError(".api.WorkspaceGetCurrentTariffResponse.tariff: object expected");
                message.tariff = $root.entities.Tariff.fromObject(object.tariff);
            }
            if (object.expiresAt != null)
                if ($util.Long)
                    (message.expiresAt = $util.Long.fromValue(object.expiresAt)).unsigned = true;
                else if (typeof object.expiresAt === "string")
                    message.expiresAt = parseInt(object.expiresAt, 10);
                else if (typeof object.expiresAt === "number")
                    message.expiresAt = object.expiresAt;
                else if (typeof object.expiresAt === "object")
                    message.expiresAt = new $util.LongBits(object.expiresAt.low >>> 0, object.expiresAt.high >>> 0).toNumber(true);
            if (object.autoRenew != null)
                message.autoRenew = Boolean(object.autoRenew);
            if (object.cardLastDigits != null)
                message.cardLastDigits = String(object.cardLastDigits);
            if (object.cardBrand != null)
                message.cardBrand = String(object.cardBrand);
            switch (object.paymentStatus) {
            default:
                if (typeof object.paymentStatus === "number") {
                    message.paymentStatus = object.paymentStatus;
                    break;
                }
                break;
            case "PAS_UNKNOWN":
            case 0:
                message.paymentStatus = 0;
                break;
            case "PAS_OK":
            case 1:
                message.paymentStatus = 1;
                break;
            case "PAS_FAILING":
            case 2:
                message.paymentStatus = 2;
                break;
            case "PAS_FAILED":
            case 3:
                message.paymentStatus = 3;
                break;
            }
            if (object.transactionId != null)
                message.transactionId = String(object.transactionId);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceGetCurrentTariffResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @static
         * @param {api.WorkspaceGetCurrentTariffResponse} message WorkspaceGetCurrentTariffResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceGetCurrentTariffResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.tariff = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.expiresAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expiresAt = options.longs === String ? "0" : 0;
                object.autoRenew = false;
                object.paymentStatus = options.enums === String ? "PAS_UNKNOWN" : 0;
                object.cardLastDigits = "";
                object.cardBrand = "";
                object.transactionId = "";
            }
            if (message.tariff != null && message.hasOwnProperty("tariff"))
                object.tariff = $root.entities.Tariff.toObject(message.tariff, options);
            if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                if (typeof message.expiresAt === "number")
                    object.expiresAt = options.longs === String ? String(message.expiresAt) : message.expiresAt;
                else
                    object.expiresAt = options.longs === String ? $util.Long.prototype.toString.call(message.expiresAt) : options.longs === Number ? new $util.LongBits(message.expiresAt.low >>> 0, message.expiresAt.high >>> 0).toNumber(true) : message.expiresAt;
            if (message.autoRenew != null && message.hasOwnProperty("autoRenew"))
                object.autoRenew = message.autoRenew;
            if (message.paymentStatus != null && message.hasOwnProperty("paymentStatus"))
                object.paymentStatus = options.enums === String ? $root.api.WorkspaceGetCurrentTariffResponse.PaymentStatus[message.paymentStatus] === undefined ? message.paymentStatus : $root.api.WorkspaceGetCurrentTariffResponse.PaymentStatus[message.paymentStatus] : message.paymentStatus;
            if (message.cardLastDigits != null && message.hasOwnProperty("cardLastDigits"))
                object.cardLastDigits = message.cardLastDigits;
            if (message.cardBrand != null && message.hasOwnProperty("cardBrand"))
                object.cardBrand = message.cardBrand;
            if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                object.transactionId = message.transactionId;
            return object;
        };

        /**
         * Converts this WorkspaceGetCurrentTariffResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceGetCurrentTariffResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceGetCurrentTariffResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceGetCurrentTariffResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceGetCurrentTariffResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceGetCurrentTariffResponse";
        };

        /**
         * PaymentStatus enum.
         * @name api.WorkspaceGetCurrentTariffResponse.PaymentStatus
         * @enum {number}
         * @property {number} PAS_UNKNOWN=0 PAS_UNKNOWN value
         * @property {number} PAS_OK=1 PAS_OK value
         * @property {number} PAS_FAILING=2 PAS_FAILING value
         * @property {number} PAS_FAILED=3 PAS_FAILED value
         */
        WorkspaceGetCurrentTariffResponse.PaymentStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "PAS_UNKNOWN"] = 0;
            values[valuesById[1] = "PAS_OK"] = 1;
            values[valuesById[2] = "PAS_FAILING"] = 2;
            values[valuesById[3] = "PAS_FAILED"] = 3;
            return values;
        })();

        return WorkspaceGetCurrentTariffResponse;
    })();

    api.WorkspaceGetBillingHistoryRequest = (function() {

        /**
         * Properties of a WorkspaceGetBillingHistoryRequest.
         * @memberof api
         * @interface IWorkspaceGetBillingHistoryRequest
         * @property {boolean|null} [fetch] WorkspaceGetBillingHistoryRequest fetch
         * @property {Long|null} [limit] WorkspaceGetBillingHistoryRequest limit
         * @property {string|null} [startID] WorkspaceGetBillingHistoryRequest startID
         */

        /**
         * Constructs a new WorkspaceGetBillingHistoryRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceGetBillingHistoryRequest.
         * @implements IWorkspaceGetBillingHistoryRequest
         * @constructor
         * @param {api.IWorkspaceGetBillingHistoryRequest=} [properties] Properties to set
         */
        function WorkspaceGetBillingHistoryRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceGetBillingHistoryRequest fetch.
         * @member {boolean} fetch
         * @memberof api.WorkspaceGetBillingHistoryRequest
         * @instance
         */
        WorkspaceGetBillingHistoryRequest.prototype.fetch = false;

        /**
         * WorkspaceGetBillingHistoryRequest limit.
         * @member {Long} limit
         * @memberof api.WorkspaceGetBillingHistoryRequest
         * @instance
         */
        WorkspaceGetBillingHistoryRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkspaceGetBillingHistoryRequest startID.
         * @member {string} startID
         * @memberof api.WorkspaceGetBillingHistoryRequest
         * @instance
         */
        WorkspaceGetBillingHistoryRequest.prototype.startID = "";

        /**
         * Creates a new WorkspaceGetBillingHistoryRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceGetBillingHistoryRequest
         * @static
         * @param {api.IWorkspaceGetBillingHistoryRequest=} [properties] Properties to set
         * @returns {api.WorkspaceGetBillingHistoryRequest} WorkspaceGetBillingHistoryRequest instance
         */
        WorkspaceGetBillingHistoryRequest.create = function create(properties) {
            return new WorkspaceGetBillingHistoryRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceGetBillingHistoryRequest message. Does not implicitly {@link api.WorkspaceGetBillingHistoryRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceGetBillingHistoryRequest
         * @static
         * @param {api.IWorkspaceGetBillingHistoryRequest} message WorkspaceGetBillingHistoryRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetBillingHistoryRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fetch != null && Object.hasOwnProperty.call(message, "fetch"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.fetch);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.limit);
            if (message.startID != null && Object.hasOwnProperty.call(message, "startID"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.startID);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceGetBillingHistoryRequest message, length delimited. Does not implicitly {@link api.WorkspaceGetBillingHistoryRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceGetBillingHistoryRequest
         * @static
         * @param {api.IWorkspaceGetBillingHistoryRequest} message WorkspaceGetBillingHistoryRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetBillingHistoryRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceGetBillingHistoryRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceGetBillingHistoryRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceGetBillingHistoryRequest} WorkspaceGetBillingHistoryRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetBillingHistoryRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceGetBillingHistoryRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fetch = reader.bool();
                        break;
                    }
                case 2: {
                        message.limit = reader.int64();
                        break;
                    }
                case 3: {
                        message.startID = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceGetBillingHistoryRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceGetBillingHistoryRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceGetBillingHistoryRequest} WorkspaceGetBillingHistoryRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetBillingHistoryRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceGetBillingHistoryRequest message.
         * @function verify
         * @memberof api.WorkspaceGetBillingHistoryRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceGetBillingHistoryRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                if (typeof message.fetch !== "boolean")
                    return "fetch: boolean expected";
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                    return "limit: integer|Long expected";
            if (message.startID != null && message.hasOwnProperty("startID"))
                if (!$util.isString(message.startID))
                    return "startID: string expected";
            return null;
        };

        /**
         * Creates a WorkspaceGetBillingHistoryRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceGetBillingHistoryRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceGetBillingHistoryRequest} WorkspaceGetBillingHistoryRequest
         */
        WorkspaceGetBillingHistoryRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceGetBillingHistoryRequest)
                return object;
            let message = new $root.api.WorkspaceGetBillingHistoryRequest();
            if (object.fetch != null)
                message.fetch = Boolean(object.fetch);
            if (object.limit != null)
                if ($util.Long)
                    (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                else if (typeof object.limit === "string")
                    message.limit = parseInt(object.limit, 10);
                else if (typeof object.limit === "number")
                    message.limit = object.limit;
                else if (typeof object.limit === "object")
                    message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
            if (object.startID != null)
                message.startID = String(object.startID);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceGetBillingHistoryRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceGetBillingHistoryRequest
         * @static
         * @param {api.WorkspaceGetBillingHistoryRequest} message WorkspaceGetBillingHistoryRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceGetBillingHistoryRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.fetch = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.limit = options.longs === String ? "0" : 0;
                object.startID = "";
            }
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                object.fetch = message.fetch;
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (typeof message.limit === "number")
                    object.limit = options.longs === String ? String(message.limit) : message.limit;
                else
                    object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
            if (message.startID != null && message.hasOwnProperty("startID"))
                object.startID = message.startID;
            return object;
        };

        /**
         * Converts this WorkspaceGetBillingHistoryRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceGetBillingHistoryRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceGetBillingHistoryRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceGetBillingHistoryRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceGetBillingHistoryRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceGetBillingHistoryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceGetBillingHistoryRequest";
        };

        return WorkspaceGetBillingHistoryRequest;
    })();

    api.WorkspaceGetBillingHistoryResponse = (function() {

        /**
         * Properties of a WorkspaceGetBillingHistoryResponse.
         * @memberof api
         * @interface IWorkspaceGetBillingHistoryResponse
         * @property {Array.<entities.IBillingHistoryEntry>|null} [entries] WorkspaceGetBillingHistoryResponse entries
         * @property {Array.<entities.ITariff>|null} [mentionedTariffs] WorkspaceGetBillingHistoryResponse mentionedTariffs
         * @property {boolean|null} [hasMore] WorkspaceGetBillingHistoryResponse hasMore
         */

        /**
         * Constructs a new WorkspaceGetBillingHistoryResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceGetBillingHistoryResponse.
         * @implements IWorkspaceGetBillingHistoryResponse
         * @constructor
         * @param {api.IWorkspaceGetBillingHistoryResponse=} [properties] Properties to set
         */
        function WorkspaceGetBillingHistoryResponse(properties) {
            this.entries = [];
            this.mentionedTariffs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceGetBillingHistoryResponse entries.
         * @member {Array.<entities.IBillingHistoryEntry>} entries
         * @memberof api.WorkspaceGetBillingHistoryResponse
         * @instance
         */
        WorkspaceGetBillingHistoryResponse.prototype.entries = $util.emptyArray;

        /**
         * WorkspaceGetBillingHistoryResponse mentionedTariffs.
         * @member {Array.<entities.ITariff>} mentionedTariffs
         * @memberof api.WorkspaceGetBillingHistoryResponse
         * @instance
         */
        WorkspaceGetBillingHistoryResponse.prototype.mentionedTariffs = $util.emptyArray;

        /**
         * WorkspaceGetBillingHistoryResponse hasMore.
         * @member {boolean} hasMore
         * @memberof api.WorkspaceGetBillingHistoryResponse
         * @instance
         */
        WorkspaceGetBillingHistoryResponse.prototype.hasMore = false;

        /**
         * Creates a new WorkspaceGetBillingHistoryResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceGetBillingHistoryResponse
         * @static
         * @param {api.IWorkspaceGetBillingHistoryResponse=} [properties] Properties to set
         * @returns {api.WorkspaceGetBillingHistoryResponse} WorkspaceGetBillingHistoryResponse instance
         */
        WorkspaceGetBillingHistoryResponse.create = function create(properties) {
            return new WorkspaceGetBillingHistoryResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceGetBillingHistoryResponse message. Does not implicitly {@link api.WorkspaceGetBillingHistoryResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceGetBillingHistoryResponse
         * @static
         * @param {api.IWorkspaceGetBillingHistoryResponse} message WorkspaceGetBillingHistoryResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetBillingHistoryResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.entries != null && message.entries.length)
                for (let i = 0; i < message.entries.length; ++i)
                    $root.entities.BillingHistoryEntry.encode(message.entries[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.mentionedTariffs != null && message.mentionedTariffs.length)
                for (let i = 0; i < message.mentionedTariffs.length; ++i)
                    $root.entities.Tariff.encode(message.mentionedTariffs[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.hasMore != null && Object.hasOwnProperty.call(message, "hasMore"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.hasMore);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceGetBillingHistoryResponse message, length delimited. Does not implicitly {@link api.WorkspaceGetBillingHistoryResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceGetBillingHistoryResponse
         * @static
         * @param {api.IWorkspaceGetBillingHistoryResponse} message WorkspaceGetBillingHistoryResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetBillingHistoryResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceGetBillingHistoryResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceGetBillingHistoryResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceGetBillingHistoryResponse} WorkspaceGetBillingHistoryResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetBillingHistoryResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceGetBillingHistoryResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.entries && message.entries.length))
                            message.entries = [];
                        message.entries.push($root.entities.BillingHistoryEntry.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        if (!(message.mentionedTariffs && message.mentionedTariffs.length))
                            message.mentionedTariffs = [];
                        message.mentionedTariffs.push($root.entities.Tariff.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.hasMore = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceGetBillingHistoryResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceGetBillingHistoryResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceGetBillingHistoryResponse} WorkspaceGetBillingHistoryResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetBillingHistoryResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceGetBillingHistoryResponse message.
         * @function verify
         * @memberof api.WorkspaceGetBillingHistoryResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceGetBillingHistoryResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.entries != null && message.hasOwnProperty("entries")) {
                if (!Array.isArray(message.entries))
                    return "entries: array expected";
                for (let i = 0; i < message.entries.length; ++i) {
                    let error = $root.entities.BillingHistoryEntry.verify(message.entries[i]);
                    if (error)
                        return "entries." + error;
                }
            }
            if (message.mentionedTariffs != null && message.hasOwnProperty("mentionedTariffs")) {
                if (!Array.isArray(message.mentionedTariffs))
                    return "mentionedTariffs: array expected";
                for (let i = 0; i < message.mentionedTariffs.length; ++i) {
                    let error = $root.entities.Tariff.verify(message.mentionedTariffs[i]);
                    if (error)
                        return "mentionedTariffs." + error;
                }
            }
            if (message.hasMore != null && message.hasOwnProperty("hasMore"))
                if (typeof message.hasMore !== "boolean")
                    return "hasMore: boolean expected";
            return null;
        };

        /**
         * Creates a WorkspaceGetBillingHistoryResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceGetBillingHistoryResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceGetBillingHistoryResponse} WorkspaceGetBillingHistoryResponse
         */
        WorkspaceGetBillingHistoryResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceGetBillingHistoryResponse)
                return object;
            let message = new $root.api.WorkspaceGetBillingHistoryResponse();
            if (object.entries) {
                if (!Array.isArray(object.entries))
                    throw TypeError(".api.WorkspaceGetBillingHistoryResponse.entries: array expected");
                message.entries = [];
                for (let i = 0; i < object.entries.length; ++i) {
                    if (typeof object.entries[i] !== "object")
                        throw TypeError(".api.WorkspaceGetBillingHistoryResponse.entries: object expected");
                    message.entries[i] = $root.entities.BillingHistoryEntry.fromObject(object.entries[i]);
                }
            }
            if (object.mentionedTariffs) {
                if (!Array.isArray(object.mentionedTariffs))
                    throw TypeError(".api.WorkspaceGetBillingHistoryResponse.mentionedTariffs: array expected");
                message.mentionedTariffs = [];
                for (let i = 0; i < object.mentionedTariffs.length; ++i) {
                    if (typeof object.mentionedTariffs[i] !== "object")
                        throw TypeError(".api.WorkspaceGetBillingHistoryResponse.mentionedTariffs: object expected");
                    message.mentionedTariffs[i] = $root.entities.Tariff.fromObject(object.mentionedTariffs[i]);
                }
            }
            if (object.hasMore != null)
                message.hasMore = Boolean(object.hasMore);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceGetBillingHistoryResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceGetBillingHistoryResponse
         * @static
         * @param {api.WorkspaceGetBillingHistoryResponse} message WorkspaceGetBillingHistoryResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceGetBillingHistoryResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.entries = [];
                object.mentionedTariffs = [];
            }
            if (options.defaults)
                object.hasMore = false;
            if (message.entries && message.entries.length) {
                object.entries = [];
                for (let j = 0; j < message.entries.length; ++j)
                    object.entries[j] = $root.entities.BillingHistoryEntry.toObject(message.entries[j], options);
            }
            if (message.mentionedTariffs && message.mentionedTariffs.length) {
                object.mentionedTariffs = [];
                for (let j = 0; j < message.mentionedTariffs.length; ++j)
                    object.mentionedTariffs[j] = $root.entities.Tariff.toObject(message.mentionedTariffs[j], options);
            }
            if (message.hasMore != null && message.hasOwnProperty("hasMore"))
                object.hasMore = message.hasMore;
            return object;
        };

        /**
         * Converts this WorkspaceGetBillingHistoryResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceGetBillingHistoryResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceGetBillingHistoryResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceGetBillingHistoryResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceGetBillingHistoryResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceGetBillingHistoryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceGetBillingHistoryResponse";
        };

        return WorkspaceGetBillingHistoryResponse;
    })();

    api.WorkspacePayForTariffRequest = (function() {

        /**
         * Properties of a WorkspacePayForTariffRequest.
         * @memberof api
         * @interface IWorkspacePayForTariffRequest
         * @property {string|null} [tariffId] WorkspacePayForTariffRequest tariffId
         * @property {Array.<Long>|null} [blockMemberIDs] WorkspacePayForTariffRequest blockMemberIDs
         */

        /**
         * Constructs a new WorkspacePayForTariffRequest.
         * @memberof api
         * @classdesc Represents a WorkspacePayForTariffRequest.
         * @implements IWorkspacePayForTariffRequest
         * @constructor
         * @param {api.IWorkspacePayForTariffRequest=} [properties] Properties to set
         */
        function WorkspacePayForTariffRequest(properties) {
            this.blockMemberIDs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspacePayForTariffRequest tariffId.
         * @member {string} tariffId
         * @memberof api.WorkspacePayForTariffRequest
         * @instance
         */
        WorkspacePayForTariffRequest.prototype.tariffId = "";

        /**
         * WorkspacePayForTariffRequest blockMemberIDs.
         * @member {Array.<Long>} blockMemberIDs
         * @memberof api.WorkspacePayForTariffRequest
         * @instance
         */
        WorkspacePayForTariffRequest.prototype.blockMemberIDs = $util.emptyArray;

        /**
         * Creates a new WorkspacePayForTariffRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspacePayForTariffRequest
         * @static
         * @param {api.IWorkspacePayForTariffRequest=} [properties] Properties to set
         * @returns {api.WorkspacePayForTariffRequest} WorkspacePayForTariffRequest instance
         */
        WorkspacePayForTariffRequest.create = function create(properties) {
            return new WorkspacePayForTariffRequest(properties);
        };

        /**
         * Encodes the specified WorkspacePayForTariffRequest message. Does not implicitly {@link api.WorkspacePayForTariffRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspacePayForTariffRequest
         * @static
         * @param {api.IWorkspacePayForTariffRequest} message WorkspacePayForTariffRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspacePayForTariffRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tariffId != null && Object.hasOwnProperty.call(message, "tariffId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.tariffId);
            if (message.blockMemberIDs != null && message.blockMemberIDs.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.blockMemberIDs.length; ++i)
                    writer.uint64(message.blockMemberIDs[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified WorkspacePayForTariffRequest message, length delimited. Does not implicitly {@link api.WorkspacePayForTariffRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspacePayForTariffRequest
         * @static
         * @param {api.IWorkspacePayForTariffRequest} message WorkspacePayForTariffRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspacePayForTariffRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspacePayForTariffRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspacePayForTariffRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspacePayForTariffRequest} WorkspacePayForTariffRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspacePayForTariffRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspacePayForTariffRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tariffId = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.blockMemberIDs && message.blockMemberIDs.length))
                            message.blockMemberIDs = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.blockMemberIDs.push(reader.uint64());
                        } else
                            message.blockMemberIDs.push(reader.uint64());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspacePayForTariffRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspacePayForTariffRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspacePayForTariffRequest} WorkspacePayForTariffRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspacePayForTariffRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspacePayForTariffRequest message.
         * @function verify
         * @memberof api.WorkspacePayForTariffRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspacePayForTariffRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tariffId != null && message.hasOwnProperty("tariffId"))
                if (!$util.isString(message.tariffId))
                    return "tariffId: string expected";
            if (message.blockMemberIDs != null && message.hasOwnProperty("blockMemberIDs")) {
                if (!Array.isArray(message.blockMemberIDs))
                    return "blockMemberIDs: array expected";
                for (let i = 0; i < message.blockMemberIDs.length; ++i)
                    if (!$util.isInteger(message.blockMemberIDs[i]) && !(message.blockMemberIDs[i] && $util.isInteger(message.blockMemberIDs[i].low) && $util.isInteger(message.blockMemberIDs[i].high)))
                        return "blockMemberIDs: integer|Long[] expected";
            }
            return null;
        };

        /**
         * Creates a WorkspacePayForTariffRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspacePayForTariffRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspacePayForTariffRequest} WorkspacePayForTariffRequest
         */
        WorkspacePayForTariffRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspacePayForTariffRequest)
                return object;
            let message = new $root.api.WorkspacePayForTariffRequest();
            if (object.tariffId != null)
                message.tariffId = String(object.tariffId);
            if (object.blockMemberIDs) {
                if (!Array.isArray(object.blockMemberIDs))
                    throw TypeError(".api.WorkspacePayForTariffRequest.blockMemberIDs: array expected");
                message.blockMemberIDs = [];
                for (let i = 0; i < object.blockMemberIDs.length; ++i)
                    if ($util.Long)
                        (message.blockMemberIDs[i] = $util.Long.fromValue(object.blockMemberIDs[i])).unsigned = true;
                    else if (typeof object.blockMemberIDs[i] === "string")
                        message.blockMemberIDs[i] = parseInt(object.blockMemberIDs[i], 10);
                    else if (typeof object.blockMemberIDs[i] === "number")
                        message.blockMemberIDs[i] = object.blockMemberIDs[i];
                    else if (typeof object.blockMemberIDs[i] === "object")
                        message.blockMemberIDs[i] = new $util.LongBits(object.blockMemberIDs[i].low >>> 0, object.blockMemberIDs[i].high >>> 0).toNumber(true);
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspacePayForTariffRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspacePayForTariffRequest
         * @static
         * @param {api.WorkspacePayForTariffRequest} message WorkspacePayForTariffRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspacePayForTariffRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.blockMemberIDs = [];
            if (options.defaults)
                object.tariffId = "";
            if (message.tariffId != null && message.hasOwnProperty("tariffId"))
                object.tariffId = message.tariffId;
            if (message.blockMemberIDs && message.blockMemberIDs.length) {
                object.blockMemberIDs = [];
                for (let j = 0; j < message.blockMemberIDs.length; ++j)
                    if (typeof message.blockMemberIDs[j] === "number")
                        object.blockMemberIDs[j] = options.longs === String ? String(message.blockMemberIDs[j]) : message.blockMemberIDs[j];
                    else
                        object.blockMemberIDs[j] = options.longs === String ? $util.Long.prototype.toString.call(message.blockMemberIDs[j]) : options.longs === Number ? new $util.LongBits(message.blockMemberIDs[j].low >>> 0, message.blockMemberIDs[j].high >>> 0).toNumber(true) : message.blockMemberIDs[j];
            }
            return object;
        };

        /**
         * Converts this WorkspacePayForTariffRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspacePayForTariffRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspacePayForTariffRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspacePayForTariffRequest
         * @function getTypeUrl
         * @memberof api.WorkspacePayForTariffRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspacePayForTariffRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspacePayForTariffRequest";
        };

        return WorkspacePayForTariffRequest;
    })();

    api.WorkspacePayForTariffResponse = (function() {

        /**
         * Properties of a WorkspacePayForTariffResponse.
         * @memberof api
         * @interface IWorkspacePayForTariffResponse
         * @property {api.WorkspacePayForTariffResponse.Status|null} [status] WorkspacePayForTariffResponse status
         * @property {string|null} [paymentLink] WorkspacePayForTariffResponse paymentLink
         * @property {string|null} [receipt] WorkspacePayForTariffResponse receipt
         */

        /**
         * Constructs a new WorkspacePayForTariffResponse.
         * @memberof api
         * @classdesc Represents a WorkspacePayForTariffResponse.
         * @implements IWorkspacePayForTariffResponse
         * @constructor
         * @param {api.IWorkspacePayForTariffResponse=} [properties] Properties to set
         */
        function WorkspacePayForTariffResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspacePayForTariffResponse status.
         * @member {api.WorkspacePayForTariffResponse.Status} status
         * @memberof api.WorkspacePayForTariffResponse
         * @instance
         */
        WorkspacePayForTariffResponse.prototype.status = 0;

        /**
         * WorkspacePayForTariffResponse paymentLink.
         * @member {string} paymentLink
         * @memberof api.WorkspacePayForTariffResponse
         * @instance
         */
        WorkspacePayForTariffResponse.prototype.paymentLink = "";

        /**
         * WorkspacePayForTariffResponse receipt.
         * @member {string} receipt
         * @memberof api.WorkspacePayForTariffResponse
         * @instance
         */
        WorkspacePayForTariffResponse.prototype.receipt = "";

        /**
         * Creates a new WorkspacePayForTariffResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspacePayForTariffResponse
         * @static
         * @param {api.IWorkspacePayForTariffResponse=} [properties] Properties to set
         * @returns {api.WorkspacePayForTariffResponse} WorkspacePayForTariffResponse instance
         */
        WorkspacePayForTariffResponse.create = function create(properties) {
            return new WorkspacePayForTariffResponse(properties);
        };

        /**
         * Encodes the specified WorkspacePayForTariffResponse message. Does not implicitly {@link api.WorkspacePayForTariffResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspacePayForTariffResponse
         * @static
         * @param {api.IWorkspacePayForTariffResponse} message WorkspacePayForTariffResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspacePayForTariffResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.paymentLink != null && Object.hasOwnProperty.call(message, "paymentLink"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.paymentLink);
            if (message.receipt != null && Object.hasOwnProperty.call(message, "receipt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.receipt);
            return writer;
        };

        /**
         * Encodes the specified WorkspacePayForTariffResponse message, length delimited. Does not implicitly {@link api.WorkspacePayForTariffResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspacePayForTariffResponse
         * @static
         * @param {api.IWorkspacePayForTariffResponse} message WorkspacePayForTariffResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspacePayForTariffResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspacePayForTariffResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspacePayForTariffResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspacePayForTariffResponse} WorkspacePayForTariffResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspacePayForTariffResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspacePayForTariffResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.paymentLink = reader.string();
                        break;
                    }
                case 3: {
                        message.receipt = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspacePayForTariffResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspacePayForTariffResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspacePayForTariffResponse} WorkspacePayForTariffResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspacePayForTariffResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspacePayForTariffResponse message.
         * @function verify
         * @memberof api.WorkspacePayForTariffResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspacePayForTariffResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.paymentLink != null && message.hasOwnProperty("paymentLink"))
                if (!$util.isString(message.paymentLink))
                    return "paymentLink: string expected";
            if (message.receipt != null && message.hasOwnProperty("receipt"))
                if (!$util.isString(message.receipt))
                    return "receipt: string expected";
            return null;
        };

        /**
         * Creates a WorkspacePayForTariffResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspacePayForTariffResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspacePayForTariffResponse} WorkspacePayForTariffResponse
         */
        WorkspacePayForTariffResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspacePayForTariffResponse)
                return object;
            let message = new $root.api.WorkspacePayForTariffResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "WPT_UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "WPT_OK":
            case 1:
                message.status = 1;
                break;
            case "WPT_INVALID_TARIFF":
            case 2:
                message.status = 2;
                break;
            case "WPT_INTERNAL_ERROR":
            case 3:
                message.status = 3;
                break;
            }
            if (object.paymentLink != null)
                message.paymentLink = String(object.paymentLink);
            if (object.receipt != null)
                message.receipt = String(object.receipt);
            return message;
        };

        /**
         * Creates a plain object from a WorkspacePayForTariffResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspacePayForTariffResponse
         * @static
         * @param {api.WorkspacePayForTariffResponse} message WorkspacePayForTariffResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspacePayForTariffResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "WPT_UNKNOWN" : 0;
                object.paymentLink = "";
                object.receipt = "";
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.api.WorkspacePayForTariffResponse.Status[message.status] === undefined ? message.status : $root.api.WorkspacePayForTariffResponse.Status[message.status] : message.status;
            if (message.paymentLink != null && message.hasOwnProperty("paymentLink"))
                object.paymentLink = message.paymentLink;
            if (message.receipt != null && message.hasOwnProperty("receipt"))
                object.receipt = message.receipt;
            return object;
        };

        /**
         * Converts this WorkspacePayForTariffResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspacePayForTariffResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspacePayForTariffResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspacePayForTariffResponse
         * @function getTypeUrl
         * @memberof api.WorkspacePayForTariffResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspacePayForTariffResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspacePayForTariffResponse";
        };

        /**
         * Status enum.
         * @name api.WorkspacePayForTariffResponse.Status
         * @enum {number}
         * @property {number} WPT_UNKNOWN=0 WPT_UNKNOWN value
         * @property {number} WPT_OK=1 WPT_OK value
         * @property {number} WPT_INVALID_TARIFF=2 WPT_INVALID_TARIFF value
         * @property {number} WPT_INTERNAL_ERROR=3 WPT_INTERNAL_ERROR value
         */
        WorkspacePayForTariffResponse.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "WPT_UNKNOWN"] = 0;
            values[valuesById[1] = "WPT_OK"] = 1;
            values[valuesById[2] = "WPT_INVALID_TARIFF"] = 2;
            values[valuesById[3] = "WPT_INTERNAL_ERROR"] = 3;
            return values;
        })();

        return WorkspacePayForTariffResponse;
    })();

    api.WorkspaceCancelCurrentTariff = (function() {

        /**
         * Properties of a WorkspaceCancelCurrentTariff.
         * @memberof api
         * @interface IWorkspaceCancelCurrentTariff
         * @property {boolean|null} [fetch] WorkspaceCancelCurrentTariff fetch
         */

        /**
         * Constructs a new WorkspaceCancelCurrentTariff.
         * @memberof api
         * @classdesc Represents a WorkspaceCancelCurrentTariff.
         * @implements IWorkspaceCancelCurrentTariff
         * @constructor
         * @param {api.IWorkspaceCancelCurrentTariff=} [properties] Properties to set
         */
        function WorkspaceCancelCurrentTariff(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceCancelCurrentTariff fetch.
         * @member {boolean} fetch
         * @memberof api.WorkspaceCancelCurrentTariff
         * @instance
         */
        WorkspaceCancelCurrentTariff.prototype.fetch = false;

        /**
         * Creates a new WorkspaceCancelCurrentTariff instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceCancelCurrentTariff
         * @static
         * @param {api.IWorkspaceCancelCurrentTariff=} [properties] Properties to set
         * @returns {api.WorkspaceCancelCurrentTariff} WorkspaceCancelCurrentTariff instance
         */
        WorkspaceCancelCurrentTariff.create = function create(properties) {
            return new WorkspaceCancelCurrentTariff(properties);
        };

        /**
         * Encodes the specified WorkspaceCancelCurrentTariff message. Does not implicitly {@link api.WorkspaceCancelCurrentTariff.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceCancelCurrentTariff
         * @static
         * @param {api.IWorkspaceCancelCurrentTariff} message WorkspaceCancelCurrentTariff message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceCancelCurrentTariff.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fetch != null && Object.hasOwnProperty.call(message, "fetch"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.fetch);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceCancelCurrentTariff message, length delimited. Does not implicitly {@link api.WorkspaceCancelCurrentTariff.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceCancelCurrentTariff
         * @static
         * @param {api.IWorkspaceCancelCurrentTariff} message WorkspaceCancelCurrentTariff message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceCancelCurrentTariff.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceCancelCurrentTariff message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceCancelCurrentTariff
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceCancelCurrentTariff} WorkspaceCancelCurrentTariff
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceCancelCurrentTariff.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceCancelCurrentTariff();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fetch = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceCancelCurrentTariff message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceCancelCurrentTariff
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceCancelCurrentTariff} WorkspaceCancelCurrentTariff
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceCancelCurrentTariff.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceCancelCurrentTariff message.
         * @function verify
         * @memberof api.WorkspaceCancelCurrentTariff
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceCancelCurrentTariff.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                if (typeof message.fetch !== "boolean")
                    return "fetch: boolean expected";
            return null;
        };

        /**
         * Creates a WorkspaceCancelCurrentTariff message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceCancelCurrentTariff
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceCancelCurrentTariff} WorkspaceCancelCurrentTariff
         */
        WorkspaceCancelCurrentTariff.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceCancelCurrentTariff)
                return object;
            let message = new $root.api.WorkspaceCancelCurrentTariff();
            if (object.fetch != null)
                message.fetch = Boolean(object.fetch);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceCancelCurrentTariff message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceCancelCurrentTariff
         * @static
         * @param {api.WorkspaceCancelCurrentTariff} message WorkspaceCancelCurrentTariff
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceCancelCurrentTariff.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fetch = false;
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                object.fetch = message.fetch;
            return object;
        };

        /**
         * Converts this WorkspaceCancelCurrentTariff to JSON.
         * @function toJSON
         * @memberof api.WorkspaceCancelCurrentTariff
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceCancelCurrentTariff.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceCancelCurrentTariff
         * @function getTypeUrl
         * @memberof api.WorkspaceCancelCurrentTariff
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceCancelCurrentTariff.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceCancelCurrentTariff";
        };

        return WorkspaceCancelCurrentTariff;
    })();

    api.WorkspaceCancelCurrentTariffResponse = (function() {

        /**
         * Properties of a WorkspaceCancelCurrentTariffResponse.
         * @memberof api
         * @interface IWorkspaceCancelCurrentTariffResponse
         * @property {api.WorkspaceCancelCurrentTariffResponse.Status|null} [status] WorkspaceCancelCurrentTariffResponse status
         */

        /**
         * Constructs a new WorkspaceCancelCurrentTariffResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceCancelCurrentTariffResponse.
         * @implements IWorkspaceCancelCurrentTariffResponse
         * @constructor
         * @param {api.IWorkspaceCancelCurrentTariffResponse=} [properties] Properties to set
         */
        function WorkspaceCancelCurrentTariffResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceCancelCurrentTariffResponse status.
         * @member {api.WorkspaceCancelCurrentTariffResponse.Status} status
         * @memberof api.WorkspaceCancelCurrentTariffResponse
         * @instance
         */
        WorkspaceCancelCurrentTariffResponse.prototype.status = 0;

        /**
         * Creates a new WorkspaceCancelCurrentTariffResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceCancelCurrentTariffResponse
         * @static
         * @param {api.IWorkspaceCancelCurrentTariffResponse=} [properties] Properties to set
         * @returns {api.WorkspaceCancelCurrentTariffResponse} WorkspaceCancelCurrentTariffResponse instance
         */
        WorkspaceCancelCurrentTariffResponse.create = function create(properties) {
            return new WorkspaceCancelCurrentTariffResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceCancelCurrentTariffResponse message. Does not implicitly {@link api.WorkspaceCancelCurrentTariffResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceCancelCurrentTariffResponse
         * @static
         * @param {api.IWorkspaceCancelCurrentTariffResponse} message WorkspaceCancelCurrentTariffResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceCancelCurrentTariffResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceCancelCurrentTariffResponse message, length delimited. Does not implicitly {@link api.WorkspaceCancelCurrentTariffResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceCancelCurrentTariffResponse
         * @static
         * @param {api.IWorkspaceCancelCurrentTariffResponse} message WorkspaceCancelCurrentTariffResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceCancelCurrentTariffResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceCancelCurrentTariffResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceCancelCurrentTariffResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceCancelCurrentTariffResponse} WorkspaceCancelCurrentTariffResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceCancelCurrentTariffResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceCancelCurrentTariffResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceCancelCurrentTariffResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceCancelCurrentTariffResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceCancelCurrentTariffResponse} WorkspaceCancelCurrentTariffResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceCancelCurrentTariffResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceCancelCurrentTariffResponse message.
         * @function verify
         * @memberof api.WorkspaceCancelCurrentTariffResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceCancelCurrentTariffResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates a WorkspaceCancelCurrentTariffResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceCancelCurrentTariffResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceCancelCurrentTariffResponse} WorkspaceCancelCurrentTariffResponse
         */
        WorkspaceCancelCurrentTariffResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceCancelCurrentTariffResponse)
                return object;
            let message = new $root.api.WorkspaceCancelCurrentTariffResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "WCT_UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "WCT_OK":
            case 1:
                message.status = 1;
                break;
            case "WCT_INTERNAL_ERROR":
            case 2:
                message.status = 2;
                break;
            case "WCT_NO_ACTIVE_TARIFF":
            case 3:
                message.status = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceCancelCurrentTariffResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceCancelCurrentTariffResponse
         * @static
         * @param {api.WorkspaceCancelCurrentTariffResponse} message WorkspaceCancelCurrentTariffResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceCancelCurrentTariffResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.status = options.enums === String ? "WCT_UNKNOWN" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.api.WorkspaceCancelCurrentTariffResponse.Status[message.status] === undefined ? message.status : $root.api.WorkspaceCancelCurrentTariffResponse.Status[message.status] : message.status;
            return object;
        };

        /**
         * Converts this WorkspaceCancelCurrentTariffResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceCancelCurrentTariffResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceCancelCurrentTariffResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceCancelCurrentTariffResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceCancelCurrentTariffResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceCancelCurrentTariffResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceCancelCurrentTariffResponse";
        };

        /**
         * Status enum.
         * @name api.WorkspaceCancelCurrentTariffResponse.Status
         * @enum {number}
         * @property {number} WCT_UNKNOWN=0 WCT_UNKNOWN value
         * @property {number} WCT_OK=1 WCT_OK value
         * @property {number} WCT_INTERNAL_ERROR=2 WCT_INTERNAL_ERROR value
         * @property {number} WCT_NO_ACTIVE_TARIFF=3 WCT_NO_ACTIVE_TARIFF value
         */
        WorkspaceCancelCurrentTariffResponse.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "WCT_UNKNOWN"] = 0;
            values[valuesById[1] = "WCT_OK"] = 1;
            values[valuesById[2] = "WCT_INTERNAL_ERROR"] = 2;
            values[valuesById[3] = "WCT_NO_ACTIVE_TARIFF"] = 3;
            return values;
        })();

        return WorkspaceCancelCurrentTariffResponse;
    })();

    api.WorkspaceResumeCurrentTariff = (function() {

        /**
         * Properties of a WorkspaceResumeCurrentTariff.
         * @memberof api
         * @interface IWorkspaceResumeCurrentTariff
         * @property {boolean|null} [fetch] WorkspaceResumeCurrentTariff fetch
         */

        /**
         * Constructs a new WorkspaceResumeCurrentTariff.
         * @memberof api
         * @classdesc Represents a WorkspaceResumeCurrentTariff.
         * @implements IWorkspaceResumeCurrentTariff
         * @constructor
         * @param {api.IWorkspaceResumeCurrentTariff=} [properties] Properties to set
         */
        function WorkspaceResumeCurrentTariff(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceResumeCurrentTariff fetch.
         * @member {boolean} fetch
         * @memberof api.WorkspaceResumeCurrentTariff
         * @instance
         */
        WorkspaceResumeCurrentTariff.prototype.fetch = false;

        /**
         * Creates a new WorkspaceResumeCurrentTariff instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceResumeCurrentTariff
         * @static
         * @param {api.IWorkspaceResumeCurrentTariff=} [properties] Properties to set
         * @returns {api.WorkspaceResumeCurrentTariff} WorkspaceResumeCurrentTariff instance
         */
        WorkspaceResumeCurrentTariff.create = function create(properties) {
            return new WorkspaceResumeCurrentTariff(properties);
        };

        /**
         * Encodes the specified WorkspaceResumeCurrentTariff message. Does not implicitly {@link api.WorkspaceResumeCurrentTariff.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceResumeCurrentTariff
         * @static
         * @param {api.IWorkspaceResumeCurrentTariff} message WorkspaceResumeCurrentTariff message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceResumeCurrentTariff.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fetch != null && Object.hasOwnProperty.call(message, "fetch"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.fetch);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceResumeCurrentTariff message, length delimited. Does not implicitly {@link api.WorkspaceResumeCurrentTariff.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceResumeCurrentTariff
         * @static
         * @param {api.IWorkspaceResumeCurrentTariff} message WorkspaceResumeCurrentTariff message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceResumeCurrentTariff.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceResumeCurrentTariff message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceResumeCurrentTariff
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceResumeCurrentTariff} WorkspaceResumeCurrentTariff
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceResumeCurrentTariff.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceResumeCurrentTariff();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fetch = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceResumeCurrentTariff message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceResumeCurrentTariff
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceResumeCurrentTariff} WorkspaceResumeCurrentTariff
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceResumeCurrentTariff.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceResumeCurrentTariff message.
         * @function verify
         * @memberof api.WorkspaceResumeCurrentTariff
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceResumeCurrentTariff.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                if (typeof message.fetch !== "boolean")
                    return "fetch: boolean expected";
            return null;
        };

        /**
         * Creates a WorkspaceResumeCurrentTariff message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceResumeCurrentTariff
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceResumeCurrentTariff} WorkspaceResumeCurrentTariff
         */
        WorkspaceResumeCurrentTariff.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceResumeCurrentTariff)
                return object;
            let message = new $root.api.WorkspaceResumeCurrentTariff();
            if (object.fetch != null)
                message.fetch = Boolean(object.fetch);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceResumeCurrentTariff message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceResumeCurrentTariff
         * @static
         * @param {api.WorkspaceResumeCurrentTariff} message WorkspaceResumeCurrentTariff
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceResumeCurrentTariff.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fetch = false;
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                object.fetch = message.fetch;
            return object;
        };

        /**
         * Converts this WorkspaceResumeCurrentTariff to JSON.
         * @function toJSON
         * @memberof api.WorkspaceResumeCurrentTariff
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceResumeCurrentTariff.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceResumeCurrentTariff
         * @function getTypeUrl
         * @memberof api.WorkspaceResumeCurrentTariff
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceResumeCurrentTariff.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceResumeCurrentTariff";
        };

        return WorkspaceResumeCurrentTariff;
    })();

    api.WorkspaceResumeCurrentTariffResponse = (function() {

        /**
         * Properties of a WorkspaceResumeCurrentTariffResponse.
         * @memberof api
         * @interface IWorkspaceResumeCurrentTariffResponse
         * @property {api.WorkspaceResumeCurrentTariffResponse.Status|null} [status] WorkspaceResumeCurrentTariffResponse status
         */

        /**
         * Constructs a new WorkspaceResumeCurrentTariffResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceResumeCurrentTariffResponse.
         * @implements IWorkspaceResumeCurrentTariffResponse
         * @constructor
         * @param {api.IWorkspaceResumeCurrentTariffResponse=} [properties] Properties to set
         */
        function WorkspaceResumeCurrentTariffResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceResumeCurrentTariffResponse status.
         * @member {api.WorkspaceResumeCurrentTariffResponse.Status} status
         * @memberof api.WorkspaceResumeCurrentTariffResponse
         * @instance
         */
        WorkspaceResumeCurrentTariffResponse.prototype.status = 0;

        /**
         * Creates a new WorkspaceResumeCurrentTariffResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceResumeCurrentTariffResponse
         * @static
         * @param {api.IWorkspaceResumeCurrentTariffResponse=} [properties] Properties to set
         * @returns {api.WorkspaceResumeCurrentTariffResponse} WorkspaceResumeCurrentTariffResponse instance
         */
        WorkspaceResumeCurrentTariffResponse.create = function create(properties) {
            return new WorkspaceResumeCurrentTariffResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceResumeCurrentTariffResponse message. Does not implicitly {@link api.WorkspaceResumeCurrentTariffResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceResumeCurrentTariffResponse
         * @static
         * @param {api.IWorkspaceResumeCurrentTariffResponse} message WorkspaceResumeCurrentTariffResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceResumeCurrentTariffResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceResumeCurrentTariffResponse message, length delimited. Does not implicitly {@link api.WorkspaceResumeCurrentTariffResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceResumeCurrentTariffResponse
         * @static
         * @param {api.IWorkspaceResumeCurrentTariffResponse} message WorkspaceResumeCurrentTariffResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceResumeCurrentTariffResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceResumeCurrentTariffResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceResumeCurrentTariffResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceResumeCurrentTariffResponse} WorkspaceResumeCurrentTariffResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceResumeCurrentTariffResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceResumeCurrentTariffResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceResumeCurrentTariffResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceResumeCurrentTariffResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceResumeCurrentTariffResponse} WorkspaceResumeCurrentTariffResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceResumeCurrentTariffResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceResumeCurrentTariffResponse message.
         * @function verify
         * @memberof api.WorkspaceResumeCurrentTariffResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceResumeCurrentTariffResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates a WorkspaceResumeCurrentTariffResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceResumeCurrentTariffResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceResumeCurrentTariffResponse} WorkspaceResumeCurrentTariffResponse
         */
        WorkspaceResumeCurrentTariffResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceResumeCurrentTariffResponse)
                return object;
            let message = new $root.api.WorkspaceResumeCurrentTariffResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "WRT_UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "WRT_OK":
            case 1:
                message.status = 1;
                break;
            case "WRT_INTERNAL_ERROR":
            case 2:
                message.status = 2;
                break;
            case "WRT_NO_ACTIVE_TARIFF":
            case 3:
                message.status = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceResumeCurrentTariffResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceResumeCurrentTariffResponse
         * @static
         * @param {api.WorkspaceResumeCurrentTariffResponse} message WorkspaceResumeCurrentTariffResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceResumeCurrentTariffResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.status = options.enums === String ? "WRT_UNKNOWN" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.api.WorkspaceResumeCurrentTariffResponse.Status[message.status] === undefined ? message.status : $root.api.WorkspaceResumeCurrentTariffResponse.Status[message.status] : message.status;
            return object;
        };

        /**
         * Converts this WorkspaceResumeCurrentTariffResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceResumeCurrentTariffResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceResumeCurrentTariffResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceResumeCurrentTariffResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceResumeCurrentTariffResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceResumeCurrentTariffResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceResumeCurrentTariffResponse";
        };

        /**
         * Status enum.
         * @name api.WorkspaceResumeCurrentTariffResponse.Status
         * @enum {number}
         * @property {number} WRT_UNKNOWN=0 WRT_UNKNOWN value
         * @property {number} WRT_OK=1 WRT_OK value
         * @property {number} WRT_INTERNAL_ERROR=2 WRT_INTERNAL_ERROR value
         * @property {number} WRT_NO_ACTIVE_TARIFF=3 WRT_NO_ACTIVE_TARIFF value
         */
        WorkspaceResumeCurrentTariffResponse.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "WRT_UNKNOWN"] = 0;
            values[valuesById[1] = "WRT_OK"] = 1;
            values[valuesById[2] = "WRT_INTERNAL_ERROR"] = 2;
            values[valuesById[3] = "WRT_NO_ACTIVE_TARIFF"] = 3;
            return values;
        })();

        return WorkspaceResumeCurrentTariffResponse;
    })();

    api.WorkspaceGetPaymentInvoiceRequest = (function() {

        /**
         * Properties of a WorkspaceGetPaymentInvoiceRequest.
         * @memberof api
         * @interface IWorkspaceGetPaymentInvoiceRequest
         * @property {string|null} [sessionID] WorkspaceGetPaymentInvoiceRequest sessionID
         */

        /**
         * Constructs a new WorkspaceGetPaymentInvoiceRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceGetPaymentInvoiceRequest.
         * @implements IWorkspaceGetPaymentInvoiceRequest
         * @constructor
         * @param {api.IWorkspaceGetPaymentInvoiceRequest=} [properties] Properties to set
         */
        function WorkspaceGetPaymentInvoiceRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceGetPaymentInvoiceRequest sessionID.
         * @member {string} sessionID
         * @memberof api.WorkspaceGetPaymentInvoiceRequest
         * @instance
         */
        WorkspaceGetPaymentInvoiceRequest.prototype.sessionID = "";

        /**
         * Creates a new WorkspaceGetPaymentInvoiceRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceGetPaymentInvoiceRequest
         * @static
         * @param {api.IWorkspaceGetPaymentInvoiceRequest=} [properties] Properties to set
         * @returns {api.WorkspaceGetPaymentInvoiceRequest} WorkspaceGetPaymentInvoiceRequest instance
         */
        WorkspaceGetPaymentInvoiceRequest.create = function create(properties) {
            return new WorkspaceGetPaymentInvoiceRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceGetPaymentInvoiceRequest message. Does not implicitly {@link api.WorkspaceGetPaymentInvoiceRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceGetPaymentInvoiceRequest
         * @static
         * @param {api.IWorkspaceGetPaymentInvoiceRequest} message WorkspaceGetPaymentInvoiceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetPaymentInvoiceRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionID != null && Object.hasOwnProperty.call(message, "sessionID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionID);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceGetPaymentInvoiceRequest message, length delimited. Does not implicitly {@link api.WorkspaceGetPaymentInvoiceRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceGetPaymentInvoiceRequest
         * @static
         * @param {api.IWorkspaceGetPaymentInvoiceRequest} message WorkspaceGetPaymentInvoiceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetPaymentInvoiceRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceGetPaymentInvoiceRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceGetPaymentInvoiceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceGetPaymentInvoiceRequest} WorkspaceGetPaymentInvoiceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetPaymentInvoiceRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceGetPaymentInvoiceRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionID = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceGetPaymentInvoiceRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceGetPaymentInvoiceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceGetPaymentInvoiceRequest} WorkspaceGetPaymentInvoiceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetPaymentInvoiceRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceGetPaymentInvoiceRequest message.
         * @function verify
         * @memberof api.WorkspaceGetPaymentInvoiceRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceGetPaymentInvoiceRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionID != null && message.hasOwnProperty("sessionID"))
                if (!$util.isString(message.sessionID))
                    return "sessionID: string expected";
            return null;
        };

        /**
         * Creates a WorkspaceGetPaymentInvoiceRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceGetPaymentInvoiceRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceGetPaymentInvoiceRequest} WorkspaceGetPaymentInvoiceRequest
         */
        WorkspaceGetPaymentInvoiceRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceGetPaymentInvoiceRequest)
                return object;
            let message = new $root.api.WorkspaceGetPaymentInvoiceRequest();
            if (object.sessionID != null)
                message.sessionID = String(object.sessionID);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceGetPaymentInvoiceRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceGetPaymentInvoiceRequest
         * @static
         * @param {api.WorkspaceGetPaymentInvoiceRequest} message WorkspaceGetPaymentInvoiceRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceGetPaymentInvoiceRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sessionID = "";
            if (message.sessionID != null && message.hasOwnProperty("sessionID"))
                object.sessionID = message.sessionID;
            return object;
        };

        /**
         * Converts this WorkspaceGetPaymentInvoiceRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceGetPaymentInvoiceRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceGetPaymentInvoiceRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceGetPaymentInvoiceRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceGetPaymentInvoiceRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceGetPaymentInvoiceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceGetPaymentInvoiceRequest";
        };

        return WorkspaceGetPaymentInvoiceRequest;
    })();

    api.WorkspaceGetPaymentInvoiceResponse = (function() {

        /**
         * Properties of a WorkspaceGetPaymentInvoiceResponse.
         * @memberof api
         * @interface IWorkspaceGetPaymentInvoiceResponse
         * @property {api.WorkspaceGetPaymentInvoiceResponse.Status|null} [status] WorkspaceGetPaymentInvoiceResponse status
         * @property {string|null} [downloadLink] WorkspaceGetPaymentInvoiceResponse downloadLink
         */

        /**
         * Constructs a new WorkspaceGetPaymentInvoiceResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceGetPaymentInvoiceResponse.
         * @implements IWorkspaceGetPaymentInvoiceResponse
         * @constructor
         * @param {api.IWorkspaceGetPaymentInvoiceResponse=} [properties] Properties to set
         */
        function WorkspaceGetPaymentInvoiceResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceGetPaymentInvoiceResponse status.
         * @member {api.WorkspaceGetPaymentInvoiceResponse.Status} status
         * @memberof api.WorkspaceGetPaymentInvoiceResponse
         * @instance
         */
        WorkspaceGetPaymentInvoiceResponse.prototype.status = 0;

        /**
         * WorkspaceGetPaymentInvoiceResponse downloadLink.
         * @member {string} downloadLink
         * @memberof api.WorkspaceGetPaymentInvoiceResponse
         * @instance
         */
        WorkspaceGetPaymentInvoiceResponse.prototype.downloadLink = "";

        /**
         * Creates a new WorkspaceGetPaymentInvoiceResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceGetPaymentInvoiceResponse
         * @static
         * @param {api.IWorkspaceGetPaymentInvoiceResponse=} [properties] Properties to set
         * @returns {api.WorkspaceGetPaymentInvoiceResponse} WorkspaceGetPaymentInvoiceResponse instance
         */
        WorkspaceGetPaymentInvoiceResponse.create = function create(properties) {
            return new WorkspaceGetPaymentInvoiceResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceGetPaymentInvoiceResponse message. Does not implicitly {@link api.WorkspaceGetPaymentInvoiceResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceGetPaymentInvoiceResponse
         * @static
         * @param {api.IWorkspaceGetPaymentInvoiceResponse} message WorkspaceGetPaymentInvoiceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetPaymentInvoiceResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.downloadLink != null && Object.hasOwnProperty.call(message, "downloadLink"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.downloadLink);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceGetPaymentInvoiceResponse message, length delimited. Does not implicitly {@link api.WorkspaceGetPaymentInvoiceResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceGetPaymentInvoiceResponse
         * @static
         * @param {api.IWorkspaceGetPaymentInvoiceResponse} message WorkspaceGetPaymentInvoiceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetPaymentInvoiceResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceGetPaymentInvoiceResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceGetPaymentInvoiceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceGetPaymentInvoiceResponse} WorkspaceGetPaymentInvoiceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetPaymentInvoiceResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceGetPaymentInvoiceResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.downloadLink = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceGetPaymentInvoiceResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceGetPaymentInvoiceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceGetPaymentInvoiceResponse} WorkspaceGetPaymentInvoiceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetPaymentInvoiceResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceGetPaymentInvoiceResponse message.
         * @function verify
         * @memberof api.WorkspaceGetPaymentInvoiceResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceGetPaymentInvoiceResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.downloadLink != null && message.hasOwnProperty("downloadLink"))
                if (!$util.isString(message.downloadLink))
                    return "downloadLink: string expected";
            return null;
        };

        /**
         * Creates a WorkspaceGetPaymentInvoiceResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceGetPaymentInvoiceResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceGetPaymentInvoiceResponse} WorkspaceGetPaymentInvoiceResponse
         */
        WorkspaceGetPaymentInvoiceResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceGetPaymentInvoiceResponse)
                return object;
            let message = new $root.api.WorkspaceGetPaymentInvoiceResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "GPI_UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "GPI_OK":
            case 1:
                message.status = 1;
                break;
            case "GPI_SESSION_NOT_FOUND":
            case 2:
                message.status = 2;
                break;
            case "GPI_INTERNAL_ERROR":
            case 3:
                message.status = 3;
                break;
            }
            if (object.downloadLink != null)
                message.downloadLink = String(object.downloadLink);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceGetPaymentInvoiceResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceGetPaymentInvoiceResponse
         * @static
         * @param {api.WorkspaceGetPaymentInvoiceResponse} message WorkspaceGetPaymentInvoiceResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceGetPaymentInvoiceResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "GPI_UNKNOWN" : 0;
                object.downloadLink = "";
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.api.WorkspaceGetPaymentInvoiceResponse.Status[message.status] === undefined ? message.status : $root.api.WorkspaceGetPaymentInvoiceResponse.Status[message.status] : message.status;
            if (message.downloadLink != null && message.hasOwnProperty("downloadLink"))
                object.downloadLink = message.downloadLink;
            return object;
        };

        /**
         * Converts this WorkspaceGetPaymentInvoiceResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceGetPaymentInvoiceResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceGetPaymentInvoiceResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceGetPaymentInvoiceResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceGetPaymentInvoiceResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceGetPaymentInvoiceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceGetPaymentInvoiceResponse";
        };

        /**
         * Status enum.
         * @name api.WorkspaceGetPaymentInvoiceResponse.Status
         * @enum {number}
         * @property {number} GPI_UNKNOWN=0 GPI_UNKNOWN value
         * @property {number} GPI_OK=1 GPI_OK value
         * @property {number} GPI_SESSION_NOT_FOUND=2 GPI_SESSION_NOT_FOUND value
         * @property {number} GPI_INTERNAL_ERROR=3 GPI_INTERNAL_ERROR value
         */
        WorkspaceGetPaymentInvoiceResponse.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "GPI_UNKNOWN"] = 0;
            values[valuesById[1] = "GPI_OK"] = 1;
            values[valuesById[2] = "GPI_SESSION_NOT_FOUND"] = 2;
            values[valuesById[3] = "GPI_INTERNAL_ERROR"] = 3;
            return values;
        })();

        return WorkspaceGetPaymentInvoiceResponse;
    })();

    api.WorkspaceGetCustomerPortalRequest = (function() {

        /**
         * Properties of a WorkspaceGetCustomerPortalRequest.
         * @memberof api
         * @interface IWorkspaceGetCustomerPortalRequest
         * @property {boolean|null} [fetch] WorkspaceGetCustomerPortalRequest fetch
         */

        /**
         * Constructs a new WorkspaceGetCustomerPortalRequest.
         * @memberof api
         * @classdesc Represents a WorkspaceGetCustomerPortalRequest.
         * @implements IWorkspaceGetCustomerPortalRequest
         * @constructor
         * @param {api.IWorkspaceGetCustomerPortalRequest=} [properties] Properties to set
         */
        function WorkspaceGetCustomerPortalRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceGetCustomerPortalRequest fetch.
         * @member {boolean} fetch
         * @memberof api.WorkspaceGetCustomerPortalRequest
         * @instance
         */
        WorkspaceGetCustomerPortalRequest.prototype.fetch = false;

        /**
         * Creates a new WorkspaceGetCustomerPortalRequest instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceGetCustomerPortalRequest
         * @static
         * @param {api.IWorkspaceGetCustomerPortalRequest=} [properties] Properties to set
         * @returns {api.WorkspaceGetCustomerPortalRequest} WorkspaceGetCustomerPortalRequest instance
         */
        WorkspaceGetCustomerPortalRequest.create = function create(properties) {
            return new WorkspaceGetCustomerPortalRequest(properties);
        };

        /**
         * Encodes the specified WorkspaceGetCustomerPortalRequest message. Does not implicitly {@link api.WorkspaceGetCustomerPortalRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceGetCustomerPortalRequest
         * @static
         * @param {api.IWorkspaceGetCustomerPortalRequest} message WorkspaceGetCustomerPortalRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetCustomerPortalRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fetch != null && Object.hasOwnProperty.call(message, "fetch"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.fetch);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceGetCustomerPortalRequest message, length delimited. Does not implicitly {@link api.WorkspaceGetCustomerPortalRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceGetCustomerPortalRequest
         * @static
         * @param {api.IWorkspaceGetCustomerPortalRequest} message WorkspaceGetCustomerPortalRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetCustomerPortalRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceGetCustomerPortalRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceGetCustomerPortalRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceGetCustomerPortalRequest} WorkspaceGetCustomerPortalRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetCustomerPortalRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceGetCustomerPortalRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fetch = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceGetCustomerPortalRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceGetCustomerPortalRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceGetCustomerPortalRequest} WorkspaceGetCustomerPortalRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetCustomerPortalRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceGetCustomerPortalRequest message.
         * @function verify
         * @memberof api.WorkspaceGetCustomerPortalRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceGetCustomerPortalRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                if (typeof message.fetch !== "boolean")
                    return "fetch: boolean expected";
            return null;
        };

        /**
         * Creates a WorkspaceGetCustomerPortalRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceGetCustomerPortalRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceGetCustomerPortalRequest} WorkspaceGetCustomerPortalRequest
         */
        WorkspaceGetCustomerPortalRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceGetCustomerPortalRequest)
                return object;
            let message = new $root.api.WorkspaceGetCustomerPortalRequest();
            if (object.fetch != null)
                message.fetch = Boolean(object.fetch);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceGetCustomerPortalRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceGetCustomerPortalRequest
         * @static
         * @param {api.WorkspaceGetCustomerPortalRequest} message WorkspaceGetCustomerPortalRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceGetCustomerPortalRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fetch = false;
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                object.fetch = message.fetch;
            return object;
        };

        /**
         * Converts this WorkspaceGetCustomerPortalRequest to JSON.
         * @function toJSON
         * @memberof api.WorkspaceGetCustomerPortalRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceGetCustomerPortalRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceGetCustomerPortalRequest
         * @function getTypeUrl
         * @memberof api.WorkspaceGetCustomerPortalRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceGetCustomerPortalRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceGetCustomerPortalRequest";
        };

        return WorkspaceGetCustomerPortalRequest;
    })();

    api.WorkspaceGetCustomerPortalResponse = (function() {

        /**
         * Properties of a WorkspaceGetCustomerPortalResponse.
         * @memberof api
         * @interface IWorkspaceGetCustomerPortalResponse
         * @property {api.WorkspaceGetCustomerPortalResponse.Status|null} [status] WorkspaceGetCustomerPortalResponse status
         * @property {string|null} [link] WorkspaceGetCustomerPortalResponse link
         */

        /**
         * Constructs a new WorkspaceGetCustomerPortalResponse.
         * @memberof api
         * @classdesc Represents a WorkspaceGetCustomerPortalResponse.
         * @implements IWorkspaceGetCustomerPortalResponse
         * @constructor
         * @param {api.IWorkspaceGetCustomerPortalResponse=} [properties] Properties to set
         */
        function WorkspaceGetCustomerPortalResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceGetCustomerPortalResponse status.
         * @member {api.WorkspaceGetCustomerPortalResponse.Status} status
         * @memberof api.WorkspaceGetCustomerPortalResponse
         * @instance
         */
        WorkspaceGetCustomerPortalResponse.prototype.status = 0;

        /**
         * WorkspaceGetCustomerPortalResponse link.
         * @member {string} link
         * @memberof api.WorkspaceGetCustomerPortalResponse
         * @instance
         */
        WorkspaceGetCustomerPortalResponse.prototype.link = "";

        /**
         * Creates a new WorkspaceGetCustomerPortalResponse instance using the specified properties.
         * @function create
         * @memberof api.WorkspaceGetCustomerPortalResponse
         * @static
         * @param {api.IWorkspaceGetCustomerPortalResponse=} [properties] Properties to set
         * @returns {api.WorkspaceGetCustomerPortalResponse} WorkspaceGetCustomerPortalResponse instance
         */
        WorkspaceGetCustomerPortalResponse.create = function create(properties) {
            return new WorkspaceGetCustomerPortalResponse(properties);
        };

        /**
         * Encodes the specified WorkspaceGetCustomerPortalResponse message. Does not implicitly {@link api.WorkspaceGetCustomerPortalResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WorkspaceGetCustomerPortalResponse
         * @static
         * @param {api.IWorkspaceGetCustomerPortalResponse} message WorkspaceGetCustomerPortalResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetCustomerPortalResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.link != null && Object.hasOwnProperty.call(message, "link"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.link);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceGetCustomerPortalResponse message, length delimited. Does not implicitly {@link api.WorkspaceGetCustomerPortalResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WorkspaceGetCustomerPortalResponse
         * @static
         * @param {api.IWorkspaceGetCustomerPortalResponse} message WorkspaceGetCustomerPortalResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceGetCustomerPortalResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceGetCustomerPortalResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WorkspaceGetCustomerPortalResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WorkspaceGetCustomerPortalResponse} WorkspaceGetCustomerPortalResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetCustomerPortalResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WorkspaceGetCustomerPortalResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.link = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceGetCustomerPortalResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WorkspaceGetCustomerPortalResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WorkspaceGetCustomerPortalResponse} WorkspaceGetCustomerPortalResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceGetCustomerPortalResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceGetCustomerPortalResponse message.
         * @function verify
         * @memberof api.WorkspaceGetCustomerPortalResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceGetCustomerPortalResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.link != null && message.hasOwnProperty("link"))
                if (!$util.isString(message.link))
                    return "link: string expected";
            return null;
        };

        /**
         * Creates a WorkspaceGetCustomerPortalResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WorkspaceGetCustomerPortalResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WorkspaceGetCustomerPortalResponse} WorkspaceGetCustomerPortalResponse
         */
        WorkspaceGetCustomerPortalResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WorkspaceGetCustomerPortalResponse)
                return object;
            let message = new $root.api.WorkspaceGetCustomerPortalResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "GCP_UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "GCP_OK":
            case 1:
                message.status = 1;
                break;
            }
            if (object.link != null)
                message.link = String(object.link);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceGetCustomerPortalResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WorkspaceGetCustomerPortalResponse
         * @static
         * @param {api.WorkspaceGetCustomerPortalResponse} message WorkspaceGetCustomerPortalResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceGetCustomerPortalResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "GCP_UNKNOWN" : 0;
                object.link = "";
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.api.WorkspaceGetCustomerPortalResponse.Status[message.status] === undefined ? message.status : $root.api.WorkspaceGetCustomerPortalResponse.Status[message.status] : message.status;
            if (message.link != null && message.hasOwnProperty("link"))
                object.link = message.link;
            return object;
        };

        /**
         * Converts this WorkspaceGetCustomerPortalResponse to JSON.
         * @function toJSON
         * @memberof api.WorkspaceGetCustomerPortalResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceGetCustomerPortalResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceGetCustomerPortalResponse
         * @function getTypeUrl
         * @memberof api.WorkspaceGetCustomerPortalResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceGetCustomerPortalResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WorkspaceGetCustomerPortalResponse";
        };

        /**
         * Status enum.
         * @name api.WorkspaceGetCustomerPortalResponse.Status
         * @enum {number}
         * @property {number} GCP_UNKNOWN=0 GCP_UNKNOWN value
         * @property {number} GCP_OK=1 GCP_OK value
         */
        WorkspaceGetCustomerPortalResponse.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "GCP_UNKNOWN"] = 0;
            values[valuesById[1] = "GCP_OK"] = 1;
            return values;
        })();

        return WorkspaceGetCustomerPortalResponse;
    })();

    api.ChannelsRequest = (function() {

        /**
         * Properties of a ChannelsRequest.
         * @memberof api
         * @interface IChannelsRequest
         * @property {Uint8Array|null} [workspaceId] ChannelsRequest workspaceId
         * @property {IMCMethod|null} [action] ChannelsRequest action
         * @property {api.IChannelsUpdatesRequest|null} [updates] ChannelsRequest updates
         */

        /**
         * Constructs a new ChannelsRequest.
         * @memberof api
         * @classdesc Represents a ChannelsRequest.
         * @implements IChannelsRequest
         * @constructor
         * @param {api.IChannelsRequest=} [properties] Properties to set
         */
        function ChannelsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelsRequest workspaceId.
         * @member {Uint8Array} workspaceId
         * @memberof api.ChannelsRequest
         * @instance
         */
        ChannelsRequest.prototype.workspaceId = $util.newBuffer([]);

        /**
         * ChannelsRequest action.
         * @member {IMCMethod|null|undefined} action
         * @memberof api.ChannelsRequest
         * @instance
         */
        ChannelsRequest.prototype.action = null;

        /**
         * ChannelsRequest updates.
         * @member {api.IChannelsUpdatesRequest|null|undefined} updates
         * @memberof api.ChannelsRequest
         * @instance
         */
        ChannelsRequest.prototype.updates = null;

        /**
         * Creates a new ChannelsRequest instance using the specified properties.
         * @function create
         * @memberof api.ChannelsRequest
         * @static
         * @param {api.IChannelsRequest=} [properties] Properties to set
         * @returns {api.ChannelsRequest} ChannelsRequest instance
         */
        ChannelsRequest.create = function create(properties) {
            return new ChannelsRequest(properties);
        };

        /**
         * Encodes the specified ChannelsRequest message. Does not implicitly {@link api.ChannelsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.ChannelsRequest
         * @static
         * @param {api.IChannelsRequest} message ChannelsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.workspaceId != null && Object.hasOwnProperty.call(message, "workspaceId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.workspaceId);
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                $root.MCMethod.encode(message.action, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.updates != null && Object.hasOwnProperty.call(message, "updates"))
                $root.api.ChannelsUpdatesRequest.encode(message.updates, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChannelsRequest message, length delimited. Does not implicitly {@link api.ChannelsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ChannelsRequest
         * @static
         * @param {api.IChannelsRequest} message ChannelsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.ChannelsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ChannelsRequest} ChannelsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ChannelsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.workspaceId = reader.bytes();
                        break;
                    }
                case 10: {
                        message.action = $root.MCMethod.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.updates = $root.api.ChannelsUpdatesRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ChannelsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ChannelsRequest} ChannelsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelsRequest message.
         * @function verify
         * @memberof api.ChannelsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                if (!(message.workspaceId && typeof message.workspaceId.length === "number" || $util.isString(message.workspaceId)))
                    return "workspaceId: buffer expected";
            if (message.action != null && message.hasOwnProperty("action")) {
                let error = $root.MCMethod.verify(message.action);
                if (error)
                    return "action." + error;
            }
            if (message.updates != null && message.hasOwnProperty("updates")) {
                let error = $root.api.ChannelsUpdatesRequest.verify(message.updates);
                if (error)
                    return "updates." + error;
            }
            return null;
        };

        /**
         * Creates a ChannelsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ChannelsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ChannelsRequest} ChannelsRequest
         */
        ChannelsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ChannelsRequest)
                return object;
            let message = new $root.api.ChannelsRequest();
            if (object.workspaceId != null)
                if (typeof object.workspaceId === "string")
                    $util.base64.decode(object.workspaceId, message.workspaceId = $util.newBuffer($util.base64.length(object.workspaceId)), 0);
                else if (object.workspaceId.length >= 0)
                    message.workspaceId = object.workspaceId;
            if (object.action != null) {
                if (typeof object.action !== "object")
                    throw TypeError(".api.ChannelsRequest.action: object expected");
                message.action = $root.MCMethod.fromObject(object.action);
            }
            if (object.updates != null) {
                if (typeof object.updates !== "object")
                    throw TypeError(".api.ChannelsRequest.updates: object expected");
                message.updates = $root.api.ChannelsUpdatesRequest.fromObject(object.updates);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ChannelsRequest
         * @static
         * @param {api.ChannelsRequest} message ChannelsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.workspaceId = "";
                else {
                    object.workspaceId = [];
                    if (options.bytes !== Array)
                        object.workspaceId = $util.newBuffer(object.workspaceId);
                }
                object.action = null;
                object.updates = null;
            }
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                object.workspaceId = options.bytes === String ? $util.base64.encode(message.workspaceId, 0, message.workspaceId.length) : options.bytes === Array ? Array.prototype.slice.call(message.workspaceId) : message.workspaceId;
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = $root.MCMethod.toObject(message.action, options);
            if (message.updates != null && message.hasOwnProperty("updates"))
                object.updates = $root.api.ChannelsUpdatesRequest.toObject(message.updates, options);
            return object;
        };

        /**
         * Converts this ChannelsRequest to JSON.
         * @function toJSON
         * @memberof api.ChannelsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelsRequest
         * @function getTypeUrl
         * @memberof api.ChannelsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ChannelsRequest";
        };

        return ChannelsRequest;
    })();

    api.ChannelsResponse = (function() {

        /**
         * Properties of a ChannelsResponse.
         * @memberof api
         * @interface IChannelsResponse
         * @property {IMCMethodResponse|null} [methodResponse] ChannelsResponse methodResponse
         * @property {api.IChannelsUpdatesResponse|null} [updates] ChannelsResponse updates
         */

        /**
         * Constructs a new ChannelsResponse.
         * @memberof api
         * @classdesc Represents a ChannelsResponse.
         * @implements IChannelsResponse
         * @constructor
         * @param {api.IChannelsResponse=} [properties] Properties to set
         */
        function ChannelsResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelsResponse methodResponse.
         * @member {IMCMethodResponse|null|undefined} methodResponse
         * @memberof api.ChannelsResponse
         * @instance
         */
        ChannelsResponse.prototype.methodResponse = null;

        /**
         * ChannelsResponse updates.
         * @member {api.IChannelsUpdatesResponse|null|undefined} updates
         * @memberof api.ChannelsResponse
         * @instance
         */
        ChannelsResponse.prototype.updates = null;

        /**
         * Creates a new ChannelsResponse instance using the specified properties.
         * @function create
         * @memberof api.ChannelsResponse
         * @static
         * @param {api.IChannelsResponse=} [properties] Properties to set
         * @returns {api.ChannelsResponse} ChannelsResponse instance
         */
        ChannelsResponse.create = function create(properties) {
            return new ChannelsResponse(properties);
        };

        /**
         * Encodes the specified ChannelsResponse message. Does not implicitly {@link api.ChannelsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.ChannelsResponse
         * @static
         * @param {api.IChannelsResponse} message ChannelsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.methodResponse != null && Object.hasOwnProperty.call(message, "methodResponse"))
                $root.MCMethodResponse.encode(message.methodResponse, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.updates != null && Object.hasOwnProperty.call(message, "updates"))
                $root.api.ChannelsUpdatesResponse.encode(message.updates, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChannelsResponse message, length delimited. Does not implicitly {@link api.ChannelsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ChannelsResponse
         * @static
         * @param {api.IChannelsResponse} message ChannelsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.ChannelsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ChannelsResponse} ChannelsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ChannelsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 10: {
                        message.methodResponse = $root.MCMethodResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.updates = $root.api.ChannelsUpdatesResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ChannelsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ChannelsResponse} ChannelsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelsResponse message.
         * @function verify
         * @memberof api.ChannelsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.methodResponse != null && message.hasOwnProperty("methodResponse")) {
                let error = $root.MCMethodResponse.verify(message.methodResponse);
                if (error)
                    return "methodResponse." + error;
            }
            if (message.updates != null && message.hasOwnProperty("updates")) {
                let error = $root.api.ChannelsUpdatesResponse.verify(message.updates);
                if (error)
                    return "updates." + error;
            }
            return null;
        };

        /**
         * Creates a ChannelsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ChannelsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ChannelsResponse} ChannelsResponse
         */
        ChannelsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ChannelsResponse)
                return object;
            let message = new $root.api.ChannelsResponse();
            if (object.methodResponse != null) {
                if (typeof object.methodResponse !== "object")
                    throw TypeError(".api.ChannelsResponse.methodResponse: object expected");
                message.methodResponse = $root.MCMethodResponse.fromObject(object.methodResponse);
            }
            if (object.updates != null) {
                if (typeof object.updates !== "object")
                    throw TypeError(".api.ChannelsResponse.updates: object expected");
                message.updates = $root.api.ChannelsUpdatesResponse.fromObject(object.updates);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ChannelsResponse
         * @static
         * @param {api.ChannelsResponse} message ChannelsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.methodResponse = null;
                object.updates = null;
            }
            if (message.methodResponse != null && message.hasOwnProperty("methodResponse"))
                object.methodResponse = $root.MCMethodResponse.toObject(message.methodResponse, options);
            if (message.updates != null && message.hasOwnProperty("updates"))
                object.updates = $root.api.ChannelsUpdatesResponse.toObject(message.updates, options);
            return object;
        };

        /**
         * Converts this ChannelsResponse to JSON.
         * @function toJSON
         * @memberof api.ChannelsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelsResponse
         * @function getTypeUrl
         * @memberof api.ChannelsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ChannelsResponse";
        };

        return ChannelsResponse;
    })();

    api.ChannelsUpdatesRequest = (function() {

        /**
         * Properties of a ChannelsUpdatesRequest.
         * @memberof api
         * @interface IChannelsUpdatesRequest
         * @property {Array.<entities.IChannelVersion>|null} [versions] ChannelsUpdatesRequest versions
         */

        /**
         * Constructs a new ChannelsUpdatesRequest.
         * @memberof api
         * @classdesc Represents a ChannelsUpdatesRequest.
         * @implements IChannelsUpdatesRequest
         * @constructor
         * @param {api.IChannelsUpdatesRequest=} [properties] Properties to set
         */
        function ChannelsUpdatesRequest(properties) {
            this.versions = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelsUpdatesRequest versions.
         * @member {Array.<entities.IChannelVersion>} versions
         * @memberof api.ChannelsUpdatesRequest
         * @instance
         */
        ChannelsUpdatesRequest.prototype.versions = $util.emptyArray;

        /**
         * Creates a new ChannelsUpdatesRequest instance using the specified properties.
         * @function create
         * @memberof api.ChannelsUpdatesRequest
         * @static
         * @param {api.IChannelsUpdatesRequest=} [properties] Properties to set
         * @returns {api.ChannelsUpdatesRequest} ChannelsUpdatesRequest instance
         */
        ChannelsUpdatesRequest.create = function create(properties) {
            return new ChannelsUpdatesRequest(properties);
        };

        /**
         * Encodes the specified ChannelsUpdatesRequest message. Does not implicitly {@link api.ChannelsUpdatesRequest.verify|verify} messages.
         * @function encode
         * @memberof api.ChannelsUpdatesRequest
         * @static
         * @param {api.IChannelsUpdatesRequest} message ChannelsUpdatesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelsUpdatesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.versions != null && message.versions.length)
                for (let i = 0; i < message.versions.length; ++i)
                    $root.entities.ChannelVersion.encode(message.versions[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChannelsUpdatesRequest message, length delimited. Does not implicitly {@link api.ChannelsUpdatesRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ChannelsUpdatesRequest
         * @static
         * @param {api.IChannelsUpdatesRequest} message ChannelsUpdatesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelsUpdatesRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelsUpdatesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.ChannelsUpdatesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ChannelsUpdatesRequest} ChannelsUpdatesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelsUpdatesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ChannelsUpdatesRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 10: {
                        if (!(message.versions && message.versions.length))
                            message.versions = [];
                        message.versions.push($root.entities.ChannelVersion.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelsUpdatesRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ChannelsUpdatesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ChannelsUpdatesRequest} ChannelsUpdatesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelsUpdatesRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelsUpdatesRequest message.
         * @function verify
         * @memberof api.ChannelsUpdatesRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelsUpdatesRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.versions != null && message.hasOwnProperty("versions")) {
                if (!Array.isArray(message.versions))
                    return "versions: array expected";
                for (let i = 0; i < message.versions.length; ++i) {
                    let error = $root.entities.ChannelVersion.verify(message.versions[i]);
                    if (error)
                        return "versions." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ChannelsUpdatesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ChannelsUpdatesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ChannelsUpdatesRequest} ChannelsUpdatesRequest
         */
        ChannelsUpdatesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ChannelsUpdatesRequest)
                return object;
            let message = new $root.api.ChannelsUpdatesRequest();
            if (object.versions) {
                if (!Array.isArray(object.versions))
                    throw TypeError(".api.ChannelsUpdatesRequest.versions: array expected");
                message.versions = [];
                for (let i = 0; i < object.versions.length; ++i) {
                    if (typeof object.versions[i] !== "object")
                        throw TypeError(".api.ChannelsUpdatesRequest.versions: object expected");
                    message.versions[i] = $root.entities.ChannelVersion.fromObject(object.versions[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelsUpdatesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ChannelsUpdatesRequest
         * @static
         * @param {api.ChannelsUpdatesRequest} message ChannelsUpdatesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelsUpdatesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.versions = [];
            if (message.versions && message.versions.length) {
                object.versions = [];
                for (let j = 0; j < message.versions.length; ++j)
                    object.versions[j] = $root.entities.ChannelVersion.toObject(message.versions[j], options);
            }
            return object;
        };

        /**
         * Converts this ChannelsUpdatesRequest to JSON.
         * @function toJSON
         * @memberof api.ChannelsUpdatesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelsUpdatesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelsUpdatesRequest
         * @function getTypeUrl
         * @memberof api.ChannelsUpdatesRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelsUpdatesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ChannelsUpdatesRequest";
        };

        return ChannelsUpdatesRequest;
    })();

    api.ChannelUpdate = (function() {

        /**
         * Properties of a ChannelUpdate.
         * @memberof api
         * @interface IChannelUpdate
         * @property {Long|null} [version] ChannelUpdate version
         * @property {IUpdate|null} [update] ChannelUpdate update
         */

        /**
         * Constructs a new ChannelUpdate.
         * @memberof api
         * @classdesc Represents a ChannelUpdate.
         * @implements IChannelUpdate
         * @constructor
         * @param {api.IChannelUpdate=} [properties] Properties to set
         */
        function ChannelUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelUpdate version.
         * @member {Long} version
         * @memberof api.ChannelUpdate
         * @instance
         */
        ChannelUpdate.prototype.version = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelUpdate update.
         * @member {IUpdate|null|undefined} update
         * @memberof api.ChannelUpdate
         * @instance
         */
        ChannelUpdate.prototype.update = null;

        /**
         * Creates a new ChannelUpdate instance using the specified properties.
         * @function create
         * @memberof api.ChannelUpdate
         * @static
         * @param {api.IChannelUpdate=} [properties] Properties to set
         * @returns {api.ChannelUpdate} ChannelUpdate instance
         */
        ChannelUpdate.create = function create(properties) {
            return new ChannelUpdate(properties);
        };

        /**
         * Encodes the specified ChannelUpdate message. Does not implicitly {@link api.ChannelUpdate.verify|verify} messages.
         * @function encode
         * @memberof api.ChannelUpdate
         * @static
         * @param {api.IChannelUpdate} message ChannelUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.version);
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.Update.encode(message.update, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChannelUpdate message, length delimited. Does not implicitly {@link api.ChannelUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ChannelUpdate
         * @static
         * @param {api.IChannelUpdate} message ChannelUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof api.ChannelUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ChannelUpdate} ChannelUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ChannelUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.version = reader.uint64();
                        break;
                    }
                case 2: {
                        message.update = $root.Update.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ChannelUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ChannelUpdate} ChannelUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelUpdate message.
         * @function verify
         * @memberof api.ChannelUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isInteger(message.version) && !(message.version && $util.isInteger(message.version.low) && $util.isInteger(message.version.high)))
                    return "version: integer|Long expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.Update.verify(message.update);
                if (error)
                    return "update." + error;
            }
            return null;
        };

        /**
         * Creates a ChannelUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ChannelUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ChannelUpdate} ChannelUpdate
         */
        ChannelUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ChannelUpdate)
                return object;
            let message = new $root.api.ChannelUpdate();
            if (object.version != null)
                if ($util.Long)
                    (message.version = $util.Long.fromValue(object.version)).unsigned = true;
                else if (typeof object.version === "string")
                    message.version = parseInt(object.version, 10);
                else if (typeof object.version === "number")
                    message.version = object.version;
                else if (typeof object.version === "object")
                    message.version = new $util.LongBits(object.version.low >>> 0, object.version.high >>> 0).toNumber(true);
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.ChannelUpdate.update: object expected");
                message.update = $root.Update.fromObject(object.update);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ChannelUpdate
         * @static
         * @param {api.ChannelUpdate} message ChannelUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.version = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.version = options.longs === String ? "0" : 0;
                object.update = null;
            }
            if (message.version != null && message.hasOwnProperty("version"))
                if (typeof message.version === "number")
                    object.version = options.longs === String ? String(message.version) : message.version;
                else
                    object.version = options.longs === String ? $util.Long.prototype.toString.call(message.version) : options.longs === Number ? new $util.LongBits(message.version.low >>> 0, message.version.high >>> 0).toNumber(true) : message.version;
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.Update.toObject(message.update, options);
            return object;
        };

        /**
         * Converts this ChannelUpdate to JSON.
         * @function toJSON
         * @memberof api.ChannelUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelUpdate
         * @function getTypeUrl
         * @memberof api.ChannelUpdate
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ChannelUpdate";
        };

        return ChannelUpdate;
    })();

    api.ChannelsUpdatesResponse = (function() {

        /**
         * Properties of a ChannelsUpdatesResponse.
         * @memberof api
         * @interface IChannelsUpdatesResponse
         * @property {api.ChannelsUpdatesResponse.Status|null} [status] ChannelsUpdatesResponse status
         * @property {Long|null} [nextRequestAfterMs] ChannelsUpdatesResponse nextRequestAfterMs
         * @property {Array.<api.IChannelUpdate>|null} [updates] ChannelsUpdatesResponse updates
         */

        /**
         * Constructs a new ChannelsUpdatesResponse.
         * @memberof api
         * @classdesc Represents a ChannelsUpdatesResponse.
         * @implements IChannelsUpdatesResponse
         * @constructor
         * @param {api.IChannelsUpdatesResponse=} [properties] Properties to set
         */
        function ChannelsUpdatesResponse(properties) {
            this.updates = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelsUpdatesResponse status.
         * @member {api.ChannelsUpdatesResponse.Status} status
         * @memberof api.ChannelsUpdatesResponse
         * @instance
         */
        ChannelsUpdatesResponse.prototype.status = 0;

        /**
         * ChannelsUpdatesResponse nextRequestAfterMs.
         * @member {Long} nextRequestAfterMs
         * @memberof api.ChannelsUpdatesResponse
         * @instance
         */
        ChannelsUpdatesResponse.prototype.nextRequestAfterMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelsUpdatesResponse updates.
         * @member {Array.<api.IChannelUpdate>} updates
         * @memberof api.ChannelsUpdatesResponse
         * @instance
         */
        ChannelsUpdatesResponse.prototype.updates = $util.emptyArray;

        /**
         * Creates a new ChannelsUpdatesResponse instance using the specified properties.
         * @function create
         * @memberof api.ChannelsUpdatesResponse
         * @static
         * @param {api.IChannelsUpdatesResponse=} [properties] Properties to set
         * @returns {api.ChannelsUpdatesResponse} ChannelsUpdatesResponse instance
         */
        ChannelsUpdatesResponse.create = function create(properties) {
            return new ChannelsUpdatesResponse(properties);
        };

        /**
         * Encodes the specified ChannelsUpdatesResponse message. Does not implicitly {@link api.ChannelsUpdatesResponse.verify|verify} messages.
         * @function encode
         * @memberof api.ChannelsUpdatesResponse
         * @static
         * @param {api.IChannelsUpdatesResponse} message ChannelsUpdatesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelsUpdatesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.nextRequestAfterMs != null && Object.hasOwnProperty.call(message, "nextRequestAfterMs"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.nextRequestAfterMs);
            if (message.updates != null && message.updates.length)
                for (let i = 0; i < message.updates.length; ++i)
                    $root.api.ChannelUpdate.encode(message.updates[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChannelsUpdatesResponse message, length delimited. Does not implicitly {@link api.ChannelsUpdatesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ChannelsUpdatesResponse
         * @static
         * @param {api.IChannelsUpdatesResponse} message ChannelsUpdatesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelsUpdatesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelsUpdatesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.ChannelsUpdatesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ChannelsUpdatesResponse} ChannelsUpdatesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelsUpdatesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ChannelsUpdatesResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.nextRequestAfterMs = reader.uint64();
                        break;
                    }
                case 10: {
                        if (!(message.updates && message.updates.length))
                            message.updates = [];
                        message.updates.push($root.api.ChannelUpdate.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelsUpdatesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ChannelsUpdatesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ChannelsUpdatesResponse} ChannelsUpdatesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelsUpdatesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelsUpdatesResponse message.
         * @function verify
         * @memberof api.ChannelsUpdatesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelsUpdatesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.nextRequestAfterMs != null && message.hasOwnProperty("nextRequestAfterMs"))
                if (!$util.isInteger(message.nextRequestAfterMs) && !(message.nextRequestAfterMs && $util.isInteger(message.nextRequestAfterMs.low) && $util.isInteger(message.nextRequestAfterMs.high)))
                    return "nextRequestAfterMs: integer|Long expected";
            if (message.updates != null && message.hasOwnProperty("updates")) {
                if (!Array.isArray(message.updates))
                    return "updates: array expected";
                for (let i = 0; i < message.updates.length; ++i) {
                    let error = $root.api.ChannelUpdate.verify(message.updates[i]);
                    if (error)
                        return "updates." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ChannelsUpdatesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ChannelsUpdatesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ChannelsUpdatesResponse} ChannelsUpdatesResponse
         */
        ChannelsUpdatesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ChannelsUpdatesResponse)
                return object;
            let message = new $root.api.ChannelsUpdatesResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "CU_STATUS_OK":
            case 0:
                message.status = 0;
                break;
            case "CU_STATUS_TOO_MANY_UPDATES":
            case 1:
                message.status = 1;
                break;
            }
            if (object.nextRequestAfterMs != null)
                if ($util.Long)
                    (message.nextRequestAfterMs = $util.Long.fromValue(object.nextRequestAfterMs)).unsigned = true;
                else if (typeof object.nextRequestAfterMs === "string")
                    message.nextRequestAfterMs = parseInt(object.nextRequestAfterMs, 10);
                else if (typeof object.nextRequestAfterMs === "number")
                    message.nextRequestAfterMs = object.nextRequestAfterMs;
                else if (typeof object.nextRequestAfterMs === "object")
                    message.nextRequestAfterMs = new $util.LongBits(object.nextRequestAfterMs.low >>> 0, object.nextRequestAfterMs.high >>> 0).toNumber(true);
            if (object.updates) {
                if (!Array.isArray(object.updates))
                    throw TypeError(".api.ChannelsUpdatesResponse.updates: array expected");
                message.updates = [];
                for (let i = 0; i < object.updates.length; ++i) {
                    if (typeof object.updates[i] !== "object")
                        throw TypeError(".api.ChannelsUpdatesResponse.updates: object expected");
                    message.updates[i] = $root.api.ChannelUpdate.fromObject(object.updates[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelsUpdatesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ChannelsUpdatesResponse
         * @static
         * @param {api.ChannelsUpdatesResponse} message ChannelsUpdatesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelsUpdatesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.updates = [];
            if (options.defaults) {
                object.status = options.enums === String ? "CU_STATUS_OK" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.nextRequestAfterMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nextRequestAfterMs = options.longs === String ? "0" : 0;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.api.ChannelsUpdatesResponse.Status[message.status] === undefined ? message.status : $root.api.ChannelsUpdatesResponse.Status[message.status] : message.status;
            if (message.nextRequestAfterMs != null && message.hasOwnProperty("nextRequestAfterMs"))
                if (typeof message.nextRequestAfterMs === "number")
                    object.nextRequestAfterMs = options.longs === String ? String(message.nextRequestAfterMs) : message.nextRequestAfterMs;
                else
                    object.nextRequestAfterMs = options.longs === String ? $util.Long.prototype.toString.call(message.nextRequestAfterMs) : options.longs === Number ? new $util.LongBits(message.nextRequestAfterMs.low >>> 0, message.nextRequestAfterMs.high >>> 0).toNumber(true) : message.nextRequestAfterMs;
            if (message.updates && message.updates.length) {
                object.updates = [];
                for (let j = 0; j < message.updates.length; ++j)
                    object.updates[j] = $root.api.ChannelUpdate.toObject(message.updates[j], options);
            }
            return object;
        };

        /**
         * Converts this ChannelsUpdatesResponse to JSON.
         * @function toJSON
         * @memberof api.ChannelsUpdatesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelsUpdatesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelsUpdatesResponse
         * @function getTypeUrl
         * @memberof api.ChannelsUpdatesResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelsUpdatesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ChannelsUpdatesResponse";
        };

        /**
         * Status enum.
         * @name api.ChannelsUpdatesResponse.Status
         * @enum {number}
         * @property {number} CU_STATUS_OK=0 CU_STATUS_OK value
         * @property {number} CU_STATUS_TOO_MANY_UPDATES=1 CU_STATUS_TOO_MANY_UPDATES value
         */
        ChannelsUpdatesResponse.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CU_STATUS_OK"] = 0;
            values[valuesById[1] = "CU_STATUS_TOO_MANY_UPDATES"] = 1;
            return values;
        })();

        return ChannelsUpdatesResponse;
    })();

    api.RefetchTip = (function() {

        /**
         * Properties of a RefetchTip.
         * @memberof api
         * @interface IRefetchTip
         * @property {Long|null} [userID] RefetchTip userID
         * @property {boolean|null} [workspaceMembers] RefetchTip workspaceMembers
         * @property {boolean|null} [workspaceChannels] RefetchTip workspaceChannels
         * @property {boolean|null} [snippets] RefetchTip snippets
         * @property {boolean|null} [invites] RefetchTip invites
         * @property {boolean|null} [workspaceTariff] RefetchTip workspaceTariff
         */

        /**
         * Constructs a new RefetchTip.
         * @memberof api
         * @classdesc Represents a RefetchTip.
         * @implements IRefetchTip
         * @constructor
         * @param {api.IRefetchTip=} [properties] Properties to set
         */
        function RefetchTip(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RefetchTip userID.
         * @member {Long} userID
         * @memberof api.RefetchTip
         * @instance
         */
        RefetchTip.prototype.userID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * RefetchTip workspaceMembers.
         * @member {boolean|null|undefined} workspaceMembers
         * @memberof api.RefetchTip
         * @instance
         */
        RefetchTip.prototype.workspaceMembers = null;

        /**
         * RefetchTip workspaceChannels.
         * @member {boolean|null|undefined} workspaceChannels
         * @memberof api.RefetchTip
         * @instance
         */
        RefetchTip.prototype.workspaceChannels = null;

        /**
         * RefetchTip snippets.
         * @member {boolean|null|undefined} snippets
         * @memberof api.RefetchTip
         * @instance
         */
        RefetchTip.prototype.snippets = null;

        /**
         * RefetchTip invites.
         * @member {boolean|null|undefined} invites
         * @memberof api.RefetchTip
         * @instance
         */
        RefetchTip.prototype.invites = null;

        /**
         * RefetchTip workspaceTariff.
         * @member {boolean|null|undefined} workspaceTariff
         * @memberof api.RefetchTip
         * @instance
         */
        RefetchTip.prototype.workspaceTariff = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * RefetchTip scope.
         * @member {"workspaceMembers"|"workspaceChannels"|"snippets"|"invites"|"workspaceTariff"|undefined} scope
         * @memberof api.RefetchTip
         * @instance
         */
        Object.defineProperty(RefetchTip.prototype, "scope", {
            get: $util.oneOfGetter($oneOfFields = ["workspaceMembers", "workspaceChannels", "snippets", "invites", "workspaceTariff"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RefetchTip instance using the specified properties.
         * @function create
         * @memberof api.RefetchTip
         * @static
         * @param {api.IRefetchTip=} [properties] Properties to set
         * @returns {api.RefetchTip} RefetchTip instance
         */
        RefetchTip.create = function create(properties) {
            return new RefetchTip(properties);
        };

        /**
         * Encodes the specified RefetchTip message. Does not implicitly {@link api.RefetchTip.verify|verify} messages.
         * @function encode
         * @memberof api.RefetchTip
         * @static
         * @param {api.IRefetchTip} message RefetchTip message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RefetchTip.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.workspaceMembers != null && Object.hasOwnProperty.call(message, "workspaceMembers"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.workspaceMembers);
            if (message.workspaceChannels != null && Object.hasOwnProperty.call(message, "workspaceChannels"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.workspaceChannels);
            if (message.snippets != null && Object.hasOwnProperty.call(message, "snippets"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.snippets);
            if (message.invites != null && Object.hasOwnProperty.call(message, "invites"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.invites);
            if (message.workspaceTariff != null && Object.hasOwnProperty.call(message, "workspaceTariff"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.workspaceTariff);
            if (message.userID != null && Object.hasOwnProperty.call(message, "userID"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.userID);
            return writer;
        };

        /**
         * Encodes the specified RefetchTip message, length delimited. Does not implicitly {@link api.RefetchTip.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RefetchTip
         * @static
         * @param {api.IRefetchTip} message RefetchTip message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RefetchTip.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RefetchTip message from the specified reader or buffer.
         * @function decode
         * @memberof api.RefetchTip
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RefetchTip} RefetchTip
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RefetchTip.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RefetchTip();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 10: {
                        message.userID = reader.uint64();
                        break;
                    }
                case 1: {
                        message.workspaceMembers = reader.bool();
                        break;
                    }
                case 2: {
                        message.workspaceChannels = reader.bool();
                        break;
                    }
                case 3: {
                        message.snippets = reader.bool();
                        break;
                    }
                case 4: {
                        message.invites = reader.bool();
                        break;
                    }
                case 5: {
                        message.workspaceTariff = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RefetchTip message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RefetchTip
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RefetchTip} RefetchTip
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RefetchTip.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RefetchTip message.
         * @function verify
         * @memberof api.RefetchTip
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RefetchTip.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.userID != null && message.hasOwnProperty("userID"))
                if (!$util.isInteger(message.userID) && !(message.userID && $util.isInteger(message.userID.low) && $util.isInteger(message.userID.high)))
                    return "userID: integer|Long expected";
            if (message.workspaceMembers != null && message.hasOwnProperty("workspaceMembers")) {
                properties.scope = 1;
                if (typeof message.workspaceMembers !== "boolean")
                    return "workspaceMembers: boolean expected";
            }
            if (message.workspaceChannels != null && message.hasOwnProperty("workspaceChannels")) {
                if (properties.scope === 1)
                    return "scope: multiple values";
                properties.scope = 1;
                if (typeof message.workspaceChannels !== "boolean")
                    return "workspaceChannels: boolean expected";
            }
            if (message.snippets != null && message.hasOwnProperty("snippets")) {
                if (properties.scope === 1)
                    return "scope: multiple values";
                properties.scope = 1;
                if (typeof message.snippets !== "boolean")
                    return "snippets: boolean expected";
            }
            if (message.invites != null && message.hasOwnProperty("invites")) {
                if (properties.scope === 1)
                    return "scope: multiple values";
                properties.scope = 1;
                if (typeof message.invites !== "boolean")
                    return "invites: boolean expected";
            }
            if (message.workspaceTariff != null && message.hasOwnProperty("workspaceTariff")) {
                if (properties.scope === 1)
                    return "scope: multiple values";
                properties.scope = 1;
                if (typeof message.workspaceTariff !== "boolean")
                    return "workspaceTariff: boolean expected";
            }
            return null;
        };

        /**
         * Creates a RefetchTip message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RefetchTip
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RefetchTip} RefetchTip
         */
        RefetchTip.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RefetchTip)
                return object;
            let message = new $root.api.RefetchTip();
            if (object.userID != null)
                if ($util.Long)
                    (message.userID = $util.Long.fromValue(object.userID)).unsigned = true;
                else if (typeof object.userID === "string")
                    message.userID = parseInt(object.userID, 10);
                else if (typeof object.userID === "number")
                    message.userID = object.userID;
                else if (typeof object.userID === "object")
                    message.userID = new $util.LongBits(object.userID.low >>> 0, object.userID.high >>> 0).toNumber(true);
            if (object.workspaceMembers != null)
                message.workspaceMembers = Boolean(object.workspaceMembers);
            if (object.workspaceChannels != null)
                message.workspaceChannels = Boolean(object.workspaceChannels);
            if (object.snippets != null)
                message.snippets = Boolean(object.snippets);
            if (object.invites != null)
                message.invites = Boolean(object.invites);
            if (object.workspaceTariff != null)
                message.workspaceTariff = Boolean(object.workspaceTariff);
            return message;
        };

        /**
         * Creates a plain object from a RefetchTip message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RefetchTip
         * @static
         * @param {api.RefetchTip} message RefetchTip
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RefetchTip.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.userID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.userID = options.longs === String ? "0" : 0;
            if (message.workspaceMembers != null && message.hasOwnProperty("workspaceMembers")) {
                object.workspaceMembers = message.workspaceMembers;
                if (options.oneofs)
                    object.scope = "workspaceMembers";
            }
            if (message.workspaceChannels != null && message.hasOwnProperty("workspaceChannels")) {
                object.workspaceChannels = message.workspaceChannels;
                if (options.oneofs)
                    object.scope = "workspaceChannels";
            }
            if (message.snippets != null && message.hasOwnProperty("snippets")) {
                object.snippets = message.snippets;
                if (options.oneofs)
                    object.scope = "snippets";
            }
            if (message.invites != null && message.hasOwnProperty("invites")) {
                object.invites = message.invites;
                if (options.oneofs)
                    object.scope = "invites";
            }
            if (message.workspaceTariff != null && message.hasOwnProperty("workspaceTariff")) {
                object.workspaceTariff = message.workspaceTariff;
                if (options.oneofs)
                    object.scope = "workspaceTariff";
            }
            if (message.userID != null && message.hasOwnProperty("userID"))
                if (typeof message.userID === "number")
                    object.userID = options.longs === String ? String(message.userID) : message.userID;
                else
                    object.userID = options.longs === String ? $util.Long.prototype.toString.call(message.userID) : options.longs === Number ? new $util.LongBits(message.userID.low >>> 0, message.userID.high >>> 0).toNumber(true) : message.userID;
            return object;
        };

        /**
         * Converts this RefetchTip to JSON.
         * @function toJSON
         * @memberof api.RefetchTip
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RefetchTip.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RefetchTip
         * @function getTypeUrl
         * @memberof api.RefetchTip
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RefetchTip.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RefetchTip";
        };

        return RefetchTip;
    })();

    return api;
})();

export const entities = $root.entities = (() => {

    /**
     * Namespace entities.
     * @exports entities
     * @namespace
     */
    const entities = {};

    /**
     * UserOptType enum.
     * @name entities.UserOptType
     * @enum {number}
     * @property {number} UOT_FIRST_SEEN=0 UOT_FIRST_SEEN value
     * @property {number} UOT_REGISTRATION_STAMP=1 UOT_REGISTRATION_STAMP value
     * @property {number} UOT_REGISTERED=2 UOT_REGISTERED value
     * @property {number} UOT_APP_VERSION=3 UOT_APP_VERSION value
     * @property {number} UOT_WORKSPACE=4 UOT_WORKSPACE value
     * @property {number} UOT_PASSWORD=10 UOT_PASSWORD value
     * @property {number} UOT_FIRST_NAME=11 UOT_FIRST_NAME value
     * @property {number} UOT_LAST_NAME=12 UOT_LAST_NAME value
     * @property {number} UOT_EMAIL=20 UOT_EMAIL value
     * @property {number} UOT_PENDING_EMAIL=42 UOT_PENDING_EMAIL value
     * @property {number} UOT_EMAIL_CONFIRMED=21 UOT_EMAIL_CONFIRMED value
     * @property {number} UOT_EMAIL_CONFIRMATION_CODE=22 UOT_EMAIL_CONFIRMATION_CODE value
     * @property {number} UOT_EMAIL_CONFIRMATION_NEXT_TRY=23 UOT_EMAIL_CONFIRMATION_NEXT_TRY value
     * @property {number} UOT_EMAIL_CONFIRMATION_TRIES=24 UOT_EMAIL_CONFIRMATION_TRIES value
     * @property {number} UOT_EMAIL_CONFIRMATION_SENT_AT=25 UOT_EMAIL_CONFIRMATION_SENT_AT value
     * @property {number} UOT_EMAIL_CONFIRMATION_RESEND_NEXT_TRY=26 UOT_EMAIL_CONFIRMATION_RESEND_NEXT_TRY value
     * @property {number} UOT_EMAIL_CONFIRMATION_RESEND_TRIES=27 UOT_EMAIL_CONFIRMATION_RESEND_TRIES value
     * @property {number} UOT_PASSWORD_FORGOT_NEXT_TRY=28 UOT_PASSWORD_FORGOT_NEXT_TRY value
     * @property {number} UOT_PASSWORD_FORGOT_TRIES=29 UOT_PASSWORD_FORGOT_TRIES value
     * @property {number} UOT_PASSWORD_RESET_CODE=30 UOT_PASSWORD_RESET_CODE value
     * @property {number} UOT_PASSWORD_RESET_NEXT_TRY=31 UOT_PASSWORD_RESET_NEXT_TRY value
     * @property {number} UOT_PASSWORD_RESET_TRIES=32 UOT_PASSWORD_RESET_TRIES value
     * @property {number} UOT_PASSWORD_RESET_CODE_SENT_AT=33 UOT_PASSWORD_RESET_CODE_SENT_AT value
     * @property {number} UOT_LOGIN_NEXT_TRY=34 UOT_LOGIN_NEXT_TRY value
     * @property {number} UOT_LOGIN_TRIES=35 UOT_LOGIN_TRIES value
     * @property {number} UOT_REGISTER_NEXT_TRY=36 UOT_REGISTER_NEXT_TRY value
     * @property {number} UOT_REGISTER_TRIES=37 UOT_REGISTER_TRIES value
     * @property {number} UOT_CHANGE_EMAIL_TRIES=38 UOT_CHANGE_EMAIL_TRIES value
     * @property {number} UOT_CHANGE_EMAIL_NEXT_TRY=39 UOT_CHANGE_EMAIL_NEXT_TRY value
     * @property {number} UOT_CHANGE_PASSWORD_TRIES=40 UOT_CHANGE_PASSWORD_TRIES value
     * @property {number} UOT_CHANGE_PASSWORD_NEXT_TRY=41 UOT_CHANGE_PASSWORD_NEXT_TRY value
     * @property {number} UOT_FREE_TRIAL_UNTIL=50 UOT_FREE_TRIAL_UNTIL value
     */
    entities.UserOptType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UOT_FIRST_SEEN"] = 0;
        values[valuesById[1] = "UOT_REGISTRATION_STAMP"] = 1;
        values[valuesById[2] = "UOT_REGISTERED"] = 2;
        values[valuesById[3] = "UOT_APP_VERSION"] = 3;
        values[valuesById[4] = "UOT_WORKSPACE"] = 4;
        values[valuesById[10] = "UOT_PASSWORD"] = 10;
        values[valuesById[11] = "UOT_FIRST_NAME"] = 11;
        values[valuesById[12] = "UOT_LAST_NAME"] = 12;
        values[valuesById[20] = "UOT_EMAIL"] = 20;
        values[valuesById[42] = "UOT_PENDING_EMAIL"] = 42;
        values[valuesById[21] = "UOT_EMAIL_CONFIRMED"] = 21;
        values[valuesById[22] = "UOT_EMAIL_CONFIRMATION_CODE"] = 22;
        values[valuesById[23] = "UOT_EMAIL_CONFIRMATION_NEXT_TRY"] = 23;
        values[valuesById[24] = "UOT_EMAIL_CONFIRMATION_TRIES"] = 24;
        values[valuesById[25] = "UOT_EMAIL_CONFIRMATION_SENT_AT"] = 25;
        values[valuesById[26] = "UOT_EMAIL_CONFIRMATION_RESEND_NEXT_TRY"] = 26;
        values[valuesById[27] = "UOT_EMAIL_CONFIRMATION_RESEND_TRIES"] = 27;
        values[valuesById[28] = "UOT_PASSWORD_FORGOT_NEXT_TRY"] = 28;
        values[valuesById[29] = "UOT_PASSWORD_FORGOT_TRIES"] = 29;
        values[valuesById[30] = "UOT_PASSWORD_RESET_CODE"] = 30;
        values[valuesById[31] = "UOT_PASSWORD_RESET_NEXT_TRY"] = 31;
        values[valuesById[32] = "UOT_PASSWORD_RESET_TRIES"] = 32;
        values[valuesById[33] = "UOT_PASSWORD_RESET_CODE_SENT_AT"] = 33;
        values[valuesById[34] = "UOT_LOGIN_NEXT_TRY"] = 34;
        values[valuesById[35] = "UOT_LOGIN_TRIES"] = 35;
        values[valuesById[36] = "UOT_REGISTER_NEXT_TRY"] = 36;
        values[valuesById[37] = "UOT_REGISTER_TRIES"] = 37;
        values[valuesById[38] = "UOT_CHANGE_EMAIL_TRIES"] = 38;
        values[valuesById[39] = "UOT_CHANGE_EMAIL_NEXT_TRY"] = 39;
        values[valuesById[40] = "UOT_CHANGE_PASSWORD_TRIES"] = 40;
        values[valuesById[41] = "UOT_CHANGE_PASSWORD_NEXT_TRY"] = 41;
        values[valuesById[50] = "UOT_FREE_TRIAL_UNTIL"] = 50;
        return values;
    })();

    /**
     * UserRegistrationStatus enum.
     * @name entities.UserRegistrationStatus
     * @enum {number}
     * @property {number} UL_GUEST=0 UL_GUEST value
     * @property {number} UL_REGISTERED=1 UL_REGISTERED value
     */
    entities.UserRegistrationStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UL_GUEST"] = 0;
        values[valuesById[1] = "UL_REGISTERED"] = 1;
        return values;
    })();

    /**
     * UserProfileFieldType enum.
     * @name entities.UserProfileFieldType
     * @enum {number}
     * @property {number} UPFT_FIRST_NAME=0 UPFT_FIRST_NAME value
     * @property {number} UPFT_LAST_NAME=1 UPFT_LAST_NAME value
     * @property {number} UPFT_EMAIL=2 UPFT_EMAIL value
     */
    entities.UserProfileFieldType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UPFT_FIRST_NAME"] = 0;
        values[valuesById[1] = "UPFT_LAST_NAME"] = 1;
        values[valuesById[2] = "UPFT_EMAIL"] = 2;
        return values;
    })();

    entities.UserProfileField = (function() {

        /**
         * Properties of a UserProfileField.
         * @memberof entities
         * @interface IUserProfileField
         * @property {entities.UserProfileFieldType|null} [type] UserProfileField type
         * @property {entities.IOptValue|null} [value] UserProfileField value
         */

        /**
         * Constructs a new UserProfileField.
         * @memberof entities
         * @classdesc Represents a UserProfileField.
         * @implements IUserProfileField
         * @constructor
         * @param {entities.IUserProfileField=} [properties] Properties to set
         */
        function UserProfileField(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserProfileField type.
         * @member {entities.UserProfileFieldType} type
         * @memberof entities.UserProfileField
         * @instance
         */
        UserProfileField.prototype.type = 0;

        /**
         * UserProfileField value.
         * @member {entities.IOptValue|null|undefined} value
         * @memberof entities.UserProfileField
         * @instance
         */
        UserProfileField.prototype.value = null;

        /**
         * Creates a new UserProfileField instance using the specified properties.
         * @function create
         * @memberof entities.UserProfileField
         * @static
         * @param {entities.IUserProfileField=} [properties] Properties to set
         * @returns {entities.UserProfileField} UserProfileField instance
         */
        UserProfileField.create = function create(properties) {
            return new UserProfileField(properties);
        };

        /**
         * Encodes the specified UserProfileField message. Does not implicitly {@link entities.UserProfileField.verify|verify} messages.
         * @function encode
         * @memberof entities.UserProfileField
         * @static
         * @param {entities.IUserProfileField} message UserProfileField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserProfileField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                $root.entities.OptValue.encode(message.value, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UserProfileField message, length delimited. Does not implicitly {@link entities.UserProfileField.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.UserProfileField
         * @static
         * @param {entities.IUserProfileField} message UserProfileField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserProfileField.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserProfileField message from the specified reader or buffer.
         * @function decode
         * @memberof entities.UserProfileField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.UserProfileField} UserProfileField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserProfileField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.UserProfileField();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.value = $root.entities.OptValue.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserProfileField message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.UserProfileField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.UserProfileField} UserProfileField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserProfileField.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserProfileField message.
         * @function verify
         * @memberof entities.UserProfileField
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserProfileField.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.value != null && message.hasOwnProperty("value")) {
                let error = $root.entities.OptValue.verify(message.value);
                if (error)
                    return "value." + error;
            }
            return null;
        };

        /**
         * Creates a UserProfileField message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.UserProfileField
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.UserProfileField} UserProfileField
         */
        UserProfileField.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.UserProfileField)
                return object;
            let message = new $root.entities.UserProfileField();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "UPFT_FIRST_NAME":
            case 0:
                message.type = 0;
                break;
            case "UPFT_LAST_NAME":
            case 1:
                message.type = 1;
                break;
            case "UPFT_EMAIL":
            case 2:
                message.type = 2;
                break;
            }
            if (object.value != null) {
                if (typeof object.value !== "object")
                    throw TypeError(".entities.UserProfileField.value: object expected");
                message.value = $root.entities.OptValue.fromObject(object.value);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserProfileField message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.UserProfileField
         * @static
         * @param {entities.UserProfileField} message UserProfileField
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserProfileField.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "UPFT_FIRST_NAME" : 0;
                object.value = null;
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.entities.UserProfileFieldType[message.type] === undefined ? message.type : $root.entities.UserProfileFieldType[message.type] : message.type;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = $root.entities.OptValue.toObject(message.value, options);
            return object;
        };

        /**
         * Converts this UserProfileField to JSON.
         * @function toJSON
         * @memberof entities.UserProfileField
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserProfileField.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserProfileField
         * @function getTypeUrl
         * @memberof entities.UserProfileField
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserProfileField.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.UserProfileField";
        };

        return UserProfileField;
    })();

    entities.UserShortProfile = (function() {

        /**
         * Properties of a UserShortProfile.
         * @memberof entities
         * @interface IUserShortProfile
         * @property {Long|null} [id] UserShortProfile id
         * @property {Array.<entities.IUserProfileField>|null} [fields] UserShortProfile fields
         */

        /**
         * Constructs a new UserShortProfile.
         * @memberof entities
         * @classdesc Represents a UserShortProfile.
         * @implements IUserShortProfile
         * @constructor
         * @param {entities.IUserShortProfile=} [properties] Properties to set
         */
        function UserShortProfile(properties) {
            this.fields = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserShortProfile id.
         * @member {Long} id
         * @memberof entities.UserShortProfile
         * @instance
         */
        UserShortProfile.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UserShortProfile fields.
         * @member {Array.<entities.IUserProfileField>} fields
         * @memberof entities.UserShortProfile
         * @instance
         */
        UserShortProfile.prototype.fields = $util.emptyArray;

        /**
         * Creates a new UserShortProfile instance using the specified properties.
         * @function create
         * @memberof entities.UserShortProfile
         * @static
         * @param {entities.IUserShortProfile=} [properties] Properties to set
         * @returns {entities.UserShortProfile} UserShortProfile instance
         */
        UserShortProfile.create = function create(properties) {
            return new UserShortProfile(properties);
        };

        /**
         * Encodes the specified UserShortProfile message. Does not implicitly {@link entities.UserShortProfile.verify|verify} messages.
         * @function encode
         * @memberof entities.UserShortProfile
         * @static
         * @param {entities.IUserShortProfile} message UserShortProfile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserShortProfile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
            if (message.fields != null && message.fields.length)
                for (let i = 0; i < message.fields.length; ++i)
                    $root.entities.UserProfileField.encode(message.fields[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UserShortProfile message, length delimited. Does not implicitly {@link entities.UserShortProfile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.UserShortProfile
         * @static
         * @param {entities.IUserShortProfile} message UserShortProfile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserShortProfile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserShortProfile message from the specified reader or buffer.
         * @function decode
         * @memberof entities.UserShortProfile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.UserShortProfile} UserShortProfile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserShortProfile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.UserShortProfile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 10: {
                        if (!(message.fields && message.fields.length))
                            message.fields = [];
                        message.fields.push($root.entities.UserProfileField.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserShortProfile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.UserShortProfile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.UserShortProfile} UserShortProfile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserShortProfile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserShortProfile message.
         * @function verify
         * @memberof entities.UserShortProfile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserShortProfile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                    return "id: integer|Long expected";
            if (message.fields != null && message.hasOwnProperty("fields")) {
                if (!Array.isArray(message.fields))
                    return "fields: array expected";
                for (let i = 0; i < message.fields.length; ++i) {
                    let error = $root.entities.UserProfileField.verify(message.fields[i]);
                    if (error)
                        return "fields." + error;
                }
            }
            return null;
        };

        /**
         * Creates a UserShortProfile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.UserShortProfile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.UserShortProfile} UserShortProfile
         */
        UserShortProfile.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.UserShortProfile)
                return object;
            let message = new $root.entities.UserShortProfile();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.fields) {
                if (!Array.isArray(object.fields))
                    throw TypeError(".entities.UserShortProfile.fields: array expected");
                message.fields = [];
                for (let i = 0; i < object.fields.length; ++i) {
                    if (typeof object.fields[i] !== "object")
                        throw TypeError(".entities.UserShortProfile.fields: object expected");
                    message.fields[i] = $root.entities.UserProfileField.fromObject(object.fields[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a UserShortProfile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.UserShortProfile
         * @static
         * @param {entities.UserShortProfile} message UserShortProfile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserShortProfile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.fields = [];
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.fields && message.fields.length) {
                object.fields = [];
                for (let j = 0; j < message.fields.length; ++j)
                    object.fields[j] = $root.entities.UserProfileField.toObject(message.fields[j], options);
            }
            return object;
        };

        /**
         * Converts this UserShortProfile to JSON.
         * @function toJSON
         * @memberof entities.UserShortProfile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserShortProfile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserShortProfile
         * @function getTypeUrl
         * @memberof entities.UserShortProfile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserShortProfile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.UserShortProfile";
        };

        return UserShortProfile;
    })();

    /**
     * UserRegisterResult enum.
     * @name entities.UserRegisterResult
     * @enum {number}
     * @property {number} RS_OK=0 RS_OK value
     * @property {number} RS_INVALID_EMAIL=1 RS_INVALID_EMAIL value
     * @property {number} RS_EMAIL_USED=2 RS_EMAIL_USED value
     * @property {number} RS_PASSWORD_TOO_SHORT=3 RS_PASSWORD_TOO_SHORT value
     * @property {number} RS_ONLY_SPECIFIC_DOMAINS=4 RS_ONLY_SPECIFIC_DOMAINS value
     * @property {number} RS_PASSWORDS_MISMATCH=5 RS_PASSWORDS_MISMATCH value
     * @property {number} RS_TERMS_NOT_ACCEPTED=6 RS_TERMS_NOT_ACCEPTED value
     * @property {number} RS_PENDING_STAMP=7 RS_PENDING_STAMP value
     * @property {number} RS_ALREADY_REGISTERED=8 RS_ALREADY_REGISTERED value
     * @property {number} RS_INVALID_INVITE=9 RS_INVALID_INVITE value
     * @property {number} RS_UPGRADE_REQUIRED=10 RS_UPGRADE_REQUIRED value
     */
    entities.UserRegisterResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RS_OK"] = 0;
        values[valuesById[1] = "RS_INVALID_EMAIL"] = 1;
        values[valuesById[2] = "RS_EMAIL_USED"] = 2;
        values[valuesById[3] = "RS_PASSWORD_TOO_SHORT"] = 3;
        values[valuesById[4] = "RS_ONLY_SPECIFIC_DOMAINS"] = 4;
        values[valuesById[5] = "RS_PASSWORDS_MISMATCH"] = 5;
        values[valuesById[6] = "RS_TERMS_NOT_ACCEPTED"] = 6;
        values[valuesById[7] = "RS_PENDING_STAMP"] = 7;
        values[valuesById[8] = "RS_ALREADY_REGISTERED"] = 8;
        values[valuesById[9] = "RS_INVALID_INVITE"] = 9;
        values[valuesById[10] = "RS_UPGRADE_REQUIRED"] = 10;
        return values;
    })();

    /**
     * UserLoginResult enum.
     * @name entities.UserLoginResult
     * @enum {number}
     * @property {number} LS_OK=0 LS_OK value
     * @property {number} LS_INVALID_CREDENTIALS=1 LS_INVALID_CREDENTIALS value
     * @property {number} LS_PENDING_STAMP=2 LS_PENDING_STAMP value
     */
    entities.UserLoginResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "LS_OK"] = 0;
        values[valuesById[1] = "LS_INVALID_CREDENTIALS"] = 1;
        values[valuesById[2] = "LS_PENDING_STAMP"] = 2;
        return values;
    })();

    /**
     * ConfirmCredsResult enum.
     * @name entities.ConfirmCredsResult
     * @enum {number}
     * @property {number} CS_CONFIRMED=0 CS_CONFIRMED value
     * @property {number} CS_INVALID_CODE=1 CS_INVALID_CODE value
     * @property {number} CS_PENDING_STAMP=2 CS_PENDING_STAMP value
     * @property {number} CS_INVALID_TOKEN=3 CS_INVALID_TOKEN value
     * @property {number} CS_ALREADY_CONFIRMED=4 CS_ALREADY_CONFIRMED value
     * @property {number} CS_CODE_EXPIRED=5 CS_CODE_EXPIRED value
     */
    entities.ConfirmCredsResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CS_CONFIRMED"] = 0;
        values[valuesById[1] = "CS_INVALID_CODE"] = 1;
        values[valuesById[2] = "CS_PENDING_STAMP"] = 2;
        values[valuesById[3] = "CS_INVALID_TOKEN"] = 3;
        values[valuesById[4] = "CS_ALREADY_CONFIRMED"] = 4;
        values[valuesById[5] = "CS_CODE_EXPIRED"] = 5;
        return values;
    })();

    /**
     * ResendConfirmResult enum.
     * @name entities.ResendConfirmResult
     * @enum {number}
     * @property {number} RCS_CODE_SENT=0 RCS_CODE_SENT value
     * @property {number} RCS_PENDING_STAMP=1 RCS_PENDING_STAMP value
     * @property {number} RCS_INVALID_TOKEN=2 RCS_INVALID_TOKEN value
     * @property {number} RCS_ALREADY_CONFIRMED=3 RCS_ALREADY_CONFIRMED value
     */
    entities.ResendConfirmResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RCS_CODE_SENT"] = 0;
        values[valuesById[1] = "RCS_PENDING_STAMP"] = 1;
        values[valuesById[2] = "RCS_INVALID_TOKEN"] = 2;
        values[valuesById[3] = "RCS_ALREADY_CONFIRMED"] = 3;
        return values;
    })();

    /**
     * RestorePasswordResult enum.
     * @name entities.RestorePasswordResult
     * @enum {number}
     * @property {number} RPR_CODE_SENT=0 RPR_CODE_SENT value
     * @property {number} RPR_PENDING_STAMP=1 RPR_PENDING_STAMP value
     * @property {number} RPR_INVALID_EMAIL=2 RPR_INVALID_EMAIL value
     * @property {number} RPR_UNKNOWN_EMAIL=3 RPR_UNKNOWN_EMAIL value
     */
    entities.RestorePasswordResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RPR_CODE_SENT"] = 0;
        values[valuesById[1] = "RPR_PENDING_STAMP"] = 1;
        values[valuesById[2] = "RPR_INVALID_EMAIL"] = 2;
        values[valuesById[3] = "RPR_UNKNOWN_EMAIL"] = 3;
        return values;
    })();

    /**
     * ResetPasswordResult enum.
     * @name entities.ResetPasswordResult
     * @enum {number}
     * @property {number} RP_OK=0 RP_OK value
     * @property {number} RP_INVALID_CODE=1 RP_INVALID_CODE value
     * @property {number} RP_PENDING_STAMP=2 RP_PENDING_STAMP value
     * @property {number} RP_INVALID_EMAIL=3 RP_INVALID_EMAIL value
     * @property {number} RP_PASSWORD_MISMATCH=4 RP_PASSWORD_MISMATCH value
     * @property {number} RP_PASSWORD_TOO_SHORT=5 RP_PASSWORD_TOO_SHORT value
     * @property {number} RP_CODE_EXPIRED=6 RP_CODE_EXPIRED value
     */
    entities.ResetPasswordResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RP_OK"] = 0;
        values[valuesById[1] = "RP_INVALID_CODE"] = 1;
        values[valuesById[2] = "RP_PENDING_STAMP"] = 2;
        values[valuesById[3] = "RP_INVALID_EMAIL"] = 3;
        values[valuesById[4] = "RP_PASSWORD_MISMATCH"] = 4;
        values[valuesById[5] = "RP_PASSWORD_TOO_SHORT"] = 5;
        values[valuesById[6] = "RP_CODE_EXPIRED"] = 6;
        return values;
    })();

    /**
     * ChangeEmailResult enum.
     * @name entities.ChangeEmailResult
     * @enum {number}
     * @property {number} CER_OK=0 CER_OK value
     * @property {number} CER_INVALID_EMAIL=1 CER_INVALID_EMAIL value
     * @property {number} CER_PENDING_STAMP=2 CER_PENDING_STAMP value
     * @property {number} CER_EMAIL_USED=3 CER_EMAIL_USED value
     * @property {number} CER_NOT_REGISTERED=4 CER_NOT_REGISTERED value
     * @property {number} CER_INVALID_PASSWORD=5 CER_INVALID_PASSWORD value
     */
    entities.ChangeEmailResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CER_OK"] = 0;
        values[valuesById[1] = "CER_INVALID_EMAIL"] = 1;
        values[valuesById[2] = "CER_PENDING_STAMP"] = 2;
        values[valuesById[3] = "CER_EMAIL_USED"] = 3;
        values[valuesById[4] = "CER_NOT_REGISTERED"] = 4;
        values[valuesById[5] = "CER_INVALID_PASSWORD"] = 5;
        return values;
    })();

    /**
     * ChangePasswordResult enum.
     * @name entities.ChangePasswordResult
     * @enum {number}
     * @property {number} CPR_OK=0 CPR_OK value
     * @property {number} CPR_INVALID_OLD_PASSWORD=1 CPR_INVALID_OLD_PASSWORD value
     * @property {number} CPR_PENDING_STAMP=2 CPR_PENDING_STAMP value
     * @property {number} CPR_NOT_REGISTERED=3 CPR_NOT_REGISTERED value
     * @property {number} CPR_INVALID_NEW_PASSWORD=4 CPR_INVALID_NEW_PASSWORD value
     */
    entities.ChangePasswordResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CPR_OK"] = 0;
        values[valuesById[1] = "CPR_INVALID_OLD_PASSWORD"] = 1;
        values[valuesById[2] = "CPR_PENDING_STAMP"] = 2;
        values[valuesById[3] = "CPR_NOT_REGISTERED"] = 3;
        values[valuesById[4] = "CPR_INVALID_NEW_PASSWORD"] = 4;
        return values;
    })();

    /**
     * AuthType enum.
     * @name entities.AuthType
     * @enum {number}
     * @property {number} AT_EMAIL=0 AT_EMAIL value
     */
    entities.AuthType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AT_EMAIL"] = 0;
        return values;
    })();

    entities.UserOpt = (function() {

        /**
         * Properties of a UserOpt.
         * @memberof entities
         * @interface IUserOpt
         * @property {entities.UserOptType|null} [type] UserOpt type
         * @property {entities.IOptValue|null} [value] UserOpt value
         */

        /**
         * Constructs a new UserOpt.
         * @memberof entities
         * @classdesc Represents a UserOpt.
         * @implements IUserOpt
         * @constructor
         * @param {entities.IUserOpt=} [properties] Properties to set
         */
        function UserOpt(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserOpt type.
         * @member {entities.UserOptType} type
         * @memberof entities.UserOpt
         * @instance
         */
        UserOpt.prototype.type = 0;

        /**
         * UserOpt value.
         * @member {entities.IOptValue|null|undefined} value
         * @memberof entities.UserOpt
         * @instance
         */
        UserOpt.prototype.value = null;

        /**
         * Creates a new UserOpt instance using the specified properties.
         * @function create
         * @memberof entities.UserOpt
         * @static
         * @param {entities.IUserOpt=} [properties] Properties to set
         * @returns {entities.UserOpt} UserOpt instance
         */
        UserOpt.create = function create(properties) {
            return new UserOpt(properties);
        };

        /**
         * Encodes the specified UserOpt message. Does not implicitly {@link entities.UserOpt.verify|verify} messages.
         * @function encode
         * @memberof entities.UserOpt
         * @static
         * @param {entities.IUserOpt} message UserOpt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserOpt.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                $root.entities.OptValue.encode(message.value, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UserOpt message, length delimited. Does not implicitly {@link entities.UserOpt.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.UserOpt
         * @static
         * @param {entities.IUserOpt} message UserOpt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserOpt.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserOpt message from the specified reader or buffer.
         * @function decode
         * @memberof entities.UserOpt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.UserOpt} UserOpt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserOpt.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.UserOpt();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.value = $root.entities.OptValue.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserOpt message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.UserOpt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.UserOpt} UserOpt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserOpt.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserOpt message.
         * @function verify
         * @memberof entities.UserOpt
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserOpt.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 10:
                case 11:
                case 12:
                case 20:
                case 42:
                case 21:
                case 22:
                case 23:
                case 24:
                case 25:
                case 26:
                case 27:
                case 28:
                case 29:
                case 30:
                case 31:
                case 32:
                case 33:
                case 34:
                case 35:
                case 36:
                case 37:
                case 38:
                case 39:
                case 40:
                case 41:
                case 50:
                    break;
                }
            if (message.value != null && message.hasOwnProperty("value")) {
                let error = $root.entities.OptValue.verify(message.value);
                if (error)
                    return "value." + error;
            }
            return null;
        };

        /**
         * Creates a UserOpt message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.UserOpt
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.UserOpt} UserOpt
         */
        UserOpt.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.UserOpt)
                return object;
            let message = new $root.entities.UserOpt();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "UOT_FIRST_SEEN":
            case 0:
                message.type = 0;
                break;
            case "UOT_REGISTRATION_STAMP":
            case 1:
                message.type = 1;
                break;
            case "UOT_REGISTERED":
            case 2:
                message.type = 2;
                break;
            case "UOT_APP_VERSION":
            case 3:
                message.type = 3;
                break;
            case "UOT_WORKSPACE":
            case 4:
                message.type = 4;
                break;
            case "UOT_PASSWORD":
            case 10:
                message.type = 10;
                break;
            case "UOT_FIRST_NAME":
            case 11:
                message.type = 11;
                break;
            case "UOT_LAST_NAME":
            case 12:
                message.type = 12;
                break;
            case "UOT_EMAIL":
            case 20:
                message.type = 20;
                break;
            case "UOT_PENDING_EMAIL":
            case 42:
                message.type = 42;
                break;
            case "UOT_EMAIL_CONFIRMED":
            case 21:
                message.type = 21;
                break;
            case "UOT_EMAIL_CONFIRMATION_CODE":
            case 22:
                message.type = 22;
                break;
            case "UOT_EMAIL_CONFIRMATION_NEXT_TRY":
            case 23:
                message.type = 23;
                break;
            case "UOT_EMAIL_CONFIRMATION_TRIES":
            case 24:
                message.type = 24;
                break;
            case "UOT_EMAIL_CONFIRMATION_SENT_AT":
            case 25:
                message.type = 25;
                break;
            case "UOT_EMAIL_CONFIRMATION_RESEND_NEXT_TRY":
            case 26:
                message.type = 26;
                break;
            case "UOT_EMAIL_CONFIRMATION_RESEND_TRIES":
            case 27:
                message.type = 27;
                break;
            case "UOT_PASSWORD_FORGOT_NEXT_TRY":
            case 28:
                message.type = 28;
                break;
            case "UOT_PASSWORD_FORGOT_TRIES":
            case 29:
                message.type = 29;
                break;
            case "UOT_PASSWORD_RESET_CODE":
            case 30:
                message.type = 30;
                break;
            case "UOT_PASSWORD_RESET_NEXT_TRY":
            case 31:
                message.type = 31;
                break;
            case "UOT_PASSWORD_RESET_TRIES":
            case 32:
                message.type = 32;
                break;
            case "UOT_PASSWORD_RESET_CODE_SENT_AT":
            case 33:
                message.type = 33;
                break;
            case "UOT_LOGIN_NEXT_TRY":
            case 34:
                message.type = 34;
                break;
            case "UOT_LOGIN_TRIES":
            case 35:
                message.type = 35;
                break;
            case "UOT_REGISTER_NEXT_TRY":
            case 36:
                message.type = 36;
                break;
            case "UOT_REGISTER_TRIES":
            case 37:
                message.type = 37;
                break;
            case "UOT_CHANGE_EMAIL_TRIES":
            case 38:
                message.type = 38;
                break;
            case "UOT_CHANGE_EMAIL_NEXT_TRY":
            case 39:
                message.type = 39;
                break;
            case "UOT_CHANGE_PASSWORD_TRIES":
            case 40:
                message.type = 40;
                break;
            case "UOT_CHANGE_PASSWORD_NEXT_TRY":
            case 41:
                message.type = 41;
                break;
            case "UOT_FREE_TRIAL_UNTIL":
            case 50:
                message.type = 50;
                break;
            }
            if (object.value != null) {
                if (typeof object.value !== "object")
                    throw TypeError(".entities.UserOpt.value: object expected");
                message.value = $root.entities.OptValue.fromObject(object.value);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserOpt message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.UserOpt
         * @static
         * @param {entities.UserOpt} message UserOpt
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserOpt.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "UOT_FIRST_SEEN" : 0;
                object.value = null;
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.entities.UserOptType[message.type] === undefined ? message.type : $root.entities.UserOptType[message.type] : message.type;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = $root.entities.OptValue.toObject(message.value, options);
            return object;
        };

        /**
         * Converts this UserOpt to JSON.
         * @function toJSON
         * @memberof entities.UserOpt
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserOpt.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserOpt
         * @function getTypeUrl
         * @memberof entities.UserOpt
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserOpt.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.UserOpt";
        };

        return UserOpt;
    })();

    entities.OptValue = (function() {

        /**
         * Properties of an OptValue.
         * @memberof entities
         * @interface IOptValue
         * @property {string|null} [strValue] OptValue strValue
         * @property {boolean|null} [boolValue] OptValue boolValue
         * @property {Long|null} [uintValue] OptValue uintValue
         * @property {Uint8Array|null} [blobValue] OptValue blobValue
         */

        /**
         * Constructs a new OptValue.
         * @memberof entities
         * @classdesc Represents an OptValue.
         * @implements IOptValue
         * @constructor
         * @param {entities.IOptValue=} [properties] Properties to set
         */
        function OptValue(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OptValue strValue.
         * @member {string} strValue
         * @memberof entities.OptValue
         * @instance
         */
        OptValue.prototype.strValue = "";

        /**
         * OptValue boolValue.
         * @member {boolean} boolValue
         * @memberof entities.OptValue
         * @instance
         */
        OptValue.prototype.boolValue = false;

        /**
         * OptValue uintValue.
         * @member {Long} uintValue
         * @memberof entities.OptValue
         * @instance
         */
        OptValue.prototype.uintValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * OptValue blobValue.
         * @member {Uint8Array} blobValue
         * @memberof entities.OptValue
         * @instance
         */
        OptValue.prototype.blobValue = $util.newBuffer([]);

        /**
         * Creates a new OptValue instance using the specified properties.
         * @function create
         * @memberof entities.OptValue
         * @static
         * @param {entities.IOptValue=} [properties] Properties to set
         * @returns {entities.OptValue} OptValue instance
         */
        OptValue.create = function create(properties) {
            return new OptValue(properties);
        };

        /**
         * Encodes the specified OptValue message. Does not implicitly {@link entities.OptValue.verify|verify} messages.
         * @function encode
         * @memberof entities.OptValue
         * @static
         * @param {entities.IOptValue} message OptValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OptValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.strValue != null && Object.hasOwnProperty.call(message, "strValue"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.strValue);
            if (message.boolValue != null && Object.hasOwnProperty.call(message, "boolValue"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.boolValue);
            if (message.uintValue != null && Object.hasOwnProperty.call(message, "uintValue"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.uintValue);
            if (message.blobValue != null && Object.hasOwnProperty.call(message, "blobValue"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.blobValue);
            return writer;
        };

        /**
         * Encodes the specified OptValue message, length delimited. Does not implicitly {@link entities.OptValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.OptValue
         * @static
         * @param {entities.IOptValue} message OptValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OptValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OptValue message from the specified reader or buffer.
         * @function decode
         * @memberof entities.OptValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.OptValue} OptValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OptValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.OptValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.strValue = reader.string();
                        break;
                    }
                case 2: {
                        message.boolValue = reader.bool();
                        break;
                    }
                case 3: {
                        message.uintValue = reader.uint64();
                        break;
                    }
                case 4: {
                        message.blobValue = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OptValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.OptValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.OptValue} OptValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OptValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OptValue message.
         * @function verify
         * @memberof entities.OptValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OptValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.strValue != null && message.hasOwnProperty("strValue"))
                if (!$util.isString(message.strValue))
                    return "strValue: string expected";
            if (message.boolValue != null && message.hasOwnProperty("boolValue"))
                if (typeof message.boolValue !== "boolean")
                    return "boolValue: boolean expected";
            if (message.uintValue != null && message.hasOwnProperty("uintValue"))
                if (!$util.isInteger(message.uintValue) && !(message.uintValue && $util.isInteger(message.uintValue.low) && $util.isInteger(message.uintValue.high)))
                    return "uintValue: integer|Long expected";
            if (message.blobValue != null && message.hasOwnProperty("blobValue"))
                if (!(message.blobValue && typeof message.blobValue.length === "number" || $util.isString(message.blobValue)))
                    return "blobValue: buffer expected";
            return null;
        };

        /**
         * Creates an OptValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.OptValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.OptValue} OptValue
         */
        OptValue.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.OptValue)
                return object;
            let message = new $root.entities.OptValue();
            if (object.strValue != null)
                message.strValue = String(object.strValue);
            if (object.boolValue != null)
                message.boolValue = Boolean(object.boolValue);
            if (object.uintValue != null)
                if ($util.Long)
                    (message.uintValue = $util.Long.fromValue(object.uintValue)).unsigned = true;
                else if (typeof object.uintValue === "string")
                    message.uintValue = parseInt(object.uintValue, 10);
                else if (typeof object.uintValue === "number")
                    message.uintValue = object.uintValue;
                else if (typeof object.uintValue === "object")
                    message.uintValue = new $util.LongBits(object.uintValue.low >>> 0, object.uintValue.high >>> 0).toNumber(true);
            if (object.blobValue != null)
                if (typeof object.blobValue === "string")
                    $util.base64.decode(object.blobValue, message.blobValue = $util.newBuffer($util.base64.length(object.blobValue)), 0);
                else if (object.blobValue.length >= 0)
                    message.blobValue = object.blobValue;
            return message;
        };

        /**
         * Creates a plain object from an OptValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.OptValue
         * @static
         * @param {entities.OptValue} message OptValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OptValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.strValue = "";
                object.boolValue = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.uintValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.uintValue = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.blobValue = "";
                else {
                    object.blobValue = [];
                    if (options.bytes !== Array)
                        object.blobValue = $util.newBuffer(object.blobValue);
                }
            }
            if (message.strValue != null && message.hasOwnProperty("strValue"))
                object.strValue = message.strValue;
            if (message.boolValue != null && message.hasOwnProperty("boolValue"))
                object.boolValue = message.boolValue;
            if (message.uintValue != null && message.hasOwnProperty("uintValue"))
                if (typeof message.uintValue === "number")
                    object.uintValue = options.longs === String ? String(message.uintValue) : message.uintValue;
                else
                    object.uintValue = options.longs === String ? $util.Long.prototype.toString.call(message.uintValue) : options.longs === Number ? new $util.LongBits(message.uintValue.low >>> 0, message.uintValue.high >>> 0).toNumber(true) : message.uintValue;
            if (message.blobValue != null && message.hasOwnProperty("blobValue"))
                object.blobValue = options.bytes === String ? $util.base64.encode(message.blobValue, 0, message.blobValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.blobValue) : message.blobValue;
            return object;
        };

        /**
         * Converts this OptValue to JSON.
         * @function toJSON
         * @memberof entities.OptValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OptValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for OptValue
         * @function getTypeUrl
         * @memberof entities.OptValue
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OptValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.OptValue";
        };

        return OptValue;
    })();

    entities.Features = (function() {

        /**
         * Properties of a Features.
         * @memberof entities
         * @interface IFeatures
         * @property {boolean|null} [billing] Features billing
         * @property {boolean|null} [telegramBot] Features telegramBot
         * @property {boolean|null} [instagram] Features instagram
         */

        /**
         * Constructs a new Features.
         * @memberof entities
         * @classdesc Represents a Features.
         * @implements IFeatures
         * @constructor
         * @param {entities.IFeatures=} [properties] Properties to set
         */
        function Features(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Features billing.
         * @member {boolean} billing
         * @memberof entities.Features
         * @instance
         */
        Features.prototype.billing = false;

        /**
         * Features telegramBot.
         * @member {boolean} telegramBot
         * @memberof entities.Features
         * @instance
         */
        Features.prototype.telegramBot = false;

        /**
         * Features instagram.
         * @member {boolean} instagram
         * @memberof entities.Features
         * @instance
         */
        Features.prototype.instagram = false;

        /**
         * Creates a new Features instance using the specified properties.
         * @function create
         * @memberof entities.Features
         * @static
         * @param {entities.IFeatures=} [properties] Properties to set
         * @returns {entities.Features} Features instance
         */
        Features.create = function create(properties) {
            return new Features(properties);
        };

        /**
         * Encodes the specified Features message. Does not implicitly {@link entities.Features.verify|verify} messages.
         * @function encode
         * @memberof entities.Features
         * @static
         * @param {entities.IFeatures} message Features message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Features.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.billing != null && Object.hasOwnProperty.call(message, "billing"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.billing);
            if (message.telegramBot != null && Object.hasOwnProperty.call(message, "telegramBot"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.telegramBot);
            if (message.instagram != null && Object.hasOwnProperty.call(message, "instagram"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.instagram);
            return writer;
        };

        /**
         * Encodes the specified Features message, length delimited. Does not implicitly {@link entities.Features.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.Features
         * @static
         * @param {entities.IFeatures} message Features message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Features.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Features message from the specified reader or buffer.
         * @function decode
         * @memberof entities.Features
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.Features} Features
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Features.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.Features();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.billing = reader.bool();
                        break;
                    }
                case 2: {
                        message.telegramBot = reader.bool();
                        break;
                    }
                case 3: {
                        message.instagram = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Features message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.Features
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.Features} Features
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Features.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Features message.
         * @function verify
         * @memberof entities.Features
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Features.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.billing != null && message.hasOwnProperty("billing"))
                if (typeof message.billing !== "boolean")
                    return "billing: boolean expected";
            if (message.telegramBot != null && message.hasOwnProperty("telegramBot"))
                if (typeof message.telegramBot !== "boolean")
                    return "telegramBot: boolean expected";
            if (message.instagram != null && message.hasOwnProperty("instagram"))
                if (typeof message.instagram !== "boolean")
                    return "instagram: boolean expected";
            return null;
        };

        /**
         * Creates a Features message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.Features
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.Features} Features
         */
        Features.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.Features)
                return object;
            let message = new $root.entities.Features();
            if (object.billing != null)
                message.billing = Boolean(object.billing);
            if (object.telegramBot != null)
                message.telegramBot = Boolean(object.telegramBot);
            if (object.instagram != null)
                message.instagram = Boolean(object.instagram);
            return message;
        };

        /**
         * Creates a plain object from a Features message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.Features
         * @static
         * @param {entities.Features} message Features
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Features.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.billing = false;
                object.telegramBot = false;
                object.instagram = false;
            }
            if (message.billing != null && message.hasOwnProperty("billing"))
                object.billing = message.billing;
            if (message.telegramBot != null && message.hasOwnProperty("telegramBot"))
                object.telegramBot = message.telegramBot;
            if (message.instagram != null && message.hasOwnProperty("instagram"))
                object.instagram = message.instagram;
            return object;
        };

        /**
         * Converts this Features to JSON.
         * @function toJSON
         * @memberof entities.Features
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Features.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Features
         * @function getTypeUrl
         * @memberof entities.Features
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Features.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.Features";
        };

        return Features;
    })();

    entities.AttachmentSource = (function() {

        /**
         * Properties of an AttachmentSource.
         * @memberof entities
         * @interface IAttachmentSource
         * @property {entities.AttachmentSource.Source|null} [source] AttachmentSource source
         * @property {Uint8Array|null} [reference] AttachmentSource reference
         */

        /**
         * Constructs a new AttachmentSource.
         * @memberof entities
         * @classdesc Represents an AttachmentSource.
         * @implements IAttachmentSource
         * @constructor
         * @param {entities.IAttachmentSource=} [properties] Properties to set
         */
        function AttachmentSource(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AttachmentSource source.
         * @member {entities.AttachmentSource.Source} source
         * @memberof entities.AttachmentSource
         * @instance
         */
        AttachmentSource.prototype.source = 0;

        /**
         * AttachmentSource reference.
         * @member {Uint8Array} reference
         * @memberof entities.AttachmentSource
         * @instance
         */
        AttachmentSource.prototype.reference = $util.newBuffer([]);

        /**
         * Creates a new AttachmentSource instance using the specified properties.
         * @function create
         * @memberof entities.AttachmentSource
         * @static
         * @param {entities.IAttachmentSource=} [properties] Properties to set
         * @returns {entities.AttachmentSource} AttachmentSource instance
         */
        AttachmentSource.create = function create(properties) {
            return new AttachmentSource(properties);
        };

        /**
         * Encodes the specified AttachmentSource message. Does not implicitly {@link entities.AttachmentSource.verify|verify} messages.
         * @function encode
         * @memberof entities.AttachmentSource
         * @static
         * @param {entities.IAttachmentSource} message AttachmentSource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttachmentSource.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.source);
            if (message.reference != null && Object.hasOwnProperty.call(message, "reference"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.reference);
            return writer;
        };

        /**
         * Encodes the specified AttachmentSource message, length delimited. Does not implicitly {@link entities.AttachmentSource.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.AttachmentSource
         * @static
         * @param {entities.IAttachmentSource} message AttachmentSource message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttachmentSource.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AttachmentSource message from the specified reader or buffer.
         * @function decode
         * @memberof entities.AttachmentSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.AttachmentSource} AttachmentSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttachmentSource.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.AttachmentSource();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.source = reader.int32();
                        break;
                    }
                case 2: {
                        message.reference = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AttachmentSource message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.AttachmentSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.AttachmentSource} AttachmentSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttachmentSource.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AttachmentSource message.
         * @function verify
         * @memberof entities.AttachmentSource
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AttachmentSource.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.source != null && message.hasOwnProperty("source"))
                switch (message.source) {
                default:
                    return "source: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.reference != null && message.hasOwnProperty("reference"))
                if (!(message.reference && typeof message.reference.length === "number" || $util.isString(message.reference)))
                    return "reference: buffer expected";
            return null;
        };

        /**
         * Creates an AttachmentSource message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.AttachmentSource
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.AttachmentSource} AttachmentSource
         */
        AttachmentSource.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.AttachmentSource)
                return object;
            let message = new $root.entities.AttachmentSource();
            switch (object.source) {
            default:
                if (typeof object.source === "number") {
                    message.source = object.source;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                message.source = 0;
                break;
            case "BYTESERVER":
            case 1:
                message.source = 1;
                break;
            case "INLINE":
            case 2:
                message.source = 2;
                break;
            }
            if (object.reference != null)
                if (typeof object.reference === "string")
                    $util.base64.decode(object.reference, message.reference = $util.newBuffer($util.base64.length(object.reference)), 0);
                else if (object.reference.length >= 0)
                    message.reference = object.reference;
            return message;
        };

        /**
         * Creates a plain object from an AttachmentSource message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.AttachmentSource
         * @static
         * @param {entities.AttachmentSource} message AttachmentSource
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AttachmentSource.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.source = options.enums === String ? "UNKNOWN" : 0;
                if (options.bytes === String)
                    object.reference = "";
                else {
                    object.reference = [];
                    if (options.bytes !== Array)
                        object.reference = $util.newBuffer(object.reference);
                }
            }
            if (message.source != null && message.hasOwnProperty("source"))
                object.source = options.enums === String ? $root.entities.AttachmentSource.Source[message.source] === undefined ? message.source : $root.entities.AttachmentSource.Source[message.source] : message.source;
            if (message.reference != null && message.hasOwnProperty("reference"))
                object.reference = options.bytes === String ? $util.base64.encode(message.reference, 0, message.reference.length) : options.bytes === Array ? Array.prototype.slice.call(message.reference) : message.reference;
            return object;
        };

        /**
         * Converts this AttachmentSource to JSON.
         * @function toJSON
         * @memberof entities.AttachmentSource
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AttachmentSource.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AttachmentSource
         * @function getTypeUrl
         * @memberof entities.AttachmentSource
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AttachmentSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.AttachmentSource";
        };

        /**
         * Source enum.
         * @name entities.AttachmentSource.Source
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} BYTESERVER=1 BYTESERVER value
         * @property {number} INLINE=2 INLINE value
         */
        AttachmentSource.Source = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "BYTESERVER"] = 1;
            values[valuesById[2] = "INLINE"] = 2;
            return values;
        })();

        return AttachmentSource;
    })();

    entities.Workspace = (function() {

        /**
         * Properties of a Workspace.
         * @memberof entities
         * @interface IWorkspace
         * @property {Uint8Array|null} [id] Workspace id
         * @property {string|null} [name] Workspace name
         * @property {entities.IAttachmentSource|null} [avatar] Workspace avatar
         * @property {string|null} [currentTariffID] Workspace currentTariffID
         * @property {Long|null} [currentTariffExpireAt] Workspace currentTariffExpireAt
         * @property {string|null} [stripeCustomerID] Workspace stripeCustomerID
         * @property {boolean|null} [blocked] Workspace blocked
         */

        /**
         * Constructs a new Workspace.
         * @memberof entities
         * @classdesc Represents a Workspace.
         * @implements IWorkspace
         * @constructor
         * @param {entities.IWorkspace=} [properties] Properties to set
         */
        function Workspace(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Workspace id.
         * @member {Uint8Array} id
         * @memberof entities.Workspace
         * @instance
         */
        Workspace.prototype.id = $util.newBuffer([]);

        /**
         * Workspace name.
         * @member {string} name
         * @memberof entities.Workspace
         * @instance
         */
        Workspace.prototype.name = "";

        /**
         * Workspace avatar.
         * @member {entities.IAttachmentSource|null|undefined} avatar
         * @memberof entities.Workspace
         * @instance
         */
        Workspace.prototype.avatar = null;

        /**
         * Workspace currentTariffID.
         * @member {string} currentTariffID
         * @memberof entities.Workspace
         * @instance
         */
        Workspace.prototype.currentTariffID = "";

        /**
         * Workspace currentTariffExpireAt.
         * @member {Long} currentTariffExpireAt
         * @memberof entities.Workspace
         * @instance
         */
        Workspace.prototype.currentTariffExpireAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Workspace stripeCustomerID.
         * @member {string} stripeCustomerID
         * @memberof entities.Workspace
         * @instance
         */
        Workspace.prototype.stripeCustomerID = "";

        /**
         * Workspace blocked.
         * @member {boolean} blocked
         * @memberof entities.Workspace
         * @instance
         */
        Workspace.prototype.blocked = false;

        /**
         * Creates a new Workspace instance using the specified properties.
         * @function create
         * @memberof entities.Workspace
         * @static
         * @param {entities.IWorkspace=} [properties] Properties to set
         * @returns {entities.Workspace} Workspace instance
         */
        Workspace.create = function create(properties) {
            return new Workspace(properties);
        };

        /**
         * Encodes the specified Workspace message. Does not implicitly {@link entities.Workspace.verify|verify} messages.
         * @function encode
         * @memberof entities.Workspace
         * @static
         * @param {entities.IWorkspace} message Workspace message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Workspace.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                $root.entities.AttachmentSource.encode(message.avatar, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.currentTariffID != null && Object.hasOwnProperty.call(message, "currentTariffID"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.currentTariffID);
            if (message.currentTariffExpireAt != null && Object.hasOwnProperty.call(message, "currentTariffExpireAt"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.currentTariffExpireAt);
            if (message.stripeCustomerID != null && Object.hasOwnProperty.call(message, "stripeCustomerID"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.stripeCustomerID);
            if (message.blocked != null && Object.hasOwnProperty.call(message, "blocked"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.blocked);
            return writer;
        };

        /**
         * Encodes the specified Workspace message, length delimited. Does not implicitly {@link entities.Workspace.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.Workspace
         * @static
         * @param {entities.IWorkspace} message Workspace message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Workspace.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Workspace message from the specified reader or buffer.
         * @function decode
         * @memberof entities.Workspace
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.Workspace} Workspace
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Workspace.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.Workspace();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.bytes();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.avatar = $root.entities.AttachmentSource.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.currentTariffID = reader.string();
                        break;
                    }
                case 5: {
                        message.currentTariffExpireAt = reader.uint64();
                        break;
                    }
                case 6: {
                        message.stripeCustomerID = reader.string();
                        break;
                    }
                case 7: {
                        message.blocked = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Workspace message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.Workspace
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.Workspace} Workspace
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Workspace.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Workspace message.
         * @function verify
         * @memberof entities.Workspace
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Workspace.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.avatar != null && message.hasOwnProperty("avatar")) {
                let error = $root.entities.AttachmentSource.verify(message.avatar);
                if (error)
                    return "avatar." + error;
            }
            if (message.currentTariffID != null && message.hasOwnProperty("currentTariffID"))
                if (!$util.isString(message.currentTariffID))
                    return "currentTariffID: string expected";
            if (message.currentTariffExpireAt != null && message.hasOwnProperty("currentTariffExpireAt"))
                if (!$util.isInteger(message.currentTariffExpireAt) && !(message.currentTariffExpireAt && $util.isInteger(message.currentTariffExpireAt.low) && $util.isInteger(message.currentTariffExpireAt.high)))
                    return "currentTariffExpireAt: integer|Long expected";
            if (message.stripeCustomerID != null && message.hasOwnProperty("stripeCustomerID"))
                if (!$util.isString(message.stripeCustomerID))
                    return "stripeCustomerID: string expected";
            if (message.blocked != null && message.hasOwnProperty("blocked"))
                if (typeof message.blocked !== "boolean")
                    return "blocked: boolean expected";
            return null;
        };

        /**
         * Creates a Workspace message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.Workspace
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.Workspace} Workspace
         */
        Workspace.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.Workspace)
                return object;
            let message = new $root.entities.Workspace();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length >= 0)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            if (object.avatar != null) {
                if (typeof object.avatar !== "object")
                    throw TypeError(".entities.Workspace.avatar: object expected");
                message.avatar = $root.entities.AttachmentSource.fromObject(object.avatar);
            }
            if (object.currentTariffID != null)
                message.currentTariffID = String(object.currentTariffID);
            if (object.currentTariffExpireAt != null)
                if ($util.Long)
                    (message.currentTariffExpireAt = $util.Long.fromValue(object.currentTariffExpireAt)).unsigned = true;
                else if (typeof object.currentTariffExpireAt === "string")
                    message.currentTariffExpireAt = parseInt(object.currentTariffExpireAt, 10);
                else if (typeof object.currentTariffExpireAt === "number")
                    message.currentTariffExpireAt = object.currentTariffExpireAt;
                else if (typeof object.currentTariffExpireAt === "object")
                    message.currentTariffExpireAt = new $util.LongBits(object.currentTariffExpireAt.low >>> 0, object.currentTariffExpireAt.high >>> 0).toNumber(true);
            if (object.stripeCustomerID != null)
                message.stripeCustomerID = String(object.stripeCustomerID);
            if (object.blocked != null)
                message.blocked = Boolean(object.blocked);
            return message;
        };

        /**
         * Creates a plain object from a Workspace message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.Workspace
         * @static
         * @param {entities.Workspace} message Workspace
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Workspace.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
                object.avatar = null;
                object.currentTariffID = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.currentTariffExpireAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.currentTariffExpireAt = options.longs === String ? "0" : 0;
                object.stripeCustomerID = "";
                object.blocked = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.avatar != null && message.hasOwnProperty("avatar"))
                object.avatar = $root.entities.AttachmentSource.toObject(message.avatar, options);
            if (message.currentTariffID != null && message.hasOwnProperty("currentTariffID"))
                object.currentTariffID = message.currentTariffID;
            if (message.currentTariffExpireAt != null && message.hasOwnProperty("currentTariffExpireAt"))
                if (typeof message.currentTariffExpireAt === "number")
                    object.currentTariffExpireAt = options.longs === String ? String(message.currentTariffExpireAt) : message.currentTariffExpireAt;
                else
                    object.currentTariffExpireAt = options.longs === String ? $util.Long.prototype.toString.call(message.currentTariffExpireAt) : options.longs === Number ? new $util.LongBits(message.currentTariffExpireAt.low >>> 0, message.currentTariffExpireAt.high >>> 0).toNumber(true) : message.currentTariffExpireAt;
            if (message.stripeCustomerID != null && message.hasOwnProperty("stripeCustomerID"))
                object.stripeCustomerID = message.stripeCustomerID;
            if (message.blocked != null && message.hasOwnProperty("blocked"))
                object.blocked = message.blocked;
            return object;
        };

        /**
         * Converts this Workspace to JSON.
         * @function toJSON
         * @memberof entities.Workspace
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Workspace.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Workspace
         * @function getTypeUrl
         * @memberof entities.Workspace
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Workspace.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.Workspace";
        };

        return Workspace;
    })();

    /**
     * WorkspaceRole enum.
     * @name entities.WorkspaceRole
     * @enum {number}
     * @property {number} WR_NONE=0 WR_NONE value
     * @property {number} WR_OWNER=1 WR_OWNER value
     * @property {number} WR_ADMIN=2 WR_ADMIN value
     * @property {number} WR_OPERATOR=3 WR_OPERATOR value
     */
    entities.WorkspaceRole = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "WR_NONE"] = 0;
        values[valuesById[1] = "WR_OWNER"] = 1;
        values[valuesById[2] = "WR_ADMIN"] = 2;
        values[valuesById[3] = "WR_OPERATOR"] = 3;
        return values;
    })();

    /**
     * WorkspaceMemberStatus enum.
     * @name entities.WorkspaceMemberStatus
     * @enum {number}
     * @property {number} WM_ACTIVE=0 WM_ACTIVE value
     * @property {number} WM_BLOCKED=1 WM_BLOCKED value
     * @property {number} WM_INVITED=2 WM_INVITED value
     * @property {number} WM_INVITE_EXPIRED=3 WM_INVITE_EXPIRED value
     * @property {number} WM_REMOVED=4 WM_REMOVED value
     */
    entities.WorkspaceMemberStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "WM_ACTIVE"] = 0;
        values[valuesById[1] = "WM_BLOCKED"] = 1;
        values[valuesById[2] = "WM_INVITED"] = 2;
        values[valuesById[3] = "WM_INVITE_EXPIRED"] = 3;
        values[valuesById[4] = "WM_REMOVED"] = 4;
        return values;
    })();

    /**
     * WorkspaceMemberSource enum.
     * @name entities.WorkspaceMemberSource
     * @enum {number}
     * @property {number} WIT_CREATOR=0 WIT_CREATOR value
     * @property {number} WIT_EMAIL_INVITE=1 WIT_EMAIL_INVITE value
     * @property {number} WIT_LINK_INVITE=2 WIT_LINK_INVITE value
     */
    entities.WorkspaceMemberSource = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "WIT_CREATOR"] = 0;
        values[valuesById[1] = "WIT_EMAIL_INVITE"] = 1;
        values[valuesById[2] = "WIT_LINK_INVITE"] = 2;
        return values;
    })();

    /**
     * WorkspaceMemberOptType enum.
     * @name entities.WorkspaceMemberOptType
     * @enum {number}
     * @property {number} WMOT_ACTIVATED_AT=0 WMOT_ACTIVATED_AT value
     * @property {number} WMOT_INVITED_AT=1 WMOT_INVITED_AT value
     * @property {number} WMOT_INVITED_BY=2 WMOT_INVITED_BY value
     * @property {number} WMOT_INVITE_TARGET_EMAIL=3 WMOT_INVITE_TARGET_EMAIL value
     * @property {number} WMOT_INVITE_EXPIRE_AT=4 WMOT_INVITE_EXPIRE_AT value
     * @property {number} WMOT_INVITE_CODE=6 WMOT_INVITE_CODE value
     * @property {number} WMOT_INVITE_LINK=7 WMOT_INVITE_LINK value
     * @property {number} WMOT_INVITE_RESEND_NEXT_TRY=8 WMOT_INVITE_RESEND_NEXT_TRY value
     * @property {number} WMOT_FIRST_CHANNELS_REQUEST=9 WMOT_FIRST_CHANNELS_REQUEST value
     * @property {number} WMOT_BLOCK_REASON=10 WMOT_BLOCK_REASON value
     */
    entities.WorkspaceMemberOptType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "WMOT_ACTIVATED_AT"] = 0;
        values[valuesById[1] = "WMOT_INVITED_AT"] = 1;
        values[valuesById[2] = "WMOT_INVITED_BY"] = 2;
        values[valuesById[3] = "WMOT_INVITE_TARGET_EMAIL"] = 3;
        values[valuesById[4] = "WMOT_INVITE_EXPIRE_AT"] = 4;
        values[valuesById[6] = "WMOT_INVITE_CODE"] = 6;
        values[valuesById[7] = "WMOT_INVITE_LINK"] = 7;
        values[valuesById[8] = "WMOT_INVITE_RESEND_NEXT_TRY"] = 8;
        values[valuesById[9] = "WMOT_FIRST_CHANNELS_REQUEST"] = 9;
        values[valuesById[10] = "WMOT_BLOCK_REASON"] = 10;
        return values;
    })();

    /**
     * WorkspaceMemberPermission enum.
     * @name entities.WorkspaceMemberPermission
     * @enum {number}
     * @property {number} PT_ACCESS_TO_NEW_CHATS=0 PT_ACCESS_TO_NEW_CHATS value
     * @property {number} PT_ACCESS_TO_CONTACT_INFO=1 PT_ACCESS_TO_CONTACT_INFO value
     * @property {number} PT_ACCESS_TO_CONTACTS_LIST=2 PT_ACCESS_TO_CONTACTS_LIST value
     * @property {number} PT_ALLOW_MANAGE_CHATS=3 PT_ALLOW_MANAGE_CHATS value
     */
    entities.WorkspaceMemberPermission = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PT_ACCESS_TO_NEW_CHATS"] = 0;
        values[valuesById[1] = "PT_ACCESS_TO_CONTACT_INFO"] = 1;
        values[valuesById[2] = "PT_ACCESS_TO_CONTACTS_LIST"] = 2;
        values[valuesById[3] = "PT_ALLOW_MANAGE_CHATS"] = 3;
        return values;
    })();

    entities.WorkspaceMemberOpt = (function() {

        /**
         * Properties of a WorkspaceMemberOpt.
         * @memberof entities
         * @interface IWorkspaceMemberOpt
         * @property {entities.WorkspaceMemberOptType|null} [type] WorkspaceMemberOpt type
         * @property {entities.IOptValue|null} [value] WorkspaceMemberOpt value
         */

        /**
         * Constructs a new WorkspaceMemberOpt.
         * @memberof entities
         * @classdesc Represents a WorkspaceMemberOpt.
         * @implements IWorkspaceMemberOpt
         * @constructor
         * @param {entities.IWorkspaceMemberOpt=} [properties] Properties to set
         */
        function WorkspaceMemberOpt(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceMemberOpt type.
         * @member {entities.WorkspaceMemberOptType} type
         * @memberof entities.WorkspaceMemberOpt
         * @instance
         */
        WorkspaceMemberOpt.prototype.type = 0;

        /**
         * WorkspaceMemberOpt value.
         * @member {entities.IOptValue|null|undefined} value
         * @memberof entities.WorkspaceMemberOpt
         * @instance
         */
        WorkspaceMemberOpt.prototype.value = null;

        /**
         * Creates a new WorkspaceMemberOpt instance using the specified properties.
         * @function create
         * @memberof entities.WorkspaceMemberOpt
         * @static
         * @param {entities.IWorkspaceMemberOpt=} [properties] Properties to set
         * @returns {entities.WorkspaceMemberOpt} WorkspaceMemberOpt instance
         */
        WorkspaceMemberOpt.create = function create(properties) {
            return new WorkspaceMemberOpt(properties);
        };

        /**
         * Encodes the specified WorkspaceMemberOpt message. Does not implicitly {@link entities.WorkspaceMemberOpt.verify|verify} messages.
         * @function encode
         * @memberof entities.WorkspaceMemberOpt
         * @static
         * @param {entities.IWorkspaceMemberOpt} message WorkspaceMemberOpt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMemberOpt.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                $root.entities.OptValue.encode(message.value, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceMemberOpt message, length delimited. Does not implicitly {@link entities.WorkspaceMemberOpt.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.WorkspaceMemberOpt
         * @static
         * @param {entities.IWorkspaceMemberOpt} message WorkspaceMemberOpt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMemberOpt.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceMemberOpt message from the specified reader or buffer.
         * @function decode
         * @memberof entities.WorkspaceMemberOpt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.WorkspaceMemberOpt} WorkspaceMemberOpt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMemberOpt.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.WorkspaceMemberOpt();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.value = $root.entities.OptValue.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceMemberOpt message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.WorkspaceMemberOpt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.WorkspaceMemberOpt} WorkspaceMemberOpt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMemberOpt.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceMemberOpt message.
         * @function verify
         * @memberof entities.WorkspaceMemberOpt
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceMemberOpt.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                    break;
                }
            if (message.value != null && message.hasOwnProperty("value")) {
                let error = $root.entities.OptValue.verify(message.value);
                if (error)
                    return "value." + error;
            }
            return null;
        };

        /**
         * Creates a WorkspaceMemberOpt message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.WorkspaceMemberOpt
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.WorkspaceMemberOpt} WorkspaceMemberOpt
         */
        WorkspaceMemberOpt.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.WorkspaceMemberOpt)
                return object;
            let message = new $root.entities.WorkspaceMemberOpt();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "WMOT_ACTIVATED_AT":
            case 0:
                message.type = 0;
                break;
            case "WMOT_INVITED_AT":
            case 1:
                message.type = 1;
                break;
            case "WMOT_INVITED_BY":
            case 2:
                message.type = 2;
                break;
            case "WMOT_INVITE_TARGET_EMAIL":
            case 3:
                message.type = 3;
                break;
            case "WMOT_INVITE_EXPIRE_AT":
            case 4:
                message.type = 4;
                break;
            case "WMOT_INVITE_CODE":
            case 6:
                message.type = 6;
                break;
            case "WMOT_INVITE_LINK":
            case 7:
                message.type = 7;
                break;
            case "WMOT_INVITE_RESEND_NEXT_TRY":
            case 8:
                message.type = 8;
                break;
            case "WMOT_FIRST_CHANNELS_REQUEST":
            case 9:
                message.type = 9;
                break;
            case "WMOT_BLOCK_REASON":
            case 10:
                message.type = 10;
                break;
            }
            if (object.value != null) {
                if (typeof object.value !== "object")
                    throw TypeError(".entities.WorkspaceMemberOpt.value: object expected");
                message.value = $root.entities.OptValue.fromObject(object.value);
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceMemberOpt message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.WorkspaceMemberOpt
         * @static
         * @param {entities.WorkspaceMemberOpt} message WorkspaceMemberOpt
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceMemberOpt.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "WMOT_ACTIVATED_AT" : 0;
                object.value = null;
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.entities.WorkspaceMemberOptType[message.type] === undefined ? message.type : $root.entities.WorkspaceMemberOptType[message.type] : message.type;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = $root.entities.OptValue.toObject(message.value, options);
            return object;
        };

        /**
         * Converts this WorkspaceMemberOpt to JSON.
         * @function toJSON
         * @memberof entities.WorkspaceMemberOpt
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceMemberOpt.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceMemberOpt
         * @function getTypeUrl
         * @memberof entities.WorkspaceMemberOpt
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceMemberOpt.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.WorkspaceMemberOpt";
        };

        return WorkspaceMemberOpt;
    })();

    entities.WorkspaceMemberAccessRecord = (function() {

        /**
         * Properties of a WorkspaceMemberAccessRecord.
         * @memberof entities
         * @interface IWorkspaceMemberAccessRecord
         * @property {Uint8Array|null} [channelID] WorkspaceMemberAccessRecord channelID
         * @property {boolean|null} [disableChannel] WorkspaceMemberAccessRecord disableChannel
         * @property {Array.<Long>|null} [chatsAllowList] WorkspaceMemberAccessRecord chatsAllowList
         * @property {Array.<Long>|null} [chatsDenyList] WorkspaceMemberAccessRecord chatsDenyList
         */

        /**
         * Constructs a new WorkspaceMemberAccessRecord.
         * @memberof entities
         * @classdesc Represents a WorkspaceMemberAccessRecord.
         * @implements IWorkspaceMemberAccessRecord
         * @constructor
         * @param {entities.IWorkspaceMemberAccessRecord=} [properties] Properties to set
         */
        function WorkspaceMemberAccessRecord(properties) {
            this.chatsAllowList = [];
            this.chatsDenyList = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceMemberAccessRecord channelID.
         * @member {Uint8Array} channelID
         * @memberof entities.WorkspaceMemberAccessRecord
         * @instance
         */
        WorkspaceMemberAccessRecord.prototype.channelID = $util.newBuffer([]);

        /**
         * WorkspaceMemberAccessRecord disableChannel.
         * @member {boolean} disableChannel
         * @memberof entities.WorkspaceMemberAccessRecord
         * @instance
         */
        WorkspaceMemberAccessRecord.prototype.disableChannel = false;

        /**
         * WorkspaceMemberAccessRecord chatsAllowList.
         * @member {Array.<Long>} chatsAllowList
         * @memberof entities.WorkspaceMemberAccessRecord
         * @instance
         */
        WorkspaceMemberAccessRecord.prototype.chatsAllowList = $util.emptyArray;

        /**
         * WorkspaceMemberAccessRecord chatsDenyList.
         * @member {Array.<Long>} chatsDenyList
         * @memberof entities.WorkspaceMemberAccessRecord
         * @instance
         */
        WorkspaceMemberAccessRecord.prototype.chatsDenyList = $util.emptyArray;

        /**
         * Creates a new WorkspaceMemberAccessRecord instance using the specified properties.
         * @function create
         * @memberof entities.WorkspaceMemberAccessRecord
         * @static
         * @param {entities.IWorkspaceMemberAccessRecord=} [properties] Properties to set
         * @returns {entities.WorkspaceMemberAccessRecord} WorkspaceMemberAccessRecord instance
         */
        WorkspaceMemberAccessRecord.create = function create(properties) {
            return new WorkspaceMemberAccessRecord(properties);
        };

        /**
         * Encodes the specified WorkspaceMemberAccessRecord message. Does not implicitly {@link entities.WorkspaceMemberAccessRecord.verify|verify} messages.
         * @function encode
         * @memberof entities.WorkspaceMemberAccessRecord
         * @static
         * @param {entities.IWorkspaceMemberAccessRecord} message WorkspaceMemberAccessRecord message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMemberAccessRecord.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
            if (message.disableChannel != null && Object.hasOwnProperty.call(message, "disableChannel"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.disableChannel);
            if (message.chatsAllowList != null && message.chatsAllowList.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (let i = 0; i < message.chatsAllowList.length; ++i)
                    writer.uint64(message.chatsAllowList[i]);
                writer.ldelim();
            }
            if (message.chatsDenyList != null && message.chatsDenyList.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (let i = 0; i < message.chatsDenyList.length; ++i)
                    writer.uint64(message.chatsDenyList[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified WorkspaceMemberAccessRecord message, length delimited. Does not implicitly {@link entities.WorkspaceMemberAccessRecord.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.WorkspaceMemberAccessRecord
         * @static
         * @param {entities.IWorkspaceMemberAccessRecord} message WorkspaceMemberAccessRecord message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMemberAccessRecord.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceMemberAccessRecord message from the specified reader or buffer.
         * @function decode
         * @memberof entities.WorkspaceMemberAccessRecord
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.WorkspaceMemberAccessRecord} WorkspaceMemberAccessRecord
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMemberAccessRecord.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.WorkspaceMemberAccessRecord();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelID = reader.bytes();
                        break;
                    }
                case 2: {
                        message.disableChannel = reader.bool();
                        break;
                    }
                case 3: {
                        if (!(message.chatsAllowList && message.chatsAllowList.length))
                            message.chatsAllowList = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.chatsAllowList.push(reader.uint64());
                        } else
                            message.chatsAllowList.push(reader.uint64());
                        break;
                    }
                case 4: {
                        if (!(message.chatsDenyList && message.chatsDenyList.length))
                            message.chatsDenyList = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.chatsDenyList.push(reader.uint64());
                        } else
                            message.chatsDenyList.push(reader.uint64());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceMemberAccessRecord message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.WorkspaceMemberAccessRecord
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.WorkspaceMemberAccessRecord} WorkspaceMemberAccessRecord
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMemberAccessRecord.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceMemberAccessRecord message.
         * @function verify
         * @memberof entities.WorkspaceMemberAccessRecord
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceMemberAccessRecord.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.channelID != null && message.hasOwnProperty("channelID"))
                if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                    return "channelID: buffer expected";
            if (message.disableChannel != null && message.hasOwnProperty("disableChannel"))
                if (typeof message.disableChannel !== "boolean")
                    return "disableChannel: boolean expected";
            if (message.chatsAllowList != null && message.hasOwnProperty("chatsAllowList")) {
                if (!Array.isArray(message.chatsAllowList))
                    return "chatsAllowList: array expected";
                for (let i = 0; i < message.chatsAllowList.length; ++i)
                    if (!$util.isInteger(message.chatsAllowList[i]) && !(message.chatsAllowList[i] && $util.isInteger(message.chatsAllowList[i].low) && $util.isInteger(message.chatsAllowList[i].high)))
                        return "chatsAllowList: integer|Long[] expected";
            }
            if (message.chatsDenyList != null && message.hasOwnProperty("chatsDenyList")) {
                if (!Array.isArray(message.chatsDenyList))
                    return "chatsDenyList: array expected";
                for (let i = 0; i < message.chatsDenyList.length; ++i)
                    if (!$util.isInteger(message.chatsDenyList[i]) && !(message.chatsDenyList[i] && $util.isInteger(message.chatsDenyList[i].low) && $util.isInteger(message.chatsDenyList[i].high)))
                        return "chatsDenyList: integer|Long[] expected";
            }
            return null;
        };

        /**
         * Creates a WorkspaceMemberAccessRecord message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.WorkspaceMemberAccessRecord
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.WorkspaceMemberAccessRecord} WorkspaceMemberAccessRecord
         */
        WorkspaceMemberAccessRecord.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.WorkspaceMemberAccessRecord)
                return object;
            let message = new $root.entities.WorkspaceMemberAccessRecord();
            if (object.channelID != null)
                if (typeof object.channelID === "string")
                    $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
                else if (object.channelID.length >= 0)
                    message.channelID = object.channelID;
            if (object.disableChannel != null)
                message.disableChannel = Boolean(object.disableChannel);
            if (object.chatsAllowList) {
                if (!Array.isArray(object.chatsAllowList))
                    throw TypeError(".entities.WorkspaceMemberAccessRecord.chatsAllowList: array expected");
                message.chatsAllowList = [];
                for (let i = 0; i < object.chatsAllowList.length; ++i)
                    if ($util.Long)
                        (message.chatsAllowList[i] = $util.Long.fromValue(object.chatsAllowList[i])).unsigned = true;
                    else if (typeof object.chatsAllowList[i] === "string")
                        message.chatsAllowList[i] = parseInt(object.chatsAllowList[i], 10);
                    else if (typeof object.chatsAllowList[i] === "number")
                        message.chatsAllowList[i] = object.chatsAllowList[i];
                    else if (typeof object.chatsAllowList[i] === "object")
                        message.chatsAllowList[i] = new $util.LongBits(object.chatsAllowList[i].low >>> 0, object.chatsAllowList[i].high >>> 0).toNumber(true);
            }
            if (object.chatsDenyList) {
                if (!Array.isArray(object.chatsDenyList))
                    throw TypeError(".entities.WorkspaceMemberAccessRecord.chatsDenyList: array expected");
                message.chatsDenyList = [];
                for (let i = 0; i < object.chatsDenyList.length; ++i)
                    if ($util.Long)
                        (message.chatsDenyList[i] = $util.Long.fromValue(object.chatsDenyList[i])).unsigned = true;
                    else if (typeof object.chatsDenyList[i] === "string")
                        message.chatsDenyList[i] = parseInt(object.chatsDenyList[i], 10);
                    else if (typeof object.chatsDenyList[i] === "number")
                        message.chatsDenyList[i] = object.chatsDenyList[i];
                    else if (typeof object.chatsDenyList[i] === "object")
                        message.chatsDenyList[i] = new $util.LongBits(object.chatsDenyList[i].low >>> 0, object.chatsDenyList[i].high >>> 0).toNumber(true);
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceMemberAccessRecord message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.WorkspaceMemberAccessRecord
         * @static
         * @param {entities.WorkspaceMemberAccessRecord} message WorkspaceMemberAccessRecord
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceMemberAccessRecord.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.chatsAllowList = [];
                object.chatsDenyList = [];
            }
            if (options.defaults) {
                if (options.bytes === String)
                    object.channelID = "";
                else {
                    object.channelID = [];
                    if (options.bytes !== Array)
                        object.channelID = $util.newBuffer(object.channelID);
                }
                object.disableChannel = false;
            }
            if (message.channelID != null && message.hasOwnProperty("channelID"))
                object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
            if (message.disableChannel != null && message.hasOwnProperty("disableChannel"))
                object.disableChannel = message.disableChannel;
            if (message.chatsAllowList && message.chatsAllowList.length) {
                object.chatsAllowList = [];
                for (let j = 0; j < message.chatsAllowList.length; ++j)
                    if (typeof message.chatsAllowList[j] === "number")
                        object.chatsAllowList[j] = options.longs === String ? String(message.chatsAllowList[j]) : message.chatsAllowList[j];
                    else
                        object.chatsAllowList[j] = options.longs === String ? $util.Long.prototype.toString.call(message.chatsAllowList[j]) : options.longs === Number ? new $util.LongBits(message.chatsAllowList[j].low >>> 0, message.chatsAllowList[j].high >>> 0).toNumber(true) : message.chatsAllowList[j];
            }
            if (message.chatsDenyList && message.chatsDenyList.length) {
                object.chatsDenyList = [];
                for (let j = 0; j < message.chatsDenyList.length; ++j)
                    if (typeof message.chatsDenyList[j] === "number")
                        object.chatsDenyList[j] = options.longs === String ? String(message.chatsDenyList[j]) : message.chatsDenyList[j];
                    else
                        object.chatsDenyList[j] = options.longs === String ? $util.Long.prototype.toString.call(message.chatsDenyList[j]) : options.longs === Number ? new $util.LongBits(message.chatsDenyList[j].low >>> 0, message.chatsDenyList[j].high >>> 0).toNumber(true) : message.chatsDenyList[j];
            }
            return object;
        };

        /**
         * Converts this WorkspaceMemberAccessRecord to JSON.
         * @function toJSON
         * @memberof entities.WorkspaceMemberAccessRecord
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceMemberAccessRecord.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceMemberAccessRecord
         * @function getTypeUrl
         * @memberof entities.WorkspaceMemberAccessRecord
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceMemberAccessRecord.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.WorkspaceMemberAccessRecord";
        };

        return WorkspaceMemberAccessRecord;
    })();

    /**
     * WorkspaceMemberBlockReason enum.
     * @name entities.WorkspaceMemberBlockReason
     * @enum {number}
     * @property {number} WMSCR_DEFAULT=0 WMSCR_DEFAULT value
     * @property {number} WMSCR_MANUAL=1 WMSCR_MANUAL value
     * @property {number} WMSCR_ON_CHANGE_TARIFF=2 WMSCR_ON_CHANGE_TARIFF value
     */
    entities.WorkspaceMemberBlockReason = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "WMSCR_DEFAULT"] = 0;
        values[valuesById[1] = "WMSCR_MANUAL"] = 1;
        values[valuesById[2] = "WMSCR_ON_CHANGE_TARIFF"] = 2;
        return values;
    })();

    entities.WorkspaceMemberAccess = (function() {

        /**
         * Properties of a WorkspaceMemberAccess.
         * @memberof entities
         * @interface IWorkspaceMemberAccess
         * @property {Array.<entities.IWorkspaceMemberAccessRecord>|null} [records] WorkspaceMemberAccess records
         */

        /**
         * Constructs a new WorkspaceMemberAccess.
         * @memberof entities
         * @classdesc Represents a WorkspaceMemberAccess.
         * @implements IWorkspaceMemberAccess
         * @constructor
         * @param {entities.IWorkspaceMemberAccess=} [properties] Properties to set
         */
        function WorkspaceMemberAccess(properties) {
            this.records = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceMemberAccess records.
         * @member {Array.<entities.IWorkspaceMemberAccessRecord>} records
         * @memberof entities.WorkspaceMemberAccess
         * @instance
         */
        WorkspaceMemberAccess.prototype.records = $util.emptyArray;

        /**
         * Creates a new WorkspaceMemberAccess instance using the specified properties.
         * @function create
         * @memberof entities.WorkspaceMemberAccess
         * @static
         * @param {entities.IWorkspaceMemberAccess=} [properties] Properties to set
         * @returns {entities.WorkspaceMemberAccess} WorkspaceMemberAccess instance
         */
        WorkspaceMemberAccess.create = function create(properties) {
            return new WorkspaceMemberAccess(properties);
        };

        /**
         * Encodes the specified WorkspaceMemberAccess message. Does not implicitly {@link entities.WorkspaceMemberAccess.verify|verify} messages.
         * @function encode
         * @memberof entities.WorkspaceMemberAccess
         * @static
         * @param {entities.IWorkspaceMemberAccess} message WorkspaceMemberAccess message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMemberAccess.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.records != null && message.records.length)
                for (let i = 0; i < message.records.length; ++i)
                    $root.entities.WorkspaceMemberAccessRecord.encode(message.records[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceMemberAccess message, length delimited. Does not implicitly {@link entities.WorkspaceMemberAccess.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.WorkspaceMemberAccess
         * @static
         * @param {entities.IWorkspaceMemberAccess} message WorkspaceMemberAccess message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMemberAccess.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceMemberAccess message from the specified reader or buffer.
         * @function decode
         * @memberof entities.WorkspaceMemberAccess
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.WorkspaceMemberAccess} WorkspaceMemberAccess
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMemberAccess.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.WorkspaceMemberAccess();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.records && message.records.length))
                            message.records = [];
                        message.records.push($root.entities.WorkspaceMemberAccessRecord.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceMemberAccess message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.WorkspaceMemberAccess
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.WorkspaceMemberAccess} WorkspaceMemberAccess
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMemberAccess.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceMemberAccess message.
         * @function verify
         * @memberof entities.WorkspaceMemberAccess
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceMemberAccess.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.records != null && message.hasOwnProperty("records")) {
                if (!Array.isArray(message.records))
                    return "records: array expected";
                for (let i = 0; i < message.records.length; ++i) {
                    let error = $root.entities.WorkspaceMemberAccessRecord.verify(message.records[i]);
                    if (error)
                        return "records." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WorkspaceMemberAccess message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.WorkspaceMemberAccess
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.WorkspaceMemberAccess} WorkspaceMemberAccess
         */
        WorkspaceMemberAccess.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.WorkspaceMemberAccess)
                return object;
            let message = new $root.entities.WorkspaceMemberAccess();
            if (object.records) {
                if (!Array.isArray(object.records))
                    throw TypeError(".entities.WorkspaceMemberAccess.records: array expected");
                message.records = [];
                for (let i = 0; i < object.records.length; ++i) {
                    if (typeof object.records[i] !== "object")
                        throw TypeError(".entities.WorkspaceMemberAccess.records: object expected");
                    message.records[i] = $root.entities.WorkspaceMemberAccessRecord.fromObject(object.records[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceMemberAccess message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.WorkspaceMemberAccess
         * @static
         * @param {entities.WorkspaceMemberAccess} message WorkspaceMemberAccess
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceMemberAccess.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.records = [];
            if (message.records && message.records.length) {
                object.records = [];
                for (let j = 0; j < message.records.length; ++j)
                    object.records[j] = $root.entities.WorkspaceMemberAccessRecord.toObject(message.records[j], options);
            }
            return object;
        };

        /**
         * Converts this WorkspaceMemberAccess to JSON.
         * @function toJSON
         * @memberof entities.WorkspaceMemberAccess
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceMemberAccess.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceMemberAccess
         * @function getTypeUrl
         * @memberof entities.WorkspaceMemberAccess
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceMemberAccess.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.WorkspaceMemberAccess";
        };

        return WorkspaceMemberAccess;
    })();

    entities.WorkspaceMember = (function() {

        /**
         * Properties of a WorkspaceMember.
         * @memberof entities
         * @interface IWorkspaceMember
         * @property {Long|null} [id] WorkspaceMember id
         * @property {entities.IUserShortProfile|null} [profile] WorkspaceMember profile
         * @property {entities.WorkspaceRole|null} [role] WorkspaceMember role
         * @property {entities.WorkspaceMemberStatus|null} [status] WorkspaceMember status
         * @property {Long|null} [createdAt] WorkspaceMember createdAt
         * @property {entities.WorkspaceMemberSource|null} [source] WorkspaceMember source
         * @property {entities.IWorkspaceMemberAccess|null} [access] WorkspaceMember access
         * @property {entities.WorkspaceMemberBlockReason|null} [blockReason] WorkspaceMember blockReason
         * @property {Array.<entities.IWorkspaceMemberOpt>|null} [opts] WorkspaceMember opts
         * @property {Array.<entities.WorkspaceMemberPermission>|null} [permissions] WorkspaceMember permissions
         */

        /**
         * Constructs a new WorkspaceMember.
         * @memberof entities
         * @classdesc Represents a WorkspaceMember.
         * @implements IWorkspaceMember
         * @constructor
         * @param {entities.IWorkspaceMember=} [properties] Properties to set
         */
        function WorkspaceMember(properties) {
            this.opts = [];
            this.permissions = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceMember id.
         * @member {Long} id
         * @memberof entities.WorkspaceMember
         * @instance
         */
        WorkspaceMember.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * WorkspaceMember profile.
         * @member {entities.IUserShortProfile|null|undefined} profile
         * @memberof entities.WorkspaceMember
         * @instance
         */
        WorkspaceMember.prototype.profile = null;

        /**
         * WorkspaceMember role.
         * @member {entities.WorkspaceRole} role
         * @memberof entities.WorkspaceMember
         * @instance
         */
        WorkspaceMember.prototype.role = 0;

        /**
         * WorkspaceMember status.
         * @member {entities.WorkspaceMemberStatus} status
         * @memberof entities.WorkspaceMember
         * @instance
         */
        WorkspaceMember.prototype.status = 0;

        /**
         * WorkspaceMember createdAt.
         * @member {Long} createdAt
         * @memberof entities.WorkspaceMember
         * @instance
         */
        WorkspaceMember.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * WorkspaceMember source.
         * @member {entities.WorkspaceMemberSource} source
         * @memberof entities.WorkspaceMember
         * @instance
         */
        WorkspaceMember.prototype.source = 0;

        /**
         * WorkspaceMember access.
         * @member {entities.IWorkspaceMemberAccess|null|undefined} access
         * @memberof entities.WorkspaceMember
         * @instance
         */
        WorkspaceMember.prototype.access = null;

        /**
         * WorkspaceMember blockReason.
         * @member {entities.WorkspaceMemberBlockReason} blockReason
         * @memberof entities.WorkspaceMember
         * @instance
         */
        WorkspaceMember.prototype.blockReason = 0;

        /**
         * WorkspaceMember opts.
         * @member {Array.<entities.IWorkspaceMemberOpt>} opts
         * @memberof entities.WorkspaceMember
         * @instance
         */
        WorkspaceMember.prototype.opts = $util.emptyArray;

        /**
         * WorkspaceMember permissions.
         * @member {Array.<entities.WorkspaceMemberPermission>} permissions
         * @memberof entities.WorkspaceMember
         * @instance
         */
        WorkspaceMember.prototype.permissions = $util.emptyArray;

        /**
         * Creates a new WorkspaceMember instance using the specified properties.
         * @function create
         * @memberof entities.WorkspaceMember
         * @static
         * @param {entities.IWorkspaceMember=} [properties] Properties to set
         * @returns {entities.WorkspaceMember} WorkspaceMember instance
         */
        WorkspaceMember.create = function create(properties) {
            return new WorkspaceMember(properties);
        };

        /**
         * Encodes the specified WorkspaceMember message. Does not implicitly {@link entities.WorkspaceMember.verify|verify} messages.
         * @function encode
         * @memberof entities.WorkspaceMember
         * @static
         * @param {entities.IWorkspaceMember} message WorkspaceMember message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMember.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                $root.entities.UserShortProfile.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.role);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.status);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.createdAt);
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.source);
            if (message.access != null && Object.hasOwnProperty.call(message, "access"))
                $root.entities.WorkspaceMemberAccess.encode(message.access, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.blockReason != null && Object.hasOwnProperty.call(message, "blockReason"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.blockReason);
            if (message.opts != null && message.opts.length)
                for (let i = 0; i < message.opts.length; ++i)
                    $root.entities.WorkspaceMemberOpt.encode(message.opts[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.permissions != null && message.permissions.length) {
                writer.uint32(/* id 11, wireType 2 =*/90).fork();
                for (let i = 0; i < message.permissions.length; ++i)
                    writer.int32(message.permissions[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified WorkspaceMember message, length delimited. Does not implicitly {@link entities.WorkspaceMember.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.WorkspaceMember
         * @static
         * @param {entities.IWorkspaceMember} message WorkspaceMember message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceMember.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceMember message from the specified reader or buffer.
         * @function decode
         * @memberof entities.WorkspaceMember
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.WorkspaceMember} WorkspaceMember
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMember.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.WorkspaceMember();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.profile = $root.entities.UserShortProfile.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.role = reader.int32();
                        break;
                    }
                case 4: {
                        message.status = reader.int32();
                        break;
                    }
                case 5: {
                        message.createdAt = reader.uint64();
                        break;
                    }
                case 6: {
                        message.source = reader.int32();
                        break;
                    }
                case 7: {
                        message.access = $root.entities.WorkspaceMemberAccess.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.blockReason = reader.int32();
                        break;
                    }
                case 10: {
                        if (!(message.opts && message.opts.length))
                            message.opts = [];
                        message.opts.push($root.entities.WorkspaceMemberOpt.decode(reader, reader.uint32()));
                        break;
                    }
                case 11: {
                        if (!(message.permissions && message.permissions.length))
                            message.permissions = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.permissions.push(reader.int32());
                        } else
                            message.permissions.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceMember message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.WorkspaceMember
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.WorkspaceMember} WorkspaceMember
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceMember.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceMember message.
         * @function verify
         * @memberof entities.WorkspaceMember
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceMember.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                    return "id: integer|Long expected";
            if (message.profile != null && message.hasOwnProperty("profile")) {
                let error = $root.entities.UserShortProfile.verify(message.profile);
                if (error)
                    return "profile." + error;
            }
            if (message.role != null && message.hasOwnProperty("role"))
                switch (message.role) {
                default:
                    return "role: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                    return "createdAt: integer|Long expected";
            if (message.source != null && message.hasOwnProperty("source"))
                switch (message.source) {
                default:
                    return "source: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.access != null && message.hasOwnProperty("access")) {
                let error = $root.entities.WorkspaceMemberAccess.verify(message.access);
                if (error)
                    return "access." + error;
            }
            if (message.blockReason != null && message.hasOwnProperty("blockReason"))
                switch (message.blockReason) {
                default:
                    return "blockReason: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.opts != null && message.hasOwnProperty("opts")) {
                if (!Array.isArray(message.opts))
                    return "opts: array expected";
                for (let i = 0; i < message.opts.length; ++i) {
                    let error = $root.entities.WorkspaceMemberOpt.verify(message.opts[i]);
                    if (error)
                        return "opts." + error;
                }
            }
            if (message.permissions != null && message.hasOwnProperty("permissions")) {
                if (!Array.isArray(message.permissions))
                    return "permissions: array expected";
                for (let i = 0; i < message.permissions.length; ++i)
                    switch (message.permissions[i]) {
                    default:
                        return "permissions: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
            }
            return null;
        };

        /**
         * Creates a WorkspaceMember message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.WorkspaceMember
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.WorkspaceMember} WorkspaceMember
         */
        WorkspaceMember.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.WorkspaceMember)
                return object;
            let message = new $root.entities.WorkspaceMember();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.profile != null) {
                if (typeof object.profile !== "object")
                    throw TypeError(".entities.WorkspaceMember.profile: object expected");
                message.profile = $root.entities.UserShortProfile.fromObject(object.profile);
            }
            switch (object.role) {
            default:
                if (typeof object.role === "number") {
                    message.role = object.role;
                    break;
                }
                break;
            case "WR_NONE":
            case 0:
                message.role = 0;
                break;
            case "WR_OWNER":
            case 1:
                message.role = 1;
                break;
            case "WR_ADMIN":
            case 2:
                message.role = 2;
                break;
            case "WR_OPERATOR":
            case 3:
                message.role = 3;
                break;
            }
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "WM_ACTIVE":
            case 0:
                message.status = 0;
                break;
            case "WM_BLOCKED":
            case 1:
                message.status = 1;
                break;
            case "WM_INVITED":
            case 2:
                message.status = 2;
                break;
            case "WM_INVITE_EXPIRED":
            case 3:
                message.status = 3;
                break;
            case "WM_REMOVED":
            case 4:
                message.status = 4;
                break;
            }
            if (object.createdAt != null)
                if ($util.Long)
                    (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                else if (typeof object.createdAt === "string")
                    message.createdAt = parseInt(object.createdAt, 10);
                else if (typeof object.createdAt === "number")
                    message.createdAt = object.createdAt;
                else if (typeof object.createdAt === "object")
                    message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
            switch (object.source) {
            default:
                if (typeof object.source === "number") {
                    message.source = object.source;
                    break;
                }
                break;
            case "WIT_CREATOR":
            case 0:
                message.source = 0;
                break;
            case "WIT_EMAIL_INVITE":
            case 1:
                message.source = 1;
                break;
            case "WIT_LINK_INVITE":
            case 2:
                message.source = 2;
                break;
            }
            if (object.access != null) {
                if (typeof object.access !== "object")
                    throw TypeError(".entities.WorkspaceMember.access: object expected");
                message.access = $root.entities.WorkspaceMemberAccess.fromObject(object.access);
            }
            switch (object.blockReason) {
            default:
                if (typeof object.blockReason === "number") {
                    message.blockReason = object.blockReason;
                    break;
                }
                break;
            case "WMSCR_DEFAULT":
            case 0:
                message.blockReason = 0;
                break;
            case "WMSCR_MANUAL":
            case 1:
                message.blockReason = 1;
                break;
            case "WMSCR_ON_CHANGE_TARIFF":
            case 2:
                message.blockReason = 2;
                break;
            }
            if (object.opts) {
                if (!Array.isArray(object.opts))
                    throw TypeError(".entities.WorkspaceMember.opts: array expected");
                message.opts = [];
                for (let i = 0; i < object.opts.length; ++i) {
                    if (typeof object.opts[i] !== "object")
                        throw TypeError(".entities.WorkspaceMember.opts: object expected");
                    message.opts[i] = $root.entities.WorkspaceMemberOpt.fromObject(object.opts[i]);
                }
            }
            if (object.permissions) {
                if (!Array.isArray(object.permissions))
                    throw TypeError(".entities.WorkspaceMember.permissions: array expected");
                message.permissions = [];
                for (let i = 0; i < object.permissions.length; ++i)
                    switch (object.permissions[i]) {
                    default:
                        if (typeof object.permissions[i] === "number") {
                            message.permissions[i] = object.permissions[i];
                            break;
                        }
                    case "PT_ACCESS_TO_NEW_CHATS":
                    case 0:
                        message.permissions[i] = 0;
                        break;
                    case "PT_ACCESS_TO_CONTACT_INFO":
                    case 1:
                        message.permissions[i] = 1;
                        break;
                    case "PT_ACCESS_TO_CONTACTS_LIST":
                    case 2:
                        message.permissions[i] = 2;
                        break;
                    case "PT_ALLOW_MANAGE_CHATS":
                    case 3:
                        message.permissions[i] = 3;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceMember message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.WorkspaceMember
         * @static
         * @param {entities.WorkspaceMember} message WorkspaceMember
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceMember.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.opts = [];
                object.permissions = [];
            }
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.profile = null;
                object.role = options.enums === String ? "WR_NONE" : 0;
                object.status = options.enums === String ? "WM_ACTIVE" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createdAt = options.longs === String ? "0" : 0;
                object.source = options.enums === String ? "WIT_CREATOR" : 0;
                object.access = null;
                object.blockReason = options.enums === String ? "WMSCR_DEFAULT" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.profile != null && message.hasOwnProperty("profile"))
                object.profile = $root.entities.UserShortProfile.toObject(message.profile, options);
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = options.enums === String ? $root.entities.WorkspaceRole[message.role] === undefined ? message.role : $root.entities.WorkspaceRole[message.role] : message.role;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.entities.WorkspaceMemberStatus[message.status] === undefined ? message.status : $root.entities.WorkspaceMemberStatus[message.status] : message.status;
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (typeof message.createdAt === "number")
                    object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                else
                    object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
            if (message.source != null && message.hasOwnProperty("source"))
                object.source = options.enums === String ? $root.entities.WorkspaceMemberSource[message.source] === undefined ? message.source : $root.entities.WorkspaceMemberSource[message.source] : message.source;
            if (message.access != null && message.hasOwnProperty("access"))
                object.access = $root.entities.WorkspaceMemberAccess.toObject(message.access, options);
            if (message.blockReason != null && message.hasOwnProperty("blockReason"))
                object.blockReason = options.enums === String ? $root.entities.WorkspaceMemberBlockReason[message.blockReason] === undefined ? message.blockReason : $root.entities.WorkspaceMemberBlockReason[message.blockReason] : message.blockReason;
            if (message.opts && message.opts.length) {
                object.opts = [];
                for (let j = 0; j < message.opts.length; ++j)
                    object.opts[j] = $root.entities.WorkspaceMemberOpt.toObject(message.opts[j], options);
            }
            if (message.permissions && message.permissions.length) {
                object.permissions = [];
                for (let j = 0; j < message.permissions.length; ++j)
                    object.permissions[j] = options.enums === String ? $root.entities.WorkspaceMemberPermission[message.permissions[j]] === undefined ? message.permissions[j] : $root.entities.WorkspaceMemberPermission[message.permissions[j]] : message.permissions[j];
            }
            return object;
        };

        /**
         * Converts this WorkspaceMember to JSON.
         * @function toJSON
         * @memberof entities.WorkspaceMember
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceMember.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceMember
         * @function getTypeUrl
         * @memberof entities.WorkspaceMember
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceMember.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.WorkspaceMember";
        };

        return WorkspaceMember;
    })();

    /**
     * WorkspaceInviteResult enum.
     * @name entities.WorkspaceInviteResult
     * @enum {number}
     * @property {number} WIR_SUCCESS=0 WIR_SUCCESS value
     * @property {number} WIR_INVALID_EMAIL=1 WIR_INVALID_EMAIL value
     * @property {number} WIR_ALREADY_INVITED=2 WIR_ALREADY_INVITED value
     * @property {number} WIR_INVALID_ROLE=3 WIR_INVALID_ROLE value
     */
    entities.WorkspaceInviteResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "WIR_SUCCESS"] = 0;
        values[valuesById[1] = "WIR_INVALID_EMAIL"] = 1;
        values[valuesById[2] = "WIR_ALREADY_INVITED"] = 2;
        values[valuesById[3] = "WIR_INVALID_ROLE"] = 3;
        return values;
    })();

    /**
     * WorkspaceInviteResendResult enum.
     * @name entities.WorkspaceInviteResendResult
     * @enum {number}
     * @property {number} WIRR_SUCCESS=0 WIRR_SUCCESS value
     * @property {number} WIRR_MEMBER_NOT_FOUND=1 WIRR_MEMBER_NOT_FOUND value
     * @property {number} WIRR_PENDING_STAMP=2 WIRR_PENDING_STAMP value
     * @property {number} WIRR_NO_EMAIL=3 WIRR_NO_EMAIL value
     */
    entities.WorkspaceInviteResendResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "WIRR_SUCCESS"] = 0;
        values[valuesById[1] = "WIRR_MEMBER_NOT_FOUND"] = 1;
        values[valuesById[2] = "WIRR_PENDING_STAMP"] = 2;
        values[valuesById[3] = "WIRR_NO_EMAIL"] = 3;
        return values;
    })();

    /**
     * WorkspaceUpdateMemberResult enum.
     * @name entities.WorkspaceUpdateMemberResult
     * @enum {number}
     * @property {number} WUMR_SUCCESS=0 WUMR_SUCCESS value
     * @property {number} WUMR_MEMBER_NOT_FOUND=1 WUMR_MEMBER_NOT_FOUND value
     * @property {number} WUMR_INVALID_ROLE=2 WUMR_INVALID_ROLE value
     * @property {number} WUMR_INVALID_STATUS=3 WUMR_INVALID_STATUS value
     * @property {number} WUMR_INVALID_ACCESS=4 WUMR_INVALID_ACCESS value
     * @property {number} WUMR_WORKSPACE_LIMIT=5 WUMR_WORKSPACE_LIMIT value
     */
    entities.WorkspaceUpdateMemberResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "WUMR_SUCCESS"] = 0;
        values[valuesById[1] = "WUMR_MEMBER_NOT_FOUND"] = 1;
        values[valuesById[2] = "WUMR_INVALID_ROLE"] = 2;
        values[valuesById[3] = "WUMR_INVALID_STATUS"] = 3;
        values[valuesById[4] = "WUMR_INVALID_ACCESS"] = 4;
        values[valuesById[5] = "WUMR_WORKSPACE_LIMIT"] = 5;
        return values;
    })();

    entities.InviteLinkEnvelope = (function() {

        /**
         * Properties of an InviteLinkEnvelope.
         * @memberof entities
         * @interface IInviteLinkEnvelope
         * @property {Uint8Array|null} [id] InviteLinkEnvelope id
         * @property {string|null} [email] InviteLinkEnvelope email
         * @property {Uint8Array|null} [workspaceId] InviteLinkEnvelope workspaceId
         */

        /**
         * Constructs a new InviteLinkEnvelope.
         * @memberof entities
         * @classdesc Represents an InviteLinkEnvelope.
         * @implements IInviteLinkEnvelope
         * @constructor
         * @param {entities.IInviteLinkEnvelope=} [properties] Properties to set
         */
        function InviteLinkEnvelope(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InviteLinkEnvelope id.
         * @member {Uint8Array} id
         * @memberof entities.InviteLinkEnvelope
         * @instance
         */
        InviteLinkEnvelope.prototype.id = $util.newBuffer([]);

        /**
         * InviteLinkEnvelope email.
         * @member {string} email
         * @memberof entities.InviteLinkEnvelope
         * @instance
         */
        InviteLinkEnvelope.prototype.email = "";

        /**
         * InviteLinkEnvelope workspaceId.
         * @member {Uint8Array} workspaceId
         * @memberof entities.InviteLinkEnvelope
         * @instance
         */
        InviteLinkEnvelope.prototype.workspaceId = $util.newBuffer([]);

        /**
         * Creates a new InviteLinkEnvelope instance using the specified properties.
         * @function create
         * @memberof entities.InviteLinkEnvelope
         * @static
         * @param {entities.IInviteLinkEnvelope=} [properties] Properties to set
         * @returns {entities.InviteLinkEnvelope} InviteLinkEnvelope instance
         */
        InviteLinkEnvelope.create = function create(properties) {
            return new InviteLinkEnvelope(properties);
        };

        /**
         * Encodes the specified InviteLinkEnvelope message. Does not implicitly {@link entities.InviteLinkEnvelope.verify|verify} messages.
         * @function encode
         * @memberof entities.InviteLinkEnvelope
         * @static
         * @param {entities.IInviteLinkEnvelope} message InviteLinkEnvelope message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InviteLinkEnvelope.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
            if (message.workspaceId != null && Object.hasOwnProperty.call(message, "workspaceId"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.workspaceId);
            return writer;
        };

        /**
         * Encodes the specified InviteLinkEnvelope message, length delimited. Does not implicitly {@link entities.InviteLinkEnvelope.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.InviteLinkEnvelope
         * @static
         * @param {entities.IInviteLinkEnvelope} message InviteLinkEnvelope message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InviteLinkEnvelope.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InviteLinkEnvelope message from the specified reader or buffer.
         * @function decode
         * @memberof entities.InviteLinkEnvelope
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.InviteLinkEnvelope} InviteLinkEnvelope
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InviteLinkEnvelope.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.InviteLinkEnvelope();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.bytes();
                        break;
                    }
                case 2: {
                        message.email = reader.string();
                        break;
                    }
                case 3: {
                        message.workspaceId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InviteLinkEnvelope message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.InviteLinkEnvelope
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.InviteLinkEnvelope} InviteLinkEnvelope
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InviteLinkEnvelope.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InviteLinkEnvelope message.
         * @function verify
         * @memberof entities.InviteLinkEnvelope
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InviteLinkEnvelope.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                if (!(message.workspaceId && typeof message.workspaceId.length === "number" || $util.isString(message.workspaceId)))
                    return "workspaceId: buffer expected";
            return null;
        };

        /**
         * Creates an InviteLinkEnvelope message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.InviteLinkEnvelope
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.InviteLinkEnvelope} InviteLinkEnvelope
         */
        InviteLinkEnvelope.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.InviteLinkEnvelope)
                return object;
            let message = new $root.entities.InviteLinkEnvelope();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length >= 0)
                    message.id = object.id;
            if (object.email != null)
                message.email = String(object.email);
            if (object.workspaceId != null)
                if (typeof object.workspaceId === "string")
                    $util.base64.decode(object.workspaceId, message.workspaceId = $util.newBuffer($util.base64.length(object.workspaceId)), 0);
                else if (object.workspaceId.length >= 0)
                    message.workspaceId = object.workspaceId;
            return message;
        };

        /**
         * Creates a plain object from an InviteLinkEnvelope message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.InviteLinkEnvelope
         * @static
         * @param {entities.InviteLinkEnvelope} message InviteLinkEnvelope
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InviteLinkEnvelope.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.email = "";
                if (options.bytes === String)
                    object.workspaceId = "";
                else {
                    object.workspaceId = [];
                    if (options.bytes !== Array)
                        object.workspaceId = $util.newBuffer(object.workspaceId);
                }
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                object.workspaceId = options.bytes === String ? $util.base64.encode(message.workspaceId, 0, message.workspaceId.length) : options.bytes === Array ? Array.prototype.slice.call(message.workspaceId) : message.workspaceId;
            return object;
        };

        /**
         * Converts this InviteLinkEnvelope to JSON.
         * @function toJSON
         * @memberof entities.InviteLinkEnvelope
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InviteLinkEnvelope.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for InviteLinkEnvelope
         * @function getTypeUrl
         * @memberof entities.InviteLinkEnvelope
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        InviteLinkEnvelope.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.InviteLinkEnvelope";
        };

        return InviteLinkEnvelope;
    })();

    entities.Invite = (function() {

        /**
         * Properties of an Invite.
         * @memberof entities
         * @interface IInvite
         * @property {Uint8Array|null} [id] Invite id
         * @property {Uint8Array|null} [workspaceId] Invite workspaceId
         * @property {Long|null} [inviterMemberId] Invite inviterMemberId
         * @property {Long|null} [boundToMemberId] Invite boundToMemberId
         * @property {string|null} [boundToEmail] Invite boundToEmail
         * @property {Long|null} [lastResendAt] Invite lastResendAt
         * @property {boolean|null} [userWithEmailExists] Invite userWithEmailExists
         * @property {boolean|null} [used] Invite used
         * @property {Long|null} [usedByMemberID] Invite usedByMemberID
         * @property {Long|null} [usedAt] Invite usedAt
         * @property {Long|null} [createdAt] Invite createdAt
         * @property {Long|null} [expireAt] Invite expireAt
         * @property {entities.WorkspaceRole|null} [role] Invite role
         * @property {entities.IWorkspaceMemberAccess|null} [access] Invite access
         */

        /**
         * Constructs a new Invite.
         * @memberof entities
         * @classdesc Represents an Invite.
         * @implements IInvite
         * @constructor
         * @param {entities.IInvite=} [properties] Properties to set
         */
        function Invite(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Invite id.
         * @member {Uint8Array} id
         * @memberof entities.Invite
         * @instance
         */
        Invite.prototype.id = $util.newBuffer([]);

        /**
         * Invite workspaceId.
         * @member {Uint8Array} workspaceId
         * @memberof entities.Invite
         * @instance
         */
        Invite.prototype.workspaceId = $util.newBuffer([]);

        /**
         * Invite inviterMemberId.
         * @member {Long} inviterMemberId
         * @memberof entities.Invite
         * @instance
         */
        Invite.prototype.inviterMemberId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Invite boundToMemberId.
         * @member {Long} boundToMemberId
         * @memberof entities.Invite
         * @instance
         */
        Invite.prototype.boundToMemberId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Invite boundToEmail.
         * @member {string} boundToEmail
         * @memberof entities.Invite
         * @instance
         */
        Invite.prototype.boundToEmail = "";

        /**
         * Invite lastResendAt.
         * @member {Long} lastResendAt
         * @memberof entities.Invite
         * @instance
         */
        Invite.prototype.lastResendAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Invite userWithEmailExists.
         * @member {boolean} userWithEmailExists
         * @memberof entities.Invite
         * @instance
         */
        Invite.prototype.userWithEmailExists = false;

        /**
         * Invite used.
         * @member {boolean} used
         * @memberof entities.Invite
         * @instance
         */
        Invite.prototype.used = false;

        /**
         * Invite usedByMemberID.
         * @member {Long} usedByMemberID
         * @memberof entities.Invite
         * @instance
         */
        Invite.prototype.usedByMemberID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Invite usedAt.
         * @member {Long} usedAt
         * @memberof entities.Invite
         * @instance
         */
        Invite.prototype.usedAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Invite createdAt.
         * @member {Long} createdAt
         * @memberof entities.Invite
         * @instance
         */
        Invite.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Invite expireAt.
         * @member {Long} expireAt
         * @memberof entities.Invite
         * @instance
         */
        Invite.prototype.expireAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Invite role.
         * @member {entities.WorkspaceRole} role
         * @memberof entities.Invite
         * @instance
         */
        Invite.prototype.role = 0;

        /**
         * Invite access.
         * @member {entities.IWorkspaceMemberAccess|null|undefined} access
         * @memberof entities.Invite
         * @instance
         */
        Invite.prototype.access = null;

        /**
         * Creates a new Invite instance using the specified properties.
         * @function create
         * @memberof entities.Invite
         * @static
         * @param {entities.IInvite=} [properties] Properties to set
         * @returns {entities.Invite} Invite instance
         */
        Invite.create = function create(properties) {
            return new Invite(properties);
        };

        /**
         * Encodes the specified Invite message. Does not implicitly {@link entities.Invite.verify|verify} messages.
         * @function encode
         * @memberof entities.Invite
         * @static
         * @param {entities.IInvite} message Invite message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Invite.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.workspaceId != null && Object.hasOwnProperty.call(message, "workspaceId"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.workspaceId);
            if (message.inviterMemberId != null && Object.hasOwnProperty.call(message, "inviterMemberId"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.inviterMemberId);
            if (message.boundToMemberId != null && Object.hasOwnProperty.call(message, "boundToMemberId"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.boundToMemberId);
            if (message.boundToEmail != null && Object.hasOwnProperty.call(message, "boundToEmail"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.boundToEmail);
            if (message.used != null && Object.hasOwnProperty.call(message, "used"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.used);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.createdAt);
            if (message.expireAt != null && Object.hasOwnProperty.call(message, "expireAt"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.expireAt);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.role);
            if (message.access != null && Object.hasOwnProperty.call(message, "access"))
                $root.entities.WorkspaceMemberAccess.encode(message.access, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.userWithEmailExists != null && Object.hasOwnProperty.call(message, "userWithEmailExists"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.userWithEmailExists);
            if (message.usedByMemberID != null && Object.hasOwnProperty.call(message, "usedByMemberID"))
                writer.uint32(/* id 12, wireType 0 =*/96).uint64(message.usedByMemberID);
            if (message.usedAt != null && Object.hasOwnProperty.call(message, "usedAt"))
                writer.uint32(/* id 13, wireType 0 =*/104).uint64(message.usedAt);
            if (message.lastResendAt != null && Object.hasOwnProperty.call(message, "lastResendAt"))
                writer.uint32(/* id 14, wireType 0 =*/112).uint64(message.lastResendAt);
            return writer;
        };

        /**
         * Encodes the specified Invite message, length delimited. Does not implicitly {@link entities.Invite.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.Invite
         * @static
         * @param {entities.IInvite} message Invite message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Invite.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Invite message from the specified reader or buffer.
         * @function decode
         * @memberof entities.Invite
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.Invite} Invite
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Invite.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.Invite();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.bytes();
                        break;
                    }
                case 2: {
                        message.workspaceId = reader.bytes();
                        break;
                    }
                case 3: {
                        message.inviterMemberId = reader.uint64();
                        break;
                    }
                case 4: {
                        message.boundToMemberId = reader.uint64();
                        break;
                    }
                case 5: {
                        message.boundToEmail = reader.string();
                        break;
                    }
                case 14: {
                        message.lastResendAt = reader.uint64();
                        break;
                    }
                case 11: {
                        message.userWithEmailExists = reader.bool();
                        break;
                    }
                case 6: {
                        message.used = reader.bool();
                        break;
                    }
                case 12: {
                        message.usedByMemberID = reader.uint64();
                        break;
                    }
                case 13: {
                        message.usedAt = reader.uint64();
                        break;
                    }
                case 7: {
                        message.createdAt = reader.uint64();
                        break;
                    }
                case 8: {
                        message.expireAt = reader.uint64();
                        break;
                    }
                case 9: {
                        message.role = reader.int32();
                        break;
                    }
                case 10: {
                        message.access = $root.entities.WorkspaceMemberAccess.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Invite message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.Invite
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.Invite} Invite
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Invite.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Invite message.
         * @function verify
         * @memberof entities.Invite
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Invite.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                if (!(message.workspaceId && typeof message.workspaceId.length === "number" || $util.isString(message.workspaceId)))
                    return "workspaceId: buffer expected";
            if (message.inviterMemberId != null && message.hasOwnProperty("inviterMemberId"))
                if (!$util.isInteger(message.inviterMemberId) && !(message.inviterMemberId && $util.isInteger(message.inviterMemberId.low) && $util.isInteger(message.inviterMemberId.high)))
                    return "inviterMemberId: integer|Long expected";
            if (message.boundToMemberId != null && message.hasOwnProperty("boundToMemberId"))
                if (!$util.isInteger(message.boundToMemberId) && !(message.boundToMemberId && $util.isInteger(message.boundToMemberId.low) && $util.isInteger(message.boundToMemberId.high)))
                    return "boundToMemberId: integer|Long expected";
            if (message.boundToEmail != null && message.hasOwnProperty("boundToEmail"))
                if (!$util.isString(message.boundToEmail))
                    return "boundToEmail: string expected";
            if (message.lastResendAt != null && message.hasOwnProperty("lastResendAt"))
                if (!$util.isInteger(message.lastResendAt) && !(message.lastResendAt && $util.isInteger(message.lastResendAt.low) && $util.isInteger(message.lastResendAt.high)))
                    return "lastResendAt: integer|Long expected";
            if (message.userWithEmailExists != null && message.hasOwnProperty("userWithEmailExists"))
                if (typeof message.userWithEmailExists !== "boolean")
                    return "userWithEmailExists: boolean expected";
            if (message.used != null && message.hasOwnProperty("used"))
                if (typeof message.used !== "boolean")
                    return "used: boolean expected";
            if (message.usedByMemberID != null && message.hasOwnProperty("usedByMemberID"))
                if (!$util.isInteger(message.usedByMemberID) && !(message.usedByMemberID && $util.isInteger(message.usedByMemberID.low) && $util.isInteger(message.usedByMemberID.high)))
                    return "usedByMemberID: integer|Long expected";
            if (message.usedAt != null && message.hasOwnProperty("usedAt"))
                if (!$util.isInteger(message.usedAt) && !(message.usedAt && $util.isInteger(message.usedAt.low) && $util.isInteger(message.usedAt.high)))
                    return "usedAt: integer|Long expected";
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                    return "createdAt: integer|Long expected";
            if (message.expireAt != null && message.hasOwnProperty("expireAt"))
                if (!$util.isInteger(message.expireAt) && !(message.expireAt && $util.isInteger(message.expireAt.low) && $util.isInteger(message.expireAt.high)))
                    return "expireAt: integer|Long expected";
            if (message.role != null && message.hasOwnProperty("role"))
                switch (message.role) {
                default:
                    return "role: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.access != null && message.hasOwnProperty("access")) {
                let error = $root.entities.WorkspaceMemberAccess.verify(message.access);
                if (error)
                    return "access." + error;
            }
            return null;
        };

        /**
         * Creates an Invite message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.Invite
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.Invite} Invite
         */
        Invite.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.Invite)
                return object;
            let message = new $root.entities.Invite();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length >= 0)
                    message.id = object.id;
            if (object.workspaceId != null)
                if (typeof object.workspaceId === "string")
                    $util.base64.decode(object.workspaceId, message.workspaceId = $util.newBuffer($util.base64.length(object.workspaceId)), 0);
                else if (object.workspaceId.length >= 0)
                    message.workspaceId = object.workspaceId;
            if (object.inviterMemberId != null)
                if ($util.Long)
                    (message.inviterMemberId = $util.Long.fromValue(object.inviterMemberId)).unsigned = true;
                else if (typeof object.inviterMemberId === "string")
                    message.inviterMemberId = parseInt(object.inviterMemberId, 10);
                else if (typeof object.inviterMemberId === "number")
                    message.inviterMemberId = object.inviterMemberId;
                else if (typeof object.inviterMemberId === "object")
                    message.inviterMemberId = new $util.LongBits(object.inviterMemberId.low >>> 0, object.inviterMemberId.high >>> 0).toNumber(true);
            if (object.boundToMemberId != null)
                if ($util.Long)
                    (message.boundToMemberId = $util.Long.fromValue(object.boundToMemberId)).unsigned = true;
                else if (typeof object.boundToMemberId === "string")
                    message.boundToMemberId = parseInt(object.boundToMemberId, 10);
                else if (typeof object.boundToMemberId === "number")
                    message.boundToMemberId = object.boundToMemberId;
                else if (typeof object.boundToMemberId === "object")
                    message.boundToMemberId = new $util.LongBits(object.boundToMemberId.low >>> 0, object.boundToMemberId.high >>> 0).toNumber(true);
            if (object.boundToEmail != null)
                message.boundToEmail = String(object.boundToEmail);
            if (object.lastResendAt != null)
                if ($util.Long)
                    (message.lastResendAt = $util.Long.fromValue(object.lastResendAt)).unsigned = true;
                else if (typeof object.lastResendAt === "string")
                    message.lastResendAt = parseInt(object.lastResendAt, 10);
                else if (typeof object.lastResendAt === "number")
                    message.lastResendAt = object.lastResendAt;
                else if (typeof object.lastResendAt === "object")
                    message.lastResendAt = new $util.LongBits(object.lastResendAt.low >>> 0, object.lastResendAt.high >>> 0).toNumber(true);
            if (object.userWithEmailExists != null)
                message.userWithEmailExists = Boolean(object.userWithEmailExists);
            if (object.used != null)
                message.used = Boolean(object.used);
            if (object.usedByMemberID != null)
                if ($util.Long)
                    (message.usedByMemberID = $util.Long.fromValue(object.usedByMemberID)).unsigned = true;
                else if (typeof object.usedByMemberID === "string")
                    message.usedByMemberID = parseInt(object.usedByMemberID, 10);
                else if (typeof object.usedByMemberID === "number")
                    message.usedByMemberID = object.usedByMemberID;
                else if (typeof object.usedByMemberID === "object")
                    message.usedByMemberID = new $util.LongBits(object.usedByMemberID.low >>> 0, object.usedByMemberID.high >>> 0).toNumber(true);
            if (object.usedAt != null)
                if ($util.Long)
                    (message.usedAt = $util.Long.fromValue(object.usedAt)).unsigned = true;
                else if (typeof object.usedAt === "string")
                    message.usedAt = parseInt(object.usedAt, 10);
                else if (typeof object.usedAt === "number")
                    message.usedAt = object.usedAt;
                else if (typeof object.usedAt === "object")
                    message.usedAt = new $util.LongBits(object.usedAt.low >>> 0, object.usedAt.high >>> 0).toNumber(true);
            if (object.createdAt != null)
                if ($util.Long)
                    (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                else if (typeof object.createdAt === "string")
                    message.createdAt = parseInt(object.createdAt, 10);
                else if (typeof object.createdAt === "number")
                    message.createdAt = object.createdAt;
                else if (typeof object.createdAt === "object")
                    message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
            if (object.expireAt != null)
                if ($util.Long)
                    (message.expireAt = $util.Long.fromValue(object.expireAt)).unsigned = true;
                else if (typeof object.expireAt === "string")
                    message.expireAt = parseInt(object.expireAt, 10);
                else if (typeof object.expireAt === "number")
                    message.expireAt = object.expireAt;
                else if (typeof object.expireAt === "object")
                    message.expireAt = new $util.LongBits(object.expireAt.low >>> 0, object.expireAt.high >>> 0).toNumber(true);
            switch (object.role) {
            default:
                if (typeof object.role === "number") {
                    message.role = object.role;
                    break;
                }
                break;
            case "WR_NONE":
            case 0:
                message.role = 0;
                break;
            case "WR_OWNER":
            case 1:
                message.role = 1;
                break;
            case "WR_ADMIN":
            case 2:
                message.role = 2;
                break;
            case "WR_OPERATOR":
            case 3:
                message.role = 3;
                break;
            }
            if (object.access != null) {
                if (typeof object.access !== "object")
                    throw TypeError(".entities.Invite.access: object expected");
                message.access = $root.entities.WorkspaceMemberAccess.fromObject(object.access);
            }
            return message;
        };

        /**
         * Creates a plain object from an Invite message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.Invite
         * @static
         * @param {entities.Invite} message Invite
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Invite.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                if (options.bytes === String)
                    object.workspaceId = "";
                else {
                    object.workspaceId = [];
                    if (options.bytes !== Array)
                        object.workspaceId = $util.newBuffer(object.workspaceId);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.inviterMemberId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.inviterMemberId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.boundToMemberId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.boundToMemberId = options.longs === String ? "0" : 0;
                object.boundToEmail = "";
                object.used = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createdAt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.expireAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expireAt = options.longs === String ? "0" : 0;
                object.role = options.enums === String ? "WR_NONE" : 0;
                object.access = null;
                object.userWithEmailExists = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.usedByMemberID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.usedByMemberID = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.usedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.usedAt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.lastResendAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lastResendAt = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.workspaceId != null && message.hasOwnProperty("workspaceId"))
                object.workspaceId = options.bytes === String ? $util.base64.encode(message.workspaceId, 0, message.workspaceId.length) : options.bytes === Array ? Array.prototype.slice.call(message.workspaceId) : message.workspaceId;
            if (message.inviterMemberId != null && message.hasOwnProperty("inviterMemberId"))
                if (typeof message.inviterMemberId === "number")
                    object.inviterMemberId = options.longs === String ? String(message.inviterMemberId) : message.inviterMemberId;
                else
                    object.inviterMemberId = options.longs === String ? $util.Long.prototype.toString.call(message.inviterMemberId) : options.longs === Number ? new $util.LongBits(message.inviterMemberId.low >>> 0, message.inviterMemberId.high >>> 0).toNumber(true) : message.inviterMemberId;
            if (message.boundToMemberId != null && message.hasOwnProperty("boundToMemberId"))
                if (typeof message.boundToMemberId === "number")
                    object.boundToMemberId = options.longs === String ? String(message.boundToMemberId) : message.boundToMemberId;
                else
                    object.boundToMemberId = options.longs === String ? $util.Long.prototype.toString.call(message.boundToMemberId) : options.longs === Number ? new $util.LongBits(message.boundToMemberId.low >>> 0, message.boundToMemberId.high >>> 0).toNumber(true) : message.boundToMemberId;
            if (message.boundToEmail != null && message.hasOwnProperty("boundToEmail"))
                object.boundToEmail = message.boundToEmail;
            if (message.used != null && message.hasOwnProperty("used"))
                object.used = message.used;
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (typeof message.createdAt === "number")
                    object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                else
                    object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
            if (message.expireAt != null && message.hasOwnProperty("expireAt"))
                if (typeof message.expireAt === "number")
                    object.expireAt = options.longs === String ? String(message.expireAt) : message.expireAt;
                else
                    object.expireAt = options.longs === String ? $util.Long.prototype.toString.call(message.expireAt) : options.longs === Number ? new $util.LongBits(message.expireAt.low >>> 0, message.expireAt.high >>> 0).toNumber(true) : message.expireAt;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = options.enums === String ? $root.entities.WorkspaceRole[message.role] === undefined ? message.role : $root.entities.WorkspaceRole[message.role] : message.role;
            if (message.access != null && message.hasOwnProperty("access"))
                object.access = $root.entities.WorkspaceMemberAccess.toObject(message.access, options);
            if (message.userWithEmailExists != null && message.hasOwnProperty("userWithEmailExists"))
                object.userWithEmailExists = message.userWithEmailExists;
            if (message.usedByMemberID != null && message.hasOwnProperty("usedByMemberID"))
                if (typeof message.usedByMemberID === "number")
                    object.usedByMemberID = options.longs === String ? String(message.usedByMemberID) : message.usedByMemberID;
                else
                    object.usedByMemberID = options.longs === String ? $util.Long.prototype.toString.call(message.usedByMemberID) : options.longs === Number ? new $util.LongBits(message.usedByMemberID.low >>> 0, message.usedByMemberID.high >>> 0).toNumber(true) : message.usedByMemberID;
            if (message.usedAt != null && message.hasOwnProperty("usedAt"))
                if (typeof message.usedAt === "number")
                    object.usedAt = options.longs === String ? String(message.usedAt) : message.usedAt;
                else
                    object.usedAt = options.longs === String ? $util.Long.prototype.toString.call(message.usedAt) : options.longs === Number ? new $util.LongBits(message.usedAt.low >>> 0, message.usedAt.high >>> 0).toNumber(true) : message.usedAt;
            if (message.lastResendAt != null && message.hasOwnProperty("lastResendAt"))
                if (typeof message.lastResendAt === "number")
                    object.lastResendAt = options.longs === String ? String(message.lastResendAt) : message.lastResendAt;
                else
                    object.lastResendAt = options.longs === String ? $util.Long.prototype.toString.call(message.lastResendAt) : options.longs === Number ? new $util.LongBits(message.lastResendAt.low >>> 0, message.lastResendAt.high >>> 0).toNumber(true) : message.lastResendAt;
            return object;
        };

        /**
         * Converts this Invite to JSON.
         * @function toJSON
         * @memberof entities.Invite
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Invite.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Invite
         * @function getTypeUrl
         * @memberof entities.Invite
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Invite.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.Invite";
        };

        return Invite;
    })();

    entities.WorkspaceSnippet = (function() {

        /**
         * Properties of a WorkspaceSnippet.
         * @memberof entities
         * @interface IWorkspaceSnippet
         * @property {Long|null} [memberID] WorkspaceSnippet memberID
         * @property {string|null} [title] WorkspaceSnippet title
         * @property {string|null} [command] WorkspaceSnippet command
         * @property {string|null} [text] WorkspaceSnippet text
         * @property {Array.<entities.ITextEntities>|null} [entities] WorkspaceSnippet entities
         */

        /**
         * Constructs a new WorkspaceSnippet.
         * @memberof entities
         * @classdesc Represents a WorkspaceSnippet.
         * @implements IWorkspaceSnippet
         * @constructor
         * @param {entities.IWorkspaceSnippet=} [properties] Properties to set
         */
        function WorkspaceSnippet(properties) {
            this.entities = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceSnippet memberID.
         * @member {Long} memberID
         * @memberof entities.WorkspaceSnippet
         * @instance
         */
        WorkspaceSnippet.prototype.memberID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * WorkspaceSnippet title.
         * @member {string} title
         * @memberof entities.WorkspaceSnippet
         * @instance
         */
        WorkspaceSnippet.prototype.title = "";

        /**
         * WorkspaceSnippet command.
         * @member {string} command
         * @memberof entities.WorkspaceSnippet
         * @instance
         */
        WorkspaceSnippet.prototype.command = "";

        /**
         * WorkspaceSnippet text.
         * @member {string} text
         * @memberof entities.WorkspaceSnippet
         * @instance
         */
        WorkspaceSnippet.prototype.text = "";

        /**
         * WorkspaceSnippet entities.
         * @member {Array.<entities.ITextEntities>} entities
         * @memberof entities.WorkspaceSnippet
         * @instance
         */
        WorkspaceSnippet.prototype.entities = $util.emptyArray;

        /**
         * Creates a new WorkspaceSnippet instance using the specified properties.
         * @function create
         * @memberof entities.WorkspaceSnippet
         * @static
         * @param {entities.IWorkspaceSnippet=} [properties] Properties to set
         * @returns {entities.WorkspaceSnippet} WorkspaceSnippet instance
         */
        WorkspaceSnippet.create = function create(properties) {
            return new WorkspaceSnippet(properties);
        };

        /**
         * Encodes the specified WorkspaceSnippet message. Does not implicitly {@link entities.WorkspaceSnippet.verify|verify} messages.
         * @function encode
         * @memberof entities.WorkspaceSnippet
         * @static
         * @param {entities.IWorkspaceSnippet} message WorkspaceSnippet message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceSnippet.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.memberID != null && Object.hasOwnProperty.call(message, "memberID"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.memberID);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.command != null && Object.hasOwnProperty.call(message, "command"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.command);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.text);
            if (message.entities != null && message.entities.length)
                for (let i = 0; i < message.entities.length; ++i)
                    $root.entities.TextEntities.encode(message.entities[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceSnippet message, length delimited. Does not implicitly {@link entities.WorkspaceSnippet.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.WorkspaceSnippet
         * @static
         * @param {entities.IWorkspaceSnippet} message WorkspaceSnippet message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceSnippet.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceSnippet message from the specified reader or buffer.
         * @function decode
         * @memberof entities.WorkspaceSnippet
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.WorkspaceSnippet} WorkspaceSnippet
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceSnippet.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.WorkspaceSnippet();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.memberID = reader.uint64();
                        break;
                    }
                case 2: {
                        message.title = reader.string();
                        break;
                    }
                case 3: {
                        message.command = reader.string();
                        break;
                    }
                case 4: {
                        message.text = reader.string();
                        break;
                    }
                case 5: {
                        if (!(message.entities && message.entities.length))
                            message.entities = [];
                        message.entities.push($root.entities.TextEntities.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceSnippet message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.WorkspaceSnippet
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.WorkspaceSnippet} WorkspaceSnippet
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceSnippet.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceSnippet message.
         * @function verify
         * @memberof entities.WorkspaceSnippet
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceSnippet.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.memberID != null && message.hasOwnProperty("memberID"))
                if (!$util.isInteger(message.memberID) && !(message.memberID && $util.isInteger(message.memberID.low) && $util.isInteger(message.memberID.high)))
                    return "memberID: integer|Long expected";
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (message.command != null && message.hasOwnProperty("command"))
                if (!$util.isString(message.command))
                    return "command: string expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            if (message.entities != null && message.hasOwnProperty("entities")) {
                if (!Array.isArray(message.entities))
                    return "entities: array expected";
                for (let i = 0; i < message.entities.length; ++i) {
                    let error = $root.entities.TextEntities.verify(message.entities[i]);
                    if (error)
                        return "entities." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WorkspaceSnippet message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.WorkspaceSnippet
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.WorkspaceSnippet} WorkspaceSnippet
         */
        WorkspaceSnippet.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.WorkspaceSnippet)
                return object;
            let message = new $root.entities.WorkspaceSnippet();
            if (object.memberID != null)
                if ($util.Long)
                    (message.memberID = $util.Long.fromValue(object.memberID)).unsigned = true;
                else if (typeof object.memberID === "string")
                    message.memberID = parseInt(object.memberID, 10);
                else if (typeof object.memberID === "number")
                    message.memberID = object.memberID;
                else if (typeof object.memberID === "object")
                    message.memberID = new $util.LongBits(object.memberID.low >>> 0, object.memberID.high >>> 0).toNumber(true);
            if (object.title != null)
                message.title = String(object.title);
            if (object.command != null)
                message.command = String(object.command);
            if (object.text != null)
                message.text = String(object.text);
            if (object.entities) {
                if (!Array.isArray(object.entities))
                    throw TypeError(".entities.WorkspaceSnippet.entities: array expected");
                message.entities = [];
                for (let i = 0; i < object.entities.length; ++i) {
                    if (typeof object.entities[i] !== "object")
                        throw TypeError(".entities.WorkspaceSnippet.entities: object expected");
                    message.entities[i] = $root.entities.TextEntities.fromObject(object.entities[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceSnippet message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.WorkspaceSnippet
         * @static
         * @param {entities.WorkspaceSnippet} message WorkspaceSnippet
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceSnippet.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.entities = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.memberID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.memberID = options.longs === String ? "0" : 0;
                object.title = "";
                object.command = "";
                object.text = "";
            }
            if (message.memberID != null && message.hasOwnProperty("memberID"))
                if (typeof message.memberID === "number")
                    object.memberID = options.longs === String ? String(message.memberID) : message.memberID;
                else
                    object.memberID = options.longs === String ? $util.Long.prototype.toString.call(message.memberID) : options.longs === Number ? new $util.LongBits(message.memberID.low >>> 0, message.memberID.high >>> 0).toNumber(true) : message.memberID;
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.command != null && message.hasOwnProperty("command"))
                object.command = message.command;
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            if (message.entities && message.entities.length) {
                object.entities = [];
                for (let j = 0; j < message.entities.length; ++j)
                    object.entities[j] = $root.entities.TextEntities.toObject(message.entities[j], options);
            }
            return object;
        };

        /**
         * Converts this WorkspaceSnippet to JSON.
         * @function toJSON
         * @memberof entities.WorkspaceSnippet
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceSnippet.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceSnippet
         * @function getTypeUrl
         * @memberof entities.WorkspaceSnippet
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceSnippet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.WorkspaceSnippet";
        };

        return WorkspaceSnippet;
    })();

    entities.WorkspaceSnippets = (function() {

        /**
         * Properties of a WorkspaceSnippets.
         * @memberof entities
         * @interface IWorkspaceSnippets
         * @property {Array.<entities.IWorkspaceSnippet>|null} [snippets] WorkspaceSnippets snippets
         */

        /**
         * Constructs a new WorkspaceSnippets.
         * @memberof entities
         * @classdesc Represents a WorkspaceSnippets.
         * @implements IWorkspaceSnippets
         * @constructor
         * @param {entities.IWorkspaceSnippets=} [properties] Properties to set
         */
        function WorkspaceSnippets(properties) {
            this.snippets = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceSnippets snippets.
         * @member {Array.<entities.IWorkspaceSnippet>} snippets
         * @memberof entities.WorkspaceSnippets
         * @instance
         */
        WorkspaceSnippets.prototype.snippets = $util.emptyArray;

        /**
         * Creates a new WorkspaceSnippets instance using the specified properties.
         * @function create
         * @memberof entities.WorkspaceSnippets
         * @static
         * @param {entities.IWorkspaceSnippets=} [properties] Properties to set
         * @returns {entities.WorkspaceSnippets} WorkspaceSnippets instance
         */
        WorkspaceSnippets.create = function create(properties) {
            return new WorkspaceSnippets(properties);
        };

        /**
         * Encodes the specified WorkspaceSnippets message. Does not implicitly {@link entities.WorkspaceSnippets.verify|verify} messages.
         * @function encode
         * @memberof entities.WorkspaceSnippets
         * @static
         * @param {entities.IWorkspaceSnippets} message WorkspaceSnippets message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceSnippets.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.snippets != null && message.snippets.length)
                for (let i = 0; i < message.snippets.length; ++i)
                    $root.entities.WorkspaceSnippet.encode(message.snippets[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceSnippets message, length delimited. Does not implicitly {@link entities.WorkspaceSnippets.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.WorkspaceSnippets
         * @static
         * @param {entities.IWorkspaceSnippets} message WorkspaceSnippets message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceSnippets.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceSnippets message from the specified reader or buffer.
         * @function decode
         * @memberof entities.WorkspaceSnippets
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.WorkspaceSnippets} WorkspaceSnippets
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceSnippets.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.WorkspaceSnippets();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.snippets && message.snippets.length))
                            message.snippets = [];
                        message.snippets.push($root.entities.WorkspaceSnippet.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceSnippets message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.WorkspaceSnippets
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.WorkspaceSnippets} WorkspaceSnippets
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceSnippets.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceSnippets message.
         * @function verify
         * @memberof entities.WorkspaceSnippets
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceSnippets.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.snippets != null && message.hasOwnProperty("snippets")) {
                if (!Array.isArray(message.snippets))
                    return "snippets: array expected";
                for (let i = 0; i < message.snippets.length; ++i) {
                    let error = $root.entities.WorkspaceSnippet.verify(message.snippets[i]);
                    if (error)
                        return "snippets." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WorkspaceSnippets message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.WorkspaceSnippets
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.WorkspaceSnippets} WorkspaceSnippets
         */
        WorkspaceSnippets.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.WorkspaceSnippets)
                return object;
            let message = new $root.entities.WorkspaceSnippets();
            if (object.snippets) {
                if (!Array.isArray(object.snippets))
                    throw TypeError(".entities.WorkspaceSnippets.snippets: array expected");
                message.snippets = [];
                for (let i = 0; i < object.snippets.length; ++i) {
                    if (typeof object.snippets[i] !== "object")
                        throw TypeError(".entities.WorkspaceSnippets.snippets: object expected");
                    message.snippets[i] = $root.entities.WorkspaceSnippet.fromObject(object.snippets[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceSnippets message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.WorkspaceSnippets
         * @static
         * @param {entities.WorkspaceSnippets} message WorkspaceSnippets
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceSnippets.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.snippets = [];
            if (message.snippets && message.snippets.length) {
                object.snippets = [];
                for (let j = 0; j < message.snippets.length; ++j)
                    object.snippets[j] = $root.entities.WorkspaceSnippet.toObject(message.snippets[j], options);
            }
            return object;
        };

        /**
         * Converts this WorkspaceSnippets to JSON.
         * @function toJSON
         * @memberof entities.WorkspaceSnippets
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceSnippets.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkspaceSnippets
         * @function getTypeUrl
         * @memberof entities.WorkspaceSnippets
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkspaceSnippets.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.WorkspaceSnippets";
        };

        return WorkspaceSnippets;
    })();

    entities.TextEntities = (function() {

        /**
         * Properties of a TextEntities.
         * @memberof entities
         * @interface ITextEntities
         * @property {entities.TextEntities.Type|null} [type] TextEntities type
         * @property {Long|null} [offset] TextEntities offset
         * @property {Long|null} [length] TextEntities length
         * @property {Uint8Array|null} [payload] TextEntities payload
         */

        /**
         * Constructs a new TextEntities.
         * @memberof entities
         * @classdesc Represents a TextEntities.
         * @implements ITextEntities
         * @constructor
         * @param {entities.ITextEntities=} [properties] Properties to set
         */
        function TextEntities(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TextEntities type.
         * @member {entities.TextEntities.Type} type
         * @memberof entities.TextEntities
         * @instance
         */
        TextEntities.prototype.type = 0;

        /**
         * TextEntities offset.
         * @member {Long} offset
         * @memberof entities.TextEntities
         * @instance
         */
        TextEntities.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TextEntities length.
         * @member {Long} length
         * @memberof entities.TextEntities
         * @instance
         */
        TextEntities.prototype.length = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TextEntities payload.
         * @member {Uint8Array} payload
         * @memberof entities.TextEntities
         * @instance
         */
        TextEntities.prototype.payload = $util.newBuffer([]);

        /**
         * Creates a new TextEntities instance using the specified properties.
         * @function create
         * @memberof entities.TextEntities
         * @static
         * @param {entities.ITextEntities=} [properties] Properties to set
         * @returns {entities.TextEntities} TextEntities instance
         */
        TextEntities.create = function create(properties) {
            return new TextEntities(properties);
        };

        /**
         * Encodes the specified TextEntities message. Does not implicitly {@link entities.TextEntities.verify|verify} messages.
         * @function encode
         * @memberof entities.TextEntities
         * @static
         * @param {entities.ITextEntities} message TextEntities message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TextEntities.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.offset);
            if (message.length != null && Object.hasOwnProperty.call(message, "length"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.length);
            if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.payload);
            return writer;
        };

        /**
         * Encodes the specified TextEntities message, length delimited. Does not implicitly {@link entities.TextEntities.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.TextEntities
         * @static
         * @param {entities.ITextEntities} message TextEntities message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TextEntities.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TextEntities message from the specified reader or buffer.
         * @function decode
         * @memberof entities.TextEntities
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.TextEntities} TextEntities
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TextEntities.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.TextEntities();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.offset = reader.uint64();
                        break;
                    }
                case 3: {
                        message.length = reader.uint64();
                        break;
                    }
                case 4: {
                        message.payload = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TextEntities message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.TextEntities
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.TextEntities} TextEntities
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TextEntities.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TextEntities message.
         * @function verify
         * @memberof entities.TextEntities
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TextEntities.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                    break;
                }
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset) && !(message.offset && $util.isInteger(message.offset.low) && $util.isInteger(message.offset.high)))
                    return "offset: integer|Long expected";
            if (message.length != null && message.hasOwnProperty("length"))
                if (!$util.isInteger(message.length) && !(message.length && $util.isInteger(message.length.low) && $util.isInteger(message.length.high)))
                    return "length: integer|Long expected";
            if (message.payload != null && message.hasOwnProperty("payload"))
                if (!(message.payload && typeof message.payload.length === "number" || $util.isString(message.payload)))
                    return "payload: buffer expected";
            return null;
        };

        /**
         * Creates a TextEntities message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.TextEntities
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.TextEntities} TextEntities
         */
        TextEntities.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.TextEntities)
                return object;
            let message = new $root.entities.TextEntities();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                message.type = 0;
                break;
            case "BOLD":
            case 1:
                message.type = 1;
                break;
            case "ITALIC":
            case 2:
                message.type = 2;
                break;
            case "UNDERLINE":
            case 3:
                message.type = 3;
                break;
            case "STRIKETHROUGH":
            case 4:
                message.type = 4;
                break;
            case "CODE":
            case 5:
                message.type = 5;
                break;
            case "SPOLIER":
            case 6:
                message.type = 6;
                break;
            case "MENTION":
            case 7:
                message.type = 7;
                break;
            case "LINK":
            case 8:
                message.type = 8;
                break;
            case "EMAIL_ADDRESS":
            case 9:
                message.type = 9;
                break;
            case "PHONE_NUMBER":
            case 10:
                message.type = 10;
                break;
            }
            if (object.offset != null)
                if ($util.Long)
                    (message.offset = $util.Long.fromValue(object.offset)).unsigned = true;
                else if (typeof object.offset === "string")
                    message.offset = parseInt(object.offset, 10);
                else if (typeof object.offset === "number")
                    message.offset = object.offset;
                else if (typeof object.offset === "object")
                    message.offset = new $util.LongBits(object.offset.low >>> 0, object.offset.high >>> 0).toNumber(true);
            if (object.length != null)
                if ($util.Long)
                    (message.length = $util.Long.fromValue(object.length)).unsigned = true;
                else if (typeof object.length === "string")
                    message.length = parseInt(object.length, 10);
                else if (typeof object.length === "number")
                    message.length = object.length;
                else if (typeof object.length === "object")
                    message.length = new $util.LongBits(object.length.low >>> 0, object.length.high >>> 0).toNumber(true);
            if (object.payload != null)
                if (typeof object.payload === "string")
                    $util.base64.decode(object.payload, message.payload = $util.newBuffer($util.base64.length(object.payload)), 0);
                else if (object.payload.length >= 0)
                    message.payload = object.payload;
            return message;
        };

        /**
         * Creates a plain object from a TextEntities message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.TextEntities
         * @static
         * @param {entities.TextEntities} message TextEntities
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TextEntities.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "UNKNOWN" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.offset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.offset = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.length = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.length = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.payload = "";
                else {
                    object.payload = [];
                    if (options.bytes !== Array)
                        object.payload = $util.newBuffer(object.payload);
                }
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.entities.TextEntities.Type[message.type] === undefined ? message.type : $root.entities.TextEntities.Type[message.type] : message.type;
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (typeof message.offset === "number")
                    object.offset = options.longs === String ? String(message.offset) : message.offset;
                else
                    object.offset = options.longs === String ? $util.Long.prototype.toString.call(message.offset) : options.longs === Number ? new $util.LongBits(message.offset.low >>> 0, message.offset.high >>> 0).toNumber(true) : message.offset;
            if (message.length != null && message.hasOwnProperty("length"))
                if (typeof message.length === "number")
                    object.length = options.longs === String ? String(message.length) : message.length;
                else
                    object.length = options.longs === String ? $util.Long.prototype.toString.call(message.length) : options.longs === Number ? new $util.LongBits(message.length.low >>> 0, message.length.high >>> 0).toNumber(true) : message.length;
            if (message.payload != null && message.hasOwnProperty("payload"))
                object.payload = options.bytes === String ? $util.base64.encode(message.payload, 0, message.payload.length) : options.bytes === Array ? Array.prototype.slice.call(message.payload) : message.payload;
            return object;
        };

        /**
         * Converts this TextEntities to JSON.
         * @function toJSON
         * @memberof entities.TextEntities
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TextEntities.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TextEntities
         * @function getTypeUrl
         * @memberof entities.TextEntities
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TextEntities.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.TextEntities";
        };

        /**
         * Type enum.
         * @name entities.TextEntities.Type
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} BOLD=1 BOLD value
         * @property {number} ITALIC=2 ITALIC value
         * @property {number} UNDERLINE=3 UNDERLINE value
         * @property {number} STRIKETHROUGH=4 STRIKETHROUGH value
         * @property {number} CODE=5 CODE value
         * @property {number} SPOLIER=6 SPOLIER value
         * @property {number} MENTION=7 MENTION value
         * @property {number} LINK=8 LINK value
         * @property {number} EMAIL_ADDRESS=9 EMAIL_ADDRESS value
         * @property {number} PHONE_NUMBER=10 PHONE_NUMBER value
         */
        TextEntities.Type = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "BOLD"] = 1;
            values[valuesById[2] = "ITALIC"] = 2;
            values[valuesById[3] = "UNDERLINE"] = 3;
            values[valuesById[4] = "STRIKETHROUGH"] = 4;
            values[valuesById[5] = "CODE"] = 5;
            values[valuesById[6] = "SPOLIER"] = 6;
            values[valuesById[7] = "MENTION"] = 7;
            values[valuesById[8] = "LINK"] = 8;
            values[valuesById[9] = "EMAIL_ADDRESS"] = 9;
            values[valuesById[10] = "PHONE_NUMBER"] = 10;
            return values;
        })();

        return TextEntities;
    })();

    /**
     * BillingPeriod enum.
     * @name entities.BillingPeriod
     * @enum {number}
     * @property {number} MONTHLY=0 MONTHLY value
     * @property {number} ANNUAL=1 ANNUAL value
     * @property {number} DAILY=2 DAILY value
     * @property {number} DEFAULT=3 DEFAULT value
     */
    entities.BillingPeriod = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MONTHLY"] = 0;
        values[valuesById[1] = "ANNUAL"] = 1;
        values[valuesById[2] = "DAILY"] = 2;
        values[valuesById[3] = "DEFAULT"] = 3;
        return values;
    })();

    entities.Tariff = (function() {

        /**
         * Properties of a Tariff.
         * @memberof entities
         * @interface ITariff
         * @property {string|null} [id] Tariff id
         * @property {string|null} [name] Tariff name
         * @property {string|null} [description] Tariff description
         * @property {number|null} [price] Tariff price
         * @property {string|null} [currency] Tariff currency
         * @property {entities.BillingPeriod|null} [billingPeriod] Tariff billingPeriod
         * @property {Long|null} [includedUsers] Tariff includedUsers
         * @property {number|null} [pricePerExtraUser] Tariff pricePerExtraUser
         * @property {Long|null} [maxUsers] Tariff maxUsers
         * @property {boolean|null} [isActive] Tariff isActive
         */

        /**
         * Constructs a new Tariff.
         * @memberof entities
         * @classdesc Represents a Tariff.
         * @implements ITariff
         * @constructor
         * @param {entities.ITariff=} [properties] Properties to set
         */
        function Tariff(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Tariff id.
         * @member {string} id
         * @memberof entities.Tariff
         * @instance
         */
        Tariff.prototype.id = "";

        /**
         * Tariff name.
         * @member {string} name
         * @memberof entities.Tariff
         * @instance
         */
        Tariff.prototype.name = "";

        /**
         * Tariff description.
         * @member {string} description
         * @memberof entities.Tariff
         * @instance
         */
        Tariff.prototype.description = "";

        /**
         * Tariff price.
         * @member {number} price
         * @memberof entities.Tariff
         * @instance
         */
        Tariff.prototype.price = 0;

        /**
         * Tariff currency.
         * @member {string} currency
         * @memberof entities.Tariff
         * @instance
         */
        Tariff.prototype.currency = "";

        /**
         * Tariff billingPeriod.
         * @member {entities.BillingPeriod} billingPeriod
         * @memberof entities.Tariff
         * @instance
         */
        Tariff.prototype.billingPeriod = 0;

        /**
         * Tariff includedUsers.
         * @member {Long} includedUsers
         * @memberof entities.Tariff
         * @instance
         */
        Tariff.prototype.includedUsers = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Tariff pricePerExtraUser.
         * @member {number} pricePerExtraUser
         * @memberof entities.Tariff
         * @instance
         */
        Tariff.prototype.pricePerExtraUser = 0;

        /**
         * Tariff maxUsers.
         * @member {Long} maxUsers
         * @memberof entities.Tariff
         * @instance
         */
        Tariff.prototype.maxUsers = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Tariff isActive.
         * @member {boolean} isActive
         * @memberof entities.Tariff
         * @instance
         */
        Tariff.prototype.isActive = false;

        /**
         * Creates a new Tariff instance using the specified properties.
         * @function create
         * @memberof entities.Tariff
         * @static
         * @param {entities.ITariff=} [properties] Properties to set
         * @returns {entities.Tariff} Tariff instance
         */
        Tariff.create = function create(properties) {
            return new Tariff(properties);
        };

        /**
         * Encodes the specified Tariff message. Does not implicitly {@link entities.Tariff.verify|verify} messages.
         * @function encode
         * @memberof entities.Tariff
         * @static
         * @param {entities.ITariff} message Tariff message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Tariff.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.price);
            if (message.billingPeriod != null && Object.hasOwnProperty.call(message, "billingPeriod"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.billingPeriod);
            if (message.includedUsers != null && Object.hasOwnProperty.call(message, "includedUsers"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.includedUsers);
            if (message.pricePerExtraUser != null && Object.hasOwnProperty.call(message, "pricePerExtraUser"))
                writer.uint32(/* id 7, wireType 1 =*/57).double(message.pricePerExtraUser);
            if (message.maxUsers != null && Object.hasOwnProperty.call(message, "maxUsers"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.maxUsers);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.currency);
            if (message.isActive != null && Object.hasOwnProperty.call(message, "isActive"))
                writer.uint32(/* id 30, wireType 0 =*/240).bool(message.isActive);
            return writer;
        };

        /**
         * Encodes the specified Tariff message, length delimited. Does not implicitly {@link entities.Tariff.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.Tariff
         * @static
         * @param {entities.ITariff} message Tariff message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Tariff.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Tariff message from the specified reader or buffer.
         * @function decode
         * @memberof entities.Tariff
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.Tariff} Tariff
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Tariff.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.Tariff();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.description = reader.string();
                        break;
                    }
                case 4: {
                        message.price = reader.double();
                        break;
                    }
                case 10: {
                        message.currency = reader.string();
                        break;
                    }
                case 5: {
                        message.billingPeriod = reader.int32();
                        break;
                    }
                case 6: {
                        message.includedUsers = reader.uint64();
                        break;
                    }
                case 7: {
                        message.pricePerExtraUser = reader.double();
                        break;
                    }
                case 8: {
                        message.maxUsers = reader.uint64();
                        break;
                    }
                case 30: {
                        message.isActive = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Tariff message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.Tariff
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.Tariff} Tariff
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Tariff.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Tariff message.
         * @function verify
         * @memberof entities.Tariff
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Tariff.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.price != null && message.hasOwnProperty("price"))
                if (typeof message.price !== "number")
                    return "price: number expected";
            if (message.currency != null && message.hasOwnProperty("currency"))
                if (!$util.isString(message.currency))
                    return "currency: string expected";
            if (message.billingPeriod != null && message.hasOwnProperty("billingPeriod"))
                switch (message.billingPeriod) {
                default:
                    return "billingPeriod: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.includedUsers != null && message.hasOwnProperty("includedUsers"))
                if (!$util.isInteger(message.includedUsers) && !(message.includedUsers && $util.isInteger(message.includedUsers.low) && $util.isInteger(message.includedUsers.high)))
                    return "includedUsers: integer|Long expected";
            if (message.pricePerExtraUser != null && message.hasOwnProperty("pricePerExtraUser"))
                if (typeof message.pricePerExtraUser !== "number")
                    return "pricePerExtraUser: number expected";
            if (message.maxUsers != null && message.hasOwnProperty("maxUsers"))
                if (!$util.isInteger(message.maxUsers) && !(message.maxUsers && $util.isInteger(message.maxUsers.low) && $util.isInteger(message.maxUsers.high)))
                    return "maxUsers: integer|Long expected";
            if (message.isActive != null && message.hasOwnProperty("isActive"))
                if (typeof message.isActive !== "boolean")
                    return "isActive: boolean expected";
            return null;
        };

        /**
         * Creates a Tariff message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.Tariff
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.Tariff} Tariff
         */
        Tariff.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.Tariff)
                return object;
            let message = new $root.entities.Tariff();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            if (object.description != null)
                message.description = String(object.description);
            if (object.price != null)
                message.price = Number(object.price);
            if (object.currency != null)
                message.currency = String(object.currency);
            switch (object.billingPeriod) {
            default:
                if (typeof object.billingPeriod === "number") {
                    message.billingPeriod = object.billingPeriod;
                    break;
                }
                break;
            case "MONTHLY":
            case 0:
                message.billingPeriod = 0;
                break;
            case "ANNUAL":
            case 1:
                message.billingPeriod = 1;
                break;
            case "DAILY":
            case 2:
                message.billingPeriod = 2;
                break;
            case "DEFAULT":
            case 3:
                message.billingPeriod = 3;
                break;
            }
            if (object.includedUsers != null)
                if ($util.Long)
                    (message.includedUsers = $util.Long.fromValue(object.includedUsers)).unsigned = true;
                else if (typeof object.includedUsers === "string")
                    message.includedUsers = parseInt(object.includedUsers, 10);
                else if (typeof object.includedUsers === "number")
                    message.includedUsers = object.includedUsers;
                else if (typeof object.includedUsers === "object")
                    message.includedUsers = new $util.LongBits(object.includedUsers.low >>> 0, object.includedUsers.high >>> 0).toNumber(true);
            if (object.pricePerExtraUser != null)
                message.pricePerExtraUser = Number(object.pricePerExtraUser);
            if (object.maxUsers != null)
                if ($util.Long)
                    (message.maxUsers = $util.Long.fromValue(object.maxUsers)).unsigned = true;
                else if (typeof object.maxUsers === "string")
                    message.maxUsers = parseInt(object.maxUsers, 10);
                else if (typeof object.maxUsers === "number")
                    message.maxUsers = object.maxUsers;
                else if (typeof object.maxUsers === "object")
                    message.maxUsers = new $util.LongBits(object.maxUsers.low >>> 0, object.maxUsers.high >>> 0).toNumber(true);
            if (object.isActive != null)
                message.isActive = Boolean(object.isActive);
            return message;
        };

        /**
         * Creates a plain object from a Tariff message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.Tariff
         * @static
         * @param {entities.Tariff} message Tariff
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Tariff.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.name = "";
                object.description = "";
                object.price = 0;
                object.billingPeriod = options.enums === String ? "MONTHLY" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.includedUsers = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.includedUsers = options.longs === String ? "0" : 0;
                object.pricePerExtraUser = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.maxUsers = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxUsers = options.longs === String ? "0" : 0;
                object.currency = "";
                object.isActive = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
            if (message.billingPeriod != null && message.hasOwnProperty("billingPeriod"))
                object.billingPeriod = options.enums === String ? $root.entities.BillingPeriod[message.billingPeriod] === undefined ? message.billingPeriod : $root.entities.BillingPeriod[message.billingPeriod] : message.billingPeriod;
            if (message.includedUsers != null && message.hasOwnProperty("includedUsers"))
                if (typeof message.includedUsers === "number")
                    object.includedUsers = options.longs === String ? String(message.includedUsers) : message.includedUsers;
                else
                    object.includedUsers = options.longs === String ? $util.Long.prototype.toString.call(message.includedUsers) : options.longs === Number ? new $util.LongBits(message.includedUsers.low >>> 0, message.includedUsers.high >>> 0).toNumber(true) : message.includedUsers;
            if (message.pricePerExtraUser != null && message.hasOwnProperty("pricePerExtraUser"))
                object.pricePerExtraUser = options.json && !isFinite(message.pricePerExtraUser) ? String(message.pricePerExtraUser) : message.pricePerExtraUser;
            if (message.maxUsers != null && message.hasOwnProperty("maxUsers"))
                if (typeof message.maxUsers === "number")
                    object.maxUsers = options.longs === String ? String(message.maxUsers) : message.maxUsers;
                else
                    object.maxUsers = options.longs === String ? $util.Long.prototype.toString.call(message.maxUsers) : options.longs === Number ? new $util.LongBits(message.maxUsers.low >>> 0, message.maxUsers.high >>> 0).toNumber(true) : message.maxUsers;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.isActive != null && message.hasOwnProperty("isActive"))
                object.isActive = message.isActive;
            return object;
        };

        /**
         * Converts this Tariff to JSON.
         * @function toJSON
         * @memberof entities.Tariff
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Tariff.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Tariff
         * @function getTypeUrl
         * @memberof entities.Tariff
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Tariff.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.Tariff";
        };

        return Tariff;
    })();

    /**
     * PaymentStatus enum.
     * @name entities.PaymentStatus
     * @enum {number}
     * @property {number} PENDING=0 PENDING value
     * @property {number} PAID=1 PAID value
     * @property {number} FAILED=2 FAILED value
     */
    entities.PaymentStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PENDING"] = 0;
        values[valuesById[1] = "PAID"] = 1;
        values[valuesById[2] = "FAILED"] = 2;
        return values;
    })();

    entities.BillingHistoryEntry = (function() {

        /**
         * Properties of a BillingHistoryEntry.
         * @memberof entities
         * @interface IBillingHistoryEntry
         * @property {Uint8Array|null} [id] BillingHistoryEntry id
         * @property {Uint8Array|null} [workspaceID] BillingHistoryEntry workspaceID
         * @property {string|null} [stripeSessionID] BillingHistoryEntry stripeSessionID
         * @property {string|null} [tariffID] BillingHistoryEntry tariffID
         * @property {number|null} [amount] BillingHistoryEntry amount
         * @property {string|null} [currency] BillingHistoryEntry currency
         * @property {Long|null} [billingDate] BillingHistoryEntry billingDate
         * @property {Long|null} [billingPeriodStart] BillingHistoryEntry billingPeriodStart
         * @property {Long|null} [billingPeriodEnd] BillingHistoryEntry billingPeriodEnd
         * @property {Long|null} [userCount] BillingHistoryEntry userCount
         * @property {entities.PaymentStatus|null} [paymentStatus] BillingHistoryEntry paymentStatus
         * @property {string|null} [invoicePDF] BillingHistoryEntry invoicePDF
         * @property {string|null} [invoiceID] BillingHistoryEntry invoiceID
         * @property {string|null} [cardBrand] BillingHistoryEntry cardBrand
         * @property {string|null} [cardLastDigits] BillingHistoryEntry cardLastDigits
         */

        /**
         * Constructs a new BillingHistoryEntry.
         * @memberof entities
         * @classdesc Represents a BillingHistoryEntry.
         * @implements IBillingHistoryEntry
         * @constructor
         * @param {entities.IBillingHistoryEntry=} [properties] Properties to set
         */
        function BillingHistoryEntry(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BillingHistoryEntry id.
         * @member {Uint8Array} id
         * @memberof entities.BillingHistoryEntry
         * @instance
         */
        BillingHistoryEntry.prototype.id = $util.newBuffer([]);

        /**
         * BillingHistoryEntry workspaceID.
         * @member {Uint8Array} workspaceID
         * @memberof entities.BillingHistoryEntry
         * @instance
         */
        BillingHistoryEntry.prototype.workspaceID = $util.newBuffer([]);

        /**
         * BillingHistoryEntry stripeSessionID.
         * @member {string} stripeSessionID
         * @memberof entities.BillingHistoryEntry
         * @instance
         */
        BillingHistoryEntry.prototype.stripeSessionID = "";

        /**
         * BillingHistoryEntry tariffID.
         * @member {string} tariffID
         * @memberof entities.BillingHistoryEntry
         * @instance
         */
        BillingHistoryEntry.prototype.tariffID = "";

        /**
         * BillingHistoryEntry amount.
         * @member {number} amount
         * @memberof entities.BillingHistoryEntry
         * @instance
         */
        BillingHistoryEntry.prototype.amount = 0;

        /**
         * BillingHistoryEntry currency.
         * @member {string} currency
         * @memberof entities.BillingHistoryEntry
         * @instance
         */
        BillingHistoryEntry.prototype.currency = "";

        /**
         * BillingHistoryEntry billingDate.
         * @member {Long} billingDate
         * @memberof entities.BillingHistoryEntry
         * @instance
         */
        BillingHistoryEntry.prototype.billingDate = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BillingHistoryEntry billingPeriodStart.
         * @member {Long} billingPeriodStart
         * @memberof entities.BillingHistoryEntry
         * @instance
         */
        BillingHistoryEntry.prototype.billingPeriodStart = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BillingHistoryEntry billingPeriodEnd.
         * @member {Long} billingPeriodEnd
         * @memberof entities.BillingHistoryEntry
         * @instance
         */
        BillingHistoryEntry.prototype.billingPeriodEnd = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BillingHistoryEntry userCount.
         * @member {Long} userCount
         * @memberof entities.BillingHistoryEntry
         * @instance
         */
        BillingHistoryEntry.prototype.userCount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BillingHistoryEntry paymentStatus.
         * @member {entities.PaymentStatus} paymentStatus
         * @memberof entities.BillingHistoryEntry
         * @instance
         */
        BillingHistoryEntry.prototype.paymentStatus = 0;

        /**
         * BillingHistoryEntry invoicePDF.
         * @member {string} invoicePDF
         * @memberof entities.BillingHistoryEntry
         * @instance
         */
        BillingHistoryEntry.prototype.invoicePDF = "";

        /**
         * BillingHistoryEntry invoiceID.
         * @member {string} invoiceID
         * @memberof entities.BillingHistoryEntry
         * @instance
         */
        BillingHistoryEntry.prototype.invoiceID = "";

        /**
         * BillingHistoryEntry cardBrand.
         * @member {string} cardBrand
         * @memberof entities.BillingHistoryEntry
         * @instance
         */
        BillingHistoryEntry.prototype.cardBrand = "";

        /**
         * BillingHistoryEntry cardLastDigits.
         * @member {string} cardLastDigits
         * @memberof entities.BillingHistoryEntry
         * @instance
         */
        BillingHistoryEntry.prototype.cardLastDigits = "";

        /**
         * Creates a new BillingHistoryEntry instance using the specified properties.
         * @function create
         * @memberof entities.BillingHistoryEntry
         * @static
         * @param {entities.IBillingHistoryEntry=} [properties] Properties to set
         * @returns {entities.BillingHistoryEntry} BillingHistoryEntry instance
         */
        BillingHistoryEntry.create = function create(properties) {
            return new BillingHistoryEntry(properties);
        };

        /**
         * Encodes the specified BillingHistoryEntry message. Does not implicitly {@link entities.BillingHistoryEntry.verify|verify} messages.
         * @function encode
         * @memberof entities.BillingHistoryEntry
         * @static
         * @param {entities.IBillingHistoryEntry} message BillingHistoryEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BillingHistoryEntry.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.workspaceID != null && Object.hasOwnProperty.call(message, "workspaceID"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.workspaceID);
            if (message.stripeSessionID != null && Object.hasOwnProperty.call(message, "stripeSessionID"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.stripeSessionID);
            if (message.tariffID != null && Object.hasOwnProperty.call(message, "tariffID"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.tariffID);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 5, wireType 1 =*/41).double(message.amount);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.currency);
            if (message.billingDate != null && Object.hasOwnProperty.call(message, "billingDate"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.billingDate);
            if (message.billingPeriodStart != null && Object.hasOwnProperty.call(message, "billingPeriodStart"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.billingPeriodStart);
            if (message.billingPeriodEnd != null && Object.hasOwnProperty.call(message, "billingPeriodEnd"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.billingPeriodEnd);
            if (message.userCount != null && Object.hasOwnProperty.call(message, "userCount"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.userCount);
            if (message.paymentStatus != null && Object.hasOwnProperty.call(message, "paymentStatus"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.paymentStatus);
            if (message.invoicePDF != null && Object.hasOwnProperty.call(message, "invoicePDF"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.invoicePDF);
            if (message.cardBrand != null && Object.hasOwnProperty.call(message, "cardBrand"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.cardBrand);
            if (message.cardLastDigits != null && Object.hasOwnProperty.call(message, "cardLastDigits"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.cardLastDigits);
            if (message.invoiceID != null && Object.hasOwnProperty.call(message, "invoiceID"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.invoiceID);
            return writer;
        };

        /**
         * Encodes the specified BillingHistoryEntry message, length delimited. Does not implicitly {@link entities.BillingHistoryEntry.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.BillingHistoryEntry
         * @static
         * @param {entities.IBillingHistoryEntry} message BillingHistoryEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BillingHistoryEntry.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BillingHistoryEntry message from the specified reader or buffer.
         * @function decode
         * @memberof entities.BillingHistoryEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.BillingHistoryEntry} BillingHistoryEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BillingHistoryEntry.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.BillingHistoryEntry();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.bytes();
                        break;
                    }
                case 2: {
                        message.workspaceID = reader.bytes();
                        break;
                    }
                case 3: {
                        message.stripeSessionID = reader.string();
                        break;
                    }
                case 4: {
                        message.tariffID = reader.string();
                        break;
                    }
                case 5: {
                        message.amount = reader.double();
                        break;
                    }
                case 6: {
                        message.currency = reader.string();
                        break;
                    }
                case 7: {
                        message.billingDate = reader.uint64();
                        break;
                    }
                case 8: {
                        message.billingPeriodStart = reader.uint64();
                        break;
                    }
                case 9: {
                        message.billingPeriodEnd = reader.uint64();
                        break;
                    }
                case 10: {
                        message.userCount = reader.uint64();
                        break;
                    }
                case 11: {
                        message.paymentStatus = reader.int32();
                        break;
                    }
                case 12: {
                        message.invoicePDF = reader.string();
                        break;
                    }
                case 15: {
                        message.invoiceID = reader.string();
                        break;
                    }
                case 13: {
                        message.cardBrand = reader.string();
                        break;
                    }
                case 14: {
                        message.cardLastDigits = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BillingHistoryEntry message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.BillingHistoryEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.BillingHistoryEntry} BillingHistoryEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BillingHistoryEntry.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BillingHistoryEntry message.
         * @function verify
         * @memberof entities.BillingHistoryEntry
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BillingHistoryEntry.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.workspaceID != null && message.hasOwnProperty("workspaceID"))
                if (!(message.workspaceID && typeof message.workspaceID.length === "number" || $util.isString(message.workspaceID)))
                    return "workspaceID: buffer expected";
            if (message.stripeSessionID != null && message.hasOwnProperty("stripeSessionID"))
                if (!$util.isString(message.stripeSessionID))
                    return "stripeSessionID: string expected";
            if (message.tariffID != null && message.hasOwnProperty("tariffID"))
                if (!$util.isString(message.tariffID))
                    return "tariffID: string expected";
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (typeof message.amount !== "number")
                    return "amount: number expected";
            if (message.currency != null && message.hasOwnProperty("currency"))
                if (!$util.isString(message.currency))
                    return "currency: string expected";
            if (message.billingDate != null && message.hasOwnProperty("billingDate"))
                if (!$util.isInteger(message.billingDate) && !(message.billingDate && $util.isInteger(message.billingDate.low) && $util.isInteger(message.billingDate.high)))
                    return "billingDate: integer|Long expected";
            if (message.billingPeriodStart != null && message.hasOwnProperty("billingPeriodStart"))
                if (!$util.isInteger(message.billingPeriodStart) && !(message.billingPeriodStart && $util.isInteger(message.billingPeriodStart.low) && $util.isInteger(message.billingPeriodStart.high)))
                    return "billingPeriodStart: integer|Long expected";
            if (message.billingPeriodEnd != null && message.hasOwnProperty("billingPeriodEnd"))
                if (!$util.isInteger(message.billingPeriodEnd) && !(message.billingPeriodEnd && $util.isInteger(message.billingPeriodEnd.low) && $util.isInteger(message.billingPeriodEnd.high)))
                    return "billingPeriodEnd: integer|Long expected";
            if (message.userCount != null && message.hasOwnProperty("userCount"))
                if (!$util.isInteger(message.userCount) && !(message.userCount && $util.isInteger(message.userCount.low) && $util.isInteger(message.userCount.high)))
                    return "userCount: integer|Long expected";
            if (message.paymentStatus != null && message.hasOwnProperty("paymentStatus"))
                switch (message.paymentStatus) {
                default:
                    return "paymentStatus: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.invoicePDF != null && message.hasOwnProperty("invoicePDF"))
                if (!$util.isString(message.invoicePDF))
                    return "invoicePDF: string expected";
            if (message.invoiceID != null && message.hasOwnProperty("invoiceID"))
                if (!$util.isString(message.invoiceID))
                    return "invoiceID: string expected";
            if (message.cardBrand != null && message.hasOwnProperty("cardBrand"))
                if (!$util.isString(message.cardBrand))
                    return "cardBrand: string expected";
            if (message.cardLastDigits != null && message.hasOwnProperty("cardLastDigits"))
                if (!$util.isString(message.cardLastDigits))
                    return "cardLastDigits: string expected";
            return null;
        };

        /**
         * Creates a BillingHistoryEntry message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.BillingHistoryEntry
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.BillingHistoryEntry} BillingHistoryEntry
         */
        BillingHistoryEntry.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.BillingHistoryEntry)
                return object;
            let message = new $root.entities.BillingHistoryEntry();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length >= 0)
                    message.id = object.id;
            if (object.workspaceID != null)
                if (typeof object.workspaceID === "string")
                    $util.base64.decode(object.workspaceID, message.workspaceID = $util.newBuffer($util.base64.length(object.workspaceID)), 0);
                else if (object.workspaceID.length >= 0)
                    message.workspaceID = object.workspaceID;
            if (object.stripeSessionID != null)
                message.stripeSessionID = String(object.stripeSessionID);
            if (object.tariffID != null)
                message.tariffID = String(object.tariffID);
            if (object.amount != null)
                message.amount = Number(object.amount);
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.billingDate != null)
                if ($util.Long)
                    (message.billingDate = $util.Long.fromValue(object.billingDate)).unsigned = true;
                else if (typeof object.billingDate === "string")
                    message.billingDate = parseInt(object.billingDate, 10);
                else if (typeof object.billingDate === "number")
                    message.billingDate = object.billingDate;
                else if (typeof object.billingDate === "object")
                    message.billingDate = new $util.LongBits(object.billingDate.low >>> 0, object.billingDate.high >>> 0).toNumber(true);
            if (object.billingPeriodStart != null)
                if ($util.Long)
                    (message.billingPeriodStart = $util.Long.fromValue(object.billingPeriodStart)).unsigned = true;
                else if (typeof object.billingPeriodStart === "string")
                    message.billingPeriodStart = parseInt(object.billingPeriodStart, 10);
                else if (typeof object.billingPeriodStart === "number")
                    message.billingPeriodStart = object.billingPeriodStart;
                else if (typeof object.billingPeriodStart === "object")
                    message.billingPeriodStart = new $util.LongBits(object.billingPeriodStart.low >>> 0, object.billingPeriodStart.high >>> 0).toNumber(true);
            if (object.billingPeriodEnd != null)
                if ($util.Long)
                    (message.billingPeriodEnd = $util.Long.fromValue(object.billingPeriodEnd)).unsigned = true;
                else if (typeof object.billingPeriodEnd === "string")
                    message.billingPeriodEnd = parseInt(object.billingPeriodEnd, 10);
                else if (typeof object.billingPeriodEnd === "number")
                    message.billingPeriodEnd = object.billingPeriodEnd;
                else if (typeof object.billingPeriodEnd === "object")
                    message.billingPeriodEnd = new $util.LongBits(object.billingPeriodEnd.low >>> 0, object.billingPeriodEnd.high >>> 0).toNumber(true);
            if (object.userCount != null)
                if ($util.Long)
                    (message.userCount = $util.Long.fromValue(object.userCount)).unsigned = true;
                else if (typeof object.userCount === "string")
                    message.userCount = parseInt(object.userCount, 10);
                else if (typeof object.userCount === "number")
                    message.userCount = object.userCount;
                else if (typeof object.userCount === "object")
                    message.userCount = new $util.LongBits(object.userCount.low >>> 0, object.userCount.high >>> 0).toNumber(true);
            switch (object.paymentStatus) {
            default:
                if (typeof object.paymentStatus === "number") {
                    message.paymentStatus = object.paymentStatus;
                    break;
                }
                break;
            case "PENDING":
            case 0:
                message.paymentStatus = 0;
                break;
            case "PAID":
            case 1:
                message.paymentStatus = 1;
                break;
            case "FAILED":
            case 2:
                message.paymentStatus = 2;
                break;
            }
            if (object.invoicePDF != null)
                message.invoicePDF = String(object.invoicePDF);
            if (object.invoiceID != null)
                message.invoiceID = String(object.invoiceID);
            if (object.cardBrand != null)
                message.cardBrand = String(object.cardBrand);
            if (object.cardLastDigits != null)
                message.cardLastDigits = String(object.cardLastDigits);
            return message;
        };

        /**
         * Creates a plain object from a BillingHistoryEntry message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.BillingHistoryEntry
         * @static
         * @param {entities.BillingHistoryEntry} message BillingHistoryEntry
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BillingHistoryEntry.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                if (options.bytes === String)
                    object.workspaceID = "";
                else {
                    object.workspaceID = [];
                    if (options.bytes !== Array)
                        object.workspaceID = $util.newBuffer(object.workspaceID);
                }
                object.stripeSessionID = "";
                object.tariffID = "";
                object.amount = 0;
                object.currency = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.billingDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.billingDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.billingPeriodStart = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.billingPeriodStart = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.billingPeriodEnd = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.billingPeriodEnd = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.userCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.userCount = options.longs === String ? "0" : 0;
                object.paymentStatus = options.enums === String ? "PENDING" : 0;
                object.invoicePDF = "";
                object.cardBrand = "";
                object.cardLastDigits = "";
                object.invoiceID = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.workspaceID != null && message.hasOwnProperty("workspaceID"))
                object.workspaceID = options.bytes === String ? $util.base64.encode(message.workspaceID, 0, message.workspaceID.length) : options.bytes === Array ? Array.prototype.slice.call(message.workspaceID) : message.workspaceID;
            if (message.stripeSessionID != null && message.hasOwnProperty("stripeSessionID"))
                object.stripeSessionID = message.stripeSessionID;
            if (message.tariffID != null && message.hasOwnProperty("tariffID"))
                object.tariffID = message.tariffID;
            if (message.amount != null && message.hasOwnProperty("amount"))
                object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.billingDate != null && message.hasOwnProperty("billingDate"))
                if (typeof message.billingDate === "number")
                    object.billingDate = options.longs === String ? String(message.billingDate) : message.billingDate;
                else
                    object.billingDate = options.longs === String ? $util.Long.prototype.toString.call(message.billingDate) : options.longs === Number ? new $util.LongBits(message.billingDate.low >>> 0, message.billingDate.high >>> 0).toNumber(true) : message.billingDate;
            if (message.billingPeriodStart != null && message.hasOwnProperty("billingPeriodStart"))
                if (typeof message.billingPeriodStart === "number")
                    object.billingPeriodStart = options.longs === String ? String(message.billingPeriodStart) : message.billingPeriodStart;
                else
                    object.billingPeriodStart = options.longs === String ? $util.Long.prototype.toString.call(message.billingPeriodStart) : options.longs === Number ? new $util.LongBits(message.billingPeriodStart.low >>> 0, message.billingPeriodStart.high >>> 0).toNumber(true) : message.billingPeriodStart;
            if (message.billingPeriodEnd != null && message.hasOwnProperty("billingPeriodEnd"))
                if (typeof message.billingPeriodEnd === "number")
                    object.billingPeriodEnd = options.longs === String ? String(message.billingPeriodEnd) : message.billingPeriodEnd;
                else
                    object.billingPeriodEnd = options.longs === String ? $util.Long.prototype.toString.call(message.billingPeriodEnd) : options.longs === Number ? new $util.LongBits(message.billingPeriodEnd.low >>> 0, message.billingPeriodEnd.high >>> 0).toNumber(true) : message.billingPeriodEnd;
            if (message.userCount != null && message.hasOwnProperty("userCount"))
                if (typeof message.userCount === "number")
                    object.userCount = options.longs === String ? String(message.userCount) : message.userCount;
                else
                    object.userCount = options.longs === String ? $util.Long.prototype.toString.call(message.userCount) : options.longs === Number ? new $util.LongBits(message.userCount.low >>> 0, message.userCount.high >>> 0).toNumber(true) : message.userCount;
            if (message.paymentStatus != null && message.hasOwnProperty("paymentStatus"))
                object.paymentStatus = options.enums === String ? $root.entities.PaymentStatus[message.paymentStatus] === undefined ? message.paymentStatus : $root.entities.PaymentStatus[message.paymentStatus] : message.paymentStatus;
            if (message.invoicePDF != null && message.hasOwnProperty("invoicePDF"))
                object.invoicePDF = message.invoicePDF;
            if (message.cardBrand != null && message.hasOwnProperty("cardBrand"))
                object.cardBrand = message.cardBrand;
            if (message.cardLastDigits != null && message.hasOwnProperty("cardLastDigits"))
                object.cardLastDigits = message.cardLastDigits;
            if (message.invoiceID != null && message.hasOwnProperty("invoiceID"))
                object.invoiceID = message.invoiceID;
            return object;
        };

        /**
         * Converts this BillingHistoryEntry to JSON.
         * @function toJSON
         * @memberof entities.BillingHistoryEntry
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BillingHistoryEntry.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BillingHistoryEntry
         * @function getTypeUrl
         * @memberof entities.BillingHistoryEntry
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BillingHistoryEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.BillingHistoryEntry";
        };

        return BillingHistoryEntry;
    })();

    /**
     * OzekonChannelType enum.
     * @name entities.OzekonChannelType
     * @enum {number}
     * @property {number} OIT_NONE=0 OIT_NONE value
     * @property {number} OIT_TELEGRAM=1 OIT_TELEGRAM value
     * @property {number} OIT_WEB_WIDGET=2 OIT_WEB_WIDGET value
     * @property {number} OIT_INSTAGRAM=3 OIT_INSTAGRAM value
     * @property {number} OIT_TELEGRAM_BOT=4 OIT_TELEGRAM_BOT value
     */
    entities.OzekonChannelType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OIT_NONE"] = 0;
        values[valuesById[1] = "OIT_TELEGRAM"] = 1;
        values[valuesById[2] = "OIT_WEB_WIDGET"] = 2;
        values[valuesById[3] = "OIT_INSTAGRAM"] = 3;
        values[valuesById[4] = "OIT_TELEGRAM_BOT"] = 4;
        return values;
    })();

    /**
     * OzekonChannelStatus enum.
     * @name entities.OzekonChannelStatus
     * @enum {number}
     * @property {number} OIS_ENABLED=0 OIS_ENABLED value
     * @property {number} OIS_DISABLED=1 OIS_DISABLED value
     */
    entities.OzekonChannelStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OIS_ENABLED"] = 0;
        values[valuesById[1] = "OIS_DISABLED"] = 1;
        return values;
    })();

    /**
     * OzekonChannelOptType enum.
     * @name entities.OzekonChannelOptType
     * @enum {number}
     * @property {number} IO_NAME=0 IO_NAME value
     * @property {number} IO_STATUS=1 IO_STATUS value
     */
    entities.OzekonChannelOptType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "IO_NAME"] = 0;
        values[valuesById[1] = "IO_STATUS"] = 1;
        return values;
    })();

    entities.ChannelOpt = (function() {

        /**
         * Properties of a ChannelOpt.
         * @memberof entities
         * @interface IChannelOpt
         * @property {entities.OzekonChannelOptType|null} [type] ChannelOpt type
         * @property {entities.IOptValue|null} [value] ChannelOpt value
         */

        /**
         * Constructs a new ChannelOpt.
         * @memberof entities
         * @classdesc Represents a ChannelOpt.
         * @implements IChannelOpt
         * @constructor
         * @param {entities.IChannelOpt=} [properties] Properties to set
         */
        function ChannelOpt(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelOpt type.
         * @member {entities.OzekonChannelOptType} type
         * @memberof entities.ChannelOpt
         * @instance
         */
        ChannelOpt.prototype.type = 0;

        /**
         * ChannelOpt value.
         * @member {entities.IOptValue|null|undefined} value
         * @memberof entities.ChannelOpt
         * @instance
         */
        ChannelOpt.prototype.value = null;

        /**
         * Creates a new ChannelOpt instance using the specified properties.
         * @function create
         * @memberof entities.ChannelOpt
         * @static
         * @param {entities.IChannelOpt=} [properties] Properties to set
         * @returns {entities.ChannelOpt} ChannelOpt instance
         */
        ChannelOpt.create = function create(properties) {
            return new ChannelOpt(properties);
        };

        /**
         * Encodes the specified ChannelOpt message. Does not implicitly {@link entities.ChannelOpt.verify|verify} messages.
         * @function encode
         * @memberof entities.ChannelOpt
         * @static
         * @param {entities.IChannelOpt} message ChannelOpt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelOpt.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                $root.entities.OptValue.encode(message.value, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChannelOpt message, length delimited. Does not implicitly {@link entities.ChannelOpt.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.ChannelOpt
         * @static
         * @param {entities.IChannelOpt} message ChannelOpt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelOpt.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelOpt message from the specified reader or buffer.
         * @function decode
         * @memberof entities.ChannelOpt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.ChannelOpt} ChannelOpt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelOpt.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.ChannelOpt();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.value = $root.entities.OptValue.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelOpt message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.ChannelOpt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.ChannelOpt} ChannelOpt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelOpt.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelOpt message.
         * @function verify
         * @memberof entities.ChannelOpt
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelOpt.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.value != null && message.hasOwnProperty("value")) {
                let error = $root.entities.OptValue.verify(message.value);
                if (error)
                    return "value." + error;
            }
            return null;
        };

        /**
         * Creates a ChannelOpt message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.ChannelOpt
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.ChannelOpt} ChannelOpt
         */
        ChannelOpt.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.ChannelOpt)
                return object;
            let message = new $root.entities.ChannelOpt();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "IO_NAME":
            case 0:
                message.type = 0;
                break;
            case "IO_STATUS":
            case 1:
                message.type = 1;
                break;
            }
            if (object.value != null) {
                if (typeof object.value !== "object")
                    throw TypeError(".entities.ChannelOpt.value: object expected");
                message.value = $root.entities.OptValue.fromObject(object.value);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelOpt message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.ChannelOpt
         * @static
         * @param {entities.ChannelOpt} message ChannelOpt
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelOpt.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "IO_NAME" : 0;
                object.value = null;
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.entities.OzekonChannelOptType[message.type] === undefined ? message.type : $root.entities.OzekonChannelOptType[message.type] : message.type;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = $root.entities.OptValue.toObject(message.value, options);
            return object;
        };

        /**
         * Converts this ChannelOpt to JSON.
         * @function toJSON
         * @memberof entities.ChannelOpt
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelOpt.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelOpt
         * @function getTypeUrl
         * @memberof entities.ChannelOpt
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelOpt.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.ChannelOpt";
        };

        return ChannelOpt;
    })();

    entities.ChannelVersion = (function() {

        /**
         * Properties of a ChannelVersion.
         * @memberof entities
         * @interface IChannelVersion
         * @property {Uint8Array|null} [channelId] ChannelVersion channelId
         * @property {Long|null} [version] ChannelVersion version
         */

        /**
         * Constructs a new ChannelVersion.
         * @memberof entities
         * @classdesc Represents a ChannelVersion.
         * @implements IChannelVersion
         * @constructor
         * @param {entities.IChannelVersion=} [properties] Properties to set
         */
        function ChannelVersion(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelVersion channelId.
         * @member {Uint8Array} channelId
         * @memberof entities.ChannelVersion
         * @instance
         */
        ChannelVersion.prototype.channelId = $util.newBuffer([]);

        /**
         * ChannelVersion version.
         * @member {Long} version
         * @memberof entities.ChannelVersion
         * @instance
         */
        ChannelVersion.prototype.version = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ChannelVersion instance using the specified properties.
         * @function create
         * @memberof entities.ChannelVersion
         * @static
         * @param {entities.IChannelVersion=} [properties] Properties to set
         * @returns {entities.ChannelVersion} ChannelVersion instance
         */
        ChannelVersion.create = function create(properties) {
            return new ChannelVersion(properties);
        };

        /**
         * Encodes the specified ChannelVersion message. Does not implicitly {@link entities.ChannelVersion.verify|verify} messages.
         * @function encode
         * @memberof entities.ChannelVersion
         * @static
         * @param {entities.IChannelVersion} message ChannelVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelVersion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channelId != null && Object.hasOwnProperty.call(message, "channelId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelId);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.version);
            return writer;
        };

        /**
         * Encodes the specified ChannelVersion message, length delimited. Does not implicitly {@link entities.ChannelVersion.verify|verify} messages.
         * @function encodeDelimited
         * @memberof entities.ChannelVersion
         * @static
         * @param {entities.IChannelVersion} message ChannelVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelVersion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelVersion message from the specified reader or buffer.
         * @function decode
         * @memberof entities.ChannelVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {entities.ChannelVersion} ChannelVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelVersion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.entities.ChannelVersion();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.version = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelVersion message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof entities.ChannelVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {entities.ChannelVersion} ChannelVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelVersion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelVersion message.
         * @function verify
         * @memberof entities.ChannelVersion
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelVersion.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                if (!(message.channelId && typeof message.channelId.length === "number" || $util.isString(message.channelId)))
                    return "channelId: buffer expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isInteger(message.version) && !(message.version && $util.isInteger(message.version.low) && $util.isInteger(message.version.high)))
                    return "version: integer|Long expected";
            return null;
        };

        /**
         * Creates a ChannelVersion message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof entities.ChannelVersion
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {entities.ChannelVersion} ChannelVersion
         */
        ChannelVersion.fromObject = function fromObject(object) {
            if (object instanceof $root.entities.ChannelVersion)
                return object;
            let message = new $root.entities.ChannelVersion();
            if (object.channelId != null)
                if (typeof object.channelId === "string")
                    $util.base64.decode(object.channelId, message.channelId = $util.newBuffer($util.base64.length(object.channelId)), 0);
                else if (object.channelId.length >= 0)
                    message.channelId = object.channelId;
            if (object.version != null)
                if ($util.Long)
                    (message.version = $util.Long.fromValue(object.version)).unsigned = true;
                else if (typeof object.version === "string")
                    message.version = parseInt(object.version, 10);
                else if (typeof object.version === "number")
                    message.version = object.version;
                else if (typeof object.version === "object")
                    message.version = new $util.LongBits(object.version.low >>> 0, object.version.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a ChannelVersion message. Also converts values to other types if specified.
         * @function toObject
         * @memberof entities.ChannelVersion
         * @static
         * @param {entities.ChannelVersion} message ChannelVersion
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelVersion.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.channelId = "";
                else {
                    object.channelId = [];
                    if (options.bytes !== Array)
                        object.channelId = $util.newBuffer(object.channelId);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.version = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.version = options.longs === String ? "0" : 0;
            }
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                object.channelId = options.bytes === String ? $util.base64.encode(message.channelId, 0, message.channelId.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelId) : message.channelId;
            if (message.version != null && message.hasOwnProperty("version"))
                if (typeof message.version === "number")
                    object.version = options.longs === String ? String(message.version) : message.version;
                else
                    object.version = options.longs === String ? $util.Long.prototype.toString.call(message.version) : options.longs === Number ? new $util.LongBits(message.version.low >>> 0, message.version.high >>> 0).toNumber(true) : message.version;
            return object;
        };

        /**
         * Converts this ChannelVersion to JSON.
         * @function toJSON
         * @memberof entities.ChannelVersion
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelVersion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelVersion
         * @function getTypeUrl
         * @memberof entities.ChannelVersion
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelVersion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/entities.ChannelVersion";
        };

        return ChannelVersion;
    })();

    return entities;
})();

export const MCMethod = $root.MCMethod = (() => {

    /**
     * Properties of a MCMethod.
     * @exports IMCMethod
     * @interface IMCMethod
     * @property {IMCCreateChannel|null} [createChannel] MCMethod createChannel
     * @property {IMCChannelAuthFlow|null} [authFlow] MCMethod authFlow
     * @property {IMCDropChannel|null} [dropChannel] MCMethod dropChannel
     * @property {IMCGetChannels|null} [getChannels] MCMethod getChannels
     * @property {IMCEditChannel|null} [editChannel] MCMethod editChannel
     * @property {IMCMethodGetMessages|null} [getMessages] MCMethod getMessages
     * @property {IMCMethodGetPeers|null} [getPeers] MCMethod getPeers
     * @property {IMCMethodGetChatsPreview|null} [getChatsPreview] MCMethod getChatsPreview
     * @property {IMCMethodMarkRead|null} [markRead] MCMethod markRead
     * @property {IMCMethodSendMessage|null} [sendMessage] MCMethod sendMessage
     * @property {IMCMethodSendGroupedMessages|null} [sendGroupedMessages] MCMethod sendGroupedMessages
     * @property {IMCMethodMarkReadAll|null} [markReadAll] MCMethod markReadAll
     * @property {IMCMethodDeleteChat|null} [deleteChat] MCMethod deleteChat
     * @property {IMCMethodGetChat|null} [getChat] MCMethod getChat
     * @property {IMCMethodGetChats|null} [getChats] MCMethod getChats
     * @property {IMCMethodSearchMessages|null} [searchMessages] MCMethod searchMessages
     * @property {IMCMethodSearchChats|null} [searchChats] MCMethod searchChats
     * @property {IMCMethodQueryPeers|null} [queryPeers] MCMethod queryPeers
     * @property {IMCMethodEditChat|null} [editChat] MCMethod editChat
     * @property {IMCMethodDeleteMessage|null} [deleteMessage] MCMethod deleteMessage
     * @property {IMCMethodRestrictChatAccess|null} [restrictChatAccess] MCMethod restrictChatAccess
     * @property {IMCMethodSendCustomUpdate|null} [sendCustomUpdate] MCMethod sendCustomUpdate
     * @property {IMCMethodGetOrCreateChatByExternalID|null} [getOrCreateChatByExternalID] MCMethod getOrCreateChatByExternalID
     * @property {IMCMethodGetOrCreatePeerByExternalID|null} [getOrCreatePeerByExternalID] MCMethod getOrCreatePeerByExternalID
     * @property {IMCMethodFindMessageLocalID|null} [findMessageLocalID] MCMethod findMessageLocalID
     * @property {IMCMethodFindChatByExternalID|null} [findChatByExternalID] MCMethod findChatByExternalID
     * @property {IMCMethodJoinWidgetChat|null} [joinWidgetChat] MCMethod joinWidgetChat
     * @property {IMCMethodLeaveWidgetChat|null} [leaveWidgetChat] MCMethod leaveWidgetChat
     * @property {IMCMethodSuggestNextOperatorMessages|null} [suggestNextOperatorMessages] MCMethod suggestNextOperatorMessages
     */

    /**
     * Constructs a new MCMethod.
     * @exports MCMethod
     * @classdesc Represents a MCMethod.
     * @implements IMCMethod
     * @constructor
     * @param {IMCMethod=} [properties] Properties to set
     */
    function MCMethod(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethod createChannel.
     * @member {IMCCreateChannel|null|undefined} createChannel
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.createChannel = null;

    /**
     * MCMethod authFlow.
     * @member {IMCChannelAuthFlow|null|undefined} authFlow
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.authFlow = null;

    /**
     * MCMethod dropChannel.
     * @member {IMCDropChannel|null|undefined} dropChannel
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.dropChannel = null;

    /**
     * MCMethod getChannels.
     * @member {IMCGetChannels|null|undefined} getChannels
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.getChannels = null;

    /**
     * MCMethod editChannel.
     * @member {IMCEditChannel|null|undefined} editChannel
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.editChannel = null;

    /**
     * MCMethod getMessages.
     * @member {IMCMethodGetMessages|null|undefined} getMessages
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.getMessages = null;

    /**
     * MCMethod getPeers.
     * @member {IMCMethodGetPeers|null|undefined} getPeers
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.getPeers = null;

    /**
     * MCMethod getChatsPreview.
     * @member {IMCMethodGetChatsPreview|null|undefined} getChatsPreview
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.getChatsPreview = null;

    /**
     * MCMethod markRead.
     * @member {IMCMethodMarkRead|null|undefined} markRead
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.markRead = null;

    /**
     * MCMethod sendMessage.
     * @member {IMCMethodSendMessage|null|undefined} sendMessage
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.sendMessage = null;

    /**
     * MCMethod sendGroupedMessages.
     * @member {IMCMethodSendGroupedMessages|null|undefined} sendGroupedMessages
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.sendGroupedMessages = null;

    /**
     * MCMethod markReadAll.
     * @member {IMCMethodMarkReadAll|null|undefined} markReadAll
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.markReadAll = null;

    /**
     * MCMethod deleteChat.
     * @member {IMCMethodDeleteChat|null|undefined} deleteChat
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.deleteChat = null;

    /**
     * MCMethod getChat.
     * @member {IMCMethodGetChat|null|undefined} getChat
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.getChat = null;

    /**
     * MCMethod getChats.
     * @member {IMCMethodGetChats|null|undefined} getChats
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.getChats = null;

    /**
     * MCMethod searchMessages.
     * @member {IMCMethodSearchMessages|null|undefined} searchMessages
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.searchMessages = null;

    /**
     * MCMethod searchChats.
     * @member {IMCMethodSearchChats|null|undefined} searchChats
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.searchChats = null;

    /**
     * MCMethod queryPeers.
     * @member {IMCMethodQueryPeers|null|undefined} queryPeers
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.queryPeers = null;

    /**
     * MCMethod editChat.
     * @member {IMCMethodEditChat|null|undefined} editChat
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.editChat = null;

    /**
     * MCMethod deleteMessage.
     * @member {IMCMethodDeleteMessage|null|undefined} deleteMessage
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.deleteMessage = null;

    /**
     * MCMethod restrictChatAccess.
     * @member {IMCMethodRestrictChatAccess|null|undefined} restrictChatAccess
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.restrictChatAccess = null;

    /**
     * MCMethod sendCustomUpdate.
     * @member {IMCMethodSendCustomUpdate|null|undefined} sendCustomUpdate
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.sendCustomUpdate = null;

    /**
     * MCMethod getOrCreateChatByExternalID.
     * @member {IMCMethodGetOrCreateChatByExternalID|null|undefined} getOrCreateChatByExternalID
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.getOrCreateChatByExternalID = null;

    /**
     * MCMethod getOrCreatePeerByExternalID.
     * @member {IMCMethodGetOrCreatePeerByExternalID|null|undefined} getOrCreatePeerByExternalID
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.getOrCreatePeerByExternalID = null;

    /**
     * MCMethod findMessageLocalID.
     * @member {IMCMethodFindMessageLocalID|null|undefined} findMessageLocalID
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.findMessageLocalID = null;

    /**
     * MCMethod findChatByExternalID.
     * @member {IMCMethodFindChatByExternalID|null|undefined} findChatByExternalID
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.findChatByExternalID = null;

    /**
     * MCMethod joinWidgetChat.
     * @member {IMCMethodJoinWidgetChat|null|undefined} joinWidgetChat
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.joinWidgetChat = null;

    /**
     * MCMethod leaveWidgetChat.
     * @member {IMCMethodLeaveWidgetChat|null|undefined} leaveWidgetChat
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.leaveWidgetChat = null;

    /**
     * MCMethod suggestNextOperatorMessages.
     * @member {IMCMethodSuggestNextOperatorMessages|null|undefined} suggestNextOperatorMessages
     * @memberof MCMethod
     * @instance
     */
    MCMethod.prototype.suggestNextOperatorMessages = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * MCMethod method.
     * @member {"createChannel"|"authFlow"|"dropChannel"|"getChannels"|"editChannel"|"getMessages"|"getPeers"|"getChatsPreview"|"markRead"|"sendMessage"|"sendGroupedMessages"|"markReadAll"|"deleteChat"|"getChat"|"getChats"|"searchMessages"|"searchChats"|"queryPeers"|"editChat"|"deleteMessage"|"restrictChatAccess"|"sendCustomUpdate"|"getOrCreateChatByExternalID"|"getOrCreatePeerByExternalID"|"findMessageLocalID"|"findChatByExternalID"|"joinWidgetChat"|"leaveWidgetChat"|"suggestNextOperatorMessages"|undefined} method
     * @memberof MCMethod
     * @instance
     */
    Object.defineProperty(MCMethod.prototype, "method", {
        get: $util.oneOfGetter($oneOfFields = ["createChannel", "authFlow", "dropChannel", "getChannels", "editChannel", "getMessages", "getPeers", "getChatsPreview", "markRead", "sendMessage", "sendGroupedMessages", "markReadAll", "deleteChat", "getChat", "getChats", "searchMessages", "searchChats", "queryPeers", "editChat", "deleteMessage", "restrictChatAccess", "sendCustomUpdate", "getOrCreateChatByExternalID", "getOrCreatePeerByExternalID", "findMessageLocalID", "findChatByExternalID", "joinWidgetChat", "leaveWidgetChat", "suggestNextOperatorMessages"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new MCMethod instance using the specified properties.
     * @function create
     * @memberof MCMethod
     * @static
     * @param {IMCMethod=} [properties] Properties to set
     * @returns {MCMethod} MCMethod instance
     */
    MCMethod.create = function create(properties) {
        return new MCMethod(properties);
    };

    /**
     * Encodes the specified MCMethod message. Does not implicitly {@link MCMethod.verify|verify} messages.
     * @function encode
     * @memberof MCMethod
     * @static
     * @param {IMCMethod} message MCMethod message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethod.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.createChannel != null && Object.hasOwnProperty.call(message, "createChannel"))
            $root.MCCreateChannel.encode(message.createChannel, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.authFlow != null && Object.hasOwnProperty.call(message, "authFlow"))
            $root.MCChannelAuthFlow.encode(message.authFlow, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.dropChannel != null && Object.hasOwnProperty.call(message, "dropChannel"))
            $root.MCDropChannel.encode(message.dropChannel, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.getChannels != null && Object.hasOwnProperty.call(message, "getChannels"))
            $root.MCGetChannels.encode(message.getChannels, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.editChannel != null && Object.hasOwnProperty.call(message, "editChannel"))
            $root.MCEditChannel.encode(message.editChannel, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.getMessages != null && Object.hasOwnProperty.call(message, "getMessages"))
            $root.MCMethodGetMessages.encode(message.getMessages, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
        if (message.getPeers != null && Object.hasOwnProperty.call(message, "getPeers"))
            $root.MCMethodGetPeers.encode(message.getPeers, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
        if (message.getChatsPreview != null && Object.hasOwnProperty.call(message, "getChatsPreview"))
            $root.MCMethodGetChatsPreview.encode(message.getChatsPreview, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
        if (message.markRead != null && Object.hasOwnProperty.call(message, "markRead"))
            $root.MCMethodMarkRead.encode(message.markRead, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
        if (message.sendMessage != null && Object.hasOwnProperty.call(message, "sendMessage"))
            $root.MCMethodSendMessage.encode(message.sendMessage, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
        if (message.sendGroupedMessages != null && Object.hasOwnProperty.call(message, "sendGroupedMessages"))
            $root.MCMethodSendGroupedMessages.encode(message.sendGroupedMessages, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
        if (message.markReadAll != null && Object.hasOwnProperty.call(message, "markReadAll"))
            $root.MCMethodMarkReadAll.encode(message.markReadAll, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
        if (message.deleteChat != null && Object.hasOwnProperty.call(message, "deleteChat"))
            $root.MCMethodDeleteChat.encode(message.deleteChat, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
        if (message.searchMessages != null && Object.hasOwnProperty.call(message, "searchMessages"))
            $root.MCMethodSearchMessages.encode(message.searchMessages, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
        if (message.searchChats != null && Object.hasOwnProperty.call(message, "searchChats"))
            $root.MCMethodSearchChats.encode(message.searchChats, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
        if (message.queryPeers != null && Object.hasOwnProperty.call(message, "queryPeers"))
            $root.MCMethodQueryPeers.encode(message.queryPeers, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
        if (message.editChat != null && Object.hasOwnProperty.call(message, "editChat"))
            $root.MCMethodEditChat.encode(message.editChat, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
        if (message.deleteMessage != null && Object.hasOwnProperty.call(message, "deleteMessage"))
            $root.MCMethodDeleteMessage.encode(message.deleteMessage, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
        if (message.restrictChatAccess != null && Object.hasOwnProperty.call(message, "restrictChatAccess"))
            $root.MCMethodRestrictChatAccess.encode(message.restrictChatAccess, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
        if (message.sendCustomUpdate != null && Object.hasOwnProperty.call(message, "sendCustomUpdate"))
            $root.MCMethodSendCustomUpdate.encode(message.sendCustomUpdate, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
        if (message.getOrCreateChatByExternalID != null && Object.hasOwnProperty.call(message, "getOrCreateChatByExternalID"))
            $root.MCMethodGetOrCreateChatByExternalID.encode(message.getOrCreateChatByExternalID, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
        if (message.getOrCreatePeerByExternalID != null && Object.hasOwnProperty.call(message, "getOrCreatePeerByExternalID"))
            $root.MCMethodGetOrCreatePeerByExternalID.encode(message.getOrCreatePeerByExternalID, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
        if (message.findMessageLocalID != null && Object.hasOwnProperty.call(message, "findMessageLocalID"))
            $root.MCMethodFindMessageLocalID.encode(message.findMessageLocalID, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
        if (message.findChatByExternalID != null && Object.hasOwnProperty.call(message, "findChatByExternalID"))
            $root.MCMethodFindChatByExternalID.encode(message.findChatByExternalID, writer.uint32(/* id 53, wireType 2 =*/426).fork()).ldelim();
        if (message.getChat != null && Object.hasOwnProperty.call(message, "getChat"))
            $root.MCMethodGetChat.encode(message.getChat, writer.uint32(/* id 60, wireType 2 =*/482).fork()).ldelim();
        if (message.getChats != null && Object.hasOwnProperty.call(message, "getChats"))
            $root.MCMethodGetChats.encode(message.getChats, writer.uint32(/* id 61, wireType 2 =*/490).fork()).ldelim();
        if (message.joinWidgetChat != null && Object.hasOwnProperty.call(message, "joinWidgetChat"))
            $root.MCMethodJoinWidgetChat.encode(message.joinWidgetChat, writer.uint32(/* id 70, wireType 2 =*/562).fork()).ldelim();
        if (message.leaveWidgetChat != null && Object.hasOwnProperty.call(message, "leaveWidgetChat"))
            $root.MCMethodLeaveWidgetChat.encode(message.leaveWidgetChat, writer.uint32(/* id 71, wireType 2 =*/570).fork()).ldelim();
        if (message.suggestNextOperatorMessages != null && Object.hasOwnProperty.call(message, "suggestNextOperatorMessages"))
            $root.MCMethodSuggestNextOperatorMessages.encode(message.suggestNextOperatorMessages, writer.uint32(/* id 80, wireType 2 =*/642).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethod message, length delimited. Does not implicitly {@link MCMethod.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethod
     * @static
     * @param {IMCMethod} message MCMethod message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethod.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethod message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethod
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethod} MCMethod
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethod.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethod();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 3: {
                    message.createChannel = $root.MCCreateChannel.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.authFlow = $root.MCChannelAuthFlow.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.dropChannel = $root.MCDropChannel.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.getChannels = $root.MCGetChannels.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.editChannel = $root.MCEditChannel.decode(reader, reader.uint32());
                    break;
                }
            case 20: {
                    message.getMessages = $root.MCMethodGetMessages.decode(reader, reader.uint32());
                    break;
                }
            case 21: {
                    message.getPeers = $root.MCMethodGetPeers.decode(reader, reader.uint32());
                    break;
                }
            case 22: {
                    message.getChatsPreview = $root.MCMethodGetChatsPreview.decode(reader, reader.uint32());
                    break;
                }
            case 24: {
                    message.markRead = $root.MCMethodMarkRead.decode(reader, reader.uint32());
                    break;
                }
            case 25: {
                    message.sendMessage = $root.MCMethodSendMessage.decode(reader, reader.uint32());
                    break;
                }
            case 27: {
                    message.sendGroupedMessages = $root.MCMethodSendGroupedMessages.decode(reader, reader.uint32());
                    break;
                }
            case 28: {
                    message.markReadAll = $root.MCMethodMarkReadAll.decode(reader, reader.uint32());
                    break;
                }
            case 29: {
                    message.deleteChat = $root.MCMethodDeleteChat.decode(reader, reader.uint32());
                    break;
                }
            case 60: {
                    message.getChat = $root.MCMethodGetChat.decode(reader, reader.uint32());
                    break;
                }
            case 61: {
                    message.getChats = $root.MCMethodGetChats.decode(reader, reader.uint32());
                    break;
                }
            case 30: {
                    message.searchMessages = $root.MCMethodSearchMessages.decode(reader, reader.uint32());
                    break;
                }
            case 31: {
                    message.searchChats = $root.MCMethodSearchChats.decode(reader, reader.uint32());
                    break;
                }
            case 32: {
                    message.queryPeers = $root.MCMethodQueryPeers.decode(reader, reader.uint32());
                    break;
                }
            case 33: {
                    message.editChat = $root.MCMethodEditChat.decode(reader, reader.uint32());
                    break;
                }
            case 35: {
                    message.deleteMessage = $root.MCMethodDeleteMessage.decode(reader, reader.uint32());
                    break;
                }
            case 36: {
                    message.restrictChatAccess = $root.MCMethodRestrictChatAccess.decode(reader, reader.uint32());
                    break;
                }
            case 40: {
                    message.sendCustomUpdate = $root.MCMethodSendCustomUpdate.decode(reader, reader.uint32());
                    break;
                }
            case 50: {
                    message.getOrCreateChatByExternalID = $root.MCMethodGetOrCreateChatByExternalID.decode(reader, reader.uint32());
                    break;
                }
            case 51: {
                    message.getOrCreatePeerByExternalID = $root.MCMethodGetOrCreatePeerByExternalID.decode(reader, reader.uint32());
                    break;
                }
            case 52: {
                    message.findMessageLocalID = $root.MCMethodFindMessageLocalID.decode(reader, reader.uint32());
                    break;
                }
            case 53: {
                    message.findChatByExternalID = $root.MCMethodFindChatByExternalID.decode(reader, reader.uint32());
                    break;
                }
            case 70: {
                    message.joinWidgetChat = $root.MCMethodJoinWidgetChat.decode(reader, reader.uint32());
                    break;
                }
            case 71: {
                    message.leaveWidgetChat = $root.MCMethodLeaveWidgetChat.decode(reader, reader.uint32());
                    break;
                }
            case 80: {
                    message.suggestNextOperatorMessages = $root.MCMethodSuggestNextOperatorMessages.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethod message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethod
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethod} MCMethod
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethod.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethod message.
     * @function verify
     * @memberof MCMethod
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethod.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.createChannel != null && message.hasOwnProperty("createChannel")) {
            properties.method = 1;
            {
                let error = $root.MCCreateChannel.verify(message.createChannel);
                if (error)
                    return "createChannel." + error;
            }
        }
        if (message.authFlow != null && message.hasOwnProperty("authFlow")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCChannelAuthFlow.verify(message.authFlow);
                if (error)
                    return "authFlow." + error;
            }
        }
        if (message.dropChannel != null && message.hasOwnProperty("dropChannel")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCDropChannel.verify(message.dropChannel);
                if (error)
                    return "dropChannel." + error;
            }
        }
        if (message.getChannels != null && message.hasOwnProperty("getChannels")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCGetChannels.verify(message.getChannels);
                if (error)
                    return "getChannels." + error;
            }
        }
        if (message.editChannel != null && message.hasOwnProperty("editChannel")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCEditChannel.verify(message.editChannel);
                if (error)
                    return "editChannel." + error;
            }
        }
        if (message.getMessages != null && message.hasOwnProperty("getMessages")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodGetMessages.verify(message.getMessages);
                if (error)
                    return "getMessages." + error;
            }
        }
        if (message.getPeers != null && message.hasOwnProperty("getPeers")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodGetPeers.verify(message.getPeers);
                if (error)
                    return "getPeers." + error;
            }
        }
        if (message.getChatsPreview != null && message.hasOwnProperty("getChatsPreview")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodGetChatsPreview.verify(message.getChatsPreview);
                if (error)
                    return "getChatsPreview." + error;
            }
        }
        if (message.markRead != null && message.hasOwnProperty("markRead")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodMarkRead.verify(message.markRead);
                if (error)
                    return "markRead." + error;
            }
        }
        if (message.sendMessage != null && message.hasOwnProperty("sendMessage")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodSendMessage.verify(message.sendMessage);
                if (error)
                    return "sendMessage." + error;
            }
        }
        if (message.sendGroupedMessages != null && message.hasOwnProperty("sendGroupedMessages")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodSendGroupedMessages.verify(message.sendGroupedMessages);
                if (error)
                    return "sendGroupedMessages." + error;
            }
        }
        if (message.markReadAll != null && message.hasOwnProperty("markReadAll")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodMarkReadAll.verify(message.markReadAll);
                if (error)
                    return "markReadAll." + error;
            }
        }
        if (message.deleteChat != null && message.hasOwnProperty("deleteChat")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodDeleteChat.verify(message.deleteChat);
                if (error)
                    return "deleteChat." + error;
            }
        }
        if (message.getChat != null && message.hasOwnProperty("getChat")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodGetChat.verify(message.getChat);
                if (error)
                    return "getChat." + error;
            }
        }
        if (message.getChats != null && message.hasOwnProperty("getChats")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodGetChats.verify(message.getChats);
                if (error)
                    return "getChats." + error;
            }
        }
        if (message.searchMessages != null && message.hasOwnProperty("searchMessages")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodSearchMessages.verify(message.searchMessages);
                if (error)
                    return "searchMessages." + error;
            }
        }
        if (message.searchChats != null && message.hasOwnProperty("searchChats")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodSearchChats.verify(message.searchChats);
                if (error)
                    return "searchChats." + error;
            }
        }
        if (message.queryPeers != null && message.hasOwnProperty("queryPeers")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodQueryPeers.verify(message.queryPeers);
                if (error)
                    return "queryPeers." + error;
            }
        }
        if (message.editChat != null && message.hasOwnProperty("editChat")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodEditChat.verify(message.editChat);
                if (error)
                    return "editChat." + error;
            }
        }
        if (message.deleteMessage != null && message.hasOwnProperty("deleteMessage")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodDeleteMessage.verify(message.deleteMessage);
                if (error)
                    return "deleteMessage." + error;
            }
        }
        if (message.restrictChatAccess != null && message.hasOwnProperty("restrictChatAccess")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodRestrictChatAccess.verify(message.restrictChatAccess);
                if (error)
                    return "restrictChatAccess." + error;
            }
        }
        if (message.sendCustomUpdate != null && message.hasOwnProperty("sendCustomUpdate")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodSendCustomUpdate.verify(message.sendCustomUpdate);
                if (error)
                    return "sendCustomUpdate." + error;
            }
        }
        if (message.getOrCreateChatByExternalID != null && message.hasOwnProperty("getOrCreateChatByExternalID")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodGetOrCreateChatByExternalID.verify(message.getOrCreateChatByExternalID);
                if (error)
                    return "getOrCreateChatByExternalID." + error;
            }
        }
        if (message.getOrCreatePeerByExternalID != null && message.hasOwnProperty("getOrCreatePeerByExternalID")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodGetOrCreatePeerByExternalID.verify(message.getOrCreatePeerByExternalID);
                if (error)
                    return "getOrCreatePeerByExternalID." + error;
            }
        }
        if (message.findMessageLocalID != null && message.hasOwnProperty("findMessageLocalID")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodFindMessageLocalID.verify(message.findMessageLocalID);
                if (error)
                    return "findMessageLocalID." + error;
            }
        }
        if (message.findChatByExternalID != null && message.hasOwnProperty("findChatByExternalID")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodFindChatByExternalID.verify(message.findChatByExternalID);
                if (error)
                    return "findChatByExternalID." + error;
            }
        }
        if (message.joinWidgetChat != null && message.hasOwnProperty("joinWidgetChat")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodJoinWidgetChat.verify(message.joinWidgetChat);
                if (error)
                    return "joinWidgetChat." + error;
            }
        }
        if (message.leaveWidgetChat != null && message.hasOwnProperty("leaveWidgetChat")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodLeaveWidgetChat.verify(message.leaveWidgetChat);
                if (error)
                    return "leaveWidgetChat." + error;
            }
        }
        if (message.suggestNextOperatorMessages != null && message.hasOwnProperty("suggestNextOperatorMessages")) {
            if (properties.method === 1)
                return "method: multiple values";
            properties.method = 1;
            {
                let error = $root.MCMethodSuggestNextOperatorMessages.verify(message.suggestNextOperatorMessages);
                if (error)
                    return "suggestNextOperatorMessages." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCMethod message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethod
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethod} MCMethod
     */
    MCMethod.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethod)
            return object;
        let message = new $root.MCMethod();
        if (object.createChannel != null) {
            if (typeof object.createChannel !== "object")
                throw TypeError(".MCMethod.createChannel: object expected");
            message.createChannel = $root.MCCreateChannel.fromObject(object.createChannel);
        }
        if (object.authFlow != null) {
            if (typeof object.authFlow !== "object")
                throw TypeError(".MCMethod.authFlow: object expected");
            message.authFlow = $root.MCChannelAuthFlow.fromObject(object.authFlow);
        }
        if (object.dropChannel != null) {
            if (typeof object.dropChannel !== "object")
                throw TypeError(".MCMethod.dropChannel: object expected");
            message.dropChannel = $root.MCDropChannel.fromObject(object.dropChannel);
        }
        if (object.getChannels != null) {
            if (typeof object.getChannels !== "object")
                throw TypeError(".MCMethod.getChannels: object expected");
            message.getChannels = $root.MCGetChannels.fromObject(object.getChannels);
        }
        if (object.editChannel != null) {
            if (typeof object.editChannel !== "object")
                throw TypeError(".MCMethod.editChannel: object expected");
            message.editChannel = $root.MCEditChannel.fromObject(object.editChannel);
        }
        if (object.getMessages != null) {
            if (typeof object.getMessages !== "object")
                throw TypeError(".MCMethod.getMessages: object expected");
            message.getMessages = $root.MCMethodGetMessages.fromObject(object.getMessages);
        }
        if (object.getPeers != null) {
            if (typeof object.getPeers !== "object")
                throw TypeError(".MCMethod.getPeers: object expected");
            message.getPeers = $root.MCMethodGetPeers.fromObject(object.getPeers);
        }
        if (object.getChatsPreview != null) {
            if (typeof object.getChatsPreview !== "object")
                throw TypeError(".MCMethod.getChatsPreview: object expected");
            message.getChatsPreview = $root.MCMethodGetChatsPreview.fromObject(object.getChatsPreview);
        }
        if (object.markRead != null) {
            if (typeof object.markRead !== "object")
                throw TypeError(".MCMethod.markRead: object expected");
            message.markRead = $root.MCMethodMarkRead.fromObject(object.markRead);
        }
        if (object.sendMessage != null) {
            if (typeof object.sendMessage !== "object")
                throw TypeError(".MCMethod.sendMessage: object expected");
            message.sendMessage = $root.MCMethodSendMessage.fromObject(object.sendMessage);
        }
        if (object.sendGroupedMessages != null) {
            if (typeof object.sendGroupedMessages !== "object")
                throw TypeError(".MCMethod.sendGroupedMessages: object expected");
            message.sendGroupedMessages = $root.MCMethodSendGroupedMessages.fromObject(object.sendGroupedMessages);
        }
        if (object.markReadAll != null) {
            if (typeof object.markReadAll !== "object")
                throw TypeError(".MCMethod.markReadAll: object expected");
            message.markReadAll = $root.MCMethodMarkReadAll.fromObject(object.markReadAll);
        }
        if (object.deleteChat != null) {
            if (typeof object.deleteChat !== "object")
                throw TypeError(".MCMethod.deleteChat: object expected");
            message.deleteChat = $root.MCMethodDeleteChat.fromObject(object.deleteChat);
        }
        if (object.getChat != null) {
            if (typeof object.getChat !== "object")
                throw TypeError(".MCMethod.getChat: object expected");
            message.getChat = $root.MCMethodGetChat.fromObject(object.getChat);
        }
        if (object.getChats != null) {
            if (typeof object.getChats !== "object")
                throw TypeError(".MCMethod.getChats: object expected");
            message.getChats = $root.MCMethodGetChats.fromObject(object.getChats);
        }
        if (object.searchMessages != null) {
            if (typeof object.searchMessages !== "object")
                throw TypeError(".MCMethod.searchMessages: object expected");
            message.searchMessages = $root.MCMethodSearchMessages.fromObject(object.searchMessages);
        }
        if (object.searchChats != null) {
            if (typeof object.searchChats !== "object")
                throw TypeError(".MCMethod.searchChats: object expected");
            message.searchChats = $root.MCMethodSearchChats.fromObject(object.searchChats);
        }
        if (object.queryPeers != null) {
            if (typeof object.queryPeers !== "object")
                throw TypeError(".MCMethod.queryPeers: object expected");
            message.queryPeers = $root.MCMethodQueryPeers.fromObject(object.queryPeers);
        }
        if (object.editChat != null) {
            if (typeof object.editChat !== "object")
                throw TypeError(".MCMethod.editChat: object expected");
            message.editChat = $root.MCMethodEditChat.fromObject(object.editChat);
        }
        if (object.deleteMessage != null) {
            if (typeof object.deleteMessage !== "object")
                throw TypeError(".MCMethod.deleteMessage: object expected");
            message.deleteMessage = $root.MCMethodDeleteMessage.fromObject(object.deleteMessage);
        }
        if (object.restrictChatAccess != null) {
            if (typeof object.restrictChatAccess !== "object")
                throw TypeError(".MCMethod.restrictChatAccess: object expected");
            message.restrictChatAccess = $root.MCMethodRestrictChatAccess.fromObject(object.restrictChatAccess);
        }
        if (object.sendCustomUpdate != null) {
            if (typeof object.sendCustomUpdate !== "object")
                throw TypeError(".MCMethod.sendCustomUpdate: object expected");
            message.sendCustomUpdate = $root.MCMethodSendCustomUpdate.fromObject(object.sendCustomUpdate);
        }
        if (object.getOrCreateChatByExternalID != null) {
            if (typeof object.getOrCreateChatByExternalID !== "object")
                throw TypeError(".MCMethod.getOrCreateChatByExternalID: object expected");
            message.getOrCreateChatByExternalID = $root.MCMethodGetOrCreateChatByExternalID.fromObject(object.getOrCreateChatByExternalID);
        }
        if (object.getOrCreatePeerByExternalID != null) {
            if (typeof object.getOrCreatePeerByExternalID !== "object")
                throw TypeError(".MCMethod.getOrCreatePeerByExternalID: object expected");
            message.getOrCreatePeerByExternalID = $root.MCMethodGetOrCreatePeerByExternalID.fromObject(object.getOrCreatePeerByExternalID);
        }
        if (object.findMessageLocalID != null) {
            if (typeof object.findMessageLocalID !== "object")
                throw TypeError(".MCMethod.findMessageLocalID: object expected");
            message.findMessageLocalID = $root.MCMethodFindMessageLocalID.fromObject(object.findMessageLocalID);
        }
        if (object.findChatByExternalID != null) {
            if (typeof object.findChatByExternalID !== "object")
                throw TypeError(".MCMethod.findChatByExternalID: object expected");
            message.findChatByExternalID = $root.MCMethodFindChatByExternalID.fromObject(object.findChatByExternalID);
        }
        if (object.joinWidgetChat != null) {
            if (typeof object.joinWidgetChat !== "object")
                throw TypeError(".MCMethod.joinWidgetChat: object expected");
            message.joinWidgetChat = $root.MCMethodJoinWidgetChat.fromObject(object.joinWidgetChat);
        }
        if (object.leaveWidgetChat != null) {
            if (typeof object.leaveWidgetChat !== "object")
                throw TypeError(".MCMethod.leaveWidgetChat: object expected");
            message.leaveWidgetChat = $root.MCMethodLeaveWidgetChat.fromObject(object.leaveWidgetChat);
        }
        if (object.suggestNextOperatorMessages != null) {
            if (typeof object.suggestNextOperatorMessages !== "object")
                throw TypeError(".MCMethod.suggestNextOperatorMessages: object expected");
            message.suggestNextOperatorMessages = $root.MCMethodSuggestNextOperatorMessages.fromObject(object.suggestNextOperatorMessages);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethod message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethod
     * @static
     * @param {MCMethod} message MCMethod
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethod.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (message.createChannel != null && message.hasOwnProperty("createChannel")) {
            object.createChannel = $root.MCCreateChannel.toObject(message.createChannel, options);
            if (options.oneofs)
                object.method = "createChannel";
        }
        if (message.authFlow != null && message.hasOwnProperty("authFlow")) {
            object.authFlow = $root.MCChannelAuthFlow.toObject(message.authFlow, options);
            if (options.oneofs)
                object.method = "authFlow";
        }
        if (message.dropChannel != null && message.hasOwnProperty("dropChannel")) {
            object.dropChannel = $root.MCDropChannel.toObject(message.dropChannel, options);
            if (options.oneofs)
                object.method = "dropChannel";
        }
        if (message.getChannels != null && message.hasOwnProperty("getChannels")) {
            object.getChannels = $root.MCGetChannels.toObject(message.getChannels, options);
            if (options.oneofs)
                object.method = "getChannels";
        }
        if (message.editChannel != null && message.hasOwnProperty("editChannel")) {
            object.editChannel = $root.MCEditChannel.toObject(message.editChannel, options);
            if (options.oneofs)
                object.method = "editChannel";
        }
        if (message.getMessages != null && message.hasOwnProperty("getMessages")) {
            object.getMessages = $root.MCMethodGetMessages.toObject(message.getMessages, options);
            if (options.oneofs)
                object.method = "getMessages";
        }
        if (message.getPeers != null && message.hasOwnProperty("getPeers")) {
            object.getPeers = $root.MCMethodGetPeers.toObject(message.getPeers, options);
            if (options.oneofs)
                object.method = "getPeers";
        }
        if (message.getChatsPreview != null && message.hasOwnProperty("getChatsPreview")) {
            object.getChatsPreview = $root.MCMethodGetChatsPreview.toObject(message.getChatsPreview, options);
            if (options.oneofs)
                object.method = "getChatsPreview";
        }
        if (message.markRead != null && message.hasOwnProperty("markRead")) {
            object.markRead = $root.MCMethodMarkRead.toObject(message.markRead, options);
            if (options.oneofs)
                object.method = "markRead";
        }
        if (message.sendMessage != null && message.hasOwnProperty("sendMessage")) {
            object.sendMessage = $root.MCMethodSendMessage.toObject(message.sendMessage, options);
            if (options.oneofs)
                object.method = "sendMessage";
        }
        if (message.sendGroupedMessages != null && message.hasOwnProperty("sendGroupedMessages")) {
            object.sendGroupedMessages = $root.MCMethodSendGroupedMessages.toObject(message.sendGroupedMessages, options);
            if (options.oneofs)
                object.method = "sendGroupedMessages";
        }
        if (message.markReadAll != null && message.hasOwnProperty("markReadAll")) {
            object.markReadAll = $root.MCMethodMarkReadAll.toObject(message.markReadAll, options);
            if (options.oneofs)
                object.method = "markReadAll";
        }
        if (message.deleteChat != null && message.hasOwnProperty("deleteChat")) {
            object.deleteChat = $root.MCMethodDeleteChat.toObject(message.deleteChat, options);
            if (options.oneofs)
                object.method = "deleteChat";
        }
        if (message.searchMessages != null && message.hasOwnProperty("searchMessages")) {
            object.searchMessages = $root.MCMethodSearchMessages.toObject(message.searchMessages, options);
            if (options.oneofs)
                object.method = "searchMessages";
        }
        if (message.searchChats != null && message.hasOwnProperty("searchChats")) {
            object.searchChats = $root.MCMethodSearchChats.toObject(message.searchChats, options);
            if (options.oneofs)
                object.method = "searchChats";
        }
        if (message.queryPeers != null && message.hasOwnProperty("queryPeers")) {
            object.queryPeers = $root.MCMethodQueryPeers.toObject(message.queryPeers, options);
            if (options.oneofs)
                object.method = "queryPeers";
        }
        if (message.editChat != null && message.hasOwnProperty("editChat")) {
            object.editChat = $root.MCMethodEditChat.toObject(message.editChat, options);
            if (options.oneofs)
                object.method = "editChat";
        }
        if (message.deleteMessage != null && message.hasOwnProperty("deleteMessage")) {
            object.deleteMessage = $root.MCMethodDeleteMessage.toObject(message.deleteMessage, options);
            if (options.oneofs)
                object.method = "deleteMessage";
        }
        if (message.restrictChatAccess != null && message.hasOwnProperty("restrictChatAccess")) {
            object.restrictChatAccess = $root.MCMethodRestrictChatAccess.toObject(message.restrictChatAccess, options);
            if (options.oneofs)
                object.method = "restrictChatAccess";
        }
        if (message.sendCustomUpdate != null && message.hasOwnProperty("sendCustomUpdate")) {
            object.sendCustomUpdate = $root.MCMethodSendCustomUpdate.toObject(message.sendCustomUpdate, options);
            if (options.oneofs)
                object.method = "sendCustomUpdate";
        }
        if (message.getOrCreateChatByExternalID != null && message.hasOwnProperty("getOrCreateChatByExternalID")) {
            object.getOrCreateChatByExternalID = $root.MCMethodGetOrCreateChatByExternalID.toObject(message.getOrCreateChatByExternalID, options);
            if (options.oneofs)
                object.method = "getOrCreateChatByExternalID";
        }
        if (message.getOrCreatePeerByExternalID != null && message.hasOwnProperty("getOrCreatePeerByExternalID")) {
            object.getOrCreatePeerByExternalID = $root.MCMethodGetOrCreatePeerByExternalID.toObject(message.getOrCreatePeerByExternalID, options);
            if (options.oneofs)
                object.method = "getOrCreatePeerByExternalID";
        }
        if (message.findMessageLocalID != null && message.hasOwnProperty("findMessageLocalID")) {
            object.findMessageLocalID = $root.MCMethodFindMessageLocalID.toObject(message.findMessageLocalID, options);
            if (options.oneofs)
                object.method = "findMessageLocalID";
        }
        if (message.findChatByExternalID != null && message.hasOwnProperty("findChatByExternalID")) {
            object.findChatByExternalID = $root.MCMethodFindChatByExternalID.toObject(message.findChatByExternalID, options);
            if (options.oneofs)
                object.method = "findChatByExternalID";
        }
        if (message.getChat != null && message.hasOwnProperty("getChat")) {
            object.getChat = $root.MCMethodGetChat.toObject(message.getChat, options);
            if (options.oneofs)
                object.method = "getChat";
        }
        if (message.getChats != null && message.hasOwnProperty("getChats")) {
            object.getChats = $root.MCMethodGetChats.toObject(message.getChats, options);
            if (options.oneofs)
                object.method = "getChats";
        }
        if (message.joinWidgetChat != null && message.hasOwnProperty("joinWidgetChat")) {
            object.joinWidgetChat = $root.MCMethodJoinWidgetChat.toObject(message.joinWidgetChat, options);
            if (options.oneofs)
                object.method = "joinWidgetChat";
        }
        if (message.leaveWidgetChat != null && message.hasOwnProperty("leaveWidgetChat")) {
            object.leaveWidgetChat = $root.MCMethodLeaveWidgetChat.toObject(message.leaveWidgetChat, options);
            if (options.oneofs)
                object.method = "leaveWidgetChat";
        }
        if (message.suggestNextOperatorMessages != null && message.hasOwnProperty("suggestNextOperatorMessages")) {
            object.suggestNextOperatorMessages = $root.MCMethodSuggestNextOperatorMessages.toObject(message.suggestNextOperatorMessages, options);
            if (options.oneofs)
                object.method = "suggestNextOperatorMessages";
        }
        return object;
    };

    /**
     * Converts this MCMethod to JSON.
     * @function toJSON
     * @memberof MCMethod
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethod.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethod
     * @function getTypeUrl
     * @memberof MCMethod
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethod.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethod";
    };

    return MCMethod;
})();

export const MCMethodResponse = $root.MCMethodResponse = (() => {

    /**
     * Properties of a MCMethodResponse.
     * @exports IMCMethodResponse
     * @interface IMCMethodResponse
     * @property {string|null} [error] MCMethodResponse error
     * @property {Array.<entities.IChannelVersion>|null} [versions] MCMethodResponse versions
     * @property {IMCCreateChannelResponse|null} [createChannel] MCMethodResponse createChannel
     * @property {IMCChannelAuthFlowResponse|null} [authFlow] MCMethodResponse authFlow
     * @property {IMCDropChannelResponse|null} [dropChannel] MCMethodResponse dropChannel
     * @property {IMCGetChannelsResponse|null} [getChannels] MCMethodResponse getChannels
     * @property {IMCEditChannelResponse|null} [editChannel] MCMethodResponse editChannel
     * @property {IMCMethodGetMessagesResponse|null} [getMessages] MCMethodResponse getMessages
     * @property {IMCMethodGetPeersResponse|null} [getPeers] MCMethodResponse getPeers
     * @property {IMCMethodGetChatsPreviewResponse|null} [getChats] MCMethodResponse getChats
     * @property {IMCMethodMarkReadResponse|null} [markRead] MCMethodResponse markRead
     * @property {IMCMethodSendMessageResponse|null} [sendMessage] MCMethodResponse sendMessage
     * @property {IMCMethodSendGroupedMessagesResponse|null} [sendGroupedMessages] MCMethodResponse sendGroupedMessages
     * @property {IMCMethodMarkReadAllResponse|null} [markReadAll] MCMethodResponse markReadAll
     * @property {IMCMethodDeleteChatResponse|null} [deleteChat] MCMethodResponse deleteChat
     * @property {IMCMethodGetChatResponse|null} [getChat] MCMethodResponse getChat
     * @property {IMCMethodGetChatsResponse|null} [getChatsByID] MCMethodResponse getChatsByID
     * @property {IMCMethodSearchMessagesResponse|null} [searchMessages] MCMethodResponse searchMessages
     * @property {IMCMethodSearchChatsResponse|null} [searchChats] MCMethodResponse searchChats
     * @property {IMCMethodQueryPeersResponse|null} [queryPeers] MCMethodResponse queryPeers
     * @property {IMCMethodEditChatResponse|null} [editChat] MCMethodResponse editChat
     * @property {IMCMethodDeleteMessageResponse|null} [deleteMessage] MCMethodResponse deleteMessage
     * @property {IMCMethodRestrictChatAccessResponse|null} [restrictChatAccess] MCMethodResponse restrictChatAccess
     * @property {IMCMethodSendCustomUpdateResponse|null} [sendCustomUpdate] MCMethodResponse sendCustomUpdate
     * @property {IMCMethodGetOrCreateChatByExternalIDResponse|null} [getOrCreateChatByExternalID] MCMethodResponse getOrCreateChatByExternalID
     * @property {IMCMethodGetOrCreatePeerByExternalIDResponse|null} [getOrCreatePeerByExternalID] MCMethodResponse getOrCreatePeerByExternalID
     * @property {IMCMethodFindMessageLocalIDResponse|null} [findMessageLocalID] MCMethodResponse findMessageLocalID
     * @property {IMCMethodFindChatByExternalIDResponse|null} [findChatByExternalID] MCMethodResponse findChatByExternalID
     * @property {IMCMethodJoinWidgetChatResponse|null} [joinWidgetChat] MCMethodResponse joinWidgetChat
     * @property {IMCMethodLeaveWidgetChatResponse|null} [leaveWidgetChat] MCMethodResponse leaveWidgetChat
     * @property {IMCMethodSuggestNextOperatorMessagesResponse|null} [suggestNextOperatorMessages] MCMethodResponse suggestNextOperatorMessages
     */

    /**
     * Constructs a new MCMethodResponse.
     * @exports MCMethodResponse
     * @classdesc Represents a MCMethodResponse.
     * @implements IMCMethodResponse
     * @constructor
     * @param {IMCMethodResponse=} [properties] Properties to set
     */
    function MCMethodResponse(properties) {
        this.versions = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodResponse error.
     * @member {string} error
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.error = "";

    /**
     * MCMethodResponse versions.
     * @member {Array.<entities.IChannelVersion>} versions
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.versions = $util.emptyArray;

    /**
     * MCMethodResponse createChannel.
     * @member {IMCCreateChannelResponse|null|undefined} createChannel
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.createChannel = null;

    /**
     * MCMethodResponse authFlow.
     * @member {IMCChannelAuthFlowResponse|null|undefined} authFlow
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.authFlow = null;

    /**
     * MCMethodResponse dropChannel.
     * @member {IMCDropChannelResponse|null|undefined} dropChannel
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.dropChannel = null;

    /**
     * MCMethodResponse getChannels.
     * @member {IMCGetChannelsResponse|null|undefined} getChannels
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.getChannels = null;

    /**
     * MCMethodResponse editChannel.
     * @member {IMCEditChannelResponse|null|undefined} editChannel
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.editChannel = null;

    /**
     * MCMethodResponse getMessages.
     * @member {IMCMethodGetMessagesResponse|null|undefined} getMessages
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.getMessages = null;

    /**
     * MCMethodResponse getPeers.
     * @member {IMCMethodGetPeersResponse|null|undefined} getPeers
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.getPeers = null;

    /**
     * MCMethodResponse getChats.
     * @member {IMCMethodGetChatsPreviewResponse|null|undefined} getChats
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.getChats = null;

    /**
     * MCMethodResponse markRead.
     * @member {IMCMethodMarkReadResponse|null|undefined} markRead
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.markRead = null;

    /**
     * MCMethodResponse sendMessage.
     * @member {IMCMethodSendMessageResponse|null|undefined} sendMessage
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.sendMessage = null;

    /**
     * MCMethodResponse sendGroupedMessages.
     * @member {IMCMethodSendGroupedMessagesResponse|null|undefined} sendGroupedMessages
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.sendGroupedMessages = null;

    /**
     * MCMethodResponse markReadAll.
     * @member {IMCMethodMarkReadAllResponse|null|undefined} markReadAll
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.markReadAll = null;

    /**
     * MCMethodResponse deleteChat.
     * @member {IMCMethodDeleteChatResponse|null|undefined} deleteChat
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.deleteChat = null;

    /**
     * MCMethodResponse getChat.
     * @member {IMCMethodGetChatResponse|null|undefined} getChat
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.getChat = null;

    /**
     * MCMethodResponse getChatsByID.
     * @member {IMCMethodGetChatsResponse|null|undefined} getChatsByID
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.getChatsByID = null;

    /**
     * MCMethodResponse searchMessages.
     * @member {IMCMethodSearchMessagesResponse|null|undefined} searchMessages
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.searchMessages = null;

    /**
     * MCMethodResponse searchChats.
     * @member {IMCMethodSearchChatsResponse|null|undefined} searchChats
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.searchChats = null;

    /**
     * MCMethodResponse queryPeers.
     * @member {IMCMethodQueryPeersResponse|null|undefined} queryPeers
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.queryPeers = null;

    /**
     * MCMethodResponse editChat.
     * @member {IMCMethodEditChatResponse|null|undefined} editChat
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.editChat = null;

    /**
     * MCMethodResponse deleteMessage.
     * @member {IMCMethodDeleteMessageResponse|null|undefined} deleteMessage
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.deleteMessage = null;

    /**
     * MCMethodResponse restrictChatAccess.
     * @member {IMCMethodRestrictChatAccessResponse|null|undefined} restrictChatAccess
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.restrictChatAccess = null;

    /**
     * MCMethodResponse sendCustomUpdate.
     * @member {IMCMethodSendCustomUpdateResponse|null|undefined} sendCustomUpdate
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.sendCustomUpdate = null;

    /**
     * MCMethodResponse getOrCreateChatByExternalID.
     * @member {IMCMethodGetOrCreateChatByExternalIDResponse|null|undefined} getOrCreateChatByExternalID
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.getOrCreateChatByExternalID = null;

    /**
     * MCMethodResponse getOrCreatePeerByExternalID.
     * @member {IMCMethodGetOrCreatePeerByExternalIDResponse|null|undefined} getOrCreatePeerByExternalID
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.getOrCreatePeerByExternalID = null;

    /**
     * MCMethodResponse findMessageLocalID.
     * @member {IMCMethodFindMessageLocalIDResponse|null|undefined} findMessageLocalID
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.findMessageLocalID = null;

    /**
     * MCMethodResponse findChatByExternalID.
     * @member {IMCMethodFindChatByExternalIDResponse|null|undefined} findChatByExternalID
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.findChatByExternalID = null;

    /**
     * MCMethodResponse joinWidgetChat.
     * @member {IMCMethodJoinWidgetChatResponse|null|undefined} joinWidgetChat
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.joinWidgetChat = null;

    /**
     * MCMethodResponse leaveWidgetChat.
     * @member {IMCMethodLeaveWidgetChatResponse|null|undefined} leaveWidgetChat
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.leaveWidgetChat = null;

    /**
     * MCMethodResponse suggestNextOperatorMessages.
     * @member {IMCMethodSuggestNextOperatorMessagesResponse|null|undefined} suggestNextOperatorMessages
     * @memberof MCMethodResponse
     * @instance
     */
    MCMethodResponse.prototype.suggestNextOperatorMessages = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * MCMethodResponse response.
     * @member {"createChannel"|"authFlow"|"dropChannel"|"getChannels"|"editChannel"|"getMessages"|"getPeers"|"getChats"|"markRead"|"sendMessage"|"sendGroupedMessages"|"markReadAll"|"deleteChat"|"getChat"|"getChatsByID"|"searchMessages"|"searchChats"|"queryPeers"|"editChat"|"deleteMessage"|"restrictChatAccess"|"sendCustomUpdate"|"getOrCreateChatByExternalID"|"getOrCreatePeerByExternalID"|"findMessageLocalID"|"findChatByExternalID"|"joinWidgetChat"|"leaveWidgetChat"|"suggestNextOperatorMessages"|undefined} response
     * @memberof MCMethodResponse
     * @instance
     */
    Object.defineProperty(MCMethodResponse.prototype, "response", {
        get: $util.oneOfGetter($oneOfFields = ["createChannel", "authFlow", "dropChannel", "getChannels", "editChannel", "getMessages", "getPeers", "getChats", "markRead", "sendMessage", "sendGroupedMessages", "markReadAll", "deleteChat", "getChat", "getChatsByID", "searchMessages", "searchChats", "queryPeers", "editChat", "deleteMessage", "restrictChatAccess", "sendCustomUpdate", "getOrCreateChatByExternalID", "getOrCreatePeerByExternalID", "findMessageLocalID", "findChatByExternalID", "joinWidgetChat", "leaveWidgetChat", "suggestNextOperatorMessages"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new MCMethodResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodResponse
     * @static
     * @param {IMCMethodResponse=} [properties] Properties to set
     * @returns {MCMethodResponse} MCMethodResponse instance
     */
    MCMethodResponse.create = function create(properties) {
        return new MCMethodResponse(properties);
    };

    /**
     * Encodes the specified MCMethodResponse message. Does not implicitly {@link MCMethodResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodResponse
     * @static
     * @param {IMCMethodResponse} message MCMethodResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.error != null && Object.hasOwnProperty.call(message, "error"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.error);
        if (message.versions != null && message.versions.length)
            for (let i = 0; i < message.versions.length; ++i)
                $root.entities.ChannelVersion.encode(message.versions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.createChannel != null && Object.hasOwnProperty.call(message, "createChannel"))
            $root.MCCreateChannelResponse.encode(message.createChannel, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.authFlow != null && Object.hasOwnProperty.call(message, "authFlow"))
            $root.MCChannelAuthFlowResponse.encode(message.authFlow, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.dropChannel != null && Object.hasOwnProperty.call(message, "dropChannel"))
            $root.MCDropChannelResponse.encode(message.dropChannel, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.getChannels != null && Object.hasOwnProperty.call(message, "getChannels"))
            $root.MCGetChannelsResponse.encode(message.getChannels, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.editChannel != null && Object.hasOwnProperty.call(message, "editChannel"))
            $root.MCEditChannelResponse.encode(message.editChannel, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.getMessages != null && Object.hasOwnProperty.call(message, "getMessages"))
            $root.MCMethodGetMessagesResponse.encode(message.getMessages, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
        if (message.getPeers != null && Object.hasOwnProperty.call(message, "getPeers"))
            $root.MCMethodGetPeersResponse.encode(message.getPeers, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
        if (message.getChats != null && Object.hasOwnProperty.call(message, "getChats"))
            $root.MCMethodGetChatsPreviewResponse.encode(message.getChats, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
        if (message.markRead != null && Object.hasOwnProperty.call(message, "markRead"))
            $root.MCMethodMarkReadResponse.encode(message.markRead, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
        if (message.sendMessage != null && Object.hasOwnProperty.call(message, "sendMessage"))
            $root.MCMethodSendMessageResponse.encode(message.sendMessage, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
        if (message.sendGroupedMessages != null && Object.hasOwnProperty.call(message, "sendGroupedMessages"))
            $root.MCMethodSendGroupedMessagesResponse.encode(message.sendGroupedMessages, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
        if (message.markReadAll != null && Object.hasOwnProperty.call(message, "markReadAll"))
            $root.MCMethodMarkReadAllResponse.encode(message.markReadAll, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
        if (message.deleteChat != null && Object.hasOwnProperty.call(message, "deleteChat"))
            $root.MCMethodDeleteChatResponse.encode(message.deleteChat, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
        if (message.searchMessages != null && Object.hasOwnProperty.call(message, "searchMessages"))
            $root.MCMethodSearchMessagesResponse.encode(message.searchMessages, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
        if (message.searchChats != null && Object.hasOwnProperty.call(message, "searchChats"))
            $root.MCMethodSearchChatsResponse.encode(message.searchChats, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
        if (message.queryPeers != null && Object.hasOwnProperty.call(message, "queryPeers"))
            $root.MCMethodQueryPeersResponse.encode(message.queryPeers, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
        if (message.editChat != null && Object.hasOwnProperty.call(message, "editChat"))
            $root.MCMethodEditChatResponse.encode(message.editChat, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
        if (message.deleteMessage != null && Object.hasOwnProperty.call(message, "deleteMessage"))
            $root.MCMethodDeleteMessageResponse.encode(message.deleteMessage, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
        if (message.restrictChatAccess != null && Object.hasOwnProperty.call(message, "restrictChatAccess"))
            $root.MCMethodRestrictChatAccessResponse.encode(message.restrictChatAccess, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
        if (message.sendCustomUpdate != null && Object.hasOwnProperty.call(message, "sendCustomUpdate"))
            $root.MCMethodSendCustomUpdateResponse.encode(message.sendCustomUpdate, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
        if (message.getOrCreateChatByExternalID != null && Object.hasOwnProperty.call(message, "getOrCreateChatByExternalID"))
            $root.MCMethodGetOrCreateChatByExternalIDResponse.encode(message.getOrCreateChatByExternalID, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
        if (message.getOrCreatePeerByExternalID != null && Object.hasOwnProperty.call(message, "getOrCreatePeerByExternalID"))
            $root.MCMethodGetOrCreatePeerByExternalIDResponse.encode(message.getOrCreatePeerByExternalID, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
        if (message.findMessageLocalID != null && Object.hasOwnProperty.call(message, "findMessageLocalID"))
            $root.MCMethodFindMessageLocalIDResponse.encode(message.findMessageLocalID, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
        if (message.findChatByExternalID != null && Object.hasOwnProperty.call(message, "findChatByExternalID"))
            $root.MCMethodFindChatByExternalIDResponse.encode(message.findChatByExternalID, writer.uint32(/* id 53, wireType 2 =*/426).fork()).ldelim();
        if (message.getChat != null && Object.hasOwnProperty.call(message, "getChat"))
            $root.MCMethodGetChatResponse.encode(message.getChat, writer.uint32(/* id 60, wireType 2 =*/482).fork()).ldelim();
        if (message.getChatsByID != null && Object.hasOwnProperty.call(message, "getChatsByID"))
            $root.MCMethodGetChatsResponse.encode(message.getChatsByID, writer.uint32(/* id 61, wireType 2 =*/490).fork()).ldelim();
        if (message.joinWidgetChat != null && Object.hasOwnProperty.call(message, "joinWidgetChat"))
            $root.MCMethodJoinWidgetChatResponse.encode(message.joinWidgetChat, writer.uint32(/* id 70, wireType 2 =*/562).fork()).ldelim();
        if (message.leaveWidgetChat != null && Object.hasOwnProperty.call(message, "leaveWidgetChat"))
            $root.MCMethodLeaveWidgetChatResponse.encode(message.leaveWidgetChat, writer.uint32(/* id 71, wireType 2 =*/570).fork()).ldelim();
        if (message.suggestNextOperatorMessages != null && Object.hasOwnProperty.call(message, "suggestNextOperatorMessages"))
            $root.MCMethodSuggestNextOperatorMessagesResponse.encode(message.suggestNextOperatorMessages, writer.uint32(/* id 80, wireType 2 =*/642).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodResponse message, length delimited. Does not implicitly {@link MCMethodResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodResponse
     * @static
     * @param {IMCMethodResponse} message MCMethodResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodResponse} MCMethodResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.error = reader.string();
                    break;
                }
            case 2: {
                    if (!(message.versions && message.versions.length))
                        message.versions = [];
                    message.versions.push($root.entities.ChannelVersion.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.createChannel = $root.MCCreateChannelResponse.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.authFlow = $root.MCChannelAuthFlowResponse.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.dropChannel = $root.MCDropChannelResponse.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.getChannels = $root.MCGetChannelsResponse.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.editChannel = $root.MCEditChannelResponse.decode(reader, reader.uint32());
                    break;
                }
            case 20: {
                    message.getMessages = $root.MCMethodGetMessagesResponse.decode(reader, reader.uint32());
                    break;
                }
            case 21: {
                    message.getPeers = $root.MCMethodGetPeersResponse.decode(reader, reader.uint32());
                    break;
                }
            case 22: {
                    message.getChats = $root.MCMethodGetChatsPreviewResponse.decode(reader, reader.uint32());
                    break;
                }
            case 24: {
                    message.markRead = $root.MCMethodMarkReadResponse.decode(reader, reader.uint32());
                    break;
                }
            case 25: {
                    message.sendMessage = $root.MCMethodSendMessageResponse.decode(reader, reader.uint32());
                    break;
                }
            case 27: {
                    message.sendGroupedMessages = $root.MCMethodSendGroupedMessagesResponse.decode(reader, reader.uint32());
                    break;
                }
            case 28: {
                    message.markReadAll = $root.MCMethodMarkReadAllResponse.decode(reader, reader.uint32());
                    break;
                }
            case 29: {
                    message.deleteChat = $root.MCMethodDeleteChatResponse.decode(reader, reader.uint32());
                    break;
                }
            case 60: {
                    message.getChat = $root.MCMethodGetChatResponse.decode(reader, reader.uint32());
                    break;
                }
            case 61: {
                    message.getChatsByID = $root.MCMethodGetChatsResponse.decode(reader, reader.uint32());
                    break;
                }
            case 30: {
                    message.searchMessages = $root.MCMethodSearchMessagesResponse.decode(reader, reader.uint32());
                    break;
                }
            case 31: {
                    message.searchChats = $root.MCMethodSearchChatsResponse.decode(reader, reader.uint32());
                    break;
                }
            case 32: {
                    message.queryPeers = $root.MCMethodQueryPeersResponse.decode(reader, reader.uint32());
                    break;
                }
            case 33: {
                    message.editChat = $root.MCMethodEditChatResponse.decode(reader, reader.uint32());
                    break;
                }
            case 35: {
                    message.deleteMessage = $root.MCMethodDeleteMessageResponse.decode(reader, reader.uint32());
                    break;
                }
            case 36: {
                    message.restrictChatAccess = $root.MCMethodRestrictChatAccessResponse.decode(reader, reader.uint32());
                    break;
                }
            case 40: {
                    message.sendCustomUpdate = $root.MCMethodSendCustomUpdateResponse.decode(reader, reader.uint32());
                    break;
                }
            case 50: {
                    message.getOrCreateChatByExternalID = $root.MCMethodGetOrCreateChatByExternalIDResponse.decode(reader, reader.uint32());
                    break;
                }
            case 51: {
                    message.getOrCreatePeerByExternalID = $root.MCMethodGetOrCreatePeerByExternalIDResponse.decode(reader, reader.uint32());
                    break;
                }
            case 52: {
                    message.findMessageLocalID = $root.MCMethodFindMessageLocalIDResponse.decode(reader, reader.uint32());
                    break;
                }
            case 53: {
                    message.findChatByExternalID = $root.MCMethodFindChatByExternalIDResponse.decode(reader, reader.uint32());
                    break;
                }
            case 70: {
                    message.joinWidgetChat = $root.MCMethodJoinWidgetChatResponse.decode(reader, reader.uint32());
                    break;
                }
            case 71: {
                    message.leaveWidgetChat = $root.MCMethodLeaveWidgetChatResponse.decode(reader, reader.uint32());
                    break;
                }
            case 80: {
                    message.suggestNextOperatorMessages = $root.MCMethodSuggestNextOperatorMessagesResponse.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodResponse} MCMethodResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodResponse message.
     * @function verify
     * @memberof MCMethodResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.error != null && message.hasOwnProperty("error"))
            if (!$util.isString(message.error))
                return "error: string expected";
        if (message.versions != null && message.hasOwnProperty("versions")) {
            if (!Array.isArray(message.versions))
                return "versions: array expected";
            for (let i = 0; i < message.versions.length; ++i) {
                let error = $root.entities.ChannelVersion.verify(message.versions[i]);
                if (error)
                    return "versions." + error;
            }
        }
        if (message.createChannel != null && message.hasOwnProperty("createChannel")) {
            properties.response = 1;
            {
                let error = $root.MCCreateChannelResponse.verify(message.createChannel);
                if (error)
                    return "createChannel." + error;
            }
        }
        if (message.authFlow != null && message.hasOwnProperty("authFlow")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCChannelAuthFlowResponse.verify(message.authFlow);
                if (error)
                    return "authFlow." + error;
            }
        }
        if (message.dropChannel != null && message.hasOwnProperty("dropChannel")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCDropChannelResponse.verify(message.dropChannel);
                if (error)
                    return "dropChannel." + error;
            }
        }
        if (message.getChannels != null && message.hasOwnProperty("getChannels")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCGetChannelsResponse.verify(message.getChannels);
                if (error)
                    return "getChannels." + error;
            }
        }
        if (message.editChannel != null && message.hasOwnProperty("editChannel")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCEditChannelResponse.verify(message.editChannel);
                if (error)
                    return "editChannel." + error;
            }
        }
        if (message.getMessages != null && message.hasOwnProperty("getMessages")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodGetMessagesResponse.verify(message.getMessages);
                if (error)
                    return "getMessages." + error;
            }
        }
        if (message.getPeers != null && message.hasOwnProperty("getPeers")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodGetPeersResponse.verify(message.getPeers);
                if (error)
                    return "getPeers." + error;
            }
        }
        if (message.getChats != null && message.hasOwnProperty("getChats")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodGetChatsPreviewResponse.verify(message.getChats);
                if (error)
                    return "getChats." + error;
            }
        }
        if (message.markRead != null && message.hasOwnProperty("markRead")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodMarkReadResponse.verify(message.markRead);
                if (error)
                    return "markRead." + error;
            }
        }
        if (message.sendMessage != null && message.hasOwnProperty("sendMessage")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodSendMessageResponse.verify(message.sendMessage);
                if (error)
                    return "sendMessage." + error;
            }
        }
        if (message.sendGroupedMessages != null && message.hasOwnProperty("sendGroupedMessages")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodSendGroupedMessagesResponse.verify(message.sendGroupedMessages);
                if (error)
                    return "sendGroupedMessages." + error;
            }
        }
        if (message.markReadAll != null && message.hasOwnProperty("markReadAll")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodMarkReadAllResponse.verify(message.markReadAll);
                if (error)
                    return "markReadAll." + error;
            }
        }
        if (message.deleteChat != null && message.hasOwnProperty("deleteChat")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodDeleteChatResponse.verify(message.deleteChat);
                if (error)
                    return "deleteChat." + error;
            }
        }
        if (message.getChat != null && message.hasOwnProperty("getChat")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodGetChatResponse.verify(message.getChat);
                if (error)
                    return "getChat." + error;
            }
        }
        if (message.getChatsByID != null && message.hasOwnProperty("getChatsByID")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodGetChatsResponse.verify(message.getChatsByID);
                if (error)
                    return "getChatsByID." + error;
            }
        }
        if (message.searchMessages != null && message.hasOwnProperty("searchMessages")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodSearchMessagesResponse.verify(message.searchMessages);
                if (error)
                    return "searchMessages." + error;
            }
        }
        if (message.searchChats != null && message.hasOwnProperty("searchChats")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodSearchChatsResponse.verify(message.searchChats);
                if (error)
                    return "searchChats." + error;
            }
        }
        if (message.queryPeers != null && message.hasOwnProperty("queryPeers")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodQueryPeersResponse.verify(message.queryPeers);
                if (error)
                    return "queryPeers." + error;
            }
        }
        if (message.editChat != null && message.hasOwnProperty("editChat")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodEditChatResponse.verify(message.editChat);
                if (error)
                    return "editChat." + error;
            }
        }
        if (message.deleteMessage != null && message.hasOwnProperty("deleteMessage")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodDeleteMessageResponse.verify(message.deleteMessage);
                if (error)
                    return "deleteMessage." + error;
            }
        }
        if (message.restrictChatAccess != null && message.hasOwnProperty("restrictChatAccess")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodRestrictChatAccessResponse.verify(message.restrictChatAccess);
                if (error)
                    return "restrictChatAccess." + error;
            }
        }
        if (message.sendCustomUpdate != null && message.hasOwnProperty("sendCustomUpdate")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodSendCustomUpdateResponse.verify(message.sendCustomUpdate);
                if (error)
                    return "sendCustomUpdate." + error;
            }
        }
        if (message.getOrCreateChatByExternalID != null && message.hasOwnProperty("getOrCreateChatByExternalID")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodGetOrCreateChatByExternalIDResponse.verify(message.getOrCreateChatByExternalID);
                if (error)
                    return "getOrCreateChatByExternalID." + error;
            }
        }
        if (message.getOrCreatePeerByExternalID != null && message.hasOwnProperty("getOrCreatePeerByExternalID")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodGetOrCreatePeerByExternalIDResponse.verify(message.getOrCreatePeerByExternalID);
                if (error)
                    return "getOrCreatePeerByExternalID." + error;
            }
        }
        if (message.findMessageLocalID != null && message.hasOwnProperty("findMessageLocalID")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodFindMessageLocalIDResponse.verify(message.findMessageLocalID);
                if (error)
                    return "findMessageLocalID." + error;
            }
        }
        if (message.findChatByExternalID != null && message.hasOwnProperty("findChatByExternalID")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodFindChatByExternalIDResponse.verify(message.findChatByExternalID);
                if (error)
                    return "findChatByExternalID." + error;
            }
        }
        if (message.joinWidgetChat != null && message.hasOwnProperty("joinWidgetChat")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodJoinWidgetChatResponse.verify(message.joinWidgetChat);
                if (error)
                    return "joinWidgetChat." + error;
            }
        }
        if (message.leaveWidgetChat != null && message.hasOwnProperty("leaveWidgetChat")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodLeaveWidgetChatResponse.verify(message.leaveWidgetChat);
                if (error)
                    return "leaveWidgetChat." + error;
            }
        }
        if (message.suggestNextOperatorMessages != null && message.hasOwnProperty("suggestNextOperatorMessages")) {
            if (properties.response === 1)
                return "response: multiple values";
            properties.response = 1;
            {
                let error = $root.MCMethodSuggestNextOperatorMessagesResponse.verify(message.suggestNextOperatorMessages);
                if (error)
                    return "suggestNextOperatorMessages." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCMethodResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodResponse} MCMethodResponse
     */
    MCMethodResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodResponse)
            return object;
        let message = new $root.MCMethodResponse();
        if (object.error != null)
            message.error = String(object.error);
        if (object.versions) {
            if (!Array.isArray(object.versions))
                throw TypeError(".MCMethodResponse.versions: array expected");
            message.versions = [];
            for (let i = 0; i < object.versions.length; ++i) {
                if (typeof object.versions[i] !== "object")
                    throw TypeError(".MCMethodResponse.versions: object expected");
                message.versions[i] = $root.entities.ChannelVersion.fromObject(object.versions[i]);
            }
        }
        if (object.createChannel != null) {
            if (typeof object.createChannel !== "object")
                throw TypeError(".MCMethodResponse.createChannel: object expected");
            message.createChannel = $root.MCCreateChannelResponse.fromObject(object.createChannel);
        }
        if (object.authFlow != null) {
            if (typeof object.authFlow !== "object")
                throw TypeError(".MCMethodResponse.authFlow: object expected");
            message.authFlow = $root.MCChannelAuthFlowResponse.fromObject(object.authFlow);
        }
        if (object.dropChannel != null) {
            if (typeof object.dropChannel !== "object")
                throw TypeError(".MCMethodResponse.dropChannel: object expected");
            message.dropChannel = $root.MCDropChannelResponse.fromObject(object.dropChannel);
        }
        if (object.getChannels != null) {
            if (typeof object.getChannels !== "object")
                throw TypeError(".MCMethodResponse.getChannels: object expected");
            message.getChannels = $root.MCGetChannelsResponse.fromObject(object.getChannels);
        }
        if (object.editChannel != null) {
            if (typeof object.editChannel !== "object")
                throw TypeError(".MCMethodResponse.editChannel: object expected");
            message.editChannel = $root.MCEditChannelResponse.fromObject(object.editChannel);
        }
        if (object.getMessages != null) {
            if (typeof object.getMessages !== "object")
                throw TypeError(".MCMethodResponse.getMessages: object expected");
            message.getMessages = $root.MCMethodGetMessagesResponse.fromObject(object.getMessages);
        }
        if (object.getPeers != null) {
            if (typeof object.getPeers !== "object")
                throw TypeError(".MCMethodResponse.getPeers: object expected");
            message.getPeers = $root.MCMethodGetPeersResponse.fromObject(object.getPeers);
        }
        if (object.getChats != null) {
            if (typeof object.getChats !== "object")
                throw TypeError(".MCMethodResponse.getChats: object expected");
            message.getChats = $root.MCMethodGetChatsPreviewResponse.fromObject(object.getChats);
        }
        if (object.markRead != null) {
            if (typeof object.markRead !== "object")
                throw TypeError(".MCMethodResponse.markRead: object expected");
            message.markRead = $root.MCMethodMarkReadResponse.fromObject(object.markRead);
        }
        if (object.sendMessage != null) {
            if (typeof object.sendMessage !== "object")
                throw TypeError(".MCMethodResponse.sendMessage: object expected");
            message.sendMessage = $root.MCMethodSendMessageResponse.fromObject(object.sendMessage);
        }
        if (object.sendGroupedMessages != null) {
            if (typeof object.sendGroupedMessages !== "object")
                throw TypeError(".MCMethodResponse.sendGroupedMessages: object expected");
            message.sendGroupedMessages = $root.MCMethodSendGroupedMessagesResponse.fromObject(object.sendGroupedMessages);
        }
        if (object.markReadAll != null) {
            if (typeof object.markReadAll !== "object")
                throw TypeError(".MCMethodResponse.markReadAll: object expected");
            message.markReadAll = $root.MCMethodMarkReadAllResponse.fromObject(object.markReadAll);
        }
        if (object.deleteChat != null) {
            if (typeof object.deleteChat !== "object")
                throw TypeError(".MCMethodResponse.deleteChat: object expected");
            message.deleteChat = $root.MCMethodDeleteChatResponse.fromObject(object.deleteChat);
        }
        if (object.getChat != null) {
            if (typeof object.getChat !== "object")
                throw TypeError(".MCMethodResponse.getChat: object expected");
            message.getChat = $root.MCMethodGetChatResponse.fromObject(object.getChat);
        }
        if (object.getChatsByID != null) {
            if (typeof object.getChatsByID !== "object")
                throw TypeError(".MCMethodResponse.getChatsByID: object expected");
            message.getChatsByID = $root.MCMethodGetChatsResponse.fromObject(object.getChatsByID);
        }
        if (object.searchMessages != null) {
            if (typeof object.searchMessages !== "object")
                throw TypeError(".MCMethodResponse.searchMessages: object expected");
            message.searchMessages = $root.MCMethodSearchMessagesResponse.fromObject(object.searchMessages);
        }
        if (object.searchChats != null) {
            if (typeof object.searchChats !== "object")
                throw TypeError(".MCMethodResponse.searchChats: object expected");
            message.searchChats = $root.MCMethodSearchChatsResponse.fromObject(object.searchChats);
        }
        if (object.queryPeers != null) {
            if (typeof object.queryPeers !== "object")
                throw TypeError(".MCMethodResponse.queryPeers: object expected");
            message.queryPeers = $root.MCMethodQueryPeersResponse.fromObject(object.queryPeers);
        }
        if (object.editChat != null) {
            if (typeof object.editChat !== "object")
                throw TypeError(".MCMethodResponse.editChat: object expected");
            message.editChat = $root.MCMethodEditChatResponse.fromObject(object.editChat);
        }
        if (object.deleteMessage != null) {
            if (typeof object.deleteMessage !== "object")
                throw TypeError(".MCMethodResponse.deleteMessage: object expected");
            message.deleteMessage = $root.MCMethodDeleteMessageResponse.fromObject(object.deleteMessage);
        }
        if (object.restrictChatAccess != null) {
            if (typeof object.restrictChatAccess !== "object")
                throw TypeError(".MCMethodResponse.restrictChatAccess: object expected");
            message.restrictChatAccess = $root.MCMethodRestrictChatAccessResponse.fromObject(object.restrictChatAccess);
        }
        if (object.sendCustomUpdate != null) {
            if (typeof object.sendCustomUpdate !== "object")
                throw TypeError(".MCMethodResponse.sendCustomUpdate: object expected");
            message.sendCustomUpdate = $root.MCMethodSendCustomUpdateResponse.fromObject(object.sendCustomUpdate);
        }
        if (object.getOrCreateChatByExternalID != null) {
            if (typeof object.getOrCreateChatByExternalID !== "object")
                throw TypeError(".MCMethodResponse.getOrCreateChatByExternalID: object expected");
            message.getOrCreateChatByExternalID = $root.MCMethodGetOrCreateChatByExternalIDResponse.fromObject(object.getOrCreateChatByExternalID);
        }
        if (object.getOrCreatePeerByExternalID != null) {
            if (typeof object.getOrCreatePeerByExternalID !== "object")
                throw TypeError(".MCMethodResponse.getOrCreatePeerByExternalID: object expected");
            message.getOrCreatePeerByExternalID = $root.MCMethodGetOrCreatePeerByExternalIDResponse.fromObject(object.getOrCreatePeerByExternalID);
        }
        if (object.findMessageLocalID != null) {
            if (typeof object.findMessageLocalID !== "object")
                throw TypeError(".MCMethodResponse.findMessageLocalID: object expected");
            message.findMessageLocalID = $root.MCMethodFindMessageLocalIDResponse.fromObject(object.findMessageLocalID);
        }
        if (object.findChatByExternalID != null) {
            if (typeof object.findChatByExternalID !== "object")
                throw TypeError(".MCMethodResponse.findChatByExternalID: object expected");
            message.findChatByExternalID = $root.MCMethodFindChatByExternalIDResponse.fromObject(object.findChatByExternalID);
        }
        if (object.joinWidgetChat != null) {
            if (typeof object.joinWidgetChat !== "object")
                throw TypeError(".MCMethodResponse.joinWidgetChat: object expected");
            message.joinWidgetChat = $root.MCMethodJoinWidgetChatResponse.fromObject(object.joinWidgetChat);
        }
        if (object.leaveWidgetChat != null) {
            if (typeof object.leaveWidgetChat !== "object")
                throw TypeError(".MCMethodResponse.leaveWidgetChat: object expected");
            message.leaveWidgetChat = $root.MCMethodLeaveWidgetChatResponse.fromObject(object.leaveWidgetChat);
        }
        if (object.suggestNextOperatorMessages != null) {
            if (typeof object.suggestNextOperatorMessages !== "object")
                throw TypeError(".MCMethodResponse.suggestNextOperatorMessages: object expected");
            message.suggestNextOperatorMessages = $root.MCMethodSuggestNextOperatorMessagesResponse.fromObject(object.suggestNextOperatorMessages);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodResponse
     * @static
     * @param {MCMethodResponse} message MCMethodResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.versions = [];
        if (options.defaults)
            object.error = "";
        if (message.error != null && message.hasOwnProperty("error"))
            object.error = message.error;
        if (message.versions && message.versions.length) {
            object.versions = [];
            for (let j = 0; j < message.versions.length; ++j)
                object.versions[j] = $root.entities.ChannelVersion.toObject(message.versions[j], options);
        }
        if (message.createChannel != null && message.hasOwnProperty("createChannel")) {
            object.createChannel = $root.MCCreateChannelResponse.toObject(message.createChannel, options);
            if (options.oneofs)
                object.response = "createChannel";
        }
        if (message.authFlow != null && message.hasOwnProperty("authFlow")) {
            object.authFlow = $root.MCChannelAuthFlowResponse.toObject(message.authFlow, options);
            if (options.oneofs)
                object.response = "authFlow";
        }
        if (message.dropChannel != null && message.hasOwnProperty("dropChannel")) {
            object.dropChannel = $root.MCDropChannelResponse.toObject(message.dropChannel, options);
            if (options.oneofs)
                object.response = "dropChannel";
        }
        if (message.getChannels != null && message.hasOwnProperty("getChannels")) {
            object.getChannels = $root.MCGetChannelsResponse.toObject(message.getChannels, options);
            if (options.oneofs)
                object.response = "getChannels";
        }
        if (message.editChannel != null && message.hasOwnProperty("editChannel")) {
            object.editChannel = $root.MCEditChannelResponse.toObject(message.editChannel, options);
            if (options.oneofs)
                object.response = "editChannel";
        }
        if (message.getMessages != null && message.hasOwnProperty("getMessages")) {
            object.getMessages = $root.MCMethodGetMessagesResponse.toObject(message.getMessages, options);
            if (options.oneofs)
                object.response = "getMessages";
        }
        if (message.getPeers != null && message.hasOwnProperty("getPeers")) {
            object.getPeers = $root.MCMethodGetPeersResponse.toObject(message.getPeers, options);
            if (options.oneofs)
                object.response = "getPeers";
        }
        if (message.getChats != null && message.hasOwnProperty("getChats")) {
            object.getChats = $root.MCMethodGetChatsPreviewResponse.toObject(message.getChats, options);
            if (options.oneofs)
                object.response = "getChats";
        }
        if (message.markRead != null && message.hasOwnProperty("markRead")) {
            object.markRead = $root.MCMethodMarkReadResponse.toObject(message.markRead, options);
            if (options.oneofs)
                object.response = "markRead";
        }
        if (message.sendMessage != null && message.hasOwnProperty("sendMessage")) {
            object.sendMessage = $root.MCMethodSendMessageResponse.toObject(message.sendMessage, options);
            if (options.oneofs)
                object.response = "sendMessage";
        }
        if (message.sendGroupedMessages != null && message.hasOwnProperty("sendGroupedMessages")) {
            object.sendGroupedMessages = $root.MCMethodSendGroupedMessagesResponse.toObject(message.sendGroupedMessages, options);
            if (options.oneofs)
                object.response = "sendGroupedMessages";
        }
        if (message.markReadAll != null && message.hasOwnProperty("markReadAll")) {
            object.markReadAll = $root.MCMethodMarkReadAllResponse.toObject(message.markReadAll, options);
            if (options.oneofs)
                object.response = "markReadAll";
        }
        if (message.deleteChat != null && message.hasOwnProperty("deleteChat")) {
            object.deleteChat = $root.MCMethodDeleteChatResponse.toObject(message.deleteChat, options);
            if (options.oneofs)
                object.response = "deleteChat";
        }
        if (message.searchMessages != null && message.hasOwnProperty("searchMessages")) {
            object.searchMessages = $root.MCMethodSearchMessagesResponse.toObject(message.searchMessages, options);
            if (options.oneofs)
                object.response = "searchMessages";
        }
        if (message.searchChats != null && message.hasOwnProperty("searchChats")) {
            object.searchChats = $root.MCMethodSearchChatsResponse.toObject(message.searchChats, options);
            if (options.oneofs)
                object.response = "searchChats";
        }
        if (message.queryPeers != null && message.hasOwnProperty("queryPeers")) {
            object.queryPeers = $root.MCMethodQueryPeersResponse.toObject(message.queryPeers, options);
            if (options.oneofs)
                object.response = "queryPeers";
        }
        if (message.editChat != null && message.hasOwnProperty("editChat")) {
            object.editChat = $root.MCMethodEditChatResponse.toObject(message.editChat, options);
            if (options.oneofs)
                object.response = "editChat";
        }
        if (message.deleteMessage != null && message.hasOwnProperty("deleteMessage")) {
            object.deleteMessage = $root.MCMethodDeleteMessageResponse.toObject(message.deleteMessage, options);
            if (options.oneofs)
                object.response = "deleteMessage";
        }
        if (message.restrictChatAccess != null && message.hasOwnProperty("restrictChatAccess")) {
            object.restrictChatAccess = $root.MCMethodRestrictChatAccessResponse.toObject(message.restrictChatAccess, options);
            if (options.oneofs)
                object.response = "restrictChatAccess";
        }
        if (message.sendCustomUpdate != null && message.hasOwnProperty("sendCustomUpdate")) {
            object.sendCustomUpdate = $root.MCMethodSendCustomUpdateResponse.toObject(message.sendCustomUpdate, options);
            if (options.oneofs)
                object.response = "sendCustomUpdate";
        }
        if (message.getOrCreateChatByExternalID != null && message.hasOwnProperty("getOrCreateChatByExternalID")) {
            object.getOrCreateChatByExternalID = $root.MCMethodGetOrCreateChatByExternalIDResponse.toObject(message.getOrCreateChatByExternalID, options);
            if (options.oneofs)
                object.response = "getOrCreateChatByExternalID";
        }
        if (message.getOrCreatePeerByExternalID != null && message.hasOwnProperty("getOrCreatePeerByExternalID")) {
            object.getOrCreatePeerByExternalID = $root.MCMethodGetOrCreatePeerByExternalIDResponse.toObject(message.getOrCreatePeerByExternalID, options);
            if (options.oneofs)
                object.response = "getOrCreatePeerByExternalID";
        }
        if (message.findMessageLocalID != null && message.hasOwnProperty("findMessageLocalID")) {
            object.findMessageLocalID = $root.MCMethodFindMessageLocalIDResponse.toObject(message.findMessageLocalID, options);
            if (options.oneofs)
                object.response = "findMessageLocalID";
        }
        if (message.findChatByExternalID != null && message.hasOwnProperty("findChatByExternalID")) {
            object.findChatByExternalID = $root.MCMethodFindChatByExternalIDResponse.toObject(message.findChatByExternalID, options);
            if (options.oneofs)
                object.response = "findChatByExternalID";
        }
        if (message.getChat != null && message.hasOwnProperty("getChat")) {
            object.getChat = $root.MCMethodGetChatResponse.toObject(message.getChat, options);
            if (options.oneofs)
                object.response = "getChat";
        }
        if (message.getChatsByID != null && message.hasOwnProperty("getChatsByID")) {
            object.getChatsByID = $root.MCMethodGetChatsResponse.toObject(message.getChatsByID, options);
            if (options.oneofs)
                object.response = "getChatsByID";
        }
        if (message.joinWidgetChat != null && message.hasOwnProperty("joinWidgetChat")) {
            object.joinWidgetChat = $root.MCMethodJoinWidgetChatResponse.toObject(message.joinWidgetChat, options);
            if (options.oneofs)
                object.response = "joinWidgetChat";
        }
        if (message.leaveWidgetChat != null && message.hasOwnProperty("leaveWidgetChat")) {
            object.leaveWidgetChat = $root.MCMethodLeaveWidgetChatResponse.toObject(message.leaveWidgetChat, options);
            if (options.oneofs)
                object.response = "leaveWidgetChat";
        }
        if (message.suggestNextOperatorMessages != null && message.hasOwnProperty("suggestNextOperatorMessages")) {
            object.suggestNextOperatorMessages = $root.MCMethodSuggestNextOperatorMessagesResponse.toObject(message.suggestNextOperatorMessages, options);
            if (options.oneofs)
                object.response = "suggestNextOperatorMessages";
        }
        return object;
    };

    /**
     * Converts this MCMethodResponse to JSON.
     * @function toJSON
     * @memberof MCMethodResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodResponse
     * @function getTypeUrl
     * @memberof MCMethodResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodResponse";
    };

    return MCMethodResponse;
})();

export const MCMethodGetMessages = $root.MCMethodGetMessages = (() => {

    /**
     * Properties of a MCMethodGetMessages.
     * @exports IMCMethodGetMessages
     * @interface IMCMethodGetMessages
     * @property {Uint8Array|null} [channelID] MCMethodGetMessages channelID
     * @property {Long|null} [chatID] MCMethodGetMessages chatID
     * @property {Long|null} [fromMessageID] MCMethodGetMessages fromMessageID
     * @property {Long|null} [fromDay] MCMethodGetMessages fromDay
     * @property {Long|null} [limit] MCMethodGetMessages limit
     * @property {MCAttachment.Type|null} [attachmentType] MCMethodGetMessages attachmentType
     */

    /**
     * Constructs a new MCMethodGetMessages.
     * @exports MCMethodGetMessages
     * @classdesc Represents a MCMethodGetMessages.
     * @implements IMCMethodGetMessages
     * @constructor
     * @param {IMCMethodGetMessages=} [properties] Properties to set
     */
    function MCMethodGetMessages(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetMessages channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodGetMessages
     * @instance
     */
    MCMethodGetMessages.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodGetMessages chatID.
     * @member {Long} chatID
     * @memberof MCMethodGetMessages
     * @instance
     */
    MCMethodGetMessages.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodGetMessages fromMessageID.
     * @member {Long} fromMessageID
     * @memberof MCMethodGetMessages
     * @instance
     */
    MCMethodGetMessages.prototype.fromMessageID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodGetMessages fromDay.
     * @member {Long} fromDay
     * @memberof MCMethodGetMessages
     * @instance
     */
    MCMethodGetMessages.prototype.fromDay = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodGetMessages limit.
     * @member {Long} limit
     * @memberof MCMethodGetMessages
     * @instance
     */
    MCMethodGetMessages.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodGetMessages attachmentType.
     * @member {MCAttachment.Type} attachmentType
     * @memberof MCMethodGetMessages
     * @instance
     */
    MCMethodGetMessages.prototype.attachmentType = 0;

    /**
     * Creates a new MCMethodGetMessages instance using the specified properties.
     * @function create
     * @memberof MCMethodGetMessages
     * @static
     * @param {IMCMethodGetMessages=} [properties] Properties to set
     * @returns {MCMethodGetMessages} MCMethodGetMessages instance
     */
    MCMethodGetMessages.create = function create(properties) {
        return new MCMethodGetMessages(properties);
    };

    /**
     * Encodes the specified MCMethodGetMessages message. Does not implicitly {@link MCMethodGetMessages.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetMessages
     * @static
     * @param {IMCMethodGetMessages} message MCMethodGetMessages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetMessages.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chatID);
        if (message.fromMessageID != null && Object.hasOwnProperty.call(message, "fromMessageID"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.fromMessageID);
        if (message.fromDay != null && Object.hasOwnProperty.call(message, "fromDay"))
            writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.fromDay);
        if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
            writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.limit);
        if (message.attachmentType != null && Object.hasOwnProperty.call(message, "attachmentType"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.attachmentType);
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetMessages message, length delimited. Does not implicitly {@link MCMethodGetMessages.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetMessages
     * @static
     * @param {IMCMethodGetMessages} message MCMethodGetMessages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetMessages.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetMessages message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetMessages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetMessages} MCMethodGetMessages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetMessages.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetMessages();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 3: {
                    message.fromMessageID = reader.uint64();
                    break;
                }
            case 4: {
                    message.fromDay = reader.uint64();
                    break;
                }
            case 5: {
                    message.limit = reader.uint64();
                    break;
                }
            case 6: {
                    message.attachmentType = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetMessages message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetMessages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetMessages} MCMethodGetMessages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetMessages.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetMessages message.
     * @function verify
     * @memberof MCMethodGetMessages
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetMessages.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.fromMessageID != null && message.hasOwnProperty("fromMessageID"))
            if (!$util.isInteger(message.fromMessageID) && !(message.fromMessageID && $util.isInteger(message.fromMessageID.low) && $util.isInteger(message.fromMessageID.high)))
                return "fromMessageID: integer|Long expected";
        if (message.fromDay != null && message.hasOwnProperty("fromDay"))
            if (!$util.isInteger(message.fromDay) && !(message.fromDay && $util.isInteger(message.fromDay.low) && $util.isInteger(message.fromDay.high)))
                return "fromDay: integer|Long expected";
        if (message.limit != null && message.hasOwnProperty("limit"))
            if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                return "limit: integer|Long expected";
        if (message.attachmentType != null && message.hasOwnProperty("attachmentType"))
            switch (message.attachmentType) {
            default:
                return "attachmentType: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                break;
            }
        return null;
    };

    /**
     * Creates a MCMethodGetMessages message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetMessages
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetMessages} MCMethodGetMessages
     */
    MCMethodGetMessages.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetMessages)
            return object;
        let message = new $root.MCMethodGetMessages();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.fromMessageID != null)
            if ($util.Long)
                (message.fromMessageID = $util.Long.fromValue(object.fromMessageID)).unsigned = true;
            else if (typeof object.fromMessageID === "string")
                message.fromMessageID = parseInt(object.fromMessageID, 10);
            else if (typeof object.fromMessageID === "number")
                message.fromMessageID = object.fromMessageID;
            else if (typeof object.fromMessageID === "object")
                message.fromMessageID = new $util.LongBits(object.fromMessageID.low >>> 0, object.fromMessageID.high >>> 0).toNumber(true);
        if (object.fromDay != null)
            if ($util.Long)
                (message.fromDay = $util.Long.fromValue(object.fromDay)).unsigned = true;
            else if (typeof object.fromDay === "string")
                message.fromDay = parseInt(object.fromDay, 10);
            else if (typeof object.fromDay === "number")
                message.fromDay = object.fromDay;
            else if (typeof object.fromDay === "object")
                message.fromDay = new $util.LongBits(object.fromDay.low >>> 0, object.fromDay.high >>> 0).toNumber(true);
        if (object.limit != null)
            if ($util.Long)
                (message.limit = $util.Long.fromValue(object.limit)).unsigned = true;
            else if (typeof object.limit === "string")
                message.limit = parseInt(object.limit, 10);
            else if (typeof object.limit === "number")
                message.limit = object.limit;
            else if (typeof object.limit === "object")
                message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber(true);
        switch (object.attachmentType) {
        default:
            if (typeof object.attachmentType === "number") {
                message.attachmentType = object.attachmentType;
                break;
            }
            break;
        case "UNKNOWN":
        case 0:
            message.attachmentType = 0;
            break;
        case "IMAGE":
        case 1:
            message.attachmentType = 1;
            break;
        case "VIDEO":
        case 2:
            message.attachmentType = 2;
            break;
        case "AUDIO":
        case 3:
            message.attachmentType = 3;
            break;
        case "DOCUMENT":
        case 4:
            message.attachmentType = 4;
            break;
        case "STICKER":
        case 5:
            message.attachmentType = 5;
            break;
        case "GIF":
        case 6:
            message.attachmentType = 6;
            break;
        case "VOICE":
        case 7:
            message.attachmentType = 7;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetMessages message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetMessages
     * @static
     * @param {MCMethodGetMessages} message MCMethodGetMessages
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetMessages.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.fromMessageID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.fromMessageID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.fromDay = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.fromDay = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.limit = options.longs === String ? "0" : 0;
            object.attachmentType = options.enums === String ? "UNKNOWN" : 0;
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.fromMessageID != null && message.hasOwnProperty("fromMessageID"))
            if (typeof message.fromMessageID === "number")
                object.fromMessageID = options.longs === String ? String(message.fromMessageID) : message.fromMessageID;
            else
                object.fromMessageID = options.longs === String ? $util.Long.prototype.toString.call(message.fromMessageID) : options.longs === Number ? new $util.LongBits(message.fromMessageID.low >>> 0, message.fromMessageID.high >>> 0).toNumber(true) : message.fromMessageID;
        if (message.fromDay != null && message.hasOwnProperty("fromDay"))
            if (typeof message.fromDay === "number")
                object.fromDay = options.longs === String ? String(message.fromDay) : message.fromDay;
            else
                object.fromDay = options.longs === String ? $util.Long.prototype.toString.call(message.fromDay) : options.longs === Number ? new $util.LongBits(message.fromDay.low >>> 0, message.fromDay.high >>> 0).toNumber(true) : message.fromDay;
        if (message.limit != null && message.hasOwnProperty("limit"))
            if (typeof message.limit === "number")
                object.limit = options.longs === String ? String(message.limit) : message.limit;
            else
                object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber(true) : message.limit;
        if (message.attachmentType != null && message.hasOwnProperty("attachmentType"))
            object.attachmentType = options.enums === String ? $root.MCAttachment.Type[message.attachmentType] === undefined ? message.attachmentType : $root.MCAttachment.Type[message.attachmentType] : message.attachmentType;
        return object;
    };

    /**
     * Converts this MCMethodGetMessages to JSON.
     * @function toJSON
     * @memberof MCMethodGetMessages
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetMessages.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetMessages
     * @function getTypeUrl
     * @memberof MCMethodGetMessages
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetMessages.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetMessages";
    };

    return MCMethodGetMessages;
})();

export const MCMethodGetMessagesResponse = $root.MCMethodGetMessagesResponse = (() => {

    /**
     * Properties of a MCMethodGetMessagesResponse.
     * @exports IMCMethodGetMessagesResponse
     * @interface IMCMethodGetMessagesResponse
     * @property {MCMethodGetMessagesResponse.Result|null} [result] MCMethodGetMessagesResponse result
     * @property {Array.<IMCMessage>|null} [messages] MCMethodGetMessagesResponse messages
     */

    /**
     * Constructs a new MCMethodGetMessagesResponse.
     * @exports MCMethodGetMessagesResponse
     * @classdesc Represents a MCMethodGetMessagesResponse.
     * @implements IMCMethodGetMessagesResponse
     * @constructor
     * @param {IMCMethodGetMessagesResponse=} [properties] Properties to set
     */
    function MCMethodGetMessagesResponse(properties) {
        this.messages = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetMessagesResponse result.
     * @member {MCMethodGetMessagesResponse.Result} result
     * @memberof MCMethodGetMessagesResponse
     * @instance
     */
    MCMethodGetMessagesResponse.prototype.result = 0;

    /**
     * MCMethodGetMessagesResponse messages.
     * @member {Array.<IMCMessage>} messages
     * @memberof MCMethodGetMessagesResponse
     * @instance
     */
    MCMethodGetMessagesResponse.prototype.messages = $util.emptyArray;

    /**
     * Creates a new MCMethodGetMessagesResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodGetMessagesResponse
     * @static
     * @param {IMCMethodGetMessagesResponse=} [properties] Properties to set
     * @returns {MCMethodGetMessagesResponse} MCMethodGetMessagesResponse instance
     */
    MCMethodGetMessagesResponse.create = function create(properties) {
        return new MCMethodGetMessagesResponse(properties);
    };

    /**
     * Encodes the specified MCMethodGetMessagesResponse message. Does not implicitly {@link MCMethodGetMessagesResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetMessagesResponse
     * @static
     * @param {IMCMethodGetMessagesResponse} message MCMethodGetMessagesResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetMessagesResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.messages != null && message.messages.length)
            for (let i = 0; i < message.messages.length; ++i)
                $root.MCMessage.encode(message.messages[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetMessagesResponse message, length delimited. Does not implicitly {@link MCMethodGetMessagesResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetMessagesResponse
     * @static
     * @param {IMCMethodGetMessagesResponse} message MCMethodGetMessagesResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetMessagesResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetMessagesResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetMessagesResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetMessagesResponse} MCMethodGetMessagesResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetMessagesResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetMessagesResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.messages && message.messages.length))
                        message.messages = [];
                    message.messages.push($root.MCMessage.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetMessagesResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetMessagesResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetMessagesResponse} MCMethodGetMessagesResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetMessagesResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetMessagesResponse message.
     * @function verify
     * @memberof MCMethodGetMessagesResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetMessagesResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 10:
                break;
            }
        if (message.messages != null && message.hasOwnProperty("messages")) {
            if (!Array.isArray(message.messages))
                return "messages: array expected";
            for (let i = 0; i < message.messages.length; ++i) {
                let error = $root.MCMessage.verify(message.messages[i]);
                if (error)
                    return "messages." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCMethodGetMessagesResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetMessagesResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetMessagesResponse} MCMethodGetMessagesResponse
     */
    MCMethodGetMessagesResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetMessagesResponse)
            return object;
        let message = new $root.MCMethodGetMessagesResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_OK":
        case 0:
            message.result = 0;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 1:
            message.result = 1;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.messages) {
            if (!Array.isArray(object.messages))
                throw TypeError(".MCMethodGetMessagesResponse.messages: array expected");
            message.messages = [];
            for (let i = 0; i < object.messages.length; ++i) {
                if (typeof object.messages[i] !== "object")
                    throw TypeError(".MCMethodGetMessagesResponse.messages: object expected");
                message.messages[i] = $root.MCMessage.fromObject(object.messages[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetMessagesResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetMessagesResponse
     * @static
     * @param {MCMethodGetMessagesResponse} message MCMethodGetMessagesResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetMessagesResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.messages = [];
        if (options.defaults)
            object.result = options.enums === String ? "R_OK" : 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodGetMessagesResponse.Result[message.result] === undefined ? message.result : $root.MCMethodGetMessagesResponse.Result[message.result] : message.result;
        if (message.messages && message.messages.length) {
            object.messages = [];
            for (let j = 0; j < message.messages.length; ++j)
                object.messages[j] = $root.MCMessage.toObject(message.messages[j], options);
        }
        return object;
    };

    /**
     * Converts this MCMethodGetMessagesResponse to JSON.
     * @function toJSON
     * @memberof MCMethodGetMessagesResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetMessagesResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetMessagesResponse
     * @function getTypeUrl
     * @memberof MCMethodGetMessagesResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetMessagesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetMessagesResponse";
    };

    /**
     * Result enum.
     * @name MCMethodGetMessagesResponse.Result
     * @enum {number}
     * @property {number} R_OK=0 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=1 R_CHANNEL_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodGetMessagesResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_OK"] = 0;
        values[valuesById[1] = "R_CHANNEL_NOT_FOUND"] = 1;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodGetMessagesResponse;
})();

export const MCMethodGetPeer = $root.MCMethodGetPeer = (() => {

    /**
     * Properties of a MCMethodGetPeer.
     * @exports IMCMethodGetPeer
     * @interface IMCMethodGetPeer
     * @property {MCMethodGetPeer.IPeerKey|null} [key] MCMethodGetPeer key
     */

    /**
     * Constructs a new MCMethodGetPeer.
     * @exports MCMethodGetPeer
     * @classdesc Represents a MCMethodGetPeer.
     * @implements IMCMethodGetPeer
     * @constructor
     * @param {IMCMethodGetPeer=} [properties] Properties to set
     */
    function MCMethodGetPeer(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetPeer key.
     * @member {MCMethodGetPeer.IPeerKey|null|undefined} key
     * @memberof MCMethodGetPeer
     * @instance
     */
    MCMethodGetPeer.prototype.key = null;

    /**
     * Creates a new MCMethodGetPeer instance using the specified properties.
     * @function create
     * @memberof MCMethodGetPeer
     * @static
     * @param {IMCMethodGetPeer=} [properties] Properties to set
     * @returns {MCMethodGetPeer} MCMethodGetPeer instance
     */
    MCMethodGetPeer.create = function create(properties) {
        return new MCMethodGetPeer(properties);
    };

    /**
     * Encodes the specified MCMethodGetPeer message. Does not implicitly {@link MCMethodGetPeer.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetPeer
     * @static
     * @param {IMCMethodGetPeer} message MCMethodGetPeer message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetPeer.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.key != null && Object.hasOwnProperty.call(message, "key"))
            $root.MCMethodGetPeer.PeerKey.encode(message.key, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetPeer message, length delimited. Does not implicitly {@link MCMethodGetPeer.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetPeer
     * @static
     * @param {IMCMethodGetPeer} message MCMethodGetPeer message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetPeer.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetPeer message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetPeer
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetPeer} MCMethodGetPeer
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetPeer.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetPeer();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.key = $root.MCMethodGetPeer.PeerKey.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetPeer message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetPeer
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetPeer} MCMethodGetPeer
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetPeer.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetPeer message.
     * @function verify
     * @memberof MCMethodGetPeer
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetPeer.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.key != null && message.hasOwnProperty("key")) {
            let error = $root.MCMethodGetPeer.PeerKey.verify(message.key);
            if (error)
                return "key." + error;
        }
        return null;
    };

    /**
     * Creates a MCMethodGetPeer message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetPeer
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetPeer} MCMethodGetPeer
     */
    MCMethodGetPeer.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetPeer)
            return object;
        let message = new $root.MCMethodGetPeer();
        if (object.key != null) {
            if (typeof object.key !== "object")
                throw TypeError(".MCMethodGetPeer.key: object expected");
            message.key = $root.MCMethodGetPeer.PeerKey.fromObject(object.key);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetPeer message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetPeer
     * @static
     * @param {MCMethodGetPeer} message MCMethodGetPeer
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetPeer.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.key = null;
        if (message.key != null && message.hasOwnProperty("key"))
            object.key = $root.MCMethodGetPeer.PeerKey.toObject(message.key, options);
        return object;
    };

    /**
     * Converts this MCMethodGetPeer to JSON.
     * @function toJSON
     * @memberof MCMethodGetPeer
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetPeer.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetPeer
     * @function getTypeUrl
     * @memberof MCMethodGetPeer
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetPeer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetPeer";
    };

    MCMethodGetPeer.PeerKey = (function() {

        /**
         * Properties of a PeerKey.
         * @memberof MCMethodGetPeer
         * @interface IPeerKey
         * @property {Uint8Array|null} [channelID] PeerKey channelID
         * @property {Long|null} [id] PeerKey id
         */

        /**
         * Constructs a new PeerKey.
         * @memberof MCMethodGetPeer
         * @classdesc Represents a PeerKey.
         * @implements IPeerKey
         * @constructor
         * @param {MCMethodGetPeer.IPeerKey=} [properties] Properties to set
         */
        function PeerKey(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PeerKey channelID.
         * @member {Uint8Array} channelID
         * @memberof MCMethodGetPeer.PeerKey
         * @instance
         */
        PeerKey.prototype.channelID = $util.newBuffer([]);

        /**
         * PeerKey id.
         * @member {Long} id
         * @memberof MCMethodGetPeer.PeerKey
         * @instance
         */
        PeerKey.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new PeerKey instance using the specified properties.
         * @function create
         * @memberof MCMethodGetPeer.PeerKey
         * @static
         * @param {MCMethodGetPeer.IPeerKey=} [properties] Properties to set
         * @returns {MCMethodGetPeer.PeerKey} PeerKey instance
         */
        PeerKey.create = function create(properties) {
            return new PeerKey(properties);
        };

        /**
         * Encodes the specified PeerKey message. Does not implicitly {@link MCMethodGetPeer.PeerKey.verify|verify} messages.
         * @function encode
         * @memberof MCMethodGetPeer.PeerKey
         * @static
         * @param {MCMethodGetPeer.IPeerKey} message PeerKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeerKey.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.id);
            return writer;
        };

        /**
         * Encodes the specified PeerKey message, length delimited. Does not implicitly {@link MCMethodGetPeer.PeerKey.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MCMethodGetPeer.PeerKey
         * @static
         * @param {MCMethodGetPeer.IPeerKey} message PeerKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeerKey.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PeerKey message from the specified reader or buffer.
         * @function decode
         * @memberof MCMethodGetPeer.PeerKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MCMethodGetPeer.PeerKey} PeerKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeerKey.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetPeer.PeerKey();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelID = reader.bytes();
                        break;
                    }
                case 2: {
                        message.id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PeerKey message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MCMethodGetPeer.PeerKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MCMethodGetPeer.PeerKey} PeerKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeerKey.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PeerKey message.
         * @function verify
         * @memberof MCMethodGetPeer.PeerKey
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PeerKey.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.channelID != null && message.hasOwnProperty("channelID"))
                if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                    return "channelID: buffer expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                    return "id: integer|Long expected";
            return null;
        };

        /**
         * Creates a PeerKey message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MCMethodGetPeer.PeerKey
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MCMethodGetPeer.PeerKey} PeerKey
         */
        PeerKey.fromObject = function fromObject(object) {
            if (object instanceof $root.MCMethodGetPeer.PeerKey)
                return object;
            let message = new $root.MCMethodGetPeer.PeerKey();
            if (object.channelID != null)
                if (typeof object.channelID === "string")
                    $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
                else if (object.channelID.length >= 0)
                    message.channelID = object.channelID;
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a PeerKey message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MCMethodGetPeer.PeerKey
         * @static
         * @param {MCMethodGetPeer.PeerKey} message PeerKey
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PeerKey.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.channelID = "";
                else {
                    object.channelID = [];
                    if (options.bytes !== Array)
                        object.channelID = $util.newBuffer(object.channelID);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
            }
            if (message.channelID != null && message.hasOwnProperty("channelID"))
                object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            return object;
        };

        /**
         * Converts this PeerKey to JSON.
         * @function toJSON
         * @memberof MCMethodGetPeer.PeerKey
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PeerKey.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PeerKey
         * @function getTypeUrl
         * @memberof MCMethodGetPeer.PeerKey
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PeerKey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MCMethodGetPeer.PeerKey";
        };

        return PeerKey;
    })();

    return MCMethodGetPeer;
})();

export const MCMethodGetPeerResponse = $root.MCMethodGetPeerResponse = (() => {

    /**
     * Properties of a MCMethodGetPeerResponse.
     * @exports IMCMethodGetPeerResponse
     * @interface IMCMethodGetPeerResponse
     * @property {MCMethodGetPeerResponse.Result|null} [result] MCMethodGetPeerResponse result
     * @property {IMCPeer|null} [peer] MCMethodGetPeerResponse peer
     */

    /**
     * Constructs a new MCMethodGetPeerResponse.
     * @exports MCMethodGetPeerResponse
     * @classdesc Represents a MCMethodGetPeerResponse.
     * @implements IMCMethodGetPeerResponse
     * @constructor
     * @param {IMCMethodGetPeerResponse=} [properties] Properties to set
     */
    function MCMethodGetPeerResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetPeerResponse result.
     * @member {MCMethodGetPeerResponse.Result} result
     * @memberof MCMethodGetPeerResponse
     * @instance
     */
    MCMethodGetPeerResponse.prototype.result = 0;

    /**
     * MCMethodGetPeerResponse peer.
     * @member {IMCPeer|null|undefined} peer
     * @memberof MCMethodGetPeerResponse
     * @instance
     */
    MCMethodGetPeerResponse.prototype.peer = null;

    /**
     * Creates a new MCMethodGetPeerResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodGetPeerResponse
     * @static
     * @param {IMCMethodGetPeerResponse=} [properties] Properties to set
     * @returns {MCMethodGetPeerResponse} MCMethodGetPeerResponse instance
     */
    MCMethodGetPeerResponse.create = function create(properties) {
        return new MCMethodGetPeerResponse(properties);
    };

    /**
     * Encodes the specified MCMethodGetPeerResponse message. Does not implicitly {@link MCMethodGetPeerResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetPeerResponse
     * @static
     * @param {IMCMethodGetPeerResponse} message MCMethodGetPeerResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetPeerResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.peer != null && Object.hasOwnProperty.call(message, "peer"))
            $root.MCPeer.encode(message.peer, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetPeerResponse message, length delimited. Does not implicitly {@link MCMethodGetPeerResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetPeerResponse
     * @static
     * @param {IMCMethodGetPeerResponse} message MCMethodGetPeerResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetPeerResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetPeerResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetPeerResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetPeerResponse} MCMethodGetPeerResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetPeerResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetPeerResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    message.peer = $root.MCPeer.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetPeerResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetPeerResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetPeerResponse} MCMethodGetPeerResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetPeerResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetPeerResponse message.
     * @function verify
     * @memberof MCMethodGetPeerResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetPeerResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 10:
                break;
            }
        if (message.peer != null && message.hasOwnProperty("peer")) {
            let error = $root.MCPeer.verify(message.peer);
            if (error)
                return "peer." + error;
        }
        return null;
    };

    /**
     * Creates a MCMethodGetPeerResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetPeerResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetPeerResponse} MCMethodGetPeerResponse
     */
    MCMethodGetPeerResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetPeerResponse)
            return object;
        let message = new $root.MCMethodGetPeerResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_OK":
        case 0:
            message.result = 0;
            break;
        case "R_PEER_NOT_FOUND":
        case 1:
            message.result = 1;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.peer != null) {
            if (typeof object.peer !== "object")
                throw TypeError(".MCMethodGetPeerResponse.peer: object expected");
            message.peer = $root.MCPeer.fromObject(object.peer);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetPeerResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetPeerResponse
     * @static
     * @param {MCMethodGetPeerResponse} message MCMethodGetPeerResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetPeerResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = options.enums === String ? "R_OK" : 0;
            object.peer = null;
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodGetPeerResponse.Result[message.result] === undefined ? message.result : $root.MCMethodGetPeerResponse.Result[message.result] : message.result;
        if (message.peer != null && message.hasOwnProperty("peer"))
            object.peer = $root.MCPeer.toObject(message.peer, options);
        return object;
    };

    /**
     * Converts this MCMethodGetPeerResponse to JSON.
     * @function toJSON
     * @memberof MCMethodGetPeerResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetPeerResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetPeerResponse
     * @function getTypeUrl
     * @memberof MCMethodGetPeerResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetPeerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetPeerResponse";
    };

    /**
     * Result enum.
     * @name MCMethodGetPeerResponse.Result
     * @enum {number}
     * @property {number} R_OK=0 R_OK value
     * @property {number} R_PEER_NOT_FOUND=1 R_PEER_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodGetPeerResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_OK"] = 0;
        values[valuesById[1] = "R_PEER_NOT_FOUND"] = 1;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodGetPeerResponse;
})();

export const MCMethodGetPeers = $root.MCMethodGetPeers = (() => {

    /**
     * Properties of a MCMethodGetPeers.
     * @exports IMCMethodGetPeers
     * @interface IMCMethodGetPeers
     * @property {Array.<MCMethodGetPeer.IPeerKey>|null} [keys] MCMethodGetPeers keys
     */

    /**
     * Constructs a new MCMethodGetPeers.
     * @exports MCMethodGetPeers
     * @classdesc Represents a MCMethodGetPeers.
     * @implements IMCMethodGetPeers
     * @constructor
     * @param {IMCMethodGetPeers=} [properties] Properties to set
     */
    function MCMethodGetPeers(properties) {
        this.keys = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetPeers keys.
     * @member {Array.<MCMethodGetPeer.IPeerKey>} keys
     * @memberof MCMethodGetPeers
     * @instance
     */
    MCMethodGetPeers.prototype.keys = $util.emptyArray;

    /**
     * Creates a new MCMethodGetPeers instance using the specified properties.
     * @function create
     * @memberof MCMethodGetPeers
     * @static
     * @param {IMCMethodGetPeers=} [properties] Properties to set
     * @returns {MCMethodGetPeers} MCMethodGetPeers instance
     */
    MCMethodGetPeers.create = function create(properties) {
        return new MCMethodGetPeers(properties);
    };

    /**
     * Encodes the specified MCMethodGetPeers message. Does not implicitly {@link MCMethodGetPeers.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetPeers
     * @static
     * @param {IMCMethodGetPeers} message MCMethodGetPeers message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetPeers.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.keys != null && message.keys.length)
            for (let i = 0; i < message.keys.length; ++i)
                $root.MCMethodGetPeer.PeerKey.encode(message.keys[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetPeers message, length delimited. Does not implicitly {@link MCMethodGetPeers.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetPeers
     * @static
     * @param {IMCMethodGetPeers} message MCMethodGetPeers message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetPeers.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetPeers message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetPeers
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetPeers} MCMethodGetPeers
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetPeers.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetPeers();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.keys && message.keys.length))
                        message.keys = [];
                    message.keys.push($root.MCMethodGetPeer.PeerKey.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetPeers message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetPeers
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetPeers} MCMethodGetPeers
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetPeers.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetPeers message.
     * @function verify
     * @memberof MCMethodGetPeers
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetPeers.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.keys != null && message.hasOwnProperty("keys")) {
            if (!Array.isArray(message.keys))
                return "keys: array expected";
            for (let i = 0; i < message.keys.length; ++i) {
                let error = $root.MCMethodGetPeer.PeerKey.verify(message.keys[i]);
                if (error)
                    return "keys." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCMethodGetPeers message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetPeers
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetPeers} MCMethodGetPeers
     */
    MCMethodGetPeers.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetPeers)
            return object;
        let message = new $root.MCMethodGetPeers();
        if (object.keys) {
            if (!Array.isArray(object.keys))
                throw TypeError(".MCMethodGetPeers.keys: array expected");
            message.keys = [];
            for (let i = 0; i < object.keys.length; ++i) {
                if (typeof object.keys[i] !== "object")
                    throw TypeError(".MCMethodGetPeers.keys: object expected");
                message.keys[i] = $root.MCMethodGetPeer.PeerKey.fromObject(object.keys[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetPeers message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetPeers
     * @static
     * @param {MCMethodGetPeers} message MCMethodGetPeers
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetPeers.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.keys = [];
        if (message.keys && message.keys.length) {
            object.keys = [];
            for (let j = 0; j < message.keys.length; ++j)
                object.keys[j] = $root.MCMethodGetPeer.PeerKey.toObject(message.keys[j], options);
        }
        return object;
    };

    /**
     * Converts this MCMethodGetPeers to JSON.
     * @function toJSON
     * @memberof MCMethodGetPeers
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetPeers.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetPeers
     * @function getTypeUrl
     * @memberof MCMethodGetPeers
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetPeers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetPeers";
    };

    return MCMethodGetPeers;
})();

export const MCMethodGetPeersResponse = $root.MCMethodGetPeersResponse = (() => {

    /**
     * Properties of a MCMethodGetPeersResponse.
     * @exports IMCMethodGetPeersResponse
     * @interface IMCMethodGetPeersResponse
     * @property {Array.<IMCMethodGetPeerResponse>|null} [peers] MCMethodGetPeersResponse peers
     */

    /**
     * Constructs a new MCMethodGetPeersResponse.
     * @exports MCMethodGetPeersResponse
     * @classdesc Represents a MCMethodGetPeersResponse.
     * @implements IMCMethodGetPeersResponse
     * @constructor
     * @param {IMCMethodGetPeersResponse=} [properties] Properties to set
     */
    function MCMethodGetPeersResponse(properties) {
        this.peers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetPeersResponse peers.
     * @member {Array.<IMCMethodGetPeerResponse>} peers
     * @memberof MCMethodGetPeersResponse
     * @instance
     */
    MCMethodGetPeersResponse.prototype.peers = $util.emptyArray;

    /**
     * Creates a new MCMethodGetPeersResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodGetPeersResponse
     * @static
     * @param {IMCMethodGetPeersResponse=} [properties] Properties to set
     * @returns {MCMethodGetPeersResponse} MCMethodGetPeersResponse instance
     */
    MCMethodGetPeersResponse.create = function create(properties) {
        return new MCMethodGetPeersResponse(properties);
    };

    /**
     * Encodes the specified MCMethodGetPeersResponse message. Does not implicitly {@link MCMethodGetPeersResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetPeersResponse
     * @static
     * @param {IMCMethodGetPeersResponse} message MCMethodGetPeersResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetPeersResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.peers != null && message.peers.length)
            for (let i = 0; i < message.peers.length; ++i)
                $root.MCMethodGetPeerResponse.encode(message.peers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetPeersResponse message, length delimited. Does not implicitly {@link MCMethodGetPeersResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetPeersResponse
     * @static
     * @param {IMCMethodGetPeersResponse} message MCMethodGetPeersResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetPeersResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetPeersResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetPeersResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetPeersResponse} MCMethodGetPeersResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetPeersResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetPeersResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.peers && message.peers.length))
                        message.peers = [];
                    message.peers.push($root.MCMethodGetPeerResponse.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetPeersResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetPeersResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetPeersResponse} MCMethodGetPeersResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetPeersResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetPeersResponse message.
     * @function verify
     * @memberof MCMethodGetPeersResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetPeersResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.peers != null && message.hasOwnProperty("peers")) {
            if (!Array.isArray(message.peers))
                return "peers: array expected";
            for (let i = 0; i < message.peers.length; ++i) {
                let error = $root.MCMethodGetPeerResponse.verify(message.peers[i]);
                if (error)
                    return "peers." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCMethodGetPeersResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetPeersResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetPeersResponse} MCMethodGetPeersResponse
     */
    MCMethodGetPeersResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetPeersResponse)
            return object;
        let message = new $root.MCMethodGetPeersResponse();
        if (object.peers) {
            if (!Array.isArray(object.peers))
                throw TypeError(".MCMethodGetPeersResponse.peers: array expected");
            message.peers = [];
            for (let i = 0; i < object.peers.length; ++i) {
                if (typeof object.peers[i] !== "object")
                    throw TypeError(".MCMethodGetPeersResponse.peers: object expected");
                message.peers[i] = $root.MCMethodGetPeerResponse.fromObject(object.peers[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetPeersResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetPeersResponse
     * @static
     * @param {MCMethodGetPeersResponse} message MCMethodGetPeersResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetPeersResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.peers = [];
        if (message.peers && message.peers.length) {
            object.peers = [];
            for (let j = 0; j < message.peers.length; ++j)
                object.peers[j] = $root.MCMethodGetPeerResponse.toObject(message.peers[j], options);
        }
        return object;
    };

    /**
     * Converts this MCMethodGetPeersResponse to JSON.
     * @function toJSON
     * @memberof MCMethodGetPeersResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetPeersResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetPeersResponse
     * @function getTypeUrl
     * @memberof MCMethodGetPeersResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetPeersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetPeersResponse";
    };

    return MCMethodGetPeersResponse;
})();

export const MCMethodGetChatsPreview = $root.MCMethodGetChatsPreview = (() => {

    /**
     * Properties of a MCMethodGetChatsPreview.
     * @exports IMCMethodGetChatsPreview
     * @interface IMCMethodGetChatsPreview
     * @property {Array.<Uint8Array>|null} [channelIDs] MCMethodGetChatsPreview channelIDs
     * @property {boolean|null} [fetch] MCMethodGetChatsPreview fetch
     * @property {Long|null} [offsetID] MCMethodGetChatsPreview offsetID
     * @property {Long|null} [offsetBefore] MCMethodGetChatsPreview offsetBefore
     * @property {Long|null} [offsetAfter] MCMethodGetChatsPreview offsetAfter
     * @property {boolean|null} [unreadOnly] MCMethodGetChatsPreview unreadOnly
     * @property {boolean|null} [assignedToMeOnly] MCMethodGetChatsPreview assignedToMeOnly
     * @property {boolean|null} [assignedToOthersOnly] MCMethodGetChatsPreview assignedToOthersOnly
     * @property {MCMethodGetChatsPreview.MCChatStatus|null} [status] MCMethodGetChatsPreview status
     * @property {Array.<IMCChannelWithAccess>|null} [channels] MCMethodGetChatsPreview channels
     */

    /**
     * Constructs a new MCMethodGetChatsPreview.
     * @exports MCMethodGetChatsPreview
     * @classdesc Represents a MCMethodGetChatsPreview.
     * @implements IMCMethodGetChatsPreview
     * @constructor
     * @param {IMCMethodGetChatsPreview=} [properties] Properties to set
     */
    function MCMethodGetChatsPreview(properties) {
        this.channelIDs = [];
        this.channels = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetChatsPreview channelIDs.
     * @member {Array.<Uint8Array>} channelIDs
     * @memberof MCMethodGetChatsPreview
     * @instance
     */
    MCMethodGetChatsPreview.prototype.channelIDs = $util.emptyArray;

    /**
     * MCMethodGetChatsPreview fetch.
     * @member {boolean} fetch
     * @memberof MCMethodGetChatsPreview
     * @instance
     */
    MCMethodGetChatsPreview.prototype.fetch = false;

    /**
     * MCMethodGetChatsPreview offsetID.
     * @member {Long} offsetID
     * @memberof MCMethodGetChatsPreview
     * @instance
     */
    MCMethodGetChatsPreview.prototype.offsetID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodGetChatsPreview offsetBefore.
     * @member {Long} offsetBefore
     * @memberof MCMethodGetChatsPreview
     * @instance
     */
    MCMethodGetChatsPreview.prototype.offsetBefore = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodGetChatsPreview offsetAfter.
     * @member {Long} offsetAfter
     * @memberof MCMethodGetChatsPreview
     * @instance
     */
    MCMethodGetChatsPreview.prototype.offsetAfter = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodGetChatsPreview unreadOnly.
     * @member {boolean} unreadOnly
     * @memberof MCMethodGetChatsPreview
     * @instance
     */
    MCMethodGetChatsPreview.prototype.unreadOnly = false;

    /**
     * MCMethodGetChatsPreview assignedToMeOnly.
     * @member {boolean} assignedToMeOnly
     * @memberof MCMethodGetChatsPreview
     * @instance
     */
    MCMethodGetChatsPreview.prototype.assignedToMeOnly = false;

    /**
     * MCMethodGetChatsPreview assignedToOthersOnly.
     * @member {boolean} assignedToOthersOnly
     * @memberof MCMethodGetChatsPreview
     * @instance
     */
    MCMethodGetChatsPreview.prototype.assignedToOthersOnly = false;

    /**
     * MCMethodGetChatsPreview status.
     * @member {MCMethodGetChatsPreview.MCChatStatus} status
     * @memberof MCMethodGetChatsPreview
     * @instance
     */
    MCMethodGetChatsPreview.prototype.status = 0;

    /**
     * MCMethodGetChatsPreview channels.
     * @member {Array.<IMCChannelWithAccess>} channels
     * @memberof MCMethodGetChatsPreview
     * @instance
     */
    MCMethodGetChatsPreview.prototype.channels = $util.emptyArray;

    /**
     * Creates a new MCMethodGetChatsPreview instance using the specified properties.
     * @function create
     * @memberof MCMethodGetChatsPreview
     * @static
     * @param {IMCMethodGetChatsPreview=} [properties] Properties to set
     * @returns {MCMethodGetChatsPreview} MCMethodGetChatsPreview instance
     */
    MCMethodGetChatsPreview.create = function create(properties) {
        return new MCMethodGetChatsPreview(properties);
    };

    /**
     * Encodes the specified MCMethodGetChatsPreview message. Does not implicitly {@link MCMethodGetChatsPreview.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetChatsPreview
     * @static
     * @param {IMCMethodGetChatsPreview} message MCMethodGetChatsPreview message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetChatsPreview.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelIDs != null && message.channelIDs.length)
            for (let i = 0; i < message.channelIDs.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelIDs[i]);
        if (message.fetch != null && Object.hasOwnProperty.call(message, "fetch"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.fetch);
        if (message.offsetID != null && Object.hasOwnProperty.call(message, "offsetID"))
            writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.offsetID);
        if (message.offsetBefore != null && Object.hasOwnProperty.call(message, "offsetBefore"))
            writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.offsetBefore);
        if (message.offsetAfter != null && Object.hasOwnProperty.call(message, "offsetAfter"))
            writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.offsetAfter);
        if (message.unreadOnly != null && Object.hasOwnProperty.call(message, "unreadOnly"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.unreadOnly);
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.status);
        if (message.assignedToMeOnly != null && Object.hasOwnProperty.call(message, "assignedToMeOnly"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.assignedToMeOnly);
        if (message.channels != null && message.channels.length)
            for (let i = 0; i < message.channels.length; ++i)
                $root.MCChannelWithAccess.encode(message.channels[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.assignedToOthersOnly != null && Object.hasOwnProperty.call(message, "assignedToOthersOnly"))
            writer.uint32(/* id 13, wireType 0 =*/104).bool(message.assignedToOthersOnly);
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetChatsPreview message, length delimited. Does not implicitly {@link MCMethodGetChatsPreview.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetChatsPreview
     * @static
     * @param {IMCMethodGetChatsPreview} message MCMethodGetChatsPreview message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetChatsPreview.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetChatsPreview message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetChatsPreview
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetChatsPreview} MCMethodGetChatsPreview
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetChatsPreview.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetChatsPreview();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.channelIDs && message.channelIDs.length))
                        message.channelIDs = [];
                    message.channelIDs.push(reader.bytes());
                    break;
                }
            case 3: {
                    message.fetch = reader.bool();
                    break;
                }
            case 5: {
                    message.offsetID = reader.uint64();
                    break;
                }
            case 6: {
                    message.offsetBefore = reader.uint64();
                    break;
                }
            case 7: {
                    message.offsetAfter = reader.uint64();
                    break;
                }
            case 8: {
                    message.unreadOnly = reader.bool();
                    break;
                }
            case 10: {
                    message.assignedToMeOnly = reader.bool();
                    break;
                }
            case 13: {
                    message.assignedToOthersOnly = reader.bool();
                    break;
                }
            case 9: {
                    message.status = reader.int32();
                    break;
                }
            case 11: {
                    if (!(message.channels && message.channels.length))
                        message.channels = [];
                    message.channels.push($root.MCChannelWithAccess.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetChatsPreview message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetChatsPreview
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetChatsPreview} MCMethodGetChatsPreview
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetChatsPreview.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetChatsPreview message.
     * @function verify
     * @memberof MCMethodGetChatsPreview
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetChatsPreview.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelIDs != null && message.hasOwnProperty("channelIDs")) {
            if (!Array.isArray(message.channelIDs))
                return "channelIDs: array expected";
            for (let i = 0; i < message.channelIDs.length; ++i)
                if (!(message.channelIDs[i] && typeof message.channelIDs[i].length === "number" || $util.isString(message.channelIDs[i])))
                    return "channelIDs: buffer[] expected";
        }
        if (message.fetch != null && message.hasOwnProperty("fetch"))
            if (typeof message.fetch !== "boolean")
                return "fetch: boolean expected";
        if (message.offsetID != null && message.hasOwnProperty("offsetID"))
            if (!$util.isInteger(message.offsetID) && !(message.offsetID && $util.isInteger(message.offsetID.low) && $util.isInteger(message.offsetID.high)))
                return "offsetID: integer|Long expected";
        if (message.offsetBefore != null && message.hasOwnProperty("offsetBefore"))
            if (!$util.isInteger(message.offsetBefore) && !(message.offsetBefore && $util.isInteger(message.offsetBefore.low) && $util.isInteger(message.offsetBefore.high)))
                return "offsetBefore: integer|Long expected";
        if (message.offsetAfter != null && message.hasOwnProperty("offsetAfter"))
            if (!$util.isInteger(message.offsetAfter) && !(message.offsetAfter && $util.isInteger(message.offsetAfter.low) && $util.isInteger(message.offsetAfter.high)))
                return "offsetAfter: integer|Long expected";
        if (message.unreadOnly != null && message.hasOwnProperty("unreadOnly"))
            if (typeof message.unreadOnly !== "boolean")
                return "unreadOnly: boolean expected";
        if (message.assignedToMeOnly != null && message.hasOwnProperty("assignedToMeOnly"))
            if (typeof message.assignedToMeOnly !== "boolean")
                return "assignedToMeOnly: boolean expected";
        if (message.assignedToOthersOnly != null && message.hasOwnProperty("assignedToOthersOnly"))
            if (typeof message.assignedToOthersOnly !== "boolean")
                return "assignedToOthersOnly: boolean expected";
        if (message.status != null && message.hasOwnProperty("status"))
            switch (message.status) {
            default:
                return "status: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.channels != null && message.hasOwnProperty("channels")) {
            if (!Array.isArray(message.channels))
                return "channels: array expected";
            for (let i = 0; i < message.channels.length; ++i) {
                let error = $root.MCChannelWithAccess.verify(message.channels[i]);
                if (error)
                    return "channels." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCMethodGetChatsPreview message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetChatsPreview
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetChatsPreview} MCMethodGetChatsPreview
     */
    MCMethodGetChatsPreview.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetChatsPreview)
            return object;
        let message = new $root.MCMethodGetChatsPreview();
        if (object.channelIDs) {
            if (!Array.isArray(object.channelIDs))
                throw TypeError(".MCMethodGetChatsPreview.channelIDs: array expected");
            message.channelIDs = [];
            for (let i = 0; i < object.channelIDs.length; ++i)
                if (typeof object.channelIDs[i] === "string")
                    $util.base64.decode(object.channelIDs[i], message.channelIDs[i] = $util.newBuffer($util.base64.length(object.channelIDs[i])), 0);
                else if (object.channelIDs[i].length >= 0)
                    message.channelIDs[i] = object.channelIDs[i];
        }
        if (object.fetch != null)
            message.fetch = Boolean(object.fetch);
        if (object.offsetID != null)
            if ($util.Long)
                (message.offsetID = $util.Long.fromValue(object.offsetID)).unsigned = true;
            else if (typeof object.offsetID === "string")
                message.offsetID = parseInt(object.offsetID, 10);
            else if (typeof object.offsetID === "number")
                message.offsetID = object.offsetID;
            else if (typeof object.offsetID === "object")
                message.offsetID = new $util.LongBits(object.offsetID.low >>> 0, object.offsetID.high >>> 0).toNumber(true);
        if (object.offsetBefore != null)
            if ($util.Long)
                (message.offsetBefore = $util.Long.fromValue(object.offsetBefore)).unsigned = true;
            else if (typeof object.offsetBefore === "string")
                message.offsetBefore = parseInt(object.offsetBefore, 10);
            else if (typeof object.offsetBefore === "number")
                message.offsetBefore = object.offsetBefore;
            else if (typeof object.offsetBefore === "object")
                message.offsetBefore = new $util.LongBits(object.offsetBefore.low >>> 0, object.offsetBefore.high >>> 0).toNumber(true);
        if (object.offsetAfter != null)
            if ($util.Long)
                (message.offsetAfter = $util.Long.fromValue(object.offsetAfter)).unsigned = true;
            else if (typeof object.offsetAfter === "string")
                message.offsetAfter = parseInt(object.offsetAfter, 10);
            else if (typeof object.offsetAfter === "number")
                message.offsetAfter = object.offsetAfter;
            else if (typeof object.offsetAfter === "object")
                message.offsetAfter = new $util.LongBits(object.offsetAfter.low >>> 0, object.offsetAfter.high >>> 0).toNumber(true);
        if (object.unreadOnly != null)
            message.unreadOnly = Boolean(object.unreadOnly);
        if (object.assignedToMeOnly != null)
            message.assignedToMeOnly = Boolean(object.assignedToMeOnly);
        if (object.assignedToOthersOnly != null)
            message.assignedToOthersOnly = Boolean(object.assignedToOthersOnly);
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "S_ALL":
        case 0:
            message.status = 0;
            break;
        case "S_OPEN":
        case 1:
            message.status = 1;
            break;
        case "S_IN_PROGRESS":
        case 2:
            message.status = 2;
            break;
        case "S_RESOLVED":
        case 3:
            message.status = 3;
            break;
        }
        if (object.channels) {
            if (!Array.isArray(object.channels))
                throw TypeError(".MCMethodGetChatsPreview.channels: array expected");
            message.channels = [];
            for (let i = 0; i < object.channels.length; ++i) {
                if (typeof object.channels[i] !== "object")
                    throw TypeError(".MCMethodGetChatsPreview.channels: object expected");
                message.channels[i] = $root.MCChannelWithAccess.fromObject(object.channels[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetChatsPreview message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetChatsPreview
     * @static
     * @param {MCMethodGetChatsPreview} message MCMethodGetChatsPreview
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetChatsPreview.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.channelIDs = [];
            object.channels = [];
        }
        if (options.defaults) {
            object.fetch = false;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.offsetID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.offsetID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.offsetBefore = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.offsetBefore = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.offsetAfter = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.offsetAfter = options.longs === String ? "0" : 0;
            object.unreadOnly = false;
            object.status = options.enums === String ? "S_ALL" : 0;
            object.assignedToMeOnly = false;
            object.assignedToOthersOnly = false;
        }
        if (message.channelIDs && message.channelIDs.length) {
            object.channelIDs = [];
            for (let j = 0; j < message.channelIDs.length; ++j)
                object.channelIDs[j] = options.bytes === String ? $util.base64.encode(message.channelIDs[j], 0, message.channelIDs[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.channelIDs[j]) : message.channelIDs[j];
        }
        if (message.fetch != null && message.hasOwnProperty("fetch"))
            object.fetch = message.fetch;
        if (message.offsetID != null && message.hasOwnProperty("offsetID"))
            if (typeof message.offsetID === "number")
                object.offsetID = options.longs === String ? String(message.offsetID) : message.offsetID;
            else
                object.offsetID = options.longs === String ? $util.Long.prototype.toString.call(message.offsetID) : options.longs === Number ? new $util.LongBits(message.offsetID.low >>> 0, message.offsetID.high >>> 0).toNumber(true) : message.offsetID;
        if (message.offsetBefore != null && message.hasOwnProperty("offsetBefore"))
            if (typeof message.offsetBefore === "number")
                object.offsetBefore = options.longs === String ? String(message.offsetBefore) : message.offsetBefore;
            else
                object.offsetBefore = options.longs === String ? $util.Long.prototype.toString.call(message.offsetBefore) : options.longs === Number ? new $util.LongBits(message.offsetBefore.low >>> 0, message.offsetBefore.high >>> 0).toNumber(true) : message.offsetBefore;
        if (message.offsetAfter != null && message.hasOwnProperty("offsetAfter"))
            if (typeof message.offsetAfter === "number")
                object.offsetAfter = options.longs === String ? String(message.offsetAfter) : message.offsetAfter;
            else
                object.offsetAfter = options.longs === String ? $util.Long.prototype.toString.call(message.offsetAfter) : options.longs === Number ? new $util.LongBits(message.offsetAfter.low >>> 0, message.offsetAfter.high >>> 0).toNumber(true) : message.offsetAfter;
        if (message.unreadOnly != null && message.hasOwnProperty("unreadOnly"))
            object.unreadOnly = message.unreadOnly;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.MCMethodGetChatsPreview.MCChatStatus[message.status] === undefined ? message.status : $root.MCMethodGetChatsPreview.MCChatStatus[message.status] : message.status;
        if (message.assignedToMeOnly != null && message.hasOwnProperty("assignedToMeOnly"))
            object.assignedToMeOnly = message.assignedToMeOnly;
        if (message.channels && message.channels.length) {
            object.channels = [];
            for (let j = 0; j < message.channels.length; ++j)
                object.channels[j] = $root.MCChannelWithAccess.toObject(message.channels[j], options);
        }
        if (message.assignedToOthersOnly != null && message.hasOwnProperty("assignedToOthersOnly"))
            object.assignedToOthersOnly = message.assignedToOthersOnly;
        return object;
    };

    /**
     * Converts this MCMethodGetChatsPreview to JSON.
     * @function toJSON
     * @memberof MCMethodGetChatsPreview
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetChatsPreview.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetChatsPreview
     * @function getTypeUrl
     * @memberof MCMethodGetChatsPreview
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetChatsPreview.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetChatsPreview";
    };

    /**
     * MCChatStatus enum.
     * @name MCMethodGetChatsPreview.MCChatStatus
     * @enum {number}
     * @property {number} S_ALL=0 S_ALL value
     * @property {number} S_OPEN=1 S_OPEN value
     * @property {number} S_IN_PROGRESS=2 S_IN_PROGRESS value
     * @property {number} S_RESOLVED=3 S_RESOLVED value
     */
    MCMethodGetChatsPreview.MCChatStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "S_ALL"] = 0;
        values[valuesById[1] = "S_OPEN"] = 1;
        values[valuesById[2] = "S_IN_PROGRESS"] = 2;
        values[valuesById[3] = "S_RESOLVED"] = 3;
        return values;
    })();

    return MCMethodGetChatsPreview;
})();

export const MCMethodGetChatsPreviewResponse = $root.MCMethodGetChatsPreviewResponse = (() => {

    /**
     * Properties of a MCMethodGetChatsPreviewResponse.
     * @exports IMCMethodGetChatsPreviewResponse
     * @interface IMCMethodGetChatsPreviewResponse
     * @property {MCMethodGetChatsPreviewResponse.Result|null} [result] MCMethodGetChatsPreviewResponse result
     * @property {Long|null} [totalUnreadCount] MCMethodGetChatsPreviewResponse totalUnreadCount
     * @property {Long|null} [total] MCMethodGetChatsPreviewResponse total
     * @property {Array.<IMCChatPreview>|null} [chats] MCMethodGetChatsPreviewResponse chats
     */

    /**
     * Constructs a new MCMethodGetChatsPreviewResponse.
     * @exports MCMethodGetChatsPreviewResponse
     * @classdesc Represents a MCMethodGetChatsPreviewResponse.
     * @implements IMCMethodGetChatsPreviewResponse
     * @constructor
     * @param {IMCMethodGetChatsPreviewResponse=} [properties] Properties to set
     */
    function MCMethodGetChatsPreviewResponse(properties) {
        this.chats = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetChatsPreviewResponse result.
     * @member {MCMethodGetChatsPreviewResponse.Result} result
     * @memberof MCMethodGetChatsPreviewResponse
     * @instance
     */
    MCMethodGetChatsPreviewResponse.prototype.result = 0;

    /**
     * MCMethodGetChatsPreviewResponse totalUnreadCount.
     * @member {Long} totalUnreadCount
     * @memberof MCMethodGetChatsPreviewResponse
     * @instance
     */
    MCMethodGetChatsPreviewResponse.prototype.totalUnreadCount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodGetChatsPreviewResponse total.
     * @member {Long} total
     * @memberof MCMethodGetChatsPreviewResponse
     * @instance
     */
    MCMethodGetChatsPreviewResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodGetChatsPreviewResponse chats.
     * @member {Array.<IMCChatPreview>} chats
     * @memberof MCMethodGetChatsPreviewResponse
     * @instance
     */
    MCMethodGetChatsPreviewResponse.prototype.chats = $util.emptyArray;

    /**
     * Creates a new MCMethodGetChatsPreviewResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodGetChatsPreviewResponse
     * @static
     * @param {IMCMethodGetChatsPreviewResponse=} [properties] Properties to set
     * @returns {MCMethodGetChatsPreviewResponse} MCMethodGetChatsPreviewResponse instance
     */
    MCMethodGetChatsPreviewResponse.create = function create(properties) {
        return new MCMethodGetChatsPreviewResponse(properties);
    };

    /**
     * Encodes the specified MCMethodGetChatsPreviewResponse message. Does not implicitly {@link MCMethodGetChatsPreviewResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetChatsPreviewResponse
     * @static
     * @param {IMCMethodGetChatsPreviewResponse} message MCMethodGetChatsPreviewResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetChatsPreviewResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.totalUnreadCount != null && Object.hasOwnProperty.call(message, "totalUnreadCount"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.totalUnreadCount);
        if (message.total != null && Object.hasOwnProperty.call(message, "total"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.total);
        if (message.chats != null && message.chats.length)
            for (let i = 0; i < message.chats.length; ++i)
                $root.MCChatPreview.encode(message.chats[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetChatsPreviewResponse message, length delimited. Does not implicitly {@link MCMethodGetChatsPreviewResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetChatsPreviewResponse
     * @static
     * @param {IMCMethodGetChatsPreviewResponse} message MCMethodGetChatsPreviewResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetChatsPreviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetChatsPreviewResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetChatsPreviewResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetChatsPreviewResponse} MCMethodGetChatsPreviewResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetChatsPreviewResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetChatsPreviewResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    message.totalUnreadCount = reader.uint64();
                    break;
                }
            case 3: {
                    message.total = reader.uint64();
                    break;
                }
            case 4: {
                    if (!(message.chats && message.chats.length))
                        message.chats = [];
                    message.chats.push($root.MCChatPreview.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetChatsPreviewResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetChatsPreviewResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetChatsPreviewResponse} MCMethodGetChatsPreviewResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetChatsPreviewResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetChatsPreviewResponse message.
     * @function verify
     * @memberof MCMethodGetChatsPreviewResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetChatsPreviewResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 10:
                break;
            }
        if (message.totalUnreadCount != null && message.hasOwnProperty("totalUnreadCount"))
            if (!$util.isInteger(message.totalUnreadCount) && !(message.totalUnreadCount && $util.isInteger(message.totalUnreadCount.low) && $util.isInteger(message.totalUnreadCount.high)))
                return "totalUnreadCount: integer|Long expected";
        if (message.total != null && message.hasOwnProperty("total"))
            if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                return "total: integer|Long expected";
        if (message.chats != null && message.hasOwnProperty("chats")) {
            if (!Array.isArray(message.chats))
                return "chats: array expected";
            for (let i = 0; i < message.chats.length; ++i) {
                let error = $root.MCChatPreview.verify(message.chats[i]);
                if (error)
                    return "chats." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCMethodGetChatsPreviewResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetChatsPreviewResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetChatsPreviewResponse} MCMethodGetChatsPreviewResponse
     */
    MCMethodGetChatsPreviewResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetChatsPreviewResponse)
            return object;
        let message = new $root.MCMethodGetChatsPreviewResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_OK":
        case 0:
            message.result = 0;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.totalUnreadCount != null)
            if ($util.Long)
                (message.totalUnreadCount = $util.Long.fromValue(object.totalUnreadCount)).unsigned = true;
            else if (typeof object.totalUnreadCount === "string")
                message.totalUnreadCount = parseInt(object.totalUnreadCount, 10);
            else if (typeof object.totalUnreadCount === "number")
                message.totalUnreadCount = object.totalUnreadCount;
            else if (typeof object.totalUnreadCount === "object")
                message.totalUnreadCount = new $util.LongBits(object.totalUnreadCount.low >>> 0, object.totalUnreadCount.high >>> 0).toNumber(true);
        if (object.total != null)
            if ($util.Long)
                (message.total = $util.Long.fromValue(object.total)).unsigned = true;
            else if (typeof object.total === "string")
                message.total = parseInt(object.total, 10);
            else if (typeof object.total === "number")
                message.total = object.total;
            else if (typeof object.total === "object")
                message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber(true);
        if (object.chats) {
            if (!Array.isArray(object.chats))
                throw TypeError(".MCMethodGetChatsPreviewResponse.chats: array expected");
            message.chats = [];
            for (let i = 0; i < object.chats.length; ++i) {
                if (typeof object.chats[i] !== "object")
                    throw TypeError(".MCMethodGetChatsPreviewResponse.chats: object expected");
                message.chats[i] = $root.MCChatPreview.fromObject(object.chats[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetChatsPreviewResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetChatsPreviewResponse
     * @static
     * @param {MCMethodGetChatsPreviewResponse} message MCMethodGetChatsPreviewResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetChatsPreviewResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.chats = [];
        if (options.defaults) {
            object.result = options.enums === String ? "R_OK" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.totalUnreadCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.totalUnreadCount = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.total = options.longs === String ? "0" : 0;
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodGetChatsPreviewResponse.Result[message.result] === undefined ? message.result : $root.MCMethodGetChatsPreviewResponse.Result[message.result] : message.result;
        if (message.totalUnreadCount != null && message.hasOwnProperty("totalUnreadCount"))
            if (typeof message.totalUnreadCount === "number")
                object.totalUnreadCount = options.longs === String ? String(message.totalUnreadCount) : message.totalUnreadCount;
            else
                object.totalUnreadCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalUnreadCount) : options.longs === Number ? new $util.LongBits(message.totalUnreadCount.low >>> 0, message.totalUnreadCount.high >>> 0).toNumber(true) : message.totalUnreadCount;
        if (message.total != null && message.hasOwnProperty("total"))
            if (typeof message.total === "number")
                object.total = options.longs === String ? String(message.total) : message.total;
            else
                object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber(true) : message.total;
        if (message.chats && message.chats.length) {
            object.chats = [];
            for (let j = 0; j < message.chats.length; ++j)
                object.chats[j] = $root.MCChatPreview.toObject(message.chats[j], options);
        }
        return object;
    };

    /**
     * Converts this MCMethodGetChatsPreviewResponse to JSON.
     * @function toJSON
     * @memberof MCMethodGetChatsPreviewResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetChatsPreviewResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetChatsPreviewResponse
     * @function getTypeUrl
     * @memberof MCMethodGetChatsPreviewResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetChatsPreviewResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetChatsPreviewResponse";
    };

    /**
     * Result enum.
     * @name MCMethodGetChatsPreviewResponse.Result
     * @enum {number}
     * @property {number} R_OK=0 R_OK value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodGetChatsPreviewResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_OK"] = 0;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodGetChatsPreviewResponse;
})();

export const MCMethodMarkRead = $root.MCMethodMarkRead = (() => {

    /**
     * Properties of a MCMethodMarkRead.
     * @exports IMCMethodMarkRead
     * @interface IMCMethodMarkRead
     * @property {IMCChannelWithAccess|null} [channel] MCMethodMarkRead channel
     * @property {Long|null} [chatID] MCMethodMarkRead chatID
     * @property {Long|null} [messageID] MCMethodMarkRead messageID
     * @property {Long|null} [operatorID] MCMethodMarkRead operatorID
     */

    /**
     * Constructs a new MCMethodMarkRead.
     * @exports MCMethodMarkRead
     * @classdesc Represents a MCMethodMarkRead.
     * @implements IMCMethodMarkRead
     * @constructor
     * @param {IMCMethodMarkRead=} [properties] Properties to set
     */
    function MCMethodMarkRead(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodMarkRead channel.
     * @member {IMCChannelWithAccess|null|undefined} channel
     * @memberof MCMethodMarkRead
     * @instance
     */
    MCMethodMarkRead.prototype.channel = null;

    /**
     * MCMethodMarkRead chatID.
     * @member {Long} chatID
     * @memberof MCMethodMarkRead
     * @instance
     */
    MCMethodMarkRead.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodMarkRead messageID.
     * @member {Long} messageID
     * @memberof MCMethodMarkRead
     * @instance
     */
    MCMethodMarkRead.prototype.messageID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodMarkRead operatorID.
     * @member {Long} operatorID
     * @memberof MCMethodMarkRead
     * @instance
     */
    MCMethodMarkRead.prototype.operatorID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCMethodMarkRead instance using the specified properties.
     * @function create
     * @memberof MCMethodMarkRead
     * @static
     * @param {IMCMethodMarkRead=} [properties] Properties to set
     * @returns {MCMethodMarkRead} MCMethodMarkRead instance
     */
    MCMethodMarkRead.create = function create(properties) {
        return new MCMethodMarkRead(properties);
    };

    /**
     * Encodes the specified MCMethodMarkRead message. Does not implicitly {@link MCMethodMarkRead.verify|verify} messages.
     * @function encode
     * @memberof MCMethodMarkRead
     * @static
     * @param {IMCMethodMarkRead} message MCMethodMarkRead message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodMarkRead.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
            $root.MCChannelWithAccess.encode(message.channel, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chatID);
        if (message.messageID != null && Object.hasOwnProperty.call(message, "messageID"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.messageID);
        if (message.operatorID != null && Object.hasOwnProperty.call(message, "operatorID"))
            writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.operatorID);
        return writer;
    };

    /**
     * Encodes the specified MCMethodMarkRead message, length delimited. Does not implicitly {@link MCMethodMarkRead.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodMarkRead
     * @static
     * @param {IMCMethodMarkRead} message MCMethodMarkRead message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodMarkRead.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodMarkRead message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodMarkRead
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodMarkRead} MCMethodMarkRead
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodMarkRead.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodMarkRead();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channel = $root.MCChannelWithAccess.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 3: {
                    message.messageID = reader.uint64();
                    break;
                }
            case 4: {
                    message.operatorID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodMarkRead message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodMarkRead
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodMarkRead} MCMethodMarkRead
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodMarkRead.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodMarkRead message.
     * @function verify
     * @memberof MCMethodMarkRead
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodMarkRead.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channel != null && message.hasOwnProperty("channel")) {
            let error = $root.MCChannelWithAccess.verify(message.channel);
            if (error)
                return "channel." + error;
        }
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.messageID != null && message.hasOwnProperty("messageID"))
            if (!$util.isInteger(message.messageID) && !(message.messageID && $util.isInteger(message.messageID.low) && $util.isInteger(message.messageID.high)))
                return "messageID: integer|Long expected";
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (!$util.isInteger(message.operatorID) && !(message.operatorID && $util.isInteger(message.operatorID.low) && $util.isInteger(message.operatorID.high)))
                return "operatorID: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCMethodMarkRead message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodMarkRead
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodMarkRead} MCMethodMarkRead
     */
    MCMethodMarkRead.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodMarkRead)
            return object;
        let message = new $root.MCMethodMarkRead();
        if (object.channel != null) {
            if (typeof object.channel !== "object")
                throw TypeError(".MCMethodMarkRead.channel: object expected");
            message.channel = $root.MCChannelWithAccess.fromObject(object.channel);
        }
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.messageID != null)
            if ($util.Long)
                (message.messageID = $util.Long.fromValue(object.messageID)).unsigned = true;
            else if (typeof object.messageID === "string")
                message.messageID = parseInt(object.messageID, 10);
            else if (typeof object.messageID === "number")
                message.messageID = object.messageID;
            else if (typeof object.messageID === "object")
                message.messageID = new $util.LongBits(object.messageID.low >>> 0, object.messageID.high >>> 0).toNumber(true);
        if (object.operatorID != null)
            if ($util.Long)
                (message.operatorID = $util.Long.fromValue(object.operatorID)).unsigned = true;
            else if (typeof object.operatorID === "string")
                message.operatorID = parseInt(object.operatorID, 10);
            else if (typeof object.operatorID === "number")
                message.operatorID = object.operatorID;
            else if (typeof object.operatorID === "object")
                message.operatorID = new $util.LongBits(object.operatorID.low >>> 0, object.operatorID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodMarkRead message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodMarkRead
     * @static
     * @param {MCMethodMarkRead} message MCMethodMarkRead
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodMarkRead.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.channel = null;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.messageID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.messageID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.operatorID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.operatorID = options.longs === String ? "0" : 0;
        }
        if (message.channel != null && message.hasOwnProperty("channel"))
            object.channel = $root.MCChannelWithAccess.toObject(message.channel, options);
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.messageID != null && message.hasOwnProperty("messageID"))
            if (typeof message.messageID === "number")
                object.messageID = options.longs === String ? String(message.messageID) : message.messageID;
            else
                object.messageID = options.longs === String ? $util.Long.prototype.toString.call(message.messageID) : options.longs === Number ? new $util.LongBits(message.messageID.low >>> 0, message.messageID.high >>> 0).toNumber(true) : message.messageID;
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (typeof message.operatorID === "number")
                object.operatorID = options.longs === String ? String(message.operatorID) : message.operatorID;
            else
                object.operatorID = options.longs === String ? $util.Long.prototype.toString.call(message.operatorID) : options.longs === Number ? new $util.LongBits(message.operatorID.low >>> 0, message.operatorID.high >>> 0).toNumber(true) : message.operatorID;
        return object;
    };

    /**
     * Converts this MCMethodMarkRead to JSON.
     * @function toJSON
     * @memberof MCMethodMarkRead
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodMarkRead.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodMarkRead
     * @function getTypeUrl
     * @memberof MCMethodMarkRead
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodMarkRead.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodMarkRead";
    };

    return MCMethodMarkRead;
})();

export const MCMethodMarkReadResponse = $root.MCMethodMarkReadResponse = (() => {

    /**
     * Properties of a MCMethodMarkReadResponse.
     * @exports IMCMethodMarkReadResponse
     * @interface IMCMethodMarkReadResponse
     * @property {MCMethodMarkReadResponse.Result|null} [result] MCMethodMarkReadResponse result
     * @property {boolean|null} [ok] MCMethodMarkReadResponse ok
     */

    /**
     * Constructs a new MCMethodMarkReadResponse.
     * @exports MCMethodMarkReadResponse
     * @classdesc Represents a MCMethodMarkReadResponse.
     * @implements IMCMethodMarkReadResponse
     * @constructor
     * @param {IMCMethodMarkReadResponse=} [properties] Properties to set
     */
    function MCMethodMarkReadResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodMarkReadResponse result.
     * @member {MCMethodMarkReadResponse.Result} result
     * @memberof MCMethodMarkReadResponse
     * @instance
     */
    MCMethodMarkReadResponse.prototype.result = 0;

    /**
     * MCMethodMarkReadResponse ok.
     * @member {boolean} ok
     * @memberof MCMethodMarkReadResponse
     * @instance
     */
    MCMethodMarkReadResponse.prototype.ok = false;

    /**
     * Creates a new MCMethodMarkReadResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodMarkReadResponse
     * @static
     * @param {IMCMethodMarkReadResponse=} [properties] Properties to set
     * @returns {MCMethodMarkReadResponse} MCMethodMarkReadResponse instance
     */
    MCMethodMarkReadResponse.create = function create(properties) {
        return new MCMethodMarkReadResponse(properties);
    };

    /**
     * Encodes the specified MCMethodMarkReadResponse message. Does not implicitly {@link MCMethodMarkReadResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodMarkReadResponse
     * @static
     * @param {IMCMethodMarkReadResponse} message MCMethodMarkReadResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodMarkReadResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.ok != null && Object.hasOwnProperty.call(message, "ok"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.ok);
        return writer;
    };

    /**
     * Encodes the specified MCMethodMarkReadResponse message, length delimited. Does not implicitly {@link MCMethodMarkReadResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodMarkReadResponse
     * @static
     * @param {IMCMethodMarkReadResponse} message MCMethodMarkReadResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodMarkReadResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodMarkReadResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodMarkReadResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodMarkReadResponse} MCMethodMarkReadResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodMarkReadResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodMarkReadResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    message.ok = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodMarkReadResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodMarkReadResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodMarkReadResponse} MCMethodMarkReadResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodMarkReadResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodMarkReadResponse message.
     * @function verify
     * @memberof MCMethodMarkReadResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodMarkReadResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 10:
                break;
            }
        if (message.ok != null && message.hasOwnProperty("ok"))
            if (typeof message.ok !== "boolean")
                return "ok: boolean expected";
        return null;
    };

    /**
     * Creates a MCMethodMarkReadResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodMarkReadResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodMarkReadResponse} MCMethodMarkReadResponse
     */
    MCMethodMarkReadResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodMarkReadResponse)
            return object;
        let message = new $root.MCMethodMarkReadResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_OK":
        case 0:
            message.result = 0;
            break;
        case "R_MessageNotExist":
        case 1:
            message.result = 1;
            break;
        case "R_ChatNotExist":
        case 2:
            message.result = 2;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.ok != null)
            message.ok = Boolean(object.ok);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodMarkReadResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodMarkReadResponse
     * @static
     * @param {MCMethodMarkReadResponse} message MCMethodMarkReadResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodMarkReadResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = options.enums === String ? "R_OK" : 0;
            object.ok = false;
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodMarkReadResponse.Result[message.result] === undefined ? message.result : $root.MCMethodMarkReadResponse.Result[message.result] : message.result;
        if (message.ok != null && message.hasOwnProperty("ok"))
            object.ok = message.ok;
        return object;
    };

    /**
     * Converts this MCMethodMarkReadResponse to JSON.
     * @function toJSON
     * @memberof MCMethodMarkReadResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodMarkReadResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodMarkReadResponse
     * @function getTypeUrl
     * @memberof MCMethodMarkReadResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodMarkReadResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodMarkReadResponse";
    };

    /**
     * Result enum.
     * @name MCMethodMarkReadResponse.Result
     * @enum {number}
     * @property {number} R_OK=0 R_OK value
     * @property {number} R_MessageNotExist=1 R_MessageNotExist value
     * @property {number} R_ChatNotExist=2 R_ChatNotExist value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodMarkReadResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_OK"] = 0;
        values[valuesById[1] = "R_MessageNotExist"] = 1;
        values[valuesById[2] = "R_ChatNotExist"] = 2;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodMarkReadResponse;
})();

export const MCCreateChannel = $root.MCCreateChannel = (() => {

    /**
     * Properties of a MCCreateChannel.
     * @exports IMCCreateChannel
     * @interface IMCCreateChannel
     * @property {entities.OzekonChannelType|null} [type] MCCreateChannel type
     * @property {Uint8Array|null} [workspaceID] MCCreateChannel workspaceID
     * @property {IMCChannelShownFields|null} [shownFields] MCCreateChannel shownFields
     * @property {ITelegramAuthorization|null} [telegramAuthFlow] MCCreateChannel telegramAuthFlow
     * @property {IWebWidgetCreate|null} [webWidgetFlow] MCCreateChannel webWidgetFlow
     * @property {IInstagramAuthorization|null} [instagramAuthFlow] MCCreateChannel instagramAuthFlow
     * @property {ITelegramBotAuthorization|null} [telegramBotAuthFlow] MCCreateChannel telegramBotAuthFlow
     */

    /**
     * Constructs a new MCCreateChannel.
     * @exports MCCreateChannel
     * @classdesc Represents a MCCreateChannel.
     * @implements IMCCreateChannel
     * @constructor
     * @param {IMCCreateChannel=} [properties] Properties to set
     */
    function MCCreateChannel(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCCreateChannel type.
     * @member {entities.OzekonChannelType} type
     * @memberof MCCreateChannel
     * @instance
     */
    MCCreateChannel.prototype.type = 0;

    /**
     * MCCreateChannel workspaceID.
     * @member {Uint8Array} workspaceID
     * @memberof MCCreateChannel
     * @instance
     */
    MCCreateChannel.prototype.workspaceID = $util.newBuffer([]);

    /**
     * MCCreateChannel shownFields.
     * @member {IMCChannelShownFields|null|undefined} shownFields
     * @memberof MCCreateChannel
     * @instance
     */
    MCCreateChannel.prototype.shownFields = null;

    /**
     * MCCreateChannel telegramAuthFlow.
     * @member {ITelegramAuthorization|null|undefined} telegramAuthFlow
     * @memberof MCCreateChannel
     * @instance
     */
    MCCreateChannel.prototype.telegramAuthFlow = null;

    /**
     * MCCreateChannel webWidgetFlow.
     * @member {IWebWidgetCreate|null|undefined} webWidgetFlow
     * @memberof MCCreateChannel
     * @instance
     */
    MCCreateChannel.prototype.webWidgetFlow = null;

    /**
     * MCCreateChannel instagramAuthFlow.
     * @member {IInstagramAuthorization|null|undefined} instagramAuthFlow
     * @memberof MCCreateChannel
     * @instance
     */
    MCCreateChannel.prototype.instagramAuthFlow = null;

    /**
     * MCCreateChannel telegramBotAuthFlow.
     * @member {ITelegramBotAuthorization|null|undefined} telegramBotAuthFlow
     * @memberof MCCreateChannel
     * @instance
     */
    MCCreateChannel.prototype.telegramBotAuthFlow = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * MCCreateChannel flow.
     * @member {"telegramAuthFlow"|"webWidgetFlow"|"instagramAuthFlow"|"telegramBotAuthFlow"|undefined} flow
     * @memberof MCCreateChannel
     * @instance
     */
    Object.defineProperty(MCCreateChannel.prototype, "flow", {
        get: $util.oneOfGetter($oneOfFields = ["telegramAuthFlow", "webWidgetFlow", "instagramAuthFlow", "telegramBotAuthFlow"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new MCCreateChannel instance using the specified properties.
     * @function create
     * @memberof MCCreateChannel
     * @static
     * @param {IMCCreateChannel=} [properties] Properties to set
     * @returns {MCCreateChannel} MCCreateChannel instance
     */
    MCCreateChannel.create = function create(properties) {
        return new MCCreateChannel(properties);
    };

    /**
     * Encodes the specified MCCreateChannel message. Does not implicitly {@link MCCreateChannel.verify|verify} messages.
     * @function encode
     * @memberof MCCreateChannel
     * @static
     * @param {IMCCreateChannel} message MCCreateChannel message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCCreateChannel.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
        if (message.shownFields != null && Object.hasOwnProperty.call(message, "shownFields"))
            $root.MCChannelShownFields.encode(message.shownFields, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.workspaceID != null && Object.hasOwnProperty.call(message, "workspaceID"))
            writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.workspaceID);
        if (message.telegramAuthFlow != null && Object.hasOwnProperty.call(message, "telegramAuthFlow"))
            $root.TelegramAuthorization.encode(message.telegramAuthFlow, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.webWidgetFlow != null && Object.hasOwnProperty.call(message, "webWidgetFlow"))
            $root.WebWidgetCreate.encode(message.webWidgetFlow, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.instagramAuthFlow != null && Object.hasOwnProperty.call(message, "instagramAuthFlow"))
            $root.InstagramAuthorization.encode(message.instagramAuthFlow, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.telegramBotAuthFlow != null && Object.hasOwnProperty.call(message, "telegramBotAuthFlow"))
            $root.TelegramBotAuthorization.encode(message.telegramBotAuthFlow, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCCreateChannel message, length delimited. Does not implicitly {@link MCCreateChannel.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCCreateChannel
     * @static
     * @param {IMCCreateChannel} message MCCreateChannel message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCCreateChannel.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCCreateChannel message from the specified reader or buffer.
     * @function decode
     * @memberof MCCreateChannel
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCCreateChannel} MCCreateChannel
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCCreateChannel.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCCreateChannel();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.type = reader.int32();
                    break;
                }
            case 3: {
                    message.workspaceID = reader.bytes();
                    break;
                }
            case 2: {
                    message.shownFields = $root.MCChannelShownFields.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.telegramAuthFlow = $root.TelegramAuthorization.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.webWidgetFlow = $root.WebWidgetCreate.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.instagramAuthFlow = $root.InstagramAuthorization.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.telegramBotAuthFlow = $root.TelegramBotAuthorization.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCCreateChannel message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCCreateChannel
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCCreateChannel} MCCreateChannel
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCCreateChannel.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCCreateChannel message.
     * @function verify
     * @memberof MCCreateChannel
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCCreateChannel.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message.workspaceID != null && message.hasOwnProperty("workspaceID"))
            if (!(message.workspaceID && typeof message.workspaceID.length === "number" || $util.isString(message.workspaceID)))
                return "workspaceID: buffer expected";
        if (message.shownFields != null && message.hasOwnProperty("shownFields")) {
            let error = $root.MCChannelShownFields.verify(message.shownFields);
            if (error)
                return "shownFields." + error;
        }
        if (message.telegramAuthFlow != null && message.hasOwnProperty("telegramAuthFlow")) {
            properties.flow = 1;
            {
                let error = $root.TelegramAuthorization.verify(message.telegramAuthFlow);
                if (error)
                    return "telegramAuthFlow." + error;
            }
        }
        if (message.webWidgetFlow != null && message.hasOwnProperty("webWidgetFlow")) {
            if (properties.flow === 1)
                return "flow: multiple values";
            properties.flow = 1;
            {
                let error = $root.WebWidgetCreate.verify(message.webWidgetFlow);
                if (error)
                    return "webWidgetFlow." + error;
            }
        }
        if (message.instagramAuthFlow != null && message.hasOwnProperty("instagramAuthFlow")) {
            if (properties.flow === 1)
                return "flow: multiple values";
            properties.flow = 1;
            {
                let error = $root.InstagramAuthorization.verify(message.instagramAuthFlow);
                if (error)
                    return "instagramAuthFlow." + error;
            }
        }
        if (message.telegramBotAuthFlow != null && message.hasOwnProperty("telegramBotAuthFlow")) {
            if (properties.flow === 1)
                return "flow: multiple values";
            properties.flow = 1;
            {
                let error = $root.TelegramBotAuthorization.verify(message.telegramBotAuthFlow);
                if (error)
                    return "telegramBotAuthFlow." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCCreateChannel message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCCreateChannel
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCCreateChannel} MCCreateChannel
     */
    MCCreateChannel.fromObject = function fromObject(object) {
        if (object instanceof $root.MCCreateChannel)
            return object;
        let message = new $root.MCCreateChannel();
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "OIT_NONE":
        case 0:
            message.type = 0;
            break;
        case "OIT_TELEGRAM":
        case 1:
            message.type = 1;
            break;
        case "OIT_WEB_WIDGET":
        case 2:
            message.type = 2;
            break;
        case "OIT_INSTAGRAM":
        case 3:
            message.type = 3;
            break;
        case "OIT_TELEGRAM_BOT":
        case 4:
            message.type = 4;
            break;
        }
        if (object.workspaceID != null)
            if (typeof object.workspaceID === "string")
                $util.base64.decode(object.workspaceID, message.workspaceID = $util.newBuffer($util.base64.length(object.workspaceID)), 0);
            else if (object.workspaceID.length >= 0)
                message.workspaceID = object.workspaceID;
        if (object.shownFields != null) {
            if (typeof object.shownFields !== "object")
                throw TypeError(".MCCreateChannel.shownFields: object expected");
            message.shownFields = $root.MCChannelShownFields.fromObject(object.shownFields);
        }
        if (object.telegramAuthFlow != null) {
            if (typeof object.telegramAuthFlow !== "object")
                throw TypeError(".MCCreateChannel.telegramAuthFlow: object expected");
            message.telegramAuthFlow = $root.TelegramAuthorization.fromObject(object.telegramAuthFlow);
        }
        if (object.webWidgetFlow != null) {
            if (typeof object.webWidgetFlow !== "object")
                throw TypeError(".MCCreateChannel.webWidgetFlow: object expected");
            message.webWidgetFlow = $root.WebWidgetCreate.fromObject(object.webWidgetFlow);
        }
        if (object.instagramAuthFlow != null) {
            if (typeof object.instagramAuthFlow !== "object")
                throw TypeError(".MCCreateChannel.instagramAuthFlow: object expected");
            message.instagramAuthFlow = $root.InstagramAuthorization.fromObject(object.instagramAuthFlow);
        }
        if (object.telegramBotAuthFlow != null) {
            if (typeof object.telegramBotAuthFlow !== "object")
                throw TypeError(".MCCreateChannel.telegramBotAuthFlow: object expected");
            message.telegramBotAuthFlow = $root.TelegramBotAuthorization.fromObject(object.telegramBotAuthFlow);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCCreateChannel message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCCreateChannel
     * @static
     * @param {MCCreateChannel} message MCCreateChannel
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCCreateChannel.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "OIT_NONE" : 0;
            object.shownFields = null;
            if (options.bytes === String)
                object.workspaceID = "";
            else {
                object.workspaceID = [];
                if (options.bytes !== Array)
                    object.workspaceID = $util.newBuffer(object.workspaceID);
            }
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.entities.OzekonChannelType[message.type] === undefined ? message.type : $root.entities.OzekonChannelType[message.type] : message.type;
        if (message.shownFields != null && message.hasOwnProperty("shownFields"))
            object.shownFields = $root.MCChannelShownFields.toObject(message.shownFields, options);
        if (message.workspaceID != null && message.hasOwnProperty("workspaceID"))
            object.workspaceID = options.bytes === String ? $util.base64.encode(message.workspaceID, 0, message.workspaceID.length) : options.bytes === Array ? Array.prototype.slice.call(message.workspaceID) : message.workspaceID;
        if (message.telegramAuthFlow != null && message.hasOwnProperty("telegramAuthFlow")) {
            object.telegramAuthFlow = $root.TelegramAuthorization.toObject(message.telegramAuthFlow, options);
            if (options.oneofs)
                object.flow = "telegramAuthFlow";
        }
        if (message.webWidgetFlow != null && message.hasOwnProperty("webWidgetFlow")) {
            object.webWidgetFlow = $root.WebWidgetCreate.toObject(message.webWidgetFlow, options);
            if (options.oneofs)
                object.flow = "webWidgetFlow";
        }
        if (message.instagramAuthFlow != null && message.hasOwnProperty("instagramAuthFlow")) {
            object.instagramAuthFlow = $root.InstagramAuthorization.toObject(message.instagramAuthFlow, options);
            if (options.oneofs)
                object.flow = "instagramAuthFlow";
        }
        if (message.telegramBotAuthFlow != null && message.hasOwnProperty("telegramBotAuthFlow")) {
            object.telegramBotAuthFlow = $root.TelegramBotAuthorization.toObject(message.telegramBotAuthFlow, options);
            if (options.oneofs)
                object.flow = "telegramBotAuthFlow";
        }
        return object;
    };

    /**
     * Converts this MCCreateChannel to JSON.
     * @function toJSON
     * @memberof MCCreateChannel
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCCreateChannel.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCCreateChannel
     * @function getTypeUrl
     * @memberof MCCreateChannel
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCCreateChannel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCCreateChannel";
    };

    return MCCreateChannel;
})();

export const MCCreateChannelResponse = $root.MCCreateChannelResponse = (() => {

    /**
     * Properties of a MCCreateChannelResponse.
     * @exports IMCCreateChannelResponse
     * @interface IMCCreateChannelResponse
     * @property {MCCreateChannelResponse.Result|null} [result] MCCreateChannelResponse result
     * @property {Uint8Array|null} [channelID] MCCreateChannelResponse channelID
     * @property {ITelegramAuthorizationResponse|null} [telegramAuthFlow] MCCreateChannelResponse telegramAuthFlow
     * @property {IWebWidgetCreateResponse|null} [webWidgetFlow] MCCreateChannelResponse webWidgetFlow
     * @property {IInstagramAuthorizationResponse|null} [instagramAuthFlow] MCCreateChannelResponse instagramAuthFlow
     * @property {ITelegramBotAuthorizationResponse|null} [telegramBotAuthFlow] MCCreateChannelResponse telegramBotAuthFlow
     */

    /**
     * Constructs a new MCCreateChannelResponse.
     * @exports MCCreateChannelResponse
     * @classdesc Represents a MCCreateChannelResponse.
     * @implements IMCCreateChannelResponse
     * @constructor
     * @param {IMCCreateChannelResponse=} [properties] Properties to set
     */
    function MCCreateChannelResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCCreateChannelResponse result.
     * @member {MCCreateChannelResponse.Result} result
     * @memberof MCCreateChannelResponse
     * @instance
     */
    MCCreateChannelResponse.prototype.result = 0;

    /**
     * MCCreateChannelResponse channelID.
     * @member {Uint8Array} channelID
     * @memberof MCCreateChannelResponse
     * @instance
     */
    MCCreateChannelResponse.prototype.channelID = $util.newBuffer([]);

    /**
     * MCCreateChannelResponse telegramAuthFlow.
     * @member {ITelegramAuthorizationResponse|null|undefined} telegramAuthFlow
     * @memberof MCCreateChannelResponse
     * @instance
     */
    MCCreateChannelResponse.prototype.telegramAuthFlow = null;

    /**
     * MCCreateChannelResponse webWidgetFlow.
     * @member {IWebWidgetCreateResponse|null|undefined} webWidgetFlow
     * @memberof MCCreateChannelResponse
     * @instance
     */
    MCCreateChannelResponse.prototype.webWidgetFlow = null;

    /**
     * MCCreateChannelResponse instagramAuthFlow.
     * @member {IInstagramAuthorizationResponse|null|undefined} instagramAuthFlow
     * @memberof MCCreateChannelResponse
     * @instance
     */
    MCCreateChannelResponse.prototype.instagramAuthFlow = null;

    /**
     * MCCreateChannelResponse telegramBotAuthFlow.
     * @member {ITelegramBotAuthorizationResponse|null|undefined} telegramBotAuthFlow
     * @memberof MCCreateChannelResponse
     * @instance
     */
    MCCreateChannelResponse.prototype.telegramBotAuthFlow = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * MCCreateChannelResponse flow.
     * @member {"telegramAuthFlow"|"webWidgetFlow"|"instagramAuthFlow"|"telegramBotAuthFlow"|undefined} flow
     * @memberof MCCreateChannelResponse
     * @instance
     */
    Object.defineProperty(MCCreateChannelResponse.prototype, "flow", {
        get: $util.oneOfGetter($oneOfFields = ["telegramAuthFlow", "webWidgetFlow", "instagramAuthFlow", "telegramBotAuthFlow"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new MCCreateChannelResponse instance using the specified properties.
     * @function create
     * @memberof MCCreateChannelResponse
     * @static
     * @param {IMCCreateChannelResponse=} [properties] Properties to set
     * @returns {MCCreateChannelResponse} MCCreateChannelResponse instance
     */
    MCCreateChannelResponse.create = function create(properties) {
        return new MCCreateChannelResponse(properties);
    };

    /**
     * Encodes the specified MCCreateChannelResponse message. Does not implicitly {@link MCCreateChannelResponse.verify|verify} messages.
     * @function encode
     * @memberof MCCreateChannelResponse
     * @static
     * @param {IMCCreateChannelResponse} message MCCreateChannelResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCCreateChannelResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.channelID);
        if (message.telegramAuthFlow != null && Object.hasOwnProperty.call(message, "telegramAuthFlow"))
            $root.TelegramAuthorizationResponse.encode(message.telegramAuthFlow, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.webWidgetFlow != null && Object.hasOwnProperty.call(message, "webWidgetFlow"))
            $root.WebWidgetCreateResponse.encode(message.webWidgetFlow, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.instagramAuthFlow != null && Object.hasOwnProperty.call(message, "instagramAuthFlow"))
            $root.InstagramAuthorizationResponse.encode(message.instagramAuthFlow, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.telegramBotAuthFlow != null && Object.hasOwnProperty.call(message, "telegramBotAuthFlow"))
            $root.TelegramBotAuthorizationResponse.encode(message.telegramBotAuthFlow, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCCreateChannelResponse message, length delimited. Does not implicitly {@link MCCreateChannelResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCCreateChannelResponse
     * @static
     * @param {IMCCreateChannelResponse} message MCCreateChannelResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCCreateChannelResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCCreateChannelResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCCreateChannelResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCCreateChannelResponse} MCCreateChannelResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCCreateChannelResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCCreateChannelResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 10: {
                    message.telegramAuthFlow = $root.TelegramAuthorizationResponse.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.webWidgetFlow = $root.WebWidgetCreateResponse.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.instagramAuthFlow = $root.InstagramAuthorizationResponse.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.telegramBotAuthFlow = $root.TelegramBotAuthorizationResponse.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCCreateChannelResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCCreateChannelResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCCreateChannelResponse} MCCreateChannelResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCCreateChannelResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCCreateChannelResponse message.
     * @function verify
     * @memberof MCCreateChannelResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCCreateChannelResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 10:
                break;
            }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.telegramAuthFlow != null && message.hasOwnProperty("telegramAuthFlow")) {
            properties.flow = 1;
            {
                let error = $root.TelegramAuthorizationResponse.verify(message.telegramAuthFlow);
                if (error)
                    return "telegramAuthFlow." + error;
            }
        }
        if (message.webWidgetFlow != null && message.hasOwnProperty("webWidgetFlow")) {
            if (properties.flow === 1)
                return "flow: multiple values";
            properties.flow = 1;
            {
                let error = $root.WebWidgetCreateResponse.verify(message.webWidgetFlow);
                if (error)
                    return "webWidgetFlow." + error;
            }
        }
        if (message.instagramAuthFlow != null && message.hasOwnProperty("instagramAuthFlow")) {
            if (properties.flow === 1)
                return "flow: multiple values";
            properties.flow = 1;
            {
                let error = $root.InstagramAuthorizationResponse.verify(message.instagramAuthFlow);
                if (error)
                    return "instagramAuthFlow." + error;
            }
        }
        if (message.telegramBotAuthFlow != null && message.hasOwnProperty("telegramBotAuthFlow")) {
            if (properties.flow === 1)
                return "flow: multiple values";
            properties.flow = 1;
            {
                let error = $root.TelegramBotAuthorizationResponse.verify(message.telegramBotAuthFlow);
                if (error)
                    return "telegramBotAuthFlow." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCCreateChannelResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCCreateChannelResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCCreateChannelResponse} MCCreateChannelResponse
     */
    MCCreateChannelResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCCreateChannelResponse)
            return object;
        let message = new $root.MCCreateChannelResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_OK":
        case 0:
            message.result = 0;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.telegramAuthFlow != null) {
            if (typeof object.telegramAuthFlow !== "object")
                throw TypeError(".MCCreateChannelResponse.telegramAuthFlow: object expected");
            message.telegramAuthFlow = $root.TelegramAuthorizationResponse.fromObject(object.telegramAuthFlow);
        }
        if (object.webWidgetFlow != null) {
            if (typeof object.webWidgetFlow !== "object")
                throw TypeError(".MCCreateChannelResponse.webWidgetFlow: object expected");
            message.webWidgetFlow = $root.WebWidgetCreateResponse.fromObject(object.webWidgetFlow);
        }
        if (object.instagramAuthFlow != null) {
            if (typeof object.instagramAuthFlow !== "object")
                throw TypeError(".MCCreateChannelResponse.instagramAuthFlow: object expected");
            message.instagramAuthFlow = $root.InstagramAuthorizationResponse.fromObject(object.instagramAuthFlow);
        }
        if (object.telegramBotAuthFlow != null) {
            if (typeof object.telegramBotAuthFlow !== "object")
                throw TypeError(".MCCreateChannelResponse.telegramBotAuthFlow: object expected");
            message.telegramBotAuthFlow = $root.TelegramBotAuthorizationResponse.fromObject(object.telegramBotAuthFlow);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCCreateChannelResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCCreateChannelResponse
     * @static
     * @param {MCCreateChannelResponse} message MCCreateChannelResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCCreateChannelResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = options.enums === String ? "R_OK" : 0;
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCCreateChannelResponse.Result[message.result] === undefined ? message.result : $root.MCCreateChannelResponse.Result[message.result] : message.result;
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.telegramAuthFlow != null && message.hasOwnProperty("telegramAuthFlow")) {
            object.telegramAuthFlow = $root.TelegramAuthorizationResponse.toObject(message.telegramAuthFlow, options);
            if (options.oneofs)
                object.flow = "telegramAuthFlow";
        }
        if (message.webWidgetFlow != null && message.hasOwnProperty("webWidgetFlow")) {
            object.webWidgetFlow = $root.WebWidgetCreateResponse.toObject(message.webWidgetFlow, options);
            if (options.oneofs)
                object.flow = "webWidgetFlow";
        }
        if (message.instagramAuthFlow != null && message.hasOwnProperty("instagramAuthFlow")) {
            object.instagramAuthFlow = $root.InstagramAuthorizationResponse.toObject(message.instagramAuthFlow, options);
            if (options.oneofs)
                object.flow = "instagramAuthFlow";
        }
        if (message.telegramBotAuthFlow != null && message.hasOwnProperty("telegramBotAuthFlow")) {
            object.telegramBotAuthFlow = $root.TelegramBotAuthorizationResponse.toObject(message.telegramBotAuthFlow, options);
            if (options.oneofs)
                object.flow = "telegramBotAuthFlow";
        }
        return object;
    };

    /**
     * Converts this MCCreateChannelResponse to JSON.
     * @function toJSON
     * @memberof MCCreateChannelResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCCreateChannelResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCCreateChannelResponse
     * @function getTypeUrl
     * @memberof MCCreateChannelResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCCreateChannelResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCCreateChannelResponse";
    };

    /**
     * Result enum.
     * @name MCCreateChannelResponse.Result
     * @enum {number}
     * @property {number} R_OK=0 R_OK value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCCreateChannelResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_OK"] = 0;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCCreateChannelResponse;
})();

export const MCChannelAuthFlow = $root.MCChannelAuthFlow = (() => {

    /**
     * Properties of a MCChannelAuthFlow.
     * @exports IMCChannelAuthFlow
     * @interface IMCChannelAuthFlow
     * @property {Uint8Array|null} [channelID] MCChannelAuthFlow channelID
     * @property {boolean|null} [resetAuthFlow] MCChannelAuthFlow resetAuthFlow
     * @property {ITelegramAuthorization|null} [telegramAuthFlow] MCChannelAuthFlow telegramAuthFlow
     */

    /**
     * Constructs a new MCChannelAuthFlow.
     * @exports MCChannelAuthFlow
     * @classdesc Represents a MCChannelAuthFlow.
     * @implements IMCChannelAuthFlow
     * @constructor
     * @param {IMCChannelAuthFlow=} [properties] Properties to set
     */
    function MCChannelAuthFlow(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCChannelAuthFlow channelID.
     * @member {Uint8Array} channelID
     * @memberof MCChannelAuthFlow
     * @instance
     */
    MCChannelAuthFlow.prototype.channelID = $util.newBuffer([]);

    /**
     * MCChannelAuthFlow resetAuthFlow.
     * @member {boolean} resetAuthFlow
     * @memberof MCChannelAuthFlow
     * @instance
     */
    MCChannelAuthFlow.prototype.resetAuthFlow = false;

    /**
     * MCChannelAuthFlow telegramAuthFlow.
     * @member {ITelegramAuthorization|null|undefined} telegramAuthFlow
     * @memberof MCChannelAuthFlow
     * @instance
     */
    MCChannelAuthFlow.prototype.telegramAuthFlow = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * MCChannelAuthFlow flow.
     * @member {"telegramAuthFlow"|undefined} flow
     * @memberof MCChannelAuthFlow
     * @instance
     */
    Object.defineProperty(MCChannelAuthFlow.prototype, "flow", {
        get: $util.oneOfGetter($oneOfFields = ["telegramAuthFlow"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new MCChannelAuthFlow instance using the specified properties.
     * @function create
     * @memberof MCChannelAuthFlow
     * @static
     * @param {IMCChannelAuthFlow=} [properties] Properties to set
     * @returns {MCChannelAuthFlow} MCChannelAuthFlow instance
     */
    MCChannelAuthFlow.create = function create(properties) {
        return new MCChannelAuthFlow(properties);
    };

    /**
     * Encodes the specified MCChannelAuthFlow message. Does not implicitly {@link MCChannelAuthFlow.verify|verify} messages.
     * @function encode
     * @memberof MCChannelAuthFlow
     * @static
     * @param {IMCChannelAuthFlow} message MCChannelAuthFlow message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChannelAuthFlow.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.telegramAuthFlow != null && Object.hasOwnProperty.call(message, "telegramAuthFlow"))
            $root.TelegramAuthorization.encode(message.telegramAuthFlow, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.resetAuthFlow != null && Object.hasOwnProperty.call(message, "resetAuthFlow"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.resetAuthFlow);
        return writer;
    };

    /**
     * Encodes the specified MCChannelAuthFlow message, length delimited. Does not implicitly {@link MCChannelAuthFlow.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCChannelAuthFlow
     * @static
     * @param {IMCChannelAuthFlow} message MCChannelAuthFlow message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChannelAuthFlow.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCChannelAuthFlow message from the specified reader or buffer.
     * @function decode
     * @memberof MCChannelAuthFlow
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCChannelAuthFlow} MCChannelAuthFlow
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChannelAuthFlow.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCChannelAuthFlow();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 3: {
                    message.resetAuthFlow = reader.bool();
                    break;
                }
            case 2: {
                    message.telegramAuthFlow = $root.TelegramAuthorization.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCChannelAuthFlow message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCChannelAuthFlow
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCChannelAuthFlow} MCChannelAuthFlow
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChannelAuthFlow.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCChannelAuthFlow message.
     * @function verify
     * @memberof MCChannelAuthFlow
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCChannelAuthFlow.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.resetAuthFlow != null && message.hasOwnProperty("resetAuthFlow"))
            if (typeof message.resetAuthFlow !== "boolean")
                return "resetAuthFlow: boolean expected";
        if (message.telegramAuthFlow != null && message.hasOwnProperty("telegramAuthFlow")) {
            properties.flow = 1;
            {
                let error = $root.TelegramAuthorization.verify(message.telegramAuthFlow);
                if (error)
                    return "telegramAuthFlow." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCChannelAuthFlow message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCChannelAuthFlow
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCChannelAuthFlow} MCChannelAuthFlow
     */
    MCChannelAuthFlow.fromObject = function fromObject(object) {
        if (object instanceof $root.MCChannelAuthFlow)
            return object;
        let message = new $root.MCChannelAuthFlow();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.resetAuthFlow != null)
            message.resetAuthFlow = Boolean(object.resetAuthFlow);
        if (object.telegramAuthFlow != null) {
            if (typeof object.telegramAuthFlow !== "object")
                throw TypeError(".MCChannelAuthFlow.telegramAuthFlow: object expected");
            message.telegramAuthFlow = $root.TelegramAuthorization.fromObject(object.telegramAuthFlow);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCChannelAuthFlow message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCChannelAuthFlow
     * @static
     * @param {MCChannelAuthFlow} message MCChannelAuthFlow
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCChannelAuthFlow.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            object.resetAuthFlow = false;
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.telegramAuthFlow != null && message.hasOwnProperty("telegramAuthFlow")) {
            object.telegramAuthFlow = $root.TelegramAuthorization.toObject(message.telegramAuthFlow, options);
            if (options.oneofs)
                object.flow = "telegramAuthFlow";
        }
        if (message.resetAuthFlow != null && message.hasOwnProperty("resetAuthFlow"))
            object.resetAuthFlow = message.resetAuthFlow;
        return object;
    };

    /**
     * Converts this MCChannelAuthFlow to JSON.
     * @function toJSON
     * @memberof MCChannelAuthFlow
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCChannelAuthFlow.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCChannelAuthFlow
     * @function getTypeUrl
     * @memberof MCChannelAuthFlow
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCChannelAuthFlow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCChannelAuthFlow";
    };

    return MCChannelAuthFlow;
})();

export const MCChannelAuthFlowResponse = $root.MCChannelAuthFlowResponse = (() => {

    /**
     * Properties of a MCChannelAuthFlowResponse.
     * @exports IMCChannelAuthFlowResponse
     * @interface IMCChannelAuthFlowResponse
     * @property {MCChannelAuthFlowResponse.Result|null} [result] MCChannelAuthFlowResponse result
     * @property {Uint8Array|null} [channelID] MCChannelAuthFlowResponse channelID
     * @property {ITelegramAuthorizationResponse|null} [telegramAuthFlowResponse] MCChannelAuthFlowResponse telegramAuthFlowResponse
     */

    /**
     * Constructs a new MCChannelAuthFlowResponse.
     * @exports MCChannelAuthFlowResponse
     * @classdesc Represents a MCChannelAuthFlowResponse.
     * @implements IMCChannelAuthFlowResponse
     * @constructor
     * @param {IMCChannelAuthFlowResponse=} [properties] Properties to set
     */
    function MCChannelAuthFlowResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCChannelAuthFlowResponse result.
     * @member {MCChannelAuthFlowResponse.Result} result
     * @memberof MCChannelAuthFlowResponse
     * @instance
     */
    MCChannelAuthFlowResponse.prototype.result = 0;

    /**
     * MCChannelAuthFlowResponse channelID.
     * @member {Uint8Array} channelID
     * @memberof MCChannelAuthFlowResponse
     * @instance
     */
    MCChannelAuthFlowResponse.prototype.channelID = $util.newBuffer([]);

    /**
     * MCChannelAuthFlowResponse telegramAuthFlowResponse.
     * @member {ITelegramAuthorizationResponse|null|undefined} telegramAuthFlowResponse
     * @memberof MCChannelAuthFlowResponse
     * @instance
     */
    MCChannelAuthFlowResponse.prototype.telegramAuthFlowResponse = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * MCChannelAuthFlowResponse flow.
     * @member {"telegramAuthFlowResponse"|undefined} flow
     * @memberof MCChannelAuthFlowResponse
     * @instance
     */
    Object.defineProperty(MCChannelAuthFlowResponse.prototype, "flow", {
        get: $util.oneOfGetter($oneOfFields = ["telegramAuthFlowResponse"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new MCChannelAuthFlowResponse instance using the specified properties.
     * @function create
     * @memberof MCChannelAuthFlowResponse
     * @static
     * @param {IMCChannelAuthFlowResponse=} [properties] Properties to set
     * @returns {MCChannelAuthFlowResponse} MCChannelAuthFlowResponse instance
     */
    MCChannelAuthFlowResponse.create = function create(properties) {
        return new MCChannelAuthFlowResponse(properties);
    };

    /**
     * Encodes the specified MCChannelAuthFlowResponse message. Does not implicitly {@link MCChannelAuthFlowResponse.verify|verify} messages.
     * @function encode
     * @memberof MCChannelAuthFlowResponse
     * @static
     * @param {IMCChannelAuthFlowResponse} message MCChannelAuthFlowResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChannelAuthFlowResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.channelID);
        if (message.telegramAuthFlowResponse != null && Object.hasOwnProperty.call(message, "telegramAuthFlowResponse"))
            $root.TelegramAuthorizationResponse.encode(message.telegramAuthFlowResponse, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCChannelAuthFlowResponse message, length delimited. Does not implicitly {@link MCChannelAuthFlowResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCChannelAuthFlowResponse
     * @static
     * @param {IMCChannelAuthFlowResponse} message MCChannelAuthFlowResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChannelAuthFlowResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCChannelAuthFlowResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCChannelAuthFlowResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCChannelAuthFlowResponse} MCChannelAuthFlowResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChannelAuthFlowResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCChannelAuthFlowResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 3: {
                    message.telegramAuthFlowResponse = $root.TelegramAuthorizationResponse.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCChannelAuthFlowResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCChannelAuthFlowResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCChannelAuthFlowResponse} MCChannelAuthFlowResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChannelAuthFlowResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCChannelAuthFlowResponse message.
     * @function verify
     * @memberof MCChannelAuthFlowResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCChannelAuthFlowResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 10:
                break;
            }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.telegramAuthFlowResponse != null && message.hasOwnProperty("telegramAuthFlowResponse")) {
            properties.flow = 1;
            {
                let error = $root.TelegramAuthorizationResponse.verify(message.telegramAuthFlowResponse);
                if (error)
                    return "telegramAuthFlowResponse." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCChannelAuthFlowResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCChannelAuthFlowResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCChannelAuthFlowResponse} MCChannelAuthFlowResponse
     */
    MCChannelAuthFlowResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCChannelAuthFlowResponse)
            return object;
        let message = new $root.MCChannelAuthFlowResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_OK":
        case 0:
            message.result = 0;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 1:
            message.result = 1;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.telegramAuthFlowResponse != null) {
            if (typeof object.telegramAuthFlowResponse !== "object")
                throw TypeError(".MCChannelAuthFlowResponse.telegramAuthFlowResponse: object expected");
            message.telegramAuthFlowResponse = $root.TelegramAuthorizationResponse.fromObject(object.telegramAuthFlowResponse);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCChannelAuthFlowResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCChannelAuthFlowResponse
     * @static
     * @param {MCChannelAuthFlowResponse} message MCChannelAuthFlowResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCChannelAuthFlowResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = options.enums === String ? "R_OK" : 0;
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCChannelAuthFlowResponse.Result[message.result] === undefined ? message.result : $root.MCChannelAuthFlowResponse.Result[message.result] : message.result;
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.telegramAuthFlowResponse != null && message.hasOwnProperty("telegramAuthFlowResponse")) {
            object.telegramAuthFlowResponse = $root.TelegramAuthorizationResponse.toObject(message.telegramAuthFlowResponse, options);
            if (options.oneofs)
                object.flow = "telegramAuthFlowResponse";
        }
        return object;
    };

    /**
     * Converts this MCChannelAuthFlowResponse to JSON.
     * @function toJSON
     * @memberof MCChannelAuthFlowResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCChannelAuthFlowResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCChannelAuthFlowResponse
     * @function getTypeUrl
     * @memberof MCChannelAuthFlowResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCChannelAuthFlowResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCChannelAuthFlowResponse";
    };

    /**
     * Result enum.
     * @name MCChannelAuthFlowResponse.Result
     * @enum {number}
     * @property {number} R_OK=0 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=1 R_CHANNEL_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCChannelAuthFlowResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_OK"] = 0;
        values[valuesById[1] = "R_CHANNEL_NOT_FOUND"] = 1;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCChannelAuthFlowResponse;
})();

export const MCGetChannelResponse = $root.MCGetChannelResponse = (() => {

    /**
     * Properties of a MCGetChannelResponse.
     * @exports IMCGetChannelResponse
     * @interface IMCGetChannelResponse
     * @property {MCGetChannelResponse.Result|null} [result] MCGetChannelResponse result
     * @property {IMCChannel|null} [channel] MCGetChannelResponse channel
     * @property {Long|null} [version] MCGetChannelResponse version
     * @property {Long|null} [unreadCount] MCGetChannelResponse unreadCount
     */

    /**
     * Constructs a new MCGetChannelResponse.
     * @exports MCGetChannelResponse
     * @classdesc Represents a MCGetChannelResponse.
     * @implements IMCGetChannelResponse
     * @constructor
     * @param {IMCGetChannelResponse=} [properties] Properties to set
     */
    function MCGetChannelResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCGetChannelResponse result.
     * @member {MCGetChannelResponse.Result} result
     * @memberof MCGetChannelResponse
     * @instance
     */
    MCGetChannelResponse.prototype.result = 0;

    /**
     * MCGetChannelResponse channel.
     * @member {IMCChannel|null|undefined} channel
     * @memberof MCGetChannelResponse
     * @instance
     */
    MCGetChannelResponse.prototype.channel = null;

    /**
     * MCGetChannelResponse version.
     * @member {Long} version
     * @memberof MCGetChannelResponse
     * @instance
     */
    MCGetChannelResponse.prototype.version = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCGetChannelResponse unreadCount.
     * @member {Long} unreadCount
     * @memberof MCGetChannelResponse
     * @instance
     */
    MCGetChannelResponse.prototype.unreadCount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCGetChannelResponse instance using the specified properties.
     * @function create
     * @memberof MCGetChannelResponse
     * @static
     * @param {IMCGetChannelResponse=} [properties] Properties to set
     * @returns {MCGetChannelResponse} MCGetChannelResponse instance
     */
    MCGetChannelResponse.create = function create(properties) {
        return new MCGetChannelResponse(properties);
    };

    /**
     * Encodes the specified MCGetChannelResponse message. Does not implicitly {@link MCGetChannelResponse.verify|verify} messages.
     * @function encode
     * @memberof MCGetChannelResponse
     * @static
     * @param {IMCGetChannelResponse} message MCGetChannelResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCGetChannelResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.unreadCount != null && Object.hasOwnProperty.call(message, "unreadCount"))
            writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.unreadCount);
        if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
            $root.MCChannel.encode(message.channel, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.version != null && Object.hasOwnProperty.call(message, "version"))
            writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.version);
        return writer;
    };

    /**
     * Encodes the specified MCGetChannelResponse message, length delimited. Does not implicitly {@link MCGetChannelResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCGetChannelResponse
     * @static
     * @param {IMCGetChannelResponse} message MCGetChannelResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCGetChannelResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCGetChannelResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCGetChannelResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCGetChannelResponse} MCGetChannelResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCGetChannelResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCGetChannelResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 7: {
                    message.channel = $root.MCChannel.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.version = reader.uint64();
                    break;
                }
            case 4: {
                    message.unreadCount = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCGetChannelResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCGetChannelResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCGetChannelResponse} MCGetChannelResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCGetChannelResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCGetChannelResponse message.
     * @function verify
     * @memberof MCGetChannelResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCGetChannelResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 10:
                break;
            }
        if (message.channel != null && message.hasOwnProperty("channel")) {
            let error = $root.MCChannel.verify(message.channel);
            if (error)
                return "channel." + error;
        }
        if (message.version != null && message.hasOwnProperty("version"))
            if (!$util.isInteger(message.version) && !(message.version && $util.isInteger(message.version.low) && $util.isInteger(message.version.high)))
                return "version: integer|Long expected";
        if (message.unreadCount != null && message.hasOwnProperty("unreadCount"))
            if (!$util.isInteger(message.unreadCount) && !(message.unreadCount && $util.isInteger(message.unreadCount.low) && $util.isInteger(message.unreadCount.high)))
                return "unreadCount: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCGetChannelResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCGetChannelResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCGetChannelResponse} MCGetChannelResponse
     */
    MCGetChannelResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCGetChannelResponse)
            return object;
        let message = new $root.MCGetChannelResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_OK":
        case 0:
            message.result = 0;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 1:
            message.result = 1;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.channel != null) {
            if (typeof object.channel !== "object")
                throw TypeError(".MCGetChannelResponse.channel: object expected");
            message.channel = $root.MCChannel.fromObject(object.channel);
        }
        if (object.version != null)
            if ($util.Long)
                (message.version = $util.Long.fromValue(object.version)).unsigned = true;
            else if (typeof object.version === "string")
                message.version = parseInt(object.version, 10);
            else if (typeof object.version === "number")
                message.version = object.version;
            else if (typeof object.version === "object")
                message.version = new $util.LongBits(object.version.low >>> 0, object.version.high >>> 0).toNumber(true);
        if (object.unreadCount != null)
            if ($util.Long)
                (message.unreadCount = $util.Long.fromValue(object.unreadCount)).unsigned = true;
            else if (typeof object.unreadCount === "string")
                message.unreadCount = parseInt(object.unreadCount, 10);
            else if (typeof object.unreadCount === "number")
                message.unreadCount = object.unreadCount;
            else if (typeof object.unreadCount === "object")
                message.unreadCount = new $util.LongBits(object.unreadCount.low >>> 0, object.unreadCount.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCGetChannelResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCGetChannelResponse
     * @static
     * @param {MCGetChannelResponse} message MCGetChannelResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCGetChannelResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = options.enums === String ? "R_OK" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.unreadCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.unreadCount = options.longs === String ? "0" : 0;
            object.channel = null;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.version = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.version = options.longs === String ? "0" : 0;
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCGetChannelResponse.Result[message.result] === undefined ? message.result : $root.MCGetChannelResponse.Result[message.result] : message.result;
        if (message.unreadCount != null && message.hasOwnProperty("unreadCount"))
            if (typeof message.unreadCount === "number")
                object.unreadCount = options.longs === String ? String(message.unreadCount) : message.unreadCount;
            else
                object.unreadCount = options.longs === String ? $util.Long.prototype.toString.call(message.unreadCount) : options.longs === Number ? new $util.LongBits(message.unreadCount.low >>> 0, message.unreadCount.high >>> 0).toNumber(true) : message.unreadCount;
        if (message.channel != null && message.hasOwnProperty("channel"))
            object.channel = $root.MCChannel.toObject(message.channel, options);
        if (message.version != null && message.hasOwnProperty("version"))
            if (typeof message.version === "number")
                object.version = options.longs === String ? String(message.version) : message.version;
            else
                object.version = options.longs === String ? $util.Long.prototype.toString.call(message.version) : options.longs === Number ? new $util.LongBits(message.version.low >>> 0, message.version.high >>> 0).toNumber(true) : message.version;
        return object;
    };

    /**
     * Converts this MCGetChannelResponse to JSON.
     * @function toJSON
     * @memberof MCGetChannelResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCGetChannelResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCGetChannelResponse
     * @function getTypeUrl
     * @memberof MCGetChannelResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCGetChannelResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCGetChannelResponse";
    };

    /**
     * Result enum.
     * @name MCGetChannelResponse.Result
     * @enum {number}
     * @property {number} R_OK=0 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=1 R_CHANNEL_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCGetChannelResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_OK"] = 0;
        values[valuesById[1] = "R_CHANNEL_NOT_FOUND"] = 1;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCGetChannelResponse;
})();

export const MCGetChannels = $root.MCGetChannels = (() => {

    /**
     * Properties of a MCGetChannels.
     * @exports IMCGetChannels
     * @interface IMCGetChannels
     * @property {Array.<IMCChannelWithAccess>|null} [channels] MCGetChannels channels
     * @property {boolean|null} [fetch] MCGetChannels fetch
     */

    /**
     * Constructs a new MCGetChannels.
     * @exports MCGetChannels
     * @classdesc Represents a MCGetChannels.
     * @implements IMCGetChannels
     * @constructor
     * @param {IMCGetChannels=} [properties] Properties to set
     */
    function MCGetChannels(properties) {
        this.channels = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCGetChannels channels.
     * @member {Array.<IMCChannelWithAccess>} channels
     * @memberof MCGetChannels
     * @instance
     */
    MCGetChannels.prototype.channels = $util.emptyArray;

    /**
     * MCGetChannels fetch.
     * @member {boolean} fetch
     * @memberof MCGetChannels
     * @instance
     */
    MCGetChannels.prototype.fetch = false;

    /**
     * Creates a new MCGetChannels instance using the specified properties.
     * @function create
     * @memberof MCGetChannels
     * @static
     * @param {IMCGetChannels=} [properties] Properties to set
     * @returns {MCGetChannels} MCGetChannels instance
     */
    MCGetChannels.create = function create(properties) {
        return new MCGetChannels(properties);
    };

    /**
     * Encodes the specified MCGetChannels message. Does not implicitly {@link MCGetChannels.verify|verify} messages.
     * @function encode
     * @memberof MCGetChannels
     * @static
     * @param {IMCGetChannels} message MCGetChannels message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCGetChannels.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channels != null && message.channels.length)
            for (let i = 0; i < message.channels.length; ++i)
                $root.MCChannelWithAccess.encode(message.channels[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.fetch != null && Object.hasOwnProperty.call(message, "fetch"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.fetch);
        return writer;
    };

    /**
     * Encodes the specified MCGetChannels message, length delimited. Does not implicitly {@link MCGetChannels.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCGetChannels
     * @static
     * @param {IMCGetChannels} message MCGetChannels message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCGetChannels.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCGetChannels message from the specified reader or buffer.
     * @function decode
     * @memberof MCGetChannels
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCGetChannels} MCGetChannels
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCGetChannels.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCGetChannels();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.channels && message.channels.length))
                        message.channels = [];
                    message.channels.push($root.MCChannelWithAccess.decode(reader, reader.uint32()));
                    break;
                }
            case 2: {
                    message.fetch = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCGetChannels message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCGetChannels
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCGetChannels} MCGetChannels
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCGetChannels.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCGetChannels message.
     * @function verify
     * @memberof MCGetChannels
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCGetChannels.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channels != null && message.hasOwnProperty("channels")) {
            if (!Array.isArray(message.channels))
                return "channels: array expected";
            for (let i = 0; i < message.channels.length; ++i) {
                let error = $root.MCChannelWithAccess.verify(message.channels[i]);
                if (error)
                    return "channels." + error;
            }
        }
        if (message.fetch != null && message.hasOwnProperty("fetch"))
            if (typeof message.fetch !== "boolean")
                return "fetch: boolean expected";
        return null;
    };

    /**
     * Creates a MCGetChannels message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCGetChannels
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCGetChannels} MCGetChannels
     */
    MCGetChannels.fromObject = function fromObject(object) {
        if (object instanceof $root.MCGetChannels)
            return object;
        let message = new $root.MCGetChannels();
        if (object.channels) {
            if (!Array.isArray(object.channels))
                throw TypeError(".MCGetChannels.channels: array expected");
            message.channels = [];
            for (let i = 0; i < object.channels.length; ++i) {
                if (typeof object.channels[i] !== "object")
                    throw TypeError(".MCGetChannels.channels: object expected");
                message.channels[i] = $root.MCChannelWithAccess.fromObject(object.channels[i]);
            }
        }
        if (object.fetch != null)
            message.fetch = Boolean(object.fetch);
        return message;
    };

    /**
     * Creates a plain object from a MCGetChannels message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCGetChannels
     * @static
     * @param {MCGetChannels} message MCGetChannels
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCGetChannels.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.channels = [];
        if (options.defaults)
            object.fetch = false;
        if (message.channels && message.channels.length) {
            object.channels = [];
            for (let j = 0; j < message.channels.length; ++j)
                object.channels[j] = $root.MCChannelWithAccess.toObject(message.channels[j], options);
        }
        if (message.fetch != null && message.hasOwnProperty("fetch"))
            object.fetch = message.fetch;
        return object;
    };

    /**
     * Converts this MCGetChannels to JSON.
     * @function toJSON
     * @memberof MCGetChannels
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCGetChannels.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCGetChannels
     * @function getTypeUrl
     * @memberof MCGetChannels
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCGetChannels.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCGetChannels";
    };

    return MCGetChannels;
})();

export const MCGetChannelsResponse = $root.MCGetChannelsResponse = (() => {

    /**
     * Properties of a MCGetChannelsResponse.
     * @exports IMCGetChannelsResponse
     * @interface IMCGetChannelsResponse
     * @property {Array.<IMCGetChannelResponse>|null} [channels] MCGetChannelsResponse channels
     */

    /**
     * Constructs a new MCGetChannelsResponse.
     * @exports MCGetChannelsResponse
     * @classdesc Represents a MCGetChannelsResponse.
     * @implements IMCGetChannelsResponse
     * @constructor
     * @param {IMCGetChannelsResponse=} [properties] Properties to set
     */
    function MCGetChannelsResponse(properties) {
        this.channels = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCGetChannelsResponse channels.
     * @member {Array.<IMCGetChannelResponse>} channels
     * @memberof MCGetChannelsResponse
     * @instance
     */
    MCGetChannelsResponse.prototype.channels = $util.emptyArray;

    /**
     * Creates a new MCGetChannelsResponse instance using the specified properties.
     * @function create
     * @memberof MCGetChannelsResponse
     * @static
     * @param {IMCGetChannelsResponse=} [properties] Properties to set
     * @returns {MCGetChannelsResponse} MCGetChannelsResponse instance
     */
    MCGetChannelsResponse.create = function create(properties) {
        return new MCGetChannelsResponse(properties);
    };

    /**
     * Encodes the specified MCGetChannelsResponse message. Does not implicitly {@link MCGetChannelsResponse.verify|verify} messages.
     * @function encode
     * @memberof MCGetChannelsResponse
     * @static
     * @param {IMCGetChannelsResponse} message MCGetChannelsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCGetChannelsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channels != null && message.channels.length)
            for (let i = 0; i < message.channels.length; ++i)
                $root.MCGetChannelResponse.encode(message.channels[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCGetChannelsResponse message, length delimited. Does not implicitly {@link MCGetChannelsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCGetChannelsResponse
     * @static
     * @param {IMCGetChannelsResponse} message MCGetChannelsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCGetChannelsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCGetChannelsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCGetChannelsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCGetChannelsResponse} MCGetChannelsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCGetChannelsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCGetChannelsResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.channels && message.channels.length))
                        message.channels = [];
                    message.channels.push($root.MCGetChannelResponse.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCGetChannelsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCGetChannelsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCGetChannelsResponse} MCGetChannelsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCGetChannelsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCGetChannelsResponse message.
     * @function verify
     * @memberof MCGetChannelsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCGetChannelsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channels != null && message.hasOwnProperty("channels")) {
            if (!Array.isArray(message.channels))
                return "channels: array expected";
            for (let i = 0; i < message.channels.length; ++i) {
                let error = $root.MCGetChannelResponse.verify(message.channels[i]);
                if (error)
                    return "channels." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCGetChannelsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCGetChannelsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCGetChannelsResponse} MCGetChannelsResponse
     */
    MCGetChannelsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCGetChannelsResponse)
            return object;
        let message = new $root.MCGetChannelsResponse();
        if (object.channels) {
            if (!Array.isArray(object.channels))
                throw TypeError(".MCGetChannelsResponse.channels: array expected");
            message.channels = [];
            for (let i = 0; i < object.channels.length; ++i) {
                if (typeof object.channels[i] !== "object")
                    throw TypeError(".MCGetChannelsResponse.channels: object expected");
                message.channels[i] = $root.MCGetChannelResponse.fromObject(object.channels[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCGetChannelsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCGetChannelsResponse
     * @static
     * @param {MCGetChannelsResponse} message MCGetChannelsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCGetChannelsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.channels = [];
        if (message.channels && message.channels.length) {
            object.channels = [];
            for (let j = 0; j < message.channels.length; ++j)
                object.channels[j] = $root.MCGetChannelResponse.toObject(message.channels[j], options);
        }
        return object;
    };

    /**
     * Converts this MCGetChannelsResponse to JSON.
     * @function toJSON
     * @memberof MCGetChannelsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCGetChannelsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCGetChannelsResponse
     * @function getTypeUrl
     * @memberof MCGetChannelsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCGetChannelsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCGetChannelsResponse";
    };

    return MCGetChannelsResponse;
})();

export const MCMethodSendMessage = $root.MCMethodSendMessage = (() => {

    /**
     * Properties of a MCMethodSendMessage.
     * @exports IMCMethodSendMessage
     * @interface IMCMethodSendMessage
     * @property {Uint8Array|null} [channelID] MCMethodSendMessage channelID
     * @property {Long|null} [chatID] MCMethodSendMessage chatID
     * @property {Uint8Array|null} [externalChatID] MCMethodSendMessage externalChatID
     * @property {Array.<IMCTextEntities>|null} [textEntities] MCMethodSendMessage textEntities
     * @property {string|null} [text] MCMethodSendMessage text
     * @property {Array.<IMCAttachment>|null} [attachments] MCMethodSendMessage attachments
     * @property {Long|null} [replyToMessageID] MCMethodSendMessage replyToMessageID
     * @property {Long|null} [randomID] MCMethodSendMessage randomID
     */

    /**
     * Constructs a new MCMethodSendMessage.
     * @exports MCMethodSendMessage
     * @classdesc Represents a MCMethodSendMessage.
     * @implements IMCMethodSendMessage
     * @constructor
     * @param {IMCMethodSendMessage=} [properties] Properties to set
     */
    function MCMethodSendMessage(properties) {
        this.textEntities = [];
        this.attachments = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodSendMessage channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodSendMessage
     * @instance
     */
    MCMethodSendMessage.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodSendMessage chatID.
     * @member {Long|null|undefined} chatID
     * @memberof MCMethodSendMessage
     * @instance
     */
    MCMethodSendMessage.prototype.chatID = null;

    /**
     * MCMethodSendMessage externalChatID.
     * @member {Uint8Array|null|undefined} externalChatID
     * @memberof MCMethodSendMessage
     * @instance
     */
    MCMethodSendMessage.prototype.externalChatID = null;

    /**
     * MCMethodSendMessage textEntities.
     * @member {Array.<IMCTextEntities>} textEntities
     * @memberof MCMethodSendMessage
     * @instance
     */
    MCMethodSendMessage.prototype.textEntities = $util.emptyArray;

    /**
     * MCMethodSendMessage text.
     * @member {string} text
     * @memberof MCMethodSendMessage
     * @instance
     */
    MCMethodSendMessage.prototype.text = "";

    /**
     * MCMethodSendMessage attachments.
     * @member {Array.<IMCAttachment>} attachments
     * @memberof MCMethodSendMessage
     * @instance
     */
    MCMethodSendMessage.prototype.attachments = $util.emptyArray;

    /**
     * MCMethodSendMessage replyToMessageID.
     * @member {Long} replyToMessageID
     * @memberof MCMethodSendMessage
     * @instance
     */
    MCMethodSendMessage.prototype.replyToMessageID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodSendMessage randomID.
     * @member {Long} randomID
     * @memberof MCMethodSendMessage
     * @instance
     */
    MCMethodSendMessage.prototype.randomID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * MCMethodSendMessage chat.
     * @member {"chatID"|"externalChatID"|undefined} chat
     * @memberof MCMethodSendMessage
     * @instance
     */
    Object.defineProperty(MCMethodSendMessage.prototype, "chat", {
        get: $util.oneOfGetter($oneOfFields = ["chatID", "externalChatID"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new MCMethodSendMessage instance using the specified properties.
     * @function create
     * @memberof MCMethodSendMessage
     * @static
     * @param {IMCMethodSendMessage=} [properties] Properties to set
     * @returns {MCMethodSendMessage} MCMethodSendMessage instance
     */
    MCMethodSendMessage.create = function create(properties) {
        return new MCMethodSendMessage(properties);
    };

    /**
     * Encodes the specified MCMethodSendMessage message. Does not implicitly {@link MCMethodSendMessage.verify|verify} messages.
     * @function encode
     * @memberof MCMethodSendMessage
     * @static
     * @param {IMCMethodSendMessage} message MCMethodSendMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSendMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chatID);
        if (message.text != null && Object.hasOwnProperty.call(message, "text"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.text);
        if (message.attachments != null && message.attachments.length)
            for (let i = 0; i < message.attachments.length; ++i)
                $root.MCAttachment.encode(message.attachments[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.replyToMessageID != null && Object.hasOwnProperty.call(message, "replyToMessageID"))
            writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.replyToMessageID);
        if (message.externalChatID != null && Object.hasOwnProperty.call(message, "externalChatID"))
            writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.externalChatID);
        if (message.textEntities != null && message.textEntities.length)
            for (let i = 0; i < message.textEntities.length; ++i)
                $root.MCTextEntities.encode(message.textEntities[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.randomID != null && Object.hasOwnProperty.call(message, "randomID"))
            writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.randomID);
        return writer;
    };

    /**
     * Encodes the specified MCMethodSendMessage message, length delimited. Does not implicitly {@link MCMethodSendMessage.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodSendMessage
     * @static
     * @param {IMCMethodSendMessage} message MCMethodSendMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSendMessage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodSendMessage message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodSendMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodSendMessage} MCMethodSendMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSendMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodSendMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 6: {
                    message.externalChatID = reader.bytes();
                    break;
                }
            case 9: {
                    if (!(message.textEntities && message.textEntities.length))
                        message.textEntities = [];
                    message.textEntities.push($root.MCTextEntities.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.text = reader.string();
                    break;
                }
            case 4: {
                    if (!(message.attachments && message.attachments.length))
                        message.attachments = [];
                    message.attachments.push($root.MCAttachment.decode(reader, reader.uint32()));
                    break;
                }
            case 5: {
                    message.replyToMessageID = reader.uint64();
                    break;
                }
            case 10: {
                    message.randomID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodSendMessage message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodSendMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodSendMessage} MCMethodSendMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSendMessage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodSendMessage message.
     * @function verify
     * @memberof MCMethodSendMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodSendMessage.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.chatID != null && message.hasOwnProperty("chatID")) {
            properties.chat = 1;
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        }
        if (message.externalChatID != null && message.hasOwnProperty("externalChatID")) {
            if (properties.chat === 1)
                return "chat: multiple values";
            properties.chat = 1;
            if (!(message.externalChatID && typeof message.externalChatID.length === "number" || $util.isString(message.externalChatID)))
                return "externalChatID: buffer expected";
        }
        if (message.textEntities != null && message.hasOwnProperty("textEntities")) {
            if (!Array.isArray(message.textEntities))
                return "textEntities: array expected";
            for (let i = 0; i < message.textEntities.length; ++i) {
                let error = $root.MCTextEntities.verify(message.textEntities[i]);
                if (error)
                    return "textEntities." + error;
            }
        }
        if (message.text != null && message.hasOwnProperty("text"))
            if (!$util.isString(message.text))
                return "text: string expected";
        if (message.attachments != null && message.hasOwnProperty("attachments")) {
            if (!Array.isArray(message.attachments))
                return "attachments: array expected";
            for (let i = 0; i < message.attachments.length; ++i) {
                let error = $root.MCAttachment.verify(message.attachments[i]);
                if (error)
                    return "attachments." + error;
            }
        }
        if (message.replyToMessageID != null && message.hasOwnProperty("replyToMessageID"))
            if (!$util.isInteger(message.replyToMessageID) && !(message.replyToMessageID && $util.isInteger(message.replyToMessageID.low) && $util.isInteger(message.replyToMessageID.high)))
                return "replyToMessageID: integer|Long expected";
        if (message.randomID != null && message.hasOwnProperty("randomID"))
            if (!$util.isInteger(message.randomID) && !(message.randomID && $util.isInteger(message.randomID.low) && $util.isInteger(message.randomID.high)))
                return "randomID: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCMethodSendMessage message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodSendMessage
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodSendMessage} MCMethodSendMessage
     */
    MCMethodSendMessage.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodSendMessage)
            return object;
        let message = new $root.MCMethodSendMessage();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.externalChatID != null)
            if (typeof object.externalChatID === "string")
                $util.base64.decode(object.externalChatID, message.externalChatID = $util.newBuffer($util.base64.length(object.externalChatID)), 0);
            else if (object.externalChatID.length >= 0)
                message.externalChatID = object.externalChatID;
        if (object.textEntities) {
            if (!Array.isArray(object.textEntities))
                throw TypeError(".MCMethodSendMessage.textEntities: array expected");
            message.textEntities = [];
            for (let i = 0; i < object.textEntities.length; ++i) {
                if (typeof object.textEntities[i] !== "object")
                    throw TypeError(".MCMethodSendMessage.textEntities: object expected");
                message.textEntities[i] = $root.MCTextEntities.fromObject(object.textEntities[i]);
            }
        }
        if (object.text != null)
            message.text = String(object.text);
        if (object.attachments) {
            if (!Array.isArray(object.attachments))
                throw TypeError(".MCMethodSendMessage.attachments: array expected");
            message.attachments = [];
            for (let i = 0; i < object.attachments.length; ++i) {
                if (typeof object.attachments[i] !== "object")
                    throw TypeError(".MCMethodSendMessage.attachments: object expected");
                message.attachments[i] = $root.MCAttachment.fromObject(object.attachments[i]);
            }
        }
        if (object.replyToMessageID != null)
            if ($util.Long)
                (message.replyToMessageID = $util.Long.fromValue(object.replyToMessageID)).unsigned = true;
            else if (typeof object.replyToMessageID === "string")
                message.replyToMessageID = parseInt(object.replyToMessageID, 10);
            else if (typeof object.replyToMessageID === "number")
                message.replyToMessageID = object.replyToMessageID;
            else if (typeof object.replyToMessageID === "object")
                message.replyToMessageID = new $util.LongBits(object.replyToMessageID.low >>> 0, object.replyToMessageID.high >>> 0).toNumber(true);
        if (object.randomID != null)
            if ($util.Long)
                (message.randomID = $util.Long.fromValue(object.randomID)).unsigned = true;
            else if (typeof object.randomID === "string")
                message.randomID = parseInt(object.randomID, 10);
            else if (typeof object.randomID === "number")
                message.randomID = object.randomID;
            else if (typeof object.randomID === "object")
                message.randomID = new $util.LongBits(object.randomID.low >>> 0, object.randomID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodSendMessage message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodSendMessage
     * @static
     * @param {MCMethodSendMessage} message MCMethodSendMessage
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodSendMessage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.attachments = [];
            object.textEntities = [];
        }
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            object.text = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.replyToMessageID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.replyToMessageID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.randomID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.randomID = options.longs === String ? "0" : 0;
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.chatID != null && message.hasOwnProperty("chatID")) {
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
            if (options.oneofs)
                object.chat = "chatID";
        }
        if (message.text != null && message.hasOwnProperty("text"))
            object.text = message.text;
        if (message.attachments && message.attachments.length) {
            object.attachments = [];
            for (let j = 0; j < message.attachments.length; ++j)
                object.attachments[j] = $root.MCAttachment.toObject(message.attachments[j], options);
        }
        if (message.replyToMessageID != null && message.hasOwnProperty("replyToMessageID"))
            if (typeof message.replyToMessageID === "number")
                object.replyToMessageID = options.longs === String ? String(message.replyToMessageID) : message.replyToMessageID;
            else
                object.replyToMessageID = options.longs === String ? $util.Long.prototype.toString.call(message.replyToMessageID) : options.longs === Number ? new $util.LongBits(message.replyToMessageID.low >>> 0, message.replyToMessageID.high >>> 0).toNumber(true) : message.replyToMessageID;
        if (message.externalChatID != null && message.hasOwnProperty("externalChatID")) {
            object.externalChatID = options.bytes === String ? $util.base64.encode(message.externalChatID, 0, message.externalChatID.length) : options.bytes === Array ? Array.prototype.slice.call(message.externalChatID) : message.externalChatID;
            if (options.oneofs)
                object.chat = "externalChatID";
        }
        if (message.textEntities && message.textEntities.length) {
            object.textEntities = [];
            for (let j = 0; j < message.textEntities.length; ++j)
                object.textEntities[j] = $root.MCTextEntities.toObject(message.textEntities[j], options);
        }
        if (message.randomID != null && message.hasOwnProperty("randomID"))
            if (typeof message.randomID === "number")
                object.randomID = options.longs === String ? String(message.randomID) : message.randomID;
            else
                object.randomID = options.longs === String ? $util.Long.prototype.toString.call(message.randomID) : options.longs === Number ? new $util.LongBits(message.randomID.low >>> 0, message.randomID.high >>> 0).toNumber(true) : message.randomID;
        return object;
    };

    /**
     * Converts this MCMethodSendMessage to JSON.
     * @function toJSON
     * @memberof MCMethodSendMessage
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodSendMessage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodSendMessage
     * @function getTypeUrl
     * @memberof MCMethodSendMessage
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodSendMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodSendMessage";
    };

    return MCMethodSendMessage;
})();

export const MCMethodSendMessageResponse = $root.MCMethodSendMessageResponse = (() => {

    /**
     * Properties of a MCMethodSendMessageResponse.
     * @exports IMCMethodSendMessageResponse
     * @interface IMCMethodSendMessageResponse
     * @property {MCMethodSendMessageResponse.Result|null} [result] MCMethodSendMessageResponse result
     * @property {Long|null} [messageID] MCMethodSendMessageResponse messageID
     */

    /**
     * Constructs a new MCMethodSendMessageResponse.
     * @exports MCMethodSendMessageResponse
     * @classdesc Represents a MCMethodSendMessageResponse.
     * @implements IMCMethodSendMessageResponse
     * @constructor
     * @param {IMCMethodSendMessageResponse=} [properties] Properties to set
     */
    function MCMethodSendMessageResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodSendMessageResponse result.
     * @member {MCMethodSendMessageResponse.Result} result
     * @memberof MCMethodSendMessageResponse
     * @instance
     */
    MCMethodSendMessageResponse.prototype.result = 0;

    /**
     * MCMethodSendMessageResponse messageID.
     * @member {Long} messageID
     * @memberof MCMethodSendMessageResponse
     * @instance
     */
    MCMethodSendMessageResponse.prototype.messageID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCMethodSendMessageResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodSendMessageResponse
     * @static
     * @param {IMCMethodSendMessageResponse=} [properties] Properties to set
     * @returns {MCMethodSendMessageResponse} MCMethodSendMessageResponse instance
     */
    MCMethodSendMessageResponse.create = function create(properties) {
        return new MCMethodSendMessageResponse(properties);
    };

    /**
     * Encodes the specified MCMethodSendMessageResponse message. Does not implicitly {@link MCMethodSendMessageResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodSendMessageResponse
     * @static
     * @param {IMCMethodSendMessageResponse} message MCMethodSendMessageResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSendMessageResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.messageID != null && Object.hasOwnProperty.call(message, "messageID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.messageID);
        return writer;
    };

    /**
     * Encodes the specified MCMethodSendMessageResponse message, length delimited. Does not implicitly {@link MCMethodSendMessageResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodSendMessageResponse
     * @static
     * @param {IMCMethodSendMessageResponse} message MCMethodSendMessageResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSendMessageResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodSendMessageResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodSendMessageResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodSendMessageResponse} MCMethodSendMessageResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSendMessageResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodSendMessageResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    message.messageID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodSendMessageResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodSendMessageResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodSendMessageResponse} MCMethodSendMessageResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSendMessageResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodSendMessageResponse message.
     * @function verify
     * @memberof MCMethodSendMessageResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodSendMessageResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 39:
            case 40:
                break;
            }
        if (message.messageID != null && message.hasOwnProperty("messageID"))
            if (!$util.isInteger(message.messageID) && !(message.messageID && $util.isInteger(message.messageID.low) && $util.isInteger(message.messageID.high)))
                return "messageID: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCMethodSendMessageResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodSendMessageResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodSendMessageResponse} MCMethodSendMessageResponse
     */
    MCMethodSendMessageResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodSendMessageResponse)
            return object;
        let message = new $root.MCMethodSendMessageResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_OK":
        case 0:
            message.result = 0;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 1:
            message.result = 1;
            break;
        case "R_CHAT_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_MESSAGE_TOO_LONG":
        case 3:
            message.result = 3;
            break;
        case "R_ATTACHMENT_NOT_FOUND":
        case 4:
            message.result = 4;
            break;
        case "R_ATTACHMENT_BROKEN":
        case 5:
            message.result = 5;
            break;
        case "R_NO_RIGHTS":
        case 6:
            message.result = 6;
            break;
        case "R_CHAT_BLACKLISTED":
        case 7:
            message.result = 7;
            break;
        case "R_YOU_BLACKLISTED":
        case 8:
            message.result = 8;
            break;
        case "R_FORBIDDEN_ATTACHMENT":
        case 9:
            message.result = 9;
            break;
        case "R_INCORRECT_TEXT_ENTITIES":
        case 10:
            message.result = 10;
            break;
        case "R_INVALID_REPLY":
        case 11:
            message.result = 11;
            break;
        case "R_UNKNOWN_ERROR":
        case 39:
            message.result = 39;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 40:
            message.result = 40;
            break;
        }
        if (object.messageID != null)
            if ($util.Long)
                (message.messageID = $util.Long.fromValue(object.messageID)).unsigned = true;
            else if (typeof object.messageID === "string")
                message.messageID = parseInt(object.messageID, 10);
            else if (typeof object.messageID === "number")
                message.messageID = object.messageID;
            else if (typeof object.messageID === "object")
                message.messageID = new $util.LongBits(object.messageID.low >>> 0, object.messageID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodSendMessageResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodSendMessageResponse
     * @static
     * @param {MCMethodSendMessageResponse} message MCMethodSendMessageResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodSendMessageResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = options.enums === String ? "R_OK" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.messageID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.messageID = options.longs === String ? "0" : 0;
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodSendMessageResponse.Result[message.result] === undefined ? message.result : $root.MCMethodSendMessageResponse.Result[message.result] : message.result;
        if (message.messageID != null && message.hasOwnProperty("messageID"))
            if (typeof message.messageID === "number")
                object.messageID = options.longs === String ? String(message.messageID) : message.messageID;
            else
                object.messageID = options.longs === String ? $util.Long.prototype.toString.call(message.messageID) : options.longs === Number ? new $util.LongBits(message.messageID.low >>> 0, message.messageID.high >>> 0).toNumber(true) : message.messageID;
        return object;
    };

    /**
     * Converts this MCMethodSendMessageResponse to JSON.
     * @function toJSON
     * @memberof MCMethodSendMessageResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodSendMessageResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodSendMessageResponse
     * @function getTypeUrl
     * @memberof MCMethodSendMessageResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodSendMessageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodSendMessageResponse";
    };

    /**
     * Result enum.
     * @name MCMethodSendMessageResponse.Result
     * @enum {number}
     * @property {number} R_OK=0 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=1 R_CHANNEL_NOT_FOUND value
     * @property {number} R_CHAT_NOT_FOUND=2 R_CHAT_NOT_FOUND value
     * @property {number} R_MESSAGE_TOO_LONG=3 R_MESSAGE_TOO_LONG value
     * @property {number} R_ATTACHMENT_NOT_FOUND=4 R_ATTACHMENT_NOT_FOUND value
     * @property {number} R_ATTACHMENT_BROKEN=5 R_ATTACHMENT_BROKEN value
     * @property {number} R_NO_RIGHTS=6 R_NO_RIGHTS value
     * @property {number} R_CHAT_BLACKLISTED=7 R_CHAT_BLACKLISTED value
     * @property {number} R_YOU_BLACKLISTED=8 R_YOU_BLACKLISTED value
     * @property {number} R_FORBIDDEN_ATTACHMENT=9 R_FORBIDDEN_ATTACHMENT value
     * @property {number} R_INCORRECT_TEXT_ENTITIES=10 R_INCORRECT_TEXT_ENTITIES value
     * @property {number} R_INVALID_REPLY=11 R_INVALID_REPLY value
     * @property {number} R_UNKNOWN_ERROR=39 R_UNKNOWN_ERROR value
     * @property {number} R_INTERNAL_SERVER_ERROR=40 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodSendMessageResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_OK"] = 0;
        values[valuesById[1] = "R_CHANNEL_NOT_FOUND"] = 1;
        values[valuesById[2] = "R_CHAT_NOT_FOUND"] = 2;
        values[valuesById[3] = "R_MESSAGE_TOO_LONG"] = 3;
        values[valuesById[4] = "R_ATTACHMENT_NOT_FOUND"] = 4;
        values[valuesById[5] = "R_ATTACHMENT_BROKEN"] = 5;
        values[valuesById[6] = "R_NO_RIGHTS"] = 6;
        values[valuesById[7] = "R_CHAT_BLACKLISTED"] = 7;
        values[valuesById[8] = "R_YOU_BLACKLISTED"] = 8;
        values[valuesById[9] = "R_FORBIDDEN_ATTACHMENT"] = 9;
        values[valuesById[10] = "R_INCORRECT_TEXT_ENTITIES"] = 10;
        values[valuesById[11] = "R_INVALID_REPLY"] = 11;
        values[valuesById[39] = "R_UNKNOWN_ERROR"] = 39;
        values[valuesById[40] = "R_INTERNAL_SERVER_ERROR"] = 40;
        return values;
    })();

    return MCMethodSendMessageResponse;
})();

export const MCMethodSendGroupedMessages = $root.MCMethodSendGroupedMessages = (() => {

    /**
     * Properties of a MCMethodSendGroupedMessages.
     * @exports IMCMethodSendGroupedMessages
     * @interface IMCMethodSendGroupedMessages
     * @property {Uint8Array|null} [channelID] MCMethodSendGroupedMessages channelID
     * @property {Long|null} [chatID] MCMethodSendGroupedMessages chatID
     * @property {Array.<IMCSendEntities>|null} [messages] MCMethodSendGroupedMessages messages
     */

    /**
     * Constructs a new MCMethodSendGroupedMessages.
     * @exports MCMethodSendGroupedMessages
     * @classdesc Represents a MCMethodSendGroupedMessages.
     * @implements IMCMethodSendGroupedMessages
     * @constructor
     * @param {IMCMethodSendGroupedMessages=} [properties] Properties to set
     */
    function MCMethodSendGroupedMessages(properties) {
        this.messages = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodSendGroupedMessages channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodSendGroupedMessages
     * @instance
     */
    MCMethodSendGroupedMessages.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodSendGroupedMessages chatID.
     * @member {Long} chatID
     * @memberof MCMethodSendGroupedMessages
     * @instance
     */
    MCMethodSendGroupedMessages.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodSendGroupedMessages messages.
     * @member {Array.<IMCSendEntities>} messages
     * @memberof MCMethodSendGroupedMessages
     * @instance
     */
    MCMethodSendGroupedMessages.prototype.messages = $util.emptyArray;

    /**
     * Creates a new MCMethodSendGroupedMessages instance using the specified properties.
     * @function create
     * @memberof MCMethodSendGroupedMessages
     * @static
     * @param {IMCMethodSendGroupedMessages=} [properties] Properties to set
     * @returns {MCMethodSendGroupedMessages} MCMethodSendGroupedMessages instance
     */
    MCMethodSendGroupedMessages.create = function create(properties) {
        return new MCMethodSendGroupedMessages(properties);
    };

    /**
     * Encodes the specified MCMethodSendGroupedMessages message. Does not implicitly {@link MCMethodSendGroupedMessages.verify|verify} messages.
     * @function encode
     * @memberof MCMethodSendGroupedMessages
     * @static
     * @param {IMCMethodSendGroupedMessages} message MCMethodSendGroupedMessages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSendGroupedMessages.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chatID);
        if (message.messages != null && message.messages.length)
            for (let i = 0; i < message.messages.length; ++i)
                $root.MCSendEntities.encode(message.messages[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodSendGroupedMessages message, length delimited. Does not implicitly {@link MCMethodSendGroupedMessages.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodSendGroupedMessages
     * @static
     * @param {IMCMethodSendGroupedMessages} message MCMethodSendGroupedMessages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSendGroupedMessages.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodSendGroupedMessages message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodSendGroupedMessages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodSendGroupedMessages} MCMethodSendGroupedMessages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSendGroupedMessages.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodSendGroupedMessages();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 3: {
                    if (!(message.messages && message.messages.length))
                        message.messages = [];
                    message.messages.push($root.MCSendEntities.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodSendGroupedMessages message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodSendGroupedMessages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodSendGroupedMessages} MCMethodSendGroupedMessages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSendGroupedMessages.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodSendGroupedMessages message.
     * @function verify
     * @memberof MCMethodSendGroupedMessages
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodSendGroupedMessages.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.messages != null && message.hasOwnProperty("messages")) {
            if (!Array.isArray(message.messages))
                return "messages: array expected";
            for (let i = 0; i < message.messages.length; ++i) {
                let error = $root.MCSendEntities.verify(message.messages[i]);
                if (error)
                    return "messages." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCMethodSendGroupedMessages message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodSendGroupedMessages
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodSendGroupedMessages} MCMethodSendGroupedMessages
     */
    MCMethodSendGroupedMessages.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodSendGroupedMessages)
            return object;
        let message = new $root.MCMethodSendGroupedMessages();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.messages) {
            if (!Array.isArray(object.messages))
                throw TypeError(".MCMethodSendGroupedMessages.messages: array expected");
            message.messages = [];
            for (let i = 0; i < object.messages.length; ++i) {
                if (typeof object.messages[i] !== "object")
                    throw TypeError(".MCMethodSendGroupedMessages.messages: object expected");
                message.messages[i] = $root.MCSendEntities.fromObject(object.messages[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodSendGroupedMessages message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodSendGroupedMessages
     * @static
     * @param {MCMethodSendGroupedMessages} message MCMethodSendGroupedMessages
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodSendGroupedMessages.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.messages = [];
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.messages && message.messages.length) {
            object.messages = [];
            for (let j = 0; j < message.messages.length; ++j)
                object.messages[j] = $root.MCSendEntities.toObject(message.messages[j], options);
        }
        return object;
    };

    /**
     * Converts this MCMethodSendGroupedMessages to JSON.
     * @function toJSON
     * @memberof MCMethodSendGroupedMessages
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodSendGroupedMessages.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodSendGroupedMessages
     * @function getTypeUrl
     * @memberof MCMethodSendGroupedMessages
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodSendGroupedMessages.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodSendGroupedMessages";
    };

    return MCMethodSendGroupedMessages;
})();

export const MCMethodSendGroupedMessagesResponse = $root.MCMethodSendGroupedMessagesResponse = (() => {

    /**
     * Properties of a MCMethodSendGroupedMessagesResponse.
     * @exports IMCMethodSendGroupedMessagesResponse
     * @interface IMCMethodSendGroupedMessagesResponse
     * @property {MCMethodSendGroupedMessagesResponse.Result|null} [result] MCMethodSendGroupedMessagesResponse result
     * @property {Array.<Long>|null} [messageIDs] MCMethodSendGroupedMessagesResponse messageIDs
     */

    /**
     * Constructs a new MCMethodSendGroupedMessagesResponse.
     * @exports MCMethodSendGroupedMessagesResponse
     * @classdesc Represents a MCMethodSendGroupedMessagesResponse.
     * @implements IMCMethodSendGroupedMessagesResponse
     * @constructor
     * @param {IMCMethodSendGroupedMessagesResponse=} [properties] Properties to set
     */
    function MCMethodSendGroupedMessagesResponse(properties) {
        this.messageIDs = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodSendGroupedMessagesResponse result.
     * @member {MCMethodSendGroupedMessagesResponse.Result} result
     * @memberof MCMethodSendGroupedMessagesResponse
     * @instance
     */
    MCMethodSendGroupedMessagesResponse.prototype.result = 0;

    /**
     * MCMethodSendGroupedMessagesResponse messageIDs.
     * @member {Array.<Long>} messageIDs
     * @memberof MCMethodSendGroupedMessagesResponse
     * @instance
     */
    MCMethodSendGroupedMessagesResponse.prototype.messageIDs = $util.emptyArray;

    /**
     * Creates a new MCMethodSendGroupedMessagesResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodSendGroupedMessagesResponse
     * @static
     * @param {IMCMethodSendGroupedMessagesResponse=} [properties] Properties to set
     * @returns {MCMethodSendGroupedMessagesResponse} MCMethodSendGroupedMessagesResponse instance
     */
    MCMethodSendGroupedMessagesResponse.create = function create(properties) {
        return new MCMethodSendGroupedMessagesResponse(properties);
    };

    /**
     * Encodes the specified MCMethodSendGroupedMessagesResponse message. Does not implicitly {@link MCMethodSendGroupedMessagesResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodSendGroupedMessagesResponse
     * @static
     * @param {IMCMethodSendGroupedMessagesResponse} message MCMethodSendGroupedMessagesResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSendGroupedMessagesResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.messageIDs != null && message.messageIDs.length) {
            writer.uint32(/* id 2, wireType 2 =*/18).fork();
            for (let i = 0; i < message.messageIDs.length; ++i)
                writer.uint64(message.messageIDs[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified MCMethodSendGroupedMessagesResponse message, length delimited. Does not implicitly {@link MCMethodSendGroupedMessagesResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodSendGroupedMessagesResponse
     * @static
     * @param {IMCMethodSendGroupedMessagesResponse} message MCMethodSendGroupedMessagesResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSendGroupedMessagesResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodSendGroupedMessagesResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodSendGroupedMessagesResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodSendGroupedMessagesResponse} MCMethodSendGroupedMessagesResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSendGroupedMessagesResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodSendGroupedMessagesResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.messageIDs && message.messageIDs.length))
                        message.messageIDs = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.messageIDs.push(reader.uint64());
                    } else
                        message.messageIDs.push(reader.uint64());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodSendGroupedMessagesResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodSendGroupedMessagesResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodSendGroupedMessagesResponse} MCMethodSendGroupedMessagesResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSendGroupedMessagesResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodSendGroupedMessagesResponse message.
     * @function verify
     * @memberof MCMethodSendGroupedMessagesResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodSendGroupedMessagesResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 30:
            case 39:
            case 40:
                break;
            }
        if (message.messageIDs != null && message.hasOwnProperty("messageIDs")) {
            if (!Array.isArray(message.messageIDs))
                return "messageIDs: array expected";
            for (let i = 0; i < message.messageIDs.length; ++i)
                if (!$util.isInteger(message.messageIDs[i]) && !(message.messageIDs[i] && $util.isInteger(message.messageIDs[i].low) && $util.isInteger(message.messageIDs[i].high)))
                    return "messageIDs: integer|Long[] expected";
        }
        return null;
    };

    /**
     * Creates a MCMethodSendGroupedMessagesResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodSendGroupedMessagesResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodSendGroupedMessagesResponse} MCMethodSendGroupedMessagesResponse
     */
    MCMethodSendGroupedMessagesResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodSendGroupedMessagesResponse)
            return object;
        let message = new $root.MCMethodSendGroupedMessagesResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_OK":
        case 0:
            message.result = 0;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 1:
            message.result = 1;
            break;
        case "R_CHAT_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_MESSAGE_TOO_LONG":
        case 3:
            message.result = 3;
            break;
        case "R_ATTACHMENT_NOT_FOUND":
        case 4:
            message.result = 4;
            break;
        case "R_ATTACHMENT_BROKEN":
        case 5:
            message.result = 5;
            break;
        case "R_NO_RIGHTS":
        case 6:
            message.result = 6;
            break;
        case "R_CHAT_BLACKLISTED":
        case 7:
            message.result = 7;
            break;
        case "R_YOU_BLACKLISTED":
        case 8:
            message.result = 8;
            break;
        case "R_FORBIDDEN_ATTACHMENT":
        case 9:
            message.result = 9;
            break;
        case "R_INCORRECT_TEXT_ENTITIES":
        case 10:
            message.result = 10;
            break;
        case "R_INVALID_REPLY":
        case 11:
            message.result = 11;
            break;
        case "R_UNBUILDABLE_ALBUM":
        case 30:
            message.result = 30;
            break;
        case "R_UNKNOWN_ERROR":
        case 39:
            message.result = 39;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 40:
            message.result = 40;
            break;
        }
        if (object.messageIDs) {
            if (!Array.isArray(object.messageIDs))
                throw TypeError(".MCMethodSendGroupedMessagesResponse.messageIDs: array expected");
            message.messageIDs = [];
            for (let i = 0; i < object.messageIDs.length; ++i)
                if ($util.Long)
                    (message.messageIDs[i] = $util.Long.fromValue(object.messageIDs[i])).unsigned = true;
                else if (typeof object.messageIDs[i] === "string")
                    message.messageIDs[i] = parseInt(object.messageIDs[i], 10);
                else if (typeof object.messageIDs[i] === "number")
                    message.messageIDs[i] = object.messageIDs[i];
                else if (typeof object.messageIDs[i] === "object")
                    message.messageIDs[i] = new $util.LongBits(object.messageIDs[i].low >>> 0, object.messageIDs[i].high >>> 0).toNumber(true);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodSendGroupedMessagesResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodSendGroupedMessagesResponse
     * @static
     * @param {MCMethodSendGroupedMessagesResponse} message MCMethodSendGroupedMessagesResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodSendGroupedMessagesResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.messageIDs = [];
        if (options.defaults)
            object.result = options.enums === String ? "R_OK" : 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodSendGroupedMessagesResponse.Result[message.result] === undefined ? message.result : $root.MCMethodSendGroupedMessagesResponse.Result[message.result] : message.result;
        if (message.messageIDs && message.messageIDs.length) {
            object.messageIDs = [];
            for (let j = 0; j < message.messageIDs.length; ++j)
                if (typeof message.messageIDs[j] === "number")
                    object.messageIDs[j] = options.longs === String ? String(message.messageIDs[j]) : message.messageIDs[j];
                else
                    object.messageIDs[j] = options.longs === String ? $util.Long.prototype.toString.call(message.messageIDs[j]) : options.longs === Number ? new $util.LongBits(message.messageIDs[j].low >>> 0, message.messageIDs[j].high >>> 0).toNumber(true) : message.messageIDs[j];
        }
        return object;
    };

    /**
     * Converts this MCMethodSendGroupedMessagesResponse to JSON.
     * @function toJSON
     * @memberof MCMethodSendGroupedMessagesResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodSendGroupedMessagesResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodSendGroupedMessagesResponse
     * @function getTypeUrl
     * @memberof MCMethodSendGroupedMessagesResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodSendGroupedMessagesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodSendGroupedMessagesResponse";
    };

    /**
     * Result enum.
     * @name MCMethodSendGroupedMessagesResponse.Result
     * @enum {number}
     * @property {number} R_OK=0 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=1 R_CHANNEL_NOT_FOUND value
     * @property {number} R_CHAT_NOT_FOUND=2 R_CHAT_NOT_FOUND value
     * @property {number} R_MESSAGE_TOO_LONG=3 R_MESSAGE_TOO_LONG value
     * @property {number} R_ATTACHMENT_NOT_FOUND=4 R_ATTACHMENT_NOT_FOUND value
     * @property {number} R_ATTACHMENT_BROKEN=5 R_ATTACHMENT_BROKEN value
     * @property {number} R_NO_RIGHTS=6 R_NO_RIGHTS value
     * @property {number} R_CHAT_BLACKLISTED=7 R_CHAT_BLACKLISTED value
     * @property {number} R_YOU_BLACKLISTED=8 R_YOU_BLACKLISTED value
     * @property {number} R_FORBIDDEN_ATTACHMENT=9 R_FORBIDDEN_ATTACHMENT value
     * @property {number} R_INCORRECT_TEXT_ENTITIES=10 R_INCORRECT_TEXT_ENTITIES value
     * @property {number} R_INVALID_REPLY=11 R_INVALID_REPLY value
     * @property {number} R_UNBUILDABLE_ALBUM=30 R_UNBUILDABLE_ALBUM value
     * @property {number} R_UNKNOWN_ERROR=39 R_UNKNOWN_ERROR value
     * @property {number} R_INTERNAL_SERVER_ERROR=40 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodSendGroupedMessagesResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_OK"] = 0;
        values[valuesById[1] = "R_CHANNEL_NOT_FOUND"] = 1;
        values[valuesById[2] = "R_CHAT_NOT_FOUND"] = 2;
        values[valuesById[3] = "R_MESSAGE_TOO_LONG"] = 3;
        values[valuesById[4] = "R_ATTACHMENT_NOT_FOUND"] = 4;
        values[valuesById[5] = "R_ATTACHMENT_BROKEN"] = 5;
        values[valuesById[6] = "R_NO_RIGHTS"] = 6;
        values[valuesById[7] = "R_CHAT_BLACKLISTED"] = 7;
        values[valuesById[8] = "R_YOU_BLACKLISTED"] = 8;
        values[valuesById[9] = "R_FORBIDDEN_ATTACHMENT"] = 9;
        values[valuesById[10] = "R_INCORRECT_TEXT_ENTITIES"] = 10;
        values[valuesById[11] = "R_INVALID_REPLY"] = 11;
        values[valuesById[30] = "R_UNBUILDABLE_ALBUM"] = 30;
        values[valuesById[39] = "R_UNKNOWN_ERROR"] = 39;
        values[valuesById[40] = "R_INTERNAL_SERVER_ERROR"] = 40;
        return values;
    })();

    return MCMethodSendGroupedMessagesResponse;
})();

export const MCEditChannel = $root.MCEditChannel = (() => {

    /**
     * Properties of a MCEditChannel.
     * @exports IMCEditChannel
     * @interface IMCEditChannel
     * @property {Uint8Array|null} [channelID] MCEditChannel channelID
     * @property {IMCChannelShownFields|null} [shownFields] MCEditChannel shownFields
     * @property {IMCWebWidgetConfig|null} [webWidgetConfig] MCEditChannel webWidgetConfig
     */

    /**
     * Constructs a new MCEditChannel.
     * @exports MCEditChannel
     * @classdesc Represents a MCEditChannel.
     * @implements IMCEditChannel
     * @constructor
     * @param {IMCEditChannel=} [properties] Properties to set
     */
    function MCEditChannel(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCEditChannel channelID.
     * @member {Uint8Array} channelID
     * @memberof MCEditChannel
     * @instance
     */
    MCEditChannel.prototype.channelID = $util.newBuffer([]);

    /**
     * MCEditChannel shownFields.
     * @member {IMCChannelShownFields|null|undefined} shownFields
     * @memberof MCEditChannel
     * @instance
     */
    MCEditChannel.prototype.shownFields = null;

    /**
     * MCEditChannel webWidgetConfig.
     * @member {IMCWebWidgetConfig|null|undefined} webWidgetConfig
     * @memberof MCEditChannel
     * @instance
     */
    MCEditChannel.prototype.webWidgetConfig = null;

    /**
     * Creates a new MCEditChannel instance using the specified properties.
     * @function create
     * @memberof MCEditChannel
     * @static
     * @param {IMCEditChannel=} [properties] Properties to set
     * @returns {MCEditChannel} MCEditChannel instance
     */
    MCEditChannel.create = function create(properties) {
        return new MCEditChannel(properties);
    };

    /**
     * Encodes the specified MCEditChannel message. Does not implicitly {@link MCEditChannel.verify|verify} messages.
     * @function encode
     * @memberof MCEditChannel
     * @static
     * @param {IMCEditChannel} message MCEditChannel message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCEditChannel.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.shownFields != null && Object.hasOwnProperty.call(message, "shownFields"))
            $root.MCChannelShownFields.encode(message.shownFields, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.webWidgetConfig != null && Object.hasOwnProperty.call(message, "webWidgetConfig"))
            $root.MCWebWidgetConfig.encode(message.webWidgetConfig, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCEditChannel message, length delimited. Does not implicitly {@link MCEditChannel.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCEditChannel
     * @static
     * @param {IMCEditChannel} message MCEditChannel message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCEditChannel.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCEditChannel message from the specified reader or buffer.
     * @function decode
     * @memberof MCEditChannel
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCEditChannel} MCEditChannel
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCEditChannel.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCEditChannel();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.shownFields = $root.MCChannelShownFields.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.webWidgetConfig = $root.MCWebWidgetConfig.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCEditChannel message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCEditChannel
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCEditChannel} MCEditChannel
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCEditChannel.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCEditChannel message.
     * @function verify
     * @memberof MCEditChannel
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCEditChannel.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.shownFields != null && message.hasOwnProperty("shownFields")) {
            let error = $root.MCChannelShownFields.verify(message.shownFields);
            if (error)
                return "shownFields." + error;
        }
        if (message.webWidgetConfig != null && message.hasOwnProperty("webWidgetConfig")) {
            let error = $root.MCWebWidgetConfig.verify(message.webWidgetConfig);
            if (error)
                return "webWidgetConfig." + error;
        }
        return null;
    };

    /**
     * Creates a MCEditChannel message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCEditChannel
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCEditChannel} MCEditChannel
     */
    MCEditChannel.fromObject = function fromObject(object) {
        if (object instanceof $root.MCEditChannel)
            return object;
        let message = new $root.MCEditChannel();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.shownFields != null) {
            if (typeof object.shownFields !== "object")
                throw TypeError(".MCEditChannel.shownFields: object expected");
            message.shownFields = $root.MCChannelShownFields.fromObject(object.shownFields);
        }
        if (object.webWidgetConfig != null) {
            if (typeof object.webWidgetConfig !== "object")
                throw TypeError(".MCEditChannel.webWidgetConfig: object expected");
            message.webWidgetConfig = $root.MCWebWidgetConfig.fromObject(object.webWidgetConfig);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCEditChannel message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCEditChannel
     * @static
     * @param {MCEditChannel} message MCEditChannel
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCEditChannel.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            object.shownFields = null;
            object.webWidgetConfig = null;
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.shownFields != null && message.hasOwnProperty("shownFields"))
            object.shownFields = $root.MCChannelShownFields.toObject(message.shownFields, options);
        if (message.webWidgetConfig != null && message.hasOwnProperty("webWidgetConfig"))
            object.webWidgetConfig = $root.MCWebWidgetConfig.toObject(message.webWidgetConfig, options);
        return object;
    };

    /**
     * Converts this MCEditChannel to JSON.
     * @function toJSON
     * @memberof MCEditChannel
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCEditChannel.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCEditChannel
     * @function getTypeUrl
     * @memberof MCEditChannel
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCEditChannel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCEditChannel";
    };

    return MCEditChannel;
})();

export const MCEditChannelResponse = $root.MCEditChannelResponse = (() => {

    /**
     * Properties of a MCEditChannelResponse.
     * @exports IMCEditChannelResponse
     * @interface IMCEditChannelResponse
     * @property {MCEditChannelResponse.Result|null} [result] MCEditChannelResponse result
     */

    /**
     * Constructs a new MCEditChannelResponse.
     * @exports MCEditChannelResponse
     * @classdesc Represents a MCEditChannelResponse.
     * @implements IMCEditChannelResponse
     * @constructor
     * @param {IMCEditChannelResponse=} [properties] Properties to set
     */
    function MCEditChannelResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCEditChannelResponse result.
     * @member {MCEditChannelResponse.Result} result
     * @memberof MCEditChannelResponse
     * @instance
     */
    MCEditChannelResponse.prototype.result = 0;

    /**
     * Creates a new MCEditChannelResponse instance using the specified properties.
     * @function create
     * @memberof MCEditChannelResponse
     * @static
     * @param {IMCEditChannelResponse=} [properties] Properties to set
     * @returns {MCEditChannelResponse} MCEditChannelResponse instance
     */
    MCEditChannelResponse.create = function create(properties) {
        return new MCEditChannelResponse(properties);
    };

    /**
     * Encodes the specified MCEditChannelResponse message. Does not implicitly {@link MCEditChannelResponse.verify|verify} messages.
     * @function encode
     * @memberof MCEditChannelResponse
     * @static
     * @param {IMCEditChannelResponse} message MCEditChannelResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCEditChannelResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        return writer;
    };

    /**
     * Encodes the specified MCEditChannelResponse message, length delimited. Does not implicitly {@link MCEditChannelResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCEditChannelResponse
     * @static
     * @param {IMCEditChannelResponse} message MCEditChannelResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCEditChannelResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCEditChannelResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCEditChannelResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCEditChannelResponse} MCEditChannelResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCEditChannelResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCEditChannelResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCEditChannelResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCEditChannelResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCEditChannelResponse} MCEditChannelResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCEditChannelResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCEditChannelResponse message.
     * @function verify
     * @memberof MCEditChannelResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCEditChannelResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 10:
                break;
            }
        return null;
    };

    /**
     * Creates a MCEditChannelResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCEditChannelResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCEditChannelResponse} MCEditChannelResponse
     */
    MCEditChannelResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCEditChannelResponse)
            return object;
        let message = new $root.MCEditChannelResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_UNKNOWN":
        case 0:
            message.result = 0;
            break;
        case "R_OK":
        case 1:
            message.result = 1;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a MCEditChannelResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCEditChannelResponse
     * @static
     * @param {MCEditChannelResponse} message MCEditChannelResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCEditChannelResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.result = options.enums === String ? "R_UNKNOWN" : 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCEditChannelResponse.Result[message.result] === undefined ? message.result : $root.MCEditChannelResponse.Result[message.result] : message.result;
        return object;
    };

    /**
     * Converts this MCEditChannelResponse to JSON.
     * @function toJSON
     * @memberof MCEditChannelResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCEditChannelResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCEditChannelResponse
     * @function getTypeUrl
     * @memberof MCEditChannelResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCEditChannelResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCEditChannelResponse";
    };

    /**
     * Result enum.
     * @name MCEditChannelResponse.Result
     * @enum {number}
     * @property {number} R_UNKNOWN=0 R_UNKNOWN value
     * @property {number} R_OK=1 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=2 R_CHANNEL_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCEditChannelResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_UNKNOWN"] = 0;
        values[valuesById[1] = "R_OK"] = 1;
        values[valuesById[2] = "R_CHANNEL_NOT_FOUND"] = 2;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCEditChannelResponse;
})();

export const MCDropChannel = $root.MCDropChannel = (() => {

    /**
     * Properties of a MCDropChannel.
     * @exports IMCDropChannel
     * @interface IMCDropChannel
     * @property {Uint8Array|null} [channelID] MCDropChannel channelID
     */

    /**
     * Constructs a new MCDropChannel.
     * @exports MCDropChannel
     * @classdesc Represents a MCDropChannel.
     * @implements IMCDropChannel
     * @constructor
     * @param {IMCDropChannel=} [properties] Properties to set
     */
    function MCDropChannel(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCDropChannel channelID.
     * @member {Uint8Array} channelID
     * @memberof MCDropChannel
     * @instance
     */
    MCDropChannel.prototype.channelID = $util.newBuffer([]);

    /**
     * Creates a new MCDropChannel instance using the specified properties.
     * @function create
     * @memberof MCDropChannel
     * @static
     * @param {IMCDropChannel=} [properties] Properties to set
     * @returns {MCDropChannel} MCDropChannel instance
     */
    MCDropChannel.create = function create(properties) {
        return new MCDropChannel(properties);
    };

    /**
     * Encodes the specified MCDropChannel message. Does not implicitly {@link MCDropChannel.verify|verify} messages.
     * @function encode
     * @memberof MCDropChannel
     * @static
     * @param {IMCDropChannel} message MCDropChannel message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCDropChannel.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        return writer;
    };

    /**
     * Encodes the specified MCDropChannel message, length delimited. Does not implicitly {@link MCDropChannel.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCDropChannel
     * @static
     * @param {IMCDropChannel} message MCDropChannel message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCDropChannel.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCDropChannel message from the specified reader or buffer.
     * @function decode
     * @memberof MCDropChannel
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCDropChannel} MCDropChannel
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCDropChannel.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCDropChannel();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCDropChannel message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCDropChannel
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCDropChannel} MCDropChannel
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCDropChannel.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCDropChannel message.
     * @function verify
     * @memberof MCDropChannel
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCDropChannel.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        return null;
    };

    /**
     * Creates a MCDropChannel message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCDropChannel
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCDropChannel} MCDropChannel
     */
    MCDropChannel.fromObject = function fromObject(object) {
        if (object instanceof $root.MCDropChannel)
            return object;
        let message = new $root.MCDropChannel();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        return message;
    };

    /**
     * Creates a plain object from a MCDropChannel message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCDropChannel
     * @static
     * @param {MCDropChannel} message MCDropChannel
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCDropChannel.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        return object;
    };

    /**
     * Converts this MCDropChannel to JSON.
     * @function toJSON
     * @memberof MCDropChannel
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCDropChannel.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCDropChannel
     * @function getTypeUrl
     * @memberof MCDropChannel
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCDropChannel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCDropChannel";
    };

    return MCDropChannel;
})();

export const MCDropChannelResponse = $root.MCDropChannelResponse = (() => {

    /**
     * Properties of a MCDropChannelResponse.
     * @exports IMCDropChannelResponse
     * @interface IMCDropChannelResponse
     * @property {MCDropChannelResponse.Result|null} [result] MCDropChannelResponse result
     */

    /**
     * Constructs a new MCDropChannelResponse.
     * @exports MCDropChannelResponse
     * @classdesc Represents a MCDropChannelResponse.
     * @implements IMCDropChannelResponse
     * @constructor
     * @param {IMCDropChannelResponse=} [properties] Properties to set
     */
    function MCDropChannelResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCDropChannelResponse result.
     * @member {MCDropChannelResponse.Result} result
     * @memberof MCDropChannelResponse
     * @instance
     */
    MCDropChannelResponse.prototype.result = 0;

    /**
     * Creates a new MCDropChannelResponse instance using the specified properties.
     * @function create
     * @memberof MCDropChannelResponse
     * @static
     * @param {IMCDropChannelResponse=} [properties] Properties to set
     * @returns {MCDropChannelResponse} MCDropChannelResponse instance
     */
    MCDropChannelResponse.create = function create(properties) {
        return new MCDropChannelResponse(properties);
    };

    /**
     * Encodes the specified MCDropChannelResponse message. Does not implicitly {@link MCDropChannelResponse.verify|verify} messages.
     * @function encode
     * @memberof MCDropChannelResponse
     * @static
     * @param {IMCDropChannelResponse} message MCDropChannelResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCDropChannelResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        return writer;
    };

    /**
     * Encodes the specified MCDropChannelResponse message, length delimited. Does not implicitly {@link MCDropChannelResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCDropChannelResponse
     * @static
     * @param {IMCDropChannelResponse} message MCDropChannelResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCDropChannelResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCDropChannelResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCDropChannelResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCDropChannelResponse} MCDropChannelResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCDropChannelResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCDropChannelResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCDropChannelResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCDropChannelResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCDropChannelResponse} MCDropChannelResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCDropChannelResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCDropChannelResponse message.
     * @function verify
     * @memberof MCDropChannelResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCDropChannelResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 10:
                break;
            }
        return null;
    };

    /**
     * Creates a MCDropChannelResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCDropChannelResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCDropChannelResponse} MCDropChannelResponse
     */
    MCDropChannelResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCDropChannelResponse)
            return object;
        let message = new $root.MCDropChannelResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_UNKNOWN":
        case 0:
            message.result = 0;
            break;
        case "R_OK":
        case 1:
            message.result = 1;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a MCDropChannelResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCDropChannelResponse
     * @static
     * @param {MCDropChannelResponse} message MCDropChannelResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCDropChannelResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.result = options.enums === String ? "R_UNKNOWN" : 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCDropChannelResponse.Result[message.result] === undefined ? message.result : $root.MCDropChannelResponse.Result[message.result] : message.result;
        return object;
    };

    /**
     * Converts this MCDropChannelResponse to JSON.
     * @function toJSON
     * @memberof MCDropChannelResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCDropChannelResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCDropChannelResponse
     * @function getTypeUrl
     * @memberof MCDropChannelResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCDropChannelResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCDropChannelResponse";
    };

    /**
     * Result enum.
     * @name MCDropChannelResponse.Result
     * @enum {number}
     * @property {number} R_UNKNOWN=0 R_UNKNOWN value
     * @property {number} R_OK=1 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=2 R_CHANNEL_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCDropChannelResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_UNKNOWN"] = 0;
        values[valuesById[1] = "R_OK"] = 1;
        values[valuesById[2] = "R_CHANNEL_NOT_FOUND"] = 2;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCDropChannelResponse;
})();

export const MCMethodSearchMessages = $root.MCMethodSearchMessages = (() => {

    /**
     * Properties of a MCMethodSearchMessages.
     * @exports IMCMethodSearchMessages
     * @interface IMCMethodSearchMessages
     * @property {Uint8Array|null} [channelID] MCMethodSearchMessages channelID
     * @property {Long|null} [chatID] MCMethodSearchMessages chatID
     * @property {string|null} [query] MCMethodSearchMessages query
     * @property {Array.<IMCChannelWithAccess>|null} [channels] MCMethodSearchMessages channels
     */

    /**
     * Constructs a new MCMethodSearchMessages.
     * @exports MCMethodSearchMessages
     * @classdesc Represents a MCMethodSearchMessages.
     * @implements IMCMethodSearchMessages
     * @constructor
     * @param {IMCMethodSearchMessages=} [properties] Properties to set
     */
    function MCMethodSearchMessages(properties) {
        this.channels = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodSearchMessages channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodSearchMessages
     * @instance
     */
    MCMethodSearchMessages.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodSearchMessages chatID.
     * @member {Long} chatID
     * @memberof MCMethodSearchMessages
     * @instance
     */
    MCMethodSearchMessages.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodSearchMessages query.
     * @member {string} query
     * @memberof MCMethodSearchMessages
     * @instance
     */
    MCMethodSearchMessages.prototype.query = "";

    /**
     * MCMethodSearchMessages channels.
     * @member {Array.<IMCChannelWithAccess>} channels
     * @memberof MCMethodSearchMessages
     * @instance
     */
    MCMethodSearchMessages.prototype.channels = $util.emptyArray;

    /**
     * Creates a new MCMethodSearchMessages instance using the specified properties.
     * @function create
     * @memberof MCMethodSearchMessages
     * @static
     * @param {IMCMethodSearchMessages=} [properties] Properties to set
     * @returns {MCMethodSearchMessages} MCMethodSearchMessages instance
     */
    MCMethodSearchMessages.create = function create(properties) {
        return new MCMethodSearchMessages(properties);
    };

    /**
     * Encodes the specified MCMethodSearchMessages message. Does not implicitly {@link MCMethodSearchMessages.verify|verify} messages.
     * @function encode
     * @memberof MCMethodSearchMessages
     * @static
     * @param {IMCMethodSearchMessages} message MCMethodSearchMessages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSearchMessages.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chatID);
        if (message.query != null && Object.hasOwnProperty.call(message, "query"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.query);
        if (message.channels != null && message.channels.length)
            for (let i = 0; i < message.channels.length; ++i)
                $root.MCChannelWithAccess.encode(message.channels[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodSearchMessages message, length delimited. Does not implicitly {@link MCMethodSearchMessages.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodSearchMessages
     * @static
     * @param {IMCMethodSearchMessages} message MCMethodSearchMessages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSearchMessages.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodSearchMessages message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodSearchMessages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodSearchMessages} MCMethodSearchMessages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSearchMessages.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodSearchMessages();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 3: {
                    message.query = reader.string();
                    break;
                }
            case 12: {
                    if (!(message.channels && message.channels.length))
                        message.channels = [];
                    message.channels.push($root.MCChannelWithAccess.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodSearchMessages message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodSearchMessages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodSearchMessages} MCMethodSearchMessages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSearchMessages.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodSearchMessages message.
     * @function verify
     * @memberof MCMethodSearchMessages
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodSearchMessages.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.query != null && message.hasOwnProperty("query"))
            if (!$util.isString(message.query))
                return "query: string expected";
        if (message.channels != null && message.hasOwnProperty("channels")) {
            if (!Array.isArray(message.channels))
                return "channels: array expected";
            for (let i = 0; i < message.channels.length; ++i) {
                let error = $root.MCChannelWithAccess.verify(message.channels[i]);
                if (error)
                    return "channels." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCMethodSearchMessages message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodSearchMessages
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodSearchMessages} MCMethodSearchMessages
     */
    MCMethodSearchMessages.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodSearchMessages)
            return object;
        let message = new $root.MCMethodSearchMessages();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.query != null)
            message.query = String(object.query);
        if (object.channels) {
            if (!Array.isArray(object.channels))
                throw TypeError(".MCMethodSearchMessages.channels: array expected");
            message.channels = [];
            for (let i = 0; i < object.channels.length; ++i) {
                if (typeof object.channels[i] !== "object")
                    throw TypeError(".MCMethodSearchMessages.channels: object expected");
                message.channels[i] = $root.MCChannelWithAccess.fromObject(object.channels[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodSearchMessages message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodSearchMessages
     * @static
     * @param {MCMethodSearchMessages} message MCMethodSearchMessages
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodSearchMessages.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.channels = [];
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
            object.query = "";
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.query != null && message.hasOwnProperty("query"))
            object.query = message.query;
        if (message.channels && message.channels.length) {
            object.channels = [];
            for (let j = 0; j < message.channels.length; ++j)
                object.channels[j] = $root.MCChannelWithAccess.toObject(message.channels[j], options);
        }
        return object;
    };

    /**
     * Converts this MCMethodSearchMessages to JSON.
     * @function toJSON
     * @memberof MCMethodSearchMessages
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodSearchMessages.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodSearchMessages
     * @function getTypeUrl
     * @memberof MCMethodSearchMessages
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodSearchMessages.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodSearchMessages";
    };

    return MCMethodSearchMessages;
})();

export const MCMethodSearchMessagesResponse = $root.MCMethodSearchMessagesResponse = (() => {

    /**
     * Properties of a MCMethodSearchMessagesResponse.
     * @exports IMCMethodSearchMessagesResponse
     * @interface IMCMethodSearchMessagesResponse
     * @property {MCMethodSearchMessagesResponse.Result|null} [result] MCMethodSearchMessagesResponse result
     * @property {Array.<IMCMessage>|null} [messages] MCMethodSearchMessagesResponse messages
     * @property {Array.<IMCChatPreview>|null} [mentionedChats] MCMethodSearchMessagesResponse mentionedChats
     */

    /**
     * Constructs a new MCMethodSearchMessagesResponse.
     * @exports MCMethodSearchMessagesResponse
     * @classdesc Represents a MCMethodSearchMessagesResponse.
     * @implements IMCMethodSearchMessagesResponse
     * @constructor
     * @param {IMCMethodSearchMessagesResponse=} [properties] Properties to set
     */
    function MCMethodSearchMessagesResponse(properties) {
        this.messages = [];
        this.mentionedChats = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodSearchMessagesResponse result.
     * @member {MCMethodSearchMessagesResponse.Result} result
     * @memberof MCMethodSearchMessagesResponse
     * @instance
     */
    MCMethodSearchMessagesResponse.prototype.result = 0;

    /**
     * MCMethodSearchMessagesResponse messages.
     * @member {Array.<IMCMessage>} messages
     * @memberof MCMethodSearchMessagesResponse
     * @instance
     */
    MCMethodSearchMessagesResponse.prototype.messages = $util.emptyArray;

    /**
     * MCMethodSearchMessagesResponse mentionedChats.
     * @member {Array.<IMCChatPreview>} mentionedChats
     * @memberof MCMethodSearchMessagesResponse
     * @instance
     */
    MCMethodSearchMessagesResponse.prototype.mentionedChats = $util.emptyArray;

    /**
     * Creates a new MCMethodSearchMessagesResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodSearchMessagesResponse
     * @static
     * @param {IMCMethodSearchMessagesResponse=} [properties] Properties to set
     * @returns {MCMethodSearchMessagesResponse} MCMethodSearchMessagesResponse instance
     */
    MCMethodSearchMessagesResponse.create = function create(properties) {
        return new MCMethodSearchMessagesResponse(properties);
    };

    /**
     * Encodes the specified MCMethodSearchMessagesResponse message. Does not implicitly {@link MCMethodSearchMessagesResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodSearchMessagesResponse
     * @static
     * @param {IMCMethodSearchMessagesResponse} message MCMethodSearchMessagesResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSearchMessagesResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.messages != null && message.messages.length)
            for (let i = 0; i < message.messages.length; ++i)
                $root.MCMessage.encode(message.messages[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.mentionedChats != null && message.mentionedChats.length)
            for (let i = 0; i < message.mentionedChats.length; ++i)
                $root.MCChatPreview.encode(message.mentionedChats[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodSearchMessagesResponse message, length delimited. Does not implicitly {@link MCMethodSearchMessagesResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodSearchMessagesResponse
     * @static
     * @param {IMCMethodSearchMessagesResponse} message MCMethodSearchMessagesResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSearchMessagesResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodSearchMessagesResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodSearchMessagesResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodSearchMessagesResponse} MCMethodSearchMessagesResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSearchMessagesResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodSearchMessagesResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.messages && message.messages.length))
                        message.messages = [];
                    message.messages.push($root.MCMessage.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    if (!(message.mentionedChats && message.mentionedChats.length))
                        message.mentionedChats = [];
                    message.mentionedChats.push($root.MCChatPreview.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodSearchMessagesResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodSearchMessagesResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodSearchMessagesResponse} MCMethodSearchMessagesResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSearchMessagesResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodSearchMessagesResponse message.
     * @function verify
     * @memberof MCMethodSearchMessagesResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodSearchMessagesResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 10:
                break;
            }
        if (message.messages != null && message.hasOwnProperty("messages")) {
            if (!Array.isArray(message.messages))
                return "messages: array expected";
            for (let i = 0; i < message.messages.length; ++i) {
                let error = $root.MCMessage.verify(message.messages[i]);
                if (error)
                    return "messages." + error;
            }
        }
        if (message.mentionedChats != null && message.hasOwnProperty("mentionedChats")) {
            if (!Array.isArray(message.mentionedChats))
                return "mentionedChats: array expected";
            for (let i = 0; i < message.mentionedChats.length; ++i) {
                let error = $root.MCChatPreview.verify(message.mentionedChats[i]);
                if (error)
                    return "mentionedChats." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCMethodSearchMessagesResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodSearchMessagesResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodSearchMessagesResponse} MCMethodSearchMessagesResponse
     */
    MCMethodSearchMessagesResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodSearchMessagesResponse)
            return object;
        let message = new $root.MCMethodSearchMessagesResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_OK":
        case 0:
            message.result = 0;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 1:
            message.result = 1;
            break;
        case "R_CHAT_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.messages) {
            if (!Array.isArray(object.messages))
                throw TypeError(".MCMethodSearchMessagesResponse.messages: array expected");
            message.messages = [];
            for (let i = 0; i < object.messages.length; ++i) {
                if (typeof object.messages[i] !== "object")
                    throw TypeError(".MCMethodSearchMessagesResponse.messages: object expected");
                message.messages[i] = $root.MCMessage.fromObject(object.messages[i]);
            }
        }
        if (object.mentionedChats) {
            if (!Array.isArray(object.mentionedChats))
                throw TypeError(".MCMethodSearchMessagesResponse.mentionedChats: array expected");
            message.mentionedChats = [];
            for (let i = 0; i < object.mentionedChats.length; ++i) {
                if (typeof object.mentionedChats[i] !== "object")
                    throw TypeError(".MCMethodSearchMessagesResponse.mentionedChats: object expected");
                message.mentionedChats[i] = $root.MCChatPreview.fromObject(object.mentionedChats[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodSearchMessagesResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodSearchMessagesResponse
     * @static
     * @param {MCMethodSearchMessagesResponse} message MCMethodSearchMessagesResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodSearchMessagesResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.messages = [];
            object.mentionedChats = [];
        }
        if (options.defaults)
            object.result = options.enums === String ? "R_OK" : 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodSearchMessagesResponse.Result[message.result] === undefined ? message.result : $root.MCMethodSearchMessagesResponse.Result[message.result] : message.result;
        if (message.messages && message.messages.length) {
            object.messages = [];
            for (let j = 0; j < message.messages.length; ++j)
                object.messages[j] = $root.MCMessage.toObject(message.messages[j], options);
        }
        if (message.mentionedChats && message.mentionedChats.length) {
            object.mentionedChats = [];
            for (let j = 0; j < message.mentionedChats.length; ++j)
                object.mentionedChats[j] = $root.MCChatPreview.toObject(message.mentionedChats[j], options);
        }
        return object;
    };

    /**
     * Converts this MCMethodSearchMessagesResponse to JSON.
     * @function toJSON
     * @memberof MCMethodSearchMessagesResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodSearchMessagesResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodSearchMessagesResponse
     * @function getTypeUrl
     * @memberof MCMethodSearchMessagesResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodSearchMessagesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodSearchMessagesResponse";
    };

    /**
     * Result enum.
     * @name MCMethodSearchMessagesResponse.Result
     * @enum {number}
     * @property {number} R_OK=0 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=1 R_CHANNEL_NOT_FOUND value
     * @property {number} R_CHAT_NOT_FOUND=2 R_CHAT_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodSearchMessagesResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_OK"] = 0;
        values[valuesById[1] = "R_CHANNEL_NOT_FOUND"] = 1;
        values[valuesById[2] = "R_CHAT_NOT_FOUND"] = 2;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodSearchMessagesResponse;
})();

export const MCMethodSearchChats = $root.MCMethodSearchChats = (() => {

    /**
     * Properties of a MCMethodSearchChats.
     * @exports IMCMethodSearchChats
     * @interface IMCMethodSearchChats
     * @property {Uint8Array|null} [channelID] MCMethodSearchChats channelID
     * @property {Array.<IMCChannelWithAccess>|null} [channels] MCMethodSearchChats channels
     * @property {string|null} [query] MCMethodSearchChats query
     */

    /**
     * Constructs a new MCMethodSearchChats.
     * @exports MCMethodSearchChats
     * @classdesc Represents a MCMethodSearchChats.
     * @implements IMCMethodSearchChats
     * @constructor
     * @param {IMCMethodSearchChats=} [properties] Properties to set
     */
    function MCMethodSearchChats(properties) {
        this.channels = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodSearchChats channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodSearchChats
     * @instance
     */
    MCMethodSearchChats.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodSearchChats channels.
     * @member {Array.<IMCChannelWithAccess>} channels
     * @memberof MCMethodSearchChats
     * @instance
     */
    MCMethodSearchChats.prototype.channels = $util.emptyArray;

    /**
     * MCMethodSearchChats query.
     * @member {string} query
     * @memberof MCMethodSearchChats
     * @instance
     */
    MCMethodSearchChats.prototype.query = "";

    /**
     * Creates a new MCMethodSearchChats instance using the specified properties.
     * @function create
     * @memberof MCMethodSearchChats
     * @static
     * @param {IMCMethodSearchChats=} [properties] Properties to set
     * @returns {MCMethodSearchChats} MCMethodSearchChats instance
     */
    MCMethodSearchChats.create = function create(properties) {
        return new MCMethodSearchChats(properties);
    };

    /**
     * Encodes the specified MCMethodSearchChats message. Does not implicitly {@link MCMethodSearchChats.verify|verify} messages.
     * @function encode
     * @memberof MCMethodSearchChats
     * @static
     * @param {IMCMethodSearchChats} message MCMethodSearchChats message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSearchChats.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.query != null && Object.hasOwnProperty.call(message, "query"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.query);
        if (message.channels != null && message.channels.length)
            for (let i = 0; i < message.channels.length; ++i)
                $root.MCChannelWithAccess.encode(message.channels[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodSearchChats message, length delimited. Does not implicitly {@link MCMethodSearchChats.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodSearchChats
     * @static
     * @param {IMCMethodSearchChats} message MCMethodSearchChats message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSearchChats.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodSearchChats message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodSearchChats
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodSearchChats} MCMethodSearchChats
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSearchChats.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodSearchChats();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 12: {
                    if (!(message.channels && message.channels.length))
                        message.channels = [];
                    message.channels.push($root.MCChannelWithAccess.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.query = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodSearchChats message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodSearchChats
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodSearchChats} MCMethodSearchChats
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSearchChats.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodSearchChats message.
     * @function verify
     * @memberof MCMethodSearchChats
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodSearchChats.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.channels != null && message.hasOwnProperty("channels")) {
            if (!Array.isArray(message.channels))
                return "channels: array expected";
            for (let i = 0; i < message.channels.length; ++i) {
                let error = $root.MCChannelWithAccess.verify(message.channels[i]);
                if (error)
                    return "channels." + error;
            }
        }
        if (message.query != null && message.hasOwnProperty("query"))
            if (!$util.isString(message.query))
                return "query: string expected";
        return null;
    };

    /**
     * Creates a MCMethodSearchChats message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodSearchChats
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodSearchChats} MCMethodSearchChats
     */
    MCMethodSearchChats.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodSearchChats)
            return object;
        let message = new $root.MCMethodSearchChats();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.channels) {
            if (!Array.isArray(object.channels))
                throw TypeError(".MCMethodSearchChats.channels: array expected");
            message.channels = [];
            for (let i = 0; i < object.channels.length; ++i) {
                if (typeof object.channels[i] !== "object")
                    throw TypeError(".MCMethodSearchChats.channels: object expected");
                message.channels[i] = $root.MCChannelWithAccess.fromObject(object.channels[i]);
            }
        }
        if (object.query != null)
            message.query = String(object.query);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodSearchChats message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodSearchChats
     * @static
     * @param {MCMethodSearchChats} message MCMethodSearchChats
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodSearchChats.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.channels = [];
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            object.query = "";
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.query != null && message.hasOwnProperty("query"))
            object.query = message.query;
        if (message.channels && message.channels.length) {
            object.channels = [];
            for (let j = 0; j < message.channels.length; ++j)
                object.channels[j] = $root.MCChannelWithAccess.toObject(message.channels[j], options);
        }
        return object;
    };

    /**
     * Converts this MCMethodSearchChats to JSON.
     * @function toJSON
     * @memberof MCMethodSearchChats
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodSearchChats.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodSearchChats
     * @function getTypeUrl
     * @memberof MCMethodSearchChats
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodSearchChats.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodSearchChats";
    };

    return MCMethodSearchChats;
})();

export const MCMethodSearchChatsResponse = $root.MCMethodSearchChatsResponse = (() => {

    /**
     * Properties of a MCMethodSearchChatsResponse.
     * @exports IMCMethodSearchChatsResponse
     * @interface IMCMethodSearchChatsResponse
     * @property {MCMethodSearchChatsResponse.Result|null} [result] MCMethodSearchChatsResponse result
     * @property {Array.<IMCChatPreview>|null} [chats] MCMethodSearchChatsResponse chats
     */

    /**
     * Constructs a new MCMethodSearchChatsResponse.
     * @exports MCMethodSearchChatsResponse
     * @classdesc Represents a MCMethodSearchChatsResponse.
     * @implements IMCMethodSearchChatsResponse
     * @constructor
     * @param {IMCMethodSearchChatsResponse=} [properties] Properties to set
     */
    function MCMethodSearchChatsResponse(properties) {
        this.chats = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodSearchChatsResponse result.
     * @member {MCMethodSearchChatsResponse.Result} result
     * @memberof MCMethodSearchChatsResponse
     * @instance
     */
    MCMethodSearchChatsResponse.prototype.result = 0;

    /**
     * MCMethodSearchChatsResponse chats.
     * @member {Array.<IMCChatPreview>} chats
     * @memberof MCMethodSearchChatsResponse
     * @instance
     */
    MCMethodSearchChatsResponse.prototype.chats = $util.emptyArray;

    /**
     * Creates a new MCMethodSearchChatsResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodSearchChatsResponse
     * @static
     * @param {IMCMethodSearchChatsResponse=} [properties] Properties to set
     * @returns {MCMethodSearchChatsResponse} MCMethodSearchChatsResponse instance
     */
    MCMethodSearchChatsResponse.create = function create(properties) {
        return new MCMethodSearchChatsResponse(properties);
    };

    /**
     * Encodes the specified MCMethodSearchChatsResponse message. Does not implicitly {@link MCMethodSearchChatsResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodSearchChatsResponse
     * @static
     * @param {IMCMethodSearchChatsResponse} message MCMethodSearchChatsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSearchChatsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.chats != null && message.chats.length)
            for (let i = 0; i < message.chats.length; ++i)
                $root.MCChatPreview.encode(message.chats[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodSearchChatsResponse message, length delimited. Does not implicitly {@link MCMethodSearchChatsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodSearchChatsResponse
     * @static
     * @param {IMCMethodSearchChatsResponse} message MCMethodSearchChatsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSearchChatsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodSearchChatsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodSearchChatsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodSearchChatsResponse} MCMethodSearchChatsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSearchChatsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodSearchChatsResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.chats && message.chats.length))
                        message.chats = [];
                    message.chats.push($root.MCChatPreview.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodSearchChatsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodSearchChatsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodSearchChatsResponse} MCMethodSearchChatsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSearchChatsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodSearchChatsResponse message.
     * @function verify
     * @memberof MCMethodSearchChatsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodSearchChatsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 10:
                break;
            }
        if (message.chats != null && message.hasOwnProperty("chats")) {
            if (!Array.isArray(message.chats))
                return "chats: array expected";
            for (let i = 0; i < message.chats.length; ++i) {
                let error = $root.MCChatPreview.verify(message.chats[i]);
                if (error)
                    return "chats." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCMethodSearchChatsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodSearchChatsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodSearchChatsResponse} MCMethodSearchChatsResponse
     */
    MCMethodSearchChatsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodSearchChatsResponse)
            return object;
        let message = new $root.MCMethodSearchChatsResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_OK":
        case 0:
            message.result = 0;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.chats) {
            if (!Array.isArray(object.chats))
                throw TypeError(".MCMethodSearchChatsResponse.chats: array expected");
            message.chats = [];
            for (let i = 0; i < object.chats.length; ++i) {
                if (typeof object.chats[i] !== "object")
                    throw TypeError(".MCMethodSearchChatsResponse.chats: object expected");
                message.chats[i] = $root.MCChatPreview.fromObject(object.chats[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodSearchChatsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodSearchChatsResponse
     * @static
     * @param {MCMethodSearchChatsResponse} message MCMethodSearchChatsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodSearchChatsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.chats = [];
        if (options.defaults)
            object.result = options.enums === String ? "R_OK" : 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodSearchChatsResponse.Result[message.result] === undefined ? message.result : $root.MCMethodSearchChatsResponse.Result[message.result] : message.result;
        if (message.chats && message.chats.length) {
            object.chats = [];
            for (let j = 0; j < message.chats.length; ++j)
                object.chats[j] = $root.MCChatPreview.toObject(message.chats[j], options);
        }
        return object;
    };

    /**
     * Converts this MCMethodSearchChatsResponse to JSON.
     * @function toJSON
     * @memberof MCMethodSearchChatsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodSearchChatsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodSearchChatsResponse
     * @function getTypeUrl
     * @memberof MCMethodSearchChatsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodSearchChatsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodSearchChatsResponse";
    };

    /**
     * Result enum.
     * @name MCMethodSearchChatsResponse.Result
     * @enum {number}
     * @property {number} R_OK=0 R_OK value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodSearchChatsResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_OK"] = 0;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodSearchChatsResponse;
})();

export const MCMethodMarkReadAll = $root.MCMethodMarkReadAll = (() => {

    /**
     * Properties of a MCMethodMarkReadAll.
     * @exports IMCMethodMarkReadAll
     * @interface IMCMethodMarkReadAll
     * @property {Array.<Uint8Array>|null} [channelIDs] MCMethodMarkReadAll channelIDs
     */

    /**
     * Constructs a new MCMethodMarkReadAll.
     * @exports MCMethodMarkReadAll
     * @classdesc Represents a MCMethodMarkReadAll.
     * @implements IMCMethodMarkReadAll
     * @constructor
     * @param {IMCMethodMarkReadAll=} [properties] Properties to set
     */
    function MCMethodMarkReadAll(properties) {
        this.channelIDs = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodMarkReadAll channelIDs.
     * @member {Array.<Uint8Array>} channelIDs
     * @memberof MCMethodMarkReadAll
     * @instance
     */
    MCMethodMarkReadAll.prototype.channelIDs = $util.emptyArray;

    /**
     * Creates a new MCMethodMarkReadAll instance using the specified properties.
     * @function create
     * @memberof MCMethodMarkReadAll
     * @static
     * @param {IMCMethodMarkReadAll=} [properties] Properties to set
     * @returns {MCMethodMarkReadAll} MCMethodMarkReadAll instance
     */
    MCMethodMarkReadAll.create = function create(properties) {
        return new MCMethodMarkReadAll(properties);
    };

    /**
     * Encodes the specified MCMethodMarkReadAll message. Does not implicitly {@link MCMethodMarkReadAll.verify|verify} messages.
     * @function encode
     * @memberof MCMethodMarkReadAll
     * @static
     * @param {IMCMethodMarkReadAll} message MCMethodMarkReadAll message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodMarkReadAll.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelIDs != null && message.channelIDs.length)
            for (let i = 0; i < message.channelIDs.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelIDs[i]);
        return writer;
    };

    /**
     * Encodes the specified MCMethodMarkReadAll message, length delimited. Does not implicitly {@link MCMethodMarkReadAll.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodMarkReadAll
     * @static
     * @param {IMCMethodMarkReadAll} message MCMethodMarkReadAll message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodMarkReadAll.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodMarkReadAll message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodMarkReadAll
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodMarkReadAll} MCMethodMarkReadAll
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodMarkReadAll.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodMarkReadAll();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.channelIDs && message.channelIDs.length))
                        message.channelIDs = [];
                    message.channelIDs.push(reader.bytes());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodMarkReadAll message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodMarkReadAll
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodMarkReadAll} MCMethodMarkReadAll
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodMarkReadAll.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodMarkReadAll message.
     * @function verify
     * @memberof MCMethodMarkReadAll
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodMarkReadAll.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelIDs != null && message.hasOwnProperty("channelIDs")) {
            if (!Array.isArray(message.channelIDs))
                return "channelIDs: array expected";
            for (let i = 0; i < message.channelIDs.length; ++i)
                if (!(message.channelIDs[i] && typeof message.channelIDs[i].length === "number" || $util.isString(message.channelIDs[i])))
                    return "channelIDs: buffer[] expected";
        }
        return null;
    };

    /**
     * Creates a MCMethodMarkReadAll message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodMarkReadAll
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodMarkReadAll} MCMethodMarkReadAll
     */
    MCMethodMarkReadAll.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodMarkReadAll)
            return object;
        let message = new $root.MCMethodMarkReadAll();
        if (object.channelIDs) {
            if (!Array.isArray(object.channelIDs))
                throw TypeError(".MCMethodMarkReadAll.channelIDs: array expected");
            message.channelIDs = [];
            for (let i = 0; i < object.channelIDs.length; ++i)
                if (typeof object.channelIDs[i] === "string")
                    $util.base64.decode(object.channelIDs[i], message.channelIDs[i] = $util.newBuffer($util.base64.length(object.channelIDs[i])), 0);
                else if (object.channelIDs[i].length >= 0)
                    message.channelIDs[i] = object.channelIDs[i];
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodMarkReadAll message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodMarkReadAll
     * @static
     * @param {MCMethodMarkReadAll} message MCMethodMarkReadAll
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodMarkReadAll.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.channelIDs = [];
        if (message.channelIDs && message.channelIDs.length) {
            object.channelIDs = [];
            for (let j = 0; j < message.channelIDs.length; ++j)
                object.channelIDs[j] = options.bytes === String ? $util.base64.encode(message.channelIDs[j], 0, message.channelIDs[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.channelIDs[j]) : message.channelIDs[j];
        }
        return object;
    };

    /**
     * Converts this MCMethodMarkReadAll to JSON.
     * @function toJSON
     * @memberof MCMethodMarkReadAll
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodMarkReadAll.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodMarkReadAll
     * @function getTypeUrl
     * @memberof MCMethodMarkReadAll
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodMarkReadAll.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodMarkReadAll";
    };

    return MCMethodMarkReadAll;
})();

export const MCMethodMarkReadAllResponse = $root.MCMethodMarkReadAllResponse = (() => {

    /**
     * Properties of a MCMethodMarkReadAllResponse.
     * @exports IMCMethodMarkReadAllResponse
     * @interface IMCMethodMarkReadAllResponse
     * @property {MCMethodMarkReadAllResponse.Result|null} [result] MCMethodMarkReadAllResponse result
     */

    /**
     * Constructs a new MCMethodMarkReadAllResponse.
     * @exports MCMethodMarkReadAllResponse
     * @classdesc Represents a MCMethodMarkReadAllResponse.
     * @implements IMCMethodMarkReadAllResponse
     * @constructor
     * @param {IMCMethodMarkReadAllResponse=} [properties] Properties to set
     */
    function MCMethodMarkReadAllResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodMarkReadAllResponse result.
     * @member {MCMethodMarkReadAllResponse.Result} result
     * @memberof MCMethodMarkReadAllResponse
     * @instance
     */
    MCMethodMarkReadAllResponse.prototype.result = 0;

    /**
     * Creates a new MCMethodMarkReadAllResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodMarkReadAllResponse
     * @static
     * @param {IMCMethodMarkReadAllResponse=} [properties] Properties to set
     * @returns {MCMethodMarkReadAllResponse} MCMethodMarkReadAllResponse instance
     */
    MCMethodMarkReadAllResponse.create = function create(properties) {
        return new MCMethodMarkReadAllResponse(properties);
    };

    /**
     * Encodes the specified MCMethodMarkReadAllResponse message. Does not implicitly {@link MCMethodMarkReadAllResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodMarkReadAllResponse
     * @static
     * @param {IMCMethodMarkReadAllResponse} message MCMethodMarkReadAllResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodMarkReadAllResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        return writer;
    };

    /**
     * Encodes the specified MCMethodMarkReadAllResponse message, length delimited. Does not implicitly {@link MCMethodMarkReadAllResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodMarkReadAllResponse
     * @static
     * @param {IMCMethodMarkReadAllResponse} message MCMethodMarkReadAllResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodMarkReadAllResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodMarkReadAllResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodMarkReadAllResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodMarkReadAllResponse} MCMethodMarkReadAllResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodMarkReadAllResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodMarkReadAllResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodMarkReadAllResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodMarkReadAllResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodMarkReadAllResponse} MCMethodMarkReadAllResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodMarkReadAllResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodMarkReadAllResponse message.
     * @function verify
     * @memberof MCMethodMarkReadAllResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodMarkReadAllResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 10:
                break;
            }
        return null;
    };

    /**
     * Creates a MCMethodMarkReadAllResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodMarkReadAllResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodMarkReadAllResponse} MCMethodMarkReadAllResponse
     */
    MCMethodMarkReadAllResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodMarkReadAllResponse)
            return object;
        let message = new $root.MCMethodMarkReadAllResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_UNKNOWN":
        case 0:
            message.result = 0;
            break;
        case "R_OK":
        case 1:
            message.result = 1;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodMarkReadAllResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodMarkReadAllResponse
     * @static
     * @param {MCMethodMarkReadAllResponse} message MCMethodMarkReadAllResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodMarkReadAllResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.result = options.enums === String ? "R_UNKNOWN" : 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodMarkReadAllResponse.Result[message.result] === undefined ? message.result : $root.MCMethodMarkReadAllResponse.Result[message.result] : message.result;
        return object;
    };

    /**
     * Converts this MCMethodMarkReadAllResponse to JSON.
     * @function toJSON
     * @memberof MCMethodMarkReadAllResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodMarkReadAllResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodMarkReadAllResponse
     * @function getTypeUrl
     * @memberof MCMethodMarkReadAllResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodMarkReadAllResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodMarkReadAllResponse";
    };

    /**
     * Result enum.
     * @name MCMethodMarkReadAllResponse.Result
     * @enum {number}
     * @property {number} R_UNKNOWN=0 R_UNKNOWN value
     * @property {number} R_OK=1 R_OK value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodMarkReadAllResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_UNKNOWN"] = 0;
        values[valuesById[1] = "R_OK"] = 1;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodMarkReadAllResponse;
})();

export const MCMethodDeleteChat = $root.MCMethodDeleteChat = (() => {

    /**
     * Properties of a MCMethodDeleteChat.
     * @exports IMCMethodDeleteChat
     * @interface IMCMethodDeleteChat
     * @property {Uint8Array|null} [channelID] MCMethodDeleteChat channelID
     * @property {Long|null} [chatID] MCMethodDeleteChat chatID
     */

    /**
     * Constructs a new MCMethodDeleteChat.
     * @exports MCMethodDeleteChat
     * @classdesc Represents a MCMethodDeleteChat.
     * @implements IMCMethodDeleteChat
     * @constructor
     * @param {IMCMethodDeleteChat=} [properties] Properties to set
     */
    function MCMethodDeleteChat(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodDeleteChat channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodDeleteChat
     * @instance
     */
    MCMethodDeleteChat.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodDeleteChat chatID.
     * @member {Long} chatID
     * @memberof MCMethodDeleteChat
     * @instance
     */
    MCMethodDeleteChat.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCMethodDeleteChat instance using the specified properties.
     * @function create
     * @memberof MCMethodDeleteChat
     * @static
     * @param {IMCMethodDeleteChat=} [properties] Properties to set
     * @returns {MCMethodDeleteChat} MCMethodDeleteChat instance
     */
    MCMethodDeleteChat.create = function create(properties) {
        return new MCMethodDeleteChat(properties);
    };

    /**
     * Encodes the specified MCMethodDeleteChat message. Does not implicitly {@link MCMethodDeleteChat.verify|verify} messages.
     * @function encode
     * @memberof MCMethodDeleteChat
     * @static
     * @param {IMCMethodDeleteChat} message MCMethodDeleteChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodDeleteChat.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chatID);
        return writer;
    };

    /**
     * Encodes the specified MCMethodDeleteChat message, length delimited. Does not implicitly {@link MCMethodDeleteChat.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodDeleteChat
     * @static
     * @param {IMCMethodDeleteChat} message MCMethodDeleteChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodDeleteChat.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodDeleteChat message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodDeleteChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodDeleteChat} MCMethodDeleteChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodDeleteChat.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodDeleteChat();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.chatID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodDeleteChat message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodDeleteChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodDeleteChat} MCMethodDeleteChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodDeleteChat.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodDeleteChat message.
     * @function verify
     * @memberof MCMethodDeleteChat
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodDeleteChat.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCMethodDeleteChat message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodDeleteChat
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodDeleteChat} MCMethodDeleteChat
     */
    MCMethodDeleteChat.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodDeleteChat)
            return object;
        let message = new $root.MCMethodDeleteChat();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodDeleteChat message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodDeleteChat
     * @static
     * @param {MCMethodDeleteChat} message MCMethodDeleteChat
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodDeleteChat.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        return object;
    };

    /**
     * Converts this MCMethodDeleteChat to JSON.
     * @function toJSON
     * @memberof MCMethodDeleteChat
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodDeleteChat.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodDeleteChat
     * @function getTypeUrl
     * @memberof MCMethodDeleteChat
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodDeleteChat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodDeleteChat";
    };

    return MCMethodDeleteChat;
})();

export const MCMethodDeleteChatResponse = $root.MCMethodDeleteChatResponse = (() => {

    /**
     * Properties of a MCMethodDeleteChatResponse.
     * @exports IMCMethodDeleteChatResponse
     * @interface IMCMethodDeleteChatResponse
     * @property {MCMethodDeleteChatResponse.Result|null} [result] MCMethodDeleteChatResponse result
     */

    /**
     * Constructs a new MCMethodDeleteChatResponse.
     * @exports MCMethodDeleteChatResponse
     * @classdesc Represents a MCMethodDeleteChatResponse.
     * @implements IMCMethodDeleteChatResponse
     * @constructor
     * @param {IMCMethodDeleteChatResponse=} [properties] Properties to set
     */
    function MCMethodDeleteChatResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodDeleteChatResponse result.
     * @member {MCMethodDeleteChatResponse.Result} result
     * @memberof MCMethodDeleteChatResponse
     * @instance
     */
    MCMethodDeleteChatResponse.prototype.result = 0;

    /**
     * Creates a new MCMethodDeleteChatResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodDeleteChatResponse
     * @static
     * @param {IMCMethodDeleteChatResponse=} [properties] Properties to set
     * @returns {MCMethodDeleteChatResponse} MCMethodDeleteChatResponse instance
     */
    MCMethodDeleteChatResponse.create = function create(properties) {
        return new MCMethodDeleteChatResponse(properties);
    };

    /**
     * Encodes the specified MCMethodDeleteChatResponse message. Does not implicitly {@link MCMethodDeleteChatResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodDeleteChatResponse
     * @static
     * @param {IMCMethodDeleteChatResponse} message MCMethodDeleteChatResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodDeleteChatResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        return writer;
    };

    /**
     * Encodes the specified MCMethodDeleteChatResponse message, length delimited. Does not implicitly {@link MCMethodDeleteChatResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodDeleteChatResponse
     * @static
     * @param {IMCMethodDeleteChatResponse} message MCMethodDeleteChatResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodDeleteChatResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodDeleteChatResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodDeleteChatResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodDeleteChatResponse} MCMethodDeleteChatResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodDeleteChatResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodDeleteChatResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodDeleteChatResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodDeleteChatResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodDeleteChatResponse} MCMethodDeleteChatResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodDeleteChatResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodDeleteChatResponse message.
     * @function verify
     * @memberof MCMethodDeleteChatResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodDeleteChatResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 10:
                break;
            }
        return null;
    };

    /**
     * Creates a MCMethodDeleteChatResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodDeleteChatResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodDeleteChatResponse} MCMethodDeleteChatResponse
     */
    MCMethodDeleteChatResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodDeleteChatResponse)
            return object;
        let message = new $root.MCMethodDeleteChatResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_OK":
        case 0:
            message.result = 0;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 1:
            message.result = 1;
            break;
        case "R_CHAT_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodDeleteChatResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodDeleteChatResponse
     * @static
     * @param {MCMethodDeleteChatResponse} message MCMethodDeleteChatResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodDeleteChatResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.result = options.enums === String ? "R_OK" : 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodDeleteChatResponse.Result[message.result] === undefined ? message.result : $root.MCMethodDeleteChatResponse.Result[message.result] : message.result;
        return object;
    };

    /**
     * Converts this MCMethodDeleteChatResponse to JSON.
     * @function toJSON
     * @memberof MCMethodDeleteChatResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodDeleteChatResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodDeleteChatResponse
     * @function getTypeUrl
     * @memberof MCMethodDeleteChatResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodDeleteChatResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodDeleteChatResponse";
    };

    /**
     * Result enum.
     * @name MCMethodDeleteChatResponse.Result
     * @enum {number}
     * @property {number} R_OK=0 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=1 R_CHANNEL_NOT_FOUND value
     * @property {number} R_CHAT_NOT_FOUND=2 R_CHAT_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodDeleteChatResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_OK"] = 0;
        values[valuesById[1] = "R_CHANNEL_NOT_FOUND"] = 1;
        values[valuesById[2] = "R_CHAT_NOT_FOUND"] = 2;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodDeleteChatResponse;
})();

export const MCMethodSendCustomUpdate = $root.MCMethodSendCustomUpdate = (() => {

    /**
     * Properties of a MCMethodSendCustomUpdate.
     * @exports IMCMethodSendCustomUpdate
     * @interface IMCMethodSendCustomUpdate
     * @property {IUpdate|null} [update] MCMethodSendCustomUpdate update
     */

    /**
     * Constructs a new MCMethodSendCustomUpdate.
     * @exports MCMethodSendCustomUpdate
     * @classdesc Represents a MCMethodSendCustomUpdate.
     * @implements IMCMethodSendCustomUpdate
     * @constructor
     * @param {IMCMethodSendCustomUpdate=} [properties] Properties to set
     */
    function MCMethodSendCustomUpdate(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodSendCustomUpdate update.
     * @member {IUpdate|null|undefined} update
     * @memberof MCMethodSendCustomUpdate
     * @instance
     */
    MCMethodSendCustomUpdate.prototype.update = null;

    /**
     * Creates a new MCMethodSendCustomUpdate instance using the specified properties.
     * @function create
     * @memberof MCMethodSendCustomUpdate
     * @static
     * @param {IMCMethodSendCustomUpdate=} [properties] Properties to set
     * @returns {MCMethodSendCustomUpdate} MCMethodSendCustomUpdate instance
     */
    MCMethodSendCustomUpdate.create = function create(properties) {
        return new MCMethodSendCustomUpdate(properties);
    };

    /**
     * Encodes the specified MCMethodSendCustomUpdate message. Does not implicitly {@link MCMethodSendCustomUpdate.verify|verify} messages.
     * @function encode
     * @memberof MCMethodSendCustomUpdate
     * @static
     * @param {IMCMethodSendCustomUpdate} message MCMethodSendCustomUpdate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSendCustomUpdate.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.update != null && Object.hasOwnProperty.call(message, "update"))
            $root.Update.encode(message.update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodSendCustomUpdate message, length delimited. Does not implicitly {@link MCMethodSendCustomUpdate.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodSendCustomUpdate
     * @static
     * @param {IMCMethodSendCustomUpdate} message MCMethodSendCustomUpdate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSendCustomUpdate.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodSendCustomUpdate message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodSendCustomUpdate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodSendCustomUpdate} MCMethodSendCustomUpdate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSendCustomUpdate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodSendCustomUpdate();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.update = $root.Update.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodSendCustomUpdate message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodSendCustomUpdate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodSendCustomUpdate} MCMethodSendCustomUpdate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSendCustomUpdate.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodSendCustomUpdate message.
     * @function verify
     * @memberof MCMethodSendCustomUpdate
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodSendCustomUpdate.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.update != null && message.hasOwnProperty("update")) {
            let error = $root.Update.verify(message.update);
            if (error)
                return "update." + error;
        }
        return null;
    };

    /**
     * Creates a MCMethodSendCustomUpdate message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodSendCustomUpdate
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodSendCustomUpdate} MCMethodSendCustomUpdate
     */
    MCMethodSendCustomUpdate.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodSendCustomUpdate)
            return object;
        let message = new $root.MCMethodSendCustomUpdate();
        if (object.update != null) {
            if (typeof object.update !== "object")
                throw TypeError(".MCMethodSendCustomUpdate.update: object expected");
            message.update = $root.Update.fromObject(object.update);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodSendCustomUpdate message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodSendCustomUpdate
     * @static
     * @param {MCMethodSendCustomUpdate} message MCMethodSendCustomUpdate
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodSendCustomUpdate.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.update = null;
        if (message.update != null && message.hasOwnProperty("update"))
            object.update = $root.Update.toObject(message.update, options);
        return object;
    };

    /**
     * Converts this MCMethodSendCustomUpdate to JSON.
     * @function toJSON
     * @memberof MCMethodSendCustomUpdate
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodSendCustomUpdate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodSendCustomUpdate
     * @function getTypeUrl
     * @memberof MCMethodSendCustomUpdate
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodSendCustomUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodSendCustomUpdate";
    };

    return MCMethodSendCustomUpdate;
})();

export const MCMethodSendCustomUpdateResponse = $root.MCMethodSendCustomUpdateResponse = (() => {

    /**
     * Properties of a MCMethodSendCustomUpdateResponse.
     * @exports IMCMethodSendCustomUpdateResponse
     * @interface IMCMethodSendCustomUpdateResponse
     * @property {MCMethodSendCustomUpdateResponse.Result|null} [result] MCMethodSendCustomUpdateResponse result
     */

    /**
     * Constructs a new MCMethodSendCustomUpdateResponse.
     * @exports MCMethodSendCustomUpdateResponse
     * @classdesc Represents a MCMethodSendCustomUpdateResponse.
     * @implements IMCMethodSendCustomUpdateResponse
     * @constructor
     * @param {IMCMethodSendCustomUpdateResponse=} [properties] Properties to set
     */
    function MCMethodSendCustomUpdateResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodSendCustomUpdateResponse result.
     * @member {MCMethodSendCustomUpdateResponse.Result} result
     * @memberof MCMethodSendCustomUpdateResponse
     * @instance
     */
    MCMethodSendCustomUpdateResponse.prototype.result = 0;

    /**
     * Creates a new MCMethodSendCustomUpdateResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodSendCustomUpdateResponse
     * @static
     * @param {IMCMethodSendCustomUpdateResponse=} [properties] Properties to set
     * @returns {MCMethodSendCustomUpdateResponse} MCMethodSendCustomUpdateResponse instance
     */
    MCMethodSendCustomUpdateResponse.create = function create(properties) {
        return new MCMethodSendCustomUpdateResponse(properties);
    };

    /**
     * Encodes the specified MCMethodSendCustomUpdateResponse message. Does not implicitly {@link MCMethodSendCustomUpdateResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodSendCustomUpdateResponse
     * @static
     * @param {IMCMethodSendCustomUpdateResponse} message MCMethodSendCustomUpdateResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSendCustomUpdateResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        return writer;
    };

    /**
     * Encodes the specified MCMethodSendCustomUpdateResponse message, length delimited. Does not implicitly {@link MCMethodSendCustomUpdateResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodSendCustomUpdateResponse
     * @static
     * @param {IMCMethodSendCustomUpdateResponse} message MCMethodSendCustomUpdateResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSendCustomUpdateResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodSendCustomUpdateResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodSendCustomUpdateResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodSendCustomUpdateResponse} MCMethodSendCustomUpdateResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSendCustomUpdateResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodSendCustomUpdateResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodSendCustomUpdateResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodSendCustomUpdateResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodSendCustomUpdateResponse} MCMethodSendCustomUpdateResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSendCustomUpdateResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodSendCustomUpdateResponse message.
     * @function verify
     * @memberof MCMethodSendCustomUpdateResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodSendCustomUpdateResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 10:
                break;
            }
        return null;
    };

    /**
     * Creates a MCMethodSendCustomUpdateResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodSendCustomUpdateResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodSendCustomUpdateResponse} MCMethodSendCustomUpdateResponse
     */
    MCMethodSendCustomUpdateResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodSendCustomUpdateResponse)
            return object;
        let message = new $root.MCMethodSendCustomUpdateResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_OK":
        case 0:
            message.result = 0;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 1:
            message.result = 1;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodSendCustomUpdateResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodSendCustomUpdateResponse
     * @static
     * @param {MCMethodSendCustomUpdateResponse} message MCMethodSendCustomUpdateResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodSendCustomUpdateResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.result = options.enums === String ? "R_OK" : 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodSendCustomUpdateResponse.Result[message.result] === undefined ? message.result : $root.MCMethodSendCustomUpdateResponse.Result[message.result] : message.result;
        return object;
    };

    /**
     * Converts this MCMethodSendCustomUpdateResponse to JSON.
     * @function toJSON
     * @memberof MCMethodSendCustomUpdateResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodSendCustomUpdateResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodSendCustomUpdateResponse
     * @function getTypeUrl
     * @memberof MCMethodSendCustomUpdateResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodSendCustomUpdateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodSendCustomUpdateResponse";
    };

    /**
     * Result enum.
     * @name MCMethodSendCustomUpdateResponse.Result
     * @enum {number}
     * @property {number} R_OK=0 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=1 R_CHANNEL_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodSendCustomUpdateResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_OK"] = 0;
        values[valuesById[1] = "R_CHANNEL_NOT_FOUND"] = 1;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodSendCustomUpdateResponse;
})();

export const MCMethodQueryPeers = $root.MCMethodQueryPeers = (() => {

    /**
     * Properties of a MCMethodQueryPeers.
     * @exports IMCMethodQueryPeers
     * @interface IMCMethodQueryPeers
     * @property {Uint8Array|null} [channelID] MCMethodQueryPeers channelID
     * @property {Long|null} [chatID] MCMethodQueryPeers chatID
     * @property {string|null} [query] MCMethodQueryPeers query
     */

    /**
     * Constructs a new MCMethodQueryPeers.
     * @exports MCMethodQueryPeers
     * @classdesc Represents a MCMethodQueryPeers.
     * @implements IMCMethodQueryPeers
     * @constructor
     * @param {IMCMethodQueryPeers=} [properties] Properties to set
     */
    function MCMethodQueryPeers(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodQueryPeers channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodQueryPeers
     * @instance
     */
    MCMethodQueryPeers.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodQueryPeers chatID.
     * @member {Long} chatID
     * @memberof MCMethodQueryPeers
     * @instance
     */
    MCMethodQueryPeers.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodQueryPeers query.
     * @member {string} query
     * @memberof MCMethodQueryPeers
     * @instance
     */
    MCMethodQueryPeers.prototype.query = "";

    /**
     * Creates a new MCMethodQueryPeers instance using the specified properties.
     * @function create
     * @memberof MCMethodQueryPeers
     * @static
     * @param {IMCMethodQueryPeers=} [properties] Properties to set
     * @returns {MCMethodQueryPeers} MCMethodQueryPeers instance
     */
    MCMethodQueryPeers.create = function create(properties) {
        return new MCMethodQueryPeers(properties);
    };

    /**
     * Encodes the specified MCMethodQueryPeers message. Does not implicitly {@link MCMethodQueryPeers.verify|verify} messages.
     * @function encode
     * @memberof MCMethodQueryPeers
     * @static
     * @param {IMCMethodQueryPeers} message MCMethodQueryPeers message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodQueryPeers.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chatID);
        if (message.query != null && Object.hasOwnProperty.call(message, "query"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.query);
        return writer;
    };

    /**
     * Encodes the specified MCMethodQueryPeers message, length delimited. Does not implicitly {@link MCMethodQueryPeers.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodQueryPeers
     * @static
     * @param {IMCMethodQueryPeers} message MCMethodQueryPeers message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodQueryPeers.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodQueryPeers message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodQueryPeers
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodQueryPeers} MCMethodQueryPeers
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodQueryPeers.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodQueryPeers();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 3: {
                    message.query = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodQueryPeers message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodQueryPeers
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodQueryPeers} MCMethodQueryPeers
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodQueryPeers.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodQueryPeers message.
     * @function verify
     * @memberof MCMethodQueryPeers
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodQueryPeers.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.query != null && message.hasOwnProperty("query"))
            if (!$util.isString(message.query))
                return "query: string expected";
        return null;
    };

    /**
     * Creates a MCMethodQueryPeers message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodQueryPeers
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodQueryPeers} MCMethodQueryPeers
     */
    MCMethodQueryPeers.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodQueryPeers)
            return object;
        let message = new $root.MCMethodQueryPeers();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.query != null)
            message.query = String(object.query);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodQueryPeers message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodQueryPeers
     * @static
     * @param {MCMethodQueryPeers} message MCMethodQueryPeers
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodQueryPeers.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
            object.query = "";
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.query != null && message.hasOwnProperty("query"))
            object.query = message.query;
        return object;
    };

    /**
     * Converts this MCMethodQueryPeers to JSON.
     * @function toJSON
     * @memberof MCMethodQueryPeers
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodQueryPeers.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodQueryPeers
     * @function getTypeUrl
     * @memberof MCMethodQueryPeers
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodQueryPeers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodQueryPeers";
    };

    return MCMethodQueryPeers;
})();

export const MCMethodQueryPeersResponse = $root.MCMethodQueryPeersResponse = (() => {

    /**
     * Properties of a MCMethodQueryPeersResponse.
     * @exports IMCMethodQueryPeersResponse
     * @interface IMCMethodQueryPeersResponse
     * @property {MCMethodQueryPeersResponse.Result|null} [result] MCMethodQueryPeersResponse result
     * @property {Array.<IMCPeerPreview>|null} [peers] MCMethodQueryPeersResponse peers
     */

    /**
     * Constructs a new MCMethodQueryPeersResponse.
     * @exports MCMethodQueryPeersResponse
     * @classdesc Represents a MCMethodQueryPeersResponse.
     * @implements IMCMethodQueryPeersResponse
     * @constructor
     * @param {IMCMethodQueryPeersResponse=} [properties] Properties to set
     */
    function MCMethodQueryPeersResponse(properties) {
        this.peers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodQueryPeersResponse result.
     * @member {MCMethodQueryPeersResponse.Result} result
     * @memberof MCMethodQueryPeersResponse
     * @instance
     */
    MCMethodQueryPeersResponse.prototype.result = 0;

    /**
     * MCMethodQueryPeersResponse peers.
     * @member {Array.<IMCPeerPreview>} peers
     * @memberof MCMethodQueryPeersResponse
     * @instance
     */
    MCMethodQueryPeersResponse.prototype.peers = $util.emptyArray;

    /**
     * Creates a new MCMethodQueryPeersResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodQueryPeersResponse
     * @static
     * @param {IMCMethodQueryPeersResponse=} [properties] Properties to set
     * @returns {MCMethodQueryPeersResponse} MCMethodQueryPeersResponse instance
     */
    MCMethodQueryPeersResponse.create = function create(properties) {
        return new MCMethodQueryPeersResponse(properties);
    };

    /**
     * Encodes the specified MCMethodQueryPeersResponse message. Does not implicitly {@link MCMethodQueryPeersResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodQueryPeersResponse
     * @static
     * @param {IMCMethodQueryPeersResponse} message MCMethodQueryPeersResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodQueryPeersResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.peers != null && message.peers.length)
            for (let i = 0; i < message.peers.length; ++i)
                $root.MCPeerPreview.encode(message.peers[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodQueryPeersResponse message, length delimited. Does not implicitly {@link MCMethodQueryPeersResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodQueryPeersResponse
     * @static
     * @param {IMCMethodQueryPeersResponse} message MCMethodQueryPeersResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodQueryPeersResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodQueryPeersResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodQueryPeersResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodQueryPeersResponse} MCMethodQueryPeersResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodQueryPeersResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodQueryPeersResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.peers && message.peers.length))
                        message.peers = [];
                    message.peers.push($root.MCPeerPreview.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodQueryPeersResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodQueryPeersResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodQueryPeersResponse} MCMethodQueryPeersResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodQueryPeersResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodQueryPeersResponse message.
     * @function verify
     * @memberof MCMethodQueryPeersResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodQueryPeersResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 10:
                break;
            }
        if (message.peers != null && message.hasOwnProperty("peers")) {
            if (!Array.isArray(message.peers))
                return "peers: array expected";
            for (let i = 0; i < message.peers.length; ++i) {
                let error = $root.MCPeerPreview.verify(message.peers[i]);
                if (error)
                    return "peers." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCMethodQueryPeersResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodQueryPeersResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodQueryPeersResponse} MCMethodQueryPeersResponse
     */
    MCMethodQueryPeersResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodQueryPeersResponse)
            return object;
        let message = new $root.MCMethodQueryPeersResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_OK":
        case 0:
            message.result = 0;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 1:
            message.result = 1;
            break;
        case "R_CHAT_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.peers) {
            if (!Array.isArray(object.peers))
                throw TypeError(".MCMethodQueryPeersResponse.peers: array expected");
            message.peers = [];
            for (let i = 0; i < object.peers.length; ++i) {
                if (typeof object.peers[i] !== "object")
                    throw TypeError(".MCMethodQueryPeersResponse.peers: object expected");
                message.peers[i] = $root.MCPeerPreview.fromObject(object.peers[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodQueryPeersResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodQueryPeersResponse
     * @static
     * @param {MCMethodQueryPeersResponse} message MCMethodQueryPeersResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodQueryPeersResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.peers = [];
        if (options.defaults)
            object.result = options.enums === String ? "R_OK" : 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodQueryPeersResponse.Result[message.result] === undefined ? message.result : $root.MCMethodQueryPeersResponse.Result[message.result] : message.result;
        if (message.peers && message.peers.length) {
            object.peers = [];
            for (let j = 0; j < message.peers.length; ++j)
                object.peers[j] = $root.MCPeerPreview.toObject(message.peers[j], options);
        }
        return object;
    };

    /**
     * Converts this MCMethodQueryPeersResponse to JSON.
     * @function toJSON
     * @memberof MCMethodQueryPeersResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodQueryPeersResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodQueryPeersResponse
     * @function getTypeUrl
     * @memberof MCMethodQueryPeersResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodQueryPeersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodQueryPeersResponse";
    };

    /**
     * Result enum.
     * @name MCMethodQueryPeersResponse.Result
     * @enum {number}
     * @property {number} R_OK=0 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=1 R_CHANNEL_NOT_FOUND value
     * @property {number} R_CHAT_NOT_FOUND=2 R_CHAT_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodQueryPeersResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_OK"] = 0;
        values[valuesById[1] = "R_CHANNEL_NOT_FOUND"] = 1;
        values[valuesById[2] = "R_CHAT_NOT_FOUND"] = 2;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodQueryPeersResponse;
})();

export const MCMethodEditChat = $root.MCMethodEditChat = (() => {

    /**
     * Properties of a MCMethodEditChat.
     * @exports IMCMethodEditChat
     * @interface IMCMethodEditChat
     * @property {Uint8Array|null} [channelID] MCMethodEditChat channelID
     * @property {Long|null} [chatID] MCMethodEditChat chatID
     * @property {string|null} [name] MCMethodEditChat name
     * @property {string|null} [alternativeName] MCMethodEditChat alternativeName
     * @property {boolean|null} [showAlternativeName] MCMethodEditChat showAlternativeName
     */

    /**
     * Constructs a new MCMethodEditChat.
     * @exports MCMethodEditChat
     * @classdesc Represents a MCMethodEditChat.
     * @implements IMCMethodEditChat
     * @constructor
     * @param {IMCMethodEditChat=} [properties] Properties to set
     */
    function MCMethodEditChat(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodEditChat channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodEditChat
     * @instance
     */
    MCMethodEditChat.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodEditChat chatID.
     * @member {Long} chatID
     * @memberof MCMethodEditChat
     * @instance
     */
    MCMethodEditChat.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodEditChat name.
     * @member {string} name
     * @memberof MCMethodEditChat
     * @instance
     */
    MCMethodEditChat.prototype.name = "";

    /**
     * MCMethodEditChat alternativeName.
     * @member {string} alternativeName
     * @memberof MCMethodEditChat
     * @instance
     */
    MCMethodEditChat.prototype.alternativeName = "";

    /**
     * MCMethodEditChat showAlternativeName.
     * @member {boolean} showAlternativeName
     * @memberof MCMethodEditChat
     * @instance
     */
    MCMethodEditChat.prototype.showAlternativeName = false;

    /**
     * Creates a new MCMethodEditChat instance using the specified properties.
     * @function create
     * @memberof MCMethodEditChat
     * @static
     * @param {IMCMethodEditChat=} [properties] Properties to set
     * @returns {MCMethodEditChat} MCMethodEditChat instance
     */
    MCMethodEditChat.create = function create(properties) {
        return new MCMethodEditChat(properties);
    };

    /**
     * Encodes the specified MCMethodEditChat message. Does not implicitly {@link MCMethodEditChat.verify|verify} messages.
     * @function encode
     * @memberof MCMethodEditChat
     * @static
     * @param {IMCMethodEditChat} message MCMethodEditChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodEditChat.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chatID);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
        if (message.showAlternativeName != null && Object.hasOwnProperty.call(message, "showAlternativeName"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.showAlternativeName);
        if (message.alternativeName != null && Object.hasOwnProperty.call(message, "alternativeName"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.alternativeName);
        return writer;
    };

    /**
     * Encodes the specified MCMethodEditChat message, length delimited. Does not implicitly {@link MCMethodEditChat.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodEditChat
     * @static
     * @param {IMCMethodEditChat} message MCMethodEditChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodEditChat.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodEditChat message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodEditChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodEditChat} MCMethodEditChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodEditChat.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodEditChat();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 3: {
                    message.name = reader.string();
                    break;
                }
            case 5: {
                    message.alternativeName = reader.string();
                    break;
                }
            case 4: {
                    message.showAlternativeName = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodEditChat message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodEditChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodEditChat} MCMethodEditChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodEditChat.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodEditChat message.
     * @function verify
     * @memberof MCMethodEditChat
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodEditChat.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.alternativeName != null && message.hasOwnProperty("alternativeName"))
            if (!$util.isString(message.alternativeName))
                return "alternativeName: string expected";
        if (message.showAlternativeName != null && message.hasOwnProperty("showAlternativeName"))
            if (typeof message.showAlternativeName !== "boolean")
                return "showAlternativeName: boolean expected";
        return null;
    };

    /**
     * Creates a MCMethodEditChat message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodEditChat
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodEditChat} MCMethodEditChat
     */
    MCMethodEditChat.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodEditChat)
            return object;
        let message = new $root.MCMethodEditChat();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.name != null)
            message.name = String(object.name);
        if (object.alternativeName != null)
            message.alternativeName = String(object.alternativeName);
        if (object.showAlternativeName != null)
            message.showAlternativeName = Boolean(object.showAlternativeName);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodEditChat message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodEditChat
     * @static
     * @param {MCMethodEditChat} message MCMethodEditChat
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodEditChat.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
            object.name = "";
            object.showAlternativeName = false;
            object.alternativeName = "";
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.showAlternativeName != null && message.hasOwnProperty("showAlternativeName"))
            object.showAlternativeName = message.showAlternativeName;
        if (message.alternativeName != null && message.hasOwnProperty("alternativeName"))
            object.alternativeName = message.alternativeName;
        return object;
    };

    /**
     * Converts this MCMethodEditChat to JSON.
     * @function toJSON
     * @memberof MCMethodEditChat
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodEditChat.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodEditChat
     * @function getTypeUrl
     * @memberof MCMethodEditChat
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodEditChat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodEditChat";
    };

    return MCMethodEditChat;
})();

export const MCMethodEditChatResponse = $root.MCMethodEditChatResponse = (() => {

    /**
     * Properties of a MCMethodEditChatResponse.
     * @exports IMCMethodEditChatResponse
     * @interface IMCMethodEditChatResponse
     * @property {MCMethodEditChatResponse.Result|null} [result] MCMethodEditChatResponse result
     */

    /**
     * Constructs a new MCMethodEditChatResponse.
     * @exports MCMethodEditChatResponse
     * @classdesc Represents a MCMethodEditChatResponse.
     * @implements IMCMethodEditChatResponse
     * @constructor
     * @param {IMCMethodEditChatResponse=} [properties] Properties to set
     */
    function MCMethodEditChatResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodEditChatResponse result.
     * @member {MCMethodEditChatResponse.Result} result
     * @memberof MCMethodEditChatResponse
     * @instance
     */
    MCMethodEditChatResponse.prototype.result = 0;

    /**
     * Creates a new MCMethodEditChatResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodEditChatResponse
     * @static
     * @param {IMCMethodEditChatResponse=} [properties] Properties to set
     * @returns {MCMethodEditChatResponse} MCMethodEditChatResponse instance
     */
    MCMethodEditChatResponse.create = function create(properties) {
        return new MCMethodEditChatResponse(properties);
    };

    /**
     * Encodes the specified MCMethodEditChatResponse message. Does not implicitly {@link MCMethodEditChatResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodEditChatResponse
     * @static
     * @param {IMCMethodEditChatResponse} message MCMethodEditChatResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodEditChatResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        return writer;
    };

    /**
     * Encodes the specified MCMethodEditChatResponse message, length delimited. Does not implicitly {@link MCMethodEditChatResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodEditChatResponse
     * @static
     * @param {IMCMethodEditChatResponse} message MCMethodEditChatResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodEditChatResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodEditChatResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodEditChatResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodEditChatResponse} MCMethodEditChatResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodEditChatResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodEditChatResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodEditChatResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodEditChatResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodEditChatResponse} MCMethodEditChatResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodEditChatResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodEditChatResponse message.
     * @function verify
     * @memberof MCMethodEditChatResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodEditChatResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 10:
                break;
            }
        return null;
    };

    /**
     * Creates a MCMethodEditChatResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodEditChatResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodEditChatResponse} MCMethodEditChatResponse
     */
    MCMethodEditChatResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodEditChatResponse)
            return object;
        let message = new $root.MCMethodEditChatResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_UNKNOWN":
        case 0:
            message.result = 0;
            break;
        case "R_OK":
        case 1:
            message.result = 1;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_CHAT_NOT_FOUND":
        case 3:
            message.result = 3;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodEditChatResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodEditChatResponse
     * @static
     * @param {MCMethodEditChatResponse} message MCMethodEditChatResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodEditChatResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.result = options.enums === String ? "R_UNKNOWN" : 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodEditChatResponse.Result[message.result] === undefined ? message.result : $root.MCMethodEditChatResponse.Result[message.result] : message.result;
        return object;
    };

    /**
     * Converts this MCMethodEditChatResponse to JSON.
     * @function toJSON
     * @memberof MCMethodEditChatResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodEditChatResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodEditChatResponse
     * @function getTypeUrl
     * @memberof MCMethodEditChatResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodEditChatResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodEditChatResponse";
    };

    /**
     * Result enum.
     * @name MCMethodEditChatResponse.Result
     * @enum {number}
     * @property {number} R_UNKNOWN=0 R_UNKNOWN value
     * @property {number} R_OK=1 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=2 R_CHANNEL_NOT_FOUND value
     * @property {number} R_CHAT_NOT_FOUND=3 R_CHAT_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodEditChatResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_UNKNOWN"] = 0;
        values[valuesById[1] = "R_OK"] = 1;
        values[valuesById[2] = "R_CHANNEL_NOT_FOUND"] = 2;
        values[valuesById[3] = "R_CHAT_NOT_FOUND"] = 3;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodEditChatResponse;
})();

export const MCMethodDeleteMessage = $root.MCMethodDeleteMessage = (() => {

    /**
     * Properties of a MCMethodDeleteMessage.
     * @exports IMCMethodDeleteMessage
     * @interface IMCMethodDeleteMessage
     * @property {Uint8Array|null} [channelID] MCMethodDeleteMessage channelID
     * @property {Long|null} [chatID] MCMethodDeleteMessage chatID
     * @property {Long|null} [messageID] MCMethodDeleteMessage messageID
     * @property {boolean|null} [bothSides] MCMethodDeleteMessage bothSides
     */

    /**
     * Constructs a new MCMethodDeleteMessage.
     * @exports MCMethodDeleteMessage
     * @classdesc Represents a MCMethodDeleteMessage.
     * @implements IMCMethodDeleteMessage
     * @constructor
     * @param {IMCMethodDeleteMessage=} [properties] Properties to set
     */
    function MCMethodDeleteMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodDeleteMessage channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodDeleteMessage
     * @instance
     */
    MCMethodDeleteMessage.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodDeleteMessage chatID.
     * @member {Long} chatID
     * @memberof MCMethodDeleteMessage
     * @instance
     */
    MCMethodDeleteMessage.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodDeleteMessage messageID.
     * @member {Long} messageID
     * @memberof MCMethodDeleteMessage
     * @instance
     */
    MCMethodDeleteMessage.prototype.messageID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodDeleteMessage bothSides.
     * @member {boolean} bothSides
     * @memberof MCMethodDeleteMessage
     * @instance
     */
    MCMethodDeleteMessage.prototype.bothSides = false;

    /**
     * Creates a new MCMethodDeleteMessage instance using the specified properties.
     * @function create
     * @memberof MCMethodDeleteMessage
     * @static
     * @param {IMCMethodDeleteMessage=} [properties] Properties to set
     * @returns {MCMethodDeleteMessage} MCMethodDeleteMessage instance
     */
    MCMethodDeleteMessage.create = function create(properties) {
        return new MCMethodDeleteMessage(properties);
    };

    /**
     * Encodes the specified MCMethodDeleteMessage message. Does not implicitly {@link MCMethodDeleteMessage.verify|verify} messages.
     * @function encode
     * @memberof MCMethodDeleteMessage
     * @static
     * @param {IMCMethodDeleteMessage} message MCMethodDeleteMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodDeleteMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chatID);
        if (message.messageID != null && Object.hasOwnProperty.call(message, "messageID"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.messageID);
        if (message.bothSides != null && Object.hasOwnProperty.call(message, "bothSides"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.bothSides);
        return writer;
    };

    /**
     * Encodes the specified MCMethodDeleteMessage message, length delimited. Does not implicitly {@link MCMethodDeleteMessage.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodDeleteMessage
     * @static
     * @param {IMCMethodDeleteMessage} message MCMethodDeleteMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodDeleteMessage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodDeleteMessage message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodDeleteMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodDeleteMessage} MCMethodDeleteMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodDeleteMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodDeleteMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 3: {
                    message.messageID = reader.uint64();
                    break;
                }
            case 4: {
                    message.bothSides = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodDeleteMessage message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodDeleteMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodDeleteMessage} MCMethodDeleteMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodDeleteMessage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodDeleteMessage message.
     * @function verify
     * @memberof MCMethodDeleteMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodDeleteMessage.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.messageID != null && message.hasOwnProperty("messageID"))
            if (!$util.isInteger(message.messageID) && !(message.messageID && $util.isInteger(message.messageID.low) && $util.isInteger(message.messageID.high)))
                return "messageID: integer|Long expected";
        if (message.bothSides != null && message.hasOwnProperty("bothSides"))
            if (typeof message.bothSides !== "boolean")
                return "bothSides: boolean expected";
        return null;
    };

    /**
     * Creates a MCMethodDeleteMessage message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodDeleteMessage
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodDeleteMessage} MCMethodDeleteMessage
     */
    MCMethodDeleteMessage.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodDeleteMessage)
            return object;
        let message = new $root.MCMethodDeleteMessage();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.messageID != null)
            if ($util.Long)
                (message.messageID = $util.Long.fromValue(object.messageID)).unsigned = true;
            else if (typeof object.messageID === "string")
                message.messageID = parseInt(object.messageID, 10);
            else if (typeof object.messageID === "number")
                message.messageID = object.messageID;
            else if (typeof object.messageID === "object")
                message.messageID = new $util.LongBits(object.messageID.low >>> 0, object.messageID.high >>> 0).toNumber(true);
        if (object.bothSides != null)
            message.bothSides = Boolean(object.bothSides);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodDeleteMessage message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodDeleteMessage
     * @static
     * @param {MCMethodDeleteMessage} message MCMethodDeleteMessage
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodDeleteMessage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.messageID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.messageID = options.longs === String ? "0" : 0;
            object.bothSides = false;
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.messageID != null && message.hasOwnProperty("messageID"))
            if (typeof message.messageID === "number")
                object.messageID = options.longs === String ? String(message.messageID) : message.messageID;
            else
                object.messageID = options.longs === String ? $util.Long.prototype.toString.call(message.messageID) : options.longs === Number ? new $util.LongBits(message.messageID.low >>> 0, message.messageID.high >>> 0).toNumber(true) : message.messageID;
        if (message.bothSides != null && message.hasOwnProperty("bothSides"))
            object.bothSides = message.bothSides;
        return object;
    };

    /**
     * Converts this MCMethodDeleteMessage to JSON.
     * @function toJSON
     * @memberof MCMethodDeleteMessage
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodDeleteMessage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodDeleteMessage
     * @function getTypeUrl
     * @memberof MCMethodDeleteMessage
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodDeleteMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodDeleteMessage";
    };

    return MCMethodDeleteMessage;
})();

export const MCMethodDeleteMessageResponse = $root.MCMethodDeleteMessageResponse = (() => {

    /**
     * Properties of a MCMethodDeleteMessageResponse.
     * @exports IMCMethodDeleteMessageResponse
     * @interface IMCMethodDeleteMessageResponse
     * @property {MCMethodDeleteMessageResponse.Result|null} [result] MCMethodDeleteMessageResponse result
     */

    /**
     * Constructs a new MCMethodDeleteMessageResponse.
     * @exports MCMethodDeleteMessageResponse
     * @classdesc Represents a MCMethodDeleteMessageResponse.
     * @implements IMCMethodDeleteMessageResponse
     * @constructor
     * @param {IMCMethodDeleteMessageResponse=} [properties] Properties to set
     */
    function MCMethodDeleteMessageResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodDeleteMessageResponse result.
     * @member {MCMethodDeleteMessageResponse.Result} result
     * @memberof MCMethodDeleteMessageResponse
     * @instance
     */
    MCMethodDeleteMessageResponse.prototype.result = 0;

    /**
     * Creates a new MCMethodDeleteMessageResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodDeleteMessageResponse
     * @static
     * @param {IMCMethodDeleteMessageResponse=} [properties] Properties to set
     * @returns {MCMethodDeleteMessageResponse} MCMethodDeleteMessageResponse instance
     */
    MCMethodDeleteMessageResponse.create = function create(properties) {
        return new MCMethodDeleteMessageResponse(properties);
    };

    /**
     * Encodes the specified MCMethodDeleteMessageResponse message. Does not implicitly {@link MCMethodDeleteMessageResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodDeleteMessageResponse
     * @static
     * @param {IMCMethodDeleteMessageResponse} message MCMethodDeleteMessageResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodDeleteMessageResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        return writer;
    };

    /**
     * Encodes the specified MCMethodDeleteMessageResponse message, length delimited. Does not implicitly {@link MCMethodDeleteMessageResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodDeleteMessageResponse
     * @static
     * @param {IMCMethodDeleteMessageResponse} message MCMethodDeleteMessageResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodDeleteMessageResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodDeleteMessageResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodDeleteMessageResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodDeleteMessageResponse} MCMethodDeleteMessageResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodDeleteMessageResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodDeleteMessageResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodDeleteMessageResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodDeleteMessageResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodDeleteMessageResponse} MCMethodDeleteMessageResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodDeleteMessageResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodDeleteMessageResponse message.
     * @function verify
     * @memberof MCMethodDeleteMessageResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodDeleteMessageResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 10:
                break;
            }
        return null;
    };

    /**
     * Creates a MCMethodDeleteMessageResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodDeleteMessageResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodDeleteMessageResponse} MCMethodDeleteMessageResponse
     */
    MCMethodDeleteMessageResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodDeleteMessageResponse)
            return object;
        let message = new $root.MCMethodDeleteMessageResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_UNKNOWN":
        case 0:
            message.result = 0;
            break;
        case "R_OK":
        case 1:
            message.result = 1;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_CHAT_NOT_FOUND":
        case 3:
            message.result = 3;
            break;
        case "R_MESSAGE_NOT_FOUND":
        case 4:
            message.result = 4;
            break;
        case "R_NO_RIGHTS":
        case 5:
            message.result = 5;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodDeleteMessageResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodDeleteMessageResponse
     * @static
     * @param {MCMethodDeleteMessageResponse} message MCMethodDeleteMessageResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodDeleteMessageResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.result = options.enums === String ? "R_UNKNOWN" : 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodDeleteMessageResponse.Result[message.result] === undefined ? message.result : $root.MCMethodDeleteMessageResponse.Result[message.result] : message.result;
        return object;
    };

    /**
     * Converts this MCMethodDeleteMessageResponse to JSON.
     * @function toJSON
     * @memberof MCMethodDeleteMessageResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodDeleteMessageResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodDeleteMessageResponse
     * @function getTypeUrl
     * @memberof MCMethodDeleteMessageResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodDeleteMessageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodDeleteMessageResponse";
    };

    /**
     * Result enum.
     * @name MCMethodDeleteMessageResponse.Result
     * @enum {number}
     * @property {number} R_UNKNOWN=0 R_UNKNOWN value
     * @property {number} R_OK=1 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=2 R_CHANNEL_NOT_FOUND value
     * @property {number} R_CHAT_NOT_FOUND=3 R_CHAT_NOT_FOUND value
     * @property {number} R_MESSAGE_NOT_FOUND=4 R_MESSAGE_NOT_FOUND value
     * @property {number} R_NO_RIGHTS=5 R_NO_RIGHTS value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodDeleteMessageResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_UNKNOWN"] = 0;
        values[valuesById[1] = "R_OK"] = 1;
        values[valuesById[2] = "R_CHANNEL_NOT_FOUND"] = 2;
        values[valuesById[3] = "R_CHAT_NOT_FOUND"] = 3;
        values[valuesById[4] = "R_MESSAGE_NOT_FOUND"] = 4;
        values[valuesById[5] = "R_NO_RIGHTS"] = 5;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodDeleteMessageResponse;
})();

export const MCMethodGetChat = $root.MCMethodGetChat = (() => {

    /**
     * Properties of a MCMethodGetChat.
     * @exports IMCMethodGetChat
     * @interface IMCMethodGetChat
     * @property {Uint8Array|null} [channelID] MCMethodGetChat channelID
     * @property {Long|null} [chatID] MCMethodGetChat chatID
     */

    /**
     * Constructs a new MCMethodGetChat.
     * @exports MCMethodGetChat
     * @classdesc Represents a MCMethodGetChat.
     * @implements IMCMethodGetChat
     * @constructor
     * @param {IMCMethodGetChat=} [properties] Properties to set
     */
    function MCMethodGetChat(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetChat channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodGetChat
     * @instance
     */
    MCMethodGetChat.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodGetChat chatID.
     * @member {Long} chatID
     * @memberof MCMethodGetChat
     * @instance
     */
    MCMethodGetChat.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCMethodGetChat instance using the specified properties.
     * @function create
     * @memberof MCMethodGetChat
     * @static
     * @param {IMCMethodGetChat=} [properties] Properties to set
     * @returns {MCMethodGetChat} MCMethodGetChat instance
     */
    MCMethodGetChat.create = function create(properties) {
        return new MCMethodGetChat(properties);
    };

    /**
     * Encodes the specified MCMethodGetChat message. Does not implicitly {@link MCMethodGetChat.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetChat
     * @static
     * @param {IMCMethodGetChat} message MCMethodGetChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetChat.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chatID);
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetChat message, length delimited. Does not implicitly {@link MCMethodGetChat.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetChat
     * @static
     * @param {IMCMethodGetChat} message MCMethodGetChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetChat.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetChat message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetChat} MCMethodGetChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetChat.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetChat();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.chatID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetChat message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetChat} MCMethodGetChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetChat.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetChat message.
     * @function verify
     * @memberof MCMethodGetChat
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetChat.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCMethodGetChat message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetChat
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetChat} MCMethodGetChat
     */
    MCMethodGetChat.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetChat)
            return object;
        let message = new $root.MCMethodGetChat();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetChat message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetChat
     * @static
     * @param {MCMethodGetChat} message MCMethodGetChat
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetChat.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        return object;
    };

    /**
     * Converts this MCMethodGetChat to JSON.
     * @function toJSON
     * @memberof MCMethodGetChat
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetChat.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetChat
     * @function getTypeUrl
     * @memberof MCMethodGetChat
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetChat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetChat";
    };

    return MCMethodGetChat;
})();

export const MCMethodGetChatResponse = $root.MCMethodGetChatResponse = (() => {

    /**
     * Properties of a MCMethodGetChatResponse.
     * @exports IMCMethodGetChatResponse
     * @interface IMCMethodGetChatResponse
     * @property {MCMethodGetChatResponse.Result|null} [result] MCMethodGetChatResponse result
     * @property {IMCChat|null} [chat] MCMethodGetChatResponse chat
     */

    /**
     * Constructs a new MCMethodGetChatResponse.
     * @exports MCMethodGetChatResponse
     * @classdesc Represents a MCMethodGetChatResponse.
     * @implements IMCMethodGetChatResponse
     * @constructor
     * @param {IMCMethodGetChatResponse=} [properties] Properties to set
     */
    function MCMethodGetChatResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetChatResponse result.
     * @member {MCMethodGetChatResponse.Result} result
     * @memberof MCMethodGetChatResponse
     * @instance
     */
    MCMethodGetChatResponse.prototype.result = 0;

    /**
     * MCMethodGetChatResponse chat.
     * @member {IMCChat|null|undefined} chat
     * @memberof MCMethodGetChatResponse
     * @instance
     */
    MCMethodGetChatResponse.prototype.chat = null;

    /**
     * Creates a new MCMethodGetChatResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodGetChatResponse
     * @static
     * @param {IMCMethodGetChatResponse=} [properties] Properties to set
     * @returns {MCMethodGetChatResponse} MCMethodGetChatResponse instance
     */
    MCMethodGetChatResponse.create = function create(properties) {
        return new MCMethodGetChatResponse(properties);
    };

    /**
     * Encodes the specified MCMethodGetChatResponse message. Does not implicitly {@link MCMethodGetChatResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetChatResponse
     * @static
     * @param {IMCMethodGetChatResponse} message MCMethodGetChatResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetChatResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.chat != null && Object.hasOwnProperty.call(message, "chat"))
            $root.MCChat.encode(message.chat, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetChatResponse message, length delimited. Does not implicitly {@link MCMethodGetChatResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetChatResponse
     * @static
     * @param {IMCMethodGetChatResponse} message MCMethodGetChatResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetChatResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetChatResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetChatResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetChatResponse} MCMethodGetChatResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetChatResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetChatResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    message.chat = $root.MCChat.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetChatResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetChatResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetChatResponse} MCMethodGetChatResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetChatResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetChatResponse message.
     * @function verify
     * @memberof MCMethodGetChatResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetChatResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 10:
                break;
            }
        if (message.chat != null && message.hasOwnProperty("chat")) {
            let error = $root.MCChat.verify(message.chat);
            if (error)
                return "chat." + error;
        }
        return null;
    };

    /**
     * Creates a MCMethodGetChatResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetChatResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetChatResponse} MCMethodGetChatResponse
     */
    MCMethodGetChatResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetChatResponse)
            return object;
        let message = new $root.MCMethodGetChatResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_UNKNOWN":
        case 0:
            message.result = 0;
            break;
        case "R_OK":
        case 1:
            message.result = 1;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_CHAT_NOT_FOUND":
        case 3:
            message.result = 3;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.chat != null) {
            if (typeof object.chat !== "object")
                throw TypeError(".MCMethodGetChatResponse.chat: object expected");
            message.chat = $root.MCChat.fromObject(object.chat);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetChatResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetChatResponse
     * @static
     * @param {MCMethodGetChatResponse} message MCMethodGetChatResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetChatResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = options.enums === String ? "R_UNKNOWN" : 0;
            object.chat = null;
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodGetChatResponse.Result[message.result] === undefined ? message.result : $root.MCMethodGetChatResponse.Result[message.result] : message.result;
        if (message.chat != null && message.hasOwnProperty("chat"))
            object.chat = $root.MCChat.toObject(message.chat, options);
        return object;
    };

    /**
     * Converts this MCMethodGetChatResponse to JSON.
     * @function toJSON
     * @memberof MCMethodGetChatResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetChatResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetChatResponse
     * @function getTypeUrl
     * @memberof MCMethodGetChatResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetChatResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetChatResponse";
    };

    /**
     * Result enum.
     * @name MCMethodGetChatResponse.Result
     * @enum {number}
     * @property {number} R_UNKNOWN=0 R_UNKNOWN value
     * @property {number} R_OK=1 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=2 R_CHANNEL_NOT_FOUND value
     * @property {number} R_CHAT_NOT_FOUND=3 R_CHAT_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodGetChatResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_UNKNOWN"] = 0;
        values[valuesById[1] = "R_OK"] = 1;
        values[valuesById[2] = "R_CHANNEL_NOT_FOUND"] = 2;
        values[valuesById[3] = "R_CHAT_NOT_FOUND"] = 3;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodGetChatResponse;
})();

export const MCMethodGetChats = $root.MCMethodGetChats = (() => {

    /**
     * Properties of a MCMethodGetChats.
     * @exports IMCMethodGetChats
     * @interface IMCMethodGetChats
     * @property {Uint8Array|null} [channelID] MCMethodGetChats channelID
     * @property {Array.<Long>|null} [chatIDs] MCMethodGetChats chatIDs
     */

    /**
     * Constructs a new MCMethodGetChats.
     * @exports MCMethodGetChats
     * @classdesc Represents a MCMethodGetChats.
     * @implements IMCMethodGetChats
     * @constructor
     * @param {IMCMethodGetChats=} [properties] Properties to set
     */
    function MCMethodGetChats(properties) {
        this.chatIDs = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetChats channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodGetChats
     * @instance
     */
    MCMethodGetChats.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodGetChats chatIDs.
     * @member {Array.<Long>} chatIDs
     * @memberof MCMethodGetChats
     * @instance
     */
    MCMethodGetChats.prototype.chatIDs = $util.emptyArray;

    /**
     * Creates a new MCMethodGetChats instance using the specified properties.
     * @function create
     * @memberof MCMethodGetChats
     * @static
     * @param {IMCMethodGetChats=} [properties] Properties to set
     * @returns {MCMethodGetChats} MCMethodGetChats instance
     */
    MCMethodGetChats.create = function create(properties) {
        return new MCMethodGetChats(properties);
    };

    /**
     * Encodes the specified MCMethodGetChats message. Does not implicitly {@link MCMethodGetChats.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetChats
     * @static
     * @param {IMCMethodGetChats} message MCMethodGetChats message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetChats.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.chatIDs != null && message.chatIDs.length) {
            writer.uint32(/* id 2, wireType 2 =*/18).fork();
            for (let i = 0; i < message.chatIDs.length; ++i)
                writer.uint64(message.chatIDs[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetChats message, length delimited. Does not implicitly {@link MCMethodGetChats.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetChats
     * @static
     * @param {IMCMethodGetChats} message MCMethodGetChats message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetChats.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetChats message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetChats
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetChats} MCMethodGetChats
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetChats.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetChats();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    if (!(message.chatIDs && message.chatIDs.length))
                        message.chatIDs = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.chatIDs.push(reader.uint64());
                    } else
                        message.chatIDs.push(reader.uint64());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetChats message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetChats
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetChats} MCMethodGetChats
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetChats.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetChats message.
     * @function verify
     * @memberof MCMethodGetChats
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetChats.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.chatIDs != null && message.hasOwnProperty("chatIDs")) {
            if (!Array.isArray(message.chatIDs))
                return "chatIDs: array expected";
            for (let i = 0; i < message.chatIDs.length; ++i)
                if (!$util.isInteger(message.chatIDs[i]) && !(message.chatIDs[i] && $util.isInteger(message.chatIDs[i].low) && $util.isInteger(message.chatIDs[i].high)))
                    return "chatIDs: integer|Long[] expected";
        }
        return null;
    };

    /**
     * Creates a MCMethodGetChats message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetChats
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetChats} MCMethodGetChats
     */
    MCMethodGetChats.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetChats)
            return object;
        let message = new $root.MCMethodGetChats();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.chatIDs) {
            if (!Array.isArray(object.chatIDs))
                throw TypeError(".MCMethodGetChats.chatIDs: array expected");
            message.chatIDs = [];
            for (let i = 0; i < object.chatIDs.length; ++i)
                if ($util.Long)
                    (message.chatIDs[i] = $util.Long.fromValue(object.chatIDs[i])).unsigned = true;
                else if (typeof object.chatIDs[i] === "string")
                    message.chatIDs[i] = parseInt(object.chatIDs[i], 10);
                else if (typeof object.chatIDs[i] === "number")
                    message.chatIDs[i] = object.chatIDs[i];
                else if (typeof object.chatIDs[i] === "object")
                    message.chatIDs[i] = new $util.LongBits(object.chatIDs[i].low >>> 0, object.chatIDs[i].high >>> 0).toNumber(true);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetChats message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetChats
     * @static
     * @param {MCMethodGetChats} message MCMethodGetChats
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetChats.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.chatIDs = [];
        if (options.defaults)
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.chatIDs && message.chatIDs.length) {
            object.chatIDs = [];
            for (let j = 0; j < message.chatIDs.length; ++j)
                if (typeof message.chatIDs[j] === "number")
                    object.chatIDs[j] = options.longs === String ? String(message.chatIDs[j]) : message.chatIDs[j];
                else
                    object.chatIDs[j] = options.longs === String ? $util.Long.prototype.toString.call(message.chatIDs[j]) : options.longs === Number ? new $util.LongBits(message.chatIDs[j].low >>> 0, message.chatIDs[j].high >>> 0).toNumber(true) : message.chatIDs[j];
        }
        return object;
    };

    /**
     * Converts this MCMethodGetChats to JSON.
     * @function toJSON
     * @memberof MCMethodGetChats
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetChats.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetChats
     * @function getTypeUrl
     * @memberof MCMethodGetChats
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetChats.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetChats";
    };

    return MCMethodGetChats;
})();

export const MCMethodGetChatsResponse = $root.MCMethodGetChatsResponse = (() => {

    /**
     * Properties of a MCMethodGetChatsResponse.
     * @exports IMCMethodGetChatsResponse
     * @interface IMCMethodGetChatsResponse
     * @property {Array.<IMCMethodGetChatResponse>|null} [chats] MCMethodGetChatsResponse chats
     */

    /**
     * Constructs a new MCMethodGetChatsResponse.
     * @exports MCMethodGetChatsResponse
     * @classdesc Represents a MCMethodGetChatsResponse.
     * @implements IMCMethodGetChatsResponse
     * @constructor
     * @param {IMCMethodGetChatsResponse=} [properties] Properties to set
     */
    function MCMethodGetChatsResponse(properties) {
        this.chats = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetChatsResponse chats.
     * @member {Array.<IMCMethodGetChatResponse>} chats
     * @memberof MCMethodGetChatsResponse
     * @instance
     */
    MCMethodGetChatsResponse.prototype.chats = $util.emptyArray;

    /**
     * Creates a new MCMethodGetChatsResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodGetChatsResponse
     * @static
     * @param {IMCMethodGetChatsResponse=} [properties] Properties to set
     * @returns {MCMethodGetChatsResponse} MCMethodGetChatsResponse instance
     */
    MCMethodGetChatsResponse.create = function create(properties) {
        return new MCMethodGetChatsResponse(properties);
    };

    /**
     * Encodes the specified MCMethodGetChatsResponse message. Does not implicitly {@link MCMethodGetChatsResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetChatsResponse
     * @static
     * @param {IMCMethodGetChatsResponse} message MCMethodGetChatsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetChatsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.chats != null && message.chats.length)
            for (let i = 0; i < message.chats.length; ++i)
                $root.MCMethodGetChatResponse.encode(message.chats[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetChatsResponse message, length delimited. Does not implicitly {@link MCMethodGetChatsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetChatsResponse
     * @static
     * @param {IMCMethodGetChatsResponse} message MCMethodGetChatsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetChatsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetChatsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetChatsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetChatsResponse} MCMethodGetChatsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetChatsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetChatsResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.chats && message.chats.length))
                        message.chats = [];
                    message.chats.push($root.MCMethodGetChatResponse.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetChatsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetChatsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetChatsResponse} MCMethodGetChatsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetChatsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetChatsResponse message.
     * @function verify
     * @memberof MCMethodGetChatsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetChatsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.chats != null && message.hasOwnProperty("chats")) {
            if (!Array.isArray(message.chats))
                return "chats: array expected";
            for (let i = 0; i < message.chats.length; ++i) {
                let error = $root.MCMethodGetChatResponse.verify(message.chats[i]);
                if (error)
                    return "chats." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCMethodGetChatsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetChatsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetChatsResponse} MCMethodGetChatsResponse
     */
    MCMethodGetChatsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetChatsResponse)
            return object;
        let message = new $root.MCMethodGetChatsResponse();
        if (object.chats) {
            if (!Array.isArray(object.chats))
                throw TypeError(".MCMethodGetChatsResponse.chats: array expected");
            message.chats = [];
            for (let i = 0; i < object.chats.length; ++i) {
                if (typeof object.chats[i] !== "object")
                    throw TypeError(".MCMethodGetChatsResponse.chats: object expected");
                message.chats[i] = $root.MCMethodGetChatResponse.fromObject(object.chats[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetChatsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetChatsResponse
     * @static
     * @param {MCMethodGetChatsResponse} message MCMethodGetChatsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetChatsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.chats = [];
        if (message.chats && message.chats.length) {
            object.chats = [];
            for (let j = 0; j < message.chats.length; ++j)
                object.chats[j] = $root.MCMethodGetChatResponse.toObject(message.chats[j], options);
        }
        return object;
    };

    /**
     * Converts this MCMethodGetChatsResponse to JSON.
     * @function toJSON
     * @memberof MCMethodGetChatsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetChatsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetChatsResponse
     * @function getTypeUrl
     * @memberof MCMethodGetChatsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetChatsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetChatsResponse";
    };

    return MCMethodGetChatsResponse;
})();

export const MCMethodGetOrCreateChatByExternalID = $root.MCMethodGetOrCreateChatByExternalID = (() => {

    /**
     * Properties of a MCMethodGetOrCreateChatByExternalID.
     * @exports IMCMethodGetOrCreateChatByExternalID
     * @interface IMCMethodGetOrCreateChatByExternalID
     * @property {IMCChannelWithAccess|null} [channel] MCMethodGetOrCreateChatByExternalID channel
     * @property {Uint8Array|null} [externalID] MCMethodGetOrCreateChatByExternalID externalID
     */

    /**
     * Constructs a new MCMethodGetOrCreateChatByExternalID.
     * @exports MCMethodGetOrCreateChatByExternalID
     * @classdesc Represents a MCMethodGetOrCreateChatByExternalID.
     * @implements IMCMethodGetOrCreateChatByExternalID
     * @constructor
     * @param {IMCMethodGetOrCreateChatByExternalID=} [properties] Properties to set
     */
    function MCMethodGetOrCreateChatByExternalID(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetOrCreateChatByExternalID channel.
     * @member {IMCChannelWithAccess|null|undefined} channel
     * @memberof MCMethodGetOrCreateChatByExternalID
     * @instance
     */
    MCMethodGetOrCreateChatByExternalID.prototype.channel = null;

    /**
     * MCMethodGetOrCreateChatByExternalID externalID.
     * @member {Uint8Array} externalID
     * @memberof MCMethodGetOrCreateChatByExternalID
     * @instance
     */
    MCMethodGetOrCreateChatByExternalID.prototype.externalID = $util.newBuffer([]);

    /**
     * Creates a new MCMethodGetOrCreateChatByExternalID instance using the specified properties.
     * @function create
     * @memberof MCMethodGetOrCreateChatByExternalID
     * @static
     * @param {IMCMethodGetOrCreateChatByExternalID=} [properties] Properties to set
     * @returns {MCMethodGetOrCreateChatByExternalID} MCMethodGetOrCreateChatByExternalID instance
     */
    MCMethodGetOrCreateChatByExternalID.create = function create(properties) {
        return new MCMethodGetOrCreateChatByExternalID(properties);
    };

    /**
     * Encodes the specified MCMethodGetOrCreateChatByExternalID message. Does not implicitly {@link MCMethodGetOrCreateChatByExternalID.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetOrCreateChatByExternalID
     * @static
     * @param {IMCMethodGetOrCreateChatByExternalID} message MCMethodGetOrCreateChatByExternalID message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetOrCreateChatByExternalID.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
            $root.MCChannelWithAccess.encode(message.channel, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.externalID != null && Object.hasOwnProperty.call(message, "externalID"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.externalID);
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetOrCreateChatByExternalID message, length delimited. Does not implicitly {@link MCMethodGetOrCreateChatByExternalID.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetOrCreateChatByExternalID
     * @static
     * @param {IMCMethodGetOrCreateChatByExternalID} message MCMethodGetOrCreateChatByExternalID message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetOrCreateChatByExternalID.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetOrCreateChatByExternalID message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetOrCreateChatByExternalID
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetOrCreateChatByExternalID} MCMethodGetOrCreateChatByExternalID
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetOrCreateChatByExternalID.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetOrCreateChatByExternalID();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channel = $root.MCChannelWithAccess.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.externalID = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetOrCreateChatByExternalID message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetOrCreateChatByExternalID
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetOrCreateChatByExternalID} MCMethodGetOrCreateChatByExternalID
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetOrCreateChatByExternalID.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetOrCreateChatByExternalID message.
     * @function verify
     * @memberof MCMethodGetOrCreateChatByExternalID
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetOrCreateChatByExternalID.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channel != null && message.hasOwnProperty("channel")) {
            let error = $root.MCChannelWithAccess.verify(message.channel);
            if (error)
                return "channel." + error;
        }
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            if (!(message.externalID && typeof message.externalID.length === "number" || $util.isString(message.externalID)))
                return "externalID: buffer expected";
        return null;
    };

    /**
     * Creates a MCMethodGetOrCreateChatByExternalID message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetOrCreateChatByExternalID
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetOrCreateChatByExternalID} MCMethodGetOrCreateChatByExternalID
     */
    MCMethodGetOrCreateChatByExternalID.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetOrCreateChatByExternalID)
            return object;
        let message = new $root.MCMethodGetOrCreateChatByExternalID();
        if (object.channel != null) {
            if (typeof object.channel !== "object")
                throw TypeError(".MCMethodGetOrCreateChatByExternalID.channel: object expected");
            message.channel = $root.MCChannelWithAccess.fromObject(object.channel);
        }
        if (object.externalID != null)
            if (typeof object.externalID === "string")
                $util.base64.decode(object.externalID, message.externalID = $util.newBuffer($util.base64.length(object.externalID)), 0);
            else if (object.externalID.length >= 0)
                message.externalID = object.externalID;
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetOrCreateChatByExternalID message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetOrCreateChatByExternalID
     * @static
     * @param {MCMethodGetOrCreateChatByExternalID} message MCMethodGetOrCreateChatByExternalID
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetOrCreateChatByExternalID.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.channel = null;
            if (options.bytes === String)
                object.externalID = "";
            else {
                object.externalID = [];
                if (options.bytes !== Array)
                    object.externalID = $util.newBuffer(object.externalID);
            }
        }
        if (message.channel != null && message.hasOwnProperty("channel"))
            object.channel = $root.MCChannelWithAccess.toObject(message.channel, options);
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            object.externalID = options.bytes === String ? $util.base64.encode(message.externalID, 0, message.externalID.length) : options.bytes === Array ? Array.prototype.slice.call(message.externalID) : message.externalID;
        return object;
    };

    /**
     * Converts this MCMethodGetOrCreateChatByExternalID to JSON.
     * @function toJSON
     * @memberof MCMethodGetOrCreateChatByExternalID
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetOrCreateChatByExternalID.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetOrCreateChatByExternalID
     * @function getTypeUrl
     * @memberof MCMethodGetOrCreateChatByExternalID
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetOrCreateChatByExternalID.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetOrCreateChatByExternalID";
    };

    return MCMethodGetOrCreateChatByExternalID;
})();

export const MCMethodGetOrCreateChatByExternalIDResponse = $root.MCMethodGetOrCreateChatByExternalIDResponse = (() => {

    /**
     * Properties of a MCMethodGetOrCreateChatByExternalIDResponse.
     * @exports IMCMethodGetOrCreateChatByExternalIDResponse
     * @interface IMCMethodGetOrCreateChatByExternalIDResponse
     * @property {MCMethodGetOrCreateChatByExternalIDResponse.Result|null} [result] MCMethodGetOrCreateChatByExternalIDResponse result
     * @property {IMCChat|null} [chat] MCMethodGetOrCreateChatByExternalIDResponse chat
     * @property {boolean|null} [created] MCMethodGetOrCreateChatByExternalIDResponse created
     */

    /**
     * Constructs a new MCMethodGetOrCreateChatByExternalIDResponse.
     * @exports MCMethodGetOrCreateChatByExternalIDResponse
     * @classdesc Represents a MCMethodGetOrCreateChatByExternalIDResponse.
     * @implements IMCMethodGetOrCreateChatByExternalIDResponse
     * @constructor
     * @param {IMCMethodGetOrCreateChatByExternalIDResponse=} [properties] Properties to set
     */
    function MCMethodGetOrCreateChatByExternalIDResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetOrCreateChatByExternalIDResponse result.
     * @member {MCMethodGetOrCreateChatByExternalIDResponse.Result} result
     * @memberof MCMethodGetOrCreateChatByExternalIDResponse
     * @instance
     */
    MCMethodGetOrCreateChatByExternalIDResponse.prototype.result = 0;

    /**
     * MCMethodGetOrCreateChatByExternalIDResponse chat.
     * @member {IMCChat|null|undefined} chat
     * @memberof MCMethodGetOrCreateChatByExternalIDResponse
     * @instance
     */
    MCMethodGetOrCreateChatByExternalIDResponse.prototype.chat = null;

    /**
     * MCMethodGetOrCreateChatByExternalIDResponse created.
     * @member {boolean} created
     * @memberof MCMethodGetOrCreateChatByExternalIDResponse
     * @instance
     */
    MCMethodGetOrCreateChatByExternalIDResponse.prototype.created = false;

    /**
     * Creates a new MCMethodGetOrCreateChatByExternalIDResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodGetOrCreateChatByExternalIDResponse
     * @static
     * @param {IMCMethodGetOrCreateChatByExternalIDResponse=} [properties] Properties to set
     * @returns {MCMethodGetOrCreateChatByExternalIDResponse} MCMethodGetOrCreateChatByExternalIDResponse instance
     */
    MCMethodGetOrCreateChatByExternalIDResponse.create = function create(properties) {
        return new MCMethodGetOrCreateChatByExternalIDResponse(properties);
    };

    /**
     * Encodes the specified MCMethodGetOrCreateChatByExternalIDResponse message. Does not implicitly {@link MCMethodGetOrCreateChatByExternalIDResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetOrCreateChatByExternalIDResponse
     * @static
     * @param {IMCMethodGetOrCreateChatByExternalIDResponse} message MCMethodGetOrCreateChatByExternalIDResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetOrCreateChatByExternalIDResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.chat != null && Object.hasOwnProperty.call(message, "chat"))
            $root.MCChat.encode(message.chat, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.created != null && Object.hasOwnProperty.call(message, "created"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.created);
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetOrCreateChatByExternalIDResponse message, length delimited. Does not implicitly {@link MCMethodGetOrCreateChatByExternalIDResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetOrCreateChatByExternalIDResponse
     * @static
     * @param {IMCMethodGetOrCreateChatByExternalIDResponse} message MCMethodGetOrCreateChatByExternalIDResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetOrCreateChatByExternalIDResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetOrCreateChatByExternalIDResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetOrCreateChatByExternalIDResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetOrCreateChatByExternalIDResponse} MCMethodGetOrCreateChatByExternalIDResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetOrCreateChatByExternalIDResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetOrCreateChatByExternalIDResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    message.chat = $root.MCChat.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.created = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetOrCreateChatByExternalIDResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetOrCreateChatByExternalIDResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetOrCreateChatByExternalIDResponse} MCMethodGetOrCreateChatByExternalIDResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetOrCreateChatByExternalIDResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetOrCreateChatByExternalIDResponse message.
     * @function verify
     * @memberof MCMethodGetOrCreateChatByExternalIDResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetOrCreateChatByExternalIDResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 10:
                break;
            }
        if (message.chat != null && message.hasOwnProperty("chat")) {
            let error = $root.MCChat.verify(message.chat);
            if (error)
                return "chat." + error;
        }
        if (message.created != null && message.hasOwnProperty("created"))
            if (typeof message.created !== "boolean")
                return "created: boolean expected";
        return null;
    };

    /**
     * Creates a MCMethodGetOrCreateChatByExternalIDResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetOrCreateChatByExternalIDResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetOrCreateChatByExternalIDResponse} MCMethodGetOrCreateChatByExternalIDResponse
     */
    MCMethodGetOrCreateChatByExternalIDResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetOrCreateChatByExternalIDResponse)
            return object;
        let message = new $root.MCMethodGetOrCreateChatByExternalIDResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_UNKNOWN":
        case 0:
            message.result = 0;
            break;
        case "R_OK":
        case 1:
            message.result = 1;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.chat != null) {
            if (typeof object.chat !== "object")
                throw TypeError(".MCMethodGetOrCreateChatByExternalIDResponse.chat: object expected");
            message.chat = $root.MCChat.fromObject(object.chat);
        }
        if (object.created != null)
            message.created = Boolean(object.created);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetOrCreateChatByExternalIDResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetOrCreateChatByExternalIDResponse
     * @static
     * @param {MCMethodGetOrCreateChatByExternalIDResponse} message MCMethodGetOrCreateChatByExternalIDResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetOrCreateChatByExternalIDResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = options.enums === String ? "R_UNKNOWN" : 0;
            object.chat = null;
            object.created = false;
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodGetOrCreateChatByExternalIDResponse.Result[message.result] === undefined ? message.result : $root.MCMethodGetOrCreateChatByExternalIDResponse.Result[message.result] : message.result;
        if (message.chat != null && message.hasOwnProperty("chat"))
            object.chat = $root.MCChat.toObject(message.chat, options);
        if (message.created != null && message.hasOwnProperty("created"))
            object.created = message.created;
        return object;
    };

    /**
     * Converts this MCMethodGetOrCreateChatByExternalIDResponse to JSON.
     * @function toJSON
     * @memberof MCMethodGetOrCreateChatByExternalIDResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetOrCreateChatByExternalIDResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetOrCreateChatByExternalIDResponse
     * @function getTypeUrl
     * @memberof MCMethodGetOrCreateChatByExternalIDResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetOrCreateChatByExternalIDResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetOrCreateChatByExternalIDResponse";
    };

    /**
     * Result enum.
     * @name MCMethodGetOrCreateChatByExternalIDResponse.Result
     * @enum {number}
     * @property {number} R_UNKNOWN=0 R_UNKNOWN value
     * @property {number} R_OK=1 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=2 R_CHANNEL_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodGetOrCreateChatByExternalIDResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_UNKNOWN"] = 0;
        values[valuesById[1] = "R_OK"] = 1;
        values[valuesById[2] = "R_CHANNEL_NOT_FOUND"] = 2;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodGetOrCreateChatByExternalIDResponse;
})();

export const MCMethodGetOrCreatePeerByExternalID = $root.MCMethodGetOrCreatePeerByExternalID = (() => {

    /**
     * Properties of a MCMethodGetOrCreatePeerByExternalID.
     * @exports IMCMethodGetOrCreatePeerByExternalID
     * @interface IMCMethodGetOrCreatePeerByExternalID
     * @property {IMCChannelWithAccess|null} [channel] MCMethodGetOrCreatePeerByExternalID channel
     * @property {Uint8Array|null} [externalID] MCMethodGetOrCreatePeerByExternalID externalID
     * @property {IMCPeer|null} [peer] MCMethodGetOrCreatePeerByExternalID peer
     */

    /**
     * Constructs a new MCMethodGetOrCreatePeerByExternalID.
     * @exports MCMethodGetOrCreatePeerByExternalID
     * @classdesc Represents a MCMethodGetOrCreatePeerByExternalID.
     * @implements IMCMethodGetOrCreatePeerByExternalID
     * @constructor
     * @param {IMCMethodGetOrCreatePeerByExternalID=} [properties] Properties to set
     */
    function MCMethodGetOrCreatePeerByExternalID(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetOrCreatePeerByExternalID channel.
     * @member {IMCChannelWithAccess|null|undefined} channel
     * @memberof MCMethodGetOrCreatePeerByExternalID
     * @instance
     */
    MCMethodGetOrCreatePeerByExternalID.prototype.channel = null;

    /**
     * MCMethodGetOrCreatePeerByExternalID externalID.
     * @member {Uint8Array} externalID
     * @memberof MCMethodGetOrCreatePeerByExternalID
     * @instance
     */
    MCMethodGetOrCreatePeerByExternalID.prototype.externalID = $util.newBuffer([]);

    /**
     * MCMethodGetOrCreatePeerByExternalID peer.
     * @member {IMCPeer|null|undefined} peer
     * @memberof MCMethodGetOrCreatePeerByExternalID
     * @instance
     */
    MCMethodGetOrCreatePeerByExternalID.prototype.peer = null;

    /**
     * Creates a new MCMethodGetOrCreatePeerByExternalID instance using the specified properties.
     * @function create
     * @memberof MCMethodGetOrCreatePeerByExternalID
     * @static
     * @param {IMCMethodGetOrCreatePeerByExternalID=} [properties] Properties to set
     * @returns {MCMethodGetOrCreatePeerByExternalID} MCMethodGetOrCreatePeerByExternalID instance
     */
    MCMethodGetOrCreatePeerByExternalID.create = function create(properties) {
        return new MCMethodGetOrCreatePeerByExternalID(properties);
    };

    /**
     * Encodes the specified MCMethodGetOrCreatePeerByExternalID message. Does not implicitly {@link MCMethodGetOrCreatePeerByExternalID.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetOrCreatePeerByExternalID
     * @static
     * @param {IMCMethodGetOrCreatePeerByExternalID} message MCMethodGetOrCreatePeerByExternalID message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetOrCreatePeerByExternalID.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
            $root.MCChannelWithAccess.encode(message.channel, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.externalID != null && Object.hasOwnProperty.call(message, "externalID"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.externalID);
        if (message.peer != null && Object.hasOwnProperty.call(message, "peer"))
            $root.MCPeer.encode(message.peer, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetOrCreatePeerByExternalID message, length delimited. Does not implicitly {@link MCMethodGetOrCreatePeerByExternalID.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetOrCreatePeerByExternalID
     * @static
     * @param {IMCMethodGetOrCreatePeerByExternalID} message MCMethodGetOrCreatePeerByExternalID message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetOrCreatePeerByExternalID.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetOrCreatePeerByExternalID message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetOrCreatePeerByExternalID
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetOrCreatePeerByExternalID} MCMethodGetOrCreatePeerByExternalID
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetOrCreatePeerByExternalID.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetOrCreatePeerByExternalID();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channel = $root.MCChannelWithAccess.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.externalID = reader.bytes();
                    break;
                }
            case 3: {
                    message.peer = $root.MCPeer.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetOrCreatePeerByExternalID message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetOrCreatePeerByExternalID
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetOrCreatePeerByExternalID} MCMethodGetOrCreatePeerByExternalID
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetOrCreatePeerByExternalID.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetOrCreatePeerByExternalID message.
     * @function verify
     * @memberof MCMethodGetOrCreatePeerByExternalID
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetOrCreatePeerByExternalID.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channel != null && message.hasOwnProperty("channel")) {
            let error = $root.MCChannelWithAccess.verify(message.channel);
            if (error)
                return "channel." + error;
        }
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            if (!(message.externalID && typeof message.externalID.length === "number" || $util.isString(message.externalID)))
                return "externalID: buffer expected";
        if (message.peer != null && message.hasOwnProperty("peer")) {
            let error = $root.MCPeer.verify(message.peer);
            if (error)
                return "peer." + error;
        }
        return null;
    };

    /**
     * Creates a MCMethodGetOrCreatePeerByExternalID message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetOrCreatePeerByExternalID
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetOrCreatePeerByExternalID} MCMethodGetOrCreatePeerByExternalID
     */
    MCMethodGetOrCreatePeerByExternalID.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetOrCreatePeerByExternalID)
            return object;
        let message = new $root.MCMethodGetOrCreatePeerByExternalID();
        if (object.channel != null) {
            if (typeof object.channel !== "object")
                throw TypeError(".MCMethodGetOrCreatePeerByExternalID.channel: object expected");
            message.channel = $root.MCChannelWithAccess.fromObject(object.channel);
        }
        if (object.externalID != null)
            if (typeof object.externalID === "string")
                $util.base64.decode(object.externalID, message.externalID = $util.newBuffer($util.base64.length(object.externalID)), 0);
            else if (object.externalID.length >= 0)
                message.externalID = object.externalID;
        if (object.peer != null) {
            if (typeof object.peer !== "object")
                throw TypeError(".MCMethodGetOrCreatePeerByExternalID.peer: object expected");
            message.peer = $root.MCPeer.fromObject(object.peer);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetOrCreatePeerByExternalID message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetOrCreatePeerByExternalID
     * @static
     * @param {MCMethodGetOrCreatePeerByExternalID} message MCMethodGetOrCreatePeerByExternalID
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetOrCreatePeerByExternalID.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.channel = null;
            if (options.bytes === String)
                object.externalID = "";
            else {
                object.externalID = [];
                if (options.bytes !== Array)
                    object.externalID = $util.newBuffer(object.externalID);
            }
            object.peer = null;
        }
        if (message.channel != null && message.hasOwnProperty("channel"))
            object.channel = $root.MCChannelWithAccess.toObject(message.channel, options);
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            object.externalID = options.bytes === String ? $util.base64.encode(message.externalID, 0, message.externalID.length) : options.bytes === Array ? Array.prototype.slice.call(message.externalID) : message.externalID;
        if (message.peer != null && message.hasOwnProperty("peer"))
            object.peer = $root.MCPeer.toObject(message.peer, options);
        return object;
    };

    /**
     * Converts this MCMethodGetOrCreatePeerByExternalID to JSON.
     * @function toJSON
     * @memberof MCMethodGetOrCreatePeerByExternalID
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetOrCreatePeerByExternalID.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetOrCreatePeerByExternalID
     * @function getTypeUrl
     * @memberof MCMethodGetOrCreatePeerByExternalID
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetOrCreatePeerByExternalID.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetOrCreatePeerByExternalID";
    };

    return MCMethodGetOrCreatePeerByExternalID;
})();

export const MCMethodGetOrCreatePeerByExternalIDResponse = $root.MCMethodGetOrCreatePeerByExternalIDResponse = (() => {

    /**
     * Properties of a MCMethodGetOrCreatePeerByExternalIDResponse.
     * @exports IMCMethodGetOrCreatePeerByExternalIDResponse
     * @interface IMCMethodGetOrCreatePeerByExternalIDResponse
     * @property {MCMethodGetOrCreatePeerByExternalIDResponse.Result|null} [result] MCMethodGetOrCreatePeerByExternalIDResponse result
     * @property {IMCPeer|null} [peer] MCMethodGetOrCreatePeerByExternalIDResponse peer
     * @property {boolean|null} [created] MCMethodGetOrCreatePeerByExternalIDResponse created
     */

    /**
     * Constructs a new MCMethodGetOrCreatePeerByExternalIDResponse.
     * @exports MCMethodGetOrCreatePeerByExternalIDResponse
     * @classdesc Represents a MCMethodGetOrCreatePeerByExternalIDResponse.
     * @implements IMCMethodGetOrCreatePeerByExternalIDResponse
     * @constructor
     * @param {IMCMethodGetOrCreatePeerByExternalIDResponse=} [properties] Properties to set
     */
    function MCMethodGetOrCreatePeerByExternalIDResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodGetOrCreatePeerByExternalIDResponse result.
     * @member {MCMethodGetOrCreatePeerByExternalIDResponse.Result} result
     * @memberof MCMethodGetOrCreatePeerByExternalIDResponse
     * @instance
     */
    MCMethodGetOrCreatePeerByExternalIDResponse.prototype.result = 0;

    /**
     * MCMethodGetOrCreatePeerByExternalIDResponse peer.
     * @member {IMCPeer|null|undefined} peer
     * @memberof MCMethodGetOrCreatePeerByExternalIDResponse
     * @instance
     */
    MCMethodGetOrCreatePeerByExternalIDResponse.prototype.peer = null;

    /**
     * MCMethodGetOrCreatePeerByExternalIDResponse created.
     * @member {boolean} created
     * @memberof MCMethodGetOrCreatePeerByExternalIDResponse
     * @instance
     */
    MCMethodGetOrCreatePeerByExternalIDResponse.prototype.created = false;

    /**
     * Creates a new MCMethodGetOrCreatePeerByExternalIDResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodGetOrCreatePeerByExternalIDResponse
     * @static
     * @param {IMCMethodGetOrCreatePeerByExternalIDResponse=} [properties] Properties to set
     * @returns {MCMethodGetOrCreatePeerByExternalIDResponse} MCMethodGetOrCreatePeerByExternalIDResponse instance
     */
    MCMethodGetOrCreatePeerByExternalIDResponse.create = function create(properties) {
        return new MCMethodGetOrCreatePeerByExternalIDResponse(properties);
    };

    /**
     * Encodes the specified MCMethodGetOrCreatePeerByExternalIDResponse message. Does not implicitly {@link MCMethodGetOrCreatePeerByExternalIDResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodGetOrCreatePeerByExternalIDResponse
     * @static
     * @param {IMCMethodGetOrCreatePeerByExternalIDResponse} message MCMethodGetOrCreatePeerByExternalIDResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetOrCreatePeerByExternalIDResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.peer != null && Object.hasOwnProperty.call(message, "peer"))
            $root.MCPeer.encode(message.peer, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.created != null && Object.hasOwnProperty.call(message, "created"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.created);
        return writer;
    };

    /**
     * Encodes the specified MCMethodGetOrCreatePeerByExternalIDResponse message, length delimited. Does not implicitly {@link MCMethodGetOrCreatePeerByExternalIDResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodGetOrCreatePeerByExternalIDResponse
     * @static
     * @param {IMCMethodGetOrCreatePeerByExternalIDResponse} message MCMethodGetOrCreatePeerByExternalIDResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodGetOrCreatePeerByExternalIDResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodGetOrCreatePeerByExternalIDResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodGetOrCreatePeerByExternalIDResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodGetOrCreatePeerByExternalIDResponse} MCMethodGetOrCreatePeerByExternalIDResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetOrCreatePeerByExternalIDResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodGetOrCreatePeerByExternalIDResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    message.peer = $root.MCPeer.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.created = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodGetOrCreatePeerByExternalIDResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodGetOrCreatePeerByExternalIDResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodGetOrCreatePeerByExternalIDResponse} MCMethodGetOrCreatePeerByExternalIDResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodGetOrCreatePeerByExternalIDResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodGetOrCreatePeerByExternalIDResponse message.
     * @function verify
     * @memberof MCMethodGetOrCreatePeerByExternalIDResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodGetOrCreatePeerByExternalIDResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 10:
                break;
            }
        if (message.peer != null && message.hasOwnProperty("peer")) {
            let error = $root.MCPeer.verify(message.peer);
            if (error)
                return "peer." + error;
        }
        if (message.created != null && message.hasOwnProperty("created"))
            if (typeof message.created !== "boolean")
                return "created: boolean expected";
        return null;
    };

    /**
     * Creates a MCMethodGetOrCreatePeerByExternalIDResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodGetOrCreatePeerByExternalIDResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodGetOrCreatePeerByExternalIDResponse} MCMethodGetOrCreatePeerByExternalIDResponse
     */
    MCMethodGetOrCreatePeerByExternalIDResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodGetOrCreatePeerByExternalIDResponse)
            return object;
        let message = new $root.MCMethodGetOrCreatePeerByExternalIDResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_UNKNOWN":
        case 0:
            message.result = 0;
            break;
        case "R_OK":
        case 1:
            message.result = 1;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.peer != null) {
            if (typeof object.peer !== "object")
                throw TypeError(".MCMethodGetOrCreatePeerByExternalIDResponse.peer: object expected");
            message.peer = $root.MCPeer.fromObject(object.peer);
        }
        if (object.created != null)
            message.created = Boolean(object.created);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodGetOrCreatePeerByExternalIDResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodGetOrCreatePeerByExternalIDResponse
     * @static
     * @param {MCMethodGetOrCreatePeerByExternalIDResponse} message MCMethodGetOrCreatePeerByExternalIDResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodGetOrCreatePeerByExternalIDResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = options.enums === String ? "R_UNKNOWN" : 0;
            object.peer = null;
            object.created = false;
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodGetOrCreatePeerByExternalIDResponse.Result[message.result] === undefined ? message.result : $root.MCMethodGetOrCreatePeerByExternalIDResponse.Result[message.result] : message.result;
        if (message.peer != null && message.hasOwnProperty("peer"))
            object.peer = $root.MCPeer.toObject(message.peer, options);
        if (message.created != null && message.hasOwnProperty("created"))
            object.created = message.created;
        return object;
    };

    /**
     * Converts this MCMethodGetOrCreatePeerByExternalIDResponse to JSON.
     * @function toJSON
     * @memberof MCMethodGetOrCreatePeerByExternalIDResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodGetOrCreatePeerByExternalIDResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodGetOrCreatePeerByExternalIDResponse
     * @function getTypeUrl
     * @memberof MCMethodGetOrCreatePeerByExternalIDResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodGetOrCreatePeerByExternalIDResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodGetOrCreatePeerByExternalIDResponse";
    };

    /**
     * Result enum.
     * @name MCMethodGetOrCreatePeerByExternalIDResponse.Result
     * @enum {number}
     * @property {number} R_UNKNOWN=0 R_UNKNOWN value
     * @property {number} R_OK=1 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=2 R_CHANNEL_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodGetOrCreatePeerByExternalIDResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_UNKNOWN"] = 0;
        values[valuesById[1] = "R_OK"] = 1;
        values[valuesById[2] = "R_CHANNEL_NOT_FOUND"] = 2;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodGetOrCreatePeerByExternalIDResponse;
})();

export const MCMethodFindMessageLocalID = $root.MCMethodFindMessageLocalID = (() => {

    /**
     * Properties of a MCMethodFindMessageLocalID.
     * @exports IMCMethodFindMessageLocalID
     * @interface IMCMethodFindMessageLocalID
     * @property {Uint8Array|null} [channelID] MCMethodFindMessageLocalID channelID
     * @property {Long|null} [chatID] MCMethodFindMessageLocalID chatID
     * @property {Uint8Array|null} [externalID] MCMethodFindMessageLocalID externalID
     */

    /**
     * Constructs a new MCMethodFindMessageLocalID.
     * @exports MCMethodFindMessageLocalID
     * @classdesc Represents a MCMethodFindMessageLocalID.
     * @implements IMCMethodFindMessageLocalID
     * @constructor
     * @param {IMCMethodFindMessageLocalID=} [properties] Properties to set
     */
    function MCMethodFindMessageLocalID(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodFindMessageLocalID channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodFindMessageLocalID
     * @instance
     */
    MCMethodFindMessageLocalID.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodFindMessageLocalID chatID.
     * @member {Long} chatID
     * @memberof MCMethodFindMessageLocalID
     * @instance
     */
    MCMethodFindMessageLocalID.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodFindMessageLocalID externalID.
     * @member {Uint8Array} externalID
     * @memberof MCMethodFindMessageLocalID
     * @instance
     */
    MCMethodFindMessageLocalID.prototype.externalID = $util.newBuffer([]);

    /**
     * Creates a new MCMethodFindMessageLocalID instance using the specified properties.
     * @function create
     * @memberof MCMethodFindMessageLocalID
     * @static
     * @param {IMCMethodFindMessageLocalID=} [properties] Properties to set
     * @returns {MCMethodFindMessageLocalID} MCMethodFindMessageLocalID instance
     */
    MCMethodFindMessageLocalID.create = function create(properties) {
        return new MCMethodFindMessageLocalID(properties);
    };

    /**
     * Encodes the specified MCMethodFindMessageLocalID message. Does not implicitly {@link MCMethodFindMessageLocalID.verify|verify} messages.
     * @function encode
     * @memberof MCMethodFindMessageLocalID
     * @static
     * @param {IMCMethodFindMessageLocalID} message MCMethodFindMessageLocalID message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodFindMessageLocalID.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chatID);
        if (message.externalID != null && Object.hasOwnProperty.call(message, "externalID"))
            writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.externalID);
        return writer;
    };

    /**
     * Encodes the specified MCMethodFindMessageLocalID message, length delimited. Does not implicitly {@link MCMethodFindMessageLocalID.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodFindMessageLocalID
     * @static
     * @param {IMCMethodFindMessageLocalID} message MCMethodFindMessageLocalID message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodFindMessageLocalID.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodFindMessageLocalID message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodFindMessageLocalID
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodFindMessageLocalID} MCMethodFindMessageLocalID
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodFindMessageLocalID.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodFindMessageLocalID();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 3: {
                    message.externalID = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodFindMessageLocalID message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodFindMessageLocalID
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodFindMessageLocalID} MCMethodFindMessageLocalID
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodFindMessageLocalID.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodFindMessageLocalID message.
     * @function verify
     * @memberof MCMethodFindMessageLocalID
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodFindMessageLocalID.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            if (!(message.externalID && typeof message.externalID.length === "number" || $util.isString(message.externalID)))
                return "externalID: buffer expected";
        return null;
    };

    /**
     * Creates a MCMethodFindMessageLocalID message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodFindMessageLocalID
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodFindMessageLocalID} MCMethodFindMessageLocalID
     */
    MCMethodFindMessageLocalID.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodFindMessageLocalID)
            return object;
        let message = new $root.MCMethodFindMessageLocalID();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.externalID != null)
            if (typeof object.externalID === "string")
                $util.base64.decode(object.externalID, message.externalID = $util.newBuffer($util.base64.length(object.externalID)), 0);
            else if (object.externalID.length >= 0)
                message.externalID = object.externalID;
        return message;
    };

    /**
     * Creates a plain object from a MCMethodFindMessageLocalID message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodFindMessageLocalID
     * @static
     * @param {MCMethodFindMessageLocalID} message MCMethodFindMessageLocalID
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodFindMessageLocalID.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
            if (options.bytes === String)
                object.externalID = "";
            else {
                object.externalID = [];
                if (options.bytes !== Array)
                    object.externalID = $util.newBuffer(object.externalID);
            }
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            object.externalID = options.bytes === String ? $util.base64.encode(message.externalID, 0, message.externalID.length) : options.bytes === Array ? Array.prototype.slice.call(message.externalID) : message.externalID;
        return object;
    };

    /**
     * Converts this MCMethodFindMessageLocalID to JSON.
     * @function toJSON
     * @memberof MCMethodFindMessageLocalID
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodFindMessageLocalID.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodFindMessageLocalID
     * @function getTypeUrl
     * @memberof MCMethodFindMessageLocalID
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodFindMessageLocalID.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodFindMessageLocalID";
    };

    return MCMethodFindMessageLocalID;
})();

export const MCMethodFindMessageLocalIDResponse = $root.MCMethodFindMessageLocalIDResponse = (() => {

    /**
     * Properties of a MCMethodFindMessageLocalIDResponse.
     * @exports IMCMethodFindMessageLocalIDResponse
     * @interface IMCMethodFindMessageLocalIDResponse
     * @property {MCMethodFindMessageLocalIDResponse.Result|null} [result] MCMethodFindMessageLocalIDResponse result
     * @property {Long|null} [localID] MCMethodFindMessageLocalIDResponse localID
     */

    /**
     * Constructs a new MCMethodFindMessageLocalIDResponse.
     * @exports MCMethodFindMessageLocalIDResponse
     * @classdesc Represents a MCMethodFindMessageLocalIDResponse.
     * @implements IMCMethodFindMessageLocalIDResponse
     * @constructor
     * @param {IMCMethodFindMessageLocalIDResponse=} [properties] Properties to set
     */
    function MCMethodFindMessageLocalIDResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodFindMessageLocalIDResponse result.
     * @member {MCMethodFindMessageLocalIDResponse.Result} result
     * @memberof MCMethodFindMessageLocalIDResponse
     * @instance
     */
    MCMethodFindMessageLocalIDResponse.prototype.result = 0;

    /**
     * MCMethodFindMessageLocalIDResponse localID.
     * @member {Long} localID
     * @memberof MCMethodFindMessageLocalIDResponse
     * @instance
     */
    MCMethodFindMessageLocalIDResponse.prototype.localID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCMethodFindMessageLocalIDResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodFindMessageLocalIDResponse
     * @static
     * @param {IMCMethodFindMessageLocalIDResponse=} [properties] Properties to set
     * @returns {MCMethodFindMessageLocalIDResponse} MCMethodFindMessageLocalIDResponse instance
     */
    MCMethodFindMessageLocalIDResponse.create = function create(properties) {
        return new MCMethodFindMessageLocalIDResponse(properties);
    };

    /**
     * Encodes the specified MCMethodFindMessageLocalIDResponse message. Does not implicitly {@link MCMethodFindMessageLocalIDResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodFindMessageLocalIDResponse
     * @static
     * @param {IMCMethodFindMessageLocalIDResponse} message MCMethodFindMessageLocalIDResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodFindMessageLocalIDResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.localID != null && Object.hasOwnProperty.call(message, "localID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.localID);
        return writer;
    };

    /**
     * Encodes the specified MCMethodFindMessageLocalIDResponse message, length delimited. Does not implicitly {@link MCMethodFindMessageLocalIDResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodFindMessageLocalIDResponse
     * @static
     * @param {IMCMethodFindMessageLocalIDResponse} message MCMethodFindMessageLocalIDResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodFindMessageLocalIDResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodFindMessageLocalIDResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodFindMessageLocalIDResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodFindMessageLocalIDResponse} MCMethodFindMessageLocalIDResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodFindMessageLocalIDResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodFindMessageLocalIDResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    message.localID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodFindMessageLocalIDResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodFindMessageLocalIDResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodFindMessageLocalIDResponse} MCMethodFindMessageLocalIDResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodFindMessageLocalIDResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodFindMessageLocalIDResponse message.
     * @function verify
     * @memberof MCMethodFindMessageLocalIDResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodFindMessageLocalIDResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 10:
                break;
            }
        if (message.localID != null && message.hasOwnProperty("localID"))
            if (!$util.isInteger(message.localID) && !(message.localID && $util.isInteger(message.localID.low) && $util.isInteger(message.localID.high)))
                return "localID: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCMethodFindMessageLocalIDResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodFindMessageLocalIDResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodFindMessageLocalIDResponse} MCMethodFindMessageLocalIDResponse
     */
    MCMethodFindMessageLocalIDResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodFindMessageLocalIDResponse)
            return object;
        let message = new $root.MCMethodFindMessageLocalIDResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_UNKNOWN":
        case 0:
            message.result = 0;
            break;
        case "R_OK":
        case 1:
            message.result = 1;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_CHAT_NOT_FOUND":
        case 3:
            message.result = 3;
            break;
        case "R_MESSAGE_NOT_FOUND":
        case 4:
            message.result = 4;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.localID != null)
            if ($util.Long)
                (message.localID = $util.Long.fromValue(object.localID)).unsigned = true;
            else if (typeof object.localID === "string")
                message.localID = parseInt(object.localID, 10);
            else if (typeof object.localID === "number")
                message.localID = object.localID;
            else if (typeof object.localID === "object")
                message.localID = new $util.LongBits(object.localID.low >>> 0, object.localID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodFindMessageLocalIDResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodFindMessageLocalIDResponse
     * @static
     * @param {MCMethodFindMessageLocalIDResponse} message MCMethodFindMessageLocalIDResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodFindMessageLocalIDResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = options.enums === String ? "R_UNKNOWN" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.localID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.localID = options.longs === String ? "0" : 0;
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodFindMessageLocalIDResponse.Result[message.result] === undefined ? message.result : $root.MCMethodFindMessageLocalIDResponse.Result[message.result] : message.result;
        if (message.localID != null && message.hasOwnProperty("localID"))
            if (typeof message.localID === "number")
                object.localID = options.longs === String ? String(message.localID) : message.localID;
            else
                object.localID = options.longs === String ? $util.Long.prototype.toString.call(message.localID) : options.longs === Number ? new $util.LongBits(message.localID.low >>> 0, message.localID.high >>> 0).toNumber(true) : message.localID;
        return object;
    };

    /**
     * Converts this MCMethodFindMessageLocalIDResponse to JSON.
     * @function toJSON
     * @memberof MCMethodFindMessageLocalIDResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodFindMessageLocalIDResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodFindMessageLocalIDResponse
     * @function getTypeUrl
     * @memberof MCMethodFindMessageLocalIDResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodFindMessageLocalIDResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodFindMessageLocalIDResponse";
    };

    /**
     * Result enum.
     * @name MCMethodFindMessageLocalIDResponse.Result
     * @enum {number}
     * @property {number} R_UNKNOWN=0 R_UNKNOWN value
     * @property {number} R_OK=1 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=2 R_CHANNEL_NOT_FOUND value
     * @property {number} R_CHAT_NOT_FOUND=3 R_CHAT_NOT_FOUND value
     * @property {number} R_MESSAGE_NOT_FOUND=4 R_MESSAGE_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodFindMessageLocalIDResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_UNKNOWN"] = 0;
        values[valuesById[1] = "R_OK"] = 1;
        values[valuesById[2] = "R_CHANNEL_NOT_FOUND"] = 2;
        values[valuesById[3] = "R_CHAT_NOT_FOUND"] = 3;
        values[valuesById[4] = "R_MESSAGE_NOT_FOUND"] = 4;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodFindMessageLocalIDResponse;
})();

export const MCMethodFindChatByExternalID = $root.MCMethodFindChatByExternalID = (() => {

    /**
     * Properties of a MCMethodFindChatByExternalID.
     * @exports IMCMethodFindChatByExternalID
     * @interface IMCMethodFindChatByExternalID
     * @property {Uint8Array|null} [channelID] MCMethodFindChatByExternalID channelID
     * @property {Uint8Array|null} [externalID] MCMethodFindChatByExternalID externalID
     */

    /**
     * Constructs a new MCMethodFindChatByExternalID.
     * @exports MCMethodFindChatByExternalID
     * @classdesc Represents a MCMethodFindChatByExternalID.
     * @implements IMCMethodFindChatByExternalID
     * @constructor
     * @param {IMCMethodFindChatByExternalID=} [properties] Properties to set
     */
    function MCMethodFindChatByExternalID(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodFindChatByExternalID channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodFindChatByExternalID
     * @instance
     */
    MCMethodFindChatByExternalID.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodFindChatByExternalID externalID.
     * @member {Uint8Array} externalID
     * @memberof MCMethodFindChatByExternalID
     * @instance
     */
    MCMethodFindChatByExternalID.prototype.externalID = $util.newBuffer([]);

    /**
     * Creates a new MCMethodFindChatByExternalID instance using the specified properties.
     * @function create
     * @memberof MCMethodFindChatByExternalID
     * @static
     * @param {IMCMethodFindChatByExternalID=} [properties] Properties to set
     * @returns {MCMethodFindChatByExternalID} MCMethodFindChatByExternalID instance
     */
    MCMethodFindChatByExternalID.create = function create(properties) {
        return new MCMethodFindChatByExternalID(properties);
    };

    /**
     * Encodes the specified MCMethodFindChatByExternalID message. Does not implicitly {@link MCMethodFindChatByExternalID.verify|verify} messages.
     * @function encode
     * @memberof MCMethodFindChatByExternalID
     * @static
     * @param {IMCMethodFindChatByExternalID} message MCMethodFindChatByExternalID message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodFindChatByExternalID.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.externalID != null && Object.hasOwnProperty.call(message, "externalID"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.externalID);
        return writer;
    };

    /**
     * Encodes the specified MCMethodFindChatByExternalID message, length delimited. Does not implicitly {@link MCMethodFindChatByExternalID.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodFindChatByExternalID
     * @static
     * @param {IMCMethodFindChatByExternalID} message MCMethodFindChatByExternalID message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodFindChatByExternalID.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodFindChatByExternalID message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodFindChatByExternalID
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodFindChatByExternalID} MCMethodFindChatByExternalID
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodFindChatByExternalID.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodFindChatByExternalID();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.externalID = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodFindChatByExternalID message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodFindChatByExternalID
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodFindChatByExternalID} MCMethodFindChatByExternalID
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodFindChatByExternalID.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodFindChatByExternalID message.
     * @function verify
     * @memberof MCMethodFindChatByExternalID
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodFindChatByExternalID.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            if (!(message.externalID && typeof message.externalID.length === "number" || $util.isString(message.externalID)))
                return "externalID: buffer expected";
        return null;
    };

    /**
     * Creates a MCMethodFindChatByExternalID message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodFindChatByExternalID
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodFindChatByExternalID} MCMethodFindChatByExternalID
     */
    MCMethodFindChatByExternalID.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodFindChatByExternalID)
            return object;
        let message = new $root.MCMethodFindChatByExternalID();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.externalID != null)
            if (typeof object.externalID === "string")
                $util.base64.decode(object.externalID, message.externalID = $util.newBuffer($util.base64.length(object.externalID)), 0);
            else if (object.externalID.length >= 0)
                message.externalID = object.externalID;
        return message;
    };

    /**
     * Creates a plain object from a MCMethodFindChatByExternalID message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodFindChatByExternalID
     * @static
     * @param {MCMethodFindChatByExternalID} message MCMethodFindChatByExternalID
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodFindChatByExternalID.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            if (options.bytes === String)
                object.externalID = "";
            else {
                object.externalID = [];
                if (options.bytes !== Array)
                    object.externalID = $util.newBuffer(object.externalID);
            }
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            object.externalID = options.bytes === String ? $util.base64.encode(message.externalID, 0, message.externalID.length) : options.bytes === Array ? Array.prototype.slice.call(message.externalID) : message.externalID;
        return object;
    };

    /**
     * Converts this MCMethodFindChatByExternalID to JSON.
     * @function toJSON
     * @memberof MCMethodFindChatByExternalID
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodFindChatByExternalID.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodFindChatByExternalID
     * @function getTypeUrl
     * @memberof MCMethodFindChatByExternalID
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodFindChatByExternalID.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodFindChatByExternalID";
    };

    return MCMethodFindChatByExternalID;
})();

export const MCMethodFindChatByExternalIDResponse = $root.MCMethodFindChatByExternalIDResponse = (() => {

    /**
     * Properties of a MCMethodFindChatByExternalIDResponse.
     * @exports IMCMethodFindChatByExternalIDResponse
     * @interface IMCMethodFindChatByExternalIDResponse
     * @property {MCMethodFindChatByExternalIDResponse.Result|null} [result] MCMethodFindChatByExternalIDResponse result
     * @property {IMCChat|null} [chat] MCMethodFindChatByExternalIDResponse chat
     */

    /**
     * Constructs a new MCMethodFindChatByExternalIDResponse.
     * @exports MCMethodFindChatByExternalIDResponse
     * @classdesc Represents a MCMethodFindChatByExternalIDResponse.
     * @implements IMCMethodFindChatByExternalIDResponse
     * @constructor
     * @param {IMCMethodFindChatByExternalIDResponse=} [properties] Properties to set
     */
    function MCMethodFindChatByExternalIDResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodFindChatByExternalIDResponse result.
     * @member {MCMethodFindChatByExternalIDResponse.Result} result
     * @memberof MCMethodFindChatByExternalIDResponse
     * @instance
     */
    MCMethodFindChatByExternalIDResponse.prototype.result = 0;

    /**
     * MCMethodFindChatByExternalIDResponse chat.
     * @member {IMCChat|null|undefined} chat
     * @memberof MCMethodFindChatByExternalIDResponse
     * @instance
     */
    MCMethodFindChatByExternalIDResponse.prototype.chat = null;

    /**
     * Creates a new MCMethodFindChatByExternalIDResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodFindChatByExternalIDResponse
     * @static
     * @param {IMCMethodFindChatByExternalIDResponse=} [properties] Properties to set
     * @returns {MCMethodFindChatByExternalIDResponse} MCMethodFindChatByExternalIDResponse instance
     */
    MCMethodFindChatByExternalIDResponse.create = function create(properties) {
        return new MCMethodFindChatByExternalIDResponse(properties);
    };

    /**
     * Encodes the specified MCMethodFindChatByExternalIDResponse message. Does not implicitly {@link MCMethodFindChatByExternalIDResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodFindChatByExternalIDResponse
     * @static
     * @param {IMCMethodFindChatByExternalIDResponse} message MCMethodFindChatByExternalIDResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodFindChatByExternalIDResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.chat != null && Object.hasOwnProperty.call(message, "chat"))
            $root.MCChat.encode(message.chat, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodFindChatByExternalIDResponse message, length delimited. Does not implicitly {@link MCMethodFindChatByExternalIDResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodFindChatByExternalIDResponse
     * @static
     * @param {IMCMethodFindChatByExternalIDResponse} message MCMethodFindChatByExternalIDResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodFindChatByExternalIDResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodFindChatByExternalIDResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodFindChatByExternalIDResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodFindChatByExternalIDResponse} MCMethodFindChatByExternalIDResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodFindChatByExternalIDResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodFindChatByExternalIDResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    message.chat = $root.MCChat.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodFindChatByExternalIDResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodFindChatByExternalIDResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodFindChatByExternalIDResponse} MCMethodFindChatByExternalIDResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodFindChatByExternalIDResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodFindChatByExternalIDResponse message.
     * @function verify
     * @memberof MCMethodFindChatByExternalIDResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodFindChatByExternalIDResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 10:
                break;
            }
        if (message.chat != null && message.hasOwnProperty("chat")) {
            let error = $root.MCChat.verify(message.chat);
            if (error)
                return "chat." + error;
        }
        return null;
    };

    /**
     * Creates a MCMethodFindChatByExternalIDResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodFindChatByExternalIDResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodFindChatByExternalIDResponse} MCMethodFindChatByExternalIDResponse
     */
    MCMethodFindChatByExternalIDResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodFindChatByExternalIDResponse)
            return object;
        let message = new $root.MCMethodFindChatByExternalIDResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_UNKNOWN":
        case 0:
            message.result = 0;
            break;
        case "R_OK":
        case 1:
            message.result = 1;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_CHAT_NOT_FOUND":
        case 3:
            message.result = 3;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.chat != null) {
            if (typeof object.chat !== "object")
                throw TypeError(".MCMethodFindChatByExternalIDResponse.chat: object expected");
            message.chat = $root.MCChat.fromObject(object.chat);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodFindChatByExternalIDResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodFindChatByExternalIDResponse
     * @static
     * @param {MCMethodFindChatByExternalIDResponse} message MCMethodFindChatByExternalIDResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodFindChatByExternalIDResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = options.enums === String ? "R_UNKNOWN" : 0;
            object.chat = null;
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodFindChatByExternalIDResponse.Result[message.result] === undefined ? message.result : $root.MCMethodFindChatByExternalIDResponse.Result[message.result] : message.result;
        if (message.chat != null && message.hasOwnProperty("chat"))
            object.chat = $root.MCChat.toObject(message.chat, options);
        return object;
    };

    /**
     * Converts this MCMethodFindChatByExternalIDResponse to JSON.
     * @function toJSON
     * @memberof MCMethodFindChatByExternalIDResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodFindChatByExternalIDResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodFindChatByExternalIDResponse
     * @function getTypeUrl
     * @memberof MCMethodFindChatByExternalIDResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodFindChatByExternalIDResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodFindChatByExternalIDResponse";
    };

    /**
     * Result enum.
     * @name MCMethodFindChatByExternalIDResponse.Result
     * @enum {number}
     * @property {number} R_UNKNOWN=0 R_UNKNOWN value
     * @property {number} R_OK=1 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=2 R_CHANNEL_NOT_FOUND value
     * @property {number} R_CHAT_NOT_FOUND=3 R_CHAT_NOT_FOUND value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodFindChatByExternalIDResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_UNKNOWN"] = 0;
        values[valuesById[1] = "R_OK"] = 1;
        values[valuesById[2] = "R_CHANNEL_NOT_FOUND"] = 2;
        values[valuesById[3] = "R_CHAT_NOT_FOUND"] = 3;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodFindChatByExternalIDResponse;
})();

export const MCMethodRestrictChatAccess = $root.MCMethodRestrictChatAccess = (() => {

    /**
     * Properties of a MCMethodRestrictChatAccess.
     * @exports IMCMethodRestrictChatAccess
     * @interface IMCMethodRestrictChatAccess
     * @property {Uint8Array|null} [channelID] MCMethodRestrictChatAccess channelID
     * @property {Long|null} [chatID] MCMethodRestrictChatAccess chatID
     * @property {Long|null} [peerID] MCMethodRestrictChatAccess peerID
     * @property {Long|null} [until] MCMethodRestrictChatAccess until
     */

    /**
     * Constructs a new MCMethodRestrictChatAccess.
     * @exports MCMethodRestrictChatAccess
     * @classdesc Represents a MCMethodRestrictChatAccess.
     * @implements IMCMethodRestrictChatAccess
     * @constructor
     * @param {IMCMethodRestrictChatAccess=} [properties] Properties to set
     */
    function MCMethodRestrictChatAccess(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodRestrictChatAccess channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodRestrictChatAccess
     * @instance
     */
    MCMethodRestrictChatAccess.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodRestrictChatAccess chatID.
     * @member {Long} chatID
     * @memberof MCMethodRestrictChatAccess
     * @instance
     */
    MCMethodRestrictChatAccess.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodRestrictChatAccess peerID.
     * @member {Long} peerID
     * @memberof MCMethodRestrictChatAccess
     * @instance
     */
    MCMethodRestrictChatAccess.prototype.peerID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodRestrictChatAccess until.
     * @member {Long} until
     * @memberof MCMethodRestrictChatAccess
     * @instance
     */
    MCMethodRestrictChatAccess.prototype.until = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCMethodRestrictChatAccess instance using the specified properties.
     * @function create
     * @memberof MCMethodRestrictChatAccess
     * @static
     * @param {IMCMethodRestrictChatAccess=} [properties] Properties to set
     * @returns {MCMethodRestrictChatAccess} MCMethodRestrictChatAccess instance
     */
    MCMethodRestrictChatAccess.create = function create(properties) {
        return new MCMethodRestrictChatAccess(properties);
    };

    /**
     * Encodes the specified MCMethodRestrictChatAccess message. Does not implicitly {@link MCMethodRestrictChatAccess.verify|verify} messages.
     * @function encode
     * @memberof MCMethodRestrictChatAccess
     * @static
     * @param {IMCMethodRestrictChatAccess} message MCMethodRestrictChatAccess message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodRestrictChatAccess.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chatID);
        if (message.peerID != null && Object.hasOwnProperty.call(message, "peerID"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.peerID);
        if (message.until != null && Object.hasOwnProperty.call(message, "until"))
            writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.until);
        return writer;
    };

    /**
     * Encodes the specified MCMethodRestrictChatAccess message, length delimited. Does not implicitly {@link MCMethodRestrictChatAccess.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodRestrictChatAccess
     * @static
     * @param {IMCMethodRestrictChatAccess} message MCMethodRestrictChatAccess message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodRestrictChatAccess.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodRestrictChatAccess message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodRestrictChatAccess
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodRestrictChatAccess} MCMethodRestrictChatAccess
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodRestrictChatAccess.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodRestrictChatAccess();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 3: {
                    message.peerID = reader.uint64();
                    break;
                }
            case 4: {
                    message.until = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodRestrictChatAccess message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodRestrictChatAccess
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodRestrictChatAccess} MCMethodRestrictChatAccess
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodRestrictChatAccess.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodRestrictChatAccess message.
     * @function verify
     * @memberof MCMethodRestrictChatAccess
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodRestrictChatAccess.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.peerID != null && message.hasOwnProperty("peerID"))
            if (!$util.isInteger(message.peerID) && !(message.peerID && $util.isInteger(message.peerID.low) && $util.isInteger(message.peerID.high)))
                return "peerID: integer|Long expected";
        if (message.until != null && message.hasOwnProperty("until"))
            if (!$util.isInteger(message.until) && !(message.until && $util.isInteger(message.until.low) && $util.isInteger(message.until.high)))
                return "until: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCMethodRestrictChatAccess message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodRestrictChatAccess
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodRestrictChatAccess} MCMethodRestrictChatAccess
     */
    MCMethodRestrictChatAccess.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodRestrictChatAccess)
            return object;
        let message = new $root.MCMethodRestrictChatAccess();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.peerID != null)
            if ($util.Long)
                (message.peerID = $util.Long.fromValue(object.peerID)).unsigned = true;
            else if (typeof object.peerID === "string")
                message.peerID = parseInt(object.peerID, 10);
            else if (typeof object.peerID === "number")
                message.peerID = object.peerID;
            else if (typeof object.peerID === "object")
                message.peerID = new $util.LongBits(object.peerID.low >>> 0, object.peerID.high >>> 0).toNumber(true);
        if (object.until != null)
            if ($util.Long)
                (message.until = $util.Long.fromValue(object.until)).unsigned = true;
            else if (typeof object.until === "string")
                message.until = parseInt(object.until, 10);
            else if (typeof object.until === "number")
                message.until = object.until;
            else if (typeof object.until === "object")
                message.until = new $util.LongBits(object.until.low >>> 0, object.until.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodRestrictChatAccess message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodRestrictChatAccess
     * @static
     * @param {MCMethodRestrictChatAccess} message MCMethodRestrictChatAccess
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodRestrictChatAccess.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.peerID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.peerID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.until = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.until = options.longs === String ? "0" : 0;
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.peerID != null && message.hasOwnProperty("peerID"))
            if (typeof message.peerID === "number")
                object.peerID = options.longs === String ? String(message.peerID) : message.peerID;
            else
                object.peerID = options.longs === String ? $util.Long.prototype.toString.call(message.peerID) : options.longs === Number ? new $util.LongBits(message.peerID.low >>> 0, message.peerID.high >>> 0).toNumber(true) : message.peerID;
        if (message.until != null && message.hasOwnProperty("until"))
            if (typeof message.until === "number")
                object.until = options.longs === String ? String(message.until) : message.until;
            else
                object.until = options.longs === String ? $util.Long.prototype.toString.call(message.until) : options.longs === Number ? new $util.LongBits(message.until.low >>> 0, message.until.high >>> 0).toNumber(true) : message.until;
        return object;
    };

    /**
     * Converts this MCMethodRestrictChatAccess to JSON.
     * @function toJSON
     * @memberof MCMethodRestrictChatAccess
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodRestrictChatAccess.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodRestrictChatAccess
     * @function getTypeUrl
     * @memberof MCMethodRestrictChatAccess
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodRestrictChatAccess.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodRestrictChatAccess";
    };

    return MCMethodRestrictChatAccess;
})();

export const MCMethodRestrictChatAccessResponse = $root.MCMethodRestrictChatAccessResponse = (() => {

    /**
     * Properties of a MCMethodRestrictChatAccessResponse.
     * @exports IMCMethodRestrictChatAccessResponse
     * @interface IMCMethodRestrictChatAccessResponse
     * @property {MCMethodRestrictChatAccessResponse.Result|null} [result] MCMethodRestrictChatAccessResponse result
     */

    /**
     * Constructs a new MCMethodRestrictChatAccessResponse.
     * @exports MCMethodRestrictChatAccessResponse
     * @classdesc Represents a MCMethodRestrictChatAccessResponse.
     * @implements IMCMethodRestrictChatAccessResponse
     * @constructor
     * @param {IMCMethodRestrictChatAccessResponse=} [properties] Properties to set
     */
    function MCMethodRestrictChatAccessResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodRestrictChatAccessResponse result.
     * @member {MCMethodRestrictChatAccessResponse.Result} result
     * @memberof MCMethodRestrictChatAccessResponse
     * @instance
     */
    MCMethodRestrictChatAccessResponse.prototype.result = 0;

    /**
     * Creates a new MCMethodRestrictChatAccessResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodRestrictChatAccessResponse
     * @static
     * @param {IMCMethodRestrictChatAccessResponse=} [properties] Properties to set
     * @returns {MCMethodRestrictChatAccessResponse} MCMethodRestrictChatAccessResponse instance
     */
    MCMethodRestrictChatAccessResponse.create = function create(properties) {
        return new MCMethodRestrictChatAccessResponse(properties);
    };

    /**
     * Encodes the specified MCMethodRestrictChatAccessResponse message. Does not implicitly {@link MCMethodRestrictChatAccessResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodRestrictChatAccessResponse
     * @static
     * @param {IMCMethodRestrictChatAccessResponse} message MCMethodRestrictChatAccessResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodRestrictChatAccessResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        return writer;
    };

    /**
     * Encodes the specified MCMethodRestrictChatAccessResponse message, length delimited. Does not implicitly {@link MCMethodRestrictChatAccessResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodRestrictChatAccessResponse
     * @static
     * @param {IMCMethodRestrictChatAccessResponse} message MCMethodRestrictChatAccessResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodRestrictChatAccessResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodRestrictChatAccessResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodRestrictChatAccessResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodRestrictChatAccessResponse} MCMethodRestrictChatAccessResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodRestrictChatAccessResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodRestrictChatAccessResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodRestrictChatAccessResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodRestrictChatAccessResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodRestrictChatAccessResponse} MCMethodRestrictChatAccessResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodRestrictChatAccessResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodRestrictChatAccessResponse message.
     * @function verify
     * @memberof MCMethodRestrictChatAccessResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodRestrictChatAccessResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 10:
                break;
            }
        return null;
    };

    /**
     * Creates a MCMethodRestrictChatAccessResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodRestrictChatAccessResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodRestrictChatAccessResponse} MCMethodRestrictChatAccessResponse
     */
    MCMethodRestrictChatAccessResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodRestrictChatAccessResponse)
            return object;
        let message = new $root.MCMethodRestrictChatAccessResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_UNKNOWN":
        case 0:
            message.result = 0;
            break;
        case "R_OK":
        case 1:
            message.result = 1;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_CHAT_NOT_FOUND":
        case 3:
            message.result = 3;
            break;
        case "R_NO_RIGHTS":
        case 4:
            message.result = 4;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodRestrictChatAccessResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodRestrictChatAccessResponse
     * @static
     * @param {MCMethodRestrictChatAccessResponse} message MCMethodRestrictChatAccessResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodRestrictChatAccessResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.result = options.enums === String ? "R_UNKNOWN" : 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodRestrictChatAccessResponse.Result[message.result] === undefined ? message.result : $root.MCMethodRestrictChatAccessResponse.Result[message.result] : message.result;
        return object;
    };

    /**
     * Converts this MCMethodRestrictChatAccessResponse to JSON.
     * @function toJSON
     * @memberof MCMethodRestrictChatAccessResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodRestrictChatAccessResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodRestrictChatAccessResponse
     * @function getTypeUrl
     * @memberof MCMethodRestrictChatAccessResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodRestrictChatAccessResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodRestrictChatAccessResponse";
    };

    /**
     * Result enum.
     * @name MCMethodRestrictChatAccessResponse.Result
     * @enum {number}
     * @property {number} R_UNKNOWN=0 R_UNKNOWN value
     * @property {number} R_OK=1 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=2 R_CHANNEL_NOT_FOUND value
     * @property {number} R_CHAT_NOT_FOUND=3 R_CHAT_NOT_FOUND value
     * @property {number} R_NO_RIGHTS=4 R_NO_RIGHTS value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodRestrictChatAccessResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_UNKNOWN"] = 0;
        values[valuesById[1] = "R_OK"] = 1;
        values[valuesById[2] = "R_CHANNEL_NOT_FOUND"] = 2;
        values[valuesById[3] = "R_CHAT_NOT_FOUND"] = 3;
        values[valuesById[4] = "R_NO_RIGHTS"] = 4;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodRestrictChatAccessResponse;
})();

export const MCMethodJoinWidgetChat = $root.MCMethodJoinWidgetChat = (() => {

    /**
     * Properties of a MCMethodJoinWidgetChat.
     * @exports IMCMethodJoinWidgetChat
     * @interface IMCMethodJoinWidgetChat
     * @property {Uint8Array|null} [channelID] MCMethodJoinWidgetChat channelID
     * @property {Long|null} [chatID] MCMethodJoinWidgetChat chatID
     */

    /**
     * Constructs a new MCMethodJoinWidgetChat.
     * @exports MCMethodJoinWidgetChat
     * @classdesc Represents a MCMethodJoinWidgetChat.
     * @implements IMCMethodJoinWidgetChat
     * @constructor
     * @param {IMCMethodJoinWidgetChat=} [properties] Properties to set
     */
    function MCMethodJoinWidgetChat(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodJoinWidgetChat channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodJoinWidgetChat
     * @instance
     */
    MCMethodJoinWidgetChat.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodJoinWidgetChat chatID.
     * @member {Long} chatID
     * @memberof MCMethodJoinWidgetChat
     * @instance
     */
    MCMethodJoinWidgetChat.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCMethodJoinWidgetChat instance using the specified properties.
     * @function create
     * @memberof MCMethodJoinWidgetChat
     * @static
     * @param {IMCMethodJoinWidgetChat=} [properties] Properties to set
     * @returns {MCMethodJoinWidgetChat} MCMethodJoinWidgetChat instance
     */
    MCMethodJoinWidgetChat.create = function create(properties) {
        return new MCMethodJoinWidgetChat(properties);
    };

    /**
     * Encodes the specified MCMethodJoinWidgetChat message. Does not implicitly {@link MCMethodJoinWidgetChat.verify|verify} messages.
     * @function encode
     * @memberof MCMethodJoinWidgetChat
     * @static
     * @param {IMCMethodJoinWidgetChat} message MCMethodJoinWidgetChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodJoinWidgetChat.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chatID);
        return writer;
    };

    /**
     * Encodes the specified MCMethodJoinWidgetChat message, length delimited. Does not implicitly {@link MCMethodJoinWidgetChat.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodJoinWidgetChat
     * @static
     * @param {IMCMethodJoinWidgetChat} message MCMethodJoinWidgetChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodJoinWidgetChat.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodJoinWidgetChat message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodJoinWidgetChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodJoinWidgetChat} MCMethodJoinWidgetChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodJoinWidgetChat.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodJoinWidgetChat();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.chatID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodJoinWidgetChat message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodJoinWidgetChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodJoinWidgetChat} MCMethodJoinWidgetChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodJoinWidgetChat.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodJoinWidgetChat message.
     * @function verify
     * @memberof MCMethodJoinWidgetChat
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodJoinWidgetChat.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCMethodJoinWidgetChat message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodJoinWidgetChat
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodJoinWidgetChat} MCMethodJoinWidgetChat
     */
    MCMethodJoinWidgetChat.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodJoinWidgetChat)
            return object;
        let message = new $root.MCMethodJoinWidgetChat();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodJoinWidgetChat message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodJoinWidgetChat
     * @static
     * @param {MCMethodJoinWidgetChat} message MCMethodJoinWidgetChat
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodJoinWidgetChat.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        return object;
    };

    /**
     * Converts this MCMethodJoinWidgetChat to JSON.
     * @function toJSON
     * @memberof MCMethodJoinWidgetChat
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodJoinWidgetChat.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodJoinWidgetChat
     * @function getTypeUrl
     * @memberof MCMethodJoinWidgetChat
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodJoinWidgetChat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodJoinWidgetChat";
    };

    return MCMethodJoinWidgetChat;
})();

export const MCMethodJoinWidgetChatResponse = $root.MCMethodJoinWidgetChatResponse = (() => {

    /**
     * Properties of a MCMethodJoinWidgetChatResponse.
     * @exports IMCMethodJoinWidgetChatResponse
     * @interface IMCMethodJoinWidgetChatResponse
     * @property {MCMethodJoinWidgetChatResponse.Result|null} [result] MCMethodJoinWidgetChatResponse result
     */

    /**
     * Constructs a new MCMethodJoinWidgetChatResponse.
     * @exports MCMethodJoinWidgetChatResponse
     * @classdesc Represents a MCMethodJoinWidgetChatResponse.
     * @implements IMCMethodJoinWidgetChatResponse
     * @constructor
     * @param {IMCMethodJoinWidgetChatResponse=} [properties] Properties to set
     */
    function MCMethodJoinWidgetChatResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodJoinWidgetChatResponse result.
     * @member {MCMethodJoinWidgetChatResponse.Result} result
     * @memberof MCMethodJoinWidgetChatResponse
     * @instance
     */
    MCMethodJoinWidgetChatResponse.prototype.result = 0;

    /**
     * Creates a new MCMethodJoinWidgetChatResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodJoinWidgetChatResponse
     * @static
     * @param {IMCMethodJoinWidgetChatResponse=} [properties] Properties to set
     * @returns {MCMethodJoinWidgetChatResponse} MCMethodJoinWidgetChatResponse instance
     */
    MCMethodJoinWidgetChatResponse.create = function create(properties) {
        return new MCMethodJoinWidgetChatResponse(properties);
    };

    /**
     * Encodes the specified MCMethodJoinWidgetChatResponse message. Does not implicitly {@link MCMethodJoinWidgetChatResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodJoinWidgetChatResponse
     * @static
     * @param {IMCMethodJoinWidgetChatResponse} message MCMethodJoinWidgetChatResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodJoinWidgetChatResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        return writer;
    };

    /**
     * Encodes the specified MCMethodJoinWidgetChatResponse message, length delimited. Does not implicitly {@link MCMethodJoinWidgetChatResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodJoinWidgetChatResponse
     * @static
     * @param {IMCMethodJoinWidgetChatResponse} message MCMethodJoinWidgetChatResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodJoinWidgetChatResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodJoinWidgetChatResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodJoinWidgetChatResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodJoinWidgetChatResponse} MCMethodJoinWidgetChatResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodJoinWidgetChatResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodJoinWidgetChatResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodJoinWidgetChatResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodJoinWidgetChatResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodJoinWidgetChatResponse} MCMethodJoinWidgetChatResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodJoinWidgetChatResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodJoinWidgetChatResponse message.
     * @function verify
     * @memberof MCMethodJoinWidgetChatResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodJoinWidgetChatResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 10:
                break;
            }
        return null;
    };

    /**
     * Creates a MCMethodJoinWidgetChatResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodJoinWidgetChatResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodJoinWidgetChatResponse} MCMethodJoinWidgetChatResponse
     */
    MCMethodJoinWidgetChatResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodJoinWidgetChatResponse)
            return object;
        let message = new $root.MCMethodJoinWidgetChatResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_UNKNOWN":
        case 0:
            message.result = 0;
            break;
        case "R_OK":
        case 1:
            message.result = 1;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_CHAT_NOT_FOUND":
        case 3:
            message.result = 3;
            break;
        case "R_NO_RIGHTS":
        case 4:
            message.result = 4;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodJoinWidgetChatResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodJoinWidgetChatResponse
     * @static
     * @param {MCMethodJoinWidgetChatResponse} message MCMethodJoinWidgetChatResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodJoinWidgetChatResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.result = options.enums === String ? "R_UNKNOWN" : 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodJoinWidgetChatResponse.Result[message.result] === undefined ? message.result : $root.MCMethodJoinWidgetChatResponse.Result[message.result] : message.result;
        return object;
    };

    /**
     * Converts this MCMethodJoinWidgetChatResponse to JSON.
     * @function toJSON
     * @memberof MCMethodJoinWidgetChatResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodJoinWidgetChatResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodJoinWidgetChatResponse
     * @function getTypeUrl
     * @memberof MCMethodJoinWidgetChatResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodJoinWidgetChatResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodJoinWidgetChatResponse";
    };

    /**
     * Result enum.
     * @name MCMethodJoinWidgetChatResponse.Result
     * @enum {number}
     * @property {number} R_UNKNOWN=0 R_UNKNOWN value
     * @property {number} R_OK=1 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=2 R_CHANNEL_NOT_FOUND value
     * @property {number} R_CHAT_NOT_FOUND=3 R_CHAT_NOT_FOUND value
     * @property {number} R_NO_RIGHTS=4 R_NO_RIGHTS value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodJoinWidgetChatResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_UNKNOWN"] = 0;
        values[valuesById[1] = "R_OK"] = 1;
        values[valuesById[2] = "R_CHANNEL_NOT_FOUND"] = 2;
        values[valuesById[3] = "R_CHAT_NOT_FOUND"] = 3;
        values[valuesById[4] = "R_NO_RIGHTS"] = 4;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodJoinWidgetChatResponse;
})();

export const MCMethodLeaveWidgetChat = $root.MCMethodLeaveWidgetChat = (() => {

    /**
     * Properties of a MCMethodLeaveWidgetChat.
     * @exports IMCMethodLeaveWidgetChat
     * @interface IMCMethodLeaveWidgetChat
     * @property {Uint8Array|null} [channelID] MCMethodLeaveWidgetChat channelID
     * @property {Long|null} [chatID] MCMethodLeaveWidgetChat chatID
     * @property {boolean|null} [resolve] MCMethodLeaveWidgetChat resolve
     */

    /**
     * Constructs a new MCMethodLeaveWidgetChat.
     * @exports MCMethodLeaveWidgetChat
     * @classdesc Represents a MCMethodLeaveWidgetChat.
     * @implements IMCMethodLeaveWidgetChat
     * @constructor
     * @param {IMCMethodLeaveWidgetChat=} [properties] Properties to set
     */
    function MCMethodLeaveWidgetChat(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodLeaveWidgetChat channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodLeaveWidgetChat
     * @instance
     */
    MCMethodLeaveWidgetChat.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodLeaveWidgetChat chatID.
     * @member {Long} chatID
     * @memberof MCMethodLeaveWidgetChat
     * @instance
     */
    MCMethodLeaveWidgetChat.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodLeaveWidgetChat resolve.
     * @member {boolean} resolve
     * @memberof MCMethodLeaveWidgetChat
     * @instance
     */
    MCMethodLeaveWidgetChat.prototype.resolve = false;

    /**
     * Creates a new MCMethodLeaveWidgetChat instance using the specified properties.
     * @function create
     * @memberof MCMethodLeaveWidgetChat
     * @static
     * @param {IMCMethodLeaveWidgetChat=} [properties] Properties to set
     * @returns {MCMethodLeaveWidgetChat} MCMethodLeaveWidgetChat instance
     */
    MCMethodLeaveWidgetChat.create = function create(properties) {
        return new MCMethodLeaveWidgetChat(properties);
    };

    /**
     * Encodes the specified MCMethodLeaveWidgetChat message. Does not implicitly {@link MCMethodLeaveWidgetChat.verify|verify} messages.
     * @function encode
     * @memberof MCMethodLeaveWidgetChat
     * @static
     * @param {IMCMethodLeaveWidgetChat} message MCMethodLeaveWidgetChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodLeaveWidgetChat.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chatID);
        if (message.resolve != null && Object.hasOwnProperty.call(message, "resolve"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.resolve);
        return writer;
    };

    /**
     * Encodes the specified MCMethodLeaveWidgetChat message, length delimited. Does not implicitly {@link MCMethodLeaveWidgetChat.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodLeaveWidgetChat
     * @static
     * @param {IMCMethodLeaveWidgetChat} message MCMethodLeaveWidgetChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodLeaveWidgetChat.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodLeaveWidgetChat message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodLeaveWidgetChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodLeaveWidgetChat} MCMethodLeaveWidgetChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodLeaveWidgetChat.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodLeaveWidgetChat();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 3: {
                    message.resolve = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodLeaveWidgetChat message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodLeaveWidgetChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodLeaveWidgetChat} MCMethodLeaveWidgetChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodLeaveWidgetChat.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodLeaveWidgetChat message.
     * @function verify
     * @memberof MCMethodLeaveWidgetChat
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodLeaveWidgetChat.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.resolve != null && message.hasOwnProperty("resolve"))
            if (typeof message.resolve !== "boolean")
                return "resolve: boolean expected";
        return null;
    };

    /**
     * Creates a MCMethodLeaveWidgetChat message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodLeaveWidgetChat
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodLeaveWidgetChat} MCMethodLeaveWidgetChat
     */
    MCMethodLeaveWidgetChat.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodLeaveWidgetChat)
            return object;
        let message = new $root.MCMethodLeaveWidgetChat();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.resolve != null)
            message.resolve = Boolean(object.resolve);
        return message;
    };

    /**
     * Creates a plain object from a MCMethodLeaveWidgetChat message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodLeaveWidgetChat
     * @static
     * @param {MCMethodLeaveWidgetChat} message MCMethodLeaveWidgetChat
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodLeaveWidgetChat.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
            object.resolve = false;
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.resolve != null && message.hasOwnProperty("resolve"))
            object.resolve = message.resolve;
        return object;
    };

    /**
     * Converts this MCMethodLeaveWidgetChat to JSON.
     * @function toJSON
     * @memberof MCMethodLeaveWidgetChat
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodLeaveWidgetChat.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodLeaveWidgetChat
     * @function getTypeUrl
     * @memberof MCMethodLeaveWidgetChat
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodLeaveWidgetChat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodLeaveWidgetChat";
    };

    return MCMethodLeaveWidgetChat;
})();

export const MCMethodLeaveWidgetChatResponse = $root.MCMethodLeaveWidgetChatResponse = (() => {

    /**
     * Properties of a MCMethodLeaveWidgetChatResponse.
     * @exports IMCMethodLeaveWidgetChatResponse
     * @interface IMCMethodLeaveWidgetChatResponse
     * @property {MCMethodLeaveWidgetChatResponse.Result|null} [result] MCMethodLeaveWidgetChatResponse result
     */

    /**
     * Constructs a new MCMethodLeaveWidgetChatResponse.
     * @exports MCMethodLeaveWidgetChatResponse
     * @classdesc Represents a MCMethodLeaveWidgetChatResponse.
     * @implements IMCMethodLeaveWidgetChatResponse
     * @constructor
     * @param {IMCMethodLeaveWidgetChatResponse=} [properties] Properties to set
     */
    function MCMethodLeaveWidgetChatResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodLeaveWidgetChatResponse result.
     * @member {MCMethodLeaveWidgetChatResponse.Result} result
     * @memberof MCMethodLeaveWidgetChatResponse
     * @instance
     */
    MCMethodLeaveWidgetChatResponse.prototype.result = 0;

    /**
     * Creates a new MCMethodLeaveWidgetChatResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodLeaveWidgetChatResponse
     * @static
     * @param {IMCMethodLeaveWidgetChatResponse=} [properties] Properties to set
     * @returns {MCMethodLeaveWidgetChatResponse} MCMethodLeaveWidgetChatResponse instance
     */
    MCMethodLeaveWidgetChatResponse.create = function create(properties) {
        return new MCMethodLeaveWidgetChatResponse(properties);
    };

    /**
     * Encodes the specified MCMethodLeaveWidgetChatResponse message. Does not implicitly {@link MCMethodLeaveWidgetChatResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodLeaveWidgetChatResponse
     * @static
     * @param {IMCMethodLeaveWidgetChatResponse} message MCMethodLeaveWidgetChatResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodLeaveWidgetChatResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        return writer;
    };

    /**
     * Encodes the specified MCMethodLeaveWidgetChatResponse message, length delimited. Does not implicitly {@link MCMethodLeaveWidgetChatResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodLeaveWidgetChatResponse
     * @static
     * @param {IMCMethodLeaveWidgetChatResponse} message MCMethodLeaveWidgetChatResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodLeaveWidgetChatResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodLeaveWidgetChatResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodLeaveWidgetChatResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodLeaveWidgetChatResponse} MCMethodLeaveWidgetChatResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodLeaveWidgetChatResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodLeaveWidgetChatResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodLeaveWidgetChatResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodLeaveWidgetChatResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodLeaveWidgetChatResponse} MCMethodLeaveWidgetChatResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodLeaveWidgetChatResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodLeaveWidgetChatResponse message.
     * @function verify
     * @memberof MCMethodLeaveWidgetChatResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodLeaveWidgetChatResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 10:
                break;
            }
        return null;
    };

    /**
     * Creates a MCMethodLeaveWidgetChatResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodLeaveWidgetChatResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodLeaveWidgetChatResponse} MCMethodLeaveWidgetChatResponse
     */
    MCMethodLeaveWidgetChatResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodLeaveWidgetChatResponse)
            return object;
        let message = new $root.MCMethodLeaveWidgetChatResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_UNKNOWN":
        case 0:
            message.result = 0;
            break;
        case "R_OK":
        case 1:
            message.result = 1;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_CHAT_NOT_FOUND":
        case 3:
            message.result = 3;
            break;
        case "R_NO_RIGHTS":
        case 4:
            message.result = 4;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodLeaveWidgetChatResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodLeaveWidgetChatResponse
     * @static
     * @param {MCMethodLeaveWidgetChatResponse} message MCMethodLeaveWidgetChatResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodLeaveWidgetChatResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.result = options.enums === String ? "R_UNKNOWN" : 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodLeaveWidgetChatResponse.Result[message.result] === undefined ? message.result : $root.MCMethodLeaveWidgetChatResponse.Result[message.result] : message.result;
        return object;
    };

    /**
     * Converts this MCMethodLeaveWidgetChatResponse to JSON.
     * @function toJSON
     * @memberof MCMethodLeaveWidgetChatResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodLeaveWidgetChatResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodLeaveWidgetChatResponse
     * @function getTypeUrl
     * @memberof MCMethodLeaveWidgetChatResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodLeaveWidgetChatResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodLeaveWidgetChatResponse";
    };

    /**
     * Result enum.
     * @name MCMethodLeaveWidgetChatResponse.Result
     * @enum {number}
     * @property {number} R_UNKNOWN=0 R_UNKNOWN value
     * @property {number} R_OK=1 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=2 R_CHANNEL_NOT_FOUND value
     * @property {number} R_CHAT_NOT_FOUND=3 R_CHAT_NOT_FOUND value
     * @property {number} R_NO_RIGHTS=4 R_NO_RIGHTS value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodLeaveWidgetChatResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_UNKNOWN"] = 0;
        values[valuesById[1] = "R_OK"] = 1;
        values[valuesById[2] = "R_CHANNEL_NOT_FOUND"] = 2;
        values[valuesById[3] = "R_CHAT_NOT_FOUND"] = 3;
        values[valuesById[4] = "R_NO_RIGHTS"] = 4;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodLeaveWidgetChatResponse;
})();

export const MCMethodSuggestNextOperatorMessages = $root.MCMethodSuggestNextOperatorMessages = (() => {

    /**
     * Properties of a MCMethodSuggestNextOperatorMessages.
     * @exports IMCMethodSuggestNextOperatorMessages
     * @interface IMCMethodSuggestNextOperatorMessages
     * @property {Uint8Array|null} [channelID] MCMethodSuggestNextOperatorMessages channelID
     * @property {Long|null} [chatID] MCMethodSuggestNextOperatorMessages chatID
     * @property {IMCMessageSuggestion|null} [suggestion] MCMethodSuggestNextOperatorMessages suggestion
     */

    /**
     * Constructs a new MCMethodSuggestNextOperatorMessages.
     * @exports MCMethodSuggestNextOperatorMessages
     * @classdesc Represents a MCMethodSuggestNextOperatorMessages.
     * @implements IMCMethodSuggestNextOperatorMessages
     * @constructor
     * @param {IMCMethodSuggestNextOperatorMessages=} [properties] Properties to set
     */
    function MCMethodSuggestNextOperatorMessages(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodSuggestNextOperatorMessages channelID.
     * @member {Uint8Array} channelID
     * @memberof MCMethodSuggestNextOperatorMessages
     * @instance
     */
    MCMethodSuggestNextOperatorMessages.prototype.channelID = $util.newBuffer([]);

    /**
     * MCMethodSuggestNextOperatorMessages chatID.
     * @member {Long} chatID
     * @memberof MCMethodSuggestNextOperatorMessages
     * @instance
     */
    MCMethodSuggestNextOperatorMessages.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMethodSuggestNextOperatorMessages suggestion.
     * @member {IMCMessageSuggestion|null|undefined} suggestion
     * @memberof MCMethodSuggestNextOperatorMessages
     * @instance
     */
    MCMethodSuggestNextOperatorMessages.prototype.suggestion = null;

    /**
     * Creates a new MCMethodSuggestNextOperatorMessages instance using the specified properties.
     * @function create
     * @memberof MCMethodSuggestNextOperatorMessages
     * @static
     * @param {IMCMethodSuggestNextOperatorMessages=} [properties] Properties to set
     * @returns {MCMethodSuggestNextOperatorMessages} MCMethodSuggestNextOperatorMessages instance
     */
    MCMethodSuggestNextOperatorMessages.create = function create(properties) {
        return new MCMethodSuggestNextOperatorMessages(properties);
    };

    /**
     * Encodes the specified MCMethodSuggestNextOperatorMessages message. Does not implicitly {@link MCMethodSuggestNextOperatorMessages.verify|verify} messages.
     * @function encode
     * @memberof MCMethodSuggestNextOperatorMessages
     * @static
     * @param {IMCMethodSuggestNextOperatorMessages} message MCMethodSuggestNextOperatorMessages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSuggestNextOperatorMessages.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chatID);
        if (message.suggestion != null && Object.hasOwnProperty.call(message, "suggestion"))
            $root.MCMessageSuggestion.encode(message.suggestion, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodSuggestNextOperatorMessages message, length delimited. Does not implicitly {@link MCMethodSuggestNextOperatorMessages.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodSuggestNextOperatorMessages
     * @static
     * @param {IMCMethodSuggestNextOperatorMessages} message MCMethodSuggestNextOperatorMessages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSuggestNextOperatorMessages.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodSuggestNextOperatorMessages message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodSuggestNextOperatorMessages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodSuggestNextOperatorMessages} MCMethodSuggestNextOperatorMessages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSuggestNextOperatorMessages.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodSuggestNextOperatorMessages();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 4: {
                    message.suggestion = $root.MCMessageSuggestion.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodSuggestNextOperatorMessages message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodSuggestNextOperatorMessages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodSuggestNextOperatorMessages} MCMethodSuggestNextOperatorMessages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSuggestNextOperatorMessages.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodSuggestNextOperatorMessages message.
     * @function verify
     * @memberof MCMethodSuggestNextOperatorMessages
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodSuggestNextOperatorMessages.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.suggestion != null && message.hasOwnProperty("suggestion")) {
            let error = $root.MCMessageSuggestion.verify(message.suggestion);
            if (error)
                return "suggestion." + error;
        }
        return null;
    };

    /**
     * Creates a MCMethodSuggestNextOperatorMessages message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodSuggestNextOperatorMessages
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodSuggestNextOperatorMessages} MCMethodSuggestNextOperatorMessages
     */
    MCMethodSuggestNextOperatorMessages.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodSuggestNextOperatorMessages)
            return object;
        let message = new $root.MCMethodSuggestNextOperatorMessages();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.suggestion != null) {
            if (typeof object.suggestion !== "object")
                throw TypeError(".MCMethodSuggestNextOperatorMessages.suggestion: object expected");
            message.suggestion = $root.MCMessageSuggestion.fromObject(object.suggestion);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodSuggestNextOperatorMessages message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodSuggestNextOperatorMessages
     * @static
     * @param {MCMethodSuggestNextOperatorMessages} message MCMethodSuggestNextOperatorMessages
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodSuggestNextOperatorMessages.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
            object.suggestion = null;
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.suggestion != null && message.hasOwnProperty("suggestion"))
            object.suggestion = $root.MCMessageSuggestion.toObject(message.suggestion, options);
        return object;
    };

    /**
     * Converts this MCMethodSuggestNextOperatorMessages to JSON.
     * @function toJSON
     * @memberof MCMethodSuggestNextOperatorMessages
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodSuggestNextOperatorMessages.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodSuggestNextOperatorMessages
     * @function getTypeUrl
     * @memberof MCMethodSuggestNextOperatorMessages
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodSuggestNextOperatorMessages.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodSuggestNextOperatorMessages";
    };

    return MCMethodSuggestNextOperatorMessages;
})();

export const MCMethodSuggestNextOperatorMessagesResponse = $root.MCMethodSuggestNextOperatorMessagesResponse = (() => {

    /**
     * Properties of a MCMethodSuggestNextOperatorMessagesResponse.
     * @exports IMCMethodSuggestNextOperatorMessagesResponse
     * @interface IMCMethodSuggestNextOperatorMessagesResponse
     * @property {MCMethodSuggestNextOperatorMessagesResponse.Result|null} [result] MCMethodSuggestNextOperatorMessagesResponse result
     * @property {IMCMessageSuggestion|null} [suggestion] MCMethodSuggestNextOperatorMessagesResponse suggestion
     */

    /**
     * Constructs a new MCMethodSuggestNextOperatorMessagesResponse.
     * @exports MCMethodSuggestNextOperatorMessagesResponse
     * @classdesc Represents a MCMethodSuggestNextOperatorMessagesResponse.
     * @implements IMCMethodSuggestNextOperatorMessagesResponse
     * @constructor
     * @param {IMCMethodSuggestNextOperatorMessagesResponse=} [properties] Properties to set
     */
    function MCMethodSuggestNextOperatorMessagesResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMethodSuggestNextOperatorMessagesResponse result.
     * @member {MCMethodSuggestNextOperatorMessagesResponse.Result} result
     * @memberof MCMethodSuggestNextOperatorMessagesResponse
     * @instance
     */
    MCMethodSuggestNextOperatorMessagesResponse.prototype.result = 0;

    /**
     * MCMethodSuggestNextOperatorMessagesResponse suggestion.
     * @member {IMCMessageSuggestion|null|undefined} suggestion
     * @memberof MCMethodSuggestNextOperatorMessagesResponse
     * @instance
     */
    MCMethodSuggestNextOperatorMessagesResponse.prototype.suggestion = null;

    /**
     * Creates a new MCMethodSuggestNextOperatorMessagesResponse instance using the specified properties.
     * @function create
     * @memberof MCMethodSuggestNextOperatorMessagesResponse
     * @static
     * @param {IMCMethodSuggestNextOperatorMessagesResponse=} [properties] Properties to set
     * @returns {MCMethodSuggestNextOperatorMessagesResponse} MCMethodSuggestNextOperatorMessagesResponse instance
     */
    MCMethodSuggestNextOperatorMessagesResponse.create = function create(properties) {
        return new MCMethodSuggestNextOperatorMessagesResponse(properties);
    };

    /**
     * Encodes the specified MCMethodSuggestNextOperatorMessagesResponse message. Does not implicitly {@link MCMethodSuggestNextOperatorMessagesResponse.verify|verify} messages.
     * @function encode
     * @memberof MCMethodSuggestNextOperatorMessagesResponse
     * @static
     * @param {IMCMethodSuggestNextOperatorMessagesResponse} message MCMethodSuggestNextOperatorMessagesResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSuggestNextOperatorMessagesResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.suggestion != null && Object.hasOwnProperty.call(message, "suggestion"))
            $root.MCMessageSuggestion.encode(message.suggestion, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMethodSuggestNextOperatorMessagesResponse message, length delimited. Does not implicitly {@link MCMethodSuggestNextOperatorMessagesResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMethodSuggestNextOperatorMessagesResponse
     * @static
     * @param {IMCMethodSuggestNextOperatorMessagesResponse} message MCMethodSuggestNextOperatorMessagesResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMethodSuggestNextOperatorMessagesResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMethodSuggestNextOperatorMessagesResponse message from the specified reader or buffer.
     * @function decode
     * @memberof MCMethodSuggestNextOperatorMessagesResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMethodSuggestNextOperatorMessagesResponse} MCMethodSuggestNextOperatorMessagesResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSuggestNextOperatorMessagesResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMethodSuggestNextOperatorMessagesResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 3: {
                    message.suggestion = $root.MCMessageSuggestion.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMethodSuggestNextOperatorMessagesResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMethodSuggestNextOperatorMessagesResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMethodSuggestNextOperatorMessagesResponse} MCMethodSuggestNextOperatorMessagesResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMethodSuggestNextOperatorMessagesResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMethodSuggestNextOperatorMessagesResponse message.
     * @function verify
     * @memberof MCMethodSuggestNextOperatorMessagesResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMethodSuggestNextOperatorMessagesResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 10:
                break;
            }
        if (message.suggestion != null && message.hasOwnProperty("suggestion")) {
            let error = $root.MCMessageSuggestion.verify(message.suggestion);
            if (error)
                return "suggestion." + error;
        }
        return null;
    };

    /**
     * Creates a MCMethodSuggestNextOperatorMessagesResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMethodSuggestNextOperatorMessagesResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMethodSuggestNextOperatorMessagesResponse} MCMethodSuggestNextOperatorMessagesResponse
     */
    MCMethodSuggestNextOperatorMessagesResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMethodSuggestNextOperatorMessagesResponse)
            return object;
        let message = new $root.MCMethodSuggestNextOperatorMessagesResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "R_UNKNOWN":
        case 0:
            message.result = 0;
            break;
        case "R_OK":
        case 1:
            message.result = 1;
            break;
        case "R_CHANNEL_NOT_FOUND":
        case 2:
            message.result = 2;
            break;
        case "R_CHAT_NOT_FOUND":
        case 3:
            message.result = 3;
            break;
        case "R_NO_RIGHTS":
        case 4:
            message.result = 4;
            break;
        case "R_INTERNAL_SERVER_ERROR":
        case 10:
            message.result = 10;
            break;
        }
        if (object.suggestion != null) {
            if (typeof object.suggestion !== "object")
                throw TypeError(".MCMethodSuggestNextOperatorMessagesResponse.suggestion: object expected");
            message.suggestion = $root.MCMessageSuggestion.fromObject(object.suggestion);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMethodSuggestNextOperatorMessagesResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMethodSuggestNextOperatorMessagesResponse
     * @static
     * @param {MCMethodSuggestNextOperatorMessagesResponse} message MCMethodSuggestNextOperatorMessagesResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMethodSuggestNextOperatorMessagesResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = options.enums === String ? "R_UNKNOWN" : 0;
            object.suggestion = null;
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.MCMethodSuggestNextOperatorMessagesResponse.Result[message.result] === undefined ? message.result : $root.MCMethodSuggestNextOperatorMessagesResponse.Result[message.result] : message.result;
        if (message.suggestion != null && message.hasOwnProperty("suggestion"))
            object.suggestion = $root.MCMessageSuggestion.toObject(message.suggestion, options);
        return object;
    };

    /**
     * Converts this MCMethodSuggestNextOperatorMessagesResponse to JSON.
     * @function toJSON
     * @memberof MCMethodSuggestNextOperatorMessagesResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMethodSuggestNextOperatorMessagesResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMethodSuggestNextOperatorMessagesResponse
     * @function getTypeUrl
     * @memberof MCMethodSuggestNextOperatorMessagesResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMethodSuggestNextOperatorMessagesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMethodSuggestNextOperatorMessagesResponse";
    };

    /**
     * Result enum.
     * @name MCMethodSuggestNextOperatorMessagesResponse.Result
     * @enum {number}
     * @property {number} R_UNKNOWN=0 R_UNKNOWN value
     * @property {number} R_OK=1 R_OK value
     * @property {number} R_CHANNEL_NOT_FOUND=2 R_CHANNEL_NOT_FOUND value
     * @property {number} R_CHAT_NOT_FOUND=3 R_CHAT_NOT_FOUND value
     * @property {number} R_NO_RIGHTS=4 R_NO_RIGHTS value
     * @property {number} R_INTERNAL_SERVER_ERROR=10 R_INTERNAL_SERVER_ERROR value
     */
    MCMethodSuggestNextOperatorMessagesResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "R_UNKNOWN"] = 0;
        values[valuesById[1] = "R_OK"] = 1;
        values[valuesById[2] = "R_CHANNEL_NOT_FOUND"] = 2;
        values[valuesById[3] = "R_CHAT_NOT_FOUND"] = 3;
        values[valuesById[4] = "R_NO_RIGHTS"] = 4;
        values[valuesById[10] = "R_INTERNAL_SERVER_ERROR"] = 10;
        return values;
    })();

    return MCMethodSuggestNextOperatorMessagesResponse;
})();

export const MCChannelWithAccess = $root.MCChannelWithAccess = (() => {

    /**
     * Properties of a MCChannelWithAccess.
     * @exports IMCChannelWithAccess
     * @interface IMCChannelWithAccess
     * @property {Uint8Array|null} [channelID] MCChannelWithAccess channelID
     * @property {Array.<Long>|null} [excludeChatIDs] MCChannelWithAccess excludeChatIDs
     * @property {Array.<Long>|null} [includeChatIDs] MCChannelWithAccess includeChatIDs
     */

    /**
     * Constructs a new MCChannelWithAccess.
     * @exports MCChannelWithAccess
     * @classdesc Represents a MCChannelWithAccess.
     * @implements IMCChannelWithAccess
     * @constructor
     * @param {IMCChannelWithAccess=} [properties] Properties to set
     */
    function MCChannelWithAccess(properties) {
        this.excludeChatIDs = [];
        this.includeChatIDs = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCChannelWithAccess channelID.
     * @member {Uint8Array} channelID
     * @memberof MCChannelWithAccess
     * @instance
     */
    MCChannelWithAccess.prototype.channelID = $util.newBuffer([]);

    /**
     * MCChannelWithAccess excludeChatIDs.
     * @member {Array.<Long>} excludeChatIDs
     * @memberof MCChannelWithAccess
     * @instance
     */
    MCChannelWithAccess.prototype.excludeChatIDs = $util.emptyArray;

    /**
     * MCChannelWithAccess includeChatIDs.
     * @member {Array.<Long>} includeChatIDs
     * @memberof MCChannelWithAccess
     * @instance
     */
    MCChannelWithAccess.prototype.includeChatIDs = $util.emptyArray;

    /**
     * Creates a new MCChannelWithAccess instance using the specified properties.
     * @function create
     * @memberof MCChannelWithAccess
     * @static
     * @param {IMCChannelWithAccess=} [properties] Properties to set
     * @returns {MCChannelWithAccess} MCChannelWithAccess instance
     */
    MCChannelWithAccess.create = function create(properties) {
        return new MCChannelWithAccess(properties);
    };

    /**
     * Encodes the specified MCChannelWithAccess message. Does not implicitly {@link MCChannelWithAccess.verify|verify} messages.
     * @function encode
     * @memberof MCChannelWithAccess
     * @static
     * @param {IMCChannelWithAccess} message MCChannelWithAccess message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChannelWithAccess.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.excludeChatIDs != null && message.excludeChatIDs.length) {
            writer.uint32(/* id 9, wireType 2 =*/74).fork();
            for (let i = 0; i < message.excludeChatIDs.length; ++i)
                writer.uint64(message.excludeChatIDs[i]);
            writer.ldelim();
        }
        if (message.includeChatIDs != null && message.includeChatIDs.length) {
            writer.uint32(/* id 10, wireType 2 =*/82).fork();
            for (let i = 0; i < message.includeChatIDs.length; ++i)
                writer.uint64(message.includeChatIDs[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified MCChannelWithAccess message, length delimited. Does not implicitly {@link MCChannelWithAccess.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCChannelWithAccess
     * @static
     * @param {IMCChannelWithAccess} message MCChannelWithAccess message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChannelWithAccess.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCChannelWithAccess message from the specified reader or buffer.
     * @function decode
     * @memberof MCChannelWithAccess
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCChannelWithAccess} MCChannelWithAccess
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChannelWithAccess.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCChannelWithAccess();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 9: {
                    if (!(message.excludeChatIDs && message.excludeChatIDs.length))
                        message.excludeChatIDs = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.excludeChatIDs.push(reader.uint64());
                    } else
                        message.excludeChatIDs.push(reader.uint64());
                    break;
                }
            case 10: {
                    if (!(message.includeChatIDs && message.includeChatIDs.length))
                        message.includeChatIDs = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.includeChatIDs.push(reader.uint64());
                    } else
                        message.includeChatIDs.push(reader.uint64());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCChannelWithAccess message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCChannelWithAccess
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCChannelWithAccess} MCChannelWithAccess
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChannelWithAccess.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCChannelWithAccess message.
     * @function verify
     * @memberof MCChannelWithAccess
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCChannelWithAccess.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.excludeChatIDs != null && message.hasOwnProperty("excludeChatIDs")) {
            if (!Array.isArray(message.excludeChatIDs))
                return "excludeChatIDs: array expected";
            for (let i = 0; i < message.excludeChatIDs.length; ++i)
                if (!$util.isInteger(message.excludeChatIDs[i]) && !(message.excludeChatIDs[i] && $util.isInteger(message.excludeChatIDs[i].low) && $util.isInteger(message.excludeChatIDs[i].high)))
                    return "excludeChatIDs: integer|Long[] expected";
        }
        if (message.includeChatIDs != null && message.hasOwnProperty("includeChatIDs")) {
            if (!Array.isArray(message.includeChatIDs))
                return "includeChatIDs: array expected";
            for (let i = 0; i < message.includeChatIDs.length; ++i)
                if (!$util.isInteger(message.includeChatIDs[i]) && !(message.includeChatIDs[i] && $util.isInteger(message.includeChatIDs[i].low) && $util.isInteger(message.includeChatIDs[i].high)))
                    return "includeChatIDs: integer|Long[] expected";
        }
        return null;
    };

    /**
     * Creates a MCChannelWithAccess message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCChannelWithAccess
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCChannelWithAccess} MCChannelWithAccess
     */
    MCChannelWithAccess.fromObject = function fromObject(object) {
        if (object instanceof $root.MCChannelWithAccess)
            return object;
        let message = new $root.MCChannelWithAccess();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.excludeChatIDs) {
            if (!Array.isArray(object.excludeChatIDs))
                throw TypeError(".MCChannelWithAccess.excludeChatIDs: array expected");
            message.excludeChatIDs = [];
            for (let i = 0; i < object.excludeChatIDs.length; ++i)
                if ($util.Long)
                    (message.excludeChatIDs[i] = $util.Long.fromValue(object.excludeChatIDs[i])).unsigned = true;
                else if (typeof object.excludeChatIDs[i] === "string")
                    message.excludeChatIDs[i] = parseInt(object.excludeChatIDs[i], 10);
                else if (typeof object.excludeChatIDs[i] === "number")
                    message.excludeChatIDs[i] = object.excludeChatIDs[i];
                else if (typeof object.excludeChatIDs[i] === "object")
                    message.excludeChatIDs[i] = new $util.LongBits(object.excludeChatIDs[i].low >>> 0, object.excludeChatIDs[i].high >>> 0).toNumber(true);
        }
        if (object.includeChatIDs) {
            if (!Array.isArray(object.includeChatIDs))
                throw TypeError(".MCChannelWithAccess.includeChatIDs: array expected");
            message.includeChatIDs = [];
            for (let i = 0; i < object.includeChatIDs.length; ++i)
                if ($util.Long)
                    (message.includeChatIDs[i] = $util.Long.fromValue(object.includeChatIDs[i])).unsigned = true;
                else if (typeof object.includeChatIDs[i] === "string")
                    message.includeChatIDs[i] = parseInt(object.includeChatIDs[i], 10);
                else if (typeof object.includeChatIDs[i] === "number")
                    message.includeChatIDs[i] = object.includeChatIDs[i];
                else if (typeof object.includeChatIDs[i] === "object")
                    message.includeChatIDs[i] = new $util.LongBits(object.includeChatIDs[i].low >>> 0, object.includeChatIDs[i].high >>> 0).toNumber(true);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCChannelWithAccess message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCChannelWithAccess
     * @static
     * @param {MCChannelWithAccess} message MCChannelWithAccess
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCChannelWithAccess.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.excludeChatIDs = [];
            object.includeChatIDs = [];
        }
        if (options.defaults)
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.excludeChatIDs && message.excludeChatIDs.length) {
            object.excludeChatIDs = [];
            for (let j = 0; j < message.excludeChatIDs.length; ++j)
                if (typeof message.excludeChatIDs[j] === "number")
                    object.excludeChatIDs[j] = options.longs === String ? String(message.excludeChatIDs[j]) : message.excludeChatIDs[j];
                else
                    object.excludeChatIDs[j] = options.longs === String ? $util.Long.prototype.toString.call(message.excludeChatIDs[j]) : options.longs === Number ? new $util.LongBits(message.excludeChatIDs[j].low >>> 0, message.excludeChatIDs[j].high >>> 0).toNumber(true) : message.excludeChatIDs[j];
        }
        if (message.includeChatIDs && message.includeChatIDs.length) {
            object.includeChatIDs = [];
            for (let j = 0; j < message.includeChatIDs.length; ++j)
                if (typeof message.includeChatIDs[j] === "number")
                    object.includeChatIDs[j] = options.longs === String ? String(message.includeChatIDs[j]) : message.includeChatIDs[j];
                else
                    object.includeChatIDs[j] = options.longs === String ? $util.Long.prototype.toString.call(message.includeChatIDs[j]) : options.longs === Number ? new $util.LongBits(message.includeChatIDs[j].low >>> 0, message.includeChatIDs[j].high >>> 0).toNumber(true) : message.includeChatIDs[j];
        }
        return object;
    };

    /**
     * Converts this MCChannelWithAccess to JSON.
     * @function toJSON
     * @memberof MCChannelWithAccess
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCChannelWithAccess.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCChannelWithAccess
     * @function getTypeUrl
     * @memberof MCChannelWithAccess
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCChannelWithAccess.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCChannelWithAccess";
    };

    return MCChannelWithAccess;
})();

export const MCAttachmentSource = $root.MCAttachmentSource = (() => {

    /**
     * Properties of a MCAttachmentSource.
     * @exports IMCAttachmentSource
     * @interface IMCAttachmentSource
     * @property {MCAttachmentSource.Source|null} [source] MCAttachmentSource source
     * @property {Uint8Array|null} [reference] MCAttachmentSource reference
     */

    /**
     * Constructs a new MCAttachmentSource.
     * @exports MCAttachmentSource
     * @classdesc Represents a MCAttachmentSource.
     * @implements IMCAttachmentSource
     * @constructor
     * @param {IMCAttachmentSource=} [properties] Properties to set
     */
    function MCAttachmentSource(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCAttachmentSource source.
     * @member {MCAttachmentSource.Source} source
     * @memberof MCAttachmentSource
     * @instance
     */
    MCAttachmentSource.prototype.source = 0;

    /**
     * MCAttachmentSource reference.
     * @member {Uint8Array} reference
     * @memberof MCAttachmentSource
     * @instance
     */
    MCAttachmentSource.prototype.reference = $util.newBuffer([]);

    /**
     * Creates a new MCAttachmentSource instance using the specified properties.
     * @function create
     * @memberof MCAttachmentSource
     * @static
     * @param {IMCAttachmentSource=} [properties] Properties to set
     * @returns {MCAttachmentSource} MCAttachmentSource instance
     */
    MCAttachmentSource.create = function create(properties) {
        return new MCAttachmentSource(properties);
    };

    /**
     * Encodes the specified MCAttachmentSource message. Does not implicitly {@link MCAttachmentSource.verify|verify} messages.
     * @function encode
     * @memberof MCAttachmentSource
     * @static
     * @param {IMCAttachmentSource} message MCAttachmentSource message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCAttachmentSource.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.source != null && Object.hasOwnProperty.call(message, "source"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.source);
        if (message.reference != null && Object.hasOwnProperty.call(message, "reference"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.reference);
        return writer;
    };

    /**
     * Encodes the specified MCAttachmentSource message, length delimited. Does not implicitly {@link MCAttachmentSource.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCAttachmentSource
     * @static
     * @param {IMCAttachmentSource} message MCAttachmentSource message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCAttachmentSource.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCAttachmentSource message from the specified reader or buffer.
     * @function decode
     * @memberof MCAttachmentSource
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCAttachmentSource} MCAttachmentSource
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCAttachmentSource.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCAttachmentSource();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.source = reader.int32();
                    break;
                }
            case 2: {
                    message.reference = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCAttachmentSource message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCAttachmentSource
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCAttachmentSource} MCAttachmentSource
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCAttachmentSource.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCAttachmentSource message.
     * @function verify
     * @memberof MCAttachmentSource
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCAttachmentSource.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.source != null && message.hasOwnProperty("source"))
            switch (message.source) {
            default:
                return "source: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.reference != null && message.hasOwnProperty("reference"))
            if (!(message.reference && typeof message.reference.length === "number" || $util.isString(message.reference)))
                return "reference: buffer expected";
        return null;
    };

    /**
     * Creates a MCAttachmentSource message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCAttachmentSource
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCAttachmentSource} MCAttachmentSource
     */
    MCAttachmentSource.fromObject = function fromObject(object) {
        if (object instanceof $root.MCAttachmentSource)
            return object;
        let message = new $root.MCAttachmentSource();
        switch (object.source) {
        default:
            if (typeof object.source === "number") {
                message.source = object.source;
                break;
            }
            break;
        case "UNKNOWN":
        case 0:
            message.source = 0;
            break;
        case "BYTESERVER":
        case 1:
            message.source = 1;
            break;
        case "INLINE":
        case 2:
            message.source = 2;
            break;
        }
        if (object.reference != null)
            if (typeof object.reference === "string")
                $util.base64.decode(object.reference, message.reference = $util.newBuffer($util.base64.length(object.reference)), 0);
            else if (object.reference.length >= 0)
                message.reference = object.reference;
        return message;
    };

    /**
     * Creates a plain object from a MCAttachmentSource message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCAttachmentSource
     * @static
     * @param {MCAttachmentSource} message MCAttachmentSource
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCAttachmentSource.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.source = options.enums === String ? "UNKNOWN" : 0;
            if (options.bytes === String)
                object.reference = "";
            else {
                object.reference = [];
                if (options.bytes !== Array)
                    object.reference = $util.newBuffer(object.reference);
            }
        }
        if (message.source != null && message.hasOwnProperty("source"))
            object.source = options.enums === String ? $root.MCAttachmentSource.Source[message.source] === undefined ? message.source : $root.MCAttachmentSource.Source[message.source] : message.source;
        if (message.reference != null && message.hasOwnProperty("reference"))
            object.reference = options.bytes === String ? $util.base64.encode(message.reference, 0, message.reference.length) : options.bytes === Array ? Array.prototype.slice.call(message.reference) : message.reference;
        return object;
    };

    /**
     * Converts this MCAttachmentSource to JSON.
     * @function toJSON
     * @memberof MCAttachmentSource
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCAttachmentSource.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCAttachmentSource
     * @function getTypeUrl
     * @memberof MCAttachmentSource
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCAttachmentSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCAttachmentSource";
    };

    /**
     * Source enum.
     * @name MCAttachmentSource.Source
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} BYTESERVER=1 BYTESERVER value
     * @property {number} INLINE=2 INLINE value
     */
    MCAttachmentSource.Source = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "BYTESERVER"] = 1;
        values[valuesById[2] = "INLINE"] = 2;
        return values;
    })();

    return MCAttachmentSource;
})();

export const MCAttachment = $root.MCAttachment = (() => {

    /**
     * Properties of a MCAttachment.
     * @exports IMCAttachment
     * @interface IMCAttachment
     * @property {Long|null} [size] MCAttachment size
     * @property {string|null} [name] MCAttachment name
     * @property {MCAttachment.Type|null} [type] MCAttachment type
     * @property {string|null} [mimeType] MCAttachment mimeType
     * @property {Long|null} [width] MCAttachment width
     * @property {Long|null} [height] MCAttachment height
     * @property {Long|null} [duration] MCAttachment duration
     * @property {IMCAttachmentSource|null} [source] MCAttachment source
     * @property {IMCAttachment|null} [preview] MCAttachment preview
     */

    /**
     * Constructs a new MCAttachment.
     * @exports MCAttachment
     * @classdesc Represents a MCAttachment.
     * @implements IMCAttachment
     * @constructor
     * @param {IMCAttachment=} [properties] Properties to set
     */
    function MCAttachment(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCAttachment size.
     * @member {Long} size
     * @memberof MCAttachment
     * @instance
     */
    MCAttachment.prototype.size = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCAttachment name.
     * @member {string} name
     * @memberof MCAttachment
     * @instance
     */
    MCAttachment.prototype.name = "";

    /**
     * MCAttachment type.
     * @member {MCAttachment.Type} type
     * @memberof MCAttachment
     * @instance
     */
    MCAttachment.prototype.type = 0;

    /**
     * MCAttachment mimeType.
     * @member {string} mimeType
     * @memberof MCAttachment
     * @instance
     */
    MCAttachment.prototype.mimeType = "";

    /**
     * MCAttachment width.
     * @member {Long} width
     * @memberof MCAttachment
     * @instance
     */
    MCAttachment.prototype.width = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCAttachment height.
     * @member {Long} height
     * @memberof MCAttachment
     * @instance
     */
    MCAttachment.prototype.height = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCAttachment duration.
     * @member {Long} duration
     * @memberof MCAttachment
     * @instance
     */
    MCAttachment.prototype.duration = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCAttachment source.
     * @member {IMCAttachmentSource|null|undefined} source
     * @memberof MCAttachment
     * @instance
     */
    MCAttachment.prototype.source = null;

    /**
     * MCAttachment preview.
     * @member {IMCAttachment|null|undefined} preview
     * @memberof MCAttachment
     * @instance
     */
    MCAttachment.prototype.preview = null;

    /**
     * Creates a new MCAttachment instance using the specified properties.
     * @function create
     * @memberof MCAttachment
     * @static
     * @param {IMCAttachment=} [properties] Properties to set
     * @returns {MCAttachment} MCAttachment instance
     */
    MCAttachment.create = function create(properties) {
        return new MCAttachment(properties);
    };

    /**
     * Encodes the specified MCAttachment message. Does not implicitly {@link MCAttachment.verify|verify} messages.
     * @function encode
     * @memberof MCAttachment
     * @static
     * @param {IMCAttachment} message MCAttachment message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCAttachment.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.size != null && Object.hasOwnProperty.call(message, "size"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.size);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
        if (message.width != null && Object.hasOwnProperty.call(message, "width"))
            writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.width);
        if (message.height != null && Object.hasOwnProperty.call(message, "height"))
            writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.height);
        if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
            writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.duration);
        if (message.mimeType != null && Object.hasOwnProperty.call(message, "mimeType"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.mimeType);
        if (message.source != null && Object.hasOwnProperty.call(message, "source"))
            $root.MCAttachmentSource.encode(message.source, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
        if (message.preview != null && Object.hasOwnProperty.call(message, "preview"))
            $root.MCAttachment.encode(message.preview, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCAttachment message, length delimited. Does not implicitly {@link MCAttachment.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCAttachment
     * @static
     * @param {IMCAttachment} message MCAttachment message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCAttachment.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCAttachment message from the specified reader or buffer.
     * @function decode
     * @memberof MCAttachment
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCAttachment} MCAttachment
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCAttachment.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCAttachment();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 2: {
                    message.size = reader.uint64();
                    break;
                }
            case 3: {
                    message.name = reader.string();
                    break;
                }
            case 4: {
                    message.type = reader.int32();
                    break;
                }
            case 10: {
                    message.mimeType = reader.string();
                    break;
                }
            case 6: {
                    message.width = reader.uint64();
                    break;
                }
            case 7: {
                    message.height = reader.uint64();
                    break;
                }
            case 8: {
                    message.duration = reader.uint64();
                    break;
                }
            case 20: {
                    message.source = $root.MCAttachmentSource.decode(reader, reader.uint32());
                    break;
                }
            case 21: {
                    message.preview = $root.MCAttachment.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCAttachment message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCAttachment
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCAttachment} MCAttachment
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCAttachment.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCAttachment message.
     * @function verify
     * @memberof MCAttachment
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCAttachment.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.size != null && message.hasOwnProperty("size"))
            if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                return "size: integer|Long expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                break;
            }
        if (message.mimeType != null && message.hasOwnProperty("mimeType"))
            if (!$util.isString(message.mimeType))
                return "mimeType: string expected";
        if (message.width != null && message.hasOwnProperty("width"))
            if (!$util.isInteger(message.width) && !(message.width && $util.isInteger(message.width.low) && $util.isInteger(message.width.high)))
                return "width: integer|Long expected";
        if (message.height != null && message.hasOwnProperty("height"))
            if (!$util.isInteger(message.height) && !(message.height && $util.isInteger(message.height.low) && $util.isInteger(message.height.high)))
                return "height: integer|Long expected";
        if (message.duration != null && message.hasOwnProperty("duration"))
            if (!$util.isInteger(message.duration) && !(message.duration && $util.isInteger(message.duration.low) && $util.isInteger(message.duration.high)))
                return "duration: integer|Long expected";
        if (message.source != null && message.hasOwnProperty("source")) {
            let error = $root.MCAttachmentSource.verify(message.source);
            if (error)
                return "source." + error;
        }
        if (message.preview != null && message.hasOwnProperty("preview")) {
            let error = $root.MCAttachment.verify(message.preview);
            if (error)
                return "preview." + error;
        }
        return null;
    };

    /**
     * Creates a MCAttachment message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCAttachment
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCAttachment} MCAttachment
     */
    MCAttachment.fromObject = function fromObject(object) {
        if (object instanceof $root.MCAttachment)
            return object;
        let message = new $root.MCAttachment();
        if (object.size != null)
            if ($util.Long)
                (message.size = $util.Long.fromValue(object.size)).unsigned = true;
            else if (typeof object.size === "string")
                message.size = parseInt(object.size, 10);
            else if (typeof object.size === "number")
                message.size = object.size;
            else if (typeof object.size === "object")
                message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber(true);
        if (object.name != null)
            message.name = String(object.name);
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "UNKNOWN":
        case 0:
            message.type = 0;
            break;
        case "IMAGE":
        case 1:
            message.type = 1;
            break;
        case "VIDEO":
        case 2:
            message.type = 2;
            break;
        case "AUDIO":
        case 3:
            message.type = 3;
            break;
        case "DOCUMENT":
        case 4:
            message.type = 4;
            break;
        case "STICKER":
        case 5:
            message.type = 5;
            break;
        case "GIF":
        case 6:
            message.type = 6;
            break;
        case "VOICE":
        case 7:
            message.type = 7;
            break;
        }
        if (object.mimeType != null)
            message.mimeType = String(object.mimeType);
        if (object.width != null)
            if ($util.Long)
                (message.width = $util.Long.fromValue(object.width)).unsigned = true;
            else if (typeof object.width === "string")
                message.width = parseInt(object.width, 10);
            else if (typeof object.width === "number")
                message.width = object.width;
            else if (typeof object.width === "object")
                message.width = new $util.LongBits(object.width.low >>> 0, object.width.high >>> 0).toNumber(true);
        if (object.height != null)
            if ($util.Long)
                (message.height = $util.Long.fromValue(object.height)).unsigned = true;
            else if (typeof object.height === "string")
                message.height = parseInt(object.height, 10);
            else if (typeof object.height === "number")
                message.height = object.height;
            else if (typeof object.height === "object")
                message.height = new $util.LongBits(object.height.low >>> 0, object.height.high >>> 0).toNumber(true);
        if (object.duration != null)
            if ($util.Long)
                (message.duration = $util.Long.fromValue(object.duration)).unsigned = true;
            else if (typeof object.duration === "string")
                message.duration = parseInt(object.duration, 10);
            else if (typeof object.duration === "number")
                message.duration = object.duration;
            else if (typeof object.duration === "object")
                message.duration = new $util.LongBits(object.duration.low >>> 0, object.duration.high >>> 0).toNumber(true);
        if (object.source != null) {
            if (typeof object.source !== "object")
                throw TypeError(".MCAttachment.source: object expected");
            message.source = $root.MCAttachmentSource.fromObject(object.source);
        }
        if (object.preview != null) {
            if (typeof object.preview !== "object")
                throw TypeError(".MCAttachment.preview: object expected");
            message.preview = $root.MCAttachment.fromObject(object.preview);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCAttachment message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCAttachment
     * @static
     * @param {MCAttachment} message MCAttachment
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCAttachment.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.size = options.longs === String ? "0" : 0;
            object.name = "";
            object.type = options.enums === String ? "UNKNOWN" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.width = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.width = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.height = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.height = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.duration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.duration = options.longs === String ? "0" : 0;
            object.mimeType = "";
            object.source = null;
            object.preview = null;
        }
        if (message.size != null && message.hasOwnProperty("size"))
            if (typeof message.size === "number")
                object.size = options.longs === String ? String(message.size) : message.size;
            else
                object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber(true) : message.size;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.MCAttachment.Type[message.type] === undefined ? message.type : $root.MCAttachment.Type[message.type] : message.type;
        if (message.width != null && message.hasOwnProperty("width"))
            if (typeof message.width === "number")
                object.width = options.longs === String ? String(message.width) : message.width;
            else
                object.width = options.longs === String ? $util.Long.prototype.toString.call(message.width) : options.longs === Number ? new $util.LongBits(message.width.low >>> 0, message.width.high >>> 0).toNumber(true) : message.width;
        if (message.height != null && message.hasOwnProperty("height"))
            if (typeof message.height === "number")
                object.height = options.longs === String ? String(message.height) : message.height;
            else
                object.height = options.longs === String ? $util.Long.prototype.toString.call(message.height) : options.longs === Number ? new $util.LongBits(message.height.low >>> 0, message.height.high >>> 0).toNumber(true) : message.height;
        if (message.duration != null && message.hasOwnProperty("duration"))
            if (typeof message.duration === "number")
                object.duration = options.longs === String ? String(message.duration) : message.duration;
            else
                object.duration = options.longs === String ? $util.Long.prototype.toString.call(message.duration) : options.longs === Number ? new $util.LongBits(message.duration.low >>> 0, message.duration.high >>> 0).toNumber(true) : message.duration;
        if (message.mimeType != null && message.hasOwnProperty("mimeType"))
            object.mimeType = message.mimeType;
        if (message.source != null && message.hasOwnProperty("source"))
            object.source = $root.MCAttachmentSource.toObject(message.source, options);
        if (message.preview != null && message.hasOwnProperty("preview"))
            object.preview = $root.MCAttachment.toObject(message.preview, options);
        return object;
    };

    /**
     * Converts this MCAttachment to JSON.
     * @function toJSON
     * @memberof MCAttachment
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCAttachment.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCAttachment
     * @function getTypeUrl
     * @memberof MCAttachment
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCAttachment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCAttachment";
    };

    /**
     * Type enum.
     * @name MCAttachment.Type
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} IMAGE=1 IMAGE value
     * @property {number} VIDEO=2 VIDEO value
     * @property {number} AUDIO=3 AUDIO value
     * @property {number} DOCUMENT=4 DOCUMENT value
     * @property {number} STICKER=5 STICKER value
     * @property {number} GIF=6 GIF value
     * @property {number} VOICE=7 VOICE value
     */
    MCAttachment.Type = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "IMAGE"] = 1;
        values[valuesById[2] = "VIDEO"] = 2;
        values[valuesById[3] = "AUDIO"] = 3;
        values[valuesById[4] = "DOCUMENT"] = 4;
        values[valuesById[5] = "STICKER"] = 5;
        values[valuesById[6] = "GIF"] = 6;
        values[valuesById[7] = "VOICE"] = 7;
        return values;
    })();

    return MCAttachment;
})();

export const MCMessageAction = $root.MCMessageAction = (() => {

    /**
     * Properties of a MCMessageAction.
     * @exports IMCMessageAction
     * @interface IMCMessageAction
     * @property {MCMessageAction.Type|null} [type] MCMessageAction type
     * @property {Long|null} [timestamp] MCMessageAction timestamp
     */

    /**
     * Constructs a new MCMessageAction.
     * @exports MCMessageAction
     * @classdesc Represents a MCMessageAction.
     * @implements IMCMessageAction
     * @constructor
     * @param {IMCMessageAction=} [properties] Properties to set
     */
    function MCMessageAction(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMessageAction type.
     * @member {MCMessageAction.Type} type
     * @memberof MCMessageAction
     * @instance
     */
    MCMessageAction.prototype.type = 0;

    /**
     * MCMessageAction timestamp.
     * @member {Long} timestamp
     * @memberof MCMessageAction
     * @instance
     */
    MCMessageAction.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCMessageAction instance using the specified properties.
     * @function create
     * @memberof MCMessageAction
     * @static
     * @param {IMCMessageAction=} [properties] Properties to set
     * @returns {MCMessageAction} MCMessageAction instance
     */
    MCMessageAction.create = function create(properties) {
        return new MCMessageAction(properties);
    };

    /**
     * Encodes the specified MCMessageAction message. Does not implicitly {@link MCMessageAction.verify|verify} messages.
     * @function encode
     * @memberof MCMessageAction
     * @static
     * @param {IMCMessageAction} message MCMessageAction message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMessageAction.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
        if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.timestamp);
        return writer;
    };

    /**
     * Encodes the specified MCMessageAction message, length delimited. Does not implicitly {@link MCMessageAction.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMessageAction
     * @static
     * @param {IMCMessageAction} message MCMessageAction message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMessageAction.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMessageAction message from the specified reader or buffer.
     * @function decode
     * @memberof MCMessageAction
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMessageAction} MCMessageAction
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMessageAction.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMessageAction();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.type = reader.int32();
                    break;
                }
            case 2: {
                    message.timestamp = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMessageAction message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMessageAction
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMessageAction} MCMessageAction
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMessageAction.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMessageAction message.
     * @function verify
     * @memberof MCMessageAction
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMessageAction.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.timestamp != null && message.hasOwnProperty("timestamp"))
            if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                return "timestamp: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCMessageAction message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMessageAction
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMessageAction} MCMessageAction
     */
    MCMessageAction.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMessageAction)
            return object;
        let message = new $root.MCMessageAction();
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "UNKNOWN":
        case 0:
            message.type = 0;
            break;
        case "EDIT":
        case 1:
            message.type = 1;
            break;
        case "DELETE":
        case 2:
            message.type = 2;
            break;
        }
        if (object.timestamp != null)
            if ($util.Long)
                (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = true;
            else if (typeof object.timestamp === "string")
                message.timestamp = parseInt(object.timestamp, 10);
            else if (typeof object.timestamp === "number")
                message.timestamp = object.timestamp;
            else if (typeof object.timestamp === "object")
                message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCMessageAction message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMessageAction
     * @static
     * @param {MCMessageAction} message MCMessageAction
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMessageAction.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "UNKNOWN" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.timestamp = options.longs === String ? "0" : 0;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.MCMessageAction.Type[message.type] === undefined ? message.type : $root.MCMessageAction.Type[message.type] : message.type;
        if (message.timestamp != null && message.hasOwnProperty("timestamp"))
            if (typeof message.timestamp === "number")
                object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
            else
                object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber(true) : message.timestamp;
        return object;
    };

    /**
     * Converts this MCMessageAction to JSON.
     * @function toJSON
     * @memberof MCMessageAction
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMessageAction.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMessageAction
     * @function getTypeUrl
     * @memberof MCMessageAction
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMessageAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMessageAction";
    };

    /**
     * Type enum.
     * @name MCMessageAction.Type
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} EDIT=1 EDIT value
     * @property {number} DELETE=2 DELETE value
     */
    MCMessageAction.Type = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "EDIT"] = 1;
        values[valuesById[2] = "DELETE"] = 2;
        return values;
    })();

    return MCMessageAction;
})();

export const MCTextEntities = $root.MCTextEntities = (() => {

    /**
     * Properties of a MCTextEntities.
     * @exports IMCTextEntities
     * @interface IMCTextEntities
     * @property {MCTextEntities.Type|null} [type] MCTextEntities type
     * @property {Long|null} [offset] MCTextEntities offset
     * @property {Long|null} [length] MCTextEntities length
     * @property {Uint8Array|null} [payload] MCTextEntities payload
     */

    /**
     * Constructs a new MCTextEntities.
     * @exports MCTextEntities
     * @classdesc Represents a MCTextEntities.
     * @implements IMCTextEntities
     * @constructor
     * @param {IMCTextEntities=} [properties] Properties to set
     */
    function MCTextEntities(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCTextEntities type.
     * @member {MCTextEntities.Type} type
     * @memberof MCTextEntities
     * @instance
     */
    MCTextEntities.prototype.type = 0;

    /**
     * MCTextEntities offset.
     * @member {Long} offset
     * @memberof MCTextEntities
     * @instance
     */
    MCTextEntities.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCTextEntities length.
     * @member {Long} length
     * @memberof MCTextEntities
     * @instance
     */
    MCTextEntities.prototype.length = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCTextEntities payload.
     * @member {Uint8Array} payload
     * @memberof MCTextEntities
     * @instance
     */
    MCTextEntities.prototype.payload = $util.newBuffer([]);

    /**
     * Creates a new MCTextEntities instance using the specified properties.
     * @function create
     * @memberof MCTextEntities
     * @static
     * @param {IMCTextEntities=} [properties] Properties to set
     * @returns {MCTextEntities} MCTextEntities instance
     */
    MCTextEntities.create = function create(properties) {
        return new MCTextEntities(properties);
    };

    /**
     * Encodes the specified MCTextEntities message. Does not implicitly {@link MCTextEntities.verify|verify} messages.
     * @function encode
     * @memberof MCTextEntities
     * @static
     * @param {IMCTextEntities} message MCTextEntities message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCTextEntities.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
        if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.offset);
        if (message.length != null && Object.hasOwnProperty.call(message, "length"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.length);
        if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
            writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.payload);
        return writer;
    };

    /**
     * Encodes the specified MCTextEntities message, length delimited. Does not implicitly {@link MCTextEntities.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCTextEntities
     * @static
     * @param {IMCTextEntities} message MCTextEntities message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCTextEntities.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCTextEntities message from the specified reader or buffer.
     * @function decode
     * @memberof MCTextEntities
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCTextEntities} MCTextEntities
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCTextEntities.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCTextEntities();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.type = reader.int32();
                    break;
                }
            case 2: {
                    message.offset = reader.uint64();
                    break;
                }
            case 3: {
                    message.length = reader.uint64();
                    break;
                }
            case 4: {
                    message.payload = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCTextEntities message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCTextEntities
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCTextEntities} MCTextEntities
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCTextEntities.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCTextEntities message.
     * @function verify
     * @memberof MCTextEntities
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCTextEntities.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
                break;
            }
        if (message.offset != null && message.hasOwnProperty("offset"))
            if (!$util.isInteger(message.offset) && !(message.offset && $util.isInteger(message.offset.low) && $util.isInteger(message.offset.high)))
                return "offset: integer|Long expected";
        if (message.length != null && message.hasOwnProperty("length"))
            if (!$util.isInteger(message.length) && !(message.length && $util.isInteger(message.length.low) && $util.isInteger(message.length.high)))
                return "length: integer|Long expected";
        if (message.payload != null && message.hasOwnProperty("payload"))
            if (!(message.payload && typeof message.payload.length === "number" || $util.isString(message.payload)))
                return "payload: buffer expected";
        return null;
    };

    /**
     * Creates a MCTextEntities message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCTextEntities
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCTextEntities} MCTextEntities
     */
    MCTextEntities.fromObject = function fromObject(object) {
        if (object instanceof $root.MCTextEntities)
            return object;
        let message = new $root.MCTextEntities();
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "UNKNOWN":
        case 0:
            message.type = 0;
            break;
        case "BOLD":
        case 1:
            message.type = 1;
            break;
        case "ITALIC":
        case 2:
            message.type = 2;
            break;
        case "UNDERLINE":
        case 3:
            message.type = 3;
            break;
        case "STRIKETHROUGH":
        case 4:
            message.type = 4;
            break;
        case "CODE":
        case 5:
            message.type = 5;
            break;
        case "SPOLIER":
        case 6:
            message.type = 6;
            break;
        case "MENTION":
        case 7:
            message.type = 7;
            break;
        case "LINK":
        case 8:
            message.type = 8;
            break;
        case "EMAIL_ADDRESS":
        case 9:
            message.type = 9;
            break;
        case "PHONE_NUMBER":
        case 10:
            message.type = 10;
            break;
        }
        if (object.offset != null)
            if ($util.Long)
                (message.offset = $util.Long.fromValue(object.offset)).unsigned = true;
            else if (typeof object.offset === "string")
                message.offset = parseInt(object.offset, 10);
            else if (typeof object.offset === "number")
                message.offset = object.offset;
            else if (typeof object.offset === "object")
                message.offset = new $util.LongBits(object.offset.low >>> 0, object.offset.high >>> 0).toNumber(true);
        if (object.length != null)
            if ($util.Long)
                (message.length = $util.Long.fromValue(object.length)).unsigned = true;
            else if (typeof object.length === "string")
                message.length = parseInt(object.length, 10);
            else if (typeof object.length === "number")
                message.length = object.length;
            else if (typeof object.length === "object")
                message.length = new $util.LongBits(object.length.low >>> 0, object.length.high >>> 0).toNumber(true);
        if (object.payload != null)
            if (typeof object.payload === "string")
                $util.base64.decode(object.payload, message.payload = $util.newBuffer($util.base64.length(object.payload)), 0);
            else if (object.payload.length >= 0)
                message.payload = object.payload;
        return message;
    };

    /**
     * Creates a plain object from a MCTextEntities message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCTextEntities
     * @static
     * @param {MCTextEntities} message MCTextEntities
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCTextEntities.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "UNKNOWN" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.offset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.offset = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.length = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.length = options.longs === String ? "0" : 0;
            if (options.bytes === String)
                object.payload = "";
            else {
                object.payload = [];
                if (options.bytes !== Array)
                    object.payload = $util.newBuffer(object.payload);
            }
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.MCTextEntities.Type[message.type] === undefined ? message.type : $root.MCTextEntities.Type[message.type] : message.type;
        if (message.offset != null && message.hasOwnProperty("offset"))
            if (typeof message.offset === "number")
                object.offset = options.longs === String ? String(message.offset) : message.offset;
            else
                object.offset = options.longs === String ? $util.Long.prototype.toString.call(message.offset) : options.longs === Number ? new $util.LongBits(message.offset.low >>> 0, message.offset.high >>> 0).toNumber(true) : message.offset;
        if (message.length != null && message.hasOwnProperty("length"))
            if (typeof message.length === "number")
                object.length = options.longs === String ? String(message.length) : message.length;
            else
                object.length = options.longs === String ? $util.Long.prototype.toString.call(message.length) : options.longs === Number ? new $util.LongBits(message.length.low >>> 0, message.length.high >>> 0).toNumber(true) : message.length;
        if (message.payload != null && message.hasOwnProperty("payload"))
            object.payload = options.bytes === String ? $util.base64.encode(message.payload, 0, message.payload.length) : options.bytes === Array ? Array.prototype.slice.call(message.payload) : message.payload;
        return object;
    };

    /**
     * Converts this MCTextEntities to JSON.
     * @function toJSON
     * @memberof MCTextEntities
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCTextEntities.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCTextEntities
     * @function getTypeUrl
     * @memberof MCTextEntities
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCTextEntities.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCTextEntities";
    };

    /**
     * Type enum.
     * @name MCTextEntities.Type
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} BOLD=1 BOLD value
     * @property {number} ITALIC=2 ITALIC value
     * @property {number} UNDERLINE=3 UNDERLINE value
     * @property {number} STRIKETHROUGH=4 STRIKETHROUGH value
     * @property {number} CODE=5 CODE value
     * @property {number} SPOLIER=6 SPOLIER value
     * @property {number} MENTION=7 MENTION value
     * @property {number} LINK=8 LINK value
     * @property {number} EMAIL_ADDRESS=9 EMAIL_ADDRESS value
     * @property {number} PHONE_NUMBER=10 PHONE_NUMBER value
     */
    MCTextEntities.Type = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "BOLD"] = 1;
        values[valuesById[2] = "ITALIC"] = 2;
        values[valuesById[3] = "UNDERLINE"] = 3;
        values[valuesById[4] = "STRIKETHROUGH"] = 4;
        values[valuesById[5] = "CODE"] = 5;
        values[valuesById[6] = "SPOLIER"] = 6;
        values[valuesById[7] = "MENTION"] = 7;
        values[valuesById[8] = "LINK"] = 8;
        values[valuesById[9] = "EMAIL_ADDRESS"] = 9;
        values[valuesById[10] = "PHONE_NUMBER"] = 10;
        return values;
    })();

    return MCTextEntities;
})();

export const MCMessage = $root.MCMessage = (() => {

    /**
     * Properties of a MCMessage.
     * @exports IMCMessage
     * @interface IMCMessage
     * @property {Long|null} [localID] MCMessage localID
     * @property {IMCPeerPreview|null} [peer] MCMessage peer
     * @property {Uint8Array|null} [externalID] MCMessage externalID
     * @property {Long|null} [chatID] MCMessage chatID
     * @property {Uint8Array|null} [externalChatID] MCMessage externalChatID
     * @property {Long|null} [sentAt] MCMessage sentAt
     * @property {Array.<IMCMessageAction>|null} [actions] MCMessage actions
     * @property {Array.<IMCAttachment>|null} [attachments] MCMessage attachments
     * @property {IMCServiceMessage|null} [serviceMessage] MCMessage serviceMessage
     * @property {Uint8Array|null} [groupID] MCMessage groupID
     * @property {Long|null} [replyToMessageID] MCMessage replyToMessageID
     * @property {Uint8Array|null} [replyToExternalMessageID] MCMessage replyToExternalMessageID
     * @property {IMCMessagePreview|null} [replyToMessage] MCMessage replyToMessage
     * @property {Array.<IMCTextEntities>|null} [textEntities] MCMessage textEntities
     * @property {string|null} [text] MCMessage text
     * @property {Long|null} [operatorID] MCMessage operatorID
     * @property {string|null} [operatorName] MCMessage operatorName
     * @property {Uint8Array|null} [suggestion] MCMessage suggestion
     */

    /**
     * Constructs a new MCMessage.
     * @exports MCMessage
     * @classdesc Represents a MCMessage.
     * @implements IMCMessage
     * @constructor
     * @param {IMCMessage=} [properties] Properties to set
     */
    function MCMessage(properties) {
        this.actions = [];
        this.attachments = [];
        this.textEntities = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMessage localID.
     * @member {Long} localID
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.localID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMessage peer.
     * @member {IMCPeerPreview|null|undefined} peer
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.peer = null;

    /**
     * MCMessage externalID.
     * @member {Uint8Array} externalID
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.externalID = $util.newBuffer([]);

    /**
     * MCMessage chatID.
     * @member {Long} chatID
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMessage externalChatID.
     * @member {Uint8Array} externalChatID
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.externalChatID = $util.newBuffer([]);

    /**
     * MCMessage sentAt.
     * @member {Long} sentAt
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.sentAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMessage actions.
     * @member {Array.<IMCMessageAction>} actions
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.actions = $util.emptyArray;

    /**
     * MCMessage attachments.
     * @member {Array.<IMCAttachment>} attachments
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.attachments = $util.emptyArray;

    /**
     * MCMessage serviceMessage.
     * @member {IMCServiceMessage|null|undefined} serviceMessage
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.serviceMessage = null;

    /**
     * MCMessage groupID.
     * @member {Uint8Array} groupID
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.groupID = $util.newBuffer([]);

    /**
     * MCMessage replyToMessageID.
     * @member {Long} replyToMessageID
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.replyToMessageID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMessage replyToExternalMessageID.
     * @member {Uint8Array} replyToExternalMessageID
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.replyToExternalMessageID = $util.newBuffer([]);

    /**
     * MCMessage replyToMessage.
     * @member {IMCMessagePreview|null|undefined} replyToMessage
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.replyToMessage = null;

    /**
     * MCMessage textEntities.
     * @member {Array.<IMCTextEntities>} textEntities
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.textEntities = $util.emptyArray;

    /**
     * MCMessage text.
     * @member {string} text
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.text = "";

    /**
     * MCMessage operatorID.
     * @member {Long} operatorID
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.operatorID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMessage operatorName.
     * @member {string} operatorName
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.operatorName = "";

    /**
     * MCMessage suggestion.
     * @member {Uint8Array} suggestion
     * @memberof MCMessage
     * @instance
     */
    MCMessage.prototype.suggestion = $util.newBuffer([]);

    /**
     * Creates a new MCMessage instance using the specified properties.
     * @function create
     * @memberof MCMessage
     * @static
     * @param {IMCMessage=} [properties] Properties to set
     * @returns {MCMessage} MCMessage instance
     */
    MCMessage.create = function create(properties) {
        return new MCMessage(properties);
    };

    /**
     * Encodes the specified MCMessage message. Does not implicitly {@link MCMessage.verify|verify} messages.
     * @function encode
     * @memberof MCMessage
     * @static
     * @param {IMCMessage} message MCMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.localID != null && Object.hasOwnProperty.call(message, "localID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.localID);
        if (message.peer != null && Object.hasOwnProperty.call(message, "peer"))
            $root.MCPeerPreview.encode(message.peer, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.externalID != null && Object.hasOwnProperty.call(message, "externalID"))
            writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.externalID);
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.chatID);
        if (message.sentAt != null && Object.hasOwnProperty.call(message, "sentAt"))
            writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.sentAt);
        if (message.actions != null && message.actions.length)
            for (let i = 0; i < message.actions.length; ++i)
                $root.MCMessageAction.encode(message.actions[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.attachments != null && message.attachments.length)
            for (let i = 0; i < message.attachments.length; ++i)
                $root.MCAttachment.encode(message.attachments[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.serviceMessage != null && Object.hasOwnProperty.call(message, "serviceMessage"))
            $root.MCServiceMessage.encode(message.serviceMessage, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.groupID != null && Object.hasOwnProperty.call(message, "groupID"))
            writer.uint32(/* id 12, wireType 2 =*/98).bytes(message.groupID);
        if (message.replyToMessageID != null && Object.hasOwnProperty.call(message, "replyToMessageID"))
            writer.uint32(/* id 13, wireType 0 =*/104).uint64(message.replyToMessageID);
        if (message.externalChatID != null && Object.hasOwnProperty.call(message, "externalChatID"))
            writer.uint32(/* id 14, wireType 2 =*/114).bytes(message.externalChatID);
        if (message.replyToExternalMessageID != null && Object.hasOwnProperty.call(message, "replyToExternalMessageID"))
            writer.uint32(/* id 15, wireType 2 =*/122).bytes(message.replyToExternalMessageID);
        if (message.replyToMessage != null && Object.hasOwnProperty.call(message, "replyToMessage"))
            $root.MCMessagePreview.encode(message.replyToMessage, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
        if (message.textEntities != null && message.textEntities.length)
            for (let i = 0; i < message.textEntities.length; ++i)
                $root.MCTextEntities.encode(message.textEntities[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
        if (message.text != null && Object.hasOwnProperty.call(message, "text"))
            writer.uint32(/* id 20, wireType 2 =*/162).string(message.text);
        if (message.operatorID != null && Object.hasOwnProperty.call(message, "operatorID"))
            writer.uint32(/* id 21, wireType 0 =*/168).uint64(message.operatorID);
        if (message.operatorName != null && Object.hasOwnProperty.call(message, "operatorName"))
            writer.uint32(/* id 22, wireType 2 =*/178).string(message.operatorName);
        if (message.suggestion != null && Object.hasOwnProperty.call(message, "suggestion"))
            writer.uint32(/* id 23, wireType 2 =*/186).bytes(message.suggestion);
        return writer;
    };

    /**
     * Encodes the specified MCMessage message, length delimited. Does not implicitly {@link MCMessage.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMessage
     * @static
     * @param {IMCMessage} message MCMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMessage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMessage message from the specified reader or buffer.
     * @function decode
     * @memberof MCMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMessage} MCMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.localID = reader.uint64();
                    break;
                }
            case 2: {
                    message.peer = $root.MCPeerPreview.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.externalID = reader.bytes();
                    break;
                }
            case 4: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 14: {
                    message.externalChatID = reader.bytes();
                    break;
                }
            case 5: {
                    message.sentAt = reader.uint64();
                    break;
                }
            case 8: {
                    if (!(message.actions && message.actions.length))
                        message.actions = [];
                    message.actions.push($root.MCMessageAction.decode(reader, reader.uint32()));
                    break;
                }
            case 9: {
                    if (!(message.attachments && message.attachments.length))
                        message.attachments = [];
                    message.attachments.push($root.MCAttachment.decode(reader, reader.uint32()));
                    break;
                }
            case 11: {
                    message.serviceMessage = $root.MCServiceMessage.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.groupID = reader.bytes();
                    break;
                }
            case 13: {
                    message.replyToMessageID = reader.uint64();
                    break;
                }
            case 15: {
                    message.replyToExternalMessageID = reader.bytes();
                    break;
                }
            case 16: {
                    message.replyToMessage = $root.MCMessagePreview.decode(reader, reader.uint32());
                    break;
                }
            case 19: {
                    if (!(message.textEntities && message.textEntities.length))
                        message.textEntities = [];
                    message.textEntities.push($root.MCTextEntities.decode(reader, reader.uint32()));
                    break;
                }
            case 20: {
                    message.text = reader.string();
                    break;
                }
            case 21: {
                    message.operatorID = reader.uint64();
                    break;
                }
            case 22: {
                    message.operatorName = reader.string();
                    break;
                }
            case 23: {
                    message.suggestion = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMessage message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMessage} MCMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMessage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMessage message.
     * @function verify
     * @memberof MCMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMessage.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.localID != null && message.hasOwnProperty("localID"))
            if (!$util.isInteger(message.localID) && !(message.localID && $util.isInteger(message.localID.low) && $util.isInteger(message.localID.high)))
                return "localID: integer|Long expected";
        if (message.peer != null && message.hasOwnProperty("peer")) {
            let error = $root.MCPeerPreview.verify(message.peer);
            if (error)
                return "peer." + error;
        }
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            if (!(message.externalID && typeof message.externalID.length === "number" || $util.isString(message.externalID)))
                return "externalID: buffer expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.externalChatID != null && message.hasOwnProperty("externalChatID"))
            if (!(message.externalChatID && typeof message.externalChatID.length === "number" || $util.isString(message.externalChatID)))
                return "externalChatID: buffer expected";
        if (message.sentAt != null && message.hasOwnProperty("sentAt"))
            if (!$util.isInteger(message.sentAt) && !(message.sentAt && $util.isInteger(message.sentAt.low) && $util.isInteger(message.sentAt.high)))
                return "sentAt: integer|Long expected";
        if (message.actions != null && message.hasOwnProperty("actions")) {
            if (!Array.isArray(message.actions))
                return "actions: array expected";
            for (let i = 0; i < message.actions.length; ++i) {
                let error = $root.MCMessageAction.verify(message.actions[i]);
                if (error)
                    return "actions." + error;
            }
        }
        if (message.attachments != null && message.hasOwnProperty("attachments")) {
            if (!Array.isArray(message.attachments))
                return "attachments: array expected";
            for (let i = 0; i < message.attachments.length; ++i) {
                let error = $root.MCAttachment.verify(message.attachments[i]);
                if (error)
                    return "attachments." + error;
            }
        }
        if (message.serviceMessage != null && message.hasOwnProperty("serviceMessage")) {
            let error = $root.MCServiceMessage.verify(message.serviceMessage);
            if (error)
                return "serviceMessage." + error;
        }
        if (message.groupID != null && message.hasOwnProperty("groupID"))
            if (!(message.groupID && typeof message.groupID.length === "number" || $util.isString(message.groupID)))
                return "groupID: buffer expected";
        if (message.replyToMessageID != null && message.hasOwnProperty("replyToMessageID"))
            if (!$util.isInteger(message.replyToMessageID) && !(message.replyToMessageID && $util.isInteger(message.replyToMessageID.low) && $util.isInteger(message.replyToMessageID.high)))
                return "replyToMessageID: integer|Long expected";
        if (message.replyToExternalMessageID != null && message.hasOwnProperty("replyToExternalMessageID"))
            if (!(message.replyToExternalMessageID && typeof message.replyToExternalMessageID.length === "number" || $util.isString(message.replyToExternalMessageID)))
                return "replyToExternalMessageID: buffer expected";
        if (message.replyToMessage != null && message.hasOwnProperty("replyToMessage")) {
            let error = $root.MCMessagePreview.verify(message.replyToMessage);
            if (error)
                return "replyToMessage." + error;
        }
        if (message.textEntities != null && message.hasOwnProperty("textEntities")) {
            if (!Array.isArray(message.textEntities))
                return "textEntities: array expected";
            for (let i = 0; i < message.textEntities.length; ++i) {
                let error = $root.MCTextEntities.verify(message.textEntities[i]);
                if (error)
                    return "textEntities." + error;
            }
        }
        if (message.text != null && message.hasOwnProperty("text"))
            if (!$util.isString(message.text))
                return "text: string expected";
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (!$util.isInteger(message.operatorID) && !(message.operatorID && $util.isInteger(message.operatorID.low) && $util.isInteger(message.operatorID.high)))
                return "operatorID: integer|Long expected";
        if (message.operatorName != null && message.hasOwnProperty("operatorName"))
            if (!$util.isString(message.operatorName))
                return "operatorName: string expected";
        if (message.suggestion != null && message.hasOwnProperty("suggestion"))
            if (!(message.suggestion && typeof message.suggestion.length === "number" || $util.isString(message.suggestion)))
                return "suggestion: buffer expected";
        return null;
    };

    /**
     * Creates a MCMessage message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMessage
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMessage} MCMessage
     */
    MCMessage.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMessage)
            return object;
        let message = new $root.MCMessage();
        if (object.localID != null)
            if ($util.Long)
                (message.localID = $util.Long.fromValue(object.localID)).unsigned = true;
            else if (typeof object.localID === "string")
                message.localID = parseInt(object.localID, 10);
            else if (typeof object.localID === "number")
                message.localID = object.localID;
            else if (typeof object.localID === "object")
                message.localID = new $util.LongBits(object.localID.low >>> 0, object.localID.high >>> 0).toNumber(true);
        if (object.peer != null) {
            if (typeof object.peer !== "object")
                throw TypeError(".MCMessage.peer: object expected");
            message.peer = $root.MCPeerPreview.fromObject(object.peer);
        }
        if (object.externalID != null)
            if (typeof object.externalID === "string")
                $util.base64.decode(object.externalID, message.externalID = $util.newBuffer($util.base64.length(object.externalID)), 0);
            else if (object.externalID.length >= 0)
                message.externalID = object.externalID;
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.externalChatID != null)
            if (typeof object.externalChatID === "string")
                $util.base64.decode(object.externalChatID, message.externalChatID = $util.newBuffer($util.base64.length(object.externalChatID)), 0);
            else if (object.externalChatID.length >= 0)
                message.externalChatID = object.externalChatID;
        if (object.sentAt != null)
            if ($util.Long)
                (message.sentAt = $util.Long.fromValue(object.sentAt)).unsigned = true;
            else if (typeof object.sentAt === "string")
                message.sentAt = parseInt(object.sentAt, 10);
            else if (typeof object.sentAt === "number")
                message.sentAt = object.sentAt;
            else if (typeof object.sentAt === "object")
                message.sentAt = new $util.LongBits(object.sentAt.low >>> 0, object.sentAt.high >>> 0).toNumber(true);
        if (object.actions) {
            if (!Array.isArray(object.actions))
                throw TypeError(".MCMessage.actions: array expected");
            message.actions = [];
            for (let i = 0; i < object.actions.length; ++i) {
                if (typeof object.actions[i] !== "object")
                    throw TypeError(".MCMessage.actions: object expected");
                message.actions[i] = $root.MCMessageAction.fromObject(object.actions[i]);
            }
        }
        if (object.attachments) {
            if (!Array.isArray(object.attachments))
                throw TypeError(".MCMessage.attachments: array expected");
            message.attachments = [];
            for (let i = 0; i < object.attachments.length; ++i) {
                if (typeof object.attachments[i] !== "object")
                    throw TypeError(".MCMessage.attachments: object expected");
                message.attachments[i] = $root.MCAttachment.fromObject(object.attachments[i]);
            }
        }
        if (object.serviceMessage != null) {
            if (typeof object.serviceMessage !== "object")
                throw TypeError(".MCMessage.serviceMessage: object expected");
            message.serviceMessage = $root.MCServiceMessage.fromObject(object.serviceMessage);
        }
        if (object.groupID != null)
            if (typeof object.groupID === "string")
                $util.base64.decode(object.groupID, message.groupID = $util.newBuffer($util.base64.length(object.groupID)), 0);
            else if (object.groupID.length >= 0)
                message.groupID = object.groupID;
        if (object.replyToMessageID != null)
            if ($util.Long)
                (message.replyToMessageID = $util.Long.fromValue(object.replyToMessageID)).unsigned = true;
            else if (typeof object.replyToMessageID === "string")
                message.replyToMessageID = parseInt(object.replyToMessageID, 10);
            else if (typeof object.replyToMessageID === "number")
                message.replyToMessageID = object.replyToMessageID;
            else if (typeof object.replyToMessageID === "object")
                message.replyToMessageID = new $util.LongBits(object.replyToMessageID.low >>> 0, object.replyToMessageID.high >>> 0).toNumber(true);
        if (object.replyToExternalMessageID != null)
            if (typeof object.replyToExternalMessageID === "string")
                $util.base64.decode(object.replyToExternalMessageID, message.replyToExternalMessageID = $util.newBuffer($util.base64.length(object.replyToExternalMessageID)), 0);
            else if (object.replyToExternalMessageID.length >= 0)
                message.replyToExternalMessageID = object.replyToExternalMessageID;
        if (object.replyToMessage != null) {
            if (typeof object.replyToMessage !== "object")
                throw TypeError(".MCMessage.replyToMessage: object expected");
            message.replyToMessage = $root.MCMessagePreview.fromObject(object.replyToMessage);
        }
        if (object.textEntities) {
            if (!Array.isArray(object.textEntities))
                throw TypeError(".MCMessage.textEntities: array expected");
            message.textEntities = [];
            for (let i = 0; i < object.textEntities.length; ++i) {
                if (typeof object.textEntities[i] !== "object")
                    throw TypeError(".MCMessage.textEntities: object expected");
                message.textEntities[i] = $root.MCTextEntities.fromObject(object.textEntities[i]);
            }
        }
        if (object.text != null)
            message.text = String(object.text);
        if (object.operatorID != null)
            if ($util.Long)
                (message.operatorID = $util.Long.fromValue(object.operatorID)).unsigned = true;
            else if (typeof object.operatorID === "string")
                message.operatorID = parseInt(object.operatorID, 10);
            else if (typeof object.operatorID === "number")
                message.operatorID = object.operatorID;
            else if (typeof object.operatorID === "object")
                message.operatorID = new $util.LongBits(object.operatorID.low >>> 0, object.operatorID.high >>> 0).toNumber(true);
        if (object.operatorName != null)
            message.operatorName = String(object.operatorName);
        if (object.suggestion != null)
            if (typeof object.suggestion === "string")
                $util.base64.decode(object.suggestion, message.suggestion = $util.newBuffer($util.base64.length(object.suggestion)), 0);
            else if (object.suggestion.length >= 0)
                message.suggestion = object.suggestion;
        return message;
    };

    /**
     * Creates a plain object from a MCMessage message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMessage
     * @static
     * @param {MCMessage} message MCMessage
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMessage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.actions = [];
            object.attachments = [];
            object.textEntities = [];
        }
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.localID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.localID = options.longs === String ? "0" : 0;
            object.peer = null;
            if (options.bytes === String)
                object.externalID = "";
            else {
                object.externalID = [];
                if (options.bytes !== Array)
                    object.externalID = $util.newBuffer(object.externalID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.sentAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.sentAt = options.longs === String ? "0" : 0;
            object.serviceMessage = null;
            if (options.bytes === String)
                object.groupID = "";
            else {
                object.groupID = [];
                if (options.bytes !== Array)
                    object.groupID = $util.newBuffer(object.groupID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.replyToMessageID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.replyToMessageID = options.longs === String ? "0" : 0;
            if (options.bytes === String)
                object.externalChatID = "";
            else {
                object.externalChatID = [];
                if (options.bytes !== Array)
                    object.externalChatID = $util.newBuffer(object.externalChatID);
            }
            if (options.bytes === String)
                object.replyToExternalMessageID = "";
            else {
                object.replyToExternalMessageID = [];
                if (options.bytes !== Array)
                    object.replyToExternalMessageID = $util.newBuffer(object.replyToExternalMessageID);
            }
            object.replyToMessage = null;
            object.text = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.operatorID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.operatorID = options.longs === String ? "0" : 0;
            object.operatorName = "";
            if (options.bytes === String)
                object.suggestion = "";
            else {
                object.suggestion = [];
                if (options.bytes !== Array)
                    object.suggestion = $util.newBuffer(object.suggestion);
            }
        }
        if (message.localID != null && message.hasOwnProperty("localID"))
            if (typeof message.localID === "number")
                object.localID = options.longs === String ? String(message.localID) : message.localID;
            else
                object.localID = options.longs === String ? $util.Long.prototype.toString.call(message.localID) : options.longs === Number ? new $util.LongBits(message.localID.low >>> 0, message.localID.high >>> 0).toNumber(true) : message.localID;
        if (message.peer != null && message.hasOwnProperty("peer"))
            object.peer = $root.MCPeerPreview.toObject(message.peer, options);
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            object.externalID = options.bytes === String ? $util.base64.encode(message.externalID, 0, message.externalID.length) : options.bytes === Array ? Array.prototype.slice.call(message.externalID) : message.externalID;
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.sentAt != null && message.hasOwnProperty("sentAt"))
            if (typeof message.sentAt === "number")
                object.sentAt = options.longs === String ? String(message.sentAt) : message.sentAt;
            else
                object.sentAt = options.longs === String ? $util.Long.prototype.toString.call(message.sentAt) : options.longs === Number ? new $util.LongBits(message.sentAt.low >>> 0, message.sentAt.high >>> 0).toNumber(true) : message.sentAt;
        if (message.actions && message.actions.length) {
            object.actions = [];
            for (let j = 0; j < message.actions.length; ++j)
                object.actions[j] = $root.MCMessageAction.toObject(message.actions[j], options);
        }
        if (message.attachments && message.attachments.length) {
            object.attachments = [];
            for (let j = 0; j < message.attachments.length; ++j)
                object.attachments[j] = $root.MCAttachment.toObject(message.attachments[j], options);
        }
        if (message.serviceMessage != null && message.hasOwnProperty("serviceMessage"))
            object.serviceMessage = $root.MCServiceMessage.toObject(message.serviceMessage, options);
        if (message.groupID != null && message.hasOwnProperty("groupID"))
            object.groupID = options.bytes === String ? $util.base64.encode(message.groupID, 0, message.groupID.length) : options.bytes === Array ? Array.prototype.slice.call(message.groupID) : message.groupID;
        if (message.replyToMessageID != null && message.hasOwnProperty("replyToMessageID"))
            if (typeof message.replyToMessageID === "number")
                object.replyToMessageID = options.longs === String ? String(message.replyToMessageID) : message.replyToMessageID;
            else
                object.replyToMessageID = options.longs === String ? $util.Long.prototype.toString.call(message.replyToMessageID) : options.longs === Number ? new $util.LongBits(message.replyToMessageID.low >>> 0, message.replyToMessageID.high >>> 0).toNumber(true) : message.replyToMessageID;
        if (message.externalChatID != null && message.hasOwnProperty("externalChatID"))
            object.externalChatID = options.bytes === String ? $util.base64.encode(message.externalChatID, 0, message.externalChatID.length) : options.bytes === Array ? Array.prototype.slice.call(message.externalChatID) : message.externalChatID;
        if (message.replyToExternalMessageID != null && message.hasOwnProperty("replyToExternalMessageID"))
            object.replyToExternalMessageID = options.bytes === String ? $util.base64.encode(message.replyToExternalMessageID, 0, message.replyToExternalMessageID.length) : options.bytes === Array ? Array.prototype.slice.call(message.replyToExternalMessageID) : message.replyToExternalMessageID;
        if (message.replyToMessage != null && message.hasOwnProperty("replyToMessage"))
            object.replyToMessage = $root.MCMessagePreview.toObject(message.replyToMessage, options);
        if (message.textEntities && message.textEntities.length) {
            object.textEntities = [];
            for (let j = 0; j < message.textEntities.length; ++j)
                object.textEntities[j] = $root.MCTextEntities.toObject(message.textEntities[j], options);
        }
        if (message.text != null && message.hasOwnProperty("text"))
            object.text = message.text;
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (typeof message.operatorID === "number")
                object.operatorID = options.longs === String ? String(message.operatorID) : message.operatorID;
            else
                object.operatorID = options.longs === String ? $util.Long.prototype.toString.call(message.operatorID) : options.longs === Number ? new $util.LongBits(message.operatorID.low >>> 0, message.operatorID.high >>> 0).toNumber(true) : message.operatorID;
        if (message.operatorName != null && message.hasOwnProperty("operatorName"))
            object.operatorName = message.operatorName;
        if (message.suggestion != null && message.hasOwnProperty("suggestion"))
            object.suggestion = options.bytes === String ? $util.base64.encode(message.suggestion, 0, message.suggestion.length) : options.bytes === Array ? Array.prototype.slice.call(message.suggestion) : message.suggestion;
        return object;
    };

    /**
     * Converts this MCMessage to JSON.
     * @function toJSON
     * @memberof MCMessage
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMessage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMessage
     * @function getTypeUrl
     * @memberof MCMessage
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMessage";
    };

    return MCMessage;
})();

export const MCMessagePreview = $root.MCMessagePreview = (() => {

    /**
     * Properties of a MCMessagePreview.
     * @exports IMCMessagePreview
     * @interface IMCMessagePreview
     * @property {Long|null} [localID] MCMessagePreview localID
     * @property {IMCPeerPreview|null} [peer] MCMessagePreview peer
     * @property {string|null} [text] MCMessagePreview text
     * @property {Long|null} [operatorID] MCMessagePreview operatorID
     * @property {Array.<IMCAttachment>|null} [attachments] MCMessagePreview attachments
     * @property {Long|null} [sentAt] MCMessagePreview sentAt
     * @property {Array.<IMCTextEntities>|null} [textEntities] MCMessagePreview textEntities
     * @property {IMCServiceMessage|null} [serviceMessage] MCMessagePreview serviceMessage
     */

    /**
     * Constructs a new MCMessagePreview.
     * @exports MCMessagePreview
     * @classdesc Represents a MCMessagePreview.
     * @implements IMCMessagePreview
     * @constructor
     * @param {IMCMessagePreview=} [properties] Properties to set
     */
    function MCMessagePreview(properties) {
        this.attachments = [];
        this.textEntities = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMessagePreview localID.
     * @member {Long} localID
     * @memberof MCMessagePreview
     * @instance
     */
    MCMessagePreview.prototype.localID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMessagePreview peer.
     * @member {IMCPeerPreview|null|undefined} peer
     * @memberof MCMessagePreview
     * @instance
     */
    MCMessagePreview.prototype.peer = null;

    /**
     * MCMessagePreview text.
     * @member {string} text
     * @memberof MCMessagePreview
     * @instance
     */
    MCMessagePreview.prototype.text = "";

    /**
     * MCMessagePreview operatorID.
     * @member {Long} operatorID
     * @memberof MCMessagePreview
     * @instance
     */
    MCMessagePreview.prototype.operatorID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMessagePreview attachments.
     * @member {Array.<IMCAttachment>} attachments
     * @memberof MCMessagePreview
     * @instance
     */
    MCMessagePreview.prototype.attachments = $util.emptyArray;

    /**
     * MCMessagePreview sentAt.
     * @member {Long} sentAt
     * @memberof MCMessagePreview
     * @instance
     */
    MCMessagePreview.prototype.sentAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMessagePreview textEntities.
     * @member {Array.<IMCTextEntities>} textEntities
     * @memberof MCMessagePreview
     * @instance
     */
    MCMessagePreview.prototype.textEntities = $util.emptyArray;

    /**
     * MCMessagePreview serviceMessage.
     * @member {IMCServiceMessage|null|undefined} serviceMessage
     * @memberof MCMessagePreview
     * @instance
     */
    MCMessagePreview.prototype.serviceMessage = null;

    /**
     * Creates a new MCMessagePreview instance using the specified properties.
     * @function create
     * @memberof MCMessagePreview
     * @static
     * @param {IMCMessagePreview=} [properties] Properties to set
     * @returns {MCMessagePreview} MCMessagePreview instance
     */
    MCMessagePreview.create = function create(properties) {
        return new MCMessagePreview(properties);
    };

    /**
     * Encodes the specified MCMessagePreview message. Does not implicitly {@link MCMessagePreview.verify|verify} messages.
     * @function encode
     * @memberof MCMessagePreview
     * @static
     * @param {IMCMessagePreview} message MCMessagePreview message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMessagePreview.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.localID != null && Object.hasOwnProperty.call(message, "localID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.localID);
        if (message.peer != null && Object.hasOwnProperty.call(message, "peer"))
            $root.MCPeerPreview.encode(message.peer, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.text != null && Object.hasOwnProperty.call(message, "text"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.text);
        if (message.operatorID != null && Object.hasOwnProperty.call(message, "operatorID"))
            writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.operatorID);
        if (message.attachments != null && message.attachments.length)
            for (let i = 0; i < message.attachments.length; ++i)
                $root.MCAttachment.encode(message.attachments[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.sentAt != null && Object.hasOwnProperty.call(message, "sentAt"))
            writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.sentAt);
        if (message.textEntities != null && message.textEntities.length)
            for (let i = 0; i < message.textEntities.length; ++i)
                $root.MCTextEntities.encode(message.textEntities[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.serviceMessage != null && Object.hasOwnProperty.call(message, "serviceMessage"))
            $root.MCServiceMessage.encode(message.serviceMessage, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMessagePreview message, length delimited. Does not implicitly {@link MCMessagePreview.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMessagePreview
     * @static
     * @param {IMCMessagePreview} message MCMessagePreview message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMessagePreview.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMessagePreview message from the specified reader or buffer.
     * @function decode
     * @memberof MCMessagePreview
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMessagePreview} MCMessagePreview
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMessagePreview.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMessagePreview();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.localID = reader.uint64();
                    break;
                }
            case 2: {
                    message.peer = $root.MCPeerPreview.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.text = reader.string();
                    break;
                }
            case 4: {
                    message.operatorID = reader.uint64();
                    break;
                }
            case 5: {
                    if (!(message.attachments && message.attachments.length))
                        message.attachments = [];
                    message.attachments.push($root.MCAttachment.decode(reader, reader.uint32()));
                    break;
                }
            case 6: {
                    message.sentAt = reader.uint64();
                    break;
                }
            case 7: {
                    if (!(message.textEntities && message.textEntities.length))
                        message.textEntities = [];
                    message.textEntities.push($root.MCTextEntities.decode(reader, reader.uint32()));
                    break;
                }
            case 11: {
                    message.serviceMessage = $root.MCServiceMessage.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMessagePreview message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMessagePreview
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMessagePreview} MCMessagePreview
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMessagePreview.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMessagePreview message.
     * @function verify
     * @memberof MCMessagePreview
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMessagePreview.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.localID != null && message.hasOwnProperty("localID"))
            if (!$util.isInteger(message.localID) && !(message.localID && $util.isInteger(message.localID.low) && $util.isInteger(message.localID.high)))
                return "localID: integer|Long expected";
        if (message.peer != null && message.hasOwnProperty("peer")) {
            let error = $root.MCPeerPreview.verify(message.peer);
            if (error)
                return "peer." + error;
        }
        if (message.text != null && message.hasOwnProperty("text"))
            if (!$util.isString(message.text))
                return "text: string expected";
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (!$util.isInteger(message.operatorID) && !(message.operatorID && $util.isInteger(message.operatorID.low) && $util.isInteger(message.operatorID.high)))
                return "operatorID: integer|Long expected";
        if (message.attachments != null && message.hasOwnProperty("attachments")) {
            if (!Array.isArray(message.attachments))
                return "attachments: array expected";
            for (let i = 0; i < message.attachments.length; ++i) {
                let error = $root.MCAttachment.verify(message.attachments[i]);
                if (error)
                    return "attachments." + error;
            }
        }
        if (message.sentAt != null && message.hasOwnProperty("sentAt"))
            if (!$util.isInteger(message.sentAt) && !(message.sentAt && $util.isInteger(message.sentAt.low) && $util.isInteger(message.sentAt.high)))
                return "sentAt: integer|Long expected";
        if (message.textEntities != null && message.hasOwnProperty("textEntities")) {
            if (!Array.isArray(message.textEntities))
                return "textEntities: array expected";
            for (let i = 0; i < message.textEntities.length; ++i) {
                let error = $root.MCTextEntities.verify(message.textEntities[i]);
                if (error)
                    return "textEntities." + error;
            }
        }
        if (message.serviceMessage != null && message.hasOwnProperty("serviceMessage")) {
            let error = $root.MCServiceMessage.verify(message.serviceMessage);
            if (error)
                return "serviceMessage." + error;
        }
        return null;
    };

    /**
     * Creates a MCMessagePreview message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMessagePreview
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMessagePreview} MCMessagePreview
     */
    MCMessagePreview.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMessagePreview)
            return object;
        let message = new $root.MCMessagePreview();
        if (object.localID != null)
            if ($util.Long)
                (message.localID = $util.Long.fromValue(object.localID)).unsigned = true;
            else if (typeof object.localID === "string")
                message.localID = parseInt(object.localID, 10);
            else if (typeof object.localID === "number")
                message.localID = object.localID;
            else if (typeof object.localID === "object")
                message.localID = new $util.LongBits(object.localID.low >>> 0, object.localID.high >>> 0).toNumber(true);
        if (object.peer != null) {
            if (typeof object.peer !== "object")
                throw TypeError(".MCMessagePreview.peer: object expected");
            message.peer = $root.MCPeerPreview.fromObject(object.peer);
        }
        if (object.text != null)
            message.text = String(object.text);
        if (object.operatorID != null)
            if ($util.Long)
                (message.operatorID = $util.Long.fromValue(object.operatorID)).unsigned = true;
            else if (typeof object.operatorID === "string")
                message.operatorID = parseInt(object.operatorID, 10);
            else if (typeof object.operatorID === "number")
                message.operatorID = object.operatorID;
            else if (typeof object.operatorID === "object")
                message.operatorID = new $util.LongBits(object.operatorID.low >>> 0, object.operatorID.high >>> 0).toNumber(true);
        if (object.attachments) {
            if (!Array.isArray(object.attachments))
                throw TypeError(".MCMessagePreview.attachments: array expected");
            message.attachments = [];
            for (let i = 0; i < object.attachments.length; ++i) {
                if (typeof object.attachments[i] !== "object")
                    throw TypeError(".MCMessagePreview.attachments: object expected");
                message.attachments[i] = $root.MCAttachment.fromObject(object.attachments[i]);
            }
        }
        if (object.sentAt != null)
            if ($util.Long)
                (message.sentAt = $util.Long.fromValue(object.sentAt)).unsigned = true;
            else if (typeof object.sentAt === "string")
                message.sentAt = parseInt(object.sentAt, 10);
            else if (typeof object.sentAt === "number")
                message.sentAt = object.sentAt;
            else if (typeof object.sentAt === "object")
                message.sentAt = new $util.LongBits(object.sentAt.low >>> 0, object.sentAt.high >>> 0).toNumber(true);
        if (object.textEntities) {
            if (!Array.isArray(object.textEntities))
                throw TypeError(".MCMessagePreview.textEntities: array expected");
            message.textEntities = [];
            for (let i = 0; i < object.textEntities.length; ++i) {
                if (typeof object.textEntities[i] !== "object")
                    throw TypeError(".MCMessagePreview.textEntities: object expected");
                message.textEntities[i] = $root.MCTextEntities.fromObject(object.textEntities[i]);
            }
        }
        if (object.serviceMessage != null) {
            if (typeof object.serviceMessage !== "object")
                throw TypeError(".MCMessagePreview.serviceMessage: object expected");
            message.serviceMessage = $root.MCServiceMessage.fromObject(object.serviceMessage);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMessagePreview message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMessagePreview
     * @static
     * @param {MCMessagePreview} message MCMessagePreview
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMessagePreview.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.attachments = [];
            object.textEntities = [];
        }
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.localID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.localID = options.longs === String ? "0" : 0;
            object.peer = null;
            object.text = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.operatorID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.operatorID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.sentAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.sentAt = options.longs === String ? "0" : 0;
            object.serviceMessage = null;
        }
        if (message.localID != null && message.hasOwnProperty("localID"))
            if (typeof message.localID === "number")
                object.localID = options.longs === String ? String(message.localID) : message.localID;
            else
                object.localID = options.longs === String ? $util.Long.prototype.toString.call(message.localID) : options.longs === Number ? new $util.LongBits(message.localID.low >>> 0, message.localID.high >>> 0).toNumber(true) : message.localID;
        if (message.peer != null && message.hasOwnProperty("peer"))
            object.peer = $root.MCPeerPreview.toObject(message.peer, options);
        if (message.text != null && message.hasOwnProperty("text"))
            object.text = message.text;
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (typeof message.operatorID === "number")
                object.operatorID = options.longs === String ? String(message.operatorID) : message.operatorID;
            else
                object.operatorID = options.longs === String ? $util.Long.prototype.toString.call(message.operatorID) : options.longs === Number ? new $util.LongBits(message.operatorID.low >>> 0, message.operatorID.high >>> 0).toNumber(true) : message.operatorID;
        if (message.attachments && message.attachments.length) {
            object.attachments = [];
            for (let j = 0; j < message.attachments.length; ++j)
                object.attachments[j] = $root.MCAttachment.toObject(message.attachments[j], options);
        }
        if (message.sentAt != null && message.hasOwnProperty("sentAt"))
            if (typeof message.sentAt === "number")
                object.sentAt = options.longs === String ? String(message.sentAt) : message.sentAt;
            else
                object.sentAt = options.longs === String ? $util.Long.prototype.toString.call(message.sentAt) : options.longs === Number ? new $util.LongBits(message.sentAt.low >>> 0, message.sentAt.high >>> 0).toNumber(true) : message.sentAt;
        if (message.textEntities && message.textEntities.length) {
            object.textEntities = [];
            for (let j = 0; j < message.textEntities.length; ++j)
                object.textEntities[j] = $root.MCTextEntities.toObject(message.textEntities[j], options);
        }
        if (message.serviceMessage != null && message.hasOwnProperty("serviceMessage"))
            object.serviceMessage = $root.MCServiceMessage.toObject(message.serviceMessage, options);
        return object;
    };

    /**
     * Converts this MCMessagePreview to JSON.
     * @function toJSON
     * @memberof MCMessagePreview
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMessagePreview.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMessagePreview
     * @function getTypeUrl
     * @memberof MCMessagePreview
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMessagePreview.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMessagePreview";
    };

    return MCMessagePreview;
})();

export const Meta = $root.Meta = (() => {

    /**
     * Properties of a Meta.
     * @exports IMeta
     * @interface IMeta
     * @property {Array.<Meta.IMetaOpt>|null} [opts] Meta opts
     */

    /**
     * Constructs a new Meta.
     * @exports Meta
     * @classdesc Represents a Meta.
     * @implements IMeta
     * @constructor
     * @param {IMeta=} [properties] Properties to set
     */
    function Meta(properties) {
        this.opts = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Meta opts.
     * @member {Array.<Meta.IMetaOpt>} opts
     * @memberof Meta
     * @instance
     */
    Meta.prototype.opts = $util.emptyArray;

    /**
     * Creates a new Meta instance using the specified properties.
     * @function create
     * @memberof Meta
     * @static
     * @param {IMeta=} [properties] Properties to set
     * @returns {Meta} Meta instance
     */
    Meta.create = function create(properties) {
        return new Meta(properties);
    };

    /**
     * Encodes the specified Meta message. Does not implicitly {@link Meta.verify|verify} messages.
     * @function encode
     * @memberof Meta
     * @static
     * @param {IMeta} message Meta message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Meta.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.opts != null && message.opts.length)
            for (let i = 0; i < message.opts.length; ++i)
                $root.Meta.MetaOpt.encode(message.opts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Meta message, length delimited. Does not implicitly {@link Meta.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Meta
     * @static
     * @param {IMeta} message Meta message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Meta.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Meta message from the specified reader or buffer.
     * @function decode
     * @memberof Meta
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Meta} Meta
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Meta.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Meta();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.opts && message.opts.length))
                        message.opts = [];
                    message.opts.push($root.Meta.MetaOpt.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Meta message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Meta
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Meta} Meta
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Meta.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Meta message.
     * @function verify
     * @memberof Meta
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Meta.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.opts != null && message.hasOwnProperty("opts")) {
            if (!Array.isArray(message.opts))
                return "opts: array expected";
            for (let i = 0; i < message.opts.length; ++i) {
                let error = $root.Meta.MetaOpt.verify(message.opts[i]);
                if (error)
                    return "opts." + error;
            }
        }
        return null;
    };

    /**
     * Creates a Meta message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Meta
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Meta} Meta
     */
    Meta.fromObject = function fromObject(object) {
        if (object instanceof $root.Meta)
            return object;
        let message = new $root.Meta();
        if (object.opts) {
            if (!Array.isArray(object.opts))
                throw TypeError(".Meta.opts: array expected");
            message.opts = [];
            for (let i = 0; i < object.opts.length; ++i) {
                if (typeof object.opts[i] !== "object")
                    throw TypeError(".Meta.opts: object expected");
                message.opts[i] = $root.Meta.MetaOpt.fromObject(object.opts[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a Meta message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Meta
     * @static
     * @param {Meta} message Meta
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Meta.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.opts = [];
        if (message.opts && message.opts.length) {
            object.opts = [];
            for (let j = 0; j < message.opts.length; ++j)
                object.opts[j] = $root.Meta.MetaOpt.toObject(message.opts[j], options);
        }
        return object;
    };

    /**
     * Converts this Meta to JSON.
     * @function toJSON
     * @memberof Meta
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Meta.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Meta
     * @function getTypeUrl
     * @memberof Meta
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Meta.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Meta";
    };

    Meta.MetaOpt = (function() {

        /**
         * Properties of a MetaOpt.
         * @memberof Meta
         * @interface IMetaOpt
         * @property {string|null} [key] MetaOpt key
         * @property {string|null} [value] MetaOpt value
         */

        /**
         * Constructs a new MetaOpt.
         * @memberof Meta
         * @classdesc Represents a MetaOpt.
         * @implements IMetaOpt
         * @constructor
         * @param {Meta.IMetaOpt=} [properties] Properties to set
         */
        function MetaOpt(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MetaOpt key.
         * @member {string} key
         * @memberof Meta.MetaOpt
         * @instance
         */
        MetaOpt.prototype.key = "";

        /**
         * MetaOpt value.
         * @member {string} value
         * @memberof Meta.MetaOpt
         * @instance
         */
        MetaOpt.prototype.value = "";

        /**
         * Creates a new MetaOpt instance using the specified properties.
         * @function create
         * @memberof Meta.MetaOpt
         * @static
         * @param {Meta.IMetaOpt=} [properties] Properties to set
         * @returns {Meta.MetaOpt} MetaOpt instance
         */
        MetaOpt.create = function create(properties) {
            return new MetaOpt(properties);
        };

        /**
         * Encodes the specified MetaOpt message. Does not implicitly {@link Meta.MetaOpt.verify|verify} messages.
         * @function encode
         * @memberof Meta.MetaOpt
         * @static
         * @param {Meta.IMetaOpt} message MetaOpt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MetaOpt.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            return writer;
        };

        /**
         * Encodes the specified MetaOpt message, length delimited. Does not implicitly {@link Meta.MetaOpt.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Meta.MetaOpt
         * @static
         * @param {Meta.IMetaOpt} message MetaOpt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MetaOpt.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MetaOpt message from the specified reader or buffer.
         * @function decode
         * @memberof Meta.MetaOpt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Meta.MetaOpt} MetaOpt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MetaOpt.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Meta.MetaOpt();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.value = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MetaOpt message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Meta.MetaOpt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Meta.MetaOpt} MetaOpt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MetaOpt.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MetaOpt message.
         * @function verify
         * @memberof Meta.MetaOpt
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MetaOpt.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isString(message.value))
                    return "value: string expected";
            return null;
        };

        /**
         * Creates a MetaOpt message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Meta.MetaOpt
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Meta.MetaOpt} MetaOpt
         */
        MetaOpt.fromObject = function fromObject(object) {
            if (object instanceof $root.Meta.MetaOpt)
                return object;
            let message = new $root.Meta.MetaOpt();
            if (object.key != null)
                message.key = String(object.key);
            if (object.value != null)
                message.value = String(object.value);
            return message;
        };

        /**
         * Creates a plain object from a MetaOpt message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Meta.MetaOpt
         * @static
         * @param {Meta.MetaOpt} message MetaOpt
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MetaOpt.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.value = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };

        /**
         * Converts this MetaOpt to JSON.
         * @function toJSON
         * @memberof Meta.MetaOpt
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MetaOpt.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MetaOpt
         * @function getTypeUrl
         * @memberof Meta.MetaOpt
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MetaOpt.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Meta.MetaOpt";
        };

        return MetaOpt;
    })();

    return Meta;
})();

export const MCPeer = $root.MCPeer = (() => {

    /**
     * Properties of a MCPeer.
     * @exports IMCPeer
     * @interface IMCPeer
     * @property {Long|null} [id] MCPeer id
     * @property {Uint8Array|null} [channelID] MCPeer channelID
     * @property {string|null} [name] MCPeer name
     * @property {IMCAttachment|null} [avatar] MCPeer avatar
     * @property {string|null} [username] MCPeer username
     * @property {Uint8Array|null} [externalID] MCPeer externalID
     * @property {IMeta|null} [meta] MCPeer meta
     */

    /**
     * Constructs a new MCPeer.
     * @exports MCPeer
     * @classdesc Represents a MCPeer.
     * @implements IMCPeer
     * @constructor
     * @param {IMCPeer=} [properties] Properties to set
     */
    function MCPeer(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCPeer id.
     * @member {Long} id
     * @memberof MCPeer
     * @instance
     */
    MCPeer.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCPeer channelID.
     * @member {Uint8Array} channelID
     * @memberof MCPeer
     * @instance
     */
    MCPeer.prototype.channelID = $util.newBuffer([]);

    /**
     * MCPeer name.
     * @member {string} name
     * @memberof MCPeer
     * @instance
     */
    MCPeer.prototype.name = "";

    /**
     * MCPeer avatar.
     * @member {IMCAttachment|null|undefined} avatar
     * @memberof MCPeer
     * @instance
     */
    MCPeer.prototype.avatar = null;

    /**
     * MCPeer username.
     * @member {string} username
     * @memberof MCPeer
     * @instance
     */
    MCPeer.prototype.username = "";

    /**
     * MCPeer externalID.
     * @member {Uint8Array} externalID
     * @memberof MCPeer
     * @instance
     */
    MCPeer.prototype.externalID = $util.newBuffer([]);

    /**
     * MCPeer meta.
     * @member {IMeta|null|undefined} meta
     * @memberof MCPeer
     * @instance
     */
    MCPeer.prototype.meta = null;

    /**
     * Creates a new MCPeer instance using the specified properties.
     * @function create
     * @memberof MCPeer
     * @static
     * @param {IMCPeer=} [properties] Properties to set
     * @returns {MCPeer} MCPeer instance
     */
    MCPeer.create = function create(properties) {
        return new MCPeer(properties);
    };

    /**
     * Encodes the specified MCPeer message. Does not implicitly {@link MCPeer.verify|verify} messages.
     * @function encode
     * @memberof MCPeer
     * @static
     * @param {IMCPeer} message MCPeer message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCPeer.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.channelID);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
        if (message.externalID != null && Object.hasOwnProperty.call(message, "externalID"))
            writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.externalID);
        if (message.username != null && Object.hasOwnProperty.call(message, "username"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.username);
        if (message.meta != null && Object.hasOwnProperty.call(message, "meta"))
            $root.Meta.encode(message.meta, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
            $root.MCAttachment.encode(message.avatar, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCPeer message, length delimited. Does not implicitly {@link MCPeer.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCPeer
     * @static
     * @param {IMCPeer} message MCPeer message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCPeer.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCPeer message from the specified reader or buffer.
     * @function decode
     * @memberof MCPeer
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCPeer} MCPeer
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCPeer.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCPeer();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.uint64();
                    break;
                }
            case 2: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 3: {
                    message.name = reader.string();
                    break;
                }
            case 40: {
                    message.avatar = $root.MCAttachment.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.username = reader.string();
                    break;
                }
            case 5: {
                    message.externalID = reader.bytes();
                    break;
                }
            case 10: {
                    message.meta = $root.Meta.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCPeer message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCPeer
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCPeer} MCPeer
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCPeer.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCPeer message.
     * @function verify
     * @memberof MCPeer
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCPeer.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                return "id: integer|Long expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.avatar != null && message.hasOwnProperty("avatar")) {
            let error = $root.MCAttachment.verify(message.avatar);
            if (error)
                return "avatar." + error;
        }
        if (message.username != null && message.hasOwnProperty("username"))
            if (!$util.isString(message.username))
                return "username: string expected";
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            if (!(message.externalID && typeof message.externalID.length === "number" || $util.isString(message.externalID)))
                return "externalID: buffer expected";
        if (message.meta != null && message.hasOwnProperty("meta")) {
            let error = $root.Meta.verify(message.meta);
            if (error)
                return "meta." + error;
        }
        return null;
    };

    /**
     * Creates a MCPeer message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCPeer
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCPeer} MCPeer
     */
    MCPeer.fromObject = function fromObject(object) {
        if (object instanceof $root.MCPeer)
            return object;
        let message = new $root.MCPeer();
        if (object.id != null)
            if ($util.Long)
                (message.id = $util.Long.fromValue(object.id)).unsigned = true;
            else if (typeof object.id === "string")
                message.id = parseInt(object.id, 10);
            else if (typeof object.id === "number")
                message.id = object.id;
            else if (typeof object.id === "object")
                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.name != null)
            message.name = String(object.name);
        if (object.avatar != null) {
            if (typeof object.avatar !== "object")
                throw TypeError(".MCPeer.avatar: object expected");
            message.avatar = $root.MCAttachment.fromObject(object.avatar);
        }
        if (object.username != null)
            message.username = String(object.username);
        if (object.externalID != null)
            if (typeof object.externalID === "string")
                $util.base64.decode(object.externalID, message.externalID = $util.newBuffer($util.base64.length(object.externalID)), 0);
            else if (object.externalID.length >= 0)
                message.externalID = object.externalID;
        if (object.meta != null) {
            if (typeof object.meta !== "object")
                throw TypeError(".MCPeer.meta: object expected");
            message.meta = $root.Meta.fromObject(object.meta);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCPeer message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCPeer
     * @static
     * @param {MCPeer} message MCPeer
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCPeer.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.id = options.longs === String ? "0" : 0;
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            object.name = "";
            if (options.bytes === String)
                object.externalID = "";
            else {
                object.externalID = [];
                if (options.bytes !== Array)
                    object.externalID = $util.newBuffer(object.externalID);
            }
            object.username = "";
            object.meta = null;
            object.avatar = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            if (typeof message.id === "number")
                object.id = options.longs === String ? String(message.id) : message.id;
            else
                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            object.externalID = options.bytes === String ? $util.base64.encode(message.externalID, 0, message.externalID.length) : options.bytes === Array ? Array.prototype.slice.call(message.externalID) : message.externalID;
        if (message.username != null && message.hasOwnProperty("username"))
            object.username = message.username;
        if (message.meta != null && message.hasOwnProperty("meta"))
            object.meta = $root.Meta.toObject(message.meta, options);
        if (message.avatar != null && message.hasOwnProperty("avatar"))
            object.avatar = $root.MCAttachment.toObject(message.avatar, options);
        return object;
    };

    /**
     * Converts this MCPeer to JSON.
     * @function toJSON
     * @memberof MCPeer
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCPeer.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCPeer
     * @function getTypeUrl
     * @memberof MCPeer
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCPeer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCPeer";
    };

    return MCPeer;
})();

export const MCMessageSuggestion = $root.MCMessageSuggestion = (() => {

    /**
     * Properties of a MCMessageSuggestion.
     * @exports IMCMessageSuggestion
     * @interface IMCMessageSuggestion
     * @property {Uint8Array|null} [id] MCMessageSuggestion id
     * @property {Array.<IMCMessageSuggestionOption>|null} [options] MCMessageSuggestion options
     */

    /**
     * Constructs a new MCMessageSuggestion.
     * @exports MCMessageSuggestion
     * @classdesc Represents a MCMessageSuggestion.
     * @implements IMCMessageSuggestion
     * @constructor
     * @param {IMCMessageSuggestion=} [properties] Properties to set
     */
    function MCMessageSuggestion(properties) {
        this.options = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMessageSuggestion id.
     * @member {Uint8Array} id
     * @memberof MCMessageSuggestion
     * @instance
     */
    MCMessageSuggestion.prototype.id = $util.newBuffer([]);

    /**
     * MCMessageSuggestion options.
     * @member {Array.<IMCMessageSuggestionOption>} options
     * @memberof MCMessageSuggestion
     * @instance
     */
    MCMessageSuggestion.prototype.options = $util.emptyArray;

    /**
     * Creates a new MCMessageSuggestion instance using the specified properties.
     * @function create
     * @memberof MCMessageSuggestion
     * @static
     * @param {IMCMessageSuggestion=} [properties] Properties to set
     * @returns {MCMessageSuggestion} MCMessageSuggestion instance
     */
    MCMessageSuggestion.create = function create(properties) {
        return new MCMessageSuggestion(properties);
    };

    /**
     * Encodes the specified MCMessageSuggestion message. Does not implicitly {@link MCMessageSuggestion.verify|verify} messages.
     * @function encode
     * @memberof MCMessageSuggestion
     * @static
     * @param {IMCMessageSuggestion} message MCMessageSuggestion message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMessageSuggestion.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
        if (message.options != null && message.options.length)
            for (let i = 0; i < message.options.length; ++i)
                $root.MCMessageSuggestionOption.encode(message.options[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCMessageSuggestion message, length delimited. Does not implicitly {@link MCMessageSuggestion.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMessageSuggestion
     * @static
     * @param {IMCMessageSuggestion} message MCMessageSuggestion message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMessageSuggestion.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMessageSuggestion message from the specified reader or buffer.
     * @function decode
     * @memberof MCMessageSuggestion
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMessageSuggestion} MCMessageSuggestion
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMessageSuggestion.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMessageSuggestion();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.bytes();
                    break;
                }
            case 2: {
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.MCMessageSuggestionOption.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMessageSuggestion message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMessageSuggestion
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMessageSuggestion} MCMessageSuggestion
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMessageSuggestion.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMessageSuggestion message.
     * @function verify
     * @memberof MCMessageSuggestion
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMessageSuggestion.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                return "id: buffer expected";
        if (message.options != null && message.hasOwnProperty("options")) {
            if (!Array.isArray(message.options))
                return "options: array expected";
            for (let i = 0; i < message.options.length; ++i) {
                let error = $root.MCMessageSuggestionOption.verify(message.options[i]);
                if (error)
                    return "options." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCMessageSuggestion message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMessageSuggestion
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMessageSuggestion} MCMessageSuggestion
     */
    MCMessageSuggestion.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMessageSuggestion)
            return object;
        let message = new $root.MCMessageSuggestion();
        if (object.id != null)
            if (typeof object.id === "string")
                $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
            else if (object.id.length >= 0)
                message.id = object.id;
        if (object.options) {
            if (!Array.isArray(object.options))
                throw TypeError(".MCMessageSuggestion.options: array expected");
            message.options = [];
            for (let i = 0; i < object.options.length; ++i) {
                if (typeof object.options[i] !== "object")
                    throw TypeError(".MCMessageSuggestion.options: object expected");
                message.options[i] = $root.MCMessageSuggestionOption.fromObject(object.options[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCMessageSuggestion message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMessageSuggestion
     * @static
     * @param {MCMessageSuggestion} message MCMessageSuggestion
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMessageSuggestion.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.options = [];
        if (options.defaults)
            if (options.bytes === String)
                object.id = "";
            else {
                object.id = [];
                if (options.bytes !== Array)
                    object.id = $util.newBuffer(object.id);
            }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
        if (message.options && message.options.length) {
            object.options = [];
            for (let j = 0; j < message.options.length; ++j)
                object.options[j] = $root.MCMessageSuggestionOption.toObject(message.options[j], options);
        }
        return object;
    };

    /**
     * Converts this MCMessageSuggestion to JSON.
     * @function toJSON
     * @memberof MCMessageSuggestion
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMessageSuggestion.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMessageSuggestion
     * @function getTypeUrl
     * @memberof MCMessageSuggestion
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMessageSuggestion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMessageSuggestion";
    };

    return MCMessageSuggestion;
})();

export const MCMessageSuggestionOption = $root.MCMessageSuggestionOption = (() => {

    /**
     * Properties of a MCMessageSuggestionOption.
     * @exports IMCMessageSuggestionOption
     * @interface IMCMessageSuggestionOption
     * @property {string|null} [text] MCMessageSuggestionOption text
     */

    /**
     * Constructs a new MCMessageSuggestionOption.
     * @exports MCMessageSuggestionOption
     * @classdesc Represents a MCMessageSuggestionOption.
     * @implements IMCMessageSuggestionOption
     * @constructor
     * @param {IMCMessageSuggestionOption=} [properties] Properties to set
     */
    function MCMessageSuggestionOption(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMessageSuggestionOption text.
     * @member {string} text
     * @memberof MCMessageSuggestionOption
     * @instance
     */
    MCMessageSuggestionOption.prototype.text = "";

    /**
     * Creates a new MCMessageSuggestionOption instance using the specified properties.
     * @function create
     * @memberof MCMessageSuggestionOption
     * @static
     * @param {IMCMessageSuggestionOption=} [properties] Properties to set
     * @returns {MCMessageSuggestionOption} MCMessageSuggestionOption instance
     */
    MCMessageSuggestionOption.create = function create(properties) {
        return new MCMessageSuggestionOption(properties);
    };

    /**
     * Encodes the specified MCMessageSuggestionOption message. Does not implicitly {@link MCMessageSuggestionOption.verify|verify} messages.
     * @function encode
     * @memberof MCMessageSuggestionOption
     * @static
     * @param {IMCMessageSuggestionOption} message MCMessageSuggestionOption message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMessageSuggestionOption.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.text != null && Object.hasOwnProperty.call(message, "text"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
        return writer;
    };

    /**
     * Encodes the specified MCMessageSuggestionOption message, length delimited. Does not implicitly {@link MCMessageSuggestionOption.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMessageSuggestionOption
     * @static
     * @param {IMCMessageSuggestionOption} message MCMessageSuggestionOption message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMessageSuggestionOption.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMessageSuggestionOption message from the specified reader or buffer.
     * @function decode
     * @memberof MCMessageSuggestionOption
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMessageSuggestionOption} MCMessageSuggestionOption
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMessageSuggestionOption.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMessageSuggestionOption();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.text = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMessageSuggestionOption message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMessageSuggestionOption
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMessageSuggestionOption} MCMessageSuggestionOption
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMessageSuggestionOption.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMessageSuggestionOption message.
     * @function verify
     * @memberof MCMessageSuggestionOption
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMessageSuggestionOption.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.text != null && message.hasOwnProperty("text"))
            if (!$util.isString(message.text))
                return "text: string expected";
        return null;
    };

    /**
     * Creates a MCMessageSuggestionOption message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMessageSuggestionOption
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMessageSuggestionOption} MCMessageSuggestionOption
     */
    MCMessageSuggestionOption.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMessageSuggestionOption)
            return object;
        let message = new $root.MCMessageSuggestionOption();
        if (object.text != null)
            message.text = String(object.text);
        return message;
    };

    /**
     * Creates a plain object from a MCMessageSuggestionOption message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMessageSuggestionOption
     * @static
     * @param {MCMessageSuggestionOption} message MCMessageSuggestionOption
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMessageSuggestionOption.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.text = "";
        if (message.text != null && message.hasOwnProperty("text"))
            object.text = message.text;
        return object;
    };

    /**
     * Converts this MCMessageSuggestionOption to JSON.
     * @function toJSON
     * @memberof MCMessageSuggestionOption
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMessageSuggestionOption.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMessageSuggestionOption
     * @function getTypeUrl
     * @memberof MCMessageSuggestionOption
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMessageSuggestionOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMessageSuggestionOption";
    };

    return MCMessageSuggestionOption;
})();

export const MCPeerPreview = $root.MCPeerPreview = (() => {

    /**
     * Properties of a MCPeerPreview.
     * @exports IMCPeerPreview
     * @interface IMCPeerPreview
     * @property {Long|null} [id] MCPeerPreview id
     * @property {string|null} [name] MCPeerPreview name
     * @property {IMCAttachment|null} [avatar] MCPeerPreview avatar
     * @property {string|null} [username] MCPeerPreview username
     * @property {Uint8Array|null} [externalID] MCPeerPreview externalID
     */

    /**
     * Constructs a new MCPeerPreview.
     * @exports MCPeerPreview
     * @classdesc Represents a MCPeerPreview.
     * @implements IMCPeerPreview
     * @constructor
     * @param {IMCPeerPreview=} [properties] Properties to set
     */
    function MCPeerPreview(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCPeerPreview id.
     * @member {Long} id
     * @memberof MCPeerPreview
     * @instance
     */
    MCPeerPreview.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCPeerPreview name.
     * @member {string} name
     * @memberof MCPeerPreview
     * @instance
     */
    MCPeerPreview.prototype.name = "";

    /**
     * MCPeerPreview avatar.
     * @member {IMCAttachment|null|undefined} avatar
     * @memberof MCPeerPreview
     * @instance
     */
    MCPeerPreview.prototype.avatar = null;

    /**
     * MCPeerPreview username.
     * @member {string} username
     * @memberof MCPeerPreview
     * @instance
     */
    MCPeerPreview.prototype.username = "";

    /**
     * MCPeerPreview externalID.
     * @member {Uint8Array} externalID
     * @memberof MCPeerPreview
     * @instance
     */
    MCPeerPreview.prototype.externalID = $util.newBuffer([]);

    /**
     * Creates a new MCPeerPreview instance using the specified properties.
     * @function create
     * @memberof MCPeerPreview
     * @static
     * @param {IMCPeerPreview=} [properties] Properties to set
     * @returns {MCPeerPreview} MCPeerPreview instance
     */
    MCPeerPreview.create = function create(properties) {
        return new MCPeerPreview(properties);
    };

    /**
     * Encodes the specified MCPeerPreview message. Does not implicitly {@link MCPeerPreview.verify|verify} messages.
     * @function encode
     * @memberof MCPeerPreview
     * @static
     * @param {IMCPeerPreview} message MCPeerPreview message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCPeerPreview.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.externalID != null && Object.hasOwnProperty.call(message, "externalID"))
            writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.externalID);
        if (message.username != null && Object.hasOwnProperty.call(message, "username"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.username);
        if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
            $root.MCAttachment.encode(message.avatar, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCPeerPreview message, length delimited. Does not implicitly {@link MCPeerPreview.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCPeerPreview
     * @static
     * @param {IMCPeerPreview} message MCPeerPreview message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCPeerPreview.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCPeerPreview message from the specified reader or buffer.
     * @function decode
     * @memberof MCPeerPreview
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCPeerPreview} MCPeerPreview
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCPeerPreview.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCPeerPreview();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.uint64();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            case 30: {
                    message.avatar = $root.MCAttachment.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.username = reader.string();
                    break;
                }
            case 4: {
                    message.externalID = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCPeerPreview message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCPeerPreview
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCPeerPreview} MCPeerPreview
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCPeerPreview.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCPeerPreview message.
     * @function verify
     * @memberof MCPeerPreview
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCPeerPreview.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                return "id: integer|Long expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.avatar != null && message.hasOwnProperty("avatar")) {
            let error = $root.MCAttachment.verify(message.avatar);
            if (error)
                return "avatar." + error;
        }
        if (message.username != null && message.hasOwnProperty("username"))
            if (!$util.isString(message.username))
                return "username: string expected";
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            if (!(message.externalID && typeof message.externalID.length === "number" || $util.isString(message.externalID)))
                return "externalID: buffer expected";
        return null;
    };

    /**
     * Creates a MCPeerPreview message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCPeerPreview
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCPeerPreview} MCPeerPreview
     */
    MCPeerPreview.fromObject = function fromObject(object) {
        if (object instanceof $root.MCPeerPreview)
            return object;
        let message = new $root.MCPeerPreview();
        if (object.id != null)
            if ($util.Long)
                (message.id = $util.Long.fromValue(object.id)).unsigned = true;
            else if (typeof object.id === "string")
                message.id = parseInt(object.id, 10);
            else if (typeof object.id === "number")
                message.id = object.id;
            else if (typeof object.id === "object")
                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
        if (object.name != null)
            message.name = String(object.name);
        if (object.avatar != null) {
            if (typeof object.avatar !== "object")
                throw TypeError(".MCPeerPreview.avatar: object expected");
            message.avatar = $root.MCAttachment.fromObject(object.avatar);
        }
        if (object.username != null)
            message.username = String(object.username);
        if (object.externalID != null)
            if (typeof object.externalID === "string")
                $util.base64.decode(object.externalID, message.externalID = $util.newBuffer($util.base64.length(object.externalID)), 0);
            else if (object.externalID.length >= 0)
                message.externalID = object.externalID;
        return message;
    };

    /**
     * Creates a plain object from a MCPeerPreview message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCPeerPreview
     * @static
     * @param {MCPeerPreview} message MCPeerPreview
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCPeerPreview.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.id = options.longs === String ? "0" : 0;
            object.name = "";
            if (options.bytes === String)
                object.externalID = "";
            else {
                object.externalID = [];
                if (options.bytes !== Array)
                    object.externalID = $util.newBuffer(object.externalID);
            }
            object.username = "";
            object.avatar = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            if (typeof message.id === "number")
                object.id = options.longs === String ? String(message.id) : message.id;
            else
                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            object.externalID = options.bytes === String ? $util.base64.encode(message.externalID, 0, message.externalID.length) : options.bytes === Array ? Array.prototype.slice.call(message.externalID) : message.externalID;
        if (message.username != null && message.hasOwnProperty("username"))
            object.username = message.username;
        if (message.avatar != null && message.hasOwnProperty("avatar"))
            object.avatar = $root.MCAttachment.toObject(message.avatar, options);
        return object;
    };

    /**
     * Converts this MCPeerPreview to JSON.
     * @function toJSON
     * @memberof MCPeerPreview
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCPeerPreview.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCPeerPreview
     * @function getTypeUrl
     * @memberof MCPeerPreview
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCPeerPreview.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCPeerPreview";
    };

    return MCPeerPreview;
})();

export const MCChat = $root.MCChat = (() => {

    /**
     * Properties of a MCChat.
     * @exports IMCChat
     * @interface IMCChat
     * @property {Long|null} [id] MCChat id
     * @property {Uint8Array|null} [externalID] MCChat externalID
     * @property {Uint8Array|null} [channelID] MCChat channelID
     * @property {Uint8Array|null} [workspaceID] MCChat workspaceID
     * @property {string|null} [name] MCChat name
     * @property {string|null} [alternativeName] MCChat alternativeName
     * @property {boolean|null} [showAlternativeName] MCChat showAlternativeName
     * @property {string|null} [username] MCChat username
     * @property {IMCAttachment|null} [avatar] MCChat avatar
     * @property {Long|null} [inReadPointer] MCChat inReadPointer
     * @property {Long|null} [outReadPointer] MCChat outReadPointer
     * @property {Long|null} [messagesExcludedFromUnreadRange] MCChat messagesExcludedFromUnreadRange
     * @property {Long|null} [lastMessageSentAt] MCChat lastMessageSentAt
     * @property {string|null} [inviteLink] MCChat inviteLink
     * @property {MCChat.Type|null} [type] MCChat type
     * @property {Long|null} [directChatWithPeer] MCChat directChatWithPeer
     * @property {Uint8Array|null} [directChatWithPeerExternalID] MCChat directChatWithPeerExternalID
     * @property {IMCMessagePreview|null} [lastMessage] MCChat lastMessage
     * @property {MCChat.IPrivileges|null} [privileges] MCChat privileges
     * @property {Array.<MCChat.IAttachmentGroupSize>|null} [attachmentGroupSizes] MCChat attachmentGroupSizes
     * @property {Array.<IMCChatRestriction>|null} [restrictions] MCChat restrictions
     * @property {MCChat.MCChatStatus|null} [status] MCChat status
     * @property {Long|null} [statusSetBy] MCChat statusSetBy
     * @property {Long|null} [statusSetAt] MCChat statusSetAt
     * @property {Array.<Long>|null} [joinedOperators] MCChat joinedOperators
     * @property {IMeta|null} [meta] MCChat meta
     * @property {IMCMessageSuggestion|null} [nextOperatorMessageSuggestion] MCChat nextOperatorMessageSuggestion
     */

    /**
     * Constructs a new MCChat.
     * @exports MCChat
     * @classdesc Represents a MCChat.
     * @implements IMCChat
     * @constructor
     * @param {IMCChat=} [properties] Properties to set
     */
    function MCChat(properties) {
        this.attachmentGroupSizes = [];
        this.restrictions = [];
        this.joinedOperators = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCChat id.
     * @member {Long} id
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChat externalID.
     * @member {Uint8Array} externalID
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.externalID = $util.newBuffer([]);

    /**
     * MCChat channelID.
     * @member {Uint8Array} channelID
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.channelID = $util.newBuffer([]);

    /**
     * MCChat workspaceID.
     * @member {Uint8Array} workspaceID
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.workspaceID = $util.newBuffer([]);

    /**
     * MCChat name.
     * @member {string} name
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.name = "";

    /**
     * MCChat alternativeName.
     * @member {string} alternativeName
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.alternativeName = "";

    /**
     * MCChat showAlternativeName.
     * @member {boolean} showAlternativeName
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.showAlternativeName = false;

    /**
     * MCChat username.
     * @member {string} username
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.username = "";

    /**
     * MCChat avatar.
     * @member {IMCAttachment|null|undefined} avatar
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.avatar = null;

    /**
     * MCChat inReadPointer.
     * @member {Long} inReadPointer
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.inReadPointer = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChat outReadPointer.
     * @member {Long} outReadPointer
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.outReadPointer = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChat messagesExcludedFromUnreadRange.
     * @member {Long} messagesExcludedFromUnreadRange
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.messagesExcludedFromUnreadRange = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChat lastMessageSentAt.
     * @member {Long} lastMessageSentAt
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.lastMessageSentAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChat inviteLink.
     * @member {string} inviteLink
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.inviteLink = "";

    /**
     * MCChat type.
     * @member {MCChat.Type} type
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.type = 0;

    /**
     * MCChat directChatWithPeer.
     * @member {Long} directChatWithPeer
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.directChatWithPeer = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChat directChatWithPeerExternalID.
     * @member {Uint8Array} directChatWithPeerExternalID
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.directChatWithPeerExternalID = $util.newBuffer([]);

    /**
     * MCChat lastMessage.
     * @member {IMCMessagePreview|null|undefined} lastMessage
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.lastMessage = null;

    /**
     * MCChat privileges.
     * @member {MCChat.IPrivileges|null|undefined} privileges
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.privileges = null;

    /**
     * MCChat attachmentGroupSizes.
     * @member {Array.<MCChat.IAttachmentGroupSize>} attachmentGroupSizes
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.attachmentGroupSizes = $util.emptyArray;

    /**
     * MCChat restrictions.
     * @member {Array.<IMCChatRestriction>} restrictions
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.restrictions = $util.emptyArray;

    /**
     * MCChat status.
     * @member {MCChat.MCChatStatus} status
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.status = 0;

    /**
     * MCChat statusSetBy.
     * @member {Long} statusSetBy
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.statusSetBy = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChat statusSetAt.
     * @member {Long} statusSetAt
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.statusSetAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChat joinedOperators.
     * @member {Array.<Long>} joinedOperators
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.joinedOperators = $util.emptyArray;

    /**
     * MCChat meta.
     * @member {IMeta|null|undefined} meta
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.meta = null;

    /**
     * MCChat nextOperatorMessageSuggestion.
     * @member {IMCMessageSuggestion|null|undefined} nextOperatorMessageSuggestion
     * @memberof MCChat
     * @instance
     */
    MCChat.prototype.nextOperatorMessageSuggestion = null;

    /**
     * Creates a new MCChat instance using the specified properties.
     * @function create
     * @memberof MCChat
     * @static
     * @param {IMCChat=} [properties] Properties to set
     * @returns {MCChat} MCChat instance
     */
    MCChat.create = function create(properties) {
        return new MCChat(properties);
    };

    /**
     * Encodes the specified MCChat message. Does not implicitly {@link MCChat.verify|verify} messages.
     * @function encode
     * @memberof MCChat
     * @static
     * @param {IMCChat} message MCChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChat.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.channelID);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
        if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
            $root.MCAttachment.encode(message.avatar, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.inReadPointer != null && Object.hasOwnProperty.call(message, "inReadPointer"))
            writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.inReadPointer);
        if (message.outReadPointer != null && Object.hasOwnProperty.call(message, "outReadPointer"))
            writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.outReadPointer);
        if (message.lastMessageSentAt != null && Object.hasOwnProperty.call(message, "lastMessageSentAt"))
            writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.lastMessageSentAt);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.type);
        if (message.externalID != null && Object.hasOwnProperty.call(message, "externalID"))
            writer.uint32(/* id 9, wireType 2 =*/74).bytes(message.externalID);
        if (message.lastMessage != null && Object.hasOwnProperty.call(message, "lastMessage"))
            $root.MCMessagePreview.encode(message.lastMessage, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.alternativeName != null && Object.hasOwnProperty.call(message, "alternativeName"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.alternativeName);
        if (message.showAlternativeName != null && Object.hasOwnProperty.call(message, "showAlternativeName"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.showAlternativeName);
        if (message.username != null && Object.hasOwnProperty.call(message, "username"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.username);
        if (message.privileges != null && Object.hasOwnProperty.call(message, "privileges"))
            $root.MCChat.Privileges.encode(message.privileges, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
        if (message.inviteLink != null && Object.hasOwnProperty.call(message, "inviteLink"))
            writer.uint32(/* id 15, wireType 2 =*/122).string(message.inviteLink);
        if (message.attachmentGroupSizes != null && message.attachmentGroupSizes.length)
            for (let i = 0; i < message.attachmentGroupSizes.length; ++i)
                $root.MCChat.AttachmentGroupSize.encode(message.attachmentGroupSizes[i], writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
        if (message.restrictions != null && message.restrictions.length)
            for (let i = 0; i < message.restrictions.length; ++i)
                $root.MCChatRestriction.encode(message.restrictions[i], writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(/* id 18, wireType 0 =*/144).int32(message.status);
        if (message.statusSetBy != null && Object.hasOwnProperty.call(message, "statusSetBy"))
            writer.uint32(/* id 19, wireType 0 =*/152).uint64(message.statusSetBy);
        if (message.statusSetAt != null && Object.hasOwnProperty.call(message, "statusSetAt"))
            writer.uint32(/* id 20, wireType 0 =*/160).uint64(message.statusSetAt);
        if (message.joinedOperators != null && message.joinedOperators.length) {
            writer.uint32(/* id 21, wireType 2 =*/170).fork();
            for (let i = 0; i < message.joinedOperators.length; ++i)
                writer.uint64(message.joinedOperators[i]);
            writer.ldelim();
        }
        if (message.directChatWithPeer != null && Object.hasOwnProperty.call(message, "directChatWithPeer"))
            writer.uint32(/* id 22, wireType 0 =*/176).uint64(message.directChatWithPeer);
        if (message.messagesExcludedFromUnreadRange != null && Object.hasOwnProperty.call(message, "messagesExcludedFromUnreadRange"))
            writer.uint32(/* id 23, wireType 0 =*/184).uint64(message.messagesExcludedFromUnreadRange);
        if (message.directChatWithPeerExternalID != null && Object.hasOwnProperty.call(message, "directChatWithPeerExternalID"))
            writer.uint32(/* id 24, wireType 2 =*/194).bytes(message.directChatWithPeerExternalID);
        if (message.meta != null && Object.hasOwnProperty.call(message, "meta"))
            $root.Meta.encode(message.meta, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
        if (message.nextOperatorMessageSuggestion != null && Object.hasOwnProperty.call(message, "nextOperatorMessageSuggestion"))
            $root.MCMessageSuggestion.encode(message.nextOperatorMessageSuggestion, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
        if (message.workspaceID != null && Object.hasOwnProperty.call(message, "workspaceID"))
            writer.uint32(/* id 33, wireType 2 =*/266).bytes(message.workspaceID);
        return writer;
    };

    /**
     * Encodes the specified MCChat message, length delimited. Does not implicitly {@link MCChat.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCChat
     * @static
     * @param {IMCChat} message MCChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChat.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCChat message from the specified reader or buffer.
     * @function decode
     * @memberof MCChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCChat} MCChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChat.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCChat();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.uint64();
                    break;
                }
            case 9: {
                    message.externalID = reader.bytes();
                    break;
                }
            case 2: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 33: {
                    message.workspaceID = reader.bytes();
                    break;
                }
            case 3: {
                    message.name = reader.string();
                    break;
                }
            case 11: {
                    message.alternativeName = reader.string();
                    break;
                }
            case 12: {
                    message.showAlternativeName = reader.bool();
                    break;
                }
            case 13: {
                    message.username = reader.string();
                    break;
                }
            case 4: {
                    message.avatar = $root.MCAttachment.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.inReadPointer = reader.uint64();
                    break;
                }
            case 6: {
                    message.outReadPointer = reader.uint64();
                    break;
                }
            case 23: {
                    message.messagesExcludedFromUnreadRange = reader.uint64();
                    break;
                }
            case 7: {
                    message.lastMessageSentAt = reader.uint64();
                    break;
                }
            case 15: {
                    message.inviteLink = reader.string();
                    break;
                }
            case 8: {
                    message.type = reader.int32();
                    break;
                }
            case 22: {
                    message.directChatWithPeer = reader.uint64();
                    break;
                }
            case 24: {
                    message.directChatWithPeerExternalID = reader.bytes();
                    break;
                }
            case 10: {
                    message.lastMessage = $root.MCMessagePreview.decode(reader, reader.uint32());
                    break;
                }
            case 14: {
                    message.privileges = $root.MCChat.Privileges.decode(reader, reader.uint32());
                    break;
                }
            case 16: {
                    if (!(message.attachmentGroupSizes && message.attachmentGroupSizes.length))
                        message.attachmentGroupSizes = [];
                    message.attachmentGroupSizes.push($root.MCChat.AttachmentGroupSize.decode(reader, reader.uint32()));
                    break;
                }
            case 17: {
                    if (!(message.restrictions && message.restrictions.length))
                        message.restrictions = [];
                    message.restrictions.push($root.MCChatRestriction.decode(reader, reader.uint32()));
                    break;
                }
            case 18: {
                    message.status = reader.int32();
                    break;
                }
            case 19: {
                    message.statusSetBy = reader.uint64();
                    break;
                }
            case 20: {
                    message.statusSetAt = reader.uint64();
                    break;
                }
            case 21: {
                    if (!(message.joinedOperators && message.joinedOperators.length))
                        message.joinedOperators = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.joinedOperators.push(reader.uint64());
                    } else
                        message.joinedOperators.push(reader.uint64());
                    break;
                }
            case 30: {
                    message.meta = $root.Meta.decode(reader, reader.uint32());
                    break;
                }
            case 32: {
                    message.nextOperatorMessageSuggestion = $root.MCMessageSuggestion.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCChat message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCChat} MCChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChat.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCChat message.
     * @function verify
     * @memberof MCChat
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCChat.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                return "id: integer|Long expected";
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            if (!(message.externalID && typeof message.externalID.length === "number" || $util.isString(message.externalID)))
                return "externalID: buffer expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.workspaceID != null && message.hasOwnProperty("workspaceID"))
            if (!(message.workspaceID && typeof message.workspaceID.length === "number" || $util.isString(message.workspaceID)))
                return "workspaceID: buffer expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.alternativeName != null && message.hasOwnProperty("alternativeName"))
            if (!$util.isString(message.alternativeName))
                return "alternativeName: string expected";
        if (message.showAlternativeName != null && message.hasOwnProperty("showAlternativeName"))
            if (typeof message.showAlternativeName !== "boolean")
                return "showAlternativeName: boolean expected";
        if (message.username != null && message.hasOwnProperty("username"))
            if (!$util.isString(message.username))
                return "username: string expected";
        if (message.avatar != null && message.hasOwnProperty("avatar")) {
            let error = $root.MCAttachment.verify(message.avatar);
            if (error)
                return "avatar." + error;
        }
        if (message.inReadPointer != null && message.hasOwnProperty("inReadPointer"))
            if (!$util.isInteger(message.inReadPointer) && !(message.inReadPointer && $util.isInteger(message.inReadPointer.low) && $util.isInteger(message.inReadPointer.high)))
                return "inReadPointer: integer|Long expected";
        if (message.outReadPointer != null && message.hasOwnProperty("outReadPointer"))
            if (!$util.isInteger(message.outReadPointer) && !(message.outReadPointer && $util.isInteger(message.outReadPointer.low) && $util.isInteger(message.outReadPointer.high)))
                return "outReadPointer: integer|Long expected";
        if (message.messagesExcludedFromUnreadRange != null && message.hasOwnProperty("messagesExcludedFromUnreadRange"))
            if (!$util.isInteger(message.messagesExcludedFromUnreadRange) && !(message.messagesExcludedFromUnreadRange && $util.isInteger(message.messagesExcludedFromUnreadRange.low) && $util.isInteger(message.messagesExcludedFromUnreadRange.high)))
                return "messagesExcludedFromUnreadRange: integer|Long expected";
        if (message.lastMessageSentAt != null && message.hasOwnProperty("lastMessageSentAt"))
            if (!$util.isInteger(message.lastMessageSentAt) && !(message.lastMessageSentAt && $util.isInteger(message.lastMessageSentAt.low) && $util.isInteger(message.lastMessageSentAt.high)))
                return "lastMessageSentAt: integer|Long expected";
        if (message.inviteLink != null && message.hasOwnProperty("inviteLink"))
            if (!$util.isString(message.inviteLink))
                return "inviteLink: string expected";
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message.directChatWithPeer != null && message.hasOwnProperty("directChatWithPeer"))
            if (!$util.isInteger(message.directChatWithPeer) && !(message.directChatWithPeer && $util.isInteger(message.directChatWithPeer.low) && $util.isInteger(message.directChatWithPeer.high)))
                return "directChatWithPeer: integer|Long expected";
        if (message.directChatWithPeerExternalID != null && message.hasOwnProperty("directChatWithPeerExternalID"))
            if (!(message.directChatWithPeerExternalID && typeof message.directChatWithPeerExternalID.length === "number" || $util.isString(message.directChatWithPeerExternalID)))
                return "directChatWithPeerExternalID: buffer expected";
        if (message.lastMessage != null && message.hasOwnProperty("lastMessage")) {
            let error = $root.MCMessagePreview.verify(message.lastMessage);
            if (error)
                return "lastMessage." + error;
        }
        if (message.privileges != null && message.hasOwnProperty("privileges")) {
            let error = $root.MCChat.Privileges.verify(message.privileges);
            if (error)
                return "privileges." + error;
        }
        if (message.attachmentGroupSizes != null && message.hasOwnProperty("attachmentGroupSizes")) {
            if (!Array.isArray(message.attachmentGroupSizes))
                return "attachmentGroupSizes: array expected";
            for (let i = 0; i < message.attachmentGroupSizes.length; ++i) {
                let error = $root.MCChat.AttachmentGroupSize.verify(message.attachmentGroupSizes[i]);
                if (error)
                    return "attachmentGroupSizes." + error;
            }
        }
        if (message.restrictions != null && message.hasOwnProperty("restrictions")) {
            if (!Array.isArray(message.restrictions))
                return "restrictions: array expected";
            for (let i = 0; i < message.restrictions.length; ++i) {
                let error = $root.MCChatRestriction.verify(message.restrictions[i]);
                if (error)
                    return "restrictions." + error;
            }
        }
        if (message.status != null && message.hasOwnProperty("status"))
            switch (message.status) {
            default:
                return "status: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.statusSetBy != null && message.hasOwnProperty("statusSetBy"))
            if (!$util.isInteger(message.statusSetBy) && !(message.statusSetBy && $util.isInteger(message.statusSetBy.low) && $util.isInteger(message.statusSetBy.high)))
                return "statusSetBy: integer|Long expected";
        if (message.statusSetAt != null && message.hasOwnProperty("statusSetAt"))
            if (!$util.isInteger(message.statusSetAt) && !(message.statusSetAt && $util.isInteger(message.statusSetAt.low) && $util.isInteger(message.statusSetAt.high)))
                return "statusSetAt: integer|Long expected";
        if (message.joinedOperators != null && message.hasOwnProperty("joinedOperators")) {
            if (!Array.isArray(message.joinedOperators))
                return "joinedOperators: array expected";
            for (let i = 0; i < message.joinedOperators.length; ++i)
                if (!$util.isInteger(message.joinedOperators[i]) && !(message.joinedOperators[i] && $util.isInteger(message.joinedOperators[i].low) && $util.isInteger(message.joinedOperators[i].high)))
                    return "joinedOperators: integer|Long[] expected";
        }
        if (message.meta != null && message.hasOwnProperty("meta")) {
            let error = $root.Meta.verify(message.meta);
            if (error)
                return "meta." + error;
        }
        if (message.nextOperatorMessageSuggestion != null && message.hasOwnProperty("nextOperatorMessageSuggestion")) {
            let error = $root.MCMessageSuggestion.verify(message.nextOperatorMessageSuggestion);
            if (error)
                return "nextOperatorMessageSuggestion." + error;
        }
        return null;
    };

    /**
     * Creates a MCChat message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCChat
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCChat} MCChat
     */
    MCChat.fromObject = function fromObject(object) {
        if (object instanceof $root.MCChat)
            return object;
        let message = new $root.MCChat();
        if (object.id != null)
            if ($util.Long)
                (message.id = $util.Long.fromValue(object.id)).unsigned = true;
            else if (typeof object.id === "string")
                message.id = parseInt(object.id, 10);
            else if (typeof object.id === "number")
                message.id = object.id;
            else if (typeof object.id === "object")
                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
        if (object.externalID != null)
            if (typeof object.externalID === "string")
                $util.base64.decode(object.externalID, message.externalID = $util.newBuffer($util.base64.length(object.externalID)), 0);
            else if (object.externalID.length >= 0)
                message.externalID = object.externalID;
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.workspaceID != null)
            if (typeof object.workspaceID === "string")
                $util.base64.decode(object.workspaceID, message.workspaceID = $util.newBuffer($util.base64.length(object.workspaceID)), 0);
            else if (object.workspaceID.length >= 0)
                message.workspaceID = object.workspaceID;
        if (object.name != null)
            message.name = String(object.name);
        if (object.alternativeName != null)
            message.alternativeName = String(object.alternativeName);
        if (object.showAlternativeName != null)
            message.showAlternativeName = Boolean(object.showAlternativeName);
        if (object.username != null)
            message.username = String(object.username);
        if (object.avatar != null) {
            if (typeof object.avatar !== "object")
                throw TypeError(".MCChat.avatar: object expected");
            message.avatar = $root.MCAttachment.fromObject(object.avatar);
        }
        if (object.inReadPointer != null)
            if ($util.Long)
                (message.inReadPointer = $util.Long.fromValue(object.inReadPointer)).unsigned = true;
            else if (typeof object.inReadPointer === "string")
                message.inReadPointer = parseInt(object.inReadPointer, 10);
            else if (typeof object.inReadPointer === "number")
                message.inReadPointer = object.inReadPointer;
            else if (typeof object.inReadPointer === "object")
                message.inReadPointer = new $util.LongBits(object.inReadPointer.low >>> 0, object.inReadPointer.high >>> 0).toNumber(true);
        if (object.outReadPointer != null)
            if ($util.Long)
                (message.outReadPointer = $util.Long.fromValue(object.outReadPointer)).unsigned = true;
            else if (typeof object.outReadPointer === "string")
                message.outReadPointer = parseInt(object.outReadPointer, 10);
            else if (typeof object.outReadPointer === "number")
                message.outReadPointer = object.outReadPointer;
            else if (typeof object.outReadPointer === "object")
                message.outReadPointer = new $util.LongBits(object.outReadPointer.low >>> 0, object.outReadPointer.high >>> 0).toNumber(true);
        if (object.messagesExcludedFromUnreadRange != null)
            if ($util.Long)
                (message.messagesExcludedFromUnreadRange = $util.Long.fromValue(object.messagesExcludedFromUnreadRange)).unsigned = true;
            else if (typeof object.messagesExcludedFromUnreadRange === "string")
                message.messagesExcludedFromUnreadRange = parseInt(object.messagesExcludedFromUnreadRange, 10);
            else if (typeof object.messagesExcludedFromUnreadRange === "number")
                message.messagesExcludedFromUnreadRange = object.messagesExcludedFromUnreadRange;
            else if (typeof object.messagesExcludedFromUnreadRange === "object")
                message.messagesExcludedFromUnreadRange = new $util.LongBits(object.messagesExcludedFromUnreadRange.low >>> 0, object.messagesExcludedFromUnreadRange.high >>> 0).toNumber(true);
        if (object.lastMessageSentAt != null)
            if ($util.Long)
                (message.lastMessageSentAt = $util.Long.fromValue(object.lastMessageSentAt)).unsigned = true;
            else if (typeof object.lastMessageSentAt === "string")
                message.lastMessageSentAt = parseInt(object.lastMessageSentAt, 10);
            else if (typeof object.lastMessageSentAt === "number")
                message.lastMessageSentAt = object.lastMessageSentAt;
            else if (typeof object.lastMessageSentAt === "object")
                message.lastMessageSentAt = new $util.LongBits(object.lastMessageSentAt.low >>> 0, object.lastMessageSentAt.high >>> 0).toNumber(true);
        if (object.inviteLink != null)
            message.inviteLink = String(object.inviteLink);
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "UNKNOWN":
        case 0:
            message.type = 0;
            break;
        case "PRIVATE":
        case 1:
            message.type = 1;
            break;
        case "GROUP":
        case 2:
            message.type = 2;
            break;
        case "CHANNEL":
        case 3:
            message.type = 3;
            break;
        case "SUPERGROUP":
        case 4:
            message.type = 4;
            break;
        }
        if (object.directChatWithPeer != null)
            if ($util.Long)
                (message.directChatWithPeer = $util.Long.fromValue(object.directChatWithPeer)).unsigned = true;
            else if (typeof object.directChatWithPeer === "string")
                message.directChatWithPeer = parseInt(object.directChatWithPeer, 10);
            else if (typeof object.directChatWithPeer === "number")
                message.directChatWithPeer = object.directChatWithPeer;
            else if (typeof object.directChatWithPeer === "object")
                message.directChatWithPeer = new $util.LongBits(object.directChatWithPeer.low >>> 0, object.directChatWithPeer.high >>> 0).toNumber(true);
        if (object.directChatWithPeerExternalID != null)
            if (typeof object.directChatWithPeerExternalID === "string")
                $util.base64.decode(object.directChatWithPeerExternalID, message.directChatWithPeerExternalID = $util.newBuffer($util.base64.length(object.directChatWithPeerExternalID)), 0);
            else if (object.directChatWithPeerExternalID.length >= 0)
                message.directChatWithPeerExternalID = object.directChatWithPeerExternalID;
        if (object.lastMessage != null) {
            if (typeof object.lastMessage !== "object")
                throw TypeError(".MCChat.lastMessage: object expected");
            message.lastMessage = $root.MCMessagePreview.fromObject(object.lastMessage);
        }
        if (object.privileges != null) {
            if (typeof object.privileges !== "object")
                throw TypeError(".MCChat.privileges: object expected");
            message.privileges = $root.MCChat.Privileges.fromObject(object.privileges);
        }
        if (object.attachmentGroupSizes) {
            if (!Array.isArray(object.attachmentGroupSizes))
                throw TypeError(".MCChat.attachmentGroupSizes: array expected");
            message.attachmentGroupSizes = [];
            for (let i = 0; i < object.attachmentGroupSizes.length; ++i) {
                if (typeof object.attachmentGroupSizes[i] !== "object")
                    throw TypeError(".MCChat.attachmentGroupSizes: object expected");
                message.attachmentGroupSizes[i] = $root.MCChat.AttachmentGroupSize.fromObject(object.attachmentGroupSizes[i]);
            }
        }
        if (object.restrictions) {
            if (!Array.isArray(object.restrictions))
                throw TypeError(".MCChat.restrictions: array expected");
            message.restrictions = [];
            for (let i = 0; i < object.restrictions.length; ++i) {
                if (typeof object.restrictions[i] !== "object")
                    throw TypeError(".MCChat.restrictions: object expected");
                message.restrictions[i] = $root.MCChatRestriction.fromObject(object.restrictions[i]);
            }
        }
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "S_NO_STATUS":
        case 0:
            message.status = 0;
            break;
        case "S_OPEN":
        case 1:
            message.status = 1;
            break;
        case "S_IN_PROGRESS":
        case 2:
            message.status = 2;
            break;
        case "S_RESOLVED":
        case 3:
            message.status = 3;
            break;
        }
        if (object.statusSetBy != null)
            if ($util.Long)
                (message.statusSetBy = $util.Long.fromValue(object.statusSetBy)).unsigned = true;
            else if (typeof object.statusSetBy === "string")
                message.statusSetBy = parseInt(object.statusSetBy, 10);
            else if (typeof object.statusSetBy === "number")
                message.statusSetBy = object.statusSetBy;
            else if (typeof object.statusSetBy === "object")
                message.statusSetBy = new $util.LongBits(object.statusSetBy.low >>> 0, object.statusSetBy.high >>> 0).toNumber(true);
        if (object.statusSetAt != null)
            if ($util.Long)
                (message.statusSetAt = $util.Long.fromValue(object.statusSetAt)).unsigned = true;
            else if (typeof object.statusSetAt === "string")
                message.statusSetAt = parseInt(object.statusSetAt, 10);
            else if (typeof object.statusSetAt === "number")
                message.statusSetAt = object.statusSetAt;
            else if (typeof object.statusSetAt === "object")
                message.statusSetAt = new $util.LongBits(object.statusSetAt.low >>> 0, object.statusSetAt.high >>> 0).toNumber(true);
        if (object.joinedOperators) {
            if (!Array.isArray(object.joinedOperators))
                throw TypeError(".MCChat.joinedOperators: array expected");
            message.joinedOperators = [];
            for (let i = 0; i < object.joinedOperators.length; ++i)
                if ($util.Long)
                    (message.joinedOperators[i] = $util.Long.fromValue(object.joinedOperators[i])).unsigned = true;
                else if (typeof object.joinedOperators[i] === "string")
                    message.joinedOperators[i] = parseInt(object.joinedOperators[i], 10);
                else if (typeof object.joinedOperators[i] === "number")
                    message.joinedOperators[i] = object.joinedOperators[i];
                else if (typeof object.joinedOperators[i] === "object")
                    message.joinedOperators[i] = new $util.LongBits(object.joinedOperators[i].low >>> 0, object.joinedOperators[i].high >>> 0).toNumber(true);
        }
        if (object.meta != null) {
            if (typeof object.meta !== "object")
                throw TypeError(".MCChat.meta: object expected");
            message.meta = $root.Meta.fromObject(object.meta);
        }
        if (object.nextOperatorMessageSuggestion != null) {
            if (typeof object.nextOperatorMessageSuggestion !== "object")
                throw TypeError(".MCChat.nextOperatorMessageSuggestion: object expected");
            message.nextOperatorMessageSuggestion = $root.MCMessageSuggestion.fromObject(object.nextOperatorMessageSuggestion);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCChat message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCChat
     * @static
     * @param {MCChat} message MCChat
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCChat.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.attachmentGroupSizes = [];
            object.restrictions = [];
            object.joinedOperators = [];
        }
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.id = options.longs === String ? "0" : 0;
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            object.name = "";
            object.avatar = null;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.inReadPointer = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.inReadPointer = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.outReadPointer = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.outReadPointer = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.lastMessageSentAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.lastMessageSentAt = options.longs === String ? "0" : 0;
            object.type = options.enums === String ? "UNKNOWN" : 0;
            if (options.bytes === String)
                object.externalID = "";
            else {
                object.externalID = [];
                if (options.bytes !== Array)
                    object.externalID = $util.newBuffer(object.externalID);
            }
            object.lastMessage = null;
            object.alternativeName = "";
            object.showAlternativeName = false;
            object.username = "";
            object.privileges = null;
            object.inviteLink = "";
            object.status = options.enums === String ? "S_NO_STATUS" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.statusSetBy = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.statusSetBy = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.statusSetAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.statusSetAt = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.directChatWithPeer = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.directChatWithPeer = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.messagesExcludedFromUnreadRange = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.messagesExcludedFromUnreadRange = options.longs === String ? "0" : 0;
            if (options.bytes === String)
                object.directChatWithPeerExternalID = "";
            else {
                object.directChatWithPeerExternalID = [];
                if (options.bytes !== Array)
                    object.directChatWithPeerExternalID = $util.newBuffer(object.directChatWithPeerExternalID);
            }
            object.meta = null;
            object.nextOperatorMessageSuggestion = null;
            if (options.bytes === String)
                object.workspaceID = "";
            else {
                object.workspaceID = [];
                if (options.bytes !== Array)
                    object.workspaceID = $util.newBuffer(object.workspaceID);
            }
        }
        if (message.id != null && message.hasOwnProperty("id"))
            if (typeof message.id === "number")
                object.id = options.longs === String ? String(message.id) : message.id;
            else
                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.avatar != null && message.hasOwnProperty("avatar"))
            object.avatar = $root.MCAttachment.toObject(message.avatar, options);
        if (message.inReadPointer != null && message.hasOwnProperty("inReadPointer"))
            if (typeof message.inReadPointer === "number")
                object.inReadPointer = options.longs === String ? String(message.inReadPointer) : message.inReadPointer;
            else
                object.inReadPointer = options.longs === String ? $util.Long.prototype.toString.call(message.inReadPointer) : options.longs === Number ? new $util.LongBits(message.inReadPointer.low >>> 0, message.inReadPointer.high >>> 0).toNumber(true) : message.inReadPointer;
        if (message.outReadPointer != null && message.hasOwnProperty("outReadPointer"))
            if (typeof message.outReadPointer === "number")
                object.outReadPointer = options.longs === String ? String(message.outReadPointer) : message.outReadPointer;
            else
                object.outReadPointer = options.longs === String ? $util.Long.prototype.toString.call(message.outReadPointer) : options.longs === Number ? new $util.LongBits(message.outReadPointer.low >>> 0, message.outReadPointer.high >>> 0).toNumber(true) : message.outReadPointer;
        if (message.lastMessageSentAt != null && message.hasOwnProperty("lastMessageSentAt"))
            if (typeof message.lastMessageSentAt === "number")
                object.lastMessageSentAt = options.longs === String ? String(message.lastMessageSentAt) : message.lastMessageSentAt;
            else
                object.lastMessageSentAt = options.longs === String ? $util.Long.prototype.toString.call(message.lastMessageSentAt) : options.longs === Number ? new $util.LongBits(message.lastMessageSentAt.low >>> 0, message.lastMessageSentAt.high >>> 0).toNumber(true) : message.lastMessageSentAt;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.MCChat.Type[message.type] === undefined ? message.type : $root.MCChat.Type[message.type] : message.type;
        if (message.externalID != null && message.hasOwnProperty("externalID"))
            object.externalID = options.bytes === String ? $util.base64.encode(message.externalID, 0, message.externalID.length) : options.bytes === Array ? Array.prototype.slice.call(message.externalID) : message.externalID;
        if (message.lastMessage != null && message.hasOwnProperty("lastMessage"))
            object.lastMessage = $root.MCMessagePreview.toObject(message.lastMessage, options);
        if (message.alternativeName != null && message.hasOwnProperty("alternativeName"))
            object.alternativeName = message.alternativeName;
        if (message.showAlternativeName != null && message.hasOwnProperty("showAlternativeName"))
            object.showAlternativeName = message.showAlternativeName;
        if (message.username != null && message.hasOwnProperty("username"))
            object.username = message.username;
        if (message.privileges != null && message.hasOwnProperty("privileges"))
            object.privileges = $root.MCChat.Privileges.toObject(message.privileges, options);
        if (message.inviteLink != null && message.hasOwnProperty("inviteLink"))
            object.inviteLink = message.inviteLink;
        if (message.attachmentGroupSizes && message.attachmentGroupSizes.length) {
            object.attachmentGroupSizes = [];
            for (let j = 0; j < message.attachmentGroupSizes.length; ++j)
                object.attachmentGroupSizes[j] = $root.MCChat.AttachmentGroupSize.toObject(message.attachmentGroupSizes[j], options);
        }
        if (message.restrictions && message.restrictions.length) {
            object.restrictions = [];
            for (let j = 0; j < message.restrictions.length; ++j)
                object.restrictions[j] = $root.MCChatRestriction.toObject(message.restrictions[j], options);
        }
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.MCChat.MCChatStatus[message.status] === undefined ? message.status : $root.MCChat.MCChatStatus[message.status] : message.status;
        if (message.statusSetBy != null && message.hasOwnProperty("statusSetBy"))
            if (typeof message.statusSetBy === "number")
                object.statusSetBy = options.longs === String ? String(message.statusSetBy) : message.statusSetBy;
            else
                object.statusSetBy = options.longs === String ? $util.Long.prototype.toString.call(message.statusSetBy) : options.longs === Number ? new $util.LongBits(message.statusSetBy.low >>> 0, message.statusSetBy.high >>> 0).toNumber(true) : message.statusSetBy;
        if (message.statusSetAt != null && message.hasOwnProperty("statusSetAt"))
            if (typeof message.statusSetAt === "number")
                object.statusSetAt = options.longs === String ? String(message.statusSetAt) : message.statusSetAt;
            else
                object.statusSetAt = options.longs === String ? $util.Long.prototype.toString.call(message.statusSetAt) : options.longs === Number ? new $util.LongBits(message.statusSetAt.low >>> 0, message.statusSetAt.high >>> 0).toNumber(true) : message.statusSetAt;
        if (message.joinedOperators && message.joinedOperators.length) {
            object.joinedOperators = [];
            for (let j = 0; j < message.joinedOperators.length; ++j)
                if (typeof message.joinedOperators[j] === "number")
                    object.joinedOperators[j] = options.longs === String ? String(message.joinedOperators[j]) : message.joinedOperators[j];
                else
                    object.joinedOperators[j] = options.longs === String ? $util.Long.prototype.toString.call(message.joinedOperators[j]) : options.longs === Number ? new $util.LongBits(message.joinedOperators[j].low >>> 0, message.joinedOperators[j].high >>> 0).toNumber(true) : message.joinedOperators[j];
        }
        if (message.directChatWithPeer != null && message.hasOwnProperty("directChatWithPeer"))
            if (typeof message.directChatWithPeer === "number")
                object.directChatWithPeer = options.longs === String ? String(message.directChatWithPeer) : message.directChatWithPeer;
            else
                object.directChatWithPeer = options.longs === String ? $util.Long.prototype.toString.call(message.directChatWithPeer) : options.longs === Number ? new $util.LongBits(message.directChatWithPeer.low >>> 0, message.directChatWithPeer.high >>> 0).toNumber(true) : message.directChatWithPeer;
        if (message.messagesExcludedFromUnreadRange != null && message.hasOwnProperty("messagesExcludedFromUnreadRange"))
            if (typeof message.messagesExcludedFromUnreadRange === "number")
                object.messagesExcludedFromUnreadRange = options.longs === String ? String(message.messagesExcludedFromUnreadRange) : message.messagesExcludedFromUnreadRange;
            else
                object.messagesExcludedFromUnreadRange = options.longs === String ? $util.Long.prototype.toString.call(message.messagesExcludedFromUnreadRange) : options.longs === Number ? new $util.LongBits(message.messagesExcludedFromUnreadRange.low >>> 0, message.messagesExcludedFromUnreadRange.high >>> 0).toNumber(true) : message.messagesExcludedFromUnreadRange;
        if (message.directChatWithPeerExternalID != null && message.hasOwnProperty("directChatWithPeerExternalID"))
            object.directChatWithPeerExternalID = options.bytes === String ? $util.base64.encode(message.directChatWithPeerExternalID, 0, message.directChatWithPeerExternalID.length) : options.bytes === Array ? Array.prototype.slice.call(message.directChatWithPeerExternalID) : message.directChatWithPeerExternalID;
        if (message.meta != null && message.hasOwnProperty("meta"))
            object.meta = $root.Meta.toObject(message.meta, options);
        if (message.nextOperatorMessageSuggestion != null && message.hasOwnProperty("nextOperatorMessageSuggestion"))
            object.nextOperatorMessageSuggestion = $root.MCMessageSuggestion.toObject(message.nextOperatorMessageSuggestion, options);
        if (message.workspaceID != null && message.hasOwnProperty("workspaceID"))
            object.workspaceID = options.bytes === String ? $util.base64.encode(message.workspaceID, 0, message.workspaceID.length) : options.bytes === Array ? Array.prototype.slice.call(message.workspaceID) : message.workspaceID;
        return object;
    };

    /**
     * Converts this MCChat to JSON.
     * @function toJSON
     * @memberof MCChat
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCChat.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCChat
     * @function getTypeUrl
     * @memberof MCChat
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCChat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCChat";
    };

    MCChat.Privileges = (function() {

        /**
         * Properties of a Privileges.
         * @memberof MCChat
         * @interface IPrivileges
         * @property {boolean|null} [changeInfo] Privileges changeInfo
         */

        /**
         * Constructs a new Privileges.
         * @memberof MCChat
         * @classdesc Represents a Privileges.
         * @implements IPrivileges
         * @constructor
         * @param {MCChat.IPrivileges=} [properties] Properties to set
         */
        function Privileges(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Privileges changeInfo.
         * @member {boolean} changeInfo
         * @memberof MCChat.Privileges
         * @instance
         */
        Privileges.prototype.changeInfo = false;

        /**
         * Creates a new Privileges instance using the specified properties.
         * @function create
         * @memberof MCChat.Privileges
         * @static
         * @param {MCChat.IPrivileges=} [properties] Properties to set
         * @returns {MCChat.Privileges} Privileges instance
         */
        Privileges.create = function create(properties) {
            return new Privileges(properties);
        };

        /**
         * Encodes the specified Privileges message. Does not implicitly {@link MCChat.Privileges.verify|verify} messages.
         * @function encode
         * @memberof MCChat.Privileges
         * @static
         * @param {MCChat.IPrivileges} message Privileges message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Privileges.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.changeInfo != null && Object.hasOwnProperty.call(message, "changeInfo"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.changeInfo);
            return writer;
        };

        /**
         * Encodes the specified Privileges message, length delimited. Does not implicitly {@link MCChat.Privileges.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MCChat.Privileges
         * @static
         * @param {MCChat.IPrivileges} message Privileges message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Privileges.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Privileges message from the specified reader or buffer.
         * @function decode
         * @memberof MCChat.Privileges
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MCChat.Privileges} Privileges
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Privileges.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCChat.Privileges();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.changeInfo = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Privileges message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MCChat.Privileges
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MCChat.Privileges} Privileges
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Privileges.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Privileges message.
         * @function verify
         * @memberof MCChat.Privileges
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Privileges.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.changeInfo != null && message.hasOwnProperty("changeInfo"))
                if (typeof message.changeInfo !== "boolean")
                    return "changeInfo: boolean expected";
            return null;
        };

        /**
         * Creates a Privileges message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MCChat.Privileges
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MCChat.Privileges} Privileges
         */
        Privileges.fromObject = function fromObject(object) {
            if (object instanceof $root.MCChat.Privileges)
                return object;
            let message = new $root.MCChat.Privileges();
            if (object.changeInfo != null)
                message.changeInfo = Boolean(object.changeInfo);
            return message;
        };

        /**
         * Creates a plain object from a Privileges message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MCChat.Privileges
         * @static
         * @param {MCChat.Privileges} message Privileges
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Privileges.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.changeInfo = false;
            if (message.changeInfo != null && message.hasOwnProperty("changeInfo"))
                object.changeInfo = message.changeInfo;
            return object;
        };

        /**
         * Converts this Privileges to JSON.
         * @function toJSON
         * @memberof MCChat.Privileges
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Privileges.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Privileges
         * @function getTypeUrl
         * @memberof MCChat.Privileges
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Privileges.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MCChat.Privileges";
        };

        return Privileges;
    })();

    MCChat.AttachmentGroupSize = (function() {

        /**
         * Properties of an AttachmentGroupSize.
         * @memberof MCChat
         * @interface IAttachmentGroupSize
         * @property {MCAttachment.Type|null} [type] AttachmentGroupSize type
         * @property {Long|null} [count] AttachmentGroupSize count
         */

        /**
         * Constructs a new AttachmentGroupSize.
         * @memberof MCChat
         * @classdesc Represents an AttachmentGroupSize.
         * @implements IAttachmentGroupSize
         * @constructor
         * @param {MCChat.IAttachmentGroupSize=} [properties] Properties to set
         */
        function AttachmentGroupSize(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AttachmentGroupSize type.
         * @member {MCAttachment.Type} type
         * @memberof MCChat.AttachmentGroupSize
         * @instance
         */
        AttachmentGroupSize.prototype.type = 0;

        /**
         * AttachmentGroupSize count.
         * @member {Long} count
         * @memberof MCChat.AttachmentGroupSize
         * @instance
         */
        AttachmentGroupSize.prototype.count = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new AttachmentGroupSize instance using the specified properties.
         * @function create
         * @memberof MCChat.AttachmentGroupSize
         * @static
         * @param {MCChat.IAttachmentGroupSize=} [properties] Properties to set
         * @returns {MCChat.AttachmentGroupSize} AttachmentGroupSize instance
         */
        AttachmentGroupSize.create = function create(properties) {
            return new AttachmentGroupSize(properties);
        };

        /**
         * Encodes the specified AttachmentGroupSize message. Does not implicitly {@link MCChat.AttachmentGroupSize.verify|verify} messages.
         * @function encode
         * @memberof MCChat.AttachmentGroupSize
         * @static
         * @param {MCChat.IAttachmentGroupSize} message AttachmentGroupSize message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttachmentGroupSize.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.count);
            return writer;
        };

        /**
         * Encodes the specified AttachmentGroupSize message, length delimited. Does not implicitly {@link MCChat.AttachmentGroupSize.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MCChat.AttachmentGroupSize
         * @static
         * @param {MCChat.IAttachmentGroupSize} message AttachmentGroupSize message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttachmentGroupSize.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AttachmentGroupSize message from the specified reader or buffer.
         * @function decode
         * @memberof MCChat.AttachmentGroupSize
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MCChat.AttachmentGroupSize} AttachmentGroupSize
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttachmentGroupSize.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCChat.AttachmentGroupSize();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.count = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AttachmentGroupSize message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MCChat.AttachmentGroupSize
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MCChat.AttachmentGroupSize} AttachmentGroupSize
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttachmentGroupSize.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AttachmentGroupSize message.
         * @function verify
         * @memberof MCChat.AttachmentGroupSize
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AttachmentGroupSize.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            if (message.count != null && message.hasOwnProperty("count"))
                if (!$util.isInteger(message.count) && !(message.count && $util.isInteger(message.count.low) && $util.isInteger(message.count.high)))
                    return "count: integer|Long expected";
            return null;
        };

        /**
         * Creates an AttachmentGroupSize message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MCChat.AttachmentGroupSize
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MCChat.AttachmentGroupSize} AttachmentGroupSize
         */
        AttachmentGroupSize.fromObject = function fromObject(object) {
            if (object instanceof $root.MCChat.AttachmentGroupSize)
                return object;
            let message = new $root.MCChat.AttachmentGroupSize();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                message.type = 0;
                break;
            case "IMAGE":
            case 1:
                message.type = 1;
                break;
            case "VIDEO":
            case 2:
                message.type = 2;
                break;
            case "AUDIO":
            case 3:
                message.type = 3;
                break;
            case "DOCUMENT":
            case 4:
                message.type = 4;
                break;
            case "STICKER":
            case 5:
                message.type = 5;
                break;
            case "GIF":
            case 6:
                message.type = 6;
                break;
            case "VOICE":
            case 7:
                message.type = 7;
                break;
            }
            if (object.count != null)
                if ($util.Long)
                    (message.count = $util.Long.fromValue(object.count)).unsigned = true;
                else if (typeof object.count === "string")
                    message.count = parseInt(object.count, 10);
                else if (typeof object.count === "number")
                    message.count = object.count;
                else if (typeof object.count === "object")
                    message.count = new $util.LongBits(object.count.low >>> 0, object.count.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an AttachmentGroupSize message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MCChat.AttachmentGroupSize
         * @static
         * @param {MCChat.AttachmentGroupSize} message AttachmentGroupSize
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AttachmentGroupSize.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "UNKNOWN" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.count = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.count = options.longs === String ? "0" : 0;
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.MCAttachment.Type[message.type] === undefined ? message.type : $root.MCAttachment.Type[message.type] : message.type;
            if (message.count != null && message.hasOwnProperty("count"))
                if (typeof message.count === "number")
                    object.count = options.longs === String ? String(message.count) : message.count;
                else
                    object.count = options.longs === String ? $util.Long.prototype.toString.call(message.count) : options.longs === Number ? new $util.LongBits(message.count.low >>> 0, message.count.high >>> 0).toNumber(true) : message.count;
            return object;
        };

        /**
         * Converts this AttachmentGroupSize to JSON.
         * @function toJSON
         * @memberof MCChat.AttachmentGroupSize
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AttachmentGroupSize.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AttachmentGroupSize
         * @function getTypeUrl
         * @memberof MCChat.AttachmentGroupSize
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AttachmentGroupSize.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MCChat.AttachmentGroupSize";
        };

        return AttachmentGroupSize;
    })();

    /**
     * Type enum.
     * @name MCChat.Type
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} PRIVATE=1 PRIVATE value
     * @property {number} GROUP=2 GROUP value
     * @property {number} CHANNEL=3 CHANNEL value
     * @property {number} SUPERGROUP=4 SUPERGROUP value
     */
    MCChat.Type = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "PRIVATE"] = 1;
        values[valuesById[2] = "GROUP"] = 2;
        values[valuesById[3] = "CHANNEL"] = 3;
        values[valuesById[4] = "SUPERGROUP"] = 4;
        return values;
    })();

    /**
     * MCChatStatus enum.
     * @name MCChat.MCChatStatus
     * @enum {number}
     * @property {number} S_NO_STATUS=0 S_NO_STATUS value
     * @property {number} S_OPEN=1 S_OPEN value
     * @property {number} S_IN_PROGRESS=2 S_IN_PROGRESS value
     * @property {number} S_RESOLVED=3 S_RESOLVED value
     */
    MCChat.MCChatStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "S_NO_STATUS"] = 0;
        values[valuesById[1] = "S_OPEN"] = 1;
        values[valuesById[2] = "S_IN_PROGRESS"] = 2;
        values[valuesById[3] = "S_RESOLVED"] = 3;
        return values;
    })();

    return MCChat;
})();

export const MCChatRestriction = $root.MCChatRestriction = (() => {

    /**
     * Properties of a MCChatRestriction.
     * @exports IMCChatRestriction
     * @interface IMCChatRestriction
     * @property {Long|null} [peerID] MCChatRestriction peerID
     * @property {Long|null} [until] MCChatRestriction until
     */

    /**
     * Constructs a new MCChatRestriction.
     * @exports MCChatRestriction
     * @classdesc Represents a MCChatRestriction.
     * @implements IMCChatRestriction
     * @constructor
     * @param {IMCChatRestriction=} [properties] Properties to set
     */
    function MCChatRestriction(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCChatRestriction peerID.
     * @member {Long} peerID
     * @memberof MCChatRestriction
     * @instance
     */
    MCChatRestriction.prototype.peerID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChatRestriction until.
     * @member {Long} until
     * @memberof MCChatRestriction
     * @instance
     */
    MCChatRestriction.prototype.until = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCChatRestriction instance using the specified properties.
     * @function create
     * @memberof MCChatRestriction
     * @static
     * @param {IMCChatRestriction=} [properties] Properties to set
     * @returns {MCChatRestriction} MCChatRestriction instance
     */
    MCChatRestriction.create = function create(properties) {
        return new MCChatRestriction(properties);
    };

    /**
     * Encodes the specified MCChatRestriction message. Does not implicitly {@link MCChatRestriction.verify|verify} messages.
     * @function encode
     * @memberof MCChatRestriction
     * @static
     * @param {IMCChatRestriction} message MCChatRestriction message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChatRestriction.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.peerID != null && Object.hasOwnProperty.call(message, "peerID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.peerID);
        if (message.until != null && Object.hasOwnProperty.call(message, "until"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.until);
        return writer;
    };

    /**
     * Encodes the specified MCChatRestriction message, length delimited. Does not implicitly {@link MCChatRestriction.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCChatRestriction
     * @static
     * @param {IMCChatRestriction} message MCChatRestriction message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChatRestriction.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCChatRestriction message from the specified reader or buffer.
     * @function decode
     * @memberof MCChatRestriction
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCChatRestriction} MCChatRestriction
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChatRestriction.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCChatRestriction();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.peerID = reader.uint64();
                    break;
                }
            case 2: {
                    message.until = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCChatRestriction message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCChatRestriction
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCChatRestriction} MCChatRestriction
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChatRestriction.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCChatRestriction message.
     * @function verify
     * @memberof MCChatRestriction
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCChatRestriction.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.peerID != null && message.hasOwnProperty("peerID"))
            if (!$util.isInteger(message.peerID) && !(message.peerID && $util.isInteger(message.peerID.low) && $util.isInteger(message.peerID.high)))
                return "peerID: integer|Long expected";
        if (message.until != null && message.hasOwnProperty("until"))
            if (!$util.isInteger(message.until) && !(message.until && $util.isInteger(message.until.low) && $util.isInteger(message.until.high)))
                return "until: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCChatRestriction message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCChatRestriction
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCChatRestriction} MCChatRestriction
     */
    MCChatRestriction.fromObject = function fromObject(object) {
        if (object instanceof $root.MCChatRestriction)
            return object;
        let message = new $root.MCChatRestriction();
        if (object.peerID != null)
            if ($util.Long)
                (message.peerID = $util.Long.fromValue(object.peerID)).unsigned = true;
            else if (typeof object.peerID === "string")
                message.peerID = parseInt(object.peerID, 10);
            else if (typeof object.peerID === "number")
                message.peerID = object.peerID;
            else if (typeof object.peerID === "object")
                message.peerID = new $util.LongBits(object.peerID.low >>> 0, object.peerID.high >>> 0).toNumber(true);
        if (object.until != null)
            if ($util.Long)
                (message.until = $util.Long.fromValue(object.until)).unsigned = true;
            else if (typeof object.until === "string")
                message.until = parseInt(object.until, 10);
            else if (typeof object.until === "number")
                message.until = object.until;
            else if (typeof object.until === "object")
                message.until = new $util.LongBits(object.until.low >>> 0, object.until.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCChatRestriction message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCChatRestriction
     * @static
     * @param {MCChatRestriction} message MCChatRestriction
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCChatRestriction.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.peerID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.peerID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.until = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.until = options.longs === String ? "0" : 0;
        }
        if (message.peerID != null && message.hasOwnProperty("peerID"))
            if (typeof message.peerID === "number")
                object.peerID = options.longs === String ? String(message.peerID) : message.peerID;
            else
                object.peerID = options.longs === String ? $util.Long.prototype.toString.call(message.peerID) : options.longs === Number ? new $util.LongBits(message.peerID.low >>> 0, message.peerID.high >>> 0).toNumber(true) : message.peerID;
        if (message.until != null && message.hasOwnProperty("until"))
            if (typeof message.until === "number")
                object.until = options.longs === String ? String(message.until) : message.until;
            else
                object.until = options.longs === String ? $util.Long.prototype.toString.call(message.until) : options.longs === Number ? new $util.LongBits(message.until.low >>> 0, message.until.high >>> 0).toNumber(true) : message.until;
        return object;
    };

    /**
     * Converts this MCChatRestriction to JSON.
     * @function toJSON
     * @memberof MCChatRestriction
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCChatRestriction.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCChatRestriction
     * @function getTypeUrl
     * @memberof MCChatRestriction
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCChatRestriction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCChatRestriction";
    };

    return MCChatRestriction;
})();

export const MCChatPreview = $root.MCChatPreview = (() => {

    /**
     * Properties of a MCChatPreview.
     * @exports IMCChatPreview
     * @interface IMCChatPreview
     * @property {Long|null} [id] MCChatPreview id
     * @property {Uint8Array|null} [channelID] MCChatPreview channelID
     * @property {string|null} [name] MCChatPreview name
     * @property {string|null} [alternativeName] MCChatPreview alternativeName
     * @property {boolean|null} [showAlternativeName] MCChatPreview showAlternativeName
     * @property {IMCAttachment|null} [avatar] MCChatPreview avatar
     * @property {Long|null} [inReadPointer] MCChatPreview inReadPointer
     * @property {Long|null} [outReadPointer] MCChatPreview outReadPointer
     * @property {Long|null} [messagesExcludedFromUnreadRange] MCChatPreview messagesExcludedFromUnreadRange
     * @property {Long|null} [lastMessageSentAt] MCChatPreview lastMessageSentAt
     * @property {MCChatPreview.Type|null} [type] MCChatPreview type
     * @property {Long|null} [directChatWithPeer] MCChatPreview directChatWithPeer
     * @property {MCChat.MCChatStatus|null} [status] MCChatPreview status
     * @property {Long|null} [statusSetBy] MCChatPreview statusSetBy
     * @property {Long|null} [statusSetAt] MCChatPreview statusSetAt
     * @property {Array.<Long>|null} [joinedOperators] MCChatPreview joinedOperators
     * @property {IMCMessagePreview|null} [lastMessage] MCChatPreview lastMessage
     */

    /**
     * Constructs a new MCChatPreview.
     * @exports MCChatPreview
     * @classdesc Represents a MCChatPreview.
     * @implements IMCChatPreview
     * @constructor
     * @param {IMCChatPreview=} [properties] Properties to set
     */
    function MCChatPreview(properties) {
        this.joinedOperators = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCChatPreview id.
     * @member {Long} id
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChatPreview channelID.
     * @member {Uint8Array} channelID
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.channelID = $util.newBuffer([]);

    /**
     * MCChatPreview name.
     * @member {string} name
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.name = "";

    /**
     * MCChatPreview alternativeName.
     * @member {string} alternativeName
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.alternativeName = "";

    /**
     * MCChatPreview showAlternativeName.
     * @member {boolean} showAlternativeName
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.showAlternativeName = false;

    /**
     * MCChatPreview avatar.
     * @member {IMCAttachment|null|undefined} avatar
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.avatar = null;

    /**
     * MCChatPreview inReadPointer.
     * @member {Long} inReadPointer
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.inReadPointer = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChatPreview outReadPointer.
     * @member {Long} outReadPointer
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.outReadPointer = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChatPreview messagesExcludedFromUnreadRange.
     * @member {Long} messagesExcludedFromUnreadRange
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.messagesExcludedFromUnreadRange = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChatPreview lastMessageSentAt.
     * @member {Long} lastMessageSentAt
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.lastMessageSentAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChatPreview type.
     * @member {MCChatPreview.Type} type
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.type = 0;

    /**
     * MCChatPreview directChatWithPeer.
     * @member {Long} directChatWithPeer
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.directChatWithPeer = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChatPreview status.
     * @member {MCChat.MCChatStatus} status
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.status = 0;

    /**
     * MCChatPreview statusSetBy.
     * @member {Long} statusSetBy
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.statusSetBy = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChatPreview statusSetAt.
     * @member {Long} statusSetAt
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.statusSetAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCChatPreview joinedOperators.
     * @member {Array.<Long>} joinedOperators
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.joinedOperators = $util.emptyArray;

    /**
     * MCChatPreview lastMessage.
     * @member {IMCMessagePreview|null|undefined} lastMessage
     * @memberof MCChatPreview
     * @instance
     */
    MCChatPreview.prototype.lastMessage = null;

    /**
     * Creates a new MCChatPreview instance using the specified properties.
     * @function create
     * @memberof MCChatPreview
     * @static
     * @param {IMCChatPreview=} [properties] Properties to set
     * @returns {MCChatPreview} MCChatPreview instance
     */
    MCChatPreview.create = function create(properties) {
        return new MCChatPreview(properties);
    };

    /**
     * Encodes the specified MCChatPreview message. Does not implicitly {@link MCChatPreview.verify|verify} messages.
     * @function encode
     * @memberof MCChatPreview
     * @static
     * @param {IMCChatPreview} message MCChatPreview message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChatPreview.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.channelID);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
        if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
            $root.MCAttachment.encode(message.avatar, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.inReadPointer != null && Object.hasOwnProperty.call(message, "inReadPointer"))
            writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.inReadPointer);
        if (message.outReadPointer != null && Object.hasOwnProperty.call(message, "outReadPointer"))
            writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.outReadPointer);
        if (message.lastMessageSentAt != null && Object.hasOwnProperty.call(message, "lastMessageSentAt"))
            writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.lastMessageSentAt);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.type);
        if (message.alternativeName != null && Object.hasOwnProperty.call(message, "alternativeName"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.alternativeName);
        if (message.lastMessage != null && Object.hasOwnProperty.call(message, "lastMessage"))
            $root.MCMessagePreview.encode(message.lastMessage, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.showAlternativeName != null && Object.hasOwnProperty.call(message, "showAlternativeName"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.showAlternativeName);
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.status);
        if (message.statusSetBy != null && Object.hasOwnProperty.call(message, "statusSetBy"))
            writer.uint32(/* id 13, wireType 0 =*/104).uint64(message.statusSetBy);
        if (message.statusSetAt != null && Object.hasOwnProperty.call(message, "statusSetAt"))
            writer.uint32(/* id 14, wireType 0 =*/112).uint64(message.statusSetAt);
        if (message.joinedOperators != null && message.joinedOperators.length) {
            writer.uint32(/* id 21, wireType 2 =*/170).fork();
            for (let i = 0; i < message.joinedOperators.length; ++i)
                writer.uint64(message.joinedOperators[i]);
            writer.ldelim();
        }
        if (message.directChatWithPeer != null && Object.hasOwnProperty.call(message, "directChatWithPeer"))
            writer.uint32(/* id 22, wireType 0 =*/176).uint64(message.directChatWithPeer);
        if (message.messagesExcludedFromUnreadRange != null && Object.hasOwnProperty.call(message, "messagesExcludedFromUnreadRange"))
            writer.uint32(/* id 23, wireType 0 =*/184).uint64(message.messagesExcludedFromUnreadRange);
        return writer;
    };

    /**
     * Encodes the specified MCChatPreview message, length delimited. Does not implicitly {@link MCChatPreview.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCChatPreview
     * @static
     * @param {IMCChatPreview} message MCChatPreview message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChatPreview.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCChatPreview message from the specified reader or buffer.
     * @function decode
     * @memberof MCChatPreview
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCChatPreview} MCChatPreview
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChatPreview.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCChatPreview();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.uint64();
                    break;
                }
            case 2: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 3: {
                    message.name = reader.string();
                    break;
                }
            case 9: {
                    message.alternativeName = reader.string();
                    break;
                }
            case 11: {
                    message.showAlternativeName = reader.bool();
                    break;
                }
            case 4: {
                    message.avatar = $root.MCAttachment.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.inReadPointer = reader.uint64();
                    break;
                }
            case 6: {
                    message.outReadPointer = reader.uint64();
                    break;
                }
            case 23: {
                    message.messagesExcludedFromUnreadRange = reader.uint64();
                    break;
                }
            case 7: {
                    message.lastMessageSentAt = reader.uint64();
                    break;
                }
            case 8: {
                    message.type = reader.int32();
                    break;
                }
            case 22: {
                    message.directChatWithPeer = reader.uint64();
                    break;
                }
            case 12: {
                    message.status = reader.int32();
                    break;
                }
            case 13: {
                    message.statusSetBy = reader.uint64();
                    break;
                }
            case 14: {
                    message.statusSetAt = reader.uint64();
                    break;
                }
            case 21: {
                    if (!(message.joinedOperators && message.joinedOperators.length))
                        message.joinedOperators = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.joinedOperators.push(reader.uint64());
                    } else
                        message.joinedOperators.push(reader.uint64());
                    break;
                }
            case 10: {
                    message.lastMessage = $root.MCMessagePreview.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCChatPreview message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCChatPreview
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCChatPreview} MCChatPreview
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChatPreview.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCChatPreview message.
     * @function verify
     * @memberof MCChatPreview
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCChatPreview.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                return "id: integer|Long expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.alternativeName != null && message.hasOwnProperty("alternativeName"))
            if (!$util.isString(message.alternativeName))
                return "alternativeName: string expected";
        if (message.showAlternativeName != null && message.hasOwnProperty("showAlternativeName"))
            if (typeof message.showAlternativeName !== "boolean")
                return "showAlternativeName: boolean expected";
        if (message.avatar != null && message.hasOwnProperty("avatar")) {
            let error = $root.MCAttachment.verify(message.avatar);
            if (error)
                return "avatar." + error;
        }
        if (message.inReadPointer != null && message.hasOwnProperty("inReadPointer"))
            if (!$util.isInteger(message.inReadPointer) && !(message.inReadPointer && $util.isInteger(message.inReadPointer.low) && $util.isInteger(message.inReadPointer.high)))
                return "inReadPointer: integer|Long expected";
        if (message.outReadPointer != null && message.hasOwnProperty("outReadPointer"))
            if (!$util.isInteger(message.outReadPointer) && !(message.outReadPointer && $util.isInteger(message.outReadPointer.low) && $util.isInteger(message.outReadPointer.high)))
                return "outReadPointer: integer|Long expected";
        if (message.messagesExcludedFromUnreadRange != null && message.hasOwnProperty("messagesExcludedFromUnreadRange"))
            if (!$util.isInteger(message.messagesExcludedFromUnreadRange) && !(message.messagesExcludedFromUnreadRange && $util.isInteger(message.messagesExcludedFromUnreadRange.low) && $util.isInteger(message.messagesExcludedFromUnreadRange.high)))
                return "messagesExcludedFromUnreadRange: integer|Long expected";
        if (message.lastMessageSentAt != null && message.hasOwnProperty("lastMessageSentAt"))
            if (!$util.isInteger(message.lastMessageSentAt) && !(message.lastMessageSentAt && $util.isInteger(message.lastMessageSentAt.low) && $util.isInteger(message.lastMessageSentAt.high)))
                return "lastMessageSentAt: integer|Long expected";
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message.directChatWithPeer != null && message.hasOwnProperty("directChatWithPeer"))
            if (!$util.isInteger(message.directChatWithPeer) && !(message.directChatWithPeer && $util.isInteger(message.directChatWithPeer.low) && $util.isInteger(message.directChatWithPeer.high)))
                return "directChatWithPeer: integer|Long expected";
        if (message.status != null && message.hasOwnProperty("status"))
            switch (message.status) {
            default:
                return "status: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.statusSetBy != null && message.hasOwnProperty("statusSetBy"))
            if (!$util.isInteger(message.statusSetBy) && !(message.statusSetBy && $util.isInteger(message.statusSetBy.low) && $util.isInteger(message.statusSetBy.high)))
                return "statusSetBy: integer|Long expected";
        if (message.statusSetAt != null && message.hasOwnProperty("statusSetAt"))
            if (!$util.isInteger(message.statusSetAt) && !(message.statusSetAt && $util.isInteger(message.statusSetAt.low) && $util.isInteger(message.statusSetAt.high)))
                return "statusSetAt: integer|Long expected";
        if (message.joinedOperators != null && message.hasOwnProperty("joinedOperators")) {
            if (!Array.isArray(message.joinedOperators))
                return "joinedOperators: array expected";
            for (let i = 0; i < message.joinedOperators.length; ++i)
                if (!$util.isInteger(message.joinedOperators[i]) && !(message.joinedOperators[i] && $util.isInteger(message.joinedOperators[i].low) && $util.isInteger(message.joinedOperators[i].high)))
                    return "joinedOperators: integer|Long[] expected";
        }
        if (message.lastMessage != null && message.hasOwnProperty("lastMessage")) {
            let error = $root.MCMessagePreview.verify(message.lastMessage);
            if (error)
                return "lastMessage." + error;
        }
        return null;
    };

    /**
     * Creates a MCChatPreview message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCChatPreview
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCChatPreview} MCChatPreview
     */
    MCChatPreview.fromObject = function fromObject(object) {
        if (object instanceof $root.MCChatPreview)
            return object;
        let message = new $root.MCChatPreview();
        if (object.id != null)
            if ($util.Long)
                (message.id = $util.Long.fromValue(object.id)).unsigned = true;
            else if (typeof object.id === "string")
                message.id = parseInt(object.id, 10);
            else if (typeof object.id === "number")
                message.id = object.id;
            else if (typeof object.id === "object")
                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.name != null)
            message.name = String(object.name);
        if (object.alternativeName != null)
            message.alternativeName = String(object.alternativeName);
        if (object.showAlternativeName != null)
            message.showAlternativeName = Boolean(object.showAlternativeName);
        if (object.avatar != null) {
            if (typeof object.avatar !== "object")
                throw TypeError(".MCChatPreview.avatar: object expected");
            message.avatar = $root.MCAttachment.fromObject(object.avatar);
        }
        if (object.inReadPointer != null)
            if ($util.Long)
                (message.inReadPointer = $util.Long.fromValue(object.inReadPointer)).unsigned = true;
            else if (typeof object.inReadPointer === "string")
                message.inReadPointer = parseInt(object.inReadPointer, 10);
            else if (typeof object.inReadPointer === "number")
                message.inReadPointer = object.inReadPointer;
            else if (typeof object.inReadPointer === "object")
                message.inReadPointer = new $util.LongBits(object.inReadPointer.low >>> 0, object.inReadPointer.high >>> 0).toNumber(true);
        if (object.outReadPointer != null)
            if ($util.Long)
                (message.outReadPointer = $util.Long.fromValue(object.outReadPointer)).unsigned = true;
            else if (typeof object.outReadPointer === "string")
                message.outReadPointer = parseInt(object.outReadPointer, 10);
            else if (typeof object.outReadPointer === "number")
                message.outReadPointer = object.outReadPointer;
            else if (typeof object.outReadPointer === "object")
                message.outReadPointer = new $util.LongBits(object.outReadPointer.low >>> 0, object.outReadPointer.high >>> 0).toNumber(true);
        if (object.messagesExcludedFromUnreadRange != null)
            if ($util.Long)
                (message.messagesExcludedFromUnreadRange = $util.Long.fromValue(object.messagesExcludedFromUnreadRange)).unsigned = true;
            else if (typeof object.messagesExcludedFromUnreadRange === "string")
                message.messagesExcludedFromUnreadRange = parseInt(object.messagesExcludedFromUnreadRange, 10);
            else if (typeof object.messagesExcludedFromUnreadRange === "number")
                message.messagesExcludedFromUnreadRange = object.messagesExcludedFromUnreadRange;
            else if (typeof object.messagesExcludedFromUnreadRange === "object")
                message.messagesExcludedFromUnreadRange = new $util.LongBits(object.messagesExcludedFromUnreadRange.low >>> 0, object.messagesExcludedFromUnreadRange.high >>> 0).toNumber(true);
        if (object.lastMessageSentAt != null)
            if ($util.Long)
                (message.lastMessageSentAt = $util.Long.fromValue(object.lastMessageSentAt)).unsigned = true;
            else if (typeof object.lastMessageSentAt === "string")
                message.lastMessageSentAt = parseInt(object.lastMessageSentAt, 10);
            else if (typeof object.lastMessageSentAt === "number")
                message.lastMessageSentAt = object.lastMessageSentAt;
            else if (typeof object.lastMessageSentAt === "object")
                message.lastMessageSentAt = new $util.LongBits(object.lastMessageSentAt.low >>> 0, object.lastMessageSentAt.high >>> 0).toNumber(true);
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "UNKNOWN":
        case 0:
            message.type = 0;
            break;
        case "PRIVATE":
        case 1:
            message.type = 1;
            break;
        case "GROUP":
        case 2:
            message.type = 2;
            break;
        case "CHANNEL":
        case 3:
            message.type = 3;
            break;
        case "SUPERGROUP":
        case 4:
            message.type = 4;
            break;
        }
        if (object.directChatWithPeer != null)
            if ($util.Long)
                (message.directChatWithPeer = $util.Long.fromValue(object.directChatWithPeer)).unsigned = true;
            else if (typeof object.directChatWithPeer === "string")
                message.directChatWithPeer = parseInt(object.directChatWithPeer, 10);
            else if (typeof object.directChatWithPeer === "number")
                message.directChatWithPeer = object.directChatWithPeer;
            else if (typeof object.directChatWithPeer === "object")
                message.directChatWithPeer = new $util.LongBits(object.directChatWithPeer.low >>> 0, object.directChatWithPeer.high >>> 0).toNumber(true);
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "S_NO_STATUS":
        case 0:
            message.status = 0;
            break;
        case "S_OPEN":
        case 1:
            message.status = 1;
            break;
        case "S_IN_PROGRESS":
        case 2:
            message.status = 2;
            break;
        case "S_RESOLVED":
        case 3:
            message.status = 3;
            break;
        }
        if (object.statusSetBy != null)
            if ($util.Long)
                (message.statusSetBy = $util.Long.fromValue(object.statusSetBy)).unsigned = true;
            else if (typeof object.statusSetBy === "string")
                message.statusSetBy = parseInt(object.statusSetBy, 10);
            else if (typeof object.statusSetBy === "number")
                message.statusSetBy = object.statusSetBy;
            else if (typeof object.statusSetBy === "object")
                message.statusSetBy = new $util.LongBits(object.statusSetBy.low >>> 0, object.statusSetBy.high >>> 0).toNumber(true);
        if (object.statusSetAt != null)
            if ($util.Long)
                (message.statusSetAt = $util.Long.fromValue(object.statusSetAt)).unsigned = true;
            else if (typeof object.statusSetAt === "string")
                message.statusSetAt = parseInt(object.statusSetAt, 10);
            else if (typeof object.statusSetAt === "number")
                message.statusSetAt = object.statusSetAt;
            else if (typeof object.statusSetAt === "object")
                message.statusSetAt = new $util.LongBits(object.statusSetAt.low >>> 0, object.statusSetAt.high >>> 0).toNumber(true);
        if (object.joinedOperators) {
            if (!Array.isArray(object.joinedOperators))
                throw TypeError(".MCChatPreview.joinedOperators: array expected");
            message.joinedOperators = [];
            for (let i = 0; i < object.joinedOperators.length; ++i)
                if ($util.Long)
                    (message.joinedOperators[i] = $util.Long.fromValue(object.joinedOperators[i])).unsigned = true;
                else if (typeof object.joinedOperators[i] === "string")
                    message.joinedOperators[i] = parseInt(object.joinedOperators[i], 10);
                else if (typeof object.joinedOperators[i] === "number")
                    message.joinedOperators[i] = object.joinedOperators[i];
                else if (typeof object.joinedOperators[i] === "object")
                    message.joinedOperators[i] = new $util.LongBits(object.joinedOperators[i].low >>> 0, object.joinedOperators[i].high >>> 0).toNumber(true);
        }
        if (object.lastMessage != null) {
            if (typeof object.lastMessage !== "object")
                throw TypeError(".MCChatPreview.lastMessage: object expected");
            message.lastMessage = $root.MCMessagePreview.fromObject(object.lastMessage);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCChatPreview message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCChatPreview
     * @static
     * @param {MCChatPreview} message MCChatPreview
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCChatPreview.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.joinedOperators = [];
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.id = options.longs === String ? "0" : 0;
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            object.name = "";
            object.avatar = null;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.inReadPointer = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.inReadPointer = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.outReadPointer = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.outReadPointer = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.lastMessageSentAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.lastMessageSentAt = options.longs === String ? "0" : 0;
            object.type = options.enums === String ? "UNKNOWN" : 0;
            object.alternativeName = "";
            object.lastMessage = null;
            object.showAlternativeName = false;
            object.status = options.enums === String ? "S_NO_STATUS" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.statusSetBy = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.statusSetBy = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.statusSetAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.statusSetAt = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.directChatWithPeer = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.directChatWithPeer = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.messagesExcludedFromUnreadRange = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.messagesExcludedFromUnreadRange = options.longs === String ? "0" : 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            if (typeof message.id === "number")
                object.id = options.longs === String ? String(message.id) : message.id;
            else
                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.avatar != null && message.hasOwnProperty("avatar"))
            object.avatar = $root.MCAttachment.toObject(message.avatar, options);
        if (message.inReadPointer != null && message.hasOwnProperty("inReadPointer"))
            if (typeof message.inReadPointer === "number")
                object.inReadPointer = options.longs === String ? String(message.inReadPointer) : message.inReadPointer;
            else
                object.inReadPointer = options.longs === String ? $util.Long.prototype.toString.call(message.inReadPointer) : options.longs === Number ? new $util.LongBits(message.inReadPointer.low >>> 0, message.inReadPointer.high >>> 0).toNumber(true) : message.inReadPointer;
        if (message.outReadPointer != null && message.hasOwnProperty("outReadPointer"))
            if (typeof message.outReadPointer === "number")
                object.outReadPointer = options.longs === String ? String(message.outReadPointer) : message.outReadPointer;
            else
                object.outReadPointer = options.longs === String ? $util.Long.prototype.toString.call(message.outReadPointer) : options.longs === Number ? new $util.LongBits(message.outReadPointer.low >>> 0, message.outReadPointer.high >>> 0).toNumber(true) : message.outReadPointer;
        if (message.lastMessageSentAt != null && message.hasOwnProperty("lastMessageSentAt"))
            if (typeof message.lastMessageSentAt === "number")
                object.lastMessageSentAt = options.longs === String ? String(message.lastMessageSentAt) : message.lastMessageSentAt;
            else
                object.lastMessageSentAt = options.longs === String ? $util.Long.prototype.toString.call(message.lastMessageSentAt) : options.longs === Number ? new $util.LongBits(message.lastMessageSentAt.low >>> 0, message.lastMessageSentAt.high >>> 0).toNumber(true) : message.lastMessageSentAt;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.MCChatPreview.Type[message.type] === undefined ? message.type : $root.MCChatPreview.Type[message.type] : message.type;
        if (message.alternativeName != null && message.hasOwnProperty("alternativeName"))
            object.alternativeName = message.alternativeName;
        if (message.lastMessage != null && message.hasOwnProperty("lastMessage"))
            object.lastMessage = $root.MCMessagePreview.toObject(message.lastMessage, options);
        if (message.showAlternativeName != null && message.hasOwnProperty("showAlternativeName"))
            object.showAlternativeName = message.showAlternativeName;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.MCChat.MCChatStatus[message.status] === undefined ? message.status : $root.MCChat.MCChatStatus[message.status] : message.status;
        if (message.statusSetBy != null && message.hasOwnProperty("statusSetBy"))
            if (typeof message.statusSetBy === "number")
                object.statusSetBy = options.longs === String ? String(message.statusSetBy) : message.statusSetBy;
            else
                object.statusSetBy = options.longs === String ? $util.Long.prototype.toString.call(message.statusSetBy) : options.longs === Number ? new $util.LongBits(message.statusSetBy.low >>> 0, message.statusSetBy.high >>> 0).toNumber(true) : message.statusSetBy;
        if (message.statusSetAt != null && message.hasOwnProperty("statusSetAt"))
            if (typeof message.statusSetAt === "number")
                object.statusSetAt = options.longs === String ? String(message.statusSetAt) : message.statusSetAt;
            else
                object.statusSetAt = options.longs === String ? $util.Long.prototype.toString.call(message.statusSetAt) : options.longs === Number ? new $util.LongBits(message.statusSetAt.low >>> 0, message.statusSetAt.high >>> 0).toNumber(true) : message.statusSetAt;
        if (message.joinedOperators && message.joinedOperators.length) {
            object.joinedOperators = [];
            for (let j = 0; j < message.joinedOperators.length; ++j)
                if (typeof message.joinedOperators[j] === "number")
                    object.joinedOperators[j] = options.longs === String ? String(message.joinedOperators[j]) : message.joinedOperators[j];
                else
                    object.joinedOperators[j] = options.longs === String ? $util.Long.prototype.toString.call(message.joinedOperators[j]) : options.longs === Number ? new $util.LongBits(message.joinedOperators[j].low >>> 0, message.joinedOperators[j].high >>> 0).toNumber(true) : message.joinedOperators[j];
        }
        if (message.directChatWithPeer != null && message.hasOwnProperty("directChatWithPeer"))
            if (typeof message.directChatWithPeer === "number")
                object.directChatWithPeer = options.longs === String ? String(message.directChatWithPeer) : message.directChatWithPeer;
            else
                object.directChatWithPeer = options.longs === String ? $util.Long.prototype.toString.call(message.directChatWithPeer) : options.longs === Number ? new $util.LongBits(message.directChatWithPeer.low >>> 0, message.directChatWithPeer.high >>> 0).toNumber(true) : message.directChatWithPeer;
        if (message.messagesExcludedFromUnreadRange != null && message.hasOwnProperty("messagesExcludedFromUnreadRange"))
            if (typeof message.messagesExcludedFromUnreadRange === "number")
                object.messagesExcludedFromUnreadRange = options.longs === String ? String(message.messagesExcludedFromUnreadRange) : message.messagesExcludedFromUnreadRange;
            else
                object.messagesExcludedFromUnreadRange = options.longs === String ? $util.Long.prototype.toString.call(message.messagesExcludedFromUnreadRange) : options.longs === Number ? new $util.LongBits(message.messagesExcludedFromUnreadRange.low >>> 0, message.messagesExcludedFromUnreadRange.high >>> 0).toNumber(true) : message.messagesExcludedFromUnreadRange;
        return object;
    };

    /**
     * Converts this MCChatPreview to JSON.
     * @function toJSON
     * @memberof MCChatPreview
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCChatPreview.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCChatPreview
     * @function getTypeUrl
     * @memberof MCChatPreview
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCChatPreview.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCChatPreview";
    };

    /**
     * Type enum.
     * @name MCChatPreview.Type
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} PRIVATE=1 PRIVATE value
     * @property {number} GROUP=2 GROUP value
     * @property {number} CHANNEL=3 CHANNEL value
     * @property {number} SUPERGROUP=4 SUPERGROUP value
     */
    MCChatPreview.Type = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "PRIVATE"] = 1;
        values[valuesById[2] = "GROUP"] = 2;
        values[valuesById[3] = "CHANNEL"] = 3;
        values[valuesById[4] = "SUPERGROUP"] = 4;
        return values;
    })();

    return MCChatPreview;
})();

export const MCChannelIdentifiers = $root.MCChannelIdentifiers = (() => {

    /**
     * Properties of a MCChannelIdentifiers.
     * @exports IMCChannelIdentifiers
     * @interface IMCChannelIdentifiers
     * @property {string|null} [username] MCChannelIdentifiers username
     * @property {string|null} [shownName] MCChannelIdentifiers shownName
     * @property {string|null} [phone] MCChannelIdentifiers phone
     * @property {string|null} [email] MCChannelIdentifiers email
     */

    /**
     * Constructs a new MCChannelIdentifiers.
     * @exports MCChannelIdentifiers
     * @classdesc Represents a MCChannelIdentifiers.
     * @implements IMCChannelIdentifiers
     * @constructor
     * @param {IMCChannelIdentifiers=} [properties] Properties to set
     */
    function MCChannelIdentifiers(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCChannelIdentifiers username.
     * @member {string} username
     * @memberof MCChannelIdentifiers
     * @instance
     */
    MCChannelIdentifiers.prototype.username = "";

    /**
     * MCChannelIdentifiers shownName.
     * @member {string} shownName
     * @memberof MCChannelIdentifiers
     * @instance
     */
    MCChannelIdentifiers.prototype.shownName = "";

    /**
     * MCChannelIdentifiers phone.
     * @member {string} phone
     * @memberof MCChannelIdentifiers
     * @instance
     */
    MCChannelIdentifiers.prototype.phone = "";

    /**
     * MCChannelIdentifiers email.
     * @member {string} email
     * @memberof MCChannelIdentifiers
     * @instance
     */
    MCChannelIdentifiers.prototype.email = "";

    /**
     * Creates a new MCChannelIdentifiers instance using the specified properties.
     * @function create
     * @memberof MCChannelIdentifiers
     * @static
     * @param {IMCChannelIdentifiers=} [properties] Properties to set
     * @returns {MCChannelIdentifiers} MCChannelIdentifiers instance
     */
    MCChannelIdentifiers.create = function create(properties) {
        return new MCChannelIdentifiers(properties);
    };

    /**
     * Encodes the specified MCChannelIdentifiers message. Does not implicitly {@link MCChannelIdentifiers.verify|verify} messages.
     * @function encode
     * @memberof MCChannelIdentifiers
     * @static
     * @param {IMCChannelIdentifiers} message MCChannelIdentifiers message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChannelIdentifiers.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.username != null && Object.hasOwnProperty.call(message, "username"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.username);
        if (message.shownName != null && Object.hasOwnProperty.call(message, "shownName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.shownName);
        if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
        if (message.email != null && Object.hasOwnProperty.call(message, "email"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
        return writer;
    };

    /**
     * Encodes the specified MCChannelIdentifiers message, length delimited. Does not implicitly {@link MCChannelIdentifiers.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCChannelIdentifiers
     * @static
     * @param {IMCChannelIdentifiers} message MCChannelIdentifiers message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChannelIdentifiers.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCChannelIdentifiers message from the specified reader or buffer.
     * @function decode
     * @memberof MCChannelIdentifiers
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCChannelIdentifiers} MCChannelIdentifiers
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChannelIdentifiers.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCChannelIdentifiers();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.username = reader.string();
                    break;
                }
            case 2: {
                    message.shownName = reader.string();
                    break;
                }
            case 3: {
                    message.phone = reader.string();
                    break;
                }
            case 4: {
                    message.email = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCChannelIdentifiers message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCChannelIdentifiers
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCChannelIdentifiers} MCChannelIdentifiers
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChannelIdentifiers.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCChannelIdentifiers message.
     * @function verify
     * @memberof MCChannelIdentifiers
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCChannelIdentifiers.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.username != null && message.hasOwnProperty("username"))
            if (!$util.isString(message.username))
                return "username: string expected";
        if (message.shownName != null && message.hasOwnProperty("shownName"))
            if (!$util.isString(message.shownName))
                return "shownName: string expected";
        if (message.phone != null && message.hasOwnProperty("phone"))
            if (!$util.isString(message.phone))
                return "phone: string expected";
        if (message.email != null && message.hasOwnProperty("email"))
            if (!$util.isString(message.email))
                return "email: string expected";
        return null;
    };

    /**
     * Creates a MCChannelIdentifiers message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCChannelIdentifiers
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCChannelIdentifiers} MCChannelIdentifiers
     */
    MCChannelIdentifiers.fromObject = function fromObject(object) {
        if (object instanceof $root.MCChannelIdentifiers)
            return object;
        let message = new $root.MCChannelIdentifiers();
        if (object.username != null)
            message.username = String(object.username);
        if (object.shownName != null)
            message.shownName = String(object.shownName);
        if (object.phone != null)
            message.phone = String(object.phone);
        if (object.email != null)
            message.email = String(object.email);
        return message;
    };

    /**
     * Creates a plain object from a MCChannelIdentifiers message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCChannelIdentifiers
     * @static
     * @param {MCChannelIdentifiers} message MCChannelIdentifiers
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCChannelIdentifiers.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.username = "";
            object.shownName = "";
            object.phone = "";
            object.email = "";
        }
        if (message.username != null && message.hasOwnProperty("username"))
            object.username = message.username;
        if (message.shownName != null && message.hasOwnProperty("shownName"))
            object.shownName = message.shownName;
        if (message.phone != null && message.hasOwnProperty("phone"))
            object.phone = message.phone;
        if (message.email != null && message.hasOwnProperty("email"))
            object.email = message.email;
        return object;
    };

    /**
     * Converts this MCChannelIdentifiers to JSON.
     * @function toJSON
     * @memberof MCChannelIdentifiers
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCChannelIdentifiers.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCChannelIdentifiers
     * @function getTypeUrl
     * @memberof MCChannelIdentifiers
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCChannelIdentifiers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCChannelIdentifiers";
    };

    return MCChannelIdentifiers;
})();

export const MCChannelShownFields = $root.MCChannelShownFields = (() => {

    /**
     * Properties of a MCChannelShownFields.
     * @exports IMCChannelShownFields
     * @interface IMCChannelShownFields
     * @property {string|null} [name] MCChannelShownFields name
     * @property {string|null} [notificationSound] MCChannelShownFields notificationSound
     */

    /**
     * Constructs a new MCChannelShownFields.
     * @exports MCChannelShownFields
     * @classdesc Represents a MCChannelShownFields.
     * @implements IMCChannelShownFields
     * @constructor
     * @param {IMCChannelShownFields=} [properties] Properties to set
     */
    function MCChannelShownFields(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCChannelShownFields name.
     * @member {string} name
     * @memberof MCChannelShownFields
     * @instance
     */
    MCChannelShownFields.prototype.name = "";

    /**
     * MCChannelShownFields notificationSound.
     * @member {string} notificationSound
     * @memberof MCChannelShownFields
     * @instance
     */
    MCChannelShownFields.prototype.notificationSound = "";

    /**
     * Creates a new MCChannelShownFields instance using the specified properties.
     * @function create
     * @memberof MCChannelShownFields
     * @static
     * @param {IMCChannelShownFields=} [properties] Properties to set
     * @returns {MCChannelShownFields} MCChannelShownFields instance
     */
    MCChannelShownFields.create = function create(properties) {
        return new MCChannelShownFields(properties);
    };

    /**
     * Encodes the specified MCChannelShownFields message. Does not implicitly {@link MCChannelShownFields.verify|verify} messages.
     * @function encode
     * @memberof MCChannelShownFields
     * @static
     * @param {IMCChannelShownFields} message MCChannelShownFields message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChannelShownFields.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
        if (message.notificationSound != null && Object.hasOwnProperty.call(message, "notificationSound"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.notificationSound);
        return writer;
    };

    /**
     * Encodes the specified MCChannelShownFields message, length delimited. Does not implicitly {@link MCChannelShownFields.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCChannelShownFields
     * @static
     * @param {IMCChannelShownFields} message MCChannelShownFields message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChannelShownFields.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCChannelShownFields message from the specified reader or buffer.
     * @function decode
     * @memberof MCChannelShownFields
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCChannelShownFields} MCChannelShownFields
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChannelShownFields.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCChannelShownFields();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.name = reader.string();
                    break;
                }
            case 2: {
                    message.notificationSound = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCChannelShownFields message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCChannelShownFields
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCChannelShownFields} MCChannelShownFields
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChannelShownFields.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCChannelShownFields message.
     * @function verify
     * @memberof MCChannelShownFields
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCChannelShownFields.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.notificationSound != null && message.hasOwnProperty("notificationSound"))
            if (!$util.isString(message.notificationSound))
                return "notificationSound: string expected";
        return null;
    };

    /**
     * Creates a MCChannelShownFields message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCChannelShownFields
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCChannelShownFields} MCChannelShownFields
     */
    MCChannelShownFields.fromObject = function fromObject(object) {
        if (object instanceof $root.MCChannelShownFields)
            return object;
        let message = new $root.MCChannelShownFields();
        if (object.name != null)
            message.name = String(object.name);
        if (object.notificationSound != null)
            message.notificationSound = String(object.notificationSound);
        return message;
    };

    /**
     * Creates a plain object from a MCChannelShownFields message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCChannelShownFields
     * @static
     * @param {MCChannelShownFields} message MCChannelShownFields
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCChannelShownFields.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.name = "";
            object.notificationSound = "";
        }
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.notificationSound != null && message.hasOwnProperty("notificationSound"))
            object.notificationSound = message.notificationSound;
        return object;
    };

    /**
     * Converts this MCChannelShownFields to JSON.
     * @function toJSON
     * @memberof MCChannelShownFields
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCChannelShownFields.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCChannelShownFields
     * @function getTypeUrl
     * @memberof MCChannelShownFields
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCChannelShownFields.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCChannelShownFields";
    };

    return MCChannelShownFields;
})();

export const MCChannelState = $root.MCChannelState = (() => {

    /**
     * Properties of a MCChannelState.
     * @exports IMCChannelState
     * @interface IMCChannelState
     * @property {IMCChannelIdentifiers|null} [identifiers] MCChannelState identifiers
     * @property {boolean|null} [chatsImportInProgress] MCChannelState chatsImportInProgress
     * @property {boolean|null} [messagesImportInProgress] MCChannelState messagesImportInProgress
     * @property {ITelegramChannelState|null} [telegram] MCChannelState telegram
     * @property {IWebWidgetChannelState|null} [webWidget] MCChannelState webWidget
     * @property {ITelegramBotChannelState|null} [telegramBot] MCChannelState telegramBot
     * @property {IInstagramChannelState|null} [instagram] MCChannelState instagram
     */

    /**
     * Constructs a new MCChannelState.
     * @exports MCChannelState
     * @classdesc Represents a MCChannelState.
     * @implements IMCChannelState
     * @constructor
     * @param {IMCChannelState=} [properties] Properties to set
     */
    function MCChannelState(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCChannelState identifiers.
     * @member {IMCChannelIdentifiers|null|undefined} identifiers
     * @memberof MCChannelState
     * @instance
     */
    MCChannelState.prototype.identifiers = null;

    /**
     * MCChannelState chatsImportInProgress.
     * @member {boolean} chatsImportInProgress
     * @memberof MCChannelState
     * @instance
     */
    MCChannelState.prototype.chatsImportInProgress = false;

    /**
     * MCChannelState messagesImportInProgress.
     * @member {boolean} messagesImportInProgress
     * @memberof MCChannelState
     * @instance
     */
    MCChannelState.prototype.messagesImportInProgress = false;

    /**
     * MCChannelState telegram.
     * @member {ITelegramChannelState|null|undefined} telegram
     * @memberof MCChannelState
     * @instance
     */
    MCChannelState.prototype.telegram = null;

    /**
     * MCChannelState webWidget.
     * @member {IWebWidgetChannelState|null|undefined} webWidget
     * @memberof MCChannelState
     * @instance
     */
    MCChannelState.prototype.webWidget = null;

    /**
     * MCChannelState telegramBot.
     * @member {ITelegramBotChannelState|null|undefined} telegramBot
     * @memberof MCChannelState
     * @instance
     */
    MCChannelState.prototype.telegramBot = null;

    /**
     * MCChannelState instagram.
     * @member {IInstagramChannelState|null|undefined} instagram
     * @memberof MCChannelState
     * @instance
     */
    MCChannelState.prototype.instagram = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * MCChannelState state.
     * @member {"telegram"|"webWidget"|"telegramBot"|"instagram"|undefined} state
     * @memberof MCChannelState
     * @instance
     */
    Object.defineProperty(MCChannelState.prototype, "state", {
        get: $util.oneOfGetter($oneOfFields = ["telegram", "webWidget", "telegramBot", "instagram"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new MCChannelState instance using the specified properties.
     * @function create
     * @memberof MCChannelState
     * @static
     * @param {IMCChannelState=} [properties] Properties to set
     * @returns {MCChannelState} MCChannelState instance
     */
    MCChannelState.create = function create(properties) {
        return new MCChannelState(properties);
    };

    /**
     * Encodes the specified MCChannelState message. Does not implicitly {@link MCChannelState.verify|verify} messages.
     * @function encode
     * @memberof MCChannelState
     * @static
     * @param {IMCChannelState} message MCChannelState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChannelState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.identifiers != null && Object.hasOwnProperty.call(message, "identifiers"))
            $root.MCChannelIdentifiers.encode(message.identifiers, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.chatsImportInProgress != null && Object.hasOwnProperty.call(message, "chatsImportInProgress"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.chatsImportInProgress);
        if (message.messagesImportInProgress != null && Object.hasOwnProperty.call(message, "messagesImportInProgress"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.messagesImportInProgress);
        if (message.telegram != null && Object.hasOwnProperty.call(message, "telegram"))
            $root.TelegramChannelState.encode(message.telegram, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.webWidget != null && Object.hasOwnProperty.call(message, "webWidget"))
            $root.WebWidgetChannelState.encode(message.webWidget, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.telegramBot != null && Object.hasOwnProperty.call(message, "telegramBot"))
            $root.TelegramBotChannelState.encode(message.telegramBot, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.instagram != null && Object.hasOwnProperty.call(message, "instagram"))
            $root.InstagramChannelState.encode(message.instagram, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCChannelState message, length delimited. Does not implicitly {@link MCChannelState.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCChannelState
     * @static
     * @param {IMCChannelState} message MCChannelState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChannelState.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCChannelState message from the specified reader or buffer.
     * @function decode
     * @memberof MCChannelState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCChannelState} MCChannelState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChannelState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCChannelState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.identifiers = $root.MCChannelIdentifiers.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.chatsImportInProgress = reader.bool();
                    break;
                }
            case 3: {
                    message.messagesImportInProgress = reader.bool();
                    break;
                }
            case 10: {
                    message.telegram = $root.TelegramChannelState.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.webWidget = $root.WebWidgetChannelState.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.telegramBot = $root.TelegramBotChannelState.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.instagram = $root.InstagramChannelState.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCChannelState message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCChannelState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCChannelState} MCChannelState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChannelState.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCChannelState message.
     * @function verify
     * @memberof MCChannelState
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCChannelState.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.identifiers != null && message.hasOwnProperty("identifiers")) {
            let error = $root.MCChannelIdentifiers.verify(message.identifiers);
            if (error)
                return "identifiers." + error;
        }
        if (message.chatsImportInProgress != null && message.hasOwnProperty("chatsImportInProgress"))
            if (typeof message.chatsImportInProgress !== "boolean")
                return "chatsImportInProgress: boolean expected";
        if (message.messagesImportInProgress != null && message.hasOwnProperty("messagesImportInProgress"))
            if (typeof message.messagesImportInProgress !== "boolean")
                return "messagesImportInProgress: boolean expected";
        if (message.telegram != null && message.hasOwnProperty("telegram")) {
            properties.state = 1;
            {
                let error = $root.TelegramChannelState.verify(message.telegram);
                if (error)
                    return "telegram." + error;
            }
        }
        if (message.webWidget != null && message.hasOwnProperty("webWidget")) {
            if (properties.state === 1)
                return "state: multiple values";
            properties.state = 1;
            {
                let error = $root.WebWidgetChannelState.verify(message.webWidget);
                if (error)
                    return "webWidget." + error;
            }
        }
        if (message.telegramBot != null && message.hasOwnProperty("telegramBot")) {
            if (properties.state === 1)
                return "state: multiple values";
            properties.state = 1;
            {
                let error = $root.TelegramBotChannelState.verify(message.telegramBot);
                if (error)
                    return "telegramBot." + error;
            }
        }
        if (message.instagram != null && message.hasOwnProperty("instagram")) {
            if (properties.state === 1)
                return "state: multiple values";
            properties.state = 1;
            {
                let error = $root.InstagramChannelState.verify(message.instagram);
                if (error)
                    return "instagram." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCChannelState message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCChannelState
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCChannelState} MCChannelState
     */
    MCChannelState.fromObject = function fromObject(object) {
        if (object instanceof $root.MCChannelState)
            return object;
        let message = new $root.MCChannelState();
        if (object.identifiers != null) {
            if (typeof object.identifiers !== "object")
                throw TypeError(".MCChannelState.identifiers: object expected");
            message.identifiers = $root.MCChannelIdentifiers.fromObject(object.identifiers);
        }
        if (object.chatsImportInProgress != null)
            message.chatsImportInProgress = Boolean(object.chatsImportInProgress);
        if (object.messagesImportInProgress != null)
            message.messagesImportInProgress = Boolean(object.messagesImportInProgress);
        if (object.telegram != null) {
            if (typeof object.telegram !== "object")
                throw TypeError(".MCChannelState.telegram: object expected");
            message.telegram = $root.TelegramChannelState.fromObject(object.telegram);
        }
        if (object.webWidget != null) {
            if (typeof object.webWidget !== "object")
                throw TypeError(".MCChannelState.webWidget: object expected");
            message.webWidget = $root.WebWidgetChannelState.fromObject(object.webWidget);
        }
        if (object.telegramBot != null) {
            if (typeof object.telegramBot !== "object")
                throw TypeError(".MCChannelState.telegramBot: object expected");
            message.telegramBot = $root.TelegramBotChannelState.fromObject(object.telegramBot);
        }
        if (object.instagram != null) {
            if (typeof object.instagram !== "object")
                throw TypeError(".MCChannelState.instagram: object expected");
            message.instagram = $root.InstagramChannelState.fromObject(object.instagram);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCChannelState message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCChannelState
     * @static
     * @param {MCChannelState} message MCChannelState
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCChannelState.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.identifiers = null;
            object.chatsImportInProgress = false;
            object.messagesImportInProgress = false;
        }
        if (message.identifiers != null && message.hasOwnProperty("identifiers"))
            object.identifiers = $root.MCChannelIdentifiers.toObject(message.identifiers, options);
        if (message.chatsImportInProgress != null && message.hasOwnProperty("chatsImportInProgress"))
            object.chatsImportInProgress = message.chatsImportInProgress;
        if (message.messagesImportInProgress != null && message.hasOwnProperty("messagesImportInProgress"))
            object.messagesImportInProgress = message.messagesImportInProgress;
        if (message.telegram != null && message.hasOwnProperty("telegram")) {
            object.telegram = $root.TelegramChannelState.toObject(message.telegram, options);
            if (options.oneofs)
                object.state = "telegram";
        }
        if (message.webWidget != null && message.hasOwnProperty("webWidget")) {
            object.webWidget = $root.WebWidgetChannelState.toObject(message.webWidget, options);
            if (options.oneofs)
                object.state = "webWidget";
        }
        if (message.telegramBot != null && message.hasOwnProperty("telegramBot")) {
            object.telegramBot = $root.TelegramBotChannelState.toObject(message.telegramBot, options);
            if (options.oneofs)
                object.state = "telegramBot";
        }
        if (message.instagram != null && message.hasOwnProperty("instagram")) {
            object.instagram = $root.InstagramChannelState.toObject(message.instagram, options);
            if (options.oneofs)
                object.state = "instagram";
        }
        return object;
    };

    /**
     * Converts this MCChannelState to JSON.
     * @function toJSON
     * @memberof MCChannelState
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCChannelState.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCChannelState
     * @function getTypeUrl
     * @memberof MCChannelState
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCChannelState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCChannelState";
    };

    return MCChannelState;
})();

export const MCWebWidgetConfig = $root.MCWebWidgetConfig = (() => {

    /**
     * Properties of a MCWebWidgetConfig.
     * @exports IMCWebWidgetConfig
     * @interface IMCWebWidgetConfig
     * @property {string|null} [APICode] MCWebWidgetConfig APICode
     * @property {string|null} [domain] MCWebWidgetConfig domain
     * @property {string|null} [displayCompanyName] MCWebWidgetConfig displayCompanyName
     * @property {string|null} [displayWelcomeMessage] MCWebWidgetConfig displayWelcomeMessage
     * @property {IMCAttachment|null} [displayAvatar] MCWebWidgetConfig displayAvatar
     * @property {string|null} [displayColor] MCWebWidgetConfig displayColor
     * @property {Long|null} [chatButtonSize] MCWebWidgetConfig chatButtonSize
     * @property {string|null} [position] MCWebWidgetConfig position
     * @property {Array.<MCWebWidgetConfig.IHelloSnippet>|null} [helloSnippets] MCWebWidgetConfig helloSnippets
     * @property {boolean|null} [externalMessagingAPIEnabled] MCWebWidgetConfig externalMessagingAPIEnabled
     */

    /**
     * Constructs a new MCWebWidgetConfig.
     * @exports MCWebWidgetConfig
     * @classdesc Represents a MCWebWidgetConfig.
     * @implements IMCWebWidgetConfig
     * @constructor
     * @param {IMCWebWidgetConfig=} [properties] Properties to set
     */
    function MCWebWidgetConfig(properties) {
        this.helloSnippets = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCWebWidgetConfig APICode.
     * @member {string} APICode
     * @memberof MCWebWidgetConfig
     * @instance
     */
    MCWebWidgetConfig.prototype.APICode = "";

    /**
     * MCWebWidgetConfig domain.
     * @member {string} domain
     * @memberof MCWebWidgetConfig
     * @instance
     */
    MCWebWidgetConfig.prototype.domain = "";

    /**
     * MCWebWidgetConfig displayCompanyName.
     * @member {string} displayCompanyName
     * @memberof MCWebWidgetConfig
     * @instance
     */
    MCWebWidgetConfig.prototype.displayCompanyName = "";

    /**
     * MCWebWidgetConfig displayWelcomeMessage.
     * @member {string} displayWelcomeMessage
     * @memberof MCWebWidgetConfig
     * @instance
     */
    MCWebWidgetConfig.prototype.displayWelcomeMessage = "";

    /**
     * MCWebWidgetConfig displayAvatar.
     * @member {IMCAttachment|null|undefined} displayAvatar
     * @memberof MCWebWidgetConfig
     * @instance
     */
    MCWebWidgetConfig.prototype.displayAvatar = null;

    /**
     * MCWebWidgetConfig displayColor.
     * @member {string} displayColor
     * @memberof MCWebWidgetConfig
     * @instance
     */
    MCWebWidgetConfig.prototype.displayColor = "";

    /**
     * MCWebWidgetConfig chatButtonSize.
     * @member {Long} chatButtonSize
     * @memberof MCWebWidgetConfig
     * @instance
     */
    MCWebWidgetConfig.prototype.chatButtonSize = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCWebWidgetConfig position.
     * @member {string} position
     * @memberof MCWebWidgetConfig
     * @instance
     */
    MCWebWidgetConfig.prototype.position = "";

    /**
     * MCWebWidgetConfig helloSnippets.
     * @member {Array.<MCWebWidgetConfig.IHelloSnippet>} helloSnippets
     * @memberof MCWebWidgetConfig
     * @instance
     */
    MCWebWidgetConfig.prototype.helloSnippets = $util.emptyArray;

    /**
     * MCWebWidgetConfig externalMessagingAPIEnabled.
     * @member {boolean} externalMessagingAPIEnabled
     * @memberof MCWebWidgetConfig
     * @instance
     */
    MCWebWidgetConfig.prototype.externalMessagingAPIEnabled = false;

    /**
     * Creates a new MCWebWidgetConfig instance using the specified properties.
     * @function create
     * @memberof MCWebWidgetConfig
     * @static
     * @param {IMCWebWidgetConfig=} [properties] Properties to set
     * @returns {MCWebWidgetConfig} MCWebWidgetConfig instance
     */
    MCWebWidgetConfig.create = function create(properties) {
        return new MCWebWidgetConfig(properties);
    };

    /**
     * Encodes the specified MCWebWidgetConfig message. Does not implicitly {@link MCWebWidgetConfig.verify|verify} messages.
     * @function encode
     * @memberof MCWebWidgetConfig
     * @static
     * @param {IMCWebWidgetConfig} message MCWebWidgetConfig message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCWebWidgetConfig.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.APICode != null && Object.hasOwnProperty.call(message, "APICode"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.APICode);
        if (message.domain != null && Object.hasOwnProperty.call(message, "domain"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.domain);
        if (message.displayCompanyName != null && Object.hasOwnProperty.call(message, "displayCompanyName"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.displayCompanyName);
        if (message.displayWelcomeMessage != null && Object.hasOwnProperty.call(message, "displayWelcomeMessage"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.displayWelcomeMessage);
        if (message.displayAvatar != null && Object.hasOwnProperty.call(message, "displayAvatar"))
            $root.MCAttachment.encode(message.displayAvatar, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.displayColor != null && Object.hasOwnProperty.call(message, "displayColor"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.displayColor);
        if (message.chatButtonSize != null && Object.hasOwnProperty.call(message, "chatButtonSize"))
            writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.chatButtonSize);
        if (message.position != null && Object.hasOwnProperty.call(message, "position"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.position);
        if (message.helloSnippets != null && message.helloSnippets.length)
            for (let i = 0; i < message.helloSnippets.length; ++i)
                $root.MCWebWidgetConfig.HelloSnippet.encode(message.helloSnippets[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.externalMessagingAPIEnabled != null && Object.hasOwnProperty.call(message, "externalMessagingAPIEnabled"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.externalMessagingAPIEnabled);
        return writer;
    };

    /**
     * Encodes the specified MCWebWidgetConfig message, length delimited. Does not implicitly {@link MCWebWidgetConfig.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCWebWidgetConfig
     * @static
     * @param {IMCWebWidgetConfig} message MCWebWidgetConfig message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCWebWidgetConfig.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCWebWidgetConfig message from the specified reader or buffer.
     * @function decode
     * @memberof MCWebWidgetConfig
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCWebWidgetConfig} MCWebWidgetConfig
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCWebWidgetConfig.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCWebWidgetConfig();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.APICode = reader.string();
                    break;
                }
            case 4: {
                    message.domain = reader.string();
                    break;
                }
            case 5: {
                    message.displayCompanyName = reader.string();
                    break;
                }
            case 6: {
                    message.displayWelcomeMessage = reader.string();
                    break;
                }
            case 7: {
                    message.displayAvatar = $root.MCAttachment.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.displayColor = reader.string();
                    break;
                }
            case 9: {
                    message.chatButtonSize = reader.uint64();
                    break;
                }
            case 10: {
                    message.position = reader.string();
                    break;
                }
            case 11: {
                    if (!(message.helloSnippets && message.helloSnippets.length))
                        message.helloSnippets = [];
                    message.helloSnippets.push($root.MCWebWidgetConfig.HelloSnippet.decode(reader, reader.uint32()));
                    break;
                }
            case 12: {
                    message.externalMessagingAPIEnabled = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCWebWidgetConfig message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCWebWidgetConfig
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCWebWidgetConfig} MCWebWidgetConfig
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCWebWidgetConfig.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCWebWidgetConfig message.
     * @function verify
     * @memberof MCWebWidgetConfig
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCWebWidgetConfig.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.APICode != null && message.hasOwnProperty("APICode"))
            if (!$util.isString(message.APICode))
                return "APICode: string expected";
        if (message.domain != null && message.hasOwnProperty("domain"))
            if (!$util.isString(message.domain))
                return "domain: string expected";
        if (message.displayCompanyName != null && message.hasOwnProperty("displayCompanyName"))
            if (!$util.isString(message.displayCompanyName))
                return "displayCompanyName: string expected";
        if (message.displayWelcomeMessage != null && message.hasOwnProperty("displayWelcomeMessage"))
            if (!$util.isString(message.displayWelcomeMessage))
                return "displayWelcomeMessage: string expected";
        if (message.displayAvatar != null && message.hasOwnProperty("displayAvatar")) {
            let error = $root.MCAttachment.verify(message.displayAvatar);
            if (error)
                return "displayAvatar." + error;
        }
        if (message.displayColor != null && message.hasOwnProperty("displayColor"))
            if (!$util.isString(message.displayColor))
                return "displayColor: string expected";
        if (message.chatButtonSize != null && message.hasOwnProperty("chatButtonSize"))
            if (!$util.isInteger(message.chatButtonSize) && !(message.chatButtonSize && $util.isInteger(message.chatButtonSize.low) && $util.isInteger(message.chatButtonSize.high)))
                return "chatButtonSize: integer|Long expected";
        if (message.position != null && message.hasOwnProperty("position"))
            if (!$util.isString(message.position))
                return "position: string expected";
        if (message.helloSnippets != null && message.hasOwnProperty("helloSnippets")) {
            if (!Array.isArray(message.helloSnippets))
                return "helloSnippets: array expected";
            for (let i = 0; i < message.helloSnippets.length; ++i) {
                let error = $root.MCWebWidgetConfig.HelloSnippet.verify(message.helloSnippets[i]);
                if (error)
                    return "helloSnippets." + error;
            }
        }
        if (message.externalMessagingAPIEnabled != null && message.hasOwnProperty("externalMessagingAPIEnabled"))
            if (typeof message.externalMessagingAPIEnabled !== "boolean")
                return "externalMessagingAPIEnabled: boolean expected";
        return null;
    };

    /**
     * Creates a MCWebWidgetConfig message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCWebWidgetConfig
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCWebWidgetConfig} MCWebWidgetConfig
     */
    MCWebWidgetConfig.fromObject = function fromObject(object) {
        if (object instanceof $root.MCWebWidgetConfig)
            return object;
        let message = new $root.MCWebWidgetConfig();
        if (object.APICode != null)
            message.APICode = String(object.APICode);
        if (object.domain != null)
            message.domain = String(object.domain);
        if (object.displayCompanyName != null)
            message.displayCompanyName = String(object.displayCompanyName);
        if (object.displayWelcomeMessage != null)
            message.displayWelcomeMessage = String(object.displayWelcomeMessage);
        if (object.displayAvatar != null) {
            if (typeof object.displayAvatar !== "object")
                throw TypeError(".MCWebWidgetConfig.displayAvatar: object expected");
            message.displayAvatar = $root.MCAttachment.fromObject(object.displayAvatar);
        }
        if (object.displayColor != null)
            message.displayColor = String(object.displayColor);
        if (object.chatButtonSize != null)
            if ($util.Long)
                (message.chatButtonSize = $util.Long.fromValue(object.chatButtonSize)).unsigned = true;
            else if (typeof object.chatButtonSize === "string")
                message.chatButtonSize = parseInt(object.chatButtonSize, 10);
            else if (typeof object.chatButtonSize === "number")
                message.chatButtonSize = object.chatButtonSize;
            else if (typeof object.chatButtonSize === "object")
                message.chatButtonSize = new $util.LongBits(object.chatButtonSize.low >>> 0, object.chatButtonSize.high >>> 0).toNumber(true);
        if (object.position != null)
            message.position = String(object.position);
        if (object.helloSnippets) {
            if (!Array.isArray(object.helloSnippets))
                throw TypeError(".MCWebWidgetConfig.helloSnippets: array expected");
            message.helloSnippets = [];
            for (let i = 0; i < object.helloSnippets.length; ++i) {
                if (typeof object.helloSnippets[i] !== "object")
                    throw TypeError(".MCWebWidgetConfig.helloSnippets: object expected");
                message.helloSnippets[i] = $root.MCWebWidgetConfig.HelloSnippet.fromObject(object.helloSnippets[i]);
            }
        }
        if (object.externalMessagingAPIEnabled != null)
            message.externalMessagingAPIEnabled = Boolean(object.externalMessagingAPIEnabled);
        return message;
    };

    /**
     * Creates a plain object from a MCWebWidgetConfig message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCWebWidgetConfig
     * @static
     * @param {MCWebWidgetConfig} message MCWebWidgetConfig
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCWebWidgetConfig.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.helloSnippets = [];
        if (options.defaults) {
            object.APICode = "";
            object.domain = "";
            object.displayCompanyName = "";
            object.displayWelcomeMessage = "";
            object.displayAvatar = null;
            object.displayColor = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatButtonSize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatButtonSize = options.longs === String ? "0" : 0;
            object.position = "";
            object.externalMessagingAPIEnabled = false;
        }
        if (message.APICode != null && message.hasOwnProperty("APICode"))
            object.APICode = message.APICode;
        if (message.domain != null && message.hasOwnProperty("domain"))
            object.domain = message.domain;
        if (message.displayCompanyName != null && message.hasOwnProperty("displayCompanyName"))
            object.displayCompanyName = message.displayCompanyName;
        if (message.displayWelcomeMessage != null && message.hasOwnProperty("displayWelcomeMessage"))
            object.displayWelcomeMessage = message.displayWelcomeMessage;
        if (message.displayAvatar != null && message.hasOwnProperty("displayAvatar"))
            object.displayAvatar = $root.MCAttachment.toObject(message.displayAvatar, options);
        if (message.displayColor != null && message.hasOwnProperty("displayColor"))
            object.displayColor = message.displayColor;
        if (message.chatButtonSize != null && message.hasOwnProperty("chatButtonSize"))
            if (typeof message.chatButtonSize === "number")
                object.chatButtonSize = options.longs === String ? String(message.chatButtonSize) : message.chatButtonSize;
            else
                object.chatButtonSize = options.longs === String ? $util.Long.prototype.toString.call(message.chatButtonSize) : options.longs === Number ? new $util.LongBits(message.chatButtonSize.low >>> 0, message.chatButtonSize.high >>> 0).toNumber(true) : message.chatButtonSize;
        if (message.position != null && message.hasOwnProperty("position"))
            object.position = message.position;
        if (message.helloSnippets && message.helloSnippets.length) {
            object.helloSnippets = [];
            for (let j = 0; j < message.helloSnippets.length; ++j)
                object.helloSnippets[j] = $root.MCWebWidgetConfig.HelloSnippet.toObject(message.helloSnippets[j], options);
        }
        if (message.externalMessagingAPIEnabled != null && message.hasOwnProperty("externalMessagingAPIEnabled"))
            object.externalMessagingAPIEnabled = message.externalMessagingAPIEnabled;
        return object;
    };

    /**
     * Converts this MCWebWidgetConfig to JSON.
     * @function toJSON
     * @memberof MCWebWidgetConfig
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCWebWidgetConfig.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCWebWidgetConfig
     * @function getTypeUrl
     * @memberof MCWebWidgetConfig
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCWebWidgetConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCWebWidgetConfig";
    };

    MCWebWidgetConfig.HelloSnippet = (function() {

        /**
         * Properties of a HelloSnippet.
         * @memberof MCWebWidgetConfig
         * @interface IHelloSnippet
         * @property {string|null} [text] HelloSnippet text
         * @property {boolean|null} [enabled] HelloSnippet enabled
         */

        /**
         * Constructs a new HelloSnippet.
         * @memberof MCWebWidgetConfig
         * @classdesc Represents a HelloSnippet.
         * @implements IHelloSnippet
         * @constructor
         * @param {MCWebWidgetConfig.IHelloSnippet=} [properties] Properties to set
         */
        function HelloSnippet(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HelloSnippet text.
         * @member {string} text
         * @memberof MCWebWidgetConfig.HelloSnippet
         * @instance
         */
        HelloSnippet.prototype.text = "";

        /**
         * HelloSnippet enabled.
         * @member {boolean} enabled
         * @memberof MCWebWidgetConfig.HelloSnippet
         * @instance
         */
        HelloSnippet.prototype.enabled = false;

        /**
         * Creates a new HelloSnippet instance using the specified properties.
         * @function create
         * @memberof MCWebWidgetConfig.HelloSnippet
         * @static
         * @param {MCWebWidgetConfig.IHelloSnippet=} [properties] Properties to set
         * @returns {MCWebWidgetConfig.HelloSnippet} HelloSnippet instance
         */
        HelloSnippet.create = function create(properties) {
            return new HelloSnippet(properties);
        };

        /**
         * Encodes the specified HelloSnippet message. Does not implicitly {@link MCWebWidgetConfig.HelloSnippet.verify|verify} messages.
         * @function encode
         * @memberof MCWebWidgetConfig.HelloSnippet
         * @static
         * @param {MCWebWidgetConfig.IHelloSnippet} message HelloSnippet message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HelloSnippet.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
            if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.enabled);
            return writer;
        };

        /**
         * Encodes the specified HelloSnippet message, length delimited. Does not implicitly {@link MCWebWidgetConfig.HelloSnippet.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MCWebWidgetConfig.HelloSnippet
         * @static
         * @param {MCWebWidgetConfig.IHelloSnippet} message HelloSnippet message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HelloSnippet.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HelloSnippet message from the specified reader or buffer.
         * @function decode
         * @memberof MCWebWidgetConfig.HelloSnippet
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MCWebWidgetConfig.HelloSnippet} HelloSnippet
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HelloSnippet.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCWebWidgetConfig.HelloSnippet();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.text = reader.string();
                        break;
                    }
                case 2: {
                        message.enabled = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HelloSnippet message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MCWebWidgetConfig.HelloSnippet
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MCWebWidgetConfig.HelloSnippet} HelloSnippet
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HelloSnippet.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HelloSnippet message.
         * @function verify
         * @memberof MCWebWidgetConfig.HelloSnippet
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HelloSnippet.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            if (message.enabled != null && message.hasOwnProperty("enabled"))
                if (typeof message.enabled !== "boolean")
                    return "enabled: boolean expected";
            return null;
        };

        /**
         * Creates a HelloSnippet message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MCWebWidgetConfig.HelloSnippet
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MCWebWidgetConfig.HelloSnippet} HelloSnippet
         */
        HelloSnippet.fromObject = function fromObject(object) {
            if (object instanceof $root.MCWebWidgetConfig.HelloSnippet)
                return object;
            let message = new $root.MCWebWidgetConfig.HelloSnippet();
            if (object.text != null)
                message.text = String(object.text);
            if (object.enabled != null)
                message.enabled = Boolean(object.enabled);
            return message;
        };

        /**
         * Creates a plain object from a HelloSnippet message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MCWebWidgetConfig.HelloSnippet
         * @static
         * @param {MCWebWidgetConfig.HelloSnippet} message HelloSnippet
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HelloSnippet.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.text = "";
                object.enabled = false;
            }
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            if (message.enabled != null && message.hasOwnProperty("enabled"))
                object.enabled = message.enabled;
            return object;
        };

        /**
         * Converts this HelloSnippet to JSON.
         * @function toJSON
         * @memberof MCWebWidgetConfig.HelloSnippet
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HelloSnippet.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for HelloSnippet
         * @function getTypeUrl
         * @memberof MCWebWidgetConfig.HelloSnippet
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HelloSnippet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MCWebWidgetConfig.HelloSnippet";
        };

        return HelloSnippet;
    })();

    return MCWebWidgetConfig;
})();

export const MCChannel = $root.MCChannel = (() => {

    /**
     * Properties of a MCChannel.
     * @exports IMCChannel
     * @interface IMCChannel
     * @property {Uint8Array|null} [channelID] MCChannel channelID
     * @property {Uint8Array|null} [workspaceID] MCChannel workspaceID
     * @property {entities.OzekonChannelType|null} [type] MCChannel type
     * @property {IMCChannelShownFields|null} [shownFields] MCChannel shownFields
     * @property {IMCChannelState|null} [state] MCChannel state
     * @property {IMCWebWidgetConfig|null} [webWidgetConfig] MCChannel webWidgetConfig
     */

    /**
     * Constructs a new MCChannel.
     * @exports MCChannel
     * @classdesc Represents a MCChannel.
     * @implements IMCChannel
     * @constructor
     * @param {IMCChannel=} [properties] Properties to set
     */
    function MCChannel(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCChannel channelID.
     * @member {Uint8Array} channelID
     * @memberof MCChannel
     * @instance
     */
    MCChannel.prototype.channelID = $util.newBuffer([]);

    /**
     * MCChannel workspaceID.
     * @member {Uint8Array} workspaceID
     * @memberof MCChannel
     * @instance
     */
    MCChannel.prototype.workspaceID = $util.newBuffer([]);

    /**
     * MCChannel type.
     * @member {entities.OzekonChannelType} type
     * @memberof MCChannel
     * @instance
     */
    MCChannel.prototype.type = 0;

    /**
     * MCChannel shownFields.
     * @member {IMCChannelShownFields|null|undefined} shownFields
     * @memberof MCChannel
     * @instance
     */
    MCChannel.prototype.shownFields = null;

    /**
     * MCChannel state.
     * @member {IMCChannelState|null|undefined} state
     * @memberof MCChannel
     * @instance
     */
    MCChannel.prototype.state = null;

    /**
     * MCChannel webWidgetConfig.
     * @member {IMCWebWidgetConfig|null|undefined} webWidgetConfig
     * @memberof MCChannel
     * @instance
     */
    MCChannel.prototype.webWidgetConfig = null;

    /**
     * Creates a new MCChannel instance using the specified properties.
     * @function create
     * @memberof MCChannel
     * @static
     * @param {IMCChannel=} [properties] Properties to set
     * @returns {MCChannel} MCChannel instance
     */
    MCChannel.create = function create(properties) {
        return new MCChannel(properties);
    };

    /**
     * Encodes the specified MCChannel message. Does not implicitly {@link MCChannel.verify|verify} messages.
     * @function encode
     * @memberof MCChannel
     * @static
     * @param {IMCChannel} message MCChannel message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChannel.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
        if (message.shownFields != null && Object.hasOwnProperty.call(message, "shownFields"))
            $root.MCChannelShownFields.encode(message.shownFields, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.channelID);
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
            $root.MCChannelState.encode(message.state, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.webWidgetConfig != null && Object.hasOwnProperty.call(message, "webWidgetConfig"))
            $root.MCWebWidgetConfig.encode(message.webWidgetConfig, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.workspaceID != null && Object.hasOwnProperty.call(message, "workspaceID"))
            writer.uint32(/* id 8, wireType 2 =*/66).bytes(message.workspaceID);
        return writer;
    };

    /**
     * Encodes the specified MCChannel message, length delimited. Does not implicitly {@link MCChannel.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCChannel
     * @static
     * @param {IMCChannel} message MCChannel message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCChannel.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCChannel message from the specified reader or buffer.
     * @function decode
     * @memberof MCChannel
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCChannel} MCChannel
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChannel.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCChannel();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 5: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 8: {
                    message.workspaceID = reader.bytes();
                    break;
                }
            case 2: {
                    message.type = reader.int32();
                    break;
                }
            case 4: {
                    message.shownFields = $root.MCChannelShownFields.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.state = $root.MCChannelState.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.webWidgetConfig = $root.MCWebWidgetConfig.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCChannel message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCChannel
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCChannel} MCChannel
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCChannel.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCChannel message.
     * @function verify
     * @memberof MCChannel
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCChannel.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.workspaceID != null && message.hasOwnProperty("workspaceID"))
            if (!(message.workspaceID && typeof message.workspaceID.length === "number" || $util.isString(message.workspaceID)))
                return "workspaceID: buffer expected";
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message.shownFields != null && message.hasOwnProperty("shownFields")) {
            let error = $root.MCChannelShownFields.verify(message.shownFields);
            if (error)
                return "shownFields." + error;
        }
        if (message.state != null && message.hasOwnProperty("state")) {
            let error = $root.MCChannelState.verify(message.state);
            if (error)
                return "state." + error;
        }
        if (message.webWidgetConfig != null && message.hasOwnProperty("webWidgetConfig")) {
            let error = $root.MCWebWidgetConfig.verify(message.webWidgetConfig);
            if (error)
                return "webWidgetConfig." + error;
        }
        return null;
    };

    /**
     * Creates a MCChannel message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCChannel
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCChannel} MCChannel
     */
    MCChannel.fromObject = function fromObject(object) {
        if (object instanceof $root.MCChannel)
            return object;
        let message = new $root.MCChannel();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.workspaceID != null)
            if (typeof object.workspaceID === "string")
                $util.base64.decode(object.workspaceID, message.workspaceID = $util.newBuffer($util.base64.length(object.workspaceID)), 0);
            else if (object.workspaceID.length >= 0)
                message.workspaceID = object.workspaceID;
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "OIT_NONE":
        case 0:
            message.type = 0;
            break;
        case "OIT_TELEGRAM":
        case 1:
            message.type = 1;
            break;
        case "OIT_WEB_WIDGET":
        case 2:
            message.type = 2;
            break;
        case "OIT_INSTAGRAM":
        case 3:
            message.type = 3;
            break;
        case "OIT_TELEGRAM_BOT":
        case 4:
            message.type = 4;
            break;
        }
        if (object.shownFields != null) {
            if (typeof object.shownFields !== "object")
                throw TypeError(".MCChannel.shownFields: object expected");
            message.shownFields = $root.MCChannelShownFields.fromObject(object.shownFields);
        }
        if (object.state != null) {
            if (typeof object.state !== "object")
                throw TypeError(".MCChannel.state: object expected");
            message.state = $root.MCChannelState.fromObject(object.state);
        }
        if (object.webWidgetConfig != null) {
            if (typeof object.webWidgetConfig !== "object")
                throw TypeError(".MCChannel.webWidgetConfig: object expected");
            message.webWidgetConfig = $root.MCWebWidgetConfig.fromObject(object.webWidgetConfig);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCChannel message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCChannel
     * @static
     * @param {MCChannel} message MCChannel
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCChannel.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "OIT_NONE" : 0;
            object.shownFields = null;
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            object.state = null;
            object.webWidgetConfig = null;
            if (options.bytes === String)
                object.workspaceID = "";
            else {
                object.workspaceID = [];
                if (options.bytes !== Array)
                    object.workspaceID = $util.newBuffer(object.workspaceID);
            }
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.entities.OzekonChannelType[message.type] === undefined ? message.type : $root.entities.OzekonChannelType[message.type] : message.type;
        if (message.shownFields != null && message.hasOwnProperty("shownFields"))
            object.shownFields = $root.MCChannelShownFields.toObject(message.shownFields, options);
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.state != null && message.hasOwnProperty("state"))
            object.state = $root.MCChannelState.toObject(message.state, options);
        if (message.webWidgetConfig != null && message.hasOwnProperty("webWidgetConfig"))
            object.webWidgetConfig = $root.MCWebWidgetConfig.toObject(message.webWidgetConfig, options);
        if (message.workspaceID != null && message.hasOwnProperty("workspaceID"))
            object.workspaceID = options.bytes === String ? $util.base64.encode(message.workspaceID, 0, message.workspaceID.length) : options.bytes === Array ? Array.prototype.slice.call(message.workspaceID) : message.workspaceID;
        return object;
    };

    /**
     * Converts this MCChannel to JSON.
     * @function toJSON
     * @memberof MCChannel
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCChannel.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCChannel
     * @function getTypeUrl
     * @memberof MCChannel
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCChannel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCChannel";
    };

    return MCChannel;
})();

export const MCMessageKey = $root.MCMessageKey = (() => {

    /**
     * Properties of a MCMessageKey.
     * @exports IMCMessageKey
     * @interface IMCMessageKey
     * @property {Long|null} [chatID] MCMessageKey chatID
     * @property {Long|null} [localID] MCMessageKey localID
     */

    /**
     * Constructs a new MCMessageKey.
     * @exports MCMessageKey
     * @classdesc Represents a MCMessageKey.
     * @implements IMCMessageKey
     * @constructor
     * @param {IMCMessageKey=} [properties] Properties to set
     */
    function MCMessageKey(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCMessageKey chatID.
     * @member {Long} chatID
     * @memberof MCMessageKey
     * @instance
     */
    MCMessageKey.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCMessageKey localID.
     * @member {Long} localID
     * @memberof MCMessageKey
     * @instance
     */
    MCMessageKey.prototype.localID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCMessageKey instance using the specified properties.
     * @function create
     * @memberof MCMessageKey
     * @static
     * @param {IMCMessageKey=} [properties] Properties to set
     * @returns {MCMessageKey} MCMessageKey instance
     */
    MCMessageKey.create = function create(properties) {
        return new MCMessageKey(properties);
    };

    /**
     * Encodes the specified MCMessageKey message. Does not implicitly {@link MCMessageKey.verify|verify} messages.
     * @function encode
     * @memberof MCMessageKey
     * @static
     * @param {IMCMessageKey} message MCMessageKey message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMessageKey.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatID);
        if (message.localID != null && Object.hasOwnProperty.call(message, "localID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.localID);
        return writer;
    };

    /**
     * Encodes the specified MCMessageKey message, length delimited. Does not implicitly {@link MCMessageKey.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCMessageKey
     * @static
     * @param {IMCMessageKey} message MCMessageKey message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCMessageKey.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCMessageKey message from the specified reader or buffer.
     * @function decode
     * @memberof MCMessageKey
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCMessageKey} MCMessageKey
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMessageKey.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCMessageKey();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 2: {
                    message.localID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCMessageKey message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCMessageKey
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCMessageKey} MCMessageKey
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCMessageKey.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCMessageKey message.
     * @function verify
     * @memberof MCMessageKey
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCMessageKey.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.localID != null && message.hasOwnProperty("localID"))
            if (!$util.isInteger(message.localID) && !(message.localID && $util.isInteger(message.localID.low) && $util.isInteger(message.localID.high)))
                return "localID: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCMessageKey message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCMessageKey
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCMessageKey} MCMessageKey
     */
    MCMessageKey.fromObject = function fromObject(object) {
        if (object instanceof $root.MCMessageKey)
            return object;
        let message = new $root.MCMessageKey();
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.localID != null)
            if ($util.Long)
                (message.localID = $util.Long.fromValue(object.localID)).unsigned = true;
            else if (typeof object.localID === "string")
                message.localID = parseInt(object.localID, 10);
            else if (typeof object.localID === "number")
                message.localID = object.localID;
            else if (typeof object.localID === "object")
                message.localID = new $util.LongBits(object.localID.low >>> 0, object.localID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCMessageKey message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCMessageKey
     * @static
     * @param {MCMessageKey} message MCMessageKey
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCMessageKey.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.localID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.localID = options.longs === String ? "0" : 0;
        }
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.localID != null && message.hasOwnProperty("localID"))
            if (typeof message.localID === "number")
                object.localID = options.longs === String ? String(message.localID) : message.localID;
            else
                object.localID = options.longs === String ? $util.Long.prototype.toString.call(message.localID) : options.longs === Number ? new $util.LongBits(message.localID.low >>> 0, message.localID.high >>> 0).toNumber(true) : message.localID;
        return object;
    };

    /**
     * Converts this MCMessageKey to JSON.
     * @function toJSON
     * @memberof MCMessageKey
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCMessageKey.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCMessageKey
     * @function getTypeUrl
     * @memberof MCMessageKey
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCMessageKey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCMessageKey";
    };

    return MCMessageKey;
})();

export const MCServiceMessage = $root.MCServiceMessage = (() => {

    /**
     * Properties of a MCServiceMessage.
     * @exports IMCServiceMessage
     * @interface IMCServiceMessage
     * @property {IMCServiceMessageCreateChat|null} [createChat] MCServiceMessage createChat
     * @property {IMCServiceMessageEditChat|null} [editChat] MCServiceMessage editChat
     * @property {IMCServiceMessageAddPeers|null} [addPeer] MCServiceMessage addPeer
     * @property {IMCServiceMessageRemovePeers|null} [removePeer] MCServiceMessage removePeer
     * @property {IMCServiceMessagePinMessage|null} [pinMessage] MCServiceMessage pinMessage
     * @property {IMCServiceMessageMigratedToSuperGroup|null} [migratedToSuperGroup] MCServiceMessage migratedToSuperGroup
     * @property {IMCServiceMessageMigratedFromSuperGroup|null} [migratedFromSuperGroup] MCServiceMessage migratedFromSuperGroup
     * @property {IMCServiceMessageWidgetUserConnected|null} [widgetUserConnected] MCServiceMessage widgetUserConnected
     * @property {IMCServiceMessageWidgetUserDisconnected|null} [widgetUserDisconnected] MCServiceMessage widgetUserDisconnected
     * @property {IMCServiceMessageWidgetUserRestricted|null} [widgetUserRestricted] MCServiceMessage widgetUserRestricted
     * @property {IMCServiceMessageWidgetOperatorJoined|null} [widgetOperatorJoined] MCServiceMessage widgetOperatorJoined
     * @property {IMCServiceMessageWidgetOperatorLeft|null} [widgetOperatorLeft] MCServiceMessage widgetOperatorLeft
     * @property {IMCServiceMessageWidgetResolved|null} [widgetResolved] MCServiceMessage widgetResolved
     * @property {IMCServiceMessageWidgetReopen|null} [widgetReopen] MCServiceMessage widgetReopen
     * @property {IMCServiceMessageLegacy|null} [legacy] MCServiceMessage legacy
     */

    /**
     * Constructs a new MCServiceMessage.
     * @exports MCServiceMessage
     * @classdesc Represents a MCServiceMessage.
     * @implements IMCServiceMessage
     * @constructor
     * @param {IMCServiceMessage=} [properties] Properties to set
     */
    function MCServiceMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessage createChat.
     * @member {IMCServiceMessageCreateChat|null|undefined} createChat
     * @memberof MCServiceMessage
     * @instance
     */
    MCServiceMessage.prototype.createChat = null;

    /**
     * MCServiceMessage editChat.
     * @member {IMCServiceMessageEditChat|null|undefined} editChat
     * @memberof MCServiceMessage
     * @instance
     */
    MCServiceMessage.prototype.editChat = null;

    /**
     * MCServiceMessage addPeer.
     * @member {IMCServiceMessageAddPeers|null|undefined} addPeer
     * @memberof MCServiceMessage
     * @instance
     */
    MCServiceMessage.prototype.addPeer = null;

    /**
     * MCServiceMessage removePeer.
     * @member {IMCServiceMessageRemovePeers|null|undefined} removePeer
     * @memberof MCServiceMessage
     * @instance
     */
    MCServiceMessage.prototype.removePeer = null;

    /**
     * MCServiceMessage pinMessage.
     * @member {IMCServiceMessagePinMessage|null|undefined} pinMessage
     * @memberof MCServiceMessage
     * @instance
     */
    MCServiceMessage.prototype.pinMessage = null;

    /**
     * MCServiceMessage migratedToSuperGroup.
     * @member {IMCServiceMessageMigratedToSuperGroup|null|undefined} migratedToSuperGroup
     * @memberof MCServiceMessage
     * @instance
     */
    MCServiceMessage.prototype.migratedToSuperGroup = null;

    /**
     * MCServiceMessage migratedFromSuperGroup.
     * @member {IMCServiceMessageMigratedFromSuperGroup|null|undefined} migratedFromSuperGroup
     * @memberof MCServiceMessage
     * @instance
     */
    MCServiceMessage.prototype.migratedFromSuperGroup = null;

    /**
     * MCServiceMessage widgetUserConnected.
     * @member {IMCServiceMessageWidgetUserConnected|null|undefined} widgetUserConnected
     * @memberof MCServiceMessage
     * @instance
     */
    MCServiceMessage.prototype.widgetUserConnected = null;

    /**
     * MCServiceMessage widgetUserDisconnected.
     * @member {IMCServiceMessageWidgetUserDisconnected|null|undefined} widgetUserDisconnected
     * @memberof MCServiceMessage
     * @instance
     */
    MCServiceMessage.prototype.widgetUserDisconnected = null;

    /**
     * MCServiceMessage widgetUserRestricted.
     * @member {IMCServiceMessageWidgetUserRestricted|null|undefined} widgetUserRestricted
     * @memberof MCServiceMessage
     * @instance
     */
    MCServiceMessage.prototype.widgetUserRestricted = null;

    /**
     * MCServiceMessage widgetOperatorJoined.
     * @member {IMCServiceMessageWidgetOperatorJoined|null|undefined} widgetOperatorJoined
     * @memberof MCServiceMessage
     * @instance
     */
    MCServiceMessage.prototype.widgetOperatorJoined = null;

    /**
     * MCServiceMessage widgetOperatorLeft.
     * @member {IMCServiceMessageWidgetOperatorLeft|null|undefined} widgetOperatorLeft
     * @memberof MCServiceMessage
     * @instance
     */
    MCServiceMessage.prototype.widgetOperatorLeft = null;

    /**
     * MCServiceMessage widgetResolved.
     * @member {IMCServiceMessageWidgetResolved|null|undefined} widgetResolved
     * @memberof MCServiceMessage
     * @instance
     */
    MCServiceMessage.prototype.widgetResolved = null;

    /**
     * MCServiceMessage widgetReopen.
     * @member {IMCServiceMessageWidgetReopen|null|undefined} widgetReopen
     * @memberof MCServiceMessage
     * @instance
     */
    MCServiceMessage.prototype.widgetReopen = null;

    /**
     * MCServiceMessage legacy.
     * @member {IMCServiceMessageLegacy|null|undefined} legacy
     * @memberof MCServiceMessage
     * @instance
     */
    MCServiceMessage.prototype.legacy = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * MCServiceMessage action.
     * @member {"createChat"|"editChat"|"addPeer"|"removePeer"|"pinMessage"|"migratedToSuperGroup"|"migratedFromSuperGroup"|"widgetUserConnected"|"widgetUserDisconnected"|"widgetUserRestricted"|"widgetOperatorJoined"|"widgetOperatorLeft"|"widgetResolved"|"widgetReopen"|"legacy"|undefined} action
     * @memberof MCServiceMessage
     * @instance
     */
    Object.defineProperty(MCServiceMessage.prototype, "action", {
        get: $util.oneOfGetter($oneOfFields = ["createChat", "editChat", "addPeer", "removePeer", "pinMessage", "migratedToSuperGroup", "migratedFromSuperGroup", "widgetUserConnected", "widgetUserDisconnected", "widgetUserRestricted", "widgetOperatorJoined", "widgetOperatorLeft", "widgetResolved", "widgetReopen", "legacy"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new MCServiceMessage instance using the specified properties.
     * @function create
     * @memberof MCServiceMessage
     * @static
     * @param {IMCServiceMessage=} [properties] Properties to set
     * @returns {MCServiceMessage} MCServiceMessage instance
     */
    MCServiceMessage.create = function create(properties) {
        return new MCServiceMessage(properties);
    };

    /**
     * Encodes the specified MCServiceMessage message. Does not implicitly {@link MCServiceMessage.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessage
     * @static
     * @param {IMCServiceMessage} message MCServiceMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.createChat != null && Object.hasOwnProperty.call(message, "createChat"))
            $root.MCServiceMessageCreateChat.encode(message.createChat, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.editChat != null && Object.hasOwnProperty.call(message, "editChat"))
            $root.MCServiceMessageEditChat.encode(message.editChat, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.addPeer != null && Object.hasOwnProperty.call(message, "addPeer"))
            $root.MCServiceMessageAddPeers.encode(message.addPeer, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.removePeer != null && Object.hasOwnProperty.call(message, "removePeer"))
            $root.MCServiceMessageRemovePeers.encode(message.removePeer, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.pinMessage != null && Object.hasOwnProperty.call(message, "pinMessage"))
            $root.MCServiceMessagePinMessage.encode(message.pinMessage, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.migratedToSuperGroup != null && Object.hasOwnProperty.call(message, "migratedToSuperGroup"))
            $root.MCServiceMessageMigratedToSuperGroup.encode(message.migratedToSuperGroup, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.widgetUserConnected != null && Object.hasOwnProperty.call(message, "widgetUserConnected"))
            $root.MCServiceMessageWidgetUserConnected.encode(message.widgetUserConnected, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.widgetUserDisconnected != null && Object.hasOwnProperty.call(message, "widgetUserDisconnected"))
            $root.MCServiceMessageWidgetUserDisconnected.encode(message.widgetUserDisconnected, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.widgetUserRestricted != null && Object.hasOwnProperty.call(message, "widgetUserRestricted"))
            $root.MCServiceMessageWidgetUserRestricted.encode(message.widgetUserRestricted, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.widgetOperatorJoined != null && Object.hasOwnProperty.call(message, "widgetOperatorJoined"))
            $root.MCServiceMessageWidgetOperatorJoined.encode(message.widgetOperatorJoined, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.widgetOperatorLeft != null && Object.hasOwnProperty.call(message, "widgetOperatorLeft"))
            $root.MCServiceMessageWidgetOperatorLeft.encode(message.widgetOperatorLeft, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        if (message.widgetResolved != null && Object.hasOwnProperty.call(message, "widgetResolved"))
            $root.MCServiceMessageWidgetResolved.encode(message.widgetResolved, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
        if (message.widgetReopen != null && Object.hasOwnProperty.call(message, "widgetReopen"))
            $root.MCServiceMessageWidgetReopen.encode(message.widgetReopen, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
        if (message.migratedFromSuperGroup != null && Object.hasOwnProperty.call(message, "migratedFromSuperGroup"))
            $root.MCServiceMessageMigratedFromSuperGroup.encode(message.migratedFromSuperGroup, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
        if (message.legacy != null && Object.hasOwnProperty.call(message, "legacy"))
            $root.MCServiceMessageLegacy.encode(message.legacy, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessage message, length delimited. Does not implicitly {@link MCServiceMessage.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessage
     * @static
     * @param {IMCServiceMessage} message MCServiceMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessage message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessage} MCServiceMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 3: {
                    message.createChat = $root.MCServiceMessageCreateChat.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.editChat = $root.MCServiceMessageEditChat.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.addPeer = $root.MCServiceMessageAddPeers.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.removePeer = $root.MCServiceMessageRemovePeers.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.pinMessage = $root.MCServiceMessagePinMessage.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.migratedToSuperGroup = $root.MCServiceMessageMigratedToSuperGroup.decode(reader, reader.uint32());
                    break;
                }
            case 16: {
                    message.migratedFromSuperGroup = $root.MCServiceMessageMigratedFromSuperGroup.decode(reader, reader.uint32());
                    break;
                }
            case 9: {
                    message.widgetUserConnected = $root.MCServiceMessageWidgetUserConnected.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.widgetUserDisconnected = $root.MCServiceMessageWidgetUserDisconnected.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.widgetUserRestricted = $root.MCServiceMessageWidgetUserRestricted.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.widgetOperatorJoined = $root.MCServiceMessageWidgetOperatorJoined.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.widgetOperatorLeft = $root.MCServiceMessageWidgetOperatorLeft.decode(reader, reader.uint32());
                    break;
                }
            case 14: {
                    message.widgetResolved = $root.MCServiceMessageWidgetResolved.decode(reader, reader.uint32());
                    break;
                }
            case 15: {
                    message.widgetReopen = $root.MCServiceMessageWidgetReopen.decode(reader, reader.uint32());
                    break;
                }
            case 100: {
                    message.legacy = $root.MCServiceMessageLegacy.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessage message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessage} MCServiceMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessage message.
     * @function verify
     * @memberof MCServiceMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessage.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.createChat != null && message.hasOwnProperty("createChat")) {
            properties.action = 1;
            {
                let error = $root.MCServiceMessageCreateChat.verify(message.createChat);
                if (error)
                    return "createChat." + error;
            }
        }
        if (message.editChat != null && message.hasOwnProperty("editChat")) {
            if (properties.action === 1)
                return "action: multiple values";
            properties.action = 1;
            {
                let error = $root.MCServiceMessageEditChat.verify(message.editChat);
                if (error)
                    return "editChat." + error;
            }
        }
        if (message.addPeer != null && message.hasOwnProperty("addPeer")) {
            if (properties.action === 1)
                return "action: multiple values";
            properties.action = 1;
            {
                let error = $root.MCServiceMessageAddPeers.verify(message.addPeer);
                if (error)
                    return "addPeer." + error;
            }
        }
        if (message.removePeer != null && message.hasOwnProperty("removePeer")) {
            if (properties.action === 1)
                return "action: multiple values";
            properties.action = 1;
            {
                let error = $root.MCServiceMessageRemovePeers.verify(message.removePeer);
                if (error)
                    return "removePeer." + error;
            }
        }
        if (message.pinMessage != null && message.hasOwnProperty("pinMessage")) {
            if (properties.action === 1)
                return "action: multiple values";
            properties.action = 1;
            {
                let error = $root.MCServiceMessagePinMessage.verify(message.pinMessage);
                if (error)
                    return "pinMessage." + error;
            }
        }
        if (message.migratedToSuperGroup != null && message.hasOwnProperty("migratedToSuperGroup")) {
            if (properties.action === 1)
                return "action: multiple values";
            properties.action = 1;
            {
                let error = $root.MCServiceMessageMigratedToSuperGroup.verify(message.migratedToSuperGroup);
                if (error)
                    return "migratedToSuperGroup." + error;
            }
        }
        if (message.migratedFromSuperGroup != null && message.hasOwnProperty("migratedFromSuperGroup")) {
            if (properties.action === 1)
                return "action: multiple values";
            properties.action = 1;
            {
                let error = $root.MCServiceMessageMigratedFromSuperGroup.verify(message.migratedFromSuperGroup);
                if (error)
                    return "migratedFromSuperGroup." + error;
            }
        }
        if (message.widgetUserConnected != null && message.hasOwnProperty("widgetUserConnected")) {
            if (properties.action === 1)
                return "action: multiple values";
            properties.action = 1;
            {
                let error = $root.MCServiceMessageWidgetUserConnected.verify(message.widgetUserConnected);
                if (error)
                    return "widgetUserConnected." + error;
            }
        }
        if (message.widgetUserDisconnected != null && message.hasOwnProperty("widgetUserDisconnected")) {
            if (properties.action === 1)
                return "action: multiple values";
            properties.action = 1;
            {
                let error = $root.MCServiceMessageWidgetUserDisconnected.verify(message.widgetUserDisconnected);
                if (error)
                    return "widgetUserDisconnected." + error;
            }
        }
        if (message.widgetUserRestricted != null && message.hasOwnProperty("widgetUserRestricted")) {
            if (properties.action === 1)
                return "action: multiple values";
            properties.action = 1;
            {
                let error = $root.MCServiceMessageWidgetUserRestricted.verify(message.widgetUserRestricted);
                if (error)
                    return "widgetUserRestricted." + error;
            }
        }
        if (message.widgetOperatorJoined != null && message.hasOwnProperty("widgetOperatorJoined")) {
            if (properties.action === 1)
                return "action: multiple values";
            properties.action = 1;
            {
                let error = $root.MCServiceMessageWidgetOperatorJoined.verify(message.widgetOperatorJoined);
                if (error)
                    return "widgetOperatorJoined." + error;
            }
        }
        if (message.widgetOperatorLeft != null && message.hasOwnProperty("widgetOperatorLeft")) {
            if (properties.action === 1)
                return "action: multiple values";
            properties.action = 1;
            {
                let error = $root.MCServiceMessageWidgetOperatorLeft.verify(message.widgetOperatorLeft);
                if (error)
                    return "widgetOperatorLeft." + error;
            }
        }
        if (message.widgetResolved != null && message.hasOwnProperty("widgetResolved")) {
            if (properties.action === 1)
                return "action: multiple values";
            properties.action = 1;
            {
                let error = $root.MCServiceMessageWidgetResolved.verify(message.widgetResolved);
                if (error)
                    return "widgetResolved." + error;
            }
        }
        if (message.widgetReopen != null && message.hasOwnProperty("widgetReopen")) {
            if (properties.action === 1)
                return "action: multiple values";
            properties.action = 1;
            {
                let error = $root.MCServiceMessageWidgetReopen.verify(message.widgetReopen);
                if (error)
                    return "widgetReopen." + error;
            }
        }
        if (message.legacy != null && message.hasOwnProperty("legacy")) {
            if (properties.action === 1)
                return "action: multiple values";
            properties.action = 1;
            {
                let error = $root.MCServiceMessageLegacy.verify(message.legacy);
                if (error)
                    return "legacy." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCServiceMessage message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessage
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessage} MCServiceMessage
     */
    MCServiceMessage.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessage)
            return object;
        let message = new $root.MCServiceMessage();
        if (object.createChat != null) {
            if (typeof object.createChat !== "object")
                throw TypeError(".MCServiceMessage.createChat: object expected");
            message.createChat = $root.MCServiceMessageCreateChat.fromObject(object.createChat);
        }
        if (object.editChat != null) {
            if (typeof object.editChat !== "object")
                throw TypeError(".MCServiceMessage.editChat: object expected");
            message.editChat = $root.MCServiceMessageEditChat.fromObject(object.editChat);
        }
        if (object.addPeer != null) {
            if (typeof object.addPeer !== "object")
                throw TypeError(".MCServiceMessage.addPeer: object expected");
            message.addPeer = $root.MCServiceMessageAddPeers.fromObject(object.addPeer);
        }
        if (object.removePeer != null) {
            if (typeof object.removePeer !== "object")
                throw TypeError(".MCServiceMessage.removePeer: object expected");
            message.removePeer = $root.MCServiceMessageRemovePeers.fromObject(object.removePeer);
        }
        if (object.pinMessage != null) {
            if (typeof object.pinMessage !== "object")
                throw TypeError(".MCServiceMessage.pinMessage: object expected");
            message.pinMessage = $root.MCServiceMessagePinMessage.fromObject(object.pinMessage);
        }
        if (object.migratedToSuperGroup != null) {
            if (typeof object.migratedToSuperGroup !== "object")
                throw TypeError(".MCServiceMessage.migratedToSuperGroup: object expected");
            message.migratedToSuperGroup = $root.MCServiceMessageMigratedToSuperGroup.fromObject(object.migratedToSuperGroup);
        }
        if (object.migratedFromSuperGroup != null) {
            if (typeof object.migratedFromSuperGroup !== "object")
                throw TypeError(".MCServiceMessage.migratedFromSuperGroup: object expected");
            message.migratedFromSuperGroup = $root.MCServiceMessageMigratedFromSuperGroup.fromObject(object.migratedFromSuperGroup);
        }
        if (object.widgetUserConnected != null) {
            if (typeof object.widgetUserConnected !== "object")
                throw TypeError(".MCServiceMessage.widgetUserConnected: object expected");
            message.widgetUserConnected = $root.MCServiceMessageWidgetUserConnected.fromObject(object.widgetUserConnected);
        }
        if (object.widgetUserDisconnected != null) {
            if (typeof object.widgetUserDisconnected !== "object")
                throw TypeError(".MCServiceMessage.widgetUserDisconnected: object expected");
            message.widgetUserDisconnected = $root.MCServiceMessageWidgetUserDisconnected.fromObject(object.widgetUserDisconnected);
        }
        if (object.widgetUserRestricted != null) {
            if (typeof object.widgetUserRestricted !== "object")
                throw TypeError(".MCServiceMessage.widgetUserRestricted: object expected");
            message.widgetUserRestricted = $root.MCServiceMessageWidgetUserRestricted.fromObject(object.widgetUserRestricted);
        }
        if (object.widgetOperatorJoined != null) {
            if (typeof object.widgetOperatorJoined !== "object")
                throw TypeError(".MCServiceMessage.widgetOperatorJoined: object expected");
            message.widgetOperatorJoined = $root.MCServiceMessageWidgetOperatorJoined.fromObject(object.widgetOperatorJoined);
        }
        if (object.widgetOperatorLeft != null) {
            if (typeof object.widgetOperatorLeft !== "object")
                throw TypeError(".MCServiceMessage.widgetOperatorLeft: object expected");
            message.widgetOperatorLeft = $root.MCServiceMessageWidgetOperatorLeft.fromObject(object.widgetOperatorLeft);
        }
        if (object.widgetResolved != null) {
            if (typeof object.widgetResolved !== "object")
                throw TypeError(".MCServiceMessage.widgetResolved: object expected");
            message.widgetResolved = $root.MCServiceMessageWidgetResolved.fromObject(object.widgetResolved);
        }
        if (object.widgetReopen != null) {
            if (typeof object.widgetReopen !== "object")
                throw TypeError(".MCServiceMessage.widgetReopen: object expected");
            message.widgetReopen = $root.MCServiceMessageWidgetReopen.fromObject(object.widgetReopen);
        }
        if (object.legacy != null) {
            if (typeof object.legacy !== "object")
                throw TypeError(".MCServiceMessage.legacy: object expected");
            message.legacy = $root.MCServiceMessageLegacy.fromObject(object.legacy);
        }
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessage message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessage
     * @static
     * @param {MCServiceMessage} message MCServiceMessage
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (message.createChat != null && message.hasOwnProperty("createChat")) {
            object.createChat = $root.MCServiceMessageCreateChat.toObject(message.createChat, options);
            if (options.oneofs)
                object.action = "createChat";
        }
        if (message.editChat != null && message.hasOwnProperty("editChat")) {
            object.editChat = $root.MCServiceMessageEditChat.toObject(message.editChat, options);
            if (options.oneofs)
                object.action = "editChat";
        }
        if (message.addPeer != null && message.hasOwnProperty("addPeer")) {
            object.addPeer = $root.MCServiceMessageAddPeers.toObject(message.addPeer, options);
            if (options.oneofs)
                object.action = "addPeer";
        }
        if (message.removePeer != null && message.hasOwnProperty("removePeer")) {
            object.removePeer = $root.MCServiceMessageRemovePeers.toObject(message.removePeer, options);
            if (options.oneofs)
                object.action = "removePeer";
        }
        if (message.pinMessage != null && message.hasOwnProperty("pinMessage")) {
            object.pinMessage = $root.MCServiceMessagePinMessage.toObject(message.pinMessage, options);
            if (options.oneofs)
                object.action = "pinMessage";
        }
        if (message.migratedToSuperGroup != null && message.hasOwnProperty("migratedToSuperGroup")) {
            object.migratedToSuperGroup = $root.MCServiceMessageMigratedToSuperGroup.toObject(message.migratedToSuperGroup, options);
            if (options.oneofs)
                object.action = "migratedToSuperGroup";
        }
        if (message.widgetUserConnected != null && message.hasOwnProperty("widgetUserConnected")) {
            object.widgetUserConnected = $root.MCServiceMessageWidgetUserConnected.toObject(message.widgetUserConnected, options);
            if (options.oneofs)
                object.action = "widgetUserConnected";
        }
        if (message.widgetUserDisconnected != null && message.hasOwnProperty("widgetUserDisconnected")) {
            object.widgetUserDisconnected = $root.MCServiceMessageWidgetUserDisconnected.toObject(message.widgetUserDisconnected, options);
            if (options.oneofs)
                object.action = "widgetUserDisconnected";
        }
        if (message.widgetUserRestricted != null && message.hasOwnProperty("widgetUserRestricted")) {
            object.widgetUserRestricted = $root.MCServiceMessageWidgetUserRestricted.toObject(message.widgetUserRestricted, options);
            if (options.oneofs)
                object.action = "widgetUserRestricted";
        }
        if (message.widgetOperatorJoined != null && message.hasOwnProperty("widgetOperatorJoined")) {
            object.widgetOperatorJoined = $root.MCServiceMessageWidgetOperatorJoined.toObject(message.widgetOperatorJoined, options);
            if (options.oneofs)
                object.action = "widgetOperatorJoined";
        }
        if (message.widgetOperatorLeft != null && message.hasOwnProperty("widgetOperatorLeft")) {
            object.widgetOperatorLeft = $root.MCServiceMessageWidgetOperatorLeft.toObject(message.widgetOperatorLeft, options);
            if (options.oneofs)
                object.action = "widgetOperatorLeft";
        }
        if (message.widgetResolved != null && message.hasOwnProperty("widgetResolved")) {
            object.widgetResolved = $root.MCServiceMessageWidgetResolved.toObject(message.widgetResolved, options);
            if (options.oneofs)
                object.action = "widgetResolved";
        }
        if (message.widgetReopen != null && message.hasOwnProperty("widgetReopen")) {
            object.widgetReopen = $root.MCServiceMessageWidgetReopen.toObject(message.widgetReopen, options);
            if (options.oneofs)
                object.action = "widgetReopen";
        }
        if (message.migratedFromSuperGroup != null && message.hasOwnProperty("migratedFromSuperGroup")) {
            object.migratedFromSuperGroup = $root.MCServiceMessageMigratedFromSuperGroup.toObject(message.migratedFromSuperGroup, options);
            if (options.oneofs)
                object.action = "migratedFromSuperGroup";
        }
        if (message.legacy != null && message.hasOwnProperty("legacy")) {
            object.legacy = $root.MCServiceMessageLegacy.toObject(message.legacy, options);
            if (options.oneofs)
                object.action = "legacy";
        }
        return object;
    };

    /**
     * Converts this MCServiceMessage to JSON.
     * @function toJSON
     * @memberof MCServiceMessage
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessage
     * @function getTypeUrl
     * @memberof MCServiceMessage
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessage";
    };

    return MCServiceMessage;
})();

export const MCServiceMessageCreateChat = $root.MCServiceMessageCreateChat = (() => {

    /**
     * Properties of a MCServiceMessageCreateChat.
     * @exports IMCServiceMessageCreateChat
     * @interface IMCServiceMessageCreateChat
     * @property {string|null} [title] MCServiceMessageCreateChat title
     * @property {Array.<IMCPeerPreview>|null} [peers] MCServiceMessageCreateChat peers
     */

    /**
     * Constructs a new MCServiceMessageCreateChat.
     * @exports MCServiceMessageCreateChat
     * @classdesc Represents a MCServiceMessageCreateChat.
     * @implements IMCServiceMessageCreateChat
     * @constructor
     * @param {IMCServiceMessageCreateChat=} [properties] Properties to set
     */
    function MCServiceMessageCreateChat(properties) {
        this.peers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessageCreateChat title.
     * @member {string} title
     * @memberof MCServiceMessageCreateChat
     * @instance
     */
    MCServiceMessageCreateChat.prototype.title = "";

    /**
     * MCServiceMessageCreateChat peers.
     * @member {Array.<IMCPeerPreview>} peers
     * @memberof MCServiceMessageCreateChat
     * @instance
     */
    MCServiceMessageCreateChat.prototype.peers = $util.emptyArray;

    /**
     * Creates a new MCServiceMessageCreateChat instance using the specified properties.
     * @function create
     * @memberof MCServiceMessageCreateChat
     * @static
     * @param {IMCServiceMessageCreateChat=} [properties] Properties to set
     * @returns {MCServiceMessageCreateChat} MCServiceMessageCreateChat instance
     */
    MCServiceMessageCreateChat.create = function create(properties) {
        return new MCServiceMessageCreateChat(properties);
    };

    /**
     * Encodes the specified MCServiceMessageCreateChat message. Does not implicitly {@link MCServiceMessageCreateChat.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessageCreateChat
     * @static
     * @param {IMCServiceMessageCreateChat} message MCServiceMessageCreateChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageCreateChat.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
        if (message.peers != null && message.peers.length)
            for (let i = 0; i < message.peers.length; ++i)
                $root.MCPeerPreview.encode(message.peers[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessageCreateChat message, length delimited. Does not implicitly {@link MCServiceMessageCreateChat.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessageCreateChat
     * @static
     * @param {IMCServiceMessageCreateChat} message MCServiceMessageCreateChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageCreateChat.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessageCreateChat message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessageCreateChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessageCreateChat} MCServiceMessageCreateChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageCreateChat.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessageCreateChat();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 3: {
                    message.title = reader.string();
                    break;
                }
            case 4: {
                    if (!(message.peers && message.peers.length))
                        message.peers = [];
                    message.peers.push($root.MCPeerPreview.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessageCreateChat message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessageCreateChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessageCreateChat} MCServiceMessageCreateChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageCreateChat.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessageCreateChat message.
     * @function verify
     * @memberof MCServiceMessageCreateChat
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessageCreateChat.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.title != null && message.hasOwnProperty("title"))
            if (!$util.isString(message.title))
                return "title: string expected";
        if (message.peers != null && message.hasOwnProperty("peers")) {
            if (!Array.isArray(message.peers))
                return "peers: array expected";
            for (let i = 0; i < message.peers.length; ++i) {
                let error = $root.MCPeerPreview.verify(message.peers[i]);
                if (error)
                    return "peers." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCServiceMessageCreateChat message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessageCreateChat
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessageCreateChat} MCServiceMessageCreateChat
     */
    MCServiceMessageCreateChat.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessageCreateChat)
            return object;
        let message = new $root.MCServiceMessageCreateChat();
        if (object.title != null)
            message.title = String(object.title);
        if (object.peers) {
            if (!Array.isArray(object.peers))
                throw TypeError(".MCServiceMessageCreateChat.peers: array expected");
            message.peers = [];
            for (let i = 0; i < object.peers.length; ++i) {
                if (typeof object.peers[i] !== "object")
                    throw TypeError(".MCServiceMessageCreateChat.peers: object expected");
                message.peers[i] = $root.MCPeerPreview.fromObject(object.peers[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessageCreateChat message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessageCreateChat
     * @static
     * @param {MCServiceMessageCreateChat} message MCServiceMessageCreateChat
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessageCreateChat.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.peers = [];
        if (options.defaults)
            object.title = "";
        if (message.title != null && message.hasOwnProperty("title"))
            object.title = message.title;
        if (message.peers && message.peers.length) {
            object.peers = [];
            for (let j = 0; j < message.peers.length; ++j)
                object.peers[j] = $root.MCPeerPreview.toObject(message.peers[j], options);
        }
        return object;
    };

    /**
     * Converts this MCServiceMessageCreateChat to JSON.
     * @function toJSON
     * @memberof MCServiceMessageCreateChat
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessageCreateChat.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessageCreateChat
     * @function getTypeUrl
     * @memberof MCServiceMessageCreateChat
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessageCreateChat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessageCreateChat";
    };

    return MCServiceMessageCreateChat;
})();

export const MCServiceMessageEditChat = $root.MCServiceMessageEditChat = (() => {

    /**
     * Properties of a MCServiceMessageEditChat.
     * @exports IMCServiceMessageEditChat
     * @interface IMCServiceMessageEditChat
     * @property {IMCAttachment|null} [avatar] MCServiceMessageEditChat avatar
     * @property {string|null} [title] MCServiceMessageEditChat title
     */

    /**
     * Constructs a new MCServiceMessageEditChat.
     * @exports MCServiceMessageEditChat
     * @classdesc Represents a MCServiceMessageEditChat.
     * @implements IMCServiceMessageEditChat
     * @constructor
     * @param {IMCServiceMessageEditChat=} [properties] Properties to set
     */
    function MCServiceMessageEditChat(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessageEditChat avatar.
     * @member {IMCAttachment|null|undefined} avatar
     * @memberof MCServiceMessageEditChat
     * @instance
     */
    MCServiceMessageEditChat.prototype.avatar = null;

    /**
     * MCServiceMessageEditChat title.
     * @member {string} title
     * @memberof MCServiceMessageEditChat
     * @instance
     */
    MCServiceMessageEditChat.prototype.title = "";

    /**
     * Creates a new MCServiceMessageEditChat instance using the specified properties.
     * @function create
     * @memberof MCServiceMessageEditChat
     * @static
     * @param {IMCServiceMessageEditChat=} [properties] Properties to set
     * @returns {MCServiceMessageEditChat} MCServiceMessageEditChat instance
     */
    MCServiceMessageEditChat.create = function create(properties) {
        return new MCServiceMessageEditChat(properties);
    };

    /**
     * Encodes the specified MCServiceMessageEditChat message. Does not implicitly {@link MCServiceMessageEditChat.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessageEditChat
     * @static
     * @param {IMCServiceMessageEditChat} message MCServiceMessageEditChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageEditChat.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
        if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
            $root.MCAttachment.encode(message.avatar, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessageEditChat message, length delimited. Does not implicitly {@link MCServiceMessageEditChat.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessageEditChat
     * @static
     * @param {IMCServiceMessageEditChat} message MCServiceMessageEditChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageEditChat.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessageEditChat message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessageEditChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessageEditChat} MCServiceMessageEditChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageEditChat.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessageEditChat();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 10: {
                    message.avatar = $root.MCAttachment.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.title = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessageEditChat message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessageEditChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessageEditChat} MCServiceMessageEditChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageEditChat.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessageEditChat message.
     * @function verify
     * @memberof MCServiceMessageEditChat
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessageEditChat.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.avatar != null && message.hasOwnProperty("avatar")) {
            let error = $root.MCAttachment.verify(message.avatar);
            if (error)
                return "avatar." + error;
        }
        if (message.title != null && message.hasOwnProperty("title"))
            if (!$util.isString(message.title))
                return "title: string expected";
        return null;
    };

    /**
     * Creates a MCServiceMessageEditChat message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessageEditChat
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessageEditChat} MCServiceMessageEditChat
     */
    MCServiceMessageEditChat.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessageEditChat)
            return object;
        let message = new $root.MCServiceMessageEditChat();
        if (object.avatar != null) {
            if (typeof object.avatar !== "object")
                throw TypeError(".MCServiceMessageEditChat.avatar: object expected");
            message.avatar = $root.MCAttachment.fromObject(object.avatar);
        }
        if (object.title != null)
            message.title = String(object.title);
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessageEditChat message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessageEditChat
     * @static
     * @param {MCServiceMessageEditChat} message MCServiceMessageEditChat
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessageEditChat.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.title = "";
            object.avatar = null;
        }
        if (message.title != null && message.hasOwnProperty("title"))
            object.title = message.title;
        if (message.avatar != null && message.hasOwnProperty("avatar"))
            object.avatar = $root.MCAttachment.toObject(message.avatar, options);
        return object;
    };

    /**
     * Converts this MCServiceMessageEditChat to JSON.
     * @function toJSON
     * @memberof MCServiceMessageEditChat
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessageEditChat.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessageEditChat
     * @function getTypeUrl
     * @memberof MCServiceMessageEditChat
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessageEditChat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessageEditChat";
    };

    return MCServiceMessageEditChat;
})();

export const MCServiceMessageAddPeers = $root.MCServiceMessageAddPeers = (() => {

    /**
     * Properties of a MCServiceMessageAddPeers.
     * @exports IMCServiceMessageAddPeers
     * @interface IMCServiceMessageAddPeers
     * @property {Array.<IMCPeerPreview>|null} [peers] MCServiceMessageAddPeers peers
     */

    /**
     * Constructs a new MCServiceMessageAddPeers.
     * @exports MCServiceMessageAddPeers
     * @classdesc Represents a MCServiceMessageAddPeers.
     * @implements IMCServiceMessageAddPeers
     * @constructor
     * @param {IMCServiceMessageAddPeers=} [properties] Properties to set
     */
    function MCServiceMessageAddPeers(properties) {
        this.peers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessageAddPeers peers.
     * @member {Array.<IMCPeerPreview>} peers
     * @memberof MCServiceMessageAddPeers
     * @instance
     */
    MCServiceMessageAddPeers.prototype.peers = $util.emptyArray;

    /**
     * Creates a new MCServiceMessageAddPeers instance using the specified properties.
     * @function create
     * @memberof MCServiceMessageAddPeers
     * @static
     * @param {IMCServiceMessageAddPeers=} [properties] Properties to set
     * @returns {MCServiceMessageAddPeers} MCServiceMessageAddPeers instance
     */
    MCServiceMessageAddPeers.create = function create(properties) {
        return new MCServiceMessageAddPeers(properties);
    };

    /**
     * Encodes the specified MCServiceMessageAddPeers message. Does not implicitly {@link MCServiceMessageAddPeers.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessageAddPeers
     * @static
     * @param {IMCServiceMessageAddPeers} message MCServiceMessageAddPeers message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageAddPeers.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.peers != null && message.peers.length)
            for (let i = 0; i < message.peers.length; ++i)
                $root.MCPeerPreview.encode(message.peers[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessageAddPeers message, length delimited. Does not implicitly {@link MCServiceMessageAddPeers.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessageAddPeers
     * @static
     * @param {IMCServiceMessageAddPeers} message MCServiceMessageAddPeers message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageAddPeers.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessageAddPeers message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessageAddPeers
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessageAddPeers} MCServiceMessageAddPeers
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageAddPeers.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessageAddPeers();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 2: {
                    if (!(message.peers && message.peers.length))
                        message.peers = [];
                    message.peers.push($root.MCPeerPreview.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessageAddPeers message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessageAddPeers
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessageAddPeers} MCServiceMessageAddPeers
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageAddPeers.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessageAddPeers message.
     * @function verify
     * @memberof MCServiceMessageAddPeers
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessageAddPeers.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.peers != null && message.hasOwnProperty("peers")) {
            if (!Array.isArray(message.peers))
                return "peers: array expected";
            for (let i = 0; i < message.peers.length; ++i) {
                let error = $root.MCPeerPreview.verify(message.peers[i]);
                if (error)
                    return "peers." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCServiceMessageAddPeers message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessageAddPeers
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessageAddPeers} MCServiceMessageAddPeers
     */
    MCServiceMessageAddPeers.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessageAddPeers)
            return object;
        let message = new $root.MCServiceMessageAddPeers();
        if (object.peers) {
            if (!Array.isArray(object.peers))
                throw TypeError(".MCServiceMessageAddPeers.peers: array expected");
            message.peers = [];
            for (let i = 0; i < object.peers.length; ++i) {
                if (typeof object.peers[i] !== "object")
                    throw TypeError(".MCServiceMessageAddPeers.peers: object expected");
                message.peers[i] = $root.MCPeerPreview.fromObject(object.peers[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessageAddPeers message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessageAddPeers
     * @static
     * @param {MCServiceMessageAddPeers} message MCServiceMessageAddPeers
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessageAddPeers.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.peers = [];
        if (message.peers && message.peers.length) {
            object.peers = [];
            for (let j = 0; j < message.peers.length; ++j)
                object.peers[j] = $root.MCPeerPreview.toObject(message.peers[j], options);
        }
        return object;
    };

    /**
     * Converts this MCServiceMessageAddPeers to JSON.
     * @function toJSON
     * @memberof MCServiceMessageAddPeers
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessageAddPeers.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessageAddPeers
     * @function getTypeUrl
     * @memberof MCServiceMessageAddPeers
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessageAddPeers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessageAddPeers";
    };

    return MCServiceMessageAddPeers;
})();

export const MCServiceMessageRemovePeers = $root.MCServiceMessageRemovePeers = (() => {

    /**
     * Properties of a MCServiceMessageRemovePeers.
     * @exports IMCServiceMessageRemovePeers
     * @interface IMCServiceMessageRemovePeers
     * @property {Array.<IMCPeerPreview>|null} [peers] MCServiceMessageRemovePeers peers
     */

    /**
     * Constructs a new MCServiceMessageRemovePeers.
     * @exports MCServiceMessageRemovePeers
     * @classdesc Represents a MCServiceMessageRemovePeers.
     * @implements IMCServiceMessageRemovePeers
     * @constructor
     * @param {IMCServiceMessageRemovePeers=} [properties] Properties to set
     */
    function MCServiceMessageRemovePeers(properties) {
        this.peers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessageRemovePeers peers.
     * @member {Array.<IMCPeerPreview>} peers
     * @memberof MCServiceMessageRemovePeers
     * @instance
     */
    MCServiceMessageRemovePeers.prototype.peers = $util.emptyArray;

    /**
     * Creates a new MCServiceMessageRemovePeers instance using the specified properties.
     * @function create
     * @memberof MCServiceMessageRemovePeers
     * @static
     * @param {IMCServiceMessageRemovePeers=} [properties] Properties to set
     * @returns {MCServiceMessageRemovePeers} MCServiceMessageRemovePeers instance
     */
    MCServiceMessageRemovePeers.create = function create(properties) {
        return new MCServiceMessageRemovePeers(properties);
    };

    /**
     * Encodes the specified MCServiceMessageRemovePeers message. Does not implicitly {@link MCServiceMessageRemovePeers.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessageRemovePeers
     * @static
     * @param {IMCServiceMessageRemovePeers} message MCServiceMessageRemovePeers message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageRemovePeers.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.peers != null && message.peers.length)
            for (let i = 0; i < message.peers.length; ++i)
                $root.MCPeerPreview.encode(message.peers[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessageRemovePeers message, length delimited. Does not implicitly {@link MCServiceMessageRemovePeers.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessageRemovePeers
     * @static
     * @param {IMCServiceMessageRemovePeers} message MCServiceMessageRemovePeers message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageRemovePeers.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessageRemovePeers message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessageRemovePeers
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessageRemovePeers} MCServiceMessageRemovePeers
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageRemovePeers.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessageRemovePeers();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 2: {
                    if (!(message.peers && message.peers.length))
                        message.peers = [];
                    message.peers.push($root.MCPeerPreview.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessageRemovePeers message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessageRemovePeers
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessageRemovePeers} MCServiceMessageRemovePeers
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageRemovePeers.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessageRemovePeers message.
     * @function verify
     * @memberof MCServiceMessageRemovePeers
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessageRemovePeers.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.peers != null && message.hasOwnProperty("peers")) {
            if (!Array.isArray(message.peers))
                return "peers: array expected";
            for (let i = 0; i < message.peers.length; ++i) {
                let error = $root.MCPeerPreview.verify(message.peers[i]);
                if (error)
                    return "peers." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MCServiceMessageRemovePeers message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessageRemovePeers
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessageRemovePeers} MCServiceMessageRemovePeers
     */
    MCServiceMessageRemovePeers.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessageRemovePeers)
            return object;
        let message = new $root.MCServiceMessageRemovePeers();
        if (object.peers) {
            if (!Array.isArray(object.peers))
                throw TypeError(".MCServiceMessageRemovePeers.peers: array expected");
            message.peers = [];
            for (let i = 0; i < object.peers.length; ++i) {
                if (typeof object.peers[i] !== "object")
                    throw TypeError(".MCServiceMessageRemovePeers.peers: object expected");
                message.peers[i] = $root.MCPeerPreview.fromObject(object.peers[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessageRemovePeers message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessageRemovePeers
     * @static
     * @param {MCServiceMessageRemovePeers} message MCServiceMessageRemovePeers
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessageRemovePeers.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.peers = [];
        if (message.peers && message.peers.length) {
            object.peers = [];
            for (let j = 0; j < message.peers.length; ++j)
                object.peers[j] = $root.MCPeerPreview.toObject(message.peers[j], options);
        }
        return object;
    };

    /**
     * Converts this MCServiceMessageRemovePeers to JSON.
     * @function toJSON
     * @memberof MCServiceMessageRemovePeers
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessageRemovePeers.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessageRemovePeers
     * @function getTypeUrl
     * @memberof MCServiceMessageRemovePeers
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessageRemovePeers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessageRemovePeers";
    };

    return MCServiceMessageRemovePeers;
})();

export const MCServiceMessagePinMessage = $root.MCServiceMessagePinMessage = (() => {

    /**
     * Properties of a MCServiceMessagePinMessage.
     * @exports IMCServiceMessagePinMessage
     * @interface IMCServiceMessagePinMessage
     * @property {Long|null} [messageID] MCServiceMessagePinMessage messageID
     */

    /**
     * Constructs a new MCServiceMessagePinMessage.
     * @exports MCServiceMessagePinMessage
     * @classdesc Represents a MCServiceMessagePinMessage.
     * @implements IMCServiceMessagePinMessage
     * @constructor
     * @param {IMCServiceMessagePinMessage=} [properties] Properties to set
     */
    function MCServiceMessagePinMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessagePinMessage messageID.
     * @member {Long} messageID
     * @memberof MCServiceMessagePinMessage
     * @instance
     */
    MCServiceMessagePinMessage.prototype.messageID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCServiceMessagePinMessage instance using the specified properties.
     * @function create
     * @memberof MCServiceMessagePinMessage
     * @static
     * @param {IMCServiceMessagePinMessage=} [properties] Properties to set
     * @returns {MCServiceMessagePinMessage} MCServiceMessagePinMessage instance
     */
    MCServiceMessagePinMessage.create = function create(properties) {
        return new MCServiceMessagePinMessage(properties);
    };

    /**
     * Encodes the specified MCServiceMessagePinMessage message. Does not implicitly {@link MCServiceMessagePinMessage.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessagePinMessage
     * @static
     * @param {IMCServiceMessagePinMessage} message MCServiceMessagePinMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessagePinMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.messageID != null && Object.hasOwnProperty.call(message, "messageID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.messageID);
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessagePinMessage message, length delimited. Does not implicitly {@link MCServiceMessagePinMessage.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessagePinMessage
     * @static
     * @param {IMCServiceMessagePinMessage} message MCServiceMessagePinMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessagePinMessage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessagePinMessage message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessagePinMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessagePinMessage} MCServiceMessagePinMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessagePinMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessagePinMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.messageID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessagePinMessage message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessagePinMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessagePinMessage} MCServiceMessagePinMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessagePinMessage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessagePinMessage message.
     * @function verify
     * @memberof MCServiceMessagePinMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessagePinMessage.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.messageID != null && message.hasOwnProperty("messageID"))
            if (!$util.isInteger(message.messageID) && !(message.messageID && $util.isInteger(message.messageID.low) && $util.isInteger(message.messageID.high)))
                return "messageID: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCServiceMessagePinMessage message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessagePinMessage
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessagePinMessage} MCServiceMessagePinMessage
     */
    MCServiceMessagePinMessage.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessagePinMessage)
            return object;
        let message = new $root.MCServiceMessagePinMessage();
        if (object.messageID != null)
            if ($util.Long)
                (message.messageID = $util.Long.fromValue(object.messageID)).unsigned = true;
            else if (typeof object.messageID === "string")
                message.messageID = parseInt(object.messageID, 10);
            else if (typeof object.messageID === "number")
                message.messageID = object.messageID;
            else if (typeof object.messageID === "object")
                message.messageID = new $util.LongBits(object.messageID.low >>> 0, object.messageID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessagePinMessage message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessagePinMessage
     * @static
     * @param {MCServiceMessagePinMessage} message MCServiceMessagePinMessage
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessagePinMessage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.messageID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.messageID = options.longs === String ? "0" : 0;
        if (message.messageID != null && message.hasOwnProperty("messageID"))
            if (typeof message.messageID === "number")
                object.messageID = options.longs === String ? String(message.messageID) : message.messageID;
            else
                object.messageID = options.longs === String ? $util.Long.prototype.toString.call(message.messageID) : options.longs === Number ? new $util.LongBits(message.messageID.low >>> 0, message.messageID.high >>> 0).toNumber(true) : message.messageID;
        return object;
    };

    /**
     * Converts this MCServiceMessagePinMessage to JSON.
     * @function toJSON
     * @memberof MCServiceMessagePinMessage
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessagePinMessage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessagePinMessage
     * @function getTypeUrl
     * @memberof MCServiceMessagePinMessage
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessagePinMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessagePinMessage";
    };

    return MCServiceMessagePinMessage;
})();

export const MCServiceMessageMigratedToSuperGroup = $root.MCServiceMessageMigratedToSuperGroup = (() => {

    /**
     * Properties of a MCServiceMessageMigratedToSuperGroup.
     * @exports IMCServiceMessageMigratedToSuperGroup
     * @interface IMCServiceMessageMigratedToSuperGroup
     * @property {boolean|null} [migrated] MCServiceMessageMigratedToSuperGroup migrated
     */

    /**
     * Constructs a new MCServiceMessageMigratedToSuperGroup.
     * @exports MCServiceMessageMigratedToSuperGroup
     * @classdesc Represents a MCServiceMessageMigratedToSuperGroup.
     * @implements IMCServiceMessageMigratedToSuperGroup
     * @constructor
     * @param {IMCServiceMessageMigratedToSuperGroup=} [properties] Properties to set
     */
    function MCServiceMessageMigratedToSuperGroup(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessageMigratedToSuperGroup migrated.
     * @member {boolean} migrated
     * @memberof MCServiceMessageMigratedToSuperGroup
     * @instance
     */
    MCServiceMessageMigratedToSuperGroup.prototype.migrated = false;

    /**
     * Creates a new MCServiceMessageMigratedToSuperGroup instance using the specified properties.
     * @function create
     * @memberof MCServiceMessageMigratedToSuperGroup
     * @static
     * @param {IMCServiceMessageMigratedToSuperGroup=} [properties] Properties to set
     * @returns {MCServiceMessageMigratedToSuperGroup} MCServiceMessageMigratedToSuperGroup instance
     */
    MCServiceMessageMigratedToSuperGroup.create = function create(properties) {
        return new MCServiceMessageMigratedToSuperGroup(properties);
    };

    /**
     * Encodes the specified MCServiceMessageMigratedToSuperGroup message. Does not implicitly {@link MCServiceMessageMigratedToSuperGroup.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessageMigratedToSuperGroup
     * @static
     * @param {IMCServiceMessageMigratedToSuperGroup} message MCServiceMessageMigratedToSuperGroup message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageMigratedToSuperGroup.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.migrated != null && Object.hasOwnProperty.call(message, "migrated"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.migrated);
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessageMigratedToSuperGroup message, length delimited. Does not implicitly {@link MCServiceMessageMigratedToSuperGroup.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessageMigratedToSuperGroup
     * @static
     * @param {IMCServiceMessageMigratedToSuperGroup} message MCServiceMessageMigratedToSuperGroup message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageMigratedToSuperGroup.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessageMigratedToSuperGroup message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessageMigratedToSuperGroup
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessageMigratedToSuperGroup} MCServiceMessageMigratedToSuperGroup
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageMigratedToSuperGroup.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessageMigratedToSuperGroup();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.migrated = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessageMigratedToSuperGroup message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessageMigratedToSuperGroup
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessageMigratedToSuperGroup} MCServiceMessageMigratedToSuperGroup
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageMigratedToSuperGroup.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessageMigratedToSuperGroup message.
     * @function verify
     * @memberof MCServiceMessageMigratedToSuperGroup
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessageMigratedToSuperGroup.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.migrated != null && message.hasOwnProperty("migrated"))
            if (typeof message.migrated !== "boolean")
                return "migrated: boolean expected";
        return null;
    };

    /**
     * Creates a MCServiceMessageMigratedToSuperGroup message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessageMigratedToSuperGroup
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessageMigratedToSuperGroup} MCServiceMessageMigratedToSuperGroup
     */
    MCServiceMessageMigratedToSuperGroup.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessageMigratedToSuperGroup)
            return object;
        let message = new $root.MCServiceMessageMigratedToSuperGroup();
        if (object.migrated != null)
            message.migrated = Boolean(object.migrated);
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessageMigratedToSuperGroup message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessageMigratedToSuperGroup
     * @static
     * @param {MCServiceMessageMigratedToSuperGroup} message MCServiceMessageMigratedToSuperGroup
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessageMigratedToSuperGroup.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.migrated = false;
        if (message.migrated != null && message.hasOwnProperty("migrated"))
            object.migrated = message.migrated;
        return object;
    };

    /**
     * Converts this MCServiceMessageMigratedToSuperGroup to JSON.
     * @function toJSON
     * @memberof MCServiceMessageMigratedToSuperGroup
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessageMigratedToSuperGroup.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessageMigratedToSuperGroup
     * @function getTypeUrl
     * @memberof MCServiceMessageMigratedToSuperGroup
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessageMigratedToSuperGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessageMigratedToSuperGroup";
    };

    return MCServiceMessageMigratedToSuperGroup;
})();

export const MCServiceMessageMigratedFromSuperGroup = $root.MCServiceMessageMigratedFromSuperGroup = (() => {

    /**
     * Properties of a MCServiceMessageMigratedFromSuperGroup.
     * @exports IMCServiceMessageMigratedFromSuperGroup
     * @interface IMCServiceMessageMigratedFromSuperGroup
     * @property {boolean|null} [migrated] MCServiceMessageMigratedFromSuperGroup migrated
     */

    /**
     * Constructs a new MCServiceMessageMigratedFromSuperGroup.
     * @exports MCServiceMessageMigratedFromSuperGroup
     * @classdesc Represents a MCServiceMessageMigratedFromSuperGroup.
     * @implements IMCServiceMessageMigratedFromSuperGroup
     * @constructor
     * @param {IMCServiceMessageMigratedFromSuperGroup=} [properties] Properties to set
     */
    function MCServiceMessageMigratedFromSuperGroup(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessageMigratedFromSuperGroup migrated.
     * @member {boolean} migrated
     * @memberof MCServiceMessageMigratedFromSuperGroup
     * @instance
     */
    MCServiceMessageMigratedFromSuperGroup.prototype.migrated = false;

    /**
     * Creates a new MCServiceMessageMigratedFromSuperGroup instance using the specified properties.
     * @function create
     * @memberof MCServiceMessageMigratedFromSuperGroup
     * @static
     * @param {IMCServiceMessageMigratedFromSuperGroup=} [properties] Properties to set
     * @returns {MCServiceMessageMigratedFromSuperGroup} MCServiceMessageMigratedFromSuperGroup instance
     */
    MCServiceMessageMigratedFromSuperGroup.create = function create(properties) {
        return new MCServiceMessageMigratedFromSuperGroup(properties);
    };

    /**
     * Encodes the specified MCServiceMessageMigratedFromSuperGroup message. Does not implicitly {@link MCServiceMessageMigratedFromSuperGroup.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessageMigratedFromSuperGroup
     * @static
     * @param {IMCServiceMessageMigratedFromSuperGroup} message MCServiceMessageMigratedFromSuperGroup message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageMigratedFromSuperGroup.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.migrated != null && Object.hasOwnProperty.call(message, "migrated"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.migrated);
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessageMigratedFromSuperGroup message, length delimited. Does not implicitly {@link MCServiceMessageMigratedFromSuperGroup.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessageMigratedFromSuperGroup
     * @static
     * @param {IMCServiceMessageMigratedFromSuperGroup} message MCServiceMessageMigratedFromSuperGroup message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageMigratedFromSuperGroup.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessageMigratedFromSuperGroup message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessageMigratedFromSuperGroup
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessageMigratedFromSuperGroup} MCServiceMessageMigratedFromSuperGroup
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageMigratedFromSuperGroup.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessageMigratedFromSuperGroup();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.migrated = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessageMigratedFromSuperGroup message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessageMigratedFromSuperGroup
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessageMigratedFromSuperGroup} MCServiceMessageMigratedFromSuperGroup
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageMigratedFromSuperGroup.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessageMigratedFromSuperGroup message.
     * @function verify
     * @memberof MCServiceMessageMigratedFromSuperGroup
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessageMigratedFromSuperGroup.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.migrated != null && message.hasOwnProperty("migrated"))
            if (typeof message.migrated !== "boolean")
                return "migrated: boolean expected";
        return null;
    };

    /**
     * Creates a MCServiceMessageMigratedFromSuperGroup message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessageMigratedFromSuperGroup
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessageMigratedFromSuperGroup} MCServiceMessageMigratedFromSuperGroup
     */
    MCServiceMessageMigratedFromSuperGroup.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessageMigratedFromSuperGroup)
            return object;
        let message = new $root.MCServiceMessageMigratedFromSuperGroup();
        if (object.migrated != null)
            message.migrated = Boolean(object.migrated);
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessageMigratedFromSuperGroup message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessageMigratedFromSuperGroup
     * @static
     * @param {MCServiceMessageMigratedFromSuperGroup} message MCServiceMessageMigratedFromSuperGroup
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessageMigratedFromSuperGroup.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.migrated = false;
        if (message.migrated != null && message.hasOwnProperty("migrated"))
            object.migrated = message.migrated;
        return object;
    };

    /**
     * Converts this MCServiceMessageMigratedFromSuperGroup to JSON.
     * @function toJSON
     * @memberof MCServiceMessageMigratedFromSuperGroup
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessageMigratedFromSuperGroup.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessageMigratedFromSuperGroup
     * @function getTypeUrl
     * @memberof MCServiceMessageMigratedFromSuperGroup
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessageMigratedFromSuperGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessageMigratedFromSuperGroup";
    };

    return MCServiceMessageMigratedFromSuperGroup;
})();

export const MCServiceMessageWidgetUserConnected = $root.MCServiceMessageWidgetUserConnected = (() => {

    /**
     * Properties of a MCServiceMessageWidgetUserConnected.
     * @exports IMCServiceMessageWidgetUserConnected
     * @interface IMCServiceMessageWidgetUserConnected
     * @property {boolean|null} [connected] MCServiceMessageWidgetUserConnected connected
     */

    /**
     * Constructs a new MCServiceMessageWidgetUserConnected.
     * @exports MCServiceMessageWidgetUserConnected
     * @classdesc Represents a MCServiceMessageWidgetUserConnected.
     * @implements IMCServiceMessageWidgetUserConnected
     * @constructor
     * @param {IMCServiceMessageWidgetUserConnected=} [properties] Properties to set
     */
    function MCServiceMessageWidgetUserConnected(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessageWidgetUserConnected connected.
     * @member {boolean} connected
     * @memberof MCServiceMessageWidgetUserConnected
     * @instance
     */
    MCServiceMessageWidgetUserConnected.prototype.connected = false;

    /**
     * Creates a new MCServiceMessageWidgetUserConnected instance using the specified properties.
     * @function create
     * @memberof MCServiceMessageWidgetUserConnected
     * @static
     * @param {IMCServiceMessageWidgetUserConnected=} [properties] Properties to set
     * @returns {MCServiceMessageWidgetUserConnected} MCServiceMessageWidgetUserConnected instance
     */
    MCServiceMessageWidgetUserConnected.create = function create(properties) {
        return new MCServiceMessageWidgetUserConnected(properties);
    };

    /**
     * Encodes the specified MCServiceMessageWidgetUserConnected message. Does not implicitly {@link MCServiceMessageWidgetUserConnected.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessageWidgetUserConnected
     * @static
     * @param {IMCServiceMessageWidgetUserConnected} message MCServiceMessageWidgetUserConnected message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageWidgetUserConnected.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.connected != null && Object.hasOwnProperty.call(message, "connected"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.connected);
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessageWidgetUserConnected message, length delimited. Does not implicitly {@link MCServiceMessageWidgetUserConnected.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessageWidgetUserConnected
     * @static
     * @param {IMCServiceMessageWidgetUserConnected} message MCServiceMessageWidgetUserConnected message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageWidgetUserConnected.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessageWidgetUserConnected message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessageWidgetUserConnected
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessageWidgetUserConnected} MCServiceMessageWidgetUserConnected
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageWidgetUserConnected.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessageWidgetUserConnected();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.connected = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessageWidgetUserConnected message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessageWidgetUserConnected
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessageWidgetUserConnected} MCServiceMessageWidgetUserConnected
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageWidgetUserConnected.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessageWidgetUserConnected message.
     * @function verify
     * @memberof MCServiceMessageWidgetUserConnected
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessageWidgetUserConnected.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.connected != null && message.hasOwnProperty("connected"))
            if (typeof message.connected !== "boolean")
                return "connected: boolean expected";
        return null;
    };

    /**
     * Creates a MCServiceMessageWidgetUserConnected message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessageWidgetUserConnected
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessageWidgetUserConnected} MCServiceMessageWidgetUserConnected
     */
    MCServiceMessageWidgetUserConnected.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessageWidgetUserConnected)
            return object;
        let message = new $root.MCServiceMessageWidgetUserConnected();
        if (object.connected != null)
            message.connected = Boolean(object.connected);
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessageWidgetUserConnected message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessageWidgetUserConnected
     * @static
     * @param {MCServiceMessageWidgetUserConnected} message MCServiceMessageWidgetUserConnected
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessageWidgetUserConnected.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.connected = false;
        if (message.connected != null && message.hasOwnProperty("connected"))
            object.connected = message.connected;
        return object;
    };

    /**
     * Converts this MCServiceMessageWidgetUserConnected to JSON.
     * @function toJSON
     * @memberof MCServiceMessageWidgetUserConnected
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessageWidgetUserConnected.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessageWidgetUserConnected
     * @function getTypeUrl
     * @memberof MCServiceMessageWidgetUserConnected
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessageWidgetUserConnected.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessageWidgetUserConnected";
    };

    return MCServiceMessageWidgetUserConnected;
})();

export const MCServiceMessageWidgetUserDisconnected = $root.MCServiceMessageWidgetUserDisconnected = (() => {

    /**
     * Properties of a MCServiceMessageWidgetUserDisconnected.
     * @exports IMCServiceMessageWidgetUserDisconnected
     * @interface IMCServiceMessageWidgetUserDisconnected
     * @property {boolean|null} [disconnected] MCServiceMessageWidgetUserDisconnected disconnected
     */

    /**
     * Constructs a new MCServiceMessageWidgetUserDisconnected.
     * @exports MCServiceMessageWidgetUserDisconnected
     * @classdesc Represents a MCServiceMessageWidgetUserDisconnected.
     * @implements IMCServiceMessageWidgetUserDisconnected
     * @constructor
     * @param {IMCServiceMessageWidgetUserDisconnected=} [properties] Properties to set
     */
    function MCServiceMessageWidgetUserDisconnected(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessageWidgetUserDisconnected disconnected.
     * @member {boolean} disconnected
     * @memberof MCServiceMessageWidgetUserDisconnected
     * @instance
     */
    MCServiceMessageWidgetUserDisconnected.prototype.disconnected = false;

    /**
     * Creates a new MCServiceMessageWidgetUserDisconnected instance using the specified properties.
     * @function create
     * @memberof MCServiceMessageWidgetUserDisconnected
     * @static
     * @param {IMCServiceMessageWidgetUserDisconnected=} [properties] Properties to set
     * @returns {MCServiceMessageWidgetUserDisconnected} MCServiceMessageWidgetUserDisconnected instance
     */
    MCServiceMessageWidgetUserDisconnected.create = function create(properties) {
        return new MCServiceMessageWidgetUserDisconnected(properties);
    };

    /**
     * Encodes the specified MCServiceMessageWidgetUserDisconnected message. Does not implicitly {@link MCServiceMessageWidgetUserDisconnected.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessageWidgetUserDisconnected
     * @static
     * @param {IMCServiceMessageWidgetUserDisconnected} message MCServiceMessageWidgetUserDisconnected message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageWidgetUserDisconnected.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.disconnected != null && Object.hasOwnProperty.call(message, "disconnected"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.disconnected);
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessageWidgetUserDisconnected message, length delimited. Does not implicitly {@link MCServiceMessageWidgetUserDisconnected.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessageWidgetUserDisconnected
     * @static
     * @param {IMCServiceMessageWidgetUserDisconnected} message MCServiceMessageWidgetUserDisconnected message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageWidgetUserDisconnected.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessageWidgetUserDisconnected message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessageWidgetUserDisconnected
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessageWidgetUserDisconnected} MCServiceMessageWidgetUserDisconnected
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageWidgetUserDisconnected.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessageWidgetUserDisconnected();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.disconnected = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessageWidgetUserDisconnected message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessageWidgetUserDisconnected
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessageWidgetUserDisconnected} MCServiceMessageWidgetUserDisconnected
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageWidgetUserDisconnected.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessageWidgetUserDisconnected message.
     * @function verify
     * @memberof MCServiceMessageWidgetUserDisconnected
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessageWidgetUserDisconnected.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.disconnected != null && message.hasOwnProperty("disconnected"))
            if (typeof message.disconnected !== "boolean")
                return "disconnected: boolean expected";
        return null;
    };

    /**
     * Creates a MCServiceMessageWidgetUserDisconnected message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessageWidgetUserDisconnected
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessageWidgetUserDisconnected} MCServiceMessageWidgetUserDisconnected
     */
    MCServiceMessageWidgetUserDisconnected.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessageWidgetUserDisconnected)
            return object;
        let message = new $root.MCServiceMessageWidgetUserDisconnected();
        if (object.disconnected != null)
            message.disconnected = Boolean(object.disconnected);
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessageWidgetUserDisconnected message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessageWidgetUserDisconnected
     * @static
     * @param {MCServiceMessageWidgetUserDisconnected} message MCServiceMessageWidgetUserDisconnected
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessageWidgetUserDisconnected.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.disconnected = false;
        if (message.disconnected != null && message.hasOwnProperty("disconnected"))
            object.disconnected = message.disconnected;
        return object;
    };

    /**
     * Converts this MCServiceMessageWidgetUserDisconnected to JSON.
     * @function toJSON
     * @memberof MCServiceMessageWidgetUserDisconnected
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessageWidgetUserDisconnected.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessageWidgetUserDisconnected
     * @function getTypeUrl
     * @memberof MCServiceMessageWidgetUserDisconnected
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessageWidgetUserDisconnected.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessageWidgetUserDisconnected";
    };

    return MCServiceMessageWidgetUserDisconnected;
})();

export const MCServiceMessageWidgetUserRestricted = $root.MCServiceMessageWidgetUserRestricted = (() => {

    /**
     * Properties of a MCServiceMessageWidgetUserRestricted.
     * @exports IMCServiceMessageWidgetUserRestricted
     * @interface IMCServiceMessageWidgetUserRestricted
     * @property {Long|null} [peerID] MCServiceMessageWidgetUserRestricted peerID
     * @property {Long|null} [until] MCServiceMessageWidgetUserRestricted until
     */

    /**
     * Constructs a new MCServiceMessageWidgetUserRestricted.
     * @exports MCServiceMessageWidgetUserRestricted
     * @classdesc Represents a MCServiceMessageWidgetUserRestricted.
     * @implements IMCServiceMessageWidgetUserRestricted
     * @constructor
     * @param {IMCServiceMessageWidgetUserRestricted=} [properties] Properties to set
     */
    function MCServiceMessageWidgetUserRestricted(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessageWidgetUserRestricted peerID.
     * @member {Long} peerID
     * @memberof MCServiceMessageWidgetUserRestricted
     * @instance
     */
    MCServiceMessageWidgetUserRestricted.prototype.peerID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCServiceMessageWidgetUserRestricted until.
     * @member {Long} until
     * @memberof MCServiceMessageWidgetUserRestricted
     * @instance
     */
    MCServiceMessageWidgetUserRestricted.prototype.until = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCServiceMessageWidgetUserRestricted instance using the specified properties.
     * @function create
     * @memberof MCServiceMessageWidgetUserRestricted
     * @static
     * @param {IMCServiceMessageWidgetUserRestricted=} [properties] Properties to set
     * @returns {MCServiceMessageWidgetUserRestricted} MCServiceMessageWidgetUserRestricted instance
     */
    MCServiceMessageWidgetUserRestricted.create = function create(properties) {
        return new MCServiceMessageWidgetUserRestricted(properties);
    };

    /**
     * Encodes the specified MCServiceMessageWidgetUserRestricted message. Does not implicitly {@link MCServiceMessageWidgetUserRestricted.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessageWidgetUserRestricted
     * @static
     * @param {IMCServiceMessageWidgetUserRestricted} message MCServiceMessageWidgetUserRestricted message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageWidgetUserRestricted.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.peerID != null && Object.hasOwnProperty.call(message, "peerID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.peerID);
        if (message.until != null && Object.hasOwnProperty.call(message, "until"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.until);
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessageWidgetUserRestricted message, length delimited. Does not implicitly {@link MCServiceMessageWidgetUserRestricted.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessageWidgetUserRestricted
     * @static
     * @param {IMCServiceMessageWidgetUserRestricted} message MCServiceMessageWidgetUserRestricted message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageWidgetUserRestricted.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessageWidgetUserRestricted message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessageWidgetUserRestricted
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessageWidgetUserRestricted} MCServiceMessageWidgetUserRestricted
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageWidgetUserRestricted.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessageWidgetUserRestricted();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.peerID = reader.uint64();
                    break;
                }
            case 2: {
                    message.until = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessageWidgetUserRestricted message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessageWidgetUserRestricted
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessageWidgetUserRestricted} MCServiceMessageWidgetUserRestricted
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageWidgetUserRestricted.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessageWidgetUserRestricted message.
     * @function verify
     * @memberof MCServiceMessageWidgetUserRestricted
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessageWidgetUserRestricted.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.peerID != null && message.hasOwnProperty("peerID"))
            if (!$util.isInteger(message.peerID) && !(message.peerID && $util.isInteger(message.peerID.low) && $util.isInteger(message.peerID.high)))
                return "peerID: integer|Long expected";
        if (message.until != null && message.hasOwnProperty("until"))
            if (!$util.isInteger(message.until) && !(message.until && $util.isInteger(message.until.low) && $util.isInteger(message.until.high)))
                return "until: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCServiceMessageWidgetUserRestricted message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessageWidgetUserRestricted
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessageWidgetUserRestricted} MCServiceMessageWidgetUserRestricted
     */
    MCServiceMessageWidgetUserRestricted.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessageWidgetUserRestricted)
            return object;
        let message = new $root.MCServiceMessageWidgetUserRestricted();
        if (object.peerID != null)
            if ($util.Long)
                (message.peerID = $util.Long.fromValue(object.peerID)).unsigned = true;
            else if (typeof object.peerID === "string")
                message.peerID = parseInt(object.peerID, 10);
            else if (typeof object.peerID === "number")
                message.peerID = object.peerID;
            else if (typeof object.peerID === "object")
                message.peerID = new $util.LongBits(object.peerID.low >>> 0, object.peerID.high >>> 0).toNumber(true);
        if (object.until != null)
            if ($util.Long)
                (message.until = $util.Long.fromValue(object.until)).unsigned = true;
            else if (typeof object.until === "string")
                message.until = parseInt(object.until, 10);
            else if (typeof object.until === "number")
                message.until = object.until;
            else if (typeof object.until === "object")
                message.until = new $util.LongBits(object.until.low >>> 0, object.until.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessageWidgetUserRestricted message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessageWidgetUserRestricted
     * @static
     * @param {MCServiceMessageWidgetUserRestricted} message MCServiceMessageWidgetUserRestricted
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessageWidgetUserRestricted.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.peerID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.peerID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.until = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.until = options.longs === String ? "0" : 0;
        }
        if (message.peerID != null && message.hasOwnProperty("peerID"))
            if (typeof message.peerID === "number")
                object.peerID = options.longs === String ? String(message.peerID) : message.peerID;
            else
                object.peerID = options.longs === String ? $util.Long.prototype.toString.call(message.peerID) : options.longs === Number ? new $util.LongBits(message.peerID.low >>> 0, message.peerID.high >>> 0).toNumber(true) : message.peerID;
        if (message.until != null && message.hasOwnProperty("until"))
            if (typeof message.until === "number")
                object.until = options.longs === String ? String(message.until) : message.until;
            else
                object.until = options.longs === String ? $util.Long.prototype.toString.call(message.until) : options.longs === Number ? new $util.LongBits(message.until.low >>> 0, message.until.high >>> 0).toNumber(true) : message.until;
        return object;
    };

    /**
     * Converts this MCServiceMessageWidgetUserRestricted to JSON.
     * @function toJSON
     * @memberof MCServiceMessageWidgetUserRestricted
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessageWidgetUserRestricted.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessageWidgetUserRestricted
     * @function getTypeUrl
     * @memberof MCServiceMessageWidgetUserRestricted
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessageWidgetUserRestricted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessageWidgetUserRestricted";
    };

    return MCServiceMessageWidgetUserRestricted;
})();

export const MCServiceMessageWidgetOperatorJoined = $root.MCServiceMessageWidgetOperatorJoined = (() => {

    /**
     * Properties of a MCServiceMessageWidgetOperatorJoined.
     * @exports IMCServiceMessageWidgetOperatorJoined
     * @interface IMCServiceMessageWidgetOperatorJoined
     * @property {Long|null} [operatorID] MCServiceMessageWidgetOperatorJoined operatorID
     */

    /**
     * Constructs a new MCServiceMessageWidgetOperatorJoined.
     * @exports MCServiceMessageWidgetOperatorJoined
     * @classdesc Represents a MCServiceMessageWidgetOperatorJoined.
     * @implements IMCServiceMessageWidgetOperatorJoined
     * @constructor
     * @param {IMCServiceMessageWidgetOperatorJoined=} [properties] Properties to set
     */
    function MCServiceMessageWidgetOperatorJoined(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessageWidgetOperatorJoined operatorID.
     * @member {Long} operatorID
     * @memberof MCServiceMessageWidgetOperatorJoined
     * @instance
     */
    MCServiceMessageWidgetOperatorJoined.prototype.operatorID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCServiceMessageWidgetOperatorJoined instance using the specified properties.
     * @function create
     * @memberof MCServiceMessageWidgetOperatorJoined
     * @static
     * @param {IMCServiceMessageWidgetOperatorJoined=} [properties] Properties to set
     * @returns {MCServiceMessageWidgetOperatorJoined} MCServiceMessageWidgetOperatorJoined instance
     */
    MCServiceMessageWidgetOperatorJoined.create = function create(properties) {
        return new MCServiceMessageWidgetOperatorJoined(properties);
    };

    /**
     * Encodes the specified MCServiceMessageWidgetOperatorJoined message. Does not implicitly {@link MCServiceMessageWidgetOperatorJoined.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessageWidgetOperatorJoined
     * @static
     * @param {IMCServiceMessageWidgetOperatorJoined} message MCServiceMessageWidgetOperatorJoined message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageWidgetOperatorJoined.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.operatorID != null && Object.hasOwnProperty.call(message, "operatorID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.operatorID);
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessageWidgetOperatorJoined message, length delimited. Does not implicitly {@link MCServiceMessageWidgetOperatorJoined.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessageWidgetOperatorJoined
     * @static
     * @param {IMCServiceMessageWidgetOperatorJoined} message MCServiceMessageWidgetOperatorJoined message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageWidgetOperatorJoined.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessageWidgetOperatorJoined message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessageWidgetOperatorJoined
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessageWidgetOperatorJoined} MCServiceMessageWidgetOperatorJoined
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageWidgetOperatorJoined.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessageWidgetOperatorJoined();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.operatorID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessageWidgetOperatorJoined message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessageWidgetOperatorJoined
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessageWidgetOperatorJoined} MCServiceMessageWidgetOperatorJoined
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageWidgetOperatorJoined.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessageWidgetOperatorJoined message.
     * @function verify
     * @memberof MCServiceMessageWidgetOperatorJoined
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessageWidgetOperatorJoined.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (!$util.isInteger(message.operatorID) && !(message.operatorID && $util.isInteger(message.operatorID.low) && $util.isInteger(message.operatorID.high)))
                return "operatorID: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCServiceMessageWidgetOperatorJoined message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessageWidgetOperatorJoined
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessageWidgetOperatorJoined} MCServiceMessageWidgetOperatorJoined
     */
    MCServiceMessageWidgetOperatorJoined.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessageWidgetOperatorJoined)
            return object;
        let message = new $root.MCServiceMessageWidgetOperatorJoined();
        if (object.operatorID != null)
            if ($util.Long)
                (message.operatorID = $util.Long.fromValue(object.operatorID)).unsigned = true;
            else if (typeof object.operatorID === "string")
                message.operatorID = parseInt(object.operatorID, 10);
            else if (typeof object.operatorID === "number")
                message.operatorID = object.operatorID;
            else if (typeof object.operatorID === "object")
                message.operatorID = new $util.LongBits(object.operatorID.low >>> 0, object.operatorID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessageWidgetOperatorJoined message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessageWidgetOperatorJoined
     * @static
     * @param {MCServiceMessageWidgetOperatorJoined} message MCServiceMessageWidgetOperatorJoined
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessageWidgetOperatorJoined.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.operatorID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.operatorID = options.longs === String ? "0" : 0;
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (typeof message.operatorID === "number")
                object.operatorID = options.longs === String ? String(message.operatorID) : message.operatorID;
            else
                object.operatorID = options.longs === String ? $util.Long.prototype.toString.call(message.operatorID) : options.longs === Number ? new $util.LongBits(message.operatorID.low >>> 0, message.operatorID.high >>> 0).toNumber(true) : message.operatorID;
        return object;
    };

    /**
     * Converts this MCServiceMessageWidgetOperatorJoined to JSON.
     * @function toJSON
     * @memberof MCServiceMessageWidgetOperatorJoined
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessageWidgetOperatorJoined.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessageWidgetOperatorJoined
     * @function getTypeUrl
     * @memberof MCServiceMessageWidgetOperatorJoined
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessageWidgetOperatorJoined.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessageWidgetOperatorJoined";
    };

    return MCServiceMessageWidgetOperatorJoined;
})();

export const MCServiceMessageWidgetOperatorLeft = $root.MCServiceMessageWidgetOperatorLeft = (() => {

    /**
     * Properties of a MCServiceMessageWidgetOperatorLeft.
     * @exports IMCServiceMessageWidgetOperatorLeft
     * @interface IMCServiceMessageWidgetOperatorLeft
     * @property {Long|null} [operatorID] MCServiceMessageWidgetOperatorLeft operatorID
     */

    /**
     * Constructs a new MCServiceMessageWidgetOperatorLeft.
     * @exports MCServiceMessageWidgetOperatorLeft
     * @classdesc Represents a MCServiceMessageWidgetOperatorLeft.
     * @implements IMCServiceMessageWidgetOperatorLeft
     * @constructor
     * @param {IMCServiceMessageWidgetOperatorLeft=} [properties] Properties to set
     */
    function MCServiceMessageWidgetOperatorLeft(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessageWidgetOperatorLeft operatorID.
     * @member {Long} operatorID
     * @memberof MCServiceMessageWidgetOperatorLeft
     * @instance
     */
    MCServiceMessageWidgetOperatorLeft.prototype.operatorID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCServiceMessageWidgetOperatorLeft instance using the specified properties.
     * @function create
     * @memberof MCServiceMessageWidgetOperatorLeft
     * @static
     * @param {IMCServiceMessageWidgetOperatorLeft=} [properties] Properties to set
     * @returns {MCServiceMessageWidgetOperatorLeft} MCServiceMessageWidgetOperatorLeft instance
     */
    MCServiceMessageWidgetOperatorLeft.create = function create(properties) {
        return new MCServiceMessageWidgetOperatorLeft(properties);
    };

    /**
     * Encodes the specified MCServiceMessageWidgetOperatorLeft message. Does not implicitly {@link MCServiceMessageWidgetOperatorLeft.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessageWidgetOperatorLeft
     * @static
     * @param {IMCServiceMessageWidgetOperatorLeft} message MCServiceMessageWidgetOperatorLeft message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageWidgetOperatorLeft.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.operatorID != null && Object.hasOwnProperty.call(message, "operatorID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.operatorID);
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessageWidgetOperatorLeft message, length delimited. Does not implicitly {@link MCServiceMessageWidgetOperatorLeft.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessageWidgetOperatorLeft
     * @static
     * @param {IMCServiceMessageWidgetOperatorLeft} message MCServiceMessageWidgetOperatorLeft message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageWidgetOperatorLeft.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessageWidgetOperatorLeft message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessageWidgetOperatorLeft
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessageWidgetOperatorLeft} MCServiceMessageWidgetOperatorLeft
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageWidgetOperatorLeft.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessageWidgetOperatorLeft();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.operatorID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessageWidgetOperatorLeft message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessageWidgetOperatorLeft
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessageWidgetOperatorLeft} MCServiceMessageWidgetOperatorLeft
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageWidgetOperatorLeft.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessageWidgetOperatorLeft message.
     * @function verify
     * @memberof MCServiceMessageWidgetOperatorLeft
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessageWidgetOperatorLeft.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (!$util.isInteger(message.operatorID) && !(message.operatorID && $util.isInteger(message.operatorID.low) && $util.isInteger(message.operatorID.high)))
                return "operatorID: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCServiceMessageWidgetOperatorLeft message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessageWidgetOperatorLeft
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessageWidgetOperatorLeft} MCServiceMessageWidgetOperatorLeft
     */
    MCServiceMessageWidgetOperatorLeft.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessageWidgetOperatorLeft)
            return object;
        let message = new $root.MCServiceMessageWidgetOperatorLeft();
        if (object.operatorID != null)
            if ($util.Long)
                (message.operatorID = $util.Long.fromValue(object.operatorID)).unsigned = true;
            else if (typeof object.operatorID === "string")
                message.operatorID = parseInt(object.operatorID, 10);
            else if (typeof object.operatorID === "number")
                message.operatorID = object.operatorID;
            else if (typeof object.operatorID === "object")
                message.operatorID = new $util.LongBits(object.operatorID.low >>> 0, object.operatorID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessageWidgetOperatorLeft message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessageWidgetOperatorLeft
     * @static
     * @param {MCServiceMessageWidgetOperatorLeft} message MCServiceMessageWidgetOperatorLeft
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessageWidgetOperatorLeft.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.operatorID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.operatorID = options.longs === String ? "0" : 0;
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (typeof message.operatorID === "number")
                object.operatorID = options.longs === String ? String(message.operatorID) : message.operatorID;
            else
                object.operatorID = options.longs === String ? $util.Long.prototype.toString.call(message.operatorID) : options.longs === Number ? new $util.LongBits(message.operatorID.low >>> 0, message.operatorID.high >>> 0).toNumber(true) : message.operatorID;
        return object;
    };

    /**
     * Converts this MCServiceMessageWidgetOperatorLeft to JSON.
     * @function toJSON
     * @memberof MCServiceMessageWidgetOperatorLeft
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessageWidgetOperatorLeft.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessageWidgetOperatorLeft
     * @function getTypeUrl
     * @memberof MCServiceMessageWidgetOperatorLeft
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessageWidgetOperatorLeft.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessageWidgetOperatorLeft";
    };

    return MCServiceMessageWidgetOperatorLeft;
})();

export const MCServiceMessageWidgetResolved = $root.MCServiceMessageWidgetResolved = (() => {

    /**
     * Properties of a MCServiceMessageWidgetResolved.
     * @exports IMCServiceMessageWidgetResolved
     * @interface IMCServiceMessageWidgetResolved
     * @property {Long|null} [operatorID] MCServiceMessageWidgetResolved operatorID
     */

    /**
     * Constructs a new MCServiceMessageWidgetResolved.
     * @exports MCServiceMessageWidgetResolved
     * @classdesc Represents a MCServiceMessageWidgetResolved.
     * @implements IMCServiceMessageWidgetResolved
     * @constructor
     * @param {IMCServiceMessageWidgetResolved=} [properties] Properties to set
     */
    function MCServiceMessageWidgetResolved(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessageWidgetResolved operatorID.
     * @member {Long} operatorID
     * @memberof MCServiceMessageWidgetResolved
     * @instance
     */
    MCServiceMessageWidgetResolved.prototype.operatorID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCServiceMessageWidgetResolved instance using the specified properties.
     * @function create
     * @memberof MCServiceMessageWidgetResolved
     * @static
     * @param {IMCServiceMessageWidgetResolved=} [properties] Properties to set
     * @returns {MCServiceMessageWidgetResolved} MCServiceMessageWidgetResolved instance
     */
    MCServiceMessageWidgetResolved.create = function create(properties) {
        return new MCServiceMessageWidgetResolved(properties);
    };

    /**
     * Encodes the specified MCServiceMessageWidgetResolved message. Does not implicitly {@link MCServiceMessageWidgetResolved.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessageWidgetResolved
     * @static
     * @param {IMCServiceMessageWidgetResolved} message MCServiceMessageWidgetResolved message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageWidgetResolved.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.operatorID != null && Object.hasOwnProperty.call(message, "operatorID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.operatorID);
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessageWidgetResolved message, length delimited. Does not implicitly {@link MCServiceMessageWidgetResolved.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessageWidgetResolved
     * @static
     * @param {IMCServiceMessageWidgetResolved} message MCServiceMessageWidgetResolved message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageWidgetResolved.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessageWidgetResolved message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessageWidgetResolved
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessageWidgetResolved} MCServiceMessageWidgetResolved
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageWidgetResolved.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessageWidgetResolved();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.operatorID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessageWidgetResolved message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessageWidgetResolved
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessageWidgetResolved} MCServiceMessageWidgetResolved
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageWidgetResolved.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessageWidgetResolved message.
     * @function verify
     * @memberof MCServiceMessageWidgetResolved
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessageWidgetResolved.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (!$util.isInteger(message.operatorID) && !(message.operatorID && $util.isInteger(message.operatorID.low) && $util.isInteger(message.operatorID.high)))
                return "operatorID: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCServiceMessageWidgetResolved message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessageWidgetResolved
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessageWidgetResolved} MCServiceMessageWidgetResolved
     */
    MCServiceMessageWidgetResolved.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessageWidgetResolved)
            return object;
        let message = new $root.MCServiceMessageWidgetResolved();
        if (object.operatorID != null)
            if ($util.Long)
                (message.operatorID = $util.Long.fromValue(object.operatorID)).unsigned = true;
            else if (typeof object.operatorID === "string")
                message.operatorID = parseInt(object.operatorID, 10);
            else if (typeof object.operatorID === "number")
                message.operatorID = object.operatorID;
            else if (typeof object.operatorID === "object")
                message.operatorID = new $util.LongBits(object.operatorID.low >>> 0, object.operatorID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessageWidgetResolved message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessageWidgetResolved
     * @static
     * @param {MCServiceMessageWidgetResolved} message MCServiceMessageWidgetResolved
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessageWidgetResolved.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.operatorID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.operatorID = options.longs === String ? "0" : 0;
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (typeof message.operatorID === "number")
                object.operatorID = options.longs === String ? String(message.operatorID) : message.operatorID;
            else
                object.operatorID = options.longs === String ? $util.Long.prototype.toString.call(message.operatorID) : options.longs === Number ? new $util.LongBits(message.operatorID.low >>> 0, message.operatorID.high >>> 0).toNumber(true) : message.operatorID;
        return object;
    };

    /**
     * Converts this MCServiceMessageWidgetResolved to JSON.
     * @function toJSON
     * @memberof MCServiceMessageWidgetResolved
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessageWidgetResolved.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessageWidgetResolved
     * @function getTypeUrl
     * @memberof MCServiceMessageWidgetResolved
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessageWidgetResolved.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessageWidgetResolved";
    };

    return MCServiceMessageWidgetResolved;
})();

export const MCServiceMessageWidgetReopen = $root.MCServiceMessageWidgetReopen = (() => {

    /**
     * Properties of a MCServiceMessageWidgetReopen.
     * @exports IMCServiceMessageWidgetReopen
     * @interface IMCServiceMessageWidgetReopen
     * @property {boolean|null} [reopen] MCServiceMessageWidgetReopen reopen
     */

    /**
     * Constructs a new MCServiceMessageWidgetReopen.
     * @exports MCServiceMessageWidgetReopen
     * @classdesc Represents a MCServiceMessageWidgetReopen.
     * @implements IMCServiceMessageWidgetReopen
     * @constructor
     * @param {IMCServiceMessageWidgetReopen=} [properties] Properties to set
     */
    function MCServiceMessageWidgetReopen(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessageWidgetReopen reopen.
     * @member {boolean} reopen
     * @memberof MCServiceMessageWidgetReopen
     * @instance
     */
    MCServiceMessageWidgetReopen.prototype.reopen = false;

    /**
     * Creates a new MCServiceMessageWidgetReopen instance using the specified properties.
     * @function create
     * @memberof MCServiceMessageWidgetReopen
     * @static
     * @param {IMCServiceMessageWidgetReopen=} [properties] Properties to set
     * @returns {MCServiceMessageWidgetReopen} MCServiceMessageWidgetReopen instance
     */
    MCServiceMessageWidgetReopen.create = function create(properties) {
        return new MCServiceMessageWidgetReopen(properties);
    };

    /**
     * Encodes the specified MCServiceMessageWidgetReopen message. Does not implicitly {@link MCServiceMessageWidgetReopen.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessageWidgetReopen
     * @static
     * @param {IMCServiceMessageWidgetReopen} message MCServiceMessageWidgetReopen message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageWidgetReopen.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.reopen != null && Object.hasOwnProperty.call(message, "reopen"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.reopen);
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessageWidgetReopen message, length delimited. Does not implicitly {@link MCServiceMessageWidgetReopen.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessageWidgetReopen
     * @static
     * @param {IMCServiceMessageWidgetReopen} message MCServiceMessageWidgetReopen message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageWidgetReopen.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessageWidgetReopen message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessageWidgetReopen
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessageWidgetReopen} MCServiceMessageWidgetReopen
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageWidgetReopen.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessageWidgetReopen();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.reopen = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessageWidgetReopen message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessageWidgetReopen
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessageWidgetReopen} MCServiceMessageWidgetReopen
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageWidgetReopen.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessageWidgetReopen message.
     * @function verify
     * @memberof MCServiceMessageWidgetReopen
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessageWidgetReopen.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.reopen != null && message.hasOwnProperty("reopen"))
            if (typeof message.reopen !== "boolean")
                return "reopen: boolean expected";
        return null;
    };

    /**
     * Creates a MCServiceMessageWidgetReopen message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessageWidgetReopen
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessageWidgetReopen} MCServiceMessageWidgetReopen
     */
    MCServiceMessageWidgetReopen.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessageWidgetReopen)
            return object;
        let message = new $root.MCServiceMessageWidgetReopen();
        if (object.reopen != null)
            message.reopen = Boolean(object.reopen);
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessageWidgetReopen message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessageWidgetReopen
     * @static
     * @param {MCServiceMessageWidgetReopen} message MCServiceMessageWidgetReopen
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessageWidgetReopen.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.reopen = false;
        if (message.reopen != null && message.hasOwnProperty("reopen"))
            object.reopen = message.reopen;
        return object;
    };

    /**
     * Converts this MCServiceMessageWidgetReopen to JSON.
     * @function toJSON
     * @memberof MCServiceMessageWidgetReopen
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessageWidgetReopen.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessageWidgetReopen
     * @function getTypeUrl
     * @memberof MCServiceMessageWidgetReopen
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessageWidgetReopen.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessageWidgetReopen";
    };

    return MCServiceMessageWidgetReopen;
})();

export const MCOperatorPreview = $root.MCOperatorPreview = (() => {

    /**
     * Properties of a MCOperatorPreview.
     * @exports IMCOperatorPreview
     * @interface IMCOperatorPreview
     * @property {Long|null} [operatorID] MCOperatorPreview operatorID
     * @property {string|null} [name] MCOperatorPreview name
     */

    /**
     * Constructs a new MCOperatorPreview.
     * @exports MCOperatorPreview
     * @classdesc Represents a MCOperatorPreview.
     * @implements IMCOperatorPreview
     * @constructor
     * @param {IMCOperatorPreview=} [properties] Properties to set
     */
    function MCOperatorPreview(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCOperatorPreview operatorID.
     * @member {Long} operatorID
     * @memberof MCOperatorPreview
     * @instance
     */
    MCOperatorPreview.prototype.operatorID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MCOperatorPreview name.
     * @member {string} name
     * @memberof MCOperatorPreview
     * @instance
     */
    MCOperatorPreview.prototype.name = "";

    /**
     * Creates a new MCOperatorPreview instance using the specified properties.
     * @function create
     * @memberof MCOperatorPreview
     * @static
     * @param {IMCOperatorPreview=} [properties] Properties to set
     * @returns {MCOperatorPreview} MCOperatorPreview instance
     */
    MCOperatorPreview.create = function create(properties) {
        return new MCOperatorPreview(properties);
    };

    /**
     * Encodes the specified MCOperatorPreview message. Does not implicitly {@link MCOperatorPreview.verify|verify} messages.
     * @function encode
     * @memberof MCOperatorPreview
     * @static
     * @param {IMCOperatorPreview} message MCOperatorPreview message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCOperatorPreview.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.operatorID != null && Object.hasOwnProperty.call(message, "operatorID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.operatorID);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        return writer;
    };

    /**
     * Encodes the specified MCOperatorPreview message, length delimited. Does not implicitly {@link MCOperatorPreview.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCOperatorPreview
     * @static
     * @param {IMCOperatorPreview} message MCOperatorPreview message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCOperatorPreview.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCOperatorPreview message from the specified reader or buffer.
     * @function decode
     * @memberof MCOperatorPreview
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCOperatorPreview} MCOperatorPreview
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCOperatorPreview.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCOperatorPreview();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.operatorID = reader.uint64();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCOperatorPreview message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCOperatorPreview
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCOperatorPreview} MCOperatorPreview
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCOperatorPreview.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCOperatorPreview message.
     * @function verify
     * @memberof MCOperatorPreview
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCOperatorPreview.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (!$util.isInteger(message.operatorID) && !(message.operatorID && $util.isInteger(message.operatorID.low) && $util.isInteger(message.operatorID.high)))
                return "operatorID: integer|Long expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        return null;
    };

    /**
     * Creates a MCOperatorPreview message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCOperatorPreview
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCOperatorPreview} MCOperatorPreview
     */
    MCOperatorPreview.fromObject = function fromObject(object) {
        if (object instanceof $root.MCOperatorPreview)
            return object;
        let message = new $root.MCOperatorPreview();
        if (object.operatorID != null)
            if ($util.Long)
                (message.operatorID = $util.Long.fromValue(object.operatorID)).unsigned = true;
            else if (typeof object.operatorID === "string")
                message.operatorID = parseInt(object.operatorID, 10);
            else if (typeof object.operatorID === "number")
                message.operatorID = object.operatorID;
            else if (typeof object.operatorID === "object")
                message.operatorID = new $util.LongBits(object.operatorID.low >>> 0, object.operatorID.high >>> 0).toNumber(true);
        if (object.name != null)
            message.name = String(object.name);
        return message;
    };

    /**
     * Creates a plain object from a MCOperatorPreview message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCOperatorPreview
     * @static
     * @param {MCOperatorPreview} message MCOperatorPreview
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCOperatorPreview.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.operatorID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.operatorID = options.longs === String ? "0" : 0;
            object.name = "";
        }
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (typeof message.operatorID === "number")
                object.operatorID = options.longs === String ? String(message.operatorID) : message.operatorID;
            else
                object.operatorID = options.longs === String ? $util.Long.prototype.toString.call(message.operatorID) : options.longs === Number ? new $util.LongBits(message.operatorID.low >>> 0, message.operatorID.high >>> 0).toNumber(true) : message.operatorID;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        return object;
    };

    /**
     * Converts this MCOperatorPreview to JSON.
     * @function toJSON
     * @memberof MCOperatorPreview
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCOperatorPreview.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCOperatorPreview
     * @function getTypeUrl
     * @memberof MCOperatorPreview
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCOperatorPreview.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCOperatorPreview";
    };

    return MCOperatorPreview;
})();

export const MCSendEntities = $root.MCSendEntities = (() => {

    /**
     * Properties of a MCSendEntities.
     * @exports IMCSendEntities
     * @interface IMCSendEntities
     * @property {string|null} [text] MCSendEntities text
     * @property {Array.<IMCAttachment>|null} [attachments] MCSendEntities attachments
     * @property {Long|null} [randomID] MCSendEntities randomID
     */

    /**
     * Constructs a new MCSendEntities.
     * @exports MCSendEntities
     * @classdesc Represents a MCSendEntities.
     * @implements IMCSendEntities
     * @constructor
     * @param {IMCSendEntities=} [properties] Properties to set
     */
    function MCSendEntities(properties) {
        this.attachments = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCSendEntities text.
     * @member {string} text
     * @memberof MCSendEntities
     * @instance
     */
    MCSendEntities.prototype.text = "";

    /**
     * MCSendEntities attachments.
     * @member {Array.<IMCAttachment>} attachments
     * @memberof MCSendEntities
     * @instance
     */
    MCSendEntities.prototype.attachments = $util.emptyArray;

    /**
     * MCSendEntities randomID.
     * @member {Long} randomID
     * @memberof MCSendEntities
     * @instance
     */
    MCSendEntities.prototype.randomID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MCSendEntities instance using the specified properties.
     * @function create
     * @memberof MCSendEntities
     * @static
     * @param {IMCSendEntities=} [properties] Properties to set
     * @returns {MCSendEntities} MCSendEntities instance
     */
    MCSendEntities.create = function create(properties) {
        return new MCSendEntities(properties);
    };

    /**
     * Encodes the specified MCSendEntities message. Does not implicitly {@link MCSendEntities.verify|verify} messages.
     * @function encode
     * @memberof MCSendEntities
     * @static
     * @param {IMCSendEntities} message MCSendEntities message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCSendEntities.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.text != null && Object.hasOwnProperty.call(message, "text"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
        if (message.attachments != null && message.attachments.length)
            for (let i = 0; i < message.attachments.length; ++i)
                $root.MCAttachment.encode(message.attachments[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.randomID != null && Object.hasOwnProperty.call(message, "randomID"))
            writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.randomID);
        return writer;
    };

    /**
     * Encodes the specified MCSendEntities message, length delimited. Does not implicitly {@link MCSendEntities.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCSendEntities
     * @static
     * @param {IMCSendEntities} message MCSendEntities message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCSendEntities.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCSendEntities message from the specified reader or buffer.
     * @function decode
     * @memberof MCSendEntities
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCSendEntities} MCSendEntities
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCSendEntities.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCSendEntities();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.text = reader.string();
                    break;
                }
            case 2: {
                    if (!(message.attachments && message.attachments.length))
                        message.attachments = [];
                    message.attachments.push($root.MCAttachment.decode(reader, reader.uint32()));
                    break;
                }
            case 10: {
                    message.randomID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCSendEntities message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCSendEntities
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCSendEntities} MCSendEntities
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCSendEntities.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCSendEntities message.
     * @function verify
     * @memberof MCSendEntities
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCSendEntities.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.text != null && message.hasOwnProperty("text"))
            if (!$util.isString(message.text))
                return "text: string expected";
        if (message.attachments != null && message.hasOwnProperty("attachments")) {
            if (!Array.isArray(message.attachments))
                return "attachments: array expected";
            for (let i = 0; i < message.attachments.length; ++i) {
                let error = $root.MCAttachment.verify(message.attachments[i]);
                if (error)
                    return "attachments." + error;
            }
        }
        if (message.randomID != null && message.hasOwnProperty("randomID"))
            if (!$util.isInteger(message.randomID) && !(message.randomID && $util.isInteger(message.randomID.low) && $util.isInteger(message.randomID.high)))
                return "randomID: integer|Long expected";
        return null;
    };

    /**
     * Creates a MCSendEntities message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCSendEntities
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCSendEntities} MCSendEntities
     */
    MCSendEntities.fromObject = function fromObject(object) {
        if (object instanceof $root.MCSendEntities)
            return object;
        let message = new $root.MCSendEntities();
        if (object.text != null)
            message.text = String(object.text);
        if (object.attachments) {
            if (!Array.isArray(object.attachments))
                throw TypeError(".MCSendEntities.attachments: array expected");
            message.attachments = [];
            for (let i = 0; i < object.attachments.length; ++i) {
                if (typeof object.attachments[i] !== "object")
                    throw TypeError(".MCSendEntities.attachments: object expected");
                message.attachments[i] = $root.MCAttachment.fromObject(object.attachments[i]);
            }
        }
        if (object.randomID != null)
            if ($util.Long)
                (message.randomID = $util.Long.fromValue(object.randomID)).unsigned = true;
            else if (typeof object.randomID === "string")
                message.randomID = parseInt(object.randomID, 10);
            else if (typeof object.randomID === "number")
                message.randomID = object.randomID;
            else if (typeof object.randomID === "object")
                message.randomID = new $util.LongBits(object.randomID.low >>> 0, object.randomID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MCSendEntities message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCSendEntities
     * @static
     * @param {MCSendEntities} message MCSendEntities
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCSendEntities.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.attachments = [];
        if (options.defaults) {
            object.text = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.randomID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.randomID = options.longs === String ? "0" : 0;
        }
        if (message.text != null && message.hasOwnProperty("text"))
            object.text = message.text;
        if (message.attachments && message.attachments.length) {
            object.attachments = [];
            for (let j = 0; j < message.attachments.length; ++j)
                object.attachments[j] = $root.MCAttachment.toObject(message.attachments[j], options);
        }
        if (message.randomID != null && message.hasOwnProperty("randomID"))
            if (typeof message.randomID === "number")
                object.randomID = options.longs === String ? String(message.randomID) : message.randomID;
            else
                object.randomID = options.longs === String ? $util.Long.prototype.toString.call(message.randomID) : options.longs === Number ? new $util.LongBits(message.randomID.low >>> 0, message.randomID.high >>> 0).toNumber(true) : message.randomID;
        return object;
    };

    /**
     * Converts this MCSendEntities to JSON.
     * @function toJSON
     * @memberof MCSendEntities
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCSendEntities.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCSendEntities
     * @function getTypeUrl
     * @memberof MCSendEntities
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCSendEntities.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCSendEntities";
    };

    return MCSendEntities;
})();

export const MCServiceMessageLegacy = $root.MCServiceMessageLegacy = (() => {

    /**
     * Properties of a MCServiceMessageLegacy.
     * @exports IMCServiceMessageLegacy
     * @interface IMCServiceMessageLegacy
     * @property {string|null} [text] MCServiceMessageLegacy text
     */

    /**
     * Constructs a new MCServiceMessageLegacy.
     * @exports MCServiceMessageLegacy
     * @classdesc Represents a MCServiceMessageLegacy.
     * @implements IMCServiceMessageLegacy
     * @constructor
     * @param {IMCServiceMessageLegacy=} [properties] Properties to set
     */
    function MCServiceMessageLegacy(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MCServiceMessageLegacy text.
     * @member {string} text
     * @memberof MCServiceMessageLegacy
     * @instance
     */
    MCServiceMessageLegacy.prototype.text = "";

    /**
     * Creates a new MCServiceMessageLegacy instance using the specified properties.
     * @function create
     * @memberof MCServiceMessageLegacy
     * @static
     * @param {IMCServiceMessageLegacy=} [properties] Properties to set
     * @returns {MCServiceMessageLegacy} MCServiceMessageLegacy instance
     */
    MCServiceMessageLegacy.create = function create(properties) {
        return new MCServiceMessageLegacy(properties);
    };

    /**
     * Encodes the specified MCServiceMessageLegacy message. Does not implicitly {@link MCServiceMessageLegacy.verify|verify} messages.
     * @function encode
     * @memberof MCServiceMessageLegacy
     * @static
     * @param {IMCServiceMessageLegacy} message MCServiceMessageLegacy message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageLegacy.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.text != null && Object.hasOwnProperty.call(message, "text"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
        return writer;
    };

    /**
     * Encodes the specified MCServiceMessageLegacy message, length delimited. Does not implicitly {@link MCServiceMessageLegacy.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MCServiceMessageLegacy
     * @static
     * @param {IMCServiceMessageLegacy} message MCServiceMessageLegacy message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MCServiceMessageLegacy.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MCServiceMessageLegacy message from the specified reader or buffer.
     * @function decode
     * @memberof MCServiceMessageLegacy
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MCServiceMessageLegacy} MCServiceMessageLegacy
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageLegacy.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MCServiceMessageLegacy();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.text = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MCServiceMessageLegacy message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MCServiceMessageLegacy
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MCServiceMessageLegacy} MCServiceMessageLegacy
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MCServiceMessageLegacy.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MCServiceMessageLegacy message.
     * @function verify
     * @memberof MCServiceMessageLegacy
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MCServiceMessageLegacy.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.text != null && message.hasOwnProperty("text"))
            if (!$util.isString(message.text))
                return "text: string expected";
        return null;
    };

    /**
     * Creates a MCServiceMessageLegacy message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MCServiceMessageLegacy
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MCServiceMessageLegacy} MCServiceMessageLegacy
     */
    MCServiceMessageLegacy.fromObject = function fromObject(object) {
        if (object instanceof $root.MCServiceMessageLegacy)
            return object;
        let message = new $root.MCServiceMessageLegacy();
        if (object.text != null)
            message.text = String(object.text);
        return message;
    };

    /**
     * Creates a plain object from a MCServiceMessageLegacy message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MCServiceMessageLegacy
     * @static
     * @param {MCServiceMessageLegacy} message MCServiceMessageLegacy
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MCServiceMessageLegacy.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.text = "";
        if (message.text != null && message.hasOwnProperty("text"))
            object.text = message.text;
        return object;
    };

    /**
     * Converts this MCServiceMessageLegacy to JSON.
     * @function toJSON
     * @memberof MCServiceMessageLegacy
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MCServiceMessageLegacy.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MCServiceMessageLegacy
     * @function getTypeUrl
     * @memberof MCServiceMessageLegacy
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MCServiceMessageLegacy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MCServiceMessageLegacy";
    };

    return MCServiceMessageLegacy;
})();

export const TelegramChannelState = $root.TelegramChannelState = (() => {

    /**
     * Properties of a TelegramChannelState.
     * @exports ITelegramChannelState
     * @interface ITelegramChannelState
     * @property {TelegramChannelState.State|null} [state] TelegramChannelState state
     */

    /**
     * Constructs a new TelegramChannelState.
     * @exports TelegramChannelState
     * @classdesc Represents a TelegramChannelState.
     * @implements ITelegramChannelState
     * @constructor
     * @param {ITelegramChannelState=} [properties] Properties to set
     */
    function TelegramChannelState(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TelegramChannelState state.
     * @member {TelegramChannelState.State} state
     * @memberof TelegramChannelState
     * @instance
     */
    TelegramChannelState.prototype.state = 0;

    /**
     * Creates a new TelegramChannelState instance using the specified properties.
     * @function create
     * @memberof TelegramChannelState
     * @static
     * @param {ITelegramChannelState=} [properties] Properties to set
     * @returns {TelegramChannelState} TelegramChannelState instance
     */
    TelegramChannelState.create = function create(properties) {
        return new TelegramChannelState(properties);
    };

    /**
     * Encodes the specified TelegramChannelState message. Does not implicitly {@link TelegramChannelState.verify|verify} messages.
     * @function encode
     * @memberof TelegramChannelState
     * @static
     * @param {ITelegramChannelState} message TelegramChannelState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramChannelState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
        return writer;
    };

    /**
     * Encodes the specified TelegramChannelState message, length delimited. Does not implicitly {@link TelegramChannelState.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TelegramChannelState
     * @static
     * @param {ITelegramChannelState} message TelegramChannelState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramChannelState.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TelegramChannelState message from the specified reader or buffer.
     * @function decode
     * @memberof TelegramChannelState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TelegramChannelState} TelegramChannelState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramChannelState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramChannelState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.state = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TelegramChannelState message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TelegramChannelState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TelegramChannelState} TelegramChannelState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramChannelState.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TelegramChannelState message.
     * @function verify
     * @memberof TelegramChannelState
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TelegramChannelState.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.state != null && message.hasOwnProperty("state"))
            switch (message.state) {
            default:
                return "state: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 10:
                break;
            }
        return null;
    };

    /**
     * Creates a TelegramChannelState message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TelegramChannelState
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TelegramChannelState} TelegramChannelState
     */
    TelegramChannelState.fromObject = function fromObject(object) {
        if (object instanceof $root.TelegramChannelState)
            return object;
        let message = new $root.TelegramChannelState();
        switch (object.state) {
        default:
            if (typeof object.state === "number") {
                message.state = object.state;
                break;
            }
            break;
        case "CREATE_CHANNEL":
        case 0:
            message.state = 0;
            break;
        case "ENTER_NUMBER":
        case 1:
            message.state = 1;
            break;
        case "ENTER_CODE":
        case 2:
            message.state = 2;
            break;
        case "ENTER_PASSWORD":
        case 3:
            message.state = 3;
            break;
        case "EVENT_LISTENING":
        case 10:
            message.state = 10;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a TelegramChannelState message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TelegramChannelState
     * @static
     * @param {TelegramChannelState} message TelegramChannelState
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TelegramChannelState.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.state = options.enums === String ? "CREATE_CHANNEL" : 0;
        if (message.state != null && message.hasOwnProperty("state"))
            object.state = options.enums === String ? $root.TelegramChannelState.State[message.state] === undefined ? message.state : $root.TelegramChannelState.State[message.state] : message.state;
        return object;
    };

    /**
     * Converts this TelegramChannelState to JSON.
     * @function toJSON
     * @memberof TelegramChannelState
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TelegramChannelState.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TelegramChannelState
     * @function getTypeUrl
     * @memberof TelegramChannelState
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TelegramChannelState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TelegramChannelState";
    };

    /**
     * State enum.
     * @name TelegramChannelState.State
     * @enum {number}
     * @property {number} CREATE_CHANNEL=0 CREATE_CHANNEL value
     * @property {number} ENTER_NUMBER=1 ENTER_NUMBER value
     * @property {number} ENTER_CODE=2 ENTER_CODE value
     * @property {number} ENTER_PASSWORD=3 ENTER_PASSWORD value
     * @property {number} EVENT_LISTENING=10 EVENT_LISTENING value
     */
    TelegramChannelState.State = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CREATE_CHANNEL"] = 0;
        values[valuesById[1] = "ENTER_NUMBER"] = 1;
        values[valuesById[2] = "ENTER_CODE"] = 2;
        values[valuesById[3] = "ENTER_PASSWORD"] = 3;
        values[valuesById[10] = "EVENT_LISTENING"] = 10;
        return values;
    })();

    return TelegramChannelState;
})();

export const WebWidgetChannelState = $root.WebWidgetChannelState = (() => {

    /**
     * Properties of a WebWidgetChannelState.
     * @exports IWebWidgetChannelState
     * @interface IWebWidgetChannelState
     * @property {WebWidgetChannelState.State|null} [state] WebWidgetChannelState state
     * @property {IWebWidgetConfig|null} [config] WebWidgetChannelState config
     */

    /**
     * Constructs a new WebWidgetChannelState.
     * @exports WebWidgetChannelState
     * @classdesc Represents a WebWidgetChannelState.
     * @implements IWebWidgetChannelState
     * @constructor
     * @param {IWebWidgetChannelState=} [properties] Properties to set
     */
    function WebWidgetChannelState(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WebWidgetChannelState state.
     * @member {WebWidgetChannelState.State} state
     * @memberof WebWidgetChannelState
     * @instance
     */
    WebWidgetChannelState.prototype.state = 0;

    /**
     * WebWidgetChannelState config.
     * @member {IWebWidgetConfig|null|undefined} config
     * @memberof WebWidgetChannelState
     * @instance
     */
    WebWidgetChannelState.prototype.config = null;

    /**
     * Creates a new WebWidgetChannelState instance using the specified properties.
     * @function create
     * @memberof WebWidgetChannelState
     * @static
     * @param {IWebWidgetChannelState=} [properties] Properties to set
     * @returns {WebWidgetChannelState} WebWidgetChannelState instance
     */
    WebWidgetChannelState.create = function create(properties) {
        return new WebWidgetChannelState(properties);
    };

    /**
     * Encodes the specified WebWidgetChannelState message. Does not implicitly {@link WebWidgetChannelState.verify|verify} messages.
     * @function encode
     * @memberof WebWidgetChannelState
     * @static
     * @param {IWebWidgetChannelState} message WebWidgetChannelState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebWidgetChannelState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
        if (message.config != null && Object.hasOwnProperty.call(message, "config"))
            $root.WebWidgetConfig.encode(message.config, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified WebWidgetChannelState message, length delimited. Does not implicitly {@link WebWidgetChannelState.verify|verify} messages.
     * @function encodeDelimited
     * @memberof WebWidgetChannelState
     * @static
     * @param {IWebWidgetChannelState} message WebWidgetChannelState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebWidgetChannelState.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WebWidgetChannelState message from the specified reader or buffer.
     * @function decode
     * @memberof WebWidgetChannelState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WebWidgetChannelState} WebWidgetChannelState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebWidgetChannelState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WebWidgetChannelState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.state = reader.int32();
                    break;
                }
            case 2: {
                    message.config = $root.WebWidgetConfig.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a WebWidgetChannelState message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof WebWidgetChannelState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {WebWidgetChannelState} WebWidgetChannelState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebWidgetChannelState.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WebWidgetChannelState message.
     * @function verify
     * @memberof WebWidgetChannelState
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WebWidgetChannelState.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.state != null && message.hasOwnProperty("state"))
            switch (message.state) {
            default:
                return "state: enum value expected";
            case 0:
            case 10:
                break;
            }
        if (message.config != null && message.hasOwnProperty("config")) {
            let error = $root.WebWidgetConfig.verify(message.config);
            if (error)
                return "config." + error;
        }
        return null;
    };

    /**
     * Creates a WebWidgetChannelState message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof WebWidgetChannelState
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {WebWidgetChannelState} WebWidgetChannelState
     */
    WebWidgetChannelState.fromObject = function fromObject(object) {
        if (object instanceof $root.WebWidgetChannelState)
            return object;
        let message = new $root.WebWidgetChannelState();
        switch (object.state) {
        default:
            if (typeof object.state === "number") {
                message.state = object.state;
                break;
            }
            break;
        case "CREATE_CHANNEL":
        case 0:
            message.state = 0;
            break;
        case "EVENT_LISTENING":
        case 10:
            message.state = 10;
            break;
        }
        if (object.config != null) {
            if (typeof object.config !== "object")
                throw TypeError(".WebWidgetChannelState.config: object expected");
            message.config = $root.WebWidgetConfig.fromObject(object.config);
        }
        return message;
    };

    /**
     * Creates a plain object from a WebWidgetChannelState message. Also converts values to other types if specified.
     * @function toObject
     * @memberof WebWidgetChannelState
     * @static
     * @param {WebWidgetChannelState} message WebWidgetChannelState
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WebWidgetChannelState.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.state = options.enums === String ? "CREATE_CHANNEL" : 0;
            object.config = null;
        }
        if (message.state != null && message.hasOwnProperty("state"))
            object.state = options.enums === String ? $root.WebWidgetChannelState.State[message.state] === undefined ? message.state : $root.WebWidgetChannelState.State[message.state] : message.state;
        if (message.config != null && message.hasOwnProperty("config"))
            object.config = $root.WebWidgetConfig.toObject(message.config, options);
        return object;
    };

    /**
     * Converts this WebWidgetChannelState to JSON.
     * @function toJSON
     * @memberof WebWidgetChannelState
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WebWidgetChannelState.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WebWidgetChannelState
     * @function getTypeUrl
     * @memberof WebWidgetChannelState
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WebWidgetChannelState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WebWidgetChannelState";
    };

    /**
     * State enum.
     * @name WebWidgetChannelState.State
     * @enum {number}
     * @property {number} CREATE_CHANNEL=0 CREATE_CHANNEL value
     * @property {number} EVENT_LISTENING=10 EVENT_LISTENING value
     */
    WebWidgetChannelState.State = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CREATE_CHANNEL"] = 0;
        values[valuesById[10] = "EVENT_LISTENING"] = 10;
        return values;
    })();

    return WebWidgetChannelState;
})();

export const WebWidgetConfig = $root.WebWidgetConfig = (() => {

    /**
     * Properties of a WebWidgetConfig.
     * @exports IWebWidgetConfig
     * @interface IWebWidgetConfig
     * @property {string|null} [APICode] WebWidgetConfig APICode
     * @property {string|null} [domain] WebWidgetConfig domain
     * @property {string|null} [displayCompanyName] WebWidgetConfig displayCompanyName
     * @property {string|null} [displayWelcomeMessage] WebWidgetConfig displayWelcomeMessage
     * @property {IWWAttachment|null} [displayAvatar] WebWidgetConfig displayAvatar
     * @property {string|null} [displayColor] WebWidgetConfig displayColor
     * @property {Long|null} [chatButtonSize] WebWidgetConfig chatButtonSize
     * @property {string|null} [position] WebWidgetConfig position
     * @property {Array.<WebWidgetConfig.IHelloSnippet>|null} [helloSnippets] WebWidgetConfig helloSnippets
     * @property {boolean|null} [externalMessagingAPIEnabled] WebWidgetConfig externalMessagingAPIEnabled
     */

    /**
     * Constructs a new WebWidgetConfig.
     * @exports WebWidgetConfig
     * @classdesc Represents a WebWidgetConfig.
     * @implements IWebWidgetConfig
     * @constructor
     * @param {IWebWidgetConfig=} [properties] Properties to set
     */
    function WebWidgetConfig(properties) {
        this.helloSnippets = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WebWidgetConfig APICode.
     * @member {string} APICode
     * @memberof WebWidgetConfig
     * @instance
     */
    WebWidgetConfig.prototype.APICode = "";

    /**
     * WebWidgetConfig domain.
     * @member {string} domain
     * @memberof WebWidgetConfig
     * @instance
     */
    WebWidgetConfig.prototype.domain = "";

    /**
     * WebWidgetConfig displayCompanyName.
     * @member {string} displayCompanyName
     * @memberof WebWidgetConfig
     * @instance
     */
    WebWidgetConfig.prototype.displayCompanyName = "";

    /**
     * WebWidgetConfig displayWelcomeMessage.
     * @member {string} displayWelcomeMessage
     * @memberof WebWidgetConfig
     * @instance
     */
    WebWidgetConfig.prototype.displayWelcomeMessage = "";

    /**
     * WebWidgetConfig displayAvatar.
     * @member {IWWAttachment|null|undefined} displayAvatar
     * @memberof WebWidgetConfig
     * @instance
     */
    WebWidgetConfig.prototype.displayAvatar = null;

    /**
     * WebWidgetConfig displayColor.
     * @member {string} displayColor
     * @memberof WebWidgetConfig
     * @instance
     */
    WebWidgetConfig.prototype.displayColor = "";

    /**
     * WebWidgetConfig chatButtonSize.
     * @member {Long} chatButtonSize
     * @memberof WebWidgetConfig
     * @instance
     */
    WebWidgetConfig.prototype.chatButtonSize = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * WebWidgetConfig position.
     * @member {string} position
     * @memberof WebWidgetConfig
     * @instance
     */
    WebWidgetConfig.prototype.position = "";

    /**
     * WebWidgetConfig helloSnippets.
     * @member {Array.<WebWidgetConfig.IHelloSnippet>} helloSnippets
     * @memberof WebWidgetConfig
     * @instance
     */
    WebWidgetConfig.prototype.helloSnippets = $util.emptyArray;

    /**
     * WebWidgetConfig externalMessagingAPIEnabled.
     * @member {boolean} externalMessagingAPIEnabled
     * @memberof WebWidgetConfig
     * @instance
     */
    WebWidgetConfig.prototype.externalMessagingAPIEnabled = false;

    /**
     * Creates a new WebWidgetConfig instance using the specified properties.
     * @function create
     * @memberof WebWidgetConfig
     * @static
     * @param {IWebWidgetConfig=} [properties] Properties to set
     * @returns {WebWidgetConfig} WebWidgetConfig instance
     */
    WebWidgetConfig.create = function create(properties) {
        return new WebWidgetConfig(properties);
    };

    /**
     * Encodes the specified WebWidgetConfig message. Does not implicitly {@link WebWidgetConfig.verify|verify} messages.
     * @function encode
     * @memberof WebWidgetConfig
     * @static
     * @param {IWebWidgetConfig} message WebWidgetConfig message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebWidgetConfig.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.APICode != null && Object.hasOwnProperty.call(message, "APICode"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.APICode);
        if (message.domain != null && Object.hasOwnProperty.call(message, "domain"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.domain);
        if (message.displayCompanyName != null && Object.hasOwnProperty.call(message, "displayCompanyName"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.displayCompanyName);
        if (message.displayWelcomeMessage != null && Object.hasOwnProperty.call(message, "displayWelcomeMessage"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.displayWelcomeMessage);
        if (message.displayAvatar != null && Object.hasOwnProperty.call(message, "displayAvatar"))
            $root.WWAttachment.encode(message.displayAvatar, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.displayColor != null && Object.hasOwnProperty.call(message, "displayColor"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.displayColor);
        if (message.chatButtonSize != null && Object.hasOwnProperty.call(message, "chatButtonSize"))
            writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.chatButtonSize);
        if (message.position != null && Object.hasOwnProperty.call(message, "position"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.position);
        if (message.helloSnippets != null && message.helloSnippets.length)
            for (let i = 0; i < message.helloSnippets.length; ++i)
                $root.WebWidgetConfig.HelloSnippet.encode(message.helloSnippets[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.externalMessagingAPIEnabled != null && Object.hasOwnProperty.call(message, "externalMessagingAPIEnabled"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.externalMessagingAPIEnabled);
        return writer;
    };

    /**
     * Encodes the specified WebWidgetConfig message, length delimited. Does not implicitly {@link WebWidgetConfig.verify|verify} messages.
     * @function encodeDelimited
     * @memberof WebWidgetConfig
     * @static
     * @param {IWebWidgetConfig} message WebWidgetConfig message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebWidgetConfig.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WebWidgetConfig message from the specified reader or buffer.
     * @function decode
     * @memberof WebWidgetConfig
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WebWidgetConfig} WebWidgetConfig
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebWidgetConfig.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WebWidgetConfig();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.APICode = reader.string();
                    break;
                }
            case 4: {
                    message.domain = reader.string();
                    break;
                }
            case 5: {
                    message.displayCompanyName = reader.string();
                    break;
                }
            case 6: {
                    message.displayWelcomeMessage = reader.string();
                    break;
                }
            case 7: {
                    message.displayAvatar = $root.WWAttachment.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.displayColor = reader.string();
                    break;
                }
            case 9: {
                    message.chatButtonSize = reader.uint64();
                    break;
                }
            case 10: {
                    message.position = reader.string();
                    break;
                }
            case 11: {
                    if (!(message.helloSnippets && message.helloSnippets.length))
                        message.helloSnippets = [];
                    message.helloSnippets.push($root.WebWidgetConfig.HelloSnippet.decode(reader, reader.uint32()));
                    break;
                }
            case 12: {
                    message.externalMessagingAPIEnabled = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a WebWidgetConfig message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof WebWidgetConfig
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {WebWidgetConfig} WebWidgetConfig
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebWidgetConfig.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WebWidgetConfig message.
     * @function verify
     * @memberof WebWidgetConfig
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WebWidgetConfig.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.APICode != null && message.hasOwnProperty("APICode"))
            if (!$util.isString(message.APICode))
                return "APICode: string expected";
        if (message.domain != null && message.hasOwnProperty("domain"))
            if (!$util.isString(message.domain))
                return "domain: string expected";
        if (message.displayCompanyName != null && message.hasOwnProperty("displayCompanyName"))
            if (!$util.isString(message.displayCompanyName))
                return "displayCompanyName: string expected";
        if (message.displayWelcomeMessage != null && message.hasOwnProperty("displayWelcomeMessage"))
            if (!$util.isString(message.displayWelcomeMessage))
                return "displayWelcomeMessage: string expected";
        if (message.displayAvatar != null && message.hasOwnProperty("displayAvatar")) {
            let error = $root.WWAttachment.verify(message.displayAvatar);
            if (error)
                return "displayAvatar." + error;
        }
        if (message.displayColor != null && message.hasOwnProperty("displayColor"))
            if (!$util.isString(message.displayColor))
                return "displayColor: string expected";
        if (message.chatButtonSize != null && message.hasOwnProperty("chatButtonSize"))
            if (!$util.isInteger(message.chatButtonSize) && !(message.chatButtonSize && $util.isInteger(message.chatButtonSize.low) && $util.isInteger(message.chatButtonSize.high)))
                return "chatButtonSize: integer|Long expected";
        if (message.position != null && message.hasOwnProperty("position"))
            if (!$util.isString(message.position))
                return "position: string expected";
        if (message.helloSnippets != null && message.hasOwnProperty("helloSnippets")) {
            if (!Array.isArray(message.helloSnippets))
                return "helloSnippets: array expected";
            for (let i = 0; i < message.helloSnippets.length; ++i) {
                let error = $root.WebWidgetConfig.HelloSnippet.verify(message.helloSnippets[i]);
                if (error)
                    return "helloSnippets." + error;
            }
        }
        if (message.externalMessagingAPIEnabled != null && message.hasOwnProperty("externalMessagingAPIEnabled"))
            if (typeof message.externalMessagingAPIEnabled !== "boolean")
                return "externalMessagingAPIEnabled: boolean expected";
        return null;
    };

    /**
     * Creates a WebWidgetConfig message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof WebWidgetConfig
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {WebWidgetConfig} WebWidgetConfig
     */
    WebWidgetConfig.fromObject = function fromObject(object) {
        if (object instanceof $root.WebWidgetConfig)
            return object;
        let message = new $root.WebWidgetConfig();
        if (object.APICode != null)
            message.APICode = String(object.APICode);
        if (object.domain != null)
            message.domain = String(object.domain);
        if (object.displayCompanyName != null)
            message.displayCompanyName = String(object.displayCompanyName);
        if (object.displayWelcomeMessage != null)
            message.displayWelcomeMessage = String(object.displayWelcomeMessage);
        if (object.displayAvatar != null) {
            if (typeof object.displayAvatar !== "object")
                throw TypeError(".WebWidgetConfig.displayAvatar: object expected");
            message.displayAvatar = $root.WWAttachment.fromObject(object.displayAvatar);
        }
        if (object.displayColor != null)
            message.displayColor = String(object.displayColor);
        if (object.chatButtonSize != null)
            if ($util.Long)
                (message.chatButtonSize = $util.Long.fromValue(object.chatButtonSize)).unsigned = true;
            else if (typeof object.chatButtonSize === "string")
                message.chatButtonSize = parseInt(object.chatButtonSize, 10);
            else if (typeof object.chatButtonSize === "number")
                message.chatButtonSize = object.chatButtonSize;
            else if (typeof object.chatButtonSize === "object")
                message.chatButtonSize = new $util.LongBits(object.chatButtonSize.low >>> 0, object.chatButtonSize.high >>> 0).toNumber(true);
        if (object.position != null)
            message.position = String(object.position);
        if (object.helloSnippets) {
            if (!Array.isArray(object.helloSnippets))
                throw TypeError(".WebWidgetConfig.helloSnippets: array expected");
            message.helloSnippets = [];
            for (let i = 0; i < object.helloSnippets.length; ++i) {
                if (typeof object.helloSnippets[i] !== "object")
                    throw TypeError(".WebWidgetConfig.helloSnippets: object expected");
                message.helloSnippets[i] = $root.WebWidgetConfig.HelloSnippet.fromObject(object.helloSnippets[i]);
            }
        }
        if (object.externalMessagingAPIEnabled != null)
            message.externalMessagingAPIEnabled = Boolean(object.externalMessagingAPIEnabled);
        return message;
    };

    /**
     * Creates a plain object from a WebWidgetConfig message. Also converts values to other types if specified.
     * @function toObject
     * @memberof WebWidgetConfig
     * @static
     * @param {WebWidgetConfig} message WebWidgetConfig
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WebWidgetConfig.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.helloSnippets = [];
        if (options.defaults) {
            object.APICode = "";
            object.domain = "";
            object.displayCompanyName = "";
            object.displayWelcomeMessage = "";
            object.displayAvatar = null;
            object.displayColor = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatButtonSize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatButtonSize = options.longs === String ? "0" : 0;
            object.position = "";
            object.externalMessagingAPIEnabled = false;
        }
        if (message.APICode != null && message.hasOwnProperty("APICode"))
            object.APICode = message.APICode;
        if (message.domain != null && message.hasOwnProperty("domain"))
            object.domain = message.domain;
        if (message.displayCompanyName != null && message.hasOwnProperty("displayCompanyName"))
            object.displayCompanyName = message.displayCompanyName;
        if (message.displayWelcomeMessage != null && message.hasOwnProperty("displayWelcomeMessage"))
            object.displayWelcomeMessage = message.displayWelcomeMessage;
        if (message.displayAvatar != null && message.hasOwnProperty("displayAvatar"))
            object.displayAvatar = $root.WWAttachment.toObject(message.displayAvatar, options);
        if (message.displayColor != null && message.hasOwnProperty("displayColor"))
            object.displayColor = message.displayColor;
        if (message.chatButtonSize != null && message.hasOwnProperty("chatButtonSize"))
            if (typeof message.chatButtonSize === "number")
                object.chatButtonSize = options.longs === String ? String(message.chatButtonSize) : message.chatButtonSize;
            else
                object.chatButtonSize = options.longs === String ? $util.Long.prototype.toString.call(message.chatButtonSize) : options.longs === Number ? new $util.LongBits(message.chatButtonSize.low >>> 0, message.chatButtonSize.high >>> 0).toNumber(true) : message.chatButtonSize;
        if (message.position != null && message.hasOwnProperty("position"))
            object.position = message.position;
        if (message.helloSnippets && message.helloSnippets.length) {
            object.helloSnippets = [];
            for (let j = 0; j < message.helloSnippets.length; ++j)
                object.helloSnippets[j] = $root.WebWidgetConfig.HelloSnippet.toObject(message.helloSnippets[j], options);
        }
        if (message.externalMessagingAPIEnabled != null && message.hasOwnProperty("externalMessagingAPIEnabled"))
            object.externalMessagingAPIEnabled = message.externalMessagingAPIEnabled;
        return object;
    };

    /**
     * Converts this WebWidgetConfig to JSON.
     * @function toJSON
     * @memberof WebWidgetConfig
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WebWidgetConfig.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WebWidgetConfig
     * @function getTypeUrl
     * @memberof WebWidgetConfig
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WebWidgetConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WebWidgetConfig";
    };

    WebWidgetConfig.HelloSnippet = (function() {

        /**
         * Properties of a HelloSnippet.
         * @memberof WebWidgetConfig
         * @interface IHelloSnippet
         * @property {string|null} [text] HelloSnippet text
         * @property {boolean|null} [enabled] HelloSnippet enabled
         */

        /**
         * Constructs a new HelloSnippet.
         * @memberof WebWidgetConfig
         * @classdesc Represents a HelloSnippet.
         * @implements IHelloSnippet
         * @constructor
         * @param {WebWidgetConfig.IHelloSnippet=} [properties] Properties to set
         */
        function HelloSnippet(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HelloSnippet text.
         * @member {string} text
         * @memberof WebWidgetConfig.HelloSnippet
         * @instance
         */
        HelloSnippet.prototype.text = "";

        /**
         * HelloSnippet enabled.
         * @member {boolean} enabled
         * @memberof WebWidgetConfig.HelloSnippet
         * @instance
         */
        HelloSnippet.prototype.enabled = false;

        /**
         * Creates a new HelloSnippet instance using the specified properties.
         * @function create
         * @memberof WebWidgetConfig.HelloSnippet
         * @static
         * @param {WebWidgetConfig.IHelloSnippet=} [properties] Properties to set
         * @returns {WebWidgetConfig.HelloSnippet} HelloSnippet instance
         */
        HelloSnippet.create = function create(properties) {
            return new HelloSnippet(properties);
        };

        /**
         * Encodes the specified HelloSnippet message. Does not implicitly {@link WebWidgetConfig.HelloSnippet.verify|verify} messages.
         * @function encode
         * @memberof WebWidgetConfig.HelloSnippet
         * @static
         * @param {WebWidgetConfig.IHelloSnippet} message HelloSnippet message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HelloSnippet.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
            if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.enabled);
            return writer;
        };

        /**
         * Encodes the specified HelloSnippet message, length delimited. Does not implicitly {@link WebWidgetConfig.HelloSnippet.verify|verify} messages.
         * @function encodeDelimited
         * @memberof WebWidgetConfig.HelloSnippet
         * @static
         * @param {WebWidgetConfig.IHelloSnippet} message HelloSnippet message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HelloSnippet.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HelloSnippet message from the specified reader or buffer.
         * @function decode
         * @memberof WebWidgetConfig.HelloSnippet
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {WebWidgetConfig.HelloSnippet} HelloSnippet
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HelloSnippet.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WebWidgetConfig.HelloSnippet();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.text = reader.string();
                        break;
                    }
                case 2: {
                        message.enabled = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HelloSnippet message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof WebWidgetConfig.HelloSnippet
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {WebWidgetConfig.HelloSnippet} HelloSnippet
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HelloSnippet.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HelloSnippet message.
         * @function verify
         * @memberof WebWidgetConfig.HelloSnippet
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HelloSnippet.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            if (message.enabled != null && message.hasOwnProperty("enabled"))
                if (typeof message.enabled !== "boolean")
                    return "enabled: boolean expected";
            return null;
        };

        /**
         * Creates a HelloSnippet message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WebWidgetConfig.HelloSnippet
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {WebWidgetConfig.HelloSnippet} HelloSnippet
         */
        HelloSnippet.fromObject = function fromObject(object) {
            if (object instanceof $root.WebWidgetConfig.HelloSnippet)
                return object;
            let message = new $root.WebWidgetConfig.HelloSnippet();
            if (object.text != null)
                message.text = String(object.text);
            if (object.enabled != null)
                message.enabled = Boolean(object.enabled);
            return message;
        };

        /**
         * Creates a plain object from a HelloSnippet message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WebWidgetConfig.HelloSnippet
         * @static
         * @param {WebWidgetConfig.HelloSnippet} message HelloSnippet
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HelloSnippet.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.text = "";
                object.enabled = false;
            }
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            if (message.enabled != null && message.hasOwnProperty("enabled"))
                object.enabled = message.enabled;
            return object;
        };

        /**
         * Converts this HelloSnippet to JSON.
         * @function toJSON
         * @memberof WebWidgetConfig.HelloSnippet
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HelloSnippet.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for HelloSnippet
         * @function getTypeUrl
         * @memberof WebWidgetConfig.HelloSnippet
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HelloSnippet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/WebWidgetConfig.HelloSnippet";
        };

        return HelloSnippet;
    })();

    return WebWidgetConfig;
})();

export const WebWidgetCreate = $root.WebWidgetCreate = (() => {

    /**
     * Properties of a WebWidgetCreate.
     * @exports IWebWidgetCreate
     * @interface IWebWidgetCreate
     * @property {IWebWidgetConfig|null} [config] WebWidgetCreate config
     */

    /**
     * Constructs a new WebWidgetCreate.
     * @exports WebWidgetCreate
     * @classdesc Represents a WebWidgetCreate.
     * @implements IWebWidgetCreate
     * @constructor
     * @param {IWebWidgetCreate=} [properties] Properties to set
     */
    function WebWidgetCreate(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WebWidgetCreate config.
     * @member {IWebWidgetConfig|null|undefined} config
     * @memberof WebWidgetCreate
     * @instance
     */
    WebWidgetCreate.prototype.config = null;

    /**
     * Creates a new WebWidgetCreate instance using the specified properties.
     * @function create
     * @memberof WebWidgetCreate
     * @static
     * @param {IWebWidgetCreate=} [properties] Properties to set
     * @returns {WebWidgetCreate} WebWidgetCreate instance
     */
    WebWidgetCreate.create = function create(properties) {
        return new WebWidgetCreate(properties);
    };

    /**
     * Encodes the specified WebWidgetCreate message. Does not implicitly {@link WebWidgetCreate.verify|verify} messages.
     * @function encode
     * @memberof WebWidgetCreate
     * @static
     * @param {IWebWidgetCreate} message WebWidgetCreate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebWidgetCreate.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.config != null && Object.hasOwnProperty.call(message, "config"))
            $root.WebWidgetConfig.encode(message.config, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified WebWidgetCreate message, length delimited. Does not implicitly {@link WebWidgetCreate.verify|verify} messages.
     * @function encodeDelimited
     * @memberof WebWidgetCreate
     * @static
     * @param {IWebWidgetCreate} message WebWidgetCreate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebWidgetCreate.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WebWidgetCreate message from the specified reader or buffer.
     * @function decode
     * @memberof WebWidgetCreate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WebWidgetCreate} WebWidgetCreate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebWidgetCreate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WebWidgetCreate();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.config = $root.WebWidgetConfig.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a WebWidgetCreate message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof WebWidgetCreate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {WebWidgetCreate} WebWidgetCreate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebWidgetCreate.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WebWidgetCreate message.
     * @function verify
     * @memberof WebWidgetCreate
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WebWidgetCreate.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.config != null && message.hasOwnProperty("config")) {
            let error = $root.WebWidgetConfig.verify(message.config);
            if (error)
                return "config." + error;
        }
        return null;
    };

    /**
     * Creates a WebWidgetCreate message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof WebWidgetCreate
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {WebWidgetCreate} WebWidgetCreate
     */
    WebWidgetCreate.fromObject = function fromObject(object) {
        if (object instanceof $root.WebWidgetCreate)
            return object;
        let message = new $root.WebWidgetCreate();
        if (object.config != null) {
            if (typeof object.config !== "object")
                throw TypeError(".WebWidgetCreate.config: object expected");
            message.config = $root.WebWidgetConfig.fromObject(object.config);
        }
        return message;
    };

    /**
     * Creates a plain object from a WebWidgetCreate message. Also converts values to other types if specified.
     * @function toObject
     * @memberof WebWidgetCreate
     * @static
     * @param {WebWidgetCreate} message WebWidgetCreate
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WebWidgetCreate.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.config = null;
        if (message.config != null && message.hasOwnProperty("config"))
            object.config = $root.WebWidgetConfig.toObject(message.config, options);
        return object;
    };

    /**
     * Converts this WebWidgetCreate to JSON.
     * @function toJSON
     * @memberof WebWidgetCreate
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WebWidgetCreate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WebWidgetCreate
     * @function getTypeUrl
     * @memberof WebWidgetCreate
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WebWidgetCreate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WebWidgetCreate";
    };

    return WebWidgetCreate;
})();

export const WWAttachmentSource = $root.WWAttachmentSource = (() => {

    /**
     * Properties of a WWAttachmentSource.
     * @exports IWWAttachmentSource
     * @interface IWWAttachmentSource
     * @property {WWAttachmentSource.Source|null} [source] WWAttachmentSource source
     * @property {Uint8Array|null} [reference] WWAttachmentSource reference
     */

    /**
     * Constructs a new WWAttachmentSource.
     * @exports WWAttachmentSource
     * @classdesc Represents a WWAttachmentSource.
     * @implements IWWAttachmentSource
     * @constructor
     * @param {IWWAttachmentSource=} [properties] Properties to set
     */
    function WWAttachmentSource(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WWAttachmentSource source.
     * @member {WWAttachmentSource.Source} source
     * @memberof WWAttachmentSource
     * @instance
     */
    WWAttachmentSource.prototype.source = 0;

    /**
     * WWAttachmentSource reference.
     * @member {Uint8Array} reference
     * @memberof WWAttachmentSource
     * @instance
     */
    WWAttachmentSource.prototype.reference = $util.newBuffer([]);

    /**
     * Creates a new WWAttachmentSource instance using the specified properties.
     * @function create
     * @memberof WWAttachmentSource
     * @static
     * @param {IWWAttachmentSource=} [properties] Properties to set
     * @returns {WWAttachmentSource} WWAttachmentSource instance
     */
    WWAttachmentSource.create = function create(properties) {
        return new WWAttachmentSource(properties);
    };

    /**
     * Encodes the specified WWAttachmentSource message. Does not implicitly {@link WWAttachmentSource.verify|verify} messages.
     * @function encode
     * @memberof WWAttachmentSource
     * @static
     * @param {IWWAttachmentSource} message WWAttachmentSource message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WWAttachmentSource.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.source != null && Object.hasOwnProperty.call(message, "source"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.source);
        if (message.reference != null && Object.hasOwnProperty.call(message, "reference"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.reference);
        return writer;
    };

    /**
     * Encodes the specified WWAttachmentSource message, length delimited. Does not implicitly {@link WWAttachmentSource.verify|verify} messages.
     * @function encodeDelimited
     * @memberof WWAttachmentSource
     * @static
     * @param {IWWAttachmentSource} message WWAttachmentSource message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WWAttachmentSource.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WWAttachmentSource message from the specified reader or buffer.
     * @function decode
     * @memberof WWAttachmentSource
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WWAttachmentSource} WWAttachmentSource
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WWAttachmentSource.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WWAttachmentSource();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.source = reader.int32();
                    break;
                }
            case 2: {
                    message.reference = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a WWAttachmentSource message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof WWAttachmentSource
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {WWAttachmentSource} WWAttachmentSource
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WWAttachmentSource.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WWAttachmentSource message.
     * @function verify
     * @memberof WWAttachmentSource
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WWAttachmentSource.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.source != null && message.hasOwnProperty("source"))
            switch (message.source) {
            default:
                return "source: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.reference != null && message.hasOwnProperty("reference"))
            if (!(message.reference && typeof message.reference.length === "number" || $util.isString(message.reference)))
                return "reference: buffer expected";
        return null;
    };

    /**
     * Creates a WWAttachmentSource message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof WWAttachmentSource
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {WWAttachmentSource} WWAttachmentSource
     */
    WWAttachmentSource.fromObject = function fromObject(object) {
        if (object instanceof $root.WWAttachmentSource)
            return object;
        let message = new $root.WWAttachmentSource();
        switch (object.source) {
        default:
            if (typeof object.source === "number") {
                message.source = object.source;
                break;
            }
            break;
        case "UNKNOWN":
        case 0:
            message.source = 0;
            break;
        case "BYTESERVER":
        case 1:
            message.source = 1;
            break;
        case "INLINE":
        case 2:
            message.source = 2;
            break;
        }
        if (object.reference != null)
            if (typeof object.reference === "string")
                $util.base64.decode(object.reference, message.reference = $util.newBuffer($util.base64.length(object.reference)), 0);
            else if (object.reference.length >= 0)
                message.reference = object.reference;
        return message;
    };

    /**
     * Creates a plain object from a WWAttachmentSource message. Also converts values to other types if specified.
     * @function toObject
     * @memberof WWAttachmentSource
     * @static
     * @param {WWAttachmentSource} message WWAttachmentSource
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WWAttachmentSource.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.source = options.enums === String ? "UNKNOWN" : 0;
            if (options.bytes === String)
                object.reference = "";
            else {
                object.reference = [];
                if (options.bytes !== Array)
                    object.reference = $util.newBuffer(object.reference);
            }
        }
        if (message.source != null && message.hasOwnProperty("source"))
            object.source = options.enums === String ? $root.WWAttachmentSource.Source[message.source] === undefined ? message.source : $root.WWAttachmentSource.Source[message.source] : message.source;
        if (message.reference != null && message.hasOwnProperty("reference"))
            object.reference = options.bytes === String ? $util.base64.encode(message.reference, 0, message.reference.length) : options.bytes === Array ? Array.prototype.slice.call(message.reference) : message.reference;
        return object;
    };

    /**
     * Converts this WWAttachmentSource to JSON.
     * @function toJSON
     * @memberof WWAttachmentSource
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WWAttachmentSource.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WWAttachmentSource
     * @function getTypeUrl
     * @memberof WWAttachmentSource
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WWAttachmentSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WWAttachmentSource";
    };

    /**
     * Source enum.
     * @name WWAttachmentSource.Source
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} BYTESERVER=1 BYTESERVER value
     * @property {number} INLINE=2 INLINE value
     */
    WWAttachmentSource.Source = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "BYTESERVER"] = 1;
        values[valuesById[2] = "INLINE"] = 2;
        return values;
    })();

    return WWAttachmentSource;
})();

export const WWAttachment = $root.WWAttachment = (() => {

    /**
     * Properties of a WWAttachment.
     * @exports IWWAttachment
     * @interface IWWAttachment
     * @property {Long|null} [size] WWAttachment size
     * @property {string|null} [name] WWAttachment name
     * @property {WWAttachment.Type|null} [type] WWAttachment type
     * @property {string|null} [mimeType] WWAttachment mimeType
     * @property {Long|null} [width] WWAttachment width
     * @property {Long|null} [height] WWAttachment height
     * @property {Long|null} [duration] WWAttachment duration
     * @property {IWWAttachmentSource|null} [source] WWAttachment source
     * @property {IWWAttachment|null} [preview] WWAttachment preview
     */

    /**
     * Constructs a new WWAttachment.
     * @exports WWAttachment
     * @classdesc Represents a WWAttachment.
     * @implements IWWAttachment
     * @constructor
     * @param {IWWAttachment=} [properties] Properties to set
     */
    function WWAttachment(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WWAttachment size.
     * @member {Long} size
     * @memberof WWAttachment
     * @instance
     */
    WWAttachment.prototype.size = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * WWAttachment name.
     * @member {string} name
     * @memberof WWAttachment
     * @instance
     */
    WWAttachment.prototype.name = "";

    /**
     * WWAttachment type.
     * @member {WWAttachment.Type} type
     * @memberof WWAttachment
     * @instance
     */
    WWAttachment.prototype.type = 0;

    /**
     * WWAttachment mimeType.
     * @member {string} mimeType
     * @memberof WWAttachment
     * @instance
     */
    WWAttachment.prototype.mimeType = "";

    /**
     * WWAttachment width.
     * @member {Long} width
     * @memberof WWAttachment
     * @instance
     */
    WWAttachment.prototype.width = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * WWAttachment height.
     * @member {Long} height
     * @memberof WWAttachment
     * @instance
     */
    WWAttachment.prototype.height = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * WWAttachment duration.
     * @member {Long} duration
     * @memberof WWAttachment
     * @instance
     */
    WWAttachment.prototype.duration = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * WWAttachment source.
     * @member {IWWAttachmentSource|null|undefined} source
     * @memberof WWAttachment
     * @instance
     */
    WWAttachment.prototype.source = null;

    /**
     * WWAttachment preview.
     * @member {IWWAttachment|null|undefined} preview
     * @memberof WWAttachment
     * @instance
     */
    WWAttachment.prototype.preview = null;

    /**
     * Creates a new WWAttachment instance using the specified properties.
     * @function create
     * @memberof WWAttachment
     * @static
     * @param {IWWAttachment=} [properties] Properties to set
     * @returns {WWAttachment} WWAttachment instance
     */
    WWAttachment.create = function create(properties) {
        return new WWAttachment(properties);
    };

    /**
     * Encodes the specified WWAttachment message. Does not implicitly {@link WWAttachment.verify|verify} messages.
     * @function encode
     * @memberof WWAttachment
     * @static
     * @param {IWWAttachment} message WWAttachment message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WWAttachment.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.size != null && Object.hasOwnProperty.call(message, "size"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.size);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
        if (message.width != null && Object.hasOwnProperty.call(message, "width"))
            writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.width);
        if (message.height != null && Object.hasOwnProperty.call(message, "height"))
            writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.height);
        if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
            writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.duration);
        if (message.mimeType != null && Object.hasOwnProperty.call(message, "mimeType"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.mimeType);
        if (message.source != null && Object.hasOwnProperty.call(message, "source"))
            $root.WWAttachmentSource.encode(message.source, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
        if (message.preview != null && Object.hasOwnProperty.call(message, "preview"))
            $root.WWAttachment.encode(message.preview, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified WWAttachment message, length delimited. Does not implicitly {@link WWAttachment.verify|verify} messages.
     * @function encodeDelimited
     * @memberof WWAttachment
     * @static
     * @param {IWWAttachment} message WWAttachment message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WWAttachment.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WWAttachment message from the specified reader or buffer.
     * @function decode
     * @memberof WWAttachment
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WWAttachment} WWAttachment
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WWAttachment.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WWAttachment();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 2: {
                    message.size = reader.uint64();
                    break;
                }
            case 3: {
                    message.name = reader.string();
                    break;
                }
            case 4: {
                    message.type = reader.int32();
                    break;
                }
            case 10: {
                    message.mimeType = reader.string();
                    break;
                }
            case 6: {
                    message.width = reader.uint64();
                    break;
                }
            case 7: {
                    message.height = reader.uint64();
                    break;
                }
            case 8: {
                    message.duration = reader.uint64();
                    break;
                }
            case 20: {
                    message.source = $root.WWAttachmentSource.decode(reader, reader.uint32());
                    break;
                }
            case 21: {
                    message.preview = $root.WWAttachment.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a WWAttachment message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof WWAttachment
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {WWAttachment} WWAttachment
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WWAttachment.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WWAttachment message.
     * @function verify
     * @memberof WWAttachment
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WWAttachment.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.size != null && message.hasOwnProperty("size"))
            if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                return "size: integer|Long expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                break;
            }
        if (message.mimeType != null && message.hasOwnProperty("mimeType"))
            if (!$util.isString(message.mimeType))
                return "mimeType: string expected";
        if (message.width != null && message.hasOwnProperty("width"))
            if (!$util.isInteger(message.width) && !(message.width && $util.isInteger(message.width.low) && $util.isInteger(message.width.high)))
                return "width: integer|Long expected";
        if (message.height != null && message.hasOwnProperty("height"))
            if (!$util.isInteger(message.height) && !(message.height && $util.isInteger(message.height.low) && $util.isInteger(message.height.high)))
                return "height: integer|Long expected";
        if (message.duration != null && message.hasOwnProperty("duration"))
            if (!$util.isInteger(message.duration) && !(message.duration && $util.isInteger(message.duration.low) && $util.isInteger(message.duration.high)))
                return "duration: integer|Long expected";
        if (message.source != null && message.hasOwnProperty("source")) {
            let error = $root.WWAttachmentSource.verify(message.source);
            if (error)
                return "source." + error;
        }
        if (message.preview != null && message.hasOwnProperty("preview")) {
            let error = $root.WWAttachment.verify(message.preview);
            if (error)
                return "preview." + error;
        }
        return null;
    };

    /**
     * Creates a WWAttachment message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof WWAttachment
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {WWAttachment} WWAttachment
     */
    WWAttachment.fromObject = function fromObject(object) {
        if (object instanceof $root.WWAttachment)
            return object;
        let message = new $root.WWAttachment();
        if (object.size != null)
            if ($util.Long)
                (message.size = $util.Long.fromValue(object.size)).unsigned = true;
            else if (typeof object.size === "string")
                message.size = parseInt(object.size, 10);
            else if (typeof object.size === "number")
                message.size = object.size;
            else if (typeof object.size === "object")
                message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber(true);
        if (object.name != null)
            message.name = String(object.name);
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "UNKNOWN":
        case 0:
            message.type = 0;
            break;
        case "IMAGE":
        case 1:
            message.type = 1;
            break;
        case "VIDEO":
        case 2:
            message.type = 2;
            break;
        case "AUDIO":
        case 3:
            message.type = 3;
            break;
        case "DOCUMENT":
        case 4:
            message.type = 4;
            break;
        case "STICKER":
        case 5:
            message.type = 5;
            break;
        case "GIF":
        case 6:
            message.type = 6;
            break;
        case "VOICE":
        case 7:
            message.type = 7;
            break;
        }
        if (object.mimeType != null)
            message.mimeType = String(object.mimeType);
        if (object.width != null)
            if ($util.Long)
                (message.width = $util.Long.fromValue(object.width)).unsigned = true;
            else if (typeof object.width === "string")
                message.width = parseInt(object.width, 10);
            else if (typeof object.width === "number")
                message.width = object.width;
            else if (typeof object.width === "object")
                message.width = new $util.LongBits(object.width.low >>> 0, object.width.high >>> 0).toNumber(true);
        if (object.height != null)
            if ($util.Long)
                (message.height = $util.Long.fromValue(object.height)).unsigned = true;
            else if (typeof object.height === "string")
                message.height = parseInt(object.height, 10);
            else if (typeof object.height === "number")
                message.height = object.height;
            else if (typeof object.height === "object")
                message.height = new $util.LongBits(object.height.low >>> 0, object.height.high >>> 0).toNumber(true);
        if (object.duration != null)
            if ($util.Long)
                (message.duration = $util.Long.fromValue(object.duration)).unsigned = true;
            else if (typeof object.duration === "string")
                message.duration = parseInt(object.duration, 10);
            else if (typeof object.duration === "number")
                message.duration = object.duration;
            else if (typeof object.duration === "object")
                message.duration = new $util.LongBits(object.duration.low >>> 0, object.duration.high >>> 0).toNumber(true);
        if (object.source != null) {
            if (typeof object.source !== "object")
                throw TypeError(".WWAttachment.source: object expected");
            message.source = $root.WWAttachmentSource.fromObject(object.source);
        }
        if (object.preview != null) {
            if (typeof object.preview !== "object")
                throw TypeError(".WWAttachment.preview: object expected");
            message.preview = $root.WWAttachment.fromObject(object.preview);
        }
        return message;
    };

    /**
     * Creates a plain object from a WWAttachment message. Also converts values to other types if specified.
     * @function toObject
     * @memberof WWAttachment
     * @static
     * @param {WWAttachment} message WWAttachment
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WWAttachment.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.size = options.longs === String ? "0" : 0;
            object.name = "";
            object.type = options.enums === String ? "UNKNOWN" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.width = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.width = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.height = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.height = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.duration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.duration = options.longs === String ? "0" : 0;
            object.mimeType = "";
            object.source = null;
            object.preview = null;
        }
        if (message.size != null && message.hasOwnProperty("size"))
            if (typeof message.size === "number")
                object.size = options.longs === String ? String(message.size) : message.size;
            else
                object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber(true) : message.size;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.WWAttachment.Type[message.type] === undefined ? message.type : $root.WWAttachment.Type[message.type] : message.type;
        if (message.width != null && message.hasOwnProperty("width"))
            if (typeof message.width === "number")
                object.width = options.longs === String ? String(message.width) : message.width;
            else
                object.width = options.longs === String ? $util.Long.prototype.toString.call(message.width) : options.longs === Number ? new $util.LongBits(message.width.low >>> 0, message.width.high >>> 0).toNumber(true) : message.width;
        if (message.height != null && message.hasOwnProperty("height"))
            if (typeof message.height === "number")
                object.height = options.longs === String ? String(message.height) : message.height;
            else
                object.height = options.longs === String ? $util.Long.prototype.toString.call(message.height) : options.longs === Number ? new $util.LongBits(message.height.low >>> 0, message.height.high >>> 0).toNumber(true) : message.height;
        if (message.duration != null && message.hasOwnProperty("duration"))
            if (typeof message.duration === "number")
                object.duration = options.longs === String ? String(message.duration) : message.duration;
            else
                object.duration = options.longs === String ? $util.Long.prototype.toString.call(message.duration) : options.longs === Number ? new $util.LongBits(message.duration.low >>> 0, message.duration.high >>> 0).toNumber(true) : message.duration;
        if (message.mimeType != null && message.hasOwnProperty("mimeType"))
            object.mimeType = message.mimeType;
        if (message.source != null && message.hasOwnProperty("source"))
            object.source = $root.WWAttachmentSource.toObject(message.source, options);
        if (message.preview != null && message.hasOwnProperty("preview"))
            object.preview = $root.WWAttachment.toObject(message.preview, options);
        return object;
    };

    /**
     * Converts this WWAttachment to JSON.
     * @function toJSON
     * @memberof WWAttachment
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WWAttachment.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WWAttachment
     * @function getTypeUrl
     * @memberof WWAttachment
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WWAttachment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WWAttachment";
    };

    /**
     * Type enum.
     * @name WWAttachment.Type
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} IMAGE=1 IMAGE value
     * @property {number} VIDEO=2 VIDEO value
     * @property {number} AUDIO=3 AUDIO value
     * @property {number} DOCUMENT=4 DOCUMENT value
     * @property {number} STICKER=5 STICKER value
     * @property {number} GIF=6 GIF value
     * @property {number} VOICE=7 VOICE value
     */
    WWAttachment.Type = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "IMAGE"] = 1;
        values[valuesById[2] = "VIDEO"] = 2;
        values[valuesById[3] = "AUDIO"] = 3;
        values[valuesById[4] = "DOCUMENT"] = 4;
        values[valuesById[5] = "STICKER"] = 5;
        values[valuesById[6] = "GIF"] = 6;
        values[valuesById[7] = "VOICE"] = 7;
        return values;
    })();

    return WWAttachment;
})();

export const TelegramBotChannelState = $root.TelegramBotChannelState = (() => {

    /**
     * Properties of a TelegramBotChannelState.
     * @exports ITelegramBotChannelState
     * @interface ITelegramBotChannelState
     * @property {TelegramBotChannelState.State|null} [state] TelegramBotChannelState state
     */

    /**
     * Constructs a new TelegramBotChannelState.
     * @exports TelegramBotChannelState
     * @classdesc Represents a TelegramBotChannelState.
     * @implements ITelegramBotChannelState
     * @constructor
     * @param {ITelegramBotChannelState=} [properties] Properties to set
     */
    function TelegramBotChannelState(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TelegramBotChannelState state.
     * @member {TelegramBotChannelState.State} state
     * @memberof TelegramBotChannelState
     * @instance
     */
    TelegramBotChannelState.prototype.state = 0;

    /**
     * Creates a new TelegramBotChannelState instance using the specified properties.
     * @function create
     * @memberof TelegramBotChannelState
     * @static
     * @param {ITelegramBotChannelState=} [properties] Properties to set
     * @returns {TelegramBotChannelState} TelegramBotChannelState instance
     */
    TelegramBotChannelState.create = function create(properties) {
        return new TelegramBotChannelState(properties);
    };

    /**
     * Encodes the specified TelegramBotChannelState message. Does not implicitly {@link TelegramBotChannelState.verify|verify} messages.
     * @function encode
     * @memberof TelegramBotChannelState
     * @static
     * @param {ITelegramBotChannelState} message TelegramBotChannelState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramBotChannelState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
        return writer;
    };

    /**
     * Encodes the specified TelegramBotChannelState message, length delimited. Does not implicitly {@link TelegramBotChannelState.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TelegramBotChannelState
     * @static
     * @param {ITelegramBotChannelState} message TelegramBotChannelState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramBotChannelState.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TelegramBotChannelState message from the specified reader or buffer.
     * @function decode
     * @memberof TelegramBotChannelState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TelegramBotChannelState} TelegramBotChannelState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramBotChannelState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramBotChannelState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.state = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TelegramBotChannelState message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TelegramBotChannelState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TelegramBotChannelState} TelegramBotChannelState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramBotChannelState.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TelegramBotChannelState message.
     * @function verify
     * @memberof TelegramBotChannelState
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TelegramBotChannelState.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.state != null && message.hasOwnProperty("state"))
            switch (message.state) {
            default:
                return "state: enum value expected";
            case 0:
            case 10:
                break;
            }
        return null;
    };

    /**
     * Creates a TelegramBotChannelState message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TelegramBotChannelState
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TelegramBotChannelState} TelegramBotChannelState
     */
    TelegramBotChannelState.fromObject = function fromObject(object) {
        if (object instanceof $root.TelegramBotChannelState)
            return object;
        let message = new $root.TelegramBotChannelState();
        switch (object.state) {
        default:
            if (typeof object.state === "number") {
                message.state = object.state;
                break;
            }
            break;
        case "CREATE_CHANNEL":
        case 0:
            message.state = 0;
            break;
        case "EVENT_LISTENING":
        case 10:
            message.state = 10;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a TelegramBotChannelState message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TelegramBotChannelState
     * @static
     * @param {TelegramBotChannelState} message TelegramBotChannelState
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TelegramBotChannelState.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.state = options.enums === String ? "CREATE_CHANNEL" : 0;
        if (message.state != null && message.hasOwnProperty("state"))
            object.state = options.enums === String ? $root.TelegramBotChannelState.State[message.state] === undefined ? message.state : $root.TelegramBotChannelState.State[message.state] : message.state;
        return object;
    };

    /**
     * Converts this TelegramBotChannelState to JSON.
     * @function toJSON
     * @memberof TelegramBotChannelState
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TelegramBotChannelState.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TelegramBotChannelState
     * @function getTypeUrl
     * @memberof TelegramBotChannelState
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TelegramBotChannelState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TelegramBotChannelState";
    };

    /**
     * State enum.
     * @name TelegramBotChannelState.State
     * @enum {number}
     * @property {number} CREATE_CHANNEL=0 CREATE_CHANNEL value
     * @property {number} EVENT_LISTENING=10 EVENT_LISTENING value
     */
    TelegramBotChannelState.State = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CREATE_CHANNEL"] = 0;
        values[valuesById[10] = "EVENT_LISTENING"] = 10;
        return values;
    })();

    return TelegramBotChannelState;
})();

export const InstagramChannelState = $root.InstagramChannelState = (() => {

    /**
     * Properties of an InstagramChannelState.
     * @exports IInstagramChannelState
     * @interface IInstagramChannelState
     * @property {InstagramChannelState.State|null} [state] InstagramChannelState state
     */

    /**
     * Constructs a new InstagramChannelState.
     * @exports InstagramChannelState
     * @classdesc Represents an InstagramChannelState.
     * @implements IInstagramChannelState
     * @constructor
     * @param {IInstagramChannelState=} [properties] Properties to set
     */
    function InstagramChannelState(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * InstagramChannelState state.
     * @member {InstagramChannelState.State} state
     * @memberof InstagramChannelState
     * @instance
     */
    InstagramChannelState.prototype.state = 0;

    /**
     * Creates a new InstagramChannelState instance using the specified properties.
     * @function create
     * @memberof InstagramChannelState
     * @static
     * @param {IInstagramChannelState=} [properties] Properties to set
     * @returns {InstagramChannelState} InstagramChannelState instance
     */
    InstagramChannelState.create = function create(properties) {
        return new InstagramChannelState(properties);
    };

    /**
     * Encodes the specified InstagramChannelState message. Does not implicitly {@link InstagramChannelState.verify|verify} messages.
     * @function encode
     * @memberof InstagramChannelState
     * @static
     * @param {IInstagramChannelState} message InstagramChannelState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InstagramChannelState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
        return writer;
    };

    /**
     * Encodes the specified InstagramChannelState message, length delimited. Does not implicitly {@link InstagramChannelState.verify|verify} messages.
     * @function encodeDelimited
     * @memberof InstagramChannelState
     * @static
     * @param {IInstagramChannelState} message InstagramChannelState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InstagramChannelState.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an InstagramChannelState message from the specified reader or buffer.
     * @function decode
     * @memberof InstagramChannelState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {InstagramChannelState} InstagramChannelState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InstagramChannelState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.InstagramChannelState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.state = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an InstagramChannelState message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof InstagramChannelState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {InstagramChannelState} InstagramChannelState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InstagramChannelState.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an InstagramChannelState message.
     * @function verify
     * @memberof InstagramChannelState
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    InstagramChannelState.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.state != null && message.hasOwnProperty("state"))
            switch (message.state) {
            default:
                return "state: enum value expected";
            case 0:
            case 10:
                break;
            }
        return null;
    };

    /**
     * Creates an InstagramChannelState message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof InstagramChannelState
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {InstagramChannelState} InstagramChannelState
     */
    InstagramChannelState.fromObject = function fromObject(object) {
        if (object instanceof $root.InstagramChannelState)
            return object;
        let message = new $root.InstagramChannelState();
        switch (object.state) {
        default:
            if (typeof object.state === "number") {
                message.state = object.state;
                break;
            }
            break;
        case "CREATE_CHANNEL":
        case 0:
            message.state = 0;
            break;
        case "EVENT_LISTENING":
        case 10:
            message.state = 10;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from an InstagramChannelState message. Also converts values to other types if specified.
     * @function toObject
     * @memberof InstagramChannelState
     * @static
     * @param {InstagramChannelState} message InstagramChannelState
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    InstagramChannelState.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.state = options.enums === String ? "CREATE_CHANNEL" : 0;
        if (message.state != null && message.hasOwnProperty("state"))
            object.state = options.enums === String ? $root.InstagramChannelState.State[message.state] === undefined ? message.state : $root.InstagramChannelState.State[message.state] : message.state;
        return object;
    };

    /**
     * Converts this InstagramChannelState to JSON.
     * @function toJSON
     * @memberof InstagramChannelState
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    InstagramChannelState.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for InstagramChannelState
     * @function getTypeUrl
     * @memberof InstagramChannelState
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    InstagramChannelState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/InstagramChannelState";
    };

    /**
     * State enum.
     * @name InstagramChannelState.State
     * @enum {number}
     * @property {number} CREATE_CHANNEL=0 CREATE_CHANNEL value
     * @property {number} EVENT_LISTENING=10 EVENT_LISTENING value
     */
    InstagramChannelState.State = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CREATE_CHANNEL"] = 0;
        values[valuesById[10] = "EVENT_LISTENING"] = 10;
        return values;
    })();

    return InstagramChannelState;
})();

export const Update = $root.Update = (() => {

    /**
     * Properties of an Update.
     * @exports IUpdate
     * @interface IUpdate
     * @property {Uint8Array|null} [channelID] Update channelID
     * @property {Long|null} [version] Update version
     * @property {IUpdateEntities|null} [entities] Update entities
     * @property {IUpdateNewMessage|null} [updateNewMessage] Update updateNewMessage
     * @property {IUpdateEditedMessage|null} [updateEditedMessage] Update updateEditedMessage
     * @property {IUpdateChangedReadPointer|null} [updateChangedReadPointer] Update updateChangedReadPointer
     * @property {IUpdateNewChatPreview|null} [updateNewChatPreview] Update updateNewChatPreview
     * @property {IUpdateChangedAccess|null} [updateChangedAccess] Update updateChangedAccess
     * @property {IUpdateChannelCounterReset|null} [updateChannelCounterReset] Update updateChannelCounterReset
     * @property {IUpdateChatChangedRestrictions|null} [updateChatChangedRestrictions] Update updateChatChangedRestrictions
     * @property {IUpdateOperatorAssignWidget|null} [updateOperatorAssignWidget] Update updateOperatorAssignWidget
     * @property {IUpdateNewMessageSuggestion|null} [updateNewMessageSuggestions] Update updateNewMessageSuggestions
     * @property {IUpdateChannelState|null} [updateChannelState] Update updateChannelState
     * @property {IUpdateRedacted|null} [updateRedacted] Update updateRedacted
     */

    /**
     * Constructs a new Update.
     * @exports Update
     * @classdesc Represents an Update.
     * @implements IUpdate
     * @constructor
     * @param {IUpdate=} [properties] Properties to set
     */
    function Update(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Update channelID.
     * @member {Uint8Array} channelID
     * @memberof Update
     * @instance
     */
    Update.prototype.channelID = $util.newBuffer([]);

    /**
     * Update version.
     * @member {Long} version
     * @memberof Update
     * @instance
     */
    Update.prototype.version = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Update entities.
     * @member {IUpdateEntities|null|undefined} entities
     * @memberof Update
     * @instance
     */
    Update.prototype.entities = null;

    /**
     * Update updateNewMessage.
     * @member {IUpdateNewMessage|null|undefined} updateNewMessage
     * @memberof Update
     * @instance
     */
    Update.prototype.updateNewMessage = null;

    /**
     * Update updateEditedMessage.
     * @member {IUpdateEditedMessage|null|undefined} updateEditedMessage
     * @memberof Update
     * @instance
     */
    Update.prototype.updateEditedMessage = null;

    /**
     * Update updateChangedReadPointer.
     * @member {IUpdateChangedReadPointer|null|undefined} updateChangedReadPointer
     * @memberof Update
     * @instance
     */
    Update.prototype.updateChangedReadPointer = null;

    /**
     * Update updateNewChatPreview.
     * @member {IUpdateNewChatPreview|null|undefined} updateNewChatPreview
     * @memberof Update
     * @instance
     */
    Update.prototype.updateNewChatPreview = null;

    /**
     * Update updateChangedAccess.
     * @member {IUpdateChangedAccess|null|undefined} updateChangedAccess
     * @memberof Update
     * @instance
     */
    Update.prototype.updateChangedAccess = null;

    /**
     * Update updateChannelCounterReset.
     * @member {IUpdateChannelCounterReset|null|undefined} updateChannelCounterReset
     * @memberof Update
     * @instance
     */
    Update.prototype.updateChannelCounterReset = null;

    /**
     * Update updateChatChangedRestrictions.
     * @member {IUpdateChatChangedRestrictions|null|undefined} updateChatChangedRestrictions
     * @memberof Update
     * @instance
     */
    Update.prototype.updateChatChangedRestrictions = null;

    /**
     * Update updateOperatorAssignWidget.
     * @member {IUpdateOperatorAssignWidget|null|undefined} updateOperatorAssignWidget
     * @memberof Update
     * @instance
     */
    Update.prototype.updateOperatorAssignWidget = null;

    /**
     * Update updateNewMessageSuggestions.
     * @member {IUpdateNewMessageSuggestion|null|undefined} updateNewMessageSuggestions
     * @memberof Update
     * @instance
     */
    Update.prototype.updateNewMessageSuggestions = null;

    /**
     * Update updateChannelState.
     * @member {IUpdateChannelState|null|undefined} updateChannelState
     * @memberof Update
     * @instance
     */
    Update.prototype.updateChannelState = null;

    /**
     * Update updateRedacted.
     * @member {IUpdateRedacted|null|undefined} updateRedacted
     * @memberof Update
     * @instance
     */
    Update.prototype.updateRedacted = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * Update update.
     * @member {"updateNewMessage"|"updateEditedMessage"|"updateChangedReadPointer"|"updateNewChatPreview"|"updateChangedAccess"|"updateChannelCounterReset"|"updateChatChangedRestrictions"|"updateOperatorAssignWidget"|"updateNewMessageSuggestions"|"updateChannelState"|"updateRedacted"|undefined} update
     * @memberof Update
     * @instance
     */
    Object.defineProperty(Update.prototype, "update", {
        get: $util.oneOfGetter($oneOfFields = ["updateNewMessage", "updateEditedMessage", "updateChangedReadPointer", "updateNewChatPreview", "updateChangedAccess", "updateChannelCounterReset", "updateChatChangedRestrictions", "updateOperatorAssignWidget", "updateNewMessageSuggestions", "updateChannelState", "updateRedacted"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Update instance using the specified properties.
     * @function create
     * @memberof Update
     * @static
     * @param {IUpdate=} [properties] Properties to set
     * @returns {Update} Update instance
     */
    Update.create = function create(properties) {
        return new Update(properties);
    };

    /**
     * Encodes the specified Update message. Does not implicitly {@link Update.verify|verify} messages.
     * @function encode
     * @memberof Update
     * @static
     * @param {IUpdate} message Update message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Update.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.channelID);
        if (message.version != null && Object.hasOwnProperty.call(message, "version"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.version);
        if (message.entities != null && Object.hasOwnProperty.call(message, "entities"))
            $root.UpdateEntities.encode(message.entities, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.updateNewMessage != null && Object.hasOwnProperty.call(message, "updateNewMessage"))
            $root.UpdateNewMessage.encode(message.updateNewMessage, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.updateEditedMessage != null && Object.hasOwnProperty.call(message, "updateEditedMessage"))
            $root.UpdateEditedMessage.encode(message.updateEditedMessage, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.updateChangedReadPointer != null && Object.hasOwnProperty.call(message, "updateChangedReadPointer"))
            $root.UpdateChangedReadPointer.encode(message.updateChangedReadPointer, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        if (message.updateNewChatPreview != null && Object.hasOwnProperty.call(message, "updateNewChatPreview"))
            $root.UpdateNewChatPreview.encode(message.updateNewChatPreview, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
        if (message.updateChangedAccess != null && Object.hasOwnProperty.call(message, "updateChangedAccess"))
            $root.UpdateChangedAccess.encode(message.updateChangedAccess, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
        if (message.updateChannelCounterReset != null && Object.hasOwnProperty.call(message, "updateChannelCounterReset"))
            $root.UpdateChannelCounterReset.encode(message.updateChannelCounterReset, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
        if (message.updateChatChangedRestrictions != null && Object.hasOwnProperty.call(message, "updateChatChangedRestrictions"))
            $root.UpdateChatChangedRestrictions.encode(message.updateChatChangedRestrictions, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
        if (message.updateOperatorAssignWidget != null && Object.hasOwnProperty.call(message, "updateOperatorAssignWidget"))
            $root.UpdateOperatorAssignWidget.encode(message.updateOperatorAssignWidget, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
        if (message.updateNewMessageSuggestions != null && Object.hasOwnProperty.call(message, "updateNewMessageSuggestions"))
            $root.UpdateNewMessageSuggestion.encode(message.updateNewMessageSuggestions, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
        if (message.updateChannelState != null && Object.hasOwnProperty.call(message, "updateChannelState"))
            $root.UpdateChannelState.encode(message.updateChannelState, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
        if (message.updateRedacted != null && Object.hasOwnProperty.call(message, "updateRedacted"))
            $root.UpdateRedacted.encode(message.updateRedacted, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Update message, length delimited. Does not implicitly {@link Update.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Update
     * @static
     * @param {IUpdate} message Update message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Update.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Update message from the specified reader or buffer.
     * @function decode
     * @memberof Update
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Update} Update
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Update.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Update();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channelID = reader.bytes();
                    break;
                }
            case 2: {
                    message.version = reader.uint64();
                    break;
                }
            case 3: {
                    message.entities = $root.UpdateEntities.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.updateNewMessage = $root.UpdateNewMessage.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.updateEditedMessage = $root.UpdateEditedMessage.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.updateChangedReadPointer = $root.UpdateChangedReadPointer.decode(reader, reader.uint32());
                    break;
                }
            case 14: {
                    message.updateNewChatPreview = $root.UpdateNewChatPreview.decode(reader, reader.uint32());
                    break;
                }
            case 15: {
                    message.updateChangedAccess = $root.UpdateChangedAccess.decode(reader, reader.uint32());
                    break;
                }
            case 16: {
                    message.updateChannelCounterReset = $root.UpdateChannelCounterReset.decode(reader, reader.uint32());
                    break;
                }
            case 17: {
                    message.updateChatChangedRestrictions = $root.UpdateChatChangedRestrictions.decode(reader, reader.uint32());
                    break;
                }
            case 18: {
                    message.updateOperatorAssignWidget = $root.UpdateOperatorAssignWidget.decode(reader, reader.uint32());
                    break;
                }
            case 19: {
                    message.updateNewMessageSuggestions = $root.UpdateNewMessageSuggestion.decode(reader, reader.uint32());
                    break;
                }
            case 20: {
                    message.updateChannelState = $root.UpdateChannelState.decode(reader, reader.uint32());
                    break;
                }
            case 100: {
                    message.updateRedacted = $root.UpdateRedacted.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Update message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Update
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Update} Update
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Update.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Update message.
     * @function verify
     * @memberof Update
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Update.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        if (message.version != null && message.hasOwnProperty("version"))
            if (!$util.isInteger(message.version) && !(message.version && $util.isInteger(message.version.low) && $util.isInteger(message.version.high)))
                return "version: integer|Long expected";
        if (message.entities != null && message.hasOwnProperty("entities")) {
            let error = $root.UpdateEntities.verify(message.entities);
            if (error)
                return "entities." + error;
        }
        if (message.updateNewMessage != null && message.hasOwnProperty("updateNewMessage")) {
            properties.update = 1;
            {
                let error = $root.UpdateNewMessage.verify(message.updateNewMessage);
                if (error)
                    return "updateNewMessage." + error;
            }
        }
        if (message.updateEditedMessage != null && message.hasOwnProperty("updateEditedMessage")) {
            if (properties.update === 1)
                return "update: multiple values";
            properties.update = 1;
            {
                let error = $root.UpdateEditedMessage.verify(message.updateEditedMessage);
                if (error)
                    return "updateEditedMessage." + error;
            }
        }
        if (message.updateChangedReadPointer != null && message.hasOwnProperty("updateChangedReadPointer")) {
            if (properties.update === 1)
                return "update: multiple values";
            properties.update = 1;
            {
                let error = $root.UpdateChangedReadPointer.verify(message.updateChangedReadPointer);
                if (error)
                    return "updateChangedReadPointer." + error;
            }
        }
        if (message.updateNewChatPreview != null && message.hasOwnProperty("updateNewChatPreview")) {
            if (properties.update === 1)
                return "update: multiple values";
            properties.update = 1;
            {
                let error = $root.UpdateNewChatPreview.verify(message.updateNewChatPreview);
                if (error)
                    return "updateNewChatPreview." + error;
            }
        }
        if (message.updateChangedAccess != null && message.hasOwnProperty("updateChangedAccess")) {
            if (properties.update === 1)
                return "update: multiple values";
            properties.update = 1;
            {
                let error = $root.UpdateChangedAccess.verify(message.updateChangedAccess);
                if (error)
                    return "updateChangedAccess." + error;
            }
        }
        if (message.updateChannelCounterReset != null && message.hasOwnProperty("updateChannelCounterReset")) {
            if (properties.update === 1)
                return "update: multiple values";
            properties.update = 1;
            {
                let error = $root.UpdateChannelCounterReset.verify(message.updateChannelCounterReset);
                if (error)
                    return "updateChannelCounterReset." + error;
            }
        }
        if (message.updateChatChangedRestrictions != null && message.hasOwnProperty("updateChatChangedRestrictions")) {
            if (properties.update === 1)
                return "update: multiple values";
            properties.update = 1;
            {
                let error = $root.UpdateChatChangedRestrictions.verify(message.updateChatChangedRestrictions);
                if (error)
                    return "updateChatChangedRestrictions." + error;
            }
        }
        if (message.updateOperatorAssignWidget != null && message.hasOwnProperty("updateOperatorAssignWidget")) {
            if (properties.update === 1)
                return "update: multiple values";
            properties.update = 1;
            {
                let error = $root.UpdateOperatorAssignWidget.verify(message.updateOperatorAssignWidget);
                if (error)
                    return "updateOperatorAssignWidget." + error;
            }
        }
        if (message.updateNewMessageSuggestions != null && message.hasOwnProperty("updateNewMessageSuggestions")) {
            if (properties.update === 1)
                return "update: multiple values";
            properties.update = 1;
            {
                let error = $root.UpdateNewMessageSuggestion.verify(message.updateNewMessageSuggestions);
                if (error)
                    return "updateNewMessageSuggestions." + error;
            }
        }
        if (message.updateChannelState != null && message.hasOwnProperty("updateChannelState")) {
            if (properties.update === 1)
                return "update: multiple values";
            properties.update = 1;
            {
                let error = $root.UpdateChannelState.verify(message.updateChannelState);
                if (error)
                    return "updateChannelState." + error;
            }
        }
        if (message.updateRedacted != null && message.hasOwnProperty("updateRedacted")) {
            if (properties.update === 1)
                return "update: multiple values";
            properties.update = 1;
            {
                let error = $root.UpdateRedacted.verify(message.updateRedacted);
                if (error)
                    return "updateRedacted." + error;
            }
        }
        return null;
    };

    /**
     * Creates an Update message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Update
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Update} Update
     */
    Update.fromObject = function fromObject(object) {
        if (object instanceof $root.Update)
            return object;
        let message = new $root.Update();
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        if (object.version != null)
            if ($util.Long)
                (message.version = $util.Long.fromValue(object.version)).unsigned = true;
            else if (typeof object.version === "string")
                message.version = parseInt(object.version, 10);
            else if (typeof object.version === "number")
                message.version = object.version;
            else if (typeof object.version === "object")
                message.version = new $util.LongBits(object.version.low >>> 0, object.version.high >>> 0).toNumber(true);
        if (object.entities != null) {
            if (typeof object.entities !== "object")
                throw TypeError(".Update.entities: object expected");
            message.entities = $root.UpdateEntities.fromObject(object.entities);
        }
        if (object.updateNewMessage != null) {
            if (typeof object.updateNewMessage !== "object")
                throw TypeError(".Update.updateNewMessage: object expected");
            message.updateNewMessage = $root.UpdateNewMessage.fromObject(object.updateNewMessage);
        }
        if (object.updateEditedMessage != null) {
            if (typeof object.updateEditedMessage !== "object")
                throw TypeError(".Update.updateEditedMessage: object expected");
            message.updateEditedMessage = $root.UpdateEditedMessage.fromObject(object.updateEditedMessage);
        }
        if (object.updateChangedReadPointer != null) {
            if (typeof object.updateChangedReadPointer !== "object")
                throw TypeError(".Update.updateChangedReadPointer: object expected");
            message.updateChangedReadPointer = $root.UpdateChangedReadPointer.fromObject(object.updateChangedReadPointer);
        }
        if (object.updateNewChatPreview != null) {
            if (typeof object.updateNewChatPreview !== "object")
                throw TypeError(".Update.updateNewChatPreview: object expected");
            message.updateNewChatPreview = $root.UpdateNewChatPreview.fromObject(object.updateNewChatPreview);
        }
        if (object.updateChangedAccess != null) {
            if (typeof object.updateChangedAccess !== "object")
                throw TypeError(".Update.updateChangedAccess: object expected");
            message.updateChangedAccess = $root.UpdateChangedAccess.fromObject(object.updateChangedAccess);
        }
        if (object.updateChannelCounterReset != null) {
            if (typeof object.updateChannelCounterReset !== "object")
                throw TypeError(".Update.updateChannelCounterReset: object expected");
            message.updateChannelCounterReset = $root.UpdateChannelCounterReset.fromObject(object.updateChannelCounterReset);
        }
        if (object.updateChatChangedRestrictions != null) {
            if (typeof object.updateChatChangedRestrictions !== "object")
                throw TypeError(".Update.updateChatChangedRestrictions: object expected");
            message.updateChatChangedRestrictions = $root.UpdateChatChangedRestrictions.fromObject(object.updateChatChangedRestrictions);
        }
        if (object.updateOperatorAssignWidget != null) {
            if (typeof object.updateOperatorAssignWidget !== "object")
                throw TypeError(".Update.updateOperatorAssignWidget: object expected");
            message.updateOperatorAssignWidget = $root.UpdateOperatorAssignWidget.fromObject(object.updateOperatorAssignWidget);
        }
        if (object.updateNewMessageSuggestions != null) {
            if (typeof object.updateNewMessageSuggestions !== "object")
                throw TypeError(".Update.updateNewMessageSuggestions: object expected");
            message.updateNewMessageSuggestions = $root.UpdateNewMessageSuggestion.fromObject(object.updateNewMessageSuggestions);
        }
        if (object.updateChannelState != null) {
            if (typeof object.updateChannelState !== "object")
                throw TypeError(".Update.updateChannelState: object expected");
            message.updateChannelState = $root.UpdateChannelState.fromObject(object.updateChannelState);
        }
        if (object.updateRedacted != null) {
            if (typeof object.updateRedacted !== "object")
                throw TypeError(".Update.updateRedacted: object expected");
            message.updateRedacted = $root.UpdateRedacted.fromObject(object.updateRedacted);
        }
        return message;
    };

    /**
     * Creates a plain object from an Update message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Update
     * @static
     * @param {Update} message Update
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Update.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.version = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.version = options.longs === String ? "0" : 0;
            object.entities = null;
        }
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        if (message.version != null && message.hasOwnProperty("version"))
            if (typeof message.version === "number")
                object.version = options.longs === String ? String(message.version) : message.version;
            else
                object.version = options.longs === String ? $util.Long.prototype.toString.call(message.version) : options.longs === Number ? new $util.LongBits(message.version.low >>> 0, message.version.high >>> 0).toNumber(true) : message.version;
        if (message.entities != null && message.hasOwnProperty("entities"))
            object.entities = $root.UpdateEntities.toObject(message.entities, options);
        if (message.updateNewMessage != null && message.hasOwnProperty("updateNewMessage")) {
            object.updateNewMessage = $root.UpdateNewMessage.toObject(message.updateNewMessage, options);
            if (options.oneofs)
                object.update = "updateNewMessage";
        }
        if (message.updateEditedMessage != null && message.hasOwnProperty("updateEditedMessage")) {
            object.updateEditedMessage = $root.UpdateEditedMessage.toObject(message.updateEditedMessage, options);
            if (options.oneofs)
                object.update = "updateEditedMessage";
        }
        if (message.updateChangedReadPointer != null && message.hasOwnProperty("updateChangedReadPointer")) {
            object.updateChangedReadPointer = $root.UpdateChangedReadPointer.toObject(message.updateChangedReadPointer, options);
            if (options.oneofs)
                object.update = "updateChangedReadPointer";
        }
        if (message.updateNewChatPreview != null && message.hasOwnProperty("updateNewChatPreview")) {
            object.updateNewChatPreview = $root.UpdateNewChatPreview.toObject(message.updateNewChatPreview, options);
            if (options.oneofs)
                object.update = "updateNewChatPreview";
        }
        if (message.updateChangedAccess != null && message.hasOwnProperty("updateChangedAccess")) {
            object.updateChangedAccess = $root.UpdateChangedAccess.toObject(message.updateChangedAccess, options);
            if (options.oneofs)
                object.update = "updateChangedAccess";
        }
        if (message.updateChannelCounterReset != null && message.hasOwnProperty("updateChannelCounterReset")) {
            object.updateChannelCounterReset = $root.UpdateChannelCounterReset.toObject(message.updateChannelCounterReset, options);
            if (options.oneofs)
                object.update = "updateChannelCounterReset";
        }
        if (message.updateChatChangedRestrictions != null && message.hasOwnProperty("updateChatChangedRestrictions")) {
            object.updateChatChangedRestrictions = $root.UpdateChatChangedRestrictions.toObject(message.updateChatChangedRestrictions, options);
            if (options.oneofs)
                object.update = "updateChatChangedRestrictions";
        }
        if (message.updateOperatorAssignWidget != null && message.hasOwnProperty("updateOperatorAssignWidget")) {
            object.updateOperatorAssignWidget = $root.UpdateOperatorAssignWidget.toObject(message.updateOperatorAssignWidget, options);
            if (options.oneofs)
                object.update = "updateOperatorAssignWidget";
        }
        if (message.updateNewMessageSuggestions != null && message.hasOwnProperty("updateNewMessageSuggestions")) {
            object.updateNewMessageSuggestions = $root.UpdateNewMessageSuggestion.toObject(message.updateNewMessageSuggestions, options);
            if (options.oneofs)
                object.update = "updateNewMessageSuggestions";
        }
        if (message.updateChannelState != null && message.hasOwnProperty("updateChannelState")) {
            object.updateChannelState = $root.UpdateChannelState.toObject(message.updateChannelState, options);
            if (options.oneofs)
                object.update = "updateChannelState";
        }
        if (message.updateRedacted != null && message.hasOwnProperty("updateRedacted")) {
            object.updateRedacted = $root.UpdateRedacted.toObject(message.updateRedacted, options);
            if (options.oneofs)
                object.update = "updateRedacted";
        }
        return object;
    };

    /**
     * Converts this Update to JSON.
     * @function toJSON
     * @memberof Update
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Update.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Update
     * @function getTypeUrl
     * @memberof Update
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Update.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Update";
    };

    return Update;
})();

export const UpdateEntities = $root.UpdateEntities = (() => {

    /**
     * Properties of an UpdateEntities.
     * @exports IUpdateEntities
     * @interface IUpdateEntities
     * @property {Array.<IMCMessage>|null} [messages] UpdateEntities messages
     * @property {Array.<IMCChat>|null} [chats] UpdateEntities chats
     * @property {Array.<IMCPeer>|null} [peers] UpdateEntities peers
     */

    /**
     * Constructs a new UpdateEntities.
     * @exports UpdateEntities
     * @classdesc Represents an UpdateEntities.
     * @implements IUpdateEntities
     * @constructor
     * @param {IUpdateEntities=} [properties] Properties to set
     */
    function UpdateEntities(properties) {
        this.messages = [];
        this.chats = [];
        this.peers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateEntities messages.
     * @member {Array.<IMCMessage>} messages
     * @memberof UpdateEntities
     * @instance
     */
    UpdateEntities.prototype.messages = $util.emptyArray;

    /**
     * UpdateEntities chats.
     * @member {Array.<IMCChat>} chats
     * @memberof UpdateEntities
     * @instance
     */
    UpdateEntities.prototype.chats = $util.emptyArray;

    /**
     * UpdateEntities peers.
     * @member {Array.<IMCPeer>} peers
     * @memberof UpdateEntities
     * @instance
     */
    UpdateEntities.prototype.peers = $util.emptyArray;

    /**
     * Creates a new UpdateEntities instance using the specified properties.
     * @function create
     * @memberof UpdateEntities
     * @static
     * @param {IUpdateEntities=} [properties] Properties to set
     * @returns {UpdateEntities} UpdateEntities instance
     */
    UpdateEntities.create = function create(properties) {
        return new UpdateEntities(properties);
    };

    /**
     * Encodes the specified UpdateEntities message. Does not implicitly {@link UpdateEntities.verify|verify} messages.
     * @function encode
     * @memberof UpdateEntities
     * @static
     * @param {IUpdateEntities} message UpdateEntities message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateEntities.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.messages != null && message.messages.length)
            for (let i = 0; i < message.messages.length; ++i)
                $root.MCMessage.encode(message.messages[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.chats != null && message.chats.length)
            for (let i = 0; i < message.chats.length; ++i)
                $root.MCChat.encode(message.chats[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.peers != null && message.peers.length)
            for (let i = 0; i < message.peers.length; ++i)
                $root.MCPeer.encode(message.peers[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateEntities message, length delimited. Does not implicitly {@link UpdateEntities.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateEntities
     * @static
     * @param {IUpdateEntities} message UpdateEntities message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateEntities.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateEntities message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateEntities
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateEntities} UpdateEntities
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateEntities.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateEntities();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 10: {
                    if (!(message.messages && message.messages.length))
                        message.messages = [];
                    message.messages.push($root.MCMessage.decode(reader, reader.uint32()));
                    break;
                }
            case 11: {
                    if (!(message.chats && message.chats.length))
                        message.chats = [];
                    message.chats.push($root.MCChat.decode(reader, reader.uint32()));
                    break;
                }
            case 12: {
                    if (!(message.peers && message.peers.length))
                        message.peers = [];
                    message.peers.push($root.MCPeer.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateEntities message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateEntities
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateEntities} UpdateEntities
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateEntities.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateEntities message.
     * @function verify
     * @memberof UpdateEntities
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateEntities.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.messages != null && message.hasOwnProperty("messages")) {
            if (!Array.isArray(message.messages))
                return "messages: array expected";
            for (let i = 0; i < message.messages.length; ++i) {
                let error = $root.MCMessage.verify(message.messages[i]);
                if (error)
                    return "messages." + error;
            }
        }
        if (message.chats != null && message.hasOwnProperty("chats")) {
            if (!Array.isArray(message.chats))
                return "chats: array expected";
            for (let i = 0; i < message.chats.length; ++i) {
                let error = $root.MCChat.verify(message.chats[i]);
                if (error)
                    return "chats." + error;
            }
        }
        if (message.peers != null && message.hasOwnProperty("peers")) {
            if (!Array.isArray(message.peers))
                return "peers: array expected";
            for (let i = 0; i < message.peers.length; ++i) {
                let error = $root.MCPeer.verify(message.peers[i]);
                if (error)
                    return "peers." + error;
            }
        }
        return null;
    };

    /**
     * Creates an UpdateEntities message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateEntities
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateEntities} UpdateEntities
     */
    UpdateEntities.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateEntities)
            return object;
        let message = new $root.UpdateEntities();
        if (object.messages) {
            if (!Array.isArray(object.messages))
                throw TypeError(".UpdateEntities.messages: array expected");
            message.messages = [];
            for (let i = 0; i < object.messages.length; ++i) {
                if (typeof object.messages[i] !== "object")
                    throw TypeError(".UpdateEntities.messages: object expected");
                message.messages[i] = $root.MCMessage.fromObject(object.messages[i]);
            }
        }
        if (object.chats) {
            if (!Array.isArray(object.chats))
                throw TypeError(".UpdateEntities.chats: array expected");
            message.chats = [];
            for (let i = 0; i < object.chats.length; ++i) {
                if (typeof object.chats[i] !== "object")
                    throw TypeError(".UpdateEntities.chats: object expected");
                message.chats[i] = $root.MCChat.fromObject(object.chats[i]);
            }
        }
        if (object.peers) {
            if (!Array.isArray(object.peers))
                throw TypeError(".UpdateEntities.peers: array expected");
            message.peers = [];
            for (let i = 0; i < object.peers.length; ++i) {
                if (typeof object.peers[i] !== "object")
                    throw TypeError(".UpdateEntities.peers: object expected");
                message.peers[i] = $root.MCPeer.fromObject(object.peers[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateEntities message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateEntities
     * @static
     * @param {UpdateEntities} message UpdateEntities
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateEntities.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.messages = [];
            object.chats = [];
            object.peers = [];
        }
        if (message.messages && message.messages.length) {
            object.messages = [];
            for (let j = 0; j < message.messages.length; ++j)
                object.messages[j] = $root.MCMessage.toObject(message.messages[j], options);
        }
        if (message.chats && message.chats.length) {
            object.chats = [];
            for (let j = 0; j < message.chats.length; ++j)
                object.chats[j] = $root.MCChat.toObject(message.chats[j], options);
        }
        if (message.peers && message.peers.length) {
            object.peers = [];
            for (let j = 0; j < message.peers.length; ++j)
                object.peers[j] = $root.MCPeer.toObject(message.peers[j], options);
        }
        return object;
    };

    /**
     * Converts this UpdateEntities to JSON.
     * @function toJSON
     * @memberof UpdateEntities
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateEntities.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateEntities
     * @function getTypeUrl
     * @memberof UpdateEntities
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateEntities.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateEntities";
    };

    return UpdateEntities;
})();

export const UpdateNewMessage = $root.UpdateNewMessage = (() => {

    /**
     * Properties of an UpdateNewMessage.
     * @exports IUpdateNewMessage
     * @interface IUpdateNewMessage
     * @property {IMCMessage|null} [message] UpdateNewMessage message
     * @property {Long|null} [randomID] UpdateNewMessage randomID
     */

    /**
     * Constructs a new UpdateNewMessage.
     * @exports UpdateNewMessage
     * @classdesc Represents an UpdateNewMessage.
     * @implements IUpdateNewMessage
     * @constructor
     * @param {IUpdateNewMessage=} [properties] Properties to set
     */
    function UpdateNewMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateNewMessage message.
     * @member {IMCMessage|null|undefined} message
     * @memberof UpdateNewMessage
     * @instance
     */
    UpdateNewMessage.prototype.message = null;

    /**
     * UpdateNewMessage randomID.
     * @member {Long} randomID
     * @memberof UpdateNewMessage
     * @instance
     */
    UpdateNewMessage.prototype.randomID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new UpdateNewMessage instance using the specified properties.
     * @function create
     * @memberof UpdateNewMessage
     * @static
     * @param {IUpdateNewMessage=} [properties] Properties to set
     * @returns {UpdateNewMessage} UpdateNewMessage instance
     */
    UpdateNewMessage.create = function create(properties) {
        return new UpdateNewMessage(properties);
    };

    /**
     * Encodes the specified UpdateNewMessage message. Does not implicitly {@link UpdateNewMessage.verify|verify} messages.
     * @function encode
     * @memberof UpdateNewMessage
     * @static
     * @param {IUpdateNewMessage} message UpdateNewMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateNewMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.message != null && Object.hasOwnProperty.call(message, "message"))
            $root.MCMessage.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.randomID != null && Object.hasOwnProperty.call(message, "randomID"))
            writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.randomID);
        return writer;
    };

    /**
     * Encodes the specified UpdateNewMessage message, length delimited. Does not implicitly {@link UpdateNewMessage.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateNewMessage
     * @static
     * @param {IUpdateNewMessage} message UpdateNewMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateNewMessage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateNewMessage message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateNewMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateNewMessage} UpdateNewMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateNewMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateNewMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.message = $root.MCMessage.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.randomID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateNewMessage message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateNewMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateNewMessage} UpdateNewMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateNewMessage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateNewMessage message.
     * @function verify
     * @memberof UpdateNewMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateNewMessage.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.message != null && message.hasOwnProperty("message")) {
            let error = $root.MCMessage.verify(message.message);
            if (error)
                return "message." + error;
        }
        if (message.randomID != null && message.hasOwnProperty("randomID"))
            if (!$util.isInteger(message.randomID) && !(message.randomID && $util.isInteger(message.randomID.low) && $util.isInteger(message.randomID.high)))
                return "randomID: integer|Long expected";
        return null;
    };

    /**
     * Creates an UpdateNewMessage message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateNewMessage
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateNewMessage} UpdateNewMessage
     */
    UpdateNewMessage.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateNewMessage)
            return object;
        let message = new $root.UpdateNewMessage();
        if (object.message != null) {
            if (typeof object.message !== "object")
                throw TypeError(".UpdateNewMessage.message: object expected");
            message.message = $root.MCMessage.fromObject(object.message);
        }
        if (object.randomID != null)
            if ($util.Long)
                (message.randomID = $util.Long.fromValue(object.randomID)).unsigned = true;
            else if (typeof object.randomID === "string")
                message.randomID = parseInt(object.randomID, 10);
            else if (typeof object.randomID === "number")
                message.randomID = object.randomID;
            else if (typeof object.randomID === "object")
                message.randomID = new $util.LongBits(object.randomID.low >>> 0, object.randomID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from an UpdateNewMessage message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateNewMessage
     * @static
     * @param {UpdateNewMessage} message UpdateNewMessage
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateNewMessage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.message = null;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.randomID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.randomID = options.longs === String ? "0" : 0;
        }
        if (message.message != null && message.hasOwnProperty("message"))
            object.message = $root.MCMessage.toObject(message.message, options);
        if (message.randomID != null && message.hasOwnProperty("randomID"))
            if (typeof message.randomID === "number")
                object.randomID = options.longs === String ? String(message.randomID) : message.randomID;
            else
                object.randomID = options.longs === String ? $util.Long.prototype.toString.call(message.randomID) : options.longs === Number ? new $util.LongBits(message.randomID.low >>> 0, message.randomID.high >>> 0).toNumber(true) : message.randomID;
        return object;
    };

    /**
     * Converts this UpdateNewMessage to JSON.
     * @function toJSON
     * @memberof UpdateNewMessage
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateNewMessage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateNewMessage
     * @function getTypeUrl
     * @memberof UpdateNewMessage
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateNewMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateNewMessage";
    };

    return UpdateNewMessage;
})();

export const UpdateEditedMessage = $root.UpdateEditedMessage = (() => {

    /**
     * Properties of an UpdateEditedMessage.
     * @exports IUpdateEditedMessage
     * @interface IUpdateEditedMessage
     * @property {IMCMessage|null} [message] UpdateEditedMessage message
     */

    /**
     * Constructs a new UpdateEditedMessage.
     * @exports UpdateEditedMessage
     * @classdesc Represents an UpdateEditedMessage.
     * @implements IUpdateEditedMessage
     * @constructor
     * @param {IUpdateEditedMessage=} [properties] Properties to set
     */
    function UpdateEditedMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateEditedMessage message.
     * @member {IMCMessage|null|undefined} message
     * @memberof UpdateEditedMessage
     * @instance
     */
    UpdateEditedMessage.prototype.message = null;

    /**
     * Creates a new UpdateEditedMessage instance using the specified properties.
     * @function create
     * @memberof UpdateEditedMessage
     * @static
     * @param {IUpdateEditedMessage=} [properties] Properties to set
     * @returns {UpdateEditedMessage} UpdateEditedMessage instance
     */
    UpdateEditedMessage.create = function create(properties) {
        return new UpdateEditedMessage(properties);
    };

    /**
     * Encodes the specified UpdateEditedMessage message. Does not implicitly {@link UpdateEditedMessage.verify|verify} messages.
     * @function encode
     * @memberof UpdateEditedMessage
     * @static
     * @param {IUpdateEditedMessage} message UpdateEditedMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateEditedMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.message != null && Object.hasOwnProperty.call(message, "message"))
            $root.MCMessage.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateEditedMessage message, length delimited. Does not implicitly {@link UpdateEditedMessage.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateEditedMessage
     * @static
     * @param {IUpdateEditedMessage} message UpdateEditedMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateEditedMessage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateEditedMessage message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateEditedMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateEditedMessage} UpdateEditedMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateEditedMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateEditedMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.message = $root.MCMessage.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateEditedMessage message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateEditedMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateEditedMessage} UpdateEditedMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateEditedMessage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateEditedMessage message.
     * @function verify
     * @memberof UpdateEditedMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateEditedMessage.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.message != null && message.hasOwnProperty("message")) {
            let error = $root.MCMessage.verify(message.message);
            if (error)
                return "message." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateEditedMessage message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateEditedMessage
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateEditedMessage} UpdateEditedMessage
     */
    UpdateEditedMessage.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateEditedMessage)
            return object;
        let message = new $root.UpdateEditedMessage();
        if (object.message != null) {
            if (typeof object.message !== "object")
                throw TypeError(".UpdateEditedMessage.message: object expected");
            message.message = $root.MCMessage.fromObject(object.message);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateEditedMessage message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateEditedMessage
     * @static
     * @param {UpdateEditedMessage} message UpdateEditedMessage
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateEditedMessage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.message = null;
        if (message.message != null && message.hasOwnProperty("message"))
            object.message = $root.MCMessage.toObject(message.message, options);
        return object;
    };

    /**
     * Converts this UpdateEditedMessage to JSON.
     * @function toJSON
     * @memberof UpdateEditedMessage
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateEditedMessage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateEditedMessage
     * @function getTypeUrl
     * @memberof UpdateEditedMessage
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateEditedMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateEditedMessage";
    };

    return UpdateEditedMessage;
})();

export const UpdateNewChatPreview = $root.UpdateNewChatPreview = (() => {

    /**
     * Properties of an UpdateNewChatPreview.
     * @exports IUpdateNewChatPreview
     * @interface IUpdateNewChatPreview
     * @property {IMCChatPreview|null} [chat] UpdateNewChatPreview chat
     */

    /**
     * Constructs a new UpdateNewChatPreview.
     * @exports UpdateNewChatPreview
     * @classdesc Represents an UpdateNewChatPreview.
     * @implements IUpdateNewChatPreview
     * @constructor
     * @param {IUpdateNewChatPreview=} [properties] Properties to set
     */
    function UpdateNewChatPreview(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateNewChatPreview chat.
     * @member {IMCChatPreview|null|undefined} chat
     * @memberof UpdateNewChatPreview
     * @instance
     */
    UpdateNewChatPreview.prototype.chat = null;

    /**
     * Creates a new UpdateNewChatPreview instance using the specified properties.
     * @function create
     * @memberof UpdateNewChatPreview
     * @static
     * @param {IUpdateNewChatPreview=} [properties] Properties to set
     * @returns {UpdateNewChatPreview} UpdateNewChatPreview instance
     */
    UpdateNewChatPreview.create = function create(properties) {
        return new UpdateNewChatPreview(properties);
    };

    /**
     * Encodes the specified UpdateNewChatPreview message. Does not implicitly {@link UpdateNewChatPreview.verify|verify} messages.
     * @function encode
     * @memberof UpdateNewChatPreview
     * @static
     * @param {IUpdateNewChatPreview} message UpdateNewChatPreview message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateNewChatPreview.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.chat != null && Object.hasOwnProperty.call(message, "chat"))
            $root.MCChatPreview.encode(message.chat, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateNewChatPreview message, length delimited. Does not implicitly {@link UpdateNewChatPreview.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateNewChatPreview
     * @static
     * @param {IUpdateNewChatPreview} message UpdateNewChatPreview message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateNewChatPreview.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateNewChatPreview message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateNewChatPreview
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateNewChatPreview} UpdateNewChatPreview
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateNewChatPreview.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateNewChatPreview();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.chat = $root.MCChatPreview.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateNewChatPreview message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateNewChatPreview
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateNewChatPreview} UpdateNewChatPreview
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateNewChatPreview.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateNewChatPreview message.
     * @function verify
     * @memberof UpdateNewChatPreview
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateNewChatPreview.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.chat != null && message.hasOwnProperty("chat")) {
            let error = $root.MCChatPreview.verify(message.chat);
            if (error)
                return "chat." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateNewChatPreview message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateNewChatPreview
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateNewChatPreview} UpdateNewChatPreview
     */
    UpdateNewChatPreview.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateNewChatPreview)
            return object;
        let message = new $root.UpdateNewChatPreview();
        if (object.chat != null) {
            if (typeof object.chat !== "object")
                throw TypeError(".UpdateNewChatPreview.chat: object expected");
            message.chat = $root.MCChatPreview.fromObject(object.chat);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateNewChatPreview message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateNewChatPreview
     * @static
     * @param {UpdateNewChatPreview} message UpdateNewChatPreview
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateNewChatPreview.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.chat = null;
        if (message.chat != null && message.hasOwnProperty("chat"))
            object.chat = $root.MCChatPreview.toObject(message.chat, options);
        return object;
    };

    /**
     * Converts this UpdateNewChatPreview to JSON.
     * @function toJSON
     * @memberof UpdateNewChatPreview
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateNewChatPreview.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateNewChatPreview
     * @function getTypeUrl
     * @memberof UpdateNewChatPreview
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateNewChatPreview.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateNewChatPreview";
    };

    return UpdateNewChatPreview;
})();

export const UpdateChangedReadPointer = $root.UpdateChangedReadPointer = (() => {

    /**
     * Properties of an UpdateChangedReadPointer.
     * @exports IUpdateChangedReadPointer
     * @interface IUpdateChangedReadPointer
     * @property {Long|null} [chatID] UpdateChangedReadPointer chatID
     * @property {Long|null} [previousInReadPointer] UpdateChangedReadPointer previousInReadPointer
     * @property {Long|null} [inReadPointer] UpdateChangedReadPointer inReadPointer
     * @property {Long|null} [outReadPointer] UpdateChangedReadPointer outReadPointer
     * @property {Long|null} [messagesExcludedFromUnreadRange] UpdateChangedReadPointer messagesExcludedFromUnreadRange
     * @property {Long|null} [totalChannelUnread] UpdateChangedReadPointer totalChannelUnread
     * @property {Long|null} [operatorID] UpdateChangedReadPointer operatorID
     */

    /**
     * Constructs a new UpdateChangedReadPointer.
     * @exports UpdateChangedReadPointer
     * @classdesc Represents an UpdateChangedReadPointer.
     * @implements IUpdateChangedReadPointer
     * @constructor
     * @param {IUpdateChangedReadPointer=} [properties] Properties to set
     */
    function UpdateChangedReadPointer(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateChangedReadPointer chatID.
     * @member {Long} chatID
     * @memberof UpdateChangedReadPointer
     * @instance
     */
    UpdateChangedReadPointer.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * UpdateChangedReadPointer previousInReadPointer.
     * @member {Long|null|undefined} previousInReadPointer
     * @memberof UpdateChangedReadPointer
     * @instance
     */
    UpdateChangedReadPointer.prototype.previousInReadPointer = null;

    /**
     * UpdateChangedReadPointer inReadPointer.
     * @member {Long|null|undefined} inReadPointer
     * @memberof UpdateChangedReadPointer
     * @instance
     */
    UpdateChangedReadPointer.prototype.inReadPointer = null;

    /**
     * UpdateChangedReadPointer outReadPointer.
     * @member {Long|null|undefined} outReadPointer
     * @memberof UpdateChangedReadPointer
     * @instance
     */
    UpdateChangedReadPointer.prototype.outReadPointer = null;

    /**
     * UpdateChangedReadPointer messagesExcludedFromUnreadRange.
     * @member {Long} messagesExcludedFromUnreadRange
     * @memberof UpdateChangedReadPointer
     * @instance
     */
    UpdateChangedReadPointer.prototype.messagesExcludedFromUnreadRange = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * UpdateChangedReadPointer totalChannelUnread.
     * @member {Long} totalChannelUnread
     * @memberof UpdateChangedReadPointer
     * @instance
     */
    UpdateChangedReadPointer.prototype.totalChannelUnread = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * UpdateChangedReadPointer operatorID.
     * @member {Long} operatorID
     * @memberof UpdateChangedReadPointer
     * @instance
     */
    UpdateChangedReadPointer.prototype.operatorID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * UpdateChangedReadPointer pointer.
     * @member {"previousInReadPointer"|"inReadPointer"|"outReadPointer"|undefined} pointer
     * @memberof UpdateChangedReadPointer
     * @instance
     */
    Object.defineProperty(UpdateChangedReadPointer.prototype, "pointer", {
        get: $util.oneOfGetter($oneOfFields = ["previousInReadPointer", "inReadPointer", "outReadPointer"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new UpdateChangedReadPointer instance using the specified properties.
     * @function create
     * @memberof UpdateChangedReadPointer
     * @static
     * @param {IUpdateChangedReadPointer=} [properties] Properties to set
     * @returns {UpdateChangedReadPointer} UpdateChangedReadPointer instance
     */
    UpdateChangedReadPointer.create = function create(properties) {
        return new UpdateChangedReadPointer(properties);
    };

    /**
     * Encodes the specified UpdateChangedReadPointer message. Does not implicitly {@link UpdateChangedReadPointer.verify|verify} messages.
     * @function encode
     * @memberof UpdateChangedReadPointer
     * @static
     * @param {IUpdateChangedReadPointer} message UpdateChangedReadPointer message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateChangedReadPointer.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatID);
        if (message.inReadPointer != null && Object.hasOwnProperty.call(message, "inReadPointer"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.inReadPointer);
        if (message.outReadPointer != null && Object.hasOwnProperty.call(message, "outReadPointer"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.outReadPointer);
        if (message.operatorID != null && Object.hasOwnProperty.call(message, "operatorID"))
            writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.operatorID);
        if (message.totalChannelUnread != null && Object.hasOwnProperty.call(message, "totalChannelUnread"))
            writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.totalChannelUnread);
        if (message.messagesExcludedFromUnreadRange != null && Object.hasOwnProperty.call(message, "messagesExcludedFromUnreadRange"))
            writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.messagesExcludedFromUnreadRange);
        if (message.previousInReadPointer != null && Object.hasOwnProperty.call(message, "previousInReadPointer"))
            writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.previousInReadPointer);
        return writer;
    };

    /**
     * Encodes the specified UpdateChangedReadPointer message, length delimited. Does not implicitly {@link UpdateChangedReadPointer.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateChangedReadPointer
     * @static
     * @param {IUpdateChangedReadPointer} message UpdateChangedReadPointer message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateChangedReadPointer.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateChangedReadPointer message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateChangedReadPointer
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateChangedReadPointer} UpdateChangedReadPointer
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateChangedReadPointer.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateChangedReadPointer();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 7: {
                    message.previousInReadPointer = reader.uint64();
                    break;
                }
            case 2: {
                    message.inReadPointer = reader.uint64();
                    break;
                }
            case 3: {
                    message.outReadPointer = reader.uint64();
                    break;
                }
            case 6: {
                    message.messagesExcludedFromUnreadRange = reader.uint64();
                    break;
                }
            case 5: {
                    message.totalChannelUnread = reader.uint64();
                    break;
                }
            case 4: {
                    message.operatorID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateChangedReadPointer message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateChangedReadPointer
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateChangedReadPointer} UpdateChangedReadPointer
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateChangedReadPointer.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateChangedReadPointer message.
     * @function verify
     * @memberof UpdateChangedReadPointer
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateChangedReadPointer.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.previousInReadPointer != null && message.hasOwnProperty("previousInReadPointer")) {
            properties.pointer = 1;
            if (!$util.isInteger(message.previousInReadPointer) && !(message.previousInReadPointer && $util.isInteger(message.previousInReadPointer.low) && $util.isInteger(message.previousInReadPointer.high)))
                return "previousInReadPointer: integer|Long expected";
        }
        if (message.inReadPointer != null && message.hasOwnProperty("inReadPointer")) {
            if (properties.pointer === 1)
                return "pointer: multiple values";
            properties.pointer = 1;
            if (!$util.isInteger(message.inReadPointer) && !(message.inReadPointer && $util.isInteger(message.inReadPointer.low) && $util.isInteger(message.inReadPointer.high)))
                return "inReadPointer: integer|Long expected";
        }
        if (message.outReadPointer != null && message.hasOwnProperty("outReadPointer")) {
            if (properties.pointer === 1)
                return "pointer: multiple values";
            properties.pointer = 1;
            if (!$util.isInteger(message.outReadPointer) && !(message.outReadPointer && $util.isInteger(message.outReadPointer.low) && $util.isInteger(message.outReadPointer.high)))
                return "outReadPointer: integer|Long expected";
        }
        if (message.messagesExcludedFromUnreadRange != null && message.hasOwnProperty("messagesExcludedFromUnreadRange"))
            if (!$util.isInteger(message.messagesExcludedFromUnreadRange) && !(message.messagesExcludedFromUnreadRange && $util.isInteger(message.messagesExcludedFromUnreadRange.low) && $util.isInteger(message.messagesExcludedFromUnreadRange.high)))
                return "messagesExcludedFromUnreadRange: integer|Long expected";
        if (message.totalChannelUnread != null && message.hasOwnProperty("totalChannelUnread"))
            if (!$util.isInteger(message.totalChannelUnread) && !(message.totalChannelUnread && $util.isInteger(message.totalChannelUnread.low) && $util.isInteger(message.totalChannelUnread.high)))
                return "totalChannelUnread: integer|Long expected";
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (!$util.isInteger(message.operatorID) && !(message.operatorID && $util.isInteger(message.operatorID.low) && $util.isInteger(message.operatorID.high)))
                return "operatorID: integer|Long expected";
        return null;
    };

    /**
     * Creates an UpdateChangedReadPointer message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateChangedReadPointer
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateChangedReadPointer} UpdateChangedReadPointer
     */
    UpdateChangedReadPointer.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateChangedReadPointer)
            return object;
        let message = new $root.UpdateChangedReadPointer();
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.previousInReadPointer != null)
            if ($util.Long)
                (message.previousInReadPointer = $util.Long.fromValue(object.previousInReadPointer)).unsigned = true;
            else if (typeof object.previousInReadPointer === "string")
                message.previousInReadPointer = parseInt(object.previousInReadPointer, 10);
            else if (typeof object.previousInReadPointer === "number")
                message.previousInReadPointer = object.previousInReadPointer;
            else if (typeof object.previousInReadPointer === "object")
                message.previousInReadPointer = new $util.LongBits(object.previousInReadPointer.low >>> 0, object.previousInReadPointer.high >>> 0).toNumber(true);
        if (object.inReadPointer != null)
            if ($util.Long)
                (message.inReadPointer = $util.Long.fromValue(object.inReadPointer)).unsigned = true;
            else if (typeof object.inReadPointer === "string")
                message.inReadPointer = parseInt(object.inReadPointer, 10);
            else if (typeof object.inReadPointer === "number")
                message.inReadPointer = object.inReadPointer;
            else if (typeof object.inReadPointer === "object")
                message.inReadPointer = new $util.LongBits(object.inReadPointer.low >>> 0, object.inReadPointer.high >>> 0).toNumber(true);
        if (object.outReadPointer != null)
            if ($util.Long)
                (message.outReadPointer = $util.Long.fromValue(object.outReadPointer)).unsigned = true;
            else if (typeof object.outReadPointer === "string")
                message.outReadPointer = parseInt(object.outReadPointer, 10);
            else if (typeof object.outReadPointer === "number")
                message.outReadPointer = object.outReadPointer;
            else if (typeof object.outReadPointer === "object")
                message.outReadPointer = new $util.LongBits(object.outReadPointer.low >>> 0, object.outReadPointer.high >>> 0).toNumber(true);
        if (object.messagesExcludedFromUnreadRange != null)
            if ($util.Long)
                (message.messagesExcludedFromUnreadRange = $util.Long.fromValue(object.messagesExcludedFromUnreadRange)).unsigned = true;
            else if (typeof object.messagesExcludedFromUnreadRange === "string")
                message.messagesExcludedFromUnreadRange = parseInt(object.messagesExcludedFromUnreadRange, 10);
            else if (typeof object.messagesExcludedFromUnreadRange === "number")
                message.messagesExcludedFromUnreadRange = object.messagesExcludedFromUnreadRange;
            else if (typeof object.messagesExcludedFromUnreadRange === "object")
                message.messagesExcludedFromUnreadRange = new $util.LongBits(object.messagesExcludedFromUnreadRange.low >>> 0, object.messagesExcludedFromUnreadRange.high >>> 0).toNumber(true);
        if (object.totalChannelUnread != null)
            if ($util.Long)
                (message.totalChannelUnread = $util.Long.fromValue(object.totalChannelUnread)).unsigned = true;
            else if (typeof object.totalChannelUnread === "string")
                message.totalChannelUnread = parseInt(object.totalChannelUnread, 10);
            else if (typeof object.totalChannelUnread === "number")
                message.totalChannelUnread = object.totalChannelUnread;
            else if (typeof object.totalChannelUnread === "object")
                message.totalChannelUnread = new $util.LongBits(object.totalChannelUnread.low >>> 0, object.totalChannelUnread.high >>> 0).toNumber(true);
        if (object.operatorID != null)
            if ($util.Long)
                (message.operatorID = $util.Long.fromValue(object.operatorID)).unsigned = true;
            else if (typeof object.operatorID === "string")
                message.operatorID = parseInt(object.operatorID, 10);
            else if (typeof object.operatorID === "number")
                message.operatorID = object.operatorID;
            else if (typeof object.operatorID === "object")
                message.operatorID = new $util.LongBits(object.operatorID.low >>> 0, object.operatorID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from an UpdateChangedReadPointer message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateChangedReadPointer
     * @static
     * @param {UpdateChangedReadPointer} message UpdateChangedReadPointer
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateChangedReadPointer.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.operatorID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.operatorID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.totalChannelUnread = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.totalChannelUnread = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.messagesExcludedFromUnreadRange = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.messagesExcludedFromUnreadRange = options.longs === String ? "0" : 0;
        }
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.inReadPointer != null && message.hasOwnProperty("inReadPointer")) {
            if (typeof message.inReadPointer === "number")
                object.inReadPointer = options.longs === String ? String(message.inReadPointer) : message.inReadPointer;
            else
                object.inReadPointer = options.longs === String ? $util.Long.prototype.toString.call(message.inReadPointer) : options.longs === Number ? new $util.LongBits(message.inReadPointer.low >>> 0, message.inReadPointer.high >>> 0).toNumber(true) : message.inReadPointer;
            if (options.oneofs)
                object.pointer = "inReadPointer";
        }
        if (message.outReadPointer != null && message.hasOwnProperty("outReadPointer")) {
            if (typeof message.outReadPointer === "number")
                object.outReadPointer = options.longs === String ? String(message.outReadPointer) : message.outReadPointer;
            else
                object.outReadPointer = options.longs === String ? $util.Long.prototype.toString.call(message.outReadPointer) : options.longs === Number ? new $util.LongBits(message.outReadPointer.low >>> 0, message.outReadPointer.high >>> 0).toNumber(true) : message.outReadPointer;
            if (options.oneofs)
                object.pointer = "outReadPointer";
        }
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (typeof message.operatorID === "number")
                object.operatorID = options.longs === String ? String(message.operatorID) : message.operatorID;
            else
                object.operatorID = options.longs === String ? $util.Long.prototype.toString.call(message.operatorID) : options.longs === Number ? new $util.LongBits(message.operatorID.low >>> 0, message.operatorID.high >>> 0).toNumber(true) : message.operatorID;
        if (message.totalChannelUnread != null && message.hasOwnProperty("totalChannelUnread"))
            if (typeof message.totalChannelUnread === "number")
                object.totalChannelUnread = options.longs === String ? String(message.totalChannelUnread) : message.totalChannelUnread;
            else
                object.totalChannelUnread = options.longs === String ? $util.Long.prototype.toString.call(message.totalChannelUnread) : options.longs === Number ? new $util.LongBits(message.totalChannelUnread.low >>> 0, message.totalChannelUnread.high >>> 0).toNumber(true) : message.totalChannelUnread;
        if (message.messagesExcludedFromUnreadRange != null && message.hasOwnProperty("messagesExcludedFromUnreadRange"))
            if (typeof message.messagesExcludedFromUnreadRange === "number")
                object.messagesExcludedFromUnreadRange = options.longs === String ? String(message.messagesExcludedFromUnreadRange) : message.messagesExcludedFromUnreadRange;
            else
                object.messagesExcludedFromUnreadRange = options.longs === String ? $util.Long.prototype.toString.call(message.messagesExcludedFromUnreadRange) : options.longs === Number ? new $util.LongBits(message.messagesExcludedFromUnreadRange.low >>> 0, message.messagesExcludedFromUnreadRange.high >>> 0).toNumber(true) : message.messagesExcludedFromUnreadRange;
        if (message.previousInReadPointer != null && message.hasOwnProperty("previousInReadPointer")) {
            if (typeof message.previousInReadPointer === "number")
                object.previousInReadPointer = options.longs === String ? String(message.previousInReadPointer) : message.previousInReadPointer;
            else
                object.previousInReadPointer = options.longs === String ? $util.Long.prototype.toString.call(message.previousInReadPointer) : options.longs === Number ? new $util.LongBits(message.previousInReadPointer.low >>> 0, message.previousInReadPointer.high >>> 0).toNumber(true) : message.previousInReadPointer;
            if (options.oneofs)
                object.pointer = "previousInReadPointer";
        }
        return object;
    };

    /**
     * Converts this UpdateChangedReadPointer to JSON.
     * @function toJSON
     * @memberof UpdateChangedReadPointer
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateChangedReadPointer.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateChangedReadPointer
     * @function getTypeUrl
     * @memberof UpdateChangedReadPointer
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateChangedReadPointer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateChangedReadPointer";
    };

    return UpdateChangedReadPointer;
})();

export const UpdateChangedAccess = $root.UpdateChangedAccess = (() => {

    /**
     * Properties of an UpdateChangedAccess.
     * @exports IUpdateChangedAccess
     * @interface IUpdateChangedAccess
     * @property {Long|null} [operatorID] UpdateChangedAccess operatorID
     * @property {boolean|null} [disabled] UpdateChangedAccess disabled
     * @property {Array.<Long>|null} [allowList] UpdateChangedAccess allowList
     * @property {Array.<Long>|null} [denyList] UpdateChangedAccess denyList
     */

    /**
     * Constructs a new UpdateChangedAccess.
     * @exports UpdateChangedAccess
     * @classdesc Represents an UpdateChangedAccess.
     * @implements IUpdateChangedAccess
     * @constructor
     * @param {IUpdateChangedAccess=} [properties] Properties to set
     */
    function UpdateChangedAccess(properties) {
        this.allowList = [];
        this.denyList = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateChangedAccess operatorID.
     * @member {Long} operatorID
     * @memberof UpdateChangedAccess
     * @instance
     */
    UpdateChangedAccess.prototype.operatorID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * UpdateChangedAccess disabled.
     * @member {boolean} disabled
     * @memberof UpdateChangedAccess
     * @instance
     */
    UpdateChangedAccess.prototype.disabled = false;

    /**
     * UpdateChangedAccess allowList.
     * @member {Array.<Long>} allowList
     * @memberof UpdateChangedAccess
     * @instance
     */
    UpdateChangedAccess.prototype.allowList = $util.emptyArray;

    /**
     * UpdateChangedAccess denyList.
     * @member {Array.<Long>} denyList
     * @memberof UpdateChangedAccess
     * @instance
     */
    UpdateChangedAccess.prototype.denyList = $util.emptyArray;

    /**
     * Creates a new UpdateChangedAccess instance using the specified properties.
     * @function create
     * @memberof UpdateChangedAccess
     * @static
     * @param {IUpdateChangedAccess=} [properties] Properties to set
     * @returns {UpdateChangedAccess} UpdateChangedAccess instance
     */
    UpdateChangedAccess.create = function create(properties) {
        return new UpdateChangedAccess(properties);
    };

    /**
     * Encodes the specified UpdateChangedAccess message. Does not implicitly {@link UpdateChangedAccess.verify|verify} messages.
     * @function encode
     * @memberof UpdateChangedAccess
     * @static
     * @param {IUpdateChangedAccess} message UpdateChangedAccess message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateChangedAccess.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.operatorID != null && Object.hasOwnProperty.call(message, "operatorID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.operatorID);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.disabled);
        if (message.allowList != null && message.allowList.length) {
            writer.uint32(/* id 3, wireType 2 =*/26).fork();
            for (let i = 0; i < message.allowList.length; ++i)
                writer.uint64(message.allowList[i]);
            writer.ldelim();
        }
        if (message.denyList != null && message.denyList.length) {
            writer.uint32(/* id 4, wireType 2 =*/34).fork();
            for (let i = 0; i < message.denyList.length; ++i)
                writer.uint64(message.denyList[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified UpdateChangedAccess message, length delimited. Does not implicitly {@link UpdateChangedAccess.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateChangedAccess
     * @static
     * @param {IUpdateChangedAccess} message UpdateChangedAccess message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateChangedAccess.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateChangedAccess message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateChangedAccess
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateChangedAccess} UpdateChangedAccess
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateChangedAccess.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateChangedAccess();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.operatorID = reader.uint64();
                    break;
                }
            case 2: {
                    message.disabled = reader.bool();
                    break;
                }
            case 3: {
                    if (!(message.allowList && message.allowList.length))
                        message.allowList = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.allowList.push(reader.uint64());
                    } else
                        message.allowList.push(reader.uint64());
                    break;
                }
            case 4: {
                    if (!(message.denyList && message.denyList.length))
                        message.denyList = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.denyList.push(reader.uint64());
                    } else
                        message.denyList.push(reader.uint64());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateChangedAccess message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateChangedAccess
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateChangedAccess} UpdateChangedAccess
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateChangedAccess.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateChangedAccess message.
     * @function verify
     * @memberof UpdateChangedAccess
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateChangedAccess.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (!$util.isInteger(message.operatorID) && !(message.operatorID && $util.isInteger(message.operatorID.low) && $util.isInteger(message.operatorID.high)))
                return "operatorID: integer|Long expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.allowList != null && message.hasOwnProperty("allowList")) {
            if (!Array.isArray(message.allowList))
                return "allowList: array expected";
            for (let i = 0; i < message.allowList.length; ++i)
                if (!$util.isInteger(message.allowList[i]) && !(message.allowList[i] && $util.isInteger(message.allowList[i].low) && $util.isInteger(message.allowList[i].high)))
                    return "allowList: integer|Long[] expected";
        }
        if (message.denyList != null && message.hasOwnProperty("denyList")) {
            if (!Array.isArray(message.denyList))
                return "denyList: array expected";
            for (let i = 0; i < message.denyList.length; ++i)
                if (!$util.isInteger(message.denyList[i]) && !(message.denyList[i] && $util.isInteger(message.denyList[i].low) && $util.isInteger(message.denyList[i].high)))
                    return "denyList: integer|Long[] expected";
        }
        return null;
    };

    /**
     * Creates an UpdateChangedAccess message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateChangedAccess
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateChangedAccess} UpdateChangedAccess
     */
    UpdateChangedAccess.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateChangedAccess)
            return object;
        let message = new $root.UpdateChangedAccess();
        if (object.operatorID != null)
            if ($util.Long)
                (message.operatorID = $util.Long.fromValue(object.operatorID)).unsigned = true;
            else if (typeof object.operatorID === "string")
                message.operatorID = parseInt(object.operatorID, 10);
            else if (typeof object.operatorID === "number")
                message.operatorID = object.operatorID;
            else if (typeof object.operatorID === "object")
                message.operatorID = new $util.LongBits(object.operatorID.low >>> 0, object.operatorID.high >>> 0).toNumber(true);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.allowList) {
            if (!Array.isArray(object.allowList))
                throw TypeError(".UpdateChangedAccess.allowList: array expected");
            message.allowList = [];
            for (let i = 0; i < object.allowList.length; ++i)
                if ($util.Long)
                    (message.allowList[i] = $util.Long.fromValue(object.allowList[i])).unsigned = true;
                else if (typeof object.allowList[i] === "string")
                    message.allowList[i] = parseInt(object.allowList[i], 10);
                else if (typeof object.allowList[i] === "number")
                    message.allowList[i] = object.allowList[i];
                else if (typeof object.allowList[i] === "object")
                    message.allowList[i] = new $util.LongBits(object.allowList[i].low >>> 0, object.allowList[i].high >>> 0).toNumber(true);
        }
        if (object.denyList) {
            if (!Array.isArray(object.denyList))
                throw TypeError(".UpdateChangedAccess.denyList: array expected");
            message.denyList = [];
            for (let i = 0; i < object.denyList.length; ++i)
                if ($util.Long)
                    (message.denyList[i] = $util.Long.fromValue(object.denyList[i])).unsigned = true;
                else if (typeof object.denyList[i] === "string")
                    message.denyList[i] = parseInt(object.denyList[i], 10);
                else if (typeof object.denyList[i] === "number")
                    message.denyList[i] = object.denyList[i];
                else if (typeof object.denyList[i] === "object")
                    message.denyList[i] = new $util.LongBits(object.denyList[i].low >>> 0, object.denyList[i].high >>> 0).toNumber(true);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateChangedAccess message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateChangedAccess
     * @static
     * @param {UpdateChangedAccess} message UpdateChangedAccess
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateChangedAccess.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.allowList = [];
            object.denyList = [];
        }
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.operatorID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.operatorID = options.longs === String ? "0" : 0;
            object.disabled = false;
        }
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (typeof message.operatorID === "number")
                object.operatorID = options.longs === String ? String(message.operatorID) : message.operatorID;
            else
                object.operatorID = options.longs === String ? $util.Long.prototype.toString.call(message.operatorID) : options.longs === Number ? new $util.LongBits(message.operatorID.low >>> 0, message.operatorID.high >>> 0).toNumber(true) : message.operatorID;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.allowList && message.allowList.length) {
            object.allowList = [];
            for (let j = 0; j < message.allowList.length; ++j)
                if (typeof message.allowList[j] === "number")
                    object.allowList[j] = options.longs === String ? String(message.allowList[j]) : message.allowList[j];
                else
                    object.allowList[j] = options.longs === String ? $util.Long.prototype.toString.call(message.allowList[j]) : options.longs === Number ? new $util.LongBits(message.allowList[j].low >>> 0, message.allowList[j].high >>> 0).toNumber(true) : message.allowList[j];
        }
        if (message.denyList && message.denyList.length) {
            object.denyList = [];
            for (let j = 0; j < message.denyList.length; ++j)
                if (typeof message.denyList[j] === "number")
                    object.denyList[j] = options.longs === String ? String(message.denyList[j]) : message.denyList[j];
                else
                    object.denyList[j] = options.longs === String ? $util.Long.prototype.toString.call(message.denyList[j]) : options.longs === Number ? new $util.LongBits(message.denyList[j].low >>> 0, message.denyList[j].high >>> 0).toNumber(true) : message.denyList[j];
        }
        return object;
    };

    /**
     * Converts this UpdateChangedAccess to JSON.
     * @function toJSON
     * @memberof UpdateChangedAccess
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateChangedAccess.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateChangedAccess
     * @function getTypeUrl
     * @memberof UpdateChangedAccess
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateChangedAccess.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateChangedAccess";
    };

    return UpdateChangedAccess;
})();

export const UpdateRedacted = $root.UpdateRedacted = (() => {

    /**
     * Properties of an UpdateRedacted.
     * @exports IUpdateRedacted
     * @interface IUpdateRedacted
     * @property {boolean|null} [redacted] UpdateRedacted redacted
     */

    /**
     * Constructs a new UpdateRedacted.
     * @exports UpdateRedacted
     * @classdesc Represents an UpdateRedacted.
     * @implements IUpdateRedacted
     * @constructor
     * @param {IUpdateRedacted=} [properties] Properties to set
     */
    function UpdateRedacted(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateRedacted redacted.
     * @member {boolean} redacted
     * @memberof UpdateRedacted
     * @instance
     */
    UpdateRedacted.prototype.redacted = false;

    /**
     * Creates a new UpdateRedacted instance using the specified properties.
     * @function create
     * @memberof UpdateRedacted
     * @static
     * @param {IUpdateRedacted=} [properties] Properties to set
     * @returns {UpdateRedacted} UpdateRedacted instance
     */
    UpdateRedacted.create = function create(properties) {
        return new UpdateRedacted(properties);
    };

    /**
     * Encodes the specified UpdateRedacted message. Does not implicitly {@link UpdateRedacted.verify|verify} messages.
     * @function encode
     * @memberof UpdateRedacted
     * @static
     * @param {IUpdateRedacted} message UpdateRedacted message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateRedacted.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.redacted != null && Object.hasOwnProperty.call(message, "redacted"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.redacted);
        return writer;
    };

    /**
     * Encodes the specified UpdateRedacted message, length delimited. Does not implicitly {@link UpdateRedacted.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateRedacted
     * @static
     * @param {IUpdateRedacted} message UpdateRedacted message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateRedacted.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateRedacted message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateRedacted
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateRedacted} UpdateRedacted
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateRedacted.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateRedacted();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.redacted = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateRedacted message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateRedacted
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateRedacted} UpdateRedacted
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateRedacted.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateRedacted message.
     * @function verify
     * @memberof UpdateRedacted
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateRedacted.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.redacted != null && message.hasOwnProperty("redacted"))
            if (typeof message.redacted !== "boolean")
                return "redacted: boolean expected";
        return null;
    };

    /**
     * Creates an UpdateRedacted message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateRedacted
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateRedacted} UpdateRedacted
     */
    UpdateRedacted.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateRedacted)
            return object;
        let message = new $root.UpdateRedacted();
        if (object.redacted != null)
            message.redacted = Boolean(object.redacted);
        return message;
    };

    /**
     * Creates a plain object from an UpdateRedacted message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateRedacted
     * @static
     * @param {UpdateRedacted} message UpdateRedacted
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateRedacted.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.redacted = false;
        if (message.redacted != null && message.hasOwnProperty("redacted"))
            object.redacted = message.redacted;
        return object;
    };

    /**
     * Converts this UpdateRedacted to JSON.
     * @function toJSON
     * @memberof UpdateRedacted
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateRedacted.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateRedacted
     * @function getTypeUrl
     * @memberof UpdateRedacted
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateRedacted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateRedacted";
    };

    return UpdateRedacted;
})();

export const UpdateChannelCounterReset = $root.UpdateChannelCounterReset = (() => {

    /**
     * Properties of an UpdateChannelCounterReset.
     * @exports IUpdateChannelCounterReset
     * @interface IUpdateChannelCounterReset
     * @property {Long|null} [operatorID] UpdateChannelCounterReset operatorID
     */

    /**
     * Constructs a new UpdateChannelCounterReset.
     * @exports UpdateChannelCounterReset
     * @classdesc Represents an UpdateChannelCounterReset.
     * @implements IUpdateChannelCounterReset
     * @constructor
     * @param {IUpdateChannelCounterReset=} [properties] Properties to set
     */
    function UpdateChannelCounterReset(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateChannelCounterReset operatorID.
     * @member {Long} operatorID
     * @memberof UpdateChannelCounterReset
     * @instance
     */
    UpdateChannelCounterReset.prototype.operatorID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new UpdateChannelCounterReset instance using the specified properties.
     * @function create
     * @memberof UpdateChannelCounterReset
     * @static
     * @param {IUpdateChannelCounterReset=} [properties] Properties to set
     * @returns {UpdateChannelCounterReset} UpdateChannelCounterReset instance
     */
    UpdateChannelCounterReset.create = function create(properties) {
        return new UpdateChannelCounterReset(properties);
    };

    /**
     * Encodes the specified UpdateChannelCounterReset message. Does not implicitly {@link UpdateChannelCounterReset.verify|verify} messages.
     * @function encode
     * @memberof UpdateChannelCounterReset
     * @static
     * @param {IUpdateChannelCounterReset} message UpdateChannelCounterReset message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateChannelCounterReset.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.operatorID != null && Object.hasOwnProperty.call(message, "operatorID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.operatorID);
        return writer;
    };

    /**
     * Encodes the specified UpdateChannelCounterReset message, length delimited. Does not implicitly {@link UpdateChannelCounterReset.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateChannelCounterReset
     * @static
     * @param {IUpdateChannelCounterReset} message UpdateChannelCounterReset message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateChannelCounterReset.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateChannelCounterReset message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateChannelCounterReset
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateChannelCounterReset} UpdateChannelCounterReset
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateChannelCounterReset.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateChannelCounterReset();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.operatorID = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateChannelCounterReset message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateChannelCounterReset
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateChannelCounterReset} UpdateChannelCounterReset
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateChannelCounterReset.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateChannelCounterReset message.
     * @function verify
     * @memberof UpdateChannelCounterReset
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateChannelCounterReset.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (!$util.isInteger(message.operatorID) && !(message.operatorID && $util.isInteger(message.operatorID.low) && $util.isInteger(message.operatorID.high)))
                return "operatorID: integer|Long expected";
        return null;
    };

    /**
     * Creates an UpdateChannelCounterReset message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateChannelCounterReset
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateChannelCounterReset} UpdateChannelCounterReset
     */
    UpdateChannelCounterReset.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateChannelCounterReset)
            return object;
        let message = new $root.UpdateChannelCounterReset();
        if (object.operatorID != null)
            if ($util.Long)
                (message.operatorID = $util.Long.fromValue(object.operatorID)).unsigned = true;
            else if (typeof object.operatorID === "string")
                message.operatorID = parseInt(object.operatorID, 10);
            else if (typeof object.operatorID === "number")
                message.operatorID = object.operatorID;
            else if (typeof object.operatorID === "object")
                message.operatorID = new $util.LongBits(object.operatorID.low >>> 0, object.operatorID.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from an UpdateChannelCounterReset message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateChannelCounterReset
     * @static
     * @param {UpdateChannelCounterReset} message UpdateChannelCounterReset
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateChannelCounterReset.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.operatorID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.operatorID = options.longs === String ? "0" : 0;
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (typeof message.operatorID === "number")
                object.operatorID = options.longs === String ? String(message.operatorID) : message.operatorID;
            else
                object.operatorID = options.longs === String ? $util.Long.prototype.toString.call(message.operatorID) : options.longs === Number ? new $util.LongBits(message.operatorID.low >>> 0, message.operatorID.high >>> 0).toNumber(true) : message.operatorID;
        return object;
    };

    /**
     * Converts this UpdateChannelCounterReset to JSON.
     * @function toJSON
     * @memberof UpdateChannelCounterReset
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateChannelCounterReset.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateChannelCounterReset
     * @function getTypeUrl
     * @memberof UpdateChannelCounterReset
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateChannelCounterReset.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateChannelCounterReset";
    };

    return UpdateChannelCounterReset;
})();

export const UpdateChatChangedRestrictions = $root.UpdateChatChangedRestrictions = (() => {

    /**
     * Properties of an UpdateChatChangedRestrictions.
     * @exports IUpdateChatChangedRestrictions
     * @interface IUpdateChatChangedRestrictions
     * @property {Long|null} [chatID] UpdateChatChangedRestrictions chatID
     * @property {Array.<IMCChatRestriction>|null} [restrictions] UpdateChatChangedRestrictions restrictions
     */

    /**
     * Constructs a new UpdateChatChangedRestrictions.
     * @exports UpdateChatChangedRestrictions
     * @classdesc Represents an UpdateChatChangedRestrictions.
     * @implements IUpdateChatChangedRestrictions
     * @constructor
     * @param {IUpdateChatChangedRestrictions=} [properties] Properties to set
     */
    function UpdateChatChangedRestrictions(properties) {
        this.restrictions = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateChatChangedRestrictions chatID.
     * @member {Long} chatID
     * @memberof UpdateChatChangedRestrictions
     * @instance
     */
    UpdateChatChangedRestrictions.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * UpdateChatChangedRestrictions restrictions.
     * @member {Array.<IMCChatRestriction>} restrictions
     * @memberof UpdateChatChangedRestrictions
     * @instance
     */
    UpdateChatChangedRestrictions.prototype.restrictions = $util.emptyArray;

    /**
     * Creates a new UpdateChatChangedRestrictions instance using the specified properties.
     * @function create
     * @memberof UpdateChatChangedRestrictions
     * @static
     * @param {IUpdateChatChangedRestrictions=} [properties] Properties to set
     * @returns {UpdateChatChangedRestrictions} UpdateChatChangedRestrictions instance
     */
    UpdateChatChangedRestrictions.create = function create(properties) {
        return new UpdateChatChangedRestrictions(properties);
    };

    /**
     * Encodes the specified UpdateChatChangedRestrictions message. Does not implicitly {@link UpdateChatChangedRestrictions.verify|verify} messages.
     * @function encode
     * @memberof UpdateChatChangedRestrictions
     * @static
     * @param {IUpdateChatChangedRestrictions} message UpdateChatChangedRestrictions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateChatChangedRestrictions.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatID);
        if (message.restrictions != null && message.restrictions.length)
            for (let i = 0; i < message.restrictions.length; ++i)
                $root.MCChatRestriction.encode(message.restrictions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateChatChangedRestrictions message, length delimited. Does not implicitly {@link UpdateChatChangedRestrictions.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateChatChangedRestrictions
     * @static
     * @param {IUpdateChatChangedRestrictions} message UpdateChatChangedRestrictions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateChatChangedRestrictions.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateChatChangedRestrictions message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateChatChangedRestrictions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateChatChangedRestrictions} UpdateChatChangedRestrictions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateChatChangedRestrictions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateChatChangedRestrictions();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 2: {
                    if (!(message.restrictions && message.restrictions.length))
                        message.restrictions = [];
                    message.restrictions.push($root.MCChatRestriction.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateChatChangedRestrictions message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateChatChangedRestrictions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateChatChangedRestrictions} UpdateChatChangedRestrictions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateChatChangedRestrictions.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateChatChangedRestrictions message.
     * @function verify
     * @memberof UpdateChatChangedRestrictions
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateChatChangedRestrictions.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.restrictions != null && message.hasOwnProperty("restrictions")) {
            if (!Array.isArray(message.restrictions))
                return "restrictions: array expected";
            for (let i = 0; i < message.restrictions.length; ++i) {
                let error = $root.MCChatRestriction.verify(message.restrictions[i]);
                if (error)
                    return "restrictions." + error;
            }
        }
        return null;
    };

    /**
     * Creates an UpdateChatChangedRestrictions message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateChatChangedRestrictions
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateChatChangedRestrictions} UpdateChatChangedRestrictions
     */
    UpdateChatChangedRestrictions.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateChatChangedRestrictions)
            return object;
        let message = new $root.UpdateChatChangedRestrictions();
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.restrictions) {
            if (!Array.isArray(object.restrictions))
                throw TypeError(".UpdateChatChangedRestrictions.restrictions: array expected");
            message.restrictions = [];
            for (let i = 0; i < object.restrictions.length; ++i) {
                if (typeof object.restrictions[i] !== "object")
                    throw TypeError(".UpdateChatChangedRestrictions.restrictions: object expected");
                message.restrictions[i] = $root.MCChatRestriction.fromObject(object.restrictions[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateChatChangedRestrictions message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateChatChangedRestrictions
     * @static
     * @param {UpdateChatChangedRestrictions} message UpdateChatChangedRestrictions
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateChatChangedRestrictions.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.restrictions = [];
        if (options.defaults)
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.restrictions && message.restrictions.length) {
            object.restrictions = [];
            for (let j = 0; j < message.restrictions.length; ++j)
                object.restrictions[j] = $root.MCChatRestriction.toObject(message.restrictions[j], options);
        }
        return object;
    };

    /**
     * Converts this UpdateChatChangedRestrictions to JSON.
     * @function toJSON
     * @memberof UpdateChatChangedRestrictions
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateChatChangedRestrictions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateChatChangedRestrictions
     * @function getTypeUrl
     * @memberof UpdateChatChangedRestrictions
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateChatChangedRestrictions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateChatChangedRestrictions";
    };

    return UpdateChatChangedRestrictions;
})();

export const UpdateOperatorAssignWidget = $root.UpdateOperatorAssignWidget = (() => {

    /**
     * Properties of an UpdateOperatorAssignWidget.
     * @exports IUpdateOperatorAssignWidget
     * @interface IUpdateOperatorAssignWidget
     * @property {Long|null} [chatID] UpdateOperatorAssignWidget chatID
     * @property {Long|null} [operatorID] UpdateOperatorAssignWidget operatorID
     * @property {boolean|null} [unassigned] UpdateOperatorAssignWidget unassigned
     */

    /**
     * Constructs a new UpdateOperatorAssignWidget.
     * @exports UpdateOperatorAssignWidget
     * @classdesc Represents an UpdateOperatorAssignWidget.
     * @implements IUpdateOperatorAssignWidget
     * @constructor
     * @param {IUpdateOperatorAssignWidget=} [properties] Properties to set
     */
    function UpdateOperatorAssignWidget(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateOperatorAssignWidget chatID.
     * @member {Long} chatID
     * @memberof UpdateOperatorAssignWidget
     * @instance
     */
    UpdateOperatorAssignWidget.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * UpdateOperatorAssignWidget operatorID.
     * @member {Long} operatorID
     * @memberof UpdateOperatorAssignWidget
     * @instance
     */
    UpdateOperatorAssignWidget.prototype.operatorID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * UpdateOperatorAssignWidget unassigned.
     * @member {boolean} unassigned
     * @memberof UpdateOperatorAssignWidget
     * @instance
     */
    UpdateOperatorAssignWidget.prototype.unassigned = false;

    /**
     * Creates a new UpdateOperatorAssignWidget instance using the specified properties.
     * @function create
     * @memberof UpdateOperatorAssignWidget
     * @static
     * @param {IUpdateOperatorAssignWidget=} [properties] Properties to set
     * @returns {UpdateOperatorAssignWidget} UpdateOperatorAssignWidget instance
     */
    UpdateOperatorAssignWidget.create = function create(properties) {
        return new UpdateOperatorAssignWidget(properties);
    };

    /**
     * Encodes the specified UpdateOperatorAssignWidget message. Does not implicitly {@link UpdateOperatorAssignWidget.verify|verify} messages.
     * @function encode
     * @memberof UpdateOperatorAssignWidget
     * @static
     * @param {IUpdateOperatorAssignWidget} message UpdateOperatorAssignWidget message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateOperatorAssignWidget.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatID);
        if (message.operatorID != null && Object.hasOwnProperty.call(message, "operatorID"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.operatorID);
        if (message.unassigned != null && Object.hasOwnProperty.call(message, "unassigned"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.unassigned);
        return writer;
    };

    /**
     * Encodes the specified UpdateOperatorAssignWidget message, length delimited. Does not implicitly {@link UpdateOperatorAssignWidget.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateOperatorAssignWidget
     * @static
     * @param {IUpdateOperatorAssignWidget} message UpdateOperatorAssignWidget message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateOperatorAssignWidget.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateOperatorAssignWidget message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateOperatorAssignWidget
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateOperatorAssignWidget} UpdateOperatorAssignWidget
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateOperatorAssignWidget.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateOperatorAssignWidget();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 2: {
                    message.operatorID = reader.uint64();
                    break;
                }
            case 3: {
                    message.unassigned = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateOperatorAssignWidget message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateOperatorAssignWidget
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateOperatorAssignWidget} UpdateOperatorAssignWidget
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateOperatorAssignWidget.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateOperatorAssignWidget message.
     * @function verify
     * @memberof UpdateOperatorAssignWidget
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateOperatorAssignWidget.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (!$util.isInteger(message.operatorID) && !(message.operatorID && $util.isInteger(message.operatorID.low) && $util.isInteger(message.operatorID.high)))
                return "operatorID: integer|Long expected";
        if (message.unassigned != null && message.hasOwnProperty("unassigned"))
            if (typeof message.unassigned !== "boolean")
                return "unassigned: boolean expected";
        return null;
    };

    /**
     * Creates an UpdateOperatorAssignWidget message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateOperatorAssignWidget
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateOperatorAssignWidget} UpdateOperatorAssignWidget
     */
    UpdateOperatorAssignWidget.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateOperatorAssignWidget)
            return object;
        let message = new $root.UpdateOperatorAssignWidget();
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.operatorID != null)
            if ($util.Long)
                (message.operatorID = $util.Long.fromValue(object.operatorID)).unsigned = true;
            else if (typeof object.operatorID === "string")
                message.operatorID = parseInt(object.operatorID, 10);
            else if (typeof object.operatorID === "number")
                message.operatorID = object.operatorID;
            else if (typeof object.operatorID === "object")
                message.operatorID = new $util.LongBits(object.operatorID.low >>> 0, object.operatorID.high >>> 0).toNumber(true);
        if (object.unassigned != null)
            message.unassigned = Boolean(object.unassigned);
        return message;
    };

    /**
     * Creates a plain object from an UpdateOperatorAssignWidget message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateOperatorAssignWidget
     * @static
     * @param {UpdateOperatorAssignWidget} message UpdateOperatorAssignWidget
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateOperatorAssignWidget.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.operatorID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.operatorID = options.longs === String ? "0" : 0;
            object.unassigned = false;
        }
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.operatorID != null && message.hasOwnProperty("operatorID"))
            if (typeof message.operatorID === "number")
                object.operatorID = options.longs === String ? String(message.operatorID) : message.operatorID;
            else
                object.operatorID = options.longs === String ? $util.Long.prototype.toString.call(message.operatorID) : options.longs === Number ? new $util.LongBits(message.operatorID.low >>> 0, message.operatorID.high >>> 0).toNumber(true) : message.operatorID;
        if (message.unassigned != null && message.hasOwnProperty("unassigned"))
            object.unassigned = message.unassigned;
        return object;
    };

    /**
     * Converts this UpdateOperatorAssignWidget to JSON.
     * @function toJSON
     * @memberof UpdateOperatorAssignWidget
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateOperatorAssignWidget.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateOperatorAssignWidget
     * @function getTypeUrl
     * @memberof UpdateOperatorAssignWidget
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateOperatorAssignWidget.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateOperatorAssignWidget";
    };

    return UpdateOperatorAssignWidget;
})();

export const UpdateNewMessageSuggestion = $root.UpdateNewMessageSuggestion = (() => {

    /**
     * Properties of an UpdateNewMessageSuggestion.
     * @exports IUpdateNewMessageSuggestion
     * @interface IUpdateNewMessageSuggestion
     * @property {Long|null} [chatID] UpdateNewMessageSuggestion chatID
     * @property {IMCMessageSuggestion|null} [suggestion] UpdateNewMessageSuggestion suggestion
     */

    /**
     * Constructs a new UpdateNewMessageSuggestion.
     * @exports UpdateNewMessageSuggestion
     * @classdesc Represents an UpdateNewMessageSuggestion.
     * @implements IUpdateNewMessageSuggestion
     * @constructor
     * @param {IUpdateNewMessageSuggestion=} [properties] Properties to set
     */
    function UpdateNewMessageSuggestion(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateNewMessageSuggestion chatID.
     * @member {Long} chatID
     * @memberof UpdateNewMessageSuggestion
     * @instance
     */
    UpdateNewMessageSuggestion.prototype.chatID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * UpdateNewMessageSuggestion suggestion.
     * @member {IMCMessageSuggestion|null|undefined} suggestion
     * @memberof UpdateNewMessageSuggestion
     * @instance
     */
    UpdateNewMessageSuggestion.prototype.suggestion = null;

    /**
     * Creates a new UpdateNewMessageSuggestion instance using the specified properties.
     * @function create
     * @memberof UpdateNewMessageSuggestion
     * @static
     * @param {IUpdateNewMessageSuggestion=} [properties] Properties to set
     * @returns {UpdateNewMessageSuggestion} UpdateNewMessageSuggestion instance
     */
    UpdateNewMessageSuggestion.create = function create(properties) {
        return new UpdateNewMessageSuggestion(properties);
    };

    /**
     * Encodes the specified UpdateNewMessageSuggestion message. Does not implicitly {@link UpdateNewMessageSuggestion.verify|verify} messages.
     * @function encode
     * @memberof UpdateNewMessageSuggestion
     * @static
     * @param {IUpdateNewMessageSuggestion} message UpdateNewMessageSuggestion message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateNewMessageSuggestion.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.chatID != null && Object.hasOwnProperty.call(message, "chatID"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chatID);
        if (message.suggestion != null && Object.hasOwnProperty.call(message, "suggestion"))
            $root.MCMessageSuggestion.encode(message.suggestion, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateNewMessageSuggestion message, length delimited. Does not implicitly {@link UpdateNewMessageSuggestion.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateNewMessageSuggestion
     * @static
     * @param {IUpdateNewMessageSuggestion} message UpdateNewMessageSuggestion message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateNewMessageSuggestion.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateNewMessageSuggestion message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateNewMessageSuggestion
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateNewMessageSuggestion} UpdateNewMessageSuggestion
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateNewMessageSuggestion.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateNewMessageSuggestion();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.chatID = reader.uint64();
                    break;
                }
            case 2: {
                    message.suggestion = $root.MCMessageSuggestion.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateNewMessageSuggestion message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateNewMessageSuggestion
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateNewMessageSuggestion} UpdateNewMessageSuggestion
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateNewMessageSuggestion.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateNewMessageSuggestion message.
     * @function verify
     * @memberof UpdateNewMessageSuggestion
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateNewMessageSuggestion.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (!$util.isInteger(message.chatID) && !(message.chatID && $util.isInteger(message.chatID.low) && $util.isInteger(message.chatID.high)))
                return "chatID: integer|Long expected";
        if (message.suggestion != null && message.hasOwnProperty("suggestion")) {
            let error = $root.MCMessageSuggestion.verify(message.suggestion);
            if (error)
                return "suggestion." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateNewMessageSuggestion message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateNewMessageSuggestion
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateNewMessageSuggestion} UpdateNewMessageSuggestion
     */
    UpdateNewMessageSuggestion.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateNewMessageSuggestion)
            return object;
        let message = new $root.UpdateNewMessageSuggestion();
        if (object.chatID != null)
            if ($util.Long)
                (message.chatID = $util.Long.fromValue(object.chatID)).unsigned = true;
            else if (typeof object.chatID === "string")
                message.chatID = parseInt(object.chatID, 10);
            else if (typeof object.chatID === "number")
                message.chatID = object.chatID;
            else if (typeof object.chatID === "object")
                message.chatID = new $util.LongBits(object.chatID.low >>> 0, object.chatID.high >>> 0).toNumber(true);
        if (object.suggestion != null) {
            if (typeof object.suggestion !== "object")
                throw TypeError(".UpdateNewMessageSuggestion.suggestion: object expected");
            message.suggestion = $root.MCMessageSuggestion.fromObject(object.suggestion);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateNewMessageSuggestion message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateNewMessageSuggestion
     * @static
     * @param {UpdateNewMessageSuggestion} message UpdateNewMessageSuggestion
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateNewMessageSuggestion.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.chatID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.chatID = options.longs === String ? "0" : 0;
            object.suggestion = null;
        }
        if (message.chatID != null && message.hasOwnProperty("chatID"))
            if (typeof message.chatID === "number")
                object.chatID = options.longs === String ? String(message.chatID) : message.chatID;
            else
                object.chatID = options.longs === String ? $util.Long.prototype.toString.call(message.chatID) : options.longs === Number ? new $util.LongBits(message.chatID.low >>> 0, message.chatID.high >>> 0).toNumber(true) : message.chatID;
        if (message.suggestion != null && message.hasOwnProperty("suggestion"))
            object.suggestion = $root.MCMessageSuggestion.toObject(message.suggestion, options);
        return object;
    };

    /**
     * Converts this UpdateNewMessageSuggestion to JSON.
     * @function toJSON
     * @memberof UpdateNewMessageSuggestion
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateNewMessageSuggestion.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateNewMessageSuggestion
     * @function getTypeUrl
     * @memberof UpdateNewMessageSuggestion
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateNewMessageSuggestion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateNewMessageSuggestion";
    };

    return UpdateNewMessageSuggestion;
})();

export const UpdateChannelState = $root.UpdateChannelState = (() => {

    /**
     * Properties of an UpdateChannelState.
     * @exports IUpdateChannelState
     * @interface IUpdateChannelState
     * @property {IMCChannelState|null} [newState] UpdateChannelState newState
     */

    /**
     * Constructs a new UpdateChannelState.
     * @exports UpdateChannelState
     * @classdesc Represents an UpdateChannelState.
     * @implements IUpdateChannelState
     * @constructor
     * @param {IUpdateChannelState=} [properties] Properties to set
     */
    function UpdateChannelState(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateChannelState newState.
     * @member {IMCChannelState|null|undefined} newState
     * @memberof UpdateChannelState
     * @instance
     */
    UpdateChannelState.prototype.newState = null;

    /**
     * Creates a new UpdateChannelState instance using the specified properties.
     * @function create
     * @memberof UpdateChannelState
     * @static
     * @param {IUpdateChannelState=} [properties] Properties to set
     * @returns {UpdateChannelState} UpdateChannelState instance
     */
    UpdateChannelState.create = function create(properties) {
        return new UpdateChannelState(properties);
    };

    /**
     * Encodes the specified UpdateChannelState message. Does not implicitly {@link UpdateChannelState.verify|verify} messages.
     * @function encode
     * @memberof UpdateChannelState
     * @static
     * @param {IUpdateChannelState} message UpdateChannelState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateChannelState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.newState != null && Object.hasOwnProperty.call(message, "newState"))
            $root.MCChannelState.encode(message.newState, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateChannelState message, length delimited. Does not implicitly {@link UpdateChannelState.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateChannelState
     * @static
     * @param {IUpdateChannelState} message UpdateChannelState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateChannelState.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateChannelState message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateChannelState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateChannelState} UpdateChannelState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateChannelState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateChannelState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 2: {
                    message.newState = $root.MCChannelState.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateChannelState message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateChannelState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateChannelState} UpdateChannelState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateChannelState.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateChannelState message.
     * @function verify
     * @memberof UpdateChannelState
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateChannelState.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.newState != null && message.hasOwnProperty("newState")) {
            let error = $root.MCChannelState.verify(message.newState);
            if (error)
                return "newState." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateChannelState message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateChannelState
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateChannelState} UpdateChannelState
     */
    UpdateChannelState.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateChannelState)
            return object;
        let message = new $root.UpdateChannelState();
        if (object.newState != null) {
            if (typeof object.newState !== "object")
                throw TypeError(".UpdateChannelState.newState: object expected");
            message.newState = $root.MCChannelState.fromObject(object.newState);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateChannelState message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateChannelState
     * @static
     * @param {UpdateChannelState} message UpdateChannelState
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateChannelState.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.newState = null;
        if (message.newState != null && message.hasOwnProperty("newState"))
            object.newState = $root.MCChannelState.toObject(message.newState, options);
        return object;
    };

    /**
     * Converts this UpdateChannelState to JSON.
     * @function toJSON
     * @memberof UpdateChannelState
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateChannelState.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateChannelState
     * @function getTypeUrl
     * @memberof UpdateChannelState
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateChannelState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateChannelState";
    };

    return UpdateChannelState;
})();

export const TelegramAuthStage = $root.TelegramAuthStage = (() => {

    /**
     * Properties of a TelegramAuthStage.
     * @exports ITelegramAuthStage
     * @interface ITelegramAuthStage
     * @property {TelegramAuthStage.IEnterPhone|null} [enterPhone] TelegramAuthStage enterPhone
     * @property {TelegramAuthStage.IResendCode|null} [resendCode] TelegramAuthStage resendCode
     * @property {TelegramAuthStage.IEnterCode|null} [enterCode] TelegramAuthStage enterCode
     * @property {TelegramAuthStage.IEnterPassword|null} [enterPassword] TelegramAuthStage enterPassword
     */

    /**
     * Constructs a new TelegramAuthStage.
     * @exports TelegramAuthStage
     * @classdesc Represents a TelegramAuthStage.
     * @implements ITelegramAuthStage
     * @constructor
     * @param {ITelegramAuthStage=} [properties] Properties to set
     */
    function TelegramAuthStage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TelegramAuthStage enterPhone.
     * @member {TelegramAuthStage.IEnterPhone|null|undefined} enterPhone
     * @memberof TelegramAuthStage
     * @instance
     */
    TelegramAuthStage.prototype.enterPhone = null;

    /**
     * TelegramAuthStage resendCode.
     * @member {TelegramAuthStage.IResendCode|null|undefined} resendCode
     * @memberof TelegramAuthStage
     * @instance
     */
    TelegramAuthStage.prototype.resendCode = null;

    /**
     * TelegramAuthStage enterCode.
     * @member {TelegramAuthStage.IEnterCode|null|undefined} enterCode
     * @memberof TelegramAuthStage
     * @instance
     */
    TelegramAuthStage.prototype.enterCode = null;

    /**
     * TelegramAuthStage enterPassword.
     * @member {TelegramAuthStage.IEnterPassword|null|undefined} enterPassword
     * @memberof TelegramAuthStage
     * @instance
     */
    TelegramAuthStage.prototype.enterPassword = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * TelegramAuthStage stage.
     * @member {"enterPhone"|"resendCode"|"enterCode"|"enterPassword"|undefined} stage
     * @memberof TelegramAuthStage
     * @instance
     */
    Object.defineProperty(TelegramAuthStage.prototype, "stage", {
        get: $util.oneOfGetter($oneOfFields = ["enterPhone", "resendCode", "enterCode", "enterPassword"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new TelegramAuthStage instance using the specified properties.
     * @function create
     * @memberof TelegramAuthStage
     * @static
     * @param {ITelegramAuthStage=} [properties] Properties to set
     * @returns {TelegramAuthStage} TelegramAuthStage instance
     */
    TelegramAuthStage.create = function create(properties) {
        return new TelegramAuthStage(properties);
    };

    /**
     * Encodes the specified TelegramAuthStage message. Does not implicitly {@link TelegramAuthStage.verify|verify} messages.
     * @function encode
     * @memberof TelegramAuthStage
     * @static
     * @param {ITelegramAuthStage} message TelegramAuthStage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramAuthStage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.enterPhone != null && Object.hasOwnProperty.call(message, "enterPhone"))
            $root.TelegramAuthStage.EnterPhone.encode(message.enterPhone, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.resendCode != null && Object.hasOwnProperty.call(message, "resendCode"))
            $root.TelegramAuthStage.ResendCode.encode(message.resendCode, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.enterCode != null && Object.hasOwnProperty.call(message, "enterCode"))
            $root.TelegramAuthStage.EnterCode.encode(message.enterCode, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.enterPassword != null && Object.hasOwnProperty.call(message, "enterPassword"))
            $root.TelegramAuthStage.EnterPassword.encode(message.enterPassword, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified TelegramAuthStage message, length delimited. Does not implicitly {@link TelegramAuthStage.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TelegramAuthStage
     * @static
     * @param {ITelegramAuthStage} message TelegramAuthStage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramAuthStage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TelegramAuthStage message from the specified reader or buffer.
     * @function decode
     * @memberof TelegramAuthStage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TelegramAuthStage} TelegramAuthStage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramAuthStage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramAuthStage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.enterPhone = $root.TelegramAuthStage.EnterPhone.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.resendCode = $root.TelegramAuthStage.ResendCode.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.enterCode = $root.TelegramAuthStage.EnterCode.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.enterPassword = $root.TelegramAuthStage.EnterPassword.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TelegramAuthStage message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TelegramAuthStage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TelegramAuthStage} TelegramAuthStage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramAuthStage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TelegramAuthStage message.
     * @function verify
     * @memberof TelegramAuthStage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TelegramAuthStage.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.enterPhone != null && message.hasOwnProperty("enterPhone")) {
            properties.stage = 1;
            {
                let error = $root.TelegramAuthStage.EnterPhone.verify(message.enterPhone);
                if (error)
                    return "enterPhone." + error;
            }
        }
        if (message.resendCode != null && message.hasOwnProperty("resendCode")) {
            if (properties.stage === 1)
                return "stage: multiple values";
            properties.stage = 1;
            {
                let error = $root.TelegramAuthStage.ResendCode.verify(message.resendCode);
                if (error)
                    return "resendCode." + error;
            }
        }
        if (message.enterCode != null && message.hasOwnProperty("enterCode")) {
            if (properties.stage === 1)
                return "stage: multiple values";
            properties.stage = 1;
            {
                let error = $root.TelegramAuthStage.EnterCode.verify(message.enterCode);
                if (error)
                    return "enterCode." + error;
            }
        }
        if (message.enterPassword != null && message.hasOwnProperty("enterPassword")) {
            if (properties.stage === 1)
                return "stage: multiple values";
            properties.stage = 1;
            {
                let error = $root.TelegramAuthStage.EnterPassword.verify(message.enterPassword);
                if (error)
                    return "enterPassword." + error;
            }
        }
        return null;
    };

    /**
     * Creates a TelegramAuthStage message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TelegramAuthStage
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TelegramAuthStage} TelegramAuthStage
     */
    TelegramAuthStage.fromObject = function fromObject(object) {
        if (object instanceof $root.TelegramAuthStage)
            return object;
        let message = new $root.TelegramAuthStage();
        if (object.enterPhone != null) {
            if (typeof object.enterPhone !== "object")
                throw TypeError(".TelegramAuthStage.enterPhone: object expected");
            message.enterPhone = $root.TelegramAuthStage.EnterPhone.fromObject(object.enterPhone);
        }
        if (object.resendCode != null) {
            if (typeof object.resendCode !== "object")
                throw TypeError(".TelegramAuthStage.resendCode: object expected");
            message.resendCode = $root.TelegramAuthStage.ResendCode.fromObject(object.resendCode);
        }
        if (object.enterCode != null) {
            if (typeof object.enterCode !== "object")
                throw TypeError(".TelegramAuthStage.enterCode: object expected");
            message.enterCode = $root.TelegramAuthStage.EnterCode.fromObject(object.enterCode);
        }
        if (object.enterPassword != null) {
            if (typeof object.enterPassword !== "object")
                throw TypeError(".TelegramAuthStage.enterPassword: object expected");
            message.enterPassword = $root.TelegramAuthStage.EnterPassword.fromObject(object.enterPassword);
        }
        return message;
    };

    /**
     * Creates a plain object from a TelegramAuthStage message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TelegramAuthStage
     * @static
     * @param {TelegramAuthStage} message TelegramAuthStage
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TelegramAuthStage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (message.enterPhone != null && message.hasOwnProperty("enterPhone")) {
            object.enterPhone = $root.TelegramAuthStage.EnterPhone.toObject(message.enterPhone, options);
            if (options.oneofs)
                object.stage = "enterPhone";
        }
        if (message.resendCode != null && message.hasOwnProperty("resendCode")) {
            object.resendCode = $root.TelegramAuthStage.ResendCode.toObject(message.resendCode, options);
            if (options.oneofs)
                object.stage = "resendCode";
        }
        if (message.enterCode != null && message.hasOwnProperty("enterCode")) {
            object.enterCode = $root.TelegramAuthStage.EnterCode.toObject(message.enterCode, options);
            if (options.oneofs)
                object.stage = "enterCode";
        }
        if (message.enterPassword != null && message.hasOwnProperty("enterPassword")) {
            object.enterPassword = $root.TelegramAuthStage.EnterPassword.toObject(message.enterPassword, options);
            if (options.oneofs)
                object.stage = "enterPassword";
        }
        return object;
    };

    /**
     * Converts this TelegramAuthStage to JSON.
     * @function toJSON
     * @memberof TelegramAuthStage
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TelegramAuthStage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TelegramAuthStage
     * @function getTypeUrl
     * @memberof TelegramAuthStage
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TelegramAuthStage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TelegramAuthStage";
    };

    TelegramAuthStage.EnterPhone = (function() {

        /**
         * Properties of an EnterPhone.
         * @memberof TelegramAuthStage
         * @interface IEnterPhone
         * @property {string|null} [phone] EnterPhone phone
         */

        /**
         * Constructs a new EnterPhone.
         * @memberof TelegramAuthStage
         * @classdesc Represents an EnterPhone.
         * @implements IEnterPhone
         * @constructor
         * @param {TelegramAuthStage.IEnterPhone=} [properties] Properties to set
         */
        function EnterPhone(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EnterPhone phone.
         * @member {string} phone
         * @memberof TelegramAuthStage.EnterPhone
         * @instance
         */
        EnterPhone.prototype.phone = "";

        /**
         * Creates a new EnterPhone instance using the specified properties.
         * @function create
         * @memberof TelegramAuthStage.EnterPhone
         * @static
         * @param {TelegramAuthStage.IEnterPhone=} [properties] Properties to set
         * @returns {TelegramAuthStage.EnterPhone} EnterPhone instance
         */
        EnterPhone.create = function create(properties) {
            return new EnterPhone(properties);
        };

        /**
         * Encodes the specified EnterPhone message. Does not implicitly {@link TelegramAuthStage.EnterPhone.verify|verify} messages.
         * @function encode
         * @memberof TelegramAuthStage.EnterPhone
         * @static
         * @param {TelegramAuthStage.IEnterPhone} message EnterPhone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterPhone.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phone);
            return writer;
        };

        /**
         * Encodes the specified EnterPhone message, length delimited. Does not implicitly {@link TelegramAuthStage.EnterPhone.verify|verify} messages.
         * @function encodeDelimited
         * @memberof TelegramAuthStage.EnterPhone
         * @static
         * @param {TelegramAuthStage.IEnterPhone} message EnterPhone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterPhone.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnterPhone message from the specified reader or buffer.
         * @function decode
         * @memberof TelegramAuthStage.EnterPhone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {TelegramAuthStage.EnterPhone} EnterPhone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterPhone.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramAuthStage.EnterPhone();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.phone = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnterPhone message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof TelegramAuthStage.EnterPhone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {TelegramAuthStage.EnterPhone} EnterPhone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterPhone.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnterPhone message.
         * @function verify
         * @memberof TelegramAuthStage.EnterPhone
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnterPhone.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.phone != null && message.hasOwnProperty("phone"))
                if (!$util.isString(message.phone))
                    return "phone: string expected";
            return null;
        };

        /**
         * Creates an EnterPhone message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof TelegramAuthStage.EnterPhone
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {TelegramAuthStage.EnterPhone} EnterPhone
         */
        EnterPhone.fromObject = function fromObject(object) {
            if (object instanceof $root.TelegramAuthStage.EnterPhone)
                return object;
            let message = new $root.TelegramAuthStage.EnterPhone();
            if (object.phone != null)
                message.phone = String(object.phone);
            return message;
        };

        /**
         * Creates a plain object from an EnterPhone message. Also converts values to other types if specified.
         * @function toObject
         * @memberof TelegramAuthStage.EnterPhone
         * @static
         * @param {TelegramAuthStage.EnterPhone} message EnterPhone
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnterPhone.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.phone = "";
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            return object;
        };

        /**
         * Converts this EnterPhone to JSON.
         * @function toJSON
         * @memberof TelegramAuthStage.EnterPhone
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnterPhone.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EnterPhone
         * @function getTypeUrl
         * @memberof TelegramAuthStage.EnterPhone
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EnterPhone.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/TelegramAuthStage.EnterPhone";
        };

        return EnterPhone;
    })();

    TelegramAuthStage.ResendCode = (function() {

        /**
         * Properties of a ResendCode.
         * @memberof TelegramAuthStage
         * @interface IResendCode
         * @property {string|null} [phone] ResendCode phone
         * @property {string|null} [codeHash] ResendCode codeHash
         */

        /**
         * Constructs a new ResendCode.
         * @memberof TelegramAuthStage
         * @classdesc Represents a ResendCode.
         * @implements IResendCode
         * @constructor
         * @param {TelegramAuthStage.IResendCode=} [properties] Properties to set
         */
        function ResendCode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResendCode phone.
         * @member {string} phone
         * @memberof TelegramAuthStage.ResendCode
         * @instance
         */
        ResendCode.prototype.phone = "";

        /**
         * ResendCode codeHash.
         * @member {string} codeHash
         * @memberof TelegramAuthStage.ResendCode
         * @instance
         */
        ResendCode.prototype.codeHash = "";

        /**
         * Creates a new ResendCode instance using the specified properties.
         * @function create
         * @memberof TelegramAuthStage.ResendCode
         * @static
         * @param {TelegramAuthStage.IResendCode=} [properties] Properties to set
         * @returns {TelegramAuthStage.ResendCode} ResendCode instance
         */
        ResendCode.create = function create(properties) {
            return new ResendCode(properties);
        };

        /**
         * Encodes the specified ResendCode message. Does not implicitly {@link TelegramAuthStage.ResendCode.verify|verify} messages.
         * @function encode
         * @memberof TelegramAuthStage.ResendCode
         * @static
         * @param {TelegramAuthStage.IResendCode} message ResendCode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResendCode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phone);
            if (message.codeHash != null && Object.hasOwnProperty.call(message, "codeHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.codeHash);
            return writer;
        };

        /**
         * Encodes the specified ResendCode message, length delimited. Does not implicitly {@link TelegramAuthStage.ResendCode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof TelegramAuthStage.ResendCode
         * @static
         * @param {TelegramAuthStage.IResendCode} message ResendCode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResendCode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResendCode message from the specified reader or buffer.
         * @function decode
         * @memberof TelegramAuthStage.ResendCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {TelegramAuthStage.ResendCode} ResendCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResendCode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramAuthStage.ResendCode();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.phone = reader.string();
                        break;
                    }
                case 2: {
                        message.codeHash = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResendCode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof TelegramAuthStage.ResendCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {TelegramAuthStage.ResendCode} ResendCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResendCode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResendCode message.
         * @function verify
         * @memberof TelegramAuthStage.ResendCode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResendCode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.phone != null && message.hasOwnProperty("phone"))
                if (!$util.isString(message.phone))
                    return "phone: string expected";
            if (message.codeHash != null && message.hasOwnProperty("codeHash"))
                if (!$util.isString(message.codeHash))
                    return "codeHash: string expected";
            return null;
        };

        /**
         * Creates a ResendCode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof TelegramAuthStage.ResendCode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {TelegramAuthStage.ResendCode} ResendCode
         */
        ResendCode.fromObject = function fromObject(object) {
            if (object instanceof $root.TelegramAuthStage.ResendCode)
                return object;
            let message = new $root.TelegramAuthStage.ResendCode();
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.codeHash != null)
                message.codeHash = String(object.codeHash);
            return message;
        };

        /**
         * Creates a plain object from a ResendCode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof TelegramAuthStage.ResendCode
         * @static
         * @param {TelegramAuthStage.ResendCode} message ResendCode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResendCode.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.phone = "";
                object.codeHash = "";
            }
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.codeHash != null && message.hasOwnProperty("codeHash"))
                object.codeHash = message.codeHash;
            return object;
        };

        /**
         * Converts this ResendCode to JSON.
         * @function toJSON
         * @memberof TelegramAuthStage.ResendCode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResendCode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResendCode
         * @function getTypeUrl
         * @memberof TelegramAuthStage.ResendCode
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResendCode.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/TelegramAuthStage.ResendCode";
        };

        return ResendCode;
    })();

    TelegramAuthStage.EnterCode = (function() {

        /**
         * Properties of an EnterCode.
         * @memberof TelegramAuthStage
         * @interface IEnterCode
         * @property {string|null} [phone] EnterCode phone
         * @property {string|null} [codeHash] EnterCode codeHash
         * @property {string|null} [code] EnterCode code
         */

        /**
         * Constructs a new EnterCode.
         * @memberof TelegramAuthStage
         * @classdesc Represents an EnterCode.
         * @implements IEnterCode
         * @constructor
         * @param {TelegramAuthStage.IEnterCode=} [properties] Properties to set
         */
        function EnterCode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EnterCode phone.
         * @member {string} phone
         * @memberof TelegramAuthStage.EnterCode
         * @instance
         */
        EnterCode.prototype.phone = "";

        /**
         * EnterCode codeHash.
         * @member {string} codeHash
         * @memberof TelegramAuthStage.EnterCode
         * @instance
         */
        EnterCode.prototype.codeHash = "";

        /**
         * EnterCode code.
         * @member {string} code
         * @memberof TelegramAuthStage.EnterCode
         * @instance
         */
        EnterCode.prototype.code = "";

        /**
         * Creates a new EnterCode instance using the specified properties.
         * @function create
         * @memberof TelegramAuthStage.EnterCode
         * @static
         * @param {TelegramAuthStage.IEnterCode=} [properties] Properties to set
         * @returns {TelegramAuthStage.EnterCode} EnterCode instance
         */
        EnterCode.create = function create(properties) {
            return new EnterCode(properties);
        };

        /**
         * Encodes the specified EnterCode message. Does not implicitly {@link TelegramAuthStage.EnterCode.verify|verify} messages.
         * @function encode
         * @memberof TelegramAuthStage.EnterCode
         * @static
         * @param {TelegramAuthStage.IEnterCode} message EnterCode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterCode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phone);
            if (message.codeHash != null && Object.hasOwnProperty.call(message, "codeHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.codeHash);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.code);
            return writer;
        };

        /**
         * Encodes the specified EnterCode message, length delimited. Does not implicitly {@link TelegramAuthStage.EnterCode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof TelegramAuthStage.EnterCode
         * @static
         * @param {TelegramAuthStage.IEnterCode} message EnterCode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterCode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnterCode message from the specified reader or buffer.
         * @function decode
         * @memberof TelegramAuthStage.EnterCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {TelegramAuthStage.EnterCode} EnterCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterCode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramAuthStage.EnterCode();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.phone = reader.string();
                        break;
                    }
                case 2: {
                        message.codeHash = reader.string();
                        break;
                    }
                case 3: {
                        message.code = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnterCode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof TelegramAuthStage.EnterCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {TelegramAuthStage.EnterCode} EnterCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterCode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnterCode message.
         * @function verify
         * @memberof TelegramAuthStage.EnterCode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnterCode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.phone != null && message.hasOwnProperty("phone"))
                if (!$util.isString(message.phone))
                    return "phone: string expected";
            if (message.codeHash != null && message.hasOwnProperty("codeHash"))
                if (!$util.isString(message.codeHash))
                    return "codeHash: string expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            return null;
        };

        /**
         * Creates an EnterCode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof TelegramAuthStage.EnterCode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {TelegramAuthStage.EnterCode} EnterCode
         */
        EnterCode.fromObject = function fromObject(object) {
            if (object instanceof $root.TelegramAuthStage.EnterCode)
                return object;
            let message = new $root.TelegramAuthStage.EnterCode();
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.codeHash != null)
                message.codeHash = String(object.codeHash);
            if (object.code != null)
                message.code = String(object.code);
            return message;
        };

        /**
         * Creates a plain object from an EnterCode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof TelegramAuthStage.EnterCode
         * @static
         * @param {TelegramAuthStage.EnterCode} message EnterCode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnterCode.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.phone = "";
                object.codeHash = "";
                object.code = "";
            }
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.codeHash != null && message.hasOwnProperty("codeHash"))
                object.codeHash = message.codeHash;
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            return object;
        };

        /**
         * Converts this EnterCode to JSON.
         * @function toJSON
         * @memberof TelegramAuthStage.EnterCode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnterCode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EnterCode
         * @function getTypeUrl
         * @memberof TelegramAuthStage.EnterCode
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EnterCode.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/TelegramAuthStage.EnterCode";
        };

        return EnterCode;
    })();

    TelegramAuthStage.EnterPassword = (function() {

        /**
         * Properties of an EnterPassword.
         * @memberof TelegramAuthStage
         * @interface IEnterPassword
         * @property {string|null} [password] EnterPassword password
         */

        /**
         * Constructs a new EnterPassword.
         * @memberof TelegramAuthStage
         * @classdesc Represents an EnterPassword.
         * @implements IEnterPassword
         * @constructor
         * @param {TelegramAuthStage.IEnterPassword=} [properties] Properties to set
         */
        function EnterPassword(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EnterPassword password.
         * @member {string} password
         * @memberof TelegramAuthStage.EnterPassword
         * @instance
         */
        EnterPassword.prototype.password = "";

        /**
         * Creates a new EnterPassword instance using the specified properties.
         * @function create
         * @memberof TelegramAuthStage.EnterPassword
         * @static
         * @param {TelegramAuthStage.IEnterPassword=} [properties] Properties to set
         * @returns {TelegramAuthStage.EnterPassword} EnterPassword instance
         */
        EnterPassword.create = function create(properties) {
            return new EnterPassword(properties);
        };

        /**
         * Encodes the specified EnterPassword message. Does not implicitly {@link TelegramAuthStage.EnterPassword.verify|verify} messages.
         * @function encode
         * @memberof TelegramAuthStage.EnterPassword
         * @static
         * @param {TelegramAuthStage.IEnterPassword} message EnterPassword message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterPassword.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.password);
            return writer;
        };

        /**
         * Encodes the specified EnterPassword message, length delimited. Does not implicitly {@link TelegramAuthStage.EnterPassword.verify|verify} messages.
         * @function encodeDelimited
         * @memberof TelegramAuthStage.EnterPassword
         * @static
         * @param {TelegramAuthStage.IEnterPassword} message EnterPassword message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterPassword.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnterPassword message from the specified reader or buffer.
         * @function decode
         * @memberof TelegramAuthStage.EnterPassword
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {TelegramAuthStage.EnterPassword} EnterPassword
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterPassword.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramAuthStage.EnterPassword();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.password = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnterPassword message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof TelegramAuthStage.EnterPassword
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {TelegramAuthStage.EnterPassword} EnterPassword
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterPassword.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnterPassword message.
         * @function verify
         * @memberof TelegramAuthStage.EnterPassword
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnterPassword.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            return null;
        };

        /**
         * Creates an EnterPassword message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof TelegramAuthStage.EnterPassword
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {TelegramAuthStage.EnterPassword} EnterPassword
         */
        EnterPassword.fromObject = function fromObject(object) {
            if (object instanceof $root.TelegramAuthStage.EnterPassword)
                return object;
            let message = new $root.TelegramAuthStage.EnterPassword();
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };

        /**
         * Creates a plain object from an EnterPassword message. Also converts values to other types if specified.
         * @function toObject
         * @memberof TelegramAuthStage.EnterPassword
         * @static
         * @param {TelegramAuthStage.EnterPassword} message EnterPassword
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnterPassword.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.password = "";
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            return object;
        };

        /**
         * Converts this EnterPassword to JSON.
         * @function toJSON
         * @memberof TelegramAuthStage.EnterPassword
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnterPassword.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EnterPassword
         * @function getTypeUrl
         * @memberof TelegramAuthStage.EnterPassword
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EnterPassword.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/TelegramAuthStage.EnterPassword";
        };

        return EnterPassword;
    })();

    return TelegramAuthStage;
})();

export const TelegramAuthorization = $root.TelegramAuthorization = (() => {

    /**
     * Properties of a TelegramAuthorization.
     * @exports ITelegramAuthorization
     * @interface ITelegramAuthorization
     * @property {ITelegramAuthStage|null} [auth] TelegramAuthorization auth
     */

    /**
     * Constructs a new TelegramAuthorization.
     * @exports TelegramAuthorization
     * @classdesc Represents a TelegramAuthorization.
     * @implements ITelegramAuthorization
     * @constructor
     * @param {ITelegramAuthorization=} [properties] Properties to set
     */
    function TelegramAuthorization(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TelegramAuthorization auth.
     * @member {ITelegramAuthStage|null|undefined} auth
     * @memberof TelegramAuthorization
     * @instance
     */
    TelegramAuthorization.prototype.auth = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * TelegramAuthorization action.
     * @member {"auth"|undefined} action
     * @memberof TelegramAuthorization
     * @instance
     */
    Object.defineProperty(TelegramAuthorization.prototype, "action", {
        get: $util.oneOfGetter($oneOfFields = ["auth"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new TelegramAuthorization instance using the specified properties.
     * @function create
     * @memberof TelegramAuthorization
     * @static
     * @param {ITelegramAuthorization=} [properties] Properties to set
     * @returns {TelegramAuthorization} TelegramAuthorization instance
     */
    TelegramAuthorization.create = function create(properties) {
        return new TelegramAuthorization(properties);
    };

    /**
     * Encodes the specified TelegramAuthorization message. Does not implicitly {@link TelegramAuthorization.verify|verify} messages.
     * @function encode
     * @memberof TelegramAuthorization
     * @static
     * @param {ITelegramAuthorization} message TelegramAuthorization message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramAuthorization.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.auth != null && Object.hasOwnProperty.call(message, "auth"))
            $root.TelegramAuthStage.encode(message.auth, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified TelegramAuthorization message, length delimited. Does not implicitly {@link TelegramAuthorization.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TelegramAuthorization
     * @static
     * @param {ITelegramAuthorization} message TelegramAuthorization message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramAuthorization.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TelegramAuthorization message from the specified reader or buffer.
     * @function decode
     * @memberof TelegramAuthorization
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TelegramAuthorization} TelegramAuthorization
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramAuthorization.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramAuthorization();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.auth = $root.TelegramAuthStage.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TelegramAuthorization message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TelegramAuthorization
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TelegramAuthorization} TelegramAuthorization
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramAuthorization.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TelegramAuthorization message.
     * @function verify
     * @memberof TelegramAuthorization
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TelegramAuthorization.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.auth != null && message.hasOwnProperty("auth")) {
            properties.action = 1;
            {
                let error = $root.TelegramAuthStage.verify(message.auth);
                if (error)
                    return "auth." + error;
            }
        }
        return null;
    };

    /**
     * Creates a TelegramAuthorization message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TelegramAuthorization
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TelegramAuthorization} TelegramAuthorization
     */
    TelegramAuthorization.fromObject = function fromObject(object) {
        if (object instanceof $root.TelegramAuthorization)
            return object;
        let message = new $root.TelegramAuthorization();
        if (object.auth != null) {
            if (typeof object.auth !== "object")
                throw TypeError(".TelegramAuthorization.auth: object expected");
            message.auth = $root.TelegramAuthStage.fromObject(object.auth);
        }
        return message;
    };

    /**
     * Creates a plain object from a TelegramAuthorization message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TelegramAuthorization
     * @static
     * @param {TelegramAuthorization} message TelegramAuthorization
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TelegramAuthorization.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (message.auth != null && message.hasOwnProperty("auth")) {
            object.auth = $root.TelegramAuthStage.toObject(message.auth, options);
            if (options.oneofs)
                object.action = "auth";
        }
        return object;
    };

    /**
     * Converts this TelegramAuthorization to JSON.
     * @function toJSON
     * @memberof TelegramAuthorization
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TelegramAuthorization.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TelegramAuthorization
     * @function getTypeUrl
     * @memberof TelegramAuthorization
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TelegramAuthorization.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TelegramAuthorization";
    };

    return TelegramAuthorization;
})();

export const TelegramBotAuthorization = $root.TelegramBotAuthorization = (() => {

    /**
     * Properties of a TelegramBotAuthorization.
     * @exports ITelegramBotAuthorization
     * @interface ITelegramBotAuthorization
     * @property {string|null} [token] TelegramBotAuthorization token
     */

    /**
     * Constructs a new TelegramBotAuthorization.
     * @exports TelegramBotAuthorization
     * @classdesc Represents a TelegramBotAuthorization.
     * @implements ITelegramBotAuthorization
     * @constructor
     * @param {ITelegramBotAuthorization=} [properties] Properties to set
     */
    function TelegramBotAuthorization(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TelegramBotAuthorization token.
     * @member {string} token
     * @memberof TelegramBotAuthorization
     * @instance
     */
    TelegramBotAuthorization.prototype.token = "";

    /**
     * Creates a new TelegramBotAuthorization instance using the specified properties.
     * @function create
     * @memberof TelegramBotAuthorization
     * @static
     * @param {ITelegramBotAuthorization=} [properties] Properties to set
     * @returns {TelegramBotAuthorization} TelegramBotAuthorization instance
     */
    TelegramBotAuthorization.create = function create(properties) {
        return new TelegramBotAuthorization(properties);
    };

    /**
     * Encodes the specified TelegramBotAuthorization message. Does not implicitly {@link TelegramBotAuthorization.verify|verify} messages.
     * @function encode
     * @memberof TelegramBotAuthorization
     * @static
     * @param {ITelegramBotAuthorization} message TelegramBotAuthorization message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramBotAuthorization.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.token != null && Object.hasOwnProperty.call(message, "token"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
        return writer;
    };

    /**
     * Encodes the specified TelegramBotAuthorization message, length delimited. Does not implicitly {@link TelegramBotAuthorization.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TelegramBotAuthorization
     * @static
     * @param {ITelegramBotAuthorization} message TelegramBotAuthorization message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramBotAuthorization.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TelegramBotAuthorization message from the specified reader or buffer.
     * @function decode
     * @memberof TelegramBotAuthorization
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TelegramBotAuthorization} TelegramBotAuthorization
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramBotAuthorization.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramBotAuthorization();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.token = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TelegramBotAuthorization message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TelegramBotAuthorization
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TelegramBotAuthorization} TelegramBotAuthorization
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramBotAuthorization.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TelegramBotAuthorization message.
     * @function verify
     * @memberof TelegramBotAuthorization
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TelegramBotAuthorization.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.token != null && message.hasOwnProperty("token"))
            if (!$util.isString(message.token))
                return "token: string expected";
        return null;
    };

    /**
     * Creates a TelegramBotAuthorization message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TelegramBotAuthorization
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TelegramBotAuthorization} TelegramBotAuthorization
     */
    TelegramBotAuthorization.fromObject = function fromObject(object) {
        if (object instanceof $root.TelegramBotAuthorization)
            return object;
        let message = new $root.TelegramBotAuthorization();
        if (object.token != null)
            message.token = String(object.token);
        return message;
    };

    /**
     * Creates a plain object from a TelegramBotAuthorization message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TelegramBotAuthorization
     * @static
     * @param {TelegramBotAuthorization} message TelegramBotAuthorization
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TelegramBotAuthorization.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.token = "";
        if (message.token != null && message.hasOwnProperty("token"))
            object.token = message.token;
        return object;
    };

    /**
     * Converts this TelegramBotAuthorization to JSON.
     * @function toJSON
     * @memberof TelegramBotAuthorization
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TelegramBotAuthorization.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TelegramBotAuthorization
     * @function getTypeUrl
     * @memberof TelegramBotAuthorization
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TelegramBotAuthorization.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TelegramBotAuthorization";
    };

    return TelegramBotAuthorization;
})();

export const TelegramAuthStageResponse = $root.TelegramAuthStageResponse = (() => {

    /**
     * Properties of a TelegramAuthStageResponse.
     * @exports ITelegramAuthStageResponse
     * @interface ITelegramAuthStageResponse
     * @property {TelegramAuthStageResponse.IEnterPhone|null} [enterPhone] TelegramAuthStageResponse enterPhone
     * @property {TelegramAuthStageResponse.IResendCode|null} [resendCode] TelegramAuthStageResponse resendCode
     * @property {TelegramAuthStageResponse.IEnterCode|null} [enterCode] TelegramAuthStageResponse enterCode
     * @property {TelegramAuthStageResponse.IEnterPassword|null} [enterPassword] TelegramAuthStageResponse enterPassword
     */

    /**
     * Constructs a new TelegramAuthStageResponse.
     * @exports TelegramAuthStageResponse
     * @classdesc Represents a TelegramAuthStageResponse.
     * @implements ITelegramAuthStageResponse
     * @constructor
     * @param {ITelegramAuthStageResponse=} [properties] Properties to set
     */
    function TelegramAuthStageResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TelegramAuthStageResponse enterPhone.
     * @member {TelegramAuthStageResponse.IEnterPhone|null|undefined} enterPhone
     * @memberof TelegramAuthStageResponse
     * @instance
     */
    TelegramAuthStageResponse.prototype.enterPhone = null;

    /**
     * TelegramAuthStageResponse resendCode.
     * @member {TelegramAuthStageResponse.IResendCode|null|undefined} resendCode
     * @memberof TelegramAuthStageResponse
     * @instance
     */
    TelegramAuthStageResponse.prototype.resendCode = null;

    /**
     * TelegramAuthStageResponse enterCode.
     * @member {TelegramAuthStageResponse.IEnterCode|null|undefined} enterCode
     * @memberof TelegramAuthStageResponse
     * @instance
     */
    TelegramAuthStageResponse.prototype.enterCode = null;

    /**
     * TelegramAuthStageResponse enterPassword.
     * @member {TelegramAuthStageResponse.IEnterPassword|null|undefined} enterPassword
     * @memberof TelegramAuthStageResponse
     * @instance
     */
    TelegramAuthStageResponse.prototype.enterPassword = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * TelegramAuthStageResponse stage.
     * @member {"enterPhone"|"resendCode"|"enterCode"|"enterPassword"|undefined} stage
     * @memberof TelegramAuthStageResponse
     * @instance
     */
    Object.defineProperty(TelegramAuthStageResponse.prototype, "stage", {
        get: $util.oneOfGetter($oneOfFields = ["enterPhone", "resendCode", "enterCode", "enterPassword"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new TelegramAuthStageResponse instance using the specified properties.
     * @function create
     * @memberof TelegramAuthStageResponse
     * @static
     * @param {ITelegramAuthStageResponse=} [properties] Properties to set
     * @returns {TelegramAuthStageResponse} TelegramAuthStageResponse instance
     */
    TelegramAuthStageResponse.create = function create(properties) {
        return new TelegramAuthStageResponse(properties);
    };

    /**
     * Encodes the specified TelegramAuthStageResponse message. Does not implicitly {@link TelegramAuthStageResponse.verify|verify} messages.
     * @function encode
     * @memberof TelegramAuthStageResponse
     * @static
     * @param {ITelegramAuthStageResponse} message TelegramAuthStageResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramAuthStageResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.enterPhone != null && Object.hasOwnProperty.call(message, "enterPhone"))
            $root.TelegramAuthStageResponse.EnterPhone.encode(message.enterPhone, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.resendCode != null && Object.hasOwnProperty.call(message, "resendCode"))
            $root.TelegramAuthStageResponse.ResendCode.encode(message.resendCode, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.enterCode != null && Object.hasOwnProperty.call(message, "enterCode"))
            $root.TelegramAuthStageResponse.EnterCode.encode(message.enterCode, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.enterPassword != null && Object.hasOwnProperty.call(message, "enterPassword"))
            $root.TelegramAuthStageResponse.EnterPassword.encode(message.enterPassword, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified TelegramAuthStageResponse message, length delimited. Does not implicitly {@link TelegramAuthStageResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TelegramAuthStageResponse
     * @static
     * @param {ITelegramAuthStageResponse} message TelegramAuthStageResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramAuthStageResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TelegramAuthStageResponse message from the specified reader or buffer.
     * @function decode
     * @memberof TelegramAuthStageResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TelegramAuthStageResponse} TelegramAuthStageResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramAuthStageResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramAuthStageResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.enterPhone = $root.TelegramAuthStageResponse.EnterPhone.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.resendCode = $root.TelegramAuthStageResponse.ResendCode.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.enterCode = $root.TelegramAuthStageResponse.EnterCode.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.enterPassword = $root.TelegramAuthStageResponse.EnterPassword.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TelegramAuthStageResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TelegramAuthStageResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TelegramAuthStageResponse} TelegramAuthStageResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramAuthStageResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TelegramAuthStageResponse message.
     * @function verify
     * @memberof TelegramAuthStageResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TelegramAuthStageResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.enterPhone != null && message.hasOwnProperty("enterPhone")) {
            properties.stage = 1;
            {
                let error = $root.TelegramAuthStageResponse.EnterPhone.verify(message.enterPhone);
                if (error)
                    return "enterPhone." + error;
            }
        }
        if (message.resendCode != null && message.hasOwnProperty("resendCode")) {
            if (properties.stage === 1)
                return "stage: multiple values";
            properties.stage = 1;
            {
                let error = $root.TelegramAuthStageResponse.ResendCode.verify(message.resendCode);
                if (error)
                    return "resendCode." + error;
            }
        }
        if (message.enterCode != null && message.hasOwnProperty("enterCode")) {
            if (properties.stage === 1)
                return "stage: multiple values";
            properties.stage = 1;
            {
                let error = $root.TelegramAuthStageResponse.EnterCode.verify(message.enterCode);
                if (error)
                    return "enterCode." + error;
            }
        }
        if (message.enterPassword != null && message.hasOwnProperty("enterPassword")) {
            if (properties.stage === 1)
                return "stage: multiple values";
            properties.stage = 1;
            {
                let error = $root.TelegramAuthStageResponse.EnterPassword.verify(message.enterPassword);
                if (error)
                    return "enterPassword." + error;
            }
        }
        return null;
    };

    /**
     * Creates a TelegramAuthStageResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TelegramAuthStageResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TelegramAuthStageResponse} TelegramAuthStageResponse
     */
    TelegramAuthStageResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.TelegramAuthStageResponse)
            return object;
        let message = new $root.TelegramAuthStageResponse();
        if (object.enterPhone != null) {
            if (typeof object.enterPhone !== "object")
                throw TypeError(".TelegramAuthStageResponse.enterPhone: object expected");
            message.enterPhone = $root.TelegramAuthStageResponse.EnterPhone.fromObject(object.enterPhone);
        }
        if (object.resendCode != null) {
            if (typeof object.resendCode !== "object")
                throw TypeError(".TelegramAuthStageResponse.resendCode: object expected");
            message.resendCode = $root.TelegramAuthStageResponse.ResendCode.fromObject(object.resendCode);
        }
        if (object.enterCode != null) {
            if (typeof object.enterCode !== "object")
                throw TypeError(".TelegramAuthStageResponse.enterCode: object expected");
            message.enterCode = $root.TelegramAuthStageResponse.EnterCode.fromObject(object.enterCode);
        }
        if (object.enterPassword != null) {
            if (typeof object.enterPassword !== "object")
                throw TypeError(".TelegramAuthStageResponse.enterPassword: object expected");
            message.enterPassword = $root.TelegramAuthStageResponse.EnterPassword.fromObject(object.enterPassword);
        }
        return message;
    };

    /**
     * Creates a plain object from a TelegramAuthStageResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TelegramAuthStageResponse
     * @static
     * @param {TelegramAuthStageResponse} message TelegramAuthStageResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TelegramAuthStageResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (message.enterPhone != null && message.hasOwnProperty("enterPhone")) {
            object.enterPhone = $root.TelegramAuthStageResponse.EnterPhone.toObject(message.enterPhone, options);
            if (options.oneofs)
                object.stage = "enterPhone";
        }
        if (message.resendCode != null && message.hasOwnProperty("resendCode")) {
            object.resendCode = $root.TelegramAuthStageResponse.ResendCode.toObject(message.resendCode, options);
            if (options.oneofs)
                object.stage = "resendCode";
        }
        if (message.enterCode != null && message.hasOwnProperty("enterCode")) {
            object.enterCode = $root.TelegramAuthStageResponse.EnterCode.toObject(message.enterCode, options);
            if (options.oneofs)
                object.stage = "enterCode";
        }
        if (message.enterPassword != null && message.hasOwnProperty("enterPassword")) {
            object.enterPassword = $root.TelegramAuthStageResponse.EnterPassword.toObject(message.enterPassword, options);
            if (options.oneofs)
                object.stage = "enterPassword";
        }
        return object;
    };

    /**
     * Converts this TelegramAuthStageResponse to JSON.
     * @function toJSON
     * @memberof TelegramAuthStageResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TelegramAuthStageResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TelegramAuthStageResponse
     * @function getTypeUrl
     * @memberof TelegramAuthStageResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TelegramAuthStageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TelegramAuthStageResponse";
    };

    TelegramAuthStageResponse.EnterPhone = (function() {

        /**
         * Properties of an EnterPhone.
         * @memberof TelegramAuthStageResponse
         * @interface IEnterPhone
         * @property {TelegramAuthStageResponse.EnterPhone.Result|null} [status] EnterPhone status
         * @property {string|null} [codeHash] EnterPhone codeHash
         */

        /**
         * Constructs a new EnterPhone.
         * @memberof TelegramAuthStageResponse
         * @classdesc Represents an EnterPhone.
         * @implements IEnterPhone
         * @constructor
         * @param {TelegramAuthStageResponse.IEnterPhone=} [properties] Properties to set
         */
        function EnterPhone(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EnterPhone status.
         * @member {TelegramAuthStageResponse.EnterPhone.Result} status
         * @memberof TelegramAuthStageResponse.EnterPhone
         * @instance
         */
        EnterPhone.prototype.status = 0;

        /**
         * EnterPhone codeHash.
         * @member {string} codeHash
         * @memberof TelegramAuthStageResponse.EnterPhone
         * @instance
         */
        EnterPhone.prototype.codeHash = "";

        /**
         * Creates a new EnterPhone instance using the specified properties.
         * @function create
         * @memberof TelegramAuthStageResponse.EnterPhone
         * @static
         * @param {TelegramAuthStageResponse.IEnterPhone=} [properties] Properties to set
         * @returns {TelegramAuthStageResponse.EnterPhone} EnterPhone instance
         */
        EnterPhone.create = function create(properties) {
            return new EnterPhone(properties);
        };

        /**
         * Encodes the specified EnterPhone message. Does not implicitly {@link TelegramAuthStageResponse.EnterPhone.verify|verify} messages.
         * @function encode
         * @memberof TelegramAuthStageResponse.EnterPhone
         * @static
         * @param {TelegramAuthStageResponse.IEnterPhone} message EnterPhone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterPhone.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.codeHash != null && Object.hasOwnProperty.call(message, "codeHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.codeHash);
            return writer;
        };

        /**
         * Encodes the specified EnterPhone message, length delimited. Does not implicitly {@link TelegramAuthStageResponse.EnterPhone.verify|verify} messages.
         * @function encodeDelimited
         * @memberof TelegramAuthStageResponse.EnterPhone
         * @static
         * @param {TelegramAuthStageResponse.IEnterPhone} message EnterPhone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterPhone.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnterPhone message from the specified reader or buffer.
         * @function decode
         * @memberof TelegramAuthStageResponse.EnterPhone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {TelegramAuthStageResponse.EnterPhone} EnterPhone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterPhone.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramAuthStageResponse.EnterPhone();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.codeHash = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnterPhone message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof TelegramAuthStageResponse.EnterPhone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {TelegramAuthStageResponse.EnterPhone} EnterPhone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterPhone.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnterPhone message.
         * @function verify
         * @memberof TelegramAuthStageResponse.EnterPhone
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnterPhone.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.codeHash != null && message.hasOwnProperty("codeHash"))
                if (!$util.isString(message.codeHash))
                    return "codeHash: string expected";
            return null;
        };

        /**
         * Creates an EnterPhone message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof TelegramAuthStageResponse.EnterPhone
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {TelegramAuthStageResponse.EnterPhone} EnterPhone
         */
        EnterPhone.fromObject = function fromObject(object) {
            if (object instanceof $root.TelegramAuthStageResponse.EnterPhone)
                return object;
            let message = new $root.TelegramAuthStageResponse.EnterPhone();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "CODE_SENT":
            case 1:
                message.status = 1;
                break;
            case "PHONE_INCORRECT":
            case 2:
                message.status = 2;
                break;
            case "PHONE_BANNED":
            case 3:
                message.status = 3;
                break;
            case "PHONE_RATE_LIMIT":
            case 4:
                message.status = 4;
                break;
            }
            if (object.codeHash != null)
                message.codeHash = String(object.codeHash);
            return message;
        };

        /**
         * Creates a plain object from an EnterPhone message. Also converts values to other types if specified.
         * @function toObject
         * @memberof TelegramAuthStageResponse.EnterPhone
         * @static
         * @param {TelegramAuthStageResponse.EnterPhone} message EnterPhone
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnterPhone.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "UNKNOWN" : 0;
                object.codeHash = "";
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.TelegramAuthStageResponse.EnterPhone.Result[message.status] === undefined ? message.status : $root.TelegramAuthStageResponse.EnterPhone.Result[message.status] : message.status;
            if (message.codeHash != null && message.hasOwnProperty("codeHash"))
                object.codeHash = message.codeHash;
            return object;
        };

        /**
         * Converts this EnterPhone to JSON.
         * @function toJSON
         * @memberof TelegramAuthStageResponse.EnterPhone
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnterPhone.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EnterPhone
         * @function getTypeUrl
         * @memberof TelegramAuthStageResponse.EnterPhone
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EnterPhone.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/TelegramAuthStageResponse.EnterPhone";
        };

        /**
         * Result enum.
         * @name TelegramAuthStageResponse.EnterPhone.Result
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} CODE_SENT=1 CODE_SENT value
         * @property {number} PHONE_INCORRECT=2 PHONE_INCORRECT value
         * @property {number} PHONE_BANNED=3 PHONE_BANNED value
         * @property {number} PHONE_RATE_LIMIT=4 PHONE_RATE_LIMIT value
         */
        EnterPhone.Result = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "CODE_SENT"] = 1;
            values[valuesById[2] = "PHONE_INCORRECT"] = 2;
            values[valuesById[3] = "PHONE_BANNED"] = 3;
            values[valuesById[4] = "PHONE_RATE_LIMIT"] = 4;
            return values;
        })();

        return EnterPhone;
    })();

    TelegramAuthStageResponse.ResendCode = (function() {

        /**
         * Properties of a ResendCode.
         * @memberof TelegramAuthStageResponse
         * @interface IResendCode
         * @property {TelegramAuthStageResponse.ResendCode.Result|null} [status] ResendCode status
         */

        /**
         * Constructs a new ResendCode.
         * @memberof TelegramAuthStageResponse
         * @classdesc Represents a ResendCode.
         * @implements IResendCode
         * @constructor
         * @param {TelegramAuthStageResponse.IResendCode=} [properties] Properties to set
         */
        function ResendCode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResendCode status.
         * @member {TelegramAuthStageResponse.ResendCode.Result} status
         * @memberof TelegramAuthStageResponse.ResendCode
         * @instance
         */
        ResendCode.prototype.status = 0;

        /**
         * Creates a new ResendCode instance using the specified properties.
         * @function create
         * @memberof TelegramAuthStageResponse.ResendCode
         * @static
         * @param {TelegramAuthStageResponse.IResendCode=} [properties] Properties to set
         * @returns {TelegramAuthStageResponse.ResendCode} ResendCode instance
         */
        ResendCode.create = function create(properties) {
            return new ResendCode(properties);
        };

        /**
         * Encodes the specified ResendCode message. Does not implicitly {@link TelegramAuthStageResponse.ResendCode.verify|verify} messages.
         * @function encode
         * @memberof TelegramAuthStageResponse.ResendCode
         * @static
         * @param {TelegramAuthStageResponse.IResendCode} message ResendCode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResendCode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified ResendCode message, length delimited. Does not implicitly {@link TelegramAuthStageResponse.ResendCode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof TelegramAuthStageResponse.ResendCode
         * @static
         * @param {TelegramAuthStageResponse.IResendCode} message ResendCode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResendCode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResendCode message from the specified reader or buffer.
         * @function decode
         * @memberof TelegramAuthStageResponse.ResendCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {TelegramAuthStageResponse.ResendCode} ResendCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResendCode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramAuthStageResponse.ResendCode();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResendCode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof TelegramAuthStageResponse.ResendCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {TelegramAuthStageResponse.ResendCode} ResendCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResendCode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResendCode message.
         * @function verify
         * @memberof TelegramAuthStageResponse.ResendCode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResendCode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResendCode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof TelegramAuthStageResponse.ResendCode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {TelegramAuthStageResponse.ResendCode} ResendCode
         */
        ResendCode.fromObject = function fromObject(object) {
            if (object instanceof $root.TelegramAuthStageResponse.ResendCode)
                return object;
            let message = new $root.TelegramAuthStageResponse.ResendCode();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "CODE_RESENT":
            case 1:
                message.status = 1;
                break;
            case "CODE_RATE_LIMIT":
            case 2:
                message.status = 2;
                break;
            case "CODE_HASH_INVALID":
            case 3:
                message.status = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResendCode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof TelegramAuthStageResponse.ResendCode
         * @static
         * @param {TelegramAuthStageResponse.ResendCode} message ResendCode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResendCode.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.status = options.enums === String ? "UNKNOWN" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.TelegramAuthStageResponse.ResendCode.Result[message.status] === undefined ? message.status : $root.TelegramAuthStageResponse.ResendCode.Result[message.status] : message.status;
            return object;
        };

        /**
         * Converts this ResendCode to JSON.
         * @function toJSON
         * @memberof TelegramAuthStageResponse.ResendCode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResendCode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResendCode
         * @function getTypeUrl
         * @memberof TelegramAuthStageResponse.ResendCode
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResendCode.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/TelegramAuthStageResponse.ResendCode";
        };

        /**
         * Result enum.
         * @name TelegramAuthStageResponse.ResendCode.Result
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} CODE_RESENT=1 CODE_RESENT value
         * @property {number} CODE_RATE_LIMIT=2 CODE_RATE_LIMIT value
         * @property {number} CODE_HASH_INVALID=3 CODE_HASH_INVALID value
         */
        ResendCode.Result = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "CODE_RESENT"] = 1;
            values[valuesById[2] = "CODE_RATE_LIMIT"] = 2;
            values[valuesById[3] = "CODE_HASH_INVALID"] = 3;
            return values;
        })();

        return ResendCode;
    })();

    TelegramAuthStageResponse.EnterCode = (function() {

        /**
         * Properties of an EnterCode.
         * @memberof TelegramAuthStageResponse
         * @interface IEnterCode
         * @property {TelegramAuthStageResponse.EnterCode.Result|null} [status] EnterCode status
         */

        /**
         * Constructs a new EnterCode.
         * @memberof TelegramAuthStageResponse
         * @classdesc Represents an EnterCode.
         * @implements IEnterCode
         * @constructor
         * @param {TelegramAuthStageResponse.IEnterCode=} [properties] Properties to set
         */
        function EnterCode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EnterCode status.
         * @member {TelegramAuthStageResponse.EnterCode.Result} status
         * @memberof TelegramAuthStageResponse.EnterCode
         * @instance
         */
        EnterCode.prototype.status = 0;

        /**
         * Creates a new EnterCode instance using the specified properties.
         * @function create
         * @memberof TelegramAuthStageResponse.EnterCode
         * @static
         * @param {TelegramAuthStageResponse.IEnterCode=} [properties] Properties to set
         * @returns {TelegramAuthStageResponse.EnterCode} EnterCode instance
         */
        EnterCode.create = function create(properties) {
            return new EnterCode(properties);
        };

        /**
         * Encodes the specified EnterCode message. Does not implicitly {@link TelegramAuthStageResponse.EnterCode.verify|verify} messages.
         * @function encode
         * @memberof TelegramAuthStageResponse.EnterCode
         * @static
         * @param {TelegramAuthStageResponse.IEnterCode} message EnterCode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterCode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified EnterCode message, length delimited. Does not implicitly {@link TelegramAuthStageResponse.EnterCode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof TelegramAuthStageResponse.EnterCode
         * @static
         * @param {TelegramAuthStageResponse.IEnterCode} message EnterCode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterCode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnterCode message from the specified reader or buffer.
         * @function decode
         * @memberof TelegramAuthStageResponse.EnterCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {TelegramAuthStageResponse.EnterCode} EnterCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterCode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramAuthStageResponse.EnterCode();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnterCode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof TelegramAuthStageResponse.EnterCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {TelegramAuthStageResponse.EnterCode} EnterCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterCode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnterCode message.
         * @function verify
         * @memberof TelegramAuthStageResponse.EnterCode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnterCode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                    break;
                }
            return null;
        };

        /**
         * Creates an EnterCode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof TelegramAuthStageResponse.EnterCode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {TelegramAuthStageResponse.EnterCode} EnterCode
         */
        EnterCode.fromObject = function fromObject(object) {
            if (object instanceof $root.TelegramAuthStageResponse.EnterCode)
                return object;
            let message = new $root.TelegramAuthStageResponse.EnterCode();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "AUTH_SUCCESS":
            case 1:
                message.status = 1;
                break;
            case "PASSWORD_REQUIRED":
            case 2:
                message.status = 2;
                break;
            case "PHONE_CODE_EMPTY":
            case 3:
                message.status = 3;
                break;
            case "PHONE_CODE_EXPIRED":
            case 4:
                message.status = 4;
                break;
            case "PHONE_CODE_INVALID":
            case 5:
                message.status = 5;
                break;
            case "PHONE_NUMBER_INVALID":
            case 6:
                message.status = 6;
                break;
            case "PHONE_NUMBER_UNOCCUPIED":
            case 7:
                message.status = 7;
                break;
            case "SIGN_IN_FAILED":
            case 8:
                message.status = 8;
                break;
            case "SIGN_UP_REQUIRED":
            case 9:
                message.status = 9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an EnterCode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof TelegramAuthStageResponse.EnterCode
         * @static
         * @param {TelegramAuthStageResponse.EnterCode} message EnterCode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnterCode.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.status = options.enums === String ? "UNKNOWN" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.TelegramAuthStageResponse.EnterCode.Result[message.status] === undefined ? message.status : $root.TelegramAuthStageResponse.EnterCode.Result[message.status] : message.status;
            return object;
        };

        /**
         * Converts this EnterCode to JSON.
         * @function toJSON
         * @memberof TelegramAuthStageResponse.EnterCode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnterCode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EnterCode
         * @function getTypeUrl
         * @memberof TelegramAuthStageResponse.EnterCode
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EnterCode.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/TelegramAuthStageResponse.EnterCode";
        };

        /**
         * Result enum.
         * @name TelegramAuthStageResponse.EnterCode.Result
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} AUTH_SUCCESS=1 AUTH_SUCCESS value
         * @property {number} PASSWORD_REQUIRED=2 PASSWORD_REQUIRED value
         * @property {number} PHONE_CODE_EMPTY=3 PHONE_CODE_EMPTY value
         * @property {number} PHONE_CODE_EXPIRED=4 PHONE_CODE_EXPIRED value
         * @property {number} PHONE_CODE_INVALID=5 PHONE_CODE_INVALID value
         * @property {number} PHONE_NUMBER_INVALID=6 PHONE_NUMBER_INVALID value
         * @property {number} PHONE_NUMBER_UNOCCUPIED=7 PHONE_NUMBER_UNOCCUPIED value
         * @property {number} SIGN_IN_FAILED=8 SIGN_IN_FAILED value
         * @property {number} SIGN_UP_REQUIRED=9 SIGN_UP_REQUIRED value
         */
        EnterCode.Result = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "AUTH_SUCCESS"] = 1;
            values[valuesById[2] = "PASSWORD_REQUIRED"] = 2;
            values[valuesById[3] = "PHONE_CODE_EMPTY"] = 3;
            values[valuesById[4] = "PHONE_CODE_EXPIRED"] = 4;
            values[valuesById[5] = "PHONE_CODE_INVALID"] = 5;
            values[valuesById[6] = "PHONE_NUMBER_INVALID"] = 6;
            values[valuesById[7] = "PHONE_NUMBER_UNOCCUPIED"] = 7;
            values[valuesById[8] = "SIGN_IN_FAILED"] = 8;
            values[valuesById[9] = "SIGN_UP_REQUIRED"] = 9;
            return values;
        })();

        return EnterCode;
    })();

    TelegramAuthStageResponse.EnterPassword = (function() {

        /**
         * Properties of an EnterPassword.
         * @memberof TelegramAuthStageResponse
         * @interface IEnterPassword
         * @property {TelegramAuthStageResponse.EnterPassword.Result|null} [status] EnterPassword status
         */

        /**
         * Constructs a new EnterPassword.
         * @memberof TelegramAuthStageResponse
         * @classdesc Represents an EnterPassword.
         * @implements IEnterPassword
         * @constructor
         * @param {TelegramAuthStageResponse.IEnterPassword=} [properties] Properties to set
         */
        function EnterPassword(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EnterPassword status.
         * @member {TelegramAuthStageResponse.EnterPassword.Result} status
         * @memberof TelegramAuthStageResponse.EnterPassword
         * @instance
         */
        EnterPassword.prototype.status = 0;

        /**
         * Creates a new EnterPassword instance using the specified properties.
         * @function create
         * @memberof TelegramAuthStageResponse.EnterPassword
         * @static
         * @param {TelegramAuthStageResponse.IEnterPassword=} [properties] Properties to set
         * @returns {TelegramAuthStageResponse.EnterPassword} EnterPassword instance
         */
        EnterPassword.create = function create(properties) {
            return new EnterPassword(properties);
        };

        /**
         * Encodes the specified EnterPassword message. Does not implicitly {@link TelegramAuthStageResponse.EnterPassword.verify|verify} messages.
         * @function encode
         * @memberof TelegramAuthStageResponse.EnterPassword
         * @static
         * @param {TelegramAuthStageResponse.IEnterPassword} message EnterPassword message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterPassword.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified EnterPassword message, length delimited. Does not implicitly {@link TelegramAuthStageResponse.EnterPassword.verify|verify} messages.
         * @function encodeDelimited
         * @memberof TelegramAuthStageResponse.EnterPassword
         * @static
         * @param {TelegramAuthStageResponse.IEnterPassword} message EnterPassword message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterPassword.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnterPassword message from the specified reader or buffer.
         * @function decode
         * @memberof TelegramAuthStageResponse.EnterPassword
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {TelegramAuthStageResponse.EnterPassword} EnterPassword
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterPassword.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramAuthStageResponse.EnterPassword();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnterPassword message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof TelegramAuthStageResponse.EnterPassword
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {TelegramAuthStageResponse.EnterPassword} EnterPassword
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterPassword.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnterPassword message.
         * @function verify
         * @memberof TelegramAuthStageResponse.EnterPassword
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnterPassword.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            return null;
        };

        /**
         * Creates an EnterPassword message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof TelegramAuthStageResponse.EnterPassword
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {TelegramAuthStageResponse.EnterPassword} EnterPassword
         */
        EnterPassword.fromObject = function fromObject(object) {
            if (object instanceof $root.TelegramAuthStageResponse.EnterPassword)
                return object;
            let message = new $root.TelegramAuthStageResponse.EnterPassword();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "AUTH_SUCCESS":
            case 1:
                message.status = 1;
                break;
            case "WRONG_PASSWORD":
            case 2:
                message.status = 2;
                break;
            case "RETRY_LOGIN":
            case 3:
                message.status = 3;
                break;
            case "SIGN_UP_REQUIRED":
            case 4:
                message.status = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an EnterPassword message. Also converts values to other types if specified.
         * @function toObject
         * @memberof TelegramAuthStageResponse.EnterPassword
         * @static
         * @param {TelegramAuthStageResponse.EnterPassword} message EnterPassword
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnterPassword.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.status = options.enums === String ? "UNKNOWN" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.TelegramAuthStageResponse.EnterPassword.Result[message.status] === undefined ? message.status : $root.TelegramAuthStageResponse.EnterPassword.Result[message.status] : message.status;
            return object;
        };

        /**
         * Converts this EnterPassword to JSON.
         * @function toJSON
         * @memberof TelegramAuthStageResponse.EnterPassword
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnterPassword.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EnterPassword
         * @function getTypeUrl
         * @memberof TelegramAuthStageResponse.EnterPassword
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EnterPassword.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/TelegramAuthStageResponse.EnterPassword";
        };

        /**
         * Result enum.
         * @name TelegramAuthStageResponse.EnterPassword.Result
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} AUTH_SUCCESS=1 AUTH_SUCCESS value
         * @property {number} WRONG_PASSWORD=2 WRONG_PASSWORD value
         * @property {number} RETRY_LOGIN=3 RETRY_LOGIN value
         * @property {number} SIGN_UP_REQUIRED=4 SIGN_UP_REQUIRED value
         */
        EnterPassword.Result = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "AUTH_SUCCESS"] = 1;
            values[valuesById[2] = "WRONG_PASSWORD"] = 2;
            values[valuesById[3] = "RETRY_LOGIN"] = 3;
            values[valuesById[4] = "SIGN_UP_REQUIRED"] = 4;
            return values;
        })();

        return EnterPassword;
    })();

    return TelegramAuthStageResponse;
})();

export const TelegramAuthorizationResponse = $root.TelegramAuthorizationResponse = (() => {

    /**
     * Properties of a TelegramAuthorizationResponse.
     * @exports ITelegramAuthorizationResponse
     * @interface ITelegramAuthorizationResponse
     * @property {ITelegramAuthStageResponse|null} [auth] TelegramAuthorizationResponse auth
     */

    /**
     * Constructs a new TelegramAuthorizationResponse.
     * @exports TelegramAuthorizationResponse
     * @classdesc Represents a TelegramAuthorizationResponse.
     * @implements ITelegramAuthorizationResponse
     * @constructor
     * @param {ITelegramAuthorizationResponse=} [properties] Properties to set
     */
    function TelegramAuthorizationResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TelegramAuthorizationResponse auth.
     * @member {ITelegramAuthStageResponse|null|undefined} auth
     * @memberof TelegramAuthorizationResponse
     * @instance
     */
    TelegramAuthorizationResponse.prototype.auth = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * TelegramAuthorizationResponse action.
     * @member {"auth"|undefined} action
     * @memberof TelegramAuthorizationResponse
     * @instance
     */
    Object.defineProperty(TelegramAuthorizationResponse.prototype, "action", {
        get: $util.oneOfGetter($oneOfFields = ["auth"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new TelegramAuthorizationResponse instance using the specified properties.
     * @function create
     * @memberof TelegramAuthorizationResponse
     * @static
     * @param {ITelegramAuthorizationResponse=} [properties] Properties to set
     * @returns {TelegramAuthorizationResponse} TelegramAuthorizationResponse instance
     */
    TelegramAuthorizationResponse.create = function create(properties) {
        return new TelegramAuthorizationResponse(properties);
    };

    /**
     * Encodes the specified TelegramAuthorizationResponse message. Does not implicitly {@link TelegramAuthorizationResponse.verify|verify} messages.
     * @function encode
     * @memberof TelegramAuthorizationResponse
     * @static
     * @param {ITelegramAuthorizationResponse} message TelegramAuthorizationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramAuthorizationResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.auth != null && Object.hasOwnProperty.call(message, "auth"))
            $root.TelegramAuthStageResponse.encode(message.auth, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified TelegramAuthorizationResponse message, length delimited. Does not implicitly {@link TelegramAuthorizationResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TelegramAuthorizationResponse
     * @static
     * @param {ITelegramAuthorizationResponse} message TelegramAuthorizationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramAuthorizationResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TelegramAuthorizationResponse message from the specified reader or buffer.
     * @function decode
     * @memberof TelegramAuthorizationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TelegramAuthorizationResponse} TelegramAuthorizationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramAuthorizationResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramAuthorizationResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.auth = $root.TelegramAuthStageResponse.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TelegramAuthorizationResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TelegramAuthorizationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TelegramAuthorizationResponse} TelegramAuthorizationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramAuthorizationResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TelegramAuthorizationResponse message.
     * @function verify
     * @memberof TelegramAuthorizationResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TelegramAuthorizationResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.auth != null && message.hasOwnProperty("auth")) {
            properties.action = 1;
            {
                let error = $root.TelegramAuthStageResponse.verify(message.auth);
                if (error)
                    return "auth." + error;
            }
        }
        return null;
    };

    /**
     * Creates a TelegramAuthorizationResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TelegramAuthorizationResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TelegramAuthorizationResponse} TelegramAuthorizationResponse
     */
    TelegramAuthorizationResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.TelegramAuthorizationResponse)
            return object;
        let message = new $root.TelegramAuthorizationResponse();
        if (object.auth != null) {
            if (typeof object.auth !== "object")
                throw TypeError(".TelegramAuthorizationResponse.auth: object expected");
            message.auth = $root.TelegramAuthStageResponse.fromObject(object.auth);
        }
        return message;
    };

    /**
     * Creates a plain object from a TelegramAuthorizationResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TelegramAuthorizationResponse
     * @static
     * @param {TelegramAuthorizationResponse} message TelegramAuthorizationResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TelegramAuthorizationResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (message.auth != null && message.hasOwnProperty("auth")) {
            object.auth = $root.TelegramAuthStageResponse.toObject(message.auth, options);
            if (options.oneofs)
                object.action = "auth";
        }
        return object;
    };

    /**
     * Converts this TelegramAuthorizationResponse to JSON.
     * @function toJSON
     * @memberof TelegramAuthorizationResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TelegramAuthorizationResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TelegramAuthorizationResponse
     * @function getTypeUrl
     * @memberof TelegramAuthorizationResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TelegramAuthorizationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TelegramAuthorizationResponse";
    };

    return TelegramAuthorizationResponse;
})();

export const TelegramBotAuthorizationResponse = $root.TelegramBotAuthorizationResponse = (() => {

    /**
     * Properties of a TelegramBotAuthorizationResponse.
     * @exports ITelegramBotAuthorizationResponse
     * @interface ITelegramBotAuthorizationResponse
     * @property {boolean|null} [success] TelegramBotAuthorizationResponse success
     * @property {Uint8Array|null} [channelID] TelegramBotAuthorizationResponse channelID
     */

    /**
     * Constructs a new TelegramBotAuthorizationResponse.
     * @exports TelegramBotAuthorizationResponse
     * @classdesc Represents a TelegramBotAuthorizationResponse.
     * @implements ITelegramBotAuthorizationResponse
     * @constructor
     * @param {ITelegramBotAuthorizationResponse=} [properties] Properties to set
     */
    function TelegramBotAuthorizationResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TelegramBotAuthorizationResponse success.
     * @member {boolean} success
     * @memberof TelegramBotAuthorizationResponse
     * @instance
     */
    TelegramBotAuthorizationResponse.prototype.success = false;

    /**
     * TelegramBotAuthorizationResponse channelID.
     * @member {Uint8Array} channelID
     * @memberof TelegramBotAuthorizationResponse
     * @instance
     */
    TelegramBotAuthorizationResponse.prototype.channelID = $util.newBuffer([]);

    /**
     * Creates a new TelegramBotAuthorizationResponse instance using the specified properties.
     * @function create
     * @memberof TelegramBotAuthorizationResponse
     * @static
     * @param {ITelegramBotAuthorizationResponse=} [properties] Properties to set
     * @returns {TelegramBotAuthorizationResponse} TelegramBotAuthorizationResponse instance
     */
    TelegramBotAuthorizationResponse.create = function create(properties) {
        return new TelegramBotAuthorizationResponse(properties);
    };

    /**
     * Encodes the specified TelegramBotAuthorizationResponse message. Does not implicitly {@link TelegramBotAuthorizationResponse.verify|verify} messages.
     * @function encode
     * @memberof TelegramBotAuthorizationResponse
     * @static
     * @param {ITelegramBotAuthorizationResponse} message TelegramBotAuthorizationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramBotAuthorizationResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.success != null && Object.hasOwnProperty.call(message, "success"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
        if (message.channelID != null && Object.hasOwnProperty.call(message, "channelID"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.channelID);
        return writer;
    };

    /**
     * Encodes the specified TelegramBotAuthorizationResponse message, length delimited. Does not implicitly {@link TelegramBotAuthorizationResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TelegramBotAuthorizationResponse
     * @static
     * @param {ITelegramBotAuthorizationResponse} message TelegramBotAuthorizationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TelegramBotAuthorizationResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TelegramBotAuthorizationResponse message from the specified reader or buffer.
     * @function decode
     * @memberof TelegramBotAuthorizationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TelegramBotAuthorizationResponse} TelegramBotAuthorizationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramBotAuthorizationResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TelegramBotAuthorizationResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.success = reader.bool();
                    break;
                }
            case 2: {
                    message.channelID = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TelegramBotAuthorizationResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TelegramBotAuthorizationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TelegramBotAuthorizationResponse} TelegramBotAuthorizationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TelegramBotAuthorizationResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TelegramBotAuthorizationResponse message.
     * @function verify
     * @memberof TelegramBotAuthorizationResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TelegramBotAuthorizationResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.success != null && message.hasOwnProperty("success"))
            if (typeof message.success !== "boolean")
                return "success: boolean expected";
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            if (!(message.channelID && typeof message.channelID.length === "number" || $util.isString(message.channelID)))
                return "channelID: buffer expected";
        return null;
    };

    /**
     * Creates a TelegramBotAuthorizationResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TelegramBotAuthorizationResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TelegramBotAuthorizationResponse} TelegramBotAuthorizationResponse
     */
    TelegramBotAuthorizationResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.TelegramBotAuthorizationResponse)
            return object;
        let message = new $root.TelegramBotAuthorizationResponse();
        if (object.success != null)
            message.success = Boolean(object.success);
        if (object.channelID != null)
            if (typeof object.channelID === "string")
                $util.base64.decode(object.channelID, message.channelID = $util.newBuffer($util.base64.length(object.channelID)), 0);
            else if (object.channelID.length >= 0)
                message.channelID = object.channelID;
        return message;
    };

    /**
     * Creates a plain object from a TelegramBotAuthorizationResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TelegramBotAuthorizationResponse
     * @static
     * @param {TelegramBotAuthorizationResponse} message TelegramBotAuthorizationResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TelegramBotAuthorizationResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.success = false;
            if (options.bytes === String)
                object.channelID = "";
            else {
                object.channelID = [];
                if (options.bytes !== Array)
                    object.channelID = $util.newBuffer(object.channelID);
            }
        }
        if (message.success != null && message.hasOwnProperty("success"))
            object.success = message.success;
        if (message.channelID != null && message.hasOwnProperty("channelID"))
            object.channelID = options.bytes === String ? $util.base64.encode(message.channelID, 0, message.channelID.length) : options.bytes === Array ? Array.prototype.slice.call(message.channelID) : message.channelID;
        return object;
    };

    /**
     * Converts this TelegramBotAuthorizationResponse to JSON.
     * @function toJSON
     * @memberof TelegramBotAuthorizationResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TelegramBotAuthorizationResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TelegramBotAuthorizationResponse
     * @function getTypeUrl
     * @memberof TelegramBotAuthorizationResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TelegramBotAuthorizationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TelegramBotAuthorizationResponse";
    };

    return TelegramBotAuthorizationResponse;
})();

export const WebWidgetCreateResponse = $root.WebWidgetCreateResponse = (() => {

    /**
     * Properties of a WebWidgetCreateResponse.
     * @exports IWebWidgetCreateResponse
     * @interface IWebWidgetCreateResponse
     * @property {WebWidgetCreateResponse.Result|null} [result] WebWidgetCreateResponse result
     * @property {string|null} [APICode] WebWidgetCreateResponse APICode
     */

    /**
     * Constructs a new WebWidgetCreateResponse.
     * @exports WebWidgetCreateResponse
     * @classdesc Represents a WebWidgetCreateResponse.
     * @implements IWebWidgetCreateResponse
     * @constructor
     * @param {IWebWidgetCreateResponse=} [properties] Properties to set
     */
    function WebWidgetCreateResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WebWidgetCreateResponse result.
     * @member {WebWidgetCreateResponse.Result} result
     * @memberof WebWidgetCreateResponse
     * @instance
     */
    WebWidgetCreateResponse.prototype.result = 0;

    /**
     * WebWidgetCreateResponse APICode.
     * @member {string} APICode
     * @memberof WebWidgetCreateResponse
     * @instance
     */
    WebWidgetCreateResponse.prototype.APICode = "";

    /**
     * Creates a new WebWidgetCreateResponse instance using the specified properties.
     * @function create
     * @memberof WebWidgetCreateResponse
     * @static
     * @param {IWebWidgetCreateResponse=} [properties] Properties to set
     * @returns {WebWidgetCreateResponse} WebWidgetCreateResponse instance
     */
    WebWidgetCreateResponse.create = function create(properties) {
        return new WebWidgetCreateResponse(properties);
    };

    /**
     * Encodes the specified WebWidgetCreateResponse message. Does not implicitly {@link WebWidgetCreateResponse.verify|verify} messages.
     * @function encode
     * @memberof WebWidgetCreateResponse
     * @static
     * @param {IWebWidgetCreateResponse} message WebWidgetCreateResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebWidgetCreateResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.APICode != null && Object.hasOwnProperty.call(message, "APICode"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.APICode);
        return writer;
    };

    /**
     * Encodes the specified WebWidgetCreateResponse message, length delimited. Does not implicitly {@link WebWidgetCreateResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof WebWidgetCreateResponse
     * @static
     * @param {IWebWidgetCreateResponse} message WebWidgetCreateResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebWidgetCreateResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WebWidgetCreateResponse message from the specified reader or buffer.
     * @function decode
     * @memberof WebWidgetCreateResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WebWidgetCreateResponse} WebWidgetCreateResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebWidgetCreateResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WebWidgetCreateResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.int32();
                    break;
                }
            case 2: {
                    message.APICode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a WebWidgetCreateResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof WebWidgetCreateResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {WebWidgetCreateResponse} WebWidgetCreateResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebWidgetCreateResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WebWidgetCreateResponse message.
     * @function verify
     * @memberof WebWidgetCreateResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WebWidgetCreateResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.APICode != null && message.hasOwnProperty("APICode"))
            if (!$util.isString(message.APICode))
                return "APICode: string expected";
        return null;
    };

    /**
     * Creates a WebWidgetCreateResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof WebWidgetCreateResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {WebWidgetCreateResponse} WebWidgetCreateResponse
     */
    WebWidgetCreateResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.WebWidgetCreateResponse)
            return object;
        let message = new $root.WebWidgetCreateResponse();
        switch (object.result) {
        default:
            if (typeof object.result === "number") {
                message.result = object.result;
                break;
            }
            break;
        case "UNKNOWN":
        case 0:
            message.result = 0;
            break;
        case "SUCCESS":
        case 1:
            message.result = 1;
            break;
        case "INCORRECT_PROPS":
        case 2:
            message.result = 2;
            break;
        }
        if (object.APICode != null)
            message.APICode = String(object.APICode);
        return message;
    };

    /**
     * Creates a plain object from a WebWidgetCreateResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof WebWidgetCreateResponse
     * @static
     * @param {WebWidgetCreateResponse} message WebWidgetCreateResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WebWidgetCreateResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = options.enums === String ? "UNKNOWN" : 0;
            object.APICode = "";
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.enums === String ? $root.WebWidgetCreateResponse.Result[message.result] === undefined ? message.result : $root.WebWidgetCreateResponse.Result[message.result] : message.result;
        if (message.APICode != null && message.hasOwnProperty("APICode"))
            object.APICode = message.APICode;
        return object;
    };

    /**
     * Converts this WebWidgetCreateResponse to JSON.
     * @function toJSON
     * @memberof WebWidgetCreateResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WebWidgetCreateResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WebWidgetCreateResponse
     * @function getTypeUrl
     * @memberof WebWidgetCreateResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WebWidgetCreateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WebWidgetCreateResponse";
    };

    /**
     * Result enum.
     * @name WebWidgetCreateResponse.Result
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} SUCCESS=1 SUCCESS value
     * @property {number} INCORRECT_PROPS=2 INCORRECT_PROPS value
     */
    WebWidgetCreateResponse.Result = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "SUCCESS"] = 1;
        values[valuesById[2] = "INCORRECT_PROPS"] = 2;
        return values;
    })();

    return WebWidgetCreateResponse;
})();

export const InstagramAuthorization = $root.InstagramAuthorization = (() => {

    /**
     * Properties of an InstagramAuthorization.
     * @exports IInstagramAuthorization
     * @interface IInstagramAuthorization
     * @property {string|null} [callbackCode] InstagramAuthorization callbackCode
     */

    /**
     * Constructs a new InstagramAuthorization.
     * @exports InstagramAuthorization
     * @classdesc Represents an InstagramAuthorization.
     * @implements IInstagramAuthorization
     * @constructor
     * @param {IInstagramAuthorization=} [properties] Properties to set
     */
    function InstagramAuthorization(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * InstagramAuthorization callbackCode.
     * @member {string} callbackCode
     * @memberof InstagramAuthorization
     * @instance
     */
    InstagramAuthorization.prototype.callbackCode = "";

    /**
     * Creates a new InstagramAuthorization instance using the specified properties.
     * @function create
     * @memberof InstagramAuthorization
     * @static
     * @param {IInstagramAuthorization=} [properties] Properties to set
     * @returns {InstagramAuthorization} InstagramAuthorization instance
     */
    InstagramAuthorization.create = function create(properties) {
        return new InstagramAuthorization(properties);
    };

    /**
     * Encodes the specified InstagramAuthorization message. Does not implicitly {@link InstagramAuthorization.verify|verify} messages.
     * @function encode
     * @memberof InstagramAuthorization
     * @static
     * @param {IInstagramAuthorization} message InstagramAuthorization message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InstagramAuthorization.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.callbackCode != null && Object.hasOwnProperty.call(message, "callbackCode"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.callbackCode);
        return writer;
    };

    /**
     * Encodes the specified InstagramAuthorization message, length delimited. Does not implicitly {@link InstagramAuthorization.verify|verify} messages.
     * @function encodeDelimited
     * @memberof InstagramAuthorization
     * @static
     * @param {IInstagramAuthorization} message InstagramAuthorization message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InstagramAuthorization.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an InstagramAuthorization message from the specified reader or buffer.
     * @function decode
     * @memberof InstagramAuthorization
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {InstagramAuthorization} InstagramAuthorization
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InstagramAuthorization.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.InstagramAuthorization();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.callbackCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an InstagramAuthorization message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof InstagramAuthorization
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {InstagramAuthorization} InstagramAuthorization
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InstagramAuthorization.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an InstagramAuthorization message.
     * @function verify
     * @memberof InstagramAuthorization
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    InstagramAuthorization.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.callbackCode != null && message.hasOwnProperty("callbackCode"))
            if (!$util.isString(message.callbackCode))
                return "callbackCode: string expected";
        return null;
    };

    /**
     * Creates an InstagramAuthorization message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof InstagramAuthorization
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {InstagramAuthorization} InstagramAuthorization
     */
    InstagramAuthorization.fromObject = function fromObject(object) {
        if (object instanceof $root.InstagramAuthorization)
            return object;
        let message = new $root.InstagramAuthorization();
        if (object.callbackCode != null)
            message.callbackCode = String(object.callbackCode);
        return message;
    };

    /**
     * Creates a plain object from an InstagramAuthorization message. Also converts values to other types if specified.
     * @function toObject
     * @memberof InstagramAuthorization
     * @static
     * @param {InstagramAuthorization} message InstagramAuthorization
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    InstagramAuthorization.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.callbackCode = "";
        if (message.callbackCode != null && message.hasOwnProperty("callbackCode"))
            object.callbackCode = message.callbackCode;
        return object;
    };

    /**
     * Converts this InstagramAuthorization to JSON.
     * @function toJSON
     * @memberof InstagramAuthorization
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    InstagramAuthorization.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for InstagramAuthorization
     * @function getTypeUrl
     * @memberof InstagramAuthorization
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    InstagramAuthorization.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/InstagramAuthorization";
    };

    return InstagramAuthorization;
})();

export const InstagramAuthorizationResponse = $root.InstagramAuthorizationResponse = (() => {

    /**
     * Properties of an InstagramAuthorizationResponse.
     * @exports IInstagramAuthorizationResponse
     * @interface IInstagramAuthorizationResponse
     * @property {InstagramAuthorizationResponse.Status|null} [status] InstagramAuthorizationResponse status
     */

    /**
     * Constructs a new InstagramAuthorizationResponse.
     * @exports InstagramAuthorizationResponse
     * @classdesc Represents an InstagramAuthorizationResponse.
     * @implements IInstagramAuthorizationResponse
     * @constructor
     * @param {IInstagramAuthorizationResponse=} [properties] Properties to set
     */
    function InstagramAuthorizationResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * InstagramAuthorizationResponse status.
     * @member {InstagramAuthorizationResponse.Status} status
     * @memberof InstagramAuthorizationResponse
     * @instance
     */
    InstagramAuthorizationResponse.prototype.status = 0;

    /**
     * Creates a new InstagramAuthorizationResponse instance using the specified properties.
     * @function create
     * @memberof InstagramAuthorizationResponse
     * @static
     * @param {IInstagramAuthorizationResponse=} [properties] Properties to set
     * @returns {InstagramAuthorizationResponse} InstagramAuthorizationResponse instance
     */
    InstagramAuthorizationResponse.create = function create(properties) {
        return new InstagramAuthorizationResponse(properties);
    };

    /**
     * Encodes the specified InstagramAuthorizationResponse message. Does not implicitly {@link InstagramAuthorizationResponse.verify|verify} messages.
     * @function encode
     * @memberof InstagramAuthorizationResponse
     * @static
     * @param {IInstagramAuthorizationResponse} message InstagramAuthorizationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InstagramAuthorizationResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
        return writer;
    };

    /**
     * Encodes the specified InstagramAuthorizationResponse message, length delimited. Does not implicitly {@link InstagramAuthorizationResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof InstagramAuthorizationResponse
     * @static
     * @param {IInstagramAuthorizationResponse} message InstagramAuthorizationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InstagramAuthorizationResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an InstagramAuthorizationResponse message from the specified reader or buffer.
     * @function decode
     * @memberof InstagramAuthorizationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {InstagramAuthorizationResponse} InstagramAuthorizationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InstagramAuthorizationResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.InstagramAuthorizationResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.status = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an InstagramAuthorizationResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof InstagramAuthorizationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {InstagramAuthorizationResponse} InstagramAuthorizationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InstagramAuthorizationResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an InstagramAuthorizationResponse message.
     * @function verify
     * @memberof InstagramAuthorizationResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    InstagramAuthorizationResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.status != null && message.hasOwnProperty("status"))
            switch (message.status) {
            default:
                return "status: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 10:
                break;
            }
        return null;
    };

    /**
     * Creates an InstagramAuthorizationResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof InstagramAuthorizationResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {InstagramAuthorizationResponse} InstagramAuthorizationResponse
     */
    InstagramAuthorizationResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.InstagramAuthorizationResponse)
            return object;
        let message = new $root.InstagramAuthorizationResponse();
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "OK":
        case 0:
            message.status = 0;
            break;
        case "INVALID_CODE":
        case 1:
            message.status = 1;
            break;
        case "INSTAGRAM_ACCESS_NOT_GRANTED":
        case 2:
            message.status = 2;
            break;
        case "INSTAGRAM_NO_CONNECTED_ACCOUNTS":
        case 3:
            message.status = 3;
            break;
        case "INTERNAL_ERROR":
        case 10:
            message.status = 10;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from an InstagramAuthorizationResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof InstagramAuthorizationResponse
     * @static
     * @param {InstagramAuthorizationResponse} message InstagramAuthorizationResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    InstagramAuthorizationResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.status = options.enums === String ? "OK" : 0;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.InstagramAuthorizationResponse.Status[message.status] === undefined ? message.status : $root.InstagramAuthorizationResponse.Status[message.status] : message.status;
        return object;
    };

    /**
     * Converts this InstagramAuthorizationResponse to JSON.
     * @function toJSON
     * @memberof InstagramAuthorizationResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    InstagramAuthorizationResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for InstagramAuthorizationResponse
     * @function getTypeUrl
     * @memberof InstagramAuthorizationResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    InstagramAuthorizationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/InstagramAuthorizationResponse";
    };

    /**
     * Status enum.
     * @name InstagramAuthorizationResponse.Status
     * @enum {number}
     * @property {number} OK=0 OK value
     * @property {number} INVALID_CODE=1 INVALID_CODE value
     * @property {number} INSTAGRAM_ACCESS_NOT_GRANTED=2 INSTAGRAM_ACCESS_NOT_GRANTED value
     * @property {number} INSTAGRAM_NO_CONNECTED_ACCOUNTS=3 INSTAGRAM_NO_CONNECTED_ACCOUNTS value
     * @property {number} INTERNAL_ERROR=10 INTERNAL_ERROR value
     */
    InstagramAuthorizationResponse.Status = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OK"] = 0;
        values[valuesById[1] = "INVALID_CODE"] = 1;
        values[valuesById[2] = "INSTAGRAM_ACCESS_NOT_GRANTED"] = 2;
        values[valuesById[3] = "INSTAGRAM_NO_CONNECTED_ACCOUNTS"] = 3;
        values[valuesById[10] = "INTERNAL_ERROR"] = 10;
        return values;
    })();

    return InstagramAuthorizationResponse;
})();

export { $root as default };
