import i18n from '../../../i18n';
import Chat from '../../Chat/Chat';
import Message from '../../Message';
import {ITag} from '../../MessageTagsStore';
import ChatsFoundItem from './ChatsFoundItem';

export default function composeSearchResult(
  foundChats: Chat[],
  foundMessages: Message[],
  chatForSearch?: Chat,
): ChatsFoundItem[] {
  const foundTags: ITag[] = [];
  const foundMessagesByTags: Message[] = [];
  let items: ChatsFoundItem[] = [];

  if (chatForSearch) {
    items.push({
      delimiter: i18n.t('Search messages in'),
    });
    items.push({
      chatForSearch: chatForSearch,
    });
  }

  if (foundMessagesByTags.length) {
    items.push({
      delimiter: i18n.t('Found {{count}} messages', {count: foundMessagesByTags.length}),
    });
    items = items.concat(foundMessagesByTags.map((message) => ({message})));
    return items;
  }

  if (foundTags.length) {
    items.push({
      delimiter: i18n.t('Search by tag'),
    });
    items = items.concat(foundTags.map((tag) => ({tag})));
  }

  if (!chatForSearch && foundChats.length) {
    items.push({
      delimiter: i18n.t('Global search results'),
    });

    items = items.concat(foundChats.map((chat) => ({chat})));
  }

  items.push({
    delimiter: foundMessages.length
      ? i18n.t('Found {{count}} messages', {count: foundMessages.length})
      : i18n.t('No messages found'),
  });

  items = items.concat(foundMessages.map((message) => ({message})));

  return items;
}