
export default function isVisible(el: HTMLElement, container?: HTMLElement | null): boolean {
  if (!container) {
    return false;
  }

  const eleTop = el.offsetTop;
  const eleBottom = eleTop + el.clientHeight;

  const containerTop = container.scrollTop;
  const containerBottom = containerTop + container.clientHeight;

  // The element is fully visible in the container
  return (
    (eleTop >= containerTop && eleBottom <= containerBottom) ||
    // Some part of the element is visible in the container
    (eleTop < containerTop && containerTop < eleBottom) ||
    (eleTop < containerBottom && containerBottom < eleBottom)
  );
}
