import {matchPath} from 'react-router';
import Paths from './Paths';

const PathToRawPathDict = {
  [Paths.RawBillingTariff]: Paths.BillingTariff,
};

export function getPathForRawPath(rawPath: string): Paths | null {

  for (const path of Object.keys(PathToRawPathDict)) {
    if (matchPath(path, rawPath)) {
      return PathToRawPathDict[path];
    }
  }

  return null;
}

export default getPathForRawPath;