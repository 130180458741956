import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import Avatar from 'o-ui/Avatar';
import Button from 'o-ui/Button';
import DialogActions from 'o-ui/Dialog/DialogActions';
import DialogContent from 'o-ui/Dialog/DialogContent';
import FormLabel from 'o-ui/FormLabel';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';

import useAvatarAttachment from '../pages/Chat/useAvatarAttachment';
import {useStore} from '../stores/AppStore';
import {ModalItem} from '../stores/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
}

export const ModalEditGroup = observer((props: IProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const {
    layOutStore: {
      rightSideBar: {chat},
    },
    notifications,
  } = useStore();

  const {src} = useAvatarAttachment(chat?.avatar, chat?.channelID);

  // const [newPhoto, setNewPhoto] = useState<FileData | null>(null);
  // const refPhotoInput = useRef<HTMLInputElement>(null);
  const [submiting, setSubmiting] = React.useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      // newPhoto: '',
      name: chat?.name || '',
      alternativeName: chat?.alternativeName || '',
      // description: chat?.description || '',
    },
    validateOnBlur: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('modal_edit_group_group_name_required'))
        .max(128, t('modal_edit_group_group_name_max_length', {maxLength: 128})),
      alternativeName: Yup.string().max(128, t('modal_edit_group_group_alt_name_max_length', {maxLength: 128})),
      // description: Yup.string().max(255, t('Max length {{maxLength}} symbols', {maxLength: 255})),
    }),
    onSubmit: ({name, alternativeName}) => {
      if (submiting) {
        return;
      }

      setSubmiting(true);

      try {
        // let fileId: number | null = null;

        // if (newPhoto && chat) {
        //   const res = await uploaderApi.uploadFiles(chat, [newPhoto]);
        //   fileId = res.filesIds.length > 0 ? res.filesIds[0] : null;
        // }

        chat?.store?.editChat({name, alternativeName});
        handleCloseClick();
      } catch (e: any) {
        notifications.error(e.message);
      }

      setSubmiting(false);
    },
  });

  // const handleChangeAvatar = () => {
  //   refPhotoInput.current?.click();
  // };

  // const handleFileChange = (dataFiles: FileData[]) => {
  //   if (dataFiles.length) {
  //     // formik.setFieldValue('newPhoto', dataFiles[0].file.name);
  //     // setNewPhoto(dataFiles[0]);
  //   }
  // };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      title={t('modal_edit_group_title')}
      className="modal-group-creating"
      maxWidth="md"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogContent className="p-0 position-relative overflow-hidden px-6" style={{maxWidth: '500px'}}>
          <div className="d-flex align-items-center">
            <div>
              <Avatar
                size="lg"
                className="upload-btn filled d-flex justify-content-center m-5"
                name={chat?.name}
                src={src}
                // src={newPhoto?.objectUrl || chat?.image?.url}
                // onClick={handleChangeAvatar}
              >
                <i className="profile-sidebar__upload-icon position-absolute-center tg-icon-camera-add-line text-white" />
              </Avatar>
              {/* <AvatarUploader className="d-none" ref={refPhotoInput} onChange={handleFileChange} /> */}
            </div>

            <div className="w-100 pt-6">
              <FormLabel>{t('modal_edit_group_group_name_label')}</FormLabel>
              <OutlinedTextInput
                name="name"
                className="w-100"
                placeholder={t('modal_edit_group_group_name_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                errorHelper={formik.errors.name || ''}
                autoFocus
                disabled
              />
            </div>
          </div>
          <div className="w-100 pt-3">
            <FormLabel>{t('modal_edit_group_group_alt_name_label')}</FormLabel>
            <OutlinedTextInput
              name="alternativeName"
              className="w-100"
              placeholder={t('modal_edit_group_group_alt_name_placeholder')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.alternativeName}
              errorHelper={formik.errors.alternativeName || ''}
            />
          </div>
          <div className="w-100 pt-3 pb-6">
            <FormLabel>{t('modal_edit_group_group_description_label')}</FormLabel>
            <OutlinedTextInput
              name="description"
              className="w-100"
              placeholder={t('modal_edit_group_group_description_placeholder')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // value={formik.values.description}
              // errorHelper={formik.errors.description || ''}
              disabled
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            color="primary"
            variant="contained"
            type="submit"
            disabled={!(formik.isValid && formik.dirty) || submiting}
          >
            {t('modal_save_btn')}
          </Button>
          <Button onClick={handleCloseClick} size="large" color="secondary" variant="contained">
            {t('modal_cancel_btn')}
          </Button>
        </DialogActions>
      </form>
    </ModalDialog>
  );
});

export default ModalEditGroup;
