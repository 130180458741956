import {matchPath, useLocation} from 'react-router-dom';
import Paths from '../../../routes/Paths';

export default function useTariffId() {
  const location = useLocation();

  const match = location.state?.from?.pathname ?
    matchPath(Paths.RawBillingTariff, location.state?.from?.pathname) : null;

  return match?.params?.tariffId;
}