import cn from 'classnames';
import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import useStore from '../../../stores/useStore';
import UsersImg from '../../../assets/images/users.svg';

interface InvitationStatusProps {
  className?: string;
}

export const InvitationStatus: React.FC<InvitationStatusProps> = observer(({
  className,
}) => {
  const {t} = useTranslation();
  const {activeWorkspace: {membersCount}} = useStore();

  return (
    <span
      className={cn(
        'd-inline-flex align-items-center border-primary radius-8 p-4',
        className,
      )}
    >
      <img className="mr-4" src={UsersImg} alt="" />
      {membersCount === 1 ?
        t('settings_billing_one_invited_user')
        : t('settings_billing_number_invited_users', {number: membersCount})}
    </span>
  );
});

export default InvitationStatus;