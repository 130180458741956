import {observer} from 'mobx-react';
import React from 'react';
import {Navigate} from 'react-router';
import {generatePath} from 'react-router-dom';


import useStore from '../../stores/useStore';
import {uint8ArrayToBase64} from '../../utils/arrayUtils';
import getPathForRawPath from '../../routes/Paths/getPathForRawPath';
import usePathParams from '../../routes/Paths/usePathParams';


const SettingsRedirect = observer(() => {
  const {activeWorkspace} = useStore();
  const params = usePathParams();

  const [workspaceId, setWorkspaceId] = React.useState<string | undefined>(
    encodeURIComponent(uint8ArrayToBase64(activeWorkspace.id))
  );

  React.useEffect(() => {
    if (activeWorkspace.id) {
      setWorkspaceId(encodeURIComponent(uint8ArrayToBase64(activeWorkspace.id)));
    }
  }, [
    activeWorkspace.id,
  ]);

  const path = getPathForRawPath(window.location.pathname);

  const redirectPath = path ? generatePath(path, {
    ...params,
    workspaceId: workspaceId || '',
    page: null,
  }) : path;
  console.debug('redirectPath=', redirectPath);

  return redirectPath && workspaceId ? (
    <Navigate
      to={`${redirectPath}${location.search}`}
      replace
    />
  ) : null;
});

export default SettingsRedirect;
