import {action, observable} from 'mobx';
import {AppStore} from './AppStore';
import browserStorage from '../utils/browserStorage';

const VIDEO_PLAYER_VOLUME_STORE_KEY = 'videoPlayerVolume';


export class VideoPlayerStore {
  @observable volume = 100;

  @action setVolume = (volume: number) => {
    this.volume = volume;
    browserStorage.setItem(VIDEO_PLAYER_VOLUME_STORE_KEY, volume.toString());
  };

  constructor(public app: AppStore) {
    this.init();
  }

  init = () => {
    const volume = browserStorage.getItem(VIDEO_PLAYER_VOLUME_STORE_KEY);
    this.setVolume(volume ? parseInt(volume, 10) : 100);
  };
}

export default VideoPlayerStore;
