import {observer} from 'mobx-react';
import {Button, Tab, Tabs} from 'o-ui';
import InputBase from 'o-ui/Input/InputBase';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as UserIcon} from '../../../../assets/image-icons/user.svg';
import useWorkspaceNavigate from '../../../../hooks/useWorkspaceNavigate';
import Paths from '../../../../routes/Paths';
import {useStore} from '../../../../stores/AppStore';
import {SnippetType} from '../../../../stores/Snippets/SnippetsStore';

interface SnippetsBarHeaderProps {
  selectedType?: SnippetType;
  filter: string;
  onChangeTab(e: React.ChangeEvent, snippetType: SnippetType): void;
  onSearch(e: React.ChangeEvent<HTMLInputElement>): void;
}

const SnippetsBarHeader: React.FC<SnippetsBarHeaderProps> = observer((props) => {
  const {t} = useTranslation();

  const {activeWorkspace} = useStore();

  const workspaceNavigate = useWorkspaceNavigate();

  const workspaceSnippetsCount = activeWorkspace.workspaceSnippets.count;
  const personalSnippetsCount = activeWorkspace.personalSnippets.count;

  const handleManageSnippets = () => {
    workspaceNavigate(Paths.Snippets);
  };

  return (
    <div className="snippets-bar__header pt-3">
      <div className="snippets-bar__header-tabs border-bottom px-4">
        <Tabs className="snippets-bar__tabs" value={props.selectedType} onChange={props.onChangeTab}>
          <Tab
            label={
              <span className="d-flex align-items-center">
                {t('chat_user_chat_snippets_bar_all_title')}
                <span className="tab-counter">{workspaceSnippetsCount + personalSnippetsCount}</span>
              </span>
            }
          />
          <Tab
            label={
              <span className="d-flex align-items-center">
                {t('chat_user_chat_snippets_bar_workspace_title')}
                <span className="tab-counter">{workspaceSnippetsCount}</span>
              </span>
            }
            value={SnippetType.Workspace}
          />
          <Tab
            label={
              <span className="d-flex align-items-center">
                {t('chat_user_chat_snippets_bar_personal_title')}
                <span className="tab-counter">{personalSnippetsCount}</span>
              </span>
            }
            value={SnippetType.Personal}
          />
        </Tabs>
        <div className="ml-auto">
          <Button
            className="snippets-bar__manage-btn"
            variant="outlined"
            color="tertiary"
            size="small"
            startIcon={<UserIcon />}
            onClick={handleManageSnippets}
          >
            {t('chat_user_chat_snippets_bar_manage_btn')}
          </Button>
        </div>
      </div>
      {(workspaceSnippetsCount && props.selectedType === SnippetType.Workspace) ||
      (personalSnippetsCount && props.selectedType === SnippetType.Personal) ||
      (!props.selectedType && (workspaceSnippetsCount || personalSnippetsCount)) ? (
        <div className="snippets-bar__search bg-background-secondary radius-8 d-flex flex-auto align-items-center px-5 py-1 mt-4 mx-4">
          <span className="tg-icon-search-line mr-5" />
          <InputBase
            className="w-100"
            placeholder={t('chat_user_chat_snippets_bar_search_placeholder')}
            value={props.filter}
            onChange={props.onSearch}
          />
        </div>
      ) : null}
    </div>
  );
});

export default SnippetsBarHeader;
