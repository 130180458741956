import useResizeObserver from 'use-resize-observer';

const MOBILE_WIDTH = 800;

export function useMobileByWidth<T extends Element>() {
  const {ref, width = 0} = useResizeObserver<T>();

  return {ref, isMobile: width < MOBILE_WIDTH};
}

export default useMobileByWidth;