import cn from 'classnames';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import Button from 'o-ui/Button';
import FormHelperText from 'o-ui/FormHelperText';
import FormLabel from 'o-ui/FormLabel';
import InputLabel from 'o-ui/Input/InputLabel';
import PhoneInput from 'o-ui/Input/PhoneInput';
import PinInput from 'o-ui/Input/PinInput';
import Link from 'o-ui/Link';

import {TelegramAuthStageResponse, entities} from '../../../../api/proto';
import {useMountedState} from '../../../../hooks/useMountedState';
import {useStore} from '../../../../stores/AppStore';
import {Channel} from '../../../../stores/Channel';
import {getChannelPinCodeLength, } from '../../../../stores/Channel/ChannelsStore';
import getChannelTypeName from '../../../../stores/Channel/utils/getChannelTypeName';

interface IProps {
  title?: string;
  subTitle?: string;
  type: entities.OzekonChannelType;
  newChannel: Channel;
  codeHash?: string | null;
  onSubmit?: (status?: TelegramAuthStageResponse.EnterCode.Result | null) => void;
  onClose?: () => void;
  reactivationMode?: boolean;
}

export const TelegramConfirmationForm = observer((props: IProps) => {
  const isMounted = useMountedState();
  const {t} = useTranslation();
  const {channels, notifications} = useStore();
  const [apiError, setApiError] = React.useState<string | null | undefined>('');

  const pinCodeLength = getChannelPinCodeLength();

  const getSendCodeStatusTitle = (status?: TelegramAuthStageResponse.EnterCode.Result | null) => {
    switch (status) {
      case TelegramAuthStageResponse.EnterCode.Result.UNKNOWN:
        return t('Unknown');
      case TelegramAuthStageResponse.EnterCode.Result.PASSWORD_REQUIRED:
        return t('Password required');
      case TelegramAuthStageResponse.EnterCode.Result.PHONE_CODE_EMPTY:
        return t('Phone code empty');
      case TelegramAuthStageResponse.EnterCode.Result.PHONE_CODE_EXPIRED:
        return t('Phone code expired');
      case TelegramAuthStageResponse.EnterCode.Result.PHONE_CODE_INVALID:
        return t('Phone code invalid');
      case TelegramAuthStageResponse.EnterCode.Result.PHONE_NUMBER_INVALID:
        return t('Phone number invalid');
      case TelegramAuthStageResponse.EnterCode.Result.PHONE_NUMBER_UNOCCUPIED:
        return t('Phone number unoccupied');
      case TelegramAuthStageResponse.EnterCode.Result.SIGN_IN_FAILED:
        return t('Sign in failed');
      case TelegramAuthStageResponse.EnterCode.Result.SIGN_UP_REQUIRED:
        return t('Sign in required');
      case TelegramAuthStageResponse.EnterCode.Result.AUTH_SUCCESS:
      default:
        return null;
    }
  };

  const getResendCodeStatusTitle = (status?: TelegramAuthStageResponse.ResendCode.Result | null) => {
    switch (status) {
      case TelegramAuthStageResponse.ResendCode.Result.UNKNOWN:
        return t('Unknown error');
      case TelegramAuthStageResponse.ResendCode.Result.CODE_HASH_INVALID:
        return t('Code hash invalid');
      case TelegramAuthStageResponse.ResendCode.Result.CODE_RATE_LIMIT:
        return t('To many requests');
      case TelegramAuthStageResponse.ResendCode.Result.CODE_RESENT:
      default:
        return null;
    }
  };

  const handleResendCode = async (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (props.newChannel?.id && props.codeHash) {
      const {error, res} = await channels.telegram.resendAuthCode({
        channel: props.newChannel,
        codeHash: props.codeHash,
      });

      const title = getResendCodeStatusTitle(res?.status);

      if (title || error) {
        notifications.error(title || error?.message);
      }
    }
  };

  const formikConfirm = useFormik({
    initialValues: {
      confirmationCode: '',
    },
    validateOnBlur: false,
    validationSchema: Yup.object({
      confirmationCode: Yup.string().required(t('Required')),
    }),
    onSubmit: async ({confirmationCode}) => {
      setApiError('');
      const {error, res} = await channels.telegram.sendAuthCode({
        channel: props.newChannel,
        codeHash: props.codeHash || '',
        code: confirmationCode,
      });

      if (!isMounted()) {
        return;
      }

      if (error) {
        setApiError(error.message);
        notifications.error(error.message);
      }

      if (res) {
        props.onSubmit && props.onSubmit(res.status);

        if (
          res.status &&
          ![
            TelegramAuthStageResponse.EnterCode.Result.AUTH_SUCCESS,
            TelegramAuthStageResponse.EnterCode.Result.PASSWORD_REQUIRED,
          ].includes(res.status)
        ) {
          const statusTitle = getSendCodeStatusTitle(res.status);
          notifications.error(statusTitle);
          setApiError(statusTitle);
        }
      }
    },
  });

  const handleSubmitConfirmationForm = (e: React.FormEvent<HTMLFormElement>) => {
    formikConfirm.handleSubmit(e);
  };

  const handleFormKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.keyCode === 13) {
      formikConfirm.submitForm();
    }
  };

  const handleChangeCode = async (value: string) => {
    setApiError('');
    await formikConfirm.setFieldValue('confirmationCode', value, false);
    if (value.length === pinCodeLength) {
      formikConfirm.submitForm();
    }
  };

  const closePhoneNumberConfirmation = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setApiError('');
    formikConfirm.setFieldValue('confirmationCode', '', false);
    props.onClose && props.onClose();
  };

  return (
    <>
      <form
        className="channel-dialog-form__form custom-scroll"
        onSubmit={handleSubmitConfirmationForm}
        onKeyDown={handleFormKeyDown}
      >
        {props.title ? <div className="h1-bold color-body-primary">{props.title}</div> : null}
        {props.subTitle ? <div className="body1-regular color-body-primary pb-6">{props.subTitle}</div> : null}
        <p className="body1-regular color-body-primary">
          {t('settings_channel_creation_form_sended_code', {
            messengerName: getChannelTypeName(props.type),
          })}
        </p>
        <FormLabel className="body3-regular color-body-tertiary mb-2 text-capitalize">
          {t('settings_channel_creation_form_channel_phone_label')}
        </FormLabel>
        <div className="position-relative">
          <PhoneInput
            className="channel-dialog-form__phone-input w-100"
            name="phoneNumber"
            variant="outlined"
            value={props.newChannel?.phone}
            disabled
          />
          {props.reactivationMode ? null : (
            <Link
              component="button"
              type="button"
              color="textPrimary"
              className="channel-dialog-form__change-button color-brand-primary text-initial"
              onClick={closePhoneNumberConfirmation}
            >
              {t('settings_channel_creation_form_change')}
            </Link>
          )}
        </div>

        <InputLabel className="channel-dialog-form__code-title mt-6">
          {t('settings_channel_creation_form_channel_code_label', {number: getChannelPinCodeLength()})}
        </InputLabel>
        <PinInput
          className={cn({
            error: apiError || formikConfirm.errors.confirmationCode,
          })}
          name="confirmationCode"
          onChange={handleChangeCode}
          value={formikConfirm.values.confirmationCode}
          fields={pinCodeLength}
        />

        <FormHelperText error>{apiError || formikConfirm.errors.confirmationCode || ' '}</FormHelperText>

        {channels.telegram.resendAuthCodeTimer.stopped ? (
          <Link
            className="channel-dialog-form__resend-button color-brand-primary body1-regular"
            component="button"
            type="button"
            color="textPrimary"
            onClick={handleResendCode}
          >
            {t('settings_channel_creation_form_resend_code')}
          </Link>
        ) : (
          <InputLabel className="channel-dialog-form__resend-status">
            {t('settings_channel_creation_form_resend_code_message', {
              seconds: channels.telegram.resendAuthCodeTimer.currentTime,
            })}
          </InputLabel>
        )}
      </form>

      <div className="channel-dialog-form__footer">
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          onClick={formikConfirm.submitForm}
          loading={channels.telegram.loading}
        >
          {t('settings_channel_creation_form_connect')}
        </Button>
      </div>
    </>
  );
});

export default TelegramConfirmationForm;
