import Long from 'long';

import {api, IMCMessage, IUpdate, MCAttachment, MCMethod, MCMethodGetMessages} from '../../api/proto';
import EventEmitter from '../../api/EventEmitter';
import WorkspaceStore from '../Workspaces/WorkspaceStore';
import {ChannelsUpdaterEvent} from '../Channel/ChannelsUpdater';
import {CHAT_MESSAGES_PAGE_SIZE} from '../../constants';
import RawChatHistoryEvent from './RawChatHistoryEvent';

export type LoadChatHistoryParams = {
  fromMessageID?: Long | null;
  limit?: Long;
  attachmentType?: MCAttachment.Type;
};

export type RawChatHistoryData = {
  rawMessages: IMCMessage[];
};

interface IRawChatHistory {
  load(params: LoadChatHistoryParams): Promise<void>;
  find(messageID?: Long | null): IMCMessage | null;
  clear(): void;
  isEmpty(): boolean;
}

export class RawChatHistory extends EventEmitter implements IRawChatHistory {
  private rawMessages_: IMCMessage[] = [];

  private messageIdNumbersDict_: {[id: string]: boolean} = {};

  constructor(
    protected workspace: WorkspaceStore,
    protected type: MCAttachment.Type,
    protected channelID?: Uint8Array | null,
    protected chatID?: Long | null,
  ) {
    super();

    workspace.updater.on(ChannelsUpdaterEvent.UPDATE, this.processNewUpdate_);
  }

  public clear = () => {
    this.rawMessages_ = [];
    this.messageIdNumbersDict_ = {};
  };

  public reset = () => {
    this.clear();

    this.workspace.updater.off(ChannelsUpdaterEvent.UPDATE, this.processNewUpdate_);
  };

  public find = (messageID?: Long | null) => {
    return this.rawMessages_.find(({localID}) => messageID && localID?.equals(messageID)) || null;
  };

  public isEmpty = () => {
    return !this.rawMessages_.length;
  };

  public load = async (params: LoadChatHistoryParams): Promise<void> => {
    const foundRawMessages = this.getSlice_(params.fromMessageID, params.limit);

    if (foundRawMessages?.length === params.limit?.toNumber()) {
      // console.debug(`%c----->RawChatHistory->load foundRawMessages`, 'color: blue', foundRawMessages);
      this.emit(RawChatHistoryEvent.LOAD_HISTORY, {
        rawMessages: foundRawMessages,
      });
      return;
    }

    const {res} = await this.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            getMessages: new MCMethodGetMessages({
              channelID: this.channelID,
              chatID: this.chatID,
              limit: params.limit,
              fromMessageID: params.fromMessageID,
              attachmentType: params.attachmentType,
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    this.addMessages_(res?.methodResponse?.getMessages?.messages || []);
  };

  private processNewUpdate_ = (update: IUpdate) => {
    if (update.updateNewMessage?.message && this.someAttachmentsHasExistType_(update.updateNewMessage.message)) {
      this.processMessage_(update.updateNewMessage.message);
    }

    if (update.updateEditedMessage?.message && this.someAttachmentsHasExistType_(update.updateEditedMessage.message)) {
      this.processMessage_(update.updateEditedMessage.message);
    }
  };

  private processMessage_ = (rawMessage: IMCMessage) => {
    if (!this.chatID || !rawMessage.chatID?.equals(this.chatID) || !rawMessage.localID) {
      return;
    }

    if (this.messageIdNumbersDict_[rawMessage.localID.toString()]) {
      this.updateMessage_(rawMessage);
    } else {
      this.addMessage_(rawMessage);
    }
  };

  private addMessage_ = (rawNewMessage: IMCMessage) => {
    // console.debug(
    //   `%c----->RawChatHistory->addMessage_`,
    //   'color: green',
    //   rawNewMessage,
    //   randomID?.toString(),
    //   getMessageText(rawNewMessage),
    // );
    if (rawNewMessage.localID) {
      this.addMessageToRawList_(rawNewMessage);
      this.messageIdNumbersDict_[rawNewMessage.localID.toString()] = true;

      this.emit(RawChatHistoryEvent.ADD_NEW_MESSAGE, rawNewMessage);
    }
  };

  private addMessageToRawList_ = (rawMessage: IMCMessage) => {
    this.rawMessages_ = [rawMessage].concat(this.rawMessages_);
  };

  private updateMessage_ = (rawMessage: IMCMessage) => {
    // const hasRandomId = randomID && randomID?.greaterThan(0);

    // console.debug(
    //   `%c----->RawChatHistory->updateMessage_`,
    //   'color: green',
    //   rawMessage,
    //   randomID,
    //   ' new text=',
    //   getMessageText(rawMessage),
    // );
    // const idx = hasRandomId
    //   ? this.rawMessages_.findIndex((raw) => raw.randomID?.equals(randomID))
    //   : this.rawMessages_.findIndex((raw) => raw.localID && rawMessage.localID?.equals(raw.localID));

    const idx = this.rawMessages_.findIndex((raw) => raw.localID && rawMessage.localID?.equals(raw.localID));

    if (idx >= 0) {
      const currentMessage = this.rawMessages_[idx];
      const updatedMessage = {...currentMessage, ...rawMessage};

      this.rawMessages_[idx] = updatedMessage;

      this.emit(RawChatHistoryEvent.UPDATE_MESSAGE, updatedMessage);
    } else {
      // console.debug(
      //   `%c----->RawChatHistory->updateMessage_ message not found!`,
      //   'color: red',
      //   rawMessage,
      //   randomID?.toString(),
      //   ' new text=',
      //   getMessageText(rawMessage),
      // );
      this.addMessage_(rawMessage);
    }
  };

  private addMessages_ = (rawMessages: IMCMessage[]) => {
    rawMessages.forEach((msg) => {
      if (msg.localID && !this.messageIdNumbersDict_[msg.localID.toString()]) {
        this.rawMessages_.push(msg);
        this.messageIdNumbersDict_[msg.localID.toString()] = true;
      }
    });

    this.emit(RawChatHistoryEvent.LOAD_HISTORY, {rawMessages});
  };

  private getSlice_ = (
    startMessageID?: Long | null,
    limit = Long.fromNumber(CHAT_MESSAGES_PAGE_SIZE),
  ): IMCMessage[] | null => {
    // console.debug(`%c----->RawChatHistory->getSlice_ startMessageID`, 'color: green', startMessageID?.toNumber());
    if (!this.rawMessages_.length) {
      return null;
    }

    if (!startMessageID) {
      return this.rawMessages_.slice(0, limit.toNumber());
    }

    const messageIdx = this.findIndex_(startMessageID);
    let foundMessages: IMCMessage[] | null = null;

    if (messageIdx !== -1) {
      foundMessages = this.rawMessages_.slice(messageIdx, messageIdx + limit.toNumber());
    }

    return foundMessages?.length ? foundMessages : null;
  };

  private findIndex_ = (messageID?: Long | null) => {
    return messageID ? this.rawMessages_.findIndex(({localID}) => localID?.equals(messageID)) : -1;
  };

  private someAttachmentsHasExistType_ = (rawMessage: IMCMessage) => {
    return rawMessage?.attachments?.some((attach) => attach.type === this.type);
  };
}

export default RawChatHistory;
