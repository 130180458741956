import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import {entities} from '../../../../api/proto';
import useMobileByWidth from '../../../../hooks/useMobileByWidth';
import useStore from '../../../../stores/useStore';
import TariffSelectItem from './TariffSelectItem';
import TariffSelectItemActive from './TariffSelectItemActive';

interface TariffSelectProps {
  className?: string;
  itemClassName?: string;
  style?: React.CSSProperties;
  onCancelClick?: (tariff: entities.ITariff) => void;
}

export const TariffSelect: React.FC<TariffSelectProps> = observer(({
  className,
  itemClassName,
  style,
  onCancelClick,
}) => {
  const {activeWorkspace: {billing}} = useStore();
  const {ref: innerRef, isMobile} = useMobileByWidth<HTMLDivElement>();

  const tariffIsActive = (tariff: entities.ITariff): boolean => {
    if (billing.trialEnabled) {
      return !!tariff.name && tariff.name.toLowerCase().indexOf('basic') >= 0;
    }
    return billing.currentTariff?.id === tariff.id;
  };

  const tariffIsRecomended = (tariff: entities.ITariff, i: number): boolean => {
    if (billing.currentTariff && i > 0) {
      return billing.currentTariff.id === billing.availableTariffs[i - 1]?.id;
    }
    return false;
  };

  const count = isMobile ? Math.round(billing.availableTariffs.length / 2) : billing.availableTariffs.length;
  const itemWidth = `calc((100% - ${count - 1} * var(--indent-size-3)) / ${count})`;

  return count ? (
    <div
      ref={innerRef}
      className={cn(
        'd-flex flex-row justify-content-between flex-wrap',
        className,
      )}
      style={style}
    >
      {billing.availableTariffs.map((tariff, i) => {
        return tariffIsActive(tariff) ? (
          <TariffSelectItemActive
            key={tariff.id?.toString()}
            className={cn(
              'flex-grow-1',
              itemClassName,
            )}
            style={{width: itemWidth, minWidth: itemWidth, maxWidth: itemWidth}}
            tariff={tariff}
            trial={billing.trialEnabled}
            onCancelClick={onCancelClick}
          />
        ) : (
          <TariffSelectItem
            key={tariff.id?.toString()}
            className={cn(
              'flex-grow-1',
              itemClassName,
            )}
            style={{width: itemWidth, minWidth: itemWidth, maxWidth: itemWidth}}
            tariff={tariff}
            recomended={tariffIsRecomended(tariff, i)}
          />
        );
      })}
    </div>
  ) : null;
});

export default TariffSelect;