import React from 'react';
import {useLocation, useMatch, useParams} from 'react-router-dom';

import Paths from '../../routes/Paths';
import useStore from '../../stores/useStore';
import {base64ToUint8Array} from '../../utils/arrayUtils';

type RouteParams = {
  workspaceId?: string;
  channelId?: string;
  chatId?: string;
  messageId?: string;
  userId?: string;
};

function decodeRouteParams(params: RouteParams): RouteParams {
  const decodedParams: RouteParams = {};
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      decodedParams[key] = decodeURIComponent(params[key] || '');
    }
  }
  return decodedParams;
}

export const useChatPageRoute = () => {
  const {workspaceId, channelId, chatId, messageId, userId} = useParams<RouteParams>();
  const location = useLocation();
  const state = location.state as {selectedChannelId: null | string};

  const params = React.useMemo(() => {
    return decodeRouteParams({workspaceId, channelId, chatId, messageId, userId});
  }, [workspaceId, channelId, chatId, messageId, userId]);

  const {chatsView, workspaces} = useStore();
  const matchUserChat = useMatch(Paths.UserChat);

  React.useEffect(() => {
    if (params.channelId) {
      if (matchUserChat) {
        console.debug(`useChatPageRoute: matchChat chatsView.openUserChatByParams(params)`, params);
        chatsView.openUserChatByParams(params);
      } else {
        console.debug(`useChatPageRoute: matchChat chatsView.openUserChatByParams(params, state?.selectedChannelId)`, params, state?.selectedChannelId);
        chatsView.openChatByParams(params, state?.selectedChannelId);
      }
    } else if (params.workspaceId) {
      console.debug(`useChatPageRoute: selectWorkspace`, params.workspaceId);
      workspaces.selectWorkspace(base64ToUint8Array(params.workspaceId));
      chatsView.selectChannel(null);
    }
  }, [
    workspaces,
    params,
    chatsView,
    location,
    matchUserChat,
    state?.selectedChannelId,
  ]);
};

export default useChatPageRoute;