import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';
import {useInView} from 'react-intersection-observer';

import Video from 'o-ui/Video';
import {useStore} from '../stores/AppStore';
import MessageAttachment from '../stores/Attachment/MessageAttachment';
import Message from '../stores/Message';
import {formatDuration} from '../utils/format';
import calcImageSize from '../utils/image/calcImageSize';

import {ReactComponent as PlayButton} from '../assets/image-icons/playButton.svg';

interface PreviewVideoProps {
  message?: Message;
  attachment?: MessageAttachment;
  children?: React.ReactNode;
  className?: string;
  src?: string | null;
  autoPlay?: boolean;
  autoPlayOnActive?: boolean;
  loop?: boolean;
  controls?: boolean;
  muted?: boolean;
  playButton?: boolean;
  playButtonSmall?: boolean;
  onClick?: () => void;
  onInViewChange?: (inView: boolean, entry: IntersectionObserverEntry) => void;
  width?: number | null;
  height?: number | null;
  maxWidth?: number;
  maxHeight?: number;
  duration?: number | null;
  isScrolling?: boolean;
}

export const PreviewVideo: React.FC<PreviewVideoProps> = observer((props) => {
  const {page, modals} = useStore();

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [src, setSrc] = React.useState<string | undefined>(undefined);
  const [videoRef, setVideoRef] = React.useState<HTMLVideoElement | null>(null);
  const [width, height] = calcImageSize(
    props.width || 0,
    props.height || 0,
    props.maxWidth,
    props.maxHeight,
  );

  const {ref: inViewRef, inView} = useInView({
    threshold: 0,
    onChange: props.onInViewChange,
  });

  const handleLoadedData = () => {
    setLoaded(true);
  };

  React.useEffect(() => {
    if (!src && !props.isScrolling && inView) {
      setSrc(props.src || undefined);
    }
  }, [src, props.src, props.isScrolling, inView]);

  React.useEffect(() => {
    if (src && props.autoPlayOnActive && videoRef && !props.isScrolling) {
      if (inView && page.inFocus && !modals.isOpen && loaded) {
        videoRef?.play();
      } else {
        videoRef?.pause();
      }
    }
  }, [props.autoPlayOnActive, src, props.isScrolling, videoRef, inView, page.inFocus, modals.isOpen, loaded]);

  const handleVideoRef = (el: HTMLVideoElement | null) => {
    setVideoRef(el);
    inViewRef(el);
  };

  const playButtonVisible = loaded && props.playButton && props.onClick && !props.playButtonSmall && (!props.message || props.message.isDelivered);
  const playButtonSmallVisible = loaded && props.playButton && props.onClick && props.playButtonSmall && (!props.message || props.message.isDelivered);

  return (
    <>
      <Video
        ref={handleVideoRef}
        data-original-src={props.src}
        data-attachment-uuid={props.attachment?.uuid}
        src={src}
        className={cn('preview-video', props.className)}
        autoPlay={props.autoPlay || props.autoPlayOnActive}
        loop={props.loop}
        controls={props.controls}
        muted={props.muted}
        style={{width: width || undefined, height: height || undefined}}
        loaderEnabled={true}
        retryOnError={true}
        reloadButtonEnabled={true}
        reloadButtonSize={32}
        onClick={props.onClick}
        onLoadedData={handleLoadedData}
      >
        {props.children}
      </Video>
      {playButtonSmallVisible ? (
        <div className="message-video__small-play-button tg-icon tg-icon-play-fill" onClick={props.onClick}>
          {formatDuration((props.duration || 0) * 1000)}
        </div>
      ) : null}
      {playButtonVisible ? (
        <PlayButton className="message-video__play-button position-absolute-center" onClick={props.onClick} />
      ) : null}
    </>
  );
});

export default PreviewVideo;
