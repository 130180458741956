import mimeDb from './mimeDb.json';

export function findMimeType(fileName?: string | null): string {
  const ext = fileName?.split('.').pop()?.toLocaleLowerCase() ?? '';
  if (!ext) {
    return '';
  }
  let mimeType = '';

  for (const m in mimeDb) {
    if (mimeDb[m].extensions?.includes(ext)) {
      mimeType = m;
      break;
    }
  }

  return mimeType;
}

