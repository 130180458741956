import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import Button from 'o-ui/Button';
import CircularProgress from 'o-ui/CircularProgress';
import FormLabel from 'o-ui/FormLabel';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import Channel from '../../../../stores/Channel';
import useStore from '../../../../stores/useStore';
import ChannelCreationStepType from '../ChannelCreationStepType';

import brandIcon from '../../../../assets/image-icons/brandIcon.svg';
import instagramIconSquared from '../../../../assets/image-icons/instagramIconSquared.svg';

interface InstagramAuthFormProps {
  title?: string;
  subTitle?: string;
  onChangeStep?: (nextStep: ChannelCreationStepType, channel?: Channel | null) => void;
  onClose?: () => void;
}

export const InstagramAuthForm: React.FC<InstagramAuthFormProps> = observer(({
  title,
  subTitle,
  onChangeStep,
  onClose,
}) => {
  const {t} = useTranslation();
  const [apiError, setApiError] = React.useState<string | null | undefined>('');
  const {channels: {instagram}, notifications} = useStore();


  const formikCreate = useFormik({
    initialValues: {
      channelName: '',
      accessToken: instagram.authToken || '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      channelName: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      setApiError('');
      const {error, res, channel} = await instagram.createChannel(
        instagram.authToken,
        {name: values.channelName},
      );

      if (error) {
        setApiError(error.message);
        notifications.error(error.message);
      } else if (res) {
        onChangeStep?.(ChannelCreationStepType.SUCCESS, channel);
      }
    },
  });

  const handleFormKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.keyCode === 13) {
      formikCreate.submitForm();
    }
  };

  return (
    <>
      <form
        className="channel-dialog-form__form custom-scroll"
        onSubmit={formikCreate.handleSubmit}
        onKeyDown={handleFormKeyDown}
      >
        {title ? <div className="h1-bold color-body-primary">{title}</div> : null}
        {subTitle ? <div className="body1-regular color-body-primary pb-6">{subTitle}</div> : null}

        {instagram.authToken ? null : (
          <div className="channel-dialog-form__title-icons">
            <img className="channel-dialog-form__tg-icon" src={instagramIconSquared} alt="" />
            <img className="channel-dialog-form__ozekon-icon" src={brandIcon} alt="" />
          </div>
        )}

        {instagram.authError ? (
          <div className="h5-bold font-bold color-error-primary mt-5">
            {instagram.authError}
          </div>
        ) : instagram.authTokenSuccessful ? (
          <div className="h4-bold font-bold color-success-primary mt-5">
            {t('settings_channel_creation_form_authorization_successful')}
          </div>
        ) : instagram.authToken ? (
          <>
            <FormLabel className="body3-regular color-body-tertiary mb-1">
              {t('settings_channel_creation_form_channel_name_label')}
            </FormLabel>
            <OutlinedTextInput
              className="w-100"
              name="channelName"
              onChange={formikCreate.handleChange}
              placeholder={t('settings_channel_creation_form_channel_name_placeholder')}
              onBlur={formikCreate.handleBlur}
              value={formikCreate.values.channelName}
              errorHelper={apiError || formikCreate.errors.channelName || ' '}
              required
            />
          </>
        ) : (
          <>
            <div className="h4-bold font-bold color-body-primary mt-5">
              {t('settings_channel_creation_form_authorization_processing')}
            </div>

            <div
              className="d-flex justify-content-center mt-5"
              style={{
                height: '50px',
              }}
            >
              <CircularProgress
                size={36}
                style={{
                  color: 'var(--brand-primary)',
                }}
              />
            </div>
          </>
        )}
      </form>

      {instagram.authToken && !instagram.authTokenSuccessful ? (
        <div className="border-primary-top px-6 pt-6">
          <Button
            className="w-sm-100"
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            onClick={formikCreate.submitForm}
            loading={instagram.loading}
          >
            {t('settings_channel_creation_form_connect_btn')}
          </Button>
        </div>
      ) : (
        <div className="border-primary-top px-6 pt-6 mt-6">
          <Button
            variant="contained"
            color="primary"
            size="large"
            className="channel-dialog-form__cancel-button"
            onClick={onClose}
          >
            {t('settings_channel_creation_form_close')}
          </Button>
        </div>
      )}
    </>
  );
});

export default InstagramAuthForm;