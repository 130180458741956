import {matchPath} from 'react-router-dom';

import {entities} from '../../api/proto';
import Paths from '../../routes/Paths';
import {uint8ArrayToUuid} from '../../utils/arrayUtils';
import formatMoneyInDecimals from '../../utils/format/formatMoneyInDecimals';
import {AppStore} from '../AppStore';
import Channel from '../Channel';
import getChannelTypeName from '../Channel/utils/getChannelTypeName';
import {getUserRoleTitle} from '../Workspaces';
import AnalyticsEvent from './AnalyticsEvent';
import PageType from './PageType';
import UserType from './UserType';
import getPageType from './utils/getPageType';
import getPreferableTariffId from './utils/getPreferableTariffId';
import getUserType from './utils/getUserType';


export class Analytics {
  constructor(protected app: AppStore) {
  }

  protected visits_: Record<string, boolean> = {};

  protected setVisited_ = (path: string) => {
    this.visits_[path] = true;
  };

  protected isVisited_ = (path: string): boolean => {
    return !!this.visits_[path];
  };

  protected push_ = (data: any) => {
    if (!window.dataLayer) {
      return;
    }

    window.dataLayer?.push(data);
  };

  protected pushEvent_ = (event: string, data: any) => {
    const formatedData = this.formatData_(event, data);
    console.debug('anal.pushEvent_', formatedData);
    this.push_(formatedData);
  };

  protected formatData_ = (event: string, data: any) => {
    const {currentMember, user} = this.app.userStore;
    const activeWorkspace = this.app.activeWorkspace;

    const info = {
      event,
      event_value: null,
      event_context: null,
      event_content: null,

      userAuth: user?.isLoggedIn ? '1' : '0',
      userId: user?.isLoggedIn ? user?.userId?.toString() : null,
      userType: getUserType(activeWorkspace, user),
      roleName: currentMember?.roleTitle || null,

      workspaceId: uint8ArrayToUuid(activeWorkspace.id) || null,
      workspaceName: activeWorkspace.name || null,

      page_location: window.location.href,
      ...data
    };

    return info;
  };

  public openRoute = (path: string, data: any = {}) => {
    console.debug('Analytics.openRoute', path);
    let pageType = getPageType(path);

    if (matchPath(Paths.Registration, path) && getPreferableTariffId()) {
      // prevent double event for registration page
      return;
    } else if (matchPath(Paths.Onboarding, path) && !this.isVisited_(path)) {
      pageType = PageType.Onboarding;
      this.setVisited_(path);
    }

    this.push_({ecommerce: null});
    this.pushEvent_('headchat_pageview', {
      pageType,
      ...data
    });
  };


  public openPage = (pageType: PageType, data: any = {}) => {
    console.debug('Analytics.openPage', pageType);
    this.push_({ecommerce: null});
    this.pushEvent_('headchat_pageview', {
      pageType,
      ...data
    });
  };

  public event = (event_name: AnalyticsEvent, data?: any) => {
    console.debug('Analytics.event', event_name);
    this.push_({ecommerce: null});
    this.pushEvent_('headchat_event', {
      event_name,
      ...data
    });
  };


  public registrationCheckout = (tariff: entities.ITariff) => {
    const price = formatMoneyInDecimals(tariff.price, tariff.currency);

    this.openPage(PageType.Cart, {
      s_event: 'view_cart',
      e_step: '2. view_cart: registration',

      discountAmount: 0,
      orderPrice: price,
      ecommerce: {
        currency: tariff.currency,
        items: [
          {
            item_name: tariff.name,
            item_id: tariff.id,
            price,
            item_category: 'Plans',
            quantity: 1
          },
        ],
      },
    });
  };

  public onboardingCheckout = (tariff?: entities.ITariff | null) => {
    const price = formatMoneyInDecimals(tariff?.price, tariff?.currency);

    this.openPage(PageType.Cart, {
      s_event: 'view_cart',
      e_step: '3. view_cart: onboarding plan selection',

      virtual_page_title: 'Onboarding Plans',
      virtual_page_path: window.location.pathname,

      discountAmount: 0,
      orderPrice: price,
      ecommerce: {
        currency: tariff?.currency,
        items: [
          {
            item_name: tariff?.name,
            item_id: tariff?.id,
            price,
            item_category: 'Plans',
            quantity: 1
          },
        ],
      },
    });
  };

  public onboardingSubscribe = (tariff: entities.ITariff) => {
    const price = formatMoneyInDecimals(tariff.price, tariff.currency);

    this.event(AnalyticsEvent.subscribe, {
      pageType: PageType.Checkout,

      event_value: price,
      event_context: 'Subscribe',
      event_content: 'Button',

      s_event: 'begin_checkout',
      e_step: '4. begin_checkout: subscription',

      discountAmount: 0,
      orderPrice: price,
      ecommerce: {
        currency: tariff.currency,
        items: [
          {
            item_name: tariff.name,
            item_id: tariff.id,
            price,
            item_category: 'Plans',
            quantity: 1
          },
        ],
      },
    });
  };

  public tariffUpgrade = (tariff: entities.ITariff) => {
    const price = formatMoneyInDecimals(tariff.price, tariff.currency);

    this.event(AnalyticsEvent.upgrade, {
      pageType: PageType.Checkout,

      event_value: price,
      event_context: 'Upgrade',
      event_content: 'Button',

      s_event: 'begin_checkout',
      e_step: '5. begin_checkout: upgrade',

      discountAmount: 0,
      orderPrice: price,
      ecommerce: {
        currency: tariff.currency,
        items: [
          {
            item_name: tariff.name,
            item_id: tariff.id,
            price,
            item_category: 'Plans',
            quantity: 1
          },
        ],
      },
    });
  };

  public tariffPaymentSuccess = (tariff: entities.ITariff) => {
    const price = formatMoneyInDecimals(tariff.price, tariff.currency);
    const {billing} = this.app.activeWorkspace;

    const transactionId = billing.transactionId ? billing.transactionId :
      `${this.app.userStore.userId?.toString()}__${tariff.id}__${billing.expiresAt?.toString()}`;

    this.openPage(PageType.ThankYouPage, {
      userType: UserType.Customer,

      s_event: 'purchase',
      e_step: '6. purchase',

      billingCountry: null,
      billingState: null,
      billingCity: null,

      discountAmount: 0,
      orderPrice: price,
      ecommerce: {
        currency: tariff.currency,
        transaction_id: transactionId,
        value: price,
        tax: 0,
        coupon: null,
        items: [
          {
            item_name: tariff.name,
            item_id: tariff.id,
            price,
            item_category: 'Plans',
            quantity: 1
          },
        ],
      },
    });
  };

  public loginEvent = () => {
    this.event(AnalyticsEvent.login_success, {
      pageType: PageType.ProductSettings,
    });
  };

  public signupEvent = () => {
    this.event(AnalyticsEvent.signup_success, {
      pageType: PageType.Onboarding,
    });
  };

  public fileDownloadClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    this.event(AnalyticsEvent.file_download, {
      pageType: PageType.Entrance,
      event_context: e.currentTarget.innerText,
      link_url: e.currentTarget.href,
      link_text: e.currentTarget.innerText,
      event_content: 'Link',
    });
  };

  public inviteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.event(AnalyticsEvent.invite_btn_click, {
      pageType: PageType.ProductSettings,
      event_context: e.currentTarget.innerText,
      event_content: 'Button',
    });
  };

  public inviteEvent = (invite: entities.IInvite) => {
    this.event(AnalyticsEvent.user_invite_sent, {
      pageType: PageType.ProductSettings,
      event_context: getUserRoleTitle(invite.role),
    });
  };

  public addChannelClick = () => {
    this.event(AnalyticsEvent.add_channel_btn_click, {
      pageType: PageType.ProductSettings,
      event_context: 'Add channel',
      event_content: 'Button',
    });
  };

  public channelSetupEvent = (channel: Channel) => {
    this.event(AnalyticsEvent.channel_setup, {
      pageType: PageType.ProductSettings,
      event_content: getChannelTypeName(channel.type),
      event_context: channel.name,
    });
  };

  public workspaceCreatedEvent = (workspaceName: string) => {
    this.event(AnalyticsEvent.workspace_created, {
      pageType: PageType.ProductSettings,
      event_context: workspaceName,
    });
  };
}

export default Analytics;