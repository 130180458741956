import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Navigate, useNavigate} from 'react-router';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';

import {ReactComponent as ExpiredInviteLinkIcon} from '../../assets/image-icons/expiredInviteLink.svg';

const ExpiredInvitePage: React.FC = observer(() => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {activeWorkspace} = useStore();

  const {invites} = activeWorkspace;

  const skipInviteHandle = (e) => {
    e.preventDefault();
    invites.clearRegistrationInvite();
    navigate(Paths.Login);
  };

  if (!invites.registrationInvite) {
    return <Navigate to={Paths.Login} replace />;
  }
  return (
    <div className="auth-page">
      <div className="auth-page__expired-invite">
        <div className="auth-page__expired-invite-inner">
          <ExpiredInviteLinkIcon className="mb-4" />
          <div className="auth-page__title mb-4">
            {invites.registrationInvite.used ? t('used_invite_page_title') : t('expired_invite_page_title')}
          </div>
          <div className="body2-regular mb-4">
            {t('expired_invite_page_subtitle', {name: invites.registrationInvite.workspaceName})}
          </div>
          <div className="body2-regular color-body-tertiary">{t('expired_invite_page_get_access_text')}</div>
          <div className="auth-page__expired-invite-item mt-5">
            <div className="body2-bold abel-1">{t('expired_invite_page_contact_the_person_text')}</div>
            <div className="body2-regular color-body-tertiary">{t('expired_invite_page_send_link_text')}</div>
          </div>
          <div className="auth-page__expired-invite-item">
            <div className="body2-bold abel-1">
              {t('expired_invite_page_visit_homepage_text')}
              <a onClick={skipInviteHandle} className="auth-page__expired-invite-item-link link ml-1">
                {t('expired_invite_page_visit_homepage_label')}
              </a>
            </div>
            <div className="body2-regular color-body-tertiary ">{t('expired_invite_page_log_directly_text')}</div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ExpiredInvitePage;
