import {computed, makeObservable} from 'mobx';

import {api} from '../../api/proto';
import TimerStore from '../TimerStore';
import InviteBase, {IInviteBase} from './InviteBase';
import WorkspaceMember from './WorkspaceMember';
import WorkspaceMemberConfig from './WorkspaceMemberConfig';
import WorkspaceStore from './WorkspaceStore';
import getErrorByInviteResendResult from './utils/getErrorByInviteResendResult';

export class Invite extends InviteBase {
  constructor(public raw: IInviteBase, private workspace: WorkspaceStore) {
    super(raw, workspace.workspace?.raw, workspace.app);
    this.registrationRequired = raw.registrationRequired;

    makeObservable(this);
  }

  config: WorkspaceMemberConfig = new WorkspaceMemberConfig(this.workspace);

  @computed get inviter(): WorkspaceMember | null {
    return this.workspace.findMember(this.inviterMemberId);
  }

  @computed get activator(): WorkspaceMember | null {
    return this.workspace.findMember(this.usedByMemberID);
  }

  @computed get boundToMember(): WorkspaceMember | null {
    return this.workspace.findMember(this.boundToMemberId);
  }

  delete = async () => {
    const {error, res} = await this.workspace.workspaceRequest<api.IWorkspaceInviteDeleteResponse>(
      {
        inviteDelete: {id: this.id},
      },
      'inviteDelete',
    );

    if (res) {
      this.processDeleteInvite();
    }

    return {error, res: res};
  };

  processDeleteInvite = (/*res?: api.IWorkspaceInviteDeleteResponse | null*/) => {
    this.workspace.refreshMembers();
  };

  resendRegistrationEmailTimer = new TimerStore();

  resendRegistrationEmail = async () => {
    const {error, res} = await this.workspace.workspaceRequest<api.IWorkspaceResendInviteResponse>(
      {
        resendInvite: {
          // memberId: this.id,
        },
      },
      'resendInvite',
    );

    let message: string | null | undefined = null;

    if (res) {
      message = getErrorByInviteResendResult(res.status);
      this.resendRegistrationEmailTimer.runByNextTryAtTimestamp(res.nextTryTimestamp);
    } else if (error) {
      message = error.message;
    }

    return {error: message ? {message} : null, res};
  };
}

export default Invite;
