export default function convertWidthToNewHeight(
  newHeight: number,
  prevWidth: number = 0,
  prevHeight: number = 0,
) {
  if (!prevWidth || !prevHeight) {
    return [0, 0];
  }
  const ratio = newHeight / prevHeight;

  const newWidth = Math.floor(prevWidth * ratio);

  return [newWidth, newHeight];
}