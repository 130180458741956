import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import {Message} from '../../../../../stores/Message';

interface ChatLastMessageFileIconProps {
  className?: string;
  lastChatMessage?: Message | null;
}

export const ChatLastMessageFileIcon: React.FC<ChatLastMessageFileIconProps> = observer(
  ({className, lastChatMessage}: ChatLastMessageFileIconProps) => {
    const {t} = useTranslation();
    const firstAttachment = lastChatMessage?.firstAttachment;

    return (
      <>
        {firstAttachment?.isImage ? <i className={cn(className, 'tg-icon tg-icon-image-fill')} /> : null}
        {firstAttachment?.isDocument ? <i className={cn(className, 'tg-icon tg-icon-file-text-fill')} /> : null}
        {firstAttachment?.isAudio || firstAttachment?.isVoiceNote ? (
          <i className={cn(className, 'tg-icon tg-icon-music-fill')} />
        ) : null}
        {firstAttachment?.isVideo ? <i className={cn(className, 'tg-icon tg-icon-video-fill')} /> : null}
        {firstAttachment?.isGif ? (
          <>
            <i className={cn(className, 'tg-icon tg-icon-image-fill')} />{' '}
            <small>{t('chat_last_message_gif_title')}</small>
          </>
        ) : null}
        {firstAttachment?.isSticker ? (
          <>
            <i className={cn(className, 'tg-icon tg-icon-image-fill')} /> {t('chat_last_message_gif_sticker')}
          </>
        ) : null}
      </>
    );
  },
);

export default ChatLastMessageFileIcon;
