import React from 'react';

interface IWindowControlsProps {
  onMinimize?: () => void;
  onMaximalize?: () => void;
  onClose?: () => void;
  isMaximized?: boolean;
}

const WindowControls = ({
  onMinimize,
  onMaximalize,
  onClose,
  isMaximized,
}: IWindowControlsProps) => {
  return (
    <div className="window-title-bar__controls">
      <button
        className="window-title-bar__minimize-button"
        onClick={onMinimize}
      >
        <svg
          className="window-title-bar__button-icon"
          aria-hidden="true"
          version="1.1"
          width="10"
          height="10"
        >
          <path d="M 0,5 10,5 10,6 0,6 Z" />
        </svg>
      </button>

      <button
        className="window-title-bar__min-max-button"
        onClick={onMaximalize}
      >
        <svg
          className="window-title-bar__button-icon"
          aria-hidden="true"
          version="1.1"
          width="10"
          height="10"
        >
          <path
            d={
              isMaximized
                ? 'm 2,1e-5 0,2 -2,0 0,8 8,0 0,-2 2,0 0,-8 z m 1,1 6,0 0,6 -1,0 0,-5 -5,0 z m -2,2 6,0 0,6 -6,0 z'
                : 'M 0,0 0,10 10,10 10,0 Z M 1,1 9,1 9,9 1,9 Z'
            }
          />
        </svg>
      </button>

      <button
        className="window-title-bar__close-button"
        onClick={onClose}
      >
        <svg
          className="window-title-bar__button-icon"
          aria-hidden="true"
          version="1.1"
          width="10"
          height="10"
        >
          <path d="M 0,0 0,0.7 4.3,5 0,9.3 0,10 0.7,10 5,5.7 9.3,10 10,10 10,9.3 5.7,5 10,0.7 10,0 9.3,0 5,4.3 0.7,0 Z" />
        </svg>
      </button>
    </div>
  );
};

export default WindowControls;
