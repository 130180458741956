import React from 'react';

interface FileInputProps {
  className?: string;
  accept?: string;
  onChange?: (files: File[]) => void;
  multiple?: boolean;
}

export const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      const files: File[] = [];

      for (let i = 0; i < e.target.files.length; i++) {
        files.push(e.target.files[i]);
      }

      props.onChange && props.onChange(files);
      e.target.value = '';
    }
  };

  return (
    <input
      ref={ref}
      className={props.className}
      onChange={handleFileChange}
      accept={props.accept}
      type="file"
      multiple={props.multiple}
      name="fileInput"
    />
  );
});

FileInput.displayName = 'FileInput';

export default FileInput;