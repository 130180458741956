import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';

import {useStore} from '../../../../stores/AppStore';
import ModalType from '../../../../stores/ModalType';
import Invite from '../../../../stores/Workspaces/Invite';
import WorkspaceMember from '../../../../stores/Workspaces/WorkspaceMember';
import InviteManageForm from './InviteManageForm';
import ResendInviteButton from './ResendInviteButton';

interface InvitedMemberManageTabProps {
  member: WorkspaceMember;
  className?: string;
  bodyClassName?: string;
  onSubmit?: () => void;
  onClose?: () => void;
}

export const InvitedMemberManageTab: React.FC<InvitedMemberManageTabProps> = observer((props) => {
  const {t} = useTranslation();
  const {modals, notifications} = useStore();

  const invite = props.member.emailInvite;

  if (!invite) {
    return null;
  }

  const deleteInvite = async (invite: Invite) => {
    const {error, res} = await invite.delete();
    if (error) {
      notifications.error(error.message);
    }
    if (res) {
      notifications.success(t('settings_workspace_members_manage_invite_deleted_notification', {email: invite.email}));
      props.onSubmit?.();
    }
  };

  const handleDeleteClick = () => {
    modals.open(ModalType.INVITE_DELETING, {
      invite: invite,
      callbacks: {
        submit: async (data) => {
          if (data.invite) {
            await deleteInvite(data.invite);
          }
        },
      },
    });
  };

  return (
    <div className={cn('channel-settings', props.className)}>
      <div className={cn('channel-settings__wrap', props.bodyClassName)}>
        <div className="channel-settings__paper px-6 h-100 border-primary radius-8">
          <InviteManageForm member={props.member} />
        </div>
      </div>

      <div className="settings-tab__footer manage-operators">
        <div className="left">
          <Button variant="contained" color="secondary" size="large" className="mr-5" onClick={props.onClose}>
            {t('settings_workspace_members_manage_close')}
          </Button>
        </div>

        <div className="right">
          <ResendInviteButton
            className="ml-auto"
            size="large"
            member={props.member}
          />

          <Button
            variant="contained"
            color="error"
            size="large"
            className="nowrap ml-3"
            onClick={handleDeleteClick}
          >
            {t('settings_workspace_members_manage_delete_invite')}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default InvitedMemberManageTab;
