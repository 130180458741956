import React from 'react';
import cn from 'classnames';
import * as uuid from 'uuid';
import {useTranslation} from 'react-i18next';
import {arrayMove} from '@dnd-kit/sortable';

import Button from 'o-ui/Button';
import {PlusLine} from 'o-ui/icons';
import WebWidgetSnippetItem from './WebWidgetSnippetItem';
import WebWidgetSnippetForm from './WebWidgetSnippetForm';
import SortableContainer from '../../../../../components/SortableContainer';


export interface WebWidgetSnippet {
  id?: string;
  text?: string | null;
  enabled?: boolean | null;
}

interface WebWidgetSnippetsListProps {
  className?: string;
  snippets?: WebWidgetSnippet[] | null;
  onAdd?: (option: WebWidgetSnippet) => void;
  onEdit?: (index: number, option: WebWidgetSnippet) => void;
  onRemove?: (index: number, option: WebWidgetSnippet) => void;
  onChange?: (option: WebWidgetSnippet[]) => void;
}

export const WebWidgetSnippetsList: React.FC<WebWidgetSnippetsListProps> = (props) => {
  const {t} = useTranslation();
  const [addFormOpened, setAddFormOpened] = React.useState<boolean>(false);
  const snippets = props.snippets?.map((snippet) => ({...snippet, id: uuid.v4()})) || ([] as WebWidgetSnippet[]);

  const handleAddNewSnippet = (snippetText: string) => {
    const newSnippet = {text: snippetText, enabled: true};
    props.onAdd?.(newSnippet);
    props.onChange?.([...snippets, newSnippet]);
    setAddFormOpened(false);
  };

  const handleEdit = (index: number, snippet: WebWidgetSnippet) => {
    const newSnippets = snippets.slice();
    newSnippets.splice(index, 1, snippet);
    props.onEdit?.(index, snippet);
    props.onChange?.([...newSnippets]);
  };

  const handleRemove = (index: number, snippet: WebWidgetSnippet) => {
    const newSnippets = snippets.slice();
    newSnippets.splice(index, 1);
    props.onRemove?.(index, snippet);
    props.onChange?.([...newSnippets]);
  };

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const sortedSnippets = arrayMove(snippets, oldIndex, newIndex);
    props.onChange?.(sortedSnippets);
  };

  const openAddForm = () => setAddFormOpened(true);
  const closeAddForm = () => setAddFormOpened(false);

  return (
    <div className={cn('webwidget-snippets-list', props.className)}>
      <SortableContainer<WebWidgetSnippet> items={snippets} onSortEnd={onSortEnd}>
        {snippets.map((snippet, idx) => (
          <WebWidgetSnippetItem
            key={snippet.id}
            index={idx}
            snippet={snippet}
            onEdit={handleEdit}
            onRemove={handleRemove}
          />
        ))}
      </SortableContainer>

      <div className="mt-3">
        {addFormOpened ? (
          <WebWidgetSnippetForm
            inputName="accountName"
            onSubmit={handleAddNewSnippet}
            onClose={closeAddForm}
          />
        ) : (
          <Button variant="contained" color="secondary" size="small" onClick={openAddForm}>
            <PlusLine className="icon-size-14 mr-1" />
            {t('Add')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default WebWidgetSnippetsList;
