import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {arrayMove} from '@dnd-kit/sortable';

import {ModalType} from '../../../stores/ModalType';
import {useStore} from '../../../stores/AppStore';

import {TagType, filterTagsList, ITag} from '../../../stores/MessageTagsStore';
import {MessageTagItem} from '../../../components/MessageTagItem';
import {MessageTagsNoTagsItem} from './MessageTagsNoTagsItem';
import SortableContainer from '../../../components/SortableContainer';


interface MessageTagsTableProps {
  selectedType: TagType;
  filter: string;
}

export const MessageTagsTable: React.FC<MessageTagsTableProps> = observer(({selectedType, filter}) => {
  const {t} = useTranslation();
  const {modals} = useStore();
  /*
  const {
    tags: {list: tagsList},
  } = chatStore;
  */
  const [tagsList] = React.useState<ITag[]>([]); // chatStore.tags.list;

  const [displayTagsList, setDisplayTagsList] = React.useState<ITag[]>([]);

  const isPersonalTags = selectedType === TagType.Personal;
  const userIsAdmin = true; // user?.isAdmin || systemUser?.isOwner

  const canDeleteTag = userIsAdmin || isPersonalTags;
  const canEditTag = userIsAdmin || isPersonalTags;
  const canSortedTags = (userIsAdmin || isPersonalTags) && !filter;

  const handleEditTag = (tag: ITag) => {
    modals.open(ModalType.MESSAGE_TAG_CREATING, {
      tag,
    });
  };

  const handleDeleteTag = (tag: ITag) => {
    modals.open(ModalType.CONFIRM, {
      headerText: tag.isWorkspace ? t('settings_message_tags_delete_workspace_tag') : t('settings_message_tags_delete_personal_tag'),
      content: (
        <>
          <div className="h6-medium mb-2">
            {t('settings_message_tags_confirm_deleting')} <MessageTagItem tag={tag} /> {t('settings_message_tags_tag')}
          </div>
          <div className="body2-regular">
            {t('settings_message_tags_delete_tag_info')}
          </div>
        </>
      ),
      submitButtonText: t('settings_message_tags_delete_tag'),
      callbacks: {
        submit: () => {
          // chatStore.tags.delete(tag);
        },
      },
    });
  };

  React.useEffect(() => {
    setDisplayTagsList(filterTagsList(tagsList, selectedType, filter));
  }, [tagsList, selectedType, filter]);

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const sortedItems = arrayMove(displayTagsList, oldIndex, newIndex);
    // const sortedIds = sortedItems.map((item) => item.id);

    setDisplayTagsList(sortedItems);
    // await chatStore.tags.updateOrdering(sortedIds as Long[]);
  };

  if (displayTagsList.length < 1) {
    return (
      <div className="message-tags-tab__tags-table">
        <MessageTagsNoTagsItem tagType={selectedType} />
      </div>
    );
  }

  return (
    <div className="message-tags-tab__tags-table">
      <SortableContainer<ITag> items={displayTagsList} onSortEnd={onSortEnd}>
        {displayTagsList.map((item) => (
          <MessageTagItem
            className="message-tags-tab__tags-table-item"
            key={item.id?.toString()}
            tag={item}
            onDelete={handleDeleteTag}
            onEdit={handleEditTag}
            dragging={canSortedTags}
            disableDelete={!canDeleteTag}
            disableEdit={!canEditTag}
            tableMode
            bordered
          />
        ))}
      </SortableContainer>
    </div>
  );
});

export default MessageTagsTable;
