import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';

import Peer from '../../../../stores/Peer';


interface PeerStatusTitleProps {
  className?: string;
  peer: Peer;
}

export const PeerStatusTitle = observer((props: PeerStatusTitleProps) => {
  return props.peer?.statusTitle ? (
    <span
      className={cn(props.className, {
        'body3-bold color-success-500': false,
      })}
    >
      {props.peer.statusTitle}
    </span>
  ) : null;
});

export default PeerStatusTitle;
