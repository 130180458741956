import {MAX_MEDIA_PREVIEW_HEIGHT, MAX_MEDIA_PREVIEW_WIDTH} from '../../constants';

export function calcImageSize(
  width: number,
  height: number,
  maxWidth: number = MAX_MEDIA_PREVIEW_WIDTH,
  maxHeight: number = MAX_MEDIA_PREVIEW_HEIGHT,
  noDefaultSizesPossible = false,
) {
  let k = 1;

  let newWidth = width;
  let newHeight = height;

  if (width > maxWidth && height > maxHeight) {
    if (maxWidth / width > maxHeight / height) {
      k = maxHeight / height;
      newWidth = k * width;
      newHeight = maxHeight;
    } else {
      k = maxWidth / width;
      newWidth = maxWidth;
      newHeight = k * height;
    }
  } else if (width > maxWidth) {
    k = maxWidth / width;
    newWidth = maxWidth;
    newHeight = k * height;
  } else if (height > maxHeight) {
    k = maxHeight / height;
    newWidth = k * width;
    newHeight = maxHeight;
  } else if (!height && !width && noDefaultSizesPossible) {
    newHeight = maxHeight;
    newWidth = maxWidth;
  }

  return [newWidth, newHeight];
}

export default calcImageSize;
