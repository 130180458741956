import Long from 'long';
import browserStorage from './browserStorage';

export function getCacheData<T = any>(dataSetName: string, expiration: number): T | null {
  const cacheDataStr = browserStorage.getItem(dataSetName);
  if (cacheDataStr) {
    try {
      const cacheData = JSON.parse(cacheDataStr, (key, value) => {
        if (typeof value === 'string' && new RegExp('^[0-9]{9,80}$').test(value)) {
          return Long.fromString(value);
        }
        return value;
      });
      const cacheDataStamp: number = (cacheData && cacheData.stamp) || 0;
      const stampDiff = new Date().getTime() - cacheDataStamp;
      if (cacheData.data && expiration && cacheDataStamp && stampDiff < expiration) {
        return cacheData.data as T;
      }
    } catch (e) {
      console.log(e);
    }
  }
  return null;
}

export function setCacheData(dataSetName: string, data: any) {
  if (dataSetName) {
    try {
      const cacheData = {
        data,
        stamp: new Date().getTime(),
      };

      const cacheDataStr = JSON.stringify(cacheData);
      browserStorage.setItem(dataSetName, cacheDataStr);
    } catch (e) {
      console.log(e);
    }
  }
}
