import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';

import {TagType} from '../../../stores/MessageTagsStore';
import {useStore} from '../../../stores/AppStore';
import {ModalType} from '../../../stores/ModalType';

import {ReactComponent as NoTags} from '../../../assets/image-icons/messageTagNoTags.svg';
import {ReactComponent as NoTagsDarkTheme} from '../../../assets/image-icons/messageTagNoTagsDarkTheme.svg';
import {ReactComponent as PlusIcon} from '../../../assets/icons/plus-line.svg';
import Button from 'o-ui/Button';

interface IProps {
  tagType: TagType;
}

export const MessageTagsNoTagsItem: React.FC<IProps> = observer(({tagType}) => {
  const {t} = useTranslation();

  const {modals, lightThemeChosen} = useStore();

  const userIsAdmin = true; // user?.isAdmin || systemUser?.isOwner

  const titles = {
    [TagType.Workspace]: t('settings_message_tags_workspace_tags_not_found'),
    [TagType.Personal]: t('settings_message_tags_personal_tags_not_found'),
  };

  const descriptions = {
    [TagType.Workspace]: userIsAdmin
      ? t('settings_message_tags_workspace_descriptions_for_admin')
      : t('settings_message_tags_workspace_descriptions_for_user'),
    [TagType.Personal]: t('settings_message_tags_personal_descriptions'),
  };

  const createButtonTexts = {
    [TagType.Workspace]: userIsAdmin
      ? t('settings_message_tags_create_workspace_tag')
      : t('settings_message_tags_create_personal_tag'),
    [TagType.Personal]: t('settings_message_tags_create_personal_tag'),
  };

  const handleCreateTag = () => {
    modals.open(ModalType.MESSAGE_TAG_CREATING, {
      tag: {
        isWorkspace: userIsAdmin ? tagType === TagType.Workspace : false,
      },
    });
  };

  return (
    <div className="message-tags-tab__no-tags text-center m-auto">
      <div>
        {lightThemeChosen ? <NoTags className="mb-6" /> : <NoTagsDarkTheme className="mb-6" />}

        <div className="message-tags-tab__no-tags-title mb-3">{titles[tagType]}</div>

        <div className="message-tags-tab__no-tags-description  mb-6">{descriptions[tagType]}</div>

        <Button className="ml-3" variant="contained" color="secondary" onClick={handleCreateTag}>
          <PlusIcon className="message-tags-tab__add-icon" />
          {createButtonTexts[tagType]}
        </Button>
      </div>
    </div>
  );
});

export default MessageTagsNoTagsItem;
