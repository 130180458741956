import {useTranslation} from 'react-i18next';

import {useStore} from '../../stores/AppStore';
import someFileIsEmpty from '../../utils/file/someFileIsEmpty';
import {FileData, dataFileReader} from '../../utils/file/fileReaders';

export default function useFileChangeHendler(hendler: (dataFiles: FileData[]) => void) {
  const {t} = useTranslation();
  const {notifications} = useStore();

  return (files: File[]) => {
    if (someFileIsEmpty(files)) {
      notifications.error(t('file_uploader_file_is_empty'));
      return;
    }
    dataFileReader(files, hendler);
  };
}