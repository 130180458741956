import React from 'react';
import {useTranslation} from 'react-i18next';

import SettingsTab from '../../stores/LayOutStore/SettingsTab';
import MessageTagsViewer from './MessageTags/MessageTagsViewer';
import {SettingsHeadState} from './SettingsHead';
import useAllowChangeHeadState from './useAllowChangeHeadState';

interface MessageTagsTabProps {
  onChangeState?: (state: SettingsHeadState) => void;
}

export const MessageTagsTab = React.memo((props: MessageTagsTabProps) => {
  const {t} = useTranslation();

  const allowChangeHeadState = useAllowChangeHeadState(SettingsTab.MESSAGE_TAGS);

  React.useEffect(() => {
    if (!allowChangeHeadState) {
      return;
    }
    props.onChangeState?.({
      title: t('settings_message_tags_tag_management'),
    });
  }, [props, allowChangeHeadState, t]);

  return (
    <div className="settings-tab">
      <div className="settings-tab__body custom-scroll">
        <div className="settings-tab__paper w-100 h-100">
          <div className="settings-tab__table message-tags-tab__table">
            <MessageTagsViewer />
          </div>
        </div>
      </div>
    </div>
  );
});

export default MessageTagsTab;
