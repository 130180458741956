import React from 'react';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import Button, {IconButton} from 'o-ui/Button';
import {ReactComponent as DragIcon} from '../assets/icons/drag-icon.svg';
import {DEFAULT_MESSAGE_TAG_COLOR, ITag} from '../stores/MessageTagsStore';


interface IProps {
  tag: ITag;
  className?: string;
  labelClassName?: string;
  dragging?: boolean;
  noWrap?: boolean;
  noWrapMaxWidth?: number;
  tableMode?: boolean;
  bordered?: boolean;
  disableDelete?: boolean;
  disableEdit?: boolean;
  onSelect?(tag: ITag): void;
  onCancel?(tag: ITag): void;
  onDelete?(tag: ITag): void;
  onEdit?(tag: ITag): void;
}

export const MessageTagItem: React.FC<IProps> = ({
  tag,
  className,
  labelClassName,
  onDelete,
  onEdit,
  onCancel,
  onSelect,
  noWrapMaxWidth,
  disableDelete = false,
  disableEdit = false,
  dragging = false,
  noWrap = false,
  bordered = false,
  tableMode = false,
}) => {
  const {t} = useTranslation();
  const {setNodeRef, attributes, listeners, transform, transition, isSorting, index, activeIndex} = useSortable({
    id: tag.id?.toString() || '',
  });

  const sortingStyle = {
    transform: CSS.Translate.toString(transform),
    transition,
    touchAction: 'none',
  };

  const isNoWrap = Boolean(!tableMode && (noWrap || noWrapMaxWidth));

  const tagLabel = (
    <span
      className={cn('message-tag-item message-tag-item__label radius-8', labelClassName, {
        'message-tag-item__label-with-cancel': !!onCancel,
        'message-tag-item__label-no-wrap': isNoWrap,
      })}
      style={{
        backgroundColor: tag.color || DEFAULT_MESSAGE_TAG_COLOR,
        maxWidth: noWrapMaxWidth || 'none',
      }}
    >
      {tag.text || ''}
      {onCancel && (
        <IconButton
          className="message-tag-item__label-cancel-button tg-icon-cross-line"
          onClick={(e) => {
            e.stopPropagation();
            onCancel(tag);
          }}
          size="extraSmall"
        />
      )}
    </span>
  );

  if (tableMode === false) {
    return tagLabel;
  } else {
    return (
      <div
        ref={setNodeRef}
        className={cn('message-tag-item message-tag-item__table-mode radius-8', className, {
          'message-tag-item__bordered': bordered,
          'message-tag-item__selectable': Boolean(onSelect),
          sortable: index === activeIndex,
          sorting: isSorting,
        })}
        onClick={() => {
          onSelect && onSelect(tag);
        }}
        style={sortingStyle}
      >
        {dragging && (
          <IconButton className="message-tag-item__drag-button" {...listeners} {...attributes}>
            <DragIcon className="message-tag-item__drag-button-icon" />
          </IconButton>
        )}

        {tagLabel}

        {(onDelete || onEdit) && !isSorting && (
          <div className="message-tag-item__action-btn-container">
            {onDelete && (
              <Button
                className="message-tag-item__action-btn ml-3"
                variant="contained"
                color="errorSecondary"
                disabled={disableDelete}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(tag);
                }}
              >
                {t('settings_message_tags_tag_item_delete')}
              </Button>
            )}

            {onEdit && (
              <Button
                className="message-tag-item__action-btn ml-3"
                variant="contained"
                color="primary"
                disabled={disableEdit}
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(tag);
                }}
              >
                {t('settings_message_tags_tag_item_edit')}
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
};

export default MessageTagItem;
