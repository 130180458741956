import React from 'react';

import ChatsTab from './Tabs/ChatsTab/ChatsTab';
//import ContactsTab from './Tabs/ContactsTab/ContactsTab';
//import TeamTab from './Tabs/TeamTab';
import SettingsTab from './Tabs/SettingsMenuTab/SettingsMenuTab';
//import OperatorsTab from './Tabs/OperatorsTab';
import {ChatTab} from '../../stores/LayOutStore';

interface ChatLeftSidebarTabProps {
  tab?: ChatTab;
}

export const ChatLeftSidebarTab = React.memo(({tab}: ChatLeftSidebarTabProps) => {
  switch (tab) {
    case ChatTab.CHATS:
      return <ChatsTab />;
    //case ChatTab.CONTACTS:
    //  return <ContactsTab />;
    //case ChatTab.TEAM:
    //  return <TeamTab />;
    //case ChatTab.OPERATORS:
    //  return <OperatorsTab />;
    case ChatTab.SETTINGS:
      return <SettingsTab />;
  }
  return null;
});

export default ChatLeftSidebarTab;
