import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import DialogActions from 'o-ui/Dialog/DialogActions';
import DialogContent from 'o-ui/Dialog/DialogContent';

import getChannelTypeName from '../stores/Channel/utils/getChannelTypeName';
import {ModalItem} from '../stores/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface ModalChatUnblockingProps {
  modal: ModalItem;
}

export const ModalChatUnblocking = (props: ModalChatUnblockingProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const {chat} = props.modal.data;

  const handleSubmit = () => {
    props.modal.trigger('submit', props.modal.data);
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  return (
    <ModalDialog ref={modalRef} modal={props.modal} title={t('modal_unblock_chat_title')} maxWidth="md">
      <DialogContent className="py-4" style={{width: '455px'}}>
        <div className="h4-bold font-bold color-body-primary">{t('modal_unblock_chat_confirm')}</div>

        <div className="account-dialog-form__field-label">{t('modal_unblock_chat_chat_name')}</div>
        <div className="account-dialog-form__field-value">{chat?.name || '-'}</div>

        <div className="account-dialog-form__field-label">{t('modal_unblock_chat_messenger')}</div>
        <div className="account-dialog-form__field-value">{getChannelTypeName(chat?.channel?.type)}</div>

        <div className="account-dialog-form__field-label">{t('modal_unblock_chat_account')}</div>
        <div className="account-dialog-form__field-value">{chat?.channel?.name || '-'}</div>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className="account-dialog-form__connect-button"
          onClick={handleSubmit}
        >
          {t('modal_unblock_chat_unblock')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          className="account-dialog-form__cancel-button"
          onClick={handleCloseClick}
        >
          {t('modal_cancel_btn')}
        </Button>
      </DialogActions>
    </ModalDialog>
  );
};

export default ModalChatUnblocking;
