import {action, computed, makeObservable, observable} from 'mobx';

import isGroup from '../../stores/Chat/utils/isGroup';
import isPrivate from '../../stores/Chat/utils/isPrivate';
// import {trimPhone} from '../../utils/getPhoneMask';
import {AppStore} from '../AppStore';
import Chat from '../Chat/Chat';
import ChatsView from './ChatsView';

export enum ChatsFilterType {
  SEARCH_ONLY,
  COMMON_FILTER,
  WEB_WIDGET_FILTER,
}

enum FilterType {
  Opened,
  InProgress,
  Resolved,
}

enum InternalFilterType {
  Group,
  Private,
}

export default class ChatsFilterStore {
  constructor(public chatsView: ChatsView, public app: AppStore) {
    makeObservable(this);
  }

  @computed get type(): ChatsFilterType {
    if (this.app.activeWorkspace.channels.selectedChannel) {
      if (this.app.activeWorkspace.channels.selectedChannel?.assignmentEnabled) {
        return ChatsFilterType.WEB_WIDGET_FILTER;
      } else {
        return ChatsFilterType.SEARCH_ONLY;
      }
    } else if (this.app.activeWorkspace.availableChannels.some((channel) => channel.assignmentEnabled)) {
      return ChatsFilterType.COMMON_FILTER;
    }

    return ChatsFilterType.SEARCH_ONLY;
  }

  @observable filterGroupChats = false;
  @observable filterPrivateChats = false;
  @observable filterOpenedChats = false;
  @observable filterResolvedChats = false;
  @observable filterUnreadChats = false;
  @observable filterInprogressChats = false;

  @action toggleGroupChats = () => {
    this.filterGroupChats = !this.filterGroupChats;
    this.filterPrivateChats = false;
  };

  @action togglePrivateChats = () => {
    this.filterGroupChats = false;
    this.filterPrivateChats = !this.filterPrivateChats;
  };

  @action toggleOpenedChats = () => {
    this.filterResolvedChats = false;
    this.filterInprogressChats = false;
    this.filterOpenedChats = !this.filterOpenedChats;
  };

  @action toggleResolvedChats = () => {
    this.filterOpenedChats = false;
    this.filterInprogressChats = false;
    this.filterResolvedChats = !this.filterResolvedChats;
  };

  @action toggleUnreadChats = () => {
    this.filterUnreadChats = !this.filterUnreadChats;
  };

  @action toggleInprogressChats = () => {
    this.filterOpenedChats = false;
    this.filterResolvedChats = false;
    this.filterInprogressChats = !this.filterInprogressChats;
  };

  @action toggleToAllChats = () => {
    this.filterGroupChats = false;
    this.filterPrivateChats = false;
    this.filterOpenedChats = false;
    this.filterResolvedChats = false;
  };

  @action reset = () => {
    this.filterGroupChats = false;
    this.filterPrivateChats = false;
    this.filterOpenedChats = false;
    this.filterResolvedChats = false;
    this.filterUnreadChats = false;
    this.filterInprogressChats = false;

    if (this.type === ChatsFilterType.WEB_WIDGET_FILTER) {
      this.filterOpenedChats = true;
    }
  };

  commonFilterChat = (chat: Chat, searchText: string, filterUnread: boolean) => {
    return (
      (!searchText || chat.displayTitle.toLowerCase().includes(searchText)) /*||
        chat.user?.email?.toLowerCase().includes(searchText) ||
        chat.user?.phoneNumber?.includes(trimPhone(searchText)) ||
        chat.user?.widgetUserId?.toLowerCase().includes(searchText) ||
        chat.user?.metadata?.toLowerCase().includes(searchText)
        */ &&
      (!filterUnread || chat.unreadCount > 0)
    );
  };

  filterChat = (chat: Chat, filterOpened: boolean, filterResolved: boolean, filterInProgress: boolean) => {
    console.debug(`TODO: not implemented method "filterChat"`, chat, filterOpened, filterResolved, filterInProgress);
    return false;
    /*
    return (
      (!filterOpened || !chat.resolveInfo) &&
      (!filterResolved || chat.resolveInfo) &&
      (!filterInProgress || chat.assignedOperators.length > 0)
    );
    */
  };

  filterInternalChat = (chat: Chat, filterGroup: boolean, filterPrivate: boolean) => {
    return (!filterGroup || isGroup(chat)) && (!filterPrivate || isPrivate(chat));
  };
  /*
  @computed get chats() {
    // const trimmedLoweredText = this.chatsView.search.searchText.toLowerCase();
    // return trimmedLoweredText ||
    //   this.filterOpenedChats ||
    //   this.filterResolvedChats ||
    //   this.filterUnreadChats ||
    //   this.filterInprogressChats
    //   ? this.chatsView.search.searchChatsResult.filter(
    //       (chat) =>
    //         this.commonFilterChat(chat, trimmedLoweredText, this.filterUnreadChats) &&
    //         this.filterChat(chat, this.filterOpenedChats, this.filterResolvedChats, this.filterInprogressChats),
    //     )
    //   : this.chatsView.chats;

    return this.chatsView.chats;
  }
  */
  @computed get internalChats(): Chat[] {
    /*
    const trimmedLoweredText = this.chatsView.internalSearch.searchText.toLowerCase();
    return trimmedLoweredText || this.filterGroupChats || this.filterPrivateChats || this.filterUnreadChats
      ? this.chatsView.internalChats.filter(
          (chat) =>
            this.commonFilterChat(chat, trimmedLoweredText, this.filterUnreadChats) &&
            this.filterInternalChat(chat, this.filterGroupChats, this.filterPrivateChats),
        )
      : this.chatsView.internalChats;
      */
    return [];
  }

  getAllowedChatFilterToggleFunc = (chat: Chat): (() => void) | null => {
    console.debug(`TODO: not implemented method "getAllowedChatFilterToggleFunc"`, chat);
    const allowingByFilterType: Record<FilterType, boolean> = {
      [FilterType.Opened]: false, //!this.filterOpenedChats && !chat.resolveInfo,
      [FilterType.Resolved]: false, //!this.filterResolvedChats && !!chat.resolveInfo,
      [FilterType.InProgress]: false, //!this.filterInprogressChats && chat.assignedOperators.length > 0,
    };

    const toggleFuncByType: Record<FilterType, () => void> = {
      [FilterType.Opened]: this.toggleOpenedChats,
      [FilterType.Resolved]: this.toggleResolvedChats,
      [FilterType.InProgress]: this.toggleInprogressChats,
    };

    for (const filterType in allowingByFilterType) {
      if (allowingByFilterType[filterType]) {
        return toggleFuncByType[filterType];
      }
    }

    return null;
  };

  getAllowedInternalChatFilterToggleFunc = (chat: Chat): (() => void) | null => {
    const allowingByFilterType: Record<InternalFilterType, boolean> = {
      [InternalFilterType.Group]: !this.filterGroupChats && isGroup(chat),
      [InternalFilterType.Private]: !this.filterPrivateChats && isPrivate(chat),
    };

    const toggleFuncByType: Record<InternalFilterType, () => void> = {
      [InternalFilterType.Group]: this.toggleGroupChats,
      [InternalFilterType.Private]: this.togglePrivateChats,
    };

    for (const filterType in allowingByFilterType) {
      if (allowingByFilterType[filterType]) {
        return toggleFuncByType[filterType];
      }
    }

    return null;
  };

  autoToggleChatsFilter = () => {
    console.debug(`TODO: not implemented method "autoToggleChatsFilter"`);
    /*
    if (this.chats.length || this.chatsView.search.chatForSearch) {
      return;
    }

    const trimmedLoweredText = this.chatsView.search.searchText.toLowerCase();

    if (trimmedLoweredText || this.filterUnreadChats) {
      const _chats = this.chatsView.chats.filter((chat) => {
        return this.commonFilterChat(chat, trimmedLoweredText, this.filterUnreadChats);
      });

      for (const chat of _chats) {
        const toggleFilter = this.getAllowedChatFilterToggleFunc(chat);

        if (toggleFilter) {
          toggleFilter();
          break;
        }
      }
    }
    */
  };

  autoToggleInternalChatsFilter = () => {
    console.debug(`TODO: not implemented method "autoToggleInternalChatsFilter"`);
    /*
    if (this.internalChats.length || this.chatsView.internalSearch.chatForSearch) {
      return;
    }

    const trimmedLoweredText = this.chatsView.internalSearch.searchText.toLowerCase();

    if (trimmedLoweredText || this.filterUnreadChats) {
      const _chats = this.chatsView.internalChats.filter((chat) => {
        return this.commonFilterChat(chat, trimmedLoweredText, this.filterUnreadChats);
      });

      for (const chat of _chats) {
        const toggleFilter = this.getAllowedInternalChatFilterToggleFunc(chat);

        if (toggleFilter) {
          toggleFilter();
          break;
        }
      }
    }
    */
  };
}
