import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {animated, useTransition} from 'react-spring';

import Button from 'o-ui/Button';
import useIsFirstRender from '../../../../hooks/useIsFirstRender';
import {useStore} from '../../../../stores/AppStore';
import Chat from '../../../../stores/Chat';
import {ChatInfoType} from '../../../../stores/LayOutStore';
import ModalType from '../../../../stores/ModalType';
import ChatExtraInfo from './ChatExtraInfo';
import ChatFilesInfo from './ChatFilesInfo';
import ChatInfoGallery, {getChatInfoGalleryTitle} from './ChatInfoGallery';
import ChatProfileInfo from './ChatProfileInfo';
import ProfileSidebar from './ProfileSidebar';
// import ChatMembersInfo from './ChatMembersInfo';
// import ChatActionsList from './ChatActionsList';
// import ChatOperatorsInfo from './ChatOperatorsInfo';

interface ChatInfoBarProps {
  chat: Chat;
}

export const ChatInfoBar: React.FC<ChatInfoBarProps> = observer(({chat}) => {
  const {
    layOutStore: {rightSideBar},
    animationsEnabled,
    modals,
    userStore,
  } = useStore();
  const {t} = useTranslation();

  const isFirst = useIsFirstRender();

  const openEditing = () => {
    modals.open(ModalType.EDIT_GROUP);
  };

  const handleFilesViewClick = (type: ChatInfoType) => {
    rightSideBar.chatInfoBarAnimationEnable();
    rightSideBar.openChatGallery(type);
  };

  const handleCloseFilesViewClick = () => {
    rightSideBar.setChatInfoBarGalleryItemsDisplayAllow(false);
    rightSideBar.closeChatGallery();
  };

  const handleCloseSidebar = () => {
    rightSideBar.close();
  };

  const canEditChatInfo = !userStore.isOperator;

  const transitions = useTransition(rightSideBar.chatInfoType, {
    from: {
      transform: `translateX(${rightSideBar.chatInfoType === ChatInfoType.NONE ? '-100%' : '100%'})`,
    },
    enter: {
      transform: 'translateX(0%)',
    },
    leave: {
      transform: `translateX(${rightSideBar.chatInfoType === ChatInfoType.NONE ? '100%' : '-100%'})`,
    },
    onDestroyed: () => {
      rightSideBar.chatInfoBarAnimationEnable();

      rightSideBar.setChatInfoBarGalleryItemsDisplayAllow(true);
    },
  });

  const getContent = (chatInfoType: ChatInfoType) => {
    if (chatInfoType === ChatInfoType.NONE) {
      return (
        <ProfileSidebar
          className="info-list"
          title={
            chat.isGroup || chat.isSuperGroup
              ? t('chat_user_chat_head_group_info_title')
              : t('chat_user_chat_head_chat_info_title')
          }
          onClose={handleCloseSidebar}
          headChildren={
            canEditChatInfo ? (
              <Button className="profile-sidebar__head-edit" onClick={openEditing} disableRipple>
                {t('chat_user_chat_info_edit_button')}
              </Button>
            ) : null
          }
        >
          <ChatProfileInfo className="profile-sidebar__profile-info" chat={chat} />

          <ChatExtraInfo className="profile-sidebar__extra-info" chat={chat} />
          {/* <ChatMembersInfo className="profile-sidebar__files-info" chat={chat} onClick={handleFilesViewClick} /> */}
          <ChatFilesInfo className="profile-sidebar__files-info" chat={chat} onClick={handleFilesViewClick} />
          {/* <ChatActionsList className="profile-sidebar__files-info" chat={chat} /> */}
        </ProfileSidebar>
      );
    } else {
      return (
        <ProfileSidebar
          title={getChatInfoGalleryTitle(chatInfoType)}
          onBack={handleCloseFilesViewClick}
          onClose={rightSideBar.close}
        >
          <ChatInfoGallery chat={chat} infoType={chatInfoType} />
        </ProfileSidebar>
      );
    }
  };

  const isAnimated = !isFirst && animationsEnabled && rightSideBar.chatInfoBarAnimationAllow;
  return (
    <>
      {isAnimated
        ? transitions((style, chatInfoType) => (
            <animated.div style={style} className="animation-info-bar">
              {getContent(chatInfoType)}
            </animated.div>
          ))
        : getContent(rightSideBar.chatInfoType)}
    </>
  );
});

export default ChatInfoBar;
