import React from 'react';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import WorkspaceMember from '../../../stores/Workspaces/WorkspaceMember';
import Invite from '../../../stores/Workspaces/Invite';
import {entities} from '../../../api/proto';

interface MemberStatusProps {
  member: WorkspaceMember | Invite;
}

type StatusType = 'expired' | 'invited' | 'active' | 'blocked' | 'blockedByLimit';

export const MemberStatus = ({member}: MemberStatusProps) => {
  const {t} = useTranslation();

  const getMemberStatusType = (): StatusType => {
    if (member.expired) {
      return 'expired';
    } else if (member.invited) {
      return 'invited';
    } else if (member.active) {
      return 'active';
    } else if (member.blockReason === entities.WorkspaceMemberBlockReason.WMSCR_ON_CHANGE_TARIFF) {
      return 'blockedByLimit';
    }

    return 'blocked';
  };

  const statusType = getMemberStatusType();

  const statusTitleByType: Record<StatusType, string> = {
    expired: t('settings_workspace_members_manage_status_expired'),
    invited: t('settings_workspace_members_manage_status_invited'),
    active: t('settings_workspace_members_manage_status_active'),
    blocked: t('settings_workspace_members_manage_status_blocked'),
    blockedByLimit: t('settings_workspace_members_manage_status_blocked_by_limit'),
  };

  return <span className={cn(`operator-status ${statusType}`)}>{statusTitleByType[statusType]}</span>;
};

export default MemberStatus;
