import {observer} from 'mobx-react';
import React from 'react';

import CircularProgress from 'o-ui/CircularProgress';

import ChatTab from '../../stores/LayOutStore/ChatTab';
import useStore from '../../stores/useStore';
import SettingsTabWrapper, {ISettingsTabWrapperRef} from './SettingsTabWrapper';
import SettingsPageWrapper from './SettingsPageWrapper';


const SettingsPage = observer(() => {
  const {layOutStore, activeWorkspace} = useStore();
  const tabRef = React.useRef<ISettingsTabWrapperRef>(null);

  React.useEffect(() => {
    layOutStore.setActiveTab(ChatTab.SETTINGS);
    layOutStore.setActiveSettingsTabRef(tabRef.current);
  }, [layOutStore]);


  return (
    <SettingsPageWrapper>
      {activeWorkspace.isInit ? (
        <SettingsTabWrapper
          onToggleMenu={layOutStore.toggleSettingsTabContent}
          selectedTab={layOutStore.activeSettingsTab}
          ref={tabRef}
        />
      ) : (
        <CircularProgress
          size={72}
          style={{
            color: 'var(--brand-primary)',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            margin: 'auto',
            zIndex: 3,
          }}
        />
      )}
    </SettingsPageWrapper>
  );
});

export default SettingsPage;
