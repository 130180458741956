import React from 'react';

export default function useRotate() {
  const [deg, setDeg] = React.useState<number | null>(null);

  const rotate = () => {
    if (deg) {
      if (deg === 270) {
        setDeg(0);
      } else {
        setDeg(deg + 90);
      }
    } else {
      setDeg(90);
    }
  };

  const reset = React.useCallback(() => {
    setDeg(null);
  }, []);

  return {
    deg,
    rotate,
    reset,
  };
}
