import {entities} from '../../../api/proto';
import {t} from '../../../i18n';


export function getErrorByInviteResendResult(type?: entities.WorkspaceInviteResendResult | null): string | null {
  switch (type) {
    case entities.WorkspaceInviteResendResult.WIRR_MEMBER_NOT_FOUND:
      return t('Member not found');
    case entities.WorkspaceInviteResendResult.WIRR_PENDING_STAMP:
      return t('Pending stamp');
    case entities.WorkspaceInviteResendResult.WIRR_NO_EMAIL:
      return t('No email');
  }

  if (type && entities.WorkspaceInviteResendResult[type]) {
    return entities.WorkspaceInviteResendResult[type]?.toLowerCase().replace(/_/g, ' ');
  }

  return null;
}

export default getErrorByInviteResendResult;