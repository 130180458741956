import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import ReactPlayer from 'react-player';

import Loader from 'o-ui/Loader';

import useMountedState from '../../hooks/useMountedState';
import {useStore} from '../../stores/useStore';
import calcImageSize from '../../utils/image/calcImageSize';
import {uncachedFileUrl} from '../../utils/uncachedFileUrl';
import windowInnerHeight from '../../utils/windowInnerHeight';
import {VideoPlayerControls} from './VideoPlayerControls';

import {ReactComponent as ReloadButton} from '../../assets/image-icons/reloadIcon.svg';

const MAX_RELOAD_COUNTER = 4;

type Src = string | undefined | null;

export interface VideoPlayerProps {
  className?: string;
  src?: Src;
  width: number;
  height: number;
  duration?: number | null;
  toggleHideViewerControls: () => void;
  rotateDeg?: number | null;
  smallPlayer: boolean;
  toggleSmallPlayerClick: (e: React.MouseEvent) => void;
  toggleHideCloseButton?: (hide: boolean) => void;
  retryOnError?: boolean;
  loading?: boolean;
}

export const VideoPlayer = observer((props: VideoPlayerProps) => {
  const {
    src,
    retryOnError,
    toggleHideCloseButton,
  } = props;
  const {
    chatsView: {videoPlayerStore},
  } = useStore();

  const isMounted = useMountedState();
  const ref = React.createRef<ReactPlayer>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [currentUrl, setCurrentUrl] = React.useState<Src>(src);
  const [prevUrl, setPrevUrl] = React.useState<Src>(src);
  const [reloadCounter, setReloadCounter] = React.useState<number>(0);
  const [reloadTimer, setReloadTimer] = React.useState<NodeJS.Timeout | null>(null);

  const [played, setPlayed] = React.useState<number>(0);
  const [playedSeconds, setPlayedSeconds] = React.useState<number>(0);
  const [isPlaying, setIsPlaying] = React.useState<boolean>(true);
  const [fullscreen, setFullscreen] = React.useState<boolean>(false);
  const [width, setWidth] = React.useState<number>(0);
  const [height, setHeight] = React.useState<number>(0);
  const [duration, setDuration] = React.useState<number>(props.duration || 0);

  const [hideControls, setHideControls] = React.useState(false);

  const hideControlsTimer = React.useRef<null | NodeJS.Timeout>(null);

  React.useEffect(() => {
    const innerWidth = props.smallPlayer ? 300 : fullscreen ? window.innerWidth : window.innerWidth * 0.6;
    const windowHeight = windowInnerHeight();
    const innerHeight = props.smallPlayer ? 200 : fullscreen ? windowHeight : windowHeight * 0.6;

    const [width, height] = calcImageSize(
      fullscreen ? innerWidth : props.width,
      fullscreen ? innerHeight : props.height,
      props.rotateDeg && (props.rotateDeg === 90 || props.rotateDeg === 270) ? innerHeight : innerWidth,
      props.rotateDeg && (props.rotateDeg === 90 || props.rotateDeg === 270) ? innerWidth : innerHeight,
      true,
    );
    setWidth(width);
    setHeight(height);
  }, [fullscreen, props.rotateDeg, props.smallPlayer, props.width, props.height]);

  const hideControlsHandle = React.useCallback(() => {
    setHideControls(false);
    toggleHideCloseButton?.(false);

    hideControlsTimer.current && clearTimeout(hideControlsTimer.current);
    hideControlsTimer.current = setTimeout(function () {
      toggleHideCloseButton?.(true);
      setHideControls(true);
    }, 2000);
  }, [
    toggleHideCloseButton,
  ]);

  React.useEffect(() => {
    if (src !== prevUrl) {
      setPrevUrl(src);
      setReloadCounter(0);
      setCurrentUrl(src);
      reloadTimer && clearTimeout(reloadTimer);
    }
  }, [src, prevUrl, reloadTimer]);

  React.useEffect(() => {
    if (fullscreen) {
      document.addEventListener('mousemove', hideControlsHandle);
    }

    return () => {
      document.removeEventListener('mousemove', hideControlsHandle);
      hideControlsTimer.current && clearTimeout(hideControlsTimer.current);
    };
  }, [
    fullscreen,
    hideControlsHandle,
  ]);

  const togglePlayingClick = () => {
    setIsPlaying(!isPlaying);
  };

  const handleError = () => {
    if (retryOnError && reloadCounter < MAX_RELOAD_COUNTER) {
      const timer = setTimeout(updateUrlStamp, (reloadCounter + 1) * 2000 + 1000);
      setReloadTimer(timer);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  const handleReady = (player: ReactPlayer) => {
    setLoading(false);
    setLoaded(true);
    if (!duration) {
      setDuration(player.getDuration());
    }
  };

  const updateUrlStamp = () => {
    if (!isMounted()) {
      return;
    }
    setReloadTimer(null);
    setReloadCounter(reloadCounter + 1);

    const newUrl = uncachedFileUrl(src);
    setCurrentUrl(newUrl);
  };

  const handleReloadClick = () => {
    setReloadCounter(0);
    setLoading(true);
    setError(false);
    setCurrentUrl(src);
    const newUrl = uncachedFileUrl(src);
    setCurrentUrl(newUrl);
  };

  const handleProgress = (state) => {
    setPlayed(state.played);
    setPlayedSeconds(state.playedSeconds);
  };

  const handlePlayEnd = () => {
    setIsPlaying(false);
  };

  const seekTo = (fraction: number) => {
    ref?.current?.seekTo(fraction, 'fraction');
  };

  const toggleFullScreenClick = () => {
    if (!fullscreen) {
      hideControlsHandle();
    }

    setFullscreen(!fullscreen);
    props.toggleHideViewerControls();
  };

  const videoStyle = props.rotateDeg ? {transform: `rotate(${props.rotateDeg}deg)`} : {};

  return (
    <div
      className={cn('video-player', props.className, {
        fullscreen: fullscreen && !props.smallPlayer,
        'video-playe--error': error,
      })}
    >
      {props.smallPlayer ? (
        <i onClick={props.toggleSmallPlayerClick} className="toggle-full-player tg-icon-picture-in-picture-icon" />
      ) : null}
      {currentUrl ? (
        <ReactPlayer
          ref={ref}
          url={currentUrl}
          controls={false}
          width={width}
          height={height}
          volume={videoPlayerStore.volume ? videoPlayerStore.volume / 100 : 0}
          playing={isPlaying}
          onEnded={handlePlayEnd}
          onProgress={handleProgress}
          progressInterval={100}
          style={videoStyle}
          onError={handleError}
          onReady={handleReady}
        />
      ) : null}
      <VideoPlayerControls
        hide={hideControls}
        toggleFullScreenClick={toggleFullScreenClick}
        isPlaying={isPlaying}
        togglePlayingClick={togglePlayingClick}
        duration={duration}
        volume={videoPlayerStore.volume}
        changeVolume={videoPlayerStore.setVolume}
        played={played}
        playedSeconds={playedSeconds}
        onProgressClick={seekTo}
        toggleSmallPlayerClick={props.toggleSmallPlayerClick}
      />
      {!props.loading && !loading && !loaded ? (
        <ReloadButton className="video-player__reload-button" onClick={handleReloadClick} />
      ) : null}
      {props.loading || loading ? (
        <Loader
          className="video-player__loader"
          size={80}
        />
      ) : null}
    </div>
  );
});

export default VideoPlayer;
