import {observer} from 'mobx-react';
import React from 'react';

import CustomScrollbar from 'o-ui/CustomScrollbar';
import VariableSizeList, {IVariableSizeList, ListOnScrollProps} from 'o-ui/VirtualizedList';
import AutoSizer from '../../../../components/AutoSizer';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';
import {useStore} from '../../../../stores/AppStore';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import {Chat} from '../../../../stores/Chat';
import {ChatInfoType} from '../../../../stores/LayOutStore';
import AudioFilesRow from './AudioFilesRow';
import AudioFilesSkeleton from './AudioFilesSkeleton';

interface AudioFilesProps {
  attachments?: MessageAttachment[];
  type: ChatInfoType;
  chat: Chat;
}

interface AudioFilesListProps extends AudioFilesProps {
  width: number;
  height: number;
}

export const AudioFilesList: React.FC<AudioFilesListProps> = observer(({width, height, attachments, type, chat}) => {
  const {layOutStore} = useStore();

  const listRef = React.useRef<IVariableSizeList>(null);
  const outerRef = React.useRef<HTMLDivElement>(null);
  const listInnerRef = React.useRef<HTMLDivElement>(null);

  const sizeMap = React.useRef({});
  const setSize = React.useCallback((index, size) => {
    sizeMap.current = {...sizeMap.current, [index]: size};
    listRef.current?.resetAfterIndex(index);
  }, []);

  const chatHistory = React.useMemo(() => {
    switch (type) {
      case ChatInfoType.AUDIOS:
        return chat.store?.audiosHistory;
      case ChatInfoType.VOICES:
        return chat.store?.voicesHistory;
    }

    return null;
  }, [chat.store, type]);

  const getItemSize = (index: number): number => {
    return sizeMap.current[index] || width / 3;
  };

  React.useEffect(() => {
    chatHistory?.load();

    return () => {
      layOutStore.rightSideBar.setChatInfoBarGalleryItemsDisplayAllow(false);
    };
  }, [type, chatHistory, layOutStore]);

  const handleScrolledToBottom = () => {
    if (height !== 0) {
      if (type === ChatInfoType.AUDIOS) {
        chat.store?.audiosHistory?.loadNextPage();
      } else {
        chat.store?.voicesHistory?.loadNextPage();
      }
    }
  };

  const handleScrollList = (params: ListOnScrollProps) => {
    scrollCallback(params);
  };

  const scrollCallback = useDebouncedCallback((params: ListOnScrollProps) => {
    if (params.scrollUpdateWasRequested) {
      return;
    }

    const scrollHeight = listInnerRef.current?.clientHeight || 0;
    if (scrollHeight - params.scrollOffset - height === 0) {
      handleScrolledToBottom();
    }
  }, 400);

  return (
    <AudioFilesSkeleton attachmentType={chatHistory?.type}>
      <VariableSizeList
        ref={listRef}
        innerRef={listInnerRef}
        outerRef={outerRef}
        className="chat-custom-scroll"
        width={width}
        height={height}
        itemCount={attachments?.length || 0}
        itemSize={getItemSize}
        outerElementType={CustomScrollbar}
        onScroll={handleScrollList}
      >
        {(rowProps) => <AudioFilesRow rowProps={rowProps} setSize={setSize} items={attachments || []} />}
      </VariableSizeList>
    </AudioFilesSkeleton>
  );
});

export const AudioFiles = (props: AudioFilesProps) => {
  return <AutoSizer>{({width, height}) => <AudioFilesList {...props} width={width} height={height} />}</AutoSizer>;
};

export default AudioFiles;
