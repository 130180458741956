import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import copy from 'copy-to-clipboard';

import Button from 'o-ui/Button';
import FormControl from 'o-ui/FormControl';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';

import {api} from '../../../../api/proto';
import {useStore} from '../../../../stores/AppStore';
import {formatInviteUrl} from '../../../../utils/formatUrl';


interface IProps {
  inviteLink?: api.IWorkspaceCreateInviteResponse | null;
  onClose?(): void;
}

export const InvitationByLinkResult = observer(({inviteLink, onClose}: IProps) => {
  const {t} = useTranslation();
  const {notifications} = useStore();

  const inviteUrl = formatInviteUrl(inviteLink?.invite?.id);

  const handleCopyLink = () => {
    copy(inviteUrl);
    notifications.action(t('settings_workspace_members_invite_link_copy_notification'));
  };

  return (
    <div className="operator-invitation__form">
      <div>{t('settings_workspace_members_invite_link_info_1')}</div>
      <div className="mt-3">{t('settings_workspace_members_invite_link_info_2')}</div>

      <FormControl className="w-100 position-relative mt-6" variant="outlined">
        <OutlinedTextInput value={inviteUrl} />
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className="my-3 input-adornment"
          onClick={handleCopyLink}
        >
          {t('settings_workspace_members_invite_link_copy_button')}
        </Button>
      </FormControl>

      <div className="operator-invitation__footer">
        <Button
          className="mt-6"
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          {t('settings_workspace_members_invite_close_button')}
        </Button>
      </div>
    </div>
  );
});

export default InvitationByLinkResult;
