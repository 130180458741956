import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';

import Slide from 'o-ui/Transition/Slide';
import Chat from '../../../../stores/Chat';
import ChatItemUnreadCounter from '../../Tabs/ChatsTab/ChatItem/ChatItemUnreadCounter';

interface ChatMessagesScrollDownProps {
  chat: Chat;
}

export const ChatMessagesScrollDown: React.FC<ChatMessagesScrollDownProps> = observer((props) => {
  return (
    <Slide direction="up" in={props.chat.store?.scrolled} mountOnEnter unmountOnExit>
      <button
        type="button"
        className={cn('btn scroll-down-button shadow-none tg-icon tg-icon-chevron-down-line', {
          visible: props.chat.store?.scrolled,
        })}
        onClick={props.chat.store?.chatScrollToEnd}
      >
        <ChatItemUnreadCounter className="scroll-down-button__unread-counter" chat={props.chat} />
      </button>
    </Slide>
  );
});

export default ChatMessagesScrollDown;
