import {observer} from 'mobx-react';
import CircularProgress from 'o-ui/CircularProgress';
import React from 'react';

import {useTranslation} from 'react-i18next';
import {Message} from '../../../../stores/Message';

interface MessageDeliveryStatusProps {
  message: Message;
}

const MessageDeliveryStatus: React.FC<MessageDeliveryStatusProps> = observer(function ({message}) {
  const {t} = useTranslation();

  if (message.isFailed) {
    return (
      <span className="message-status__failed color-error-500" title={message.error || ''}>
        {t('chat_user_chat_message_failed_status')}
      </span>
    );
  }

  if (message.isDeleted) {
    return <i className="message-status__deleted tg-icon tg-icon-cross-line color-error-500" />;
  }

  if (!message.isDelivered) {
    return <CircularProgress className="ml-2" color="inherit" size={12} />;
  }

  if (message.isReadOutbox) {
    return <i className="message-status__read delivered read tg-icon tg-icon-check-double-line" />;
  }

  return <i className="message-status__read delivered tg-icon tg-icon-check-line" />;
});

export default function MessageDeliveryStatusWrapper({message}: MessageDeliveryStatusProps) {
  if (!message.isOwn && !(message.isDeleted || message.isFailed)) {
    return null;
  }

  return (
    <span className="message-status__delivery-status ml-2">
      <MessageDeliveryStatus message={message} />
    </span>
  );
}
