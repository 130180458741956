import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import Message from '../../../../stores/Message';
import {niceBytes} from '../../../../utils/format';
import MessageContent from './MessageContent';


interface IProps {
  message: Message;
  attachment?: MessageAttachment;
  searchId?: string;
  albumMessage?: Message;
  onContextMenu?(e: React.MouseEvent, albumMessage?: Message): void;
  style?: React.CSSProperties;
}

export const MessageAlbumItem: React.FC<IProps> = observer(({
  message,
  attachment,
  searchId,
  albumMessage,
  style,
}) => {
  const {t} = useTranslation();

  return (
    <div
      className={cn('message-album__item-wrapper', {
        'message-album__item-wrapper--images': message.hasImage,
        'message-album__item-wrapper--videos': message.hasVideo,
        'selected-message': message.isSelected,
        'found-message-album':
          message.id.notEquals(albumMessage?.id || '') &&
          (message.id.toString() === searchId ||
            (message.chat.store?.searchMessage?.id && message.id.equals(message.chat.store?.searchMessage?.id)) ||
            (message.chat.store?.targetMessage?.id && message.id.equals(message.chat.store?.targetMessage?.id))),
      })}
      data-msg-id={message.localID.toString()}
      data-msg-random-id={message.randomID?.toString()}
      data-attachment-size={attachment ? niceBytes(attachment?.size?.toNumber()) : undefined}
      style={style}
    >
      <div
        className={cn('found-message-animation', {
          found:
            message.id.notEquals(albumMessage?.id || '') &&
            (message.id.toString() === searchId ||
              (message.chat.store?.searchMessage?.id && message.id.equals(message.chat.store?.searchMessage?.id)) ||
              (message.chat.store?.targetMessage?.id && message.id.equals(message.chat.store?.targetMessage?.id))),
        })}
      />
      <MessageContent
        className="message-album__item"
        message={message}
        attachment={attachment}
        style={style}
        playButton={true}
      />
      {message.deleted ? (
        <span
          className="message-album__deleted tg-icon-cross-line color-error-500"
          title={t('chat_user_chat_message_deleted_status')}
        />
      ) : null}
    </div>
  );
});

export default MessageAlbumItem;
