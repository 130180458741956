import React from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import MessageAttachment from '../../stores/Attachment/MessageAttachment';

export interface AttachmentsNavigation<T> {
  attachment?: T | null;
  index?: number;
  getAttachment: (index: number) => T | null;
  switchToPrev?: () => void;
  switchToNext?: () => void;
  downloadUrl?: string;
  setDownloadUrl: (url: string) => void;
  title?: string;
  subTitle?: string | React.ReactNode;
}

export default function useMessageAttachmentsNavigation(
  attachments: MessageAttachment[],
  startAttachment: MessageAttachment,
): AttachmentsNavigation<MessageAttachment> {
  const {t} = useTranslation();
  const [attachment, setAttachment] = React.useState<MessageAttachment>(startAttachment);

  const [index, setIndex] = React.useState<number>(
    attachments.findIndex((attach) => attachment?.unicID === attach.unicID),
  );

  const [downloadUrl, setDownloadUrl] = React.useState<string>('');

  React.useEffect(() => {
    const newIndex = attachments.findIndex((attach) => attachment?.unicID === attach.unicID);
    if (newIndex >= 0) {
      setIndex(newIndex);
    }
  }, [attachments, attachment?.unicID]);

  function switchToNext() {
    if (index > 0) {
      const prevAttachment = attachments[index - 1];

      setAttachment(prevAttachment);
      setIndex(index - 1);
    }
  }

  function switchToPrev() {
    if (index < attachments.length - 1) {
      const nextAttachment = attachments[index + 1];

      setAttachment(nextAttachment);
      setIndex(index + 1);
    }
  }

  function getAttachment(idx: number) {
    return (attachments[idx] ? attachments[idx] : attachment) || null;
  }

  let title = '';
  if (typeof index === 'number' && attachments.length) {
    title = attachment?.isDocument
      ? t('modal_media_viewer_file_of_counter', {
          current: index + 1,
          count: attachments.length,
        })
      : t('modal_media_viewer_media_of_counter', {
          current: index + 1,
          count: attachments.length,
        });
  } else {
    title = attachment?.isDocument ? t('modal_media_viewer_file_title') : t('modal_media_viewer_media_title');
  }

  const senderName = attachment?.message?.senderName || '';
  const time = attachment?.message?.stamp
    ? moment.unix(attachment?.message?.stamp.toNumber()).format('DD.MM.YYYY [at] HH:mm')
    : '';
  const subTitle = (
    <span>
      {senderName} &#128900; {time}{' '}
    </span>
  );

  return {
    attachment,
    index,
    getAttachment,
    switchToNext: index > 0 ? switchToNext : undefined,
    switchToPrev: index < attachments.length - 1 ? switchToPrev : undefined,
    downloadUrl,
    setDownloadUrl,
    title,
    subTitle,
  };
}
