import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {Chat} from '../../../../stores/Chat';
// import {StickersPicker} from '../../../../components/StickersPicker/StickersPicker';
import EmojiPicker, {Emoji} from 'o-ui/EmojiPicker/EmojiPicker';
import Tab from 'o-ui/Tab';
import Tabs from 'o-ui/Tabs';
import {useStore} from '../../../../stores/AppStore';

interface SymbolMenuProps {
  chat: Chat;
  onAddEmoji?: (e: Emoji) => void;
  onAddSticker?: (e: any) => void;
  hideStickers?: boolean;
}

enum MENU_TABS {
  emoji,
  stickers,
}

export const SymbolMenu = observer((props: SymbolMenuProps) => {
  const {t} = useTranslation();

  const {
    layOutStore: {emojiSpriteLoader},
  } = useStore();

  const [selectedTab, setSelectedTab] = React.useState<MENU_TABS>(MENU_TABS.emoji);

  const handleChangeTab = (e: React.ChangeEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="symbol-menu">
      <div className="symbol-menu__header">
        <Tabs value={selectedTab} onChange={handleChangeTab}>
          <Tab label={t('chat_user_chat_symbol_menu_emoji_tab')} />
          {props.hideStickers ? null : <Tab label={t('chat_user_chat_symbol_menu_stickers_tab')} />}
        </Tabs>
      </div>

      <div className="symbol-menu__body">
        {selectedTab === MENU_TABS.emoji ? (
          <EmojiPicker
            onSelect={props.onAddEmoji}
            emojiSize={30}
            spriteLoaded={!!emojiSpriteLoader.isLoaded}
          />
        ) : null}
        {/* {selectedTab === MENU_TABS.stickers ? <StickersPicker onClick={props.onAddSticker} /> : null} */}
      </div>
    </div>
  );
});

export default SymbolMenu;
