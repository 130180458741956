import React from 'react';

import {ListChildComponentProps} from 'o-ui/VirtualizedList';
import {CHAT_ITEM_HEIGHT, CHAT_ITEM_HEIGHT_COMPACT} from '../../../../../constants';
import {Chat, ChatClickEventHandler} from '../../../../../stores/Chat';
import {Message, MessageClickEventHandler} from '../../../../../stores/Message';
import {ITag} from '../../../../../stores/MessageTagsStore';
import useStore from '../../../../../stores/useStore';
import ChatForSearchItem from '../ChatForSearchItem';
import ChatItem from '../ChatItem';
import ChatItemDelimiter from '../ChatItemDelimiter';
import FoundMessageItem from '../FoundMessageItem';
import FoundTagItem from '../FoundTagItem';


interface ChatsFoundListItemProps {
  row: ListChildComponentProps;
  setSize: (index: number, size: number) => void;
  onChatClick?: ChatClickEventHandler;
  onFoundMessageClick?: MessageClickEventHandler;
  onFoundTagClick?: (tag?: ITag | null) => void;
  onCloseChatForSearch?: (chat: Chat) => void;
}

export const ChatsFoundListItem = React.memo((props: ChatsFoundListItemProps) => {
  const {row, setSize} = props;
  const {chatsView, layOutStore} = useStore();
  const foundItem = chatsView.foundItems[row.index];

  React.useEffect(() => {
    if (foundItem.delimiter) {
      setSize(row.index, 32);
    } else if (foundItem.tag) {
      setSize(row.index, 42);
    } else {
      setSize(row.index, layOutStore.compactMode ? CHAT_ITEM_HEIGHT_COMPACT : CHAT_ITEM_HEIGHT);
    }
  }, [
    setSize,
    row.index,
    foundItem.delimiter,
    foundItem.tag,
    layOutStore.compactMode,
  ]);

  const onChatClick = (e: React.MouseEvent, chat: Chat) => {
    e.preventDefault();
    props.onChatClick?.(chat);
  };

  const onFoundMessageClick = (message: Message) => {
    props.onFoundMessageClick?.(message);
  };

  const onFoundTagClick = (tag?: ITag | null) => {
    props.onFoundTagClick?.(tag);
  };

  const onCloseChatClick = (chat: Chat) => {
    props.onCloseChatForSearch?.(chat);
  };

  if (foundItem.delimiter) {
    return (
      <ChatItemDelimiter key={`delimiter-${row.index}`} style={row.style}>
        {foundItem.delimiter}
      </ChatItemDelimiter>
    );
  }

  if (foundItem.message) {
    return (
      <FoundMessageItem
        key={`found-message-${foundItem.message.chatID?.toString()}-${foundItem.message.id.toString()}`}
        style={row.style}
        message={foundItem.message}
        onClick={onFoundMessageClick}
      />
    );
  }

  if (foundItem.chat) {
    return (
      <ChatItem
        key={'chat-' + foundItem.chat?.id.toString()}
        className="found-chat-item"
        chat={foundItem.chat}
        style={row.style}
        onOpen={onChatClick}
      />
    );
  }

  if (foundItem.chatForSearch) {
    return (
      <ChatForSearchItem
        key={'chat-for-search-' + foundItem.chatForSearch?.id.toString()}
        chat={foundItem.chatForSearch}
        style={row.style}
        onClose={onCloseChatClick}
      />
    );
  }

  if (foundItem.tag) {
    return (
      <FoundTagItem
        key={`found-tag-${foundItem.tag?.id?.toString()}`}
        style={row.style}
        tag={foundItem.tag}
        onClick={onFoundTagClick}
      />
    );
  }

  return null;
});

export default ChatsFoundListItem;
