import {observer} from 'mobx-react';
import React from 'react';

import Avatar from 'o-ui/Avatar';
import {navigateToWorkspace} from '../../../../routes/Paths';
import useStore from '../../../../stores/useStore';
import Workspace from '../../../../stores/Workspaces/Workspace';
import {equalUint8Arrays, uint8ArrayToBase64, uint8ArrayToUuid} from '../../../../utils/arrayUtils';
import useAvatarAttachment from '../../useAvatarAttachment';


interface WorkspaceBarItemProps {
  className?: string;
  workspace: Workspace;
}

export const WorkspaceBarItem: React.FC<WorkspaceBarItemProps> = observer(({
  workspace,
}) => {
  const {activeWorkspace} = useStore();

  const handleSelectWorkspace = () => {
    navigateToWorkspace(workspace.id);
  };

  const {src} = useAvatarAttachment({source: workspace.avatar}, workspace.id);

  return (
    <button
      key={uint8ArrayToUuid(workspace.id)}
      data-workspace-id={uint8ArrayToUuid(workspace.id)}
      data-avatar-reference={uint8ArrayToUuid(workspace.avatar?.reference)}
      type="button"
      className="workspace-bar__menu-item dropdown-item d-flex"
      onClick={handleSelectWorkspace}
    >
      <Avatar
        className="workspace-bar__menu-item-avatar"
        size="xs"
        name={workspace.name || uint8ArrayToBase64(workspace.id)}
        src={src}
      />
      <span
        className="workspace-bar__menu-item-name body2-bold color-body-primary text-truncate"
        title={workspace.name || uint8ArrayToBase64(workspace.id)}
      >
        {workspace.name || uint8ArrayToBase64(workspace.id)}
      </span>
      {equalUint8Arrays(workspace.id, activeWorkspace.id) ? (
        <i className="workspace-bar__active tg-icon tg-icon-check-line ml-auto" />
      ) : null}
    </button>
  );
});

export default WorkspaceBarItem;