import React from 'react';
import {IRawChat} from '../../stores/RawChatsStore/RawChat';

export interface SelectChatContextValue {
  selectedChats: IRawChat[];
  toggleChat: (chat: IRawChat) => void;
  selectChats: (chats: IRawChat[]) => void;
}

const noOp = () => {
  return;
};

const SelectChatContext = React.createContext<SelectChatContextValue>({
  selectedChats: [],
  toggleChat: noOp,
  selectChats: noOp,
});

export default SelectChatContext;
