import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import cn from 'classnames';
import {observer} from 'mobx-react-lite';
import Button, {IconButton} from 'o-ui/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {ReactComponent as DragIcon} from '../../../assets/icons/drag-icon.svg';
import {Snippet} from '../../../stores/Snippets';
import {formatText} from '../../../utils/textFormat';
import SnippetManageActions from './SnippetManageActions';

interface IProps {
  snippet: Snippet;
  className?: string;
  allowChange?: boolean;
  onDelete?(snippet: Snippet): void;
  onEdit?(snippet: Snippet): void;
}

export const SnippetManage: React.FC<IProps> = observer(
  ({snippet, className, onDelete, onEdit, allowChange = false}) => {
    const {t} = useTranslation();
    const {setNodeRef, attributes, listeners, transform, transition, isSorting, index, activeIndex} = useSortable({
      id: snippet.id?.toString() || '',
    });

    const textRef = React.useRef<HTMLDivElement>(null);

    const [allowCollapse, setAllowCollapse] = React.useState(false);
    const [textCollapsed, setTextCollapsed] = React.useState(true);

    const sortingStyle = {
      transform: CSS.Translate.toString(transform),
      transition,
      touchAction: 'none',
    };

    const toggleTextCollapse = () => {
      textRef.current?.classList.toggle('truncate');
      setTextCollapsed(!textCollapsed);
    };

    React.useEffect(() => {
      const checkCollapsible = () => {
        if (textRef.current && textCollapsed) {
          setAllowCollapse(textRef.current.scrollHeight > textRef.current.clientHeight);
        }
      };

      checkCollapsible();

      window.addEventListener('resize', checkCollapsible);

      return () => {
        window.removeEventListener('resize', checkCollapsible);
      };
    }, [textCollapsed]);

    return (
      <div
        ref={setNodeRef}
        className={cn('snippet-manage', className, {
          sortable: index === activeIndex,
          sorting: isSorting,
        })}
        style={sortingStyle}
      >
        {allowChange && (
          <div className="snippet-manage__dnd">
            <IconButton className="snippet-manage__drag-button" {...listeners} {...attributes}>
              <DragIcon className="snippet-manage__drag-button-icon" />
            </IconButton>
          </div>
        )}

        <div className="snippet-manage__shortcut">{snippet.command}</div>

        <div className="snippet-manage__content">
          <div
            ref={textRef}
            className="snippet-manage__content-text truncate"
            dangerouslySetInnerHTML={{__html: formatText(snippet.text || '', snippet.entities)}}
          />
          {allowCollapse ? (
            <div>
              <Button
                variant="text"
                size="small"
                disableRipple
                className="snippet-manage__content-btn"
                onClick={toggleTextCollapse}
              >
                {textCollapsed ? t('settings_snippets_manage_show_more_btn') : t('settings_snippets_manage_hide_btn')}
                <i
                  className={cn('tg-icon icon-size-8 ml-3', {
                    'tg-icon-chevron-up-line': !textCollapsed,
                    'tg-icon-chevron-down-line ': textCollapsed,
                  })}
                ></i>
              </Button>
            </div>
          ) : null}
        </div>

        <SnippetManageActions
          isSorting={isSorting}
          allowChange={allowChange}
          onDelete={() => onDelete?.(snippet)}
          onEdit={() => onEdit?.(snippet)}
        />
      </div>
    );
  },
);

export default SnippetManage;
