import {api} from '../../api/proto';
import {t} from '../../i18n';

export default function getCustomerPortalError(status?: api.WorkspaceGetCustomerPortalResponse.Status | null): string | null {
  switch (status) {
    case api.WorkspaceGetCustomerPortalResponse.Status.GCP_UNKNOWN:
      return t('Unknown error');
  }

  return null;
}