import {action, makeObservable, observable} from 'mobx';
import EmojiList from 'o-ui/utils/emoji/EmojiList';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';

export class EmojiSpriteLoader extends APILayer {
  @observable isLoaded = false;

  constructor(public app: AppStore) {
    super(app);
    makeObservable(this);
  }

  @action setIsLoaded = (loaded: boolean) => {
    this.isLoaded = loaded;
  };

  load = async () => {
    if (this.isLoaded) {
      return;
    }

    const {loaded} = await EmojiList.loadSpriteImage();
    this.setIsLoaded(loaded);
  };
}

export default EmojiSpriteLoader;
