import {action, computed, makeObservable, observable} from 'mobx';

import {APIResponse, api, entities} from '../../api/proto';
import Paths from '../../routes/Paths';
import browserHistory from '../../routes/browserHistory';
import {APILayer} from '../../stores/APILayer';
import {base64ToUint8Array, equalUint8Arrays, uint8ArrayToBase64} from '../../utils/arrayUtils';
import browserStorage from '../../utils/browserStorage';
import {AppStore} from '../AppStore';
import Workspace from './Workspace';
import WorkspaceStore from './WorkspaceStore';

const WORKSPACE_STORE_KEY = 'activeWorkspaceID';

export class WorkspacesStore extends APILayer {
  constructor(public app: AppStore) {
    super(app);
    makeObservable(this);

    this.api.on('refetchTip', this.onRefetchTip_);
  }

  @observable public isInit = false;

  @action protected setInit_ = (isInit: boolean) => {
    this.isInit = isInit;
  };

  init = async () => {
    if (this.loading || this.isInit) {
      return;
    }

    await this.load();

    this.setInit_(true);
  };

  load = async () => {
    if (this.loading) {
      return;
    }
    this.setLoading(true);

    await this.loadWorkspaces();

    this.setLoading(false);
  };

  @observable list_: Workspace[] = [];

  @observable protected blocked_: Uint8Array[] = [];

  @computed get list(): Workspace[] {
    return this.list_.filter((w) => !this.isBlocked(w.id));
  }

  isBlocked = (workspaceId?: Uint8Array | null): boolean => {
    return this.blocked_.some((id) => equalUint8Arrays(workspaceId, id));
  };

  findWorkspace = (workspaceId?: Uint8Array | null): Workspace | null => {
    return this.list.find((w) => equalUint8Arrays(w.id, workspaceId)) || null;
  };

  @observable currentWorkspace: WorkspaceStore = new WorkspaceStore(null, this.app);

  @action protected setCurrentWorkspace_ = (nextWorkspace: WorkspaceStore) => {
    console.debug(`%c---->WorkspacesStore.setCurrentWorkspace ${uint8ArrayToBase64(nextWorkspace.id)}`, 'color: red');

    const prevWorkspace = this.currentWorkspace;
    this.currentWorkspace = nextWorkspace;
    this.selectedWorkspaceId = nextWorkspace.id;
    browserStorage.session(WORKSPACE_STORE_KEY, uint8ArrayToBase64(nextWorkspace.id));
    browserStorage.local(WORKSPACE_STORE_KEY, uint8ArrayToBase64(nextWorkspace.id));
    prevWorkspace.reset();
  };

  @observable private selectedWorkspaceId: Uint8Array | null | undefined = null;

  getCashedWorkspaceId(): Uint8Array | null | undefined {
    const cachedStringId = browserStorage.session(WORKSPACE_STORE_KEY) || browserStorage.local(WORKSPACE_STORE_KEY);
    return base64ToUint8Array(cachedStringId) || null;
  }

  @computed public get currentWorkspaceId(): Uint8Array | null | undefined {
    if (this.selectedWorkspaceId) {
      return this.selectedWorkspaceId;
    }

    const workspaceIDStr = this.app.initialSelections?.workspaceId;
    const workspaceID = base64ToUint8Array(workspaceIDStr);

    if (this.list.some((w) => equalUint8Arrays(w.id, workspaceID))) {
      return workspaceID;
    }

    const cashedId = this.getCashedWorkspaceId();

    if (this.list.some((w) => equalUint8Arrays(w.id, cashedId))) {
      return cashedId;
    }

    return this.list.length ? this.list[0].id : null;
  }

  public loadWorkspaces = async () => {
    const {error, res} = await this.request<api.WorkspaceResponse>(
      {
        workspaceRequest: new api.WorkspaceRequest({
          workspaces: new api.WorkspacesListRequest({fetch: true}),
        }),
      },
      'workspaceResponse',
    );

    if (res) {
      this.processWorkspaces_(res.workspaces?.entries);
    }

    return {error, res};
  };

  @action protected processWorkspaces_ = (workspaces?: entities.IWorkspace[] | null) => {
    this.list_ = workspaces?.map((w) => new Workspace(w)) ?? [];
    this.blocked_ = this.blocked_?.filter((id) => !workspaces?.some((w) => equalUint8Arrays(w.id, id))) ?? [];

    const foundWorkspace = this.findWorkspace(this.currentWorkspaceId);

    if (foundWorkspace && !this.currentWorkspace.id) {
      const currentWorkspace = new WorkspaceStore(foundWorkspace, this.app);
      currentWorkspace.init();

      this.setCurrentWorkspace_(currentWorkspace);
    }
    this.setInit_(true);
  };

  public refreshWorkspaces = async () => {
    const {error, res} = await this.request<api.WorkspaceResponse>(
      {
        workspaceRequest: new api.WorkspaceRequest({
          workspaces: new api.WorkspacesListRequest({fetch: true}),
        }),
      },
      'workspaceResponse',
    );

    if (res) {
      this.processWorkspacesRefresh_(res.workspaces?.entries);
    }

    return {error, res};
  };

  @action protected processWorkspacesRefresh_ = (workspaces?: entities.IWorkspace[] | null) => {
    workspaces?.forEach((w) => {
      const workspace = this.findWorkspace(w.id);
      workspace?.update(w);
    });

    const foundWorkspace = this.findWorkspace(this.currentWorkspaceId);
    if (foundWorkspace && !this.currentWorkspace.id) {
      this.currentWorkspace.update(foundWorkspace);
    }
  };

  private onRefetchTip_ = async ({
    userID,
    workspaceChannels,
    workspaceMembers,
    invites,
    snippets,
    workspaceTariff,
  }: api.IRefetchTip) => {
    const shouldUpdate =
      (!userID || userID.equals(0) || this.app.userStore.isCurrentOperator(userID)) && this.app.userStore.isLoggedIn;

    console.debug(
      `%c---->onRefetchTip_: income userID ${userID?.toString()}, current userID: ${this.app.userStore.user?.userId?.toString()} shouldUpdate=${shouldUpdate}`,
      'color: green',
    );

    if (invites && shouldUpdate) {
      console.debug(`%c web updates: invites`, 'color: green');
      this.currentWorkspace.invites.load();
    }

    if (workspaceMembers && shouldUpdate) {
      console.debug(`%c web updates: workspaceMembers`, 'color: green');
      const {accessIsChanged, error} = await this.currentWorkspace.refreshMembers();
      if (accessIsChanged) {
        console.debug(`%c---->onRefetchTip_: accessIsChanged=${accessIsChanged}`, 'color: red');
        this.currentWorkspace.recheckMemberChatsChannelsAccess();
      }

      if (error?.type === APIResponse.Status.AS_NOT_ALLOWED) {
        console.debug(`%c---->onRefetchTip_: members NOT ALLOWED`, 'color: red');
        this.addToBlocked_(this.currentWorkspace.id);
        browserHistory.push(Paths.BlockedWorkspaceAccess);
      }
    }

    if (workspaceChannels && shouldUpdate) {
      console.debug(`%c web updates: workspaceChannels, userID: ${userID?.toString()}`, 'color: green');
      await this.load();
      await this.currentWorkspace.channels.load();
    }

    if (snippets && shouldUpdate) {
      console.debug(`%c web updates: snippets, userID: ${userID?.toString()}`, 'color: green');
      await this.currentWorkspace.loadSnippets();
    }

    if (workspaceTariff) {
      console.debug(`%c web updates: workspaceTariff, userID: ${userID?.toString()}`, 'color: green');
      await this.currentWorkspace.billing.refresh();
    }
  };

  @action protected addToBlocked_ = (id?: Uint8Array | null) => {
    if (!id) {
      return;
    }
    this.blocked_ = [...this.blocked_, id];
  };

  @action public reset = () => {
    console.debug(`%c---->WorkspacesStore.reset`, 'color: red');
    this.setInit_(false);
    this.currentWorkspace.reset();
    this.currentWorkspace = new WorkspaceStore(null, this.app);
    this.setInit_(false);
    this.selectedWorkspaceId = null;
  };

  public selectWorkspace = async (id?: Uint8Array | null | undefined) => {
    console.debug(`%c---->WorkspacesStore.selectWorkspace ${uint8ArrayToBase64(id)}`, 'color: red');
    const foundWorkspace = this.findWorkspace(id);

    if (foundWorkspace?.id && !equalUint8Arrays(this.selectedWorkspaceId, foundWorkspace.id)) {
      const nextWorkspace = new WorkspaceStore(foundWorkspace, this.app);

      this.setCurrentWorkspace_(nextWorkspace);

      await nextWorkspace.init();
      this.app.routingHistory.resetToInitState();
    }
  };

  public createWorkspace = async (name: string, avatar?: entities.IAttachmentSource | null) => {
    const {error, res} = await this.request<api.WorkspaceResponse>(
      {
        workspaceRequest: new api.WorkspaceRequest({
          create: new api.WorkspaceCreateRequest({
            name,
            avatar,
          }),
        }),
      },
      'workspaceResponse',
    );

    if (res) {
      this.loadWorkspaces();
      this.app.anal.workspaceCreatedEvent(name);
    }

    return {error, res};
  };
}

export default WorkspacesStore;
