import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import {ChatTab} from '../stores/LayOutStore';
import useStore from '../stores/useStore';

const AuthSideMenuLayoutWrapper: React.FC<React.PropsWithChildren> = observer(({children}) => {
  const {layOutStore} = useStore();

  return (
    <div
      className={cn('layout-wrapper', {
        'right-sidebar-open': layOutStore.rightSideBar.isOpen,
        collapsed: layOutStore.leftBarCollapsed,
        [`layout-wrapper--${ChatTab[layOutStore.activeTab].toLowerCase()}`]: true,
      })}
    >
      {children}
    </div>
  );
});

export default AuthSideMenuLayoutWrapper;