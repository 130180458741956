import React from 'react';
import {observer} from 'mobx-react';

import {InvitationByEmailFormikProps, InvitationFormTabs} from '../../WorkspaceMembersTab';
import InvitationForm from './InvitationForm';


interface InvitationTabProps {
  onOpenInvitationAccess(): void;
  formik: InvitationByEmailFormikProps;
  activeTab: InvitationFormTabs;
  onChangeTab(newTab: InvitationFormTabs): void;
}

const InvitationTab: React.FC<InvitationTabProps> = observer((props) => {
  return (
    <div className="settings-tab__content">
      <div className="settings-tab__paper">
        <InvitationForm
          activeTab={props.activeTab}
          onChangeTab={props.onChangeTab}
          formik={props.formik}
          onOpenInvitationAccess={props.onOpenInvitationAccess}
        />
      </div>
    </div>
  );
});

export default InvitationTab;
