import React from 'react';
import {observer} from 'mobx-react';

export interface VideoPlayerProgressBarProps {
  played: number;
  playedSeconds: number;
  onProgressClick: (fraction: number) => void;
}

export const VideoPlayerProgressBar = observer((props: VideoPlayerProgressBarProps) => {
  const progressLineRef = React.useRef<HTMLDivElement>(null);

  const handleClick = (e: React.MouseEvent) => {
    if (progressLineRef.current) {
      const percentWidth = progressLineRef.current.clientWidth / 100;
      const rect = progressLineRef.current.getBoundingClientRect();
      const clickDistance = e.clientX - rect.left;
      const fraction = Math.round(clickDistance / percentWidth) / 100;
      props.onProgressClick(fraction);
    }
  };
  return (
    <div className="video-player-controls__progress-bar-container" ref={progressLineRef} onClick={handleClick}>
      <div className="video-player-controls__progress-bar">
        <div className="video-player-controls__progress-bar__progress" style={{width: `${props.played * 100}%`}} />
        <div
          className="video-player-controls__progress-bar__progress-circle"
          style={{left: `${props.played * 100}%`}}
        />
      </div>
    </div>
  );
});

export default VideoPlayerProgressBar;