import React from 'react';
import {observer} from 'mobx-react';

import useStore from '../../../../stores/useStore';
import ChatsListTransitional from './ChatsList/ChatsListTransitional';
import ChatsListSkeleton from './ChatsList/ChatsListSkeleton';


export const ChatsTabContent = observer(() => {
  const {activeWorkspace, chatsView} = useStore();

  return (
    <ChatsListSkeleton className="chats-tab__content">
      <ChatsListTransitional
        onChatClick={chatsView.navigateToChat}
        onFoundMessageClick={chatsView.navigateToMessage}
        onScrolledToTop={activeWorkspace.chatsLoader.loadPrevPage}
        onScrolledToBottom={activeWorkspace.chatsLoader.loadNextPage}
        searchText={chatsView.search.searchText}
        chatForSearch={chatsView.search.chatForSearch}
        onCloseChatForSearch={chatsView.search.disabledSearchInActiveChat}
      />
    </ChatsListSkeleton>
  );
});

export default ChatsTabContent;
