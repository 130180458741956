import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';
import {useStore} from '../../../stores/AppStore';
import {useMountedState} from '../../../hooks/useMountedState';

interface NotificationSoundPlayerProps {
  soundName: string;
  className?: string;
}

export const NotificationSoundPlayer = observer((props: NotificationSoundPlayerProps) => {
  const isMounted = useMountedState();
  const {notifications} = useStore();
  const [plaing, setPlaying] = React.useState<boolean>(false);

  const handlePlaySound = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.soundName) {
      const player = notifications.play(props.soundName);

      const duration = Math.round((player.duration() || 1) * 1000);
      setPlaying(true);
      setTimeout(() => {
        if (isMounted()) {
          setPlaying(false);
        }
      }, duration);
    }
  };

  return (
    <i
      className={cn('tg-icon tg-icon-sound-fill', props.className, {
        'color-brand-primary': plaing,
      })}
      onClick={handlePlaySound}
    />
  );
});

export default NotificationSoundPlayer;
