import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import {ModalItem} from '../stores/ModalsStore';
import {getUserRoleTitle} from '../stores/Workspaces';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
}

export const ModalInviteDeleting = (props: IProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const {invite} = props.modal.data;

  const handleSubmit = () => {
    props.modal.trigger('submit', props.modal.data);
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  return (
    <ModalDialog ref={modalRef} modal={props.modal} className="new-dlg">
      <div className="account-dialog-form account-blocking">
        <div className="account-dialog-form__body">
          <div className="account-dialog-form__header">
            <span className="account-dialog-form__title">{t('modal_invite_deleting_title')}</span>
            <span
              className="tg-icon-cross-line cursor-pointer icon-size-18 color-body-primary ml-auto"
              onClick={handleCloseClick}
            />
          </div>

          <div className="account-dialog-form__form custom-scroll d-flex flex-column">
            <div className="h4-bold font-bold color-body-primary">{t('modal_invite_deleting_description')}</div>

            <div className="account-dialog-form__field-label">{t('modal_invite_deleting_email')}</div>
            <div className="account-dialog-form__field-value">{invite?.email || '-'}</div>

            <div className="account-dialog-form__field-label">{t('modal_invite_deleting_role')}</div>
            <div className="account-dialog-form__field-value">{getUserRoleTitle(invite?.role)}</div>
          </div>

          <div className="account-dialog-form__footer mt-auto">
            <Button
              variant="contained"
              color="error"
              size="large"
              className="account-dialog-form__connect-button"
              onClick={handleSubmit}
            >
              {t('modal_delete_btn')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className="account-dialog-form__cancel-button"
              onClick={handleCloseClick}
            >
              {t('modal_cancel_btn')}
            </Button>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};

export default ModalInviteDeleting;
