import React from 'react';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import Button, {IconButton} from 'o-ui/Button';
import OutlinedTextInput, {OutlinedTextInputProps} from 'o-ui/Input/OutlinedTextInput';

import {useStore} from '../../stores/AppStore';
import {FileData, dataFileReader} from '../../utils/file/fileReaders';
import someFileIsEmpty from '../../utils/file/someFileIsEmpty';
import FileInput from './FileInput';


interface IProps {
  fileUrl?: string;
  accept?: string;
  multiple?: boolean;
  onChange?: (files: FileData[]) => void;
}

export type FileInputUploaderProps = Omit<OutlinedTextInputProps, 'onChange'> & IProps;

export const FileInputUploader = ({fileUrl, accept, multiple, onChange, ...props}: FileInputUploaderProps) => {
  const {t} = useTranslation();
  const {notifications} = useStore();
  const refFileInput = React.useRef<HTMLInputElement>(null);
  const [chosenFiles, setChosenFiles] = React.useState<FileData[]>([]);
  const [imageFile, setImageFile] = React.useState<FileData | null>(null);
  const [error, setError] = React.useState<string | null | undefined>('');

  React.useEffect(() => {
    setImageFile(chosenFiles.find((f) => f.mimeType?.startsWith('image')) || null);
  }, [chosenFiles]);

  const handleSend = (dataFiles: FileData[]) => {
    setChosenFiles(dataFiles);
    onChange?.(dataFiles);
  };

  const handleFileChange = (files: File[]) => {
    setError('');
    try {
      if (someFileIsEmpty(files)) {
        notifications.error(t('file_uploader_file_is_empty'));
        return;
      }
      dataFileReader(files, handleSend);
    } catch (e: any) {
      setError(e.message);
    }
  };

  const handleFileClick = () => {
    refFileInput.current?.click();
  };

  const handleRemoveFileClick = () => {
    handleSend([]);
  };

  let fileName = t('Upload an image...');
  if (chosenFiles.length && !(imageFile || fileUrl)) {
    fileName = chosenFiles[0].file.name;
  } else if (imageFile || fileUrl) {
    fileName = '';
  }

  return (
    <div
      className={cn('position-relative', {
        'with-help-text': !!(error || props.errorHelper),
      })}
    >
      <FileInput
        className="d-none"
        ref={refFileInput}
        onChange={handleFileChange}
        multiple={multiple}
        accept={accept}
      />
      {imageFile || fileUrl ? (
        <div className="input-adornment input-adornment--left input-adornment--image d-inline-block">
          <img alt="" src={imageFile?.objectUrl || fileUrl} className="input-adornment--image" />
          <IconButton
            className="tg-icon-delete-bin-line icon-size-12 position-absolute bg-background-primary box-shadow hover-scale"
            style={{zIndex: 2, right: -6, top: -6}}
            onClick={handleRemoveFileClick}
            size="extraSmall"
          />
        </div>
      ) : null}
      <OutlinedTextInput {...props} value={fileName} errorHelper={error || props.errorHelper} />
      <Button
        variant="contained"
        color="primary"
        size="small"
        className="input-adornment input-adornment--right"
        onClick={handleFileClick}
      >
        {t('Upload...')}
      </Button>
    </div>
  );
};

export default FileInputUploader;
