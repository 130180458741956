import React from 'react';
import {observer} from 'mobx-react';

// import {NewChatButton} from './NewChatButton';
import AllChatsReadButton from './AllChatsReadButton';
import {useStore} from '../../../../stores/AppStore';

export const ChatsTabActions = observer(() => {
  const {layOutStore} = useStore();

  return (
    <div className="chats-tab__actions">
      {layOutStore.allChatsReadButtonVisible ? <AllChatsReadButton /> : null}
      {/* {layOutStore.newChatButtonVisible ? <NewChatButton className="ml-auto" /> : null} */}
      {/* <NewChatButton className="ml-auto" /> */}
    </div>
  );
});

export default ChatsTabActions;
