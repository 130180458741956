import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';

import {IMCAttachment, IMCAttachmentSource, MCAttachment} from '../../api/proto';
import {isImageFile} from '../../utils/file/fileReaders';
import {uint8ArrayToUuid} from '../../utils/arrayUtils';


export class Attachment implements IMCAttachment {
  @observable size?: Long | null;
  @observable name?: string | null;
  @observable type?: MCAttachment.Type | null;
  @observable mimeType?: string | null;
  @observable width?: Long | null;
  @observable height?: Long | null;
  @observable duration?: Long | null;
  @observable source?: IMCAttachmentSource | null;
  @observable preview?: (IMCAttachment | null);

  constructor(public raw: IMCAttachment) {
    makeObservable(this);
    this.assign(raw);
  }

  @computed get id() {
    return this.source?.reference;
  }

  @computed get uuid() {
    return uint8ArrayToUuid(this.id);
  }

  @computed get fileType() {
    return this.name?.split('.').pop();
  }

  @computed get fileSize() {
    return this.size?.toNumber();
  }

  @computed get fileDuration() {
    return this.duration?.toNumber();
  }

  @action private assign = (props: IMCAttachment) => {
    Object.assign(this, props);
  };

  @computed get isDocument(): boolean {
    return this.type === MCAttachment.Type.DOCUMENT;
  }

  @computed get isAudio(): boolean {
    return this.type === MCAttachment.Type.AUDIO;
  }

  @computed get isVoiceNote(): boolean {
    return this.type === MCAttachment.Type.VOICE;
  }

  @computed get isImage(): boolean {
    return this.type === MCAttachment.Type.IMAGE;
  }

  @computed get isSticker(): boolean {
    return this.type === MCAttachment.Type.STICKER;
  }

  @computed get isGif(): boolean {
    return this.type === MCAttachment.Type.GIF;
  }

  @computed get isVideo(): boolean {
    return this.type === MCAttachment.Type.VIDEO;
  }

  @computed get isDocumentImage(): boolean {
    return isImageFile(this.mimeType, this.name);
  }
}

export default Attachment;
