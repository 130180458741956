export default function getFileExtension(filename?: string | null): string {
  if (!filename) {
    return '';
  }

  const dotIndex = filename.lastIndexOf('.');
  return dotIndex >= 0 ? filename.substring(dotIndex + 1) : '';
}

export function isWebmExtension(fileExt?: string | null): boolean {
  return fileExt === 'webm';
}

export function hasWebmExtension(fileName?: string | null): boolean {
  const fileExt = getFileExtension(fileName);
  return isWebmExtension(fileExt);
}

export function isMovExtension(fileExt?: string | null): boolean {
  return fileExt === 'mov';
}

export function hasMovExtension(fileName?: string | null): boolean {
  const fileExt = getFileExtension(fileName);
  return isMovExtension(fileExt);
}
