import {arrayMove} from '@dnd-kit/sortable';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useStore} from '../../../stores/AppStore';
import {ModalType} from '../../../stores/ModalType';

import {FormLabel} from 'o-ui';
import SortableContainer from '../../../components/SortableContainer';
import {Snippet, SnippetType, filterSnippetsList} from '../../../stores/Snippets';
import NoSnippetsItem from './NoSnippetsItem';
import SnippetManage from './SnippetManage';

interface ListHeaderProps {
  allowChange?: boolean;
}

const ListHeader = ({allowChange}: ListHeaderProps) => {
  const {t} = useTranslation();

  return (
    <div className="snippets-tab__list-header mt-5">
      <div className="snippet-manage snippet-manage--title">
        {allowChange ? <div className="snippet-manage__dnd" /> : null}
        <div className="snippet-manage__shortcut">{t('settings_snippets_list_shortcut_title')}</div>
        <div className="snippet-manage__message">{t('settings_snippets_list_message_title')}</div>
        {allowChange ? <div className="snippet-manage__actions" /> : null}
      </div>
    </div>
  );
};

interface SnippetsListProps {
  type: SnippetType;
  filter: string;
  onCreateSnippet(): void;
}

export const SnippetsList: React.FC<SnippetsListProps> = observer((props) => {
  const {t} = useTranslation();
  const {modals, contextMenu} = useStore();
  const {activeWorkspace, userStore, notifications} = useStore();

  const snippets = activeWorkspace.getSnippetsStore(props.type);
  const [snippetsList, setSnippetsList] = React.useState<Snippet[]>([]);

  const handleDelete = (snippet: Snippet) => {
    modals.open(ModalType.CONFIRM, {
      maxWidth: 'sm',
      headerText:
        snippet.type === SnippetType.Workspace
          ? t('modal_delete_workspace_snippet_title')
          : t('modal_delete_personal_snippet_title'),
      content: (
        <div className="overflow-auto custom-scroll-x">
          <FormLabel className="body3-regular text-capitalize d-flex mb-1">
            {t('modal_delete_snippet_shortcut_label')}
          </FormLabel>
          <div className="mb-2 text-break">{snippet.command}</div>

          <FormLabel className="body3-regular text-capitalize d-flex mb-1">
            {t('modal_delete_snippet_shortcut_text')}
          </FormLabel>
          <div className="snippet-text-inner text-break" dangerouslySetInnerHTML={{__html: snippet.text || ''}} />
        </div>
      ),
      submitButtonText: t('modal_delete_btn'),
      callbacks: {
        submit: async () => {
          const {res} = await snippets.delete(snippet);

          if (res) {
            notifications.success(t('notification_snippet_delete_success', {shortCode: snippet.command}));
          }
        },
      },
    });
  };

  const handleEdit = (snippet: Snippet) => {
    modals.open(ModalType.EDIT_SNIPPET, {
      snippet,
      callbacks: {
        delete: () => handleDelete(snippet),
      },
    });
  };

  React.useEffect(() => {
    setSnippetsList(filterSnippetsList(snippets.list, props.filter));
  }, [snippets.list, props.filter]);

  const onSortEnd = async (oldIndex: number, newIndex: number) => {
    const sortedItems = arrayMove(snippetsList, oldIndex, newIndex);

    const {error} = await snippets.updateOrder(sortedItems);

    if (error) {
      notifications.error(t('settings_snippets_manage_change_order_error_notification'));
    }
  };

  if (!snippetsList.length) {
    return (
      <div className="snippets-tab__list h-100">
        <NoSnippetsItem
          onCreateSnippet={props.onCreateSnippet}
          snippetType={props.type}
          isSearchQuery={!!props.filter}
        />
      </div>
    );
  }

  const allowChange = snippets.isPersonal || userStore.isAdmin || userStore.isOwner;

  return (
    <>
      <ListHeader allowChange={allowChange} />

      <div
        className="snippets-tab__list custom-scrollbar"
        onMouseLeave={() => {
          contextMenu.close();
        }}
      >
        <SortableContainer<Snippet> items={snippetsList} onSortEnd={onSortEnd}>
          {snippetsList.map((snippet) => (
            <SnippetManage
              key={snippet.id?.toString()}
              snippet={snippet}
              onDelete={handleDelete}
              className="snippets-tab__snippet-manage"
              allowChange={allowChange}
              onEdit={handleEdit}
            />
          ))}
        </SortableContainer>
      </div>
    </>
  );
});

export default SnippetsList;
