import cn from 'classnames';
import React from 'react';

import {entities} from '../../api/proto';
import {ReactComponent as BrandIcon} from '../../assets/image-icons/brandIcon.svg';
import {ReactComponent as TelegramIconSquared} from '../../assets/image-icons/telegramIconSquared.svg';
import {ReactComponent as TelegramBotIconSquared} from '../../assets/image-icons/telegramBotIconSquared.svg';
// import {ReactComponent as SignalIconSquared} from '../../assets/image-icons/signalIconSquared.svg';
import {ReactComponent as InstagramIconSquared} from '../../assets/image-icons/instagramIconSquared.svg';
import {ReactComponent as LiveChatIconSquared} from '../../assets/image-icons/liveChatIconSquared.svg';

interface Props {
  type?: entities.OzekonChannelType | null;
  success: boolean;
}

const getStatusImgUrl = (type?: entities.OzekonChannelType | null) => {
  switch (type) {
    case entities.OzekonChannelType.OIT_TELEGRAM:
      return <TelegramIconSquared />;
    case entities.OzekonChannelType.OIT_WEB_WIDGET:
      return <LiveChatIconSquared />;
    case entities.OzekonChannelType.OIT_INSTAGRAM:
      return <InstagramIconSquared />;
    case entities.OzekonChannelType.OIT_TELEGRAM_BOT:
      return <TelegramBotIconSquared />;

    // case com.chatman.Source.SIGNAL:
    //   return <SignalIconSquared />;
    // case com.chatman.Source.WEB_WIDGET:
    //   return <LiveChatIconSquared />;
  }
  return <TelegramIconSquared />;
};

const ChannelCreationStepImage: React.FC<Props> = ({success, type}) => {
  return (
    <div className={cn('onboarding__creation-step-image creation-step-image', {success})}>
      <div className="creation-step-image__inner">
        <div className="creation-step-image__logo">
          <BrandIcon className="creation-step-image__logo-svg" />
        </div>
        <div className="creation-step-image__middle-icon">
          {success ? (
            <i className="tg-icon-check-fill color-white icon-size-20"></i>
          ) : (
            <i className="tg-icon-link-line color-white icon-size-20"></i>
          )}
        </div>
        <div className="creation-step-image__logo">{getStatusImgUrl(type)}</div>
      </div>
    </div>
  );
};

export default ChannelCreationStepImage;