import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import 'highlight.js/styles/github.css';
import remarkMenu from './remarkMenu';

const WidgetApiDocsPage = () => {
  const [widgetApiDocsText, setWidgetApiDocsText] = React.useState('');

  React.useEffect(() => {
    fetch('/widget-api-docs.md')
      .then((res) => res.text())
      .then((text) => setWidgetApiDocsText(text));
  });

  return (
    <div className="widget-api-docs">
      <div className="widget-api-docs__side-bar vh-100 custom-scroll-y">
        <div className="widget-api-docs__side-bar-title">API functions</div>
        <ReactMarkdown
          remarkPlugins={[remarkMath, remarkMenu, remarkGfm]}
          rehypePlugins={[rehypeHighlight, rehypeRaw, rehypeSlug]}
        >
          {widgetApiDocsText}
        </ReactMarkdown>
      </div>
      <div className="widget-api-docs__content vh-100 custom-scroll-y">
        <ReactMarkdown remarkPlugins={[remarkMath, remarkGfm]} rehypePlugins={[rehypeHighlight, rehypeRaw, rehypeSlug]}>
          {widgetApiDocsText}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default WidgetApiDocsPage;
