import React from 'react';
import Long from 'long';
import {autorun} from 'mobx';

import {Chat} from '../../../../../stores/Chat';
import {useStore} from '../../../../../stores/AppStore';
import isInViewport from '../../../../../utils/dom/isInViewport';
import isVisible from '../../../../../utils/dom/isVisible';


interface UseSelectedChatProps {
  chats: Chat[];
  listRendered: boolean;
}

export const useSelectedChatIndex = (props: UseSelectedChatProps): [number | null, Long | null] => {
  const {chatsView} = useStore();
  const [activeUsersChatId, setActiveUsersChatId] = React.useState<Long | undefined>(chatsView.activeUsersChat?.id);

  React.useEffect(() => {
    autorun(() => {
      // console.debug(`%c--->useSelectedChatIndex autorun: ${chatsView.activeUsersChat?.id?.toString()}`, 'color: red');
      setActiveUsersChatId(chatsView.activeUsersChat?.id)
    });
  }, [
    chatsView.activeUsersChat,
  ]);

  return React.useMemo(() => {
    if (activeUsersChatId && props.listRendered) {
      let result: number | null = null;
      const idx = props.chats.findIndex((c) => {
        return c?.id?.equals(activeUsersChatId);
      });

      const el = document.querySelector<HTMLElement>(`.chat-message-list [data-id="${activeUsersChatId?.toString()}"]`);

      if (idx >= 0 && (!el || !isInViewport(el) || !isVisible(el, el.parentElement?.parentElement))) {
        result = idx;
      } else if (idx < 0) {
        result = 0;
      }
      return [result, activeUsersChatId];
    }

    return [null, null];
  }, [
    activeUsersChatId,
    props.chats,
    props.listRendered,
  ]);
};

export default useSelectedChatIndex;
