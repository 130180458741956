import {matchPath} from 'react-router-dom';
import Paths from '../../../routes/Paths';
import PageType from '../PageType';

export default function getPageType(path: string): PageType {
  if (matchPath(Paths.Billing, path)) {
    return PageType.ProductBilling;
  } else if (matchPath(Paths.Settings, path)) {
    return PageType.ProductSettings;
  } else if (matchPath(Paths.Login, path)) {
    return PageType.Entrance;
  } else if (matchPath(Paths.Registration, path)) {
    return PageType.Cart;
  } else if (matchPath(Paths.RegistrationComplete, path)) {
    return PageType.Onboarding;
  }

  return PageType.Product;
}