import React from 'react';
import {useTranslation} from 'react-i18next';

import FormLabel from 'o-ui/FormLabel';
import Channel from '../../../../stores/Channel';

import brandIcon from '../../../../assets/image-icons/brandIcon.svg';
import instagramIconSquared from '../../../../assets/image-icons/instagramIconSquared.svg';

interface IProps {
  title?: string;
  subTitle?: string;
  channel?: Channel | null;
}

export const InstagramCreationResult: React.FC<IProps> = ({
  title,
  subTitle,
  channel,
}) => {
  const {t} = useTranslation();

  return (
    <div className="channel-dialog-form__form custom-scroll d-flex flex-column">
      {title ? <div className="h1-bold color-body-primary">{title}</div> : null}
      {subTitle ? <div className="body1-regular color-body-primary pb-6">{subTitle}</div> : null}

      <div className="account-dialog-form__title-icons">
        <img className="account-dialog-form__tg-icon" src={instagramIconSquared} alt="" />
        <img className="account-dialog-form__ozekon-icon" src={brandIcon} alt="" />
      </div>
      <div className="h4-bold font-bold color-body-primary mt-5">
        {t('settings_channel_creation_form_success_connect')}
      </div>

      <FormLabel className="body3-regular text-capitalize d-flex mt-5 mb-1">
        {t('settings_channel_creation_form_channel_user_name_label')}
      </FormLabel>
      <p className="body1-regular m-0">{channel?.userName || '-'}</p>

      <FormLabel className="body3-regular text-capitalize d-flex mt-5 mb-1">
        {t('settings_channel_creation_form_channel_name_label')}
      </FormLabel>
      <p className="body1-regular m-0 mb-5">{channel?.name || '-'}</p>
    </div>
  );
};

export default InstagramCreationResult;
