import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';

import {
  IMCMessagePreview,
  MCAttachment,
  MCMessagePreview,
} from '../../api/proto';
import {isGifFile, isImageFile} from '../../utils/file/fileReaders';
import MessageAttachment from '../Attachment/MessageAttachment';
import Chat from '../Chat';
import Message from './Message';
import {convertToMessage, getMessageServiceText, messageIsDeleted, messageIsEdited} from './utils';


export class MessagePreview extends MCMessagePreview {

  @observable messageAttachments?: MessageAttachment[] | null;

  constructor(public raw: IMCMessagePreview, public chat: Chat, lastMessageSentAt?: Long | null) {
    super(raw);
    makeObservable(this);

    this.setLastMessageSentAt(lastMessageSentAt);
    this.messageAttachments = raw?.attachments?.map((attach) => new MessageAttachment(
      attach,
      new Message(convertToMessage(raw, chat?.id), chat),
    ));
  }

  @action setLastMessageSentAt = (lastMessageSentAt?: Long | null) => {
    this.lastMessageSentAt = lastMessageSentAt;
  };

  get id(): Long {
    return this.localID;
  }

  lastMessageSentAt?: Long | null;

  get operator() {
    return this.chat?.store.channel.workspace.findMemberByUserId(this.operatorID);
  }

  get isExternal(): boolean {
    return !!this.peer;
  }

  get isOwn(): boolean {
    if (!this.operatorID) {
      return false;
    }
    return !!this.chat?.channel.app.userStore.user?.userId?.equals(this.operatorID);
  }

  get senderName() {
    if (this.peer?.id && this.chat.store?.directPeer?.id?.equals(this.peer?.id)) {
      return this.chat.store?.directPeer.fullName;
    }

    if (this.peer) {
      return this.peer.name || '';
    }

    if (this.operatorID.greaterThan(0)) {
      return this.operator?.fullName || this.operatorID.toString() || '';
    }

    if (this.isOwn) {
      return this.chat?.channel.app.userStore.user?.fullName || '';
    }

    return '';
  }

  get stamp() {
    return this.lastMessageSentAt;
  }

  @computed get isSystem(): boolean {
    return !!this.serviceMessage;
  }

  get fileId() {
    return Long.ZERO;
  }

  @computed get edited() {
    return messageIsEdited(this);
  }

  get pinned() {
    return false;
  }

  @computed get menuIsAvailable(): boolean {
    return false;
  }

  @computed get firstAttachment(): MessageAttachment | null {
    return this.messageAttachments ? this.messageAttachments[0] : null;
  }

  @computed get type(): MCAttachment.Type | null {
    if (!this.attachments?.length) {
      return null;
    }

    const type: MCAttachment.Type | null | undefined = this.firstAttachment?.type;
    const every = this.attachments.every((attachment) => attachment.type === type);

    return every ? type || MCAttachment.Type.UNKNOWN : MCAttachment.Type.DOCUMENT;
  }

  @computed get isFailed(): boolean {
    return false; //NOT implemented yet
  }

  @computed get isDelivered(): boolean {
    return this.localID ? this.localID.greaterThan(0) : false;
  }

  @computed get isReadInbox(): boolean {
    return !(!this.chat?.inReadPointer || this.localID?.greaterThan(this.chat.inReadPointer));
  }

  @computed get isReadOutbox(): boolean {
    if (!this.chat?.outReadPointer) {
      return false;
    }
    return this.localID?.lessThanOrEqual(this.chat.outReadPointer);
  }

  @computed get serviceMessageText(): string {
    return this.serviceMessage ? getMessageServiceText(this.serviceMessage, this) : '';
  }

  @computed get deleted() {
    return messageIsDeleted(this);
  }


  @computed get hasDocument() {
    return this.type === MCAttachment.Type.DOCUMENT && !isGifFile(this.firstAttachment?.mimeType);
  }

  @computed get hasAudio() {
    return this.type === MCAttachment.Type.AUDIO;
  }

  @computed get hasImage() {
    return this.type === MCAttachment.Type.IMAGE;
  }

  @computed get hasVideo() {
    return this.type === MCAttachment.Type.VIDEO;
  }

  @computed get hasSticker() {
    return this.type === MCAttachment.Type.STICKER && !this.hasStickerAnimated;
  }

  @computed get hasStickerAnimated() {
    return this.type === MCAttachment.Type.STICKER && !isImageFile(this.firstAttachment?.mimeType);
  }

  @computed get hasGif() {
    return this.type === MCAttachment.Type.GIF || isGifFile(this.firstAttachment?.mimeType);
  }

  @computed get hasVoice() {
    return this.type === MCAttachment.Type.VOICE;
  }

}

export default MessagePreview;
