import {MCChat, MCMethodGetChatsPreview} from '../../../api/proto';
import Chat from '../Chat';

export function getChatPreviewStatus(chat?: Chat | null) {
  if (!chat?.channel.assignmentEnabled) {
    return MCMethodGetChatsPreview.MCChatStatus.S_ALL;
  }

  switch (chat?.status) {
    case MCChat.MCChatStatus.S_IN_PROGRESS:
      return MCMethodGetChatsPreview.MCChatStatus.S_IN_PROGRESS;
    case MCChat.MCChatStatus.S_OPEN:
      return MCMethodGetChatsPreview.MCChatStatus.S_OPEN;
    case MCChat.MCChatStatus.S_RESOLVED:
      return MCMethodGetChatsPreview.MCChatStatus.S_RESOLVED;
    case MCChat.MCChatStatus.S_NO_STATUS:
    default:
      return MCMethodGetChatsPreview.MCChatStatus.S_ALL;
  }
}

export default getChatPreviewStatus;