import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import Checkbox from 'o-ui/Checkbox';
import DialogActions from 'o-ui/Dialog/DialogActions';
import DialogContent from 'o-ui/Dialog/DialogContent';

import {ModalItem} from '../stores/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
}

export const ModalDeleteChatMessage = (props: IProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);
  const {message} = props.modal.data;

  const [bothSides, setBothSides] = React.useState<boolean>(!!message?.chat.isSuperGroup);

  if (!message) {
    return null;
  }

  const handleSubmit = () => {
    message?.chat.store.deleteMessages(message.id, bothSides);
    handleCloseClick();
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  const handleChangeBothSides = () => {
    setBothSides(!bothSides);
  };

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      title={t('modal_chat_message_delete_title')}
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogContent className="p-6 body1-regular">
        <div className="p-0">{t('modal_chat_message_delete_question')}</div>
        <div className="p-0 pt-3">{t('modal_chat_message_delete_description')}</div>
        {(message.chat.isPrivate || message.chat.isGroup) && !message.chat.channel.isWebWidget ? (
          <div className="p-0 pt-6">
            <Checkbox checked={bothSides} onChange={handleChangeBothSides}>
              {t('modal_chat_message_delete_for_both_sides', {name: props.modal.data?.message?.chat?.name})}
            </Checkbox>
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" size="large" onClick={handleSubmit}>
          {t('modal_delete_btn')}
        </Button>
        <Button variant="contained" color="secondary" size="large" autoFocus onClick={handleCloseClick}>
          {t('modal_cancel_btn')}
        </Button>
      </DialogActions>
    </ModalDialog>
  );
};

export default ModalDeleteChatMessage;
