import React from 'react';
import cn from 'classnames';

import {VideoPlayerProgressBar} from './VideoPlayerProgressBar';
import {formatSeconds} from '../../utils/formatSeconds';
import VolumeControl from '../AudioPlayer/VolumeControl';


export interface VideoPlayerControlsProps {
  className?: string;
  played: number;
  hide: boolean;
  playedSeconds: number;
  onProgressClick: (fraction: number) => void;
  volume: number;
  changeVolume: (value: number) => void;
  duration?: number | null;
  togglePlayingClick: (e: React.MouseEvent) => void;
  isPlaying: boolean;
  toggleFullScreenClick: (e: React.MouseEvent) => void;
  toggleSmallPlayerClick: (e: React.MouseEvent) => void;
}

export const VideoPlayerControls = (props: VideoPlayerControlsProps) => {
  const playedSeconds = formatSeconds(props.playedSeconds);
  const duration = formatSeconds(props.duration || 0);

  return (
    <div className={cn('video-player-controls', {hide: props.hide}, props.className)}>
      <VideoPlayerProgressBar
        played={props.played}
        playedSeconds={props.playedSeconds}
        onProgressClick={props.onProgressClick}
      />
      <div className="video-player-controls__bottom">
        <div className="video-player-controls__bottom__volume-block">
          <VolumeControl
            className="video-player-controls__volume-control"
            volume={props.volume}
            onChange={props.changeVolume}
            color="gray"
          />
        </div>
        <div className="video-player-controls__bottom__time-block">
          <div className={`video-player-controls__bottom__time-block__sec width-${playedSeconds.length}`}>
            {playedSeconds}
          </div>
          <div onClick={props.togglePlayingClick} className="video-player-controls__bottom-button video-player-controls__bottom-button--play">
            <i className={props.isPlaying ? 'tg-icon tg-icon-pause-line' : 'tg-icon tg-icon-play-fill'} />
          </div>
          <div className={`video-player-controls__bottom__time-block__sec width-${duration.length}`}>
            {duration}
          </div>
        </div>
        <div className="video-player-controls__bottom__right-block">
          <div onClick={props.toggleFullScreenClick} className="video-player-controls__bottom-button">
            <i className="tg-icon tg-icon-fullscreen-line" />
          </div>
          <div onClick={props.toggleSmallPlayerClick} className="video-player-controls__bottom-button">
            <i className="tg-icon tg-icon-picture-in-picture-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayerControls;
