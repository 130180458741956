import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Message from '../../../stores/Message';
import ModalType from '../../../stores/ModalType';
import useStore from '../../../stores/useStore';
import {parseUnixStamp} from '../../../utils/format';


const DATE_FORMAT = 'MMMM D';
const DATE_YEAR_FORMAT = 'MMMM D YYYY';

interface MessageDayTitleProps {
  className?: string;
  message: Message;
  prevMessage?: Message | null;
}

export const MessageDayTitle: React.FC<MessageDayTitleProps> = ({className, message, prevMessage}) => {
  const {t} = useTranslation();
  const {modals} = useStore();
  let title = '';
  const FORMAT_STR = moment().local().isSame(parseUnixStamp(message.stamp), 'year')
    ? DATE_FORMAT
    : DATE_YEAR_FORMAT;

  if (prevMessage) {
    if (parseUnixStamp(message.stamp).isAfter(parseUnixStamp(prevMessage.stamp), 'day')) {
      if (moment().local().isSame(parseUnixStamp(message.stamp), 'day')) {
        title = t('chat_user_chat_message_day_today_title');
      } else {
        title = parseUnixStamp(message.stamp).format(FORMAT_STR);
      }
    }
  } else {
    if (moment().local().isSame(parseUnixStamp(message.stamp), 'day')) {
      title = t('chat_user_chat_message_day_today_title');
    } else {
      title = parseUnixStamp(message.stamp).format(FORMAT_STR);
    }
  }

  const handleClick = () => {
    modals.open(ModalType.JUMP_TO_DATE, {chat: message.chat});
  };

  if (title) {
    return (
      <div className={cn('chat-day-title', className)}>
        <span className="chat-day-title__date cursor-pointer" onClick={handleClick}>
          {title}
        </span>
      </div>
    );
  }

  return null;
};

export default MessageDayTitle;
