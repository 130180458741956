import React from 'react';

import InviteLinksListItem from './InviteLinksListItem';
import Invite from '../../../../stores/Workspaces/Invite';


interface Props {
  inviteLinks: Invite[];
  onDelete?: (inviteLink: Invite) => void;
}

export const InviteLinksList: React.FC<Props> = React.memo((props) => {
  return (
    <div className="invite-links-list">
      {props.inviteLinks.map((row, i) => (
        <InviteLinksListItem
          key={row.raw.id?.toString() ?? i}
          inviteLink={row}
          onDelete={props.onDelete}
        />
      ))}
    </div>
  );
});

export default InviteLinksList;
