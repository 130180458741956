import cn from 'classnames';
import React from 'react';

import {TelegramChannelState, WebWidgetChannelState, entities} from '../../../api/proto';
import Channel from '../../../stores/Channel';
import {getChannelStateTitle} from '../../../stores/Channel/utils/channel';

const getClassByStatusType = (type: 'warning' | 'success'): string => {
  let borderClass = '';
  let colorClass = '';

  switch (type) {
    case 'success':
      borderClass = 'border-success-tint3';
      colorClass = 'color-success-primary';
      break;
    case 'warning':
      borderClass = 'border-warning-tint3';
      colorClass = 'color-warning-primary';
      break;
  }

  return `${borderClass} ${colorClass}`;
};

const getTgChannelStatusClassNames = (state?: TelegramChannelState.State | null): string => {
  switch (state) {
    case TelegramChannelState.State.CREATE_CHANNEL:
    case TelegramChannelState.State.ENTER_CODE:
    case TelegramChannelState.State.ENTER_NUMBER:
    case TelegramChannelState.State.ENTER_PASSWORD:
      return getClassByStatusType('warning');
    case TelegramChannelState.State.EVENT_LISTENING:
      return getClassByStatusType('success');
    default:
      return '';
  }
};

const getWebWidgetChannelStatusClassNames = (state?: WebWidgetChannelState.State | null): string => {
  switch (state) {
    case WebWidgetChannelState.State.CREATE_CHANNEL:
      return getClassByStatusType('success');
    default:
      return '';
  }
};

interface ChannelStatusProps {
  channel: Channel;
}

export const ChannelStatus: React.FC<ChannelStatusProps> = (props) => {
  const getStatusClassNames = () => {
    switch (props.channel.type) {
      case entities.OzekonChannelType.OIT_TELEGRAM:
        return getTgChannelStatusClassNames(props.channel.state?.telegram?.state);
      case entities.OzekonChannelType.OIT_WEB_WIDGET:
        return getWebWidgetChannelStatusClassNames(props.channel.state?.webWidget?.state);
      default:
        return '';
    }
  };

  return (
    <div className={cn('channel-status radius-6 body2-regular align-items-center', getStatusClassNames())}>
      {getChannelStateTitle(props.channel)}
    </div>
  );
};

export default ChannelStatus;
