import React from 'react';

export function useMediaQuery(query: string): boolean {
  const getMatches = (queryStr: string): boolean => {
    if (typeof window !== 'undefined') {
      return window.matchMedia(queryStr).matches;
    }
    return false;
  };

  const [matches, setMatches] = React.useState<boolean>(getMatches(query));

  const handleChange = React.useCallback(() => {
    setMatches(getMatches(query));
  }, [query]);

  React.useEffect(() => {
    const matchMedia = window.matchMedia(query);

    handleChange();

    matchMedia.addEventListener('change', handleChange);

    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
  }, [handleChange, query]);

  return matches;
}

export default useMediaQuery;
