import React from 'react';
import {generatePath, matchPath} from 'react-router';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import Paths from '../../routes/Paths';
import settingsPaths from '../../routes/Paths/settingsPaths';
import {getSettingsTabByPath} from '../../stores/LayOutStore/utils/getSettingsTabByPath';
import ModalType from '../../stores/ModalType';
import useStore from '../../stores/useStore';
import {base64ToUint8Array, uint8ArrayToBase64} from '../../utils/arrayUtils';
import isNil from '../../utils/isNil';

type RouteParams = {
  workspaceId?: string;
  code?: string;
  email?: string;
};

function decodeRouteParams(params: RouteParams): RouteParams {
  const decodedParams: RouteParams = {};
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      decodedParams[key] = decodeURIComponent(params[key] || '');
    }
  }
  return decodedParams;
}

function isSettingsPath(path: string): boolean {
  return settingsPaths.some((settingPath) => matchPath(settingPath, path));
}


const operatorSettingsPath: string[] = [Paths.Snippets, Paths.Tags, Paths.PersonalInfo, Paths.DownloadApp];
const adminSettingsPath: string[] = [...operatorSettingsPath, Paths.Channels, Paths.WorkspaceMembers];

export function allowSettingsPathForOperator(pathname: string): boolean {
  if (!isSettingsPath(pathname)) {
    return true;
  }
  return operatorSettingsPath.some((path) => !!matchPath(path, pathname));
}

export function allowSettingsPathForAdmin(pathname: string): boolean {
  if (!isSettingsPath(pathname)) {
    return true;
  }
  return adminSettingsPath.some((path) => !!matchPath(path, pathname));
}

export const useSettingsPageRoute = () => {
  const {code, email, workspaceId} = useParams<RouteParams>();
  const location = useLocation();
  const navigate = useNavigate();

  const {layOutStore, userStore, activeWorkspace, workspaces, modals, setInitialSelections} = useStore();

  const params = React.useMemo(() => {
    return decodeRouteParams({code, email, workspaceId});
  }, [code, email, workspaceId]);

  React.useEffect(() => {
    setInitialSelections({workspaceId: params.workspaceId});
  }, [params, setInitialSelections, workspaces]);

  React.useEffect(() => {
    if (matchPath(Paths.ResetPasswordProfile, location.pathname) && params.code && params.email) {
      modals.open(ModalType.PROFILE_RESET_PASSWORD, {code: params.code, email: params.email}, true, true);
    }
  }, [modals, location.pathname, params]);

  React.useEffect(() => {
    const activeTab = getSettingsTabByPath(location.pathname);

    if (!isNil(activeTab)) {
      layOutStore.setActiveSettingsTab(activeTab);
    }

    if (!activeWorkspace.isInit) {
      return;
    }

    if (
      (userStore.isOperator && !allowSettingsPathForOperator(location.pathname)) ||
      (userStore.isAdmin && !allowSettingsPathForAdmin(location.pathname))
    ) {
      navigate(
        generatePath(Paths.PersonalInfo, {
          workspaceId: encodeURIComponent(uint8ArrayToBase64(activeWorkspace.id)),
        }),
        {replace: true},
      );
    }

    layOutStore.setShowSettingsTabContent(true);

    if (layOutStore.activeSettingsTabRef?.reset) {
      layOutStore.activeSettingsTabRef.reset();
    }

    if (params.workspaceId) {
      console.debug(`useSettingsPageRoute: selectWorkspace`, params.workspaceId);
      workspaces.selectWorkspace(base64ToUint8Array(params.workspaceId));
    }
  }, [
    params,
    userStore.isOperator,
    userStore.isAdmin,
    navigate,
    activeWorkspace.id,
    activeWorkspace.isInit,
    layOutStore,
    location.pathname,
    workspaces,
  ]);
};

export default useSettingsPageRoute;
