import {observer} from 'mobx-react';
import React from 'react';

import {entities} from '../api/proto';
import InstagramCreationForm from '../pages/Settings/Channels/Instagram/InstagramCreationForm';
import TelegramBotCreationForm from '../pages/Settings/Channels/TelegramBotCreation';
import TelegramCreationForm from '../pages/Settings/Channels/TelegramCreationForm';
import WebWidgetCreationForm from '../pages/Settings/Channels/WebWidgetCreation';
import {ModalItem} from '../stores/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';
import Paths from '../routes/Paths';
import useWorkspaceNavigate from '../hooks/useWorkspaceNavigate';

interface ModalTelegramCreationProps {
  modal: ModalItem;
}

export const ModalChannelCreation: React.FC<ModalTelegramCreationProps> = observer((props) => {
  const modalRef = React.useRef<ModalDialogRef>(null);
  const type = props.modal.data?.channelType;
  const channelScreenType = props.modal.data?.channelScreenType;
  const workspaceNavigate = useWorkspaceNavigate();

  const handleCloseClick = () => {
    modalRef.current?.close();
    handleCrossCloseClick();
  };

  const handleCrossCloseClick = () => {
    if (type === entities.OzekonChannelType.OIT_INSTAGRAM) {
      workspaceNavigate(Paths.Channels, {}, {channelType: 'instagram'});
    }
  };

  const getCreationForm = () => {
    switch (type) {
      case entities.OzekonChannelType.OIT_TELEGRAM:
        return <TelegramCreationForm type={type} onClose={handleCloseClick} modalHeader />;
      case entities.OzekonChannelType.OIT_WEB_WIDGET:
        return <WebWidgetCreationForm type={type} onClose={handleCloseClick} modalHeader />;
      case entities.OzekonChannelType.OIT_INSTAGRAM:
        return (
          <InstagramCreationForm
            type={type}
            onClose={handleCloseClick}
            modalHeader
            channelScreenType={channelScreenType}
          />
        );
      case entities.OzekonChannelType.OIT_TELEGRAM_BOT:
        return (
          <TelegramBotCreationForm
            type={type}
            onClose={handleCloseClick}
            modalHeader
          />
        );
      case entities.OzekonChannelType.OIT_NONE:
      default:
        return null;
    }
  };

  const getModalClassNames = () => {
    switch (type) {
      case entities.OzekonChannelType.OIT_WEB_WIDGET:
        return 'web-widget-creation-modal';
      default:
        return '';
    }
  };

  const form = getCreationForm();

  return form ? (
    <ModalDialog
      maxWidth="md"
      ref={modalRef}
      modal={props.modal}
      className={`channel-creation-dlg ${getModalClassNames()}`}
      onClose={handleCrossCloseClick}
    >
      {form}
    </ModalDialog>
  ) : null;
});

export default ModalChannelCreation;
