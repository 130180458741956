import {action, computed, makeObservable, observable} from 'mobx';
import {entities} from '../../api/proto';
import {uint8ArrayToBase64} from '../../utils/arrayUtils';

export default class Workspace extends entities.Workspace {
  @observable public id: Uint8Array = new Uint8Array();
  @observable public name: string = '';
  @observable public avatar?: entities.IAttachmentSource | null;
  @observable public blocked: boolean = false;

  constructor(public raw: entities.IWorkspace) {
    super(raw);
    console.debug('%c Workspace', 'color: red', raw);

    makeObservable(this);
    this.update(raw);
  }

  @action update = (raw?: entities.IWorkspace | null) => {
    if (raw?.id) {
      this.id = raw.id;
    }

    this.name = raw?.name || '';
    this.avatar = raw?.avatar;
    this.blocked = !!raw?.blocked;
  };

  @computed get base64Id() {
    return uint8ArrayToBase64(this.id);
  }

  @computed get uuid() {
    return uint8ArrayToBase64(this.id);
  }

  @computed get displayName() {
    return this.name || this.base64Id;
  }
}
