import React from 'react';
import {useTranslation} from 'react-i18next';
import PhoneInput from 'o-ui/Input/PhoneInput';
import FormLabel from 'o-ui/FormLabel';

interface PhoneNumberFieldProps {
  phone?: string;
}

export const PhoneNumberField = (props: PhoneNumberFieldProps) => {
  const {t} = useTranslation();

  return (
    <>
      <FormLabel className="body3-regular body-secondary d-flex mb-2 text-capitalize mt-5">
        {t('Phone number')}
      </FormLabel>
      <PhoneInput className="account-dialog-form__phone-input w-100" variant="outlined" value={props.phone} disabled />
    </>
  );
};
