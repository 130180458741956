import {observer} from 'mobx-react';
import React from 'react';
import {Navigate, generatePath} from 'react-router';


import {Paths} from '../../routes/Paths';
import ChannelScreenType from '../../stores/Channel/ChannelScreenType';
import useStore from '../../stores/useStore';
import {uint8ArrayToBase64} from '../../utils/arrayUtils';
import getQueryStringParam from '../../utils/getQueryStringParam';

interface IState {
  workspaceId?: string;
  channelType?: string;
  channelScreenType?: string;
}

function getState(): IState{
  try {
    const stateStr = getQueryStringParam('state');
    const state = JSON.parse(stateStr);
    return state;
  } catch (e) {
    console.error(`getState failed`, e)
  }

  return {};
}

const ChannelsInstagramCallbackPage = observer(() => {
  const {activeWorkspace} = useStore();

  const state = getState();
  const [workspaceId, setWorkspaceId] = React.useState<string | null>(
      state.workspaceId || encodeURIComponent(uint8ArrayToBase64(activeWorkspace.id))
  );

  React.useEffect(() => {
    if (activeWorkspace.id) {
      setWorkspaceId(encodeURIComponent(uint8ArrayToBase64(activeWorkspace.id)));
    }
  }, [
    activeWorkspace.id,
  ]);

  const redirectPath = generatePath(Paths.Channels, {
    workspaceId,
    channelType: state.channelType || 'instagram',
    channelScreenType: state.channelScreenType || ChannelScreenType.CALLBACK,
  });
  console.debug('redirectPath=', redirectPath);

  return workspaceId ? (
    <Navigate
      to={`${redirectPath}${location.search}`}
      replace
    />
  ) : null;
});

export default ChannelsInstagramCallbackPage;
