import React from 'react';

import {IMCAttachment} from '../../api/proto';
import {useStore} from '../../stores/AppStore';
import {DOWNLOAD_CANCELED_ERROR_MESSAGE} from '../../stores/Attachment/AttachmentStore';


interface AvatarAttachmentInfo {
  src: string;
  mimeType?: string;
  width?: number;
  height?: number;
  duration?: number;
  error?: string;
}

export default function useAvatarAttachment(attachment?: IMCAttachment | null, channelID?: Uint8Array | null): AvatarAttachmentInfo {
  const {activeWorkspace, uploader} = useStore();

  const width = attachment?.width?.toNumber();
  const height = attachment?.height?.toNumber();
  const duration = attachment?.duration?.toNumber();

  const att = activeWorkspace.attachmentStore.getFromCache(attachment?.source?.reference, channelID);
  const [src, setSrc] = React.useState<string>(att.src);
  const [mimeType, setMimeType] = React.useState<string | undefined>(att.mimeType);
  const [error, setError] = React.useState<string | undefined>();

  const loadingRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    async function getAttachment() {
      const attachmentID = attachment?.source?.reference;
      if (
        src &&
        !attachmentID?.length
      ) {
        setSrc('');
        setMimeType(undefined);
        setError(undefined);
        loadingRef.current = false;
        return;
      }
      if (
        !attachmentID?.length ||
        (error && error !== DOWNLOAD_CANCELED_ERROR_MESSAGE) ||
        loadingRef.current
      ) {
        return;
      }

      if (uploader.hasInProgress(attachmentID)) {
        const dataFile = uploader.getFile(attachmentID);
        setSrc(dataFile?.objectUrl || '');
        setMimeType(dataFile?.mimeType);
        console.debug(`%c----->useAvatarAttachment FileInProgress: ${dataFile?.objectUrl}`, 'color: red');
        return;
      }

      loadingRef.current = true;
      const res = await activeWorkspace.attachmentStore.get(attachmentID, channelID, attachment?.name);
      loadingRef.current = false;

      if (res?.error) {
        setError(res?.error?.message);
        setSrc('');
      } else if (res?.res?.src) {
        setSrc(res?.res?.src);
        setMimeType(res?.res?.mimeType);
        setError(undefined);
      }
    }

    getAttachment();
  }, [
    attachment,
    channelID,
    uploader,
    activeWorkspace,
    error,
    src,
    loadingRef,
  ]);

  // console.debug(`%c----->useAvatarAttachment->render src=${src}, att.src=${att.src}`, `color: #afb0b6`);
  return {
    width,
    height,
    duration,
    src,
    mimeType,
    error,
  };
}
