import cn from 'classnames';
import React from 'react';
import {observer} from 'mobx-react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';

import {useStore} from '../../stores/AppStore';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import FormLabel from 'o-ui/FormLabel';
import FormHelperText from 'o-ui/FormHelperText';
import {getUserResponseStatusError} from '../../api/getErrorByType';

export interface IPersonalInfoFormRef {
  submitForm?: () => void;
}

interface IProps {
  className?: string;
  title?: string;
  subTitle?: string;
  onSubmit?: () => void;
  onError?: () => void;
}

export const PersonalInfoForm = observer(
  React.forwardRef<IPersonalInfoFormRef, IProps>((props: IProps, ref) => {
    const {notifications, userStore} = useStore();
    const {t} = useTranslation();

    const [apiError, setApiError] = React.useState<string | null | undefined>('');

    const formik = useFormik({
      initialValues: {
        firstName: userStore.user?.firstName || '',
        lastName: userStore.user?.lastName || '',
      },
      validationSchema: Yup.object({
        firstName: Yup.string().required(t('Required')),
        lastName: Yup.string().required(t('Required')),
      }),
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: async (values) => {
        setApiError('');
        const {error, res} = await userStore.updateUserDetails(values);

        if (error) {
          setApiError(error.message);
          notifications.error(error.message);
        }

        if (res) {
          const statusErrorMessage = getUserResponseStatusError('changeDetails', res.status);

          if (statusErrorMessage) {
            notifications.error(statusErrorMessage);
            setApiError(statusErrorMessage);

            props.onSubmit && props.onSubmit();
            return;
          }

          props.onSubmit && props.onSubmit();
        }
      },
      onReset: () => {
        setApiError('');
      },
    });

    React.useImperativeHandle(ref, () => ({
      submitForm: () => formik.submitForm(),
    }));

    React.useEffect(() => {
      formik.setFieldValue('firstName', userStore.user?.firstName || '', false);
      formik.setFieldValue('lastName', userStore.user?.lastName || '', false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore.user]);

    return (
      <form
        className={cn(
          'onboarding__form',
          props.className,
        )}
        onSubmit={formik.handleSubmit}
      >
        {props.title ? <div className="h1-bold color-body-primary mt-auto mb-2">{props.title}</div> : null}
        {props.subTitle ? <div className="body1-regular color-body-primary mb-5">{props.subTitle}</div> : null}

        <FormLabel>{t('onboarding_personal_info_fist_name_label')}</FormLabel>
        <OutlinedTextInput
          className="w-100"
          name="firstName"
          placeholder={t('Enter your first name')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
          required
          errorHelper={formik.errors.firstName || ' '}
        />

        <FormLabel>{t('onboarding_personal_info_last_name_label')}</FormLabel>
        <OutlinedTextInput
          className="w-100"
          name="lastName"
          placeholder={t('onboarding_personal_info_last_name_placeholder')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastName}
          required
          errorHelper={formik.errors.lastName || ' '}
        />

        <FormHelperText className="mt-2" error>
          {apiError || ' '}
        </FormHelperText>
      </form>
    );
  }),
);

export default PersonalInfoForm;
