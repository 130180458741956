import cn from 'classnames';
import Long from 'long';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import useStore from '../../../../stores/useStore';
import {BILLING_DATE_FORMAT, formatDateTime} from '../../../../utils/format';


interface CanceledSubscriptionInfoProps {
  className?: string;
}

export const CanceledSubscriptionInfo: React.FC<CanceledSubscriptionInfoProps> = observer(({className}) => {
  const {t} = useTranslation();

  const {activeWorkspace} = useStore();
  const {billing} = activeWorkspace;
  const {expiresAt} = billing;

  const [payLoading, setPayLoading] = React.useState<boolean>(false);

  const handleClickPay = async () => {
    setPayLoading(true);
    await billing.resumeCurrentTariff();
    setPayLoading(false);
  };

  return (
    <div
      className={cn(
        'd-flex flex-row p-6',
        className,
      )}
    >
      <div className="d-flex flex-column justify-content-between">
        <div className="label-2 color-body-tertiary text-uppercase">
          {t('settings_billing_subscription_expires')}
        </div>

        <div className="h3-bold mt-3">
          {expiresAt?.greaterThan(Long.ZERO) ? formatDateTime(expiresAt, true, BILLING_DATE_FORMAT) : null}
        </div>
      </div>

      <div className="ml-auto d-flex align-items-center">
        <Button
          variant="outlined"
          color="tertiary"
          onClick={handleClickPay}
          loading={payLoading}
        >
          {t('settings_billing_subscription_undo_cancellation')}
        </Button>
      </div>
    </div>
  );
});

export default CanceledSubscriptionInfo;