import React from 'react';
import {observer} from 'mobx-react';
import {ContextMenuItem} from '../stores/ContextMenuStore';
import {useTranslation} from 'react-i18next';
import {useStore} from '../stores/AppStore';
import {ModalType} from '../stores/ModalType';

interface MenuChannelProps {
  menu: ContextMenuItem;
}

export const MenuAllChannelsItem: React.FC<MenuChannelProps> = observer((props) => {
  const {t} = useTranslation();
  const {modals, activeWorkspace} = useStore();

  const handleReadAllChats = () => {
    props.menu.close();

    modals.open(ModalType.CONFIRM, {
      headerText: t('chat_chats_tab_all_channels_mark_as_read_button_header'),
      title: t('chat_chats_tab_all_channels_mark_as_read_button_title'),
      submitButtonText: t('Submit'),
      submitButtonColor: 'primary',
      syncCallbacks: true,
      callbacks: {
        submit: () => {
          activeWorkspace.channels.readAllChannelsChats(activeWorkspace.channels.availableChannels);
        },
      },
    });
  };

  return (
    <div className="dropdown-menu">
      <button type="button" className="dropdown-item d-flex" onClick={handleReadAllChats}>
        {t('ctx_menu_channel_mark_all_chats_as_read')}
      </button>
    </div>
  );
});

export default MenuAllChannelsItem;
