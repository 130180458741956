import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import {ContextMenuItem} from '../stores/ContextMenuStore';
import MemberInvitationTime from '../pages/Settings/WorkspaceMembers/MembersTable/MemberInvitationTime';

interface Props {
  menu: ContextMenuItem;
}

export const MenuManageInvite: React.FC<Props> = observer((props) => {
  const {t} = useTranslation();

  const {invite} = props.menu.data;

  return (
    <div className="dropdown-menu">
      {invite?.email || invite?.boundToMember?.createdAt?.notEquals(0) ? null : (
        <div className="dropdown-item d-flex border-bottom" style={{pointerEvents: 'none'}}>
          <MemberInvitationTime className="lowercase operator-invitation-time" member={invite} />
        </div>
      )}

      {props.menu.data.callbacks?.copyInviteLink ? (
        <button type="button" className="dropdown-item d-flex" onClick={() => props.menu.trigger('copyInviteLink')}>
          {t('ctx_menu_team_member_copy_invite_link')}
        </button>
      ) : null}

      {props.menu.data.callbacks?.resendInvite ? (
        <button
          type="button"
          className="dropdown-item d-flex"
          onClick={() => props.menu.trigger('resendInvite')}
          disabled={!invite?.resendRegistrationEmailTimer.stopped}
        >
          {invite?.resendRegistrationEmailTimer.currentTime
            ? `(${invite.resendRegistrationEmailTimer.currentTime}) `
            : ''}
          {t('ctx_menu_team_member_resend_invite')}
        </button>
      ) : null}

      {props.menu.data.callbacks?.unblocked ? (
        <button type="button" className="dropdown-item d-flex" onClick={() => props.menu.trigger('unblocked')}>
          {t('ctx_menu_team_member_unblocked')}
        </button>
      ) : null}

      {props.menu.data.callbacks?.manageOperator ? (
        <button type="button" className="dropdown-item d-flex" onClick={() => props.menu.trigger('manageOperator')}>
          {t('ctx_menu_team_member_manage')}
        </button>
      ) : null}
    </div>
  );
});

export default MenuManageInvite;
