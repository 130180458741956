import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import Paths from '../../../routes/Paths';
import useWorkspaceNavigate from '../../../hooks/useWorkspaceNavigate';

import messageImg from '../../../assets/images/gray-message-illustration.png';


export const NoChannelsArea: React.FC<React.PropsWithChildren> = observer(() => {
  const {t} = useTranslation();
  const workspaceNavigate = useWorkspaceNavigate();

  const handleConnectClick = () => {
    workspaceNavigate(Paths.Channels);
  };

  return (
    <div className="blocked-chat-area border-primary-left d-flex flex-column justify-content-center align-items-center">
      <img src={messageImg} className="mb-6" alt="" />

      <div className="h4-bold">
        {t('chat_no_connected_channels_message')}
      </div>
      <div className="mt-3">
        {t('chat_no_connected_channels_info')}
      </div>

      <div className="mt-6">
        <Button
          variant="contained"
          size="large"
          onClick={handleConnectClick}
        >
          {t('chat_no_connected_channels_connect_button')}
        </Button>
      </div>
    </div>
  );
});

export default NoChannelsArea;
