import React from 'react';

export type PositionAlign = 'right-bottom' | 'left-bottom' | 'right-top' | 'left-top';

export const DEFAULT_POSITION_ALIGN: PositionAlign = 'right-bottom';
export const DEFAULT_MARGIN = 48;
export const BASIC_BUTTON_SIZE = 96;

export const BUTTON_OFFSET = 24;

export const DEFAULT_POSITION: React.CSSProperties = parsePositionByAlign({}, DEFAULT_POSITION_ALIGN);

export function getPositionAlign(props?: React.CSSProperties | null): PositionAlign {
  if (!props) {
    return DEFAULT_POSITION_ALIGN;
  }

  if (Object.prototype.hasOwnProperty.call(props, 'right') && Object.prototype.hasOwnProperty.call(props, 'bottom')) {
    return 'right-bottom';
  } else if (
    Object.prototype.hasOwnProperty.call(props, 'left') &&
    Object.prototype.hasOwnProperty.call(props, 'bottom')
  ) {
    return 'left-bottom';
  } else if (
    Object.prototype.hasOwnProperty.call(props, 'right') &&
    Object.prototype.hasOwnProperty.call(props, 'top')
  ) {
    return 'right-top';
  } else if (
    Object.prototype.hasOwnProperty.call(props, 'left') &&
    Object.prototype.hasOwnProperty.call(props, 'top')
  ) {
    return 'left-top';
  }

  return DEFAULT_POSITION_ALIGN;
}

function getValue(value?: string | number | null, defaultValue = DEFAULT_MARGIN): number {
  return typeof value === 'number' ? value : defaultValue;
}

export function parsePositionByAlign(
  props?: React.CSSProperties | null,
  align?: PositionAlign,
): React.CSSProperties {
  let newPosProps = {};
  const currentAlign = align || getPositionAlign(props);

  switch (currentAlign) {
    case 'right-bottom':
      newPosProps = {
        right: getValue(props?.right),
        bottom: getValue(props?.bottom),
      };
      break;
    case 'left-bottom':
      newPosProps = {
        left: getValue(props?.left),
        bottom: getValue(props?.bottom),
      };
      break;
    case 'right-top':
      newPosProps = {
        right: getValue(props?.right),
        top: getValue(props?.top),
      };
      break;
    case 'left-top':
      newPosProps = {
        left: getValue(props?.left),
        top: getValue(props?.top),
      };
      break;
  }

  return newPosProps;
}

export function getMaxXOffset(container?: HTMLElement | null): number {
  if (container) {
    return container.clientWidth;
  }

  return window.innerWidth || window.document.documentElement.clientWidth;
}

export function getMaxYOffset(container?: HTMLElement | null): number {
  if (container) {
    return container.clientHeight;
  }

  return window.innerHeight || window.document.documentElement.clientHeight;
}


interface IMaxOffset {x: number; y: number;}

export function getMaxOffset(el?: HTMLElement | null, container?: HTMLElement | null): IMaxOffset {
  const height = el?.clientHeight || 0;
  const width = el?.clientWidth || 0;

  const maxX = getMaxXOffset(container);
  const maxY = getMaxYOffset(container);

  return {
    x: maxX - width,
    y: maxY - height,
  };
}

function orMax(value?: number | null, max?: number | null): number | undefined {
  if (!value) {
    return undefined;
  }

  if (max && value > max) {
    return max;
  }

  return value;
}

function parsePositionProp(value?: string | number | null, max?: number | null): number | undefined {
  const val: number = value || value === 0 ? parseFloat(value.toString()) : NaN;
  if (isNaN(val)) {
    return undefined;
  }

  return orMax(val, max);
}


export interface PositionStyles {
  chatBoxStyle: React.CSSProperties;
  chatButtonBodyStyle: React.CSSProperties;
  chatButtonStyle: React.CSSProperties;
}

export function parsePositionStyles(
  _chatButtonSize?: string | null,
  _chatButtonPosition?: React.CSSProperties | null,
  maxOffset?: IMaxOffset | null,
): PositionStyles {

  const chatButtonSize = _chatButtonSize ? parseFloat(_chatButtonSize) : BASIC_BUTTON_SIZE;
  const chatButtonPosition = parsePositionByAlign(_chatButtonPosition);
  const chatButtonGap = chatButtonSize + BUTTON_OFFSET;

  const chatButtonTop = parsePositionProp(chatButtonPosition?.top, maxOffset?.y);
  const chatButtonLeft = parsePositionProp(chatButtonPosition?.left, maxOffset?.x);

  const chatButtonBottom = parsePositionProp(chatButtonPosition?.bottom, maxOffset?.y);
  const chatButtonRight = parsePositionProp(chatButtonPosition?.right, maxOffset?.x);

  const chatBoxTop =
    typeof chatButtonTop === 'number' ? orMax(chatButtonTop + chatButtonGap, maxOffset?.y) : undefined;
  const chatBoxBottom =
    typeof chatButtonBottom === 'number' ? orMax(chatButtonBottom + chatButtonGap, maxOffset?.y) : undefined;

  return {
    chatBoxStyle: {
      top: chatBoxTop,
      bottom: chatBoxBottom,
      right: chatButtonRight,
      left: chatButtonLeft,
    },
    chatButtonBodyStyle:
      !chatButtonSize || BASIC_BUTTON_SIZE === chatButtonSize
        ? {}
        : {
          transform: `scale(${chatButtonSize / BASIC_BUTTON_SIZE})`,
        },
    chatButtonStyle: {
      width: chatButtonSize,
      height: chatButtonSize,
      bottom: orMax(chatButtonBottom, chatBoxBottom ? chatBoxBottom - chatButtonGap : 0),
      right: chatButtonRight,
      top: orMax(chatButtonTop, chatBoxTop ? chatBoxTop - chatButtonGap : 0),
      left: chatButtonLeft,
    },
  };
}
