import {action, computed, makeObservable, observable} from 'mobx';

import {api, entities} from '../../api/proto';
import {t} from '../../i18n';
import {equalUint8Arrays} from '../../utils/arrayUtils';
import Invite from './Invite';
import InviteBase from './InviteBase';
import WorkspaceStore from './WorkspaceStore';

export default class InvitesStore {
  constructor(private workspace: WorkspaceStore) {
    makeObservable(this);
  }

  @observable _invites: Invite[] = [];

  @computed get emailInvites() {
    return this._invites.filter((invite) => !!invite.boundToEmail);
  }

  @computed get inviteLinks() {
    return this._invites.filter((invite) => !invite.boundToEmail);
  }

  @computed get inviteLinksActive() {
    return this.inviteLinks.filter((invite) => invite.active);
  }

  @computed get inviteLinksActivated() {
    return this.inviteLinks.filter((invite) => invite.activated);
  }

  @computed get inviteLinksExpired() {
    return this.inviteLinks.filter((invite) => invite.expired);
  }

  @observable public registrationInvite: InviteBase | null = null;

  @action protected setRegistrationInvite_ = (invite: InviteBase | null) => {
    this.registrationInvite = invite;
  };

  public clearRegistrationInvite = () => {
    this.setRegistrationInvite_(null);
  };

  public load = async () => {
    const {error, res} = await this.workspace.workspaceRequest<api.IWorkspaceInvitesResponse>(
      {
        invitesRequest: new api.WorkspaceInvitesRequest({
          fetch: true,
        }),
      },
      'invitesRequest',
    );

    if (res) {
      this.processInvites_(res.invites);
    }

    return {error, res};
  };

  @action private processInvites_ = (invites?: entities.IInvite[] | null) => {
    this._invites = invites?.map((invite) => new Invite(invite, this.workspace)) ?? [];
  };

  @action public reset = () => {
    this._invites = [];
    this.registrationInvite = null;
  };

  public invite = async (invite: api.IWorkspaceCreateInviteRequest) => {
    const {error, res} = await this.workspace.workspaceRequest<api.IWorkspaceCreateInviteResponse>(
      {
        createInvite: invite,
      },
      'createInvite',
    );

    if (res?.invite) {
      this.workspace.app.anal.inviteEvent(res?.invite);
      this.processInvite_(res?.invite);
    }

    return {error, res: res};
  };

  private processInvite_ = (invite?: entities.IInvite | null) => {
    if (invite) {
      this.load();
    }
  };

  public getInvite = async (id?: Uint8Array | null): Promise<api.IWorkspaceInviteResponseNew | null> => {
    if (!id) {
      return null;
    }

    const {res} = await this.workspace.request<api.IWorkspaceResponse>(
      {
        workspaceRequest: {
          inviteRequest: {
            id,
          },
        },
      },
      'workspaceResponse',
    );

    return res?.inviteRequest || null;
  };

  public acceptInvite = async (invite?: InviteBase | null) => {
    const {error, res} = await this.workspace.request<api.IWorkspaceResponse>(
      {
        workspaceRequest: {
          inviteAccept: {
            id: invite?.id,
          },
        },
      },
      'workspaceResponse',
    );

    if (res?.inviteAccept) {
      this.processAcceptInvite_(res?.inviteAccept, invite);
    }

    return {res, error};
  };

  private processAcceptInvite_ = async (res: api.IWorkspaceAcceptInviteResponse, invite?: InviteBase | null) => {
    await this.workspace.app.workspaces.load();

    if (typeof res.status === 'number') {
      const notifications = this.workspace.app.notifications;

      switch (res.status) {
        case api.WorkspaceAcceptInviteResponse.Status.AINV_INVITE_EXPIRED:
          notifications.warning(
            t('notification_invite_expired_title'),
            t('notification_invite_expired_subtitle')
          );
          break;
        case api.WorkspaceAcceptInviteResponse.Status.AINV_ALREADY_MEMBER:
          notifications.error(
            t('notification_invite_already_member_title'),
            t('notification_invite_already_member_subtitle'),
          );
          break;
        case api.WorkspaceAcceptInviteResponse.Status.AINV_INVITE_USED:
          notifications.error(
            t('notification_invite_used_title'),
            t('notification_invite_used_subtitle')
          );
          break;
        case api.WorkspaceAcceptInviteResponse.Status.AINV_UPGRADE_REQUIRED:
          notifications.error(
            t('notification_invite_upgrade_require_title'),
            t('notification_invite_upgrade_require_subtitle')
          );
          break;
        case api.WorkspaceAcceptInviteResponse.Status.AINV_OK:
          this.workspace.app.workspaces.selectWorkspace(invite?.workspaceId);
          notifications.success(
            t('notification_invite_accept_success_title', {name: invite?.displayName}),
            t('notification_invite_accept_success_subtitle'),
          );
          break;
        default:
          notifications.error('Accept invite internal error');
      }
    }

    this.clearRegistrationInvite();
  };

  public loadRegistrationInvite = async (inviteID?: Uint8Array | null) => {
    if (equalUint8Arrays(inviteID, this.registrationInvite?.id)) {
      return;
    }

    const res = await this.getInvite(inviteID);

    if (res?.invite) {
      this.setRegistrationInvite_(
        new InviteBase(
          {
            ...res.invite,
            registrationRequired: res.registrationRequired,
          },
          res.workspace,
          this.workspace.app,
        ),
      );
    }
  };
}
