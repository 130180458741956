import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import copy from 'copy-to-clipboard';

import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import Button, {IconButton} from 'o-ui/Button';
import FormControl from 'o-ui/FormControl';
import {entities} from '../../../../api/proto';
import {useStore} from '../../../../stores/AppStore';
import {getInviteLinkRoleTitle} from '../../../../stores/Workspaces/InviteLink';
import Invite from '../../../../stores/Workspaces/Invite';
import InviteLinkStatus from './InviteLinkStatus';


interface IProps {
  inviteLink: Invite;
  onDelete?: (inviteLink: Invite) => void;
}

export const InviteLinksListItem: React.FC<IProps> = observer(({inviteLink}) => {
  const {t} = useTranslation();
  const {notifications, activeWorkspace} = useStore();

  const handleCopyLink = () => {
    copy(inviteLink.inviteUrl);
    notifications.action(t('settings_workspace_members_invite_link_copy_notification'));
  };
  
  const handleDelete = inviteLink.activated ? undefined : async () => {
    const {res, error} = await inviteLink.delete();
    if (error) {
      notifications.error(error.message);
    } else if (res) {
      activeWorkspace.invites.load();
      notifications.success(t('settings_workspace_members_invite_link_deleted_notification'));
    }
  };

  const getInviteLinkStringType = (): string => {
    switch (inviteLink.role) {
      case entities.WorkspaceRole.WR_ADMIN:
        return 'admin';
      case entities.WorkspaceRole.WR_OWNER:
        return 'owner';
    }
    return 'operator';
  };

  return (
    <div className="invite-links-list__item">
      <div className="invite-links-list__item--left">
        <FormControl className=" w-100 position-relative" variant="outlined">
          <OutlinedTextInput value={inviteLink.inviteUrl} disabled={!inviteLink.isAvailable} />
          {inviteLink.isAvailable ? (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className="my-3 input-adornment"
              onClick={handleCopyLink}
            >
              {t('settings_workspace_members_invite_link_copy_button')}
            </Button>
          ) : null}
        </FormControl>
      </div>
      <div className="invite-links-list__item--right">
        <div className="invite-links-list__link-type">
          <span className={`uppercase system-user-role-label system-user-role-label--${getInviteLinkStringType()}`}>
            {getInviteLinkRoleTitle(inviteLink.role)}
          </span>
        </div>
        <InviteLinkStatus className="mx-1" inviteLink={inviteLink} />
        {handleDelete ? (
          <IconButton
            className="tg-icon-cross-line icon-size-12 ml-3"
            onClick={handleDelete}
            title={t('settings_workspace_members_invite_link_delete_button')}
            size="extraSmall"
          />
        ) : null}
      </div>
    </div>
  );
});

export default InviteLinksListItem;
