import React from 'react';

import {ListChildComponentProps} from 'o-ui/VirtualizedList';
import {useStore} from '../../../../stores/AppStore';
import useContextMenuHandler from '../../../../hooks/useContextMenuHandler';
import ContextMenuType from '../../../../stores/ContextMenuType';
import AudioFile from '../../../../components/Files/AudioFile';
import ChatInfoDateDelimiter, {useDiffMonth} from '../ChatInfo/ChatInfoDateDelimiter';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';

interface AudioFilesRowProps {
  rowProps: ListChildComponentProps;
  items: MessageAttachment[];
  setSize: (index: number, size: number) => void;
}

export const AudioFilesRow = React.memo(({rowProps, items, setSize}: AudioFilesRowProps) => {
  const {contextMenu} = useStore();

  const attachment = items[rowProps.index];
  const prevAttachment = rowProps.index ? items[rowProps.index - 1] : null;

  const diffMonth = useDiffMonth(attachment.message.stamp, prevAttachment?.message?.stamp);

  React.useEffect(() => {
    if (diffMonth) {
      setSize(rowProps.index, 88);
    } else {
      setSize(rowProps.index, 48);
    }
  }, [setSize, rowProps.index, diffMonth]);

  const contextMenuHandlers = useContextMenuHandler((e: React.MouseEvent, attachment: MessageAttachment) => {
    e.stopPropagation();
    e.preventDefault();

    contextMenu.open({clientX: e.clientX, clientY: e.clientY}, ContextMenuType.FILE, {
      attachment,
    });
  });

  return (
    <div style={rowProps.style} key={rowProps.index}>
      {diffMonth ? <ChatInfoDateDelimiter stamp={attachment.message.stamp} /> : null}

      <div
        className="card py-1 px-5 m-0"
        onContextMenu={(e) => contextMenuHandlers.onContextMenu(e, attachment)}
        onTouchCancel={contextMenuHandlers.onTouchCancel}
        onTouchEnd={contextMenuHandlers.onTouchEnd}
        onTouchMove={contextMenuHandlers.onTouchMove}
        onTouchStart={(e) => contextMenuHandlers.onTouchStart(e, attachment)}
      >
        {attachment.isAudio || attachment.isVoiceNote ? <AudioFile attachment={attachment} /> : null}
      </div>
    </div>
  );
});

export default AudioFilesRow;
