import cn from 'classnames';
import Long from 'long';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from 'o-ui/Button/IconButton';
import {IMCTextEntities, MCTextEntities} from '../../../api/proto';
import {Message, MessageClickEventHandler} from '../../../stores/Message';
import {ITextSelection} from '../../../stores/TextSelection';
import MessageThumbnail from './MessageThumbnail';
import TextContentTree from './TextContent/TextContentTree';
import useMessageTypeTitle from './useMessageTypeTitle';

import {ReactComponent as CrossIcon} from '../../../assets/icons/cross-line.svg';

interface IProps {
  className?: string | null;
  message: Message;
  icon?: string;
  infoTitle?: string | null;
  onClick?: MessageClickEventHandler;
  onClose?: MessageClickEventHandler;
  textSelection?: ITextSelection | null;
}

export const ReplyBar: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const title = useMessageTypeTitle(props.message);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.onClick?.(props.message);
  };

  const handleCloseClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.onClose?.(props.message);
  };

  const textEntities: IMCTextEntities[] = props.textSelection
    ? [
      {
        offset: Long.fromNumber(props.textSelection.offset),
        length: Long.fromNumber(props.textSelection?.length),
        type: MCTextEntities.Type.BOLD,
      },
    ]
    : [];

  return (
    <div className={cn('reply-bar', props.className)}>
      <i className={`reply-bar__icon tg-icon-${props.icon}`} />
      <div className="reply-bar__body" onClick={handleClick}>
        <MessageThumbnail className="reply-bar__media-preview" message={props.message} />
        <div className="reply-bar__body-right">
          <h5 className="reply-bar__title text-truncate">{t('reply_to_sender', {name: props.message.senderName})}</h5>
          {props.infoTitle ? (
            <p className="reply-bar__message-info m-0 text-truncate">{props.infoTitle}</p>
          ) : (
            <p className="reply-bar__message-info m-0 text-truncate">
              {title ? <span className="reply-bar__message-type">{title}</span> : null}
              <span className="reply-bar__message-text text-truncate">
                <TextContentTree
                  text={props.message.text || ''}
                  // textEntities={props.message.textEntities || []}
                  textEntities={textEntities}
                  startIndex={0}
                  level={0}
                />
              </span>
            </p>
          )}
        </div>
      </div>
      <IconButton onClick={handleCloseClick}>
        <CrossIcon className="icon-size-14" />
      </IconButton>
    </div>
  );
});

export default ReplyBar;
