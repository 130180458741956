import React from 'react';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';

import Button from 'o-ui/Button';
import Link from 'o-ui/Link';
import Select from 'o-ui/Select';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';

import {entities} from '../../../../api/proto';
import {getUserRoles} from '../../../../stores/Workspaces';
import {InvitationByEmailFormikProps} from '../../WorkspaceMembersTab';
import {useStore} from '../../../../stores/AppStore';

interface Props {
  onOpenInvitationAccess(): void;
  formik: InvitationByEmailFormikProps;
  submitting?: boolean;
}

export const InvitationByEmailForm: React.FC<Props> = observer((props) => {
  const {t} = useTranslation();
  const {userStore} = useStore();
  const rolesList = getUserRoles();

  const {formik, onOpenInvitationAccess} = props;

  const handleOpenConfigDlg = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onOpenInvitationAccess();
  };

  return (
    <form className="operator-invitation__form" onSubmit={formik.handleSubmit}>
      <div className="operator-invitation__row d-flex flex-column flex-sm-row">
        <div className="d-flex flex-grow-1">
          <OutlinedTextInput
            className="flex-grow-1 mb-3 mr-0 mr-sm-3"
            name="email"
            placeholder={t('settings_workspace_members_new_member_email_placeholder')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={!!formik.errors.email}
            errorHelper={formik.errors.email || ''}
            required
          />
        </div>
        <div className="d-flex">
          <Select
            name="role"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.role}
            items={rolesList}
            error={!!formik.errors.role}
            required
            disabled={!userStore.isOwner}
          />
        </div>
      </div>

      <div className="operator-invitation__row pt-3">
        <Button
          className="operator-invitation__button"
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<i className="tg-icon tg-icon-add-line font-size-12" />}
          disabled
        >
          {t('settings_workspace_members_add_another_email_button')}
        </Button>
      </div>

      <div className="operator-invitation__row pt-5">
        <Link
          className={cn('operator-invitation__button', {
            invisible: formik.values.role !== entities.WorkspaceRole.WR_OPERATOR,
          })}
          component="button"
          color="primary"
          onClick={handleOpenConfigDlg}
        >
          {t('settings_workspace_members_invitation_settings_button')}
        </Link>
      </div>

      <div className="body2-regular color-body-secondary mt-5">{t('settings_workspace_members_invite_link_expire_info')}</div>

      <div className="operator-invitation__footer pt-5">
        <Button
          className="operator-invitation__button"
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          loading={props.submitting}
        >
          {t('settings_workspace_members_invite_button')}
        </Button>
      </div>
    </form>
  );
});

export default InvitationByEmailForm;
