import React from 'react';
import {observer} from 'mobx-react';

import {Chat} from '../../../../stores/Chat';
import i18n from '../../../../i18n';
import {ChatInfoType} from '../../../../stores/LayOutStore';
import MediaGallery, {MediaGalleryType} from '../MediaGallery';
import AttachedFiles from '../AttachedFiles';
import AudioFiles from '../AudioFiles';

interface ChatInfoGalleryProps {
  className?: string;
  chat: Chat;
  infoType?: ChatInfoType;
}

export const getChatInfoGalleryTitle = (type?: ChatInfoType): string => {
  switch (type) {
    case ChatInfoType.MEMBERS:
      return i18n.t('chat_user_chat_info_operators_title');
    case ChatInfoType.IMAGES:
      return i18n.t('chat_user_chat_info_images_title');
    case ChatInfoType.VIDEOS:
      return i18n.t('chat_user_chat_info_videos_title');
    case ChatInfoType.FILES:
      return i18n.t('chat_user_chat_info_files_title');
    case ChatInfoType.AUDIOS:
      return i18n.t('chat_user_chat_info_audios_title');
    case ChatInfoType.VOICES:
      return i18n.t('chat_user_chat_info_voice_messages_title');
    case ChatInfoType.LINKS:
      return i18n.t('chat_user_chat_info_links_title');
    case ChatInfoType.GIFS:
      return i18n.t('chat_user_chat_info_gifs_title');
    case ChatInfoType.OPERATORS:
      return i18n.t('chat_user_chat_info_chat_access_for_users_title');
  }

  return '';
};

export const ChatInfoGallery: React.FC<ChatInfoGalleryProps> = observer((props) => {
  switch (props.infoType) {
    // case ChatInfoType.MEMBERS:
    //   return (
    //     <SimpleBar className="tab-simplebar h-100">
    //       <UsersListHead chat={props.chat} />
    //       <UsersList chat={props.chat} />
    //     </SimpleBar>
    //   );
    case ChatInfoType.IMAGES:
      return (
        <MediaGallery
          chat={props.chat}
          type={MediaGalleryType.IMAGES}
          attachments={props.chat.store?.imagesHistory?.attachments}
        />
      );
    case ChatInfoType.VIDEOS:
      return (
        <MediaGallery
          chat={props.chat}
          type={MediaGalleryType.VIDEOS}
          attachments={props.chat.store?.videosHistory?.attachments}
        />
      );
    case ChatInfoType.FILES:
      return <AttachedFiles chat={props.chat} attachments={props.chat.store?.filesHistory?.attachments} />;
    case ChatInfoType.AUDIOS:
      return (
        <AudioFiles
          chat={props.chat}
          type={ChatInfoType.AUDIOS}
          attachments={props.chat.store?.audiosHistory?.attachments}
        />
      );
    case ChatInfoType.VOICES:
      return (
        <AudioFiles
          chat={props.chat}
          type={ChatInfoType.VOICES}
          attachments={props.chat.store?.voicesHistory?.attachments}
        />
      );
    case ChatInfoType.GIFS:
      return (
        <MediaGallery
          chat={props.chat}
          type={MediaGalleryType.GIFS}
          attachments={props.chat.store?.gifsHistory?.attachments}
        />
      );
    case ChatInfoType.STICKERS:
      return (
        <MediaGallery
          chat={props.chat}
          type={MediaGalleryType.STICKERS}
          attachments={props.chat.store?.stickersHistory?.attachments}
        />
      );
    // case ChatInfoType.LINKS:
    //   return <SharedLinks chat={props.chat} sharedLinks={props.chat.store?.linksHistory?.messages} />;

    // case ChatInfoType.OPERATORS:
    //   return <OperatorsList chat={props.chat} />;
    default: null;
  }

  return null;
});

export default ChatInfoGallery;
