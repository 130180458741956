import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import {entities} from '../../../api/proto';
import useMobileByWidth from '../../../hooks/useMobileByWidth';
import useStore from '../../../stores/useStore';
import TariffSelectItem from './TariffSelectItem';
import TariffSelectItemActive from './TariffSelectItemActive';
import useHighlightedTariff from './useHighlightedTariff';

interface TariffSelectProps {
  className?: string;
  itemClassName?: string;
  style?: React.CSSProperties;
  onCancelClick?: (tariff: entities.ITariff) => void;
}

export const TariffSelect: React.FC<TariffSelectProps> = observer(({
  className,
  itemClassName,
  style,
  onCancelClick,
}) => {
  const {activeWorkspace: {billing, membersCount}} = useStore();
  const {ref: innerRef, isMobile} = useMobileByWidth<HTMLDivElement>();

  const highlightTariff = useHighlightedTariff();

  const recomendedTariff = billing.availableTariffs.find((tariff: entities.ITariff) => {
    return (tariff.includedUsers?.toNumber() || 0) >= membersCount && tariff.price;
  });

  const count = isMobile ? Math.round(billing.availableTariffs.length / 2) : billing.availableTariffs.length;
  const itemWidth = `calc((100% - ${count - 1} * var(--indent-size-5)) / ${count})`;

  return count ? (
    <div
      ref={innerRef}
      className={cn(
        'd-flex flex-row justify-content-between flex-wrap',
        className,
      )}
      style={style}
    >
      {billing.availableTariffs.map((tariff, i) => {
        return billing.currentTariff?.id === tariff.id ? (
          <TariffSelectItemActive
            key={tariff.id?.toString()}
            className={cn(
              'flex-grow-1',
              itemClassName,
            )}
            style={{width: itemWidth, minWidth: itemWidth, maxWidth: itemWidth}}
            tariff={tariff}
            trial={billing.trialEnabled}
            onCancelClick={onCancelClick}
          />
        ) : (
          <TariffSelectItem
            key={tariff.id?.toString()}
            className={cn(
              'flex-grow-1',
              itemClassName,
            )}
            style={{width: itemWidth, minWidth: itemWidth, maxWidth: itemWidth}}
            tariff={tariff}
            highlight={tariff.id === highlightTariff?.id}
            recomended={recomendedTariff?.id === tariff.id}
            maxTariff={count === i + 1}
          />
        );
      })}
    </div>
  ) : null;
});

export default TariffSelect;