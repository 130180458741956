import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import Div100vh from '../../components/Div100vh';
import ContextMenu from '../../contextMenu/ContextMenu';
import useStore from '../../stores/useStore';
import useSettingsPageRoute from './useSettingsPageRoute';


const SettingsPageWrapper = observer(({children}: React.PropsWithChildren) => {
  const {layOutStore} = useStore();

  useSettingsPageRoute();

  return (
    <>
      <Div100vh
        className={cn('settings-page user-chat user-chat--no-transition', {
          'user-chat-show': layOutStore.showSettingsTabContent,
        })}
      >
        {children}
      </Div100vh>

      <ContextMenu />
    </>
  );
});

export default SettingsPageWrapper;
