import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import PreviewImage from '../../../../components/PreviewImage';
import PreviewVideo from '../../../../components/PreviewVideo';
import useMaxMediaSize from '../../../../hooks/useMaxMediaSize';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import ModalType from '../../../../stores/ModalType';
import useStore from '../../../../stores/useStore';
import {isImageFile} from '../../../../utils/file/fileReaders';
import useMessageAttachment from './useMessageAttachment';

export interface AnimationContentProps {
  className?: string;
  attachment: MessageAttachment;
  onContextMenu?: React.MouseEventHandler;
  fixedSize?: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  isScrolling?: boolean;
  withoutAnimationInfoSizes?: boolean;
}

export const AnimationContent: React.FC<AnimationContentProps> = observer((props) => {
  const {modals} = useStore();

  const attachment = props.attachment;
  const message = props.attachment.message;

  const [inView, setInView] = React.useState<boolean>(false);

  const {src, width, height, mimeType} = useMessageAttachment(attachment, !!props.autoPlay && inView);
  const {maxWidth, maxHeight} = useMaxMediaSize();

  const openLightbox = () => {
    modals.open(ModalType.MEDIA_LIGHT_BOX, {message, attachment: attachment});
  };

  const isImage = isImageFile(props.attachment.mimeType, props.attachment.name);

  if (!isImage) {
    return (
      <PreviewVideo
        className={cn(
          'animation-content',
          'message-video__animation',
          'message-video__animation--video',
          'cursor-pointer',
          props.className,
        )}
        message={message}
        attachment={attachment}
        onClick={openLightbox}
        onInViewChange={setInView}
        src={src}
        width={!props.withoutAnimationInfoSizes ? width : undefined}
        height={!props.withoutAnimationInfoSizes ? height : undefined}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        autoPlayOnActive={true}
        loop={true}
        muted={true}
        isScrolling={props.isScrolling}
      />
    );
  }

  return (
    <PreviewImage
      className={cn(
        'animation-content',
        'message-video__animation',
        'message-video__animation--image',
        'cursor-pointer',
        props.className,
      )}
      src={src}
      mimeType={mimeType}
      fileName={attachment.name}
      fixedSize={props.fixedSize}
      width={!props.withoutAnimationInfoSizes ? width : undefined}
      height={!props.withoutAnimationInfoSizes ? height : undefined}
      onClick={openLightbox}
      onInViewChange={setInView}
      onContextMenu={props.onContextMenu}
      isScrolling={props.isScrolling}
    />
  );
});

AnimationContent.displayName = 'AnimationContent';

export default AnimationContent;
