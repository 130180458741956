import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import {IMCMessageSuggestionOption} from '../../../../api/proto';
import useCompactMode from '../../../../hooks/useCompactMode';
import Chat from '../../../../stores/Chat';
import {uint8ArrayToUuid} from '../../../../utils/arrayUtils';
import MessageSuggestionsBarItem from './MessageSuggestionsBarItem';

interface MessageSuggestionsBarProps {
  className?: string;
  chat: Chat;
  onClick?(suggestion: IMCMessageSuggestionOption): void;
  disabled?: boolean;
}

const MessageSuggestionsBar = observer(({
  className,
  chat,
  onClick,
  disabled,
}: MessageSuggestionsBarProps) => {
  const [suggestions, setSuggestion] = React.useState<IMCMessageSuggestionOption[]>(chat.nextOperatorMessageSuggestion?.options || []);
  const [appliedSuggestions, setAppliedSuggestions] = React.useState<string[]>([]);
  const compactMode = useCompactMode();

  React.useEffect(() => {
    setSuggestion(chat.nextOperatorMessageSuggestion?.options || []);
  }, [chat.nextOperatorMessageSuggestion?.options]);

  const handleClick = (suggestion: IMCMessageSuggestionOption) => {
    onClick?.(suggestion);
    setAppliedSuggestions([...appliedSuggestions, uint8ArrayToUuid(chat.nextOperatorMessageSuggestion?.id)]);
  };

  const filteredSuggestions = appliedSuggestions.indexOf(uint8ArrayToUuid(chat.nextOperatorMessageSuggestion?.id)) ? suggestions : [];

  if (!filteredSuggestions.length) {
    return null;
  }

  return (
    <div className={cn(
      'message-suggestions-bar',
      className,
      {
        'message-suggestions-bar--compact-mode': compactMode,
        disabled,
      },
    )}>
      <div className={cn('message-suggestions-bar__list')}>
        {filteredSuggestions.map((suggestion, i) => (
          <MessageSuggestionsBarItem
            key={i}
            suggestion={suggestion}
            onClick={handleClick}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
});

export default MessageSuggestionsBar;
