import React from 'react';
import cn from 'classnames';
import {IconButton} from 'o-ui/Button';

interface ProfileSidebarHeadProps {
  className?: string;
  children?: React.ReactNode;
  title?: string;
  onBack?: () => void;
  onClose?: () => void;
}

export const ProfileSidebarHead = (props: ProfileSidebarHeadProps) => {
  return (
    <div className={cn('profile-sidebar__head', props.className)}>
      <div>
        {props.onBack ? (
          <IconButton
            className="profile-sidebar__head-back btn-icon tg-icon-chevron-left-line"
            onClick={props.onBack}
            size="small"
          />
        ) : null}

        <span className="profile-sidebar__head-title">{props.title}</span>
      </div>

      <div>
        {props.children}
        {props.onClose ? (
          <IconButton
            className="profile-sidebar__head-close btn-icon tg-icon-cross-line ml-auto"
            onClick={props.onClose}
            size="small"
          />
        ) : null}
      </div>
    </div>
  );
};

interface ProfileSidebarProps {
  className?: string;
  children?: React.ReactNode;
  title?: string;
  onBack?: () => void;
  onClose?: () => void;
  headChildren?: React.ReactNode;
}

export const ProfileSidebar = (props: ProfileSidebarProps) => {
  return (
    <div className={cn('profile-sidebar', props.className)}>
      <ProfileSidebarHead
        title={props.title}
        onBack={props.onBack}
        onClose={props.onClose}
      >
        {props.headChildren}
      </ProfileSidebarHead>

      <div className="profile-sidebar__body custom-scroll d-flex flex-column">{props.children}</div>
    </div>
  );
};

export default ProfileSidebar;
