import Long from 'long';
import {uint8ArrayToBase64} from '../../../utils/arrayUtils';
import {IRawChat} from '../../RawChatsStore/RawChat';

export default function getUnreadCount(rawChat: IRawChat): number {
  const lastMessageLocalID = rawChat.lastMessage?.localID?.toSigned();
  const inReadPointer = rawChat.inReadPointer?.toSigned() || Long.ZERO;
  const messagesExcludedFromUnreadRange = rawChat.messagesExcludedFromUnreadRange?.toSigned() || Long.ZERO;

  if (lastMessageLocalID?.lessThanOrEqual(inReadPointer)) {
    return 0;
  }

  const unreadCount = lastMessageLocalID?.subtract(inReadPointer)?.subtract(messagesExcludedFromUnreadRange).toNumber() || 0;

  if (unreadCount < 0 || unreadCount > 1000000000) {
    console.error(
      `wrong unreadCount: ${lastMessageLocalID?.toNumber()} - ${inReadPointer?.toNumber()} - ${messagesExcludedFromUnreadRange?.toNumber()} = ${unreadCount}
      lastMessageLocalID=${lastMessageLocalID?.toNumber()} inReadPointer=${inReadPointer.toNumber()} messagesExcludedFromUnreadRange=${messagesExcludedFromUnreadRange.toNumber()}
      chatId=${rawChat.id?.toString()} channelID=${uint8ArrayToBase64(rawChat.channelID)}`
    );
  }

  return unreadCount;
}