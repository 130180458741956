import React from 'react';

import ChatsTabHead from './ChatsTabHead';
import ChatsTabActions from './ChatsTabActions';
import ChatsTabContainer from './ChatsTabContainer';
import ChatsTabContent from './ChatsTabContent';

export const ChatsTab = () => {
  return (
    <ChatsTabContainer>
      <ChatsTabHead />

      <ChatsTabContent />

      <ChatsTabActions />
    </ChatsTabContainer>
  );
};

export default ChatsTab;
