import {TelegramChannelState, entities} from '../../../api/proto';
import Channel from '../Channel';
import {getTgChannelStateTitle} from '../platforms/Telegram';
import {getWebWidgetChannelStateTitle} from '../platforms/WebWidget';

export const getChannelStateTitle = ({state, type}: Channel) => {
  switch (type) {
    case entities.OzekonChannelType.OIT_TELEGRAM:
      return getTgChannelStateTitle(state?.telegram?.state);
    case entities.OzekonChannelType.OIT_WEB_WIDGET:
      return getWebWidgetChannelStateTitle(state?.webWidget?.state);
    case entities.OzekonChannelType.OIT_NONE:
      return null;

    default:
      return null;
  }
};

export const filterAvailableChannelByType = ({state, type}: Channel): boolean => {
  if (type === entities.OzekonChannelType.OIT_TELEGRAM) {
    return state?.telegram?.state === TelegramChannelState.State.EVENT_LISTENING;
  }

  return true;
};
