import React from 'react';
import cn from 'classnames';

import Image from 'o-ui/Image';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import useMessageAttachment from '../MessageContent/useMessageAttachment';
import calcImageCoverSize from '../../../../utils/image/calcImageCoverSize';
import {EMPTY_IMG} from '../../../../constants';

export interface ImageThumbnailProps {
  className?: string;
  attachment: MessageAttachment;
}

export const ImageThumbnail: React.FC<ImageThumbnailProps> = React.memo((props) => {
  const {src, width, height} = useMessageAttachment(props.attachment, true);

  const [calcWidth, calcHeight] = calcImageCoverSize(
    width || 0,
    height || 0,
    32,
    32,
    false,
  );

  return (
    <div
      className={cn('image-thumbnail', props.className)}
      data-attachment-uuid={props.attachment.uuid}
    >
      <div className={cn('image-thumbnail__img-bg')} style={{backgroundImage: `url(${src})`}} />
      <Image
        className={cn(
          'image-thumbnail__img',
          `attachment-uuid-${props.attachment.uuid}`,
        )}
        style={{width: calcWidth, height: calcHeight}}
        src={src || EMPTY_IMG}
        useCache={false}
      />
    </div>
  );
});

export default ImageThumbnail;
