import axios, {AxiosError, AxiosProgressEvent, GenericAbortSignal} from 'axios';

import {NetworkErrorResponse, NetworkResponse} from './network';
import {emptyUnit8Array, uint8ArrayToUuid, uuidToUint8Array} from '../utils/arrayUtils';
import {wait} from '../utils/wait';
import {FileData} from '../utils/file/fileReaders';
import {t} from '../i18n';


const DOWNLOAD_ATTEMPTS_NUMBER = 10;
const DOWNLOAD_ATTEMPTS_DELAY = 3000;

const UPLOAD_ATTEMPTS_NUMBER = 30;
const UPLOAD_ATTEMPTS_DELAY = 2000;


interface IDownloadParams {
  attachmentID: Uint8Array;
  fileName?: string | null;
  attempt?: number;
  onDownloadProgress?(progressEvent: AxiosProgressEvent): void;
}

interface IUploadParams {
  dataFile: FileData;
  onUploadProgress?(progressEvent: AxiosProgressEvent): void;
  signal?: GenericAbortSignal;
}

interface IFileUploader {
  upload(params: IUploadParams): Promise<NetworkResponse<{attachmentID?: Uint8Array}>>;
  download(params: IDownloadParams): Promise<NetworkResponse<{content?: Uint8Array}>>;
}

class FileUploader implements IFileUploader {
  private getFileServerURL_(valiant: 'upload' | 'download', params: Record<string, string> = {}): string {
    const searchParams = new URLSearchParams(params);
    const searchParamsStr = searchParams && valiant === 'download' ? searchParams.toString() : '';

    const url = new URL(`/byteserver/${valiant}`, window.location.origin);
    if (searchParamsStr) {
      url.search = searchParamsStr;
    }

    return url.toString();
  }

  protected upload_ = async ({
    dataFile,
    signal,
    onUploadProgress,
  }: IUploadParams) => {
    const formData = new FormData();
    formData.append('file', dataFile.file, dataFile.fileName);

    return await axios.post<string>(this.getFileServerURL_('upload'), formData, {
      headers: {
        'Content-Disposition': `filename="${encodeURI(dataFile.fileName)}"`,
      },
      signal: signal,
      onUploadProgress: onUploadProgress,
    });
  };

  async upload(
    props: IUploadParams,
    retry: number = UPLOAD_ATTEMPTS_NUMBER,
  ): Promise<NetworkResponse<{attachmentID?: Uint8Array}>> {
    if (!props.dataFile.attachment) {
      return new NetworkErrorResponse(t('file_uploader_no_attachment'));
    }

    try {
      const response = await this.upload_(props);

      if (retry > 0 && !(response.status === 200 || response.status === 404)) {
        await wait(UPLOAD_ATTEMPTS_DELAY);
        return await this.upload(props, retry - 1);
      }

      const attachmentID = uuidToUint8Array(response.data) || undefined;

      return {
        error: null,
        res: {
          attachmentID: attachmentID,
        },
      };
    } catch (error: any) {
      console.error(error);
      if (retry > 0) {
        await wait(UPLOAD_ATTEMPTS_DELAY);
        return await this.upload(props, retry - 1);
      }

      return new NetworkErrorResponse(
        error?.message ?
          t('file_uploader_upload_certain_error', {error: error?.message})
          : t('file_uploader_upload_error')
      );
    }
  }

  async download({
    attachmentID,
    fileName,
    attempt = 1,
    onDownloadProgress,
  }: IDownloadParams): Promise<NetworkResponse<{content?: Uint8Array}>> {
    let status = 200;

    const fileName_ = fileName || 'unnamed';

    if (emptyUnit8Array(attachmentID)) {
      console.debug(`%c----->FilesUploader->download file [${fileName_}] has no ID: ${attachmentID}`, 'color: red;');
      return new NetworkErrorResponse(t('file_uploader_attachment_has_no_id', {fileName: fileName_}));
    }

    try {
      const {data} = await axios.get<ArrayBuffer>(
        this.getFileServerURL_('download', {id: uint8ArrayToUuid(attachmentID)}),
        {
          responseType: 'arraybuffer',
          onDownloadProgress,
        },
      );

      return {
        error: null,
        res: {
          content: new Uint8Array(data),
        },
      };
    } catch (error: unknown) {
      const error_ = error as AxiosError;
      status = error_.request.status;

      console.error(error);
    }

    switch (status) {
      case 404:
        if (attempt < DOWNLOAD_ATTEMPTS_NUMBER) {
          await wait(DOWNLOAD_ATTEMPTS_DELAY);

          return this.download({attachmentID, attempt: attempt + 1, onDownloadProgress});
        }
        return new NetworkErrorResponse(t('file_uploader_attachment_not_found'));
      case 500:
      default:
        return new NetworkErrorResponse(t('file_uploader_internal_server_error'));
    }
  }
}

export const fileUploader = new FileUploader();
