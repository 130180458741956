import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';

import Message from '../../../../stores/Message';
import {formatTime} from '../../../../utils/format';

interface SpecialMessageMetaItemsProps {
  message: Message;
}

export default observer(function SpecialMessageMetaItems({message}: SpecialMessageMetaItemsProps) {
  const {t} = useTranslation();
  return (
    <>
      {message.edited ? (
        <span className="message-status__edited">{t('chat_user_chat_message_edited_status')}</span>
      ) : null}

      <span className="message-status__time ml-2 align-middle">{formatTime(message.stamp)}</span>

      {message.deleted || message.error ? (
        <span
          className="message-status__deleted tg-icon tg-icon-cross-line color-error-500 ml-2"
          title={message.error ? message.error : t('chat_user_chat_message_deleted_status')}
        />
      ) : null}
    </>
  );
});
