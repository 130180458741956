import React from 'react';
import Long from 'long';
import {format, fromUnixTime, isSameMonth} from 'date-fns';

import {MONTH_DELIMITER_FORMAT} from '../../../../constants';


function parseUnixStamp(stamp?: Long | null): Date {
  return fromUnixTime(stamp?.toNumber() || 0);
}

function formatTime(stamp?: Long | null): string {
  return format(parseUnixStamp(stamp), MONTH_DELIMITER_FORMAT);
}

function compareForSameMonth(stamp?: Long | null, prevStamp?: Long | null): boolean {
  return !!(!prevStamp || !isSameMonth(parseUnixStamp(stamp), parseUnixStamp(prevStamp)));
}

export const useDiffMonth = (stamp?: Long | null, prevStamp?: Long | null) => {
  const [same, setSame] = React.useState(compareForSameMonth(stamp, prevStamp));

  React.useEffect(() => {
    setSame(compareForSameMonth(stamp, prevStamp));
  }, [stamp, prevStamp]);

  return same;
};

interface ChatInfoDateDelimiterProps {
  stamp?: Long | null;
}

export const ChatInfoDateDelimiter: React.FC<ChatInfoDateDelimiterProps> = ({stamp}) => {
  return (
    <div className="profile-sidebar__date-delimiter">
      {stamp ? formatTime(stamp) : null}
    </div>
  );
};

export default ChatInfoDateDelimiter;
