import React from 'react';
import cn from 'classnames';
import {IMCMessageSuggestionOption} from '../../../../api/proto';

interface MessageSuggestionsBarItemProps {
  suggestion: IMCMessageSuggestionOption;
  onClick?(suggestion: IMCMessageSuggestionOption): void;
  disabled?: boolean;
}

const MessageSuggestionsBarItem: React.FC<MessageSuggestionsBarItemProps> = ({
  suggestion,
  onClick,
  disabled,
}) => {

  const handleClick = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    !disabled && onClick?.(suggestion);
  };

  return (
    <div
      className={cn('message-suggestions-bar__item px-4', {disabled})}
      onClick={handleClick}
    >
      <div className="message-suggestions-bar__item-icon">AI:</div>
      <div className="message-suggestions-bar__item-text" title={suggestion.text || ''}>
        {suggestion.text}
      </div>
    </div>
  );
};

export default MessageSuggestionsBarItem;
