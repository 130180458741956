import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';

import Peer from '../../../../stores/Peer';
import AvatarWrapper from '../../AvatarWrapper';
import PeerStatusTitle from './PeerStatusTitle';
import useCompactMode from '../../../../hooks/useCompactMode';


interface PeerProfileInfoProps {
  className?: string;
  peer: Peer;
}

export const PeerProfileInfo = observer(({className, peer}: PeerProfileInfoProps) => {
  const compactMode = useCompactMode();
  return (
    <div className={cn('chat-profile-info', className)}>
      <AvatarWrapper
        size={compactMode ? 'md' : 'lg'}
        className="chat-profile-info__avatar"
        attachment={peer?.avatar}
        channelID={peer?.channelID}
        name={peer.displayName}
      />

      <div className="chat-profile-info__body">
        <div className="chat-profile-info__title">{peer.displayName}</div>
        <div className="chat-profile-info__status">
          <PeerStatusTitle className="chat-head-title__status-title" peer={peer} />
        </div>
      </div>
    </div>
  );
});

export default PeerProfileInfo;
