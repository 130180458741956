import {uint8ArrayToUuid} from '../../utils/arrayUtils';
import browserStorage from '../../utils/browserStorage';
import jsonParse from '../../utils/json/jsonParse';
import WorkspaceStore from './WorkspaceStore';


const WORKSPACES_STORAGE_KEY = 'workspaces_state';

export class WorkspaceLocalStore {
  constructor(protected workspace: WorkspaceStore) {
  }

  protected getFullState_ = (): Record<string, Record<string, Record<string, any>>> => {
    const visitsStr = browserStorage.getItem(WORKSPACES_STORAGE_KEY) || '';
    return visitsStr ? jsonParse(visitsStr) || {} : {};
  };

  protected setState_ = (data: any) => {
    const userId = this.workspace.app.userStore.userId?.toString();
    const workspaceId = uint8ArrayToUuid(this.workspace.id);
    if (!userId || !workspaceId) {
      return;
    }

    const state = this.getFullState_();

    const workspaceMap = state[userId] || {};
    const workspace = workspaceMap[workspaceId] || {};

    const workspaceUpdate = {
      ...workspace,
      ...data,
    };

    const newState = {
      ...state,
      [userId]: {
        ...workspaceMap,
        [workspaceId]: workspaceUpdate,
      },
    };

    browserStorage.setItem(WORKSPACES_STORAGE_KEY, JSON.stringify(newState));
  };

  protected getState_ = (): any => {
    const userId = this.workspace.app.userStore.userId?.toString();
    const workspaceId = uint8ArrayToUuid(this.workspace.id);
    if (!userId || !workspaceId) {
      return {};
    }

    const state = this.getFullState_();

    const workspaceMap = state[userId] || {};
    return workspaceMap[workspaceId] || {};
  };

  public update = () => {
    this.setState_({
      role: this.workspace.currentMember?.role,
      roleName: this.workspace.currentMember?.roleTitle,
      paid: !!this.workspace.billing.currentTariff,
    });
  }

  public get userRole() {
    const state = this.getState_();
    return state.role ? Number(state.role) : 0;
  }

  public get paid(): boolean {
    const state = this.getState_();
    return !!state.paid;
  }
}

export default WorkspaceLocalStore;