import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';

import FormControl from 'o-ui/FormControl';
import FormControlLabel from 'o-ui/FormControlLabel';
import Switch from 'o-ui/Switch';
import WorkspaceMemberConfig from '../../../../stores/Workspaces/WorkspaceMemberConfig';

interface IProps {
  memberConfig: WorkspaceMemberConfig;
  disabled?: boolean;
}

export const MemberConfigOptions = observer((props: IProps) => {
  const {t} = useTranslation();

  return (
    <>
      <FormControl className="w-100 outline-default mt-5" variant="outlined" disabled={props.disabled}>
        <FormControlLabel
          className="justify-content-end m-0"
          label={t<string>('settings_workspace_members_manage_access_to_contact_information')}
          labelPlacement="start"
          control={
            <Switch
              className="ml-auto"
              name="active"
              checked={props.memberConfig.accessToContactInfo}
              onChange={props.memberConfig.toggleAccessToContactInfo}
              disabled={props.disabled}
            />
          }
        />
        <div className="body3-regular color-body-secondary mb-3 mt-n3">
          {t('settings_workspace_members_manage_access_to_contact_information_description')}
        </div>
      </FormControl>

      <FormControl className="w-100 outline-default mt-5" variant="outlined" disabled={props.disabled}>
        <FormControlLabel
          className="justify-content-end m-0"
          label={t<string>('settings_workspace_members_manage_access_to_contacts')}
          labelPlacement="start"
          control={
            <Switch
              className="ml-auto"
              name="active"
              checked={props.memberConfig.accessToContactsList}
              onChange={props.memberConfig.toggleAccessToContactsList}
              disabled={props.disabled}
            />
          }
        />
        <div className="body3-regular color-body-secondary mb-3 mt-n3">
          {t('settings_workspace_members_manage_access_to_contacts_description')}
        </div>
      </FormControl>

      <FormControl className="w-100 outline-default mt-5" variant="outlined" disabled={props.disabled}>
        <FormControlLabel
          className="justify-content-end m-0"
          label={t<string>('settings_workspace_members_manage_allow_manage_chats')}
          labelPlacement="start"
          control={
            <Switch
              className="ml-auto"
              name="active"
              checked={props.memberConfig.allowManageChats}
              onChange={props.memberConfig.toggleAllowManageChats}
              disabled={props.disabled}
            />
          }
        />
        <div className="body3-regular color-body-secondary mb-3 mt-n3">
          {t('settings_workspace_members_manage_allow_manage_chats_description')}
        </div>
      </FormControl>
    </>
  );
});

export default MemberConfigOptions;
