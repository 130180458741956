import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';

import {
  IMCChannel,
  IMCChannelShownFields,
  IMCChannelState,
  IMCMessage,
  IMCMethod,
  IUpdate,
  IUpdateChangedReadPointer,
  IUpdateChannelCounterReset,
  IUpdateChannelState,
  IUpdateEntities,
  MCChat,
  MCMethodResponse,
  api,
  entities,
} from '../../api/proto';
import {equalUint8Arrays} from '../../utils/arrayUtils';
import {APILayer, APIRequestOptions} from '../APILayer';
import {DEFAULT_SOUND_NAME} from '../NotificationsStore';
import Peer from '../Peer';
import {RawMessage} from '../RawMessagesStore';
import WorkspaceMember from '../Workspaces/WorkspaceMember';
import WorkspaceStore from '../Workspaces/WorkspaceStore';
import {ChannelsUpdaterEvent} from './ChannelsUpdater';
import WebWidgetConfig from './WebWidgetConfig';

export const equalsChannelTypes = (
  type1?: entities.OzekonChannelType | null,
  type2?: entities.OzekonChannelType | null,
): boolean => {
  return (type1 || entities.OzekonChannelType.OIT_NONE) === (type2 || entities.OzekonChannelType.OIT_NONE);
};

export const includeChannelType = (
  types?: entities.OzekonChannelType[],
  type?: entities.OzekonChannelType | null,
): boolean => {
  return !!types?.some((t) => t === (type || entities.OzekonChannelType.OIT_NONE));
};

type OneOfChannelsResponse = MCMethodResponse[keyof MCMethodResponse];

export interface IChannelProps extends IMCChannel {
  unreadCount?: Long | null;
}

export class Channel extends APILayer {
  constructor(public raw: IChannelProps, public workspace: WorkspaceStore) {
    super(workspace.app);
    makeObservable(this);
    this.update(raw);
    workspace.updater.on(ChannelsUpdaterEvent.UPDATE, this.processNewUpdate_);
  }

  @observable type?: entities.OzekonChannelType | null;
  @observable state?: IMCChannelState;
  @observable unreadCount?: Long | null;
  @observable shownFields?: IMCChannelShownFields | null;

  @observable webWidgetConfig: WebWidgetConfig | null = null;

  @action public update = (raw: IChannelProps) => {
    const toAssign: IChannelProps = {...raw};
    if (raw.webWidgetConfig) {
      toAssign['webWidgetConfig'] = new WebWidgetConfig(raw.webWidgetConfig);
    }

    Object.assign(this, toAssign);
  };

  @computed get id(): Uint8Array {
    return this.raw?.channelID as Uint8Array;
  }

  @computed get unreadNumber(): number {
    return this.unreadCount?.toNumber() || 0;
  }

  @computed get name(): string {
    return this.shownFields?.name || '';
  }

  @computed get notificationSound(): string {
    return this.shownFields?.notificationSound || DEFAULT_SOUND_NAME;
  }

  @computed get userName(): string {
    return this.state?.identifiers?.username || '';
  }

  @computed get phone(): string {
    return this.state?.identifiers?.phone || '';
  }

  @computed get email(): string {
    return this.state?.identifiers?.email || '';
  }

  @computed get shownName(): string {
    return this.state?.identifiers?.shownName || '';
  }

  @computed get chatsImportInProgress(): boolean {
    return !!this.state?.chatsImportInProgress;
  }

  @computed get messagesImportInProgress(): boolean {
    return !!this.state?.chatsImportInProgress;
  }

  @computed get isTelegram(): boolean {
    return this.type === entities.OzekonChannelType.OIT_TELEGRAM;
  }

  @computed get isWebWidget(): boolean {
    return this.type === entities.OzekonChannelType.OIT_WEB_WIDGET;
  }

  @computed get pinned() {
    return false;
  }

  get assignmentEnabled(): boolean {
    return this.isWebWidget;
  }

  @observable disabled: boolean = false;

  @action setDisabled = (disabled: boolean) => {
    this.disabled = disabled;
  };

  users: Peer[] = [];

  public findUser = (userId: Long): Peer | null => {
    return this.users.find((user) => user.id.eq(userId)) || null;
  };

  private processNewUpdate_ = (update: IUpdate) => {
    if (!equalUint8Arrays(this.id, update.channelID)) {
      return;
    }

    if (update.updateNewMessage?.message) {
      this.processNewMessage_(update.updateNewMessage.message, update.entities);
      this.playNewMessageNotifySound_(update.updateNewMessage.message, update.entities);
    }

    if (update.updateChangedReadPointer?.totalChannelUnread?.greaterThan(0)) {
      this.processReadPointer_(update.updateChangedReadPointer);
    }

    if (update.updateChannelCounterReset) {
      this.resetReadPointer_(update.updateChannelCounterReset);
    }

    if (update.updateChannelState) {
      this.processChannelState_(update.updateChannelState);
    }
  };

  @action private processNewMessage_ = (message: RawMessage, entities?: IUpdateEntities | null) => {
    const isOwnMessage = this.app.userStore.isCurrentOperator(message.operatorID);
    const isOperatorsMessage = message.operatorID?.greaterThan(0);
    const isObservableChat = this.isObservableChat_(message.chatID, entities);

    if (isOwnMessage || message.serviceMessage || isOperatorsMessage || !isObservableChat) {
      return;
    }

    this.unreadCount = (this.unreadCount || Long.ZERO).add(1);
    this.raw.unreadCount = (this.raw.unreadCount || Long.ZERO).add(1);
  };

  @action private processReadPointer_ = (changedReadPointer?: IUpdateChangedReadPointer | null) => {
    if (changedReadPointer && this.app.userStore.isCurrentOperator(changedReadPointer?.operatorID)) {
      this.unreadCount = changedReadPointer.totalChannelUnread;
      this.raw.unreadCount = changedReadPointer.totalChannelUnread;
    }
  };

  @action private resetReadPointer_ = (updateChannelCounterReset?: IUpdateChannelCounterReset | null) => {
    if (this.app.userStore.isCurrentOperator(updateChannelCounterReset?.operatorID)) {
      this.unreadCount = Long.ZERO;
      this.raw.unreadCount = Long.ZERO;
    }
  };

  @action private processChannelState_ = (updateChannelState: IUpdateChannelState) => {
    if (updateChannelState.newState) {
      this.state = updateChannelState.newState;
    }
  };

  channelsRequest = async <T = OneOfChannelsResponse>(
    data: IMCMethod,
    responseType: keyof MCMethodResponse | null = null,
    options?: APIRequestOptions,
  ) => {
    const response = await this.request<api.ChannelsResponse>(
      {
        channelsRequest: {
          workspaceId: this.workspace.id,
          action: {
            ...data,
          },
        },
      },
      'channelsResponse',
      options,
    );

    let res: T | null | undefined = null;
    if (responseType && response.res && response.res.methodResponse && response.res.methodResponse[responseType]) {
      res = response.res.methodResponse[responseType] as T;
    }

    return {error: response.error, res};
  };

  public processReadAllChats = () => {
    this.update({...this.raw, unreadCount: Long.fromNumber(0)});

    this.workspace.chatsPool.markAllAsRead(this.id);
  };

  @computed public get members(): WorkspaceMember[] {
    return this.workspace.members;
  }

  private playNewMessageNotifySound_ = (message: IMCMessage, entities?: IUpdateEntities | null) => {
    const isOwnMessage = this.app.userStore.isCurrentOperator(message.operatorID);
    const isActiveWorkspace = equalUint8Arrays(this.workspace.id, this.app.activeWorkspace.id);
    const isOperatorsMessage = message.operatorID?.greaterThan(0);

    const isObservableChat = this.isObservableChat_(message.chatID, entities);

    if (
      !isActiveWorkspace ||
      isOwnMessage ||
      isOperatorsMessage ||
      message.serviceMessage ||
      !isObservableChat
    ) {
      return;
    }

    console.debug(`%c----->Channel.playNewMessageNotifySound`, 'color: pink', message.text);
    this.app.notifications.add(this.notificationSound);
  };

  private isObservableChat_ = (chatID?: Long | null, entities?: IUpdateEntities | null) => {
    if (!this.isWebWidget) {
      return true;
    }

    const rawChat = entities?.chats?.find((c) => chatID && c.id?.equals(chatID));
    if (!rawChat) {
      console.error(`%c----->Channel.isObservableChat_ not found chat ${chatID?.toString()} in entities`, 'color: red', entities);
    }

    const youAreJoined = !!rawChat?.joinedOperators?.some((userID) =>
      this.app.userStore.currentMember?.userId?.equals(userID),
    );

    if (rawChat?.status === MCChat.MCChatStatus.S_RESOLVED) {
      return false;
    }

    return rawChat?.status !== MCChat.MCChatStatus.S_IN_PROGRESS || youAreJoined;
  };
}

export default Channel;
