import React from 'react';

import SettingsTab from '../../stores/LayOutStore/SettingsTab';
import BillingTab from './BillingTab';
import ChannelsTab from './ChannelsTab';
import DownloadAppTab from './DownloadAppTab';
import MessageTagsTab from './MessageTagsTab';
import PersonalInfoTab from './PersonalInfoTab';
import {SettingsHeadState} from './SettingsHead';
import {ISettingsTabWrapperRef} from './SettingsTabWrapper';
import SnippetsTab from './SnippetsTab';
import WorkspaceMembersTab from './WorkspaceMembersTab';
import WorkspaceSettingsTab from './WorkspaceSettingsTab';

interface SettingsTabItemProps {
  tab: SettingsTab | null
  tabRef?: React.Ref<ISettingsTabWrapperRef>;
  onChangeState?: (state: SettingsHeadState) => void;
}

export const SettingsTabItem = React.memo(({
  tab,
  tabRef,
  onChangeState,
}: SettingsTabItemProps) => {
  switch (tab) {
    case SettingsTab.PERSONAL_INFO:
      return <PersonalInfoTab onChangeState={onChangeState} />;
    case SettingsTab.WORKSPACE_SETTINGS:
      return <WorkspaceSettingsTab onChangeState={onChangeState} />;
    case SettingsTab.CHANNELS:
      return <ChannelsTab ref={tabRef} onChangeState={onChangeState} />;
    case SettingsTab.WORKSPACE_MEMBERS:
      return <WorkspaceMembersTab ref={tabRef} onChangeState={onChangeState} />;
    case SettingsTab.MESSAGE_TAGS:
      return <MessageTagsTab onChangeState={onChangeState} />;
    case SettingsTab.SNIPPETS:
      return <SnippetsTab onChangeState={onChangeState} />;
    case SettingsTab.DOWNLOAD_APP:
      return <DownloadAppTab onChangeState={onChangeState} />;
    case SettingsTab.BILLING:
      return <BillingTab onChangeState={onChangeState} />;
  }

  return null;
});

export default SettingsTabItem;
