import React from 'react';
import {Link} from 'react-router-dom';

import {IMCTextEntities} from '../../../../api/proto';
import {uint8ArrayToString} from '../../../../utils/arrayUtils';
import {formatUrl, isLocalUrl, isPhoneNumberUrl, validURL} from '../../../../utils/formatUrl';


interface TextUrlEntityProps {
  children?: React.ReactNode;
  entity: IMCTextEntities;
  textUrl?: string;
}

export const TextUrlEntity: React.FC<TextUrlEntityProps> = (props) => {
  let url: URL | null = null;
  let href;
  const propsUrl = uint8ArrayToString(props.entity.payload) || props.textUrl;

  if (isPhoneNumberUrl(propsUrl)) {
    href = propsUrl;
  } else {
    href = formatUrl(propsUrl);

    try {
      if (validURL(href)) {
        url = new URL(href);
      }
    } catch (e) {
      console.error(e);
    }
  }

  let isLocalLink = false;
  try {
    isLocalLink = isLocalUrl(href);
  } catch (e) {
    console.error(e);
  }

  return isLocalLink && !!url ? (
    <Link to={url.pathname}>{props.children}</Link>
  ) : (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

export default TextUrlEntity;
