import cn from 'classnames';
import {observer} from 'mobx-react';
import Button from 'o-ui/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {matchPath, useNavigate} from 'react-router';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import Paths from '../../../../routes/Paths';
import {useStore} from '../../../../stores/AppStore';
import {allowSettingsPathForAdmin, allowSettingsPathForOperator} from '../../../Settings/useSettingsPageRoute';
import NavigationBarItem from './NavigationBarItem';

export const NavigationBar = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {routingHistory, chatsView, userStore} = useStore();

  const historyItems = routingHistory.history.map((location) => {
    const matchMessage = matchPath(Paths.SpecificMessage, location.pathname);
    const matchLogin = matchPath(Paths.Login, location.pathname);
    const matchLogout = matchPath(Paths.Logout, location.pathname);

    if (matchMessage || matchLogin || matchLogout) {
      return null;
    }

    if (
      (userStore.isOperator && !allowSettingsPathForOperator(location.pathname)) ||
      (userStore.isAdmin && !allowSettingsPathForAdmin(location.pathname))
    ) {
      return null;
    }

    return location;
  });

  const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false);
  const menuRef = React.useRef<HTMLDivElement | null>(null);

  useOnClickOutside(menuRef, () => {
    if (dropdownOpen) {
      setDropdownOpen(false);
    }
  });

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleGoClick = () => {
    chatsView.setIsChatOpeningByNavigationBar(true);
  };

  const handleGoBackClick = () => {
    handleGoClick();
    navigate(-1);
  };

  const handleGoForwardClick = () => {
    handleGoClick();
    navigate(1);
  };

  const handleGoToClick = (location, index) => {
    handleGoClick();
    if (routingHistory.historyIndex !== index) {
      history.go(routingHistory.historyIndex - index);
      setDropdownOpen(false);
    }
  };

  return (
    <div className="navigation-bar d-flex align-items-center border-bottom px-5">
      <Button
        variant="contained"
        color="gray"
        size="small"
        className="tg-icon-arrow-left-line body3-bold"
        onClick={handleGoBackClick}
        disabled={routingHistory.historyIndex >= historyItems.length - 1 || historyItems.length <= 1}
      />
      <Button
        variant="contained"
        color="gray"
        size="small"
        className="tg-icon-arrow-right-line body3-bold ml-5"
        onClick={handleGoForwardClick}
        disabled={routingHistory.historyIndex === 0}
      />

      <div className="position-relative ml-auto" ref={menuRef}>
        <Button
          variant="contained"
          color={dropdownOpen ? 'primary' : 'gray'}
          size="small"
          className="body3-bold"
          onClick={toggleDropdown}
          disabled={routingHistory.history.length === 0}
        >
          <i className="tg-icon tg-icon-clock-line font-size-18 mr-2" />
          {t('chat_side_menu_navigation_btn')}
        </Button>
        {dropdownOpen ? (
          <div className="dropdown-menu navigation-bar__dropdown">
            <div className="d-flex p-5 text-uppercase body2-bold color-body-tertiary">
              {t('chat_side_menu_navigation_resent_label')}
            </div>
            <div className="custom-scroll-y">
              {historyItems.map((location, index) =>
                location ? (
                  <NavigationBarItem
                    key={location.key}
                    className={cn('dropdown-item', {
                      'dropdown-item--active': routingHistory.historyIndex === index,
                      [`dropdown-item--${index}`]: true,
                    })}
                    location={location}
                    index={index}
                    onClick={handleGoToClick}
                  />
                ) : null,
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
});

export default NavigationBar;
