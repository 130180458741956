import React from 'react';

import {
  MAX_GROUP_MEDIA_PREVIEW_WIDTH,
  MAX_MEDIA_PREVIEW_WIDTH,
  MAX_MEDIA_PREVIEW_WIDTH_FOR_COMPACT,
  MIN_MEDIA_PREVIEW_WIDTH,
  STICKER_ANIMATED_SIZE,
  STICKER_IMAGE_SIZE,
} from '../../../constants';
import {IMCMessage} from '../../../api/proto';
import useCompactMode from '../../../hooks/useCompactMode';
import Message from '../../../stores/Message';
import calcImageSize from '../../../utils/image/calcImageSize';
import convertHeightToNewWidth from '../../../utils/image/convertHeightToNewWidth';
import convertWidthToNewHeight from '../../../utils/image/convertWidthToNewHeight';
import useMaxMediaSize from '../../../hooks/useMaxMediaSize';


function getMediaPreviewSize(message: IMCMessage) {
  let width: number | undefined | null;
  let height: number | undefined | null;

  if (message.attachments?.length) {
    width = message.attachments[0].width?.toNumber();
    height = message.attachments[0].height?.toNumber();
  }

  return [width || undefined, height || undefined];
}

function getMaxWidth(maxWidth = 0, compactMode?: boolean): number {
  const max = compactMode ? MAX_MEDIA_PREVIEW_WIDTH_FOR_COMPACT : MAX_MEDIA_PREVIEW_WIDTH;
  if (maxWidth === 0) {
    return max;
  }

  return maxWidth > max ? max : maxWidth;
}

function getMinWidth(width = 0): number {
  if (width === 0) {
    return MIN_MEDIA_PREVIEW_WIDTH;
  }

  return width < MIN_MEDIA_PREVIEW_WIDTH ? MIN_MEDIA_PREVIEW_WIDTH : width;
}

function adaptMediaPreviewWidth(width?: number | null, maxWidth = 0, compactMode?: boolean): number | undefined {
  if (typeof width !== 'number') {
    return undefined;
  }

  const _width = getMinWidth(width);

  return _width > getMaxWidth(maxWidth, compactMode) ? getMaxWidth(maxWidth, compactMode) : _width;
}

function equalSizes(size1?: IGroupMessageSize | null, size2?: IGroupMessageSize | null): boolean {
  if (!size1 && !size2) {
    return true;
  }
  if (!size1 || !size2) {
    return false;
  }

  if (
    size1.style.width !== size2.style.width ||
    size1.style.height !== size2.style.height ||
    size1.items.length !== size2.items.length
  ) {
    return false;
  }
  const length = size1.items.length;
  for (let i = 0; i < length; i++) {
    if (
      size1.items[i].width !== size2.items[i].width || size1.items[i].height !== size2.items[i].height
    ) {
      return false;
    }
  }
  return true;
}

export interface IMessageSize {
  width?: number;
  height?: number;
  marginBottom?: number;
}

export interface IGroupMessageSize {
  style: IMessageSize;
  items: IMessageSize[];
}

export default function useMessageSize(groupedMessage: Message[], gap: number = 0): IGroupMessageSize {
  const [size, setSize] = React.useState<IGroupMessageSize>({style: {}, items: []});
  const compactMode = useCompactMode();
  const {maxWidth, maxHeight} = useMaxMediaSize();
  const albumLength = groupedMessage.length;

  React.useEffect(() => {
    if (albumLength > 1) {
      const sizes = calcMessageSizes(groupedMessage, MAX_GROUP_MEDIA_PREVIEW_WIDTH, gap)
      if (!equalSizes(size, sizes) && (groupedMessage[0].hasImage || groupedMessage[0].hasVideo)) {
        //console.debug(`%c---->useMessageSize ${groupedMessage.map((m) => m.id.toString()).join('_')}, albumLength=${albumLength},  maxWidth=${maxWidth}, gap=${gap} sizes=`, 'color: blue', sizes);
        setSize(sizes);
      }
    } else if (albumLength === 1 && groupedMessage[0].hasSticker) {
      if (size.style.width !== STICKER_IMAGE_SIZE) {
        setSize({
          style: {
            width: STICKER_IMAGE_SIZE,
          },
          items: [{
            width: STICKER_IMAGE_SIZE,
          }],
        });
      }
    } else if (albumLength === 1 && groupedMessage[0].hasStickerAnimated) {
      if (size.style.width !== STICKER_ANIMATED_SIZE) {
        setSize({
          style: {
            width: STICKER_ANIMATED_SIZE,
          },
          items: [{
            width: STICKER_ANIMATED_SIZE,
          }],
        });
      }
    } else if (albumLength === 1 && !groupedMessage[0].isDocumentImage) {
      const message = groupedMessage[0];
      const [messageWidth, messageHeight] = getMediaPreviewSize(message);
      const [availableWidth, availableHeight] = messageWidth ? calcImageSize(
        messageWidth || 0,
        messageHeight || 0,
        maxWidth,
        maxHeight,
      ) : [undefined];

      const width = adaptMediaPreviewWidth(availableWidth, maxWidth, compactMode);
      const [newWidth, newHeight] = convertHeightToNewWidth(width, availableWidth, availableHeight);
      if (size.style.width !== width) {
        //console.debug(`%c---->useMessageSize ${groupedMessage.map((m) => m.id.toString()).join('_')}, albumLength=${albumLength},  maxWidth=${maxWidth}, gap=${gap} sizes=`, 'color: blue', size);
        setSize({
          style: {
            width: newWidth,
            height: newHeight,
          },
          items: [{
            width: newWidth,
            height: newHeight,
          }],
        });
      }
    }

  }, [
    groupedMessage,
    albumLength,
    size,
    gap,
    compactMode,
    maxWidth,
    maxHeight,
  ]);

  return size;
}

export function useReplyMessageSize(message: IMCMessage, maxWidth = 0) {
  const [size, setSize] = React.useState<IGroupMessageSize>({style: {}, items: []});
  const compactMode = useCompactMode();

  const [messageWidth, messageHeight] = getMediaPreviewSize(message);
  const [availableWidth] = messageWidth ? calcImageSize(messageWidth || 0, messageHeight || 0) : [undefined];

  const width = adaptMediaPreviewWidth(availableWidth, maxWidth, compactMode);
  if (size.style.width !== width) {
    setSize({
      style: {
        width,
      },
      items: [{
        width,
      }],
    });
  }

  return size;
}

function getMessageRatio(message: Message): number {
  const [width, height] = getMediaPreviewSize(message);

  return Number(((width || 0) / (height || 0)).toFixed(1));
}

function getMessageHeight(message: Message): number {
  const [, height] = getMediaPreviewSize(message);

  return Number(((height || 0)).toFixed(1));
}

export function sliceIntoChunksBySameHeight(groupedMessage: Message[]) {
  const albumLength = groupedMessage.length;
  if (albumLength === 1) {
    return [groupedMessage];
  } else if (albumLength === 2) {
    return chunksDuo(groupedMessage[0], groupedMessage[1]);
  } else if (albumLength === 3) {
    return chunksTrio(groupedMessage[0], groupedMessage[1], groupedMessage[2]);
  }

  const chunks: Message[][] = [];
  let index = 0;

  groupedMessage.forEach((message) => {
    const prevHeight = chunks[index] ? getMessageHeight(chunks[index][0]) : null;
    const currentHeight = getMessageHeight(message);

    if (!prevHeight && index === 0) {
      chunks[index] = [message];
    } else if (prevHeight === currentHeight) {
      try {
        chunks[index].push(message);
      } catch (e) {
        chunks[index] = [message];
      }
    } else {
      index++;
      chunks[index] = [message];
    }
  });

  // console.debug(`%c---->sliceIntoChunksBySameHeight ${groupedMessage.map((m) => m.id.toString()).join('_')} `, 'color: blue', chunks);
  return chunks;
}

function sliceIntoChunksByRatio(groupedMessage: Message[]) {
  const albumLength = groupedMessage.length;
  const chunks: Message[][] = [];
  const maxChunkSize = albumLength >= 5 ? 3 : 2;
  let index = 0;
  let stopChunking = false;

  groupedMessage.forEach((message, i) => {
    const ratioPrev = chunks[index] && chunks[index].length < maxChunkSize ? getMessageRatio(chunks[index][0]) : null;

    const ratioCurrent = getMessageRatio(message);
    const ratioNext = (i + 1) < albumLength ? getMessageRatio(groupedMessage[i + 1]) : null;

    //console.debug(`%c---->sliceIntoChunksByRatio ${message.id.toString()}  ratioPrev=${ratioPrev}, ratioCurrent=${ratioCurrent}, ratioNext=${ratioNext}, index=${index}`, 'color: blue');

    if (stopChunking && albumLength === 5) {
      //console.debug(`%c---->sliceIntoChunksByRatio ${message.id.toString()} - 1 index=${index}`, 'color: blue');
      if (!chunks[index] || chunks[index].length >= maxChunkSize) {
        index++;
      }
      chunks[index] = [message];
      index++;
    } else if (!ratioPrev) {
      //console.debug(`%c---->sliceIntoChunksByRatio ${message.id.toString()} - 1 index=${index}`, 'color: blue');
      chunks[index] = [message];
    } else if (ratioPrev > 1 && ratioCurrent <= 1 && ratioNext && ratioNext <= 1 && albumLength - i < maxChunkSize) {
      //console.debug(`%c---->sliceIntoChunksByRatio ${message.id.toString()} - 2 index=${index}`, 'color: blue');
      index++;
      chunks[index] = [message];
    } else if (ratioPrev > 1 && ratioCurrent <= 1 && ratioNext && ratioNext <= 1 && albumLength - i >= maxChunkSize) {
      //console.debug(`%c---->sliceIntoChunksByRatio ${message.id.toString()} - 3 index=${index}`, 'color: blue');
      chunks[index].push(message);
      index++;
    } else if (ratioPrev > 1 && ratioCurrent <= 1 && ratioNext && ratioNext > 1) {
      //console.debug(`%c---->sliceIntoChunksByRatio ${message.id.toString()} - 4 index=${index}`, 'color: blue');
      chunks[index].push(message);
      index++;
    } else if (
      (ratioPrev <= 1 && ratioCurrent <= 1) ||
      ((ratioPrev <= 1 || ratioCurrent <= 1) && (!ratioNext || (albumLength - (i + 1)) >= maxChunkSize)) ||
      (ratioPrev > 1 && ratioCurrent > 1 && chunks[index] && chunks[index].length < maxChunkSize)
    ) {
      //console.debug(`%c---->sliceIntoChunksByRatio ${message.id.toString()} - 5 index=${index}`, 'color: blue');
      try {
        chunks[index].push(message);
        if (chunks[index].length >= maxChunkSize) {
          index++;
          stopChunking = albumLength === 11;
        }
      } catch (e) {
        chunks[index] = [message];
      }

    } else {
      //console.debug(`%c---->sliceIntoChunksByRatio ${message.id.toString()} - 6 index=${index}`, 'color: blue');
      index++;
      chunks[index] = [message];
    }
  });

  // console.debug(`%c---->sliceIntoChunksByRatio ${groupedMessage.map((m) => m.id.toString()).join('_')} `, 'color: blue', chunks);
  return chunks;
}

function chunksDuo(message1: Message, message2: Message): Message[][] {
  const ratio1 = getMessageRatio(message1);
  const ratio2 = getMessageRatio(message2);

  //  console.debug(`%c---->chunksDuo ${message1.id.toString()}_${message2.id.toString()} - ratio=${ratio1} x ${ratio2}`, 'color: blue');

  if (ratio1 > 1 && ratio2 > 1) {
    return [[message1], [message2]];
  }

  return [[message1, message2]];
}

function chunksTrio(message1: Message, message2: Message, message3: Message): Message[][] {
  const ratio1 = getMessageRatio(message1);
  const ratio2 = getMessageRatio(message2);
  const ratio3 = getMessageRatio(message3);

  //console.debug(`%c---->chunksTrio ${message1.id.toString()}_${message2.id.toString()}_${message3.id.toString()} - ratio=${ratio1} x ${ratio2} x ${ratio3}`, 'color: blue');

  if (ratio1 < 1 && ratio2 < 1 && ratio3 < 1) {
    return [[message1, message2, message3]];
  } else if (ratio1 >= 1 && ratio2 >= 1 && ratio3 >= 1) {
    return [[message1], [message2, message3]];
  } else if (ratio1 >= 1 && ratio2 < 1 && ratio3 < 1) {
    return [[message1], [message2, message3]];
  } else if (ratio1 < 1 && ratio2 < 1 && ratio3 >= 1) {
    return [[message1, message2], [message3]];
  }

  return [[message1, message2, message3]];
}

function sliceIntoChunks(groupedMessage: Message[]) {
  const albumLength = groupedMessage.length;
  if (albumLength === 1) {
    return [groupedMessage];
  } else if (albumLength === 2) {
    return chunksDuo(groupedMessage[0], groupedMessage[1]);
  } else if (albumLength === 3) {
    return chunksTrio(groupedMessage[0], groupedMessage[1], groupedMessage[2]);
  }


  // let chunks = sliceIntoChunksBySameHeight(groupedMessage);
  let chunks = sliceIntoChunksByRatio(groupedMessage);

  if (
    (chunks.length === albumLength) ||
    (albumLength === 5 && chunks.length > 2) ||
    (albumLength === 6 && chunks.length > 3) ||
    (chunks.length > 4 || chunks.some((chunk) => chunk.length > 3))
  ) {
    chunks = sliceIntoChunksByRatio(groupedMessage);
  }
  /*
  if (albumLength === 5 && chunks.length === 2) {
    chunks = sliceIntoChunksByRatio(groupedMessage);
  }
  */
  // console.debug(`%c---->sliceIntoChunks ${groupedMessage.map((m) => m.id.toString()).join('_')} `, 'color: blue', chunks);
  return chunks;
}

function calcMessageSizes(groupedMessage: Message[], maxWidth = 0, gap = 0, step = 0): IGroupMessageSize {
  const albumLength = groupedMessage.length;

  if (albumLength === 1) {
    const message = groupedMessage[0];
    const messageSize = calcMessageSize(message);
    return {
      style: messageSize,
      items: [messageSize],
    };
  }

  const chunks = sliceIntoChunks(groupedMessage);

  const chunksLength = chunks.length;
  const sizePairs = chunks.map((chunk, i) => {
    const gap4Width = step !== 0 ? gap * (chunk.length - 1) : 0;
    const gap4Height = i < (chunksLength - 1) ? gap : 0;

    if (chunk.length === 1) {
      const message = chunk[0];
      const messageSize = calcMessageSize(message, maxWidth - gap4Width, gap4Height);
      return [messageSize];
    } else if (chunk.length === 3) {
      return calcMessageTriadSizes(chunk[0], chunk[1], chunk[2], maxWidth - gap4Width, gap4Height);
    }

    return calcMessagePairSizes(chunk[0], chunk[1], maxWidth - gap4Width, gap4Height);
  });
  //console.debug(`%c---->calcMessageSizes ${groupedMessage.map((m) => m.id.toString()).join('_')}  maxWidth=${maxWidth}, gap=${gap}, step=${step}`, 'color: blue', sizePairs);

  let items: IMessageSize[] = [];
  let width = 0;
  let height = 0;
  let minWidth = 0;

  sizePairs.forEach((chunk) => {
    const totalChunkWidth = chunk.reduce((acc, size) => (acc + (size.width || 0)), 0);
    if (totalChunkWidth > width) {
      width = totalChunkWidth + gap * (chunk.length - 1);
    }
    items = [...items, ...chunk];
    minWidth = !minWidth ? totalChunkWidth : Math.min(minWidth, totalChunkWidth);

    height += (chunk[0].height || 0);
  });

  if (minWidth < width && step < 3) {
    return calcMessageSizes(groupedMessage, minWidth, gap, step + 1);
  }

  return {
    style: {
      width,
      height,
    },
    items,
  };
}

function calcMessageSize(message1: Message, maxWidth = 0, gap = 0): IMessageSize {
  const [message1Width, message1Height] = getMediaPreviewSize(message1);

  const [newWidth1, newHeight1] = calcImageSize(message1Width || 0, message1Height || 0, maxWidth);

  return {
    width: newWidth1,
    height: newHeight1,
    marginBottom: gap,
  };
}

function calcMessagePairSizes(message1: Message, message2: Message, maxWidth = 0, marginBottom = 0): IMessageSize[] {
  const [message1Width, message1Height] = getMediaPreviewSize(message1);
  const [message2Width, message2Height] = getMediaPreviewSize(message2);

  //console.debug(`%c---->calcMessagePairSizes ${message1.id?.toString()}_${message2.id?.toString()} sizes 1: ${message1Width}x${message1Height} 2: ${message2Width}x${message2Height} maxWidth=${maxWidth}`, 'color: blue');

  const commonHeight = Math.min(message1Height || 0, message2Height || 0);

  const [newWidth1, newHeight1] = convertWidthToNewHeight(commonHeight, message1Width, message1Height);
  const [newWidth2, newHeight2] = convertWidthToNewHeight(commonHeight, message2Width, message2Height);
  //console.debug(`%c---->calcMessagePairSizes ${message1.id?.toString()}_${message2.id?.toString()} commonHeight=${commonHeight} 1: ${newWidth1}x${newHeight1} 2: ${newWidth2}x${newHeight2}`, 'color: blue');

  const totalNewWidth = newWidth1 + newWidth2;

  if (maxWidth && totalNewWidth > maxWidth) {
    const ratio = maxWidth / totalNewWidth;

    const adaptWidth = Math.floor(newWidth1 * ratio);
    const adaptHeight = Math.floor(newHeight1 * ratio);
    const adaptWidth2 = Math.floor(newWidth2 * ratio);

    //console.debug(`%c---->calcMessagePairSizes ${message1.id?.toString()}_${message2.id?.toString()} totalNewWidth=${totalNewWidth} ratio=${ratio} adaptWidth=${adaptWidth} newWidth1=${newWidth1}`, 'color: blue');
    return [
      {
        width: adaptWidth,
        height: adaptHeight,
        marginBottom,
      },
      {
        width: adaptWidth2,
        height: adaptHeight,
        marginBottom,
      },
    ];
  }

  return [
    {
      width: newWidth1,
      height: newHeight1,
      marginBottom,
    },
    {
      width: newWidth2,
      height: newHeight2,
      marginBottom,
    },
  ];
}

function calcMessageTriadSizes(message1: Message, message2: Message, message3: Message, maxWidth = 0, marginBottom = 0): IMessageSize[] {
  const [message1Width, message1Height] = getMediaPreviewSize(message1);
  const [message2Width, message2Height] = getMediaPreviewSize(message2);
  const [message3Width, message3Height] = getMediaPreviewSize(message3);

  //console.debug(`%c---->calcMessageTriadSizes ${message1.id?.toString()}_${message2.id?.toString()}_${message3.id?.toString()} sizes 1: ${message1Width}x${message1Height} 2: ${message2Width}x${message2Height} 3: ${message3Width}x${message3Height} maxWidth=${maxWidth}`, 'color: blue');

  const commonHeight = Math.min(message1Height || 0, message2Height || 0, message3Height || 0);

  const [newWidth1, newHeight1] = convertWidthToNewHeight(commonHeight, message1Width, message1Height);
  const [newWidth2, newHeight2] = convertWidthToNewHeight(commonHeight, message2Width, message2Height);
  const [newWidth3, newHeight3] = convertWidthToNewHeight(commonHeight, message3Width, message3Height);
  //console.debug(`%c---->calcMessageTriadSizes ${message1.id?.toString()}_${message2.id?.toString()}_${message3.id?.toString()} commonHeight=${commonHeight} 1: ${newWidth1}x${newHeight1} 2: ${newWidth2}x${newHeight2} 3: ${newWidth3}x${newHeight3}`, 'color: blue');

  const totalNewWidth = newWidth1 + newWidth2 + newWidth3;

  if (maxWidth && totalNewWidth > maxWidth) {
    const ratio = maxWidth / totalNewWidth;

    const adaptWidth = Math.floor(newWidth1 * ratio);
    const adaptHeight = Math.floor(newHeight1 * ratio);
    const adaptWidth2 = Math.floor(newWidth2 * ratio);
    const adaptWidth3 = Math.floor(newWidth3 * ratio);

    //console.debug(`%c---->calcMessageTriadSizes ${message1.id?.toString()}_${message2.id?.toString()}_${message3.id?.toString()} totalNewWidth=${totalNewWidth} ratio=${ratio} adaptWidth=${adaptWidth} newWidth1=${newWidth1}`, 'color: blue');
    return [
      {
        width: adaptWidth,
        height: adaptHeight,
        marginBottom,
      },
      {
        width: adaptWidth2,
        height: adaptHeight,
        marginBottom,
      },
      {
        width: adaptWidth3,
        height: adaptHeight,
        marginBottom,
      },
    ];
  }

  return [
    {
      width: newWidth1,
      height: newHeight1,
      marginBottom,
    },
    {
      width: newWidth2,
      height: newHeight2,
      marginBottom,
    },
    {
      width: newWidth3,
      height: newHeight3,
      marginBottom,
    },
  ];
}
