import {observer} from 'mobx-react';
import React from 'react';

import ContextMenuType from '../../../../stores/ContextMenuType';
import Message from '../../../../stores/Message';
import MessagePreview from '../../../../stores/Message/MessagePreview';
import useStore from '../../../../stores/useStore';
import AudioContent from './AudioContent';
import FileContent from './FileContent';
import ImageContent from './ImageContent';
import StickerContentAnimated from './StickerContentAnimated';
import VideoContent from './VideoContent';
// import {VideoNoteContent} from './VideoNoteContent';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import AnimationContent from './AnimationContent';
import FileUploadingCircularProgress from './FileUploadingCircularProgress';
import StickerContent from './StickerContent';
import useAttachmentProgress from './useAttachmentProgress';


interface MessageContentProps {
  message: Message | MessagePreview;
  attachment?: MessageAttachment | null;
  className?: string;
  fixedSize?: boolean;
  isVisible?: boolean;
  isScrolling?: boolean;
  autoSize?: boolean;
  playButton?: boolean;
  maxWidth?: number;
  maxHeight?: number;
  blurredBackground?: boolean;
  style?: React.CSSProperties;
  reply?: Message | MessagePreview | null;
}

export const MessageContent = observer(
  ({
    message,
    attachment,
    className,
    fixedSize,
    isScrolling,
    autoSize,
    playButton,
    blurredBackground,
    style,
    reply,
  }: MessageContentProps) => {
    const {animationsEnabled, contextMenu} = useStore();

    const onContextMenu = React.useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if (message.menuIsAvailable) {
          contextMenu.open(
            {clientX: e.clientX, clientY: e.clientY},
            message.deleted ? ContextMenuType.MESSAGE_DELETED_MENU : ContextMenuType.MESSAGE_MENU,
            {
              message: message instanceof Message ? message : undefined,
              messagePreview: message instanceof MessagePreview ? message : undefined,
              attachment,
            },
          );
        }
      },
      [
        contextMenu,
        message,
        attachment,
      ],
    );

    const {isInProgress} = useAttachmentProgress(attachment);

    if (!attachment) {
      return null;
    }

    if (message.hasImage) {
      return (
        <>
          <ImageContent
            className={className}
            attachment={attachment}
            fixedSize={fixedSize}
            onContextMenu={onContextMenu}
            isScrolling={isScrolling}
            blurredBackground={blurredBackground}
          />
          {!reply ? (
            <FileUploadingCircularProgress className="position-absolute-center" attachment={attachment} />
          ) : null}
        </>
      );
    } else if (message.hasVideo) {
      return (
        <>
          <VideoContent
            className={className}
            attachment={attachment}
            isScrolling={isScrolling}
            autoSize={autoSize}
            playButton={!isInProgress && playButton}
            style={style}
            autoPlay={animationsEnabled && !reply}
            inlinePlayer={true}
          />
          {!reply ? (
            <FileUploadingCircularProgress className="position-absolute-center" attachment={attachment} />
          ) : null}
        </>
      );
    } else if (message.hasAudio) {
      return <AudioContent attachment={attachment} />;
    } else if (message.hasVoice) {
      return <AudioContent attachment={attachment} />;
    } else if (message.hasSticker) {
      return (
        <>
          <StickerContent
            className={className}
            attachment={attachment}
            fixedSize={fixedSize}
            isScrolling={isScrolling}
          />
          {!reply ? (
            <FileUploadingCircularProgress className="position-absolute-center" attachment={attachment} />
          ) : null}
        </>
      );
    } else if (message.hasStickerAnimated) {
      return <StickerContentAnimated attachment={attachment} isScrolling={isScrolling} />;
    } else if (message.hasGif) {
      return (
        <>
          <AnimationContent
            className={className}
            attachment={attachment}
            fixedSize={fixedSize}
            onContextMenu={onContextMenu}
            isScrolling={isScrolling}
            autoPlay={animationsEnabled}
          />
          {!reply ? (
            <FileUploadingCircularProgress className="position-absolute-center" attachment={attachment} />
          ) : null}
        </>
      );
    }

    return (
      <FileContent
        isOwn={reply ? !reply.isExternal : !message.isExternal}
        className={className}
        attachment={attachment}
        isScrolling={isScrolling}
        fixedSize={fixedSize}
        blurredBackground={blurredBackground}
        onContextMenu={onContextMenu}
      />
    );
  },
);

export default MessageContent;
