import * as PopperJS from '@popperjs/core';
import {observer} from 'mobx-react';
import React from 'react';
import {usePopperTooltip} from 'react-popper-tooltip';
import {animated, useTransition} from 'react-spring';
import {useStore} from '../../../../stores/AppStore';

interface ChatEmojiTooltipProps extends React.PropsWithChildren {
  popperOffset?: [number, number];
  placement?: PopperJS.Placement;
  transformOrigin?: React.CSSProperties['transformOrigin'];
  onToggle?(visible: boolean): void;
  visible?: boolean;
}

export interface ChatEmojiTooltipRef {
  triggerRef: HTMLElement | null;
  setTriggerRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  getVisible(): boolean;
}

export const ChatEmojiTooltip = observer(
  React.forwardRef<ChatEmojiTooltipRef, ChatEmojiTooltipProps>((props, ref) => {
    const {animationsEnabled} = useStore();
    const {triggerRef, getTooltipProps, setTooltipRef, setTriggerRef, visible} = usePopperTooltip({
      trigger: 'click',
      placement: props.placement || 'top-start',
      delayHide: 200,
      interactive: true,
      offset: props.popperOffset,
      visible: props.visible,
    });

    React.useImperativeHandle(ref, () => ({
      triggerRef,
      setTriggerRef,
      getVisible: () => visible,
    }));

    React.useEffect(() => {
      props.onToggle?.(visible);
    }, [visible, props]);

    const transitions = useTransition(visible, {
      config: {
        duration: 150,
      },
      from: {
        scale: 0.2,
      },
      enter: {
        opacity: 1,
        scale: 1,
        transformOrigin: props.transformOrigin || 'bottom left',
      },
      leave: {
        opacity: 0,
        scale: 0.8,
      },
    });

    if (animationsEnabled) {
      return transitions((style, visible) =>
        visible ? (
          <div ref={setTooltipRef} {...getTooltipProps()}>
            <animated.div style={style} className="tooltip-unstyled-container">
              {props.children}
            </animated.div>
          </div>
        ) : null,
      );
    }

    return visible ? (
      <div ref={setTooltipRef} {...getTooltipProps({className: 'tooltip-unstyled-container'})}>
        {props.children}
      </div>
    ) : null;
  }),
);

export default ChatEmojiTooltip;
