import React from 'react';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import {entities} from '../../api/proto';
import AvatarWrapper from '../../pages/Chat/AvatarWrapper';
import ChatChannelTypeIcon from '../../pages/Chat/Tabs/ChatsTab/ChatItem/ChatChannelTypeIcon';
import useSelectChat from './useSelectChat';
import {IRawChat} from '../../stores/RawChatsStore/RawChat';
import isChannel from '../../stores/Chat/utils/isChannel';
import isGroup from '../../stores/Chat/utils/isGroup';


interface SelectChatItemProps {
  channelType?: entities.OzekonChannelType | null;
  chat: IRawChat;
  selected?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const SelectChatItem = React.memo(({
  channelType,
  chat,
  selected: selectedProp,
  className,
  style,
}: SelectChatItemProps) => {
  const {t} = useTranslation();
  const {selectedChats, toggleChat} = useSelectChat();

  const selected = React.useMemo(
    () => {
      return selectedProp || selectedChats?.some(({id}) => chat.id && id?.equals(chat.id)) || false;
    },
    [
      chat,
      selectedProp,
      selectedChats,
    ],
  );

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    toggleChat(chat);
  };

  return (
    <li
      className={cn('select-chat__item py-3 clickable', {selected}, className)}
      onClick={handleClick}
      style={style}
    >
      <div className="select-chat__body">
        <AvatarWrapper
          className="mr-5"
          attachment={chat?.avatar}
          channelID={chat?.channelID}
          name={chat.name}
          selected={selected}
        />
        <div className="select-chat__data overflow-hidden">
          <div className="text-truncate font-size-14 select-chat__item-username mb-2">
            {chat.name}
            <ChatChannelTypeIcon className="chat-item__source-icon ml-2" type={channelType} />
          </div>
          {/*
          <p className="chat-user-message select-chat__item-status text-truncate mb-0">
            {chat.store?.chatUser?.statusTitle ||
              (chat.store?.members?.list.length
              ? t('{{count}} members', {count: chat.store?.members?.list.length})
                : t('Group'))}
          </p>
          */}
          <p className="chat-user-message select-chat__item-status text-truncate mb-0">
            {isChannel(chat) ? t('Channel') : isGroup(chat) ? t('Group') : t('Private')}
          </p>
        </div>
      </div>
    </li>
  );
});

export default SelectChatItem;
