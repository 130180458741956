import {api} from '../../api/proto';
import {t} from '../../i18n';

export default function getPayForTariffError(status?: api.WorkspacePayForTariffResponse.Status | null): string | null {
  switch (status) {
    case api.WorkspacePayForTariffResponse.Status.WPT_UNKNOWN:
      return t('Unknown error');
    case api.WorkspacePayForTariffResponse.Status.WPT_INTERNAL_ERROR:
      return t('Internal error');
    case api.WorkspacePayForTariffResponse.Status.WPT_INVALID_TARIFF:
      return t('Invalid tariff');
  }

  return null;
}