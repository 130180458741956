import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  Link,
  useNavigate,
  useParams,
} from 'react-router-dom';

import Button from 'o-ui/Button';
import CircularProgress from 'o-ui/CircularProgress';
import FormHelperText from 'o-ui/FormHelperText';

import {getUserResponseStatusError} from '../../api/getErrorByType';
import {entities} from '../../api/proto';
import Div100vh from '../../components/Div100vh';
import {Paths} from '../../routes/Paths';
import useStore from '../../stores/useStore';
import AuthInfo from './AuthInfo';
import LoginForm from './LoginForm';

type RouteParams = {
  code?: string;
};

const EmailVerificationPage = observer(() => {
  const {code = ''} = useParams<RouteParams>();
  const navigate = useNavigate();
  const {userStore} = useStore();
  const {t} = useTranslation();

  const [responseStatus, setResponseStatus] = React.useState<entities.ConfirmCredsResult | null | undefined>();
  const [apiError, setApiError] = React.useState<string | null | undefined>('');

  React.useEffect(() => {
    if (!userStore.api.connectionIsOpen || !userStore.isLoggedIn) {
      return;
    }

    const verifyEmail = async () => {
      const {error, res} = await userStore.confirmEmailByVerificationCode(code);

      if (res && !res?.status && !error) {
        navigate(Paths.EmailVerificationComplete);
      }

      if (error) {
        setApiError(error.message);
      }

      if (res?.status) {
        const statusErrorMessage = getUserResponseStatusError('confirmEmail', res?.status);
        setApiError(statusErrorMessage);
        setResponseStatus(res?.status);
      }
    };

    verifyEmail();
  }, [
    userStore,
    code,
    userStore.api.connectionIsOpen,
    userStore.isLoggedIn,
    navigate,
  ]);

  return (
    <Div100vh className="auth-page">
      <div className="auth-page__left-side">
        <AuthInfo />
      </div>

      <div className="auth-page__right-side">
        <div className="auth-page__title">{userStore.isLoggedIn ? t('email_verify_title') : t('email_verify_title_login_required')}</div>
        <div className="auth-page__form mb-auto">
          {userStore.loading ? (
            <div
              className="d-flex justify-content-center mt-5"
              style={{
                height: '50px',
              }}
            >
              <CircularProgress
                size={36}
                style={{
                  color: 'var(--brand-primary)',
                }}
              />
            </div>
          ) : userStore.isLoggedIn && responseStatus === entities.ConfirmCredsResult.CS_INVALID_CODE ? (
            <Button
              className="w-100 mb-5"
              component={Link}
              to={Paths.Logout}
              variant="contained"
              color="primary"
              size="large"
            >
              {t('email_verify_logout')}
            </Button>
          ) : userStore.isLoggedIn && responseStatus === entities.ConfirmCredsResult.CS_ALREADY_CONFIRMED ? (
            <Button
              className="w-100 mb-5"
              component={Link}
              to={Paths.Default}
              variant="contained"
              color="primary"
              size="large"
            >
              {t('email_verify_back_to_home')}
            </Button>
          ) : !userStore.isLoggedIn ? (
            <LoginForm
              className="mb-auto"
              hideSignUpLink
              hideForgoPasswordtLink
            />
          ) : !apiError ? (
            <Button
              className="w-100 mb-5"
              component={Link}
              to={Paths.Login}
              variant="contained"
              color="primary"
              size="large"
            >
              {t('email_verify_back_to_login')}
            </Button>
          ) : null}
          <FormHelperText className="mb-5 mb-auto" error>
            {apiError || ' '}
          </FormHelperText>
        </div>
      </div>
    </Div100vh>
  );
});

export default EmailVerificationPage;
