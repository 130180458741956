import Long from 'long';

import {
  IMCMessage,
  IUpdate,
  IUpdateChangedReadPointer,
  IUpdateChatChangedRestrictions,
  IUpdateNewMessageSuggestion,
  IUpdateOperatorAssignWidget,
  MCMethodGetChatsPreview,
  entities,
} from '../../api/proto';
import {equalUint8Arrays} from '../../utils/arrayUtils';
import {ChannelsUpdaterEvent} from '../Channel/ChannelsUpdater';
import chatIsUnread from '../Chat/utils/chatIsUnread';
import {convertToMessagePreview} from '../Message/utils';
import {RawMessage} from '../RawMessagesStore';
import WorkspaceStore from '../Workspaces/WorkspaceStore';
import {IRawChat} from './RawChat';
import RawChatsBase from './RawChatsBase';
import RawChatsStoreMode from './RawChatsStoreMode';
import getRawChatFromUpdateEntities from './utils/getRawChatFromUpdateEntities';

export class RawChatsStore extends RawChatsBase {
  constructor(
    protected workspace: WorkspaceStore,
    public channelIDs: Uint8Array[],
    public storeKey: string,
    public mode?: RawChatsStoreMode,
    public status?: MCMethodGetChatsPreview.MCChatStatus,
    public type?: entities.OzekonChannelType | null,
    public userId?: Long | null,
  ) {
    super(workspace, channelIDs, storeKey, mode);

    workspace.updater.on(ChannelsUpdaterEvent.UPDATE, this.processNewUpdate_);
    workspace.updater.on(ChannelsUpdaterEvent.NEW_OUTGOING_MESSAGE, this.processOutgoingMessage_);
    workspace.updater.on(ChannelsUpdaterEvent.REFRESH_CHAT, this.processRefreshChat_);
  }

  private get withAssignmentStatus(): boolean {
    return (
      this.status === MCMethodGetChatsPreview.MCChatStatus.S_IN_PROGRESS ||
      this.status === MCMethodGetChatsPreview.MCChatStatus.S_RESOLVED
    );
  }

  private assignmentEnabled = (rawChat: IRawChat): boolean => {
    return !!this.workspace.channels.findChannel(rawChat?.channelID)?.assignmentEnabled;
  };

  private get isUnreadOnly() {
    return (
      this.mode &&
      [
        RawChatsStoreMode.UNREAD_ONLY,
        RawChatsStoreMode.ASSIGNED_TO_ME_UNREAD_ONLY,
        RawChatsStoreMode.ASSIGNED_TO_OTHER_UNREAD_ONLY,
      ].includes(this.mode)
    );
  }

  private get isAssignedToMeOnly() {
    return (
      this.mode &&
      [RawChatsStoreMode.ASSIGNED_TO_ME_ONLY, RawChatsStoreMode.ASSIGNED_TO_ME_UNREAD_ONLY].includes(this.mode)
    );
  }

  private get isAssignedToOtherOnly() {
    return (
      this.mode &&
      [RawChatsStoreMode.ASSIGNED_TO_OTHER_ONLY, RawChatsStoreMode.ASSIGNED_TO_OTHER_UNREAD_ONLY].includes(this.mode)
    );
  }

  private processNewUpdate_ = async (update: IUpdate) => {
    if (!this.isInit_ || !this.includeChannel(update.channelID)) {
      return;
    }
    console.debug(`%c-->processNewUpdate_ ${this.storeKey}`, 'color: #afb0b6', update);

    if (update.updateNewChatPreview?.chat) {
      await this.processNewChatPreview_(update.updateNewChatPreview?.chat, update);
    }

    if (update.updateChangedReadPointer?.chatID) {
      await this.processReadPointer_(update.updateChangedReadPointer, update.channelID);
    }

    if (update.updateNewMessage?.message) {
      await this.processMessage_(update.updateNewMessage.message, update, update.updateNewMessage.randomID);
    }

    if (update.updateEditedMessage?.message) {
      await this.processMessage_(update.updateEditedMessage.message, update, null);
    }

    if (update.updateChatChangedRestrictions) {
      this.processChatRestrictions_(update.updateChatChangedRestrictions);
    }

    if (update.updateOperatorAssignWidget) {
      this.processOperatorAssignWidget_(update.updateOperatorAssignWidget);
    }

    if (update.updateNewMessageSuggestions) {
      this.processNewMessageSuggestions_(update.updateNewMessageSuggestions, update.channelID);
    }
  };

  protected findLocally_ = (chatID?: Long | null, channelID?: Uint8Array | null): IRawChat | null => {
    if (!chatID) {
      return null;
    }

    let foundRawChat = this.find(chatID);

    if (!foundRawChat) {
      foundRawChat = this.workspace.chatsPool.find(chatID, channelID);
    }

    return foundRawChat;
  };

  protected getRemote_ = async (chatID?: Long | null, channelID?: Uint8Array | null): Promise<IRawChat | null> => {
    console.debug(`%c-->RawChatsStore.getRemote_: ${chatID?.toString()}`, 'color: #afb0b6');
    if (!chatID) {
      return null;
    }

    return await this.workspace.chatsPool.get(chatID, channelID);
  };

  protected findGlobally_ = async (chatID?: Long | null, channelID?: Uint8Array | null): Promise<IRawChat | null> => {
    return this.findLocally_(chatID, channelID) || await this.getRemote_(chatID, channelID);
  };

  private processOperatorAssignWidget_ = ({chatID, operatorID, unassigned}: IUpdateOperatorAssignWidget) => {
    if (!operatorID) {
      return;
    }

    const foundRawChat = this.find(chatID);

    if (!foundRawChat) {
      console.debug(
        `%c-->RawChatsStore.processOperatorAssignWidget - not found ${this.storeKey}`,
        'color: #afb0b6',
        foundRawChat,
      );
      return;
    }
    console.debug(
      `%c-->RawChatsStore.processOperatorAssignWidget - ${this.storeKey}`,
      'color: #afb0b6',
      foundRawChat,
    );

    const joinedOperators = unassigned
      ? foundRawChat.joinedOperators?.filter((id) => operatorID.notEquals(id))
      : foundRawChat.joinedOperators?.concat([operatorID]);

    this.updateChat_({
      ...foundRawChat,
      joinedOperators,
    });
  };

  private processChatRestrictions_ = ({chatID, restrictions}: IUpdateChatChangedRestrictions) => {
    const foundRawChat = this.find(chatID);

    if (!foundRawChat) {
      console.debug(
        `%c-->RawChatsStore.processChatRestrictions - not found ${this.storeKey}`,
        'color: #afb0b6',
        foundRawChat,
      );
      return;
    }

    this.updateChat_({
      ...foundRawChat,
      restrictions,
    });
  };

  private processMessage_ = async (rawMessage: IMCMessage, update: IUpdate, randomID?: Long | null) => {
    if (!rawMessage.chatID || !rawMessage.localID) {
      return;
    }
    console.debug(
      `%c-->RawChatsStore.processMessage ${this.storeKey}`,
      'color: #afb0b6',
      rawMessage,
      randomID,
    );

    const localRawChat = this.findLocally_(rawMessage.chatID, update.channelID);
    const foundRawChat = localRawChat || await this.getRemote_(rawMessage.chatID, update.channelID);

    if (!foundRawChat) {
      console.debug(
        `%c-->RawChatsStore.processMessage - not found ${this.storeKey}`,
        'color: #afb0b6',
        rawMessage,
        randomID,
      );
      return;
    }

    if (!this.isSuitable_(foundRawChat)) {
      console.debug(
        `%c-->RawChatsStore.processMessage - not suitable ${this.storeKey}`,
        'color: #afb0b6',
        rawMessage,
        randomID,
      );
      return;
    }

    if (!this.isSuitableByStatus_(foundRawChat)) {
      console.debug(
        `%c-->RawChatsStore.processMessage - not suitable by status ${this.storeKey}`,
        'color: #afb0b6',
        rawMessage,
        randomID,
        foundRawChat,
      );
      return;
    }

    const assignmentEnabled = this.assignmentEnabled(foundRawChat);
    if (assignmentEnabled && !this.isSuitableByAssignment_(foundRawChat)) {
      console.debug(
        `%c-->RawChatsStore.processMessage - not suitable by assignment ${this.storeKey}`,
        'color: #afb0b6',
        rawMessage,
        randomID,
      );
      return;
    }

    if (!foundRawChat.lastMessage?.localID || !rawMessage.localID.greaterThanOrEqual(foundRawChat.lastMessage.localID)) {
      return;
    }

    if (rawMessage.peer && !rawMessage.serviceMessage) {
      this.incTotalUnreadCount(1);
    }

    console.debug(
      `%c-->RawChatsStore.processMessage -> updateChatPartially ${this.storeKey}`,
      'color: #afb0b6',
      rawMessage,
      randomID,
    );

    let messagesExcludedFromUnreadRange: Long | null | undefined = null;
    const isOperatorsMessage = rawMessage.operatorID?.greaterThan(0);
    const isOwnMessage = this.userId && rawMessage.operatorID?.equals(this.userId);
    if (rawMessage.serviceMessage || isOperatorsMessage) {
      if (localRawChat && (!isOwnMessage || rawMessage.serviceMessage)) {
        messagesExcludedFromUnreadRange = (foundRawChat.messagesExcludedFromUnreadRange || Long.ZERO).add(1);
        console.debug(
          `%c-->RawChatsStore.processMessage -> increased +1 messagesExcludedFromUnreadRange=${messagesExcludedFromUnreadRange.toNumber()} ${this.storeKey}`,
          'color: orange',
          rawMessage,
          randomID,
        );
      } else {
        messagesExcludedFromUnreadRange = foundRawChat.messagesExcludedFromUnreadRange;
      }
    }

    this.updateChatPartially_(
      messagesExcludedFromUnreadRange ? {
        id: foundRawChat.id,
        lastMessage: convertToMessagePreview(rawMessage),
        lastMessageSentAt: rawMessage.sentAt,
        messagesExcludedFromUnreadRange,
      } : {
        id: foundRawChat.id,
        lastMessage: convertToMessagePreview(rawMessage),
        lastMessageSentAt: rawMessage.sentAt,
      },
      foundRawChat,
    );
  };

  private processNewChatPreview_ = async (newChatPreview: IRawChat, update: IUpdate) => {
    if (!newChatPreview.id || !this.includeChannel(newChatPreview.channelID)) {
      return;
    }
    console.debug(
      `%c-->RawChatsStore.processNewChatPreview ${this.storeKey} has=${this.has(newChatPreview.id)}`,
      'color: #afb0b6',
      newChatPreview,
    );

    if (this.has(newChatPreview.id)) {
      const rawChat = getRawChatFromUpdateEntities(update, newChatPreview.id);

      const needUpdate =
        (this.isSuitableByStatus_(newChatPreview) || this.isSuitableByStatus_(rawChat)) &&
        (this.isSuitableByAssignment_(newChatPreview) || this.isSuitableByAssignment_(rawChat));

      if (needUpdate) {
        this.updateChat_(newChatPreview);
      } else {
        console.debug(
          `%c--->RawChatsStore.processNewChatPreview_ -> removeChat_ ${this.storeKey}`,
          'color: #afb0b6',
        );
        this.removeChat_(newChatPreview);
      }
    } else if (this.mode !== RawChatsStoreMode.SEARCH) {
      const foundRawChat = await this.findGlobally_(newChatPreview.id, newChatPreview.channelID);

      if (!foundRawChat) {
        console.debug(
          `%c-->RawChatsStore.processNewChatPreview - not found ${this.storeKey}`,
          'color: #afb0b6',
          newChatPreview,
        );
        return;
      }

      if (!this.isSuitable_(foundRawChat)) {
        console.debug(
          `%c-->RawChatsStore.processNewChatPreview - not suitable --> addNewChat ${this.storeKey} foundRawChat:`,
          'color: #afb0b6',
          foundRawChat,
        );
        return;
      }

      if (
        !(
          this.assignmentEnabled(newChatPreview) &&
          this.isSuitableByStatus_(newChatPreview) &&
          this.isSuitableByAssignment_(newChatPreview)
        )
      ) {
        console.debug(
          `%c-->RawChatsStore.processNewChatPreview - assignment not suitable --> addNewChat ${this.storeKey} foundRawChat:`,
          'color: #afb0b6',
          foundRawChat,
        );
        return;
      }

      console.debug(
        `%c-->RawChatsStore.processNewChatPreview --> addNewChat ${this.storeKey} foundRawChat:`,
        'color: #afb0b6',
        foundRawChat,
      );

      this.addNewChat_({
        ...foundRawChat,
        ...newChatPreview,
        inReadPointer: foundRawChat?.inReadPointer,
        messagesExcludedFromUnreadRange: foundRawChat?.messagesExcludedFromUnreadRange,
      });
    }
  };

  private updateTotalUnreadCount_ = (changedReadPointer?: IUpdateChangedReadPointer | null) => {
    console.debug(
      `%c--->RawChatsStore.updateTotalUnreadCount_ ${this.storeKey}`,
      'color: purple',
      changedReadPointer,
    );

    const messagesExcludedFromUnreadRange = changedReadPointer?.messagesExcludedFromUnreadRange || Long.ZERO;
    const previousInReadPointer = changedReadPointer?.previousInReadPointer || Long.ZERO;
    const inReadPointer = changedReadPointer?.inReadPointer || Long.ZERO;
    const unreadDiff = inReadPointer.sub(previousInReadPointer).sub(messagesExcludedFromUnreadRange).toNumber();
    // const unreadDiff = (changedReadPointer?.inReadPointer || Long.ZERO).sub(foundRawChat.inReadPointer || Long.ZERO).toNumber();

    if (unreadDiff > 0) {
      this.decTotalUnreadCount(unreadDiff);
    }
  };

  private processReadPointer_ = async (
    changedReadPointer?: IUpdateChangedReadPointer | null,
    channelID?: Uint8Array | null,
  ) => {
    if (!changedReadPointer) {
      return;
    }

    const isCurrentUserUpdate = !changedReadPointer.operatorID || this.userId?.equals(changedReadPointer.operatorID);

    const isCurrentUserInReadPointer = !!changedReadPointer?.inReadPointer?.greaterThan(0) && isCurrentUserUpdate;
    const isCurrentUserOutReadPointer = !!changedReadPointer?.outReadPointer?.greaterThan(0);

    if (!isCurrentUserInReadPointer && !isCurrentUserOutReadPointer) {
      return;
    }
    console.debug(
      `%c--->RawChatsStore.processReadPointer_ ${this.storeKey}`,
      'color: #afb0b6',
      changedReadPointer,
    );

    const foundRawChat = await this.findGlobally_(changedReadPointer.chatID, channelID);

    if (!foundRawChat) {
      console.debug(
        `%c--->RawChatsStore.processReadPointer - not found ${this.storeKey}`,
        'color: #afb0b6',
        changedReadPointer,
      );
      return;
    }

    if (
      isCurrentUserInReadPointer &&
      !this.isSuitable_({...foundRawChat, inReadPointer: changedReadPointer?.inReadPointer})
    ) {
      console.debug(
        `%c--->RawChatsStore.processReadPointer -> removeChat_- not suitable by inReadPointer ${this.storeKey}`,
        'color: red',
        changedReadPointer,
      );
      /*
      if (isCurrentUserInReadPointer) {
        this.updateTotalUnreadCount_(changedReadPointer);
      }
      */
      this.removeChat_(foundRawChat);
      return;
    }

    if (!this.isSuitable_(foundRawChat)) {
      console.debug(
        `%c--->RawChatsStore.processReadPointer -> removeChat_- not suitable ${this.storeKey}`,
        'color: red',
      );
      this.removeChat_(foundRawChat);
      return;
    }

    if (
      this.withAssignmentStatus &&
      (
        !this.assignmentEnabled(foundRawChat) ||
        !this.isSuitableByStatus_(foundRawChat) ||
        !this.isSuitableByAssignment_(foundRawChat)
      )
    ) {
      console.debug(
        `%c--->RawChatsStore.processReadPointer -> removeChat_ - not suitable by status or assignment ${this.storeKey}`,
        'color: red',
      );
      /*
      if (isCurrentUserInReadPointer) {
        this.updateTotalUnreadCount_(changedReadPointer);
      }
      */
      this.removeChat_(foundRawChat);
      return;
    }

    if (foundRawChat && isCurrentUserInReadPointer && this.isSuitableByStatus_(foundRawChat)) {
      this.updateTotalUnreadCount_(changedReadPointer);
    }

    if (isCurrentUserInReadPointer && isCurrentUserOutReadPointer) {
      console.debug(
        `%c--->RawChatsStore.processReadPointer_ -> updateChatPartially ${this.storeKey}`,
        'color: #afb0b6',
      );
      this.updateChatPartially_(
        {
          id: foundRawChat.id,
          inReadPointer: changedReadPointer?.inReadPointer,
          outReadPointer: changedReadPointer?.outReadPointer,
          messagesExcludedFromUnreadRange: changedReadPointer.messagesExcludedFromUnreadRange,
        },
        foundRawChat,
        true,
      );
    } else if (isCurrentUserInReadPointer) {
      console.debug(
        `%c--->RawChatsStore.processReadPointer_ -> updateChatPartially ${this.storeKey}`,
        'color: #afb0b6',
      );
      this.updateChatPartially_(
        {
          id: foundRawChat.id,
          inReadPointer: changedReadPointer?.inReadPointer,
          messagesExcludedFromUnreadRange: changedReadPointer.messagesExcludedFromUnreadRange,
        },
        foundRawChat,
        true,
      );
    } else if (isCurrentUserOutReadPointer) {
      console.debug(
        `%c--->RawChatsStore.processReadPointer_ -> updateChatPartially ${this.storeKey}`,
        'color: #afb0b6',
      );
      this.updateChatPartially_(
        {
          id: foundRawChat.id,
          outReadPointer: changedReadPointer?.outReadPointer,
        },
        foundRawChat,
        true,
      );
    }
  };

  private processOutgoingMessage_ = async (newRawMessage: RawMessage, channelID?: Uint8Array | null) => {
    if (!channelID || !this.includeChannel(channelID)) {
      return;
    }

    const foundRawChat = await this.findGlobally_(newRawMessage.chatID, channelID);
    if (!foundRawChat) {
      console.debug(
        `%c--->RawChatsStore.processOutgoingMessage_ - chat not found ${this.storeKey}`,
        'color: #afb0b6',
        newRawMessage,
      );
      return;
    }

    if (!this.isSuitable_(foundRawChat)) {
      console.debug(
        `%c--->RawChatsStore.processOutgoingMessage_ - chat not suitable ${this.storeKey}`,
        'color: #afb0b6',
        newRawMessage,
      );
      return;
    }

    if (
      this.assignmentEnabled(foundRawChat) &&
      this.isSuitableByStatus_(foundRawChat) &&
      this.isSuitableByAssignment_(foundRawChat)
    ) {
      console.debug(
        `%c--->RawChatsStore.processOutgoingMessage_ - not suitable by status or assignment ${this.storeKey}`,
        'color: #afb0b6',
        newRawMessage,
      );
      return;
    }

    console.debug(
      `%c--->RawChatsStore.processOutgoingMessage_ ${this.storeKey}`,
      'color: #afb0b6',
      newRawMessage,
    );

    this.updateChatPartially_(
      {
        id: foundRawChat.id,
        inReadPointer: newRawMessage.localID,
      },
      foundRawChat,
      true,
    );
  };

  private processRefreshChat_ = (rawChat: IRawChat) => {
    if (!rawChat.id || !this.includeChannel(rawChat.channelID)) {
      return;
    }
    console.debug(
      `%c-->RawChatsStore.processRefreshChat_ - has=${this.has(rawChat.id)} ${this.storeKey}`,
      'color: #afb0b6',
      rawChat,
    );

    if (!this.isSuitable_(rawChat) || !this.has(rawChat.id)) {
      return;
    }

    this.updateChat_(rawChat);
  };

  private processNewMessageSuggestions_ = async (newMessageSuggestions: IUpdateNewMessageSuggestion, channelID?: Uint8Array | null) => {
    console.debug(
      `%c--->RawChatsStore.processNewMessageSuggestions_ ${this.storeKey}`,
      'color: #afb0b6',
      newMessageSuggestions.suggestion,
    );

    const foundRawChat = await this.findGlobally_(newMessageSuggestions.chatID, channelID);

    if (!foundRawChat) {
      console.debug(
        `%c--->RawChatsStore.processNewMessageSuggestions_ - not found ${this.storeKey}`,
        'color: #afb0b6',
        newMessageSuggestions,
      );
      return;
    }

    console.debug(
      `%c--->RawChatsStore.processNewMessageSuggestions_ -> updateChatPartially ${this.storeKey}`,
      'color: #afb0b6',
    );
    this.updateChatPartially_(
      {
        id: foundRawChat.id,
        nextOperatorMessageSuggestion: newMessageSuggestions?.suggestion,
      },
      foundRawChat,
      true,
    );
  }

  private isSuitable_ = (rawChat?: IRawChat | null): boolean => {
    if (!rawChat) {
      return false;
    }

    if (this.isUnreadOnly && !chatIsUnread(rawChat)) {
      return false;
    }

    return true;
  };

  private isSuitableByStatus_ = (rawChat?: IRawChat | null): boolean => {
    return (
      this.status === MCMethodGetChatsPreview.MCChatStatus.S_ALL ||
      rawChat?.status === this.status
    );
  };

  private isSuitableByAssignment_ = (rawChat?: IRawChat | null): boolean => {
    if (this.status !== MCMethodGetChatsPreview.MCChatStatus.S_IN_PROGRESS) {
      return true;
    }

    const isAssigned = rawChat?.joinedOperators?.some((id) => this.userId?.equals(id));

    if (this.isAssignedToMeOnly && isAssigned) {
      return true;
    }

    if (this.isAssignedToOtherOnly && !isAssigned) {
      return true;
    }

    return false;
  };


  public markAllAsRead = (channelID?: Uint8Array | null) => {
    if (!channelID || !this.includeChannel(channelID)) {
      return;
    }

    if (this.isUnreadOnly && this.equalsChannel(channelID)) {
      this.clear();
      return;
    }

    if (this.isUnreadOnly) {
      this.filterChats_((rawChat) => !equalUint8Arrays(rawChat.channelID, channelID));
      return;
    }

    this.rawChats_.forEach((rawChat) => {
      if (chatIsUnread(rawChat)) {
        this.updateChatPartially_(
          {
            id: rawChat.id,
            inReadPointer: rawChat.lastMessage?.localID || Long.ZERO,
          },
          rawChat,
          true,
        );
      }
    });
  };
}

export default RawChatsStore;
