import cn from 'classnames';
import React from 'react';

interface VolumeControlProps {
  className?: string;
  volume?: number;
  onChange?: (volume: number) => void;
  mode?: 'static' | 'collapsible';
  orient?: 'horizontal' | 'vertical';
  color?: 'brand' | 'gray';
}

export default function VolumeControl({
  className,
  volume = 0,
  onChange,
  mode = 'static',
  orient = 'horizontal',
  color = 'brand',
}: VolumeControlProps) {
  const handleChangeVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onChange?.(Number(e.target.value));
  };

  const handleToggleClick = () => {
    onChange?.(volume ? 0 : 100);
  };

  const handleTouchEvent = (e: React.TouchEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div
      className={cn(
        className,
        'volume-control',
        `volume-control--${mode}`,
        `volume-control--${orient}`,
        `volume-control--${color}`,
      )}
    >
      <button
        color="none"
        type="button"
        className={cn('volume-control__btn tg-icon', {
          'tg-icon-volume-0-fill': !volume,
          'tg-icon-volume-1-fill': volume > 0 && volume < 50,
          'tg-icon-volume-2-fill': volume >= 50 && volume < 100,
          'tg-icon-volume-3-fill': volume === 100,
        })}
        data-volume={volume}
        onClick={handleToggleClick}
      />
      <div className="volume-control__slider-wrapper">
        <input
          style={{backgroundSize: (volume * 100) / 100 + '% 100%'}}
          className="volume-control__slider"
          type="range"
          min="0"
          max="100"
          value={volume}
          step="1"
          onChange={handleChangeVolume}
          onTouchStart={handleTouchEvent}
          onTouchEnd={handleTouchEvent}
        />
      </div>
    </div>
  );
}
