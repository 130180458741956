import React from 'react';

import Tab, {TabProps} from 'o-ui/Tab';
import Badge from '../../../../../components/Badge';

interface ChatsFilterTabProps extends Omit<TabProps, 'label'> {
  children?: React.ReactNode;
  unreadCount?: number;
}

export const ChatsFilterTab = ({
  children,
  unreadCount,
  ...props
}: ChatsFilterTabProps) => {
  const badge = unreadCount && unreadCount >= 1000 ? `${unreadCount.toString().slice(-2)}+` : unreadCount?.toString();
  return (
    <Tab
      className="chats-list-filter__tab"
      label={(
        <span className="body2-bold d-flex align-items-center">
          {children}
          {unreadCount && badge ? (
            <Badge
              className="ml-2"
              text={badge}
              title={unreadCount?.toString()}
              color="error"
              variant="squared"
            />
          ) : null}
        </span>
      )}
      {...props}
    />
  );
};

export default ChatsFilterTab;
