import React, {useState} from 'react';
import cn from 'classnames';
import {ReactComponent as FileIconWhite} from '../assets/image-icons/fileIconWhite.svg';
import {ReactComponent as FileDownloadIcon} from '../assets/image-icons/fileDownloadIcon.svg';
import {ReactComponent as FileIconBlue} from '../assets/image-icons/fileIconBlue.svg';
import {ReactComponent as FileDownloadIconBlue} from '../assets/image-icons/fileDownloadIconBlue.svg';

interface IProps {
  className?: string;
  type?: string | null;
  url?: string | null;
  fileName?: string | null;
  small?: boolean;
  blue?: boolean;
  onClick?: () => void;
}

export const FileIcon: React.FC<IProps> = (props) => {
  const [hovered, setHovered] = useState<boolean>(false);
  
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onClick?.();
  };

  const fileIcon = props.blue ? <FileIconBlue /> : <FileIconWhite />;
  const downloadIcon = props.blue ? <FileDownloadIconBlue /> : <FileDownloadIcon />;

  if (props.url) {
    return (
      <a
        className={cn('file-icon d-flex text-decoration-none line-height-40 mr-5', props.className, {
          small: props.small,
        })}
        href={props.url}
        target="_blank"
        rel="noreferrer"
        download={props.fileName || true}
        onClick={handleClick}
        onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {hovered ? downloadIcon : fileIcon}
      </a>
    );
  }

  return (
    <div
      className={cn(
        'file-icon d-flex line-height-40 mr-5',
        props.className,
        {
          small: props.small,
          'cursor-pointer': props.onClick,
        },
      )}
      onClick={handleClick}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {props.onClick && hovered ? downloadIcon : fileIcon}
    </div>
  );
};

export default FileIcon;
