import React from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../stores/AppStore';

const Logout = observer(() => {
  const {userStore} = useStore();

  React.useEffect(() => {
    userStore.logout();
  }, [userStore]);

  return <React.Fragment />;
});

export default Logout;
