import React from 'react';
import {useTranslation} from 'react-i18next';
import {ModalItem} from '../stores/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';
import {entities} from '../api/proto';
import TelegramCreationForm from '../pages/Settings/Channels/TelegramCreationForm';
import Channel from '../stores/Channel';

interface ModalChannelReactivationProps {
  modal: ModalItem;
}

export const ModalChannelReactivation = (props: ModalChannelReactivationProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);
  const channel = props.modal.data?.channel;
  const codeHash = props.modal.data?.codeHash;

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  const getReactivationForm = (channel: Channel) => {
    switch (channel.type) {
      case entities.OzekonChannelType.OIT_TELEGRAM:
        return (
          <TelegramCreationForm
            type={entities.OzekonChannelType.OIT_TELEGRAM}
            onClose={handleCloseClick}
            newChannel={channel}
            codeHash={codeHash}
            reactivationMode
          />
        );

      default:
        return null;
    }
  };

  return (
    <ModalDialog ref={modalRef} modal={props.modal} title={t('modal_channel_reactivation_title')}>
      {channel ? getReactivationForm(channel) : null}
    </ModalDialog>
  );
};

export default ModalChannelReactivation;
