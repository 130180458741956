import React from 'react';
import {NavigateOptions, generatePath, useNavigate} from 'react-router';

import {WorkspacePathParams, WorkspacePathType} from '../routes/Paths';
import {useStore} from '../stores/AppStore';
import {uint8ArrayToBase64} from '../utils/arrayUtils';

export const useWorkspaceNavigate = () => {
  const {activeWorkspace} = useStore();
  const navigate = useNavigate();

  const workspaceNavigate = React.useCallback(
    (path: WorkspacePathType, options?: NavigateOptions, params?: WorkspacePathParams) => {
      navigate(
        generatePath(path, {
          workspaceId: encodeURIComponent(uint8ArrayToBase64(activeWorkspace.id)),
          tariffId: null,
          channelType: null,
          channelScreenType: null,
          ...params,
        }),
        options,
      );
    },
    [
      navigate,
      activeWorkspace.id,
    ],
  );

  return workspaceNavigate;
};

export default useWorkspaceNavigate;
