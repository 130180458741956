import React from 'react';
import cn from 'classnames';

interface BadgeProps extends React.PropsWithChildren {
  className?: string;
  text?: string;
  title?: string;
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info';
  variant?: 'circle' | 'squared';
}

export default function Badge({
  className,
  text,
  title,
  color,
  variant,
}: BadgeProps) {
  return (
    <span
      className={cn(
        'badge',
        `digits-${text?.toString().length}`,
        color,
        variant,
        className,
      )}
      title={title}
    >
      {text}
    </span>
  );
}
