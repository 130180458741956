import Image, {ImageProps} from 'o-ui/Image';
import React from 'react';
import {ReactZoomPanPinchContentRef, TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';
import useWindowInnerHeight from '../../hooks/useWindowInnerHeight';
import minScale from '../../utils/image/minScale';

export interface ImageTransformerProps extends ImageProps {
  rotateDeg?: number | null;
  transformerClassName?: string;
}

export const ImageTransformer: React.FC<ImageTransformerProps> = (props) => {
  const {rotateDeg, transformerClassName, style, ...other} = props;
  const innerHeight = useWindowInnerHeight();
  const transformWrapperRef = React.useRef<ReactZoomPanPinchContentRef>(null);

  const initialScale = minScale(window.innerWidth * 0.8, innerHeight * 0.8, other.width, other.height);

  const imageStyle = rotateDeg ? {transform: `rotate(${rotateDeg}deg)`} : undefined;

  React.useEffect(() => {
    function handleResize() {
      transformWrapperRef.current?.centerView();
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [innerHeight, other.width, other.height]);

  return (
    <TransformWrapper
      ref={transformWrapperRef}
      zoomAnimation={{disabled: true}}
      centerOnInit={true}
      initialScale={initialScale}
      minScale={initialScale}
    >
      {() => (
        <TransformComponent wrapperClass={transformerClassName}>
          <Image style={{...imageStyle, ...style}} useCache={true} {...other} />
        </TransformComponent>
      )}
    </TransformWrapper>
  );
};

export default ImageTransformer;
