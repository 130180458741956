export interface ITextSelection {
  offset: number;
  length: number;
  text: string;
}

export const getTextSelection = (): ITextSelection | null => {
  let selection: Selection | null = null;
  let selectedText: string | null = '';

  if (window.getSelection) {
    selection = window.getSelection() || null;
    selectedText = selection?.toString() || null;
  }

  if (selection && selectedText) {
    const startIndex = selection.anchorNode
      ? calculateOffset(selection.anchorNode, selection.anchorOffset)
      : selection.anchorOffset;

    return {
      offset: startIndex || 0,
      length: selectedText.length,
      text: selectedText,
    };
  }

  return null;
};

function calculateOffset(child: Node, relativeOffset: number, rootTagName = 'pre') {
  let parent = child.parentElement;
  let nextChild = child;
  const children: ChildNode[] = [];

  if (parent && parent.tagName.toLocaleUpperCase() !== rootTagName.toLocaleUpperCase()) {
    parent = parent.closest(rootTagName);
    if (nextChild.parentElement) {
      nextChild = nextChild.parentElement;
    }
  }

  if (parent && parent.tagName !== rootTagName) {
    for (const c of parent.childNodes) {
      if (c === nextChild) break;
      children.push(c);
    }
  }

  // calculate the total text length of all the preceding siblings and increment with the relative offset
  return relativeOffset + children.reduce((a, c) => a + (c.textContent?.length || 0), 0);
}
