import React from 'react';
import cn from 'classnames';
import {Accept, useDropzone} from 'react-dropzone';
import {DropzoneBox} from './DropzoneBox';
import {useTranslation} from 'react-i18next';
import {isImageFile, isVideoFile} from '../../../utils/file/fileReaders';
import {isSafariBrowser} from '../../../utils/browser-detect';

export interface IDropzoneProps {
  children?: React.ReactNode;
  className?: string;
  accept?: Accept;
  noClick?: boolean;
  noKeyboard?: boolean;
  onChange?: (files: File[], compressImages?: boolean) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
}

export const Dropzone: React.FC<IDropzoneProps> = (props) => {
  const {t} = useTranslation();
  const [showDropZone, setShowDropZone] = React.useState<boolean>(false);

  const [title, setTitle] = React.useState<string>('');
  const [countFiles, setCountFiles] = React.useState<number>(0);
  const [countPhotoFiles, setCountPhotoFiles] = React.useState<number>(0);
  const [countVideoFiles, setCountVideoFiles] = React.useState<number>(0);

  const onDrop = () => {
    setShowDropZone(false);
  };

  const onDragEnter = (e) => {
    const items = e.dataTransfer.items;

    let photoCount = 0;
    let videoCount = 0;

    for (const item of items) {
      if (isImageFile(item.type)) {
        photoCount++;
      }

      if (isVideoFile(item.type)) {
        videoCount++;
      }
    }

    setCountFiles(items.length);
    setCountPhotoFiles(photoCount);
    setCountVideoFiles(videoCount);

    setShowDropZone(true);
  };

  const onDragLeave = () => {
    setShowDropZone(false);
  };

  const {getRootProps, getInputProps} = useDropzone({
    noClick: props.noClick,
    onDrop,
    onDragEnter,
    onDragLeave,
    accept: props.accept,
    noKeyboard: props.noKeyboard,
    disabled: props.disabled,
  });

  React.useEffect(() => {
    if (countFiles === countPhotoFiles) {
      setTitle(t('Drop images here'));
    } else if (countFiles === countVideoFiles) {
      setTitle(t('Drop videos here'));
    } else {
      setTitle(countFiles > 1 ? t('Drop files here') : t('Drop file here'));
    }
  }, [countFiles, countVideoFiles, countPhotoFiles, t]);

  const handleChange = (files: File[], compressImages?: boolean) => {
    if (props.disabled) {
      return;
    }
    props.onChange && props.onChange(files, compressImages);
  };

  return (
    <div
      {...getRootProps({
        className: cn(props.className),
      })}
    >
      <input {...getInputProps()} disabled={props.disabled} />

      {showDropZone ? (
        <div className="dropzone__inner">
          {isSafariBrowser() ? (
            <DropzoneBox
              title={t('Drop files here')}
              description={t('to send them in a quick way')}
              onChange={props.onChange}
              accept={props.accept}
              noClick={props.noClick}
              disabled={props.disabled}
            />
          ) : countFiles === countPhotoFiles || countFiles === countVideoFiles ? (
            <>
              <DropzoneBox
                title={title}
                description={t('to send them without compression')}
                onChange={(e) => handleChange(e, false)}
                accept={props.accept}
                noClick={props.noClick}
                disabled={props.disabled}
              />
              <DropzoneBox
                title={title}
                description={t('to send them in a quick way')}
                onChange={(e) => handleChange(e, true)}
                accept={props.accept}
                noClick={props.noClick}
                disabled={props.disabled}
              />
            </>
          ) : (
            <DropzoneBox
              title={title}
              description={t('to send them in a quick way')}
              onChange={props.onChange}
              accept={props.accept}
              noClick={props.noClick}
              disabled={props.disabled}
            />
          )}
        </div>
      ) : null}
      {props.children}
    </div>
  );
};

export default Dropzone;
