import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Channel from '../../../../../stores/Channel';
import WorkspaceMemberAccessRecord from '../../../../../stores/Workspaces/WorkspaceMemberAccessRecord';
import WorkspaceMember from '../../../../../stores/Workspaces/WorkspaceMember';
import MembersAccessHeader from './MembersAccessHeader';
import MembersAccessItem from './MembersAccessItem';


interface MembersAccessProps {
  channel: Channel;
  members: WorkspaceMember[];
  onOpenAccessRecord?(member: WorkspaceMember, accessRecord: WorkspaceMemberAccessRecord): void;
  onChange?(changed: boolean, member: WorkspaceMember): void;
}

const MembersAccess: React.FC<MembersAccessProps> = observer(({
  channel,
  members,
  onOpenAccessRecord,
  onChange,
}) => {
  const {t} = useTranslation();

  const [filteredMembers, setFilteredMembers] = React.useState<WorkspaceMember[]>(members);

  const [filter, setFilter] = React.useState<string>('');

  const handleChangeFilter = (value: string) => {
    setFilter(value);
  };

  React.useEffect(() => {
    if (filter) {
      setFilteredMembers(
        members.filter((member) =>
          member?.fullName.toLowerCase().includes(filter.toLowerCase()) ||
          member?.email?.toLowerCase().includes(filter.toLowerCase()) ||
          member?.id?.toString().includes(filter.toLowerCase()) ||
          member?.userId?.toString().includes(filter.toLowerCase()),
        ),
      );
    } else {
      setFilteredMembers(members);
    }
  }, [filter, members]);

  return (
    <div className="channels-access">
      <MembersAccessHeader
        onSearch={handleChangeFilter}
      />

      <div className={cn('channels-access__list', {empty: !filteredMembers.length})}>
        {filteredMembers.map((member) => (
          <MembersAccessItem
            key={member.id?.toString()}
            channel={channel}
            member={member}
            onOpenAccessRecord={onOpenAccessRecord}
            onChange={onChange}
          />
        ))}

        {!filteredMembers.length ? (
          <div className="mb-3 body2-regular">{t('settings_channel_access_members_no_items_found')}</div>
        ) : null}
      </div>
    </div>
  );
});

export default MembersAccess;
