import Long from 'long';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {matchPath} from 'react-router';

import Avatar from 'o-ui/Avatar';
import {entities, MCChatPreview} from '../../../../api/proto';
import ChannelIcon, {AllChannelsIcon} from '../../../../components/ChannelIcon';
import {convertChatFromHistory} from '../../../../routes/browserHistory';
import Paths from '../../../../routes/Paths';
import getSettingsTabByPath from '../../../../stores/LayOutStore/utils/getSettingsTabByPath';
import {IRawChat} from '../../../../stores/RawChatsStore/RawChat';
import useStore from '../../../../stores/useStore';
import {base64ToUint8Array} from '../../../../utils/arrayUtils';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import isNil from '../../../../utils/isNil';
import unCamelCase from '../../../../utils/unCamelCase';
import {getSettingsMenuIcon} from '../../../Settings/SettingsMenu/SettingsMenuItemIcon';
import ChatChannelTypeIcon from '../../Tabs/ChatsTab/ChatItem/ChatChannelTypeIcon';
import ChatTypeIcon from '../../Tabs/ChatsTab/ChatItem/ChatTypeIcon';
import useAvatarAttachment from '../../useAvatarAttachment';
import NavigationBarButton from './NavigationBarButton';
import NavigationBarItemProps from './NavigationBarItemProps';

export const NavigationBarItem: React.FC<NavigationBarItemProps> = (props) => {
  const {t} = useTranslation();
  const {location} = props;
  const {userStore, channels, workspaces, lightThemeChosen} = useStore();

  let rawChat: IRawChat | null = location.state?.chat ? convertChatFromHistory(location.state.chat) : null;
  const avatarId: Uint8Array | null = location.state?.avatarId
    ? base64ToUint8Array(decodeURIComponent(location.state.avatarId))
    : null;
  const {src} = useAvatarAttachment({source: {reference: avatarId}}, rawChat?.channelID);

  const matchChat = matchPath(Paths.SpecificChat, location.pathname);
  if (matchChat?.params?.channelId && matchChat?.params?.chatId) {
    const channelId = base64ToUint8Array(matchChat.params.channelId);
    const chatId = Long.fromString(matchChat.params.chatId);

    const channel = channels.findChannel(channelId);

    if (!rawChat) {
      rawChat = workspaces.currentWorkspace.findRawChat(chatId, channelId);
    }

    if (!rawChat) {
      return null;
    }

    return (
      <NavigationBarButton {...props}>
        <Avatar className="mr-3" name={rawChat.name} size="xs" src={src} />
        <ChatTypeIcon className="font-size-16 mr-2" chatType={rawChat.type} />
        <span className="body3-bold text-truncate mr-2">{rawChat.name}</span>
        <ChatChannelTypeIcon className="chat-item__source-icon" type={channel?.type} />
      </NavigationBarButton>
    );
  }

  const matchUser = matchPath(Paths.UserChat, location.pathname);

  if (matchUser?.params?.channelId && matchUser?.params?.userId) {
    const {user} = userStore;
    return user ? (
      <NavigationBarButton {...props}>
        <Avatar className="mr-3" name={user?.fullName || user.email} size="xs" src={src} />
        <ChatTypeIcon className="font-size-16 mr-2" chatType={MCChatPreview.Type.PRIVATE} />

        <span className="body3-bold text-truncate mr-2">{user.fullName || user.email}</span>
        <ChatChannelTypeIcon className="chat-item__source-icon" type={entities.OzekonChannelType.OIT_TELEGRAM} />
      </NavigationBarButton>
    ) : null;
  }

  const matchChannel = matchPath(Paths.Channel, location.pathname);

  if (matchChannel?.params?.channelId) {
    const channelId = base64ToUint8Array(matchChannel.params.channelId);
    const channel = channels.findChannel(channelId);

    return channel ? (
      <NavigationBarButton {...props}>
        <ChannelIcon className="channels-menu__item-icon" type={channel.type} />
        <span className="body3-bold text-truncate mr-2">{channel?.name}</span>
      </NavigationBarButton>
    ) : null;
  }

  const matchAllChats = matchPath(Paths.Chat, location.pathname);

  if (matchAllChats) {
    return (
      <NavigationBarButton {...props}>
        <AllChannelsIcon className="channels-menu__item-icon" />
        <span className="body3-bold text-truncate mr-2">{t('chat_user_chat_side_menu_all_channels_title')}</span>
      </NavigationBarButton>
    );
  }

  const tab = getSettingsTabByPath(location.pathname);
  const Icon = !isNil(tab) ? getSettingsMenuIcon(tab, lightThemeChosen ? 'light' : 'dark') : null;

  const pathArr = location.pathname.split('/');
  const title = pathArr[pathArr.length - 1] || '';

  return (
    <NavigationBarButton {...props}>
      {Icon ? <Icon className="mr-3" /> : null}
      <span className="body3-bold text-truncate mr-2">
        {capitalizeFirstLetter(unCamelCase(title).toLocaleLowerCase())}
      </span>
    </NavigationBarButton>
  );
};

export default NavigationBarItem;
