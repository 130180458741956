import {useParams} from 'react-router';

export type PathParams = {
  workspaceId: string | null;
  code: string | null;
  messageId: string | null;
  chatId: string | null;
  channelId: string | null;
  channelType: string | null;
  channelScreenType: string | null;
  tariffId: string | null;
  userId: string | null;
  email: string | null;
}

const emptyParams: PathParams = {
  workspaceId: null,
  code: null,
  messageId: null,
  chatId: null,
  channelId: null,
  channelType: null,
  channelScreenType: null,
  tariffId: null,
  userId: null,
  email: null,
}

export function usePathParams(): PathParams {
  const rawParams = useParams();
  const params: PathParams = {...emptyParams};

  for (const name of Object.keys(rawParams)) {
    params[name] = rawParams[name] || null;
  }

  return params;
}

export default usePathParams;