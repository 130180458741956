import cn from 'classnames';
import React from 'react';
import {useInView} from 'react-intersection-observer';

import {useStore} from '../../../../stores/AppStore';
import Message from '../../../../stores/Message';
import SpecialMessageBody from './SpecialMessageBody';

interface SpecialMessageItemProps {
  message: Message;
  searchId?: string;
  isLastMessage?: boolean;
  messageRef?: (el: HTMLElement | null, message: Message) => void;
}

export const SpecialMessageItem = (props: SpecialMessageItemProps) => {
  const {page} = useStore();
  const {message} = props;

  const handleChangeVisibility = React.useCallback(
    (inView: boolean) => {
      if (inView) {
        message.chat.store?.addVisibleMessage(message);
      } else {
        message.chat.store?.deleteVisibleMessage(message);
      }
    },
    [message],
  );

  const {ref: inViewRef, inView} = useInView({
    threshold: 0,
    onChange: handleChangeVisibility,
  });

  React.useEffect(() => {
    // const isUnReadInbox = !message.isReadInbox && !message.isOwn;
    const isUnReadInbox = !message.isReadInbox; // Allow to read OWN service messages

    if (page.isObserving && inView && isUnReadInbox) {
      console.debug(`%c----->MessageItem-->readMessages read localID=${message.localID.toString()}`, 'color: red;');
      message.chat.store?.readMessages([message]);
    }
  }, [page.isObserving, inView, message]);

  return (
    <div
      ref={inViewRef}
      id={message.id.toString()}
      data-is-visible={inView.toString()}
      data-is-own={message.isOwn}
      className={cn(
        'message',
        'message--special',
        'center',
        {
        'found-message': message.id.toString() === props.searchId,
      })}
    >
      <div className="message__inner">
        <div className="message__content">
          <SpecialMessageBody message={message} />
        </div>
      </div>
    </div>
  );
};

export default SpecialMessageItem;
