import {MCMethodSendMessageResponse, MCMethodSendGroupedMessagesResponse} from '../../api/proto';
import {t} from '../../i18n';

export const getSendMessageError = (type?: MCMethodSendMessageResponse.Result | null): string => {
  switch (type) {
    case MCMethodSendMessageResponse.Result.R_INCORRECT_TEXT_ENTITIES:
      return t('Incorrect text entities');
    case MCMethodSendMessageResponse.Result.R_INVALID_REPLY:
      return t('Invalid reply');
    case MCMethodSendMessageResponse.Result.R_UNKNOWN_ERROR:
      return t('Unknown error');
    case MCMethodSendMessageResponse.Result.R_INTERNAL_SERVER_ERROR:
      return t('Internal server error');
  }

  if (type && MCMethodSendMessageResponse.Result[type]) {
    return MCMethodSendMessageResponse.Result[type]?.toLowerCase().replace('r_', '').replace(/_/g, ' ');
  }

  return '';
};

export const getSendGroupedMessagesError = (type?: MCMethodSendGroupedMessagesResponse.Result | null): string => {
  switch (type) {
    case MCMethodSendGroupedMessagesResponse.Result.R_INCORRECT_TEXT_ENTITIES:
      return t('Incorrect text entities');
    case MCMethodSendGroupedMessagesResponse.Result.R_INVALID_REPLY:
      return t('Invalid reply');
    case MCMethodSendGroupedMessagesResponse.Result.R_UNKNOWN_ERROR:
      return t('Unknown error');
    case MCMethodSendGroupedMessagesResponse.Result.R_INTERNAL_SERVER_ERROR:
      return t('Internal server error');
  }

  if (type && MCMethodSendGroupedMessagesResponse.Result[type]) {
    return MCMethodSendGroupedMessagesResponse.Result[type]?.toLowerCase().replace('r_', '').replace(/_/g, ' ');
  }

  return '';
};
