import Long from 'long';
import {action, observable} from 'mobx';

import {
  api,
  IMCPeerPreview,
  MCMethod,
  MCMethodGetPeers,
  MCMethodQueryPeers,
} from '../../api/proto';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';
import Chat from '../Chat';
import Peer from './Peer';


export class Peers extends APILayer {

  constructor(private chat: Chat, public app: AppStore) {
    super(app);
  }

  @observable private peersByUsernameMap_: Map<string, Peer> = new Map();
  @observable private peersMap_: Map<string, Peer> = new Map();

  private searchMap_: Map<string, Peer[]> = new Map();
  private searchPromises_ = new Map<string, Promise<Peer[]>>();

  /*
  @computed get list(): Peer[] {
    const peers: Peer[] = [];

    this.peersByUsernameMap_.forEach((peer) => {
      peers.push(peer);
    });

    return peers;
  }
  */
  get searchIsAvailable(): boolean {
    return this.chat.isGroup || this.chat.isSuperGroup;
  }

  @action reset = () => {
    this.searchMap_.clear();
  };

  public search = async (query: string): Promise<Peer[]> => {

    let promise = this.searchPromises_.get(query);

    if (!promise) {
      promise = this.searchPeers_(query)
        .catch((err) => {
          console.debug('search peers Error: ', err);
          throw err;
        })
        .finally(() => {
          this.searchPromises_.delete(query);
        });

      this.searchPromises_.set(query, promise);
    }

    return promise;
  };

  private searchPeers_ = async (query: string): Promise<Peer[]> => {
    if (!this.searchIsAvailable) {
      return [];
    }

    const loadedPeers = this.searchMap_.get(query);
    if (loadedPeers) {
      return loadedPeers;
    }

    const {res} = await this.searchRequest_(query);

    if (res?.methodResponse?.queryPeers?.peers) {
      return this.processPeers(res?.methodResponse?.queryPeers?.peers, query);
    }
    return [];
  };

  private searchRequest_ = async (query: string) => {
    return await this.chat.store.channel.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.chat.store.channel.workspace.id,
          action: new MCMethod({
            queryPeers: new MCMethodQueryPeers({
              channelID: this.chat.channelID,
              chatID: this.chat.id,
              query,
            }),
          }),
        }),
      },
      'channelsResponse',
    );
  };

  private processPeers = (rawPeers: IMCPeerPreview[], query: string): Peer[] => {
    const peers: Peer[] = rawPeers.map((p) => new Peer(p, this.chat));

    this.searchMap_.set(query, peers);

    return peers;
  };


  private findPromises_ = new Map<string, Promise<Peer | null>>();

  public findByUsername = async (username: string): Promise<Peer | null> => {

    let promise = this.findPromises_.get(username);

    if (!promise) {
      promise = this.findPeerByUsername_(username)
        .catch((err) => {
          console.debug('search peers Error: ', err);
          throw err;
        })
        .finally(() => {
          this.findPromises_.delete(username);
        });

      this.findPromises_.set(username, promise);
    }

    return promise;
  };

  private findPeerByUsername_ = async (username: string): Promise<Peer | null> => {

    const foundPeer = this.peersByUsernameMap_.get(username);
    if (foundPeer) {
      return foundPeer;
    }

    const {res} = await this.searchRequest_(username);

    const rawPeer = res?.methodResponse?.queryPeers?.peers?.[0];
    if (rawPeer) {
      const peer = new Peer(rawPeer, this.chat);
      this.peersByUsernameMap_.set(username, peer);
      this.peersMap_.set(peer.id.toString(), peer);
      return peer;
    }

    return null;
  };

  public getPeers = async (ids: Long[]) => {
    const {res} = await this.chat.store.channel.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.chat.store.channel.workspace.id,
          action: new MCMethod({
            getPeers: new MCMethodGetPeers({
              keys: ids.map((id) => ({
                id,
                channelID: this.chat.channelID,
              })),
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    const peers: Peer[] = [];

    res?.methodResponse?.getPeers?.peers?.forEach((p) => {
      if (p.peer) {
        const peer = new Peer(p.peer, this.chat);
        peers.push(peer);
        this.peersMap_.set(peer.id.toString(), peer);
      }
    });

    return peers;
  };

  public getPeer = async (id: Long): Promise<Peer | null> => {
    const foundPeer = this.peersMap_.get(id.toString());
    if (foundPeer) {
      return foundPeer;
    }

    const peers = await this.getPeers([id]);

    return peers.length ? peers[0] : null;
  };

}

export default Peers;
