import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import {Message} from '../../../../../stores/Message';

interface ChatLastMessageUserProps {
  className?: string;
  lastChatMessage?: Message | null;
}

export const ChatLastMessageUser = observer(({className, lastChatMessage}: ChatLastMessageUserProps) => {
  const {t} = useTranslation();

  if (lastChatMessage?.isOwn) {
    return <span className={className}>{t('chat_last_message_you_sender')}</span>;
  } else if (lastChatMessage?.senderName) {
    return <span className={className}>{t('chat_last_message_another_sender', {senderName: lastChatMessage?.senderName})}</span>;
  }

  return null;
});

export default ChatLastMessageUser;
