import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import SearchInput from 'o-ui/Input/SearchInput';
import Tab, {TabProps} from 'o-ui/Tab';
import Tabs from 'o-ui/Tabs';

import {entities} from '../../../../api/proto';
import {SEARCH_TEXT_LENGTH_LIMIT} from '../../../../constants';
import {useStore} from '../../../../stores/AppStore';
import ModalType from '../../../../stores/ModalType';
import {getUserDetailsValue} from '../../../../stores/User/User';
import WorkspaceMember from '../../../../stores/Workspaces/WorkspaceMember';
import {OperatorStatus, OperatorType} from '../../WorkspaceMembersTab';
import MembersTable from './MembersTable';

import {ReactComponent as PlusIcon} from '../../../../assets/icons/plus-line.svg';

interface WorkspaceMembersTabProps extends TabProps {
  count: number;
}

const WorkspaceMembersTab: React.FC<WorkspaceMembersTabProps> = ({label, count, active, ...rest}) => {
  return (
    <Tab
      className="pr-2 mr-2"
      label={
        <span className="d-flex align-items-center">
          {label}
          <span
            className={cn('px-1 border-body-separator radius-4 ml-2', {
              'bg-background-tertiary': !active,
            })}
          >
            {count}
          </span>
        </span>
      }
      active={active}
      {...rest}
    />
  );
};

const filterOperatorsByStatus = (operatorsList: WorkspaceMember[], statusType: OperatorStatus) => {
  switch (statusType) {
    case OperatorStatus.All:
      return operatorsList;
    case OperatorStatus.Active:
      return operatorsList.filter((member) => member.active);
    case OperatorStatus.Blocked:
      return operatorsList.filter((member) => member.blocked);
    case OperatorStatus.Expired:
      return operatorsList.filter((member) => member.expired);
    case OperatorStatus.Invited:
      return operatorsList.filter((member) => member.invited);
  }
};

interface IProps {
  onOperatorClick: (member: WorkspaceMember) => void;
  selectedOperatorTypeTab: OperatorType;
  selectedOperatorStatusTab: OperatorStatus;
  onChangeOperatorTypeTab(newTab: OperatorType): void;
  onChangeOperatorStatusTab(newTab: OperatorStatus): void;
}

export const WorkspaceMembersViewer = observer((props: IProps) => {
  const {t} = useTranslation();
  const {activeWorkspace, anal, modals} = useStore();

  const filterOperator = (list: WorkspaceMember[], filter: string): WorkspaceMember[] => {
    return list.filter((item) => {
      const _firstName =
        getUserDetailsValue(item?.profile?.fields, entities.UserProfileFieldType.UPFT_FIRST_NAME, 'strValue') || '';
      const _lastName =
        getUserDetailsValue(item?.profile?.fields, entities.UserProfileFieldType.UPFT_LAST_NAME, 'strValue') || '';

      const _email = item.email || '';

      return (
        `${_firstName} ${_lastName}`.toLowerCase().includes(filter.toLowerCase()) ||
        `${_email}`.toLowerCase().includes(filter.toLowerCase()) ||
        item.id?.toString().includes(filter.toLowerCase()) ||
        item.userId?.toString().includes(filter.toLowerCase())
      );
    });
  };

  const [filter, setFilter] = React.useState<string>('');

  const handleChangeFilter = (value: string) => {
    setFilter(value);
  };

  const handleChangeOperatorType = (e: React.ChangeEvent, newValue: number) => {
    props.onChangeOperatorTypeTab(newValue);
  };

  const handleChangeOperatorStatus = (e: React.ChangeEvent, newValue: number) => {
    props.onChangeOperatorStatusTab(newValue);
  };

  const [operatorsListByType, setOperatorsListByType] = React.useState<WorkspaceMember[]>([]);
  const [operatorsList, setOperatorsList] = React.useState<WorkspaceMember[]>([]);

  const filterOperatorsByType = React.useCallback(() => {
    let _operatorsList: WorkspaceMember[] = [];
    switch (props.selectedOperatorTypeTab) {
      case OperatorType.All:
        _operatorsList = activeWorkspace.members;
        break;
      case OperatorType.Admins:
        _operatorsList = activeWorkspace.admins;
        break;
      case OperatorType.Operators:
        _operatorsList = activeWorkspace.operators;
        break;
    }

    setOperatorsListByType(_operatorsList);

    const _operatorsListByStatus = filterOperatorsByStatus(_operatorsList, props.selectedOperatorStatusTab);

    if (filter) {
      setOperatorsList(filterOperator(_operatorsListByStatus, filter));
    } else {
      setOperatorsList(_operatorsListByStatus);
    }
  }, [
    props.selectedOperatorTypeTab,
    props.selectedOperatorStatusTab,
    filter,
    activeWorkspace.members,
    activeWorkspace.admins,
    activeWorkspace.operators,
  ]);

  React.useEffect(() => {
    filterOperatorsByType();
  }, [filterOperatorsByType]);

  const sortedList = React.useMemo(() => {
    return operatorsList.slice().sort((userA, userB) => {
      return (
        Number(userA.expired) - Number(userB.expired) ||
        Number(userB.active) - Number(userA.active) ||
        Number(userB.isOwner) - Number(userA.isOwner) ||
        Number(userB.isAdmin) - Number(userA.isAdmin) ||
        Number(userB.isOperator) - Number(userA.isOperator)
      );
    });
  }, [operatorsList]);

  const getOperatorsByStatusCount = (status: OperatorStatus) => {
    return filterOperatorsByStatus(operatorsListByType, status).length;
  };

  const openInvitationForm = React.useCallback((e) => {
    anal.inviteClick(e);
    modals.open(ModalType.INVITE_MEMBER_BY_EMAIL);
  }, [
    anal,
    modals,
  ]);

  return (
    <div className="members-viewer">
      <div className="d-flex mt-6 px-6 flex-wrap-reverse justify-content-between">
        <div className="d-flex flex-wrap-reverse justify-content-between">
          <Tabs
            variant="filled"
            className="mr-3 mb-5"
            value={props.selectedOperatorTypeTab}
            onChange={handleChangeOperatorType}
          >
            <WorkspaceMembersTab
              label={t('settings_workspace_members_filter_all')}
              count={activeWorkspace.members.length}
            />
            <WorkspaceMembersTab
              label={t('settings_workspace_members_filter_admins')}
              count={activeWorkspace.admins.length}
            />
            <WorkspaceMembersTab
              label={t('settings_workspace_members_filter_operators')}
              count={activeWorkspace.operators.length}
            />
          </Tabs>

          <Tabs
            variant="filled"
            className="mb-5"
            value={props.selectedOperatorStatusTab}
            onChange={handleChangeOperatorStatus}
          >
            <WorkspaceMembersTab
              label={t('settings_workspace_members_filter_all')}
              count={getOperatorsByStatusCount(OperatorStatus.All)}
            />
            <WorkspaceMembersTab
              label={t('settings_workspace_members_filter_active')}
              count={getOperatorsByStatusCount(OperatorStatus.Active)}
            />
            <WorkspaceMembersTab
              label={t('settings_workspace_members_filter_invited')}
              count={getOperatorsByStatusCount(OperatorStatus.Invited)}
            />
            <WorkspaceMembersTab
              label={t('settings_workspace_members_filter_blocked')}
              count={getOperatorsByStatusCount(OperatorStatus.Blocked)}
            />
            <WorkspaceMembersTab
              label={t('settings_workspace_members_filter_expired')}
              count={getOperatorsByStatusCount(OperatorStatus.Expired)}
            />
          </Tabs>
        </div>

        <Button
          className="d-flex align-items-center mb-5"
          variant="contained"
          color="primary"
          onClick={openInvitationForm}
        >
          <PlusIcon className="mr-3" width="12" />
          {t('settings_workspace_members_open_invite_form')}
        </Button>
      </div>

      <SearchInput
        className="settings-tab__table-search px-5 py-1 mx-6 mt-3 mb-6"
        placeholder={t('settings_workspace_members_search_placeholder')}
        onChange={handleChangeFilter}
        maxLength={SEARCH_TEXT_LENGTH_LIMIT}
      />

      <MembersTable list={sortedList} onClick={props.onOperatorClick} />
    </div>
  );
});

export default WorkspaceMembersViewer;
