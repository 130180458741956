import {entities} from '../../../api/proto';
import FeaturesStore from '../../FeaturesStore';
import getChannelTypeName from './getChannelTypeName';

export interface ChannelTypesOptions {
  caption: string;
  value: number;
}

export const getChannelTypesList = (features: FeaturesStore): ChannelTypesOptions[] => {
  return Object.keys(entities.OzekonChannelType)
    .filter((channelType) => {
      switch (entities.OzekonChannelType[channelType]) {
        case entities.OzekonChannelType.OIT_INSTAGRAM:
          return features.instagram;
        case entities.OzekonChannelType.OIT_TELEGRAM_BOT:
          return features.telegramBot;
      }
      return true;
    })
    .map((s) => ({
      caption: getChannelTypeName(entities.OzekonChannelType[s]),
      value: entities.OzekonChannelType[s],
    }))
    .filter(({value}) => !!value);
};

export default getChannelTypesList;