import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import Avatar from 'o-ui/Avatar';
import ListItemAvatar from 'o-ui/ListItemAvatar';
import ListItemText from 'o-ui/ListItemText';

import SystemUserRoleLabel from '../../../../components/SystemUserRoleLabel';
import WorkspaceMember from '../../../../stores/Workspaces/WorkspaceMember';
import {formatDateTime} from '../../../../utils/format';
import MemberStatus from '../MemberStatus';

interface MembersTableRowProps {
  style: React.CSSProperties;
  item: WorkspaceMember;
  onClick?: (member: WorkspaceMember) => void;
}

export const MembersTableRow = observer((props: MembersTableRowProps) => {
  const {item: member, onClick} = props;

  const isCanManage = !member.isOwner;

  const handleManageClick = () => {
    isCanManage && onClick?.(member);
  };

  const inviteCreator = WorkspaceMember.getDisplayName(member.invitedByMember);

  const title = member.displayName;
  const subTitle = title === member.email ? '' : member.email;

  const isInactive = !!(!member.active || member.expired);

  return (
    <div
      style={props.style}
      className={cn('members-table__row', {
        clickable: isCanManage,
        inactive: isInactive,
      })}
      onClick={handleManageClick}
      data-member-id={member.id?.toNumber()}
      data-user-id={member.userId?.toNumber()}
    >
      <div className="members-table__cell members-table__cell--name">
        <ListItemAvatar>
          <Avatar name={title} inactive={isInactive} />
        </ListItemAvatar>
        <ListItemText
          primary={title}
          secondary={subTitle}
          className={cn('text-truncate w-100', {inactive: isInactive})}
        />
      </div>
      <div className="members-table__cell members-table__cell--role">
        <SystemUserRoleLabel role={member.role} />
      </div>
      <div className="members-table__cell members-table__cell--status">
        <MemberStatus member={member} />
      </div>
      <div className="members-table__cell members-table__cell--created-at">{formatDateTime(member.createdAt)}</div>
      <div className="members-table__cell members-table__cell--invited-by">{inviteCreator || '-'}</div>
    </div>
  );
});

export default MembersTableRow;
