import React from 'react';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import NumberInput from 'o-ui/Input/NumberInput';
import FormLabel from 'o-ui/FormLabel';
import {getPositionAlign, parsePositionByAlign, PositionAlign} from '../../../../utils/widgetPositionUtils';


interface PositionSelectorProps {
  className?: string
  position?: React.CSSProperties | null;
  onChange?: (props: React.CSSProperties) => void;
}

export const PositionSelector = (props: PositionSelectorProps) => {
  const {t} = useTranslation();

  const [align, setAlign] = React.useState<PositionAlign>(getPositionAlign(props.position));
  const [posProps, setPosProps] = React.useState<React.CSSProperties>(parsePositionByAlign(props.position, align));

  const handleChangeAlign = (newAlign: PositionAlign) => {
    setAlign(newAlign);

    const newPosProps = parsePositionByAlign(posProps, newAlign);

    setPosProps(newPosProps);

    props.onChange?.(newPosProps);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPosProps = {...posProps, };
    const value = parseFloat(e.currentTarget.value);

    switch (e.currentTarget.name) {
      case 'top':
        newPosProps.top = value || 0;
        break;
      case 'bottom':
        newPosProps.bottom = value || 0;
        break;
      case 'right':
        newPosProps.right = value || 0;
        break;
      case 'left':
        newPosProps.left = value || 0;
        break;
    }

    setPosProps(newPosProps);

    props.onChange?.(newPosProps);
  };

  return (
    <div className={cn("d-flex", props.className)}>
      <div className="pr-3">
        <FormLabel>{t('Position')}</FormLabel>
        <div
          className="bg-brand-tint border-brand-tint3 position-relative radius-10"
          style={{width: 144, height: 124}}
        >
          <div
            className={cn("border-brand-tint3 radius-8 position-absolute cursor-pointer", {
              'bg-brand-primary': align === 'right-top',
            })}
            style={{width: 24, height: 24, top: 6, right: 6}}
            onClick={() => handleChangeAlign('right-top')}
          />
          <div
            className={cn("border-brand-tint3 radius-8 position-absolute cursor-pointer", {
              'bg-brand-primary': align === 'right-bottom',
            })}
            style={{width: 24, height: 24, bottom: 6, right: 6}}
            onClick={() => handleChangeAlign('right-bottom')}
          />
          <div
            className={cn("border-brand-tint3 radius-8 position-absolute cursor-pointer", {
              'bg-brand-primary': align === 'left-top',
            })}
            style={{width: 24, height: 24, top: 6, left: 6}}
            onClick={() => handleChangeAlign('left-top')}
          />
          <div
            className={cn("border-brand-tint3 radius-8 position-absolute cursor-pointer", {
              'bg-brand-primary': align === 'left-bottom',
            })}
            style={{width: 24, height: 24, bottom: 6, left: 6}}
            onClick={() => handleChangeAlign('left-bottom')}
          />
        </div>
      </div>

      <div className="pl-3" style={{width: 140}}>
        {align === 'left-bottom' || align === 'right-bottom' ? (
          <>
            <FormLabel>{t('Margin Bottom')}</FormLabel>
            <div className="position-relative">
              <NumberInput
                name="bottom"
                placeholder="0"
                value={posProps.bottom?.toString()}
                onChange={handleChange}
                maxLength={4}
              />
              <div className="input-adornment input-adornment--right d-inline-flex align-items-center my-3">px</div>
            </div>
          </>
        ) : null}

        {align === 'left-top' || align === 'right-top' ? (
          <>

            <FormLabel>{t('Margin Top')}</FormLabel>
            <div className="position-relative">
              <NumberInput
                name="top"
                placeholder="0"
                value={posProps.top?.toString()}
                onChange={handleChange}
                maxLength={4}
              />
              <div className="input-adornment input-adornment--right d-inline-flex align-items-center my-3">px</div>
            </div>
          </>
        ) : null}

        {align === 'right-top' || align === 'right-bottom' ? (
          <>
            <FormLabel>{t('Margin Right')}</FormLabel>
            <div className="position-relative">
              <NumberInput
                name="right"
                placeholder="0"
                value={posProps.right?.toString()}
                onChange={handleChange}
                maxLength={4}
              />
              <div className="input-adornment input-adornment--right d-inline-flex align-items-center my-3">px</div>
            </div>
          </>
        ) : null}

        {align === 'left-top' || align === 'left-bottom' ? (
          <>
            <FormLabel>{t('Margin Left')}</FormLabel>
            <div className="position-relative">
              <NumberInput
                name="left"
                placeholder="0"
                value={posProps.left?.toString()}
                onChange={handleChange}
                maxLength={4}
              />
              <div className="input-adornment input-adornment--right d-inline-flex align-items-center my-3">px</div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default PositionSelector;