import Long from 'long';
import {toJS} from 'mobx';
import moment from 'moment';

import {NetworkResponse} from '../../api/network';
import {
  api,
  APIResponse,
  IMCAttachment,
  IMCMethodResponse,
  IMCSendEntities,
  IMCTextEntities,
  MCMethod,
  MCMethodDeleteMessage,
  MCMethodSendGroupedMessages,
  MCMethodSendGroupedMessagesResponse,
  MCMethodSendMessage,
  MCMethodSendMessageResponse,
} from '../../api/proto';
import {randomUint8Array} from '../../utils/arrayUtils';
import {FileData} from '../../utils/file/fileReaders';
import {getRandNumericID} from '../../utils/idUtils';
import logger from '../../utils/logger';
import WorkspaceStore from '../Workspaces/WorkspaceStore';
import {RawMessage} from './IRawMessagesStore';
import RawMessagesCore from './RawMessagesCore';
import {getSendGroupedMessagesError, getSendMessageError} from './utils';


interface PreSendMessageData {
  text: string;
  randomID?: Long,
  attachments?: IMCAttachment[] | null;
  dataFiles?: FileData[] | null;
  groupID?: Uint8Array | null;
  replyToMessage?: RawMessage | null;
  textEntities?: IMCTextEntities[] | null;
}

interface IRawMessagesBase {
  preSend: (data: PreSendMessageData) => RawMessage;
  preSendGroup: (data: PreSendMessageData) => Map<number, RawMessage[]>;
}

export class RawMessagesBase extends RawMessagesCore implements IRawMessagesBase {

  constructor(
    protected workspace: WorkspaceStore,
    protected channelID: Uint8Array,
    protected chatID: Long,
    protected lastMessageId?: Long | null,
  ) {
    super(workspace, channelID, chatID, lastMessageId);
  }


  private createNewMessage_ = ({
    text,
    randomID,
    attachments,
    dataFiles,
    groupID,
    replyToMessage,
    textEntities,
  }: PreSendMessageData): RawMessage => {
    const localID = this.lastMessageId
      ? Long.fromNumber(this.lastMessageId.toNumber() + 1)
      : Long.fromNumber(1);

    const sentAt = Long.fromNumber(moment().unix());

    const rawMessage: RawMessage = {
      sending: true,
      chatID: this.chatID,
      localID,
      randomID,
      sentAt,
      text: text,
      textEntities: textEntities,
      operatorID: this.workspace.app.userStore.user?.userId,
      attachments,
      dataFiles,
      groupID,
      replyToMessageID: replyToMessage?.localID,
      replyToMessage,
    };

    return rawMessage;
  };

  public preSend = ({
    text,
    attachments,
    dataFiles,
    groupID,
    replyToMessage,
    textEntities,
  }: PreSendMessageData): RawMessage => {
    const randomID = Long.fromNumber(getRandNumericID());

    const newRawMessage = this.createNewMessage_({
      text: text.trim(),
      randomID,
      attachments,
      dataFiles,
      groupID,
      replyToMessage,
      textEntities,
    });
    this.addMessage_(newRawMessage, randomID, true);

    return newRawMessage;
  };

  public preSendGroup = ({
    text,
    attachments,
    dataFiles,
    textEntities,
  }: PreSendMessageData): Map<number, RawMessage[]> => {
    const newRawMessages: Map<number, RawMessage[]> = new Map();
    const groupIDMap: Map<number, Uint8Array> = new Map();

    if (dataFiles) {
      dataFiles.forEach((dataFile) => {
        const type = dataFile.attachment?.type || 0;

        const groupID = groupIDMap.get(type) || randomUint8Array();
        groupIDMap.set(type, groupID);

        const newRawMessage = this.preSend({
          text: newRawMessages.size === 0 ? text : '',
          attachments: dataFile.attachment ? [dataFile.attachment] : null,
          dataFiles: [dataFile],
          groupID,
          textEntities,
        });

        const pack = newRawMessages.get(type);
        newRawMessages.set(type, pack ? [...pack, newRawMessage] : [newRawMessage]);
      });
    } else {
      const newRawMessage = this.preSend({
        text,
        attachments,
        dataFiles,
        textEntities,
      });
      newRawMessages.set(0, [newRawMessage]);
    }

    return newRawMessages;
  };

  public sendRequest = async (newRawMessage: RawMessage, attachments?: IMCAttachment[]): Promise<NetworkResponse<IMCMethodResponse, MCMethodSendMessageResponse.Result | APIResponse.Status>> => {
    const {error, res} = await this.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            sendMessage: new MCMethodSendMessage({
              channelID: this.channelID,
              chatID: this.chatID,
              randomID: newRawMessage.randomID,
              text: newRawMessage.text,
              textEntities: newRawMessage.textEntities,
              attachments,
              replyToMessageID: newRawMessage.replyToMessageID,
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    const localID = res?.methodResponse?.sendMessage?.messageID || newRawMessage.localID;

    if (res?.methodResponse?.sendMessage?.result) {
      const errorMessage = getSendMessageError(res?.methodResponse?.sendMessage?.result);
      logger.error('RawMessagesBase: sendMessage: ', errorMessage, res?.methodResponse?.sendMessage);

      this.updateMessage_({...newRawMessage, localID, error: errorMessage}, newRawMessage.randomID);

      return {
        error: {
          message: errorMessage,
          type: res?.methodResponse?.sendMessage?.result,
        }
      };
    } else {
      this.updateMessage_({...newRawMessage, localID, sending: false}, newRawMessage.randomID);
    }

    return {error, res: res?.methodResponse};
  };

  public sendGroupedRequest = async (newRawMessages: RawMessage[]): Promise<NetworkResponse<IMCMethodResponse, MCMethodSendGroupedMessagesResponse.Result | APIResponse.Status>> => {
    const messageEntities: IMCSendEntities[] = newRawMessages.map((newRawMessage) => ({
      text: newRawMessage.text,
      attachments: newRawMessage.attachments?.map((attachment) => toJS(attachment)),
      randomID: newRawMessage.randomID,
    }));

    const {error, res} = await this.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            sendGroupedMessages: new MCMethodSendGroupedMessages({
              channelID: this.channelID,
              chatID: this.chatID,
              messages: messageEntities,
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    const result = res?.methodResponse?.sendGroupedMessages?.result;
    const messageIDs = res?.methodResponse?.sendGroupedMessages?.messageIDs;

    if (result) {
      const errorMessage = getSendGroupedMessagesError(result);

      newRawMessages.forEach((newRawMessage) => {
        this.updateMessage_({...newRawMessage, error: errorMessage}, newRawMessage.randomID);
      });

      return {
        error: {
          message: errorMessage,
          type: result,
        }
      };
    } else if (!messageIDs) {
      newRawMessages.forEach((newRawMessage) => {
        this.updateMessage_({...newRawMessage, error: 'unknown error'}, newRawMessage.randomID);
      });
    } else {
      newRawMessages.forEach((newRawMessage) => {
        this.updateMessage_({...newRawMessage, sending: false}, newRawMessage.randomID);
      });
    }

    return {error, res: res?.methodResponse};
  };

  delete = async (messageID: Long, bothSides?: boolean | null) => {
    const {error, res} = await this.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            deleteMessage: new MCMethodDeleteMessage({
              channelID: this.channelID,
              chatID: this.chatID,
              messageID,
              bothSides,
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    return {error, res: res?.methodResponse};
  };
}

export default RawMessagesBase;
