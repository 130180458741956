import Delta from 'quill-delta';
import {IMCTextEntities, MCTextEntities} from '../../../api/proto';
import {uint8ArrayToString} from '../../../utils/arrayUtils';


const textEntityTypeToAttrs = (textEntity: IMCTextEntities) => {
  switch (textEntity.type) {
    case MCTextEntities.Type.BOLD:
      return {bold: true};
    case MCTextEntities.Type.UNDERLINE:
      return {underline: true};
    case MCTextEntities.Type.ITALIC:
      return {italic: true};
    case MCTextEntities.Type.STRIKETHROUGH:
      return {strike: true};
    case MCTextEntities.Type.LINK:
      return {link: uint8ArrayToString(textEntity.payload)};
  }
  return {};
};

const formatInsert = (textEntity: IMCTextEntities, insert: string) => {
  console.debug('formatInsert', textEntity, insert);
  switch (textEntity.type) {
    case MCTextEntities.Type.MENTION:
      return {mention: {username: insert, userid: uint8ArrayToString(textEntity.payload)}};
    /*
    case MCTextEntities.Type.MENTION_NAME:
      return {mention: {name: insert, userid: textEntity.userId?.toString()}};
    */
  }
  return insert;
};

export default function convertTextEntitiesToDelta(text: string, textEntities: IMCTextEntities[] = []): Delta {
  const ops: any[] = [];
  const teLength = textEntities.length;

  if (textEntities.length) {
    textEntities.forEach((e, i) => {
      const offset = e.offset?.toNumber() || 0;
      const length = e.length?.toNumber() || 0;

      if (i === 0 && offset > 0) {
        ops.push({
          insert: text.substr(0, offset),
          attributes: {},
        });
      }

      const prevOp = ops.length > 0 && ops[ops.length - 1] ? ops[ops.length - 1] : null;
      const prevTextEntity = i > 0 && textEntities[i - 1] ? textEntities[i - 1] : null;

      if (prevOp && prevTextEntity?.offset === e.offset && prevTextEntity?.length === e.length) {
        prevOp.attributes = {...prevOp.attributes, ...textEntityTypeToAttrs(e)};
      } else {
        ops.push({
          insert: formatInsert(e, text.substr(offset, length)),
          attributes: textEntityTypeToAttrs(e),
        });
      }

      const nextTextEntity = i < teLength && textEntities[i + 1] ? textEntities[i + 1] : null;

      ops.push({
        insert: text.substr(
          offset + length,
          nextTextEntity ? (nextTextEntity.offset?.toNumber() || 0) - (offset + length) : undefined,
        ),
        attributes: {},
      });
    });
  } else {
    ops.push({insert: text});
  }

  return new Delta({
    ops,
  });
}