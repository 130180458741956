import {observer} from 'mobx-react';
import React, {useEffect} from 'react';

import useStore from '../../../stores/useStore';
import BlockedChatArea from './BlockedChatArea';
import ChatArea from './ChatArea';
import EmptyChatArea from './EmptyChatArea';
import NoChannelsArea from './NoChannelsArea';

export const UserChat: React.FC<React.PropsWithChildren> = observer(() => {
  const {activeWorkspace, chatsView, page} = useStore();

  useEffect(() => {
    page.updatePageVisibility();
  }, [page]);

  if (activeWorkspace.blocked) {
    return <BlockedChatArea />;
  }

  if (!activeWorkspace.channels.list.length) {
    return <NoChannelsArea />;
  }

  if (!chatsView.activeUsersChat) {
    return <EmptyChatArea />;
  }

  return <ChatArea chat={chatsView.activeUsersChat} />;
});

export default UserChat;
