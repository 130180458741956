import React from 'react';
import {useTranslation} from 'react-i18next';

import SettingsTab from '../../stores/LayOutStore/SettingsTab';
import {SettingsHeadState} from './SettingsHead';
import SnippetsViewer from './Snippets/SnippetsViewer';
import useAllowChangeHeadState from './useAllowChangeHeadState';

interface SnippetsTabProps {
  onChangeState?: (state: SettingsHeadState) => void;
}

export const SnippetsTab = React.memo((props: SnippetsTabProps) => {
  const {t} = useTranslation();

  const allowChangeHeadState = useAllowChangeHeadState(SettingsTab.SNIPPETS);

  React.useEffect(() => {
    if (!allowChangeHeadState) {
      return;
    }
    props.onChangeState?.({
      title: t('settings_snippets_snippets_management'),
    });
  }, [props, allowChangeHeadState, t]);

  return (
    <div className="settings-tab">
      <div className="settings-tab__body custom-scroll">
        <div className="settings-tab__paper w-100 h-100 px-0">
          <div className="settings-tab__table settings-tab__table--snippets h-100">
            <SnippetsViewer />
          </div>
        </div>
      </div>
    </div>
  );
});

export default SnippetsTab;
