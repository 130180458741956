import {observer} from 'mobx-react';
import {Button, SearchInput} from 'o-ui';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {SEARCH_TEXT_LENGTH_LIMIT} from '../../../../../constants';

interface ChannelsAccessHeaderProps {
  onSearch(value: string): void;
  onResetExceptions(): void;
  disabledResetExceptions: boolean;
}

const ChannelsAccessHeader: React.FC<ChannelsAccessHeaderProps> = observer((props) => {
  const {t} = useTranslation();

  return (
    <div className="channels-access__header">
      <div className="channels-access__header-top">
        <div className="channels-access__header-title">
          {t('setting_workspace_members_channels_access_header_title')}
        </div>
        <Button
          onClick={props.onResetExceptions}
          variant="contained"
          color="secondary"
          size="small"
          disabled={props.disabledResetExceptions}
        >
          {t('setting_workspace_members_channels_access_header_reset_exceptions_btn')}
        </Button>
      </div>

      <div className="channels-access__header-search">
        <SearchInput
          className="w-100"
          placeholder={t('components_select_chat_search_input_placeholder')}
          onChange={props.onSearch}
          maxLength={SEARCH_TEXT_LENGTH_LIMIT}
        />
      </div>
    </div>
  );
});

export default ChannelsAccessHeader;
