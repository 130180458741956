import {observer} from 'mobx-react';
import React from 'react';
import {ModalType} from '../stores/ModalType';
import {ModalItem} from '../stores/ModalsStore';

import ModalConfirm from './ModalConfirm';
// import ModalInfo from './ModalInfo';
// import {ModalAddMember} from './ModalAddMember';
// import {ModalAddMembers} from './ModalAddMembers';
// import {ModalRemoveMember} from './ModalRemoveMember';

import ModalMemberAccessAddChat from './ModalMemberAccessAddChat';

// import ModalLeaveChat from './ModalLeaveChat';
// import ModalLeaveGroup from './ModalLeaveGroup';
// import ModalLeaveChannel from './ModalLeaveChannel';

import ModalDeleteChatMessage from './ModalDeleteChatMessage';
// import ModalDeleteChatHistory from './ModalDeleteChatHistory';
// import ModalDeleteGroupHistory from './ModalDeleteGroupHistory';
// import ModalDeleteChannelHistory from './ModalDeleteChannelHistory';

// import ModalDeleteChatHistoryAndLeave from './ModalDeleteChatHistoryAndLeave';
// import ModalDeleteGroupHistoryAndLeave from './ModalDeleteGroupHistoryAndLeave';
// import ModalDeleteChannelHistoryAndLeave from './ModalDeleteChannelHistoryAndLeave';

// import {ModalForward} from './ModalForward';
// import {ModalPinMessage} from './ModalPinMessage';
// import {ModalUnpinMessage} from './ModalUnpinMessage';
// import {ModalUnpinAllMessages} from './ModalUnpinAllMessages';
import {ModalJumpToDate} from './ModalJumpToDate';

// import {ModalNewContact} from './ModalNewContact';
import ModalUploadFileAsync from './ModalUploadFileAsync';
import ModalUploadPhotoVideoAsync from './ModalUploadPhotoVideoAsync';

import ModalMediaLightBox from './ModalMediaLightBox';
// import {ModalUploadPhotoVideo} from './ModalUploadPhotoVideo';
import ModalUploadAvatar from './ModalUploadAvatar';
// import {ModalSnippetAdding} from './ModalSnippetAdding';
import {ModalMessageTagCreating} from './ModalMessageTagCreating';
import {ModalSnippetCreating} from './ModalSnippetCreating';
import {ModalSnippetEditing} from './ModalSnippetEditing';

import {ModalChannelCreation} from './ModalChannelCreation';
// import ModalAccountCreationTelegramBot from './ModalAccountCreationTelegramBot';
// import {ModalAccountCreationSignal} from './ModalAccountCreationSignal';
// import ModalWebWidgetCreation from './ModalWebWidgetCreation';
// import {ModalAccountBlocking} from './ModalAccountBlocking';
import {ModalChannelDeleting} from './ModalChannelDeleting';
import ModalChannelReactivation from './ModalChannelReactivation';

import ModalProfileChangeEmail from './ModalProfileChangeEmail';
import ModalProfileChangePassword from './ModalProfileChangePassword';
import ModalProfileForgotPassword, {ForgotPasswordStep} from './ModalProfileForgotPassword';
import ModalProfileResetPassword from './ModalProfileResetPassword';

import ModalMemberBlocking from './ModalMemberBlocking';
import ModalMemberDeleting from './ModalMemberDeleting';
// import ModalOperatorInvitationToChat from './ModalOperatorInvitationToChat';

import ModalWebWidgetSnippetSettings from './ModalWebWidgetSnippetSettings';
import ModalWebWidgetThemeSettings from './ModalWebWidgetThemeSettings';
import ModalWebWidgetWelcomeMessageSettings from './ModalWebWidgetWelcomeMessageSettings';

import ModalChatUnblocking from './ModalChatUnblocking';
import ModalEditGroup from './ModalEditGroup';
// import ModalEditInternalGroup from './ModalEditInternalGroup';
// import ModalGroupCreating from './ModalGroupCreating';
// import ModalChatCreating from './ModalChatCreating';
// import ModalInternalChatCreating from './ModalInternalChatCreating';
// import ModalInviteToChatViaLink from './ModalInviteToChatViaLink';
// import ModalShareInviteLink from './ModalShareInviteLink';
import ModalForceVersionUpdateApp from './ModalForceVersionUpdateApp';
import ModalInviteMemberByEmail from './ModalInviteMemberByEmail';
import ModalInviteMemberByLink from './ModalInviteMemberByLink';
// import ModalCreatedInvitationLink from './ModalCreatedInvitationLink';
import ModalInviteDeleting from './ModalInviteDeleting';
import ModalWorkspaceCreating from './ModalWorkspaceCreating';

import ModalApplyTariffWarning from './ModalApplyTariffWarning';
import ModalFreePlanActivated from './ModalFreePlanActivated';
import ModalTariffPayWithMembersBlock from './ModalTariffPayWithMembersBlock';

interface IProps {
  modal: ModalItem;
}

export const ModalWrapper: React.FC<IProps> = observer((props) => {
  switch (props.modal.type) {
    case ModalType.CONFIRM:
      return <ModalConfirm modal={props.modal} />;
    // case ModalType.INFO:
    //   return <ModalInfo modal={props.modal} />;
    // case ModalType.ADD_MEMBER:
    //   return <ModalAddMember modal={props.modal} />;
    // case ModalType.ADD_MEMBERS:
    //   return <ModalAddMembers modal={props.modal} />;
    // case ModalType.REMOVE_MEMBER:
    //   return <ModalRemoveMember modal={props.modal} />;

    // case ModalType.LEAVE_CHAT:
    //   return <ModalLeaveChat modal={props.modal} />;
    // case ModalType.LEAVE_GROUP:
    //   return <ModalLeaveGroup modal={props.modal} />;
    // case ModalType.LEAVE_CHANNEL:
    //   return <ModalLeaveChannel modal={props.modal} />;

    case ModalType.DELETE_CHAT_MESSAGE:
      return <ModalDeleteChatMessage modal={props.modal} />;
    // case ModalType.DELETE_CHAT_HISTORY:
    //   return <ModalDeleteChatHistory modal={props.modal} />;
    // case ModalType.DELETE_GROUP_HISTORY:
    //   return <ModalDeleteGroupHistory modal={props.modal} />;
    // case ModalType.DELETE_CHANNEL_HISTORY:
    //   return <ModalDeleteChannelHistory modal={props.modal} />;

    // case ModalType.DELETE_CHAT_HISTORY_AND_LEAVE:
    //   return <ModalDeleteChatHistoryAndLeave modal={props.modal} />;
    // case ModalType.DELETE_GROUP_HISTORY_AND_LEAVE:
    //   return <ModalDeleteGroupHistoryAndLeave modal={props.modal} />;
    // case ModalType.DELETE_CHANNEL_HISTORY_AND_LEAVE:
    //   return <ModalDeleteChannelHistoryAndLeave modal={props.modal} />;

    // case ModalType.FORWARD:
    //   return <ModalForward modal={props.modal} />;
    // case ModalType.PIN_MESSAGE:
    //   return <ModalPinMessage modal={props.modal} />;
    // case ModalType.UNPIN_MESSAGE:
    //   return <ModalUnpinMessage modal={props.modal} />;
    // case ModalType.UNPIN_ALL_MESSAGES:
    //   return <ModalUnpinAllMessages modal={props.modal} />;
    case ModalType.JUMP_TO_DATE:
      return <ModalJumpToDate modal={props.modal} />;

    // case ModalType.NEW_CONTACT:
    //   return <ModalNewContact modal={props.modal} />;

    case ModalType.MEDIA_LIGHT_BOX:
      return <ModalMediaLightBox modal={props.modal} />;

    // case ModalType.UPLOAD_PHOTO_VIDEO:
    //   return <ModalUploadPhotoVideo modal={props.modal} />;
    case ModalType.UPLOAD_FILE:
    case ModalType.UPLOAD_FILE_ASYNC:
      return <ModalUploadFileAsync modal={props.modal} />;
    case ModalType.UPLOAD_AVATAR:
      return <ModalUploadAvatar modal={props.modal} />;
    case ModalType.UPLOAD_PHOTO_VIDEO_ASYNC:
      return <ModalUploadPhotoVideoAsync modal={props.modal} />;

    case ModalType.CREATE_SNIPPET:
      return <ModalSnippetCreating modal={props.modal} />;
    case ModalType.EDIT_SNIPPET:
      return <ModalSnippetEditing modal={props.modal} />;
    case ModalType.MESSAGE_TAG_CREATING:
      return <ModalMessageTagCreating modal={props.modal} />;

    case ModalType.CHANNEL_CREATION:
      return <ModalChannelCreation modal={props.modal} />;
    case ModalType.MEMBER_ACCESS_ADD_CHAT:
      return <ModalMemberAccessAddChat modal={props.modal} />;
    // case ModalType.ACCOUNT_CREATION_TELEGRAM_BOT:
    //   return <ModalAccountCreationTelegramBot modal={props.modal} />;
    // case ModalType.ACCOUNT_CREATION_SIGNAL:
    //   return <ModalAccountCreationSignal modal={props.modal} />;
    // case ModalType.ACCOUNT_CREATION_WEB_WIDGET:
    //   return <ModalWebWidgetCreation modal={props.modal} />;
    // case ModalType.ACCOUNT_CREATION_INSTAGRAM:
    //   return <ModalAccountCreation modal={props.modal} />;
    case ModalType.CHANNEL_REACTIVATION:
      return <ModalChannelReactivation modal={props.modal} />;

    // case ModalType.ACCOUNT_BLOCKING:
    //   return <ModalAccountBlocking modal={props.modal} />;
    case ModalType.CHANNEL_DELETING:
      return <ModalChannelDeleting modal={props.modal} />;

    case ModalType.MEMBER_BLOCKING:
      return <ModalMemberBlocking modal={props.modal} />;
    case ModalType.MEMBER_DELETING:
      return <ModalMemberDeleting modal={props.modal} />;
    case ModalType.INVITE_DELETING:
      return <ModalInviteDeleting modal={props.modal} />;
    // case ModalType.MEMBER_INVITATION_TO_CHAT:
    //   return <ModalOperatorInvitationToChat modal={props.modal} />;

    case ModalType.PROFILE_CHANGE_EMAIL:
      return <ModalProfileChangeEmail modal={props.modal} />;
    case ModalType.PROFILE_VERIFICATION_EMAIL:
      return <ModalProfileChangeEmail modal={props.modal} emailVerificationMode />;
    case ModalType.PROFILE_CHANGE_PASSWORD:
      return <ModalProfileChangePassword modal={props.modal} />;
    case ModalType.PROFILE_FORGOT_PASSWORD:
      return <ModalProfileForgotPassword modal={props.modal} step={ForgotPasswordStep.ENTER_EMAIL} />;
    case ModalType.PROFILE_FORGOT_PASSWORD_EMAILED:
      return <ModalProfileForgotPassword modal={props.modal} step={ForgotPasswordStep.PASSWORD_EMAILED} />;
    case ModalType.PROFILE_FORGOT_PASSWORD_CONFIRM_TO_SEND_EMAIL:
      return <ModalProfileForgotPassword modal={props.modal} step={ForgotPasswordStep.CONFIRM_TO_SEND_EMAIL} />;
    case ModalType.PROFILE_FORGOT_PASSWORD_EXPIRED_LINK:
      return <ModalProfileForgotPassword modal={props.modal} step={ForgotPasswordStep.EXPIRED_LINK} />;
    case ModalType.PROFILE_RESET_PASSWORD:
      return <ModalProfileResetPassword modal={props.modal} />;

    case ModalType.WEB_WIDGET_THEME_SETTINGS:
      return <ModalWebWidgetThemeSettings modal={props.modal} />;
    case ModalType.WEB_WIDGET_WELCOME_MESSAGE_SETTINGS:
      return <ModalWebWidgetWelcomeMessageSettings modal={props.modal} />;
    case ModalType.WEB_WIDGET_SNIPPET_SETTINGS:
      return <ModalWebWidgetSnippetSettings modal={props.modal} />;

    case ModalType.CHAT_UNBLOCKING:
      return <ModalChatUnblocking modal={props.modal} />;

    case ModalType.EDIT_GROUP:
      return <ModalEditGroup modal={props.modal} />;
    // case ModalType.EDIT_INTERNAL_GROUP:
    //   return <ModalEditInternalGroup modal={props.modal} />;
    // case ModalType.GROUP_CREATING:
    //   return <ModalGroupCreating modal={props.modal} />;
    // case ModalType.CHAT_CREATING:
    //   return <ModalChatCreating modal={props.modal} />;
    // case ModalType.INTERNAL_CHAT_CREATING:
    //   return <ModalInternalChatCreating modal={props.modal} />;

    // case ModalType.INVITE_TO_CHAT_VIA_LINK:
    //   return <ModalInviteToChatViaLink modal={props.modal} />;
    // case ModalType.SHARE_INVITE_LINK:
    //   return <ModalShareInviteLink modal={props.modal} />;

    // case ModalType.CREATED_INVITATION_LINK:
    //   return <ModalCreatedInvitationLink modal={props.modal} />;

    case ModalType.FORCE_VERSION_UPDATE_APP:
      return <ModalForceVersionUpdateApp modal={props.modal} />;

    case ModalType.INVITE_MEMBER_BY_EMAIL:
      return <ModalInviteMemberByEmail modal={props.modal} />;
    case ModalType.INVITE_MEMBER_BY_LINK:
      return <ModalInviteMemberByLink modal={props.modal} />;
    case ModalType.WORKSPACE_CREATING:
      return <ModalWorkspaceCreating modal={props.modal} />;

    case ModalType.BILLING_APPLY_TARIFF_WARNING:
      return <ModalApplyTariffWarning modal={props.modal} />;
    case ModalType.BILLING_FREE_PLAN_ACTIVATED:
      return <ModalFreePlanActivated modal={props.modal} />;
    case ModalType.BILLING_TARIFF_PAY_WITH_MEMBERS:
      return <ModalTariffPayWithMembersBlock modal={props.modal} />;
  }

  return null;
});

export default ModalWrapper;
