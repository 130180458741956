import {observer} from 'mobx-react';
import React from 'react';

import MenuList from 'o-ui/Select/MenuList';
import {WorkspacePathType} from '../../../routes/Paths';
import SettingsTab from '../../../stores/LayOutStore/SettingsTab';
import useStore from '../../../stores/useStore';
import SettingsMenuItem from './SettingsMenuItem';

interface IProps {
  selectedTab: SettingsTab | null;
  onClick?: (path: WorkspacePathType) => void;
}

export const SettingsMenu: React.FC<IProps> = observer((props) => {
  const {activeWorkspace, userStore} = useStore();

  return (
    <MenuList className="settings-menu">
      <SettingsMenuItem tab={SettingsTab.PERSONAL_INFO} onClick={props.onClick} />
      <SettingsMenuItem
        tab={SettingsTab.CHANNELS}
        onClick={props.onClick}
        hidden={!userStore.isOwner && !userStore.isAdmin}
      />
      <SettingsMenuItem
        tab={SettingsTab.WORKSPACE_MEMBERS}
        onClick={props.onClick}
        hidden={!userStore.isOwner && !userStore.isAdmin}
      />
      <SettingsMenuItem tab={SettingsTab.SNIPPETS} onClick={props.onClick} />
      <SettingsMenuItem tab={SettingsTab.WORKSPACE_SETTINGS} onClick={props.onClick} hidden={!userStore.isOwner} />

      <SettingsMenuItem className="d-none" tab={SettingsTab.MESSAGE_TAGS} onClick={props.onClick} />
      <SettingsMenuItem
        tab={SettingsTab.BILLING}
        onClick={props.onClick}
        hidden={!userStore.isOwner && !userStore.isAdmin || !activeWorkspace.billing.hasAccess}
      />
    </MenuList>
  );
});

export default SettingsMenu;
