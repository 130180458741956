import Long from 'long';


export const randomUint8Array = (): Uint8Array => {
  const res = new Uint8Array(16);
  const rnd = crypto.getRandomValues(res);
  rnd[6] = (rnd[6] & 0x0f) | 0x40;
  rnd[8] = (rnd[8] & 0x3f) | 0x80;
  return rnd;
};

export function emptyUnit8Array(buf?: Uint8Array | null): boolean {
  if (!buf) {
    return true;
  }
  const dv = new Int8Array(buf);
  for (let i = 0; i !== buf.byteLength; i++) {
    if (dv[i] > 0) {
      return false;
    }
  }
  return true;
}

export function equalUint8Arrays(buf1?: Uint8Array | null, buf2?: Uint8Array | null): boolean {
  if (!buf1 || !buf2 || buf1.byteLength != buf2.byteLength) {
    return false;
  }
  const dv1 = new Int8Array(buf1);
  const dv2 = new Int8Array(buf2);
  for (let i = 0; i !== buf1.byteLength; i++) {
    if (dv1[i] != dv2[i]) {
      return false;
    }
  }
  return true;
}

export function equalArraysOfUint8Arrays(array1?: Uint8Array[] | null, array2?: Uint8Array[] | null): boolean {
  if (!array1 || !array2 || array1.length != array2.length) {
    return false;
  }

  const length = array1.length;
  for (let i = 0; i !== length; i++) {
    if (!equalUint8Arrays(array1[i], array2[i])) {
      return false;
    }
  }
  return true;
}

export function uint8ArrayToString(uint8Array?: Uint8Array | null): string {
  return uint8Array ? new TextDecoder().decode(uint8Array) : '';
}

export function stringToUint8Array(arrayString: string): Uint8Array | null {
  return arrayString ? new TextEncoder().encode(arrayString) : null;
}

export function uint8ArrayToJSON(uint8Array: Uint8Array): string {
  const arr = Array.from
    ? Array.from(uint8Array)
    : [].map.call(uint8Array, (v => v));
  return JSON.stringify(arr);
}

export function jsonToUint8Array(arrayString: string): Uint8Array {
  const retrievedArray = JSON.parse(arrayString);
  return new Uint8Array(retrievedArray);
}

export function uint8ArrayToBase64(uint8Array?: Uint8Array | null): string {
  if (!uint8Array) {
    return '';
  }

  let binary = '';
  const len = uint8Array.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(uint8Array[i]);
  }

  return window.btoa(binary);
}

function insertString(str, index, value) {
  return str.substr(0, index) + value + str.substr(index);
}

function formatUUID(str) {
  let _uuid = insertString(str, 8, '-')
  _uuid = insertString(_uuid, 13, '-')
  _uuid = insertString(_uuid, 18, '-')
  _uuid = insertString(_uuid, 23, '-')

  return _uuid
}

function digitToHex(digit: number) {
  const hex = digit.toString(16);
  return hex.length < 2 ? '0' + hex : hex;
}

export function uint8ArrayToUuid(uint8Array?: Uint8Array | null): string {
  if (!uint8Array) {
    return '';
  }

  let binary = '';
  let i = 0;
  const len = uint8Array.byteLength;
  for (; i < len; i++) {
    binary += digitToHex(uint8Array[i]);
  }

  if (binary.length !== 32) {
    console.error(`uint8ArrayToUuid: invalid uuid length=${binary.length} byteLength=${len}`, binary);
  }

  if (formatUUID(binary).length !== 36) {
    console.error(`uint8ArrayToUuid: invalid formatUUID length=${formatUUID(binary).length} byteLength=${len}`, formatUUID(binary));
  }

  return formatUUID(binary);
}

export function uuidToUint8Array(uuid?: string | null, hexDigitsNumber = 32): Uint8Array | null {
  if (!uuid) {
    return null;
  }

  const pureUuid = uuid.replace(/-/g, '');

  if (pureUuid.length !== hexDigitsNumber) {
    console.error(`uuidToUint8Array: invalid uuid length=${pureUuid.length}`, uuid);
    return null;
  }

  const twoSymbolsChunks = chunkString(pureUuid, 2);

  let i = 0;
  const len = twoSymbolsChunks.length;
  const bytes = new Uint8Array(len);
  for (; i < len; i++) {
    bytes[i] = parseInt(twoSymbolsChunks[i], 16)
  }

  return bytes;
}

export function chunkString(str: string, length: number): string[] {
  return str.match(new RegExp('.{1,' + length + '}', 'g')) || [];
}

export function base64ToUint8Array(base64?: string | null): Uint8Array | null {
  if (!base64) {
    return null;
  }

  try {
    const binary = window.atob(base64);
    const len = binary.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return bytes;
  } catch (e) {
    console.error(base64, e);
    return null;
  }
}

export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array))
      return l;
  }
  return -1;
}

export function swapElements<T>(array: Array<T>, index1: number, index2: number): Array<T> {
  array[index1] = array.splice(index2, 1, array[index1])[0];
  return array;
}

export function concatUint8Array(arrays: Array<ArrayLike<number>>, length?: number): Uint8Array {
  if (length == null) {
    length = arrays.reduce((acc, curr) => acc + curr.length, 0)
  }

  const output = allocUnsafe(length)
  let offset = 0

  for (const arr of arrays) {
    output.set(arr, offset)
    offset += arr.length
  }

  return asUint8Array(output)
}

export function alloc(size: number = 0): Uint8Array {
  if (globalThis.Buffer?.alloc != null) {
    return asUint8Array(globalThis.Buffer.alloc(size))
  }

  return new Uint8Array(size)
}

/**
 * Where possible returns a Uint8Array of the requested size that references
 * uninitialized memory. Only use if you are certain you will immediately
 * overwrite every value in the returned `Uint8Array`.
 */
export function allocUnsafe(size: number = 0): Uint8Array {
  if (globalThis.Buffer?.allocUnsafe != null) {
    return asUint8Array(globalThis.Buffer.allocUnsafe(size))
  }

  return new Uint8Array(size)
}
export function asUint8Array(buf: Uint8Array): Uint8Array {
  if (globalThis.Buffer != null) {
    return new Uint8Array(buf.buffer, buf.byteOffset, buf.byteLength)
  }

  return buf
}

export function sliceIntoChunks<T = any>(arr: Array<T>, chunkSize: number): Array<Array<T>> {
  const res: Array<Array<T>> = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

export function uniqueMergeLongArrays(arr1: Long[], arr2: Long[]) {
  const idsMap = new Map();
  arr1.concat(arr2).forEach((id) => {
    const exists = idsMap.has(id.toString());

    if (!exists) {
      idsMap.set(id.toString(), id);
    }
  });

  return Array.from(idsMap.values());
}

export function uniqueMergeByLongIdArrays<T extends {id?: Long | null}>(arr1: T[], arr2: T[]): T[] {
  const objectsMap = new Map<string, T>();

  arr1.concat(arr2).forEach((o) => {
    const idStr = (o.id || Long.ZERO).toString();
    const exists = objectsMap.has(idStr);

    if (!exists) {
      objectsMap.set(idStr, o);
    }
  });

  return Array.from(objectsMap.values());
}

export function equalLongArrays(array1?: Long[] | null, array2?: Long[] | null): boolean {
  const arr1 = array1 || [];
  const arr2 = array2 || [];
  const arr1Length = arr1.length;
  const arr2Length = arr2.length;

  if (arr1Length !== arr2Length) {
    return false;
  }
  
  for (let i = 0; i !== arr1Length; i++) {
    if (!arr1[i] || !arr2[i] || !arr1[i]?.equals(arr2[i])) {
      return false;
    }
  }

  return true;
}

export function diffLongArrays(array1?: Long[] | null, array2?: Long[] | null): Long[] {
  const arr1 = array1 || [];
  const arr2 = array2 || [];
  const arr1Length = arr1.length;
  const arr2Length = arr2.length;

  if (!arr1Length || !arr2Length) {
    return arr1;
  }

  const diff = arr1.filter((id) => !arr2.some((id2) => id.equals(id2)));

  return diff;
}
