import {makeObservable} from 'mobx';

import {
  IMCChannelShownFields,
  IMCMethodResponse,
  IWebWidgetConfig,
  MCCreateChannel,
  MCCreateChannelResponse,
  MCMethod,
  WebWidgetChannelState,
  WebWidgetCreate,
  api,
  entities,
} from '../../../api/proto';
import i18n from '../../../i18n';
import APILayer from '../../APILayer';
import Channel from '../Channel';
import ChannelsStore from '../ChannelsStore';

export const getWebWidgetChannelStateTitle = (state?: WebWidgetChannelState.State | null): string => {
  switch (state) {
    case WebWidgetChannelState.State.CREATE_CHANNEL:
      return i18n.t('settings_channel_manage_status_launch');

    default:
      return '-';
  }
};

export class WebWidget extends APILayer {
  constructor(public channels: ChannelsStore) {
    super(channels.workspace.app);
    makeObservable(this);
  }

  createWidget = async (data: {config: IWebWidgetConfig; shownFields?: IMCChannelShownFields | null}) => {
    if (this.loading) {
      return {error: {message: 'In process'}, res: null};
    }

    const {error, res} = await this.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.channels.workspace.id,
          action: new MCMethod({
            createChannel: new MCCreateChannel({
              type: entities.OzekonChannelType.OIT_WEB_WIDGET,
              shownFields: data.shownFields,
              webWidgetFlow: new WebWidgetCreate({
                config: data.config,
              }),
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    const createChannelRes = res?.methodResponse?.createChannel;

    let newChannel: Channel | null = null;

    if (createChannelRes) {
      newChannel = new Channel(
        {
          channelID: createChannelRes?.channelID,
          shownFields: data.shownFields,
          type: entities.OzekonChannelType.OIT_WEB_WIDGET,
          workspaceID: this.channels.workspace.id,
          state: {
            webWidget: {
              config: {
                ...data.config,
                APICode: createChannelRes.webWidgetFlow?.APICode,
              },
            },
          },
        },
        this.channels.workspace,
      );

      this.channels.workspace.refreshMembers();
      this.channels.workspace.app.anal.channelSetupEvent(newChannel);
    }

    if (!error) {
      this.channels.workspace.refreshMembers();
    }

    this.processReloadChannels_(res?.methodResponse);

    return {error, res: createChannelRes, channel: newChannel};
  };

  private processReloadChannels_ = (methodResponse?: IMCMethodResponse | null) => {
    if (methodResponse?.createChannel?.result !== MCCreateChannelResponse.Result.R_INTERNAL_SERVER_ERROR) {
      this.channels.load();
    }
  };
}

export default WebWidget;
