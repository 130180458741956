import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import Button, {ToggleButton, ToggleButtonGroup} from 'o-ui/Button';
import DialogActions from 'o-ui/Dialog/DialogActions';
import DialogContent from 'o-ui/Dialog/DialogContent';
import FormControl from 'o-ui/FormControl';
import FormHelperText from 'o-ui/FormHelperText';
import FormLabel from 'o-ui/FormLabel';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';

import {MAX_SNIPPET_TEXT_LENGTH, SNIPPET_SHORTCUT_MAX_LENGTH, SNIPPET_SHORTCUT_MIN_LENGTH} from '../constants';
import {QuillTextInput} from '../components/QuillTextInput';
import QuillEditor from '../components/QuillTextInput/QuillEditor';
import {useStore} from '../stores/AppStore';
import {SHORTCUT_SYMBOL} from '../stores/EditorStore';
import convertTextEntitiesToDelta from '../stores/EditorStore/utils/convertTextEntitiesToDelta';
import {ModalItem} from '../stores/ModalsStore';
import {SNIPPET_SHORTCUT_REGEXP} from '../stores/Snippets';
import {DEFAULT_SNIPPET_TYPE, SnippetType} from '../stores/Snippets/SnippetsStore';
import {ModalDialog, ModalDialogRef} from './components/ModalDialog';
import QuillTextCounter from './components/QuillTextCounter';

interface IProps {
  modal: ModalItem;
}

export const ModalSnippetEditing = observer(({modal}: IProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);
  const titleInputRef = React.useRef<HTMLInputElement>(null);

  const {userStore, activeWorkspace, notifications} = useStore();
  const {snippet} = modal.data;

  const [snippetType, setSnippetType] = React.useState<SnippetType>(snippet?.type || DEFAULT_SNIPPET_TYPE);

  const snippets = activeWorkspace.getSnippetsStore(snippetType);

  const measuredRef = React.useCallback(
    (node: QuillEditor | null) => {
      snippets.editor?.setEditorRef(node);
    },
    [snippets.editor],
  );

  React.useEffect(() => {
    snippets.editor?.setContents(convertTextEntitiesToDelta(snippet?.text || '', snippet?.entities || []));
  }, [snippets.editor, snippets.editor?.quill, snippet]);

  const canChangeSnippetType = userStore.isAdmin || userStore.isOwner;

  const formik = useFormik({
    initialValues: {
      command: snippet?.command?.replace(SHORTCUT_SYMBOL, '') || '',
    },
    validateOnBlur: true,
    validationSchema: Yup.object({
      command: Yup.string()
        .required(t('modal_edit_snippet_shortcut_required_validation'))
        .max(
          SNIPPET_SHORTCUT_MAX_LENGTH,
          t('modal_edit_snippet_shortcut_max_length', {maxLength: SNIPPET_SHORTCUT_MAX_LENGTH}),
        )
        .trim()
        .matches(
          SNIPPET_SHORTCUT_REGEXP,
          t('modal_edit_snippet_wrong_shortcut_format', {
            symbol: SHORTCUT_SYMBOL,
            from: SNIPPET_SHORTCUT_MIN_LENGTH,
            to: SNIPPET_SHORTCUT_MAX_LENGTH,
          }),
        )
        .test({
          message: t('modal_edit_snippet_same_shortcut_value'),
          test: (value) => {
            return snippets.list
              .filter((s) => snippet?.id.notEquals(s.id))
              .every((snippet) => snippet.command?.replace(SHORTCUT_SYMBOL, '') !== value);
          },
        }),
    }),
    onSubmit: async ({command}) => {
      if (snippet?.type && snippet.type !== snippetType) {
        activeWorkspace.getSnippetsStore(snippet.type).delete(snippet);
      }

      const {res} = await snippets.update(snippet?.id, {
        command: SHORTCUT_SYMBOL + command.trim(),
        text: snippets.editor?.text || '',
        entities: snippets.editor?.textEntities,
      });

      if (res) {
        notifications.success(t('notification_snippet_update_success', {shortCode: command}));
      }

      handleCloseClick();
    },
  });

  const {validateField} = formik;

  React.useEffect(() => {
    validateField('command');
  }, [validateField, snippets.list]);

  const focusOnTitleInput = () => {
    titleInputRef.current?.focus();
  };

  const handleChangeType = (e: React.MouseEvent, value: SnippetType) => {
    setSnippetType(value);
    focusOnTitleInput();
  };

  const handleChangeShortcut = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setValues({
      command: e.target.value.replace(/\s/g, ''),
    });
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  return (
    <ModalDialog ref={modalRef} modal={modal} title={t('modal_edit_snippet_title')} maxWidth="sm" fullWidth={true}>
      <form className="overflow-auto custom-scroll-y" onSubmit={formik.handleSubmit}>
        <DialogContent className="p-4">
          {canChangeSnippetType ? (
            <div>
              <FormLabel className="body3-regular text-capitalize d-flex mb-1">
                {t('modal_edit_snippet_type_label')}
              </FormLabel>
              <FormControl className="mb-3" variant="outlined">
                <ToggleButtonGroup className="w-50" value={snippetType} onChange={handleChangeType}>
                  <ToggleButton className="col-6" value={SnippetType.Workspace}>
                    {t('modal_edit_snippet_workspace_type')}
                  </ToggleButton>
                  <ToggleButton className="col-6" value={SnippetType.Personal}>
                    {t('modal_edit_snippet_personal_type')}
                  </ToggleButton>
                </ToggleButtonGroup>
                <FormHelperText className="mt-2">{t('modal_edit_snippet_type_helper_text_about_type')}</FormHelperText>
              </FormControl>
            </div>
          ) : null}

          <div>
            <FormLabel className="body3-regular text-capitalize d-flex mb-1">
              {t('modal_edit_snippet_shortcut_label')}
            </FormLabel>
            <FormControl className="w-100 mb-3" variant="outlined">
              <OutlinedTextInput
                inputRef={titleInputRef}
                name="command"
                inputClassName="pl-1"
                type="text"
                placeholder={t('modal_edit_snippet_shortcut_placeholder')}
                className="w-100"
                value={formik.values.command}
                onChange={handleChangeShortcut}
                onBlur={formik.handleBlur}
                errorHelper={formik.errors.command || ''}
                autoFocus
                startAdornment={<span className="font-size-18">{SHORTCUT_SYMBOL}</span>}
              />
            </FormControl>
          </div>
          <div>
            <FormLabel className="body3-regular text-capitalize d-flex mb-1">
              {t('modal_edit_snippet_text_label')}
            </FormLabel>
            <FormControl className="w-100 mb-3" variant="outlined">
              <div id="editSnippetText" className="text-input form-field">
                <QuillTextInput
                  ref={measuredRef}
                  className="w-100 form-field position-relative"
                  placeholder={t('modal_edit_snippet_text_placeholder')}
                  defaultValue={snippets.editor?.contents}
                  bounds="#editSnippetText"
                />
                <QuillTextCounter quill={snippets.editor?.quill} maxTextLength={MAX_SNIPPET_TEXT_LENGTH} />
              </div>
            </FormControl>
          </div>
        </DialogContent>

        <DialogActions className="justify-content-end">
          <Button
            className="mr-auto"
            variant="contained"
            color="error"
            size="large"
            onClick={() => modal.trigger('delete')}
          >
            {t('modal_delete_btn')}
          </Button>

          <Button variant="contained" color="secondary" size="large" onClick={handleCloseClick}>
            {t('modal_cancel_btn')}
          </Button>
          <Button variant="contained" color="primary" size="large" type="submit" disabled={!formik.isValid}>
            {t('modal_save_btn')}
          </Button>
        </DialogActions>
      </form>
    </ModalDialog>
  );
});

export default ModalSnippetEditing;
