import configJson from './config.json';

export const VIDEO_MOV_TYPE = 'video/quicktime';
export const VIDEO_WEBM_TYPE = 'video/webm';

export const SUPPORTED_IMAGE_CONTENT_TYPES = new Set([
  'image/png',
  'image/gif',
  'image/jpeg',
]);

export const SUPPORTED_VIDEO_CONTENT_TYPES = new Set(['video/mp4']);

export const SUPPORTED_AUDIO_CONTENT_TYPES = new Set([
  'audio/mp3',
  'audio/ogg',
  'audio/wav',
  'audio/mpeg',
  'audio/flac',
  'audio/aac',
  'audio/m4a',
  'audio/mp4',
  'audio/x-m4a',
]);

export const CONTENT_TYPES_WITH_PREVIEW = new Set([
  ...SUPPORTED_IMAGE_CONTENT_TYPES,
  ...SUPPORTED_VIDEO_CONTENT_TYPES,
]);

export const MAX_FILE_UPLOAD_MB = 50;
export const MAX_IMAGE_UPLOAD_MB = 10;
export const MAX_IMAGE_UPLOAD_WIDTH_HEIGHT_SUM = 10000;
export const MAX_IMAGE_UPLOAD_ASPECT_RATIO = 20;
export const MIN_IMAGE_UPLOAD_ASPECT_RATIO = 0.05;
export const MAX_VIDEO_AUTOPLAY_BYTES = 10 * 1024 * 1024;

export const TELEGRAM_MEDIA_CAPTION_LENGTH_LIMIT = 1024;

export const MAX_WIDTH_FOR_ENABLE_EMBEDDED_LEFT_BAR = 1024;

export const TELEGRAM_PUBLIC_CHAT_LINK = 'https://t.me/';


class Config {
  BRAND_NAME: string = '';
  API_DOCUMENTATION_URL: string = '';

  constructor(config?: any) {
    if (config) {
      Object.assign(this, config);
    }
  }
}

const config = new Config(configJson);

export default config;

export const BRAND_NAME = config.BRAND_NAME;

export const API_DOCUMENTATION_URL = config.API_DOCUMENTATION_URL;
