import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';

import {api, entities} from '../../api/proto';
import {formatUserName, getTimeDiff} from '../../utils/format';
import formatIdTitle from '../../utils/format/formatIdTitle';
import {formatInviteUrl} from '../../utils/formatUrl';
import TimerStore from '../TimerStore';
import Invite from './Invite';
import WorkspaceMemberConfig from './WorkspaceMemberConfig';
import WorkspaceStore, {getUserRoleTitle} from './WorkspaceStore';
import getErrorByInviteResendResult from './utils/getErrorByInviteResendResult';
import getErrorByUpdateMemberResult from './utils/getErrorByUpdateMemberResult';


export class WorkspaceMember {
  constructor(public raw: entities.IWorkspaceMember, private workspace: WorkspaceStore) {
    makeObservable(this);

    this.assign_(raw);
    this.config = new WorkspaceMemberConfig(this.workspace, this);
  }

  @action protected assign_ = (props: entities.IWorkspaceMember) => {
    Object.assign(this, props);
    this.raw = {...this.raw, ...props};
  };

  @action public assign = (props: entities.IWorkspaceMember) => {
    this.assign_(props);
    this.config = new WorkspaceMemberConfig(this.workspace, this);
  };

  @action public set = (props: entities.IWorkspaceMember) => {
    this.id = props.id;
    this.profile = props.profile;
    this.role = props.role;
    this.status = props.status;
    this.blockReason = props.blockReason;
    this.createdAt = props.createdAt;
    this.source = props.source;
    this.access = props.access;
    this.opts = props.opts;
    this.permissions = props.permissions;

    this.raw = props;

    this.config = new WorkspaceMemberConfig(this.workspace, this);
  };

  @observable id?: (Long | null);

  @observable profile?: (entities.IUserShortProfile | null);

  @observable role?: (entities.WorkspaceRole | null);

  @observable status?: (entities.WorkspaceMemberStatus | null);

  @observable blockReason?: (entities.WorkspaceMemberBlockReason | null);

  @observable createdAt?: (Long | null);

  @observable opts?: (entities.IWorkspaceMemberOpt[] | null);

  @observable source?: (entities.WorkspaceMemberSource | null);

  @observable permissions?: (entities.WorkspaceMemberPermission[] | null);

  @observable access?: (entities.IWorkspaceMemberAccess | null);


  static getDisplayName(member?: WorkspaceMember | null): string {
    return formatUserName(member?.firstName, member?.lastName)
      || member?.email?.toString()
      || (member?.profile?.id?.equals(Long.ZERO) ? '' : formatIdTitle(member?.profile?.id))
      || (member?.id?.equals(Long.ZERO) ? '' : formatIdTitle(member?.id))
      || ''
      ;
  }

  @computed get roleTitle(): string {
    return getUserRoleTitle(this.role);
  }

  @computed get fullName(): string {
    return formatUserName(this.firstName, this.lastName) || this.email?.toString() || '';
  }

  @computed get displayName(): string {
    return this.fullName
      || this.inviteTargetEmail?.toString()
      || (this.profile?.id?.equals(Long.ZERO) ? '' : formatIdTitle(this.profile?.id))
      || (this.id?.equals(Long.ZERO) ? '' : formatIdTitle(this.id))
      || ''
      ;
  }



  @computed get firstName(): string | null {
    return this.profile?.fields?.find((field) => field.type === entities.UserProfileFieldType.UPFT_FIRST_NAME)?.value?.strValue ?? null;
  }

  @computed get lastName(): string | null {
    return this.profile?.fields?.find((field) => field.type === entities.UserProfileFieldType.UPFT_LAST_NAME)?.value?.strValue ?? null;
  }

  @computed get userId(): Long | null {
    return this.profile?.id?.equals(Long.ZERO) ? null : (this.profile?.id || null);
  }

  @computed get activatedAt(): Long | null {
    return this.opts?.find((opt) => opt.type === entities.WorkspaceMemberOptType.WMOT_ACTIVATED_AT)?.value?.uintValue ?? null;
  }

  @computed get activated(): boolean {
    return !!this.activatedAt?.greaterThan(0);
  }

  @computed get invitedAt(): Long | null {
    return this.opts?.find((opt) => opt.type === entities.WorkspaceMemberOptType.WMOT_INVITED_AT)?.value?.uintValue ?? null;
  }

  @computed get inviteLink(): string {
    return this.opts?.find((opt) => opt.type === entities.WorkspaceMemberOptType.WMOT_INVITE_LINK)?.value?.strValue ?? '';
  }

  @computed get inviteUrl(): string {
    return this.inviteLink;
  }

  @computed get emailInvite(): Invite | null {
    return this.workspace.invites.emailInvites.find((inv) => this?.id && inv.boundToMemberId.equals(this.id)) || null;
  }

  @computed get emailInviteUrl(): string {
    return this.emailInvite?.id ? formatInviteUrl(this.emailInvite.id) : '';
  }

  @computed get email(): string | null {
    return (
      this.profile?.fields?.find((field) => field.type === entities.UserProfileFieldType.UPFT_EMAIL)?.value?.strValue ??
      this.opts?.find((opt) => opt.type === entities.WorkspaceMemberOptType.WMOT_INVITE_TARGET_EMAIL)?.value?.strValue ??
      this.emailInvite?.email ??
      null
    );
  }

  @computed get inviteCode(): string {
    return this.opts?.find((opt) => opt.type === entities.WorkspaceMemberOptType.WMOT_INVITE_CODE)?.value?.strValue ?? '';
  }

  @computed get inviteTargetEmail() {
    return this.opts?.find((opt) => opt.type === entities.WorkspaceMemberOptType.WMOT_INVITE_TARGET_EMAIL)?.value?.strValue ?? '';
  }

  @computed get invitedBy() {
    return this.opts?.find((opt) => opt.type === entities.WorkspaceMemberOptType.WMOT_INVITED_BY)?.value?.uintValue ??
      this.emailInvite?.inviterMemberId ??
      null;
  }

  @computed get invitedByMember() {
    return this.workspace.findMember(this.invitedBy);
  }

  @computed get inviteExpireAt() {
    return this.opts?.find((opt) => opt.type === entities.WorkspaceMemberOptType.WMOT_INVITE_EXPIRE_AT)?.value?.uintValue ?? null;
  }

  @computed get inviteExpired(): boolean {
    return getTimeDiff(this.inviteExpireAt) > 0;
  }

  @computed get invitedByEmail(): boolean {
    return this.source === entities.WorkspaceMemberSource.WIT_EMAIL_INVITE;
  }

  @computed get invitedByLink(): boolean {
    return this.source === entities.WorkspaceMemberSource.WIT_LINK_INVITE;
  }

  @computed get active(): boolean {
    return !this.status;
  }

  @computed get blocked(): boolean {
    return this.status === entities.WorkspaceMemberStatus.WM_BLOCKED;
  }

  @computed get invited(): boolean {
    return this.status === entities.WorkspaceMemberStatus.WM_INVITED;
  }

  @computed get expired(): boolean {
    return this.status === entities.WorkspaceMemberStatus.WM_INVITE_EXPIRED;
  }

  @computed get isAvailable(): boolean {
    return this.active && !this.blocked && !this.expired;
  }

  @computed get isOwner(): boolean {
    return this.role === entities.WorkspaceRole.WR_OWNER;
  }

  @computed get isAdmin(): boolean {
    return this.role === entities.WorkspaceRole.WR_ADMIN;
  }

  @computed get isOperator(): boolean {
    return !this.role || this.role === entities.WorkspaceRole.WR_OPERATOR;
  }

  @computed get isActiveUser(): boolean {
    return !!(this.userId && this.workspace.app.userStore.userId?.equals(this.userId));
  }

  update = async (props: api.IWorkspaceUpdateMemberRequest) => {
    const {error, res} = await this.workspace.workspaceRequest<api.IWorkspaceUpdateMemberResponse>(
      {
        updateMember: {
          memberId: this.id,
          newRole: this.role,
          newStatus: this.status,
          // newCustomPermissions: this.permissions,
          ...props,
        },
      },
      'updateMember',
    );

    let message: string | null | undefined = null;

    if (res) {
      message = getErrorByUpdateMemberResult(res.status);
    } else if (error) {
      message = error.message;
    }

    return {error: message ? {message} : null, res};
  };

  changeRole = async (newRole?: entities.WorkspaceRole | null) => this.update({newRole});

  block = async () => this.update({
    newStatus: entities.WorkspaceMemberStatus.WM_BLOCKED,
    newAccess: {
      records: this.config.rawChannelAccessRecords,
    },
  });

  unblock = async () => this.update({
    newStatus: entities.WorkspaceMemberStatus.WM_ACTIVE,
    newAccess: {
      records: this.config.rawChannelAccessRecords,
    },
  });

  resendRegistrationEmailTimer = new TimerStore();

  resendRegistrationEmail = async () => {
    const {error, res} = await this.workspace.workspaceRequest<api.IWorkspaceResendInviteResponse>(
      {
        resendInvite: {
          inviteID: this.emailInvite?.id,
        },
      },
      'resendInvite',
    );

    let message: string | null | undefined = null;

    if (res) {
      message = getErrorByInviteResendResult(res.status);
      this.resendRegistrationEmailTimer.runByNextTryAtTimestamp(res.nextTryTimestamp);
    } else if (error) {
      message = error.message;
    }

    return {error: message ? {message} : null, res};
  };

  delete = async () => this.update({
    newStatus: entities.WorkspaceMemberStatus.WM_REMOVED,
    newAccess: {
      records: this.config.rawChannelAccessRecords,
    },
  });

  @observable config: WorkspaceMemberConfig;

  clone = (): WorkspaceMember => {
    return new WorkspaceMember({...this.raw}, this.workspace);
  };

  saveConfig = async () => {
    return await this.update({
      newRole: this.role,
      newAccess: {
        records: this.config.rawChannelAccessRecords,
      },
    });
  };
}

export default WorkspaceMember;
