import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import FormControl from 'o-ui/FormControl';
import FormControlLabel from 'o-ui/FormControlLabel';
import Switch from 'o-ui/Switch';
import Tab from 'o-ui/Tab';
import Tabs from 'o-ui/Tabs';
import {WorkspaceMemberAccessRecord} from '../../../../stores/Workspaces/WorkspaceMemberAccessRecord';
import WorkspaceMemberConfig from '../../../../stores/Workspaces/WorkspaceMemberConfig';
import {ChannelsAndChatsAccess, ChannelsAndChatsAccessRef} from '../ChannelsAndChatsAccess';

interface Props {
  className?: string;
  memberConfig: WorkspaceMemberConfig;
  onClose(): void;
}
export enum PermissionsTabType {
  CHANNELS_CHATS,
  PERMISSION,
}

const InvitationAccessForm: React.FC<Props> = observer((props) => {
  const channelsAndChatsAccessRef = React.useRef<ChannelsAndChatsAccessRef>(null);
  const {t} = useTranslation();

  const [selectedPermissionsTab, setSelectedPermissionsTab] = React.useState<PermissionsTabType>(
    PermissionsTabType.CHANNELS_CHATS,
  );

  const [showTabs, setShowTabs] = React.useState(true);

  const [memberConfig] = React.useState<WorkspaceMemberConfig>(props.memberConfig.copy());

  const handleChangeTab = (e: React.ChangeEvent, newValue: number) => {
    setSelectedPermissionsTab(newValue);
  };

  const handleClose = () => {
    if (channelsAndChatsAccessRef.current?.selectedAccessRecord) {
      channelsAndChatsAccessRef.current?.backToChannels();
    } else {
      props.onClose();
    }
  };

  const handleSave = () => {
    props.memberConfig.fill(memberConfig);
    props.onClose();
  };

  const handleToggleAccessRecord = (record: WorkspaceMemberAccessRecord | null) => {
    setShowTabs(!record);
  };

  return (
    <div className={cn('access-tree-select__wrapper', props.className)}>
      {showTabs ? (
        <Tabs
          className="border-bottom mb-5"
          variant="outlined"
          value={selectedPermissionsTab}
          onChange={handleChangeTab}
        >
          <Tab
            label={
              <span className="d-flex align-items-center">
                {t('settings_workspace_members_invitation_channels_permissions_tab')}
              </span>
            }
          />
          <Tab
            label={
              <span className="d-flex align-items-center">
                {t('settings_workspace_members_invitation_permissions_tab')}
              </span>
            }
          />
        </Tabs>
      ) : null}

      <div style={{height: showTabs ? 475 : 515}}>
        {selectedPermissionsTab === PermissionsTabType.CHANNELS_CHATS ? (
          <ChannelsAndChatsAccess
            ref={channelsAndChatsAccessRef}
            memberConfig={memberConfig}
            onToggleAccessRecord={handleToggleAccessRecord}
          />
        ) : (
          <>
            <FormControl className="w-100 outline-default mt-5" variant="outlined" disabled>
              <FormControlLabel
                className="justify-content-end m-0"
                label={t<string>('settings_workspace_members_manage_access_to_contact_information')}
                labelPlacement="start"
                control={
                  <Switch
                    className="ml-auto"
                    name="active"
                    checked={memberConfig.accessToContactInfo}
                    onChange={memberConfig.toggleAccessToContactInfo}
                  />
                }
              />
              <div className="body3-regular color-body-secondary mb-3 mt-n3">
                {t('settings_workspace_members_manage_access_to_contact_information_description')}
              </div>
            </FormControl>

            <FormControl className="w-100 outline-default mt-5" variant="outlined" disabled>
              <FormControlLabel
                className="justify-content-end m-0"
                label={t<string>('settings_workspace_members_manage_access_to_contacts')}
                labelPlacement="start"
                control={
                  <Switch
                    className="ml-auto"
                    name="active"
                    checked={memberConfig.accessToContactsList}
                    onChange={memberConfig.toggleAccessToContactsList}
                  />
                }
              />
              <div className="body3-regular color-body-secondary mb-3 mt-n3">
                {t('settings_workspace_members_manage_access_to_contacts_description')}
              </div>
            </FormControl>

            <FormControl className="w-100 outline-default mt-5" variant="outlined" disabled>
              <FormControlLabel
                className="justify-content-end m-0"
                label={t<string>('settings_workspace_members_manage_allow_manage_chats')}
                labelPlacement="start"
                control={
                  <Switch
                    className="ml-auto"
                    name="active"
                    checked={memberConfig.allowManageChats}
                    onChange={memberConfig.toggleAllowManageChats}
                  />
                }
              />
              <div className="body3-regular color-body-secondary mb-3 mt-n3">
                {t('settings_workspace_members_manage_allow_manage_chats_description')}
              </div>
            </FormControl>
          </>
        )}
      </div>

      <div className="pt-6 mt-auto">
        <Button variant="contained" color="primary" size="large" onClick={handleSave}>
          {t('settings_workspace_members_invitation_settings_save_button')}
        </Button>

        <Button variant="contained" color="secondary" size="large" className="ml-3" onClick={handleClose}>
          {t('settings_workspace_members_invitation_settings_cancel_button')}
        </Button>
      </div>
    </div>
  );
});

export default InvitationAccessForm;
