import React from 'react';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import Button from 'o-ui/Button';
import {MAX_FORM_FIELD_LENGTH} from '../../../../../constants';


interface Props {
  inputName: string;
  snippetText?: string | null;
  className?: string;
  onSubmit?(snippetText: string): void;
  onClose?(): void;
}

const WebWidgetSnippetForm: React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const {inputName, className, snippetText, onSubmit, onClose} = props;

  const [inputValue, setInputValue] = React.useState<string>(snippetText || '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(inputValue);
  };

  return (
    <div className={cn('w-100 webwidget-snippets-list__form', className)}>
      <OutlinedTextInput
        className="w-100"
        name={inputName}
        placeholder={t('Enter new snippets text')}
        onChange={handleChange}
        defaultValue={inputValue}
        maxLength={MAX_FORM_FIELD_LENGTH}
        required
        autoFocus
      />
      <Button
        className="mt-2 mr-2"
        variant="contained"
        color="primary"
        size="small"
        onClick={handleSubmit}
        disabled={!inputValue}
      >
        {t('Save')}
      </Button>
      <Button className="mt-2" variant="contained" color="secondary" size="small" onClick={onClose}>
        {t('Cancel')}
      </Button>
    </div>
  );
};

export default WebWidgetSnippetForm;
