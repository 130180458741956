import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';

interface IProps {
  onClose?: () => void;
}

export const TelegramCreationModalHeader = observer((props: IProps) => {
  const {t} = useTranslation();

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    props.onClose?.();
  };

  return (
    <div className="channel-dialog-form__header">
      <span className="h3-medium">{t('settings_channel_creation_form_title')}</span>
      {props.onClose ? (
        <span
          className="tg-icon-cross-line cursor-pointer icon-size-18 color-body-primary ml-auto"
          onClick={handleClose}
        />
      ) : null}
    </div>
  );
});

export default TelegramCreationModalHeader;
