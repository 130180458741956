import {useStore} from '../stores/AppStore';
import ExternalAppStore from '../stores/ExternalApp/ExternalAppStore';

const useWindowInnerHeight = (): number => {
  const {externalApp} = useStore();
  let titleBarHeight = 0;

  if (externalApp.customTitleBar) {
    titleBarHeight = externalApp.customTitleBarHeight || ExternalAppStore.getCustomTitleBarHeight();
  }

  return isNaN(titleBarHeight) ? window.innerHeight : (window.innerHeight - titleBarHeight);
};

export default useWindowInnerHeight;
