import React from 'react';
import {observer} from 'mobx-react';

import useContextMenuHandler from '../../../../hooks/useContextMenuHandler';
import ContextMenuType from '../../../../stores/ContextMenuType';
import Message from '../../../../stores/Message';
import {useStore} from '../../../../stores/AppStore';
import MessageMeta from '../MessageContent/MessageMeta';
import MessageStatus from '../MessageContent/MessageStatus';
import TextContentItem from '../TextContent/TextContentItem';
import SpecialMessageMetaItems from './SpecialMessageMetaItems';
import SpecialMessageNewAvatar from './SpecialMessageNewAvatar';


interface SpecialMessageBodyProps {
  message: Message;
}

export const SpecialMessageBody: React.FC<SpecialMessageBodyProps> = observer(({message}) => {
  const {contextMenu} = useStore();

  const contextMenuHandlers = useContextMenuHandler((e: React.MouseEvent) => {
    if (e.button === 2) {
      e.stopPropagation();
      e.preventDefault();
      contextMenu.open({clientX: e.clientX, clientY: e.clientY}, ContextMenuType.SPECIAL_MESSAGE_MENU, {message});
    }
  });

  return (
    <div className="message__body message__body-special" {...contextMenuHandlers}>
      <div className="message__body-special-content">
        <TextContentItem message={message}>
          <MessageMeta message={message}>
            <SpecialMessageMetaItems message={message} />
          </MessageMeta>
        </TextContentItem>

        <MessageStatus message={message}>
          <SpecialMessageMetaItems message={message} />
        </MessageStatus>
      </div>
      <SpecialMessageNewAvatar message={message} />
    </div>
  );
});

export default SpecialMessageBody;
