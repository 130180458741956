import React from 'react';
import cn from 'classnames';

export interface NightModeSwitcherProps {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

export const NightModeSwitcher = (props: NightModeSwitcherProps) => {
  return (
    <span
      className={cn('night-mode-switcher', props.className, {
        'night-mode-switcher--checked': props.checked,
      })}
      onClick={() => props.onChange && props.onChange(!props.checked)}
    >
      <span className="night-mode-switcher__base">
        <span className="night-mode-switcher__thumb" />
      </span>
      <span className="night-mode-switcher__track" />
      <span className="night-mode-switcher__left-icon tg-icon tg-icon-sun-line" />
      <span className="night-mode-switcher__right-icon tg-icon tg-icon-moon-2-line" />
    </span>
  );
};

export default NightModeSwitcher;
