import cn from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import {entities} from '../../../api/proto';
import isFreeTariff from '../../../stores/Billing/utils/isFreeTariff';
import useStore from '../../../stores/useStore';
import formatMoney from '../../../utils/format/formatMoney';
import getBillingPeriodTitle from '../../Settings/Billing/getBillingPeriodTitle';

import CheckIcon from '../../../assets/images/check-in-circle.svg';
import CheckInCircle from '../../../assets/images/check-in-green-circle.svg';

interface TariffSelectItemActiveProps {
  className?: string;
  style?: React.CSSProperties;
  tariff: entities.ITariff;
  trial?: boolean;
  onCancelClick?: (tariff: entities.ITariff) => void;
}

export const TariffSelectItemActive = ({
  className,
  style,
  tariff,
  trial,
  onCancelClick,
}: TariffSelectItemActiveProps) => {
  const {t} = useTranslation();
  const {activeWorkspace: {billing}} = useStore();

  const seats: number = tariff?.includedUsers?.toNumber() || 0;

  const handleClickCancel = () => {
    onCancelClick?.(tariff);
  };

  return (
    <div
      className={cn(
        'bg-brand-primary d-inline-flex flex-column radius-8 border-primary py-5',
        className,
      )}
      style={style}
      data-tariff-id={tariff?.id}
    >

      <div className="px-5 pb-3 d-inline-flex align-items-center mr-auto">
        <span className={cn('h4-medium')}>
          {tariff?.name}
        </span>
        {trial ? (
          <span className="body1-regular bg-brand-secondary radius-8 py-1 px-3 ml-3">
            {t('settings_billing_tariff_trial')}
          </span>
        ) : null}
      </div>

      <div className="px-5 d-flex flex-row justify-content-between">
        <div>
          <div className="h3-bold">
            {formatMoney({value: tariff?.price, currency: tariff?.currency})}
          </div>
          <div>
            {getBillingPeriodTitle(tariff?.billingPeriod)}
          </div>
        </div>
        <div className="d-flex flex-row align-items-center">
          <div className="body1-regular bg-primary-400 rounded py-1 px-3">
            {t('settings_billing_tariff_seats_count', {count: seats})}
          </div>
        </div>
      </div>

      <div className="px-5 pt-5 mt-auto d-flex flex-column flex-xl-row justify-content-stretch">
        <div
          className={cn(
            'body1-regular bg-success-primary radius-8 py-1 px-3',
            'd-flex align-items-center justify-content-center flex-grow-1',
            trial || !billing.autoRenew ? '' : 'mr-xl-1',
          )}
        >
          <img className="icon mr-2" src={CheckIcon} alt="" />
          {t('settings_billing_tariff_bage_current_plan')}
        </div>


        {isFreeTariff(tariff) ? (
          <Button
            className="color-body-tint flex-grow-1 ml-xl-1 mt-3 mt-xl-0"
            variant="contained"
            color="tertiary"
            onClick={billing.openCustomerPortal}
            loading={billing.openCustomerPortalInProgress}
          >
            {t('settings_billing_tariff_manage')}
          </Button>
        ) : (trial || !billing.autoRenew) ? null : (
          <Button
            className="color-body-tint flex-grow-1 ml-xl-1 mt-3 mt-xl-0"
            variant="contained"
            color="tertiary"
            onClick={handleClickCancel}
          >
            {t('settings_billing_tariff_cancel_subscription')}
          </Button>
        )}
      </div>

      <div className="px-5 pt-5 mt-auto">
        <div className="d-flex mt-3">
          <img src={CheckInCircle} alt="" />
          <div className="ml-3">{t('settings_billing_tariff_option_unlimited_channels')}</div>
        </div>

        <div className="d-flex mt-3">
          <img src={CheckInCircle} alt="" />
          <div className="ml-3">{t('settings_billing_tariff_option_unlimited_chats')}</div>
        </div>

        <div className="d-flex mt-3">
          <img src={CheckInCircle} alt="" />
          <div className="ml-3">{t('settings_billing_tariff_option_internal_chats')}</div>
        </div>

        <div className="d-flex mt-3">
          <img src={CheckInCircle} alt="" />
          <div className="ml-3">{t('settings_billing_tariff_option_snippets')}</div>
        </div>

        <div className="d-flex mt-3">
          <img src={CheckInCircle} alt="" />
          <div className="ml-3">{t('settings_billing_tariff_option_tags')}</div>
        </div>
      </div>

    </div>
  );
}

export default TariffSelectItemActive;