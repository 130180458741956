import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';

import Message from '../../../../../stores/Message';

interface ChatLastMessageReadStatusProps {
  className?: string;
  lastChatMessage?: Message | null;
}

export const ChatLastMessageReadStatus = observer(({className, lastChatMessage}: ChatLastMessageReadStatusProps) => {
  if (!lastChatMessage?.isOwn || lastChatMessage.error) {
    return null;
  }

  return (
    <i
      className={cn(className, 'tg-icon', {
        delivered: lastChatMessage?.isDelivered,
        failed: lastChatMessage?.isFailed,
        'tg-icon-clock-line': !lastChatMessage?.isDelivered && !lastChatMessage?.isFailed,
        'tg-icon-alert-fill': lastChatMessage?.isFailed,
        'tg-icon-check-line': lastChatMessage?.isDelivered && !lastChatMessage?.isReadOutbox,
        'tg-icon-check-double-line': lastChatMessage?.isDelivered && lastChatMessage?.isReadOutbox,
      })}
    />
  );

});

export default ChatLastMessageReadStatus;
