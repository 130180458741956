import {computed, makeObservable} from 'mobx';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';
import Chat from '../Chat/Chat';
import WorkspaceMember from '../Workspaces/WorkspaceMember';

export class ChatMembers extends APILayer {
  constructor(public chat: Chat, app: AppStore) {
    super(app);
    makeObservable(this);
  }

  @computed get list(): WorkspaceMember[] {
    return this.app.activeWorkspace.members.filter((mbr) =>
      this.chat.joinedOperators?.some((id) => mbr.userId?.equals(id)),
    );
  }

  get searchIsAvailable() {
    return this.chat.isGroup || this.chat.isSuperGroup;
  }
}
