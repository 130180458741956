import React from 'react';

import {IMCTextEntities, MCTextEntities} from '../../../../api/proto';
import BoldEntity from './BoldEntity';
import ItalicEntity from './ItalicEntity';
import UnderlineEntity from './UnderlineEntity';
import StrikeEntity from './StrikeEntity';
import CodeEntity from './CodeEntity';
import TextUrlEntity from './TextUrlEntity';
import EmailEntity from './EmailEntity';
import MentionNameEntity from './MentionNameEntity';
import TextPhoneNumberEntity from './TextPhoneNumberEntity';
// import {formatDateTime} from '../../../../utils/format';


interface EntityWrapperProps {
  children?: React.ReactNode;
  entity: IMCTextEntities;
  text?: string;
}

export const EntityWrapper: React.FC<EntityWrapperProps> = (props) => {
  switch (props.entity.type) {
    case MCTextEntities.Type.BOLD:
      return <BoldEntity>{props.children}</BoldEntity>;
    case MCTextEntities.Type.ITALIC:
      return <ItalicEntity>{props.children}</ItalicEntity>;
    case MCTextEntities.Type.UNDERLINE:
      return <UnderlineEntity>{props.children}</UnderlineEntity>;
    case MCTextEntities.Type.STRIKETHROUGH:
      return <StrikeEntity>{props.children}</StrikeEntity>;
    case MCTextEntities.Type.CODE:
      return <CodeEntity>{props.children}</CodeEntity>;
    case MCTextEntities.Type.LINK:
      return (
        <TextUrlEntity entity={props.entity} textUrl={props.text}>
          {props.children}
        </TextUrlEntity>
      );
    case MCTextEntities.Type.MENTION:
      return (
        <MentionNameEntity entity={props.entity} text={props.text}>
          {props.children}
        </MentionNameEntity>
      );
    case MCTextEntities.Type.EMAIL_ADDRESS:
      return (
        <EmailEntity entity={props.entity} textUrl={props.text}>
          {props.children}
        </EmailEntity>
      );
    case MCTextEntities.Type.PHONE_NUMBER:
      return <TextPhoneNumberEntity entity={props.entity} textUrl={props.text}>{props.children}</TextPhoneNumberEntity>;
    /*
    case MCTextEntities.Type.LOCAL_DATE_TIME:
      return <>{formatDateTime(props.entity.timeStamp)}</>;
    */
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export const PlainTextEntityWrapper: React.FC<EntityWrapperProps> = (props) => {
  /*
  switch (props.entity.type) {
    case MCTextEntities.Type.LOCAL_DATE_TIME:
      return <>{formatDateTime(props.entity.timeStamp)}</>;
  }
  */
  return <React.Fragment>{props.children}</React.Fragment>;
};
