import React from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../stores/AppStore';


export const ChatHeadBackButton: React.FC<React.PropsWithChildren> = observer(() => {
  const {chatsView} = useStore();

  const switchToChatsSideBar = (e: React.MouseEvent) => {
    e.preventDefault();
    chatsView.closeActiveChat();
  };

  return (
    <div onClick={switchToChatsSideBar} className="cursor-pointer user-chat-remove text-muted font-size-16 p-3">
      <i className="tg-icon tg-icon-chevron-left-line"></i>
    </div>
  );
});

export default ChatHeadBackButton;
