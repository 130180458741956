import React from 'react';
import cn from 'classnames';

import {STICKER_IMAGE_SIZE} from '../../../../constants';
import PreviewImage from '../../../../components/PreviewImage';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import useMessageAttachment from './useMessageAttachment';


export interface StickerContentProps {
  className?: string;
  attachment: MessageAttachment;
  isScrolling?: boolean;
  fixedSize?: boolean;
}

export const StickerContent: React.FC<StickerContentProps> = (props) => {
  const [inView, setInView] = React.useState<boolean>(false);
  const {src} = useMessageAttachment(props.attachment, inView);

  return (
    <PreviewImage
      className={cn(
        'message-img__img',
        'message-img__img--sticker',
        props.className,
        `attachment-uuid-${props.attachment.uuid}`,
        `STICKER_IMAGE_SIZE-${STICKER_IMAGE_SIZE}`,
        `fixedSize-${props.fixedSize}`,
      )}
      src={src}
      fixedSize={props.fixedSize}
      width={STICKER_IMAGE_SIZE}
      height={STICKER_IMAGE_SIZE}
      isScrolling={props.isScrolling}
      onInViewChange={setInView}
    />
  );
};

export default StickerContent;