import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';

import Tabs from 'o-ui/Tabs';
import Tab from 'o-ui/Tab';
import {useStore} from '../../../../stores/AppStore';
import InvitationByEmailForm from './InvitationByEmailForm';
import InvitationByLinkForm from './InvitationByLinkForm';
import {InvitationByEmailFormikProps, InvitationFormTabs} from '../../WorkspaceMembersTab';


interface Props {
  onOpenInvitationAccess(): void;
  formik: InvitationByEmailFormikProps;
  activeTab: InvitationFormTabs;
  onChangeTab(newTab: InvitationFormTabs): void;
}

export const InvitationForm: React.FC<Props> = observer((props) => {
  const {t} = useTranslation();
  const {activeWorkspace} = useStore();

  const handleChangeAccessTab = (e: React.ChangeEvent, newValue: number) => {
    props.onChangeTab(newValue);
  };

  const handleSubmitInviteLinkCreation = async () => {
    await activeWorkspace.invites.load();
  };

  const getPageContent = (tab: InvitationFormTabs) => {
    switch (tab) {
      case InvitationFormTabs.INVITE_BY_EMAIL:
        return <InvitationByEmailForm formik={props.formik} onOpenInvitationAccess={props.onOpenInvitationAccess} />;
      case InvitationFormTabs.INVITE_BY_LINK:
        return <InvitationByLinkForm onSubmit={handleSubmitInviteLinkCreation} />;
    }
  };

  return (
    <div className="operator-invitation">
      <Tabs
        className="operator-invitation__tab-head border-bottom"
        value={props.activeTab}
        onChange={handleChangeAccessTab}
      >
        <Tab
          value={InvitationFormTabs.INVITE_BY_EMAIL}
          label={<span className="d-flex align-items-center">{t('settings_workspace_members_invite_by_email')}</span>}
        />
        <Tab
          value={InvitationFormTabs.INVITE_BY_LINK}
          label={<span className="d-flex align-items-center">{t('settings_workspace_members_invite_by_link')}</span>}
        />
      </Tabs>

      <div className="operator-invitation__tab">{getPageContent(props.activeTab)}</div>
    </div>
  );
});

export default InvitationForm;
