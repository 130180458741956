import {action, computed, makeObservable, observable} from 'mobx';

import {entities} from '../../api/proto';
import {uint8ArrayToBase64} from '../../utils/arrayUtils';
import {formatInviteUrl} from '../../utils/formatUrl';
import {AppStore} from '../AppStore';

export interface IInviteBase extends entities.IInvite {
  registrationRequired?: boolean | null;
}

export class InviteBase extends entities.Invite {
  constructor(
    public raw: IInviteBase,
    public rawWorkspace: entities.IWorkspace | null | undefined,
    public app: AppStore,
  ) {
    super(raw);
    this.registrationRequired = raw.registrationRequired;

    makeObservable(this);
  }

  @action assign = (props: entities.IInvite) => {
    Object.assign(this, props);
  };

  @observable registrationRequired?: boolean | null;
  @observable permissions: entities.WorkspaceMemberPermission[] = [];
  blockReason?: entities.WorkspaceMemberBlockReason | null;

  readonly profile?: entities.IUserShortProfile | null = null;

  @computed get displayName(): string {
    return this.email?.toString() || '';
  }

  @computed get email(): string {
    return this.boundToEmail || '';
  }

  @computed get active(): boolean {
    return !this.activated && !this.expired;
  }

  @computed get activated(): boolean {
    return !!this.used;
  }

  @computed get invited(): boolean {
    return !this.used;
  }

  @computed get expired(): boolean {
    return !this.activated && this.expireAt.notEquals(0) && this.expireAt.toNumber() - new Date().getTime() / 1000 < 0;
  }

  @computed get inviteUrl(): string {
    return formatInviteUrl(this.id);
  }

  @computed get isAvailable(): boolean {
    return this.active;
  }

  @computed get blocked(): boolean {
    return false;
  }

  @computed get isOwner(): boolean {
    return this.role === entities.WorkspaceRole.WR_OWNER;
  }

  @computed get isAdmin(): boolean {
    return this.role === entities.WorkspaceRole.WR_ADMIN;
  }

  @computed get isOperator(): boolean {
    return !this.role || this.role === entities.WorkspaceRole.WR_OPERATOR;
  }

  @computed get workspaceName(): string {
    return this.rawWorkspace?.name || uint8ArrayToBase64(this.id);
  }

  @computed get isCurrentUser(): boolean {
    if (!this.email) {
      return true;
    }

    return this.email === this.app.userStore.user?.email;
  }
}

export default InviteBase;
