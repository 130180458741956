import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {arrayMove} from '@dnd-kit/sortable';

import {useStore} from '../../stores/AppStore';
import {ITag, TagType} from '../../stores/MessageTagsStore';
import {MessageTagItem} from '../../components/MessageTagItem';
import {Message} from '../../stores/Message';
import SortableContainer from '../../components/SortableContainer';
import Long from 'long';

interface IProps {
  filter: string;
  message?: Message | null;
}

export const MenuMessageTagsTable: React.FC<IProps> = observer(({message, filter}) => {
  const {t} = useTranslation();
  const {chatsView, userStore} = useStore();

  const {user} = userStore;
  const tagsList = chatsView.tags.list;

  const workspaceTagsCortege = React.useState<ITag[]>([]);
  const personalTagsCortege = React.useState<ITag[]>([]);

  const [
    workspaceTagsList,
    // setWorkspaceTagsList
  ] = workspaceTagsCortege;
  const [
    personalTagsList,
    // setPersonalTagsList
  ] = personalTagsCortege;

  const tagsCortege = {
    [TagType.Workspace]: workspaceTagsCortege,
    [TagType.Personal]: personalTagsCortege,
  };

  const handleSelectToMessage = (tag: ITag) => {
    if (message) {
      // await chatsView.tags.addTagToMessage(tag, message);
      chatsView.tags.addTagToMessage(tag);
    }
  };

  const onSortEnd = (oldIndex: number, newIndex: number, tagType: TagType) => {
    const [activeTagsList, setActiveTagsList] = tagsCortege[tagType];
    const sortedTagsList = arrayMove(activeTagsList, oldIndex, newIndex);
    const sortedTagIds = arrayMove(
      tagsList,
      tagsList.findIndex((item) => item.id?.toString() === activeTagsList[oldIndex].id?.toString()),
      tagsList.findIndex((item) => item.id?.toString() === activeTagsList[newIndex].id?.toString()),
    ).map((tag) => tag.id);

    setActiveTagsList(sortedTagsList);
    chatsView.tags.updateOrdering(sortedTagIds as Long[]);
  };

  // React.useEffect(() => {
  //   setWorkspaceTagsList(filterTagsList(getUniqueList(tagsList, message?.tags || []), TagType.Workspace, filter));
  //   setPersonalTagsList(filterTagsList(getUniqueList(tagsList, message?.tags || []), TagType.Personal, filter));
  // }, [tagsList, filter, message?.tags, setPersonalTagsList, setWorkspaceTagsList]);

  if (filter.length) {
    return (
      <>
        <div className="message-tags-menu__title label-5 my-5">{t('ctx_menu_message_tag_select_or_create')}</div>
        <div className="message-tags-menu__table custom-scroll long py-0">
          {[...personalTagsList, ...workspaceTagsList].map((tag) => (
            <MessageTagItem key={tag.id?.toString()} tag={tag} onSelect={handleSelectToMessage} noWrap tableMode />
          ))}
        </div>
      </>
    );
  }

  return (
    <div className="mb-5">
      <div className="message-tags-menu__title label-5 my-5">{t('ctx_menu_message_tag_personal_tags')}</div>

      <div className="message-tags-menu__table p-0">
        <SortableContainer<ITag>
          items={personalTagsList}
          onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex, TagType.Personal)}
        >
          {personalTagsList.map((item) => (
            <MessageTagItem
              key={item.id?.toString()}
              tag={item}
              onSelect={handleSelectToMessage}
              // dragging={Boolean(user?.isAdmin || systemUser?.isOwner || !item.isWorkspace)}
              dragging={Boolean(user?.isOwner || !item.isWorkspace)}
              noWrap
              tableMode
            />
          ))}
        </SortableContainer>
      </div>

      <div className="message-tags-menu__title label-5 my-5">{t('ctx_menu_message_tag_workspace_tags')}</div>

      <div className="message-tags-menu__table p-0">
        <SortableContainer<ITag>
          items={workspaceTagsList}
          onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex, TagType.Workspace)}
        >
          {workspaceTagsList.map((item) => (
            <MessageTagItem
              key={item.id?.toString()}
              tag={item}
              onSelect={handleSelectToMessage}
              // dragging={Boolean(systemUser?.isAdmin || systemUser?.isOwner || !item.isWorkspace)}
              dragging={Boolean(user?.isOwner || !item.isWorkspace)}
              noWrap
              tableMode
            />
          ))}
        </SortableContainer>
      </div>
    </div>
  );
});

export default MenuMessageTagsTable;
