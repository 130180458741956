import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {ReactComponent as Settings} from '../../../assets/image-icons/settings.svg';
import {ReactComponent as SettingsActive} from '../../../assets/image-icons/settingsActive.svg';
import {ReactComponent as SettingsActiveDarkTheme} from '../../../assets/image-icons/settingsActiveDarkTheme.svg';
import {ReactComponent as SettingsDarkTheme} from '../../../assets/image-icons/settingsDarkTheme.svg';
import {ReactComponent as SettingsHover} from '../../../assets/image-icons/settingsHover.svg';
import {ReactComponent as SettingsHoverDarkTheme} from '../../../assets/image-icons/settingsHoverDarkTheme.svg';
//import {ReactComponent as Team} from '../../../assets/image-icons/team.svg';
//import {ReactComponent as TeamHover} from '../../../assets/image-icons/teamHover.svg';
//import {ReactComponent as TeamActive} from '../../../assets/image-icons/teamActive.svg';
//import {ReactComponent as TeamDarkTheme} from '../../../assets/image-icons/teamDarkTheme.svg';
//import {ReactComponent as TeamHoverDarkTheme} from '../../../assets/image-icons/teamHoverDarkTheme.svg';
//import {ReactComponent as TeamActiveDarkTheme} from '../../../assets/image-icons/teamActiveDarkTheme.svg';
//import {ReactComponent as Activities} from '../../../assets/image-icons/activities.svg';
//import {ReactComponent as ActivitiesHover} from '../../../assets/image-icons/activitiesHover.svg';
//import {ReactComponent as ActivitiesActive} from '../../../assets/image-icons/activitiesActive.svg';
//import {ReactComponent as ActivitiesDarkTheme} from '../../../assets/image-icons/activitiesDarkTheme.svg';
//import {ReactComponent as ActivitiesHoverDarkTheme} from '../../../assets/image-icons/activitiesHoverDarkTheme.svg';
//import {ReactComponent as ActivitiesActiveDarkTheme} from '../../../assets/image-icons/activitiesActiveDarkTheme.svg';

import SimpleBar from 'o-ui/CustomScrollbar/SimpleBar';
import ListItemIcon from 'o-ui/ListItemIcon';
import MenuItem from 'o-ui/Select/MenuItem';
import MenuList from 'o-ui/Select/MenuList';

import {MAX_WIDTH_FOR_ENABLE_EMBEDDED_LEFT_BAR} from '../../../config';
import useWorkspaceNavigate from '../../../hooks/useWorkspaceNavigate';
import {Paths} from '../../../routes/Paths';
import {useStore} from '../../../stores/AppStore';
import {ChatTab} from '../../../stores/LayOutStore';

export const SystemMenu: React.FC = observer(() => {
  const {t} = useTranslation();
  const {layOutStore, lightThemeChosen} = useStore();

  const workspaceNavigate = useWorkspaceNavigate();

  const closeSideMenuIfNeeded = () => {
    if (window.innerWidth <= MAX_WIDTH_FOR_ENABLE_EMBEDDED_LEFT_BAR) {
      layOutStore.setLeftBarCollapsed(true);
    }
  };
  /*
  const handleOpenTeam = () => {
    navigate(Paths.Team);
    layOutStore.setActiveTab(ChatTab.TEAM);
    chatsView.closeActiveChat();

    closeSideMenuIfNeeded();
  };
  */
  /*
  const handleOpenOperators = () => {
    navigate(Paths.Chat);
    layOutStore.setActiveTab(ChatTab.OPERATORS);
    chatStore.closeActiveChat();

    closeSideMenuIfNeeded();
  };
  */
  const handleClickSettingsMenu = () => {
    workspaceNavigate(Paths.PersonalInfo);

    closeSideMenuIfNeeded();
  };

  // React.useEffect(() => {
  //   if (chatStore.activeUsersChat?.source === com.chatman.Source.INTERNAL_CHAT) {
  //     layOutStore.setActiveTab(ChatTab.TEAM);
  //   }
  // }, [layOutStore, chatStore.activeUsersChat]);

  return (
    <SimpleBar className="system-menu-wrapper">
      <MenuList className="system-menu">
        {/*
        <MenuItem
          className={cn('system-menu__item')}
          onClick={handleOpenTeam}
          selected={layOutStore.activeTab === ChatTab.TEAM}
        >
          <ListItemIcon className="system-menu__icon-wrapper">
            {lightThemeChosen ? (
              <>
                <Team className="system-menu__icon" />
                <TeamHover className="system-menu__icon system-menu__icon--hover" />
                <TeamActive className="system-menu__icon system-menu__icon--active" />
              </>
            ) : (
              <>
                <TeamDarkTheme className="system-menu__icon" />
                <TeamHoverDarkTheme className="system-menu__icon system-menu__icon--hover" />
                <TeamActiveDarkTheme className="system-menu__icon system-menu__icon--active" />
              </>
            )}
          </ListItemIcon>

          <span className="system-menu__title">{t('Team')}</span>

          {accounts.internalUnreadCount > 0 ? (
            <div
              className={cn(
                'system-menu__item-unread-counter',
                `digits-${accounts.internalUnreadCount.toString().length}`,
              )}
            >
              {accounts.internalUnreadCount}
            </div>
          ) : null}
        </MenuItem>
        */}
        {/*settings.systemUser?.isOwner || settings.systemUser?.isAdmin ? (
          <MenuItem
            className={cn('system-menu__item')}
            onClick={handleOpenOperators}
            selected={layOutStore.activeTab === ChatTab.OPERATORS}
          >
            <ListItemIcon className="system-menu__icon-wrapper">
              {lightThemeChosen ? (
                <>
                  <Activities className="system-menu__icon" />
                  <ActivitiesHover className="system-menu__icon system-menu__icon--hover" />
                  <ActivitiesActive className="system-menu__icon system-menu__icon--active" />
                </>
              ) : (
                <>
                  <ActivitiesDarkTheme className="system-menu__icon" />
                  <ActivitiesHoverDarkTheme className="system-menu__icon system-menu__icon--hover" />
                  <ActivitiesActiveDarkTheme className="system-menu__icon system-menu__icon--active" />
                </>
              )}
            </ListItemIcon>
            <span className="system-menu__title">{t('Activity')}</span>
          </MenuItem>
        ) : null*/}

        <MenuItem
          className={cn('system-menu__item')}
          onClick={handleClickSettingsMenu}
          selected={layOutStore.activeTab === ChatTab.SETTINGS}
        >
          <ListItemIcon className="system-menu__icon-wrapper">
            {lightThemeChosen ? (
              <>
                <Settings className="system-menu__icon" />
                <SettingsHover className="system-menu__icon system-menu__icon--hover" />
                <SettingsActive className="system-menu__icon system-menu__icon--active" />
              </>
            ) : (
              <>
                <SettingsDarkTheme className="system-menu__icon" />
                <SettingsHoverDarkTheme className="system-menu__icon system-menu__icon--hover" />
                <SettingsActiveDarkTheme className="system-menu__icon system-menu__icon--active" />
              </>
            )}
          </ListItemIcon>
          <span className="system-menu__title">{t('Settings')}</span>
        </MenuItem>
      </MenuList>
    </SimpleBar>
  );
});

export default SystemMenu;
