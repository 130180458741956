import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import LinearProgress from 'o-ui/LinearProgress';
import useStore from '../../../../stores/useStore';
import {BILLING_DATE_FORMAT, formatDateTime} from '../../../../utils/format';

const TRIAL_PERIOD_DAYS = 7;

interface TrialPeriodInfoProps {
  className?: string;
}

export const TrialPeriodInfo: React.FC<TrialPeriodInfoProps> = observer(({className}) => {
  const {t} = useTranslation();

  const {activeWorkspace: {billing}} = useStore();

  const trialLeftTime: number = billing.expiresAt?.subtract(new Date().getTime() / 1000).toNumber() || 0;
  const trialLeftDays: number = Math.floor(trialLeftTime / (24 * 60 * 60)) + 1;
  const trialLefPercent: number = Math.floor(100 * (trialLeftDays) / TRIAL_PERIOD_DAYS);

  return (
    <div
      className={cn(
        'd-flex flex-column px-6 py-5',
        className,
      )}
    >
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-column">
          <div className="body1-regular">{t('Trial Period')}</div>
          <div>
            <span className="h3-bold">
              {trialLeftDays === 1 ? t('1 day left') : t('{{days}} days left', {days: trialLeftDays})}
            </span>
            <span className="body1-regular color-body-tertiary pl-3">
              {t('Ends on {{date}}', {
                date: formatDateTime(billing.expiresAt, true, BILLING_DATE_FORMAT)
              })}
            </span>
          </div>
        </div>
      </div>

      <LinearProgress
        className="mt-3"
        style={{height: '4px', backgroundColor: 'var(--brand-tint3)'}}
        color="primary"
        variant="determinate"
        value={trialLefPercent}
      />
    </div>
  );
});

export default TrialPeriodInfo;