import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useStore} from '../../../stores/AppStore';

import Button from 'o-ui/Button';
import {ReactComponent as PlusIcon} from '../../../assets/icons/plus-line.svg';

import {ReactComponent as NotFound} from '../../../assets/image-icons/snippetNotFound.svg';
import {ReactComponent as NotFoundBySearch} from '../../../assets/image-icons/snippetNotFoundBySearch.svg';
import {ReactComponent as NotFoundBySearchDarkTheme} from '../../../assets/image-icons/snippetNotFoundBySearchDarkTheme.svg';
import {ReactComponent as NotFoundDarkTheme} from '../../../assets/image-icons/snippetNotFoundDarkTheme.svg';

import {SnippetType} from '../../../stores/Snippets/SnippetsStore';

interface IProps {
  snippetType: SnippetType;
  onCreateSnippet(): void;
  isSearchQuery?: boolean;
  showCreateButton?: boolean;
}

enum NotFoundType {
  SearchQuery,
  Database,
}

export const NoSnippetsItem: React.FC<IProps> = observer(
  ({snippetType, onCreateSnippet, isSearchQuery, showCreateButton = true}) => {
    const {t} = useTranslation();

    const {lightThemeChosen, userStore} = useStore();

    const foundType = isSearchQuery ? NotFoundType.SearchQuery : NotFoundType.Database;

    const icons = {
      [NotFoundType.SearchQuery]: lightThemeChosen ?
        <NotFoundBySearch className="snippet-manage__no-snippets-icon" /> :
        <NotFoundBySearchDarkTheme className="snippet-manage__no-snippets-icon" />,
      [NotFoundType.Database]: lightThemeChosen ?
        <NotFound className="snippet-manage__no-snippets-icon" /> :
        <NotFoundDarkTheme className="snippet-manage__no-snippets-icon" />,
    };

    const titles = {
      [SnippetType.Workspace]: t('settings_snippets_workspace_snippets_not_found'),
      [SnippetType.Personal]: t('settings_snippets_personal_snippets_not_found'),
    };

    const descriptions = {
      [SnippetType.Workspace]:
        userStore.isAdmin || userStore.isOwner
          ? t('settings_snippets_workspace_descriptions_for_admin')
          : t('settings_snippets_workspace_descriptions_for_user'),
      [SnippetType.Personal]: t('settings_snippets_personal_descriptions'),
    };

    const createButtonTexts = {
      [SnippetType.Workspace]:
        userStore.isAdmin || userStore.isOwner
          ? t('settings_snippets_create_workspace_tag')
          : t('settings_snippets_create_personal_tag'),
      [SnippetType.Personal]: t('settings_snippets_create_personal_tag'),
    };

    return (
      <div className="snippet-manage__no-snippets text-center m-auto">
        <div>
          {icons[foundType]}

          <div className="snippet-manage__no-snippets-title mb-3">{titles[snippetType]}</div>

          <div className="snippet-manage__no-snippets-description  mb-6">{descriptions[snippetType]}</div>

          {showCreateButton ? (
            <Button className="ml-3 mb-5" variant="contained" color="secondary" onClick={onCreateSnippet}>
              <PlusIcon className="snippet-manage__no-snippets-btn-icon" />
              {createButtonTexts[snippetType]}
            </Button>
          ) : null}
        </div>
      </div>
    );
  },
);

export default NoSnippetsItem;
