import React from 'react';
import {Accept} from 'react-dropzone';
import {useTranslation} from 'react-i18next';

import {useStore} from '../../stores/AppStore';
import Chat from '../../stores/Chat';
import ModalType from '../../stores/ModalType';
import {FileData, dataFileReader} from '../../utils/file/fileReaders';
import someFileIsEmpty from '../../utils/file/someFileIsEmpty';
import Dropzone from './Dropzone/Dropzone';

interface IProps {
  children?: React.ReactNode;
  chat: Chat;
  className?: string;
  accept?: Accept;
  noClick?: boolean;
  disabled?: boolean;
}

export const DropzoneUploader: React.FC<IProps> = (props) => {
  const {t} = useTranslation();
  const {modals, notifications} = useStore();

  const handleSend = (dataFiles: FileData[], compressImages?: boolean) => {
    const photoCount = dataFiles?.filter((f) => f.image).length || 0;
    const videoCount = dataFiles?.filter((f) => f.video).length || 0;

    if (dataFiles.length === photoCount + videoCount) {
      modals.open(ModalType.UPLOAD_PHOTO_VIDEO_ASYNC, {dataFiles, chat: props.chat, compressImages});
    } else {
      modals.open(ModalType.UPLOAD_FILE_ASYNC, {dataFiles, chat: props.chat});
    }
  };

  const handleFileChange = (files: File[], compressImages?: boolean) => {
    if (props.disabled) {
      return;
    }
    if (someFileIsEmpty(files)) {
      notifications.error(t('file_uploader_file_is_empty'));
      return;
    }
    dataFileReader(files, (dataFiles) => handleSend(dataFiles, compressImages));
  };

  return (
    <Dropzone
      className={props.className}
      onChange={handleFileChange}
      accept={props.accept}
      noClick={props.noClick}
      disabled={props.disabled}
    >
      {props.children}
    </Dropzone>
  );
};

export default DropzoneUploader;
