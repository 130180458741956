import { entities } from '../../../api/proto';
import User from '../../User';
import { WorkspaceStore } from '../../Workspaces';
import UserType from '../UserType';

export default function getUserType(workspace: WorkspaceStore, user?: User | null): UserType {
  if (!user?.isLoggedIn) {
    return UserType.Visitor;
  }

  const paid = workspace.localStore.paid;
  const isOwner = workspace.localStore.userRole === entities.WorkspaceRole.WR_OWNER;

  return paid && isOwner ? UserType.Customer : UserType.User;
}