import React from 'react';
import cn from 'classnames';

import {entities} from '../../../../../api/proto';

import {ReactComponent as InstagramIcon} from '../../../../../assets/icons/instagram.svg';
import {ReactComponent as InstagramMonoIcon} from '../../../../../assets/icons/instagram-mono.svg';
import {ReactComponent as InternalChatIcon} from '../../../../../assets/icons/internal-chat.svg';
import {ReactComponent as InternalChatMonoIcon} from '../../../../../assets/icons/internal-chat-mono.svg';
import {ReactComponent as TelegramBotIcon} from '../../../../../assets/icons/telegram-bot.svg';
import {ReactComponent as TelegramBotMonoIcon} from '../../../../../assets/icons/telegram-bot-mono.svg';

interface ChatChannelTypeIconProps {
  className?: string;
  type?: entities.OzekonChannelType | string | null;
}

export const ChatChannelTypeIcon = (props: ChatChannelTypeIconProps) => {
  switch (props.type) {
    case entities.OzekonChannelType.OIT_TELEGRAM:
      return <i className={cn(props.className, 'tg-icon tg-icon-telegram')} />;
    case entities.OzekonChannelType.OIT_INSTAGRAM:
      //return <i className={cn(props.className, 'tg-icon tg-icon-instagram')} />;
      return (
        <>
          <InstagramIcon className={cn('tg-icon inactive-icon', props.className)} />
          <InstagramMonoIcon className={cn('tg-icon active-icon', props.className)} />
        </>
      );
    case entities.OzekonChannelType.OIT_TELEGRAM_BOT:
      return (
        <>
          <TelegramBotIcon className={cn('tg-icon inactive-icon', props.className)} />
          <TelegramBotMonoIcon className={cn('tg-icon active-icon', props.className)} />
        </>
      );
    case 'SIGNAL':
      return <i className={cn(props.className, 'tg-icon tg-icon-signal')} />;
    case entities.OzekonChannelType.OIT_WEB_WIDGET:
      return <i className={cn(props.className, 'tg-icon tg-icon-livechat')} />;
    case 'INTERNAL_CHAT':
      return (
        <>
          <InternalChatIcon className={cn('tg-icon inactive-icon ml-2', props.className)} />
          <InternalChatMonoIcon className={cn('tg-icon active-icon ml-2', props.className)} />
        </>
      );
  }

  return null;
};

export default ChatChannelTypeIcon;
