import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../../../../stores/AppStore';
import {ModalType} from '../../../../../stores/ModalType';
import {WorkspaceMemberAccessRecord} from '../../../../../stores/Workspaces/WorkspaceMemberAccessRecord';
import WorkspaceMemberConfig from '../../../../../stores/Workspaces/WorkspaceMemberConfig';
import {randomUint8Array, uint8ArrayToUuid} from '../../../../../utils/arrayUtils';
import ChannelsAccessHeader from './ChannelsAccessHeader';
import ChannelsAccessItem from './ChannelsAccessItem';

interface ChannelsAccessProps {
  memberConfig: WorkspaceMemberConfig;
  onOpenAccessRecord?(accessRecord: WorkspaceMemberAccessRecord): void;
}

const ChannelsAccess: React.FC<ChannelsAccessProps> = observer(({memberConfig, onOpenAccessRecord}) => {
  const {t} = useTranslation();
  const {modals} = useStore();

  const [channelAccessRecords, setChannelAccessRecords] = React.useState<WorkspaceMemberAccessRecord[]>(
    memberConfig.channelAccessRecords,
  );

  const [filter, setFilter] = React.useState<string>('');

  const handleChangeFilter = (value: string) => {
    setFilter(value);
  };

  React.useEffect(() => {
    if (filter) {
      setChannelAccessRecords(
        memberConfig.channelAccessRecords.filter((record) =>
          record.channel?.name.toLowerCase().includes(filter.toLowerCase()),
        ),
      );
    } else {
      setChannelAccessRecords(memberConfig.channelAccessRecords);
    }
  }, [filter, memberConfig.channelAccessRecords]);

  const handleResetExceptions = () => {
    modals.open(ModalType.CONFIRM, {
      headerText: t('modal_channels_access_confirm_reset_exceptions_header_text'),
      title: t('modal_channels_access_confirm_reset_exceptions_title'),
      submitButtonText: t('modal_submit_btn'),
      submitButtonColor: 'primary',
      syncCallbacks: true,
      callbacks: {
        submit: () => {
          memberConfig.allowAllChannels();
        },
      },
    });
  };

  return (
    <div className="channels-access">
      <ChannelsAccessHeader
        onSearch={handleChangeFilter}
        onResetExceptions={handleResetExceptions}
        disabledResetExceptions={memberConfig.channelAccessRecords.every((record) => record.isResetState)}
      />

      <div className={cn('channels-access__list', {empty: !channelAccessRecords.length})}>
        {channelAccessRecords.map((record) => (
          <ChannelsAccessItem
            key={uint8ArrayToUuid(record.channelID || randomUint8Array())}
            accessRecord={record}
            onOpenAccessRecord={onOpenAccessRecord}
          />
        ))}

        {!channelAccessRecords.length ? (
          <div className="mb-3 body2-regular">{t('setting_workspace_members_chats_access_no_items_found')}</div>
        ) : null}
      </div>
    </div>
  );
});

export default ChannelsAccess;
