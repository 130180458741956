import Long from 'long';

import {IMCTextEntities, MCTextEntities} from '../../../api/proto';
import {stringToUint8Array} from '../../../utils/arrayUtils';

const regexUrl = new RegExp(
  '(^|[\\t\\r\\n])((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|aim|webcal):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))',
  'g'
);

const emailRegex = new RegExp('[^\\.\\s@:](?:[^\\s@:]*[^\\s@:\\.])?@[^\\.\\s@]+(?:\\.[^\\.\\s@]+)*', 'g');

const phoneRegex = new RegExp(
  '\\+(?:(\\d{1,4})[-.\\s]?)?(?:[(](\\d{1,3})[)][-.\\s]?)?(\\d{1,4})[-.\\s]?(\\d{1,4})[-.\\s]?(\\d{1,9})',
  'g',
);

function hasSpaceAfter(substring, string) {
  const position = string.indexOf(substring);

  if (position !== -1 && position + substring.length < string.length) {
    return string.charAt(position + substring.length) === ' ';
  }
  return false;
}

export const findUrls = (text?: string | null): IMCTextEntities[] => {
  const textEntities: IMCTextEntities[] = [];
  const txt = text || '';
  let match;

  do {
    match = regexUrl.exec(txt);
    if (match) {
      textEntities.push({
        type: MCTextEntities.Type.LINK,
        offset: Long.fromNumber(match.index),
        length: Long.fromNumber(match[0].length),
      });
    }
  } while (match);

  return textEntities;
};

export const findEmails = (text?: string | null): IMCTextEntities[] => {
  const newEntities: IMCTextEntities[] = [];

  if (text) {
    let match: RegExpExecArray | never[] | null = [];

    while ((match = emailRegex.exec(text))) {
      newEntities.push({
        type: MCTextEntities.Type.EMAIL_ADDRESS,
        offset: Long.fromNumber(match.index || 0),
        length: Long.fromNumber(match[0]?.length),
        payload: stringToUint8Array(match[0]),
      });
    }
  }

  return newEntities;
};

export const findPhones = (text?: string | null): IMCTextEntities[] => {
  const newEntities: IMCTextEntities[] = [];

  if (text) {
    let match: RegExpExecArray | never[] | null = [];

    while ((match = phoneRegex.exec(text))) {
      if (text.length === match[0]?.length || hasSpaceAfter(match[0], text)) {
        newEntities.push({
          type: MCTextEntities.Type.PHONE_NUMBER,
          offset: Long.fromNumber(match.index || 0),
          length: Long.fromNumber(match[0]?.length),
          payload: stringToUint8Array(match[0]),
        });
      }
    }
  }

  return newEntities;
};

export const filterUnique = (
  textEntities1: IMCTextEntities[],
  textEntities2: IMCTextEntities[],
  skipType?: boolean,
): IMCTextEntities[] => {
  return textEntities1.filter(
    (textEntity) =>
      !textEntities2.some(
        (te) =>
          (skipType || textEntity.type === te.type) &&
          (textEntity.offset || Long.ZERO).equals(te.offset || Long.ZERO) &&
          (textEntity.length || Long.ZERO).equals(te.length || Long.ZERO),
      ),
  );
};
