import {getCurrencyDecimals} from '../currencies';

export const formatMoneyAmount = (value: number | null | undefined, currencyCode: string | null | undefined): string => {
  const decimals = getCurrencyDecimals(currencyCode);

  return ((value || 0) / Math.pow(10, decimals))
    .toFixed(decimals)
    .replace(/\.00$/, '').replace(/\.0$/, '');
};

export default formatMoneyAmount;