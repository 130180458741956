import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import CircularProgress from 'o-ui/CircularProgress';
import {ChatTab} from '../../../stores/LayOutStore';
import useStore from '../../../stores/useStore';

import selectChatDarkTheme from '../../../assets/images/select-chat-dark-theme.png';
import selectChat from '../../../assets/images/select-chat.png';
import selectUserChatDarkTheme from '../../../assets/images/select-user-chat-dark-theme.png';
import selectUserChat from '../../../assets/images/select-user-chat.png';

type ReturnData = {
  imageSrc: string;
  title: string;
};

const EmptyChatArea: React.FC<React.PropsWithChildren> = observer(() => {
  const {t} = useTranslation();
  const {chatsView, layOutStore, lightThemeChosen} = useStore();

  const getReturnData = (): ReturnData | null => {
    switch (layOutStore.activeTab) {
      case ChatTab.CHATS:
      case ChatTab.TEAM:
        return {
          imageSrc: lightThemeChosen ? selectChat : selectChatDarkTheme,
          title: t('chat_user_chat_empty_layout_title'),
        };

      case ChatTab.CONTACTS:
        return {
          imageSrc: lightThemeChosen ? selectUserChat : selectUserChatDarkTheme,
          title: t('chat_user_chat_contacts_empty_layout_title'),
        };
    }

    return null;
  };

  const returnData = getReturnData();

  if (!returnData) {
    return <div className="user-chat" />;
  }

  const {imageSrc, title} = returnData;

  return (
    <div className="user-chat user-chat--no-transition">
      <div className="user-chat__body user-chat__body--empty-body">
        {chatsView.openingChatID ? (
          <CircularProgress
            size={72}
            style={{
              color: 'var(--brand-primary)',
              zIndex: 3,
            }}
          />
        ) : (
          <>
            <img src={imageSrc} className="mb-6" alt={title} />
            <div className="body1-regular color-body-tertiary">{title}</div>
          </>
        )}
      </div>
    </div>
  );
});

export default EmptyChatArea;
