import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import Chat from '../../../stores/Chat';
import ContextMenuType from '../../../stores/ContextMenuType';
import Message from '../../../stores/Message';
import {ITextSelection, getTextSelection} from '../../../stores/TextSelection';
import useStore from '../../../stores/useStore';
import MessageAlbum from './MessageContent/MessageAlbum';
import MessageMeta from './MessageContent/MessageMeta';
import MessageMetaItems from './MessageContent/MessageMetaItems';
import MessageStatus from './MessageContent/MessageStatus';
import MessageReplyInfo from './MessageReplyInfo';
import TextContentItem from './TextContent/TextContentItem';
import useMessageSize from './useMessageSize';

interface MessageBodyProps {
  senderName: string;
  chat: Chat;
  searchId?: string;
  pinnedMode?: boolean;
  message: Message;
  messageGroup: Message[];
  isVisible?: boolean;
  isScrolling?: boolean;
  onClick?: React.MouseEventHandler;
  isMessageItemHover?: boolean;
}

export const MessageBody: React.FC<MessageBodyProps> = observer(
  ({chat, message, pinnedMode, messageGroup, senderName, isVisible, isScrolling, searchId}) => {
    const {contextMenu} = useStore();
    const handleClickBody = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      console.debug(`TODO: not implemented handler "handleClickBody"`, e);
    };

    const onContextMenu = (e: React.MouseEvent) => {
      const textSelection: ITextSelection | null = getTextSelection();
      if (textSelection) {
        e.stopPropagation();
        e.preventDefault();

        contextMenu.open({clientX: e.clientX, clientY: e.clientY}, ContextMenuType.MESSAGE_MENU, {
          message,
          textSelection: textSelection,
        });
      }
    };

    const messageSize = useMessageSize(messageGroup, 4);

    return (
      <div className={cn('message__body')}>
        <div
          className={cn('message__body-content', {
            'with-head': !!senderName,
            'with-text': !!message.text,
            'with-media':
              message.hasAudio || message.hasVoice || message.hasImage || message.hasSticker || message.hasVideo,
            'with-document-media': message.isDocumentImage,
            'with-sticker': message.hasSticker,
            'with-sticker-animated': message.hasStickerAnimated,
            'with-audio': message.hasAudio || message.hasVoice,
          })}
          onClick={handleClickBody}
          onContextMenu={onContextMenu}
        >
          {senderName ? <div className="message__title">{senderName}</div> : null}

          <div
            className={cn('message-inner-content', {
              'pb-3': !message.text && message.hasDocument && !message.isDocumentImage,
            })}
            style={{width: messageSize.style.width}}
          >
            {message.replyToMessage ? (
              <MessageReplyInfo chat={chat} message={message} replyToMessage={message.replyToMessage} />
            ) : null}

            <MessageAlbum
              message={message}
              messageGroup={messageGroup}
              searchId={searchId}
              isVisible={isVisible}
              isScrolling={isScrolling}
              messageSize={messageSize}
            />

            {message.text ? (
              <div className="message-text">
                <TextContentItem className="mb-0" message={message}>
                  <MessageMeta message={message} pinnedMode={pinnedMode}>
                    <MessageMetaItems message={message} messageGroup={messageGroup} pinnedMode={pinnedMode} />
                  </MessageMeta>
                </TextContentItem>
              </div>
            ) : null}

            <MessageStatus message={message}>
              <MessageMetaItems message={message} messageGroup={messageGroup} pinnedMode={pinnedMode} />
            </MessageStatus>
          </div>
        </div>
      </div>
    );
  },
);

export default MessageBody;
