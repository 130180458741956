import {Action} from 'history';
import {action, makeObservable, observable} from 'mobx';

import browserStorage from '../../utils/browserStorage';
import {AppStore} from '../AppStore';
import LocationType from './LocationType';

const ROUTING_HISTORY_STORE_KEY = 'routingHistory';
const ROUTING_HISTORY_INDEX_STORE_KEY = 'routingHistoryIndex';

const MAX_BROWSER_HISTORY_LENGTH = 50;


export class RoutingHistoryStore {
  constructor(public app: AppStore) {
    this.restore();
    makeObservable(this);
  }

  @observable history: LocationType[] = [];

  @observable historyIndex = 0;

  store = () => {
    try {
      const cacheDataStr = JSON.stringify(this.history);
      browserStorage.session(ROUTING_HISTORY_STORE_KEY, cacheDataStr);
      browserStorage.session(ROUTING_HISTORY_INDEX_STORE_KEY, this.historyIndex.toString());
    } catch (e) {
      console.log(e);
    }
  };

  @action restore = () => {
    try {
      const historyJSON = browserStorage.session(ROUTING_HISTORY_STORE_KEY) || '';
      if (historyJSON) {
        this.history = JSON.parse(historyJSON) || [];

        const historyIndexStr = browserStorage.session(ROUTING_HISTORY_INDEX_STORE_KEY) || '0';
        this.historyIndex = parseInt(historyIndexStr) || 0;
      } else {
        const location = window.history.state as LocationType;

        if (location?.key) {
          this.history = [
            {
              key: location?.key || '',
              pathname: window.location.pathname,
              search: window.location.search,
              hash: window.location.hash,
              state: location.state,
            },
          ];
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  @action push = (location: LocationType) => {
    if (this.historyIndex > 0) {
      this.history = this.history.slice(this.historyIndex);
      this.historyIndex = 0;
    }

    this.history.unshift(location);

    if (this.history.length > MAX_BROWSER_HISTORY_LENGTH) {
      this.history.pop();
    }

    this.store();
  };

  @action replace = (location: LocationType) => {
    const foundLocationIndex = this.history.findIndex((l) => l.key === location.key);

    this.history.splice(foundLocationIndex, 1, location);
    this.store();
  };

  @action pop = (location: LocationType) => {
    const foundLocationIndex = this.history.findIndex((l) => l.key === location.key);

    this.historyIndex = foundLocationIndex >= 0 ? foundLocationIndex : 0;
    this.store();
  };

  locationListener = ({location, action}: {location: LocationType; action: Action}) => {
    console.warn(`----->locationListener `, location, action);
    if (action === 'PUSH') {
      this.push(location);
    } else if (action === 'REPLACE') {
      this.replace(location);
    } else if (action === 'POP') {
      this.pop(location);
    }
  };

  public resetToInitState = () => {
    if (!this.app.workspaces.isInit) {
      return;
    }

    //TODO need to research how to reset browserHistory
    // if (this.history.length) {
    //   browserHistory.go(-1 * (this.history.length - 1));
    // }

    this.reset_();
  };

  @action protected reset_ = () => {
    this.history = [];
    this.historyIndex = 0;
  };
}

export default RoutingHistoryStore;