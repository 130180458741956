import {entities} from '../../../api/proto';
import {equalLongArrays, equalUint8Arrays} from '../../../utils/arrayUtils';

export default function equalAccessRecords(
  oldRecords: entities.IWorkspaceMemberAccessRecord[] = [],
  newRecords: entities.IWorkspaceMemberAccessRecord[] = []
): boolean {
  const oldRecordsLength = oldRecords?.length || 0;
  const newRecordsLength = newRecords?.length || 0;

  if (oldRecordsLength !== newRecordsLength) {
    return true;
  }

  for(let i = 0; i < oldRecordsLength; i++) {
    const oldRecord = oldRecords[i];
    const newRecord = newRecords[i];
    
    if (
      !equalUint8Arrays(oldRecord.channelID, newRecord.channelID) ||
      oldRecord.disableChannel !== newRecord.disableChannel ||
      !equalLongArrays(oldRecord.chatsAllowList, newRecord.chatsAllowList) ||
      !equalLongArrays(oldRecord.chatsDenyList, newRecord.chatsDenyList)
    ) {
      return true;
    }
  }

  return false;
}
