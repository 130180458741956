import React from 'react';
import useChatsTabAdaptations from './useChatsTabAdaptations';

export const ChatsTabContainer: React.FC<React.PropsWithChildren> = React.memo((props) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  useChatsTabAdaptations(containerRef);

  return (
    <div ref={containerRef} className="chats-tab">
      {props.children}
    </div>
  );
});

export default ChatsTabContainer;
