import Long from 'long';

import {WorkspaceStore} from '.';
import {MCMethod, MCMethodGetChats, MCMethodGetChatsPreview, MCMethodSearchChats, api} from '../../api/proto';
import {IRawChat} from '../RawChatsStore/RawChat';


export const PAGE_SIZE = Long.fromNumber(50);

export default class ChatsSource {
  constructor(protected workspace: WorkspaceStore) {
  }

  protected load_ = async (channelID: Uint8Array, chatID?: Long | null) => {
    return await this.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            getChatsPreview: new MCMethodGetChatsPreview({
              channelIDs: [channelID],
              fetch: true,
              offsetID: chatID,
              offsetBefore: PAGE_SIZE,
            }),
          }),
        }),
      },
      'channelsResponse',
    );
  };

  public load = async (channelID: Uint8Array, chatID?: Long | null): Promise<IRawChat[]> => {
    const {res} = await this.load_(channelID, chatID);
    const chats = res?.methodResponse?.getChats?.chats || [];

    return chats;
  };

  public search = async (channelID: Uint8Array, query: string) => {
    if (!query) {
      return [];
    }

    const {res} = await this.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            searchChats: new MCMethodSearchChats({
              channelID,
              query,
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    return res?.methodResponse?.searchChats?.chats || [];
  };

  public getChats = async (chatIDs: Long[], channelID?: Uint8Array | null): Promise<IRawChat[]> => {
    const {res} = await this.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            getChats: new MCMethodGetChats({
              channelID,
              chatIDs,
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    const chats: IRawChat[] = [];
    res?.methodResponse?.getChatsByID?.chats?.forEach((res) => {
      if (res.chat) {
        chats.push(res.chat);
      }
    });

    return chats;
  };
}
