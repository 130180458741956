import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import DialogActions from 'o-ui/Dialog/DialogActions';

import {fileUploader} from '../../../../api/fileUploader';
import {IMCAttachment, IMCWebWidgetConfig} from '../../../../api/proto';
import {WEB_WIDGET_COLORS} from '../../../../constants';
import useAsyncLoading from '../../../../hooks/useAsyncLoading';
import useAttachment from '../../../../hooks/useAttachment';
import Channel from '../../../../stores/Channel';
import useStore from '../../../../stores/useStore';
import compressImage from '../../../../utils/file/compressImages';
import {FileData} from '../../../../utils/file/fileReaders';
import {formatAttachment} from '../../../../utils/file/formatAttachment';
import jsonParse from '../../../../utils/json/jsonParse';
import jsonStringify from '../../../../utils/json/jsonStringify';
import {DEFAULT_POSITION} from '../../../../utils/widgetPositionUtils';
import WebWidgetPreview from '../WebWidgetCreation/WebWidgetPreview';
import WebWidgetThemeSettingsForm, {IWebWidgetThemeSettingsFormRef} from './WebWidgetThemeSettingsForm';


interface WebWidgetThemeSettingsProps {
  channel: Channel;
  onClose?: () => void;
}

export const WebWidgetThemeSettings = observer(({channel, onClose}: WebWidgetThemeSettingsProps) => {
  const {t} = useTranslation();
  const {notifications, channels} = useStore();
  const formRef = React.useRef<IWebWidgetThemeSettingsFormRef>(null);

  const webWidgetConfig = channel.webWidgetConfig;

  const {src: avatarSrc} = useAttachment(webWidgetConfig?.displayAvatar, channel.id);

  const [companyName, setCompanyName] = React.useState<string>(webWidgetConfig?.displayCompanyName || '');
  const [color, setColor] = React.useState<string>(webWidgetConfig?.displayColor || WEB_WIDGET_COLORS[0]);
  const [avatarFile, setAvatarFile] = React.useState<FileData | null | undefined>(undefined);
  const [chatButtonSize, setChatButtonSize] = React.useState<string>(webWidgetConfig?.chatButtonSize?.toString() || '');

  const position = jsonParse<React.CSSProperties>(webWidgetConfig?.position) || DEFAULT_POSITION;
  const [chatButtonPosition, setChatButtonPosition] = React.useState<React.CSSProperties>(position);

  const formChanged = React.useMemo<boolean>(() => {
    return webWidgetConfig?.displayCompanyName != companyName ||
      webWidgetConfig?.displayColor != color ||
      typeof avatarFile !== 'undefined' ||
      webWidgetConfig?.chatButtonSize?.toString() != chatButtonSize ||
      jsonStringify(position) !== jsonStringify(chatButtonPosition)
      ;
  }, [
    webWidgetConfig?.displayCompanyName,
    companyName,
    webWidgetConfig?.displayColor,
    color,
    avatarFile,
    webWidgetConfig?.chatButtonSize,
    chatButtonSize,
    position,
    chatButtonPosition,
  ]);

  const handleNext = () => {
    formRef.current?.submitForm?.();
  };

  const [handleSubmit, loading] = useAsyncLoading(async (rawWidgetConfig: IMCWebWidgetConfig, avatarFile?: FileData | null) => {
    if (avatarFile) {
      let displayAvatar: IMCAttachment | null = formatAttachment(avatarFile);
      avatarFile.attachment = displayAvatar;

      const dataFile = await compressImage(avatarFile);

      const {res, error} = await fileUploader.upload({dataFile});

      if (error) {
        displayAvatar = null;
      }

      if (res && displayAvatar?.source) {
        displayAvatar.source.reference = res.attachmentID;

        rawWidgetConfig.displayAvatar = displayAvatar;
      }
    }

    const {error, res} = await channels.updateChannel({
      channel,
      channelID: channel.id,
      shownFields: channel.shownFields,
      webWidgetConfig: rawWidgetConfig,
    });

    if (error) {
      notifications.error(error.message);
    }

    if (res) {
      onClose?.();
    }
  });

  const handleCompanyNameChange = (value: string) => {
    setCompanyName(value);
  };

  const handleColorChange = (value: string) => {
    setColor(value);
  };

  const handleAvatarFileChange = (file: FileData | null) => {
    setAvatarFile(file);
  };

  const handleChatButtonSizeChange = (value: string) => {
    setChatButtonSize(value);
  };

  const handleChatButtonPositionChange = (cssProps: React.CSSProperties) => {
    setChatButtonPosition(cssProps);
  };

  return (
    <>
      <div className="d-flex flex-row w-860 webwidget-settings">
        <div className="w-100 border-primary-right p-4 webwidget-settings__left">
          <WebWidgetThemeSettingsForm
            ref={formRef}
            colorOptions={WEB_WIDGET_COLORS}
            channel={channel}
            onSubmit={handleSubmit}
            onChangeCompanyName={handleCompanyNameChange}
            onChangeColor={handleColorChange}
            onChangeAvatar={handleAvatarFileChange}
            onChangeChatButtonSize={handleChatButtonSizeChange}
            onChangeChatButtonPosition={handleChatButtonPositionChange}
          />
        </div>
        <div className="webwidget-settings__right">
          <WebWidgetPreview
            color={color}
            companyName={companyName}
            welcomeMessage={webWidgetConfig?.displayWelcomeMessage || ''}
            avatarUrl={avatarFile?.objectUrl || avatarSrc}
            chatButtonSize={chatButtonSize}
            chatButtonPosition={chatButtonPosition}
          />
        </div>
      </div>

      <DialogActions>
        <Button variant="contained" color="secondary" size="large" onClick={onClose} disabled={loading}>
          {t('settings_web_widget_manage_form_cancel_btn')}
        </Button>
        <Button
          className="ml-auto"
          variant="contained"
          color="primary"
          size="large"
          onClick={handleNext}
          disabled={!formChanged || loading || channel.loading}
          loading={loading || channel.loading}
        >
          {t('settings_web_widget_manage_form_save_btn')}
        </Button>
      </DialogActions>
    </>
  );
});

export default WebWidgetThemeSettings;
