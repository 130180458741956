import cn from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';

import InvitationStatus from './InvitationStatus';
import TariffSelect from './TariffSelect';
import useMobileByWidth from '../../../hooks/useMobileByWidth';

interface BillingStepProps {
  className?: string;
}

export const BillingStep: React.FC<BillingStepProps> = ({className}) => {
  const {t} = useTranslation();
  const {ref: innerRef, isMobile} = useMobileByWidth<HTMLDivElement>();

  return (
    <div
      ref={innerRef}
      className={cn(
        'w-100 h-100 custom-scroll custom-scroll-y',
        'd-flex',
        isMobile ? 'flex-row flex-wrap ' : 'flex-column justify-content-center',
        className,
      )}
    >
      <div className="h1-bold">{t('onboarding_billing_title')}</div>
      <div>
        <InvitationStatus className="mt-5" />
      </div>
      <TariffSelect
        className="w-100 mt-5"
        itemClassName="mb-5"
      />
    </div>
  );
};

export default BillingStep;