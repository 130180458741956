import _phoneNumberMasks from './phoneNumberMasks';

interface StringDict {
  [key: string]: string;
}

export const phoneNumberMasks = _phoneNumberMasks as StringDict;

export const MASK_SYMBOL = '#';
export const DEFAULT_MASK = '+####################';

export const trimPhone = (phone?: string | null): string => {
  return phone?.replace(/#|\(|\)|\+|-| /g, '') || '';
};

export const getFullPhoneMask = (code: string) => {
  const trimedCode = code.replace(/#|\(|\)|\+|-/g, '');
  const mask = phoneNumberMasks[trimedCode];
  return mask || '';
};

export const addAditionalsToMask = (mask: string, phone?: string | null) => {
  const maskLength = (mask.match(new RegExp('#', 'g')) || []).length;
  const phoneLegth = trimPhone(phone).length;

  if (phoneLegth > maskLength) {
    for (let i = 0; i < phoneLegth - maskLength; i++) {
      mask += '#';
    }
  }

  return mask;
};

export const getFullMaskByPhone = (phone?: string | null) => {
  let mask = '';
  let phonePart = trimPhone(phone);

  while (!mask && phonePart) {
    mask = getFullPhoneMask(phonePart);
    phonePart = phonePart.substr(0, phonePart.length - 1);
  }

  const finalMask = addAditionalsToMask(mask, phone);
  return mask ? finalMask : mask;
};

export const formatPhoneNumber = (phone?: string | null) => {
  let mask = getFullMaskByPhone(phone);
  const trimedPhone = trimPhone(phone);
  const phoneLength = trimedPhone.length;

  if (mask && trimedPhone) {
    for (let i = 0; i < phoneLength; i++) {
      if (!isNaN(+trimedPhone[i])) {
        mask = mask.replace('#', trimedPhone[i]);
      }
    }
  }

  return mask.replace(/#/g, '');
};
