import React from 'react';
import {observer} from 'mobx-react';

import {useSnackbar} from '../../plugins/notistack';
import {useStore} from '../../stores/AppStore';
import SnackMessage from '../SnackMessage';
import {SnackbarType} from '../../stores/NotificationsStore';
import SnackAction from '../SnackAction';

export const Notifier = observer(() => {
  const {enqueueSnackbar} = useSnackbar();
  const {notifications, lightThemeChosen} = useStore();
  const displayed = React.useRef<string[]>([]);

  React.useEffect(() => {
    notifications.snackbars.forEach((snackbar) => {
      if (displayed.current.includes(snackbar.id)) {
        return;
      }

      if (snackbar.type === SnackbarType.Message) {
        snackbar.snackKey = enqueueSnackbar({
          message: snackbar.message as string,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          content: (key, message) => (
            <SnackMessage
              id={key}
              lightTheme={lightThemeChosen}
              message={message}
              subMessage={snackbar.subMessage}
              variant={snackbar.options?.variant}
              actions={snackbar.actions}
              hideCloseIcon={snackbar.hideCloseIcon}
            />
          ),
          autoHideDuration: 10000,
          ...snackbar.options,
          onClose: () => {
            notifications.removeSnackbar(snackbar.id);
          },
        });
      }

      if (snackbar.type === SnackbarType.Action) {
        snackbar.snackKey = enqueueSnackbar({
          message: snackbar.message as string,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          content: (key, message) => <SnackAction id={key} message={message} />,
          ...snackbar.options,
          onClose: () => {
            notifications.removeSnackbar(snackbar.id);
          },
        });
      }

      displayed.current = [...displayed.current, snackbar.id];
    });
  }, [notifications, notifications.snackbars, enqueueSnackbar, displayed, lightThemeChosen]);

  return null;
});

export default Notifier;
