import {action, makeObservable, observable} from 'mobx';

import {
  IMCChannelShownFields,
  IMCCreateChannelResponse,
  InstagramAuthorization,
  MCCreateChannel,
  entities
} from '../../../api/proto';
import Channel from '../Channel';
import ChannelsStore from '../ChannelsStore';
import getQueryStringParam from '../../../utils/getQueryStringParam';

export class Instagram {
  constructor(public channels: ChannelsStore) {
    makeObservable(this);
  }


  @observable loading = false;

  @action protected setLoading_ = (loading: boolean) => {
    this.loading = loading;
  };


  public createChannel = async (callbackCode: string, shownFields?: IMCChannelShownFields | null) => {
    if (this.loading) {
      return {error: {message: 'In process'}, res: null};
    }

    this.setAuthError_('');
    this.setLoading_(true);
    console.log("callbackCode before replace: "+ callbackCode);
    callbackCode = callbackCode.replace(/#_$/, "");
    const {error, res} = await this.channels.channelsRequest<IMCCreateChannelResponse>(
      {
        createChannel: new MCCreateChannel({
          type: entities.OzekonChannelType.OIT_INSTAGRAM,
          shownFields,
          instagramAuthFlow: new InstagramAuthorization({
            callbackCode,
          }),
        }),
      },
      'createChannel',
    );
    this.setLoading_(false);

    if (error) {
      return {error, res};
    }

    let newChannel: Channel | null = null;

    if (res) {
      newChannel = new Channel(
        {
          channelID: res?.channelID,
          shownFields,
          type: entities.OzekonChannelType.OIT_INSTAGRAM,
          workspaceID: this.channels.workspace.id,
        },
        this.channels.workspace,
      );

      this.channels.workspace.refreshMembers();
      this.channels.workspace.app.anal.channelSetupEvent(newChannel);
    }

    this.processCreateChannel_(callbackCode, res);

    return {error, res, channel: newChannel};
  };

  private processCreateChannel_ = (callbackCode: string, res?: IMCCreateChannelResponse | null) => {
    if (!res?.instagramAuthFlow?.status) {
      this.channels.load();
      this.setAuthTokenSuccessful_(callbackCode);
      return;
    }
  };

  @observable public authError: string = '';
  @observable public authToken: string = '';
  @observable public authTokenSuccessful: string = '';

  @action protected setAuthError_ = (authError: string) => {
    this.authError = authError;
  };

  @action protected setAuthToken_ = (authToken: string) => {
    this.authToken = authToken;
  };

  @action protected setAuthTokenSuccessful_ = (authTokenSuccessful: string) => {
    this.authTokenSuccessful = authTokenSuccessful;
  };

  public processAuthQueryCallback = () => {
    const errorCode = getQueryStringParam('error_code');
    const errorMessage = getQueryStringParam('error_message');
    const errorDescription = getQueryStringParam('error_description');

    if (errorDescription || errorMessage || errorCode) {
      this.setAuthError_(errorDescription || errorMessage || errorCode);
      return;
    }

    const accessToken = getQueryStringParam('code');
    console.debug('accessToken=', accessToken);
    if (!accessToken) {
      this.setAuthError_('Access token not found');
      return;
    }

    if (this.authTokenSuccessful === accessToken) {
      console.error('Access token allredy used');
      return;
    }

    this.setAuthToken_(accessToken);
  };
}

export default Instagram;