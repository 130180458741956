import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {entities} from '../../api/proto';
import useStore from '../../stores/useStore';
import ChannelCreationStepType from '../Settings/Channels/ChannelCreationStepType';
import InstagramCreationForm from '../Settings/Channels/Instagram/InstagramCreationForm';
import TelegramBotCreationForm from '../Settings/Channels/TelegramBotCreation';
import TelegramCreationForm from '../Settings/Channels/TelegramCreationForm';
import WebWidgetCreationForm from '../Settings/Channels/WebWidgetCreation';
import AccountCreationStepImage from './ChannelCreationStepImage';

interface ChannelCreationStepProps {
  onChangeStep?: (step: ChannelCreationStepType) => void;
}

export const ChannelCreationStep: React.FC<ChannelCreationStepProps> = observer(({
  onChangeStep,
}) => {
  const {t} = useTranslation();
  const {userStore} = useStore();
  const [type, setType] = React.useState<entities.OzekonChannelType>(entities.OzekonChannelType.OIT_TELEGRAM);
  const [currentStep, setCurrentStep] = React.useState<ChannelCreationStepType>(ChannelCreationStepType.CHANNEL_INFO);

  const handleChangeStep = (newPage: ChannelCreationStepType) => {
    setCurrentStep(newPage);
    onChangeStep?.(newPage);
  };

  const getTitle = (step: ChannelCreationStepType): string => {
    switch (step) {
      case ChannelCreationStepType.CHANNEL_CONFIRMATION:
        return t('onboarding_channel_creation_step_link_channel');
      case ChannelCreationStepType.TWO_FACTOR_AUTH:
        return t('onboarding_channel_creation_step_two_factor_auth');
    }
    return userStore.user?.fullName
      ? t('onboarding_channel_creation_success_greeting_with_name', {name: userStore.user?.fullName})
      : t('onboarding_channel_creation_success_greeting');
  };

  const getSubTitle = (step: ChannelCreationStepType): string => {
    switch (step) {
      case ChannelCreationStepType.CHANNEL_CONFIRMATION:
      case ChannelCreationStepType.SUCCESS:
        return '';
    }
    return t('onboarding_channel_creation_step_subtitle');
  };

  const handleChangeType = (newType: entities.OzekonChannelType) => {
    setType(newType);
  };

  const renderForm = (type_: entities.OzekonChannelType, step_: ChannelCreationStepType) => {
    switch (type_) {
      case entities.OzekonChannelType.OIT_WEB_WIDGET:
        return (
          <div className="h-100 w-100 d-flex align-items-center">
            <WebWidgetCreationForm
              isOnboarding
              className="w-100 h-100"
              type={type}
              onChangeType={handleChangeType}
              onChangeOnboardingStep={handleChangeStep}
            />
          </div>
        );
      case entities.OzekonChannelType.OIT_TELEGRAM:
        return (
          <TelegramCreationForm
            title={getTitle(step_)}
            subTitle={getSubTitle(step_)}
            onChangeStep={handleChangeStep}
            type={entities.OzekonChannelType.OIT_TELEGRAM}
            onChangeType={handleChangeType}
          />
        );
      case entities.OzekonChannelType.OIT_INSTAGRAM:
        return (
          <InstagramCreationForm
            title={getTitle(step_)}
            subTitle={getSubTitle(step_)}
            onChangeStep={handleChangeStep}
            type={entities.OzekonChannelType.OIT_INSTAGRAM}
            onChangeType={handleChangeType}
          />
        );
      case entities.OzekonChannelType.OIT_TELEGRAM_BOT:
        return (
          <TelegramBotCreationForm
            title={getTitle(step_)}
            subTitle={getSubTitle(step_)}
            onChangeStep={handleChangeStep}
            type={entities.OzekonChannelType.OIT_TELEGRAM_BOT}
            onChangeType={handleChangeType}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderForm(type, currentStep)}

      {type !== entities.OzekonChannelType.OIT_WEB_WIDGET ||
        (type === entities.OzekonChannelType.OIT_WEB_WIDGET && currentStep === ChannelCreationStepType.SUCCESS) ? (
        <AccountCreationStepImage
          type={type}
          success={currentStep === ChannelCreationStepType.SUCCESS}
        />
      ) : null}
    </>
  );
});

export default ChannelCreationStep;
