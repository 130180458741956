import {observer} from 'mobx-react';
import React, {Suspense} from 'react';
import {Routes as BrowserRoutes, Navigate, Route, matchPath, useLocation} from 'react-router-dom';

import Modals from '../modals/Modals';
import Paths from '../routes/Paths';
import LocationType from '../stores/RoutingHistory/LocationType';
import useStore from '../stores/useStore';
import {BrowserRouter} from './BrowserRouter';
import browserHistory from './browserHistory';
import {IRouteProps, routes} from './routes';
import requireRegistrationForPath from './Paths/requireRegistrationForPath';

const AppRouteLayout = observer(({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}: IRouteProps) => {
  const location = useLocation();
  const {userStore} = useStore();

  if (
    isAuthProtected &&
    userStore?.user &&
    !userStore.user.isLoggedIn &&
    !matchPath(Paths.Login, location.pathname) &&
    !matchPath(Paths.Registration, location.pathname)
  ) {
    const requireRegistration = requireRegistrationForPath(location.pathname);
    return (
      <Navigate
        to={requireRegistration ? Paths.Registration : Paths.Login}
        replace
        state={{from: location}}
      />
    );
  }

  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
});

export const Routes = React.memo(() => {
  const {routingHistory} = useStore();

  React.useEffect(() => {
    const unlisted = browserHistory.listen(({location, action}) => {
      const _location = location as LocationType;
      routingHistory.locationListener({location: _location, action});
    });

    return () => {
      unlisted();
    };
  }, [routingHistory]);

  return (
    <BrowserRouter history={browserHistory}>
      <Suspense fallback={<div />}>
        <BrowserRoutes>
          {routes.map((route, idx) => (
            <Route
              key={idx}
              element={<AppRouteLayout {...route} />}
              path={route.path}
            />
          ))}
        </BrowserRoutes>
      </Suspense>
      <Modals />
    </BrowserRouter>
  );
});

export default Routes;
