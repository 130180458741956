import * as PopperJS from '@popperjs/core';
import cn from 'classnames';
import {observer} from 'mobx-react';
import IconButton from 'o-ui/Button/IconButton';
import {Emoji} from 'o-ui/EmojiPicker/EmojiPicker';
import React from 'react';

import Chat from '../../../../stores/Chat';
import ChatEmojiTooltip, {ChatEmojiTooltipRef} from './ChatEmojiTooltip';
import {SymbolMenu} from './SymbolMenu';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

interface ChatEmojiButtonProps {
  chat: Chat;
  onAddEmoji: (e: Emoji) => void;
  onAddSticker?: (e: any) => void;
  popperOffset?: [number, number];
  placement?: PopperJS.Placement;
  transformOrigin?: React.CSSProperties['transformOrigin'];
  hideStickers?: boolean;
  className?: string;
  buttonClassName?: string;
}

export const ChatEmojiButton = observer((props: ChatEmojiButtonProps) => {
  const tooltipRef = React.useRef<ChatEmojiTooltipRef>(null);
  const ref = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const [visible, setVisible] = React.useState<boolean>(false);


  const handleClickOutside = () => {
    setVisible(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  const handleAddSticker = (stickerInfo: any) => {
    props.onAddSticker?.(stickerInfo);
    tooltipRef.current?.triggerRef?.click();
  };

  const handleOpenTooltipClick = () => {
    setVisible((val) => !val);
  };

  const handleAddEmoji = (e: Emoji) => {
    props.onAddEmoji(e);
    setVisible(false);
  };

  React.useEffect(() => {
    if (tooltipRef.current) {
      tooltipRef.current?.setTriggerRef(buttonRef.current);
    }
  }, []);

  return (
    <div
      ref={ref}
      className={cn(
        'emoji-dropdown shadow-none btn-group',
        props.className,
      )}
    >
      <IconButton
        ref={buttonRef}
        className={cn(
          'tg-icon-emotion-happy-line line-height-22 font-size-22',
          {active: visible},
          props.buttonClassName,
        )}
        onClick={handleOpenTooltipClick}
      />

      <ChatEmojiTooltip
        ref={tooltipRef}
        popperOffset={props.popperOffset || [0, 55]}
        placement={props.placement}
        transformOrigin={props.transformOrigin}
        visible={visible}
      >
        <SymbolMenu
          chat={props.chat}
          onAddEmoji={handleAddEmoji}
          onAddSticker={handleAddSticker}
          hideStickers={props.hideStickers}
        />
      </ChatEmojiTooltip>
    </div>
  );
});

export default ChatEmojiButton;
