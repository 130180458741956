import React from 'react';
import {observer} from 'mobx-react';

import Avatar from 'o-ui/Avatar';
import {useStore} from '../../../stores/AppStore';


export const SystemUserTitle = observer(() => {
  const {api, userStore} = useStore();
  return (
    <div className="system-user-title">
      <Avatar
        className="system-user-title__avatar"
        size="xs"
        name={userStore.user?.fullName}
        status={api.connectionIsOpen ? 'online' : 'offline'}
        statusTitle={api.connectionIsOpen ? '' : 'WebSocket disconnected'}
      >
        {api.navigatorOnLine ? null : (
          <span className="avatar__disconnected-status tg-icon tg-icon-disconnected-fill" title="Network offline" />
        )}
      </Avatar>

      <span className="system-user-title__user-name color-body-primary text-truncate" title={userStore.user?.fullName}>
        {userStore.user?.fullName}
      </span>
    </div>
  );
});

export default SystemUserTitle;
