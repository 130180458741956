import React from 'react';

import Chat from '../../../../stores/Chat';
import Peer from '../../../../stores/Peer';
import PeersListItem from './PeersListItem';


interface PeersListProps {
  chat: Chat;
  peers: Peer[];
  onSelect?: (peer: Peer) => void;
}

export const PeersList: React.FC<PeersListProps> = React.memo(({chat, peers, onSelect}) => {
  return (
    <div className="peers-list custom-scroll-y">
      {peers.map((peer, i) => (
        <PeersListItem
          key={`${peer.id?.toString()}_${i}`}
          chat={chat}
          peer={peer}
          onClick={onSelect}
        />
      ))}
    </div>
  );
});

export default PeersList;
