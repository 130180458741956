import React from 'react';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import Chat from '../../../../stores/Chat';
import useStore from '../../../../stores/useStore';


interface ChatExtraInfoProps {
  className?: string;
  chat: Chat;
}

export const ChatExtraInfo = observer(({className, chat}: ChatExtraInfoProps) => {
  const {t} = useTranslation();
  const {notifications} = useStore();

  const handleCopyLink = () => {
    copy(chat.link);
    notifications.action(t('chat_user_chat_info_invite_link_copied'));
  };

  const showAltName = () => {
    chat?.store?.editChat({
      showAlternativeName: true,
    });
  };

  const hideAltName = () => {
    chat?.store?.editChat({
      showAlternativeName: false,
    });
  };

  return (
    <div className={cn('chat-extra-info', className)}>
      <table className="chat-extra-info__table">
        <tbody>
          <>
            <tr>
              <td className="align-top">
                <i className="chat-extra-info__icon tg-icon tg-icon-user-fill" />
              </td>
              <td className="align-top">
                <span className="chat-extra-info__name">{t('chat_user_chat_info_group_name')}</span>
              </td>
              <td className="align-top">
                <div className="chat-extra-info__value two-rows">
                  <div>{chat.name}</div>
                  {!chat.alternativeName ? null : chat.showAlternativeName ? (
                    <div
                      className="color-brand-primary body3-regular cursor-pointer mt-1 mb-3"
                      onClick={hideAltName}
                    >
                      {t('chat_user_chat_info_display_name_label')}
                    </div>
                  ) : (
                    <div className="color-body-tertiary body3-regular mt-1 mb-3">
                      {t('chat_user_chat_info_name_displayed_label')}
                    </div>
                  )}
                </div>
              </td>
            </tr>

            {chat.alternativeName ? (
              <tr>
                <td className="align-top">
                  <i className="chat-extra-info__icon tg-icon tg-icon-user-fill" />
                </td>
                <td className="align-top">
                  <span className="chat-extra-info__name">{t('chat_user_chat_info_alt_name')}</span>
                </td>
                <td className="align-top">
                  <div className="chat-extra-info__value two-rows">
                    <div>{chat.alternativeName}</div>
                    {!chat.showAlternativeName ? (
                      <div
                        className="color-brand-primary body3-regular cursor-pointer mt-1 mb-3"
                        onClick={showAltName}
                      >
                        {t('chat_user_chat_info_display_name_label')}
                      </div>
                    ) : (
                      <div className="color-body-tertiary body3-regular mt-1 mb-3">
                        {t('chat_user_chat_info_name_displayed_label')}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ) : null}

            <tr>
              <td className="align-top">
                <i className="chat-extra-info__icon tg-icon tg-icon-telegram-fill" />
              </td>
              <td className="align-top">
                <span className="chat-extra-info__name">{t('chat_user_chat_info_invite_link')}</span>
              </td>
              <td className="align-top">
                <span className="chat-extra-info__link cursor-pointer text-break" onClick={handleCopyLink}>
                  {chat.link}
                </span>
              </td>
            </tr>
          </>
        </tbody>
      </table>
      {/*
      <div className="chat-extra-info__description-title">{t('chat_user_chat_info_description')}</div>
      <div className="chat-extra-info__description text-break"></div>
      */}
    </div>
  );
});

export default ChatExtraInfo;
