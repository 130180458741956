import {ErrorEvent} from '../../../api/network';
import {IMCMethodResponse} from '../../../api/proto';
import getCreateChannelError from './getCreateChannelError';

export const getChannelActionError = (res?: IMCMethodResponse | null): ErrorEvent | null => {
  if (res?.createChannel) {
    return getCreateChannelError(res?.createChannel);
  }

  return null;
};

export default getChannelActionError;