import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import CustomizedDialogTitle from 'o-ui/Dialog/CustomizedDialogTitle';
import DialogContent from 'o-ui/Dialog/DialogContent';

import useWorkspaceNavigate from '../hooks/useWorkspaceNavigate';
import Paths from '../routes/Paths';
import {ModalItem} from '../stores/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

import successIcon from '../assets/images/check-in-double-green-circle.svg';

interface IProps {
  modal: ModalItem;
}

export const ModalFreePlanActivated = observer(({modal}: IProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const workspaceNavigate = useWorkspaceNavigate();

  const handleGoToChat = () => {
    workspaceNavigate(Paths.Chat);
    handleCloseClick();
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  const handleGoToBilling = () => {
    workspaceNavigate(Paths.Billing);
    handleCloseClick();
  };

  return (
    <ModalDialog
      ref={modalRef}
      modal={modal}
      maxWidth={modal.data.maxWidth || 'md'}
      fullWidth={true}
    >
      <CustomizedDialogTitle>
        <div className="d-flex flex-column">
          <img className="mr-auto" src={successIcon} alt="" style={{width: 80}} />
          <div className="h3-medium mt-5">{t('Free Plan Activated!')}</div>
          <div className="body1-regular mt-1">{t('You have successfully activated the Free Plan')}</div>
        </div>
      </CustomizedDialogTitle>

      <DialogContent className="px-6 custom-scroll-y">
        <div className="h4-medium mt-5">{t('Enjoy unlimited connected channels, unlimited chats, and more')}</div>
        <div className="body1-regular mt-1">{t('If you need additional seats, consider upgrading to a higher plan')}</div>
      </DialogContent>

      <div className="d-flex pt-5 px-6 pb-6">
        <Button
          className="flex-grow-1 mr-3"
          variant="outlined"
          color="tertiary"
          size="large"
          onClick={handleGoToChat}
          autoFocus
        >
          {t('Continue with Free Plan')}
        </Button>
        <Button
          className="flex-grow-1"
          variant="contained"
          color="primary"
          size="large"
          onClick={handleGoToBilling}
        >
          {t('Upgrade Plan')}
        </Button>
      </div>
    </ModalDialog>
  );
});

export default ModalFreePlanActivated;