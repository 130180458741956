import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import useStore from '../../../stores/useStore';
import formatMoney from '../../../utils/format/formatMoney';
import useTariff from '../../Settings/Billing/PaymentForm/useTariff';

interface IProps {
  className?: string;
  tariffId?: string;
}

export const TariffPlanInfo: React.FC<IProps> = observer(({
  className,
  tariffId,
}) => {
  const {t} = useTranslation();
  const tariff = useTariff(tariffId);
  const {anal} = useStore();

  React.useEffect(() => {
    if (tariff) {
      anal.registrationCheckout(tariff);
    }
  }, [
    anal,
    tariff,
  ]);

  return tariff ? (
    <div
      className={cn(
        'bg-background-secondary d-flex flex-column radius-8 border-primary p-5',
        className,
      )}
    >
      <div className="body1-regular color-body-tertiary">
        {t('settings_billing_tariff_your_plan')}
      </div>

      <div className="h4-bold mt-1 pt-1">
        {tariff.name}
      </div>

      <div className="b2-regular">
        <span className="px-1">
          {t('settings_billing_tariff_price_per_month', {
            price: formatMoney({value: tariff?.price, currency: tariff?.currency})
          })}
        </span>
        &#183;
        <span className="px-1">{t('settings_billing_tariff_seats_count', {count: tariff?.includedUsers?.toNumber() || 0})}</span>
      </div>
    </div>
  ) : null;
});

export default TariffPlanInfo;
