export const ExternalApiAction = {
  APP_INFO: 'APP_INFO',
  APP_WINDOW_STATE: 'APP_WINDOW_STATE',
  SIGN_OUT: 'SIGN_OUT',
  LOGGER: 'LOGGER',
  APP_NEW_VERSION_AVAILABLE: 'APP_NEW_VERSION_AVAILABLE',
  APP_NEW_VERSION_DOWNLOADED: 'APP_NEW_VERSION_DOWNLOADED',
};

export const ExternalApiEvent = {
  UPDATE_UNREAD_COUNTER: 'UPDATE_UNREAD_COUNTER',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_WEB_APP: 'UPDATE_WEB_APP',
  CHECK_DESKTOP_APP_NEW_VERSION: 'CHECK_DESKTOP_APP_NEW_VERSION',
  UPDATE_DESKTOP_APP: 'UPDATE_DESKTOP_APP',
  WINDOW_MINIMIZE: 'WINDOW_MINIMIZE',
  WINDOW_RESTORE: 'WINDOW_RESTORE',
  WINDOW_MAXIMIZE: 'WINDOW_MAXIMIZE',
  WINDOW_UNMAXIMIZE: 'WINDOW_UNMAXIMIZE',
  WINDOW_TOGGLE_MAXIMIZE: 'WINDOW_TOGGLE_MAXIMIZE',
  WINDOW_CLOSE: 'WINDOW_CLOSE',
};

export const send = (channel: string, args?: any) => {
  try {
    if (window.externalAppApi) {
      window.externalAppApi.publish(channel, args);
    }
  } catch (e: any) {
    console.debug('error:', channel, e?.message);
  }
};

export const subscribe = (channel: string, func: any) => {
  try {
    if (window.externalAppApi) {
      window.externalAppApi.subscribe(channel, func);
    }
  } catch (e: any) {
    console.debug('error:', channel, e?.message);
  }
};
