import {action, computed, makeObservable, observable} from 'mobx';
import visibility from 'visibilityjs';
import {AppStore} from './AppStore';

export class PageStore {
  constructor(private app: AppStore) {
    makeObservable(this);
    
    if (visibility.isSupported()) {
      visibility.change(this.onChangePageVisibility);
    } else {
      this.setPageVisible();
    }
    window.addEventListener('focus', this.onFocus);
    window.addEventListener('blur', this.onBlur);
  }

  @observable inFocus = true;
  @observable isVisible = true;

  @action setPageVisible = () => {
    this.isVisible = true;
  };

  @action updatePageVisibility = () => {
    if (visibility.state() === 'visible') {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
  };

  @action private onChangePageVisibility = (e: Event, state: string) => {
    if (state === 'visible') {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
  };

  @action onFocus = () => {
    this.inFocus = true;
  };

  @action onBlur = () => {
    this.inFocus = false;
  };

  @computed get isObserving() {
    return this.isVisible && this.inFocus;
  }
}

export default PageStore;
