import Long from 'long';

import {DEFAULT_CURRENCY_CODE, getCurrencySymbol} from '../currencies';
import formatMoneyAmount from './formatMoneyAmount';

export interface MoneyAmount {
  currency?: string | null;
  value?: Long | number | null;
}

export const formatMoney = (amount: MoneyAmount, showCurrencyCode?: boolean): string => {
  const value = amount.value instanceof Long ? amount.value.toNumber() : amount.value;
  const currency = amount.currency || DEFAULT_CURRENCY_CODE;
  
  if (showCurrencyCode) {
    return `${formatMoneyAmount(value, currency)} ${amount.currency || ''}`;
  }

  const currencySymbol: string = getCurrencySymbol(currency);

  return `${currencySymbol} ${formatMoneyAmount(value, currency)}`;
};

export default formatMoney;