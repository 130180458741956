import React from 'react';

import {useStore} from '../../../../stores/AppStore';
import {DOWNLOAD_CANCELED_ERROR_MESSAGE} from '../../../../stores/Attachment/AttachmentStore';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';
import downloadFile from '../../../../utils/downloadFile';


interface MessageAttachmentInfo {
  download: () => void;
  src?: string;
  mimeType?: string;
  width?: number;
  height?: number;
  duration?: number;
  error?: string;
}

export default function useDownloadAttachment(attachment?: MessageAttachment | null): MessageAttachmentInfo {
  const {uploader} = useStore();

  const width = attachment?.width?.toNumber();
  const height = attachment?.height?.toNumber();
  const duration = attachment?.duration?.toNumber();

  const [src, setSrc] = React.useState<string | undefined>();
  const [mimeType, setMimeType] = React.useState<string | undefined>();
  const [error, setError] = React.useState<string | undefined>();

  const loadingRef = React.useRef<boolean>(false);

  const message = attachment?.message;
  const chatStore = message?.chat.store;

  const download = React.useCallback(async () => {
    if (src && attachment) {
      downloadFile({url: src, fileName: attachment.name});
      return;
    }

    if (!attachment?.id?.length || src || (error && error !== DOWNLOAD_CANCELED_ERROR_MESSAGE) || loadingRef.current) {
      return;
    }

    const dataFile = uploader.getFile(attachment?.id);
    if (dataFile) {
      setSrc(dataFile.objectUrl);
      setMimeType(dataFile.mimeType);
      console.debug(`%c----->useDownloadAttachment FileInProgress: ${dataFile.objectUrl}`, 'color: red');
      downloadFile({url: dataFile.objectUrl, fileName: dataFile.fileName});
      return;
    }

    loadingRef.current = true;
    const res = await chatStore?.getAttachment(attachment.id, attachment.name);
    loadingRef.current = false;

    if (res?.error) {
      setError(res?.error?.message);
      setSrc(undefined);
    } else if (res?.res?.src) {
      downloadFile({url: res?.res?.src, fileName: attachment.name});
      setSrc(res?.res?.src);
      setMimeType(res?.res?.mimeType);
      setError(undefined);
    }

  }, [attachment, uploader, chatStore, error, src, loadingRef]);

  return {
    download,
    width,
    height,
    duration,
    src,
    mimeType,
    error,
  };
}
