import cn from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Snippet} from '../../../../stores/Snippets';
import {formatText} from '../../../../utils/textFormat';

interface SnippetsBarItemProps {
  snippet: Snippet;
  onClick(snippet: Snippet): void;
}

const SnippetsBarItem: React.FC<SnippetsBarItemProps> = ({snippet, onClick}) => {
  const {t} = useTranslation();

  const typeLabel = snippet.isWorkspace
    ? t('chat_user_chat_snippets_bar_workspace_title')
    : t('chat_user_chat_snippets_bar_personal_title');

  return (
    <div className="snippets-bar-item px-4" onClick={() => onClick(snippet)}>
      <div className="snippets-bar-item__shortcut">{snippet.command}</div>
      <div
        className="snippets-bar-item__content text-truncate"
        dangerouslySetInnerHTML={{__html: formatText(snippet.text || '', snippet.entities)}}
      />
      <div className="snippets-bar-item__type">
        <div
          className={cn('snippets-bar-item__type-label', {
            workspace: snippet.isWorkspace,
            personal: snippet.isPersonal,
          })}
        >
          {typeLabel}
        </div>
      </div>
    </div>
  );
};

export default SnippetsBarItem;
