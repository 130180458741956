import {observer} from 'mobx-react';
import React from 'react';

import CustomScrollbar from 'o-ui/CustomScrollbar';
import VariableSizeList, {IVariableSizeList} from 'o-ui/VirtualizedList';
import {CHAT_ITEM_HEIGHT, CHAT_ITEM_HEIGHT_COMPACT} from '../../../../../constants';
import {Chat, ChatClickEventHandler} from '../../../../../stores/Chat';
import {MessageClickEventHandler} from '../../../../../stores/Message';
import {ITag} from '../../../../../stores/MessageTagsStore';
import useStore from '../../../../../stores/useStore';
import ChatsFoundListItem from './ChatsFoundListItem';


interface ChatsFoundListProps {
  itemCount: number;
  chatForSearch?: Chat;
  onCloseChatForSearch?: (chat: Chat) => void;
  onChatClick?: ChatClickEventHandler;
  onFoundMessageClick?: MessageClickEventHandler;
  onFoundTagClick?: (tag?: ITag | null) => void;
  width: number;
  height: number;
}

export const ChatsFoundList: React.FC<ChatsFoundListProps> = observer((props) => {
  const {layOutStore} = useStore();
  const listRef = React.useRef<IVariableSizeList>(null);
  const sizeMap = React.useRef({});
  const setSize = React.useCallback((index, size) => {
    sizeMap.current = {...sizeMap.current, [index]: size};
    listRef.current?.resetAfterIndex(index);
  }, []);

  const getItemSize = React.useCallback((index: number): number => {
    const height = layOutStore.compactMode ? CHAT_ITEM_HEIGHT_COMPACT : CHAT_ITEM_HEIGHT;
    return sizeMap.current[index] || height;
  }, [layOutStore.compactMode]);

  return (
    <VariableSizeList
      ref={listRef}
      className="chat-list chat-user-list"
      width={props.width}
      height={props.height}
      itemCount={props.itemCount}
      itemSize={getItemSize}
      outerElementType={CustomScrollbar}
    >
      {(listRow) => (
        <ChatsFoundListItem
          row={listRow}
          setSize={setSize}
          onChatClick={props.onChatClick}
          onFoundMessageClick={props.onFoundMessageClick}
          onFoundTagClick={props.onFoundTagClick}
          onCloseChatForSearch={props.onCloseChatForSearch}
        />
      )}
    </VariableSizeList>
  );
});

export default ChatsFoundList;
