import React from 'react';
import {observer} from 'mobx-react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import Switch from 'o-ui/Switch';
import IconButton from 'o-ui/Button/IconButton';
import {useStore} from '../../../../../stores/AppStore';
import ContextMenuType from '../../../../../stores/ContextMenuType';
import {WebWidgetSnippet} from './WebWidgetSnippetsList';
import WebWidgetSnippetForm from './WebWidgetSnippetForm';

import {ReactComponent as DragIcon} from '../../../../../assets/icons/drag-icon.svg';


interface WebWidgetSnippetItemProps {
  index: number;
  snippet: WebWidgetSnippet;
  onEdit?: (index: number, snippet: WebWidgetSnippet) => void;
  onRemove?: (index: number, snippet: WebWidgetSnippet) => void;
}

export const WebWidgetSnippetItem: React.FC<WebWidgetSnippetItemProps> = observer((props) => {
  const {contextMenu} = useStore();
  const [editFormOpened, setEditFormOpened] = React.useState<boolean>(false);

  const {index, snippet, onEdit, onRemove} = props;

  const {setNodeRef, attributes, listeners, transform, transition} = useSortable({
    id: snippet.id || '',
  });

  const sortingStyle = {
    transform: CSS.Translate.toString(transform),
    transition,
    touchAction: 'none',
  };

  const handleCloseEditForm = () => {
    setEditFormOpened(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onEdit?.(index, {text: snippet.text, enabled: event.target.checked, id: snippet.id});
  };

  const handleSubmitChanges = (snippetText: string) => {
    onEdit?.(index, {text: snippetText, enabled: snippet.enabled, id: snippet.id});
    setEditFormOpened(false);
  };

  const handleOpenActionsMenu = (e: React.MouseEvent) => {
    contextMenu.open({clientX: e.clientX, clientY: e.clientY}, ContextMenuType.WEB_WIDGET_SNIPPET_MENU, {
      callbacks: {
        snippetEdit: () => {
          setEditFormOpened(true);
        },
        snippetDelete: () => {
          onRemove?.(index, snippet);
        },
      },
    });
  };

  return (
    <div ref={setNodeRef} style={sortingStyle} className="webwidget-snippets-item w-100">
      {editFormOpened ? (
        <WebWidgetSnippetForm
          inputName="accountName"
          className="webwidget-snippets-item__form"
          snippetText={snippet.text}
          onSubmit={handleSubmitChanges}
          onClose={handleCloseEditForm}
        />
      ) : (
        <div className="webwidget-snippets-item__inner">
          <div className="webwidget-snippets-item__left text-truncate">
            <IconButton className="webwidget-snippets-item__drag-button" {...listeners} {...attributes}>
              <DragIcon className="webwidget-snippets-item__drag-button-icon" />
            </IconButton>

            <div className="webwidget-snippets-item__text text-truncate">{snippet.text || ''}</div>
          </div>
          <div className="webwidget-snippets-item__actions">
            <div className="webwidget-snippets-item__switch-box">
              <Switch className="small-switcher" checked={!!snippet.enabled} onChange={handleChange} />
            </div>
            <IconButton
              className="chat-head__button tg-icon-more-line"
              onClick={handleOpenActionsMenu}
              type="button"
              size="small"
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default WebWidgetSnippetItem;
