import cn from 'classnames';
import {FormikProps, useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {animated, useTransition} from 'react-spring';
import * as Yup from 'yup';

import {entities} from '../../api/proto';
import useStore from '../../stores/useStore';
import WorkspaceMember from '../../stores/Workspaces/WorkspaceMember';
import {SettingsHeadState} from './SettingsHead';
import SettingsTab from '../../stores/LayOutStore/SettingsTab';
import InvitationTab from './WorkspaceMembers/Invitation/InvitationTab';
import InvitedMemberManageTab from './WorkspaceMembers/MemberManage/InvitedMemberManageTab';
import MemberManageTab from './WorkspaceMembers/MemberManage/MemberManageTab';
import MembersAdministration from './WorkspaceMembers/MembersAdministration';
import useAllowChangeHeadState from './useAllowChangeHeadState';

export type InvitationByEmailFormikProps = FormikProps<{
  email: string;
  role: entities.WorkspaceRole.WR_OPERATOR;
  firstName: string;
  lastName: string;
}>;

export enum OperatorType {
  All,
  Admins,
  Operators,
}

export enum OperatorStatus {
  All,
  Active,
  Invited,
  Blocked,
  Expired,
}

export enum MembersAdministrationTabs {
  Members,
  InviteLinks,
}

enum Pages {
  OPERATORS = 0,
  OPERATOR_SETTINGS = 2,
  OPERATOR_INVITATION_FORM = 3,
  OPERATOR_INVITATION_ACCESS = 4,
  INVITED_OPERATOR_SETTINGS = 5,
}

export enum InvitationFormTabs {
  INVITE_BY_EMAIL = 1,
  INVITE_BY_LINK = 2,
}

interface IProps {
  onChangeState?: (state: SettingsHeadState) => void;
}

export interface IWorkspaceMembersTabRef {
  reset?: () => void;
}

export const WorkspaceMembersTab = observer(
  React.forwardRef<IWorkspaceMembersTabRef, IProps>((props: IProps, ref) => {
    React.useImperativeHandle(ref, () => ({
      reset: () => {
        openPage(Pages.OPERATORS, Pages.OPERATOR_SETTINGS);
      },
    }));

    const {t} = useTranslation();
    const app = useStore();
    const {animationsEnabled, activeWorkspace, notifications} = app;
    const [selectedOperator, setSelectedOperator] = React.useState<WorkspaceMember | null>(null);

    const [prevPageIndex, setPrevPageIndex] = React.useState<Pages>(Pages.OPERATORS);
    const [pageIndex, setPageIndex] = React.useState<Pages>(Pages.OPERATORS);
    const [pageTitle, setPageTitle] = React.useState<string>(t('settings_workspace_members'));
    const [pageLinks, setPageLinks] = React.useState<string[]>([]);

    const [invitationFormActiveTab, setInvitationFormActiveTab] = React.useState<InvitationFormTabs>(
      InvitationFormTabs.INVITE_BY_LINK,
    );

    const [membersAdministrationActiveTab, setMembersAdministrationActiveTab] = React.useState(
      MembersAdministrationTabs.Members,
    );

    const [selectedOperatorTypeTab, setSelectedOperatorTypeTab] = React.useState(OperatorType.All);
    const [selectedOperatorStatusTab, setSelectedOperatorStatusTab] = React.useState(OperatorStatus.All);

    React.useEffect(() => {
      const updated = activeWorkspace.members.find(({id}) => selectedOperator?.id && id?.equals(selectedOperator.id));
      updated && setSelectedOperator(updated);
    }, [activeWorkspace.members, selectedOperator]);

    const invitationByEmailFormik: InvitationByEmailFormikProps = useFormik({
      initialValues: {
        email: '',
        role: entities.WorkspaceRole.WR_OPERATOR,
        firstName: '',
        lastName: '',
      },
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object({
        email: Yup.string()
          .email(t('settings_workspace_members_invitation_email_placeholder'))
          .required(t('settings_workspace_members_invitation_email_required')),
        role: Yup.number().required(t('settings_workspace_members_invitation_role_required')),
      }),
      onSubmit: async (values, {resetForm}) => {
        const {error, res} = await activeWorkspace.invites.invite({
          email: values.email,
          role: values.role,
        });
        if (error) {
          notifications.error(error.message);
        }
        if (res) {
          notifications.success(t('settings_workspace_members_invitation_successfully_sent_notification'));
          await activeWorkspace.load();
          resetForm();
        }
      },
    });

    const setPageHeaders = React.useCallback(
      (newPage: Pages) => {
        switch (newPage) {
          case Pages.OPERATORS:
            setPageTitle(t('settings_workspace_members'));
            setPageLinks([]);
            break;
          case Pages.OPERATOR_SETTINGS:
            setPageTitle(t('settings_workspace_members_manage_user'));
            setPageLinks([t('settings_workspace_members')]);
            break;
          case Pages.OPERATOR_INVITATION_FORM:
            setPageTitle(t('settings_workspace_members_invite'));
            setPageLinks([t('settings_workspace_members')]);
            break;
          case Pages.OPERATOR_INVITATION_ACCESS:
            setPageTitle(t('settings_workspace_members_access'));
            setPageLinks([t('settings_workspace_members'), t('settings_workspace_members_invite')]);
            break;
          case Pages.INVITED_OPERATOR_SETTINGS:
            setPageTitle(t('settings_workspace_members_manage_invite'));
            setPageLinks([t('settings_workspace_members')]);
            break;
        }
      },
      [t],
    );

    const openPage = React.useCallback(
      (newPage: Pages, currentPage: Pages) => {
        setPrevPageIndex(currentPage);
        setPageIndex(newPage);
        setPageHeaders(newPage);
      },
      [setPageHeaders],
    );

    const goBack = React.useCallback(() => {
      openPage(Pages.OPERATORS, Pages.OPERATOR_SETTINGS);
      invitationByEmailFormik.resetForm();
    }, [openPage, invitationByEmailFormik]);

    const goBackFromInviteForm = React.useCallback(() => {
      openPage(Pages.OPERATORS, Pages.INVITED_OPERATOR_SETTINGS);
    }, [openPage]);

    const goBackFromInvitationAccess = React.useCallback(() => {
      openPage(Pages.OPERATOR_INVITATION_FORM, Pages.OPERATOR_INVITATION_ACCESS);
    }, [openPage]);

    const allowChangeHeadState = useAllowChangeHeadState(SettingsTab.WORKSPACE_MEMBERS);

    React.useEffect(() => {
      if (!allowChangeHeadState) {
        return;
      }
      props.onChangeState?.({
        title: pageTitle,
        links: pageLinks,
        onBack: () => (Pages.OPERATOR_INVITATION_ACCESS === pageIndex ? goBackFromInvitationAccess() : goBack()),
      });
    }, [props, allowChangeHeadState, pageTitle, pageLinks, goBack, goBackFromInvitationAccess, pageIndex]);

    const handleSubmitOperator = React.useCallback(() => {
      goBack();
      activeWorkspace.loadMembers();
    }, [goBack, activeWorkspace]);

    const handleSubmitInviteForm = React.useCallback(() => {
      goBackFromInviteForm();
      activeWorkspace.invites.load();
    }, [goBackFromInviteForm, activeWorkspace]);

    const openOperatorManage = React.useCallback(
      (member: WorkspaceMember) => {
        setSelectedOperator(member);

        if (member.invited) {
          openPage(Pages.INVITED_OPERATOR_SETTINGS, Pages.OPERATORS);
        } else {
          openPage(Pages.OPERATOR_SETTINGS, Pages.OPERATORS);
        }
      },
      [openPage],
    );

    const openInvitationAccess = React.useCallback(() => {
      openPage(Pages.OPERATOR_INVITATION_ACCESS, Pages.OPERATORS);
    }, [openPage]);

    const handleChangeInvitationFormTab = (newTab: InvitationFormTabs) => {
      setInvitationFormActiveTab(newTab);
    };

    const handleChangeMembersAdministrationTab = (newValue: MembersAdministrationTabs) => {
      setMembersAdministrationActiveTab(newValue);
    };

    const handleChangeOperatorTypeTab = (newTab: OperatorType) => {
      setSelectedOperatorTypeTab(newTab);
    };

    const handleChangeOperatorStatusTab = (newTab: OperatorStatus) => {
      setSelectedOperatorStatusTab(newTab);
    };

    const transitions = useTransition(pageIndex, {
      from:
        prevPageIndex === pageIndex
          ? {}
          : {
            opacity: 0,
            transform: prevPageIndex < pageIndex ? 'translate3d(100%, 0, 0)' : 'translate3d(-100%, 0, 0)',
          },
      enter: {opacity: 1, transform: 'translate3d(0%, 0, 0)'},
      leave: {
        opacity: 1,
        transform: prevPageIndex < pageIndex ? 'translate3d(-100%, 0, 0)' : 'translate3d(100%, 0, 0)',
      },
      // config: {duration: 1000},
    });

    const getPageContent = (page: Pages) => {
      switch (page) {
        case Pages.OPERATORS:
          return (
            <MembersAdministration
              onChangeTab={handleChangeMembersAdministrationTab}
              selectedTab={membersAdministrationActiveTab}
              onOpenOperatorManage={openOperatorManage}
              selectedOperatorTypeTab={selectedOperatorTypeTab}
              selectedOperatorStatusTab={selectedOperatorStatusTab}
              onChangeOperatorTypeTab={handleChangeOperatorTypeTab}
              onChangeOperatorStatusTab={handleChangeOperatorStatusTab}
            />
          );
        case Pages.OPERATOR_SETTINGS:
          return selectedOperator ? (
            <MemberManageTab
              bodyClassName="px-6 py-5"
              member={selectedOperator}
              onSubmit={handleSubmitOperator}
              onClose={goBack}
            />
          ) : null;
        case Pages.OPERATOR_INVITATION_FORM:
          return (
            <InvitationTab
              activeTab={invitationFormActiveTab}
              onChangeTab={handleChangeInvitationFormTab}
              formik={invitationByEmailFormik}
              onOpenInvitationAccess={openInvitationAccess}
            />
          );
        /*
      case Pages.OPERATOR_INVITATION_ACCESS:
        return <OperatorInvitationAccessTab userConfig={userConfig} onClose={goBackFromInvitationAccess} />;
      */
        case Pages.INVITED_OPERATOR_SETTINGS:
          return selectedOperator ? (
            <InvitedMemberManageTab
              bodyClassName="px-6 py-5"
              member={selectedOperator}
              onSubmit={handleSubmitInviteForm}
              onClose={goBackFromInviteForm}
            />
          ) : null;
      }
      return null;
    };

    return (
      <div className={cn('settings-tab without-padding', `page-${pageIndex}`)}>
        <div className="settings-tab__body">
          {animationsEnabled
            ? transitions((styles, item) => (
              <animated.div key={item} style={styles} className="settings-tab__animated-tab">
                {getPageContent(item)}
              </animated.div>
            ))
            : getPageContent(pageIndex)}
        </div>
      </div>
    );
  }),
);

export default WorkspaceMembersTab;
