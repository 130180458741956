import cn from 'classnames';
import {observer} from 'mobx-react';
import React, {useState} from 'react';

import {IMCMessageSuggestionOption, IMCTextEntities} from '../../../api/proto';
import Chat from '../../../stores/Chat';
import useCanSendMessages from '../../../stores/Chat/utils/useCanSendMessages';
import useRemovedFromChat from '../../../stores/Chat/utils/useRemovedFromChat';
import Message from '../../../stores/Message';
import useStore from '../../../stores/useStore';
import ChatHead from './ChatHead/ChatHead';
import ChatInput from './ChatInput/ChatInput';
// import {PinnedMessagesBar} from './PinnedMessages/PinnedMessagesBar';
import ChatMessagesWindow from './ChatMessagesWindow';
// import {PinnedMessagesViewer} from './PinnedMessages/PinnedMessagesViewer';
import TopAudioPlayer from '../../../components/AudioPlayer/TopAudioPlayer';
// import ChatAreaSnippetsBar from './ChatAreaSnippetsBar/ChatAreaSnippetsBar';
import {DropzoneUploader} from '../../../components/FileUploader/DropzoneUploader';
import {FilePasteUploader} from '../../../components/FileUploader/FilePasteUploader';
// import {ChatMuteButton} from './ChatMuteButton';
import Div100vh from '../../../components/Div100vh';
import convertTextEntitiesToDelta from '../../../stores/EditorStore/utils/convertTextEntitiesToDelta';
import ChatAssignControls from './ChatAssignControls';
import MessageSuggestionsBar from './MessageSuggestions/MessageSuggestionsBar';

interface ChatAreaProps {
  chat: Chat;
}

export const ChatArea: React.FC<ChatAreaProps> = observer(({chat}) => {
  const {chatsView} = useStore();
  const pinnedMessages = []; //chat.store?.pinnedMessages

  const isRemovedFromChat = useRemovedFromChat(chat);
  const canSendMessages = useCanSendMessages(chat);

  const handleSendMessage = (text: string, replyMessage?: Message | null, textEntities?: IMCTextEntities[] | null) => {
    // await chat.store.sendMessage(text);
    chat?.store?.sendMessage({
      text,
      replyMessage,
      textEntities,
    });
  };

  // const navigateToMessage = (message: Message) => {
  //   console.debug(`TODO: not implemented handler "navigateToMessage"`, message);
  // };

  // const openPinnedMessages = () => {
  //   console.debug(`TODO: not implemented handler "openPinnedMessages"`);
  // };

  const [countLines, setCountLines] = useState<number>(0);

  const handleChangeLines = (countLines: number) => {
    setCountLines(countLines);
  };

  const handleAddSuggestionToInput = React.useCallback(
    (suggestion: IMCMessageSuggestionOption) => {
      const staticDelta = convertTextEntitiesToDelta(suggestion.text || '');

      chat.store?.editor?.quill?.setContents(staticDelta);
      chat.store?.editor?.quill?.setSelection(chat.store?.editor?.quill?.getLength(), 0);
    },
    [chat.store?.editor],
  );

  const actionsAvailable = (!chat.channel?.assignmentEnabled || chat.assignment.youAreJoined) && !isRemovedFromChat && canSendMessages;

  return (
    <Div100vh
      className={cn('user-chat', `user-chat--lines-${countLines > 12 ? '12' : countLines}`, {
        'with-pinned': pinnedMessages.length,
        // reply: chat.store?.replyMessage || chat.store?.editingMessage || chat.store?.forwardMessages,
        scrolled: chat.store?.scrolled,
        'user-chat-show': chatsView.chatIsOpen,
        // unselectable: !!chat.store?.selectedMessages.length,
      })}
      data-assignment-enabled={chat.channel?.assignmentEnabled}
      data-you-are-joined={chat.assignment.youAreJoined}
      data-is-removed-from-chat={isRemovedFromChat}
      data-can-send-messages={canSendMessages}
    >
      <DropzoneUploader
        className="user-chat__body"
        chat={chat}
        noClick={true}
        disabled={isRemovedFromChat || !canSendMessages}
      >
        <FilePasteUploader chat={chat} disabled={isRemovedFromChat || !canSendMessages} />

        <ChatHead chat={chat} />
        <TopAudioPlayer chat={chat} />

        {/* {pinnedMessages.length ? (
          <PinnedMessagesBar
            messages={pinnedMessages}
            onOpen={openPinnedMessages}
            onNavigateToMessage={navigateToMessage}
          />
        ) : null} */}

        <ChatMessagesWindow chat={chat} />

        <MessageSuggestionsBar
          className="w-100"
          chat={chat}
          onClick={handleAddSuggestionToInput}
          disabled={!actionsAvailable}
        />

        {chat.channel?.assignmentEnabled ? <ChatAssignControls className="w-100" chat={chat} /> : null}

        {!chat.channel?.assignmentEnabled || chat.assignment.youAreJoined ? (
          <>
            {/* {!isRemovedFromChat && !canSendMessages ? <ChatMuteButton chat={chat} /> : null} */}

            {!isRemovedFromChat && canSendMessages ? (
              <ChatInput
                chat={chat}
                className="p-3 mb-0"
                // editingMessage={[]}
                replyMessage={chat.store?.replyMessage}
                // forwardMessages={chat.store?.forwardMessages}
                onChangeLines={handleChangeLines}
                onSendMessage={handleSendMessage}
              />
            ) : null}
          </>
        ) : null}
      </DropzoneUploader>

      {/* <PinnedMessagesViewer chat={chat} messages={[]} /> */}
    </Div100vh>
  );
});

export default ChatArea;
