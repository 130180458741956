import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import SearchInput from 'o-ui/Input/SearchInput';
import {SEARCH_TEXT_LENGTH_LIMIT} from '../../../../../constants';

interface MembersAccessHeaderProps {
  onSearch(value: string): void;
}

const MembersAccessHeader: React.FC<MembersAccessHeaderProps> = observer((props) => {
  const {t} = useTranslation();

  return (
    <div className="channels-access__header">
      <div className="channels-access__header-top">
        <div className="channels-access__header-title">
          {t('settings_channel_access_header_title')}
        </div>
      </div>

      <div className="channels-access__header-search">
        <SearchInput
          className="w-100"
          placeholder={t('settings_channel_access_header_filter_placeholder')}
          onChange={props.onSearch}
          maxLength={SEARCH_TEXT_LENGTH_LIMIT}
        />
      </div>
    </div>
  );
});

export default MembersAccessHeader;
