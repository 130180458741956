import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import Button from 'o-ui/Button';
import FormLabel from 'o-ui/FormLabel';
import ClipboardInput from 'o-ui/Input/ClipboardInput';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import Select, {SelectChangeEvent} from 'o-ui/Select';

import {entities} from '../../../../api/proto';
import useChannelTypesList from '../../../../hooks/useChannelTypesList';
import {useMountedState} from '../../../../hooks/useMountedState';
import Channel from '../../../../stores/Channel';
import useStore from '../../../../stores/useStore';

interface TelegramBotDefinitionFormProps {
  title?: string;
  subTitle?: string;
  type: entities.OzekonChannelType;
  newChannel?: Channel | null;
  onChangeForm?: () => void;
  onChangeType?: (type: entities.OzekonChannelType) => void;
  onSubmit?: (newChannel?: Channel | null) => void;
}

export const TelegramBotDefinitionForm = observer((props: TelegramBotDefinitionFormProps) => {
  const isMounted = useMountedState();
  const {t} = useTranslation();
  const {channels: {telegramBot}, notifications} = useStore();
  const [apiError, setApiError] = React.useState<string | null | undefined>('');
  const channelTypesList = useChannelTypesList();

  const formikCreate = useFormik({
    initialValues: {
      channelName: props.newChannel?.name || telegramBot.processingRequest?.shownFields?.name || '',
      accessToken: telegramBot.processingRequest?.telegramBotAuthFlow?.token || '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      channelName: Yup.string().required(t('Required')),
      accessToken: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      setApiError('');
      const {error, res, channel} = await telegramBot.createChannel(
        values.accessToken,
        {name: values.channelName},
      );

      if (!isMounted()) {
        return;
      }

      if (error) {
        setApiError(error.message);
        notifications.error(error.message);
      }

      if (res) {
        props.onSubmit?.(channel);
      }
    },
  });

  const handleChangeAccountName = (e: React.ChangeEvent) => {
    props.onChangeForm?.();
    formikCreate.handleChange(e);
  };

  const handleChangeAccessToken = (e: React.ChangeEvent) => {
    props.onChangeForm?.();
    formikCreate.handleChange(e);
  };

  const handleChangeSource = (e: SelectChangeEvent) => {
    const source = parseFloat(e.target.value);
    props.onChangeType?.(source);
  };

  const handleFormKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.keyCode === 13) {
      formikCreate.submitForm();
    }
  };

  const handlePasteToken = (value: string) => {
    formikCreate.setFieldValue('accessToken', value);
  };

  return (
    <>
      <form
        className="channel-dialog-form__form custom-scroll pb-6"
        onSubmit={formikCreate.handleSubmit}
        onKeyDown={handleFormKeyDown}
      >
        {props.title ? <div className="h1-bold color-body-primary">{props.title}</div> : null}
        {props.subTitle ? <div className="body1-regular color-body-primary pb-4">{props.subTitle}</div> : null}

        {props.onChangeType ? (
          <Select
            className="w-100 mb-3"
            labelClassName="color-body-tertiary"
            selectClassName="w-100"
            label={t('settings_channel_creation_form_channel_label')}
            onChange={handleChangeSource}
            value={props.type?.toString()}
            items={channelTypesList}
            required
          />
        ) : null}

        <FormLabel className="body3-regular color-body-tertiary mb-1">
          {t('settings_channel_creation_form_channel_name_label')}
        </FormLabel>
        <OutlinedTextInput
          className="w-100"
          name="channelName"
          onChange={handleChangeAccountName}
          placeholder={t('settings_channel_creation_form_channel_name_placeholder')}
          onBlur={formikCreate.handleBlur}
          value={formikCreate.values.channelName}
          errorHelper={formikCreate.errors.channelName || ' '}
          required
        />

        <FormLabel className="body3-regular color-body-tertiary mb-1 text-capitalize">
          {t('settings_channel_creation_form_telegram_bot_token_label')}
        </FormLabel>
        <ClipboardInput
          className="w-100"
          name="accessToken"
          placeholder={t('settings_channel_creation_form_telegram_bot_token_placeholder')}
          clipboardButtonText={t('input_button_clipboard_paste_placeholder')}
          clipboardButtonTextOnPaste={t('input_button_clipboard_paste_placeholder')}
          onChange={handleChangeAccessToken}
          onBlur={formikCreate.handleBlur}
          value={formikCreate.values.accessToken}
          errorHelper={apiError || formikCreate.errors.accessToken || ' '}
          required
          onPasteValue={handlePasteToken}
        />

        <div className="body2-regular color-body-primary">
          <Trans
            i18nKey="settings_channel_creation_form_telegram_bot_token_info"
            components={{
              a: (
                <a href="https://t.me/botfather" target="_blank" rel="noreferrer">
                  t.me/botfather
                </a>
              ),
            }}
          />
        </div>
      </form>
      <div className="border-primary-top px-6 pt-6">
        <Button
          className="w-sm-100"
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          onClick={formikCreate.submitForm}
          loading={telegramBot.loading}
        >
          {t('settings_channel_creation_form_connect_btn')}
        </Button>
      </div>
    </>
  );
});

export default TelegramBotDefinitionForm;
