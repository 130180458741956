import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';

import {entities} from '../../api/proto';
import {uniqueMergeByLongIdArrays, uniqueMergeLongArrays} from '../../utils/arrayUtils';
import WorkspaceStore from './WorkspaceStore';
import {IRawChat} from '../RawChatsStore/RawChat';


export enum AccessRecordListType {
  Allow = 'allow',
  Deny = 'deny',
}

export class WorkspaceMemberAccessRecord implements entities.IWorkspaceMemberAccessRecord {
  @observable public channelID?: Uint8Array | null;
  @observable public disableChannel?: boolean | null;
  @observable public chatsAllowList?: Long[] | null;
  @observable public chatsDenyList?: Long[] | null;

  @observable edited: boolean = false;

  @observable loading: boolean = false;

  @action protected setLoading_ = (loading: boolean) => {
    this.loading = loading;
  };

  constructor(public raw: entities.IWorkspaceMemberAccessRecord, public workspace: WorkspaceStore) {
    makeObservable(this);
    this.assign_(this.raw);

    this.init();
  }

  @computed public get chatsIds(): Long[] {
    return (this.isAllowList ? this.chatsAllowList : this.chatsDenyList) || [];
  }

  @observable protected _chats?: IRawChat[] | null;

  @computed public get chats(): IRawChat[] {
    return this._chats || [];
  }

  @action protected setChats_ = (_chats: IRawChat[]) => {
    this._chats = _chats;
  };

  protected addChats_ = (_chats: IRawChat[]) => {
    if (!this._chats) {
      return;
    }

    const newChats = uniqueMergeByLongIdArrays(this._chats, _chats);

    this.setChats_(newChats);
  };

  protected removeChat_ = (chatId?: Long | null) => {
    if (!this._chats || !chatId) {
      return;
    }

    const newChats = this._chats.filter(({id}) => !id?.equals(chatId));

    this.setChats_(newChats);
  };


  public async loadChats(): Promise<IRawChat[]> {
    if (!this.chatsIds.length) {
      this.setChats_([]);
      return [];
    }

    if (this._chats) {
      return this._chats;
    }

    if (this.loading) {
      return [];
    }

    this.setLoading_(true);
    const chats = await this.workspace.chatsSource.getChats(this.chatsIds, this.channelID);
    this.setLoading_(false);
    this.setChats_(chats);

    return chats;
  }

  @computed public get channel() {
    return this.workspace?.findChannel(this.channelID);
  }

  @observable private isInit_: boolean = false;

  @observable listType: AccessRecordListType = AccessRecordListType.Allow;

  @action setListType = (type: AccessRecordListType) => {
    this.listType = type;
  };

  @computed get isAllowList() {
    return this.listType === AccessRecordListType.Allow;
  }

  @computed get isDenyList() {
    return this.listType === AccessRecordListType.Deny;
  }

  @computed get chatsList() {
    return this.isAllowList ? this.chatsAllowList : this.chatsDenyList;
  }

  @computed get isResetState() {
    return !this.disableChannel && !this.chatsList?.length;
  }

  @action protected assign_ = (props: entities.IWorkspaceMemberAccessRecord) => {
    Object.assign(this, props);
  };

  init = () => {
    if (this.isInit_) {
      return;
    }

    if (this.chatsAllowList?.length) {
      this.setListType(AccessRecordListType.Allow);
      return;
    } else if (this.chatsDenyList?.length) {
      this.setListType(AccessRecordListType.Deny);
      return;
    }

    this.setListType(AccessRecordListType.Allow);
  };

  @action public toggleChannelAccess = () => {
    this.disableChannel = !this.disableChannel;
    this.edited = true;
  };

  @action public toggleListType = () => {
    if (this.listType === AccessRecordListType.Deny) {
      this.setListType(AccessRecordListType.Allow);
      this.chatsAllowList = this.chatsDenyList ? [...this.chatsDenyList] : [];
      this.chatsDenyList = [];
    } else {
      this.setListType(AccessRecordListType.Deny);
      this.chatsDenyList = this.chatsAllowList ? [...this.chatsAllowList] : [];
      this.chatsAllowList = [];
    }

    if (this.chatsAllowList.length || this.chatsDenyList.length) {
      this.edited = true;
    }
  };

  @action public addChats = (chats: IRawChat[]) => {
    if (!chats.length) {
      return;
    }

    const chatsIds = chats.map(({id}) => id || Long.ZERO);

    if (this.isAllowList) {
      this.chatsAllowList = this.chatsAllowList ? uniqueMergeLongArrays(this.chatsAllowList, chatsIds) : chatsIds;
      this.chatsAllowList = this.chatsAllowList.slice();
    } else {
      this.chatsDenyList = this.chatsDenyList ? uniqueMergeLongArrays(this.chatsDenyList, chatsIds) : chatsIds;
      this.chatsDenyList = this.chatsDenyList.slice();
    }
    this.addChats_(chats);
    this.edited = true;
  };

  @action public removeChat = (chatId?: Long | null) => {
    if(!chatId) {
      return;
    }

    if (this.isAllowList) {
      this.chatsAllowList = this.chatsAllowList?.filter((id) => id.notEquals(chatId));
      this.chatsAllowList = this.chatsAllowList?.slice();
    } else {
      this.chatsDenyList = this.chatsDenyList?.filter((id) => id.notEquals(chatId));
      this.chatsDenyList = this.chatsDenyList?.slice();
    }
    this.removeChat_(chatId);
    this.edited = true;
  };

  @action public reset = () => {
    this.listType = AccessRecordListType.Allow;
    this.chatsAllowList = [];
    this.chatsDenyList = null;
    this.disableChannel = false;
    this._chats = [];
    this.edited = true;
  };
}

export default WorkspaceMemberAccessRecord;
