import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import Skeleton from 'o-ui/Skeleton';
import {MCAttachment} from '../../../../api/proto';
import {useStore} from '../../../../stores/AppStore';

interface AudioFilesSkeletonProps {
  className?: string;
  children: React.ReactNode;
  attachmentType?: MCAttachment.Type | null;
}

const DEFAULT_ITEMS_COUNT = 24;
const ITEM_HEIGHT = 48;

export const AudioFilesSkeleton: React.FC<AudioFilesSkeletonProps> = observer((props) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const {layOutStore, chatsView} = useStore();

  const [itemsCount, setItemsCount] = React.useState<number>(DEFAULT_ITEMS_COUNT);

  const attachmentsCount =
    chatsView.activeUsersChat?.store.getAttachmentsHistoryByType(props.attachmentType)?.attachmentsCount || 0;

  React.useEffect(() => {
    const containerEl = containerRef.current;

    if (containerEl) {
      const maxCount = Math.round(containerEl.offsetHeight / ITEM_HEIGHT);
      setItemsCount(attachmentsCount < maxCount ? attachmentsCount : maxCount);
    }
  }, [containerRef, attachmentsCount]);

  if (
    layOutStore.rightSideBar.chatInfoBarGalleryItemsDisplayAllow ||
    !layOutStore.rightSideBar.chatInfoBarAnimationAllow
  ) {
    return <>{props.children}</>;
  }

  if (!attachmentsCount) {
    return null;
  }

  return (
    <div ref={containerRef} className={cn('chat-gallery-skeleton chat-gallery-skeleton--audio-files', props.className)}>
      <div className="profile-sidebar__date-delimiter">
        <Skeleton withoutContainer inline width="100%" height={16} animationType="slide" />
      </div>
      <div className="chat-gallery-skeleton__container">
        {Array(itemsCount)
          .fill('')
          .map((_, index) => (
            <Skeleton
              key={index}
              height={ITEM_HEIGHT}
              inline
              animationType="slide"
              className="chat-gallery-skeleton__item card"
            />
          ))}
      </div>
    </div>
  );
});

export default AudioFilesSkeleton;
