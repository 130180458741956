import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import Button from 'o-ui/Button';
import FormLabel from 'o-ui/FormLabel';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import Radio from 'o-ui/Radio';
import Select, {SelectChangeEvent} from 'o-ui/Select';
import MenuItem from 'o-ui/Select/MenuItem';

import {entities} from '../../../../api/proto';
import {MAX_FORM_FIELD_LENGTH} from '../../../../constants';
import Channel from '../../../../stores/Channel';
import getChannelTypeName from '../../../../stores/Channel/utils/getChannelTypeName';
import ModalType from '../../../../stores/ModalType';
import {DEFAULT_SOUND_NAME, soundNames} from '../../../../stores/NotificationsStore';
import useStore from '../../../../stores/useStore';
import ChannelStatus from '../ChannelStatus';
import NotificationSoundPlayer from '../NotificationSoundPlayer';
import {PhoneNumberField} from './Fields';

interface ChannelDefaultFormProps {
  channel: Channel;
  className?: string;
  onBeforeSubmit?: () => void;
  onSubmit?: () => void;
  onChange?: () => void;
}

export interface ChannelDefaultFormRef {
  submitForm?: () => void;
}

export const ChannelDefaultForm = observer(
  React.forwardRef<ChannelDefaultFormRef, ChannelDefaultFormProps>((props, ref) => {
    const {t} = useTranslation();
    const {notifications, modals, channels} = useStore();
    const [apiError, setApiError] = React.useState<string | null | undefined>('');
    const [loading, setLoading] = React.useState<boolean>(false);

    const formikUpdate = useFormik({
      initialValues: {
        accountName: props.channel?.name || '',
        notificationSound: props.channel?.notificationSound || DEFAULT_SOUND_NAME,
      },
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object({
        accountName: Yup.string().required(t('Required')),
        notificationSound: Yup.string().required(t('Required')),
      }),
      onSubmit: async (values) => {
        props.onBeforeSubmit?.();
        setApiError('');

        const {error, res} = await channels.updateChannel({
          channel: props.channel,
          channelID: props.channel.id,
          shownFields: {
            name: values.accountName,
            notificationSound: values.notificationSound,
          },
        });

        if (error) {
          setApiError(error.message);
        }
        if (res) {
          props.onSubmit?.();
        }
      },
    });

    React.useImperativeHandle(ref, () => ({
      submitForm: () => formikUpdate.submitForm(),
    }));

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange?.();
      formikUpdate.handleChange(e);
    };

    const handleChangeSound = (e: SelectChangeEvent) => {
      props.onChange?.();
      formikUpdate.handleChange(e);
      if (e.target.value) {
        notifications.play(e.target.value);
      }
    };

    const reactivateTelegramChannel = async () => {
      setLoading(true);
      const {res} = await channels.telegram.reactivateChannel({channel: props.channel, phone: props.channel.phone});
      setLoading(false);

      modals.open(ModalType.CHANNEL_REACTIVATION, {
        channel: props.channel,
        codeHash: res?.codeHash,
      });
    };

    return (
      <div>
        <FormLabel className="body3-regular body-secondary d-flex mb-2">
          {t('settings_channel_manage_status_label')}
        </FormLabel>
        <div className="d-flex flex-row">
          <ChannelStatus channel={props.channel} />
          {props.channel.type === entities.OzekonChannelType.OIT_TELEGRAM ? (
            <Button
              variant="contained"
              color="errorTertiary"
              size="small"
              className="text-capitalize ml-5"
              onClick={reactivateTelegramChannel}
              loading={loading}
            >
              {t('settings_channel_manage_reactivate_btn')}
            </Button>
          ) : null}
        </div>

        <PhoneNumberField phone={props.channel?.phone} />

        <FormLabel className="body3-regular body-secondary d-flex mb-2 mt-5">
          {t('settings_channel_manage_messenger_label')}
        </FormLabel>
        <OutlinedTextInput className="w-100" value={getChannelTypeName(props.channel.type)} disabled />

        {/* <FormLabel className="body3-regular body-secondary d-flex mb-2 text-capitalize mt-5">
          {t('User name')}
        </FormLabel>
        <OutlinedTextInput className="w-100" value={props.channel?.userName || '-'} disabled /> */}

        <form onSubmit={formikUpdate.handleSubmit}>
          <FormLabel className="body3-regular body-secondary d-flex mb-2 text-capitalize mt-5">
            {t('settings_channel_manage_channel_name_label')}
          </FormLabel>
          <OutlinedTextInput
            className="w-100"
            name="accountName"
            onChange={handleChange}
            onBlur={formikUpdate.handleBlur}
            value={formikUpdate.values.accountName}
            errorHelper={apiError || formikUpdate.errors.accountName || ' '}
            maxLength={MAX_FORM_FIELD_LENGTH}
            required
          />

          <Select
            className="w-100 mb-5"
            selectClassName="select w-100"
            labelClassName="text-capitalize"
            label={t('settings_channel_manage_notification_sound_label')}
            MenuProps={{
              className: 'select__menu',
            }}
            name="notificationSound"
            onChange={handleChangeSound}
            value={formikUpdate.values.notificationSound}
            required
          >
            {soundNames.map((soundName) => (
              <MenuItem className="select__menu-item" key={soundName} value={soundName}>
                <Radio
                  className="select__radio"
                  checked={formikUpdate.values.notificationSound === soundName}
                  value={soundName}
                  name={soundName}
                />
                {soundName}
                <NotificationSoundPlayer className="select__menu-item-icon font-size-24 ml-5" soundName={soundName} />
              </MenuItem>
            ))}
          </Select>
        </form>
      </div>
    );
  }),
);

export default ChannelDefaultForm;
