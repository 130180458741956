import {observer} from 'mobx-react';
import React from 'react';
import {useMatch} from 'react-router-dom';

import SimpleBar from 'o-ui/CustomScrollbar/SimpleBar';
import useWorkspaceNavigate from '../../../hooks/useWorkspaceNavigate';
import Paths, {navigateToChannel} from '../../../routes/Paths';
import {useStore} from '../../../stores/AppStore';
import Channel from '../../../stores/Channel';
import ChannelsMenuItem from './ChannelsMenuItem';
import ChannelsMenuItemAll from './ChannelsMenuItemAll';

export const ChannelsMenu: React.FC = observer(() => {
  const {chatsView, channels} = useStore();

  const workspaceNavigate = useWorkspaceNavigate();

  const matchAllChats = useMatch(Paths.Chat);
  const matchSpecificChat = useMatch(Paths.SpecificChat);
  const matchSpecificMessage = useMatch(Paths.SpecificMessage);

  const handleClick = (channel: Channel | null) => {
    if (!matchAllChats && !matchSpecificChat && !matchSpecificMessage && !channel) {
      workspaceNavigate(Paths.Chat);
    } else if (!matchSpecificChat && !matchSpecificMessage && channel) {
      navigateToChannel(channel);
    } else {
      chatsView.selectChannel(channel);
    }
  };

  return (
    <SimpleBar className="channels-menu-wrapper">
      <div className="channels-menu">
        <ChannelsMenuItemAll onClick={handleClick} />
        {channels.availableChannels.map((channel) => (
          <ChannelsMenuItem key={channel.id.toString()} channel={channel} onClick={handleClick} />
        ))}
      </div>
    </SimpleBar>
  );
});

export default ChannelsMenu;
