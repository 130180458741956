import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import Div100vh, {use100vh} from '../../components/Div100vh';
import {BRAND_NAME} from '../../config';
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate';
import {Paths} from '../../routes/Paths';
import useStore from '../../stores/useStore';
import {setStyleProperty} from '../../utils/styleProperty';
import PersonalInfoForm, {IPersonalInfoFormRef} from './PersonalInfoForm';

import logoBlueImg from '../../assets/images/logo-blue.svg';
import personalInfoIllustration from '../../assets/images/personal-info-illustration.png';

const QuickOnboardingPage = observer(() => {
  const height = use100vh();
  const {userStore} = useStore();

  const workspaceNavigate = useWorkspaceNavigate();

  const {t} = useTranslation();
  const formRef = React.useRef<IPersonalInfoFormRef>(null);

  React.useEffect(() => {
    setStyleProperty('--onboarding-height', height ? `${height}px` : '100vh');
  }, [height]);

  const handleNextClick = () => {
    if (formRef.current?.submitForm) {
      formRef.current.submitForm();
    }
  };

  const handleSkipOnboarding = () => {
    workspaceNavigate(Paths.Chat);
  };

  const handleSubmitPersonalInfo = () => {
    workspaceNavigate(Paths.Chat);
  };

  return (
    <Div100vh className="onboarding">
      <div className="onboarding__header onboarding__header--bordered">
        <img className="onboarding__header-logo" src={logoBlueImg} alt="" />
        <Button
          className="onboarding__header-skip"
          variant="contained"
          color="secondary"
          onClick={handleSkipOnboarding}
        >
          {t('Skip')}
        </Button>
      </div>

      <div className="onboarding__body">
        <div className="animated-tab w-100 h-100 position-absolute d-flex align-items-center">
          <PersonalInfoForm
            ref={formRef}
            title={t('onboarding_personal_info_title', {brandName: BRAND_NAME})}
            subTitle={t('onboarding_personal_info_subtitle')}
            onSubmit={handleSubmitPersonalInfo}
          />
          <img className="onboarding__illustration-img" src={personalInfoIllustration} alt="" />
        </div>
      </div>

      <div className="onboarding__footer">
        <Button
          className="onboarding__next-btn"
          variant="contained"
          size="large"
          onClick={handleNextClick}
          loading={userStore.loading}
        >
          {t('onboarding_get_started')}
        </Button>
      </div>
    </Div100vh>
  );
});

export default QuickOnboardingPage;
