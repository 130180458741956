import Long from 'long';

import {MCAttachment} from '../../../api/proto';
import Chat from '../Chat';

export function getChatAttachmentsCount(chat: Chat, attachmentType: MCAttachment.Type): number {
  const count = chat.attachmentGroupSizes?.find((g) => g.type === attachmentType)?.count;

  return count ? count.toNumber() : 0;
}

export function getChatBlockedToTime(chat: Chat, peerID?: Long | null): Long | null {
  return chat.restrictions?.find((r) => peerID && r.peerID?.equals(peerID))?.until || null;
}
