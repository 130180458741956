import React from 'react';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';

import WorkspaceMember from '../../../../stores/Workspaces/WorkspaceMember';
import {getUserRoleTitle} from '../../../../stores/Workspaces';


interface MemberExtraInfoProps {
  className?: string;
  member: WorkspaceMember;
}

export const MemberExtraInfo = observer((props: MemberExtraInfoProps) => {
  const {t} = useTranslation();

  return (
    <div className={cn('chat-extra-info', props.className)}>
      <table className="chat-extra-info__table">
        <tbody>
          <tr>
            <td>
              <i className="chat-extra-info__icon tg-icon tg-icon-user-fill" />
            </td>
            <td>
              <span className="chat-extra-info__name">{t('chat_user_chat_member_info_name')}</span>
            </td>
            <td>
              <span className="chat-extra-info__value">{props.member.fullName}</span>
            </td>
          </tr>
          <tr>
            <td>
              <i className="chat-extra-info__icon tg-icon tg-icon-info-fill" />
            </td>
            <td>
              <span className="chat-extra-info__name">{t('chat_user_chat_member_info_role')}</span>
            </td>
            <td>
              <span className="chat-extra-info__value">{getUserRoleTitle(props.member.role)}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default MemberExtraInfo;
