import {action, computed, makeObservable, observable} from 'mobx';
import {api, entities} from '../../api/proto';
import Long from 'long';
import UserStore from './UserStore';
import {formatUserName} from '../../utils/format';

const {UserProfileFieldType} = entities;

export const getUserDetailsValue = <T extends keyof entities.IOptValue>(
  fields: entities.IUserProfileField[] | null | undefined,
  filedType: entities.UserProfileFieldType,
  valueKey: T,
): entities.IOptValue[T] | null => {
  if (fields?.length) {
    const _found = fields.find((field) => field.type === filedType)?.value?.[valueKey];
    return _found || null;
  }

  return null;
};

export class User implements api.IUserData {
  @observable raw: api.IUserData;
  @observable userDetails: api.IGetDetailsResponse;

  @observable isInit: boolean = false;

  constructor(props: api.IUserData, userDetails: api.IGetDetailsResponse, private userStore: UserStore) {
    makeObservable(this);

    this.raw = props;
    this.userDetails = userDetails;
    this.init(props, userDetails);
  }

  @observable userId?: Long | null;
  @observable status?: entities.UserRegistrationStatus | null;
  @observable nickname?: string | null;
  @observable emailNotConfirmed?: boolean | null;

  @action setInit = (isInit: boolean) => {
    this.isInit = isInit;
    if (isInit) {
      window.document.documentElement.classList.remove('loading');
    }
  };

  @action private init = (props: api.IUserData, userDetails: api.IGetDetailsResponse) => {
    if (this.isInit) {
      return;
    }

    this.raw = props;
    this.userDetails = userDetails;
    Object.assign(this, props);

    this.setInit(true);
  };

  public static isLoggedIn = (status: entities.UserRegistrationStatus) => {
    return status === entities.UserRegistrationStatus.UL_REGISTERED;
  };

  @computed get isLoggedIn(): boolean {
    return this.status === entities.UserRegistrationStatus.UL_REGISTERED;
  }

  @computed get isEmailNotConfirmed(): boolean {
    return !!this.emailNotConfirmed;
  }

  @computed get firstName(): string | null {
    return getUserDetailsValue(this.userDetails?.fields, UserProfileFieldType.UPFT_FIRST_NAME, 'strValue') || null;
  }

  @computed get lastName(): string | null {
    return getUserDetailsValue(this.userDetails?.fields, UserProfileFieldType.UPFT_LAST_NAME, 'strValue') || null;
  }

  @computed get email(): string | null {
    return getUserDetailsValue(this.userDetails?.fields, UserProfileFieldType.UPFT_EMAIL, 'strValue') || null;
  }

  @computed get fullName(): string {
    return formatUserName(this.firstName, this.lastName) || this.email || '';
  }

  @computed get isOwner(): boolean {
    return !!this.userStore.app.activeWorkspace.findMemberByUserId(this.userId)?.isOwner
  }

  @action setUserDetails = (userDetails: api.IGetDetailsResponse) => {
    this.userDetails = userDetails;
  };

  @action setEmailNotConfirmed(emailNotConfirmed?: boolean | null) {
    this.emailNotConfirmed = emailNotConfirmed;
  }
}

export default User;
