import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import Channel from '../../../../stores/Channel';
import ChannelCreationStepType from '../ChannelCreationStepType';
import InstagramAuthForm from './InstagramAuthForm';
import InstagramAuthRedirect from './InstagramAuthRedirect';
import InstagramCreationResult from './InstagramCreationResult';

interface InstagramFormsWrapperProps {
  title?: string;
  subTitle?: string;
  step: ChannelCreationStepType;
  channel?: Channel | null;
  onChangeStep?: (nextStep: ChannelCreationStepType, channel?: Channel | null) => void;
  onClose?: () => void;
}

export const InstagramFormsWrapper: React.FC<InstagramFormsWrapperProps> = observer(({
  title,
  subTitle,
  step,
  channel,
  onChangeStep,
  onClose,
}) => {
  const {t} = useTranslation();

  switch (step) {
    case ChannelCreationStepType.CHANNEL_CONFIRMATION:
      return (
        <InstagramAuthRedirect
          title={title}
          subTitle={subTitle}
        />
      );
    case ChannelCreationStepType.TWO_FACTOR_AUTH:
      return (
        <InstagramAuthForm
          title={title}
          subTitle={subTitle}
          onChangeStep={onChangeStep}
          onClose={onClose}
        />
      );
    case ChannelCreationStepType.SUCCESS:
      return (
        <>
          <InstagramCreationResult
            title={title}
            subTitle={subTitle}
            channel={channel}
          />
          {onClose ? (
            <div className="channel-dialog-form__footer">
              <Button
                variant="contained"
                color="primary"
                size="large"
                className="channel-dialog-form__cancel-button"
                onClick={onClose}
              >
                {t('settings_channel_creation_form_close')}
              </Button>
            </div>
          ) : null}
        </>
      );
  }

  return null;
});

export default InstagramFormsWrapper;