import {action, computed, makeObservable, observable, toJS} from 'mobx';

import {WorkspaceStore} from '.';
import {entities} from '../../api/proto';
import WorkspaceMember from './WorkspaceMember';
import {WorkspaceMemberAccessRecord} from './WorkspaceMemberAccessRecord';
import {equalUint8Arrays} from '../../utils/arrayUtils';

export interface IWorkspaceMemberConfig {
  accessToContactInfo: boolean;
  accessToContactsList: boolean;
  allowManageChats: boolean;
}

export const createDefaultWorkspaceMember = (workspace: WorkspaceStore) => {
  return new WorkspaceMember(
    {
      access: {
        records: workspace.availableChannels.map((channel) => ({
          channelID: channel.id,
          disableChannel: false,
          chatsAllowList: [],
        })),
      },
    },
    workspace,
  );
};

export class WorkspaceMemberConfig implements IWorkspaceMemberConfig {
  @observable permissions: entities.WorkspaceMemberPermission[] = [];
  @observable channelAccessRecords: WorkspaceMemberAccessRecord[] = [];

  @computed get rawChannelAccessRecords() {
    return this.channelAccessRecords.map(({channelID, chatsAllowList, chatsDenyList, disableChannel}) => ({
      channelID,
      chatsAllowList: toJS(chatsAllowList) || [],
      chatsDenyList: toJS(chatsDenyList) || [],
      disableChannel: disableChannel,
    }));
  }

  constructor(
    public workspace: WorkspaceStore,
    public member: WorkspaceMember = createDefaultWorkspaceMember(workspace),
  ) {
    makeObservable(this);
    this.assign_(this.member);

    this.initChannelAccessRecords(member);
  }

  copy = () => {
    return new WorkspaceMemberConfig(this.workspace, this.member);
  };

  @action protected assign_ = (member?: WorkspaceMember | null) => {
    this.permissions = member?.permissions || [];
  };

  @action initChannelAccessRecords = (member: WorkspaceMember) => {
    const records_ =
      member?.access?.records?.map((record) => new WorkspaceMemberAccessRecord(record, this.workspace)) || [];

    this.channelAccessRecords = records_.sort((a, b) => Number(!!a.disableChannel) - Number(!!b.disableChannel));
  };

  @action fill = (props?: WorkspaceMemberConfig) => {
    if (props) {
      this.member.assign({
        permissions: props.permissions,
        access: {
          records: props.rawChannelAccessRecords,
        },
      });
      this.permissions = props.permissions;
      this.channelAccessRecords = props.channelAccessRecords;
    }
  };

  // Member Permission //
  @computed get accessToNewChats() {
    return this.permissions.some((p) => p === entities.WorkspaceMemberPermission.PT_ACCESS_TO_NEW_CHATS);
  }

  @computed get accessToContactInfo() {
    return this.permissions.some((p) => p === entities.WorkspaceMemberPermission.PT_ACCESS_TO_CONTACT_INFO);
  }

  @computed get accessToContactsList() {
    return this.permissions.some((p) => p === entities.WorkspaceMemberPermission.PT_ACCESS_TO_CONTACTS_LIST);
  }

  @computed get allowManageChats() {
    return this.permissions.some((p) => p === entities.WorkspaceMemberPermission.PT_ALLOW_MANAGE_CHATS);
  }

  @action togglePermission = (permission: entities.WorkspaceMemberPermission) => {
    if (this.permissions.indexOf(permission) >= 0) {
      this.permissions = this.permissions.filter((p) => p === permission);
    } else {
      this.permissions = [...this.permissions, permission];
    }
  };

  @action toggleAccessToContactInfo = () => {
    this.togglePermission(entities.WorkspaceMemberPermission.PT_ACCESS_TO_CONTACT_INFO);
  };

  @action toggleAccessToContactsList = () => {
    this.togglePermission(entities.WorkspaceMemberPermission.PT_ACCESS_TO_CONTACTS_LIST);
  };

  @action toggleAllowManageChats = () => {
    this.togglePermission(entities.WorkspaceMemberPermission.PT_ALLOW_MANAGE_CHATS);
  };

  @action enableAllPermissions = () => {
    this.permissions = [
      entities.WorkspaceMemberPermission.PT_ACCESS_TO_NEW_CHATS,
      entities.WorkspaceMemberPermission.PT_ACCESS_TO_CONTACT_INFO,
      entities.WorkspaceMemberPermission.PT_ACCESS_TO_CONTACTS_LIST,
      entities.WorkspaceMemberPermission.PT_ALLOW_MANAGE_CHATS,
    ];
  };

  @action allowAllChannels = () => {
    this.channelAccessRecords.forEach((record) => {
      record.reset();
    });
  };

  hasAccessToChannel = (channelID: Uint8Array): boolean => {
    return this.channelAccessRecords.some((r) => equalUint8Arrays(r.channelID, channelID) && r.disableChannel);
  };
}

export default WorkspaceMemberConfig;
