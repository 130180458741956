import {uint8ArrayToBase64} from '../../../utils/arrayUtils';

export function formatChannelKey(channelID?: Uint8Array | null): string {
  return uint8ArrayToBase64(channelID);
}

export function formatChannelIdsKey(channelIDs: Uint8Array[]) {
  return channelIDs
    .map((c) => formatChannelKey(c))
    .sort()
    .join('_');
}

export default formatChannelIdsKey;