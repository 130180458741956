import React from 'react';
import {observer} from 'mobx-react';
import Button, {ButtonProps} from 'o-ui/Button';
import Link from 'o-ui/Link';
import TimerStore from '../stores/TimerStore';
import {formatDuration} from '../utils/format';

interface IProps {
  className?: string;
  timer: TimerStore;
  onClick?: (e: MouseEvent) => void;
  component?: string;
}

const TimeStatus = observer((props: {timer: TimerStore}) => {
  return (
    <span className="px-1">
      {props.timer.currentTime ? `(${formatDuration(Math.floor(props.timer.currentTime) * 1000)})` : ''}
    </span>
  );
});

export const ButtonWithTimer: React.FC<IProps & ButtonProps> = observer((props) => {
  if (props.component === 'link') {
    return (
      <Link
        className={props.className}
        color={'primary' || props.color}
        onClick={props.onClick}
        disabled={!props.timer.stopped || props.disabled}
        component="button"
      >
        {props.children}
        {props.timer.stopped ? null : <TimeStatus timer={props.timer} />}
      </Link>
    );
  }

  return (
    <Button
      className={props.className}
      variant={props.variant || 'contained'}
      color={props.color || 'primary'}
      size={props.size || 'medium'}
      onClick={props.onClick}
      disabled={!props.timer.stopped || props.disabled}
      loading={props.loading}
      type={props.type}
    >
      {props.children}
      {props.timer.stopped ? null : <TimeStatus timer={props.timer} />}
    </Button>
  );
});

export default ButtonWithTimer;
