import React from 'react';
import cn from 'classnames';

import AvatarWrapper from '../../../pages/Chat/AvatarWrapper';
import useSelectMember from './useSelectMember';
import WorkspaceMember from '../../../stores/Workspaces/WorkspaceMember';


interface SelectMemberItemProps {
  member: WorkspaceMember;
  selected?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const SelectMemberItem = React.memo(({
  member,
  selected: selectedProp,
  className,
  style,
}: SelectMemberItemProps) => {
  const {selectedMembers, toggleMember} = useSelectMember();

  const selected = React.useMemo(
    () => {
      return selectedProp || selectedMembers?.some(({id}) => member.id && id?.equals(member.id)) || false;
    },
    [
      member,
      selectedProp,
      selectedMembers,
    ],
  );

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    toggleMember(member);
  };

  return (
    <li
      className={cn(
        'radius-8 p-3 clickable hover-bg-secondary-color',
        {selected},
        className
      )}
      onClick={handleClick}
      style={style}
      data-member-id={member.id?.toNumber()}
      data-user-id={member.userId?.toNumber()}
    >
      <div className="d-flex align-items-center">
        <AvatarWrapper
          className="mr-5"
          name={member.fullName}
          selected={selected}
        />
        <div className="flex-grow-1 overflow-hidden">
          <div className="text-truncate body2-bold">
            {member.fullName}
          </div>
          <p className="body3-regular text-truncate mb-0">
            {member.email}
          </p>
        </div>
      </div>
    </li>
  );
});

export default SelectMemberItem;
