import React from 'react';
import {observer} from 'mobx-react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';

import {ModalItem} from '../stores/ModalsStore';
import {DEFAULT_MESSAGE_TAG_COLOR, MESSAGE_TAG_COLORS} from '../stores/MessageTagsStore';
import {useStore} from '../stores/AppStore';

import {MessageTagItem} from '../components/MessageTagItem';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import {ColorIcon} from 'o-ui/ColorIcon';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';
import Button from 'o-ui/Button';
import DialogContent from 'o-ui/Dialog/DialogContent';
import FormLabel from 'o-ui/FormLabel';
import DialogActions from 'o-ui/Dialog/DialogActions';
import FormControl from 'o-ui/FormControl';
import RadioGroup from 'o-ui/RadioGroup';
import Radio from 'o-ui/Radio';

interface IProps {
  modal: ModalItem;
}

export const ModalMessageTagCreating = observer((props: IProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const {chatsView} = useStore();
  const {tag} = props.modal.data;

  const isWorkspace = tag?.isWorkspace || false;

  const tagTextFormLabel = t('settings_message_tags_tag_name_label');

  const closeModalModal = () => {
    modalRef.current?.close();
  };

  const formik = useFormik({
    initialValues: {
      tagText: tag?.text || '',
      tagColor: tag?.color || DEFAULT_MESSAGE_TAG_COLOR,
    },
    // validateOnBlur: true,
    validationSchema: Yup.object({
      tagText: Yup.string().required(t('settings_message_tags_tag_name_required')),
    }),
    onSubmit: ({tagText, tagColor}) => {
      if (tag?.id) {
        tag.text = tagText;
        tag.color = tagColor;
        chatsView.tags.edit(tag);
      } else {
        chatsView.tags.add(tagText, tagColor, isWorkspace);
      }

      closeModalModal();
    },
  });

  const getModalTitle = () => {
    if (isWorkspace) {
      return tag?.id
        ? t('settings_message_tags_edit_workspace_tag_title')
        : t('settings_message_tags_add_workspace_tag_title');
    } else {
      return tag?.id
        ? t('settings_message_tags_edit_personal_tag_title')
        : t('settings_message_tags_add_personal_tag_title');
    }
  };

  return (
    <ModalDialog ref={modalRef} modal={props.modal} title={getModalTitle()} maxWidth="xs" fullWidth={true}>
      <form className="d-flex h-100 flex-column" onSubmit={formik.handleSubmit}>
        <DialogContent className="p-6 overflow-initial">
          <div>
            <FormLabel className="body3-regular text-capitalize d-flex mb-2">{tagTextFormLabel}</FormLabel>
            <FormControl className="w-100 mb-5" variant="outlined">
              <OutlinedTextInput
                name="tagText"
                type="text"
                placeholder={t('settings_message_tags_tag_name_placeholder')}
                className="w-100"
                value={formik.values.tagText}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorHelper={formik.errors.tagText || ''}
                autoFocus
              />
            </FormControl>
          </div>

          <div>
            <FormLabel className="body3-regular text-capitalize d-flex mb-2">
              {t('settings_message_tags_tag_color_label')}
            </FormLabel>
            <FormControl className="w-100 mb-3" variant="outlined">
              <RadioGroup
                row
                name="tagColor"
                value={formik.values.tagColor}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {Object.values(MESSAGE_TAG_COLORS).map((color) => (
                  <Radio
                    key={color}
                    value={color}
                    icon={<ColorIcon color={color} />}
                    checkedIcon={<ColorIcon color={color} checked />}
                    className="p-2"
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>

          <div>
            <FormLabel className="body3-regular text-capitalize d-flex">
              {t('settings_message_tags_tag_color_preview_label')}
            </FormLabel>
            <FormControl className="w-100 d-block" variant="outlined">
              <MessageTagItem
                tag={{
                  text: formik.values.tagText || tagTextFormLabel,
                  color: formik.values.tagColor,
                }}
              />
            </FormControl>
          </div>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="primary" size="large" type="submit" disabled={!formik.dirty}>
            {tag?.id ? t('settings_message_tag_save_button') : t('settings_message_tag_create_button')}
          </Button>
          <Button variant="contained" color="secondary" size="large" onClick={closeModalModal}>
            {t('settings_message_tag_cancel_button')}
          </Button>
        </DialogActions>
      </form>
    </ModalDialog>
  );
});

export default ModalMessageTagCreating;
