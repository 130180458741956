import React from 'react';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';

import Button from 'o-ui/Button';
import CircularProgress from 'o-ui/CircularProgress';
import SimpleBar from 'o-ui/CustomScrollbar/SimpleBar';
import useStore from '../../../stores/useStore';
import BillingHistoryTableRow from './BillingHistoryTableRow';

interface BillingHistoryTableProps {
  className?: string;
}

export const BillingHistoryTable = observer((props: BillingHistoryTableProps) => {
  const {t} = useTranslation();
  const {activeWorkspace: {billing}} = useStore();

  const tariffActions = React.useMemo(() => {
    return billing.billingHistory.slice().sort((item1, item2) => {
      const stamp1 = item1.billingDate?.toNumber() || 0;
      const stamp2 = item2.billingDate?.toNumber() || 0;

      if (stamp1 > stamp2) {
        return -1;
      } else {
        return 1;
      }
    });
  }, [
    billing.billingHistory,
  ]);

  return tariffActions.length || billing.loadBillingHistoryInProgress ? (
    <div className={cn("settings-tab__paper settings-tab__paper--100 d-flex flex-column flex-grow-1", props.className)}>
      <div className="label-2 color-body-tertiary text-uppercase">{t('settings_billing_history_title')}</div>

      {tariffActions.length ? (
        <SimpleBar className="channels-table__container">
          {tariffActions.map((a) => {
            return (
              <BillingHistoryTableRow
                key={a.billingDate?.toNumber()}
                item={a}
              />
            );
          })}
        </SimpleBar>
      ) : null}

      {billing.loadBillingHistoryInProgress ? (
        <div className="d-flex justify-content-center">
          <CircularProgress
            size={36}
            style={{
              color: 'var(--brand-primary)',
            }}
          />
        </div>
      ) : billing.hasMoreHistory ? (
        <Button
          className="mt-3"
          color="secondary"
          variant="contained"
          loading={billing.loadBillingHistoryInProgress}
          onClick={billing.loadHistoryMore}
        >
          {t('settings_billing_history_button_load_more')}
        </Button>
      ) : null}
    </div>
  ) : null;
});

export default BillingHistoryTable;