import ExternalAppStore from '../stores/ExternalApp/ExternalAppStore';
import isClient from './isClient';

export default function windowInnerHeight(): number {
  if (!isClient()) return 0;

  if (window.externalAppConfig?.customTitleBar) {
    const titleBarHeight = ExternalAppStore.getCustomTitleBarHeight();
    return isNaN(titleBarHeight) ? window.innerHeight : (window.innerHeight - titleBarHeight);
  }

  return window.innerHeight;
}