import React from 'react';

import {ListChildComponentProps} from 'o-ui/VirtualizedList';
import {useStore} from '../../../../stores/AppStore';
import useContextMenuHandler from '../../../../hooks/useContextMenuHandler';
import ContextMenuType from '../../../../stores/ContextMenuType';
import FileInfo from '../../../../components/FileInfo';
import ChatInfoDateDelimiter, {useDiffMonth} from '../ChatInfo/ChatInfoDateDelimiter';
import MessageAttachment from '../../../../stores/Attachment/MessageAttachment';


interface AttachedFilesRowProps {
  rowProps: ListChildComponentProps;
  items: MessageAttachment[];
  setSize: (index: number, size: number) => void;
}

export const AttachedFilesRow = React.memo(({rowProps, items, setSize}: AttachedFilesRowProps) => {
  const {contextMenu} = useStore();
  const attachment = items[rowProps.index];
  const prevAttachment = rowProps.index ? items[rowProps.index - 1] : null;

  const diffMonth = useDiffMonth(attachment.message.stamp, prevAttachment?.message?.stamp);

  React.useEffect(() => {
    if (diffMonth) {
      setSize(rowProps.index, 120);
    } else {
      setSize(rowProps.index, 80);
    }
  }, [setSize, rowProps.index, diffMonth]);

  const contextMenuHandlers = useContextMenuHandler((e: React.MouseEvent, attachment: MessageAttachment) => {
    e.stopPropagation();
    e.preventDefault();

    contextMenu.open({clientX: e.clientX, clientY: e.clientY}, ContextMenuType.FILE, {
      attachment: attachment,
    });
  });

  return (
    <div style={rowProps.style} key={rowProps.index}>
      {diffMonth ? <ChatInfoDateDelimiter stamp={attachment.message.stamp} /> : null}

      <div
        key={rowProps.index}
        className="py-3 px-5 mb-3"
        onContextMenu={(e) => contextMenuHandlers.onContextMenu(e, attachment)}
        onTouchCancel={contextMenuHandlers.onTouchCancel}
        onTouchEnd={contextMenuHandlers.onTouchEnd}
        onTouchMove={contextMenuHandlers.onTouchMove}
        onTouchStart={(e) => contextMenuHandlers.onTouchStart(e, attachment)}
      >
        <FileInfo
          key={rowProps.index}
          attachment={attachment}
          stamp={attachment.message.stamp}
        />
      </div>
    </div>
  );
});

export default AttachedFilesRow;
