import React from 'react';
import {autorun} from 'mobx';

import {use100vh} from '../../../components/Div100vh';
import {useStore} from '../../../stores/AppStore';
import {getStyleProperty} from '../../../utils/styleProperty';


const useTabHeight = () => {
  const height = use100vh();
  const {layOutStore} = useStore();
  const [bottomMenuVisible, setBottomMenuVisible] = React.useState<boolean>(layOutStore.chatLeftSidebarBottomMenuVisible);

  React.useEffect(() => {
    autorun(() => {
      setBottomMenuVisible(layOutStore.chatLeftSidebarBottomMenuVisible)
    });
  }, [
    layOutStore.chatLeftSidebarBottomMenuVisible,
  ]);

  const tabHeight = React.useMemo((): string | number => {
    if (bottomMenuVisible) {
      const sidebarHeight = parseInt(getStyleProperty('--chat-left-sidebar-bottom-tabs-height'), 10);

      return height ? height - sidebarHeight : `calc(100vh - ${sidebarHeight}px)`;
    } else {
      return height || '100vh';
    }
  }, [
    bottomMenuVisible,
    height,
  ]);

  return tabHeight;
};

export default useTabHeight;
