import React from 'react';
import {autorun} from 'mobx';

import {MCChatPreview} from '../../../api/proto';
import Chat from '../Chat';


export default function useCanSendMessages(chat: Chat) {
  const [canSendMessages, setCanSendMessages] = React.useState(false);

  React.useEffect(() => {
    autorun(() => {
      setCanSendMessages(
        chat.type !== MCChatPreview.Type.CHANNEL &&
        !!chat.channel.type
      );
    });
  }, [
    chat.type,
    chat.channel.type,
  ]);

  return canSendMessages;
}
