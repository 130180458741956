import JSZip from 'jszip';
import startsWith from 'lodash/startsWith';
import endsWith from 'lodash/endsWith';


export async function unzipFile(file): Promise<string[]> {
  return new Promise((resolve, reject) => {
    JSZip.loadAsync(file)
      .then(function (zip) {
        const res: string[] = [];
        zip.forEach((relativePath, zipEntry: JSZip.JSZipObject) => {
          res.push(zipEntry.name);
        });
        resolve(res);
      },
        function (e) {
          reject(file.name + '@ ' + e.message);
        });
  });
}


function tarHeaderChecksumMatches(buffer, offset = 0) {
  const readSum = Number.parseInt(buffer.toString('utf8', 148, 154).replace(/\0.*$/, '').trim(), 8); // Read sum in header
  if (Number.isNaN(readSum)) {
    return false;
  }

  let sum = 8 * 0x20; // Initialize signed bit sum

  for (let index = offset; index < offset + 148; index++) {
    sum += buffer[index];
  }

  for (let index = offset + 156; index < offset + 512; index++) {
    sum += buffer[index];
  }

  return readSum === sum;
}

function stringToBytes(string) {
  return [...string].map(character => character.charCodeAt(0));
}

function getBrandMajor(buffer) {
  // They all can have MIME `video/mp4` except `application/mp4` special-case which is hard to detect.
  // For some cases, we're specific, everything else falls to `video/mp4` with `mp4` extension.
  const brandMajor = buffer.toString('binary', 8, 12).replace('\0', ' ').trim();
  return brandMajor;
}

function getJsonSize(buffer) {
  const jsonSize = buffer?.readUInt32LE(12);
  return jsonSize;
}

const minimumBytes = 4100;

function _check(buffer, headers: number[], options: {offset?: number; mask?: number[]} = {}) {
  const {offset = 0} = options;

  for (const [index, header] of headers.entries()) {
    if (options?.mask) {
      if (header !== (options.mask[index] & buffer[index + offset])) {
        return false;
      }
    } else if (header !== buffer[index + offset]) {
      return false;
    }
  }

  return true;
}

export class FileTypeParser {
  constructor(blob: Blob) {
    this.blobRaw = blob;
  }

  blobRaw: Blob;

  buffer: Uint8Array = new Uint8Array();

  check(header: number[], options?: {offset?: number; mask?: number[]}) {
    return _check(this.buffer, header, options);
  }

  checkString(header: string, options?: {offset?: number; mask?: number[]}) {
    return this.check(stringToBytes(header), options);
  }

  async parse(buffer: Uint8Array) {
    this.buffer = buffer;

    // -- 2-byte signatures --

    if (this.check([0x42, 0x4D])) {
      return {
        ext: 'bmp',
        mime: 'image/bmp',
      };
    }

    if (this.check([0x4D, 0x5A])) {
      return {
        ext: 'exe',
        mime: 'application/x-msdownload',
      };
    }

    if (this.check([0x47, 0x49, 0x46])) {
      return {
        ext: 'gif',
        mime: 'image/gif',
      };
    }

    //jpg
    if (this.check([0xFF, 0xD8, 0xFF])) {
      return {
        ext: 'jpg',
        mime: 'image/jpeg',
      };
    }

    //gz
    if (this.check([0x1F, 0x8B, 0x8])) {
      return {
        ext: 'gz',
        mime: 'application/gzip',
      };
    }

    // -- 4-byte signatures --

    if (this.checkString('FLIF')) {
      return {
        ext: 'flif',
        mime: 'image/flif',
      };
    }

    //psd
    if (this.checkString('8BPS')) {
      return {
        ext: 'psd',
        mime: 'image/vnd.adobe.photoshop',
      };
    }

    if (this.checkString('WEBP', {offset: 8})) {
      return {
        ext: 'webp',
        mime: 'image/webp',
      };
    }

    // Musepack, SV8
    if (this.checkString('MPCK')) {
      return {
        ext: 'mpc',
        mime: 'audio/x-musepack',
      };
    }

    if (this.checkString('FORM')) {
      return {
        ext: 'aif',
        mime: 'audio/aiff',
      };
    }

    if (this.checkString('icns', {offset: 0})) {
      return {
        ext: 'icns',
        mime: 'image/icns',
      };
    }

    // File Type Box (https://en.wikipedia.org/wiki/ISO_base_media_file_format)
    // It's not required to be first, but it's recommended to be. Almost all ISO base media files start with `ftyp` box.
    // `ftyp` box must contain a brand major identifier, which must consist of ISO 8859-1 printable characters.
    // Here we check for 8859-1 printable characters (for simplicity, it's a mask which also catches one non-printable character).
    if (
      this.checkString('ftyp', {offset: 4})
      && (this.buffer[8] & 0x60) !== 0x00 // Brand major, first character ASCII?
    ) {
      const brandMajor = getBrandMajor(this.buffer);
      switch (brandMajor) {
        case 'avif':
        case 'avis':
          return {ext: 'avif', mime: 'image/avif'};
        case 'mif1':
          return {ext: 'heic', mime: 'image/heif'};
        case 'msf1':
          return {ext: 'heic', mime: 'image/heif-sequence'};
        case 'heic':
        case 'heix':
          return {ext: 'heic', mime: 'image/heic'};
        case 'hevc':
        case 'hevx':
          return {ext: 'heic', mime: 'image/heic-sequence'};
        case 'qt':
          return {ext: 'mov', mime: 'video/quicktime'};
        case 'M4V':
        case 'M4VH':
        case 'M4VP':
          return {ext: 'm4v', mime: 'video/x-m4v'};
        case 'M4P':
          return {ext: 'm4p', mime: 'video/mp4'};
        case 'M4B':
          return {ext: 'm4b', mime: 'audio/mp4'};
        case 'M4A':
          return {ext: 'm4a', mime: 'audio/x-m4a'};
        case 'F4V':
          return {ext: 'f4v', mime: 'video/mp4'};
        case 'F4P':
          return {ext: 'f4p', mime: 'video/mp4'};
        case 'F4A':
          return {ext: 'f4a', mime: 'audio/mp4'};
        case 'F4B':
          return {ext: 'f4b', mime: 'audio/mp4'};
        case 'crx':
          return {ext: 'cr3', mime: 'image/x-canon-cr3'};
        default:
          if (brandMajor.startsWith('3g')) {
            if (brandMajor.startsWith('3g2')) {
              return {ext: '3g2', mime: 'video/3gpp2'};
            }

            return {ext: '3gp', mime: 'video/3gpp'};
          }

          return {ext: 'mp4', mime: 'video/mp4'};
      }
    }

    if (this.checkString('MThd')) {
      return {
        ext: 'mid',
        mime: 'audio/midi',
      };
    }

    if (
      this.checkString('wOFF')
      && (
        this.check([0x00, 0x01, 0x00, 0x00], {offset: 4})
        || this.checkString('OTTO', {offset: 4})
      )
    ) {
      return {
        ext: 'woff',
        mime: 'font/woff',
      };
    }

    if (
      this.checkString('wOF2')
      && (
        this.check([0x00, 0x01, 0x00, 0x00], {offset: 4})
        || this.checkString('OTTO', {offset: 4})
      )
    ) {
      return {
        ext: 'woff2',
        mime: 'font/woff2',
      };
    }

    if (this.check([0xD4, 0xC3, 0xB2, 0xA1]) || this.check([0xA1, 0xB2, 0xC3, 0xD4])) {
      return {
        ext: 'pcap',
        mime: 'application/vnd.tcpdump.pcap',
      };
    }

    // Sony DSD Stream File (DSF)
    if (this.checkString('DSD ')) {
      return {
        ext: 'dsf',
        mime: 'audio/x-dsf', // Non-standard
      };
    }

    if (this.checkString('LZIP')) {
      return {
        ext: 'lz',
        mime: 'application/x-lzip',
      };
    }

    if (this.checkString('fLaC')) {
      return {
        ext: 'flac',
        mime: 'audio/x-flac',
      };
    }

    //bpg
    if (this.check([0x42, 0x50, 0x47, 0xFB])) {
      return {
        ext: 'bpg',
        mime: 'image/bpg',
      };
    }

    if (this.checkString('wvpk')) {
      return {
        ext: 'wv',
        mime: 'audio/wavpack',
      };
    }

    if (this.checkString('%PDF')) {
      return {
        ext: 'pdf',
        mime: 'application/pdf',
      };
    }

    if (this.check([0x00, 0x61, 0x73, 0x6D])) {
      return {
        ext: 'wasm',
        mime: 'application/wasm',
      };
    }

    if (this.checkString('MAC ')) {
      return {
        ext: 'ape',
        mime: 'audio/ape',
      };
    }

    // RIFF file format which might be AVI, WAV, QCP, etc
    if (this.check([0x52, 0x49, 0x46, 0x46])) {
      if (this.check([0x41, 0x56, 0x49], {offset: 8})) {
        return {
          ext: 'avi',
          mime: 'video/vnd.avi',
        };
      }

      if (this.check([0x57, 0x41, 0x56, 0x45], {offset: 8})) {
        return {
          ext: 'wav',
          mime: 'audio/vnd.wave',
        };
      }

      // QLCM, QCP file
      if (this.check([0x51, 0x4C, 0x43, 0x4D], {offset: 8})) {
        return {
          ext: 'qcp',
          mime: 'audio/qcelp',
        };
      }
    }

    if (this.checkString('SQLi')) {
      return {
        ext: 'sqlite',
        mime: 'application/x-sqlite3',
      };
    }

    if (this.check([0x4E, 0x45, 0x53, 0x1A])) {
      return {
        ext: 'nes',
        mime: 'application/x-nintendo-nes-rom',
      };
    }

    if (this.checkString('Cr24')) {
      return {
        ext: 'crx',
        mime: 'application/x-google-chrome-extension',
      };
    }

    if (
      this.checkString('MSCF')
      || this.checkString('ISc(')
    ) {
      return {
        ext: 'cab',
        mime: 'application/vnd.ms-cab-compressed',
      };
    }

    if (this.check([0xED, 0xAB, 0xEE, 0xDB])) {
      return {
        ext: 'rpm',
        mime: 'application/x-rpm',
      };
    }

    if (this.check([0xC5, 0xD0, 0xD3, 0xC6])) {
      return {
        ext: 'eps',
        mime: 'application/eps',
      };
    }

    if (this.check([0x28, 0xB5, 0x2F, 0xFD])) {
      return {
        ext: 'zst',
        mime: 'application/zstd',
      };
    }

    if (this.check([0x7F, 0x45, 0x4C, 0x46])) {
      return {
        ext: 'elf',
        mime: 'application/x-elf',
      };
    }

    // -- 5-byte signatures --

    if (this.check([0x4F, 0x54, 0x54, 0x4F, 0x00])) {
      return {
        ext: 'otf',
        mime: 'font/otf',
      };
    }

    if (this.checkString('#!AMR')) {
      return {
        ext: 'amr',
        mime: 'audio/amr',
      };
    }

    if (this.checkString('{\\rtf')) {
      return {
        ext: 'rtf',
        mime: 'application/rtf',
      };
    }

    if (this.check([0x46, 0x4C, 0x56, 0x01])) {
      return {
        ext: 'flv',
        mime: 'video/x-flv',
      };
    }

    if (this.checkString('IMPM')) {
      return {
        ext: 'it',
        mime: 'audio/x-it',
      };
    }

    if (
      this.checkString('-lh0-', {offset: 2})
      || this.checkString('-lh1-', {offset: 2})
      || this.checkString('-lh2-', {offset: 2})
      || this.checkString('-lh3-', {offset: 2})
      || this.checkString('-lh4-', {offset: 2})
      || this.checkString('-lh5-', {offset: 2})
      || this.checkString('-lh6-', {offset: 2})
      || this.checkString('-lh7-', {offset: 2})
      || this.checkString('-lzs-', {offset: 2})
      || this.checkString('-lz4-', {offset: 2})
      || this.checkString('-lz5-', {offset: 2})
      || this.checkString('-lhd-', {offset: 2})
    ) {
      return {
        ext: 'lzh',
        mime: 'application/x-lzh-compressed',
      };
    }

    // MPEG program stream (PS or MPEG-PS)
    if (this.check([0x00, 0x00, 0x01, 0xBA])) {
      //  MPEG-PS, MPEG-1 Part 1
      if (this.check([0x21], {offset: 4, mask: [0xF1]})) {
        return {
          ext: 'mpg', // May also be .ps, .mpeg
          mime: 'video/MP1S',
        };
      }

      // MPEG-PS, MPEG-2 Part 1
      if (this.check([0x44], {offset: 4, mask: [0xC4]})) {
        return {
          ext: 'mpg', // May also be .mpg, .m2p, .vob or .sub
          mime: 'video/MP2P',
        };
      }
    }

    if (this.checkString('ITSF')) {
      return {
        ext: 'chm',
        mime: 'application/vnd.ms-htmlhelp',
      };
    }

    // -- 6-byte signatures --

    if (this.check([0xFD, 0x37, 0x7A, 0x58, 0x5A, 0x00])) {
      return {
        ext: 'xz',
        mime: 'application/x-xz',
      };
    }

    if (this.checkString('<?xml ')) {
      return {
        ext: 'xml',
        mime: 'application/xml',
      };
    }

    if (this.check([0x37, 0x7A, 0xBC, 0xAF, 0x27, 0x1C])) {
      return {
        ext: '7z',
        mime: 'application/x-7z-compressed',
      };
    }

    if (
      this.check([0x52, 0x61, 0x72, 0x21, 0x1A, 0x7])
      && (this.buffer[6] === 0x0 || this.buffer[6] === 0x1)
    ) {
      return {
        ext: 'rar',
        mime: 'application/x-rar-compressed',
      };
    }

    if (this.checkString('solid ')) {
      return {
        ext: 'stl',
        mime: 'model/stl',
      };
    }

    // -- 8-byte signatures --
    if (this.check([0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A])) {
      return {
        ext: 'png',
        mime: 'image/png',
      };
    }

    if (this.check([0x41, 0x52, 0x52, 0x4F, 0x57, 0x31, 0x00, 0x00])) {
      return {
        ext: 'arrow',
        mime: 'application/x-apache-arrow',
      };
    }

    if (this.check([0x67, 0x6C, 0x54, 0x46, 0x02, 0x00, 0x00, 0x00])) {
      return {
        ext: 'glb',
        mime: 'model/gltf-binary',
      };
    }

    // `mov` format variants
    if (
      this.check([0x66, 0x72, 0x65, 0x65], {offset: 4}) // `free`
      || this.check([0x6D, 0x64, 0x61, 0x74], {offset: 4}) // `mdat` MJPEG
      || this.check([0x6D, 0x6F, 0x6F, 0x76], {offset: 4}) // `moov`
      || this.check([0x77, 0x69, 0x64, 0x65], {offset: 4}) // `wide`
    ) {
      return {
        ext: 'mov',
        mime: 'video/quicktime',
      };
    }

    // -- 9-byte signatures --

    if (this.check([0x49, 0x49, 0x52, 0x4F, 0x08, 0x00, 0x00, 0x00, 0x18])) {
      return {
        ext: 'orf',
        mime: 'image/x-olympus-orf',
      };
    }

    if (this.checkString('gimp xcf ')) {
      return {
        ext: 'xcf',
        mime: 'image/x-xcf',
      };
    }

    // -- 12-byte signatures --

    if (this.check([0x49, 0x49, 0x55, 0x00, 0x18, 0x00, 0x00, 0x00, 0x88, 0xE7, 0x74, 0xD8])) {
      return {
        ext: 'rw2',
        mime: 'image/x-panasonic-rw2',
      };
    }

    // ASF_Header_Object first 80 bytes
    if (this.check([0x30, 0x26, 0xB2, 0x75, 0x8E, 0x66, 0xCF, 0x11, 0xA6, 0xD9])) {
      return {
        ext: 'asf',
        mime: 'application/vnd.ms-asf',
      };
    }

    if (this.check([0xAB, 0x4B, 0x54, 0x58, 0x20, 0x31, 0x31, 0xBB, 0x0D, 0x0A, 0x1A, 0x0A])) {
      return {
        ext: 'ktx',
        mime: 'image/ktx',
      };
    }

    if ((this.check([0x7E, 0x10, 0x04]) || this.check([0x7E, 0x18, 0x04])) && this.check([0x30, 0x4D, 0x49, 0x45], {offset: 4})) {
      return {
        ext: 'mie',
        mime: 'application/x-mie',
      };
    }

    if (this.check([0x27, 0x0A, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00], {offset: 2})) {
      return {
        ext: 'shp',
        mime: 'application/x-esri-shape',
      };
    }

    if (this.check([0x00, 0x00, 0x00, 0x0C, 0x6A, 0x50, 0x20, 0x20, 0x0D, 0x0A, 0x87, 0x0A])) {
      // JPEG-2000 family
      return {
        ext: 'jp2',
        mime: 'image/jp2',
      };
    }

    if (
      this.check([0xFF, 0x0A])
      || this.check([0x00, 0x00, 0x00, 0x0C, 0x4A, 0x58, 0x4C, 0x20, 0x0D, 0x0A, 0x87, 0x0A])
    ) {
      return {
        ext: 'jxl',
        mime: 'image/jxl',
      };
    }

    if (this.check([0xFE, 0xFF])) { // UTF-16-BOM-LE
      if (this.check([0, 60, 0, 63, 0, 120, 0, 109, 0, 108], {offset: 2})) {
        return {
          ext: 'xml',
          mime: 'application/xml',
        };
      }

      return undefined; // Some unknown text based format
    }

    // -- Unsafe signatures --

    if (
      this.check([0x0, 0x0, 0x1, 0xBA])
      || this.check([0x0, 0x0, 0x1, 0xB3])
    ) {
      return {
        ext: 'mpg',
        mime: 'video/mpeg',
      };
    }

    if (this.check([0x00, 0x01, 0x00, 0x00, 0x00])) {
      return {
        ext: 'ttf',
        mime: 'font/ttf',
      };
    }

    if (this.check([0x00, 0x00, 0x01, 0x00])) {
      return {
        ext: 'ico',
        mime: 'image/x-icon',
      };
    }

    if (this.check([0x00, 0x00, 0x02, 0x00])) {
      return {
        ext: 'cur',
        mime: 'image/x-icon',
      };
    }

    if (this.check([0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xB1, 0x1A, 0xE1])) {
      // office2003 (MS-CFB) Format.
      return {
        ext: 'cfb',
        mime: 'application/x-cfb',
      };
    }

    // -- 15-byte signatures --

    if (this.checkString('BEGIN:')) {
      if (this.checkString('VCARD', {offset: 6})) {
        return {
          ext: 'vcf',
          mime: 'text/vcard',
        };
      }

      if (this.checkString('VCALENDAR', {offset: 6})) {
        return {
          ext: 'ics',
          mime: 'text/calendar',
        };
      }
    }

    // `raf` is here just to keep all the raw image detectors together.
    if (this.checkString('FUJIFILMCCD-RAW')) {
      return {
        ext: 'raf',
        mime: 'image/x-fujifilm-raf',
      };
    }

    if (this.checkString('Extended Module:')) {
      return {
        ext: 'xm',
        mime: 'audio/x-xm',
      };
    }

    if (this.checkString('Creative Voice File')) {
      return {
        ext: 'voc',
        mime: 'audio/x-voc',
      };
    }

    if (this.check([0x04, 0x00, 0x00, 0x00]) && this.buffer.length >= 16) { // Rough & quick check Pickle/ASAR
      const jsonSize = getJsonSize(this.buffer);
      if (jsonSize > 12 && this.buffer.length >= jsonSize + 16) {
        try {
          const header = this.buffer.slice(16, jsonSize + 16).toString();
          const json = JSON.parse(header);
          // Check if Pickle is ASAR
          if (json.files) { // Final check, assuring Pickle/ASAR format
            return {
              ext: 'asar',
              mime: 'application/x-asar',
            };
          }
          // eslint-disable-next-line no-empty
        } catch {
        }
      }
    }

    if (this.check([0x06, 0x0E, 0x2B, 0x34, 0x02, 0x05, 0x01, 0x01, 0x0D, 0x01, 0x02, 0x01, 0x01, 0x02])) {
      return {
        ext: 'mxf',
        mime: 'application/mxf',
      };
    }

    if (this.checkString('SCRM', {offset: 44})) {
      return {
        ext: 's3m',
        mime: 'audio/x-s3m',
      };
    }

    // Raw MPEG-2 transport stream (188-byte packets)
    if (this.check([0x47]) && this.check([0x47], {offset: 188})) {
      return {
        ext: 'mts',
        mime: 'video/mp2t',
      };
    }

    // Blu-ray Disc Audio-Video (BDAV) MPEG-2 transport stream has 4-byte TP_extra_header before each 188-byte packet
    if (this.check([0x47], {offset: 4}) && this.check([0x47], {offset: 196})) {
      return {
        ext: 'mts',
        mime: 'video/mp2t',
      };
    }

    if (this.check([0x42, 0x4F, 0x4F, 0x4B, 0x4D, 0x4F, 0x42, 0x49], {offset: 60})) {
      return {
        ext: 'mobi',
        mime: 'application/x-mobipocket-ebook',
      };
    }

    if (this.check([0x44, 0x49, 0x43, 0x4D], {offset: 128})) {
      return {
        ext: 'dcm',
        mime: 'application/dicom',
      };
    }

    if (this.check([0x4C, 0x00, 0x00, 0x00, 0x01, 0x14, 0x02, 0x00, 0x00, 0x00, 0x00, 0x00, 0xC0, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x46])) {
      return {
        ext: 'lnk',
        mime: 'application/x.ms.shortcut', // Invented by us
      };
    }

    if (this.check([0x62, 0x6F, 0x6F, 0x6B, 0x00, 0x00, 0x00, 0x00, 0x6D, 0x61, 0x72, 0x6B, 0x00, 0x00, 0x00, 0x00])) {
      return {
        ext: 'alias',
        mime: 'application/x.apple.alias', // Invented by us
      };
    }

    if (
      this.check([0x4C, 0x50], {offset: 34})
      && (
        this.check([0x00, 0x00, 0x01], {offset: 8})
        || this.check([0x01, 0x00, 0x02], {offset: 8})
        || this.check([0x02, 0x00, 0x02], {offset: 8})
      )
    ) {
      return {
        ext: 'eot',
        mime: 'application/vnd.ms-fontobject',
      };
    }

    if (this.check([0x06, 0x06, 0xED, 0xF5, 0xD8, 0x1D, 0x46, 0xE5, 0xBD, 0x31, 0xEF, 0xE7, 0xFE, 0x74, 0xB7, 0x1D])) {
      return {
        ext: 'indd',
        mime: 'application/x-indesign',
      };
    }

    // Requires a buffer size of 512 bytes
    if (tarHeaderChecksumMatches(this.buffer)) {
      return {
        ext: 'tar',
        mime: 'application/x-tar',
      };
    }

    if (this.check([0xFF, 0xFE])) { // UTF-16-BOM-BE
      if (this.check([60, 0, 63, 0, 120, 0, 109, 0, 108, 0], {offset: 2})) {
        return {
          ext: 'xml',
          mime: 'application/xml',
        };
      }

      if (this.check([0xFF, 0x0E, 0x53, 0x00, 0x6B, 0x00, 0x65, 0x00, 0x74, 0x00, 0x63, 0x00, 0x68, 0x00, 0x55, 0x00, 0x70, 0x00, 0x20, 0x00, 0x4D, 0x00, 0x6F, 0x00, 0x64, 0x00, 0x65, 0x00, 0x6C, 0x00], {offset: 2})) {
        return {
          ext: 'skp',
          mime: 'application/vnd.sketchup.skp',
        };
      }

      return undefined; // Some text based format
    }

    if (this.checkString('-----BEGIN PGP MESSAGE-----')) {
      return {
        ext: 'pgp',
        mime: 'application/pgp-encrypted',
      };
    }

    // Check MPEG 1 or 2 Layer 3 header, or 'layer 0' for ADTS (MPEG sync-word 0xFFE)
    if (this.buffer.length >= 2 && this.check([0xFF, 0xE0], {offset: 0, mask: [0xFF, 0xE0]})) {
      if (this.check([0x10], {offset: 1, mask: [0x16]})) {
        // Check for (ADTS) MPEG-2
        if (this.check([0x08], {offset: 1, mask: [0x08]})) {
          return {
            ext: 'aac',
            mime: 'audio/aac',
          };
        }

        // Must be (ADTS) MPEG-4
        return {
          ext: 'aac',
          mime: 'audio/aac',
        };
      }

      // MPEG 1 or 2 Layer 3 header
      // Check for MPEG layer 3
      if (this.check([0x02], {offset: 1, mask: [0x06]})) {
        return {
          ext: 'mp3',
          mime: 'audio/mpeg',
        };
      }

      // Check for MPEG layer 2
      if (this.check([0x04], {offset: 1, mask: [0x06]})) {
        return {
          ext: 'mp2',
          mime: 'audio/mpeg',
        };
      }

      // Check for MPEG layer 1
      if (this.check([0x06], {offset: 1, mask: [0x06]})) {
        return {
          ext: 'mp1',
          mime: 'audio/mpeg',
        };
      }
    }

    if (this.check([0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xb1, 0x1A, 0xE1]) || this.checkString('WPS2001')) {
      return {
        ext: 'doc',
        mime: 'application/x-indesign',
      };
    }

    if (this.check([0x50, 0x4B, 0x3, 0x4])) {
      const zipArchive = await unzipFile(this.blobRaw);
      while (zipArchive.length) {
        const item = zipArchive.pop();
        if (item && (endsWith(item, '.rels') || endsWith(item, '.xml'))) {
          const type = item.split('/')[0];
          switch (type) {
            case '_rels':
              break;
            case 'word':
              return {
                ext: 'docx',
                mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              };
            case 'ppt':
              return {
                ext: 'pptx',
                mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
              };
            case 'xl':
              return {
                ext: 'xlsx',
                mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              };
            default:
              break;
          }
        }
        if (startsWith(item, 'xl/')) {
          return {
            ext: 'xlsx',
            mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          };
        }
        if (startsWith(item, '3D/') && endsWith(item, '.model')) {
          return {
            ext: '3mf',
            mime: 'model/3mf',
          };
        }
      }

      return {
        ext: 'zip',
        mime: 'application/zip',
      };
    }

    return {
      ext: '',
      mime: 'unknow',
    };
  }
}

export default function blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('loadend', function (e: ProgressEvent<FileReader>) {
      if (e.target?.error || !reader.result || typeof reader.result === 'string') {
        reject();
      } else {
        resolve(reader.result);
      }
    }, false);

    reader.onerror = reject;
    reader.readAsArrayBuffer(blob.slice(0, minimumBytes));
  });
}


export async function fileTypeFromBlob(blob: Blob) {
  try {
    console.debug('fileTypeFromBlob');
    const arrayBuffer = await blobToArrayBuffer(blob);
    const uint8Array = new Uint8Array(arrayBuffer);
    return new FileTypeParser(blob).parse(uint8Array);
  } catch (error: any) {
    console.debug('fileTypeFromBlob error:', error, error.message, 'EndOfStreamError');
    throw error;
  }
}
