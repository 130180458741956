import React from 'react';
import cn from 'classnames';
import Lottie from 'react-lottie-player';
import * as pako from 'pako';

function fetchPath(path: string): Promise<Record<string, unknown>> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', path, true);
    xhr.responseType = 'arraybuffer';
    xhr.send();
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        try {
          const data = pako.inflate(xhr.response, {to: 'string'});
          return resolve(JSON.parse(data));
        } catch (err) {
          return reject(err);
        }
      }
    };
  });
}

export interface LottiePlayerProps {
  className?: string;
  style?: React.CSSProperties | undefined;
  url: string;
  play?: boolean;
  playOnHover?: boolean;
  isScrolling?: boolean;
}

export interface LottiePlayerRef {
  play: () => void;
  pause: () => void;
  stop: () => void;
}

export const LottiePlayer = React.forwardRef<LottiePlayerRef, LottiePlayerProps>((props, ref) => {
  const [play, setPlay] = React.useState<boolean>(false);
  const [lottieData, setLottieData] = React.useState<Record<string, unknown> | null>(null);

  React.useEffect(() => {
    setPlay(!!props.play);
  }, [props.play]);

  React.useEffect(() => {
    let isMounted = true;

    const loadLottieData = async (url: string) => {
      try {
        const data = await fetchPath(url);
        if (!isMounted) {
          return;
        }
        setLottieData(data);
      } catch (e) {
        console.warn(e);
      }
    };

    loadLottieData(props.url);

    return () => {
      isMounted = false;
    };
  }, [props.url]);

  React.useImperativeHandle(ref, () => ({
    play: () => {
      setPlay(true);
    },
    pause: () => {
      setPlay(false);
    },
    stop: () => {
      setPlay(false);
    },
  }));

  const handleHover = () => {
    if (props.playOnHover) {
      setPlay(true);
    }
  };

  const handleMouseLeave = () => {
    if (props.playOnHover) {
      setPlay(false);
    }
  };

  return lottieData ? (
    <Lottie
      className={cn('lottie-player', props.className)}
      style={props.style}
      play={play}
      loop
      animationData={lottieData}
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
    />
  ) : null;
});

export default LottiePlayer;
