import {observer} from 'mobx-react';
import React from 'react';

import {ListChildComponentProps} from 'o-ui/VirtualizedList';
import {CHAT_ITEM_HEIGHT, CHAT_ITEM_HEIGHT_COMPACT} from '../../../../../constants';
import Chat, {ChatClickEventHandler} from '../../../../../stores/Chat';
import useStore from '../../../../../stores/useStore';
import ChatItem from '../ChatItem';


interface ChatsVirtualizedListItemProps {
  storeKey?: string;
  row: ListChildComponentProps;
  setSize: (index: number, size: number) => void;
  onChatClick?: ChatClickEventHandler;
}

export const ChatsVirtualizedListItem = observer((props: ChatsVirtualizedListItemProps) => {
  const {chatsView, layOutStore} = useStore();
  const {row, setSize} = props;
  const {index} = row;

  React.useEffect(() => {
    const height = layOutStore.compactMode ? CHAT_ITEM_HEIGHT_COMPACT : CHAT_ITEM_HEIGHT;
    setSize(index, height);
  }, [
    setSize,
    index,
    layOutStore.compactMode,
  ]);

  const onChatClick = React.useCallback((e: React.MouseEvent, chat: Chat) => {
    e.preventDefault();
    props.onChatClick?.(chat);
  }, [props]);


  const chats = !props.storeKey || chatsView.chatsStoreKey === props.storeKey ? chatsView.chats : chatsView.getChats(props.storeKey);
  const chat = chats[row.index];
  if (chat) {
    return (
      <ChatItem
        index={row.index}
        chat={chat}
        style={row.style}
        onOpen={onChatClick}
      />
    );
  }

  return null;
});

export default ChatsVirtualizedListItem;
