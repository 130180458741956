import React from 'react';
import cn from 'classnames';
import {FileRejection, useDropzone} from 'react-dropzone';
import {IDropzoneProps} from './Dropzone';

interface IProps extends IDropzoneProps {
  title?: string;
  description?: string;
  disabled?: boolean;
}

export const DropzoneBox: React.FC<IProps> = (props) => {
  const [isDrag, setIsDrag] = React.useState<boolean>(false);

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    fileRejections.forEach((rejection) => {
      console.log(rejection);
    });

    props.onChange && props.onChange(acceptedFiles);
  };

  const onDragEnter = () => {
    setIsDrag(true);
  };

  const onDragLeave = () => {
    setIsDrag(false);
  };

  const {getRootProps, getInputProps} = useDropzone({
    noClick: props.noClick,
    accept: props.accept,
    noKeyboard: props.noKeyboard,
    onDrop,
    onDragEnter,
    onDragLeave,
    disabled: props.disabled,
  });

  return (
    <div
      {...getRootProps({
        className: cn(props.className, 'dropzone__box shadow-lg', {drag: isDrag}),
      })}
    >
      {props.title ? <div className="dropzone__box-title">{props.title}</div> : null}

      {props.description ? <div className="dropzone__box-description">{props.description}</div> : null}

      <input {...getInputProps()} disabled={props.disabled} />
    </div>
  );
};

export default DropzoneBox;
