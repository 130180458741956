import Chat from './Chat';

export class DraftMessage {
  constructor(public chat: Chat) {
  }

  public save = (text: string) => {
    console.debug(`TODO: not implementre method "saveDraftMessage"`, text);
    this.chat.setDraft(text);
  };

  protected set = (text?: string | null) => {
    this.chat.setDraft(text || '');
  };

  public processUpdate = (text?: string | null) => {
    const oldDraftMessage = this.chat.draft;
    this.set(text);

    if (this.chat.store?.active) {
      if (text && !this.chat.store?.editor?.quill?.getText().trim()) {
        this.chat.store?.editor?.setText(text);
      } else if (!text && this.chat.store?.editor?.quill?.getText().trim() === oldDraftMessage) {
        this.chat.store?.editor?.setText(null);
      }
    }
  };
}

export default DraftMessage;
