import React from 'react';
import windowInnerHeight from '../utils/windowInnerHeight';
import useWasRenderedOnClientAtLeastOnce from './useWasRenderedOnClientAtLeastOnce';

export function useOrigin100vh(): number | null {
  const [height, setHeight] = React.useState<number | null>(windowInnerHeight);

  const wasRenderedOnClientAtLeastOnce = useWasRenderedOnClientAtLeastOnce();

  React.useEffect(() => {
    if (!wasRenderedOnClientAtLeastOnce) return;

    function setMeasuredHeight() {
      const _originHeight = windowInnerHeight();
      setHeight(_originHeight);
    }

    window.addEventListener('resize', setMeasuredHeight);
    return () => window.removeEventListener('resize', setMeasuredHeight);
  }, [wasRenderedOnClientAtLeastOnce]);

  return wasRenderedOnClientAtLeastOnce ? height : null;
}

export default useOrigin100vh;