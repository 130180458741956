import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import Switch from 'o-ui/Switch';

import {ReactComponent as ChevronRightIcon} from '../../../../../assets/icons/chevron-right-line.svg';
import Channel from '../../../../../stores/Channel';
import WorkspaceMember from '../../../../../stores/Workspaces/WorkspaceMember';
import {WorkspaceMemberAccessRecord} from '../../../../../stores/Workspaces/WorkspaceMemberAccessRecord';
import {equalUint8Arrays} from '../../../../../utils/arrayUtils';
import MemberAccessInfo from './MemberAccessInfo';
import {getUserRoleTitle} from '../../../../../stores/Workspaces';


interface MembersAccessItemProps {
  channel: Channel;
  member: WorkspaceMember;
  onOpenAccessRecord?(member: WorkspaceMember, accessRecord: WorkspaceMemberAccessRecord): void;
  onChange?(changed: boolean, member: WorkspaceMember): void;
}

const MembersAccessItem: React.FC<MembersAccessItemProps> = observer(({
  channel,
  member,
  onOpenAccessRecord,
  onChange,
}) => {
  const accessRecord = React.useMemo(() => member.config.channelAccessRecords.find(
    (record) => equalUint8Arrays(channel.id, record.channelID)
  ), [
    member.config,
    channel.id,
  ]);

  if (!accessRecord) {
    return null;
  }

  const handleClick = () => {
    onOpenAccessRecord?.(member, accessRecord);
  };

  const handleToggleDisableChannel = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    accessRecord.toggleChannelAccess();
    onChange?.(accessRecord.edited, member);
  };

  const handleClickToggle = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const isInactive = !!(!member.active || member.expired) || !member.isOperator;

  const title = member.displayName;
  const subTitle = title && title !== member.email && member.email ? (
    <>{getUserRoleTitle(member.role)} &bull; {member.email}</>
  ) : getUserRoleTitle(member.role);

  return (
    <div
      className={cn('channels-access-item', {
        disabled: accessRecord.disableChannel,
        inactive: isInactive,
      })}
      onClick={isInactive ? undefined : handleClick}
    >
      <div className="channels-access-item__title">
        <div className="channels-access-item__name">{title}</div>
        <div className="channels-access-item__phone">{subTitle}</div>
      </div>

      <MemberAccessInfo classNames="ml-auto" accessRecord={accessRecord} />
      <Switch
        checked={!accessRecord.disableChannel}
        onChange={handleToggleDisableChannel}
        onClick={handleClickToggle}
        disabled={isInactive}
      />

      <ChevronRightIcon />
    </div>
  );
});

export default MembersAccessItem;
