import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';

import {ErrorEvent} from '../../api/network';
import {api, entities} from '../../api/proto';
import {PREFERABLE_TARIFF_ID} from '../../constants';
import browserStorage from '../../utils/browserStorage';
import navigateToExternalUrl from '../../utils/navigateToExternalUrl';
import wait from '../../utils/wait';
import {WorkspaceStore} from '../Workspaces';
import BillingHistoryItem from './BillingHistoryItem';
import getCancelCurrentTariffError from './getCancelCurrentTariffError';
import getCustomerPortalError from './getCustomerPortalError';
import getPayForTariffError from './getPayForTariffError';
import getResumeCurrentTariffError from './getResumeCurrentTariffError';
import compareTariffId from './utils/compareTariffId';
import isFreeTariff from './utils/isFreeTariff';

export const TRIAL_TARIFF_ID = 'trial';
export const TARIFF_REFRESH_ATTEMPTS = 7;

const BILLING_HISTORY_INITIAL_LIMIT = Long.fromNumber(10);
const BILLING_HISTORY_LIMIT = Long.fromNumber(5);

export class BillingStore {

  @computed get hasAccess(): boolean {
    return this.workspace.app.features.billing && !!this.workspace.currentMember?.isOwner;
  }

  @observable public enabled = false;

  @action protected setEnabled = (enabled: boolean) => {
    this.enabled = enabled;
  };

  constructor(protected workspace: WorkspaceStore) {
    makeObservable(this);
  }

  @observable public isInit = false;

  @action protected setInit_ = (isInit: boolean) => {
    this.isInit = isInit;
  };

  trialDays: number = 7;

  @observable public availableTariffs: entities.ITariff[] = [];

  @observable public billingHistory: BillingHistoryItem[] = [];
  @observable public mentionedTariffs: entities.ITariff[] = [];
  @observable hasMoreHistory: boolean = false;

  @observable protected _currentTariff?: (entities.ITariff | null);

  @observable expiresAt?: (Long | null);

  @observable autoRenew: boolean = false;

  @observable paymentStatus: api.WorkspaceGetCurrentTariffResponse.PaymentStatus = api.WorkspaceGetCurrentTariffResponse.PaymentStatus.PAS_UNKNOWN;

  @computed get paymentError(): boolean {
    return this.paymentStatus === api.WorkspaceGetCurrentTariffResponse.PaymentStatus.PAS_FAILING ||
      this.paymentStatus === api.WorkspaceGetCurrentTariffResponse.PaymentStatus.PAS_FAILED;
  }

  @observable cardLastDigits?: (string | null) = null;
  @observable cardBrand?: (string | null) = null;
  @observable transactionId?: (string | null) = null;

  @computed get currentTariff(): entities.ITariff | null {
    if (this._currentTariff) {
      return this._currentTariff;
    }

    return null;
  }

  @computed get currentTariffIsFree(): boolean {
    return isFreeTariff(this.currentTariff);
  }

  @computed get currentTariffHasInfo(): boolean {
    if (this.currentTariffIsFree) {
      return !!(this.cardBrand || this.cardLastDigits);
    }
    return !!(this.cardBrand || this.cardLastDigits || this.expiresAt);
  }

  @computed get currentTariffExpired(): boolean {
    return !!this.expiresAt?.lessThan(new Date().getTime() / 1000);
  }

  @computed get basicTariff(): entities.ITariff | null {
    return this.availableTariffs.find((tariff) =>
      tariff.name && tariff.name.toLowerCase().indexOf('basic') >= 0
    ) || null;
  }

  @computed get topTariff(): entities.ITariff | null {
    return this.availableTariffs.length ? this.availableTariffs[this.availableTariffs.length - 1] : null;
  }

  @computed get recomendedTariff(): entities.ITariff | null {
    const tariff = this.availableTariffs.find((tariff) =>
      (tariff.includedUsers?.toNumber() || 0) >= this.workspace.membersCount && tariff.price
    ) || null;

    return tariff || this.topTariff;
  }

  @computed get trialEnabled(): boolean {
    return this.currentTariff?.id === TRIAL_TARIFF_ID;
  }

  @computed get noActiveSubscriptions(): boolean {
    return !(
      this.currentTariff ||
      this.trialEnabled ||
      this.paymentStatus === api.WorkspaceGetCurrentTariffResponse.PaymentStatus.PAS_FAILING ||
      this.paymentStatus === api.WorkspaceGetCurrentTariffResponse.PaymentStatus.PAS_FAILED
    );
  }

  private loadAvailableTariffsInProgress_ = false;

  public loadAvailableTariffs = async () => {
    if (this.loadAvailableTariffsInProgress_) {
      console.error(`BillingStore.loadAvailableTariffs already in progress`);
      return {error: {message: 'In process'}, res: null};
    }

    this.loadAvailableTariffsInProgress_ = true;
    const {res, error} = await this.workspace.workspaceRequest<api.IWorkspaceGetAvailableTariffsResponse>(
      {
        availableTariffs: new api.WorkspaceGetAvailableTariffsRequest({fetch: true}),
      },
      'availableTariffs',
      undefined,
      true,
    );
    this.loadAvailableTariffsInProgress_ = false;

    if (error?.message) {
      this.workspace.app.notifications.error(error.message);
    }

    if (res) {
      this.processAvailableTariffs_(res);
    }

    return {res, error};
  };

  @action protected processAvailableTariffs_ = (res?: api.IWorkspaceGetAvailableTariffsResponse | null) => {
    console.debug('%c $$$: availableTariffs', 'color: red', res?.tariffs);
    this.availableTariffs = res?.tariffs?.sort((tariff1, tariff2) => (tariff1.price || 0) - (tariff2.price || 0)) || [];
  };

  private tariffsIsInit_ = false;

  public initTariffs = async () => {
    if (this.tariffsIsInit_) {
      return;
    }

    await this.loadAvailableTariffs();

    if (this.availableTariffs.length) {
      this.tariffsIsInit_ = true;
    }
  };

  public findTariff = (id?: string | null): entities.ITariff | null => {
    return id ? this.availableTariffs.find((tariff) => compareTariffId(tariff, id)) || null : null;
  };


  @observable public loadBillingHistoryInProgress: boolean = false;

  @action protected setLoadBillingHistoryInProgress_ = (value: boolean) => {
    this.loadBillingHistoryInProgress = value;
  };

  protected loadHistory_ = async (startID?: string | null) => {
    if (!this.hasAccess) {
      console.error(`BillingStore.loadBillingHistory no access`);
      return {error: {message: 'No access'}, res: null};
    }

    if (this.loadBillingHistoryInProgress) {
      console.error(`BillingStore.loadBillingHistory already in progress`);
      return {error: {message: 'In process'}, res: null};
    }

    this.setLoadBillingHistoryInProgress_(true);
    const {res, error} = await this.workspace.workspaceRequest<api.IWorkspaceGetBillingHistoryResponse>(
      {
        billingHistory: new api.WorkspaceGetBillingHistoryRequest({
          fetch: true,
          startID,
          limit: startID ? BILLING_HISTORY_LIMIT : BILLING_HISTORY_INITIAL_LIMIT,
        }),
      },
      'billingHistory',
    );
    this.setLoadBillingHistoryInProgress_(false);

    return {res, error};
  };

  public loadHistory = async (startID?: string | null) => {
    if (!this.hasAccess) {
      console.error(`BillingStore.loadBillingHistory no access`);
      return {error: {message: 'No access'}, res: null};
    }

    if (this.loadBillingHistoryInProgress) {
      console.error(`BillingStore.loadBillingHistory already in progress`);
      return {error: {message: 'In process'}, res: null};
    }

    const {res, error} = await this.loadHistory_(startID);

    if (error?.message) {
      this.workspace.app.notifications.error(error.message);
    }

    if (startID) {
      this.processHistoryMore_(res);
    } else {
      this.processBillingHistory_(res);
    }

    return {res, error};
  };

  public loadHistoryMore = async () => {
    const startID = this.billingHistory[this.billingHistory.length - 1]?.stripeSessionID;
    return await this.loadHistory(startID);
  };

  @action protected processBillingHistory_ = (res?: api.IWorkspaceGetBillingHistoryResponse | null) => {
    console.debug('%c $$$: billingHistory', 'color: red', res?.entries);
    this.billingHistory = res?.entries?.map((entry) => {
      const tariff = res?.mentionedTariffs?.find((tariff) => tariff.id === entry.tariffID);
      return new BillingHistoryItem(entry, tariff);
    }) || [];
    this.mentionedTariffs = res?.mentionedTariffs || [];
    this.hasMoreHistory = res?.hasMore || false;
  };

  @action protected processHistoryMore_ = (res?: api.IWorkspaceGetBillingHistoryResponse | null) => {
    console.debug('%c $$$: billingHistory.processHistoryMore_', 'color: red', res?.entries);
    const nextPage = res?.entries?.map((entry) => {
      const tariff = res?.mentionedTariffs?.find((tariff) => tariff.id === entry.tariffID);
      return new BillingHistoryItem(entry, tariff);
    }) || [];

    this.billingHistory = [...this.billingHistory, ...nextPage];
    this.mentionedTariffs = [...this.mentionedTariffs, ...(res?.mentionedTariffs || [])];
    this.hasMoreHistory = res?.hasMore || false;
  };


  @observable public loadCurrentTariffInProgress: boolean = false;

  protected setLoadCurrentTariffInProgress_ = (value: boolean) => {
    this.loadCurrentTariffInProgress = value;
  };

  public loadCurrentTariff = async () => {
    if (!this.hasAccess) {
      console.error(`BillingStore.loadCurrentTariff no access`);
      return {error: {message: 'No access'}, res: null};
    }

    if (this.loadCurrentTariffInProgress) {
      console.error(`BillingStore.loadCurrentTariff already in progress`);
      return {error: {message: 'In process'}, res: null};
    }

    this.setLoadCurrentTariffInProgress_(true);
    const {res, error} = await this.workspace.workspaceRequest<api.IWorkspaceGetCurrentTariffResponse>(
      {
        currentTariff: new api.WorkspaceGetCurrentTariffRequest({fetch: true}),
      },
      'currentTariff',
    );
    this.setLoadCurrentTariffInProgress_(false);

    if (error?.message) {
      this.workspace.app.notifications.error(error.message);
    }

    this.processCurrentTariff_(res);

    return {res, error};
  };


  @action protected processCurrentTariff_ = (res?: api.IWorkspaceGetCurrentTariffResponse | null) => {
    console.debug('%c $$$: processCurrentTariff_', 'color: red', res);
    this._currentTariff = res?.tariff || null;
    this.expiresAt = res?.expiresAt;
    this.autoRenew = res?.autoRenew || false;
    this.paymentStatus = res?.paymentStatus || api.WorkspaceGetCurrentTariffResponse.PaymentStatus.PAS_UNKNOWN;
    this.cardLastDigits = res?.cardLastDigits || null;
    this.cardBrand = res?.cardBrand || null;
    this.transactionId = res?.transactionId || null;

    this.workspace.localStore.update();
  };

  @observable public payForTariffInProgress: string | null = null;

  @action protected setPayForTariffInProgress_ = (value?: string | null) => {
    this.payForTariffInProgress = value || null;
  };

  public payForTariff = async (tariffId?: string | null, blockMemberIDs?: Long[] | null) => {
    if (this.payForTariffInProgress) {
      console.error(`BillingStore.payForTariff already in progress`);
      return {error: {message: 'In process'}, res: null};
    }

    this.setPayForTariffInProgress_(tariffId);
    const {res, error} = await this.workspace.workspaceRequest<api.IWorkspacePayForTariffResponse>(
      {
        payForTariff: new api.WorkspacePayForTariffRequest({
          tariffId,
          blockMemberIDs,
        }),
      },
      'payForTariff',
    );
    this.setPayForTariffInProgress_('');

    const message = error?.message || getPayForTariffError(res?.status);
    const newError = message ? new ErrorEvent({message}) : error;

    if (message) {
      this.workspace.app.notifications.error(message);
    }

    if (res?.paymentLink) {
      navigateToExternalUrl(res.paymentLink);
    }

    if (res?.status === api.WorkspacePayForTariffResponse.Status.WPT_OK) {
      browserStorage.local(PREFERABLE_TARIFF_ID, null);

      this.refreshStateAfterPay_(tariffId, TARIFF_REFRESH_ATTEMPTS);
    }

    return {res, error: newError};
  };

  protected refreshStateAfterPay_ = async (tariffId?: string | null, retry: number = 0) => {
    const {res} = await this.workspace.workspaceRequest<api.IWorkspaceGetCurrentTariffResponse>(
      {
        currentTariff: new api.WorkspaceGetCurrentTariffRequest({fetch: true}),
      },
      'currentTariff',
    );

    if (retry && res?.tariff?.id !== tariffId) {
      await wait(1000);
      this.refreshStateAfterPay_(tariffId, retry - 1);
      return;
    }

    if (res?.tariff?.id === tariffId) {
      this.processCurrentTariff_(res);
    }

    await this.loadHistory();
    await this.workspace.app.workspaces.refreshWorkspaces();
    await this.workspace.refreshMembers();
  };

  private renewSubscriptionInProgress = false;

  public resumeCurrentTariff = async () => {
    if (this.renewSubscriptionInProgress) {
      console.error(`BillingStore.resumeCurrentTariff already in progress`);
      return {error: {message: 'In process'}, res: null};
    }

    this.renewSubscriptionInProgress = true;
    const {res, error} = await this.workspace.workspaceRequest<api.IWorkspaceResumeCurrentTariffResponse>(
      {
        resumeCurrentTariff: new api.WorkspaceResumeCurrentTariff({fetch: true}),
      },
      'resumeCurrentTariff',
    );
    this.renewSubscriptionInProgress = false;


    const message = error?.message || getResumeCurrentTariffError(res?.status);

    if (message) {
      this.workspace.app.notifications.error(message);
    }

    if (res?.status === api.WorkspaceResumeCurrentTariffResponse.Status.WRT_OK) {
      this.loadCurrentTariff();
      this.workspace.app.workspaces.refreshWorkspaces();
      this.workspace.refreshMembers();
    }

    return {res, error};
  };

  private refreshInProgress = false;

  public refresh = async () => {
    if (this.refreshInProgress) {
      console.error(`BillingStore.refresh already in progress`);
      return;
    }

    this.refreshInProgress = true;

    await this.loadCurrentTariff();
    await this.loadAvailableTariffs();
    this.setInit_(true);
    await this.loadHistory();

    this.refreshInProgress = false;
  };

  public init = async () => {
    if (this.isInit) {
      return;
    }
    await this.refresh();
  };


  @observable public openCustomerPortalInProgress = false;

  @action protected setOpenCustomerPortalInProgress_ = (value: boolean) => {
    this.openCustomerPortalInProgress = value;
  };

  public openCustomerPortal = async () => {
    if (this.openCustomerPortalInProgress) {
      console.error(`BillingStore.getCustomerPortal already in progress`);
      return {error: {message: 'In process'}, res: null};
    }

    this.setOpenCustomerPortalInProgress_(true);
    const {res, error} = await this.workspace.workspaceRequest<api.IWorkspaceGetCustomerPortalResponse>(
      {
        getCustomerPortal: new api.WorkspaceGetCustomerPortalRequest({fetch: true}),
      },
      'getCustomerPortal',
    );
    this.setOpenCustomerPortalInProgress_(false);


    const message = error?.message || getCustomerPortalError(res?.status);

    if (message) {
      this.workspace.app.notifications.error(message);
    }

    if (res?.link) {
      navigateToExternalUrl(res.link);
    }

    return {res, error};
  };


  @observable public cancelSubscriptionInProgress = false;

  @action protected setCancelSubscriptionInProgress_ = (value: boolean) => {
    this.cancelSubscriptionInProgress = value;
  };

  public cancelSubscription = async () => {
    if (this.cancelSubscriptionInProgress) {
      console.error(`BillingStore.cancelSubscription already in progress`);
      return {error: {message: 'In process'}, res: null};
    }

    this.setCancelSubscriptionInProgress_(true);
    const {res, error} = await this.workspace.workspaceRequest<api.IWorkspaceCancelCurrentTariffResponse>(
      {
        cancelCurrentTariff: new api.WorkspaceCancelCurrentTariff({fetch: true}),
      },
      'cancelCurrentTariff',
    );
    this.setCancelSubscriptionInProgress_(false);

    const message = error?.message || getCancelCurrentTariffError(res?.status);

    if (message) {
      this.workspace.app.notifications.error(message);
    }

    if (res?.status === api.WorkspaceCancelCurrentTariffResponse.Status.WCT_OK) {
      this.loadCurrentTariff();
      this.workspace.app.workspaces.refreshWorkspaces();
    }

    return {res, error};
  };
}

export default BillingStore;