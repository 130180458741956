
export default function parseMetaInfo(metaStr?: string | null): string[] {
  if (!metaStr) {
    return [];
  }
  let metaData: string[] = [];
  try {
    const metaDataObj = JSON.parse(metaStr);
    if (Array.isArray(metaDataObj)) {
      metaData = metaDataObj.map((o) =>
        typeof o === 'string'
          ? o
          : Object.keys(o).reduce((prevVal, current) => prevVal + `${current}: ${JSON.stringify(o[current])}; `, ''),
      );
    } else if (typeof metaDataObj === 'object') {
      metaData = Object.entries(metaDataObj).map((en) => `${en[0]}: ${en[1]}`);
    }
  } catch (e) {
    console.warn(e);
    metaData = [metaStr];
  }
  return metaData;
}
