import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import Avatar from 'o-ui/Avatar';
import Button from 'o-ui/Button';
import {useStore} from '../../../../stores/AppStore';
import Chat from '../../../../stores/Chat';

interface ChatAssignControlsProps {
  chat: Chat;
  className?: string | null;
}

export const ChatAssignControls = observer((props: ChatAssignControlsProps) => {
  const {t} = useTranslation();
  const {userStore} = useStore();
  const assignment = props.chat.assignment;

  const handleJoinClick = () => {
    assignment?.joinChat();
  };

  const handleLeaveClick = () => {
    assignment?.leaveChat();
  };
  /*
  const handleInviteClick = () => {
    modals.open(ModalType.OPERATOR_INVITATION_TO_CHAT, {chat: props.chat});
  };
  */
  const handleResolveClick = () => {
    assignment?.resolveChat();
  };

  React.useEffect(() => {
    props.chat.store?.editor?.focus();
  }, [props.chat.store?.editor, props.chat.store?.editor?.quill]);

  return (
    <div className={cn('chat-assign-controls', props.className)}>
      {assignment?.resolvedBy ? (
        <>
          <div className="chat-assign-controls__avatars-wrapper">
            <span className="chat-assign-controls__avatar chat-assign-controls__avatar--resolved tg-icon-check-s-line" />
            <Avatar size="xs" className="chat-assign-controls__avatar" name={assignment?.resolvedBy.fullName} />
          </div>
          <span className="chat-assign-controls__status-title mr-auto">
            <strong>{t('chat_user_chat_assign_panel_resolved_by', {name: assignment?.resolvedBy.fullName})}</strong>
          </span>

          {assignment?.youAreJoined ? (
            <Button
              className="chat-assign-controls__btn ml-auto"
              variant="contained"
              color="gray"
              size="small"
              onClick={handleLeaveClick}
              loading={assignment?.requestLoading}
            >
              <span className="btn-icon tg-icon-leave-line mr-2" />
              {t('chat_user_chat_assign_panel_leave_chat_btn')}
            </Button>
          ) : (
            <Button
              className="chat-assign-controls__btn ml-auto"
              variant="contained"
              color="success"
              size="small"
              onClick={handleJoinClick}
              loading={assignment?.requestLoading}
            >
              <span className="btn-icon tg-icon-join-line mr-2" />
              {t('chat_user_chat_assign_panel_join_chat_btn')}
            </Button>
          )}

          {/*settings.systemUser?.isOwner || settings.systemUser?.isAdmin ? (
            <Button
              className="chat-assign-controls__btn ml-2"
              variant="contained"
              color={assignment?.youAreJoined ? 'gray' : 'primary'}
              size="small"
              onClick={handleInviteClick}
            >
              <span className="btn-icon tg-icon-add-s-line mr-2" />
              {t('Invite operator')}
            </Button>
          ) : null*/}
        </>
      ) : assignment?.youAreJoined ? (
        <>
          <div className="chat-assign-controls__avatars-wrapper">
            {assignment?.joinedOperators.map((o, i) => (
              <Avatar
                key={`${o.id?.toString()}-${i}`}
                size="xs"
                className="chat-assign-controls__avatar"
                name={o.fullName}
              />
            ))}
          </div>
          <span className="chat-assign-controls__status-title mr-auto">
            {assignment?.otherJoinedOperators.length ? (
              <Trans>
                <strong>You</strong> and{' '}
                <strong>{assignment?.otherJoinedOperators.map((o) => o.fullName).join(', ')}</strong> are joined
              </Trans>
            ) : (
              <Trans>
                <strong>You</strong> have joined
              </Trans>
            )}
          </span>

          <Button
            className="chat-assign-controls__btn ml-auto"
            variant="contained"
            color="gray"
            size="small"
            onClick={handleLeaveClick}
            loading={assignment?.requestLoading}
          >
            <span className="btn-icon tg-icon-leave-line mr-2" />
            {t('chat_user_chat_assign_panel_leave_chat_btn')}
          </Button>

          {/*settings.systemUser?.isOwner || settings.systemUser?.isAdmin ? (
            <Button
              className="chat-assign-controls__btn ml-2"
              variant="contained"
              color="gray"
              size="small"
              onClick={handleInviteClick}
            >
              <span className="btn-icon tg-icon-add-s-line mr-2" />
              {t('Invite operator')}
            </Button>
          ) : null*/}

          <Button
            className="chat-assign-controls__btn ml-2"
            variant="contained"
            color="gray"
            size="small"
            onClick={handleResolveClick}
            loading={assignment?.requestLoading}
          >
            <span className="btn-icon tg-icon-check-s-line mr-2" />
            {t('chat_user_chat_assign_panel_join_mark_as_resolve_btn')}
          </Button>
        </>
      ) : props.chat.joinedOperators?.length ? (
        <>
          <div className="chat-assign-controls__avatars-wrapper">
            {assignment?.joinedOperators.map((o) => (
              <Avatar key={o.id?.toString()} size="xs" className="chat-assign-controls__avatar" name={o.fullName} />
            ))}
          </div>
          <span className="chat-assign-controls__status-title mr-auto">
            <Trans>
              <strong>{assignment?.joinedOperators.map((o) => o.fullName).join(', ')}</strong> are joined
            </Trans>
          </span>

          <Button
            className="chat-assign-controls__btn ml-auto"
            variant="contained"
            color="success"
            size="small"
            onClick={handleJoinClick}
            loading={assignment?.requestLoading}
          >
            <span className="btn-icon tg-icon-join-line mr-2" />
            {t('chat_user_chat_assign_panel_join_chat_btn')}
          </Button>

          {/*settings.systemUser?.isOwner || settings.systemUser?.isAdmin ? (
            <Button
              className="chat-assign-controls__btn ml-2"
              variant="contained"
              color="primary"
              size="small"
              onClick={handleInviteClick}
            >
              <span className="btn-icon tg-icon-add-s-line mr-2" />
              {t('Invite operator')}
            </Button>
          ) : null*/}
        </>
      ) : userStore.isOwner || userStore.isAdmin ? (
        <>
          <Button
            className="chat-assign-controls__btn mx-auto"
            variant="contained"
            color="success"
            size="small"
            onClick={handleJoinClick}
            loading={assignment?.requestLoading}
          >
            <span className="btn-icon tg-icon-join-line mr-2" />
            {t('chat_user_chat_assign_panel_join_to_the_chat_btn')}
          </Button>

          {/*
          <Button
            className="chat-assign-controls__btn mr-auto ml-2"
            variant="contained"
            color="primary"
            size="small"
            onClick={handleInviteClick}
          >
            <span className="btn-icon tg-icon-add-s-line mr-2" />
            {t('Invite operator')}
          </Button>
          */}
        </>
      ) : (
        <Button
          className="chat-assign-controls__btn mx-auto"
          variant="contained"
          color="success"
          size="small"
          onClick={handleJoinClick}
          loading={assignment?.requestLoading}
        >
          <span className="btn-icon tg-icon-join-line mr-2" />
          {t('chat_user_chat_assign_panel_join_to_the_chat_btn')}
        </Button>
      )}
    </div>
  );
});

export default ChatAssignControls;
