import {MCMethodGetChatsPreview} from '../../../api/proto';
import RawChatsStoreMode from '../RawChatsStoreMode';
import formatChannelIdsKey from './formatChannelIdsKey';

export default function formatStoreKey(
  channelIDs: Uint8Array[],
  mode?: RawChatsStoreMode,
  status?: MCMethodGetChatsPreview.MCChatStatus,
): string {
  const statusName = status ? MCMethodGetChatsPreview.MCChatStatus[status]?.toLowerCase().replace(/_|s_/g, '') : '';

  return (
    (mode ? `${mode}_` : '') +
    (statusName ? (statusName + '_') : '') +
    formatChannelIdsKey(channelIDs)
  );
}