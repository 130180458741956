export default function convertHeightToNewWidth(
  newWidth: number = 0,
  prevWidth: number = 0,
  prevHeight: number = 0,
) {
  if (!prevWidth || !prevHeight) {
    return [undefined, undefined];
  }
  const ratio = newWidth / prevWidth;

  const newHeight = Math.floor(prevHeight * ratio);

  return [newWidth, newHeight];
}