import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';

import IconButton from 'o-ui/Button/IconButton';
import useContextMenuHandler from '../../../../../hooks/useContextMenuHandler';
import {useStore} from '../../../../../stores/AppStore';
import Chat from '../../../../../stores/Chat';
import {ContextMenuType} from '../../../../../stores/ContextMenuType';
import {uint8ArrayToBase64} from '../../../../../utils/arrayUtils';
import {getDateOrTime} from '../../../../../utils/format';
import AvatarWrapper from '../../../AvatarWrapper';
import ChatChannelTypeIcon from './ChatChannelTypeIcon';
import ChatDraftMessage from './ChatDraftMessage';
import ChatItemTypingIcon from './ChatItemTypingIcon';
import ChatItemUnreadCounter from './ChatItemUnreadCounter';
import ChatLastMessageFileIcon from './ChatLastMessageFileIcon';
import ChatLastMessageReadStatus from './ChatLastMessageReadStatus';
import ChatLastMessageText from './ChatLastMessageText';
import ChatLastMessageUser from './ChatLastMessageUser';
import ChatTypeIcon from './ChatTypeIcon';


interface ChatItemProps {
  className?: string;
  index?: number;
  chat: Chat;
  style?: React.CSSProperties;
  isSearchItem?: boolean;
  onOpen?: (e: React.MouseEvent, chat: Chat) => void;
  onClose?: (chat: Chat) => void;
}

export const ChatItem: React.FC<ChatItemProps> = observer((props) => {
  const app = useStore();
  const {chat, className} = props;

  const openUserChat = (e: React.MouseEvent) => {
    if (chat.store?.active && !chat.store?.foundMessageId) {
      return;
    }

    e.preventDefault();
    props.onOpen?.(e, chat);
  };

  const {...contextMenuHandlers} = useContextMenuHandler((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    app.contextMenu.open({clientX: e.clientX, clientY: e.clientY}, ContextMenuType.CHAT_MENU, {chat});
  });

  const onCloseChat = (e: React.MouseEvent) => {
    e.preventDefault();
    props.onClose?.(chat);
  };

  return (
    <div
      style={props.style}
      data-id={chat.id.toString()}
      data-channel-id={uint8ArrayToBase64(chat.channelID)}
      data-in-read-pointer={chat.inReadPointer?.toString()}
      data-out-read-pointer={chat.outReadPointer?.toString()}
      data-last-chat-message-id={chat.lastChatMessageId?.toString()}
      className={cn('chat-item', className, {
        unread: chat.unreadCount,
        typing: chat.isTyping,
        active: chat.store?.active && !chat.store?.foundMessageId,
        resolved: chat.resolved,
        joined: chat.assignment.joinedOperators.length,
        'joined-other': chat.assignment.otherJoinedOperators.length,
        'joined-you': chat.assignment.youAreJoined,
      })}
      {...contextMenuHandlers}
    >
      <div onClick={openUserChat} className="chat-item__body">
        {/*
        <Avatar
          className="chat-item__avatar"
          src={chat.image?.url}
          name={chat.displayTitle}
          status={props.isSearchItem ? null : chat.store?.chatUser?.statusString}
        />
        */}
        <AvatarWrapper
          className="chat-item__avatar"
          attachment={chat?.avatar}
          channelID={chat?.channelID}
          name={chat.displayTitle}
        />
        <div className="chat-item__data">
          <div className="chat-item__head">
            <ChatTypeIcon className="chat-item__chat-type-icon" chatType={chat.type} />

            <span className="chat-item__title">{chat.displayTitle}</span>
            <ChatChannelTypeIcon className="chat-item__source-icon" type={chat.channel?.type} />

            <span className="chat-item__head-right">
              {chat.isMuted ? (
                <i className="chat-item__notification-icon tg-icon tg-icon-mute-fill" />
              ) : null}

              {/*chat.pinned ? <i className="chat-item__pin-icon tg-icon tg-icon-pin-fill" /> : null*/}

              <span className="chat-item__last-message-time">
                {chat.lastChatMessagePreview?.stamp ? getDateOrTime(chat.lastChatMessagePreview?.stamp) : null}
              </span>
            </span>
          </div>

          <div
            className={cn('chat-item__last-message d-flex mb-0', {
              [`chat-item__last-message--${chat.lastChatMessage?.firstAttachment?.type}`]: chat.lastChatMessage?.firstAttachment,
            })}
          >
            {chat.draft ? (
              <ChatDraftMessage className="chat-item__last-message-text" draftMessage={chat.draft} />
            ) : (
              <>
                {chat.isTyping ? (
                  <ChatItemTypingIcon />
                ) : (
                  <>
                    <ChatLastMessageUser
                      className="chat-item__last-message-user pr-2"
                      lastChatMessage={chat.lastChatMessage}
                    />

                    <ChatLastMessageFileIcon
                      className="chat-item__last-message-file-icon mr-2"
                      lastChatMessage={chat.lastChatMessage}
                    />

                    <ChatLastMessageText
                      className="chat-item__last-message-text"
                      lastChatMessage={chat.lastChatMessage}
                    />
                  </>
                )}
              </>
            )}
            <div className="d-inline-flex ml-auto">
              {chat.draft ? null : (
                <ChatLastMessageReadStatus
                  className="chat-item__last-message-read-status mr-2"
                  lastChatMessage={chat.lastChatMessage}
                />
              )}

              <ChatItemUnreadCounter className="chat-item__unread-counter" chat={chat} />
            </div>
          </div>
        </div>
        <ChatItemUnreadCounter className="chat-item__unread-counter chat-item__unread-counter--collapsed" chat={chat} />
        {props.onClose ? (
          <IconButton className="chat-item__close-btn tg-icon-cross-2-line" onClick={onCloseChat} size="extraSmall" />
        ) : null}
      </div>
    </div>
  );
});

export default ChatItem;
