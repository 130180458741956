import React from 'react';
import cn from 'classnames';

import {MCChatPreview} from '../../../../../api/proto';

interface ChatTypeIconProps {
  className?: string;
  chatType?: MCChatPreview.Type | null;
}

export const ChatTypeIcon = (props: ChatTypeIconProps) => {
  switch (props.chatType) {
    case MCChatPreview.Type.GROUP:
      return <i className={cn('tg-icon tg-icon-group-fill', props.className)} />;
    case MCChatPreview.Type.CHANNEL:
      return <i className={cn('tg-icon tg-icon-channel-fill', props.className)} />;
    case MCChatPreview.Type.SUPERGROUP:
      return <i className={cn('tg-icon tg-icon-group-2-line', props.className)} />;
  }
  return null;
};

export default ChatTypeIcon;
