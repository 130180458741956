import React from 'react';
import {observer} from 'mobx-react';

import WindowControls from './WindowControls';
import WindowTitleBar from './WindowTitleBar';
//import WindowTitleIcon from './WindowTitleIcon';
//import WindowTitleText from './WindowTitleText';
import {useStore} from '../../stores/AppStore';

const WindowTitleBarArea = observer(() => {
  const {externalApp, externalApiCallHandler} = useStore();
  if (!externalApp.customTitleBar) {
    return null;
  }

  return (
    <WindowTitleBar>
      {/*
      <WindowTitleIcon />
      <WindowTitleText>{BRAND_NAME}</WindowTitleText>
      */}
      <WindowControls
        onMinimize={externalApiCallHandler.minimizeWindow}
        onMaximalize={externalApiCallHandler.toggleMaximizeWindow}
        onClose={externalApiCallHandler.closeWindow}
        isMaximized={externalApp.isMaximized}
      />
    </WindowTitleBar>
  );
});

export default WindowTitleBarArea;
