import cn from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import {BRAND_NAME} from '../../../config';
import downloadFile from '../../../utils/downloadFile';
import {AppPlatform} from './utils';

import {ReactComponent as LinuxIcon} from '../../../assets/image-icons/linuxOsIcon.svg';
import {ReactComponent as MacOsIcon} from '../../../assets/image-icons/macOsIcon.svg';
import {ReactComponent as WindowsIcon} from '../../../assets/image-icons/windowsOsIcon.svg';

interface DownloadAppOSButtonProps {
  className?: string;
  platform: AppPlatform;
  downloadUrl?: string;
  version?: string;
  disabled?: boolean;
}

type SystemData = {
  name: string;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};

const DownloadAppOSButton: React.FC<DownloadAppOSButtonProps> = ({
  platform,
  className,
  disabled,
  downloadUrl,
  version,
}) => {
  const {t} = useTranslation();
  const getSystemData = (): SystemData => {
    switch (platform) {
      case AppPlatform.WINDOWS:
        return {
          name: 'Windows',
          Icon: WindowsIcon,
        };
      case AppPlatform.MAC:
        return {
          name: 'MacOS',
          Icon: MacOsIcon,
        };
      case AppPlatform.LINUX:
        return {
          name: 'Linux',
          Icon: LinuxIcon,
        };
    }
  };

  const handleClick = () => {
    downloadFile({url: downloadUrl});
  };

  const {name: systemName, Icon} = getSystemData();

  return (
    <div className={cn('download-app-os', className)}>
      <div className="download-app-os__title">{BRAND_NAME} App for {systemName}</div>

      <Button
        variant="contained"
        color="primary"
        className="download-app-os__btn px-5"
        onClick={handleClick}
        disabled={disabled}
      >
        <Icon className="mr-3" />
        {t('settings_download_app_os_button', {systemName})}
      </Button>

      {!disabled && version ? (
        <div className="download-app-os__version">{t('settings_download_app_os_version', {value: version})}</div>
      ) : null}
    </div>
  );
};

export default DownloadAppOSButton;
