import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import FormHelperText from 'o-ui/FormHelperText';
// import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import {useStore} from '../../stores/AppStore';

interface ApprovePrivateRegistrationFormProps {
  className?: string;
  code: string;
}

export const ApprovePrivateRegistrationForm = observer((props: ApprovePrivateRegistrationFormProps) => {
  const {
    userStore,
    // notifications
  } = useStore();
  const {t} = useTranslation();

  const [
    approved,
    // setApproved
  ] = React.useState<boolean>(false);
  const [
    tokenInfo,
    // setTokenInfo
  ] = React.useState(null);
  const [apiError, setApiError] = React.useState<string | null | undefined>('');

  const getTokenInfo = React.useCallback(
    () => {
      // const {error, res} = await authStore.getInviteTokenInfo(props.code);
      // if (res?.inviterEmail) {
      //   setTokenInfo(res);
      // }
      // if (error) {
      //   setApiError(error.message);
      // }
    },
    [
      // authStore, props.code
    ],
  );

  React.useEffect(() => {
    if (!tokenInfo && userStore.api.connectionIsOpen) {
      getTokenInfo();
    }
  }, [tokenInfo, getTokenInfo, userStore.api.connectionIsOpen]);

  const handleApproveClick = () => {
    setApiError('');
    // const {error, res} = await authStore.approvePrivateRegistration(props.code);

    // if (res) {
    //   setApproved(true);
    //   notifications.success(t('User successfully approved!'));
    // }

    // if (error) {
    //   setApiError(error.message);
    // }
  };

  return (
    <div className={cn('auth-page__form', props.className)}>
      <div className="body3-regular color-body-tertiary mt-5 mb-2">{t('approve_private_registration_email')}</div>
      {/* <OutlinedTextInput className="w-100" value={tokenInfo?.inviterEmail || ''} disabled={true} /> */}

      <div className="body3-regular color-body-tertiary mt-5 mb-2">{t('approve_private_registration_first_name')}</div>
      {/* <OutlinedTextInput className="w-100" value={tokenInfo?.inviterFirstName || ''} disabled={true} /> */}

      <div className="body3-regular color-body-tertiary mt-5 mb-2">{t('approve_private_registration_last_name')}</div>
      {/* <OutlinedTextInput className="w-100" value={tokenInfo?.inviterLastName || ''} disabled={true} /> */}

      <Button
        className="w-100 mt-5"
        variant="contained"
        color="primary"
        size="large"
        loading={userStore.loading}
        disabled={approved}
        onClick={handleApproveClick}
      >
        {approved ? t('approve_private_registration_approved') : t('approve_private_registration_approve')}
      </Button>

      <FormHelperText className="mt-3" error>
        {apiError || ' '}
      </FormHelperText>
    </div>
  );
});

export default ApprovePrivateRegistrationForm;
