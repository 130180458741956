import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Invite from '../../../../stores/Workspaces/Invite';
import {formatDateTime, getFormattedDuration} from '../../../../utils/format';

interface InviteLinkStatusProps {
  inviteLink: Invite;
  className?: string;
}

export const InviteLinkStatus: React.FC<InviteLinkStatusProps> = observer(({inviteLink, className}) => {
  const {t} = useTranslation();

  if (inviteLink.activated) {
    return (
      <div className={cn('d-flex flex-column', className)}>
        <span className="body2-regular color-body-primary text-lowercase">
          {inviteLink.activator?.email
            ? t('settings_workspace_members_invite_link_status_registered_at', {email: inviteLink.activator?.email})
            : null}
        </span>
        <div className="body3-regular color-gray-500">
          {inviteLink.activator?.activatedAt
            ? t('settings_workspace_members_invite_link_status_registered_on', {
                time: formatDateTime(inviteLink.activator?.activatedAt, true),
              })
            : null}
        </div>
        <div className="body3-regular color-gray-500">
          {t('settings_workspace_members_invite_link_status_created_by', {email: inviteLink.inviter?.email})}
        </div>
      </div>
    );
  }

  if (inviteLink.invited) {
    return (
      <div className={cn('d-flex flex-column', className)}>
        <div className="body2-regular color-body-primary text-lowercase">
          {t('settings_workspace_members_invite_link_status_created_by', {email: inviteLink.inviter?.email})}
        </div>
        <div className="body3-regular color-gray-500">
          {t('settings_workspace_members_invite_link_status_expires_on', {
            time: formatDateTime(inviteLink.expireAt, true),
          })}
        </div>
      </div>
    );
  }

  if (inviteLink.expired) {
    return (
      <div className={cn('d-flex flex-column', className)}>
        <div className="body2-regular color-body-primary text-lowercase">
          {t('settings_workspace_members_invite_link_status_expired_ago', {
            time: getFormattedDuration(inviteLink.expireAt),
          })}
        </div>
        <div className="body3-regular color-gray-500">
          {t('settings_workspace_members_invite_link_status_expired_on', {
            time: formatDateTime(inviteLink.expireAt, true),
          })}
        </div>
        <div className="body3-regular color-gray-500">
          {t('settings_workspace_members_invite_link_status_created_by', {email: inviteLink.inviter?.email})}
        </div>
      </div>
    );
  }

  return null;
});

export default InviteLinkStatus;
