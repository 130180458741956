import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useStore} from '../../../../stores/AppStore';
import WorkspaceMember from '../../../../stores/Workspaces/WorkspaceMember';
import MemberExtraInfo from './MemberExtraInfo';
import MemberProfileInfo from './MemberProfileInfo';
import ProfileSidebar from './ProfileSidebar';

interface MemberInfoBarProps {
  member: WorkspaceMember;
}

export const MemberInfoBar: React.FC<MemberInfoBarProps> = observer((props) => {
  const {layOutStore} = useStore();
  const {t} = useTranslation();

  const closeChatSidebar = () => {
    layOutStore.rightSideBar.setMember(null);
  };

  return (
    <ProfileSidebar title={t('chat_user_chat_member_info_title')} onClose={closeChatSidebar}>
      <MemberProfileInfo className="profile-sidebar__profile-info" member={props.member} />

      <MemberExtraInfo className="profile-sidebar__extra-info" member={props.member} />
    </ProfileSidebar>
  );
});

export default MemberInfoBar;
