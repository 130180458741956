import React from 'react';
import {useTranslation} from 'react-i18next';

import Tab from 'o-ui/Tab';
import Tabs from 'o-ui/Tabs';

import {MembersAdministrationTabs, OperatorStatus, OperatorType} from '../WorkspaceMembersTab';
import InviteLinksViewer from './InviteLinks/InviteLinksViewer';
import MembersViewer from './MembersTable/MembersViewer';
import WorkspaceMember from '../../../stores/Workspaces/WorkspaceMember';

interface MembersAdministrationProps {
  onOpenOperatorManage(workspaceMember: WorkspaceMember): void;
  selectedTab: MembersAdministrationTabs;
  onChangeTab(newTab: MembersAdministrationTabs): void;
  selectedOperatorTypeTab: OperatorType;
  selectedOperatorStatusTab: OperatorStatus;
  onChangeOperatorTypeTab(newTab: OperatorType): void;
  onChangeOperatorStatusTab(newTab: OperatorStatus): void;
}

export const MembersAdministration: React.FC<MembersAdministrationProps> = (props) => {
  const {t} = useTranslation();

  const handleChangeTab = (e: React.ChangeEvent, newValue: number) => {
    props.onChangeTab(newValue);
  };

  const getTabContent = (selectedTab: MembersAdministrationTabs) => {
    switch (selectedTab) {
      case MembersAdministrationTabs.Members:
        return (
          <MembersViewer
            selectedOperatorTypeTab={props.selectedOperatorTypeTab}
            selectedOperatorStatusTab={props.selectedOperatorStatusTab}
            onChangeOperatorTypeTab={props.onChangeOperatorTypeTab}
            onChangeOperatorStatusTab={props.onChangeOperatorStatusTab}
            onOperatorClick={props.onOpenOperatorManage}
          />
        );
      case MembersAdministrationTabs.InviteLinks:
        return <InviteLinksViewer />;
    }
  };

  return (
    <div className="settings-tab__content">
      <div className="settings-tab__paper px-0 d-flex flex-column h-100">
        <div className="flex-grow-1 d-flex flex-column px-6">
          <Tabs className="border-bottom" value={props.selectedTab} onChange={handleChangeTab}>
            <Tab label={<span className="d-flex align-items-center">{t('settings_workspace_members_tab')}</span>} />
            <Tab label={<span className="d-flex align-items-center">{t('settings_workspace_members_invite_links_tab')}</span>} />
          </Tabs>
        </div>

        <div className="flex-grow-1 d-flex flex-column h-100 overflow-auto">{getTabContent(props.selectedTab)}</div>
      </div>
    </div>
  );
};

export default MembersAdministration;
