import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import uaParser from 'ua-parser-js';

//import UserPagesHistory from './UserPagesHistory';
import {useStore} from '../../../../stores/AppStore';
import {ModalType} from '../../../../stores/ModalType';
import Peer from '../../../../stores/Peer';
import parseMetaInfo from '../../../../stores/Peer/utils/parseMetaInfo';


const isAdditionalInfoExist = (peer: Peer) => {
  // if (peer.userAgent || peer.chat?.store?.messagesUrls?.length || peer.userDebugInfo || peer.metadata) {
  if (peer.metaInfo?.userAgent || peer.metaInfo?.userDebugInfo || peer.metaData) {
    return true;
  }
  return false;
};

interface PeerPagesHistoryProps {
  peer: Peer;
}

export const PeerExtraInfoAdditional = observer((props: PeerPagesHistoryProps) => {
  const {t} = useTranslation();
  const {modals} = useStore();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  if (!isAdditionalInfoExist(props.peer)) return null;

  const userAgentInfo = props.peer.metaInfo?.userAgent ? uaParser(props.peer.metaInfo?.userAgent) : null;

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const debugInfoList = (
    <>
      {parseMetaInfo(props.peer.metaInfo?.userDebugInfo).map((item, i) => (
        <div key={item + i}>{item}</div>
      ))}
    </>
  );

  const handleDebugInfoClick = () => {
    modals.open(ModalType.INFO, {
      headerText: t('Debug info'),
      content: debugInfoList,
    });
  };

  return (
    <div className="extra-info-additional">
      <div className="extra-info-additional__head" onClick={handleToggle} style={{height: expanded ? 0 : 'auto'}}>
        <i className="extra-info-additional__icon-chevron tg-icon-chevron-down-s-line" />
        <span className="extra-info-additional__name">{t('More')}</span>
      </div>
      <div className="extra-info-additional__body" style={{height: expanded ? 'auto' : 0}}>
        <table className="chat-extra-info__table">
          <tbody>
            {userAgentInfo ? (
              <tr>
                <td>
                  <i className="chat-extra-info__icon tg-icon-device-fill" />
                </td>
                <td>
                  <span className="chat-extra-info__name">{t('Device')}</span>
                </td>
                <td>
                  <span
                    className="chat-extra-info__value"
                    title={props.peer.metaInfo?.userAgent || ''}
                  >{`${userAgentInfo.os.name} ${userAgentInfo.os.version}`}</span>
                </td>
              </tr>
            ) : null}
            {userAgentInfo ? (
              <tr>
                <td>
                  <i className="chat-extra-info__icon tg-icon-browser-fill" />
                </td>
                <td>
                  <span className="chat-extra-info__name">{t('Browser')}</span>
                </td>
                <td>
                  <span
                    className="chat-extra-info__value"
                    title={props.peer.metaInfo?.userAgent || ''}
                  >{`${userAgentInfo.browser.name} ${userAgentInfo.browser.version}`}</span>
                </td>
              </tr>
            ) : null}
            {/*props.peer.chat?.store?.messagesUrls?.length ? (
              <tr>
                <td>
                  <i className="chat-extra-info__icon tg-icon-from-page-line" />
                </td>
                <td>
                  <span className="chat-extra-info__name">{t('Current URL')}</span>
                </td>
                <td>
                  <a
                    className="chat-extra-info__value"
                    href={props.peer.chat?.store?.messagesUrls[0]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.peer.chat?.store?.messagesUrls[0]}
                  </a>
                </td>
              </tr>
            ) : null*/}
            {props.peer.metaInfo?.userDebugInfo ? (
              <tr>
                <td>
                  <i className="chat-extra-info__icon tg-icon-info-fill" />
                </td>
                <td>
                  <span className="chat-extra-info__name">{t('Debug info')}</span>
                </td>
                <td>
                  <div
                    className="chat-extra-info__value"
                    title={props.peer.metaInfo?.userDebugInfo || ''}
                    style={{
                      maxHeight: '30px',
                      overflow: 'hidden',
                      flexDirection: 'column',
                    }}
                    onClick={handleDebugInfoClick}
                  >
                    {debugInfoList}
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
        {/*props.user.chat?.store?.messagesUrls && props.user.chat?.store?.messagesUrls?.length > 1 ? (
          <UserPagesHistory user={props.user} />
        ) : null*/}
        {props.peer.metaData.length ? (
          <>
            <div className="chat-extra-info__description-title">
              <i className="chat-extra-info__description-icon tg-icon tg-icon-info-fill" /> {t('Additional info')}
            </div>
            <div className="chat-extra-info__description">
              {props.peer.metaData.map((item, i) => (
                <div key={item + i} className="chat-extra-info__description-item">
                  {item}
                </div>
              ))}
            </div>
          </>
        ) : null}
        <div className="extra-info-additional__head" onClick={handleToggle} style={{height: expanded ? 'auto' : 0}}>
          <i className="extra-info-additional__icon-chevron tg-icon-chevron-up-s-line" />
          <span className="extra-info-additional__name">{t('Less')}</span>
        </div>
      </div>
    </div>
  );
});

export default PeerExtraInfoAdditional;
