import {entities} from '../../../api/proto';
import i18n from '../../../i18n';

export default function getBillingPeriodTitle(billingPeriod?: entities.BillingPeriod | null): string | null {
  switch (billingPeriod) {
    case entities.BillingPeriod.MONTHLY:
      return i18n.t('settings_billing_tariff_period_monthly');
    case entities.BillingPeriod.ANNUAL:
      return i18n.t('settings_billing_tariff_period_annually');
    case entities.BillingPeriod.DAILY:
      return i18n.t('settings_billing_tariff_period_daily');
  }

  return null;
}