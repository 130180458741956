import copy from 'copy-to-clipboard';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import SettingButton from 'o-ui/Button/SettingButton';
import FormControl from 'o-ui/FormControl';
import FormControlLabel from 'o-ui/FormControlLabel';
import FormHelperText from 'o-ui/FormHelperText';
import FormLabel from 'o-ui/FormLabel';
import Icon from 'o-ui/Icon';
import CopyTextInput from 'o-ui/Input/CopyTextInput';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import Link from 'o-ui/Link';
import Radio from 'o-ui/Radio';
import Select, {SelectChangeEvent} from 'o-ui/Select';
import MenuItem from 'o-ui/Select/MenuItem';
import Switch from 'o-ui/Switch';

import {IMCWebWidgetConfig} from '../../../../api/proto';
import {API_DOCUMENTATION_URL} from '../../../../config';
import {MAX_FORM_FIELD_LENGTH} from '../../../../constants';
import Channel from '../../../../stores/Channel';
import ModalType from '../../../../stores/ModalType';
import {DEFAULT_SOUND_NAME, soundNames} from '../../../../stores/NotificationsStore';
import useStore from '../../../../stores/useStore';
import ChannelStatus from '../ChannelStatus';
import NotificationSoundPlayer from '../NotificationSoundPlayer';

import {ReactComponent as CopyLineIcon} from '../../../../assets/icons/copy-line.svg';


interface WebWidgetManageFormProps {
  channel: Channel;
  className?: string;
  onBeforeSubmit?: () => void;
  onSubmit?: () => void;
  onChange?: () => void;
}

export interface WebWidgetManageFormRef {
  submitForm?: () => void;
}

export const WebWidgetManageForm = observer(
  React.forwardRef<WebWidgetManageFormRef, WebWidgetManageFormProps>((props, ref) => {
    const {t} = useTranslation();
    const {modals, notifications, channels} = useStore();
    const [apiError, setApiError] = React.useState<string | null | undefined>('');
    const webWidgetConfig = props.channel.webWidgetConfig;

    const formikUpdate = useFormik({
      initialValues: {
        name: props.channel?.name || '',
        domain: webWidgetConfig?.domain || '',
        notificationSound: props.channel?.notificationSound || DEFAULT_SOUND_NAME,
        externalMessagingAPIEnabled: webWidgetConfig?.externalMessagingAPIEnabled || false,
      },
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object({
        name: Yup.string().required(t('Required')),
        domain: Yup.string(),
        notificationSound: Yup.string().required(t('Required')),
        externalMessagingAPIEnabled: Yup.boolean(),
      }),
      onSubmit: async (values) => {
        props.onBeforeSubmit?.();
        setApiError('');

        const newWebWidgetConfig: IMCWebWidgetConfig = {
          ...props.channel?.webWidgetConfig,
          domain: values.domain,
          externalMessagingAPIEnabled: values.externalMessagingAPIEnabled,
        };

        const {error, res} = await channels.updateChannel({
          channel: props.channel,
          channelID: props.channel.id,
          shownFields: {
            name: values.name,
            notificationSound: values.notificationSound,
          },
          webWidgetConfig: newWebWidgetConfig,
        });

        if (error) {
          setApiError(error.message);
        }
        if (res) {
          props.onSubmit?.();
        }
      },
    });

    React.useImperativeHandle(ref, () => ({
      submitForm: () => formikUpdate.submitForm(),
    }));

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange?.();
      formikUpdate.handleChange(e);
    };

    const handleOpenWelcomeMessageConfig = () => {
      modals.open(ModalType.WEB_WIDGET_WELCOME_MESSAGE_SETTINGS, {
        channel: props.channel,
      });
    };

    const handleOpenColorConfig = () => {
      modals.open(ModalType.WEB_WIDGET_THEME_SETTINGS, {
        channel: props.channel,
      });
    };

    const handleOpenSnippetConfig = () => {
      modals.open(ModalType.WEB_WIDGET_SNIPPET_SETTINGS, {
        channel: props.channel,
      });
    };

    const handleCopyWidgetScript = () => {
      copy(webWidgetConfig?.scriptUrl || '');
      notifications.action(t('Widget script copied to clipboard'));
    };

    const handleCopyApiCode = () => {
      copy(webWidgetConfig?.APICode || '');
      notifications.action(t('API code copied to clipboard'));
    };

    const handleChangeSound = (e: SelectChangeEvent) => {
      props.onChange?.();
      formikUpdate.handleChange(e);
      if (e.target.value) {
        notifications.play(e.target.value);
      }
    };

    const toggleExternalApi = () => {
      props.onChange?.();
      formikUpdate.handleChange({
        target: {
          name: 'externalMessagingAPIEnabled',
          value: !formikUpdate.values.externalMessagingAPIEnabled,
        },
      });
    };

    return (
      <div>
        <FormLabel className="body3-regular color-body-tertiary d-flex mb-1">{t('Status')}</FormLabel>
        <ChannelStatus channel={props.channel} />

        <form onSubmit={formikUpdate.handleSubmit}>
          <FormLabel className="body3-regular color-body-tertiary d-flex mb-1 text-capitalize mt-3">
            {t('settings_web_widget_manage_form_channel_name_label')}
          </FormLabel>
          <OutlinedTextInput
            className="w-100"
            name="name"
            onChange={handleChange}
            onBlur={formikUpdate.handleBlur}
            value={formikUpdate.values.name}
            errorHelper={apiError || formikUpdate.errors.name || ' '}
            maxLength={MAX_FORM_FIELD_LENGTH}
            required
          />

          <FormLabel className="body3-regular color-body-tertiary d-flex text-capitalize">
            {t('settings_web_widget_manage_form_website_domain_label')}
          </FormLabel>
          <OutlinedTextInput
            className="w-100"
            name="domain"
            onChange={handleChange}
            onBlur={formikUpdate.handleBlur}
            value={formikUpdate.values.domain}
            errorHelper={apiError || formikUpdate.errors.domain || ' '}
            maxLength={MAX_FORM_FIELD_LENGTH}
            required
          />

          <FormLabel className="body3-regular color-body-tertiary d-flex text-capitalize">
            {t('settings_web_widget_manage_form_widget_script_label')}
          </FormLabel>
          <CopyTextInput
            className="w-100"
            value={webWidgetConfig?.scriptUrl}
            spellCheck={false}
            onCopyValue={handleCopyWidgetScript}
            copiedStateDelay={2000}
            copyBtnIcon={<CopyLineIcon />}
          />

          <FormLabel className="body3-regular color-body-tertiary d-flex text-capitalize mt-3">
            {t('settings_web_widget_manage_form_api_code_label')}
          </FormLabel>
          <CopyTextInput
            className="w-100"
            value={webWidgetConfig?.APICode}
            spellCheck={false}
            onCopyValue={handleCopyApiCode}
            copiedStateDelay={2000}
            copyBtnIcon={<CopyLineIcon />}
          />

          <div className="mt-3 body1-regular">
            <Link className="d-flex align-items-center" href={API_DOCUMENTATION_URL} target="_blank">
              <Icon className="tg-icon-file-line icon-size-16 mr-2" />
              {t('settings_web_widget_manage_form_integration_documentation_link')}
            </Link>
          </div>

          <SettingButton
            className="w-100 border-top mt-3 py-3 text-truncate"
            title={t('settings_web_widget_manage_form_welcome_message_label')}
            onClick={handleOpenWelcomeMessageConfig}
          >
            <span className="body1-regular text-truncate">{webWidgetConfig?.displayWelcomeMessage}</span>
          </SettingButton>

          <SettingButton
            className="w-100 border-top py-3"
            title={t('settings_web_widget_manage_form_visual_settings_label')}
            onClick={handleOpenColorConfig}
          >
            <div className="d-inline-flex align-items-center">
              <span className="body1-regular">{webWidgetConfig?.displayColor}</span>
              <span
                className="d-inline-flex ml-2"
                style={{
                  backgroundColor: webWidgetConfig?.displayColor || '',
                  borderRadius: '50%',
                  width: 16,
                  height: 16,
                }}
              />
            </div>
          </SettingButton>

          <SettingButton
            className="w-100 border-top border-bottom py-3"
            title={t('Channel snippets')}
            onClick={handleOpenSnippetConfig}
          >
            <span className="body1-regular">
              {t('Active {{count}} snippets', {
                count: webWidgetConfig?.helloSnippets?.filter((s) => s.enabled).length || 0,
              })}
            </span>
          </SettingButton>

          <Select
            className="w-100"
            selectClassName="select w-100"
            labelClassName="text-capitalize mt-3"
            label={t('settings_web_widget_manage_form_notification_sound_label')}
            MenuProps={{
              className: 'select__menu',
            }}
            name="notificationSound"
            onChange={handleChangeSound}
            value={formikUpdate.values.notificationSound}
            required
            errorHelper={apiError || formikUpdate.errors.notificationSound || ' '}
          >
            {soundNames.map((soundName) => (
              <MenuItem className="select__menu-item" key={soundName} value={soundName}>
                <Radio
                  className="select__radio"
                  checked={formikUpdate.values.notificationSound === soundName}
                  value={soundName}
                  name={soundName}
                />
                {soundName}
                <NotificationSoundPlayer className="select__menu-item-icon font-size-24 ml-3" soundName={soundName} />
              </MenuItem>
            ))}
          </Select>

          <FormControl className="w-100 outline-default mt-5" variant="outlined" disabled>
            <FormControlLabel
              className="justify-content-end text-capitalize m-0"
              label={t<string>('settings_web_widget_manage_form_external_api_label')}
              labelPlacement="start"
              control={
                <Switch
                  className="ml-auto"
                  name="externalMessagingAPIEnabled"
                  checked={formikUpdate.values.externalMessagingAPIEnabled}
                  onChange={toggleExternalApi}
                />
              }
              disabled
            />
          </FormControl>
          <FormHelperText className="mb-1" error>
            {formikUpdate.errors.externalMessagingAPIEnabled || ' '}
          </FormHelperText>
        </form>
      </div>
    );
  }),
);

export default WebWidgetManageForm;
