import React from 'react';
import {observer} from 'mobx-react';

import useStore from '../../stores/useStore';
import useCompactMode from '../../hooks/useCompactMode';

export const ThemeSelector: React.FC<React.PropsWithChildren> = observer(({children}) => {
  const {
    layOutStore,
    lightThemeChosen,
    externalApp: {customTitleBar},
  } = useStore();
  const compactMode = useCompactMode();

  React.useEffect(() => {
    try {
      const classDict = {
        'light': lightThemeChosen,
        'dark': !lightThemeChosen,
        'window-custom-title-bar': customTitleBar,
        'compact-mode': compactMode,
      };

      for (const [className, condition] of Object.entries(classDict)) {
        if (condition) {
          window.document.documentElement.classList.add(className);
        } else {
          window.document.documentElement.classList.remove(className);
        }
      }
      layOutStore.setCompactMode(compactMode);
    } catch (e) {
      console.error(e);
    }
  }, [
    lightThemeChosen,
    customTitleBar,
    compactMode,
    layOutStore,
  ]);

  return <>{children}</>;
});

export default ThemeSelector;